.sellerOnboardingThankYouSection {
    height: calc(100vh - 146px);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 28px;

    @media only screen and (max-width:"576px") {
        margin: 0;
    }

    .sellerOnboardingThankYouBox {
        background: var(--white);
        border: 1px solid var(--secondary-50);
        border-radius: 10px;
        width: 100%;
        text-align: center;
        padding: 96px 90px;

        @media only screen and (max-width:"1099px") {
            padding: 32px;
        }

        .sellerRightIcon {
            width: 157px;
            margin: auto;
        }

        .sellerThankYouDetails {
            padding: 32px 0 0 0;

            h6 {
                font-family: 'DM Sans', sans-serif;
                font-weight: 500;
                font-size: 24px;
                line-height: 35px;
                color: var(--neutral-800);
                padding: 0 0 10px 0;
                max-width: 431px;
                margin: 0 auto;



                @media only screen and (max-width:"576px") {
                    font-size: 20px;
                    line-height: 28px;
                    max-width: 100%;
                }
            }

            p {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 18px;
                line-height: 30px;
                text-align: center;
                color: #6F6C90;

                @media only screen and (max-width:"576px") {
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        }
    }
}