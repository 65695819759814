.sellerOnboardingGetStartedSection {
  padding: 12px 34px 16px 34px;

  @media only screen and (max-width:"1599px") {
    padding: 14px 12px 14px 16px;
  }

  @media only screen and (max-width:"576px") {
    padding: 0;
  }

  .sellerOnboardingGetStartedHeading {
    h5 {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      font-size: 30px;
      line-height: 45px;
      color: var(--secondary-900);

      @media only screen and (max-width:"1199px") {
        font-size: 24px;
        line-height: 28px;
      }
    }
  }

  .sellerApplicationFormAlignment {
    padding: 45px 0 0 0;

    @media only screen and (max-width:"1599px") {
      padding: 25px 0 0 0;
    }

    .sellerApplicationFormBox {
      background: var(--white);
      border: 1px solid var(--secondary-50);
      border-radius: 10px;
      padding: 32px;

      @media only screen and (max-width:"1599px") {
        padding: 16px;
      }

      h6 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: var(--primary-500);
        padding: 0 0 26px 0;

        @media only screen and (max-width:"1199px") {
          font-size: 18px;
          line-height: 24px;
        }

        @media only screen and (max-width:"576px") {
          padding: 0 0 10px 0;
        }
      }

      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: var(--grey3);

        @media only screen and (max-width:"767px") {
          font-size: 12px;
          line-height: 18px;
        }
      }

      .sellerApplicationFormDetails {
        padding: 16px 0 0 0;

        @media only screen and (max-width:"576px") {
          height: calc(100vh - 300px);
          overflow-y: auto;
        }

        .sellerApplicationFormFirstRow {
          display: grid;
          grid-template-columns: 63px repeat(2, 1fr);
          gap: 16px;
          padding: 0 0 16px 0;

          @media only screen and (max-width:"1599px") {
            grid-template-columns: 63px 1fr 1fr;
            padding: 0 0 12px 0;
          }

          @media only screen and (max-width:"576px") {
            grid-template-columns: 1fr;
            padding: 0 0 12px 0;
          }
        }

        .sellerApplicationFormSecondRow {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 16px;
          padding: 0 0 16px 0;

          @media only screen and (max-width:"1599px") {
            grid-template-columns: repeat(2, 1fr);
            padding: 0 0 12px 0;
          }

          @media only screen and (max-width:"576px") {
            grid-template-columns: 1fr;
            padding: 0 0 12px 0;
          }
        }

        .sellerApplicationFormFourRow {
          display: grid;
          grid-template-columns: 140px 1fr 1fr 70px;
          gap: 16px;
          padding: 0 0 16px 0;
          align-items: flex-end;

          @media only screen and (max-width:"1599px") {
            grid-template-columns: 140px 1fr 1fr 70px;
            padding: 0 0 12px 0;
          }

          @media only screen and (max-width:"767px") {
            grid-template-columns: repeat(2, 1fr);
          }

          @media only screen and (max-width:"576px") {
            grid-template-columns: 1fr;
            padding: 0 0 12px 0;
          }
        }


        .sellerApplicationAddressRow {
          display: grid;
          grid-template-columns: 1fr;
          gap: 16px;
          padding: 0 0 16px 0;
          align-items: flex-end;
        }


        .sellerApplicationOtherRow {
          display: grid;
          grid-template-columns: 1fr 1fr 80px;
          gap: 16px;
          padding: 0 0 16px 0;
          align-items: flex-end;

          @media only screen and (max-width:"576px") {
            grid-template-columns: 1fr;
            padding: 0 0 12px 0;
          }
        }

        .sellerApplicationFormFiveRow {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 16px;
          padding: 0 0 16px 0;
          align-items: flex-end;

          @media only screen and (max-width:"1599px") {
            grid-template-columns: repeat(2, 1fr);
            padding: 0;
          }

          @media only screen and (max-width:"576px") {
            grid-template-columns: 1fr;
            padding: 0 0 12px 0;
          }
        }

        .sellerApplicationFormInput {
          label {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: var(--secondary-900);
            display: block;
            padding: 0 0 4px 0;
            margin: 0;

            @media only screen and (max-width:"767px") {
              font-size: 12px;
              line-height: 18px;
            }
          }

          .sellerApplicationFormInputBox {
            background: var(--white);
            border: 1px solid var(--secondary-50);
            border-radius: 10px;
            height: 36px;

            input {
              width: 100%;
              height: 100%;
              padding: 0 8px;
              font-family: 'Poppins', sans-serif;
              font-weight: 300;
              font-size: 12px;
              line-height: 18px;
              border: none;
              background: transparent;

              &::placeholder {
                color: var(--grey3);
              }


            }

            select {
              width: 100%;
              height: 100%;
              padding: 0 8px;
              font-family: 'Poppins', sans-serif;
              font-weight: 300;
              font-size: 12px;
              line-height: 18px;
              border: none;
              background: transparent;

              &::placeholder {
                color: var(--grey3);
              }


            }

            .createClubsInput {
              width: 100%;
              margin: 4px 0 0 0;
              border-radius: 10px;
              border: 1px solid var(--Bluejestic-Background, #ECEEF4);
              background: var(--secondary-50);
              display: flex;
              align-items: center;
              position: relative;

              &.mobileViewClubCategoryInput {
                @media only screen and (max-width:"767px") {
                  max-width: 315px;
                }
              }

              &.tagInput {
                max-width: 242px;
                width: 242px;

                @media only screen and (max-width:"767px") {
                  max-width: 100%;
                  width: 100%;
                }
              }

              &.selectOtionAlignment {
                padding: 0 8px 0 0;

                @media only screen and (max-width:"767px") {
                  max-width: 220px;
                }
              }

              input {
                width: 100%;
                height: 40px;
                background: transparent;
                border: none;
                padding: 0 16px;
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: var(--secondary-900);

                @media only screen and (max-width:"767px") {
                  font-size: 12px;
                  line-height: 18px;
                }

                &::placeholder {

                  color: var(--grey3);
                }
              }

              textarea {
                width: 100%;
                height: 130px;
                background: transparent;
                border: none;
                padding: 12px 16px;
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: var(--secondary-900);
                resize: none;

                @media only screen and (max-width:"767px") {
                  height: 87px;
                  font-size: 12px;
                  line-height: 18px;
                }

                &::placeholder {

                  color: var(--grey3);
                }
              }

              svg {
                cursor: pointer;
              }

              .createClubDropdowmBoxAlignment {
                border-radius: 10px;
                background: #FFF;
                box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
                width: 100%;
                height: auto;
                padding: 0;
                position: absolute;
                top: 48px;
                left: 0;
                z-index: 999;

                &.closeDropdown {
                  max-height: 0px;
                  transition: 0.4s ease-in-out;
                  overflow-y: hidden;
                }

                &.openDropdown {
                  max-height: 256px;
                  overflow-y: auto;
                  transition: 0.4s ease-in-out;
                  padding: 16px;
                }

                ul {
                  li {
                    color: var(--secondary-900);
                    font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    padding: 8px;
                    cursor: pointer;
                    border-radius: 8px;
                    transition: 0.4s ease-in-out;

                    @media only screen and (max-width:"767px") {
                      font-size: 12px;
                      line-height: 18px;
                    }

                    &:hover {
                      transition: 0.4s ease-in-out;
                      background: var(--primary-50);
                    }

                    &.activeSelect {
                      transition: 0.4s ease-in-out;
                      background: var(--primary-50);
                    }
                  }
                }
              }
            }


          }
        }

        .sellerApplicationMultipleOption {
          background: var(--white);
          border: 1px solid #eceef4;
          border-radius: 10px;
          padding: 4px 16px 4px 9px;
          // height: 44px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          cursor: pointer;

          label {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;
            color: var(--grey3);
            margin: 0;
            display: block;
          }

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: var(--grey5);
          }

          .dropdownArrow {
            img {
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .startedSubmitButton {
    padding: 40px 0 0 0;
    text-align: right;

    @media only screen and (max-width:"1599px") {
      padding: 24px 0 0 0;
    }

    button {
      background: var(--primary-500);
      border-radius: 10px;
      padding: 8px 18px;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: var(--white);
      cursor: pointer;
    }
  }
}

.sellerOnboardingHourlyRateDropdownDesign {
  position: absolute;
  z-index: 9999;
  width: 100%;
  border-radius: 5px;
  top: 50px;
  left: 0;

  .sellerOnboardingHourlyRateDropdownDesignAlignment {
    // border: 1px solid #e7e7e7;
    border-radius: 5px;
    background-color: #ffffff;
    // padding: 0 30px 0 0px;

    a {
      font-size: 13px;
      line-height: 18px;
      font-weight: 600;
      letter-spacing: -0.03em;
      color: #030f21;
      display: block;
      cursor: pointer;
      padding: 6px;
      border-bottom: 1px solid #e7e7e7;
    }
  }
}

.sellerOnboardingDropdownHidden {
  max-height: 0px;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}

.sellerOnboardingDropdownShow {
  max-height: 180px;
  overflow: auto;
  overflow-x: hidden;
  transition: 0.3s ease-in-out;
  background-color: #ffffff;
  border: 1px solid #e7e7e7;
}