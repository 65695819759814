.mobile-view-box {
  @media only screen and (max-width: '767px') {
    display: block;
    border-radius: 16px 16px 0 0;
    // background: #F8F8F8;
    // position: relative;
    // top: -20px;
    background: var(--white);
  }
}
