.newArrivalsImg {
  width: 100%;
  height: 220px;
  cursor: pointer;
  transition: 0.4s ease-in-out;
  position: relative;
  overflow: hidden;
  border-radius: 8px;

  @media only screen and (max-width: '1024px') {
    width: 164px;
    max-width: 164px;
    min-width: 164px;
    height: auto;
  }

  @media (max-width:576px) {
   
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    border-radius: 8px;
    transform: scale(1);
    transition: 0.4s ease-in-out;

    @media only screen and (max-width: '1024px') {
      border-radius: 4px;
      height: 164px;
      width: 164px;
      max-width: 164px;
      min-width: 164px;
    }
    @media (max-width:576px) {
   
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      height: 150px;
    }
  }

  .newArrivalHoverDetails {
    padding: 16px;
    position: absolute;
    width: 100%;
    height: 160px;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(30, 36, 50, 0) 0%, rgba(30, 36, 50, 0.8) 100%);
    display: flex;
    align-items: flex-end;
    border-radius: 0 0 8px 8px;
    visibility: hidden;
    transition: 0.3s ease-in-out;

    @media only screen and (max-width: '1024px') {
      position: static;
      visibility: visible;
      height: auto;
      background: transparent;
      padding: 5px 0 0 0;
      border-radius: 0;
    }

    .newArrivalHoverAllDetails {
      width: 100%;

      p {
        max-width: 163px;
        color: var(--light-grey);
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;

        @media only screen and (max-width: '1024px') {
          max-width: 158px;
          font-size: 13px;
          line-height: 19px;
          color: var(--secondary-900);
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .newArivalBottomOption {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4px 0 0 0;

        span {
          color: var(--light-grey);
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;

          @media only screen and (max-width: '1024px') {
            font-weight: 600;
            color: var(--secondary-900);
          }
        }

        .newArrivalOption {
          display: flex;
          align-items: center;
          gap: 12px;

          @media only screen and (max-width: '1024px') {
            display: none;
          }

          .newArrivalBox {
            width: 24px;
            height: 24px;
            cursor: pointer;
            min-width: 24px;
            max-width: 24px;
          }
        }
      }
    }
  }

  &:hover {
    transition: 0.4s ease-in-out;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);

    .newArrivalHoverDetails {
      visibility: visible;
      transition: 0.3s ease-in-out;
    }

    img {
      transform: scale(1.2);
      transition: 0.4s ease-in-out;
    
      @media (max-width:576px) {
      transform: scale(1);
      }
    }

  }
}