.clubHeaderSticky {
  padding: 0 0 16px 0;

  @media only screen and (max-width: '1099px') {
    display: none;
  }

  .clubTabDetailsAlignment {
    background: var(--white);
    padding: 8px 94px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // position: sticky;
    // top: 0;
    // z-index: 999;
    // border-bottom: 8px solid #eceef4;
    height: 80px;
    position: relative;

    .clubTabLeftSideAlignment {
      display: flex;
      align-items: center;
      gap: 24px;
      height: 100%;

      .clubGroupProfileImgAlignment {
        // position: absolute;
        width: 174px;
        height: 125px;
        // bottom: 16px;
        // left: 94px;
        margin: -64px 0 0 0;
        position: relative;

        @media only screen and (max-width: '1099px') {
          display: none;
        }

        .groupProfileSkeleton {
          border-radius: 16px;
          width: 174px;
          height: 125px;
        }

        img {
          width: 100%;
          height: 100%;
          border: 8px solid #ffffff;
          box-shadow: inset 0px 5px 40px rgba(117, 117, 118, 0.16);
          border-radius: 16px;
          object-fit: cover;
        }

        .editProfileImgAlignment {
          position: absolute;
          top: 16px;
          right: 16px;

          .profileEditIcon {
            width: 32px;
            height: 32px;
            min-width: 32px;
            max-width: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background: var(--black-6);
            border-radius: 8px;
          }
        }
      }

      .profileNameAlignment {
        display: flex;
        align-items: center;
        gap: 8px;

        h4 {
          color: var(--secondary-900);
          font-size: 24px;
          font-weight: 600;
          line-height: 120%;
        }

        .editOption {
          width: 32px;
          height: 32px;
          min-width: 32px;
          max-width: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }

      .clubNameEditAlignment {
        display: flex;
        align-items: center;
        gap: 32px;

        input {
          width: 50%;
          border: none;
          background: transparent;
          color: var(--secondary-900);
          font-size: 24px;
          font-weight: 300;
          line-height: 120%;

          &::placeholder {
            color: var(--secondary-500) !important;
          }

          @media only screen and (max-width: '1024px') {
            width: 100%;
          }
        }

        .clubOptionAlignment {
          display: flex;
          align-items: center;
          gap: 8px;

          .clubOption {
            width: 32px;
            height: 32px;
            min-width: 32px;
            max-width: 32px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    .clubGroupProfileRightSideAlignment {
      display: flex;
      align-items: center;
      gap: 24px;

      .clubGroupProfileOption {
        display: flex;
        align-items: center;

        .clubGroupprofileOptionBox {
          width: 84px;
          cursor: pointer;
          position: relative;

          .clubGroupnewTabIconAlignment {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            margin: 0 auto;
          }

          p {
            color: #636773;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            line-height: 20px;
          }

          .clubGrouphoverBottomLine {
            height: 2px;
            background: transparent;
            width: 100%;
          }

          transition: 0.4s ease-in-out;

          &:hover {
            transition: 0.4s ease-in-out;

            &::before {
              content: '';
              position: absolute;
              bottom: -4px;
              left: 50%;
              transform: translateX(-50%);
              width: 32px;
              height: 2px;
              background: var(--primary-500);
            }

            p {
              color: #556ee6;
            }

            .clubGroupnewTabIconAlignment {
              svg {
                path {
                  fill: #556ee6;
                }
              }
            }
          }

          &.activeTabAlignmet {
            transition: 0.4s ease-in-out;

            &::before {
              content: '';
              position: absolute;
              bottom: -4px;
              left: 50%;
              transform: translateX(-50%);
              width: 32px;
              height: 2px;
              background: var(--primary-500);
            }

            p {
              color: #556ee6;
            }

            .clubGroupnewTabIconAlignment {
              svg {
                path {
                  fill: #556ee6;
                }
              }
            }
          }
        }
      }

      .clubGroupLastSideAlignment {
        display: flex;
        align-items: center;
        gap: 8px;

        .joinButtonAlignment {
          width: 133px;
          height: 32px;

          button {
            width: 100%;
            height: 100%;
            color: var(--white);
            font-size: 15px;
            font-weight: 500;
            line-height: 28px;
            cursor: pointer;
            border-radius: 8px;
            background: var(--primary-500);

            &.joinedButton {
              border-radius: 8px;
              background: var(--secondary-50);
              color: var(--secondary-500);

              &:hover {
              }
            }
            transition: 0.4s ease-in-out;

            &:hover {
              transition: 0.4s ease-in-out;
              transform: translateY(-3px);
            }
          }
        }

        .clubmoreOptionBoxALignment {
          width: 32px;
          height: 32px;
          min-width: 32px;
          max-width: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border-radius: 8px;
          border: 1.25px solid var(--secondary-100);
          position: relative;
          transition: 0.4s ease-in-out;

          &:hover {
            transition: 0.4s ease-in-out;

            background: var(--primary-50);
            border: 1.25px solid var(--primary-50);

            svg {
              path {
                stroke: var(--primary-500);
              }
            }
          }
        }

        .otherDetailsBoxAlignment {
          border-radius: 16px;
          background: var(--white);
          width: 286px;
          height: auto;
          position: absolute;
          top: 40px;
          right: 0;
          z-index: 99;

          &.openDropdownBox {
            overflow-y: hidden;
            transition: 0.4s ease-in-out;
          }

          &.closeDropdownBox {
            max-height: 0px;
            overflow-y: hidden;
            transition: 0.4s ease-in-out;
          }

          .otherBoxBoraderAliggnment {
            border: 1px solid var(--primary-50);
            box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.08);
            padding: 24px 0;
            height: 100%;
            border-radius: 16px;

            .otherBoxDetails {
              padding: 8px 32px;
              display: grid;
              grid-template-columns: 24px 1fr;
              gap: 16px;
              align-items: center;
              cursor: pointer;

              svg {
                path {
                  stroke: var(--secondary-900);
                }
              }

              .optionNameAlignment {
                h4 {
                  color: var(--secondary-900);
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 24px;
                }

                p {
                  color: var(--grey2);
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 18px;
                }

                &.blockSection {
                  h4 {
                    color: var(--red-400);
                  }

                  p {
                    color: var(--red-400);
                  }
                }
              }

              &:hover {
                background: var(--primary-50);
                svg {
                  path {
                    stroke: var(--primary-500);
                  }
                }

                .optionNameAlignment {
                  h4 {
                    color: var(--primary-500);
                  }
                  p {
                    color: var(--primary-500);
                  }
                }
              }
            }
          }
        }
      }
    }

    h6 {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      font-size: 24px;
      line-height: 120%;
      color: var(--secondary-900);
      white-space: nowrap;
    }

    .GroupTabAlignment {
      width: 100%;
      height: 76px;
      background-color: #ffffff;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 0 auto;
      padding: 1.5rem 2.5rem;
      border-radius: 0.65rem;
      overflow: hidden;
    }
  }
}
