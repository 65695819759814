.mobileViewWithdrawDetailsOtpModalSection {
    height: 100%;

    .mobileViewWithdrawDetailsOtpModalHeading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 16px 14px 16px;
        border-bottom: 1px solid #EAECEF;
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 999;

        h4 {
            color: #495057;
            font-size: 18px;
            line-height: 27px;
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
        }

        a {
            color: var(--primary-500);
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            font-family: 'Poppins', sans-serif;
        }
    }

    .mobileViewWithdrawDetailsOtpAlignment {
        // height: calc(100vh - 98px);

        .mobileKYCVerifiedCodeHeading {
            padding: 100px 0 0 0;
            text-align: center;

            h4 {
                color: var(--secondary-900);
                text-align: center;
                font-family: 'Poppins', sans-serif;
                font-size: 24px;
                font-weight: 600;
                line-height: 38px;
            }

            p {
                padding: 21px 0 0 0;
                max-width: 273px;
                color: var(--secondary-900);
                text-align: center;
                font-family: 'Poppins', sans-serif;
                font-size: 13px;
                font-weight: 400;
                line-height: 19px;
                margin: 0 auto;
            }
        }

        .bottomDetailsAlignment {
            // height: 100%;

            .mobileKYCVerifiedCodeMainAlignment {
                margin: 80px 0;

                &.successPassword {
                    .mobileKYCVerifiedInputFlexAlignment {
                        .mobileKYCVerifiedInputAlignment {
                            input {
                                background: #E8F3E6;
                                color: #1D8603;
                            }
                        }
                    }
                }

                &.inCorrectPassWors {
                    margin: 100px 0 150px 0;

                    .incorrecrPassWordNotes {
                        display: block;
                    }

                    .mobileKYCVerifiedInputFlexAlignment {
                        .mobileKYCVerifiedInputAlignment {
                            input {
                                background: #FDE8E8;
                            }
                        }
                    }
                }

                .incorrecrPassWordNotes {
                    text-align: center;
                    padding: 0 0 30px 0;
                    display: none;

                    p {
                        color: #E71616;
                        text-align: center;
                        font-family: 'Poppins', sans-serif;
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 19px;
                    }
                }

                .mobileKYCVerifiedInputFlexAlignment {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;

                    .mobileKYCVerifiedInputAlignment {
                        width: 40px;
                        height: 56px;

                        input {
                            width: 100%;
                            height: 100%;
                            color: var(--secondary-900);
                            text-align: center;
                            font-family: 'Poppins', sans-serif;
                            font-size: 24px;
                            font-weight: 600;
                            border-radius: 8px;
                            background: #F8F8F8;
                            line-height: 30px;
                        }
                    }
                }
            }


            .mobileKYCVerifiedOptionAlignment {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 100px;
                padding: 0 0 16px 0;

                a {
                    color: var(--primary-500);
                    text-align: center;
                    font-family: 'Poppins', sans-serif;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 20px;
                    cursor: pointer;
                }


            }
        }
    }
}