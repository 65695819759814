.addProductConditionSection {
    margin: 0 0 16px 0;

    .addProductConditionBox {
        border-radius: 10px;
        border: 1px solid var(--secondary-50);
        background: #FFF;
        padding: 10px 16px 16px 16px;

        .addProductConditionHeading {
            display: flex;
            align-items: center;

            h4 {
                font-family: "Poppins", sans-serif;
                font-weight: 600;
                font-size: 18px;
                line-height: 27px;
                color: #495057;
                margin-right: 3px;
            }

            .starAlignment {
                font-family: "Inter";
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #d23232;
            }
        }

        .conditionNotes {
            p {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: #808191;
            }
        }

        .addProductConditionDetails {
            display: flex;
            align-items: center;
            gap: 35px;
            padding: 18px 0 0 0;

            .checkBoxAlignment {
                // width: 100%;
                position: relative;
                margin: 0 0 8px 0;
                display: flex;
                align-items: center;
                display: flex;
                gap: 12px;

                .checkboxAlignment {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 12px;

                    input.checkbox {
                        width: 15px;
                        height: 15px;
                        /* margin: 2px; */
                    }

                    label {
                        margin-bottom: 0;
                        margin-left: 10px;

                    }
                }

                label {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: var(--secondary-900);
                    margin: 0;
                }
            }
        }
    }
}