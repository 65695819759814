.sellerTipsSection {
    // margin: 0 0 16px 0;
    .sellerTipsBoxAlignment {
        background: var(--white);
        border: 2px solid var(--white);
        border-radius: 10px 10px 0px 0px;
        padding: 20px 16px;

        .sellerTipsHeadingAlignment {
            padding: 0 16px;

            h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 24px;
                line-height: 36px;
                color: var(--secondary-900);
            }
        }

        .sellerTipsDetailsAlignment {
            padding: 30px 0 0 0;

            .sellerTipsDetailsBoxAlignment {
                margin: 0 0 8px 0;

                &:last-child {
                    margin: 0;
                }

                .sellerTipsBannerAlignment {
                    width: 100%;
                    height: 80px;
                    position: relative;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 8px;
                        display: block;
                    }

                    .sellerTipsCardDetailsAlignment {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 5px;
                        position: absolute;
                        top: 16px;
                        bottom: 16px;
                        right: 16px;
                        left: 16px;

                        p {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 24px;
                            color: var(--yellow-900);

                            &.greenArrow {
                                color: var(--green-800);
                            }
                        }

                        .rightArrowAlignment {
                            width: 18px;
                            height: 18px;
                            min-width: 18px;
                            max-width: 18px;

                            img {
                                width: 100%;
                                height: 100%;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}