.art-craft-trending-store-section {
    margin: 48px 0 0 0;

    @media only screen and (max-width:"1099px") {
        margin: 32px 0 0 16px;
    }

    .art-craft-trending-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media only screen and (max-width:"1099px") {
            padding: 0 16px 0 0;
        }

        h4 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 36px;
            line-height: 54px;
            color: var(--secondary-900);

            @media only screen and (max-width:"1099px") {
                font-size: 20px;
                line-height: 30px;
            }
        }

        .see-more-alignment {
            a {
                display: flex;
                align-items: center;
                gap: 8px;
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 18px;
                line-height: 27px;
                color: var(--primary-500);
                cursor: pointer;

                @media only screen and (max-width:"1099px") {
                    font-size: 14px;
                    line-height: 21px;

                    img {
                        display: none;
                    }
                }

                &:hover {
                    gap: 10px;
                    text-decoration: underline;
                }
            }
        }
    }

    .art-craft-details-alignment {
        padding: 32px 0 0 0;

        @media only screen and (max-width:"1099px") {
            width: 100%;
            overflow-x: hidden;
            padding: 16px 0 0 0;
        }

        .art-craft-details-grid-alignment {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 21px;


            // @media only screen and (max-width:"1099px") {
            //     grid-template-columns: repeat(4, 1fr);
            // }

            @media only screen and (max-width:"1024px") {
                display: none;
            }

            .art-craft-details-gridItem-alignment {
                .art-craft-details-box-alignment {
                    padding: 8px;
                    background: #FFFFFF;
                    border: 1px solid var(--secondary-50);
                    border-radius: 16px;

                    @media only screen and (max-width:"1099px") {
                        max-width: 163px;
                        min-width: 163px;
                        width: 163px;
                    }

                    &:hover {
                        transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                        transform: translateY(-6px);
                    }

                    .art-craft-details-logo-alignment {
                        height: 240px;
                        width: 100%;

                        @media only screen and (max-width:"1099px") {
                            height: 160px;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }

                    .art-craft-child-details-alignment {
                        padding: 24px 0 0 0;
                        cursor: pointer;

                        @media only screen and (max-width:"1099px") {
                            padding: 10px 0 0 0;
                        }

                        h6 {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 30px;
                            text-align: center;
                            color: #000000;

                            @media only screen and (max-width:"1099px") {
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }

                        p {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 21px;
                            color: #495057;
                            text-align: center;

                            @media only screen and (max-width:"1099px") {
                                font-size: 12px;
                                line-height: 18px;
                                display: block;
                                padding: 2px 0 0 0;
                            }
                        }

                        .art-craft-product {
                            margin: 16px 0 8px 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 4px;

                            @media only screen and (max-width: '1099px') {
                                flex-wrap: wrap;
                                gap: 2px;
                                margin: 9px 0 8px 0;
                            }

                            span {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 21px;
                                color: #495057;

                                @media only screen and (max-width: '1099px') {
                                    font-size: 12px;
                                    line-height: 18px;
                                    margin: 0 0 2px 0;
                                    padding: 0;
                                }
                            }

                            p {
                                width: 3px;
                                height: 3px;
                                border-radius: 50%;
                                background: #495057;

                                @media only screen and (max-width: '1099px') {
                                    display: none;
                                }
                            }
                        }

                        .art-craft-visitor {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-wrap: wrap;
                            gap: 2px;

                            span {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 21px;
                                color: #495057;

                                @media only screen and (max-width: '1099px') {
                                    font-size: 12px;
                                    line-height: 18px;
                                    padding: 0;
                                }
                            }

                            .art-craft-pre {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 4px;
                            }
                        }

                        .follow-button-alignment {
                            padding: 16px 8px 8px 8px;

                            @media only screen and (max-width: '1099px') {
                                padding: 14px 0 0 0;
                            }

                            button {
                                width: 100%;
                                height: 32px;
                                background: var(--primary-500);
                                border-radius: 8px;
                                font-family: 'Poppins', sans-serif;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 21px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #f8f8f8;
                                cursor: pointer;

                                &.following {
                                    background: #f8f8f8;
                                    color: var(--primary-500);

                                    &:hover {
                                        background: #eef1fc;
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }


        .art-craft-mobile-view-details-alignment {
            display: none;

            @media only screen and (max-width: "1024px") {
                display: block;
            }

            .art-craft-details-gridItem-alignment {
                padding: 0 4px;

                .art-craft-details-box-alignment {
                    padding: 8px;
                    background: #FFFFFF;
                    border: 1px solid var(--secondary-50);
                    border-radius: 16px;

                    @media only screen and (max-width:"1099px") {
                        // max-width: 163px;
                        // min-width: 163px;
                        // width: 163px;
                    }

                    &:hover {
                        transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                        transform: translateY(-6px);
                    }

                    .art-craft-details-logo-alignment {
                        height: 240px;
                        width: 100%;

                        @media only screen and (max-width:"1099px") {
                            height: 160px;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }

                    .art-craft-child-details-alignment {
                        padding: 24px 0 0 0;
                        cursor: pointer;

                        @media only screen and (max-width:"1099px") {
                            padding: 10px 0 0 0;
                        }

                        h6 {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 30px;
                            text-align: center;
                            color: #000000;

                            @media only screen and (max-width:"1099px") {
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }

                        p {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 21px;
                            color: #495057;
                            text-align: center;

                            @media only screen and (max-width:"1099px") {
                                font-size: 12px;
                                line-height: 18px;
                                display: block;
                                padding: 2px 0 0 0;
                            }
                        }

                        .art-craft-product {
                            margin: 16px 0 8px 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 4px;

                            @media only screen and (max-width: '1099px') {
                                flex-wrap: wrap;
                                gap: 2px;
                                margin: 9px 0 8px 0;
                            }

                            span {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 21px;
                                color: #495057;

                                @media only screen and (max-width: '1099px') {
                                    font-size: 12px;
                                    line-height: 18px;
                                    margin: 0 0 2px 0;
                                    padding: 0;
                                }
                            }

                            p {
                                width: 3px;
                                height: 3px;
                                border-radius: 50%;
                                background: #495057;

                                @media only screen and (max-width: '1099px') {
                                    display: none;
                                }
                            }
                        }

                        .art-craft-visitor {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-wrap: wrap;
                            gap: 2px;

                            span {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 21px;
                                color: #495057;

                                @media only screen and (max-width: '1099px') {
                                    font-size: 12px;
                                    line-height: 18px;
                                    padding: 0;
                                }
                            }

                            .art-craft-pre {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 4px;
                            }
                        }

                        .follow-button-alignment {
                            padding: 16px 8px 8px 8px;

                            @media only screen and (max-width: '1099px') {
                                padding: 14px 0 0 0;
                            }

                            button {
                                width: 100%;
                                height: 32px;
                                background: var(--primary-500);
                                border-radius: 8px;
                                font-family: 'Poppins', sans-serif;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 21px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #f8f8f8;
                                cursor: pointer;

                                &.following {
                                    background: #f8f8f8;
                                    color: var(--primary-500);

                                    &:hover {
                                        background: #eef1fc;
                                    }
                                }
                            }
                        }

                    }
                }
            }

            .slick-arrow {
                display: none !important;
            }
        }
    }
}