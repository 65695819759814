@mixin block_btn {
    background: var(--primary-500);
    border-radius: 8px;
    display: block;
    width: 100%;
    border: unset;
    outline: unset;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 10px;
    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

    &:hover {
        transform: translateY(-3px);
        background: #2844ac;
    }
}

.mobile-view-meet-artists {
    display: none;

    @media only screen and (max-width:"1099px") {
        display: block;
        padding: 32px 0 0 0;
    }

    .mobile-view-meet-alignment {
        padding: 16px 0 16px 16px;

        .mobile-view-meet-heading-alignment {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 16px 0 0;

            h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                color: var(--secondary-900);
            }

            .see-all-alignment {
                a {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: var(--primary-500);
                }
            }
        }

        .mobile-view-meet-artists-details-alignment {
            padding: 32px 0 0 0;

            .slick-arrow {
                display: none !important;
            }

            .mobile-view-meet-artists-store-all-details-gridItem {
                padding: 0 8px;

                .mobile-view-meet-child-artists-details-alignment {
                    width: 100%;

                    .person-img-alignment {
                        width: 100%;
                        height: 170px;

                        @media only screen and (max-width:"599px") {
                            height: 140px;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                            object-fit: cover;
                        }
                    }

                    .mobile-view-meet-artists-child-details-alignment {
                        text-align: center;

                        h5 {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 24px;
                            color: var(--secondary-900);
                            padding: 16px 0 0 0;
                        }

                        span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 18px;
                            color: #495057;
                            display: block;
                            padding: 4px 0 0 0;
                        }

                        p {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 21px;
                            color: #495057;
                            padding: 8px 0 0 0;
                        }

                        .mobile-view-meet-artists-button-alignment {
                            margin: 16px 0 0 0;
                            @include block_btn();
                        }
                    }
                }
            }
        }
    }
}