.sharedCollectionModalAlignment {
  padding: 32px 0 0 0;
  height: calc(100vh - 270px);
  overflow-y: auto;

  @media only screen and (max-width: '1024px') {
    padding: 16px;
    height: unset;
  }
  .sharedBookMarkDataAlignment {
    padding: 24px 58px 0 58px;
    @media only screen and (max-width: '1024px') {
      padding: 32px 0 0 0;
    }
    .sharedCollectionGridAlignment {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 72px 56px;

      @media only screen and (max-width: '1024px') {
        grid-template-columns: repeat(2, 1fr);
        gap: 32px 54px;
        padding: 0 30px 0 16px;
      }

      .sharedCollectionGridItem {
        .bookMarkCollectionMainDetailsAlignment {
          .bookMarkCollectionCardDetails {
            position: relative;
            width: 100%;
            height: 200px;
            cursor: pointer;

            @media only screen and (max-width: '767px') {
              height: 220px;
              width: 100%;
            }

            @media only screen and (max-width: '499px') {
              height: 113px;
              width: 100%;
            }

            .bookMarkCollectionCardImg {
              width: 200px;
              height: 200px;
              border-radius: 16px;
              border: 6px solid #fff;
              background: #fff;
              box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
              transform: rotate(-6.983deg);
              position: relative;

              .tagDesign {
                position: absolute;
                top: 10px;
                right: 10px;
                button {
                  border: none;
                  border-radius: 10px;
                  padding: 4px 16px;
                  cursor: pointer;
                  color: #fff;
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 16px;
                  background-color: #000;
                }
              }

              @media only screen and (max-width: '767px') {
                border: 3px solid #fff;
                border-radius: 10px;
                width: 100%;
                height: 220px;
              }

              @media only screen and (max-width: '499px') {
                border: 3px solid #fff;
                border-radius: 10px;
                width: 100%;
                height: 113px;
              }

              img {
                width: 100%;
                height: 100%;
                display: block;
                border-radius: 16px;
                object-fit: cover;

                @media only screen and (max-width: '767px') {
                  border-radius: 10px;
                  object-position: center;
                }
              }

              &:nth-child(2) {
                position: absolute;
                top: 0;
                left: 10px;
                transform: rotate(0);
              }

              &:nth-child(3) {
                position: absolute;
                top: 0;
                left: 20px;
                transform: rotate(5.357deg);
                z-index: 9;
              }
            }

            .bookMarkCollectionCardImg2 {
              width: 100%;
              height: 100%;
              border-radius: 16px;
              border: 6px solid #fff;
              background: #fff;
              box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
              position: absolute;
              top: 0;
              left: 10px;

              @media only screen and (max-width: '767px') {
                border: 3px solid #fff;
                border-radius: 10px;
              }

              img {
                width: 100%;
                height: 100%;
                display: block;
                border-radius: 16px;
                object-fit: cover;

                @media only screen and (max-width: '767px') {
                  border-radius: 10px;
                }
              }
            }

            .bookMarkCollectionCardImg3 {
              width: 100%;
              height: 100%;
              border-radius: 16px;
              border: 6px solid #fff;
              background: #fff;
              box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
              position: absolute;
              top: 0;
              left: 20px;
              transform: rotate(5.357deg);

              @media only screen and (max-width: '767px') {
                border: 3px solid #fff;
                border-radius: 10px;
              }

              img {
                width: 100%;
                height: 100%;
                display: block;
                border-radius: 16px;
                object-fit: cover;

                @media only screen and (max-width: '767px') {
                  border-radius: 10px;
                }
              }
            }
          }

          .sharedBookMarkCollectionNamesAlignment {
            padding: 24px 0 0 18px;

            @media only screen and (max-width: '1024px') {
              padding: 20px 0 0 0;
            }

            h6 {
              color: var(--secondary-900);
              font-size: 16px;
              font-weight: 500;
              line-height: 21px;
              cursor: pointer;

              @media only screen and (max-width: '1024px') {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 133px;
                text-align: center;
              }
            }

            .sharedCollectionProfileAlignment {
              display: flex;
              align-items: center;
              gap: 4px;
              padding: 5px 0 0 0;

              @media only screen and (max-width: '1024px') {
                justify-content: center;
              }

              .sharedProfileImg {
                width: 18px;
                height: 18px;
                min-width: 18px;
                max-width: 18px;

                @media only screen and (max-width: '1024px') {
                  width: 16px;
                  height: 16px;
                  min-width: 16px;
                  max-width: 16px;
                }

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 50%;
                }
              }

              p {
                color: var(--secondary-900);
                font-size: 13px;
                font-weight: 400;
                line-height: 21px;

                @media only screen and (max-width: '1024px') {
                  font-size: 12px;
                  line-height: 18px;
                }
              }
            }

            .sharedCollectionBottomDetails {
              display: flex;
              align-items: center;
              gap: 8px;
              padding: 8px 0 0 0;

              @media only screen and (max-width: '1024px') {
                padding: 2px 0 0 0;
                gap: 4px;
                justify-content: center;
              }

              span {
                color: var(--secondary-500);
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;

                @media only screen and (max-width: '1024px') {
                  font-size: 12px;
                  line-height: 18px;
                }
              }

              p {
                width: 2px;
                height: 2px;
                border-radius: 50%;
                background: var(--secondary-300);
              }
            }
          }
        }
      }
    }
  }

  .sharedNoCollectionData {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    height: calc(100vh - 334px);
    border-radius: 16px;
    background: var(--primary-50);

    p {
      max-width: 258px;
      color: #9c6ac5;
      text-align: center;
      font-size: 12px;
      font-style: italic;
      font-weight: 400;
      line-height: 18px;
    }
  }

  .mobilesSharedCollectionDetailsSerch {
    display: none;
    @media only screen and (max-width: '1024px') {
      display: block;
      width: 100%;
      height: 40px;
      background: var(--light-grey);
      border-radius: 8px;
      display: flex;
      align-items: center;

      .mobileSearchIcon {
        width: 40px;
        min-width: 40px;
        max-width: 40px;
        height: 40px;
      }

      input {
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        &::placeholder {
          color: var(--grey3);
        }
      }
    }
  }
}
