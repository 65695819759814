@mixin block_btn {
  background: var(--primary-500);
  border-radius: 8px;
  display: block;
  width: 100%;
  border: unset;
  outline: unset;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    transform: translateY(-3px);
    background: #2844ac;
  }
}

.home-appliance-club-modal-details-alignment {
  @media only screen and (max-width: '767px') {
    background: #fff;
  }

  .mobile-view-book-appliance-back-alignment {
    display: none;

    @media only screen and (max-width: '767px') {
      display: flex;
      align-items: center;
      gap: 18px;
      padding: 8px 16px;
      background: rgba(248, 248, 248, 0.92);
      backdrop-filter: blur(13.5914px);

      .book-appliance-back_details-alignment {
        background: #ffffff;
        border-radius: 10px;
        padding: 4px 9px 4px 10px;
        display: flex;
        align-items: center;
        gap: 8px;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #808191;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 58px;
        }
      }

      .mobile-heading-alignment {
        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          text-align: center;
          color: var(--secondary-900);
        }
      }
    }
  }

  .home-appliance-club-modal-banner-alignment-sc {
    height: auto !important;

    @media only screen and (max-width: '767px') {
      height: auto !important;
    }

    .home-appliance-club-modal-banner-details {
      position: relative !important;
      top: 0 !important;
      left: 0 !important;
      padding: 32px 0 0 32px;
    }
  }

  .home-appliance-club-details-modal-details-alignment {
    padding: 32px 32px 0 32px !important;
  }

  .home-appliance-club-modal-banner-alignment {
    width: 100%;
    height: 178px;
    position: relative;

    @media only screen and (max-width: '767px') {
      height: 100px;
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 8px;

      @media only screen and (max-width: '767px') {
        display: none;
      }

      &.mobile-view-banner {
        display: none;

        @media only screen and (max-width: '767px') {
          display: block;
        }
      }
    }

    .home-appliance-club-modal-banner-details {
      position: absolute;
      top: 48px;
      left: 48px;

      @media only screen and (max-width: '767px') {
        top: 20px;
        left: 16px;
      }

      h4 {
        margin: 0;
        color: var(--bookmark-heading-color);
        font-family: 'Poppins', sans-serif;
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
        white-space: nowrap;
      }

      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--secondary-900);

        @media only screen and (max-width: '767px') {
          font-size: 12px;
          line-height: 18px;
          max-width: 165px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .home-appliance-club-details-modal-details-alignment {
    padding: 16px 0 0 0;
    cursor: pointer;

    .home-appliance-club-grid-alignment {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;

      @media only screen and (max-width: '899px') {
        grid-template-columns: repeat(2, 1fr);
      }

      @media only screen and (max-width: '767px') {
        grid-template-columns: repeat(1, 1fr);
        padding: 0 16px;
      }

      .home-appliance-club-gridItem-alignment {
        .fashion_group_slider_cover {
          .card_fashion {
            width: 100%;
            position: relative;
            border-radius: 10px 10px 0px 0px;
            overflow: hidden;
            transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
            height: 251px;
            max-height: 251px;

            @media only screen and (max-width: '767px') {
              height: 230px;
              max-height: 230px;
            }

            &:hover {
              transform: translateY(-5px);

              .img_cover img {
                transform: scale(1.05);
              }
            }

            .img_cover {
              object-fit: cover;
              object-position: center;
              height: 251px;
              max-height: 251px;

              @media only screen and (max-width: '767px') {
                height: 230px;
                max-height: 230px;
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
              }
            }

            .card_details {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: flex-end;
              padding: 15px;
              background: linear-gradient(360deg, #0e1116 0%, rgba(30, 36, 50, 0) 100%);

              .card_details_cover {
                width: 100%;

                h5 {
                  font-weight: 500;
                  font-size: 20px;
                  line-height: 30px;
                  color: #ffffff;
                }

                p {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 18px;
                  color: #ffffff;
                  margin: 4px 0 17px 0;
                }

                .users {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  .left_part {
                    display: flex;
                    align-items: center;

                    .users_likes {
                      display: flex;
                      align-items: center;
                      margin-left: -10px;

                      .user_box {
                        width: 20px;
                        display: flex;
                        align-items: center;
                        height: 20px;
                        justify-content: center;
                        background: #fff;
                        object-position: center;
                        border: 1px solid #ffffff;
                        border-radius: 30px;

                        img {
                          width: 100%;
                          object-fit: cover !important;
                        }
                      }
                    }

                    .count {
                      margin-left: -10px;
                      background: var(--primary-500);
                      border: 1px solid #ffffff;
                      border-radius: 12px;
                      font-weight: 500;
                      font-size: 12px;
                      line-height: 18px;
                      letter-spacing: 0.1px;
                      color: #ffffff;
                      padding: 0px 9px;
                    }
                  }

                  .right_part {
                    max-width: 120px;

                    button {
                      @include block_btn();
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}