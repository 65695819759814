.store-product-filterOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(30, 36, 50, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  z-index: 9999 !important;
}

.store-product-filterOverlay.open {
  opacity: 1;
  visibility: visible;
}

.store-product-filter-modal {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: white;
  translate: 0 100%;
  transition: 0.3s;
  border-radius: 16px 16px 0 0;
  overflow-y: auto;

  .ant-tabs-nav {
    margin-bottom: 0px !important;
    background-color: #fff;
  }

  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before {
    border: 0;
  }

  .ant-tabs-nav-list {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0;
    // padding: 0 74px;

    .ant-tabs-tab {
      padding: 0 38px;
      margin: 0;

      .ant-tabs-tab-btn {
        padding: 16px 23px;
        font-size: 15px;
        line-height: 22px;
        color: #a7a7a7;
        font-weight: 400 !important;
      }

      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          font-weight: 500 !important;
          color: var(--primary-700);
        }

        &:before {
          content: '';
          position: absolute;
          height: 3px;
          background: var(--primary-700);
          border-radius: 26px 26px 0 0px;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }
    }

    .ant-tabs-ink-bar {
      display: none;
    }
  }
}

.store-product-filter-modal.open {
  translate: 0;
  z-index: 9999999;
  transform: translate(0, 6%);
}

.store-product-filter-modal.about {
  translate: 0;
  z-index: 9999999;
  transform: translate(0, 36%);
}

.store-product-filter-modal.group-more {
  translate: 0;
  z-index: 9999999;
  transform: translate(0, 6%);
}

.store-product-filter-click-drop-alignment {
  border-bottom: 1px solid #eaecef;
  padding: 8px 0 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 999;

  p {
    width: 33px;
    height: 3px;
    background: #e4e4e8;
    border-radius: 1.5px;
  }
}

.filter-modal-heading-alignment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 13px 20px 13px;

  .filter-main-name-alignment {
    display: flex;
    align-items: center;
    gap: 8px;

    h6 {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: var(--secondary-900);
    }
  }

  .filter-reseat-alignment {
    a {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #d21414;
    }

    span {
      color: #556ee6;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
    }

    // .cancel-button {
    //   opacity: 0.5;
    //   color: #ff0000;
    //   cursor: pointer;
    // }
  }
}

.filter-category-details-alignment {
  .filter-heading-alignment {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h6 {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--secondary-900);
    }
  }

  .main-filter-categories-box-header {
    padding: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .drop-down-icon-alignment {
      .transition-icon {
        transition: 0.3s ease-in-out;
      }

      .icon-rotate-stable {
        transform: rotate(360deg);
      }

      .icon-rotate {
        transform: rotate(180deg);
      }
    }

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--secondary-900);
      margin: 0;
    }

    div {
      svg {
        cursor: pointer;
      }
    }
  }

  .main-filter-categories-box {
    background: #ffffff;

    margin: 0 0 13px 0;
    border-radius: 10px;
  }

  .main-filter-details {
    .main-filter-details-alignment {
      padding: 0 11px 11px 11px;

      button:last-child {
        margin: 0;
      }

      button {
        padding: 6px 8px;
        cursor: pointer;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        background: #ffffff;
        display: block;
        margin: 0 0 4px 0;
        border: 1px solid #e4e4e8;
        border-radius: 4px;
        color: #495057;
      }

      .active-class {
        border: 1px solid #556ee6;
        border-radius: 4px;
      }

      span {
        font-weight: 400;
        font-size: 14px;
        padding: 2px 0 0 0;
        line-height: 21px;
        color: var(--primary-500);
        display: block;
        cursor: pointer;
      }
    }
  }

  // .main-filter-categories-box-header {
  //     padding: 13px;
  //     display: flex;
  //     align-items: center;
  //     justify-content: space-between;

  //     p {
  //         font-weight: 500;
  //         font-size: 16px;
  //         line-height: 24px;
  //         color: var(--secondary-900);
  //         margin: 0;
  //     }

  //     div {
  //         svg {
  //             cursor: pointer;
  //         }
  //     }
  // }

  .colors-box-alignemtn {
    background: #ffffff;

    margin: 0 0 13px 0;
    border-radius: 10px;

    .color-body-content {
      .color-body-content-alignment {
        padding: 0 13px 13px 13px;

        .show-more-text {
          span {
            display: block;
            font-weight: 400;
            cursor: pointer;
            font-size: 14px;
            line-height: 21px;
            color: var(--primary-500);
          }
        }

        .button-alignment {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          padding: 0 0 13px 0;
          gap: 4px;

          button {
            background: #ffffff;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #495057;
            cursor: pointer;
            border: 1px solid #e4e4e8;
            border-radius: 4px;
            padding: 6px 8px;
          }
        }
      }
    }

    .color-header {
      padding: 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .drop-down-icon-alignment {
        .transition-icon {
          transition: 0.3s ease-in-out;
        }

        .icon-rotate-stable {
          transform: rotate(360deg);
        }

        .icon-rotate {
          transform: rotate(180deg);
        }
      }

      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: var(--secondary-900);
        margin: 0;
      }

      div {
        svg {
          cursor: pointer;
        }
      }
    }
  }

  .main-filter-categories-box {
    background: #ffffff;

    margin: 0 0 13px 0;
    border-radius: 10px;

    .main-filter-details {
      .main-filter-details-alignment {
        padding: 0 11px 11px 11px;
        display: flex;
        align-items: center;
        gap: 16px;
        /* flex-direction: column; */
        flex-wrap: wrap;

        button:last-child {
          margin: 0;
        }

        button {
          padding: 6px 8px;
          cursor: pointer;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          background: #ffffff;
          // display: block;
          // margin: 0 0 4px 0;
          border: 1px solid #e4e4e8;
          border-radius: 4px;
          color: #495057;
        }

        .active-class {
          border: 1px solid #556ee6;
          border-radius: 4px;
        }

        span {
          font-weight: 400;
          font-size: 14px;
          padding: 2px 0 0 0;
          line-height: 21px;
          color: var(--primary-500);
          display: block;
          cursor: pointer;
        }
      }
    }

    // .main-filter-categories-box-header {
    //     padding: 13px;
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;

    //     p {
    //         font-weight: 500;
    //         font-size: 16px;
    //         line-height: 24px;
    //         color: var(--secondary-900);
    //         margin: 0;
    //     }

    //     div {
    //         svg {
    //             cursor: pointer;
    //         }
    //     }
    // }
  }

  .price-range-alignment {
    background: #ffffff;
    border-radius: 10px;

    .ant-slider-handle {
      border: 2px solid #778beb !important;
    }
    .ant-slider-track {
      background-color: #778beb !important;
    }

    .price-range-header {
      padding: 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .drop-down-icon-alignment {
        .transition-icon {
          transition: 0.3s ease-in-out;
        }

        .icon-rotate-stable {
          transform: rotate(360deg);
        }

        .icon-rotate {
          transform: rotate(180deg);
        }
      }

      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: var(--secondary-900);
        margin: 0;
      }

      div {
        svg {
          cursor: pointer;
        }
      }
    }

    .price-slider-main {
      width: calc(100% - 10px);
      height: 12px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0 5px;

      .price-slider-line {
        width: 100%;
        height: 12px;
        position: relative;

        &::after {
          position: absolute;
          content: '';
          width: 100%;
          top: calc(50% - 1px);
          height: 2px;
          background-color: #eef1fd;
          z-index: 1;
        }

        .price-slider-increse-one,
        .price-slider-increse-two {
          position: absolute;
          top: 0;
          transform: translateX(-50%);
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: 2px solid #778beb;
          background-color: #fff;
          z-index: 9;
          cursor: pointer;
        }

        .price-slider-incresed-fill {
          height: 4px;
          position: absolute;
          top: 4px;
          background-color: #778beb;
          z-index: 8;
        }
      }
    }

    @mixin dragIndicator($property, $background, $z-index) {
      #{$property} {
        position: absolute;
        top: 0;
        z-index: $z-index;
        width: 0;
        height: 5px;
        border-radius: 5px;
        background: $background;

        &:hover {
          &::before {
            opacity: 1;
          }

          &::after {
            opacity: 1;
          }
        }

        &::before {
          opacity: 0;
          content: attr(data-content);
          display: block;
          position: absolute;
          top: -40px;
          right: -23px;
          width: 40px;
          padding: 3px;
          text-align: center;
          color: white;
          background: #274684;
          border-radius: 20px;
        }

        &::after {
          opacity: 0;
          content: '';
          display: block;
          position: absolute;
          top: -18px;
          right: -8px;
          border-top: 8px solid #274684;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
        }

        #{$property}-drag {
          position: absolute;
          right: -7.5px;
          top: -5px;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          border: 2px solid #778beb;
          background-color: #fff;
          transition: all 0.3s;

          &:hover {
            box-shadow: 0 0 0 6px rgba(#274684, 0.2);
          }
        }
      }
    }

    .range-slider {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 50px;
      padding-top: 25px;
      margin-top: 40px;
      border-radius: 20px;
      box-shadow: 0px 0px 20px 0px rgba(#274684, 0.2);
      background: #fff;
      overflow: hidden;

      h2 {
        margin-bottom: 40px;
      }

      .current-value {
        width: 100%;

        label {
          display: inline-flex;
          width: 50px;
          font-size: 20px;
        }

        input {
          margin: 0;
          max-width: 40px;
          margin-bottom: 5px;
          font-size: 16px;
          color: white;
          padding: 5px;
          padding-left: 15px;
          border: none;
          border-radius: 20px;
          background: #274684;
        }
      }

      .values {
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        margin-top: 30px;
        margin-bottom: 10px;
        width: 100%;
      }

      #slider {
        position: relative;
        margin: 0 auto;
        width: 100%;
        height: 5px;
        background: #eef1fd;
        border-radius: 5px;
        cursor: pointer;

        @include dragIndicator('#min', #eef1fd, 2);
        @include dragIndicator('#max', #778beb, 1);
      }
    }

    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    *:focus {
      outline: none;
      box-shadow: 0 0 0 2px #709fdc;
    }
  }

  .price-range-details-input-alignment {
    .price-range-body-alignment {
      display: flex;
      align-items: flex-end;
      gap: 16px;
      padding: 0 16px 16px 16px;

      .price-range-input-alignment {
        label {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #808191;
        }

        .ant-input-number-focused {
          border: 1px solid #e4e4e8 !important;
          box-shadow: none !important;
          outline: none !important;

          input {
            border: none !important;
            box-shadow: none !important;
            outline: none !important;
          }
        }

        .price-range-input-alignment,
        .ant-input-number {
          width: 118px !important;
          height: 32px !important;
          margin: 10px 0 0 0;
          background: #ffffff;
          border: 1px solid #e4e4e8;

          .ant-input-number-handler-wrap {
            display: none !important;
          }

          input,
          .ant-input-number-input-wrap {
            width: 100% !important;
            height: 100% !important;
            border-radius: 4px !important;
            background: transparent !important;
            border: none !important;
            padding: 0 10px !important;
            // margin: 0 10px !important;
          }
        }
      }

      .minus-alignment {
        position: relative;
        top: -6px;

        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #808191;
        }
      }
    }
  }
}

.filter-apply-button-alignment {
  padding: 20px 0 0 0;

  button {
    width: 100%;
    background: var(--primary-500);
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #f8f8f8;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.content-hidden {
  max-height: 0px;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}

.content-show {
  transition: 0.3s ease-in-out;
}

.stickyFilter {
  position: sticky;
  top: 100px;
}
