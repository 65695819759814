.jewelryFansModalSection {
    .jewelryFansModalAlignment {
        border-radius: 10px;
        background: #FFF;
        height: calc(100vh - 96px);
        padding: 0 32px;
        overflow-y: auto;

        @media only screen and (max-width:"1099px") {
            height: calc(100vh - 32px);
        }

        .jewelryFansHeading {
            padding: 32px 0;
            background: #FFF;
            position: sticky;
            top: 0;
            z-index: 99;

            h4 {
                color: var(--bookmark-heading-color);
                font-family: 'Poppins', sans-serif;
                font-size: 24px;
                font-weight: 600;
                line-height: 36px;
            }
        }

        .jewelryFansBodyAlignment {}
    }
}