.book-appliance-now-trending-store-details-alignment {
    @media only screen and (max-width:"767px") {
        background: #fff;
    }

    .mobile-view-book-appliance-back-alignment {
        display: none;

        @media only screen and (max-width:"767px") {
            display: flex;
            align-items: center;
            gap: 18px;
            padding: 8px 16px;
            background: rgba(248, 248, 248, 0.92);
            backdrop-filter: blur(13.5914px);

            .book-appliance-back_details-alignment {
                background: #ffffff;
                border-radius: 10px;
                padding: 4px 9px 4px 10px;
                display: flex;
                align-items: center;
                gap: 8px;

                p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #808191;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 58px;
                }
            }

            .mobile-heading-alignment {
                h4 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 27px;
                    text-align: center;
                    color: var(--secondary-900);
                }
            }
        }
    }


    .book-appliance-now-trending-store-banner-alignment {
        width: 100%;
        height: 178px;
        position: relative;

        @media only screen and (max-width:"767px") {
            height: 100px;
        }

        img {
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 8px;

            @media only screen and (max-width:"767px") {
                display: none;
            }

            &.mobile-view-banner {
                display: none;

                @media only screen and (max-width:"767px") {
                    display: block;
                }
            }
        }

        .book-appliance-now-trending-store-banner-details {
            position: absolute;
            top: 48px;
            left: 48px;

            @media only screen and (max-width:"767px") {
                top: 20px;
                left: 16px;
            }

            h4 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 40px;
                line-height: 60px;
                color: var(--secondary-900);

                @media only screen and (max-width:"767px") {
                    font-size: 20px;
                    line-height: 30px;
                }
            }

            p {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--secondary-900);

                @media only screen and (max-width:"767px") {
                    font-size: 12px;
                    line-height: 18px;
                    max-width: 165px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .book-appliance-now-trending-details-alignment {
        padding: 21px 0;

        @media only screen and (max-width:"767px") {
            padding: 16px 0;
        }

        .book-appliance-now-trending-grid-alignment {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 16px;

            @media only screen and (max-width:"767px") {
                grid-template-columns: repeat(2, 1fr);
                padding: 0 16px;
            }

            .book-appliance-now-trending-gridItem-alignment {
                .book-appliance-now-trending-img-alignment {
                    width: 100%;
                    height: auto;
                    margin: 0 0 16px 0;
                    position: relative;
                    transition: 0.8s ease-in-out;

                    &:last-child {
                        margin: 0;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 10px;
                    }

                    &:hover {

                        &::before {
                            content: '';
                            position: absolute;
                            background: linear-gradient(180deg, rgba(30, 36, 50, 0) 0%, #1E2432 100%);
                            border-radius: 10px;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            right: 0;

                        }

                        .hover-card-details-alignment {
                            display: block;

                            .other-option-alignment-details {
                                position: absolute;
                                top: 16px;
                                right: 16px;
                                display: flex;
                                align-items: center;
                                gap: 8px;

                                @media only screen and (max-width:"767px") {
                                    display: none;
                                }

                                .hover-option-box-alignment {
                                    background: rgba(30, 36, 50, 0.6);
                                    border-radius: 8px;
                                    padding: 4px;
                                    width: 32px;
                                    height: 32px;
                                    cursor: pointer;
                                }
                            }

                            .hover-details-other-details-alignment {
                                left: 16px;
                                bottom: 16px;
                                position: absolute;

                                h5 {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 600;
                                    font-size: 20px;
                                    line-height: 30px;
                                    color: #F8F8F8;
                                    max-width: 203px;

                                    @media only screen and (max-width:"767px") {
                                        font-size: 16px;
                                        line-height: 24px;
                                        max-width: 100%;
                                    }
                                }

                                .price-details-alignment {
                                    display: flex;
                                    align-items: center;
                                    gap: 7px;
                                    padding: 4px 0 0 0;

                                    @media only screen and (max-width:"767px") {
                                        display: none;
                                    }

                                    .price-tag-alignment {
                                        display: flex;
                                        align-items: center;
                                        gap: 2px;

                                        p {
                                            font-family: 'Poppins', sans-serif;
                                            font-weight: 600;
                                            font-size: 18px;
                                            line-height: 27px;
                                            color: #F8F8F8;
                                        }

                                        del {
                                            font-family: 'Poppins', sans-serif;
                                            font-weight: 500;
                                            font-size: 12px;
                                            line-height: 18px;
                                            color: rgba(#ffffff, 0.6);
                                        }
                                    }

                                    .offer-tag-alignment {
                                        background: #F1EFB0;
                                        border-radius: 11px;
                                        padding: 2px 6px;

                                        span {
                                            font-family: 'Poppins', sans-serif;
                                            font-weight: 500;
                                            font-size: 12px;
                                            line-height: 18px;
                                            color: #474400;
                                        }
                                    }
                                }

                                .review-rate-alignment {
                                    display: flex;
                                    align-items: center;
                                    gap: 8px;
                                    padding: 8px 0 0 0;

                                    @media only screen and (max-width:"767px") {
                                        display: none;
                                    }

                                    .rate-alignment {
                                        display: flex;
                                        align-items: center;
                                        gap: 5px;

                                        p {
                                            font-family: 'Poppins', sans-serif;
                                            font-weight: 600;
                                            font-size: 16px;
                                            line-height: 24px;
                                            color: #F8F8F8;
                                        }

                                        .rate-star-alignment {
                                            display: flex;
                                            align-items: center;
                                            gap: 4px;
                                        }
                                    }

                                    .review-number-alignment {
                                        span {
                                            font-family: 'Poppins', sans-serif;
                                            font-weight: 400;
                                            font-size: 16px;
                                            line-height: 24px;
                                            color: #F8F8F8;
                                        }
                                    }
                                }

                                .hover-hike {
                                    display: flex;
                                    align-items: center;
                                    gap: 5px;
                                    display: none;

                                    @media only screen and (max-width:"767px") {
                                        display: flex;
                                    }

                                    .inter {
                                        font-family: 'Poppins', sans-serif;
                                        font-weight: 600;
                                        font-size: 13px;
                                        line-height: 20px;
                                        color: #ffffff;
                                        display: flex;
                                        align-items: center;
                                        gap: 7px;

                                        img {
                                            max-width: 100%;
                                            height: auto;
                                            border-radius: 0;
                                        }
                                    }

                                    .incris {
                                        font-family: 'Poppins', sans-serif;
                                        font-weight: 600;
                                        font-size: 13px;
                                        line-height: 20px;
                                        color: #70C217;
                                        display: flex;
                                        align-items: center;

                                        img {
                                            margin-right: 3px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .hover-card-details-alignment {
                        display: none;
                    }
                }
            }
        }
    }
}