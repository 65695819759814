.share-with-friend-modal-alignment {
  .share-with-friend-modal-header-alignment {
    border-bottom: 1px solid #eaecef;

    .share-with-friend-modal-click-drop-alignment {
      padding: 8px 0 16px 0;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        width: 33px;
        height: 3px;
        background: #e4e4e8;
        border-radius: 1.5px;
      }
    }

    .share-with-friend-modal-heading-alignment {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 14px 16px 14px 16px;

      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #495057;
      }

      a {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--primary-500);
      }
    }
  }

  .share-with-friend-modal-body-alignment {
    padding: 14px 0 0 0;
    // height: calc(100vh - 322px);
    // max-height: calc(100vh - 322px);
    // overflow-y: auto;

    .friend-search-alignment {
      background: #f8f8f8;

      input {
        background: #f8f8f8;
      }
    }

    .share-with-friend-invite-modal {
      height: calc(100vh - 550px);
      overflow-y: auto;

      img {
        &.object-cover {
          width: 36px;
          height: 36px;
          min-width: 36px;
          max-width: 36px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .ant-radio-checked::after {
        border: none;
      }
      .ant-radio {
        display: flex;
      }
    }

    .share-with-friend-type-alignment {
      padding: 16px;

      .share-with-friend-search-input-alignment {
        width: 100%;
        height: 40px;
        padding: 8px;
        background: #fafafb;
        border: 1px solid #e4e4e8;
        border-radius: 10px;
        display: flex;
        align-items: center;
        gap: 8px;

        input {
          width: 100%;
          height: 100%;
          background: transparent;
          border: 1px solid none;
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;

          &::placeholder {
            color: #acb1c0;
          }
        }
      }
    }
  }

  .share-with-friend-footer-alignment {
    border-top: 1px solid #f1f1f5;
    padding: 16px 16px 50px 16px;

    button {
      background: var(--primary-500);
      border-radius: 10px;
      width: 100%;
      height: 44px;
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #f8f8f8;
      cursor: pointer;
      &.close {
        opacity: 0.5;
        font-weight: 400;
      }
    }
  }
}
