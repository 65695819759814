.fileUploadContainer {
  width: 100%;
  padding: 1rem;
}

.uploadSection {
  position: relative;
  width: 100%;
}

.previewGrid {
  display: grid;
  gap: 8px;
  width: 100%;
  margin: 0 0 16px 0;
  grid-template-columns: repeat(12, 1fr);
}

.previewGridTwo {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  gap: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
  aspect-ratio: 2/1;
  max-height: 400px;
}

.previewGridFour {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 8px;
  width: 100%;
}

.previewGrid > div:first-child:nth-last-child(2),
.previewGrid > div:last-child:nth-last-child(1):nth-child(2) {
  grid-column: span 6;
  height: 300px;
}

.previewGrid > div:only-child {
  grid-column: span 12;
  height: 400px;
}

.previewGrid > div:first-child:nth-last-child(3) {
  grid-column: span 12;
  height: 300px;
}

.previewGrid > div:not(:first-child):nth-last-child(2),
.previewGrid > div:last-child:nth-last-child(1):nth-child(3) {
  grid-column: span 6;
  height: 150px;
}

.previewGrid > div:nth-child(1):nth-last-child(4),
.previewGrid > div:nth-child(2):nth-last-child(3) {
  grid-column: span 6;
  height: 300px;
}

.previewGrid > div:nth-child(3):nth-last-child(2),
.previewGrid > div:nth-child(4):nth-last-child(1) {
  grid-column: span 6;
  height: 150px;
}

.previewGrid > div:nth-child(1):nth-last-child(5),
.previewGrid > div:nth-child(2):nth-last-child(4) {
  grid-column: span 6;
  height: 300px;
}

.previewGrid > div:nth-child(3):nth-last-child(3),
.previewGrid > div:nth-child(4):nth-last-child(2),
.previewGrid > div:nth-child(5):nth-last-child(1) {
  grid-column: span 4;
  height: 150px;
}

.previewGridFour > div:nth-child(1),
.previewGridFour > div:nth-child(2) {
  grid-column: span 6;
  height: 300px;
}

.previewGridFour > div:nth-child(3),
.previewGridFour > div:nth-child(4) {
  grid-column: span 6;
  height: 150px;
}

.previewItem {
  position: relative;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.previewItem img,
.previewItem video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.removeButton {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  z-index: 1;
}

.lastItem {
  position: relative;
}

.counter {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  border-radius: 8px;
}

.uploadButton {
  cursor: pointer;
  padding: 1rem;
  border: 2px dashed #e5e7eb;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;
}

.hiddenInput {
  display: none;
}

.progressBar {
  margin: 1rem 0;
}

/* Mobile styles */
.fileUploadContainerMobile {
  width: 100%;
}

.fileUploadDrag {
  border: 2px dashed #e5e7eb;
  border-radius: 0.5rem;
  padding: 2rem;
  text-align: center;
}

.fileUploadDragBox {
  position: relative;
}

.fileUploadDragBox input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.fileUploadDragBox p {
  margin: 0.5rem 0;
}

.fileUploadDragBox button {
  background-color: #4f46e5;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  border: none;
  cursor: pointer;
  font-size: 0.875rem;
}

.fileUploadDragBox button:hover {
  background-color: #4338ca;
}
