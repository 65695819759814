.inspiration-influencer-modal-details-alignment {
  @media only screen and (max-width:"767px") {
    background: #fff;
  }

  .mobile-view-book-appliance-back-alignment {
    display: none;

    @media only screen and (max-width:"767px") {
      display: flex;
      align-items: center;
      gap: 18px;
      padding: 8px 16px;
      background: rgba(248, 248, 248, 0.92);
      backdrop-filter: blur(13.5914px);

      .book-appliance-back_details-alignment {
        background: #ffffff;
        border-radius: 10px;
        padding: 4px 9px 4px 10px;
        display: flex;
        align-items: center;
        gap: 8px;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #808191;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 58px;
        }
      }

      .mobile-heading-alignment {
        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          text-align: center;
          color: var(--secondary-900);
        }
      }
    }
  }

  .inspiration-influencer-modal-banner-alignment {
    width: 100%;
    padding: 32px 32px 0 32px;
    // height: 178px;
    // position: relative;

    // @media only screen and (max-width:"767px") {
    //   height: 100px;
    // }

    img {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 8px;

      @media only screen and (max-width:"767px") {
        display: none;
      }

      &.mobile-view-banner {
        display: none;

        @media only screen and (max-width:"767px") {
          display: block;
        }
      }
    }

    .inspiration-influencer-modal-banner-details {
      // position: absolute;
      // top: 48px;
      // left: 48px;

      // @media only screen and (max-width:"767px") {
      //   top: 20px;
      //   left: 16px;
      // }

      h4 {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        margin: 0;
      }

      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--secondary-900);

        @media only screen and (max-width:"767px") {
          font-size: 12px;
          line-height: 18px;
          max-width: 165px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .inspiration-influencer-details-alignment {
    padding: 8px 45px;

    @media only screen and (max-width:"899px") {
      // grid-template-columns: repeat(2, 1fr);
      padding: 8px 20px;
    }

    .inspiration-influencer-grid-alignment {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 32px 64px;
      padding: 32px 0;

      @media only screen and (max-width:"1299px") {
        grid-template-columns: repeat(3, 1fr);
      }

      @media only screen and (max-width:"899px") {
        grid-template-columns: repeat(2, 1fr);
        gap: 32px 16px;
      }

      .inspiration-influencer-gridItem-alignment {
        .inspirational-influencers-enthusiats-details {
          .image-center-alignment {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 0 16px 0;

            img {
              width: 100%;
              height: 200px;
              border-radius: 50%;
              object-fit: cover;
              display: block;

              @media only screen and (max-width: '768px') {
                width: 140px;
                height: 140px;
              }
            }
          }

          .line-clamp {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 22px;
            line-height: 33px;
            color: #000000;
            text-align: center;
            cursor: pointer;

            @media only screen and (max-width: 799px) {
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
            }
          }

          p {
            margin: 0 0 4px 0;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #495057;

            @media only screen and (max-width: 799px) {
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              margin: 0 0 8px 0;
            }
          }

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            display: block;
            color: #495057;

            @media only screen and (max-width: '768px') {
              font-size: 12px;
              line-height: 18px;
            }
          }

          .button-center-alignment {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px 0 0 0;

            button {
              border: none;
              cursor: pointer;
              padding: 6px 47px;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              background: var(--primary-500);
              border-radius: 8px;
              color: #fff;
            }

            .button-follow {
              border: none;
              cursor: pointer;
              padding: 6px 47px;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              background: #e4e4e8;
              border-radius: 8px;
              color: var(--primary-500);
            }
          }

          .button-spcebet-alignment {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;
            padding: 16px 0 0 0;

            // flex-wrap: wrap;

            @media only screen and (max-width: '768px') {
              justify-content: center;
              flex-wrap: wrap;
            }

            .button-deactive {
              border: 1px solid #556ee6;
              cursor: pointer;
              padding: 6px 20px;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              background: #fff;
              border-radius: 8px;
              color: var(--primary-500);
            }

            .button-active {
              border: none;
              cursor: pointer;
              padding: 6px 20px;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              background: var(--primary-500);
              border-radius: 8px;
              color: #fff;
            }
          }
        }
      }
    }
  }
}