.mobile-message-selected-alignment-Overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 65px);
  // background: rgba(255, 255, 255, 0.3);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  z-index: 9999999 !important;
  display: none;
  backdrop-filter: blur(7px);
  background: rgb(255 255 255 / 58%);

  @media only screen and (max-width: '767px') {
    display: block;
  }
}

.mobile-message-selected-alignment-Overlay.open {
  opacity: 1;
  visibility: visible;

  @media only screen and (max-width: '767px') {
    display: block;
  }
}

.mobile-message-selected-alignment--modal {
  position: fixed;
  right: 0;
  bottom: 58px;
  width: 100%;
  translate: 0 100%;
  transition: 0.3s;
  overflow-y: auto;
  z-index: 999999999;
  display: none;

  .mobile-view-reply-story-rection-alignment {
    .mobile-view-reply-story-rection-grid {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      gap: 6px;
      padding: 39px 16px 0 16px;

      .mobile-view-reply-rection-box-alignment {
        width: 100%;
        height: 44px;
        background: #ffffff;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
        border-radius: 22px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
}

.more-option-modal-body-details-alignment {
  padding: 16px;
  // margin: 16px 0 0 0;
  height: 122px;
  background: #ffffff;
  // box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.25);
  border-radius: 8px 8px 0px 0px;

  .more-option-modal-grid-alignment {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    .more-option-modal-gridItem-alignment {
      .more-option-modal-box-alignment {
        width: 100%;
        height: 64px;
        background: #f8f8f8;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &:hover {
          background: var(--primary-50);
          border-radius: 4px;

          svg {
            path {
              stroke: #1e2432;
            }
          }

          p {
            color: var(--secondary-900);
          }
        }

        &.active-message-selected {
          background: var(--primary-50);
          border-radius: 4px;

          svg {
            path {
              stroke: #1e2432;
            }
          }

          p {
            color: var(--secondary-900);
          }
        }

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: var(--secondary-600);

          &.delete-details {
            color: #e71616;
          }
        }
      }
    }
  }
}

.mobile-message-selected-alignment--modal.open {
  translate: 0;
  z-index: 99999999;
  transform: translate(0, 6%);

  @media only screen and (max-width: '767px') {
    display: block;
  }
}