.fashionEnthusiasts-section {
  background: #ffffff;
  height: calc(100vh - 64px);
  // height: calc(100vh - 104px);
  overflow-y: auto;

  @media only screen and (max-width: '1099px') {
    height: 100%;
  }

  .fashionEnthusiasts-back-alignment {
    display: flex;
    align-items: center;
    gap: 22px;
    padding: 8px 10px;
    background: rgba(248, 248, 248, 0.92);
    display: none;

    @media only screen and (max-width: '767px') {
      display: flex;
      position: sticky;
      top: 0;
      z-index: 999;
    }

    .back_details-alignment {
      background: #ffffff;
      border-radius: 10px;
      padding: 4px 9px 4px 10px;
      display: flex;
      align-items: center;
      gap: 8px;

      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #808191;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 58px;
      }
    }

    .mobile-heading-alignment {
      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: var(--secondary-900);
      }
    }
  }

  .fashionEnthusiasts-banner {
    background: #f9f9f9;
    // background-image: url("../../../assets/img/enthoumBg.svg");
    // width: 100%;
    // height: 100%;
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: center;

    position: sticky;
    top: 0px;
    z-index: 99;

    @media only screen and (max-width: '1199px') {
      top: 0;
    }

    @media only screen and (max-width: '767px') {
      // background-image: url("../../../assets/img/MobilefashionEnthem.svg");
      position: sticky;
      top: 48px;
      z-index: 99;
    }

    .fashionEnthusiasts-banner-img-alignment {
      position: absolute;
      top: 0;
      right: 0;

      img {
        width: 100%;
        height: 178px;
        object-fit: cover;
        object-position: center;
        display: block;

        @media only screen and (max-width: '767px') {
          display: none;
        }

        &.mobile-view-banner {
          display: none;

          @media only screen and (max-width: '767px') {
            display: block;
            height: 100px;
            display: block;
          }
        }
      }
    }

    .container {
      max-width: 1440px;
      margin: auto;
      padding: 0 20px;

      @media only screen and (max-width: '767px') {
        max-width: 100%;
        width: 100%;
      }

      div {
        img {
          cursor: pointer;
          display: block;
        }
      }

      .fashionEnthusiasts-banner-section {
        height: 178px;
        display: flex;
        align-items: center;
        gap: 160.5px;
        position: relative;

        @media only screen and (max-width: '1024px') {
          padding: 0 20px;
          gap: 80px;
        }

        @media only screen and (max-width: '767px') {
          height: 100px;
        }

        .left-arrow-alignment {
          @media only screen and (max-width: '767px') {
            display: none;
          }
        }
      }

      .fashionEnthusiasts-heading-alignment {
        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 300;
          font-size: 40px;
          line-height: 60px;
          color: #000000;
          padding: 0 0 4px 0;

          @media only screen and (max-width: '767px') {
            font-size: 20px;
            line-height: 30px;
          }
        }

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 300;
          font-size: 18px;
          line-height: 27px;
          color: var(--secondary-900);

          @media only screen and (max-width: '767px') {
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 200px;
          }
        }
      }
    }
  }

  .fashionEnthusiasts-details-list-section {
    background: #ffffff;

    @media only screen and (max-width: '1024px') {
      height: 100%;
      overflow-y: auto;
    }

    .container {
      @media only screen and (max-width: '767px') {
        max-width: 100%;
        width: 100%;
        padding: 0;
      }
    }

    .suggested-frieds-all-content-alignment {

      .grid {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 64px;
        max-height: 100%;

        @media only screen and (max-width: 1339px) {
          grid-template-columns: repeat(4, 1fr);
        }

        @media only screen and (max-width: 999px) {
          grid-template-columns: repeat(3, 1fr);
        }

        @media only screen and (max-width: 767px) {
          grid-template-columns: repeat(2, 1fr);
          gap: 16px;
          padding: 16px 0;
        }
      }
    }
  }
}