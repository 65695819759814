.mobileViewHotWatchesModal {
  .mobileViewHeading {
    padding: 16px;
    position: sticky;
    top: 28px;
    background: var(--white);
    left: 0;
    z-index: 999;
    h6 {
      color: var(--secondary-900);
      font-size: 20px;
      font-weight: 600;
      line-height: 14px;
    }
  }

  .mobileViewBodyAlignment {
    padding: 16px 24px;

    .mobileViewBannerAlignment {
      width: 100%;
      height: 45px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        border-radius: 8px 8px 0px 0px;
      }
    }

    .mobileViewHotWatchesDetailsAlignment {
      padding: 16px 14px;

      .mobileViewHotWatchesGrid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
      }
    }
  }
}
