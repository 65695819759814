.marketplaceHeader {
  padding: 10px 0 10px 0;
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: #fff;
  border-bottom: 1px solid #d8d8d8;
  @media (max-width: 576px) {
    padding: 16px 4px;
  }

  .mobilemenu {
    display: none;

    @media (max-width: 576px) {
      display: block;
    }
  }

  .headerAlignment {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      img {
        cursor: pointer;
        max-width: 142px;

        @media (max-width: 576px) {
          max-width: 153px;
        }
      }

      span {
        color: #7a7e88;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        padding-left: 30px;
        padding-top: 2px;
        display: block;
      }
    }

    .buttonAlignment {
      display: flex;
      align-items: center;
      gap: 30px;

      @media (max-width: 576px) {
        display: none;
      }

      p {
        span {
          cursor: pointer;
        }
      }

      a {
        display: block;
        color: #424242;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      button {
        border: none;
        color: #fff;
        font-size: 16px;
        border-radius: 50px;
        background: var(--Principal-Blue, linear-gradient(0deg, #3171de 0%, #3171de 100%));
        padding: 6px 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .menu {
      padding: 10px 40px;
      border-radius: 50px;
      background: #eef1fc;
      display: flex;
      align-items: center;
      gap: 56px;
      position: relative;

      @media (max-width: 768px) {
        padding: 10px 32px;
        gap: 24px;
      }

      .dropdown {
        position: absolute;
        top: 50px;
        left: 0;
        width: 524px;
        background-color: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 6px;
        max-height: 0px;
        overflow: hidden;
        visibility: hidden;
        transition: 0.3s ease-in-out;
        @media (max-width: 768px) {
          width: 425px;
          left: 50%;
          transform: translateX(-50%);
        }
        .spacerAlignment {
          .bodyAlignment {
            padding: 15px 38px 15px 80px;
            @media (max-width: 768px) {
              padding: 20px;
            }
            .boxdesign {
              padding: 24px 16px;
              border-radius: 8px;
              transition: 0.3s ease-in-out;

              &:hover {
                background-color: rgba(85, 110, 230, 0.13);
              }

              h6 {
                color: #6f6c90;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
              }

              p {
                color: #1e2432;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
              }
            }
          }

          .launchHeader {
            padding: 56px 35px 14px 35px;
            border-bottom: 1px solid #d9dbe9;
            @media (max-width: 768px) {
              padding: 20px;
            }
            p {
              color: var(--Grey2, #808191);
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              text-align: left;
            }
          }
        }
      }

      @media (max-width: 576px) {
        display: none;
      }

      a {
        &:hover {
          .dropdown {
            max-height: 100vh;
            transition: 0.3s ease-in-out;
            overflow: hidden;
            visibility: visible;
          }
        }
      }

      span {
        &:hover {
          &::before {
            opacity: 1;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
          }
        }

        &::before {
          background: #6b21a8;
          bottom: -5px;
          content: '';
          height: 2px;
          left: 0;
          opacity: 0;
          position: absolute;
          -webkit-transform: scaleX(0);
          transform: scaleX(0);
          -webkit-transform-origin: left;
          transform-origin: left;
          transition: opacity 0.3s ease-out, -webkit-transform 0.3s ease-out;
          transition: transform 0.3s ease-out, opacity 0.3s ease-out;
          transition: transform 0.3s ease-out, opacity 0.3s ease-out, -webkit-transform 0.3s ease-out;
          width: 100%;
        }
      }

      a,
      span {
        display: block;
        color: #3d3d3d;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        position: relative;
        transition: 0.3s ease-in-out;
      }
    }
  }
}

.mobileheader {
  width: 300px;
  background-color: #fff;
  position: fixed;
  left: 0;
  z-index: 999;
  top: 81px;

  &.hide {
    max-height: 0px;
    overflow: hidden;
    transition: 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  }

  &.show {
    max-height: 100vh;
    overflow: hidden;
    transition: 0.3s cubic-bezier(1, 0, 0, 1);
  }

  .menudesign {
    border-bottom: 1px solid #d8d8d8;

    .childMenu {
      padding: 0 12px;
      &.hide {
        max-height: 0px;
        overflow: hidden;
        transition: 0.3s cubic-bezier(0.19, 1, 0.22, 1);
      }

      &.show {
        max-height: 100vh;
        overflow: hidden;
        transition: 0.3s cubic-bezier(1, 0, 0, 1);
      }

      .childboxDesign {
        padding: 12px 20px;
        border-radius: 10px;
        transition: 0.3s ease-in-out;

        h6 {
          color: #6f6c90;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 21.327px;
          /* 213.274% */
        }

        p {
          color: #1e2432;
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        &:hover {
          background: rgba(85, 110, 230, 0.13);
        }
      }
    }

    span {
      display: block;
      color: #808191;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      padding: 16px;
      line-height: normal;
    }
  }

  .buttonalignmentMobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;

    a {
      color: #808191;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    button {
      border: none;
      color: #fff;
      font-size: 11.768px;
      font-style: normal;
      border-radius: 36.774px;
      background: var(--Principal-Blue, linear-gradient(0deg, #3171de 0%, #3171de 100%));
      padding: 10px 18px;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
    }
  }
}

.headerBlur {
  position: fixed;
  top: 81px;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgb(0, 0, 0, 0.5);
}
