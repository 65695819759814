.Showmorebuttonmain {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 40px;
    background-color: #556ee6;
    border-radius: 16px;
    color: #fff;
    transform: translateY(0);
    transition: all 0.2s ease-in-out;

    p {
      font-family: 'Inter';
      font-size: 16px;
      font-weight: 500;
      line-height: 19.36px;
      text-align: center;
    }

    &:hover {
      transition: all 0.2s ease-in-out;
      transform: translateY(-5px);
      padding: 9 42px;
    }
  }
}
