.apperince-banner-setion {
  .apperince-banner-img-alignment {
    width: 100%;
    height: 706px;
    position: relative;

    @media only screen and (max-width: '899px') {
      height: 420px;
    }

    @media only screen and (max-width: '767px') {
      height: 375px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      background: linear-gradient(90deg, #000000 0%, rgba(0, 11, 52, 0) 58.79%);
      width: 100%;
      height: 100%;

      @media only screen and (max-width: '767px') {
        background: linear-gradient(360deg, #000000 0%, rgba(0, 11, 52, 0) 100%);
      }
    }

    .apperince-banner-details-alignment {
      position: absolute;
      bottom: 185px;
      left: 167px;
      max-width: 430px;

      @media only screen and (max-width: '899px') {
        left: 130px;
        bottom: 78px;
      }

      @media only screen and (max-width: '767px') {
        left: 32px;
        bottom: 52px;
        max-width: 201px;
      }

      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 40px;
        line-height: 60px;
        color: #ffffff;

        @media only screen and (max-width: '767px') {
          font-size: 18px;
          line-height: 27px;
        }
      }

      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;

        @media only screen and (max-width: '767px') {
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          padding: 12px 0 0 0;
        }
      }

      .apperince-shop-now-button-alignment {
        padding: 16px 0 0 0;

        @media only screen and (max-width: '767px') {
          display: none;
        }

        button {
          background: #ffffff;
          border-radius: 10px;
          width: 178px;
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          // background: linear-gradient(94.09deg, #556EE6 0.21%, #6B21A8 100%);
          // -webkit-background-clip: text;
          // -webkit-text-fill-color: transparent;
          // background-clip: text;
          // text-fill-color: transparent;
          color: var(--primary-500);
          cursor: pointer;
        }
      }
    }
  }

  .slick-arrow {
    display: none !important;
  }

  .slick-dots {
    top: 92% !important;
    right: 0;
    left: 0;

    li {
      button {
        &::before {
          width: 6px;
          height: 4px;
          background: #fff;
          border-radius: 2px;
        }
      }

      &.slick-active {
        width: 34px;

        button {
          &::before {
            width: 34px;
            height: 4px;
            background: #ffe500;
            border-radius: 2px;
          }
        }
      }
    }
  }
}

.book-appliance-section {
  // background: #FFFFFF;
  // @media only screen and (max-width: '767px') {
  //   padding: 0;
  // }
  max-width: 1334px;
  background: rgb(255, 255, 255);
  margin: 0 auto;
  border: 2px solid rgb(238, 241, 253);
  border-radius: 16px;
  padding: 13px 17px;
  height: 100%;
  overflow-x: hidden;
  position: relative;

  @media (max-width: 576px) {
    padding: 0;
    border: none;
    border-radius: 0;
  }
}