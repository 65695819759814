.checkout-page-container {
  // overflow: hidden !important;
  // max-width: 1300px;
  // width: 1300px;
  // height: 850px;
  height: calc(100vh - 96px);
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #e4e4e8;
  border-radius: 10px;
  // margin-top: 28px;
  padding: 0 22px 0 22px;
  overflow-y: auto;

  // margin: 0 0 16px 0;

  @media only screen and (max-width: '1024px') {
    background: transparent;
    margin: 0;
    // border: none;
  }

  .checkout-main-title {
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 24px 0;
    position: sticky;
    top: 0;
    z-index: 999;
    background: #ffffff;

    @media (max-width: 1024px) {
      display: none;
    }

    .checkout-main-text {
      span {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        display: flex;
        align-items: center;
        color: #000000;
      }
    }
  }

  .grid-container-box {
    display: grid;
    // grid-template-columns: 898px 1fr;
    // grid-template-columns: 72% 1fr;
    grid-template-columns: 1fr 334px;
    gap: 20px;

    @media only screen and (max-width: '1199px') {
      grid-template-columns: 1fr 286px;
    }

    @media only screen and (max-width: '768px') {
      padding: 0 0 106px 0;
    }

    .item-shipping-grid {
      @media (max-width: 768px) {
        margin: 16px 0 0 0;
      }

      .shpping-address-coupon {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;

        @media (max-width: 1024px) {
          gap: 16px;
        }

        .shipping-address-main {
          display: grid;
          row-gap: 12px;

          .shpping-address-align {
            display: flex;
            gap: 10px;
            align-items: center;

            .shpping-address-grey {
              span {
                font-weight: 600;
                font-size: 18px;
                line-height: 27px;
                display: flex;
                align-items: center;
                color: #808191;
              }
            }

            .shpping-address-icon {
              width: 24px;
              height: 24px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }

        .coupon-main {
          display: grid;
          // row-gap: 12px;

          @media only screen and (max-width: '1024px') {
            margin: 0;
          }

          .coupon-align {
            display: flex;
            gap: 10px;
            align-items: center;

            .coupon-grey {
              span {
                font-weight: 600;
                font-size: 18px;
                line-height: 27px;
                display: flex;
                align-items: center;
                color: #808191;
              }
            }

            .coupon-icon {
              width: 24px;
              height: 24px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }

    .payment-method-main {
      border-left: 1px solid #e4e4e8;
      display: grid;
      row-gap: 0;

      @media only screen and (max-width: '1024px') {
        row-gap: 4px;
      }

      .payment-bg-alignment {
        @media only screen and (max-width: '1024px') {
          background: #ffffff;
          border: 1px solid #e4e4e8;
          // padding: 12px 16px;
          margin: 0 0 0 0;
        }
      }

      .payment-align {
        display: flex;
        gap: 10px;
        align-items: center;
        margin-bottom: 17px;
        padding: 0 16px;

        @media only screen and (max-width: '1024px') {
          padding: 0;
        }

        .payment-grey {
          span {
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            display: flex;
            align-items: center;
            color: #808191;
          }
        }

        .payment-icon {
          width: 24px;
          height: 24px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .checkout-box-grid {
      display: grid;
      row-gap: 26px;
    }

    .items-align {
      display: flex;
      gap: 10px;
      align-items: center;
      padding-bottom: 16px;

      .shpping-address-grey {
        span {
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          display: flex;
          align-items: center;
          color: #808191;
        }
      }

      .shpping-address-icon {
        width: 24px;
        height: 24px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

//Shpping Address=============================================================================
.shipping-address-card-main {
  width: 436px;
  height: 276px;
  border: 1px solid #e4e4e8;
  border-radius: 10px;

  @media only screen and (max-width: '1024px') {
    height: auto;
  }

  .map-contain {
    // width: 436px;
    width: 100%;
    height: 100px;
    border-bottom: 1px solid #e4e4e8;
  }

  .card-description-part {
    padding: 16px;

    .apartment-text-align {
      cursor: context-menu;

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #808191;
      }
    }

    .name-text-align {
      margin-top: 8px;
      cursor: context-menu;

      h2 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #000000;
      }
    }

    .address-text-align {
      cursor: context-menu;

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: var(--secondary-900);
      }
    }

    .mobile-text-align {
      margin-top: 8px;
      cursor: context-menu;

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #808191;
      }
    }

    .chnage-text-align {
      margin-top: 17px;
      cursor: pointer;

      @media only screen and (max-width: '1024px') {
        display: none;
      }

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        text-align: center;
        color: var(--primary-500);
      }
    }
  }

  .marker {
    width: 20px;
    height: 20px;
    transform: translate(-50%, -50%);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 5px solid #1678c0;
    background-color: #1678c0;
    cursor: pointer;
    position: relative;
  }

  .marker::before {
    content: '';
    position: absolute;
    left: -webkit-calc(50% - 5px);
    left: -moz-calc(50% - 5px);
    left: calc(50% - 5px);
    top: -webkit-calc(100% - 1px);
    top: -moz-calc(100% - 1px);
    top: calc(100% - 1px);
    width: 10px;
    height: 10px;
    background-color: #1678c0;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.mobile-view-shipping-address-alignment {
  padding: 8px 0 0 9px;
  display: block;

  // @media only screen and (max-width: 1024px) {
  //   display: block;
  // }

  a {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: var(--primary-500);
    cursor: pointer;
  }
}

//Coupon ====================================================================================
.coupon-card-main {
  width: 436px;
  height: 276px;
  border: 1px solid #e4e4e8;
  border-radius: 10px;
  padding: 16px;

  .coupon-title-text {
    margin-bottom: 8px;

    h2 {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #000000;
    }
  }

  .ticket-align-contain {
    display: grid;
    row-gap: 8px;
    height: 146px;
    overflow: scroll;
    max-height: 146px;
    margin: 8px 0;

    .coupon-card-bg {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .coupon-absolute-text {
        position: relative;

        .coupon-size-align {
          position: relative;
          width: 326px;
          height: 65px;
        }

        .coupon-text-contain {
          width: 100%;
          position: absolute;
          top: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0px 24px;

          .coupon-text {
            span {
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: var(--secondary-900);
            }
          }

          .coupon-divide-border {
            border: 1px solid #bce095;
            width: 0px;
            height: 50px;
          }
        }
      }

      .trash-align {
        width: 32px;
        height: 32px;
        background: #ffffff;
        border: 1px solid #e4e4e8;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        svg {
          width: 20px;
          height: 20px;

          path {
            stroke: #acb1c0;
          }
        }
      }
    }
  }
  .empty-coupon-main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 146px;
    max-height: 146px;
    margin: 8px 0;
    .empty-coupon-state {
      .empty-icon-state {
        display: flex;
        justify-content: center;
        align-items: center;
        .no-coupon-text {
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            // font-weight: 400;
            // font-size: 14px;
            // line-height: 21px;
            // color: #808191;
            color: #9c6ac5;
            font-family: Poppins;
            font-size: 7.333px;
            font-style: italic;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #9c6ac5;
        font-family: Poppins;
        font-size: 12px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        padding-top: 16px;
      }
    }
  }

  .add-coupon-container {
    .add-coupon-code-align {
      padding-bottom: 8px;

      h4 {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #000000;
      }
    }

    .coupon-code-contain {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .coupon-code-input {
        height: 32px;
        display: flex;
        gap: 8px;
        align-items: center;

        .input-section {
          input {
            padding: 0px 12px;
            border: 1px solid #e4e4e8;
            border-radius: 10px;
            width: 141px;
            height: 32px;
          }
        }

        .button-box {
          width: 73px;
          height: 32px;
          background: var(--primary-500);
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          opacity: 0.5;

          button {
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            color: #f8f8f8;
          }
        }

        .active-class {
          opacity: 1;
        }
      }

      .coupon-text-align {
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          display: flex;
          align-items: center;
          color: var(--primary-500);
          cursor: pointer;
        }
      }
    }
  }
}

//Payment Method ============================================================================
.payment-method-box-main {
  .payment-method-card-main {
    .paypal-contain {
      padding: 15px;
      display: flex;
      align-items: center;

      input {
        cursor: pointer;
      }

      img {
        margin-left: 12px;
        cursor: pointer;
      }

      h3 {
        margin-left: 12px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #495057;
        cursor: pointer;
      }
    }

    .paypal-contain:last-child {
      padding-top: 0;
    }
  }

  .card-flex-center {
    display: flex;
    justify-content: center;

    .credit-card-container {
      width: 242px;
      height: 134px;
      background-color: hsla(0, 12%, 92%, 1);
      background-image: radial-gradient(at 72% 77%, hsla(0, 100%, 84%, 1) 0px, transparent 50%),
        radial-gradient(at 9% 92%, hsla(279, 100%, 73%, 1) 0px, transparent 50%),
        radial-gradient(at 1% 2%, hsla(213, 83%, 68%, 1) 0px, transparent 50%),
        radial-gradient(at 31% 62%, hsla(279, 100%, 73%, 1) 0px, transparent 50%),
        radial-gradient(at 55% 33%, hsla(0, 100%, 84%, 1) 0px, transparent 50%);
      backdrop-filter: blur(45.5px);
      border-radius: 8px;
      padding: 10px 16px;
      border: 1px solid #556ee6;
      position: relative;

      // &::before {
      //   content: '';
      //   background: rgba(248, 248, 248, 0.01);
      //   backdrop-filter: blur(45.5px);
      //   position: absolute;
      //   width: 100%;
      //   height: 100%;
      //   top: 0;
      //   right: 0;
      //   border-radius: 8px;
      //   z-index: 9;
      //   background: linear-gradient(90.39deg, rgba(185, 133, 227, 0.4) 0.33%, rgba(224, 155, 156, 0.4) 99.66%);
      // }

      // .bg-card-alignment {
      //   position: absolute;
      //   top: 0px;
      //   right: 0px;
      //   width: 100%;
      //   height: 100%;
      //   backdrop-filter: blur(45.5px);
      //   background-image : linear-gradient(0.25turn, rgba(185, 133, 227, 0.40), rgba(224, 155, 156, 0.40));
      // }

      .bg-card-alignment-first {
        background-color: rgba(132, 0, 236, 0.4);
        width: 92px;
        height: 92px;
        border-radius: 50%;
        position: absolute;
        top: 20px;
        left: 40px;
      }

      .bg-card-alignment-second {
        background-color: rgba(234, 0, 0, 0.3);
        width: 60px;
        height: 60px;
        border-radius: 50%;
        position: absolute;
        top: 40px;
        right: 40px;
      }

      // background-color: plum;
      .credit-user-name-text {
        margin-bottom: 36px;

        h3 {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #000000;
        }
      }

      .card-expiry-main {
        display: grid;
        row-gap: 14px;

        .card-number {
          span {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: var(--secondary-900);
          }
        }

        .card-expiry-logo-main {
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: var(--secondary-900);
          }
        }
      }
    }

    .change-card-text {
      margin-top: 8px;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      align-items: center;
      text-align: center;
      color: var(--primary-500);
      cursor: pointer;
    }
  }
}

//Order Summary Section =====================================================================
.order-summary-main {
  width: 305px;
  height: 274px;
  background: #f8f8f8;
  border-radius: 10px;

  @media only screen and (max-width: '1024px') {
    margin-top: 4px;
  }

  .title-grid {
    display: grid;
    row-gap: 6px;
    padding: 11px 16px 26px 16px;

    @media only screen and (max-width: '1024px') {
      row-gap: 16px;
      padding: 11px 16px 16px 16px !important;
    }

    .order-summary-text {
      h3 {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #808191;
      }
    }

    .summary-table {
      display: grid;
      row-gap: 8px;

      .flex-class-table {
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: '1024px') {
          border-bottom: 1px solid #e4e4e8;
          padding: 0 0 4px 0;

          &:last-child {
            border-bottom: none;
          }
        }

        .summary-menu {
          span {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #808191;
            cursor: context-menu;

            @media only screen and (max-width: '1024px') {
              color: #1e2432 !important;
            }
          }
        }

        .summary-amount {
          span {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            display: flex;
            align-items: center;
            text-align: right;
            color: var(--secondary-900);
            cursor: pointer;
          }
        }
      }
    }
  }

  .flex-class-total {
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 21px 16px;
    border-top: 1px solid #e4e4e8;

    .total-menu-text {
      span {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        display: flex;
        align-items: center;
        color: #808191;
      }
    }

    .total-menu-price {
      span {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        display: flex;
        align-items: center;
        text-align: right;
        color: var(--secondary-900);
      }
    }
  }

  .mobile-view-button-alignment {
    border-top: 1px solid #e4e4e8;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: none;

    @media only screen and (max-width: '1024px') {
      display: flex;
    }

    @media only screen and (max-width: '768px') {
      position: fixed;
      width: 100%;
      background: #ffffff;
      bottom: 64px;
      left: 0;
      z-index: 999;
    }

    .mobile-view-total-price-alignment {
      span {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #495057;
        display: flex;
        align-items: center;
        gap: 4px;

        p {
          color: var(--secondary-900);
          font-weight: 700;
        }
      }
    }

    .mobile-view-perchase-button-alignment {
      button {
        background: linear-gradient(94.09deg, #556ee6 0.21%, #6b21a8 100%);
        border-radius: 10px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #ffffff;
        width: 197px;
        height: 44px;
        cursor: pointer;
      }
    }
  }
}

.margin-class-align {
  margin-left: 15px;

  @media only screen and (max-width: 1024px) {
    margin-left: 0 !important;
  }

  .button-section-align {
    padding-top: 16px;

    .button-container {
      width: 305px;
      height: 44px;
      background: var(--primary-500);
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      opacity: 1;

      @media only screen and (max-width: '1199px') {
        width: 100%;
      }

      button {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #ffffff;
      }
    }

    .opacity-button {
      opacity: 0.5;
    }

    .cancle-button-align {
      width: 305px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 16px;

      @media only screen and (max-width: '1199px') {
        width: 100%;
      }

      button {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: var(--primary-500);
      }
    }
  }
}

//Item Section =====================================================================
.items-section-main {
  // padding: 30px 0;

  .items-container-main {
    // width: 886px;
    width: 100%;
    // height: 351px;
    background: #f8f8f8;
    border: 1px solid #e4e4e8;
    border-radius: 10px 10px 0px 0px;
    border-bottom: 0;

    .items-contain-main {
      padding: 10px 16px 0 16px;

      .store-logo-align {
        width: 24px;
        height: 24px;
        display: flex;
        gap: 6px;
        margin-bottom: 8px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          border: 1px solid #ffffff;
        }

        span {
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          display: flex;
          align-items: center;
          color: var(--secondary-900);
          white-space: nowrap;
        }
      }

      .product-delivery-section {
        display: flex;
        // gap: 16px;
        display: grid;
        grid-template-columns: 1fr 370px;
        gap: 16px;

        .grid-product-section {
          height: 334px !important;
          overflow: scroll;

          .items-section-card {
            // width: 466px;
            width: 100%;
            // height: 148px;
            height: fit-content;
            background: #ffffff;
            border: 1px solid #e4e4e8;
            border-radius: 8px;
            padding: 8px;
            margin-bottom: 4px;

            .flex-product-class {
              display: flex;
              gap: 16px;

              // align-items: center;
              .product-image-contain {
                width: 85px;
                height: 105px;

                img {
                  width: 100%;
                  height: 100%;
                  border: 1px solid #e4e4e8;
                  border-radius: 8px;
                  object-fit: cover;
                }
              }

              .product-detail-grid {
                // display: grid;
                // row-gap: 6px;
                .product-title-align {
                  margin-bottom: 8px;

                  h2 {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    color: #000000;
                  }
                }

                .discription-contain {
                  display: grid;
                  row-gap: 4px;

                  .product-size-color {
                    display: flex;
                    gap: 6px;
                    align-items: center;

                    .size-text-align {
                      span {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 21px;
                        display: flex;
                        align-items: center;
                        color: #808191;
                      }

                      .size-count {
                        span {
                          padding-left: 4px;
                          font-weight: 500;
                          font-size: 14px;
                          line-height: 21px;
                          color: var(--secondary-900);
                        }
                      }
                    }

                    .color-text-align {
                      span {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 21px;
                        display: flex;
                        align-items: center;
                        color: #808191;
                      }

                      .color-count {
                        span {
                          padding-left: 4px;
                          font-weight: 500;
                          font-size: 14px;
                          line-height: 21px;
                          color: var(--secondary-900);
                        }
                      }
                    }
                  }

                  .quantity-section {
                    span {
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 21px;
                      color: #808191;
                    }

                    .quantity {
                      span {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 21px;
                        color: var(--secondary-900);
                      }
                    }
                  }

                  .store-coupon-alignment {
                    display: flex;
                    gap: 8px;
                    flex-wrap: wrap;

                    .coupon-available {
                      span {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        color: #808191;
                      }
                    }

                    .check-availabilty-section {
                      span {
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        color: var(--secondary-900);
                      }

                      .coupon-remove {
                        span {
                          font-weight: 400;
                          font-size: 14px;
                          line-height: 21px;
                          color: var(--primary-500);
                          cursor: pointer;
                        }
                      }
                    }
                  }

                  .product-price-align {
                    span {
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 21px;
                      color: #808191;
                    }
                  }
                }
              }
            }
          }
        }

        .delivery-section-main {
          .delivery-align {
            display: flex;
            gap: 10px;
            align-items: center;
            margin-bottom: 8px;

            .shpping-address-grey {
              span {
                font-weight: 600;
                font-size: 18px;
                line-height: 27px;
                display: flex;
                align-items: center;
                color: #808191;
              }
            }

            .shpping-address-icon {
              width: 24px;
              height: 24px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          .delivery-card-contain-box {
            display: grid;
            row-gap: 4px;

            .delivery-section-active {
              .delivery-section-container {
                // width: 370px;
                width: 100%;
                height: 61px;
                background: rgba(85, 110, 230, 0.05);
                border: 1px solid #556ee6;
                border-radius: 8px;
                padding: 8px 16px;

                .devi-contain-flex {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  .delivery-text-contain {
                    span {
                      font-weight: 600;
                      font-size: 16px;
                      line-height: 24px;
                      color: var(--secondary-900);
                    }

                    p {
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 21px;
                      color: var(--secondary-900);
                    }
                  }

                  .delivery-price {
                    span {
                      font-weight: 600;
                      font-size: 16px;
                      line-height: 24px;
                      color: var(--secondary-900);
                    }
                  }
                }
              }
            }

            .delivery-section {
              .delivery-section-container {
                width: 370px;
                height: 61px;
                background: rgba(85, 110, 230, 0.05);
                border: 1px solid #e4e4e8;
                border-radius: 8px;
                padding: 8px 16px;
                cursor: pointer;

                .devi-contain-flex {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  .delivery-text-contain {
                    span {
                      font-weight: 600;
                      font-size: 16px;
                      line-height: 24px;
                      color: #808191;
                    }

                    p {
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 21px;
                      color: #808191;
                    }
                  }

                  .delivery-price {
                    span {
                      font-weight: 600;
                      font-size: 16px;
                      line-height: 24px;
                      color: var(--secondary-900);
                    }
                  }
                }
              }
            }

            .active-line {
              .delivery-section-container {
                border: 1px solid #556ee6;
                border-radius: 8px;
                cursor: pointer;

                .devi-contain-flex {
                  .delivery-text-contain {
                    span {
                      font-weight: 600;
                      font-size: 16px;
                      line-height: 24px;
                      color: var(--secondary-900);
                    }

                    p {
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 21px;
                      color: var(--secondary-900);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//Add Map Modal =================================================================================
.map-modal-title {
  margin-bottom: 18px;

  h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #000000;
  }
}

.all-input-grid {
  display: grid;
  row-gap: 16px;

  .pin-location-section {
    label {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #808191;
      margin-bottom: 4px;

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #acb1c0;
      }
    }

    .map-box-contain {
      position: relative;

      .map-container {
        width: 100%;
        height: 94px;
        border: 1px solid #e4e4e8;
        border-radius: 5px;
      }

      .absolute-button-section {
        position: absolute;
        top: 34px;
        left: 222px;

        .button-background {
          width: 117px;
          height: 32px;
          background: var(--primary-500);
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;

          button {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #ffffff;
          }
        }
      }
    }
  }

  .dropdown-last-section {
    display: flex;
    gap: 8px;

    .select-section {
      label {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #808191;
      }

      .select-box-contain {
        height: 38px;

        select {
          width: 100%;
          padding: 7px 16px;
          background: #ffffff;
          border: 1px solid #556ee6;
          box-shadow: 0px 4px 20px rgba(53, 97, 183, 0.14);
          border-radius: 5px;
        }
      }
    }
  }

  .checkbox-contain {
    display: flex;
    gap: 8px;
    // .checkbox-align {
    // }
  }
}

//Select Map Modal ===========================================================================
.shipping-address-modal {
  .shipping-address-title-text {
    margin-bottom: 16px;

    h1 {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      display: flex;
      align-items: center;
      color: #000000;
    }
  }

  .card-main-grid {
    display: grid;
    row-gap: 8px;
    margin-bottom: 16px;
    height: 379px;
    overflow: scroll;
    margin-bottom: 8px;

    .address-list-container {
      // width: 100%;
      max-height: 190px;
      height: auto;
      background: #f8f8f8;
      border: 1px solid #e4e4e8;
      border-radius: 8px;
      padding: 8px 16px;

      .address-contain-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .address-default-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;

          .address-text {
            span {
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              display: flex;
              align-items: center;
              color: #808191;
              cursor: context-menu;
            }
          }

          .default-text {
            width: 64px;
            height: 20px;
            background: #e4e4e8;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: context-menu;

            span {
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              color: #808191;
            }
          }
        }

        .edit-remove-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 21px;

          .edit-text {
            span {
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              display: flex;
              align-items: center;
              color: var(--primary-500);
              cursor: pointer;
            }
          }

          .remove-text {
            span {
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              display: flex;
              align-items: center;
              color: var(--primary-500);
              cursor: pointer;
            }
          }
        }
      }

      .address-align-grid {
        display: grid;
        row-gap: 8px;
        margin-bottom: 10px;

        .apartment-text {
          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #808191;
          }
        }

        .address-text-align {
          h2 {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #000000;
          }

          h4 {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            display: flex;
            align-items: center;
            text-align: right;
            color: var(--secondary-900);
          }
        }
      }

      .user-number-text {
        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #808191;
        }
      }

      .location-pin-align {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;

        .location-icon {
          img {
            height: 100%;
            width: 100%;
          }
        }

        .location-pinned-text {
          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #808191;
          }
        }
      }
    }
  }

  .add-new-address-modal {
    width: 100%;
    height: 82px;
    border: 1px dashed #556ee6;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;

    button {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: var(--primary-500);
    }
  }

  .button-section {
    display: flex;
    justify-content: flex-end;

    .button-end {
      display: flex;
      gap: 32px;
      align-items: center;

      .cancle-button-contain {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        color: var(--primary-500);
        cursor: pointer;
      }

      .save-button-align {
        width: 184px;
        height: 44px;
        background: var(--primary-500);
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        button {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #ffffff;
        }
      }
    }
  }
}

//Select Credit card Modal ===========================================================================
.credit-card-main-modal {
  .credit-card-title-text {
    padding-bottom: 32px;

    h1 {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      display: flex;
      align-items: center;
      color: #000000;
    }
  }

  .credit-card-box-align {
    display: grid;
    grid-template-columns: repeat(2, 270px);
    width: 100%;

    .credit-card-content {
      .add-credit-card-box {
        width: 250px;
        height: 134px;
        border: 1px dashed #556ee6;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-align: center;
          color: var(--primary-500);
        }
      }
    }

    .card-flex-center {
      // display: flex;
      // justify-content: center;
      .credit-card-container {
        width: 250px;
        height: 134px;
        background-color: hsla(0, 12%, 92%, 1);
        border: 1px solid #556ee6;

        background-image: radial-gradient(at 72% 77%, hsla(0, 100%, 84%, 1) 0px, transparent 50%),
          radial-gradient(at 9% 92%, hsla(279, 100%, 73%, 1) 0px, transparent 50%),
          radial-gradient(at 1% 2%, hsla(213, 83%, 68%, 1) 0px, transparent 50%),
          radial-gradient(at 31% 62%, hsla(279, 100%, 73%, 1) 0px, transparent 50%),
          radial-gradient(at 55% 33%, hsla(0, 100%, 84%, 1) 0px, transparent 50%);
        backdrop-filter: blur(45.5px);
        border-radius: 8px;
        padding: 10px 16px;

        // background-color: plum;
        .credit-user-name-text {
          margin-bottom: 36px;

          h3 {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #000000;
          }
        }

        .card-expiry-main {
          display: grid;
          row-gap: 14px;

          .card-number {
            span {
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: var(--secondary-900);
            }
          }

          .card-expiry-logo-main {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }

      .change-card-text {
        display: flex;
        gap: 22px;
        align-items: center;
        margin-top: 8px;
        margin-bottom: 16px;

        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          display: flex;
          align-items: center;
          text-align: center;
          color: var(--primary-500);
          cursor: pointer;
        }

        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          display: flex;
          align-items: center;
          text-align: center;
          color: var(--primary-500);
          cursor: pointer;
        }
      }
    }
  }
}

//Add credit card Modal ==============================================================================
.add-payment-card-align {
  .add-payment-title-text {
    margin-bottom: 16px;

    h1 {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      display: flex;
      align-items: center;
      color: #000000;
    }
  }

  .card-number-logo-contain {
    margin-bottom: 16px;

    .card-logo-flex {
      display: flex;
      gap: 6px;
      align-items: center;
    }

    .logo-border-active {
      border: 1.5px solid #556ee6;
      border-radius: 5px;
    }
  }

  .card-input-flex {
    display: grid;
    row-gap: 16px;

    .card-input-color-contain {
      display: flex;
      gap: 8px;
      align-items: center;
      margin-bottom: 32px;

      .input-section-text {
        label {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #808191;
        }

        .input-box-contain {
          width: 258px;
          height: 38px;

          input {
            width: 100%;
            padding: 7px 16px;
            background: #ffffff;
            border: 1px solid #556ee6;
            box-shadow: 0px 4px 20px rgba(53, 97, 183, 0.14);
            border-radius: 5px;
          }
        }
      }

      .exp-cvv-color-selection {
        display: flex;
        gap: 8px;

        .input-section-text {
          label {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #808191;
          }

          .input-box-contain {
            width: 100%;
            height: 38px;

            input {
              width: 100%;
              padding: 7px 16px;
              background: #ffffff;
              border: 1px solid #556ee6;
              box-shadow: 0px 4px 20px rgba(53, 97, 183, 0.14);
              border-radius: 5px;
            }
          }

          .color-selection {
            width: 74px;
            height: 38px;
            border: 1px solid #556ee6;
            border-radius: 5px;
            // display: flex;
            // justify-content: center;
            // align-items: center;
            cursor: pointer;

            .color-selection-arrow {
              display: flex;
              gap: 14px;
              align-items: center;

              .color-round {
                width: 20px;
                height: 20px;
                background: linear-gradient(227.73deg, #47bdff 7.38%, #c7ffac 85.08%);
                border-radius: 50%;
              }
            }
          }
        }
      }
    }
  }

  .card-deatil-billing {
    display: grid;
    row-gap: 16px;
    margin-bottom: 32px;

    .billing-title {
      h2 {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        display: flex;
        align-items: center;
        color: #000000;
      }
    }

    .checkbox-contain {
      display: flex;
      gap: 8px;
    }
  }
}

//Input Design =======================================================================================
.input-section {
  label {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #808191;
  }

  .input-box-contain {
    width: 258px;
    height: 38px;

    input {
      width: 100%;
      padding: 7px 16px;
      background: #ffffff;
      border: 1px solid #556ee6;
      box-shadow: 0px 4px 20px rgba(53, 97, 183, 0.14);
      border-radius: 5px;
    }
  }
}

.dropdown-last-section {
  display: flex;
  gap: 8px;

  .select-section {
    label {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #808191;
    }

    .select-box-contain {
      height: 38px;

      select {
        width: 100%;
        padding: 7px 16px;
        background: #ffffff;
        border: 1px solid #556ee6;
        box-shadow: 0px 4px 20px rgba(53, 97, 183, 0.14);
        border-radius: 5px;
      }
    }
  }
}

.first-last-section {
  display: flex;
  // gap: 8px;
  justify-content: space-between;
}

//Button Design Common ==============================================================================
.button-section {
  display: flex;
  justify-content: flex-end;

  .button-end {
    display: flex;
    gap: 32px;
    align-items: center;

    .cancle-button-contain {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: var(--primary-500);
      cursor: pointer;
    }

    .save-button-align {
      width: 184px;
      height: 44px;
      background: var(--primary-500);
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      button {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        color: #ffffff;
      }
    }
  }
}

.mobile-view-back-and-text-alignment {
  display: none;
  padding: 8px;

  .text-button-alignment {
    position: relative;

    .back-button {
      left: 16px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      button {
        padding: 4px 10px;
        background: #f8f8f8;
        border-radius: 10px;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #808191;
        border: none;
        cursor: pointer;
      }
    }

    p {
      font-weight: 600;
      font-size: 20px;
      margin: 0;
      line-height: 16px;
      color: #1b1d21;
      text-align: center;
    }
  }
}

//Modal Radious Common ==============================================================================
.ant-modal-content {
  border-radius: 10px;
}

//All Coupon Section ================================================================================

.view-all-coupon-align {
  .shipping-address-title-text {
    margin-bottom: 16px;

    h1 {
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      display: flex;
      align-items: center;
      color: #000000;
    }
  }

  .select-coupon-contain {
    display: grid;
    row-gap: 8px;
    margin-bottom: 16px;
    height: 544px;
    overflow: scroll;

    .select-coupon-card-modal {
      display: flex;
      gap: 3px;
      align-items: center;

      .coupon-absolute-text-view {
        position: relative;

        .coupon-size-align-view {
          position: relative;
          width: 429px;
          height: 84px;

          svg {
            width: 100%;
            height: 100%;
          }
        }

        .coupon-text-contain-view {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0px;
          display: flex;
          // justify-content: space-between;
          gap: 10px;
          align-items: center;
          padding: 0px 24px;

          .coupon-text-view {
            span {
              font-weight: 600;
              font-size: 24px;
              line-height: 36px;
              display: flex;
              align-items: center;
              color: #70c217;
            }
          }

          .coupon-divide-border-view {
            border: 1px solid #bce095;
            width: 0px;
            height: 71px;
          }

          .coupon-right-text-view {
            display: grid;
            row-gap: 13px;

            span {
              font-weight: 500;
              font-size: 20px;
              line-height: 30px;
              display: flex;
              align-items: center;
              color: #495057;
            }

            p {
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              display: flex;
              align-items: center;
              color: #808191;
            }
          }
        }
      }

      .trash-align-view {
        width: 100%;
        height: 84px;
        background: #ffffff;
        border: 1px solid #e4e4e8;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        button {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-align: center;
          color: var(--primary-500);
        }
      }
    }
  }
}

.horulyRateAlignment {
  position: relative;

  .rateBox {
    // width: 100%;
    // height: 48px;
    cursor: pointer;
    align-items: center;
    // padding: 0 20px;
    border-radius: 10px;
    display: flex;
    position: relative;
    justify-content: center;
    gap: 20px;
    padding: 9px;
    // gap: 14px;

    div:first-child {
      align-items: center;

      img {
        margin-right: 6px;
      }
    }

    div {
      span {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
        line-height: 21px;
        color: #030f21;
        display: block;
      }
    }

    .color-round {
      width: 20px;
      height: 20px;
      background: linear-gradient(227.73deg, #47bdff 7.38%, #c7ffac 85.08%);
      border-radius: 50%;
    }
  }

  .hourlyRateDropdownDesign {
    position: absolute;
    z-index: 9999;
    width: 100%;
    border-radius: 5px;

    .hourlyRateDropdownDesignAlignment {
      border: 1px solid #e7e7e7;
      border-radius: 5px;
      background-color: #ffffff;
      padding: 0 30px 0 0px;

      // a {
      //   font-size: 14px;
      //   line-height: 18px;
      //   font-weight: 600;
      //   letter-spacing: -0.03em;
      //   color: #030f21;
      //   display: block;
      //   cursor: pointer;
      //   padding: 10px;
      //   border-bottom: 1px solid #e7e7e7;
      // }
      .color-round {
        width: 20px;
        height: 20px;
        background: linear-gradient(227.73deg, #47bdff 7.38%, #c7ffac 85.08%);
        border-radius: 50%;
        display: block;
        cursor: pointer;
        margin: 10px;
        border-bottom: 1px solid #e7e7e7;
      }
    }
  }

  .dropdownHidden {
    max-height: 0px;
    overflow: hidden;
    transition: 0.3s ease-in-out;
  }

  .dropdownShow {
    max-height: 250px;
    overflow: auto;
    overflow-x: hidden;
    transition: 0.3s ease-in-out;
    background-color: #ffffff;
  }
}

.mobile-view-all-content-alignment-for-page {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .mobile-view-icon-show {
    display: none;
  }
}

@media (max-width: 1024px) {
  .checkout-page-container .grid-container-box {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin: 20px;
  }

  .mobile-view-back-and-text-alignment {
    display: block;
  }

  .mobile-content-hidden {
    max-height: 0px;
    overflow: hidden;
    transition: 0.3s ease-in-out;
  }

  .mobile-content-show {
    transition: 0.3s ease-in-out;
    max-height: calc(100vh - 0px);
    overflow: auto;
  }

  .shipping-address-card-main .map-contain {
    width: 100%;
  }

  .mobile-view-all-content-alignment-for-page {
    .mobile-view-icon-show {
      display: block;
    }
  }

  .shipping-address-card-main {
    width: 100%;
    background: #f8f8f8;
    border: 1px solid #e4e4e8;
    border-radius: 8px;
  }

  .checkout-page-container {
    height: auto;
    margin: 20px 0 0 0;
    padding: 16px;

    @media only screen and (max-width: '1024px') {
      margin: 0;
      padding: 0;
      border: none;
    }
  }

  .checkout-page-container .grid-container-box .payment-method-main {
    margin-bottom: 22px;
  }

  .checkout-page-container .grid-container-box .payment-method-main .payment-align {
    margin-bottom: 0px;
  }

  .order-summary-main .title-grid {
    margin-top: 20px;
  }

  .coupon-main {
    padding: 20px 0 0 0;
  }

  .coupon-card-main .add-coupon-container .add-coupon-code-align h4 {
    font-weight: 500;
  }

  .coupon-code-contain {
    display: block !important;
  }

  .coupon-card-main .add-coupon-container .coupon-code-contain .coupon-code-input .input-section input {
    width: 100%;
  }

  .checkout-main-title {
    margin-bottom: 0 !important;
  }

  .checkout-page-container
    .grid-container-box
    .item-shipping-grid
    .shpping-address-coupon
    .shipping-address-main
    .shpping-address-align {
    // margin: 0 0 8px 0;
  }

  .checkout-page-container .grid-container-box .items-section-main {
    margin-top: 16px;
    padding: 0;
    display: grid;
    // row-gap: 12px;

    @media only screen and (max-width: '1024px') {
      margin-top: 16px !important;
      background: #ffffff;
    }
  }

  .checkout-page-container .grid-container-box .items-align {
    padding: 0 0 0px 0;
  }

  .items-section-main .items-container-main {
    width: 100%;
    height: auto;
  }

  .items-section-main .items-container-main .items-contain-main .product-delivery-section .grid-product-section {
    height: 302px !important;
  }

  .checkout-page-container .grid-container-box .payment-method-main {
    display: block;
    gap: 0;
    padding-top: 16px;
    row-gap: 0;
    padding-left: 0;
    padding-right: 0;
    border-left: none;

    @media only screen and (max-width: '1024px') {
      padding-top: 0;
      margin-top: 0 !important;
    }
  }

  .payment-method-box-main .card-flex-center .credit-card-container {
    margin-left: 35px;
  }

  .order-summary-main {
    border-radius: 0px !important;
  }

  .items-section-main .items-container-main .items-contain-main {
    padding: 16px;
  }

  .button-section-align {
    padding: 16px 16px 0 16px;

    @media only screen and (max-width: '1024px') {
      display: none;
    }
  }

  .button-section-align .button-container {
    width: 100%;
  }

  .button-section-align .cancle-button-align {
    width: 100%;
  }

  .items-section-main
    .items-container-main
    .items-contain-main
    .product-delivery-section
    .grid-product-section
    .items-section-card
    .flex-product-class
    .product-detail-grid
    .discription-contain
    .store-coupon-alignment
    .check-availabilty-section
    .coupon-remove
    span {
    padding-left: 8px;
  }

  .payment-method-box-main .card-flex-center .change-card-text {
    margin-left: 35px;
    margin-bottom: 16px;
  }

  .order-summary-main {
    width: 100%;
    height: auto;
  }

  .payment-method-box-main .card-flex-center {
    display: block;
  }

  .items-section-main
    .items-container-main
    .items-contain-main
    .product-delivery-section
    .grid-product-section
    .items-section-card {
    width: 100%;
  }

  .items-section-main
    .items-container-main
    .items-contain-main
    .product-delivery-section
    .delivery-section-main
    .delivery-card-contain-box
    .delivery-section
    .delivery-section-container
    .devi-contain-flex
    .delivery-text-contain
    span {
    font-size: 14px;
    line-height: 21px;
  }

  .items-section-main
    .items-container-main
    .items-contain-main
    .product-delivery-section
    .delivery-section-main
    .delivery-card-contain-box
    .delivery-section
    .delivery-section-container
    .devi-contain-flex
    .delivery-text-contain
    p {
    font-size: 12px;
    line-height: 18px;
  }

  .items-section-main .items-container-main .items-contain-main .product-delivery-section {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .items-section-main
    .items-container-main
    .items-contain-main
    .product-delivery-section
    .delivery-section-main
    .delivery-card-contain-box
    .delivery-section
    .delivery-section-container {
    width: 100%;
  }

  .checkout-page-container .grid-container-box .item-shipping-grid .shpping-address-coupon {
    grid-template-columns: repeat(1, 1fr);
  }

  .checkout-page-container .grid-container-box .item-shipping-grid .shpping-address-coupon .shipping-address-main {
    display: block;

    @media only screen and (max-width: '1024px') {
      background: #ffffff;
      border: 1px solid #e4e4e8;
      // padding: 8px 16px;
    }
  }

  .coupon-card-main {
    width: 100%;
    height: auto;
  }

  .items-section-main
    .items-container-main
    .items-contain-main
    .product-delivery-section
    .grid-product-section
    .items-section-card {
    height: auto;
  }

  .checkout-main-text {
    display: none;
  }

  .coupon-code-input {
    display: grid !important;
    grid-template-columns: 1fr 75px;
    gap: 16px;
  }

  .coupon-card-main .add-coupon-container .coupon-code-contain .coupon-text-align {
    padding: 16px 0 0 0;

    span {
      text-align: center;
      display: block;
    }
  }

  .items-section-main
    .items-container-main
    .items-contain-main
    .product-delivery-section
    .grid-product-section
    .items-section-card
    .flex-product-class
    .product-image-contain {
    width: 66px;
    height: 81px;
    object-fit: cover;
  }

  .items-section-main
    .items-container-main
    .items-contain-main
    .product-delivery-section
    .grid-product-section
    .items-section-card
    .flex-product-class
    .product-detail-grid
    .product-title-align
    h2 {
    font-size: 14px;
    line-height: 20px;
  }

  .items-section-main
    .items-container-main
    .items-contain-main
    .product-delivery-section
    .grid-product-section
    .items-section-card
    .flex-product-class
    .product-detail-grid
    .discription-contain
    .product-size-color
    .size-text-align
    span,
  .items-section-main
    .items-container-main
    .items-contain-main
    .product-delivery-section
    .grid-product-section
    .items-section-card
    .flex-product-class
    .product-detail-grid
    .discription-contain
    .product-size-color
    .color-text-align
    span,
  .items-section-main
    .items-container-main
    .items-contain-main
    .product-delivery-section
    .grid-product-section
    .items-section-card
    .flex-product-class
    .product-detail-grid
    .discription-contain
    .product-size-color
    .color-text-align
    .color-count
    span,
  .items-section-main
    .items-container-main
    .items-contain-main
    .product-delivery-section
    .grid-product-section
    .items-section-card
    .flex-product-class
    .product-detail-grid
    .discription-contain
    .product-size-color
    .size-text-align
    .size-count
    span,
  .items-section-main
    .items-container-main
    .items-contain-main
    .product-delivery-section
    .grid-product-section
    .items-section-card
    .flex-product-class
    .product-detail-grid
    .discription-contain
    .quantity-section
    span {
    font-size: 12px;
    line-height: 18px;
  }
}

@media (max-width: 768px) {
  .checkout-page-container .grid-container-box {
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
    margin: 0;
  }

  .mobile-view-back-and-text-alignment {
    display: block;
  }

  .mobile-content-hidden {
    max-height: 0px;
    overflow: hidden;
    transition: 0.3s ease-in-out;
  }

  .mobile-content-show {
    transition: 0.3s ease-in-out;
    max-height: calc(100vh - 0px);
    overflow: auto;
  }

  .shipping-address-card-main .map-contain {
    width: 100%;
  }

  .mobile-view-all-content-alignment-for-page {
    .mobile-view-icon-show {
      display: block;
    }
  }

  .shipping-address-card-main {
    width: 100%;
    background: #f8f8f8;
    border: 1px solid #e4e4e8;
    border-radius: 8px;
  }

  .checkout-page-container {
    height: auto;
    margin: 20px 0 0 0;
    padding: 16px;

    @media only screen and (max-width: '1024px') {
      margin: 0;
      padding: 0;
    }
  }

  .checkout-page-container .grid-container-box .payment-method-main {
    margin-bottom: 22px;
  }

  .checkout-page-container .grid-container-box .payment-method-main .payment-align {
    margin-bottom: 0px;
  }

  .order-summary-main .title-grid {
    margin-top: 20px;
  }

  .coupon-main {
    padding: 20px 0 0 0;
  }

  .coupon-card-main .add-coupon-container .add-coupon-code-align h4 {
    font-weight: 500;
  }

  .coupon-code-contain {
    display: block !important;
  }

  .coupon-card-main .add-coupon-container .coupon-code-contain .coupon-code-input .input-section input {
    width: 100%;
  }

  .checkout-main-title {
    margin-bottom: 0 !important;
  }

  .checkout-page-container
    .grid-container-box
    .item-shipping-grid
    .shpping-address-coupon
    .shipping-address-main
    .shpping-address-align {
    // margin: 0 0 8px 0;
  }

  .checkout-page-container .grid-container-box .items-section-main {
    margin-top: 16px;
    padding: 0;
    display: grid;
    // row-gap: 12px;

    @media only screen and (max-width: '1024px') {
      margin-top: 16px !important;
      background: #ffffff;
    }
  }

  .checkout-page-container .grid-container-box .items-align {
    padding: 0 0 0px 0;
  }

  .items-section-main .items-container-main {
    width: 100%;
    height: auto;
  }

  .items-section-main .items-container-main .items-contain-main .product-delivery-section .grid-product-section {
    height: 302px !important;
  }

  .checkout-page-container .grid-container-box .payment-method-main {
    display: block;
    gap: 0;
    padding-top: 16px;
    row-gap: 0;
    padding-left: 0;
    padding-right: 0;
    border-left: none;

    @media only screen and (max-width: '1024px') {
      padding-top: 0;
      margin-top: 0 !important;
    }
  }

  .payment-method-box-main .card-flex-center .credit-card-container {
    margin-left: 35px;
  }

  .order-summary-main {
    border-radius: 0px !important;
  }

  .items-section-main .items-container-main .items-contain-main {
    padding: 16px;
  }

  .button-section-align {
    padding: 16px 16px 0 16px;

    @media only screen and (max-width: '1024px') {
      display: none;
    }
  }

  .button-section-align .button-container {
    width: 100%;
  }

  .button-section-align .cancle-button-align {
    width: 100%;
  }

  .items-section-main
    .items-container-main
    .items-contain-main
    .product-delivery-section
    .grid-product-section
    .items-section-card
    .flex-product-class
    .product-detail-grid
    .discription-contain
    .store-coupon-alignment
    .check-availabilty-section
    .coupon-remove
    span {
    padding-left: 8px;
  }

  .payment-method-box-main .card-flex-center .change-card-text {
    margin-left: 35px;
    margin-bottom: 16px;
  }

  .order-summary-main {
    width: 100%;
    height: auto;
  }

  .payment-method-box-main .card-flex-center {
    display: block;
  }

  .items-section-main
    .items-container-main
    .items-contain-main
    .product-delivery-section
    .grid-product-section
    .items-section-card {
    width: 100%;
  }

  .items-section-main
    .items-container-main
    .items-contain-main
    .product-delivery-section
    .delivery-section-main
    .delivery-card-contain-box
    .delivery-section
    .delivery-section-container
    .devi-contain-flex
    .delivery-text-contain
    span {
    font-size: 14px;
    line-height: 21px;
  }

  .items-section-main
    .items-container-main
    .items-contain-main
    .product-delivery-section
    .delivery-section-main
    .delivery-card-contain-box
    .delivery-section
    .delivery-section-container
    .devi-contain-flex
    .delivery-text-contain
    p {
    font-size: 12px;
    line-height: 18px;
  }

  .items-section-main .items-container-main .items-contain-main .product-delivery-section {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .items-section-main
    .items-container-main
    .items-contain-main
    .product-delivery-section
    .delivery-section-main
    .delivery-card-contain-box
    .delivery-section
    .delivery-section-container {
    width: 100%;
  }

  .checkout-page-container .grid-container-box .item-shipping-grid .shpping-address-coupon {
    grid-template-columns: repeat(1, 1fr);
  }

  .checkout-page-container .grid-container-box .item-shipping-grid .shpping-address-coupon .shipping-address-main {
    display: block;

    @media only screen and (max-width: '1024px') {
      background: #ffffff;
      border: 1px solid #e4e4e8;
      // padding: 8px 16px;
    }
  }

  .coupon-card-main {
    width: 100%;
    height: auto;
  }

  .items-section-main
    .items-container-main
    .items-contain-main
    .product-delivery-section
    .grid-product-section
    .items-section-card {
    height: auto;
  }

  .checkout-main-text {
    display: none;
  }

  .coupon-code-input {
    display: grid !important;
    grid-template-columns: 1fr 75px;
    gap: 16px;
  }

  .coupon-card-main .add-coupon-container .coupon-code-contain .coupon-text-align {
    padding: 16px 0 0 0;

    span {
      text-align: center;
      display: block;
    }
  }

  .items-section-main
    .items-container-main
    .items-contain-main
    .product-delivery-section
    .grid-product-section
    .items-section-card
    .flex-product-class
    .product-image-contain {
    width: 66px;
    height: 81px;
    object-fit: cover;
  }

  .items-section-main
    .items-container-main
    .items-contain-main
    .product-delivery-section
    .grid-product-section
    .items-section-card
    .flex-product-class
    .product-detail-grid
    .product-title-align
    h2 {
    font-size: 14px;
    line-height: 20px;
  }

  .items-section-main
    .items-container-main
    .items-contain-main
    .product-delivery-section
    .grid-product-section
    .items-section-card
    .flex-product-class
    .product-detail-grid
    .discription-contain
    .product-size-color
    .size-text-align
    span,
  .items-section-main
    .items-container-main
    .items-contain-main
    .product-delivery-section
    .grid-product-section
    .items-section-card
    .flex-product-class
    .product-detail-grid
    .discription-contain
    .product-size-color
    .color-text-align
    span,
  .items-section-main
    .items-container-main
    .items-contain-main
    .product-delivery-section
    .grid-product-section
    .items-section-card
    .flex-product-class
    .product-detail-grid
    .discription-contain
    .product-size-color
    .color-text-align
    .color-count
    span,
  .items-section-main
    .items-container-main
    .items-contain-main
    .product-delivery-section
    .grid-product-section
    .items-section-card
    .flex-product-class
    .product-detail-grid
    .discription-contain
    .product-size-color
    .size-text-align
    .size-count
    span,
  .items-section-main
    .items-container-main
    .items-contain-main
    .product-delivery-section
    .grid-product-section
    .items-section-card
    .flex-product-class
    .product-detail-grid
    .discription-contain
    .quantity-section
    span {
    font-size: 12px;
    line-height: 18px;
  }
}

.card-number-alignment {
  padding: 0 15px;

  @media only screen and (max-width: '1199px') {
    padding: 0 0 0 15px;
  }
}

.payment-button-disable {
  opacity: 0.8 !important;
  cursor: not-allowed !important;
}
