.productFilterSection {
  position: sticky;
  top: 28px;
  left: 0;
  width: 100%;
  z-index: 999;
  &.hideNavAlignment {
    display: none;
    transform: translateY(-100%);
    transition: 0.4s ease-in-out;
  }

  &.openNavAlignment {
    display: block;
    transform: translateY(0);
    transition: 0.4s ease-in-out;
  }

  @media only screen and (max-width: '1024px') {
    display: none;
  }

  .productFilterBox {
    background: var(--white);
    backdrop-filter: blur(16px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    .filterBarLeftSide {
      display: flex;
      align-items: center;
      gap: 9px;

      .firstfilter {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 6px 16px;
        cursor: pointer;
        position: relative;
        .firstFilterIcon {
        }

        p {
          color: var(--secondary-500);
          font-size: 15px;
          font-weight: 400;
          line-height: 20px;
        }

        .activeFilterBox {
          display: none;
          width: 4px;
          height: 4px;
          background: #e60000;
          border-radius: 50%;
          position: absolute;
          top: 2px;
          right: 8px;
        }

        &.filterActive {
          .firstFilterIcon {
            svg {
              path {
                stroke: var(--secondary-900);
              }
            }
          }
          p {
            color: var(--secondary-900);
          }

          .activeFilterBox {
            display: block;
          }
        }
      }

      .secondFilter {
        padding: 6px 16px;
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
        .secondFilterIcon {
        }

        p {
          color: var(--secondary-500);
          font-size: 15px;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }

    .filterCenterSide {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      .filterBox {
        min-width: 87px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 13px;
        background: #eff4fd;
        padding: 0 10px;

        &.yellowBox {
          background: #f9f7e0;
        }

        span {
          color: var(--secondary-900);
          font-size: 15px;
          font-weight: 400;
          line-height: 21px;
        }

        .closeIcon {
          width: 8px;
          height: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }

    .filterRightSide {
      display: flex;
      align-items: center;
      gap: 8px;

      .filterSearch {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }



      .filterByTimeDropdown {
        width: 149px;
        height: 32px;
        padding: 6px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          color: var(--secondary-900);
          font-size: 15px;
          font-weight: 400;
          line-height: 20px;
        }

        .dropdownArrow {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
      }
    }
  }
}
