.popularStoreAlignment {
    border-radius: 8px;
    background: rgba(108, 115, 181, 0.36);
    padding: 30px 21px;
    margin: 30px 0 0 0;
    @media (max-width:576px) {
        padding: 10px 16px;
        border-radius: 0px;
    }

    .flexBoxItems:nth-child(1) {
        width: 170px;
        @media (max-width:576px) {
            width: 100%;
        }
    }

    .mobileHide {
        @media (max-width:576px) {
            display: none;
        }
    }

    .mobileShow {
        display: none;
        @media (max-width:576px) {
            display: block;
        }
    }


    
    .flexBox {
        align-items: center;
        display: flex;
        gap: 107px;
        @media (max-width:576px) {
            flex-wrap: wrap;
            gap: 10px;
        }
    }

    .flexBoxItems:last-child {
        width: calc(100% - 170px - 150px);
        @media (max-width:576px) {
            width: 100%;
        }
    }

    .text {
        @media (max-width:576px) {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
        h2 {
            color: #1E2432;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            @media (max-width:576px){
                font-size: 16px;
            }
        }

        button {
            color: #fff;
            padding: 6px 24px;
            border-radius: 6px;
            background: #1E2432;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            border: none;
            cursor: pointer;
            @media (max-width:576px) {
                background-color: transparent;
                color: #556EE6;
            }
        }

        p {
            color: #4C515D;
            font-size: 13px;
            margin: 0 0 20px 0;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.1px;
            @media (max-width:576px) {
                font-size: 12px;
                margin: 0;
            }
        }
    }
}