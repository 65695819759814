.addVariantModalAlignment {
  .variantGridAlignment {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4px;

    .variantGridItemAlignment {
      background: #f8f8f8;
      border-radius: 10px;
      width: 100%;
      height: 145px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      cursor: pointer;

      .iconsAlignment {
        img {
          display: block;
          width: 44px;
          height: 44px;
        }
      }

      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: var(--secondary-900);
        padding: 0;
        margin: 0;
      }
    }
  }

  .recently-created-alignment {
    padding: 32px 0 0 0;

    .recently-grid-alignment {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 4px;

      .recently-gridItem-alignment {
        background: #ffffff;
        border: 1px solid #e4e4e8;
        border-radius: 10px;
        width: 100%;
        height: 145px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        cursor: pointer;

        .size-details-alignment {
          text-align: center;
          padding: 0 30px;

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #495057;
          }
        }

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: var(--secondary-900);
          padding: 0;
          margin: 0;
        }

        .color-picker-details-alignment {
          .color-picker-grid-alignment {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 2px 10px;
            align-items: center;

            .color-detaild-alignment {
              display: flex;
              align-items: center;
              gap: 2px 6px;

              .color-box-alignment {
                width: 22px;
                height: 21px;
                border-radius: 4px;
              }

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #495057;
              }
            }

            .more-details-alignment {
              h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: #acb1c0;
                margin: 0;
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
}

.modalWrapper {
  position: fixed;
  height: 100vh;
  width: 100%;
  background: rgba(30, 36, 50, 0.4);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;

  @media only screen and (max-width: '767px') {
    display: none;
  }

  .modalBoxAlignment {
    max-width: 654px;
    width: 654px;
    background: #ffffff;
    box-shadow: 0px 5px 50px 5px rgba(192, 191, 191, 0.15);
    border-radius: 10px;
    padding: 32px;

    .modalHeaderAlignment {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 14px;

      h3 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: var(--secondary-900);
      }

      .headerButtonAlignment {
        display: flex;
        align-items: center;
        gap: 32px;

        .cancleButtonAlignment {
          button {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: var(--primary-500);
            background: transparent;
            border: none;
            cursor: pointer;
            padding: 0;
            margin: 0;
          }
        }

        .saveButtonAlignment {
          button {
            background: #bbc5f5;
            border-radius: 8px;
            padding: 6px 18px;
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: #f8f8f8;
            cursor: pointer;
            border: none;
          }
        }

        .active {
          button {
            background: var(--primary-500);
          }
        }
      }
    }

    .modalBodyAlignment {
      padding: 30px 0 0 0;
    }

    .sizeColorContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;

      .nameVariantSection {
        display: flex;
        gap: 18px;
        align-items: center;

        .sizeTitle {
          cursor: context-menu;

          span {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            display: flex;
            align-items: center;
            color: var(--secondary-900);
          }
        }

        .sizeInput {
          cursor: context-menu;

          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            display: flex;
            align-items: center;
            color: #808191;
          }
        }
      }

      .removeEditSection {
        display: flex;
        gap: 16px;
        align-items: center;

        .editVariant {
          cursor: pointer;

          @media only screen and (max-width: '767px') {
            display: none;
          }

          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: var(--primary-500);
          }
        }

        .mobieViewEditVariant {
          cursor: pointer;
          display: none;

          @media only screen and (max-width: '767px') {
            display: block;
          }

          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: var(--primary-500);
          }
        }

        .editVariant {
          cursor: pointer;

          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: var(--primary-500);
          }
        }
      }
    }
  }
}