.stockReportModalDetailsSection {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 5px 50px rgba(145, 143, 143, 0.1);

  .stockReportModalDetailsBg {
    border-radius: 10px;
    padding: 0 16px;
    height: calc(100vh - 96px);
    overflow-y: auto;

    .stockReportModalDetailsHeadingAlignment {
      padding: 22px 16px 21px 16px;
      position: sticky;
      top: 0;
      z-index: 99;
      background: #fff;

      .stockReportModalDetailsHeading {
        padding: 0 0 32px 0;

        h5 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
          color: var(--secondary-900);
        }
      }

      .stockReportmodalTabAndSearchAlignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .stockReportModalTabAlignment {
          ul {
            display: flex;
            align-items: center;
            gap: 16px;

            li {
              padding: 6px 16px;
              background: #eceef4;
              border-radius: 8px;
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 15px;
              line-height: 20px;
              color: var(--secondary-600);
              cursor: pointer;

              &:hover {
                background: var(--primary-50);
                border-radius: 8px;
                color: var(--primary-700);
                font-weight: 600;
              }

              &.tabActiveOption {
                background: var(--primary-50);
                border-radius: 8px;
                color: var(--primary-700);
                font-weight: 600;
              }
            }
          }
        }

        .stockReportSearchAlignment {
          background: #eeeff2;
          border-radius: 8px;
          max-width: 398px;
          height: 32px;
          width: 398px;
          display: flex;
          align-items: center;
          padding: 4px 8px;

          .stockReportSearchIcon {
            img {
              cursor: pointer;
            }
          }

          input {
            width: 100%;
            height: 100%;
            background: transparent;
            border: none;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            color: #acb1c0;
          }
        }
      }

      .stockReportTotalProduct {
        padding: 16px 0 0 0;

        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: var(--secondary-500);
        }
      }
    }

    .stockReportModalDetailsAlignment {
      .stockReportModalTableAlignment {
        table {
          width: 100%;

          thead {
            position: sticky;
            top: 180px;
            background: #fff;
            z-index: 99;

            tr {
              th {
                padding: 0 10px 20px 10px;
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: var(--secondary-900);

                &:first-child {
                  padding: 0 16px 20px 16px;
                }

                &:last-child {
                  padding: 0 16px 20px 16px;
                }

                &.textCenter {
                  text-align: center;
                }

                &.textEnd {
                  text-align: right;
                }

                input[type='checkbox'] {
                  width: 18px;
                  height: 18px;
                  padding: 0;
                  margin: 0;
                }
              }
            }
          }

          tbody {
            tr {
              padding: 0 16px;
              cursor: pointer;
              transition: 0.4s ease-in-out;

              &:hover {
                background: var(--primary-50);
                border-radius: 8px;
                transition: 0.2 s ease-in-out;

                td {
                  .quantityAlignment {
                    .moreIconAlignment {
                      visibility: visible;
                    }
                  }
                }
              }

              td {
                padding: 4px 0 4px 0;

                &:first-child {
                  padding: 4px 16px 4px 16px;
                }

                &:last-child {
                  padding: 4px 16px 4px 16px;
                }

                input[type='checkbox'] {
                  width: 18px;
                  height: 18px;
                  padding: 0;
                  margin: 0;
                }

                span {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--secondary-900);
                  display: block;

                  &.priceTag {
                    text-align: center;
                  }
                }

                .stockDetails {
                  padding: 6px 16px;
                  background: #e8f3e6;
                  border-radius: 16px;
                  width: fit-content;
                  margin: auto;

                  &.lowStock {
                    background: #fff2e9;

                    p {
                      color: #e87220;
                    }
                  }

                  &.outOfStock {
                    background: #fde8e8;

                    p {
                      color: #e71616;
                    }
                  }

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 20px;
                    color: #1d8603;
                  }
                }

                .skuAlignment {
                  text-align: center;

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: #808191;
                  }
                }

                .quantityAlignment {
                  display: flex;
                  align-items: center;
                  gap: 16px;
                  justify-content: flex-end;

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 18px;
                    color: #808191;
                  }

                  .moreIconAlignment {
                    cursor: pointer;
                    visibility: hidden;
                  }
                }

                .stockProductImg {
                  width: 80px;
                  min-width: 80px;
                  max-width: 80px;
                  height: 80px;

                  img {
                    width: 100%;
                    height: 100%;
                    display: block;
                    border-radius: 8px;
                    object-fit: cover;
                  }
                }
              }
            }
          }
        }
      }

      .stockReportModalTableAlignment table tr th:nth-child(1) {
        width: 5%;
      }

      .stockReportModalTableAlignment table tr th:nth-child(2) {
        width: 15%;
      }

      .stockReportModalTableAlignment table tr th:nth-child(3) {
        width: 25%;
      }

      .stockReportModalTableAlignment table tr th:nth-child(4) {
        width: 15%;
      }

      .stockReportModalTableAlignment table tr th:nth-child(5) {
        width: 15%;
      }

      .stockReportModalTableAlignment table tr th:nth-child(6) {
        width: 15%;
      }

      .stockReportModalTableAlignment table tr th:nth-child(7) {
        width: 10%;
      }
    }
  }
}

.inputCheckboxAlignment {
  position: relative;

  input[type='checkbox'] + label {
    display: block;
    cursor: pointer;
    margin: 0;
  }

  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox'] + label:before {
    border: 2px solid #acb1c0;
    border-radius: 6px;
    display: flex;
    width: 20px;
    height: 20px;
    vertical-align: bottom;
    color: transparent;
    transition: 0.2s;
    font-size: 24px !important;
    justify-content: center;
    align-items: center;
  }

  input[type='checkbox']:checked + label:before {
    // content: url("../../../public/assets/seller/icons/check_icon.svg");
    background-color: var(--white);
    color: #acb1c0;
  }

  input[type='checkbox']:checked + label:after {
    display: block;
    position: absolute;
    top: 16px;
    left: 16px;
    width: 4px;
    height: 10px;
    border: 1px solid none !important;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}
