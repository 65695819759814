.productAdjustImageModalSection {
  .productAdjustImageModalWrapper {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 32px;
    left: 0;
    background: rgba(0, 0, 0, 0.52);
    z-index: 99999999;
    display: flex;
    align-items: center;
    justify-content: center;

    .productAdjustImageModalBoxAlignment {
      background: #eceef4;
      border-radius: 16px;
      padding: 16px;
      max-width: 1276px;
      width: 1276px;
      position: relative;
      // top: 72px;
      // height: fit-content;
      height: calc(100vh - 73px);

      @media only screen and (max-width:"1499px") {
        // top: 35px;
      }

      .productAdjustImageChildModal {
        background: #ffffff;
        box-shadow: 0px 5px 50px rgba(145, 143, 143, 0.1);
        border-radius: 10px;
        height: 100%;

        .productAdjustImageModalHeading {
          padding: 22px 32px 32px 32px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          h4 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--secondary-900);
          }

          a {
            cursor: pointer;
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            color: var(--primary-500);
          }
        }

        .productAdjustImageAlignment {
          position: relative;
          width: 100%;
          height: 642px;
          background: '#333';

          @media only screen and (max-width:"1499px") {
            height: calc(100vh - 312px);
          }

          // img {
          //     width: 100%;
          //     height: 100%;
          // }
        }

        .productOtherOptionAlignment {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          padding: 16px 32px;

          .productAdjustRangeAlignment {
            display: flex;
            align-items: center;
            gap: 16px;

            .productAdjustRangeBox {
              background: #ffffff;
              box-shadow: 0px 5px 50px rgba(145, 143, 143, 0.1);
              border-radius: 10px;
              padding: 8px 16px;

              h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 13px;
                line-height: 20px;
                color: var(--secondary-900);
              }

              .productAdjustRangeAlignment {
                padding: 8px 0 0 0;

                input[type='range'] {
                  width: 414px;
                }
              }
            }
          }

          .productAdjustSaveChanges {
            button {
              padding: 6px 16px;
              background: var(--primary-500);
              border-radius: 8px;
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 15px;
              line-height: 20px;
              color: #ffffff;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}