.group-message-sidebar-section {
  width: 64px;
  min-width: 64px;
  max-width: 64px;
  transition: 0.3s ease-in-out;

  border-radius: 0px 10px 10px 0px;
  border-left: 2px solid #eceef4;

  // width: 64px;
  // min-width: 64px;
  // max-width: 64px;
  // transition: 0.3s ease-in-out;
  // border-radius: 0px 10px 10px 0px;
  // border-right: 2px solid #eceef4;
  // border-top: 2px solid #eceef4;
  // border-bottom: 2px solid #eceef4;

  &.group-member-sidebar-open-alignment {
    width: 263px;
    min-width: 263px;
    max-width: 263px;
    transition: 0.3s ease-in-out;

    .group-message-sidebar-alignment {
      .group-message-arrow-alignment {
        justify-content: flex-start;
        padding: 0 32px;
        gap: 25px;

        .member-heading-alignment {
          display: flex;
          align-items: center;
          gap: 14px;

          h4 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: var(--secondary-900);
            cursor: context-menu;
          }

          .message-number-alignment {
            background: #eceef4;
            border-radius: 10px;
            padding: 0 8px;
            cursor: pointer;

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 13px;
              line-height: 20px;
              color: var(--secondary-500);
            }
          }
        }
      }

      .group-message-groups-details-alignment {
        .group-message-groups-details-add-user-flex-alignment {
          justify-content: flex-start;
          padding: 0 16px;
          gap: 8px;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: var(--primary-500);
            display: block;
          }
        }

        .group-message-groups-user-details-section {
          padding: 32px 8px 0 8px;

          .group-message-groups-user-flex-alignment {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 0 12px 0 0;

            &:hover {
              .more-option-alignmnet {
                display: block;
                position: relative;

                img {
                  cursor: pointer;
                  display: block;
                }

                .more-option-dropdown-position {
                  position: absolute;
                  top: 40px;
                  right: 0;
                  width: max-content;
                  z-index: 99;
                  .more-option-dropdown-alignment {
                    background: #ffffff;
                    border: 1px solid #e3e4e8;
                    border-radius: 10px;
                    padding: 16px;
                    width: 100%;

                    ul {
                      li {
                        padding: 3px 10px;
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        color: var(--secondary-900);
                        cursor: pointer;
                        margin: 0 0 8px 0;

                        &.remove-group-alignment {
                          color: #e71616;

                          &:hover {
                            background: rgba(231, 22, 22, 0.07);
                          }
                        }
                        &:last-child {
                          margin: 0;
                        }

                        &:hover {
                          background: #eef1fc;
                          border-radius: 4px;
                        }
                      }
                    }
                  }
                }
              }
            }

            &.active-user {
              .more-option-alignmnet {
                display: block;
                position: relative;
                img {
                  cursor: pointer;
                  display: block;
                }
              }
            }

            .group-message-group-user-profile-img-alignment {
              display: flex;
              align-items: center;
              gap: 8px;

              h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: var(--secondary-900);
                white-space: nowrap;
                display: block;
              }

              .admin-box-alignment {
                background: linear-gradient(97.05deg, #556ee6 0.34%, #6b21a8 100%);
                border-radius: 10px;
                padding: 0 8px;
                margin: 0 0 0 -4px;
                display: block;

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 20px;
                  color: #ffffff;
                }
              }
            }
          }
        }
      }
    }
  }

  .group-message-sidebar-alignment {
    padding: 26px 0;

    .group-message-arrow-alignment {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-width: 100%;
        cursor: pointer;
      }
    }

    .member-heading-alignment {
      display: none;
    }

    .group-message-groups-details-alignment {
      padding: 42px 0 0 0;

      .group-message-groups-details-add-user-flex-alignment {
        display: flex;
        align-items: center;
        justify-content: center;

        .group-message-groups-details-add-user-box-alignment {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: var(--primary-50);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        p {
          display: none;
        }
      }

      .group-message-groups-user-details-section {
        padding: 32px 4px 0 4px;
        // height: auto;
        height: calc(100vh - 250px);
        overflow-y: auto;

        .group-message-groups-user-flex-alignment {
          padding: 0;

          &:hover {
            background: var(--primary-50);
            border-radius: 10px;
          }

          &.active-user {
            background: var(--primary-50);
            border-radius: 10px;
          }

          .group-message-group-user-profile-img-alignment {
            padding: 8px;
            cursor: pointer;

            h6 {
              display: none;
            }

            .admin-box-alignment {
              display: none;
            }

            .group-message-profile-width-alignment {
              width: 40px;
              height: 40px;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
                border-radius: 50%;
              }
            }
          }
        }

        .more-option-alignmnet {
          display: none;
        }
      }
    }
  }
}
