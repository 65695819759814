.productSizeBoxAlignment {
  background: #f8f8f8;
  border: 2px dashed #b1c2f3;
  border-radius: 8px;
  padding: 28px;
  width: 100%;
  height: 182px;
  display: flex;
  justify-content: center;
  align-items: center;

  .productMainFlexAlignment {
    display: flex;
    // align-items: flex-start;
    align-items: center;
    justify-content: center;
    gap: 23px;

    @media only screen and (max-width: '1099px') {
      gap: 10px;
    }

    .productChildDetailsAlignment {
      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #495057;
        padding: 0 0 11px 0;
        text-align: center;
      }

      .sizesDetailsFlexAlignment {
        display: flex;
        align-items: flex-start;
        gap: 41px;

        .squareBox {
          width: 54px;
          height: 54px;
          background: #f8f8f8;
          border: 1px solid #acb1c0;
          border-radius: 6.49995px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &:hover {
            background: #eceef4;
            border: 1px solid rgba(85, 110, 230, 0.6);
            border-radius: 6.49995px;
          }

          &.nineBySixteenBox {
            width: 54px;
            height: 96px;
          }

          &.sixteenByNineBox {
            width: 96px;
            height: 54px;
          }

          &.twoByThreeBox {
            width: 54px;
            height: 81px;
          }

          &.threeByTwoBox {
            width: 81px;
            height: 54px;
          }

          &.fourByFive {
            width: 54px;
            height: 67px;
          }

          &.fiveByFourBOx {
            width: 67px;
            height: 54px;
          }

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: #808191;
          }
        }
      }
    }
  }
}
