.mobileJewelryStoreModalSection {
    .mobileJewelryStoreModalAlignment {
        padding: 16px;

        .mobileJewelryStoreModalHeader {
            padding: 0 0 16px 0;

            h6 {
                color: var(--secondary-900);
                font-family: 'Poppins', sans-serif;
                font-size: 20px;
                font-weight: 600;
                line-height: 24px;
            }
        }


        .mobileJewelryStoreModalBody {

            height: calc(100vh - 115px);
            overflow-y: auto;

            .mobileJewelryStoreModalGridAlignment {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                gap: 16px;
                padding: 0 0 16px 0;

                .jewelryStoreModalAllDetailsGridItem {


                    .jewelryStoresDetailsBoxAlignment {
                        border-radius: 8px;
                        background: #F8F8F8;
                        padding: 16px;
                        transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);

                        &:hover {
                            transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);
                            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
                            transform: translateY(-4px);
                            border-radius: 8px;
                        }

                        .jewelryStoreProductDetailsAlignment {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .jewelryStoreProductAllDetails {
                                display: flex;
                                align-items: center;
                                gap: 16px;

                                @media only screen and (max-width:"767px") {
                                    gap: 8px;
                                }

                                .jewelryStoreProductAllImg {
                                    width: 102px;
                                    height: 102px;

                                    @media only screen and (max-width:"767px") {
                                        width: 84px;
                                        height: 84px;
                                    }

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                        border-radius: 8px;
                                    }
                                }

                                .jewelryStoreProductOtherDetails {
                                    h5 {
                                        color: var(--secondary-900);
                                        font-family: 'Poppins', sans-serif;
                                        font-size: 20px;
                                        font-weight: 500;
                                        line-height: 30px;

                                        @media only screen and (max-width:"767px") {
                                            font-size: 16px;
                                            line-height: 20px;
                                        }
                                    }

                                    p {
                                        padding: 2px 0 0 0;
                                        color: var(--secondary-500);
                                        font-family: 'Poppins', sans-serif;
                                        font-size: 13px;
                                        font-weight: 400;
                                        line-height: 20px;

                                        @media only screen and (max-width:"767px") {
                                            font-size: 12px;
                                            line-height: 20px;
                                        }
                                    }

                                    .followStoreButton {
                                        padding: 10px 0 0 0;

                                        @media only screen and (max-width:"767px") {
                                            padding: 6px 0 0 0;
                                        }

                                        button {
                                            padding: 6px 26px;
                                            border-radius: 8px;
                                            background: var(--primary-50);
                                            color: var(--primary-500);
                                            text-align: center;
                                            font-family: 'Poppins', sans-serif;
                                            font-size: 14px;
                                            font-weight: 600;
                                            line-height: 21px;
                                            cursor: pointer;

                                            @media only screen and (max-width:"767px") {
                                                padding: 6px 16px;
                                            }
                                        }
                                    }
                                }
                            }

                            .jewelryLikeShareButton {
                                display: flex;
                                align-items: center;
                                flex-direction: column;
                                gap: 16px;

                                .jewelryLikeShareIconTotal {
                                    cursor: pointer;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    flex-direction: column;

                                    p {
                                        color: var(--secondary-500);
                                        font-family: 'Poppins', sans-serif;
                                        font-size: 13px;
                                        font-weight: 500;
                                        line-height: 20px;
                                        padding: 4px 0 0 0;

                                        @media only screen and (max-width:"767px") {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }

                        .jewelryChildImagesAlignment {
                            padding: 16px 0 0 0;

                            .jewelryChildGrid {
                                display: grid;
                                grid-template-columns: repeat(5, 1fr);
                                gap: 8px;

                                @media only screen and (max-width:"767px") {
                                    gap: 4px;
                                }

                                .jewelryChildImg {
                                    width: 100%;
                                    height: 100%;
                                    position: relative;

                                    @media only screen and (max-width:"767px") {
                                        height: 60px;
                                    }

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        border-radius: 4px;
                                        object-fit: cover;
                                    }

                                    &.lastImgAlignment {

                                        &::before {
                                            content: '';
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            width: 100%;
                                            height: 100%;
                                            border-radius: 4px;
                                            background: linear-gradient(0deg, rgba(30, 36, 50, 0.60) 0%, rgba(30, 36, 50, 0.60) 100%);
                                        }

                                        .jewelryMoreImg {
                                            position: absolute;
                                            top: 0;
                                            right: 0;
                                            z-index: 9;
                                            left: 0;
                                            bottom: 0;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            cursor: pointer;

                                            p {
                                                color: #FFF;
                                                font-family: 'Poppins', sans-serif;
                                                font-size: 13px;
                                                font-weight: 400;
                                                line-height: 20px;
                                                text-align: center;

                                                span {
                                                    font-size: 16px;
                                                    line-height: 24px;
                                                    display: block;
                                                }
                                            }
                                        }
                                    }

                                }
                            }
                        }
                    }

                }
            }
        }
    }
}