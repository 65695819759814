.order-search-filter-section {
  display: flex;
  align-items: center;
  gap: 94px;
  padding: 0 0 30px 0;

  @media only screen and (max-width: '767px') {
    display: none;
  }

  .search-alignment {
    height: 32px;
    width: 100%;
    position: relative;

    input {
      background: #f8f8f8;
      border-radius: 5px;
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      width: 100%;
      height: 100%;
      padding: 0 20px 0 65px;
      border: none;

      &::placeholder {
        color: #808191;
      }
    }

    .search-icon-alignment {
      position: absolute;
      top: 7px;
      left: 32px;

      img {
        cursor: pointer;
      }
    }
  }

  .filter-export-alignment {
    display: flex;
    align-items: center;
    gap: 26px;

    .filter-box-alignment {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      border: 0.5px solid #e4e4e8;
      border-radius: 5px;
      height: 32px;
      width: 84px;
      min-width: 84px;
      padding: 0 8px;
      cursor: pointer;

      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        color: var(--primary-500);
      }
    }
  }
}

.order-table-alignment {
  margin: 24px 0 0 0;
  height: auto;
  max-height: 570px;
  overflow-y: auto;

  @media only screen and (max-width: '767px') {
    display: none;
  }

  table {
    width: 100%;

    thead {
      position: sticky;
      top: 0;
      z-index: 99;
      background: #fff;

      tr {
        th {
          color: var(--grey5);
          text-align: center;
          font-family: 'Poppins', sans-serif;
          font-size: 13.762px;
          font-weight: 500;
          line-height: 18px;
          padding: 0 0 12px 0;
        }
      }
    }

    tbody {
      tr {
        transition: 0.3s ease-in-out;
        cursor: pointer;

        &:hover {
          border-radius: 8.469px;
          background: var(--primary-50);

          transition: 0.3s ease-in-out;

          td {
            &:first-child {
              border-radius: 8.469px 0 0 8.469px;
            }

            &:last-child {
              border-radius: 0 8.469px 8.469px 0;
            }
          }
        }

        td {
          padding: 25px 18px;
          color: var(--grey5);
          font-family: 'Poppins', sans-serif;
          font-size: 13.762px;
          font-weight: 400;
          line-height: 18px;
          text-align: center;

          &.price-alignment {
            font-weight: 500;
          }

          &.id-alignment {
            color: var(--primary-500);
          }

          &.address-alignment {
            color: #808191;
          }

          .flex-alignment {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 24px;

            img {
              height: 63px;
              width: 56px;
              object-fit: cover;
              border-radius: 8px;
            }

            span {
              color: var(--grey5);
              font-family: 'Poppins', sans-serif;
              font-size: 13.762px;
              font-weight: 400;
              line-height: 18px;
              text-align: center;
            }
          }

          .status-alignment {
            background-color: rgba(112, 194, 23, 0.2);
            border-radius: 5px;
            padding: 4px 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            width: 86px;
            margin: auto;

            &.hold-on-status {
              background-color: rgba(255, 148, 73, 0.2);

              p {
                background: #ff7d23;
              }

              span {
                color: #ff7d23;
              }
            }

            &.cancelled-status {
              background-color: rgba(194, 23, 23, 0.2);

              p {
                background: #c21717;
              }

              span {
                color: #c21717;
              }
            }

            p {
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background: #70c217;
            }

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              color: #70c217;
            }
          }

          &.checkbox-alignment {
            width: 21px;
            height: 21px;

            input[type='checkbox'] {
              width: 100%;
              height: 100%;
              padding: 0;
              margin: 0;
            }
          }
        }
      }
    }
  }
}

.order-table-alignment table thead tr th:nth-child(1) {
  width: 5%;
}

.order-table-alignment table thead tr th:nth-child(2) {
}

.order-table-alignment table thead tr th:nth-child(3) {
  width: 30%;
}

.order-table-alignment table thead tr th:nth-child(4) {
  width: 25%;
}

.order-table-alignment table thead tr th:nth-child(5) {
  width: 15%;
}

.order-table-alignment table thead tr th:nth-child(6) {
  width: 10%;
}

.pagination-alignment {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 44px;

  @media only screen and (max-width: '767px') {
    display: none;
  }

  .number-alignment {
    ul {
      display: flex;
      align-items: center;
      gap: 12px;

      li {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 17px;
        line-height: 26px;
        color: #808191;
        padding: 2px 12px;
        cursor: pointer;

        &:hover {
          background: var(--primary-500);
          border-radius: 50%;
          color: #ffffff;
          font-weight: 700;
        }

        &.pagination-active {
          background: var(--primary-500);
          border-radius: 50%;
          color: #ffffff;
          font-weight: 700;
        }
      }
    }
  }

  .next-page-alignment {
    display: flex;
    align-items: center;
    gap: 16px;

    span {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      font-size: 17px;
      line-height: 26px;
      color: var(--primary-500);
    }
  }
}
