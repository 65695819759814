.ShopclubeSlidermodalmain {
    border: 2px solid #EEF1FD;
    border-radius: 16px;
    padding: 15px;

    @media (max-width:700px) {
        padding: 0;
        border: none;
    }

    .ShopclubeSlidermodal {
        margin: -32px 0 0 0;

        @media (max-width:576px) {
            margin: -5px 0 0 0;
        }

        .trendingtitle {
            display: none;
            background-color: #fff;
            padding: 11px 16px;

            @media (max-width:576px) {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            }
        }

        h1 {
            color: #1E2432;
            text-align: center;
            font-family: "Poppins", sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
}