.addProductInventoryPricingSection {
  margin: 0 0 16px 0;

  .addProductInventoryPricingdBox {
    border-radius: 10px;
    border: 1px solid var(--secondary-50);
    background: #FFF;
    padding: 10px 16px 16px 16px;

    .addProductInventoryPricingdHeadingAlignment {
      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: var(--secondary-900);

      }
    }

    .addProductInventoryPricingdDetails {
      padding: 16px 0 0 0;

      .twoGridAlignment {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px 8px;

        .formInputDetailsAlignment {
          .formInputRelative {
            position: relative;

            .iconAlignment {
              position: absolute;
              top: 12px;
              right: 8px;
              cursor: pointer;
              display: flex;
              align-items: center;

              span {
                font-family: "Poppins", sans-serif;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: #acb1c0;
              }

              img {
                display: block;
              }
            }

            .iconAlignment2 {
              position: absolute;
              top: 12px;
              left: 14px;
              cursor: pointer;
              display: flex;
              align-items: center;

              span {
                font-family: "Poppins", sans-serif;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: #acb1c0;
              }
            }

            &.titleError {
              input {
                border: 1.9px solid red;
              }
            }
          }

          label {
            font-family: "Poppins", sans-serif;
            margin: 0 0 4px 0;
            display: block;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: #495057;
            text-align: left;

            span {
              color: #d23232;
            }
          }

          input {
            width: 100%;
            border-radius: 10px;
            border: 1px solid var(--secondary-50);
            background: var(--light-grey);
            height: 44px;
            padding: 0 10px;
            text-align: right;

            &.main-input-padding {
              padding: 0 60px 0 10px;
            }

            &.child-input-padding {
              padding: 0 25px 0 10px;
            }

            &.secondInputAlignment {
              padding: 0 10px 0 28px;
            }
          }
        }

        .titleError {
          input {
            border: 1.9px solid red;
          }
        }
      }
    }
  }
}