.book-appliance-idea-modal-alignment {
    @media only screen and (max-width:"767px") {
        background: #fff;
    }

    .mobile-view-book-appliance-back-alignment {
        display: none;

        @media only screen and (max-width:"767px") {
            display: flex;
            align-items: center;
            gap: 18px;
            padding: 8px 16px;
            background: rgba(248, 248, 248, 0.92);
            backdrop-filter: blur(13.5914px);

            .book-appliance-back_details-alignment {
                background: #ffffff;
                border-radius: 10px;
                padding: 4px 9px 4px 10px;
                display: flex;
                align-items: center;
                gap: 8px;

                p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #808191;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 58px;
                }
            }

            .mobile-heading-alignment {
                h4 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 27px;
                    text-align: center;
                    color: var(--secondary-900);
                }
            }
        }
    }

    .book-appliance-idea-banner-alignment {
        width: 100%;
        height: 178px;
        position: relative;

        @media only screen and (max-width:"767px") {
            height: 100px;
        }

        img {
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 8px;

            @media only screen and (max-width:"767px") {
                display: none;
            }

            &.mobile-view-banner {
                display: none;

                @media only screen and (max-width:"767px") {
                    display: block;
                }
            }
        }

        .book-appliance-idea-banner-details {
            position: absolute;
            top: 48px;
            left: 48px;


            @media only screen and (max-width:"767px") {
                top: 20px;
                left: 16px;
            }

            h4 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 40px;
                line-height: 60px;
                color: var(--secondary-900);

                @media only screen and (max-width:"767px") {
                    font-size: 20px;
                    line-height: 30px;
                }
            }

            p {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--secondary-900);

                @media only screen and (max-width:"767px") {
                    font-size: 12px;
                    line-height: 18px;
                    max-width: 165px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .book-appliance-idea-details-alignment {
        padding: 18px 0 0 0;

        @media only screen and (max-width:"767px") {
            padding: 16px 0 0 0;
        }

        .book-appliance-idea-grid-alignment {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 16px;

            @media only screen and (max-width:"767px") {
                display: none;
            }

            @media only screen and (max-width:"899px") {
                grid-template-columns: repeat(2, 1fr);
            }

            .book-appliance-idea-gridItem-alignment {
                height: 252px;
                width: 100%;
                transition: 0.4s ease-in-out;

                &:hover {

                    transform: scale(0.9);
                }

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    display: block;
                    object-fit: cover;
                }
            }
        }

        .mobile-view-idea-for-you-modal-alignment {
            display: none;

            @media only screen and (max-width:"767px") {
                display: block;
            }

            .no-data-box-alignment {
                background: #ffffff;
                width: 100%;
                height: 100%;
                border-radius: 18px;

                @media only screen and (max-width:"767px") {
                    display: none;
                }
            }


        }

   
    }
}