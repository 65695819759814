.mobileViewUserProfileBioSection {
  padding: 16px 32px;

  h4 {
    color: var(--secondary-900);
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    padding: 0 0 32px 0;
  }

  .mobileViewUserProfileDescription {
    p {
      color: var(--secondary-900);
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      padding: 0 0 24px 0;

      &:last-child {
        padding: 0;
      }
    }
  }
}
