.mobileViewWithdrawModalSection {
    .mobileViewWithdrawModalHeading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 16px 14px 16px;
        border-bottom: 1px solid #EAECEF;
        position: sticky;
        top: 0;
        z-index: 999;
        background: #fff;

        h4 {
            color: #495057;
            font-size: 18px;
            line-height: 27px;
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
        }

        a {
            color: var(--primary-500);
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            font-family: 'Poppins', sans-serif;
        }
    }

    .mobileViewWithdrawModalDetails {
        padding: 16px;
        // height: calc(100vh - 98px);
        // overflow-y: auto;
        // height: calc(100vh - 6% - 58px);
        // height: calc(100vh - 130px);
        // overflow-y: auto;

        .mobileViewWithdrawAccountDetailsBoxAlignment {
            border-radius: 16px;
            background: #F8F8F8;
            padding: 16px;
            text-align: center;

            @media only screen and (max-width:"767px") {
                background: #fff;
            }

            .mobileViewMainAccountBalance {
                h5 {
                    color: var(--secondary-900);
                    text-align: center;
                    font-size: 16px;
                    font-family: 'Poppins', sans-serif;
                    line-height: 24px;
                    font-weight: 600;
                }

                span {
                    color: var(--secondary-500);
                    font-size: 12px;
                    line-height: 18px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                }
            }

            .toAlignment {
                padding: 8px 0;

                p {
                    color: var(--secondary-900);
                    font-size: 13px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    line-height: 24px;
                }
            }

            .mobileViewBankDetails {
                p {
                    color: var(--secondary-900);
                    font-size: 16px;
                    font-family: 'Poppins', sans-serif;
                    line-height: 24px;
                    font-weight: 600;
                }

                span {
                    color: var(--secondary-500);
                    font-size: 12px;
                    line-height: 18px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                }
            }

        }

        .mobileAvailableAmountAlignment {
            display: flex;
            align-items: center;
            gap: 4px;

            p {
                color: var(--secondary-500);
                font-family: 'Poppins', sans-serif;
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
            }
        }

        .mobileViewAmountBoxAlignment {
            margin: 8px 0 0 0;
            border-radius: 16px;
            background: var(--secondary-50);

            .mobileViewWithdrawPriceDetails {
                padding: 8px 0 0 0;
                border-bottom: 1px solid #FFF;

                label {
                    color: var(--secondary-900);
                    font-family: 'Poppins', sans-serif;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14px;
                    display: block;
                    padding: 0 0 0 8px;
                    margin: 0;
                }

                input {
                    width: 100%;
                    text-align: right;
                    color: var(--secondary-900);
                    font-size: 48px;
                    line-height: 72px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    background: var(--secondary-50);
                    padding: 0 24px 0 0;
                }
            }

            .mobileViewWithdrawPriceBox {
                padding: 32px 0;
                max-width: 264px;
                margin: 0 auto;

                .mobileViewWithdrawPriceBoxGrid {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    gap: 16px 48px;

                    .mobileViewWithdrawPriceBoxGridItem {
                        .mobileViewWithdrawNumberBox {
                            width: 100%;
                            height: 56px;
                            border-radius: 48px;
                            background: #F8F8F8;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            &.nonBgAlignment {
                                background: transparent;
                            }

                            p {
                                color: #000;
                                text-align: center;
                                font-size: 24px;
                                font-family: 'Poppins', sans-serif;
                                line-height: 38px;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }

    }

    .mobileVIewWithdrawButton {
        padding: 16px;
        // position: fixed;
        // bottom: 0;
        background: #fff;
        width: 100%;

        button {
            border-radius: 10px;
            background: var( --primary-50);
            height: 44px;
            width: 100%;
            color: var(--primary-500);
            text-align: center;
            font-size: 16px;
            line-height: 24px;
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
        }
    }
}