.mobileJewelryLiveChannelSection {
    .mobileJewelryLiveChannelAlignment {
        padding: 16px;

        .mobileJewelryLiveChannelDetailsAlignment {
            margin: 0 0 32px 0;
            .mobileJewelryLiveChannelHeadingAlignment {
                display: flex;
                align-items: center;
                justify-content: space-between;

                h6 {
                    color: var(--secondary-900);
                    font-family: 'Poppins', sans-serif;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 24px;

                    &.suggestionHeading {
                        font-size: 16px;
                        font-weight: 500;
                    }
                }

                a {
                    padding: 2px 10px;
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    color: var(--primary-500);
                    text-align: center;
                    font-family: 'Poppins', sans-serif;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 20px;
                }
            }

            .mobileJewelryLiveChannelAllDetailsAlignment {
                padding: 16px 0 0 0;

                .mobileJewelryLiveChannelAllDetailsGrid {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 16px;

                    .mobileJewelryLiveChannelAllDetailsGridItem {
                        .jewelryLiveChnnelDetails {
                            .jewelryLiveDetailsImgAlignment {
                                width: 100%;
                                position: relative;
                                height: 100%;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    display: block;
                                    border-radius: 10px;
                                }


                                &:before {
                                    content: '';
                                    border-radius: 0px 0px 10px 10px;
                                    background: linear-gradient(0deg, #1E2432 0%, rgba(30, 36, 50, 0.00) 100%);
                                    width: 100%;
                                    height: 208px;
                                    bottom: 0;
                                    left: 0;
                                    z-index: 99;
                                    position: absolute;
                                }

                                .jewelryLiveDetailsBlurDetails {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    width: 100%;
                                    height: 100%;
                                    z-index: 999;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: space-between;

                                    .jewelryLiveAndViewAlignment {
                                        display: flex;
                                        align-items: center;
                                        gap: 4px;
                                        padding: 16px;
                                        flex-direction: column;
                                        align-items: flex-start;

                                        .jewelryLiveBoxAlignment {
                                            border-radius: 8px;
                                            background: #E71616;
                                            display: flex;
                                            align-items: center;
                                            gap: 4px;
                                            padding: 4px;

                                            p {
                                                color: #FFF;
                                                font-family: 'Poppins', sans-serif;
                                                font-weight: 500;
                                                font-size: 12px;
                                                line-height: 14px;
                                            }
                                        }

                                        .jewelryLiveNumberAlignment {
                                            border-radius: 8px;
                                            background: rgba(30, 36, 50, 0.60);
                                            display: flex;
                                            align-items: center;
                                            gap: 4px;
                                            height: 24px;
                                            padding: 4px;

                                            p {
                                                color: #E4E4E8;
                                                font-family: 'Poppins', sans-serif;
                                                font-weight: 500;
                                                font-size: 12px;
                                                line-height: 14px;
                                            }
                                        }
                                    }

                                    .jewelryLiveAndViewOtherDetailsALignment {
                                        padding: 16px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;

                                        .jewelryLiveAndViewOtherName {
                                            h6 {
                                                color: #F8F8F8;
                                                font-family: 'Poppins', sans-serif;
                                                font-weight: 600;
                                                max-width: 142px;
                                                font-size: 16px;
                                                line-height: 20px;
                                                max-width: 100%;
                                            }

                                            .jewelryBrandNameAlignment {
                                                display: flex;
                                                align-items: center;

                                                padding: 8px 0 0 0;
                                                gap: 8px;

                                                .jewelryBrandImg {
                                                    width: 20px;
                                                    height: 20px;

                                                    img {
                                                        width: 100%;
                                                        height: 100%;
                                                        border-radius: 2px;
                                                        object-fit: cover;
                                                        display: block;
                                                    }
                                                }

                                                p {
                                                    color: #FFF;
                                                    font-family: 'Poppins', sans-serif;
                                                    font-weight: 500;
                                                    font-size: 12px;
                                                    line-height: 14px;

                                                }
                                            }
                                        }
                                    }
                                }
                            }

                        }
                    }
                }
            }
        }


    }
}