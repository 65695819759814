.createClubsBasicInfoSection {
    padding: 32px;
    height: calc(100vh - 287px);
    overflow-y: auto;

    @media only screen and (max-width:"1024px") {
        height: 100%;
    }

    @media only screen and (max-width:"767px") {
        padding: 16px 16px 82px 16px;
    }

    .createClubsBasicFormAlignment {
        .createClubBasicInputAlignment {
            margin: 0 0 16px 0;

            .tageInputAllAlignment {
                margin: 0;
            }

            &:last-child {
                margin: 0;
            }

            label {
                color: var(--grey5);
                font-family: 'Poppins', sans-serif;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin: 0;
            }

            .createClubsInput {
                width: 100%;
                margin: 4px 0 0 0;
                border-radius: 10px;
                border: 1px solid var(--Bluejestic-Background, #ECEEF4);
                background: var(--secondary-50);
                display: flex;
                align-items: center;
                position: relative;

                &.mobileViewClubCategoryInput {
                    @media only screen and (max-width:"767px") {
                        max-width: 315px;
                    }
                }

                &.tagInput {
                    max-width: 242px;
                    width: 242px;

                    @media only screen and (max-width:"767px") {
                        max-width: 100%;
                        width: 100%;
                    }
                }

                &.selectOtionAlignment {
                    padding: 0 8px 0 0;

                    @media only screen and (max-width:"767px") {
                        max-width: 220px;
                    }
                }

                &.selectCreatelub {
                    padding: 0 8px 0 0;
                }

                input {
                    width: 100%;
                    height: 40px;
                    background: transparent;
                    border: none;
                    padding: 0 16px;
                    font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    color: var(--secondary-900);

                    &::placeholder {

                        color: var(--grey3);
                    }
                }

                textarea {
                    width: 100%;
                    height: 130px;
                    background: transparent;
                    border: none;
                    padding: 12px 16px;
                    font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    color: var(--secondary-900);
                    resize: none;

                    @media only screen and (max-width:"767px") {
                        height: 87px;
                    }

                    &::placeholder {

                        color: var(--grey3);
                    }
                }

                svg {
                    cursor: pointer;
                }

                .createClubDropdowmBoxAlignment {
                    border-radius: 10px;
                    background: #FFF;
                    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
                    width: 100%;
                    height: auto;
                    padding: 0;
                    position: absolute;
                    top: 48px;
                    left: 0;
                    z-index: 999;

                    &.closeDropdown {
                        max-height: 0px;
                        transition: 0.4s ease-in-out;
                        overflow-y: hidden;
                    }

                    &.openDropdown {
                        max-height: 256px;
                        overflow-y: auto;
                        transition: 0.4s ease-in-out;
                        padding: 16px;
                    }

                    ul {
                        li {
                            color: var(--secondary-900);
                            font-family: 'Poppins', sans-serif;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                            padding: 8px;
                            cursor: pointer;
                            border-radius: 8px;
                            transition: 0.4s ease-in-out;



                            &:hover {
                                transition: 0.4s ease-in-out;
                                background: var(--primary-50);
                            }

                            &.activeSelect {
                                transition: 0.4s ease-in-out;
                                background: var(--primary-50);
                            }
                        }
                    }
                }
            }

            .tagAddButton {
                button {
                    padding: 10px;
                    border-radius: 10px;
                    background: var(--primary-50);
                    color: var(--primary-500);
                    font-family: 'Poppins', sans-serif;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    cursor: pointer;
                    white-space: nowrap;
                }
            }

            .createClubOtherDetailsGrid {
                display: grid;
                grid-template-columns: 1fr 220px;
                gap: 16px;


                @media only screen and (max-width:"767px") {
                    grid-template-columns: 1fr;
                }
            }

            .createClubTagAlignment {
                display: flex;
                align-items: flex-end;
                gap: 8px;

                @media only screen and (max-width:"767px") {
                    max-width: 100%;
                    width: 100%;
                }
            }

            .mobileTagAlignment {
                @media only screen and (max-width:"767px") {
                    max-width: 100%;
                    width: 100%;
                }
            }

            .createClubTagNamesBoxFlex {
                display: flex;
                align-items: center;
                gap: 8px;
                flex-wrap: wrap;
                padding: 16px 0 0 0;

                @media only screen and (max-width:"767px") {
                    gap: 20px 16px;
                }

                .createClubTagNamesBox {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    border-radius: 13px;
                    background: #F1E5FF;
                    height: 26px;
                    padding: 0 8px;

                    p {
                        color: #390072;
                        font-family: 'Poppins', sans-serif;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }

                    svg {
                        cursor: pointer;
                    }

                    &.orangeBox {
                        background: #FFF0E1;

                        p {
                            color: #8F3C00;
                        }

                        svg {
                            path {
                                stroke: #8F3C00;
                            }
                        }
                    }

                    &.greenBox {
                        background: #E8F6EE;

                        p {
                            color: #005069;
                        }

                        svg {
                            path {
                                stroke: #005069;
                            }
                        }
                    }

                    &.greyBox {
                        background: var(--light-grey);

                        p {
                            color: var(--secondary-700);
                        }

                        svg {
                            path {
                                stroke: var(--secondary-700);
                            }
                        }
                    }
                }

            }
        }

    }
}