@mixin block_btn {
    background: var(--primary-500);
    border-radius: 8px;
    display: block;
    width: 100%;
    border: unset;
    outline: unset;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 10px;
    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

    &:hover {
        transform: translateY(-3px);
        background: #2844ac;
    }
}

.art-craft-creative-club-store-details-section {
    // padding: 16px;

    @media only screen and (max-width:"768px") {
        background: #fff;
        padding: 0;
    }

    .mobile-view-art-craft-back-alignment {
        display: none;

        @media only screen and (max-width:"768px") {
            display: flex;
            align-items: center;
            gap: 18px;
            padding: 8px 16px;
            background: rgba(248, 248, 248, 0.92);
            backdrop-filter: blur(13.5914px);
            justify-content: flex-start;
            // position: sticky;
            // top: 0;
            // z-index: 999;

            .art-craft-back_details-alignment {
                background: #ffffff;
                border-radius: 10px;
                padding: 4px 9px 4px 10px;
                display: flex;
                align-items: center;
                gap: 8px;

                p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #808191;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 58px;
                }
            }

            .mobile-heading-alignment {
                h4 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 27px;
                    text-align: center;
                    color: var(--secondary-900);
                }
            }
        }
    }


    .art-craft-creative-club-store-banner-alignment {
        width: 100%;
        height: 178px;
        // position: sticky;
        // top: 0;
        // z-index: 9999;
        position: relative;

        @media only screen and (max-width:"768px") {
            height: 100px;
            top: 47px;
        }

        img {
            width: 100%;
            height: 100%;
            display: block;
            // border-radius: 8px;
            object-fit: cover;

            @media only screen and (max-width:"768px") {
                display: none;
            }

            &.mobile-view-banner {
                display: none;

                @media only screen and (max-width:"768px") {
                    display: block;
                }
            }
        }

        .art-craft-creative-club-store-banner-details {
            position: absolute;
            top: 35px;
            left: 48px;

            @media only screen and (max-width:"768px") {
                top: 20px;
                left: 16px;
            }

            h4 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 40px;
                line-height: 60px;
                color: var(--secondary-900);

                @media only screen and (max-width:"768px") {
                    font-size: 20px;
                    line-height: 30px;
                }
            }

            p {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--secondary-900);
                max-width: 540px;

                @media only screen and (max-width:"768px") {
                    font-size: 12px;
                    line-height: 18px;
                    max-width: 165px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .art-craft-creative-club-all-details-alignment {
        padding: 18px 0 0 0;

        @media only screen and (max-width:"768px") {
            padding: 16px;
        }

        .art-craft-creative-club-grid-details-alignment {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 16px;

            @media only screen and (max-width:"978px") {
                grid-template-columns: repeat(2, 1fr)
            }

            @media only screen and (max-width:"767px") {
                grid-template-columns: 1fr;
            }

            .art-craft-creative-club-gridItem-details-alignment {
                .creative-clubs-right_slider_cover {
                    .card_creative-clubs {
                        width: 100%;
                        position: relative;
                        border-radius: 10px 10px 0px 0px;
                        overflow: hidden;
                        transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                        height: 251px;
                        max-height: 251px;

                        @media only screen and (max-width:"768px") {
                            height: 230px;
                            max-height: 230px;
                        }

                        &:hover {
                            transform: translateY(-5px);

                            .creative-clubs-img_cover img {
                                transform: scale(1.05);
                                transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                                object-fit: cover;
                            }
                        }

                        .creative-clubs-img_cover {
                            object-fit: cover;
                            object-position: center;
                            height: 251px;
                            max-height: 251px;

                            @media only screen and (max-width:"768px") {
                                height: 230px;
                                max-height: 230px;
                            }

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                            }
                        }

                        .creative-clubs-card_details {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: flex-end;
                            padding: 15px;
                            background: linear-gradient(360deg, #0e1116 0%, rgba(30, 36, 50, 0) 100%);

                            .creative-clubs-card_details_cover {
                                width: 100%;

                                h5 {
                                    font-weight: 500;
                                    font-size: 20px;
                                    line-height: 30px;
                                    color: #ffffff;
                                }

                                p {
                                    font-weight: 400;
                                    font-size: 12px;
                                    line-height: 18px;
                                    color: #ffffff;
                                    margin: 4px 0 17px 0;
                                }

                                .creative-clubs-users {
                                    width: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;

                                    .creative-clubs-left_part {
                                        display: flex;
                                        align-items: center;

                                        .creative-clubs-users_likes {
                                            display: flex;
                                            align-items: center;
                                            margin-left: -10px;

                                            .creative-clubs-user_box {
                                                width: 20px;
                                                display: flex;
                                                align-items: center;
                                                height: 20px;
                                                justify-content: center;
                                                background: #fff;
                                                object-position: center;
                                                border: 1px solid #ffffff;
                                                border-radius: 30px;

                                                img {
                                                    width: 100%;
                                                    object-fit: cover !important;
                                                }
                                            }
                                        }

                                        .creative-clubs-count {
                                            margin-left: -10px;
                                            background: var(--primary-500);
                                            border: 1px solid #ffffff;
                                            border-radius: 12px;
                                            font-weight: 500;
                                            font-size: 12px;
                                            line-height: 18px;
                                            letter-spacing: 0.1px;
                                            color: #ffffff;
                                            padding: 0px 9px;
                                        }
                                    }

                                    .creative-clubs-right_part {
                                        max-width: 120px;

                                        button {
                                            @include block_btn();
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}