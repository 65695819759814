@mixin block_btn {
  background: var(--primary-500);
  border-radius: 8px;
  display: block;
  width: 100%;
  border: unset;
  outline: unset;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    transform: translateY(-3px);
    background: #2844ac;
  }
}

.creative-clubs-section {
  margin: 56px 0 0 0;
  padding-bottom: 67px;

  @media only screen and (max-width:"768px") {
    display: none;
  }

  .creative-clubs-grid-alignment {
    display: grid;
    grid-template-columns: 496px 1fr;
    gap: 16px;

    @media only screen and (max-width:"1099px") {
     grid-template-columns: 370px 1fr;
    }
    .creative-clubs-gridItem-alignment {
      .creative-clubs-left-side-alignment {
        background: #fff2e9;
        border-radius: 16px 16px 0px 0px;
        width: 100%;
        padding: 80px 58px 48px 58px;
        height: 100%;

        .creative-clubs-side-details-alignment {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 60px;
        }

        .creative-left-side-clubs-heading-alignment {
          padding: 66px 0 0 0;

          h5 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 36px;
            line-height: 54px;
            color: var(--secondary-900);
            max-width: 341px;
          }

          p {
            padding: 16px 0 0 0;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--secondary-900);
            max-width: 341px;
          }
        }
      }

      .creative-clubs-right-side-alignment {
        .creative-clubs-right-grid-alignment {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 16px;

          .creative-clubs-right-gridItem-alignment {
            .creative-clubs-right_slider_cover {
              .card_creative-clubs {
                width: 100%;
                position: relative;
                border-radius: 10px 10px 0px 0px;
                overflow: hidden;
                transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                height: 251px;
                max-height: 251px;

                @media only screen and (max-width: '768px') {
                  height: 230px;
                  max-height: 230px;
                }

                &:hover {
                  transform: translateY(-5px);

                  .creative-clubs-img_cover img {
                    transform: scale(1.05);
                    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                    object-fit: cover;
                  }
                }

                .creative-clubs-img_cover {
                  object-fit: cover;
                  object-position: center;
                  height: 251px;
                  max-height: 251px;

                  @media only screen and (max-width: '768px') {
                    height: 230px;
                    max-height: 230px;
                  }

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                  }
                }

                .creative-clubs-card_details {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: flex-end;
                  padding: 15px;
                  background: linear-gradient(360deg, #0e1116 0%, rgba(30, 36, 50, 0) 100%);

                  .creative-clubs-card_details_cover {
                    width: 100%;

                    h5 {
                      font-weight: 500;
                      font-size: 20px;
                      line-height: 30px;
                      color: #ffffff;
                    }

                    p {
                      font-weight: 400;
                      font-size: 12px;
                      line-height: 18px;
                      color: #ffffff;
                      margin: 4px 0 17px 0;
                    }

                    .creative-clubs-users {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;

                      .creative-clubs-left_part {
                        display: flex;
                        align-items: center;

                        .creative-clubs-users_likes {
                          display: flex;
                          align-items: center;
                          margin-left: -10px;

                          .creative-clubs-user_box {
                            width: 20px;
                            display: flex;
                            align-items: center;
                            height: 20px;
                            justify-content: center;
                            background: #fff;
                            object-position: center;
                            border: 1px solid #ffffff;
                            border-radius: 30px;

                            img {
                              width: 100%;
                              object-fit: cover !important;
                            }
                          }
                        }

                        .creative-clubs-count {
                          margin-left: -10px;
                          background: var(--primary-500);
                          border: 1px solid #ffffff;
                          border-radius: 12px;
                          font-weight: 500;
                          font-size: 12px;
                          line-height: 18px;
                          letter-spacing: 0.1px;
                          color: #ffffff;
                          padding: 0px 9px;
                        }
                      }

                      .creative-clubs-right_part {
                        max-width: 120px;

                        button {
                          @include block_btn();
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          //         .creative-clubs-right-gridItem-alignment {
          //             .creative-clubs-img-alignment {
          //                 width: 100%;
          //                 height: 251px;
          //                 position: relative;
          //                 overflow: hidden;
          //                 transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

          //                 border-radius: 10px 10px 0px 0px;

          //                 img {
          //                     width: 100%;
          //                     height: 100%;
          //                     border-radius: 10px 10px 0px 0px;
          //                     object-fit: cover;
          //                 }

          //                 .img-cover-alignment {
          //                     position: absolute;
          //                     width: 100%;
          //                     height: 100%;
          //                     background: linear-gradient(360deg, #0E1116 0%, rgba(30, 36, 50, 0) 100%);
          //                     top: 0;
          //                     right: 0;
          //                     border-radius: 10px 10px 0px 0px;
          //                 }

          //                 &:hover {
          //                     transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

          //                     img {
          //                         transform: scale(1.05);
          //                         border-radius: 16px;
          //                     }
          //                 }

          //                 .creative-clubs-img-details-alignment {
          //                     position: absolute;
          //                     right: 16px;
          //                     bottom: 16px;
          //                     left: 16px;

          //                     .creative-clubs-img-details-heading-alignment {
          //                         h6 {
          //                             font-family: 'Poppins', sans-serif;
          //                             font-weight: 500;
          //                             font-size: 20px;
          //                             line-height: 30px;
          //                             color: #FFFFFF;
          //                         }

          //                         p {
          //                             font-family: 'Poppins', sans-serif;
          //                             font-weight: 400;
          //                             font-size: 12px;
          //                             line-height: 18px;
          //                             color: #FFFFFF;
          //                             padding: 4px 0 0 0;
          //                         }

          //                         .creative-club-users {
          //                             width: 100%;
          //                             display: flex;
          //                             align-items: center;
          //                             justify-content: space-between;
          //                             padding: 18px 0 0 0;
          //                             position: relative;

          //                             .creative-club-left_part {
          //                                 display: flex;
          //                                 align-items: center;

          //                                 .creative-club-users_likes {
          //                                     display: flex;
          //                                     align-items: center;
          //                                     margin-left: -10px;

          //                                     .creative-club-user_box {
          //                                         width: 20px;
          //                                         display: flex;
          //                                         align-items: center;
          //                                         height: 20px;
          //                                         justify-content: center;
          //                                         background: #fff;
          //                                         object-position: center;
          //                                         border: 1px solid #ffffff;
          //                                         border-radius: 30px;

          //                                         img {
          //                                             width: 100%;
          //                                             object-fit: cover !important;
          //                                         }
          //                                     }
          //                                 }

          //                                 .creative-club-count {
          //                                     margin-left: -10px;
          //                                     background: var(--primary-500);
          //                                     border: 1px solid #ffffff;
          //                                     border-radius: 12px;
          //                                     font-weight: 500;
          //                                     font-size: 12px;
          //                                     line-height: 18px;
          //                                     letter-spacing: 0.1px;
          //                                     color: #ffffff;
          //                                     padding: 0px 9px;
          //                                     position: relative;
          //                                 }
          //                             }

          //                             .creative-club-right_part {
          //                                 max-width: 120px;

          //                                 button {
          //                                     @include block_btn();
          //                                 }
          //                             }
          //                         }
          //                     }
          //                 }
          //             }

          .creative-club-more-details-alignment {
            width: 100%;
            height: 251px;
            position: relative;
            overflow: hidden;
            border-radius: 16px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 16px;
              object-fit: cover;
              overflow: hidden;
              transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
            }

            transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

            &:hover {
              img {
                transform: scale(1.05);
                transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
              }

              .creative-club-hover-effect-alignment {
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            .creative-club-hover-effect-alignment {
              position: absolute;
              background: rgba(30, 36, 50, 0.8);
              border-radius: 16px;
              width: 100%;
              height: 100%;
              top: 0;
              right: 0;
              display: none;
              transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

              a {
                display: flex;
                align-items: center;
                gap: 8px;
                justify-content: center;
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 18px;
                line-height: 27px;
                color: #ffffff;
                cursor: pointer;

                img {
                  width: auto;
                  object-fit: cover;
                }
              }
            }
          }
        }
      }
    }
  }
}