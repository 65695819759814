.filterPaginationAlignment {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background: #fff;
  width: 100%;
  padding: 8px 0;

  .paginationBox {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.4s ease-in-out;
    p {
      color: var(--secondary-500);
      font-size: 15px;
      font-weight: 400;
      line-height: 21px;
    }

    &:hover {
      transition: 0.4s ease-in-out;

      p {
        color: var(--secondary-900);
      }
    }

    &.activePaginationNumber {
      transition: 0.4s ease-in-out;

      p {
        color: var(--secondary-900);
      }
    }

    &.opacity {
      svg {
        opacity: 0.5;
      }
    }
  }
  &.menPagination {
    @media only screen and (max-width: '1024px') {
      background: #1e2432;
      .paginationBox {
        p {
          color: rgba(255, 255, 255, 0.8);
        }
        &.activePaginationNumber {
          color: #fff;
        }
        &:hover {
          color: #fff;
        }

        svg {
          path {
            stroke: #fff;
          }
        }
      }
    }
  }
}
