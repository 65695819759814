.mobileViewTransactionDetailsSection {
    .mobileViewTransactionDetailsHeading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 16px 0 16px;
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 999;

        h4 {
            color: #495057;
            font-size: 18px;
            line-height: 27px;
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
        }

        a {
            color: var(--primary-500);
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            font-family: 'Poppins', sans-serif;
        }
    }

    .mobileViewTransactionDetailsBody {
        padding: 64px 16px 0 16px;

        .mobileViewTransactionDetailsStatusDetailsAlignment {
            display: flex;
            align-items: center;
            gap: 21px;

            .mobileViewTransactionAllDetailsALignment {
                h4 {
                    color: var(--secondary-900);
                    font-family: 'Poppins', sans-serif;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 32px;
                }

                p {
                    padding: 4px 0 0 0;
                    color: var(--secondary-500);
                    font-family: 'Poppins', sans-serif;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 19px;
                }

                .mobileViewTransactionStatusAlignment {
                    padding: 8px 0 0 0;

                    span {
                        padding: 3px 14px;
                        border-radius: 16px;
                        background: #FFF2E9;
                        color: #E87220;
                        font-family: 'Poppins', sans-serif;
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 19px;
                    }
                }
            }
        }

        .mobileViewTransactionSlipAllDetailsAlignment {
            padding: 50px 0 0 0;

            .mobileViewTransactionSlipChildDetails {
                padding: 16px;
                border-bottom: 1px solid var(--secondary-50);
                display: flex;
                align-items: center;
                justify-content: space-between;

                &:first-child {
                    padding: 0 16px 16px 16px;
                }

                &:last-child {
                    padding: 16px 16px 0 16px;
                    border-bottom: none;
                }

                .slipHeadingAlignment {
                    label {
                        color: var(--secondary-900);
                        font-family: 'Poppins', sans-serif;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 18px;
                    }
                }

                .rightSideAlignment {
                    h6 {
                        color: var(--secondary-900);
                        font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                        text-align: right;
                    }

                    p {
                        color: var(--secondary-500);
                        text-align: right;
                        font-family: 'Poppins', sans-serif;
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 19px;
                    }
                }
            }
        }
    }
}