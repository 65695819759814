.seller-page-mobile {
  @media screen and (max-width: 1024px) {
    background: #ffffff;
  }

  .ant-tabs-nav {
    margin-bottom: 0 !important;

    @media screen and (max-width: 1024px) {
      margin-bottom: 0;
    }
  }

  .ant-tabs-top>.ant-tabs-nav::before,
  .ant-tabs-top>div>.ant-tabs-nav::before {
    border: 0;
  }

  .ant-tabs-nav-list {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0;
    padding: 0 22px;
    width: 100%;
    justify-content: center;

    .ant-tabs-tab {
      padding: 0;
      margin: 0;

      .ant-tabs-tab-btn {
        padding: 8px 28px;

        @media screen and (max-width: 1024px) {
          padding: 11px 22px;
        }

        padding-bottom: 16px;
        font-size: 16px;
        line-height: 22px;
        color: rgb(141, 137, 137);
        font-weight: 400 !important;
      }

      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: var(--primary-700);
          font-weight: 500 !important;
        }

        &:before {
          content: '';
          position: absolute;
          height: 2px;
          background: var(--primary-700);
          border-radius: 4px 4px 0px 0px;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }
    }

    .ant-tabs-ink-bar {
      display: none;
    }
  }
}

.mt16 {
  margin-top: 16px;

  @media only screen and (max-width: '1024px') {
    margin-top: 0;
  }
}


.m16 {
  margin: 16px 0;

  @media only screen and (max-width: '1024px') {
    margin: 0;
  }
}

.mt32 {
  margin-top: 32px;

  @media only screen and (max-width: '1024px') {
    margin-top: 0;
  }
}


.mobile-view-none {
  @media only screen and (max-width: '1024px') {
    display: none;
  }
}

.mobile-view-padding-margin {
  @media only screen and (max-width: '1024px') {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.mobile-view-height-alignment {
  @media only screen and (max-width: '1024px') {
    // height: calc(100vh - 145px);
    // overflow-y: auto;
  }
}

.sellerLeftSidebarSection {
  background: rgba(255, 255, 255, 0.8);
  height: calc(100vh - 68px);
  width: 100%;
  border: 2px solid var(--white);
  backdrop-filter: blur(32px);
  border-radius: 0px;

  @media only screen and (max-width: '1024px') {
    height: 100%;
    background: #ffffff;
    border: none;
    display: none;
  }

  .sellerLeftSidebarAlignment {
    @media only screen and (max-width: '1024px') {
      border-bottom: 2px solid #eceef4;
    }

    .sellerMainSidebarHeadingAlignment {
      padding: 32px 0;
      text-align: center;

      .sellerMainProfileImgAlignment {
        width: 89px;
        height: 89px;
        margin: auto;
        cursor: pointer;

        @media only screen and (max-width: '1024px') {
          width: 72px;
          height: 72px;
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
          object-fit: cover;
        }
      }

      .sellerMainProfileDetailsAlignment {
        padding: 16px 0 0 0;

        h6 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 20px;
          line-height: 120%;
          color: var(--secondary-900);
        }

        .sellerProfiileRateFlexAlignment {
          padding: 8px 0 0 0;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;

          @media only screen and (max-width: '1024px') {
            padding: 2px 0 0 0;
          }

          .sellerOverRateAlignment {
            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 13px;
              line-height: 20px;
              color: var(--secondary-500);
              padding: 0;
            }
          }

          .sellerRateStarFlexAlignment {
            display: flex;
            align-items: center;

            .starIconAlignment {
              img {
                max-width: 100%;
                cursor: pointer;
              }
            }
          }

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: var(--secondary-500);
          }
        }

        .mobileViewLocationDetailsAlignment {
          display: none;

          @media only screen and (max-width: '1024px') {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            padding: 5px 0 0 0;

            .mobileViewMapIcon {
              width: 12px;
              height: 12px;

              img {
                width: 100%;
                height: 100%;
                display: block;
              }
            }

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: var(--secondary-500);
            }
          }
        }
      }
    }

    .sellerMainSidebarDetailsAlignment {
      @media only screen and (max-width: '1024px') {
        display: none;
      }

      ul {
        li {
          padding: 0 0 10px 0;
          transition: 0.3s ease-in-out;

          .sellerOptionAlignment {
            padding: 11px 47px;
            display: flex;
            align-items: center;
            gap: 16px;
            cursor: pointer;
            border: 1px solid transparent;
            border-right: none;
            border-left: none;

            &:hover {
              background: var(--white);
              border: 1px solid #e7e9ee;
              border-right: none;
              border-left: none;

              .sellerHoverImg {
                display: block;
              }

              .sellerDefaltImg {
                display: none;
              }

              // svg {
              //     path {
              //         stroke: var(--primary-500);
              //         fill: var(--primary-500);
              //     }

              //     g {
              //         path {

              //             fill: var(--primary-500);
              //         }
              //     }

              //     stroke: var(--primary-500);
              // }

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                color: var(--primary-500);
              }
            }

            &.activeOption {
              background: var(--white);
              border: 1px solid #e7e9ee;
              border-right: none;
              border-left: none;

              .sellerHoverImg {
                display: block;
              }

              .sellerDefaltImg {
                display: none;
              }

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                color: var(--primary-500);
              }
            }

            .sellerHoverImg {
              display: none;
            }

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: var(--secondary-500);
            }
          }
        }
      }
    }

    .mobileViewScrollHeaderAlignment {
      display: none;

      @media only screen and (max-width: '1024px') {
        display: flex;
        align-items: center;
        gap: 48px;
        height: 50px;
        padding: 0 16px;
        border-bottom: 2px solid #e7e9ee;

        &.no-border-alignment {
          border-bottom: 0;
        }

        .mobileViewBackIcon {}

        .mobileViewScrollProfileDetailsAlignment {
          display: flex;
          align-items: center;
          gap: 8px;

          .mobileViewScrollProfileDetailsImg {
            width: 24px;
            height: 24px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 2px;
              object-fit: cover;
            }
          }

          h6 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            color: var(--secondary-900);
          }
        }
      }
    }
  }
}

.mobileViewSrollSkicty {
  display: none;

  &.Close {
    transform: translateY(-100%);
    transition: 0.3s ease-in-out;
    position: absolute;
  }

  &.Open {
    transform: translateY(0%);
    transition: 0.3s ease-in-out;
    position: sticky;
  }

  @media only screen and (max-width: '1024px') {
    display: block;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 9999;
    background-color: #ffffff;
  }

  .mobileViewScrollHeaderAlignment {
    display: flex;
    align-items: center;
    gap: 0;
    height: 50px;
    padding: 0 16px;
    border-bottom: 2px solid #e7e9ee;

    &.no-border-alignment {
      border-bottom: none;
    }

    .mobileViewBackIcon {
      width: 30px;
      max-width: 30px;
      min-width: 30px;
      height: 30px;
    }

    .mobileViewScrollProfileDetailsAlignment {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;
      justify-content: center;

      .mobileViewScrollProfileDetailsImg {
        width: 24px;
        height: 24px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 2px;
          object-fit: cover;
        }
      }

      h6 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: var(--secondary-900);
      }
    }
  }
}

.sellerMobileViewSidebarDetails {
  display: none;

  @media only screen and (max-width: '1024px') {
    padding: 24px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #ffffff;
    position: sticky;
    top: 50px;
    z-index: 9999;

    .sellerMobileHedingFlex {
      display: flex;
      align-items: center;
      gap: 8px;
      justify-content: center;
    }

    .seller-mobile-view-save-button {
      display: none;

      @media only screen and (max-width:"1024px") {
        display: block;
      }

      button {
        padding: 3px 17px;
        border-radius: 5px;
        background: var(--primary-500);
        cursor: pointer;
        color: #FFF;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
    }

    .sellerMobileViewButton {
      button {
        border-radius: 8px;
        background: var(--primary-50);
        display: flex;
        align-items: center;
        gap: 2px;
        color: var(--primary-500);
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        padding: 8px 16px;
      }
    }

    &.show {
      top: 0px;
    }

    .sellerMenuIcon {
      width: 24px;
      height: 24px;

      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    h4 {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: var(--secondary-900);
    }
  }
}

.seller-layout-alignment {
  display: flex;
  background: var(--secondary-50);
  min-height: calc(100vh - 68px);

  .left-sidebar-alignment {
    width: 320px;
    min-width: 320px;
    position: fixed;
    left: 0;
    top: 68px;
    height: calc(100vh - 68px);
    background: var(--secondary-50);
    border-right: 1px solid rgba(0, 0, 0, 0.1);

    @media only screen and (max-width: '1499px') {
      width: 280px;
      min-width: 280px;
    }

    @media only screen and (max-width: '1299px') {
      width: 90px;
      min-width: 90px;
    }

    @media only screen and (max-width: '1024px') {
      display: none;
    }

    .sticky-div {
      position: sticky;
      top: 66px;

      @media only screen and (max-width: '1299px') {
        transition: 0.4s ease-in-out;
        width: 90px;
      }

      &.allDetailsDiv {
        @media only screen and (max-width: '1299px') {
          width: 280px;
          z-index: 999;
          transition: 0.4s ease-in-out;
        }
      }
    }
  }

  .center-details-alignment {
    margin-left: 320px;
    width: calc(100% - 320px);
    max-width: 1300px;
    padding: 24px 32px;

    @media only screen and (max-width: '1499px') {
      margin-left: 280px;
      width: calc(100% - 280px);
    }

    @media only screen and (max-width: '1299px') {
      margin-left: 90px;
      width: calc(100% - 90px);
    }

    @media only screen and (max-width: '1024px') {
      margin-left: 0;
      width: 100%;
      padding: 16px;
    }
  }

  .right-sidebar-alignment {
    position: sticky;
    top: 96px;
    padding-right: 32px;

    @media only screen and (max-width: '1024px') {
      position: static;
      padding-right: 0;
    }
  }

  @media only screen and (max-width: '1024px') {
    display: block;
  }
}