.addProductModalDetailsAlignemnt {
  // background: #FFFFFF;
  // border-radius: 20px 0px 0px 20px;
  // padding: 16px;
  height: calc(100vh - 80px);
  overflow-y: auto;
  position: relative;

  @media only screen and (max-width:"1099px") {
    padding: 16px 0;
  }

  @media only screen and (max-width:"1024px ") {
    padding: 0;
    height: auto;
  }

  .addProductMobileViewHeaderAlignment {
    display: none;

    @media only screen and (max-width:"1024px ") {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 10px 16px;
      background: #ffffff;
      position: sticky;
      top: 0;
      left: 0;
      background: #fff;
      z-index: 9999;
    }

    .headerNameAlignment {

      h6 {
        color: var(--secondary-900);
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 27px;
        max-width: 125px;
        text-wrap: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
      }

      .mobileViewStatusAlignment {
        display: flex;
        align-items: center;
        justify-content: center;

        .mobileViewStatusBox {
          padding: 3px 8px;
          border-radius: 12px;
          background: var(--secondary-50);

          p {
            color: var(--secondary-700);
            font-family: 'Poppins', sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
          }

          &.publishStatus {
            background: #E8F3E6;

            p {
              color: #1D8603;
            }
          }

        }
      }
    }

    // .previewIconAlignment {
    //   width: 32px;
    //   height: 32px;
    //   border-radius: 8px;
    //   background: var(--primary-50);
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;

    //   img {
    //     width: 18px;
    //     height: 18px;
    //     display: block;
    //   }
    // }

    .moreIconAlignment {
      width: 44px;
      height: 44px;
    }
  }

  .addProductModalBody {
    margin: 21px 0 0 0;
    height: calc(100vh - 154px);
    overflow-y: auto;

    @media only screen and (max-width:"1024px ") {
      height: calc(100vh - 71px);
      margin: 0;
      background: #ffffff;
      padding: 16px 16px 0 16px;
      display: none;
    }

    @media only screen and (max-width:"767px ") {
      height: calc(100vh - 71px);
      margin: 0;
      background: #ffffff;
      padding: 0;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: grey;
      border-radius: 18px;
    }

    .modalBodyDetailsAlignment {
      gap: 19px;
      display: grid;
      grid-template-columns: 2fr 1fr;

      @media only screen and (max-width:"1199px ") {
        grid-template-columns: 1fr;
      }

      @media only screen and (max-width:"1024px ") {
        grid-template-columns: 2fr 1fr;
      }

      @media only screen and (max-width:"999px ") {
        display: block;
      }

      @media only screen and (max-width:"767px") {
        padding: 16px 16px 45px 16px;
      }

      .addProductFirstPartAlignment {
        max-width: 100%;
        min-width: 100%;
        width: 100%;
        margin: 0 0 10px 0;

        @media only screen and (max-width:"1099px ") {
          max-width: 100%;
          min-width: 100%;
          width: 100%;
        }

        @media only screen and (max-width:"999px ") {
          max-width: 100%;
          min-width: 100%;
          width: 100%;
        }

        &:last-child {
          margin: 0;
        }

        .first-part-box-alignment {
          background: #ffffff;
          box-shadow: 0px 5px 50px 5px rgba(192, 191, 191, 0.15);
          border-radius: 10px;
          padding: 10px 15px 18px 15px;

          @media only screen and (max-width:"1024px ") {
            padding: 10px 18px 8px 18px;
          }

          h3 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            color: var(--secondary-900);
          }

          img {
            display: block;
          }

          h6 {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: var(--secondary-900);
            text-align: left;
          }

          h4 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            color: #495057;
            margin-right: 3px;
          }

          .star-alignment {
            font-family: 'Inter';
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #d23232;
          }

          .product-img-details-alignment {
            margin: 3px 0 0 0;

            p {
              margin: 0;
              padding: 0;
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 13px;
              line-height: 20px;
              color: #808191;
            }
          }

          .product-add-img-alignment {
            .product-add-heading-alignment {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin: 20px 0 0 0;

              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                color: #495057;
              }

              .recorder-product-details-alignment {
                display: flex;
                align-items: center;
                gap: 16px;

                span {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 400;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--primary-500);
                }

                .recorder-icon-alignment {
                  display: flex;
                  align-items: center;
                  gap: 8px;

                  img {
                    width: 28px;
                    height: 28px;
                    display: block;
                    cursor: pointer;
                  }
                }
              }
            }

            .add-product-details-alignment {
              margin: 12px 0 0 0;
              display: flex;
              align-items: center;
              flex-direction: row-reverse;
              gap: 2px;
              // overflow-x: hidden;

              .no-data-box-alignment {
                background: rgba(85, 110, 230, 0.13);
                border: 2px dashed #b1c2f3;
                border-radius: 8px;
                padding: 34px 14px 13px 14px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                min-width: 162px;
                width: 100%;
                height: 182px;
                position: relative;

                .add-icons-alignment {
                  display: flex;
                  align-items: center;
                  gap: 2px;

                  img {
                    width: 24px;
                    height: 24px;
                    display: block;
                  }
                }

                .drap-and-frop-alignment {
                  margin: 8px 0 0 0;

                  p {
                    max-width: 104px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 20px;
                    text-align: center;
                    color: #495057;

                    a {
                      color: var(--primary-500);
                    }
                  }
                }

                .allowed-img-type-alignment {
                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 20px;
                    text-align: center;
                    color: #808191;
                  }
                }

                input {
                  position: absolute;
                  top: 0;
                  right: 0;
                  width: 100%;
                  height: 100%;
                  opacity: 0;
                  cursor: pointer;
                  z-index: 9999;
                }
              }

              .product-scroll-image {
                width: 100%;

                .overFlow-details-alignment {
                  display: flex;
                  flex-wrap: nowrap;
                  align-items: center;
                  gap: 2px;
                  overflow: scroll;
                  // width: 100%;
                  width: 408px;

                  &::-webkit-scrollbar {
                    width: 0;
                    height: 0;
                  }

                  .add-product-alignment {
                    height: 182px;
                    max-width: 232px;
                    min-width: 232px;
                    position: relative;
                    z-index: 0;

                    &.child-img {
                      min-width: 116px;
                    }

                    &:hover {
                      .hover-details-alignment {
                        display: block;
                      }
                    }

                    img {
                      width: 100%;
                      height: 100%;
                      border-radius: 8px;
                      object-fit: contain;
                    }

                    video {
                      width: 100%;
                      height: 100%;
                      border-radius: 8px;
                      object-fit: contain;
                    }

                    .hover-details-alignment {
                      position: absolute;
                      top: 0;
                      right: 0;
                      width: 100%;
                      background: rgba(30, 36, 50, 0.8);
                      height: 100%;
                      border-radius: 8px;
                      display: none;

                      .option-alignment {
                        display: flex;
                        align-items: center;
                        grid-gap: 12px;
                        gap: 12px;
                        justify-content: center;
                        height: 100%;

                        img {
                          width: 20px;
                          height: 20px;
                          display: block;
                          cursor: pointer;
                        }
                      }

                      .img-option-alignment {
                        display: flex;
                        align-items: center;
                        gap: 2px;
                        position: absolute;
                        top: 4px;
                        right: 4px;

                        .option-box-alignment {
                          background: rgba(30, 36, 50, 0.4);
                          border-radius: 6px;
                          padding: 3px;
                          cursor: pointer;

                          img {
                            width: 20px;
                            height: 20px;
                            display: block;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #495057;
            text-align: left;
          }

          .modal-child-details-box-alignment {
            margin: 16px 0 0 0;
            background: #ffffff;
            border: 1px solid #eceef4;
            border-radius: 8px;
            padding: 16px;
            height: 250px;
            overflow-x: hidden;
            overflow-y: scroll;

            .grid-alignment {
              display: grid;
              grid-template-columns: 18px 1fr;
              gap: 11px;

              .add-minus-box-alignment {
                background: #acb1c0;
                border-radius: 3px;
                padding: 8px 3px 8px 3px;
                cursor: pointer;
                height: 18px;

                .minus-alignment {
                  border: 1px solid #ffffff;
                  width: 12px;
                }
              }

              .category-fashion-name-alignment {
                .main-dropdown-name-alignment {
                  border-bottom: 1px solid #eceef4;
                  // padding: 0 0 5px 0;

                  .first-name-alignment {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 18px;
                    color: var(--secondary-900);
                  }

                  .image-transition {
                    cursor: pointer;

                    .icon-rotate-stable {
                      transform: rotate(180deg);
                    }

                    .transition-icon {
                      transition: 0.3s ease-in-out;
                    }
                  }
                }

                .child-details-dropdown-name-alignment {
                  padding: 0 0 5px 11px;
                  border-bottom: 1px solid #eceef4;

                  &:last-child {
                    border-bottom: none;
                  }

                  .child-details-name-alignment {
                    padding: 8px 0;
                    border-bottom: 1px solid #eceef4;

                    &:last-child {
                      border-bottom: none;
                    }

                    .first-name-alignment {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 12px;
                      line-height: 18px;
                      color: var(--secondary-900);
                    }

                    .image-transition {
                      cursor: pointer;

                      .icon-rotate-stable {
                        transform: rotate(180deg);
                      }

                      .transition-icon {
                        transition: 0.3s ease-in-out;
                      }
                    }
                  }

                  .option-details-alignment {
                    padding: 5px 0 0 0;
                    border-bottom: 1px solid #eceef4;
                    height: auto;
                  }
                }

                .tab-show-area {
                  height: 100%;
                  overflow: hidden;
                  transition: 0.3s ease-in-out;
                }

                .tab-hidden-area {
                  max-height: 0px;
                  overflow: hidden;
                  transition: 0.3s ease-in-out;
                }
              }
            }
          }

          .no-variant-alignment {
            padding: 35px 0;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #808191;
            text-align: center;
          }

          .add-variat-button-alignment {
            button {
              background: #eceef4;
              border: 1px solid #eceef4;
              border-radius: 10px;
              padding: 10px 0;
              width: 100%;
              font-family: 'Poppins', sans-serif;
              font-size: 14px;
              line-height: 21px;
              color: var(--primary-500);
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 6px;
              cursor: pointer;
            }
          }

          .seo-form-alignment {
            margin: 16px 0 0 0;

            .formgroup-grid-alignment {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: 22px;
            }

            .form-details-alignment {
              width: 100%;
              padding: 0 0 21px 0;

              &:last-child {
                padding: 0;
              }

              label {
                font-family: 'Poppins', sans-serif;
                margin: 0 0 4px 0;
                display: block;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: #495057;
                text-align: left;
              }

              input {
                width: 100%;
                background: #eceef4;
                border: 1px solid #eceef4;
                border-radius: 10px;
                height: 44px;
                padding: 0 10px;
              }

              textarea {
                width: 100%;
                background: #eceef4;
                border: 1px solid #eceef4;
                border-radius: 10px;
                height: 118px;
                padding: 10px;
              }
            }
          }

          .product-title-input-alignment {
            padding: 16px 0 0 0;

            textarea {
              background: #eceef4;
              border: 1px solid #eceef4;
              border-radius: 10px;
              width: 100%;
              // height: 68px;
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              padding: 10px 16px 10px 10px;

              &::placeholder {
                color: #acb1c0;
              }
            }

            .text-limit-alignment {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              padding: 4px 0 0 0;

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: #acb1c0;
              }
            }
          }

          .add-attribute-from-alignment {
            padding: 16px 0 0 0;

            .add-attribute-grid {
              display: grid;
              grid-template-columns: 181px 1fr;
              gap: 4px;
              padding: 8px 0 0 0;

              &:first-child {
                padding: 0;
              }

              .add-attribute-grid-item {
                .label-alignment {
                  padding: 0 0 0 14px;
                  text-align: left;

                  label {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    color: #495057;
                    padding: 0;
                    margin: 0;
                  }
                }

                .atrribute-form-input-alignment {
                  display: flex;
                  align-items: center;
                  gap: 8px;

                  .input-alignment {
                    position: relative;
                    width: 100%;

                    input {
                      background: #eceef4;
                      border: 1px solid #eceef4;
                      border-radius: 10px;
                      width: 100%;
                      height: 32px;
                      padding: 0 16px;
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 21px;
                      color: #495057;

                      &::placeholder {
                        color: #acb1c0;
                      }
                    }

                    .dropdown-alignment {
                      background: #ffffff;
                      border: 1px solid #e4e4e8;
                      border-radius: 10px;
                      height: auto;
                      max-height: 184px;
                      overflow-y: auto;
                      padding: 12px 0;
                      position: absolute;
                      width: 100%;
                      bottom: 40px;

                      ul {
                        list-style-type: none;
                        list-style-position: outside;
                        margin: 0;
                        padding: 0;

                        li {
                          text-align: left;
                          padding: 4px 19px;
                          font-family: 'Poppins', sans-serif;
                          font-weight: 500;
                          font-size: 16px;
                          line-height: 24px;
                          color: var(--secondary-900);

                          // &::-webkit-scrollbar {
                          //   width: 0;
                          // }

                          &:hover {
                            background: #eceef4;
                            box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.05);
                            cursor: pointer;
                          }
                        }
                      }
                    }
                  }

                  .add-button-alignment {
                    button {
                      height: 32px;
                      width: 68px;
                      max-width: 68px;
                      min-width: 68px;
                      background: #bbc5f5;
                      border-radius: 8px;
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 21px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      color: #f8f8f8;
                      cursor: pointer;
                      border: none;

                      &.active {
                        background: var(--primary-500);
                      }
                    }
                  }

                  .delete-icon-alignment {
                    img {
                      display: block;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .addProductSecondPartAlignment {
        .secondPartBoxAlignment {
          width: 100%;
          max-width: 100%;
          min-width: 100%;

          @media only screen and (max-width:"1024px ") {
            width: 100%;
            max-width: 100%;
            min-width: 100%;

          }

          .second-part-heading-alignment {
            h4 {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 18px;
              line-height: 27px;
              color: var(--secondary-900);
              text-align: left;
            }

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              color: #808191;
              text-align: left;
            }
          }

          .tage-form-alignment {
            margin: 16px 0 0 0;
            display: flex;
            align-items: center;
            gap: 8px;

            .enter-code-input {
              input {
                background: #f8f8f8;
                border: 1px solid #eceef4;
                border-radius: 6px;
                padding: 4px 14px;
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: #acb1c0;
                height: 28px;
                max-width: 257px;
                width: 2578px;
              }
            }

            .add-button-alignment {
              button {
                background: #f8f8f8;
                border-radius: 6px;
                padding: 4px 16px;
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                color: var(--primary-500);
                border: none;
                cursor: pointer;
              }
            }
          }

          .tag-details-alignment {
            margin: 8px 0 0 0;
            display: flex;
            align-items: center;
            gap: 8px;
            flex-wrap: wrap;

            .tag-box-alignment {
              width: 94px;
              height: 26px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              background: #68d3f4;
              border: 1px solid #eceef4;
              border-radius: 3px;
              padding: 4px 10px;

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: #ffffff;
              }

              img {
                display: block;
                cursor: pointer;
              }
            }
          }
        }
      }


    }

    .addProductButtonAlignment {
      padding: 45px 0 0 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;
      display: none;

      @media only screen and (max-width:"1024px ") {
        display: grid;
        position: sticky;
        bottom: 0;
        left: 0;
        z-index: 999;
        padding: 16px;
        border-top: 1px solid var(--secondary-50);
        background: #ffffff;
      }

      @media only screen and (max-width:"767px") {
        padding: 16px;
        position: sticky;
        bottom: 0;
        left: 0;
        z-index: 999;
        background: #ffffff;
        grid-template-columns: 135px 1fr;
        gap: 8px;
        border-top: 1px solid var(--secondary-50);
      }



      .saveAsDraftButton {
        @media only screen and (max-width:"767px ") {
          display: none;
        }

        button {
          border-radius: 10px;
          background: var(--primary-50);
          width: 100%;
          height: 44px;
          color: var(--primary-500);
          text-align: center;
          font-family: 'Poppins', sans-serif;
          font-size: 18px;
          font-weight: 500;
          line-height: 28px;
          cursor: pointer;
        }
      }

      .publishButton {
        button {
          border-radius: 10px;
          background: var(--primary-500);
          width: 100%;
          height: 44px;
          color: #FFF;
          text-align: center;
          font-family: 'Poppins', sans-serif;
          font-size: 18px;
          font-weight: 500;
          line-height: 28px;
          cursor: pointer;
        }
      }
    }
  }

  .mobileViewAddProductModalBody {

    display: none;
    @media only screen and (max-width:"1024px ") {
      height: calc(100vh - 71px);
      margin: 0;
      background: #ffffff;
      padding: 16px 16px 0 16px;
      display: block;
    }

    .mobileViewPublishButton {
      display: none;

      @media only screen and (max-width:"1024px ") {
        display: block;
        padding: 16px;
        position: sticky;
        bottom: 0;
        left: 0;
        z-index: 999;
        background: #ffffff;
        gap: 8px;
        border-top: 1px solid var(--secondary-50);


        .publishBoxAlignment {
          border-radius: 22px;
          border: 1px solid var(--secondary-50);
          height: 44px;
          width: 100%;
          padding: 0 9px 0 16px;
          display: flex;
          align-items: center;
          gap: 6px;

          p {
            color: var(--secondary-900);
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
          }
        }

        .publishButton {
          button {
            border-radius: 10px;
            background: var(--primary-500);
            width: 100%;
            height: 44px;
            color: #FFF;
            text-align: center;
            font-family: 'Poppins', sans-serif;
            font-size: 18px;
            font-weight: 500;
            line-height: 28px;
            cursor: pointer;
          }
        }

      }

    }
  }
}