.reportPostModal {

  .descriptionText {
    color: #666;
    font-size: 13px;
    line-height: 1.5;
    margin: 16px 24px;
  }


  .reportOptions {
    .reportOption {
      padding: 12px 24px;
      cursor: pointer;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: #f5f5f5;
      }

      .title {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        color: #000;
      }

      .description {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #808191;
      }
    }
  }
}

.reportPostModalmainMobile{
  // position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999999 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding: 50px;
  // background-color: rgba(0, 0, 0, 0.5);

  .reportPostModalheader {
    // padding: 20px 25px 15px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;

    h1 {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0%;
      color: #495057;
      text-align: center;
      flex: 1;
    }

    .closeIcon {
      width: 16px;
      height: 16px;
    }
  }

  .reportPostModalbody {
    height: 100%;
    max-height: 643px;
    overflow-y: auto;

    .descriptionText {
      color: #666;
      font-size: 13px;
      line-height: 1.5;
      margin: 16px 24px;

      &:first-child {
        font-weight: 600;
        color: #495057;
        font-size: 15px;
      }
    }

    .reportOptions {
      .reportOption {
        padding: 12px 24px;
        cursor: pointer;
        transition: background-color 0.2s ease;

        &:hover {
          background-color: #f5f5f5;
        }

        h1 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 15px;
          line-height: 22px;
          color: #000;
        }

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #808191;
        }
      }
    }
  }

}

.reportPostModalmain {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  background-color: rgba(0, 0, 0, 0.5);

  .reportPostModal {
    width: 100%;
    max-width: 517px;
    background-color: #fff;
    border-radius: 12px;
    display: grid;
    grid-template-rows: auto 1fr;

    .reportPostModalheader {
      padding: 20px 25px 15px 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f0f0f0;

      h1 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0%;
        color: #495057;
        text-align: center;
        flex: 1;
      }

      .closeIcon {
        width: 16px;
        height: 16px;
      }
    }

    .reportPostModalbody {
      height: 100%;
      max-height: 643px;
      overflow-y: auto;

      .descriptionText {
        color: #666;
        font-size: 13px;
        line-height: 1.5;
        margin: 16px 24px;

        &:first-child {
          font-weight: 600;
          color: #495057;
          font-size: 15px;
        }
      }

      .reportOptions {
        .reportOption {
          padding: 12px 24px;
          cursor: pointer;
          transition: background-color 0.2s ease;

          &:hover {
            background-color: #f5f5f5;
          }

          h1 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 15px;
            line-height: 22px;
            color: #000;
          }

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #808191;
          }
        }
      }
    }
  }
}

.mobileviewdetailsalignment {
  position: relative;
  z-index: 99999999999999999999999999999;
  
  .reportPostModalmain {
    padding: 0;
    background: none;
    height: auto;

    .reportPostModal {
      max-width: none;
      border-radius: 0;
      height: auto;
      max-height: 80vh;
      overflow-y: auto;

      .reportPostModalheader {
        padding: 16px;
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 2;
        
        h1 {
          margin: 0;
          text-align: center;
        }
      }

      .reportPostModalbody {
        max-height: none;
        padding-bottom: env(safe-area-inset-bottom);
      }
    }
  }
}