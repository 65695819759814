.clubsBannerDesign {
  padding: 17px;
  margin-bottom: 16px;
  border-radius: 8px;
  background: linear-gradient(90deg, #d3e4f7 -0.83%, #e0cdf8 101.99%);

  @media (max-width: 576px) {
    border-radius: 0px;
    background: transparent;
  }

  .headerAlignmentclub {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 30px 0;
    @media (max-width: 576px) {
      display: none;
    }
    button {
      border: none;
      cursor: pointer;
      padding: 6px 12px;
      border-radius: 8px;
      background: var(--Secondary-secondary-800, #353a48);
      color: #eef1fd;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    h5 {
      color: #171725;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .clubModalTabAlignment {
    @media only screen and (max-width: '1024px') {
      padding: 0;
      background: #fff;
    }

    @media (max-width: 576px) {
      background-color: transparent;
    }

    :global(.ant-tabs-nav) {
      margin: 0;

      @media only screen and (max-width: '1024px') {
        padding: 0 16px;
      }
    }

    :global(.ant-tabs-tab-btn) {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: var(--secondary-500);
    }

    :global(.ant-tabs-tab-active) {
      :global(.ant-tabs-tab-btn) {
        color: var(--primary-700);
      }
    }

    :global(.ant-tabs-ink-bar) {
      background: var(--primary-700);
    }

    :global(.ant-tabs-top > .ant-tabs-nav::before) {
      border-bottom: 1px solid #bec1c9;
    }

    :global(.ant-tabs-nav-operations) {
      display: none !important;
    }
  }

  .clubModalDetailsAlignment {
    padding: 16px 0 0 0;

    @media only screen and (max-width: '1024px') {
      padding: 16px 0 16px 16px;
    }

    @media (max-width:576px) {
      padding: 15px 0 0 0;
     }

    .clubModalDetailsHeadingAlignment {
      h6 {
        color: var(--bookmark-heading-color);
        font-size: 18px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        line-height: 27px;

        @media only screen and (max-width: '1024px') {
          font-size: 16px;
          line-height: 24px;
        }
      }

      p {
        padding: 2px 0 0 0;
        color: var(--secondary-500);
        font-size: 13px;
        font-family: 'Poppins', sans-serif;
        line-height: 24px;
        letter-spacing: 0.1px;

        @media only screen and (max-width: '1024px') {
          font-size: 12px;
          line-height: 24px;
        }
      }
    }

    .clubModalDetailsAllDetailsAlignment {
      margin: 16px 0 0 0;
      position: relative;

      // &::before {
      //   content: '';
      //   position: absolute;
      //   width: 82px;
      //   height: 258px;
      //   background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
      //   top: 0;
      //   right: 0;
      //   z-index: 99;

      //   @media only screen and (max-width: '1024px') {
      //     display: none;
      //   }
      // }

      // .clubModalDetailsAllContentAlignment {
      //     max-height: 590px;
      //     overflow: auto;
      //     padding: 0 8px;
      //     position: relative;

      //     .clubModalDetailsCardGridItem {
      //         border-radius: 8px 8px 0px 0px;
      //         position: relative;

      //         .clubModalDetailsCardImage {
      //             img {
      //                 width: 100%;
      //                 height: 251px;
      //                 object-fit: cover;
      //                 border-radius: 10px 10px 0 0;
      //             }
      //         }

      //         .clubModalCardDetails {
      //             position: absolute;
      //             bottom: 0;
      //             left: 0;

      //             width: 100%;

      //             .clubModalCardDetailsAlignment {
      //                 padding: 9px 9px 18px 9px;

      //                 .clubModalAllContentAlignment {
      //                     display: flex;
      //                     align-items: center;
      //                     width: 100%;
      //                     justify-content: space-between;

      //                     .clubModalRightSideContent {
      //                         button {
      //                             background: rgba(55, 55, 55, 0.78);
      //                             border-radius: 10px;
      //                             padding: 0 11px;
      //                             height: 20px;
      //                             font-weight: 500;
      //                             font-size: 12px;
      //                             display: flex;
      //                             align-items: center;
      //                             justify-content: center;
      //                             cursor: pointer;
      //                             color: #fff;
      //                         }
      //                     }

      //                     .clubModalLeftSideContent {
      //                         display: flex;
      //                         align-items: center;
      //                         gap: 4px;

      //                         img {
      //                             cursor: pointer;
      //                         }

      //                         span {
      //                             display: block;
      //                             color: #fff;
      //                             font-weight: 400;
      //                             font-size: 12px;
      //                             line-height: 18px;
      //                         }
      //                     }

      //                     .clubModalusers {
      //                         width: 100%;
      //                         display: flex;
      //                         align-items: center;
      //                         justify-content: space-between;

      //                         .clubModalUserIcon {
      //                             display: flex;
      //                             align-items: center;
      //                             gap: 4px;

      //                             .clubModaLeftPart {
      //                                 display: flex;
      //                                 align-items: center;
      //                                 padding-left: 10px;

      //                                 .clubModaUsersLikes {
      //                                     display: flex;
      //                                     align-items: center;
      //                                     margin-left: -10px;

      //                                     .clubModalUserBox {
      //                                         width: 20px;
      //                                         display: flex;
      //                                         align-items: center;
      //                                         height: 20px;
      //                                         justify-content: center;
      //                                         background: #fff;
      //                                         object-position: center;
      //                                         border: 1px solid #ffffff;
      //                                         border-radius: 30px;
      //                                         margin: 0 0 0 -10px;

      //                                         &:first-child {
      //                                             margin: 0;
      //                                         }

      //                                         img {
      //                                             width: 100%;
      //                                             border-radius: 30px;
      //                                             object-fit: cover !important;
      //                                         }
      //                                     }
      //                                 }

      //                                 .clubModaCount {
      //                                     margin-left: -10px;
      //                                     border-radius: 12px;
      //                                     border: 1px solid #FFF;
      //                                     background: #1E2432;
      //                                     border-radius: 12px;
      //                                     font-weight: 500;
      //                                     font-size: 12px;
      //                                     line-height: 18px;
      //                                     letter-spacing: 0.1px;
      //                                     color: #ffffff;
      //                                     padding: 0px 9px;
      //                                 }
      //                             }
      //                         }

      //                         .clubModalRightSideContent {
      //                             button {
      //                                 border-radius: 8px;
      //                                 opacity: 0.6000000238418579;
      //                                 background: #1E2432;
      //                                 padding: 0 32px;
      //                                 height: 32px;
      //                                 color: #FFF;
      //                                 text-align: center;
      //                                 font-size: 14px;
      //                                 font-family: 'Poppins', sans-serif;
      //                                 font-weight: 500;
      //                                 letter-spacing: 0.1px;
      //                                 display: flex;
      //                                 align-items: center;
      //                                 justify-content: center;
      //                                 cursor: pointer;
      //                                 color: #fff;
      //                             }
      //                         }
      //                     }
      //                 }

      //                 h5 {
      //                     font-weight: 400;
      //                     font-size: 12px;
      //                     line-height: 18px;
      //                     color: #fff;
      //                     margin: 0 0 18px 0;
      //                 }

      //                 .clubModalLimeCamp {
      //                     display: -webkit-box;
      //                     -webkit-line-clamp: 2;
      //                     -webkit-box-orient: vertical;
      //                     overflow: hidden;
      //                 }

      //                 h4 {
      //                     font-weight: 500;
      //                     font-size: 16px;
      //                     line-height: 24px;
      //                     color: #fff;
      //                     margin: 0 0 1px 0;
      //                     cursor: pointer;
      //                 }
      //             }
      //         }

      //         .clubModalblurDiv {
      //             position: absolute;
      //             background: linear-gradient(360deg, rgba(30, 36, 50, 0.8) 0%, rgba(30, 36, 50, 0) 100%);
      //             border-radius: 0px;
      //             bottom: 0;
      //             left: 0;
      //             width: 100%;
      //             height: 251px;
      //         }
      //     }
      // }
    }

    :global(.slick-prev) {
      left: 21px;
      z-index: 99;
    }

    :global(.slick-next) {
      right: 21px;
      z-index: 99;
    }

    :global(.slick-arrow:hover) {
      background: #fff !important;
    }

    .noFollowingStoreDetailsAlignment {
      padding: 84px 0;

      .noFollowingImg {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 16px;

        p {
          color: var(--secondary-300);
          text-align: center;
          font-family: 'Poppins', sans-serif;
          font-size: 16px;
          font-style: italic;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
  }
}
