@mixin off($display) {
  display: $display;
  visibility: hidden;
  touch-action: none;
  pointer-events: none;
}

@mixin on($display) {
  display: $display;
  visibility: visible;
  touch-action: auto;
  pointer-events: all;
}

@mixin block_btn {
  background: var(--primary-500);
  border-radius: 8px;
  display: block;
  width: 100%;
  border: unset;
  outline: unset;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    transform: translateY(-3px);
    background: #2844ac;
  }

  @media only screen and (max-width: 799px) {
    font-weight: 500;
    padding: 6px 16px;
  }
}

.mens-category-section {
  
  @media (max-width:576px) {
    padding: 0 0 38px 0;
  }

  // background: #1d2330;
  // padding-bottom: 32px !important;
  .section-1 {

    // @media only screen and (max-width: 799px) {
    //   background: #1d2330;
    //   padding-bottom: 0;
    // }
    .banner-section-alignment {
      display: flex;
      align-items: flex-start;

      @media only screen and (max-width: 1199px) {
        flex-direction: column-reverse;
      }

      .first-part-alignment {
        max-width: 472px;
        width: 472px;
        min-width: 472px;

        @media only screen and (max-width: 1299px) {
          max-width: 37%;
          width: 37%;
          min-width: 37%;
        }

        @media only screen and (max-width: 1199px) {
          max-width: 100%;
          width: 100%;
          min-width: 100%;
          display: flex;
          overflow-y: hidden;
          // overflow-x: hidden;
        }

        .first-child-details-alignment {
          height: 222px;
          width: 100%;
          background: #808191;
          position: relative;
          overflow: hidden;

          &:hover {
            transition: 0.2s ease-in-out;

            .category-img-alignment {
              transition: 0.2s ease-in-out;

              img {
                transition: 0.2s ease-in-out;
                transform: scale(1.2);
              }
            }

            .category-details-alignment {
              transition: 0.2s ease-in-out;

              span {
                transition: 0.2s ease-in-out;
                animation: SpanAlign 0.2s ease-in-out;
              }

              @keyframes SpanAlign {
                from {
                  transform: translateX(-100%);
                }

                to {
                  transform: translateX(0%);
                }
              }

              .brand-name-alignment {
                transition: 0.2s ease-in-out;

                h6 {
                  transition: 0.2s ease-in-out;
                  animation: SpanAlign2 0.3s ease-in-out;
                }

                @keyframes SpanAlign2 {
                  from {
                    transform: translateX(-100%);
                  }

                  to {
                    transform: translateX(0%);
                  }
                }
              }

              .shop-now-button-alignment {
                transition: 0.2s ease-in-out;

                button {
                  transition: 0.2s ease-in-out;
                  animation: SpanAlign3 0.4s ease-in-out;
                }

                @keyframes SpanAlign3 {
                  from {
                    transform: translateX(-100%);
                  }

                  to {
                    transform: translateX(0%);
                  }
                }
              }
            }
          }

          @media only screen and (max-width: 1199px) {
            min-width: 460px;
          }

          @media only screen and (max-width: 799px) {
            min-width: 314px;
            height: 148px;
          }

          .category-img-alignment {
            position: absolute;
            bottom: 0;
            right: 0;
            height: 100%;
            transition: 0.2s ease-in-out;

            img {
              transition: 0.2s ease-in-out;
              width: auto;
              display: block;
              height: 100%;

              @media only screen and (max-width: 1199px) {
                width: 178px;
              }
            }
          }

          .category-details-alignment {
            padding: 0 0 0 39px;

            @media only screen and (max-width: 799px) {
              padding: 0 0 0 26px;
            }

            span {
              transform: translateX(0);
              font-family: 'Arial';
              font-style: italic;
              font-weight: 400;
              font-size: 12px;
              line-height: 14px;
              color: #f8f8f8;
              padding: 58px 0 0 0;
              display: block;

              @media only screen and (max-width: 1199px) {
                font-size: 8px;
                line-height: 9px;
                padding: 36px 0 0 0;
              }

              @media only screen and (max-width: 576px) {
                transform: translateX(0) !important;
              }
            }

            .brand-name-alignment {
              @media only screen and (max-width: 799px) {
                margin: 5px 0 0 0;
              }

              h6 {
                transform: translateX(0);
                max-width: 143px;
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 24px;
                line-height: 36px;
                text-transform: uppercase;
                color: #000000;

                @media only screen and (max-width: 1199px) {
                  font-size: 16px;
                  line-height: 24px;
                  max-width: 95px;
                }

                @media only screen and (max-width: 576px) {
                  transform: translateX(0) !important;
                }
              }
            }

            h5 {
              transform: translateX(0);
              font-family: 'Helvetica';
              font-weight: 700;
              font-size: 26px;
              line-height: 30px;
              color: #f7f7f7;
              max-width: 194px;
              padding: 32px 0 0 0;

              @media only screen and (max-width: 576px) {
                transform: translateX(0) !important;
              }
            }

            p {
              transform: translateX(0);
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              color: #ffffff;
              padding: 11px 0 0 0;
              max-width: 194px;

              @media only screen and (max-width: 576px) {
                transform: translateX(0) !important;
              }
            }

            .shop-now-button-alignment {
              margin: 16px 0 0 0;

              @media only screen and (max-width: 799px) {
                margin: 11px 0 0 0;
              }

              button {
                transform: translateX(0);
                padding: 6px 26px;
                background: #272628;
                border-radius: 8px;
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                text-align: center;
                color: #ffffff;
                cursor: pointer;
                border: 1px solid #272628;
                transition: 0.3s ease-in-out;

                @media only screen and (max-width: 1199px) {
                  padding: 4px 16px;
                  font-size: 12px;
                  line-height: 14px;
                }

                @media only screen and (max-width: 576px) {
                  transform: translateX(0) !important;
                }

                &:hover {
                  transition: 0.3s ease-in-out;
                  background-color: transparent;
                  color: #272628;
                }
              }
            }
          }
        }

        .second-child-details-alignment {
          height: 222px;
          width: 100%;
          background: #1b1a1a;
          position: relative;
          overflow: hidden;

          &:hover {
            transition: 0.2s ease-in-out;

            .second-category-img-alignment {
              transition: 0.2s ease-in-out;

              img {
                transition: 0.2s ease-in-out;
                transform: scale(1.2);
              }
            }

            h5 {
              transition: 0.2s ease-in-out;
              animation: SpanAlign4 0.2s ease-in-out;
            }

            @keyframes SpanAlign4 {
              from {
                transform: translateX(100%);
              }

              to {
                transform: translateX(0%);
              }
            }

            p {
              transition: 0.2s ease-in-out;
              animation: SpanAlign5 0.3s ease-in-out;
            }

            @keyframes SpanAlign5 {
              from {
                transform: translateX(100%);
              }

              to {
                transform: translateX(0%);
              }
            }

            button {
              transition: 0.2s ease-in-out;
              animation: SpanAlign6 0.4s ease-in-out;
            }

            @keyframes SpanAlign6 {
              from {
                transform: translateX(100%);
              }

              to {
                transform: translateX(0%);
              }
            }
          }

          @media only screen and (max-width: 1199px) {
            min-width: 460px;
          }

          @media only screen and (max-width: 799px) {
            min-width: 314px;
            height: 148px;
          }

          .second-category-img-alignment {
            position: absolute;
            bottom: 0;
            left: 44px;
            top: 0;
            transition: 0.2s ease-in-out;

            img {
              transition: 0.2s ease-in-out;
              width: auto;
              display: block;

              @media only screen and (max-width: 1199px) {
                width: 116px;
              }
            }
          }

          .second-category-details-alignment {
            padding: 32px 25px 0 0;
            display: flex;
            align-items: flex-end;
            flex-direction: column;

            @media only screen and (max-width: 1199px) {
              padding: 8px 8px 0 0;
            }

            .second-category-width-alignemnt {
              max-width: 194px;
              width: 194px;

              @media only screen and (max-width: 1199px) {
                max-width: 180px;
                width: 180px;
              }

              h5 {
                transform: translateX(0%);
                font-family: 'Helvetica';
                font-weight: 700;
                font-size: 26px;
                line-height: 30px;
                color: #f7f7f7;

                @media only screen and (max-width: 1199px) {
                  font-size: 16px;
                  line-height: 24px;
                  text-align: end;
                }

                @media only screen and (max-width: 576px) {
                  transform: translateX(0) !important;
                }
              }

              p {
                transform: translateX(0%);
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #ffffff;
                padding: 11px 0 0 0;

                @media only screen and (max-width: 1199px) {
                  font-size: 12px;
                  line-height: 16px;
                  padding: 4px 0 0 0;
                  text-align: end;
                }

                @media only screen and (max-width: 576px) {
                  transform: translateX(0) !important;
                }
              }

              .second-shop-now-button-alignment {
                padding: 16px 0 0 0;

                @media only screen and (max-width: 1199px) {
                  text-align: end;
                  padding: 8px 0 0 0;
                }

                button {
                  transform: translateX(0%);
                  padding: 6px 26px;
                  background: #e7f3fc;
                  border-radius: 8px;
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;
                  text-align: center;
                  color: #c54c45;
                  cursor: pointer;
                  border: 1px solid #e7f3fc;

                  @media only screen and (max-width: 1199px) {
                    padding: 4px 16px;
                  }

                  @media only screen and (max-width: 576px) {
                    transform: translateX(0) !important;
                  }

                  &:hover {
                    transition: 0.3s ease-in-out;
                    background-color: transparent;
                    color: #e7f3fc;
                  }
                }
              }
            }
          }
        }

        .third-child-details-alignment {
          height: 222px;
          width: 100%;
          background: #3e4049;
          position: relative;
          overflow: hidden;

          @media only screen and (max-width: 1199px) {
            min-width: 460px;
          }

          @media only screen and (max-width: 799px) {
            min-width: 314px;
            height: 148px;
          }

          &:hover {
            transition: 0.2s ease-in-out;

            .third-category-img-alignment {
              transition: 0.2s ease-in-out;

              img {
                transition: 0.2s ease-in-out;
                transform: scale(1.2);
              }
            }

            .third-category-width-alignemnt {
              transition: 0.2s ease-in-out;

              h5 {
                transition: 0.2s ease-in-out;
                animation: SpanAlign7 0.2s ease-in-out;
              }

              @keyframes SpanAlign7 {
                from {
                  transform: translateX(-100%);
                }

                to {
                  transform: translateX(0%);
                }
              }

              p {
                transition: 0.2s ease-in-out;
                animation: SpanAlign8 0.3s ease-in-out;
              }

              @keyframes SpanAlign8 {
                from {
                  transform: translateX(-100%);
                }

                to {
                  transform: translateX(0%);
                }
              }

              .third-shop-now-button-alignment {
                transition: 0.2s ease-in-out;

                button {
                  transition: 0.4s ease-in-out;
                  animation: SpanAlign9 0.3s ease-in-out;
                }

                @keyframes SpanAlign9 {
                  from {
                    transform: translateX(-100%);
                  }

                  to {
                    transform: translateX(0%);
                  }
                }
              }
            }
          }

          .third-category-img-alignment {
            position: absolute;
            bottom: 0;
            right: 21px;
            transition: 0.2s ease-in-out;

            img {
              transition: 0.2s ease-in-out;
              width: auto;
              display: block;

              @media only screen and (max-width: 1199px) {
                width: 116px;
              }
            }
          }

          .third-category-details-alignment {
            padding: 32px 0 0 39px;
            transition: 0.2s ease-in-out;

            // display: flex;
            // align-items: flex-end;
            // flex-direction: column;

            @media only screen and (max-width: 1199px) {
              padding: 8px 0 0 8px;
            }

            .third-category-width-alignemnt {
              max-width: 194px;
              width: 194px;

              h5 {
                transform: translateX(0);
                font-family: 'Helvetica';
                font-weight: 700;
                font-size: 26px;
                line-height: 30px;
                color: #f7f7f7;

                @media only screen and (max-width: 1199px) {
                  font-size: 16px;
                  line-height: 24px;
                  text-align: start;
                }

                @media only screen and (max-width: 576px) {
                  transform: translateX(0) !important;
                }
              }

              p {
                transform: translateX(0);
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #ffffff;
                padding: 11px 0 0 0;

                @media only screen and (max-width: 1199px) {
                  font-size: 12px;
                  line-height: 16px;
                  padding: 4px 0 0 0;
                  text-align: start;
                }

                @media only screen and (max-width: 576px) {
                  transform: translateX(0) !important;
                }
              }

              .third-shop-now-button-alignment {
                padding: 16px 0 0 0;

                button {
                  transform: translateX(0);
                  padding: 6px 26px;
                  background: #272628;
                  border-radius: 8px;
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;
                  text-align: center;
                  color: #ffffff;
                  cursor: pointer;
                  border: 1px solid #272628;

                  &:hover {
                    transition: 0.3s ease-in-out;
                    background-color: transparent;
                    color: #ffffff;
                    border-color: #ffffff;
                  }

                  @media only screen and (max-width: 576px) {
                    transform: translateX(0) !important;
                  }
                }
              }
            }
          }
        }
      }

      .second-part-alignment {
        width: 100%;
        height: 666px;

        @media only screen and (max-width: 899px) {
          height: 444px;
        }

        @media only screen and (max-width: 799px) {
          height: 204px;
        }

        @media only screen and (max-width: 1299px) {
          width: 63%;
        }

        @media only screen and (max-width: 1199px) {
          max-width: 100%;
          width: 100%;
          min-width: 100%;
        }

        // .slick-arrow {
        //   display: none !important;
        // }

        .slick-dots {
          top: 90% !important;

          .slick-active {
            width: 24px;

            button {
              &::before {
                width: 24px;
                background: #ffe500;
              }
            }
          }
        }

        .second-part-details-alignemnt {
          position: relative;
          width: 100%;
          height: 666px;

          @media only screen and (max-width: 899px) {
            height: 444px;
          }

          @media only screen and (max-width: 799px) {
            height: 205px;
          }

          .banner-img-alignment {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;

            &::before {
              content: '';
              position: absolute;
              background: linear-gradient(60.23deg, #000000 1.38%, rgba(0, 11, 52, 0) 100%);
              width: 100%;
              height: 100%;
              top: 0;
              right: 0;
            }

            img {
              width: 100%;
              height: 100%;
              display: block;
              object-fit: cover;

              @media screen and (max-width: 1499px) {
                width: auto;
              }

              @media screen and (max-width: 1199px) {
                width: -webkit-fill-available;
              }

              @media only screen and (max-width: 799px) {
                width: 100%;
              }
            }

            video {
              width: 100%;
              height: 100%;
              display: block;
              object-fit: cover;

              @media screen and (max-width: 1499px) {
                width: auto;
              }

              @media screen and (max-width: 1199px) {
                width: -webkit-fill-available;
              }

              @media only screen and (max-width: 799px) {
                width: 100%;
              }
            }
          }

          .banner-details-alignment {
            position: absolute;
            bottom: 108px;
            left: 108px;

            @media only screen and (max-width: 799px) {
              bottom: 24px;
              left: 16px;
            }

            .name-alignment {
              h4 {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 40px;
                line-height: 60px;
                color: #ffffff;

                @media only screen and (max-width: 799px) {
                  font-size: 20px;
                  line-height: 30px;
                }
              }

              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #ffffff;
                max-width: 365px;

                @media only screen and (max-width: 799px) {
                  font-size: 12px;
                  line-height: 18px;
                  max-width: 274px;
                }
              }
            }

            .banner-button-alignment {
              padding: 16px 0 0 0;

              button {
                padding: 10px 56px;
                font-family: 'Poppins', sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                background-color: #ffffff;
                // background: linear-gradient(94.09deg, #556EE6 0.21%, #6B21A8 100%);
                // -webkit-background-clip: text;
                // -webkit-text-fill-color: transparent;
                // background-clip: text;
                // text-fill-color: transparent;
                color: var(--primary-500);
                border-radius: 10px;
                cursor: pointer;

                @media only screen and (max-width: 799px) {
                  padding: 6px 24px;
                  font-size: 14px;
                  line-height: 21px;
                }
              }
            }
          }
        }
      }
    }
  }

  .mens-search-alignment {
    padding: 32px 0;
    background: #1d2330;

    @media only screen and (max-width: 899px) {
      display: none;
    }

    .container {
      width: 1310px;

      @media only screen and (max-width: 999px) {
        max-width: 700px;
        min-width: 700px;
        width: 700px;
      }
    }

    .search_input {
      display: block;
      width: 100%;
      text-align: left;
      background: #f1f0f0;
      border-radius: 16px;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: var(--secondary-900);
      padding: 21px 35%;
      border: 1px solid #f8f8f8;
      background-size: 42px;
      background-image: url(../../../assets/img/WomansCategory/search.png);
      background-repeat: no-repeat;
      background-position: 32% center;
      transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

      &::placeholder {
        color: #acb1c0;
        opacity: 1;
        transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
      }

      &:focus {
        border: 1px solid #556ee6;
        // background-image: none;
      }

      &:focus::placeholder {
        opacity: 0;
      }
    }
  }

  .section-2 {
    background: #111111;
    padding: 64px 0;

    @media only screen and (max-width: 799px) {
      padding: 0;
      display: none;
    }

    .container {
      max-width: 1310px;
      padding: 0 20px;

      @media only screen and (max-width: 1299px) {
        max-width: 1200px;
        min-width: 1200px;
      }

      @media only screen and (max-width: 1199px) {
        max-width: 950px;
        min-width: 950px;
      }

      @media only screen and (max-width: 999px) {
        max-width: 700px;
        min-width: 700px;
      }

      @media only screen and (max-width: 899px) {
        max-width: 100%;
        min-width: 100%;
        padding: 0;
      }

      .shop-by-category-alignment {
        padding: 0 70px;

        .slick-arrow {
          z-index: 99 !important;
        }

        @media only screen and (max-width: 899px) {
          padding: 0;
        }

        .shop-by-category-heading-alignment {
          text-align: center;

          @media only screen and (max-width: 899px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 16px;
          }

          @media only screen and (max-width: 799px) {
            display: none;
          }

          h4 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 36px;
            line-height: 54px;
            text-transform: uppercase;
            color: #f8f8f8;

            @media only screen and (max-width: 899px) {
              font-size: 20px;
              line-height: 30px;
            }
          }

          .see-all-alignment {
            @media only screen and (max-width: 899px) {
              display: none;
            }

            a {
              font-family: 'Poppins', sans-serif;
              font-weight: 300;
              font-size: 18px;
              line-height: 27px;
              color: var(--primary-500);
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 8px;
              cursor: pointer;
            }
          }

          .mobile-view-see-more-alignment {
            display: none;

            @media only screen and (max-width: 899px) {
              display: block;
            }

            a {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              color: var(--primary-500);
              cursor: pointer;
            }
          }
        }

        .shop-by-category-details-alignment {
          margin: 40px 0 0 0;

          @media only screen and (max-width: 899px) {
            margin: 32px 0 0 0;
          }

          @media only screen and (max-width: 799px) {
            margin: 0;
          }

          // .slick-dots {
          //     display: none !important;
          // }

          .shop-by-cateogry-details-names {
            // padding: 0 8px;

            &:hover {
              cursor: pointer;

              .cover-alignment {
                border: 1px solid #556ee6;
              }

              .shop-by-product-name-alignment {
                // padding: 0;

                p {
                  color: #f8f8f8;
                  font-weight: 600;
                  font-size: 18px;
                  line-height: 27px;
                }
              }
            }

            .cover-alignment {
              width: 110px;
              height: 110px;
              margin: auto;
              border: 1px solid transparent;
              background: #111111;
              padding: 6px;
              border-radius: 10px;

              @media only screen and (max-width: 899px) {
                width: 100%;
                height: 100%;
              }

              // &:hover {
              //     border: 1px solid #556EE6;
              //     border-radius: 10px;
              //     padding: 6px;
              //     cursor: pointer;
              // }

              .shop-by-category-img-aligment {
                // width: 98px;
                height: 98px;
                position: relative;

                @media only screen and (max-width: 899px) {
                  height: 100%;
                }

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 8px;
                }

                &::before {
                  position: absolute;
                  content: '';
                  width: 100%;
                  height: 100%;
                  background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 70.41%);
                  border-radius: 6px;
                  top: 0;
                  right: 0;
                }
              }
            }

            .shop-by-product-name-alignment {
              padding: 2px 0 0 0;

              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #acb1c0;
              }
            }
          }
        }
      }
    }
  }

  .section-2_mobileView {
    display: none;

    @media only screen and (max-width: 799px) {
      display: block;
    }

    .container {
      width: 100%;
      max-width: 100%;
      padding: 0;
    }

    .shopBy-mobile-header-alignment {
      padding: 32px 16px 8px 16px;
      background: #1e2432;
      display: flex;
      align-items: center;
      justify-content: space-between;

      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #f8f8f8;
      }

      .mobile-seeAll-details {
        a {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: var(--primary-500);
          cursor: pointer;
        }
      }
    }

    .shopBy-mobile-details-alignment {
      background: #111111;
      padding: 16px 0 10px 16px;
      // width: 100%;
      // overflow: scroll;

      // .shopBy-mobile-grid-alignment {
      //   display: grid;
      //   grid-template-columns: repeat(5, 1fr);
      //   gap: 12px;
      //   padding: 16px;
      //   width: 100%;
      //   overflow: scroll;

      .mobile_shop-by-cateogry-details-names {
        // padding: 0 8px;

        &:hover {
          cursor: pointer;

          .mobile_cover-alignment {
            border: 1px solid #556ee6;
            width: 68px;
            height: 68px;
          }

          .mobile_shop-by-product-name-alignment {
            // padding: 0;

            p {
              color: #f8f8f8;
              font-weight: 600;
              font-size: 12px;
              line-height: 27px;
            }
          }
        }

        //     .mobile_cover-alignment {
        //   width: 60px;
        //   height: 60px;
        //   margin: auto;
        //   border: 1px solid transparent;
        //   background: #111111;
        //   // padding: 6px;
        //   border-radius: 10px;

        //   .mobile_shop-by-category-img-aligment {
        //     width: 60px;
        //     height: 60px;
        //     position: relative;
        //     margin: auto;

        //     img {
        //       width: 100%;
        //       height: 100%;
        //       border-radius: 8px;
        //     }

        //     &::before {
        //       position: absolute;
        //       content: '';
        //       width: 100%;
        //       height: 100%;
        //       background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 70.41%);
        //       border-radius: 6px;
        //       top: 0;
        //       right: 0;
        //     }
        //   }
        // }

        // .mobile_shop-by-product-name-alignment {
        //   // padding: 0;

        //   p {
        //     color: #f8f8f8;
        //     font-weight: 600;
        //     font-size: 18px;
        //     line-height: 27px;
        //   }
        // }

        .mobile_cover-alignment {
          width: 68px;
          height: 68px;
          margin: auto;
          border: 1px solid transparent;
          background: #111111;
          // padding: 6px;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;

          .mobile_shop-by-category-img-aligment {
            width: 60px;
            height: 60px;
            position: relative;
            margin: auto;

            img {
              width: 100%;
              height: 100%;
              border-radius: 8px;
            }

            &::before {
              position: absolute;
              content: '';
              width: 100%;
              height: 100%;
              background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 70.41%);
              border-radius: 6px;
              top: 0;
              right: 0;
            }
          }
        }

        .mobile_shop-by-product-name-alignment {
          padding: 4px 0 0 0;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            color: #acb1c0;
            margin-bottom: 6px;
          }
        }
      }

      // }
    }
  }

  .section-3 {
    background: #1d2330;
    padding: 32px 0;
    overflow-x: hidden;

    @media only screen and (max-width: 799px) {
      padding: 0;
    }

    .now-trending-section {
      .mobile_now-trending-heading-alignment {
        padding: 32px 16px 8px 16px;
        background: #1e2432;
        display: flex;
        align-items: center;
        justify-content: space-between;
        display: none;

        @media only screen and (max-width: 799px) {
          display: flex;
        }

        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: #f8f8f8;
        }

        .mobile-seeAll-details {
          a {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: var(--primary-500);
            cursor: pointer;
          }
        }
      }

      .now-trending-bg {
        background: url('../../../assets/img/MensCategory/trendingBg.png');
        backdrop-filter: blur(151px);
        padding: 42px 0;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;

        :global(.slick-arrow) {
          @media (max-width:576px) {
            display: none !important;
          }
        }

        .container {
          max-width: 1310px;
          padding: 0 20px;

          @media only screen and (max-width: 1199px) {
            max-width: 950px;
            min-width: 950px;
          }

          @media only screen and (max-width: 799px) {
            max-width: 100%;
            min-width: 100%;
          }

          .now-trending-details-flex {
            display: flex;
            align-items: center;
            gap: 140px;

            @media only screen and (max-width: 1199px) {
              gap: 100px;
            }

            .now-trending-heading-alignment {
              max-width: 181px;
              width: 181px;
              min-width: 181px;

              @media only screen and (max-width: 1199px) {
                width: 155px;
                max-width: 155px;
                min-width: 155px;
              }

              @media only screen and (max-width: 799px) {
                display: none;
              }

              h4 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 36px;
                line-height: 54px;
                text-transform: uppercase;
                color: #f8f8f8;
              }

              .trending-see-all-alignment {
                padding: 4px 0 0 0;

                a {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 300;
                  font-size: 18px;
                  line-height: 27px;
                  color: var(--primary-500);
                  display: flex;
                  align-items: center;
                  gap: 4px;
                  cursor: pointer;
                }
              }
            }

            .now-trending-details-alignment {
              width: 921px;
              // min-width: 921px;
              max-width: 921px;

              @media only screen and (max-width: 1299px) {
                max-width: 870px;
                width: 870px;
              }

              @media only screen and (max-width: 1199px) {
                width: 650px;
                max-width: 650px;
                min-width: 650px;
              }

              @media only screen and (max-width: 799px) {
                width: 100%;
                max-width: 100%;
                min-width: 100%;
              }

              .card_col {
                // width: 100%;
                padding: 0 8px;
                // height: 555px;
                height: 537px;
                display: grid;

                // @media only screen and (max-width : 799px) {
                //   max-width: 260px;
                //   width: 260px;
                //   min-width: 260px;
                // }

                .cardcover {
                  width: 100%;
                  border-radius: 10px;
                  position: relative;
                  overflow: hidden;
                  cursor: pointer;
                  margin-bottom: 15px;

                  .card_top {
                    height: 100%;
                  }

                  &:hover {
                    .card_top img {
                      transform: scale(1.05);
                    }

                    .card_bottom {
                      @include on(flex);
                    }
                  }

                  .card_top img {
                    width: 100%;
                    height: 100%;
                    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                    transform: scale(1.02);
                    // object-fit: cover;
                  }

                  .card_bottom {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(180deg, rgba(30, 36, 50, 0) 0%, #1e2432 100%);
                    padding: 16px;
                    display: flex;
                    align-items: flex-end;
                    @include off(none);

                    .card_bottom_cover {
                      width: 100%;

                      h4 {
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 30px;
                        color: #f8f8f8;
                      }

                      .amount {
                        margin: 5px 0 8px 0;

                        @media only screen and (max-width: 799px) {
                          display: none;
                        }

                        span {
                          vertical-align: middle;
                        }

                        .price {
                          font-weight: 600;
                          font-size: 18px;
                          line-height: 27px;
                          color: #f8f8f8;
                        }

                        .orignal_price {
                          text-decoration-line: line-through;
                          font-weight: 500;
                          font-size: 12px;
                          line-height: 18px;
                          color: #ffffff;
                          opacity: 0.6;
                          margin: 0 6px;
                        }

                        .discount {
                          font-weight: 500;
                          font-size: 12px;
                          line-height: 18px;
                          text-align: center;
                          color: #ffffff;
                          background: #ff7d23;
                          border-radius: 11px;
                          padding: 2px 6px;
                        }
                      }

                      .hike {
                        display: flex;
                        align-items: center;

                        .inter {
                          display: flex;
                          align-items: center;
                          font-size: 12px;
                          line-height: 18px;
                          color: #f8f8f8;

                          img {
                            margin-right: 4px;
                          }
                        }

                        .incris {
                          margin-left: 15px;
                          display: flex;
                          align-items: center;
                          font-weight: 600;
                          font-size: 14px;
                          line-height: 21px;
                          color: #70c217;

                          img {
                            margin-right: 4px;
                          }
                        }
                      }

                      .rating {
                        display: flex;
                        align-items: center;
                        margin: 9px 0 18px 0;

                        @media only screen and (max-width: 799px) {
                          display: none;
                        }

                        .rating_point {
                          font-weight: 600;
                          font-size: 16px;
                          line-height: 24px;
                          color: #f8f8f8;
                        }

                        .stars {
                          display: flex;
                          align-items: center;
                          margin: 0 8px;
                        }

                        .num {
                          font-size: 16px;
                          line-height: 24px;
                          color: #f8f8f8;
                        }
                      }

                      .buy_btn {
                        @include block_btn();

                        @media only screen and (max-width: 799px) {
                          display: none;
                        }
                      }
                    }

                    .MenCardContent {
                      height: 100%;
                      display: flex;
                      flex-direction: column;
                      gap: 8px;
                      justify-content: end;

                      h1 {
                        color: #f8f8f8;
                        font-family: Poppins;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                      }

                      .WomenCardPrice {
                        display: flex;
                        align-items: end;
                        justify-content: start;
                        gap: 11px;

                        p {
                          color: #f8f8f8;
                          font-family: Poppins;
                          font-size: 14px;
                          font-style: normal;
                          font-weight: 600;
                          line-height: normal;
                        }

                        del {
                          color: #999999;
                          font-family: Poppins;
                          font-size: 12px;
                          font-style: normal;
                          font-weight: 500;
                          line-height: normal;
                          text-decoration-line: strikethrough;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .section-4 {
    background: #111111;
    padding: 64px 0 126px 0;

    @media only screen and (max-width: 799px) {
      padding: 0;
    }

    .container {
      max-width: 1310px;
      padding: 0 20px;

      @media only screen and (max-width: 1299px) {
        max-width: 1200px;
        min-width: 1200px;
      }

      @media only screen and (max-width: 1199px) {
        max-width: 950px;
        min-width: 950px;
      }

      @media only screen and (max-width: 999px) {
        max-width: 700px;
        min-width: 700px;
      }

      @media only screen and (max-width: 899px) {
        max-width: 100%;
        min-width: 100%;
        padding: 0;
      }

      .slick-arrow {
        z-index: 99 !important;

        @media (max-width:576px) {
          display: none !important;
        }
      }

      .find-your-style-alignment {
        .find-your-style-heading-alignment {
          text-align: center;

          @media only screen and (max-width: 899px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 16px;
          }

          @media only screen and (max-width: 799px) {
            display: none;
          }

          h4 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 36px;
            line-height: 54px;
            text-transform: uppercase;
            color: #f8f8f8;

            @media only screen and (max-width: 899px) {
              font-size: 20px;
              line-height: 30px;
            }
          }

          .find-your-style-see-all-alignment {
            a {
              font-family: 'Poppins', sans-serif;
              font-weight: 300;
              font-size: 18px;
              line-height: 27px;
              color: var(--primary-500);
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 8px;
              cursor: pointer;
            }
          }
        }

        .mobile_find-your-style-heading-alignment {
          padding: 32px 16px 8px 16px;
          background: #1e2432;
          display: flex;
          align-items: center;
          justify-content: space-between;
          display: none;

          @media only screen and (max-width: 799px) {
            display: flex;
          }

          h4 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: #f8f8f8;
          }

          .mobile-seeAll-details {
            a {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              color: var(--primary-500);
              cursor: pointer;
            }
          }
        }

        .find-your-style-details-slider {
          margin: 48px 0 0 0;

          @media only screen and (max-width: 799px) {
            margin: 0;
            padding: 16px;
          }

          .slider-data-alignment {
            padding: 0 2px;
            cursor: pointer;
            // transform: translateY(-5px);

            .slick-arrow {
              z-index: 99 !important;
            }

            &:hover {
              .find-your-styles-data {
                border: 1px solid #a855f7;
                border-radius: 16px;
                padding: 6px 6px 8px 6px;

                .coverdata-alignment {
                  .bg-img-cover {
                    img {
                      transform: scale(1.05);
                      border-radius: 16px;
                    }
                  }
                }
              }
            }

            .find-your-styles-data {
              border: 1px solid transparent;
              border-radius: 16px;
              padding: 6px;
              transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
              height: 422px;
              max-height: 422px;
              min-height: 422px;

              // @media only screen and (max-width: '768px') {
              //   height: 205px;
              //   max-height: 205px;
              //   min-height: 205px;
              @media only screen and (max-width: '1099px') {
                height: 280px;
                max-height: 280px;
                min-height: 280px;
              }

              @media only screen and (max-width: '768px') {
                height: 190px;
                max-height: 190px;
                min-height: 190px;
              }

              .coverdata-alignment {
                width: 100%;
                position: relative;
                transition: all 0.4s ease-in-out;
                border-radius: 16px;
                overflow: hidden;
                max-height: 408px;
                height: 408px;

                @media only screen and (max-width: '1099px') {
                  height: 100%;
                  max-height: 100%;
                }

                @media only screen and (max-width: '768px') {
                  height: 173px;
                  max-height: 173px;
                }

                .bg-img-cover {
                  width: 100%;
                  height: 100%;
                  transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
                  object-fit: cover;

                  img {
                    width: 100%;
                    height: 100%;
                    border-radius: 16px;
                  }
                }

                .img-bg-alignment {
                  position: absolute;
                  top: 0;
                  right: 0;
                  width: 100%;
                  height: 100%;
                  background: linear-gradient(180deg, rgba(30, 36, 50, 0) 0%, #1e2432 100%);
                  border-radius: 13px;
                  z-index: 999;

                  .style-name-alignment {
                    width: 100%;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    height: 100%;
                    padding: 0 0 66px 0;

                    @media only screen and (max-width: 1099px) {
                      padding: 0 0 32px 0;
                    }

                    @media only screen and (max-width: 799px) {
                      padding: 16px;
                    }

                    p {
                      font-family: 'Poppins', sans-serif;
                      font-style: normal;
                      font-weight: 600;
                      font-size: 20px;
                      line-height: 30px;
                      text-align: center;
                      color: #f8f8f8;

                      @media only screen and (max-width: 799px) {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18px;
                      }
                    }
                  }
                }
              }
            }


          }
        }
      }
    }
  }

  .section-5 {
    padding: 32px 0;
    background: #1d2330;

    @media only screen and (max-width: 799px) {
      padding: 0;
    }

    .container {
      max-width: 1310px;
      padding: 0 20px;

      @media only screen and (max-width: 1299px) {
        max-width: 1200px;
        min-width: 1200px;
      }

      @media only screen and (max-width: 1199px) {
        max-width: 950px;
        min-width: 950px;
      }

      @media only screen and (max-width: 999px) {
        max-width: 700px;
        min-width: 700px;
      }

      @media only screen and (max-width: 899px) {
        max-width: 100%;
        min-width: 100%;
        padding: 0;
      }

      .fashionGroup-trendingStore-alignment {
        display: flex;
        gap: 32px;

        @media only screen and (max-width: 899px) {
          flex-wrap: wrap;
        }

        @media only screen and (max-width: 799px) {
          gap: 0;
        }

        .fashionGroup-alignment {
          width: 50%;
          background: #283043;
          border-radius: 16px;
          padding: 64px 104px 145px 104px;

          @media only screen and (max-width: 899px) {
            width: 100%;
            padding: 44px 80px 70px 80px;
          }

          @media only screen and (max-width: 799px) {
            padding: 0;
            order: 2;
          }

          .fashionGroup-heading-alignment {
            @media only screen and (max-width: 799px) {
              display: none;
            }

            text-align: center;

            h4 {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 36px;
              line-height: 54px;
              text-transform: uppercase;
              color: #f8f8f8;
              margin: 0;
            }

            .fashionGroup-see-all-alignment {
              padding: 4px 0 0 0;

              a {
                font-family: 'Poppins', sans-serif;
                font-weight: 300;
                font-size: 18px;
                line-height: 27px;
                color: var(--primary-500);
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;
                cursor: pointer;
              }
            }
          }

          .mobile_find-your-style-heading-alignment {
            padding: 32px 16px 8px 16px;
            background: #1e2432;
            display: flex;
            align-items: center;
            justify-content: space-between;
            display: none;

            @media only screen and (max-width: 799px) {
              display: flex;
            }

            h4 {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 20px;
              line-height: 30px;
              color: #f8f8f8;
            }

            .mobile-seeAll-details {
              a {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: var(--primary-500);
                cursor: pointer;
              }
            }
          }

          .mens-fashion_Group_slider {
            position: relative;
            cursor: pointer;
            margin: 0 -8px;
            padding: 92px 0 0 0;

            .mens-fashion-group-grid {
              display: grid;
              grid-template-columns: repeat(1, 1fr);
            }

            @media only screen and (max-width: 799px) {
              margin: 0;
              padding: 16px;
              background: #283043;
            }


            .mens-fashion_Group_slider_cover {
              padding: 0 8px;
              height: 251px;
              max-height: 251px;

              .card_fashion {
                width: 100%;
                position: relative;
                border-radius: 10px 10px 0px 0px;
                overflow: hidden;
                transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                height: 251px;
                max-height: 251px;

                &:hover {
                  transform: translateY(-5px);

                  .img_cover img {
                    transform: scale(1.05);
                  }
                }

                .img_cover {
                  object-fit: cover;
                  object-position: center;
                  max-height: 251px;
                  height: 100%;

                  img {
                    width: 100%;
                    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                    height: 100%;
                    object-fit: cover;
                  }
                }

                .card_details {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: flex-end;
                  padding: 15px;
                  background: linear-gradient(360deg, #0e1116 0%, rgba(30, 36, 50, 0) 100%);

                  .card_details_cover {
                    width: 100%;

                    h5 {
                      font-weight: 500;
                      font-size: 20px;
                      line-height: 30px;
                      color: #ffffff;
                    }

                    p {
                      font-weight: 400;
                      font-size: 12px;
                      line-height: 18px;
                      color: #ffffff;
                      margin: 4px 0 17px 0;

                      @media only screen and (max-width: 799px) {
                        margin: 4px 0 8px 0;
                      }
                    }

                    .users {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;

                      .left_part {
                        display: flex;
                        align-items: center;

                        .users_likes {
                          display: flex;
                          align-items: center;
                          margin-left: -10px;

                          .user_box {
                            width: 20px;
                            display: flex;
                            align-items: center;
                            height: 20px;
                            justify-content: center;
                            background: #fff;
                            object-position: center;
                            border: 1px solid #ffffff;
                            border-radius: 30px;

                            img {
                              width: 100%;
                              object-fit: cover !important;
                            }
                          }
                        }

                        .count {
                          margin-left: -10px;
                          background: var(--primary-500);
                          border: 1px solid #ffffff;
                          border-radius: 12px;
                          font-weight: 500;
                          font-size: 12px;
                          line-height: 18px;
                          letter-spacing: 0.1px;
                          color: #ffffff;
                          padding: 0px 9px;
                        }
                      }

                      .right_part {
                        max-width: 120px;

                        button {
                          @include block_btn();
                        }
                      }
                    }
                  }
                }
              }
            }

            .slick-arrow {
              z-index: 99 !important;
            }

            .slick-list {
              padding-top: 5px;
            }
          }
        }

        .trending-store-alignment {
          width: 50%;
          background: #111111;
          border-radius: 16px;
          padding: 64px 104px 92px 104px;

          @media only screen and (max-width: 899px) {
            width: 100%;
            padding: 44px 80px 70px 80px;
          }

          @media only screen and (max-width: 767px) {
            order: 1;
            // padding: 0 4px;
            padding: 0;
            // padding: 0;
          }

          .trending-store-heading-alignment {
            text-align: center;

            @media only screen and (max-width: 799px) {
              display: none;
            }

            h4 {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 36px;
              line-height: 54px;
              text-transform: uppercase;
              color: #f8f8f8;
              margin: 0;
            }

            .trending-store-see-all-alignment {
              padding: 4px 0 0 0;

              a {
                font-family: 'Poppins', sans-serif;
                font-weight: 300;
                font-size: 18px;
                line-height: 27px;
                color: var(--primary-500);
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;
                cursor: pointer;
              }
            }
          }

          .mobile_trending-store-heading-alignment {
            padding: 32px 16px 8px 16px;
            background: #1e2432;
            display: flex;
            align-items: center;
            justify-content: space-between;
            display: none;

            @media only screen and (max-width: 799px) {
              display: flex;
            }

            h4 {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 20px;
              line-height: 30px;
              color: #f8f8f8;
            }

            .mobile-seeAll-details {
              a {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: var(--primary-500);
                cursor: pointer;
              }
            }
          }

          .mens-trending_store_slider {
            position: relative;
            cursor: pointer;
            margin: 0 -8px;
            padding: 121px 0 0 0;

            @media only screen and (max-width: 799px) {
              margin: 0;
              padding: 16px 0 16px 16px;
            }

            .slick-arrow {
              z-index: 99 !important;
            }

            .mens-fashion-group-grid {
              display: grid;
              grid-template-columns: repeat(1, 1fr);
            }

            .mens-trending_store_slider_cover {
              padding: 0 8px;

              .card_store {
                width: 100%;
                border: 1px solid #eceef4;
                border-radius: 12px;
                background: #ffffff;
                overflow: hidden;
                transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

                &:hover {
                  transform: translateY(-4px);
                  border: 1px solid #ab67fc;

                  .card_content h5 {
                    color: #ab67fc;
                  }
                }

                .cardtop_store {
                  overflow: hidden;
                  border-radius: 12px;
                  width: 100%;
                  max-height: 145px;
                  height: 145px;
                  object-fit: cover;
                  object-position: center;

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }

                .card_content {
                  padding: 16px;
                  text-align: center;

                  h5 {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 30px;

                    color: #000000;

                    @media only screen and (max-width: 799px) {
                      font-size: 16px;
                      line-height: 24px;
                      margin: 0 0 2px 0;
                    }
                  }

                  h6 {
                    font-size: 14px;
                    line-height: 21px;
                    font-weight: 400;
                    color: #808191;

                    @media only screen and (max-width: 799px) {
                      font-size: 12px;
                      line-height: 18px;
                      padding: 0 0 9px 0;
                    }
                  }

                  .product {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    color: #808191;
                    margin: 16px 0 8px 0;

                    @media only screen and (max-width: 799px) {
                      font-size: 12px;
                      line-height: 18px;
                      margin: 0 0 2px 0;
                    }
                  }

                  .Visitor {
                    font-size: 14px;
                    line-height: 21px;
                    color: #808191;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;

                    @media only screen and (max-width: 799px) {
                      font-size: 12px;
                      line-height: 18px;
                    }

                    .pre {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }
                  }

                  button {
                    @include block_btn();
                    margin-top: 16px;

                    @media only screen and (max-width: 799px) {
                      font-size: 14px;
                      line-height: 21px;
                      white-space: nowrap;
                    }
                  }
                }
              }
            }

            .slick-list {
              padding-top: 5px;
            }
          }
        }
      }
    }
  }

  .section-6 {
    background: url('../../../assets/img/MensCategory/enthumsBg.png');
    width: 100%;
    background-repeat: no-repeat;
    // background-position: center center;
    background-size: cover;
    padding: 64px 0;

    @media only screen and (max-width: 799px) {
      padding: 0 0 10px 0;
      background: url('../../../assets/img/MensCategory/fashionEnthumsBg.png');
      background-position-y: bottom;
    }

    .container {
      max-width: 1310px;
      padding: 0 20px;

      @media only screen and (max-width: 1299px) {
        max-width: 1200px;
        min-width: 1200px;
      }

      @media only screen and (max-width: 1199px) {
        max-width: 950px;
        min-width: 950px;
      }

      @media only screen and (max-width: 999px) {
        max-width: 700px;
        min-width: 700px;
      }

      @media only screen and (max-width: 899px) {
        max-width: 100%;
        min-width: 100%;
        padding: 0;
      }

      .men-category-enthusiats-alignment {
        .men-category-enthusiats-heading {
          text-align: center;

          @media only screen and (max-width: 799px) {
            display: none;
          }

          h4 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 36px;
            line-height: 54px;
            text-transform: uppercase;
            color: #f8f8f8;
            padding: 0 0 10px 0;
          }

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 300;
            font-size: 18px;
            line-height: 27px;
            color: #f8f8f8;
          }

          .men-category-enthusiats-see-all-alignment {
            a {
              font-family: 'Poppins', sans-serif;
              font-weight: 300;
              font-size: 18px;
              line-height: 27px;
              color: var(--primary-500);
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 4px;
              cursor: pointer;
            }
          }
        }

        .mobile_category-enthusiats-alignment {
          padding: 32px 16px 8px 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: #1e2432;
          display: none;

          @media only screen and (max-width: 799px) {
            display: flex;
          }

          h4 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: #f8f8f8;
            max-width: 179px;
          }

          .mobile-seeAll-details {
            a {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              color: var(--primary-500);
              cursor: pointer;
            }
          }
        }

        .men-category-enthusiats-body-alignment {
          margin: 45px 0 0 0;

          @media only screen and (max-width: 799px) {
            margin: 0;
          }

          .men-category-enthusiats-slider {
            @media only screen and (max-width: 799px) {
              padding: 16px 0 0 16px;
            }

            .men-category-enthusiats-grid {
              display: grid;
              grid-template-columns: repeat(5, 1fr);
            }

            .men-category-enthusiats-details {
              padding: 0 8px;

              .image-center-alignment {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 0 16px 0;

                img {
                  width: 200px;
                  height: 200px;
                  border-radius: 50%;
                  object-fit: cover;
                  display: block;

                  @media only screen and (max-width: '767px') {
                    width: 140px;
                    height: 140px;
                  }
                }
              }

              .line-clamp {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 22px;
                line-height: 33px;
                color: #000000;
                text-align: center;
                cursor: pointer;

                @media only screen and (max-width: 799px) {
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                }
              }

              p {
                margin: 0 0 4px 0;
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #495057;

                @media only screen and (max-width: 799px) {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 18px;
                  margin: 0 0 8px 0;
                }
              }

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                text-align: center;
                display: block;
                color: #495057;

                @media only screen and (max-width: '768px') {
                  font-size: 12px;
                  line-height: 18px;
                }
              }

              .button-center-alignment {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 16px 0 0 0;

                button {
                  border: none;
                  cursor: pointer;
                  padding: 6px 47px;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 21px;
                  background: var(--primary-500);
                  border-radius: 8px;
                  color: #fff;
                }

                .button-follow {
                  border: none;
                  cursor: pointer;
                  padding: 6px 47px;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 21px;
                  background: #e4e4e8;
                  border-radius: 8px;
                  color: var(--primary-500);
                }
              }

              .button-spcebet-alignment {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 20px;
                padding: 16px 0 0 0;

                // flex-wrap: wrap;

                @media only screen and (max-width: '768px') {
                  justify-content: center;
                  flex-wrap: wrap;
                }

                .button-deactive {
                  border: 1px solid #556ee6;
                  cursor: pointer;
                  padding: 6px 20px;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 21px;
                  background: #fff;
                  border-radius: 8px;
                  color: var(--primary-500);
                }

                .button-active {
                  border: none;
                  cursor: pointer;
                  padding: 6px 20px;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 21px;
                  background: var(--primary-500);
                  border-radius: 8px;
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }

  .section-7 {
    background: #ffffff;
    padding: 63px 0 0 0;

    @media only screen and (max-width: 799px) {
      background: #eceef4;
      padding: 16px 0 0 0;
    }

    .relatedSearch-section {
      .container {
        // max-width: 1310px;
        max-width: 1343px;

        padding: 0 20px;

        @media only screen and (max-width: 1299px) {
          max-width: 1200px;
          min-width: 1200px;
        }

        @media only screen and (max-width: 1199px) {
          max-width: 950px;
          min-width: 950px;
        }

        @media only screen and (max-width: 999px) {
          max-width: 700px;
          min-width: 700px;
        }

        @media only screen and (max-width: 899px) {
          max-width: 100%;
          min-width: 100%;
          padding: 0;
        }

        .relatedSearch-heading-alignment {
          h4 {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 36px;
            line-height: 54px;
            text-align: center;
            color: var(--secondary-900);

            @media only screen and (max-width: 799px) {
              font-weight: 600;
              font-size: 20px;
              line-height: 30px;
              color: var(--secondary-900);
            }
          }
        }

        .relatedSearch-details-alignment {
          padding: 49px 0 0 0;

          @media only screen and (max-width: 799px) {
            padding: 0;
          }

          .relatedSearch-grid {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 24px;

            @media only screen and (max-width: 899px) {
              grid-template-columns: repeat(2, 1fr);
            }

            .relatedSearch-gridItem {
              .mens_card {
                margin-bottom: 20px;
                width: 100%;
                overflow: hidden;
                background: #ffffff;
                border: 1px solid #f3f3f3;
                border-radius: 10px;
                cursor: pointer;

                @media only screen and (max-width: 799px) {
                  background: #eceef4 !important;
                  padding: 16px 0 0 0;
                }

                .cardtop {
                  position: relative;

                  img {
                    width: 100%;
                  }

                  .options {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    padding: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: column;

                    .option_top {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;

                      .count {
                        background: #1e2432;
                        opacity: 0.6;
                        border-radius: 8px;
                        padding: 6px;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                        text-align: center;
                        color: #ffffff;
                      }

                      .left_options {
                        display: flex;
                        align-items: center;

                        .like {
                          background: #1e2432;
                          opacity: 0.6;
                          width: 31px;
                          height: 31px;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          border-radius: 100%;
                          margin-right: 8px;
                        }

                        .menu_l {
                          background: #1e2432;
                          opacity: 0.6;
                          width: 23px;
                          height: 23px;
                          border-radius: 100%;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        }
                      }
                    }

                    .pause {
                      background: #1e2432;
                      opacity: 0.6;
                      border-radius: 21px;
                      width: 42px;
                      height: 42px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }

                    .option_bottom {
                      width: 100%;
                      display: flex;
                      justify-content: flex-end;
                      align-items: center;

                      span {
                        background: #ffffff;
                        border: 0.5px solid #e4e4e8;
                        border-radius: 10.5px;
                        padding: 4px;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 1px;
                        color: var(--secondary-900);
                        display: inline-flex;

                        img {
                          width: 13px;
                          height: 13px;
                          margin-right: 5px;
                        }
                      }
                    }
                  }
                }

                .card_details_box {
                  border-bottom: 1px solid #f3f3f3;
                  padding: 15px;

                  @media only screen and (max-width: 799px) {
                    padding: 8px;
                  }

                  h6 {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--secondary-900);

                    @media only screen and (max-width: 799px) {
                      font-size: 14px;
                      line-height: 21px;
                    }
                  }

                  .row1 {
                    display: flex;
                    width: 100%;
                    margin: 10px 0 12px 0;
                    align-items: center;
                    flex-wrap: wrap;

                    // span {
                    //   margin-right: 10px;
                    // }

                    .price {
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 21px;
                      color: #000000;
                    }

                    .item2 {
                      font-weight: 500;
                      font-size: 12px;
                      line-height: 18px;
                      text-decoration-line: line-through;
                      color: #acb1c0;
                    }

                    .other-details-flex {
                      display: flex;
                      align-items: center;
                      gap: 8px;
                    }

                    .item3 {
                      background: #ff7d23;
                      border-radius: 11px;
                      font-weight: 500;
                      font-size: 12px;
                      line-height: 18px;
                      color: #ffffff;
                      padding: 1px 5px;
                      white-space: nowrap;
                    }

                    .item4 {
                      padding: 1px 5px;
                      background: #eaeaea;
                      border-radius: 13.5px;
                      font-weight: 500;
                      font-size: 12px;
                      line-height: 18px;
                      color: #5f40af;
                      display: flex;
                      align-items: center;
                      justify-content: center;

                      img {
                        margin-right: 4px;
                      }
                    }
                  }

                  .row2 {
                    flex-wrap: wrap;

                    .second-other-details-alignment {
                      display: flex;
                      align-items: center;
                    }

                    span {
                      margin-right: 7px;
                    }

                    display: flex;
                    align-items: center;
                    width: 100%;

                    .item1 {
                      font-weight: 400;
                      font-size: 12px;
                      line-height: 18px;
                      display: flex;
                      align-items: center;

                      color: #000000;

                      img {
                        margin-right: 5px;
                      }
                    }

                    .item2 {
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 21px;
                      color: #70c217;
                      display: flex;
                      align-items: center;
                      justify-content: center;

                      img {
                        margin-right: 7px;
                      }
                    }

                    .reting {
                      display: flex;
                      align-items: center;
                      gap: 3px;

                      @media only screen and (max-width: 799px) {
                        padding: 4px 0 0 0;
                        display: flex;
                        align-items: center;
                        gap: 4px;
                      }

                      p {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 18px;
                        color: var(--secondary-900);
                      }

                      .countee {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                        color: #acb1c0;
                        padding: 0 0 0 2px;
                      }

                      // margin-left: 29px;
                      // font-weight: 600;
                      // font-size: 16px;
                      // line-height: 1px;
                      // display: flex;
                      // align-items: center;
                      // color: #808191;

                      @media only screen and (max-width: 799px) {
                        margin-left: 0;
                      }

                      img {
                        margin-left: 2px;
                      }
                    }
                  }
                }

                .card_utils {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .box {
                    width: 33.33%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 17px 8px;

                    svg {
                      margin-right: 5px;
                    }
                  }
                }
              }
            }
          }
        }

        .alignment-class-mobile {
          @media only screen and (max-width: '1024px') {
            // padding: 0 16px;
            background: #fff;
          }
        }
      }
    }
  }

  .slick-arrow {
    background: rgba(255, 255, 255, 0.6);

    &:hover {
      background: var(--primary-500);
    }
  }
}

h3 {
  color: #f1f0f0;
}

.section-1 {
  display: block;
  // background: #1d2330;
  // padding-bottom: 32px;
  // @media only screen and (max-width: 799px) {
  //   background: #1d2330;
  //   padding-bottom: 0;
  // }
}

.mens-search-alignment {
  display: block;
}

.section-2 {
  display: block;
}

.section-3 {
  display: block;
}

.section-4 {
  display: block;
}

.section-5 {
  display: block;
}

.section-6 {
  display: block;
}

.section-7 {
  display: block;
}

@media only screen and (max-width: 899px) {
  .slick-arrow {
    display: none !important;
  }
}

.mens-trending_store_slider {
  position: relative;
  cursor: pointer;
  margin: 0 -8px;
  padding: 42px 0 0 0;

  .mens-trending-store-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  @media only screen and (max-width: 799px) {
    margin: 0;
    padding: 16px;
  }

  .mens-trending_store_slider_cover {
    padding: 0 8px;

    .card_store {
      width: 100%;
      border: 1px solid #eceef4;
      border-radius: 12px;
      background: #ffffff;
      overflow: hidden;
      transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

      &:hover {
        transform: translateY(-4px);
        border: 1px solid #ab67fc;

        .card_content h5 {
          color: #ab67fc;
        }
      }

      .cardtop_store {
        overflow: hidden;
        border-radius: 12px;
        width: 100%;
        max-height: 145px;
        height: 145px;
        object-fit: cover;
        object-position: center;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .card_content {
        padding: 16px;
        text-align: center;

        h5 {
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;

          color: #000000;

          @media only screen and (max-width: 799px) {
            font-size: 16px;
            line-height: 24px;
            margin: 0 0 2px 0;
          }
        }

        h6 {
          font-size: 14px;
          line-height: 21px;
          font-weight: 400;
          color: #808191;

          @media only screen and (max-width: 799px) {
            font-size: 12px;
            line-height: 18px;
            padding: 0 0 9px 0;
          }
        }

        .product {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: #808191;
          margin: 16px 0 8px 0;

          @media only screen and (max-width: 799px) {
            font-size: 12px;
            line-height: 18px;
            margin: 0 0 2px 0;
          }
        }

        .Visitor {
          font-size: 14px;
          line-height: 21px;
          color: #808191;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;

          @media only screen and (max-width: 799px) {
            font-size: 12px;
            line-height: 18px;
          }

          .pre {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        button {
          @include block_btn();
          margin-top: 16px;

          @media only screen and (max-width: 799px) {
            font-size: 14px;
            line-height: 21px;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .slick-list {
    padding-top: 5px;
  }
}

.men-category-enthusiats-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);

  &.mobileView-design-alignment {
    @media only screen and (max-width: 767px) {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      width: 100%;
      gap: 16px;
      padding: 0 10px 0 0;
    }
  }
}

@media only screen and (max-width: 767px) {
  .jewelry-fan-alignment {
    padding: 0 0 0 16px;
  }
}

.men-mobile-view-header-alignment {
  display: none;

  @media only screen and (max-width: 799px) {
    display: block;
    background: #1e2432;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 68px;
    width: 100%;
    z-index: 99;

    .back-heading-alignment {
      display: flex;
      align-items: center;
      gap: 16px;

      .back-button-alignment {
        button {
          background: rgba(172, 177, 192, 0.4);
          border-radius: 10px;
          padding: 4px 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #e4e4e8;
          cursor: pointer;
        }
      }

      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #f8f8f8;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 176px;
      }
    }
  }
}