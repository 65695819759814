.art-carft-banner-details-section {
    padding: 68px 0 0 0;

    @media only screen and (max-width:"768px") {
        padding: 16px 20px 0 20px;
    }

    .art-craft-main-baaner-alignment {
        background: url("../../../assets/img/artCarft/art-cart-bg.png");
        width: 100%;
        height: 542px;
        background-size: cover;
        background-position: center center;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width:"768px") {
            height: 384px;
        }

        .art-craft-main-heading-alignment {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 1px;

            .art-craft-webview-img {
                @media only screen and (max-width:"768px") {
                    display: none;
                }
            }

            .art-craft-mobileview-img {
                display: none;

                @media only screen and (max-width:"768px") {
                    display: block;
                }
            }

            .art-craft-child-notes-alignment {
                background: #FFED8E;
                transform: rotate(-2.1deg);
                padding: 6px 40px;

                @media only screen and (max-width:"768px") {
                    padding: 2px 11px;
                    max-width: 238px;
                }

                p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--secondary-900);
                }
            }
        }

        .art-carft-angle-1-alignment {
            position: absolute;
            top: 61px;
            left: 475px;

            @media only screen and (max-width:"1099px") {
                left: 335px;
            }

            @media only screen and (max-width:"768px") {
                top: 24px;
                left: 92px;

                img {
                    width: 38px;
                    height: 38px;
                    object-fit: cover;
                }
            }
        }

        .art-carft-angle-2-alignment {
            position: absolute;
            top: 87px;
            right: 434px;

            @media only screen and (max-width:"768px") {
                top: 64px;
                right: 123px;

                img {
                    width: 21px;
                    height: 14px;
                }
            }
        }

        .art-carft-angle-3-alignment {
            position: absolute;
            top: 43px;
            right: 127px;

            @media only screen and (max-width:"768px") {
                top: 15px;
                right: 38px;

                img {
                    width: 60px;
                    height: 44px;
                }
            }
        }

        .art-carft-angle-4-alignment {
            position: absolute;
            bottom: 180px;
            right: 94px;

            @media only screen and (max-width:"768px") {
                bottom: 75px;
                right: 24px;

                img {
                    width: 24px;
                    height: 24px;
                }
            }
        }

        .art-carft-angle-5-alignment {
            position: absolute;
            bottom: 164px;
            right: 328px;

            @media only screen and (max-width:"1099px") {
                bottom: 120px;
            }

            @media only screen and (max-width:"768px") {
                bottom: 48px;
                right: 100px;

                img {
                    width: 25px;
                    height: 25px;
                }
            }
        }

        .art-carft-angle-6-alignment {
            position: absolute;
            bottom: 105px;
            right: 605px;

            @media only screen and (max-width:"1099px") {
                left: 464px;
            }

            @media only screen and (max-width:"1099px") {
                bottom: 46px;
                left: 144px;
                right: 0;

                img {
                    width: 30px;
                }
            }
        }

        .art-carft-angle-7-alignment {
            position: absolute;
            bottom: 53px;
            left: 310px;

            @media only screen and (max-width:"768px") {
                left: 30px;
                bottom: 50px;

                img {
                    width: 40px;
                }
            }
        }

        .art-carft-angle-8-alignment {
            position: absolute;
            bottom: 163px;
            left: 96px;

            @media only screen and (max-width:"768px") {
                display: none;
            }
        }

        .art-carft-angle-9-alignment {
            position: absolute;
            top: 128px;
            left: 173px;

            @media only screen and (max-width:"768px") {
                top: 64px;
                left: 54px;

                img {
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }
}