.follow-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.follow-overlay.open {
  opacity: 1;
  visibility: visible;
}

.follow-modal {
  position: fixed;
  bottom: 0;
  height: 658px;
  left: 0;
  width: 100%;
  background: #eceef4;
  transform: translateY(100%);
  transition: 0.3s ease-in-out;

  @media only screen and (max-width:"1024px") {
    height: 100%;
    top: 56px;
    
  }
  .follow-modal-tab-alignment {
    background: #ffffff;
    box-shadow: 0px 1px 2px rgba(172, 177, 192, 0.2);
    padding: 0 23px;
  }

  .follow-modal-search-alignment {
    margin: 8px 0 0 0;
    background: #ffffff;
    padding: 15px 26px;

    .follow-details-input-alignment {
      position: relative;

      input {
        width: 100%;
        height: 36px;
        background: #f1f2f6;
        border-radius: 18px;
        padding: 0 18px 0 48px;
      }

      .search-icon-alignment {
        position: absolute;
        top: 7px;
        left: 18px;

        img {
          width: 24px;
          height: 24px;
          display: block;
        }
      }
    }
  }

  .follow-modal-details-alignment {
    margin: 7px 0 0 0;

    .follow-details-modal-box-alignment {
      background: #ffffff;
      padding: 10px 28px 10px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 7px 0;
      &:last-child {
        margin: 0;
      }
      .follow-details-modal-profile-alignment {
        display: flex;
        align-items: center;
        gap: 14px;

        .follow-profile-img-alignment {
          width: 50px;
          height: 50px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            display: block;
          }
        }

        .follow-profile-details-alignment {
          h6 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.32px;
            color: #0a1f44;
            padding: 0 0 3px 0;
          }

          .follow-number-alignment {
            display: flex;
            align-items: center;
            gap: 14px;

            p {
              font-family: 'Inter', sans-serif;
              font-weight: 400;
              font-size: 12px;
              line-height: 15px;
              color: #acb1c0;
            }
          }
        }
      }

      .follow-button-alignment {
        height: 26px;
        // width: 75px;
        display: flex;
        justify-content: center;
        button {
          height: 26px;
          width: 75px;
          background: var(--primary-500);
          border-radius: 8px;
          padding: 3px 9px;
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.32px;
          color: #f8f8f8;
          cursor: pointer;
          &.following {
            background: #f8f8f8;
            color: var(--primary-500);
            font-weight: 500;
            &:hover {
              background: #eef1fc;
            }
          }
        }
      }
    }
  }

  .ant-tabs-nav {
    margin-bottom: 0px !important;
    background-color: #fff;
  }

  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before {
    border: 0;
  }

  .ant-tabs-nav-list {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0;
    padding: 0 22px;

    .ant-tabs-tab {
      padding: 0;
      margin: 0;

      .ant-tabs-tab-btn {
        padding: 16px 23px;
        font-size: 15px;
        line-height: 22px;
        color: #a7a7a7;
        font-weight: 400 !important;
      }

      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          font-weight: 500 !important;
          color: var(--primary-500);
        }

        &:before {
          content: '';
          position: absolute;
          height: 2px;
          background: #5f40af;
          border-radius: 26px 26px 0 0px;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }
    }

    .ant-tabs-ink-bar {
      display: none;
    }
  }

  .group-single {
    .group-single-name {
      .action-dropdown {
        top: 0 !important;
        right: 10px !important;
      }
    }
  }
}
// }

.follow-modal.open {
  transform: translate(0);
  
  transition: 0.3s ease-in-out;
  
  
}
