.mobile-view-padding {
  margin-bottom: 14px;
  padding-left: 6px;

  @media screen and (max-width: 767px) {
    // padding: 14px 14px 0 14px;
    margin-bottom: 4px;
    gap: 4px;
  }
}

.post-description {
  margin-bottom: 8px;

  @media screen and (max-width: 767px) {
    // padding: 0px 14px 0px 14px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 6px;
  }
}

.active-border-comment {
  border-top: #e4e4e8 1px solid;

  // border-bottom: #e4e4e8 1px solid;
  @media only screen and (max-width: '1024px') {
    border-top: 1px solid var(--primary-50);
    border-bottom: none;
  }
}

.comment-part {
  // padding: 0px 14px;

  // border-bottom: #e4e4e8 1px solid;
  width: 100%;
  // height: 54px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @media screen and (max-width: 767px) {
    margin: 0px;
    width: auto;
  }

  .post-comment-section {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: #e4e4e8 1px solid;

    padding: 14px;
    gap: 10px;
    cursor: pointer;

    @media only screen and (max-width: '1024px') {
      border-right: 1px solid var(--primary-50) !important;
    }

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #495057;
    }

    &.web-view-comment-section {
      @media only screen and (max-width: '1024px') {
        display: none;
      }
    }

    &.mobile-child-details-alignment {
      display: none;

      @media only screen and (max-width: '1024px') {
        display: flex;
      }
    }
  }

  .post-share-section {
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: #e4e4e8 1px solid;
    padding: 9px;
    gap: 4px;
    cursor: pointer;

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #495057;
    }
  }

  .post-like-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px;
    gap: 10px;
    cursor: pointer;

    svg {
      &:hover {
        @media only screen and (min-width: '1024px') {
          fill: #ff0000;
        }

        path {
          @media only screen and (min-width: '1024px') {
            stroke: #ff0000;
          }
        }
      }
    }

    span {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #495057;
    }
  }

  .post-comment-share-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    border-right: #e4e4e8 1px solid;
    align-items: center;

    @media only screen and (max-width: '1024px') {
      border-right: 1px solid var(--primary-50);
    }

    :last-child {
      border-right: none;
    }
  }
}

.comment-border-line {
  border: #e4e4e8 1px solid;
}

.popover-main-align {
  width: 44px;
  height: 24px;
  background: var(--primary-500);
  border: 1.5px solid #ffffff;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -8px;
  z-index: 1;

  button {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1px;
    color: #ffffff;
  }
}

.comment-below-section {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .replies-likes-main {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px 0;
    gap: 10px;

    .replies-section-align {
      width: 32px;
      height: 32px;
      background: #f8f8f8;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        cursor: pointer;
      }
    }
  }
}

.more-comment-text {
  cursor: pointer;

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--primary-500);
  }
}

.open-comment {
  height: 0px;
  opacity: 0;
  // padding: 16px 20px;
  transition: 1s;
  animation: animone 0.3s linear 1;
}

@keyframes animone {
  0% {
    height: 0px;
    opacity: 0;
    visibility: hidden;
  }

  42% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    height: 290px;
    visibility: visible;
  }
}

.close-comment {
  height: 290px;
  // height: 266px;
  padding: 16px 14px;
  opacity: 1;
  transition: 1s;
  animation: animtwo 0.3s linear 1;
}

.close-comment-heigth {
  height: 100%;
  padding: 16px 14px;
  opacity: 1;
  transition: 1s;
  animation: animtwo 0.3s linear 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  &.no-data-comment-details {
    align-items: center;
    justify-content: center;
  }
}

@keyframes animone {
  0% {
    height: 290px;
    opacity: 1;
    visibility: visible;
  }

  20% {
    opacity: 0;
  }

  100% {
    opacity: 0;
    height: 20px;
    visibility: hidden;
  }
}

.shared-post-container {
  width: 100%;
  height: 194px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  padding: 4px;
  display: flex;
  gap: 16px;

  @media screen and (max-width: 767px) {
    gap: 8px;
    height: 176px;
    padding: 3px;
    border-radius: 0;
  }

  // align-items: center;
  .shared-post-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 135px;

    @media screen and (max-width: 767px) {
      width: 148px;
    }

    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;

      @media screen and (max-width: 767px) {
        border-radius: 0;
      }
    }
  }

  .shared-post-detail {
    padding: 12px 0px;
    width: 466px;

    @media screen and (max-width: 767px) {
      width: 343px;
    }

    // display: flex;
    // justify-content: center;
    align-items: center;

    .shared-post-detail-alignment {
      h4 {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: var(--secondary-900);
        margin-bottom: 8px;
        cursor: pointer;

        @media screen and (max-width: 767px) {
          font-size: 14px;
          margin-bottom: 0;
          line-height: 21px;
        }
      }

      .shared-product-text {
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: var(--secondary-900);
          margin-bottom: 12px;

          @media screen and (max-width: 767px) {
            font-size: 12px;
            margin-bottom: 4px;
            line-height: 18px;
          }
        }
      }

      .price-analytics-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 6px;
        margin-bottom: 12px;

        @media screen and (max-width: 767px) {
          margin-bottom: 5px;
          gap: 5px;
        }

        h5 {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          display: flex;
          align-items: center;
          color: #000000;
        }

        span {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          display: flex;
          align-items: center;
          text-decoration-line: line-through;
          color: #acb1c0;
        }

        .discount-menu-align {
          width: 61px;
          height: 21px;
          background: #ff7d23;
          border-radius: 11px;
          display: flex;
          justify-content: center;
          align-items: center;

          button {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: #ffffff;
          }
        }

        .delivery-menu-align {
          width: 62px;
          height: 21px;
          background: #eaeaea;
          border-radius: 13.5px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 2px;

          img {
          }

          span {
            font-weight: 500;
            font-style: italic;
            font-size: 12px;
            line-height: 18px;
            display: flex;
            align-items: center;
            text-decoration-line: none;
            color: #5f40af;
          }
        }

        @media screen and (max-width: 767px) {
          .discount-menu-align {
            display: none;
          }

          .delivery-menu-align {
            display: none;
          }
        }
      }

      .analytics-container-main {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;

        @media screen and (max-width: 767px) {
          gap: 5px;
        }

        .graph-menu-align {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 6px;

          img {
            width: 14px;
            height: 11.8px;
          }

          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #000000;
          }
        }

        .arrow-menu-align {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 6px;

          img {
            width: 11px;
            height: 11px;
          }

          span {
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            display: flex;
            align-items: center;
            color: #70c217;
          }
        }

        .dott-icon-align {
          width: 4px;
          height: 4px;
          background: #acb1c0;
          border-radius: 50%;
        }

        .rating-section-main {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 4px;

          img {
            margin-bottom: 2px;
          }

          span {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #808191;

            @media screen and (max-width: 767px) {
              font-size: 12px;
              line-height: 18px;
            }
          }

          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #808191;
            margin-bottom: 0 !important;

            @media screen and (max-width: 767px) {
              font-size: 12px;
              line-height: 18px;
            }
          }
        }
      }
    }
  }
}

.share-post-from-product {
  margin: 8px 16px;
  width: auto;
}

.font-medium-comment {
  font-weight: 500;
  font-size: 14px;

  // line-height: 18px;
  @media screen and (max-width: 767px) {
    font-size: 13px;
  }
}

.viewer-count-size {
  p {
    font-size: 14px;

    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }

  svg {
    height: 18px;
    width: 18px;

    @media screen and (max-width: 767px) {
      height: 15px;
      width: 15px;
    }
  }
}

.comment-count-size {
  width: 70px !important;
  height: 27px !important;

  @media screen and (max-width: 767px) {
    width: 55px !important;
    height: 27px !important;
  }

  p {
    font-size: 16px;

    @media screen and (max-width: 767px) {
      font-size: 14px;
    }
  }

  svg {
    height: 22px;
    width: 22px;

    @media screen and (max-width: 767px) {
      height: 18px;
      width: 18px;
    }
  }
}

.image-height {
  padding: 124px 0px;

  @media screen and (max-width: 767px) {
    padding: 0;
  }
}

.slider-preview-button {
  top: 43%;

  span {
    padding-left: 260px;
    font-size: 45px;
  }

  @media screen and (max-width: 767px) {
    span {
      padding-left: 0;
      font-size: 45px;
    }
  }
}

.slider-next-button {
  top: 43%;

  span {
    padding-right: 260px;
    font-size: 45px;
  }

  @media screen and (max-width: 767px) {
    span {
      padding-right: 0;
      font-size: 45px;
    }
  }
}

.commentbox-padding {
  padding: 12px;
  position: sticky;
  top: 100%;
  z-index: 0;

  @media screen and (max-width: 767px) {
    // border-top: 1px solid #f1f1f5;
    padding: 7px;
    // margin-top: 7px;
  }

  // @media screen and (max-width: 1024px) {
  //   border-top: 0;
  //   padding: 0;
  //   margin-top: 8px;
  // }
}

.comment-textbox-alignment {
  svg {
    width: 26px;
    height: 26px;

    &:hover {
      path {
        fill: #556ee6;
      }
    }
  }

  @media screen and (max-width: 767px) {
    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.react-input-emoji--input {
  padding: 9px 12px 11px !important;

  @media screen and (max-width: 767px) {
    padding: 5px 12px 6px !important;
  }
}

.comment-image {
  width: 36px;
  height: 36px;
  min-width: 36px;
  max-width: 36px;

  @media screen and (max-width: 767px) {
    width: 30px;
    height: 30px;
    min-width: 30px;
    max-width: 30px;
  }
}

.post-profile-align {
  width: 50px;
  height: 50px;
  min-width: 50px;
  max-width: 50px;
  // display: flex;
  // justify-content: center;
  // align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 50%;
  }

  .seller-post-profile-img-alignment-skeleton {
    width: 44px;
    min-width: 44px;
    max-width: 44px;
    height: 44px;
    border-radius: 4px;
  }

  .post-profile-align-skeleton {
    width: 100%;
    height: 100%;
    // display: flex;
    border-radius: 50%;
  }

  &.seller-post-profile-img-alignment {
    width: 44px;
    min-width: 44px;
    max-width: 44px;
    height: 44px;

    img {
      border-radius: 4px;
    }
  }

  @media only screen and (max-width: 1099px) {
    width: 50px;
    height: 50px;
    min-width: 50px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  @media only screen and (max-width: 767px) {
    width: 36px;
    height: 36px;
    min-width: 36px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.post-time-date-main {
  display: flex;
  gap: 6px;
  align-items: center;

  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #808191;
  }

  .dott-icon-align {
    width: 4px;
    height: 4px;
    background: #acb1c0;
    border-radius: 50%;
  }
}

.profile-username-align {
  // font-weight: 600;
  // display: flex;
  // justify-content: center;
  align-items: center;
  gap: 4px;
  flex-wrap: wrap;
  cursor: pointer;

  @media only screen and (max-width: 767px) {
    display: block;

    h5 {
      font-size: 14px;
      font-weight: 600;
      line-height: 14px;
    }
  }

  .post-owner-font {
    font-weight: 500 !important;
    font-size: 14px !important;
    letter-spacing: 0.32px !important;
    color: #000000;

    @media only screen and (max-width: 767px) {
      margin: 0 2px 0 0;
    }
  }
}

.profile-bottom-all-details {
  font-size: 14px;
  letter-spacing: 0.32px;
  color: #000000;

  &.second-details {
    padding: 0 0 0 4px;
  }
}

.product-share-name {
  font-weight: 600;
  font-size: 14px;
  color: var(--primary-500);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 152px;
  cursor: pointer;

  @media only screen and (max-width: 767px) {
    white-space: break-spaces;
    max-width: unset;
  }

  span {
    font-size: 14px;
    letter-spacing: 0.32px;
    color: #000000;
  }
}

.post-comments-wrapper {
  border-bottom: #e4e4e8 1px solid;

  // padding: 16px 20px;
  @media screen and (max-width: 767px) {
    // padding: 10px 14px;
  }
}

.border-hide {
  border-bottom: none;

  // padding: 16px 20px;
  @media screen and (max-width: 1024px) {
    padding: 0;
  }
}

.create-post-alignment {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  min-width: 44px;
  max-width: 44px;

  @media screen and (max-width: 767px) {
    width: 36px;
    height: 36px;
  }
}

.store-border-radious {
  border-radius: 4px;
}

.create-post-image {
  width: 44px;
  height: 44px;
  // border-radius: 50%;
  min-width: 44px;
  max-width: 44px;

  &.seller-create-post-img {
    img {
      border-radius: 4px;
    }
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  @media screen and (max-width: 767px) {
    width: 36px;
    height: 36px;
  }
}

.margin-medium {
  // @media screen and (max-width: 767px) {
  //   margin-bottom: 0;
  // }
  // @media screen and (min-width: 992px) {
  //   margin-bottom: 10px;
  // }
}

.feed-post-details-box {
  border-radius: 16px;
  background: #fff;
  border: 2px solid var(--primary-50);

  @media screen and (max-width: 1024px) {
    border-radius: 0;
    margin: 0 !important;
    border: 1px solid var(--primary-50);
  }
}

.post-section-main {
  padding: 14px 14px 14px 14px;

  @media screen and (max-width: 767px) {
    padding: 0;
  }
}

.input-align-reply-comment {
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
  align-items: center;

  input {
    border: #5f40af solid 1px;
    border-radius: 8px;
    padding: 4px 10px;
  }

  .send-reply-comment {
    width: 74px;
    height: 30px;
    background: var(--primary-500);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #ffffff;
    }
  }

  .opacity-div {
    opacity: 0.5;
  }
}

.reply-comment-section {
  padding-left: 50px;
  padding-top: 6px;
}

.mobile-view-post-comment-modal-alignment {
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
  }

  .mobile-view-post-comment-mobile-wrapper {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    right: 0;
    background-color: #ffffff;
    z-index: 9999;

    .modal-heading-alignment {
      display: flex;
      gap: 46px;
      padding: 16px 17px;

      .back-button-alignment {
        button {
          padding: 4px 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #808191;
          background: #f8f8f8;
          border-radius: 10px;
          cursor: pointer;
        }
      }

      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: var(--secondary-900);
      }
    }

    .modal-details-profile-name-alignment {
      padding: 0 16px 17px 16px;

      .modal-details-name-alignment {
        display: flex;
        align-items: center;
        gap: 8px;

        .img-alignment {
          min-width: 40px;
          width: 40px;
          max-width: 40px;
          height: 40px;

          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }

        .details-names-alignment {
          h4 {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: #000000;
          }

          .child-details-alignment {
            display: flex;
            align-items: center;
            gap: 8px;

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #acb1c0;
            }

            .view-alignment {
              display: flex;
              align-items: center;
              gap: 8px;

              p {
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background: #d9d9d9;
              }

              .view-child-alignment {
                display: flex;
                gap: 4px;

                span {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 18px;
                  color: #acb1c0;
                }
              }
            }
          }
        }
      }

      .comment-details-alignment {
        padding: 8px 0 0 0;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: var(--secondary-900);
        }
      }
    }

    .modal-body-alignment {
      background: #eceef4;
      padding: 8px 16px;
      height: calc(100vh - 200px);
      overflow-y: auto;

      .modal-chat-box-alignment {
        background: #ffffff;
        border: 1px solid #e3e4e8;
        border-radius: 0px 10px 10px 10px;
        padding: 16px 16px 24px 16px;

        .modal-profile-alignment {
          display: flex;
          align-items: center;
          gap: 8px;

          .profile-img-alignment {
            width: 32px;
            min-width: 32px;
            max-width: 32px;
            height: 32px;
            position: relative;

            img {
              width: 100%;
              height: 100%;
              display: block;
              border-radius: 50%;
            }
          }

          h4 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: #495057;
          }

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 10px;
            line-height: 15px;
            color: #808191;
          }
        }

        .profile-comment-details-alignment {
          padding: 8px 0 0 0;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #495057;
          }
        }

        .profile-product-img-alignment {
          margin: 20px 0 0 0;
          display: flex;
          align-items: center;
          gap: 8px;

          .profile-product-img {
            height: 82px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 5px;
            }
          }

          .last-img-alignment {
            width: 82px;
            min-width: 82px;
            max-width: 82px;
            height: 82px;
            position: relative;

            &::before {
              content: '';
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              right: 0;
              background: rgba(30, 36, 50, 0.6);
              border-radius: 5px;
            }

            img {
              width: 100%;
              height: 100%;
              border-radius: 5px;
            }

            p {
              position: absolute;
              top: 50%;
              bottom: 0;
              right: 0;
              left: 0;
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              text-align: center;
              color: #f8f8f8;
              transform: translateY(-25%);
            }
          }
        }
      }

      .like-reply-details-alignment {
        display: flex;
        align-items: center;
        gap: 16px;
        margin: 8px 0 16px 0;

        .details-flex-alignment {
          display: flex;
          align-items: center;
          gap: 8px;

          .icons-box-alignment {
            background: #f8f8f8;
            border-radius: 10px;
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }

          .number-alignement {
            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #808191;
            }
          }
        }
      }

      .reply-alignment-modal-profile-alignment {
        padding: 16px 14px 0 19px;

        .modal-profile-alignment {
          display: flex;
          align-items: center;
          gap: 8px;

          .profile-img-alignment {
            width: 32px;
            min-width: 32px;
            max-width: 32px;
            height: 32px;

            img {
              width: 100%;
              height: 100%;
              display: block;
              border-radius: 50%;
            }
          }

          h4 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: #495057;
          }

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 10px;
            line-height: 15px;
            color: #808191;
          }
        }

        .profile-comment-details-alignment {
          padding: 8px 0 0 0;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #495057;
          }
        }

        .see-more-alignment {
          padding: 16px 0 0 34px;

          a {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: var(--primary-500);
          }
        }

        // .like-reply-details-alignment {
        //   display: flex;
        //   align-items: center;
        //   gap: 16px;
        //   margin: 8px 0 0 0;

        //   .details-flex-alignment {
        //     display: flex;
        //     align-items: center;
        //     gap: 8px;

        //     .icons-box-alignment {
        //       background: #F8F8F8;
        //       border-radius: 10px;
        //       width: 32px;
        //       height: 32px;
        //       display: flex;
        //       align-items: center;
        //       justify-content: center;
        //       cursor: pointer;
        //     }

        //     .number-alignement {
        //       p {
        //         font-family: 'Poppins', sans-serif;
        //         font-weight: 400;
        //         font-size: 12px;
        //         line-height: 18px;
        //         color: #808191;
        //       }
        //     }
        //   }
        // }
      }
    }

    .bottom-chat-input-alignment {
      border-top: 1px solid #e4e4e8;
      background: rgba(248, 248, 248, 0.92);
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 8px 16px;
      position: fixed;
      bottom: 0;
      right: 0;
      width: 100%;

      .input-chat-profile-img-alignment {
        width: 36px;
        max-width: 36px;
        min-width: 36px;
        height: 36px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          display: block;
        }
      }

      .chat-input-main-box-alignment {
        display: flex;
        align-items: center;
        gap: 2px;
        width: 100%;

        .chat-input-alignment {
          background: #ffffff;
          border: 1px solid #e4e4e8;
          border-radius: 10px;
          height: 45px;
          width: 100%;
          position: relative;

          input {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            padding: 0 40px 0 10px;
          }

          .icon-alignment {
            position: absolute;
            top: 10px;
            right: 10px;
          }
        }

        .picup-img-alignment {
          width: 44px;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            display: block;
            cursor: pointer;
          }
        }

        .send-box-button-alignment {
          button {
            background: #b6c0f1;
            border-radius: 10px;
            width: 45px;
            height: 45px;
            display: flex;
            align-items: center;
            padding: 12px 11px 9px 10px;

            &:hover {
              background: var(--primary-500);
            }

            img {
              display: block;
            }
          }
        }
      }
    }
  }
}

.social-product-product-modal-alignment {
  height: 100%;
  overflow-y: auto;
  // position: relative;

  &.overflow-hidden-alignment {
    overflow-y: hidden;
  }

  .social-banner-alignment {
    height: 319px;
    width: 100%;
    position: relative;

    @media only screen and (max-width: '999px') {
      height: 250px;
    }

    @media only screen and (max-width: '767px') {
      height: 177px;
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      border-radius: 10px 10px 0px 0px;

      @media only screen and (max-width: '999px') {
        border-radius: 0;
      }
    }

    .edit-cover-button-alignment {
      position: absolute;
      top: 16px;
      right: 16px;

      @media only screen and (max-width: '999px') {
        display: none;
      }

      button {
        background: #ffffff;
        border: 1px solid #eaecef;
        border-radius: 8px;
        padding: 0 15px;
        height: 34px;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #808191;
        cursor: pointer;
      }
    }

    .mobile-view-banner-option-alignment {
      position: absolute;
      top: 20px;
      right: 16px;
      left: 16px;
      // bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      display: none;

      @media only screen and (max-width: '999px') {
        display: flex;
      }

      .mobile-view-left-box-alignment {
        background: rgba(30, 36, 50, 0.6);
        border-radius: 10px;
        padding: 0 16px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }

  .social-product-details-alignment {
    margin: 0 0 0 16px;

    @media only screen and (max-width: '999px') {
      margin: 0 40px;
    }

    @media only screen and (max-width: '767px') {
      margin: 0;
    }

    .social-grid-alignment {
      display: flex;
      // grid-template-columns: 445px 1fr;
      gap: 14px;

      @media only screen and (max-width: '1024px') {
        margin: 0 0 80px 0;
      }

      @media only screen and (max-width: '999px') {
        display: block;
        margin: 0;
      }

      .social-profile-box-alignment {
        position: sticky;
        top: 0;
        background: rgba(255, 255, 255, 0.8);
        border: 2px solid #ffffff;
        backdrop-filter: blur(32px);
        border-radius: 16px;
        padding: 50px 32px;
        margin: -158px 0 0 0;
        max-width: 445px;

        @media only screen and (max-width: '1099px') {
          max-width: 390px;
        }

        @media only screen and (max-width: '999px') {
          margin: -99px 0 0 0;
          padding: 32px;
          border-radius: 16px 16px 0px 0px;
          max-width: 100%;
        }

        .social-profile-details-alignment {
          display: flex;
          align-items: center;
          gap: 32px;

          .profile-img-alignment {
            width: 116px;
            max-width: 116px;
            min-width: 116px;
            height: 116px;
            position: relative;

            @media only screen and (max-width: '999px') {
              width: 98px;
              max-width: 98px;
              min-width: 98px;
              height: 98px;
            }

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              display: block;
              object-fit: cover;
            }

            &:hover {
              .edit-profile-img {
                background: rgba(30, 36, 50, 0.4);
                width: 100%;
                height: 100%;
                border-radius: 50%;
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: 0.2s ease-in-out;

                img {
                  width: 24px;
                  height: 24px;
                  border-radius: 0;
                }
              }
            }

            .edit-profile-img {
              display: none;
            }
          }

          .profile-name-alignment {
            h5 {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 24px;
              line-height: 120%;
              color: var(--secondary-900);

              @media only screen and (max-width: '999px') {
                font-size: 18px;
              }
            }

            .location-alignment {
              display: flex;
              align-items: center;
              gap: 2px;
              padding: 4px 0 0 0;

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 16px;
                line-height: 120%;
                color: #808191;

                @media only screen and (max-width: '999px') {
                  font-size: 12px;
                }
              }
            }

            .edit-button-alignment {
              margin: 14px 0 0 0;
              width: 180px;
              height: 44px;

              @media only screen and (max-width: '999px') {
                display: none;
              }

              button {
                width: 100%;
                height: 100%;
                background: #ffffff;
                border-radius: 10px;
                font-family: 'Poppins', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--primary-500);
                cursor: pointer;

                &:hover {
                  background: #eef1fc;
                }
              }
            }

            .mobile-view-edit-follow-button-alignment {
              display: flex;
              align-items: center;
              gap: 8px;
              padding: 16px 0 0 0;
              display: none;

              @media only screen and (max-width: '999px') {
                display: flex;
              }

              .mobile-follow-button-alignment {
                button {
                  background: var(--primary-500);
                  border-radius: 8px;
                  width: 128px;
                  height: 32px;
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: #f8f8f8;
                  cursor: pointer;

                  &.active-follow-button {
                    width: 128px;
                    height: 32px;
                    background: #ffffff;
                    border-radius: 10px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: var(--primary-500);
                    cursor: pointer;

                    &:hover {
                      background: #eef1fc;
                    }
                  }
                }
              }

              .mobile-message-button-alignment {
                button {
                  background: #ffffff;
                  border-radius: 8px;
                  padding: 7px;
                }
              }
            }
          }
        }

        .social-notes-alignment {
          padding: 40px 0 0 0;

          @media only screen and (max-width: '999px') {
            padding: 35px 0 0 0;
          }

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #808191;

            span {
              color: var(--primary-500);
              cursor: pointer;
              padding: 0 0 0 5px;
            }
          }
        }

        .follow-following-alignment-web {
          @media only screen and (max-width: '999px') {
            display: none;
          }

          padding: 30px 38px 0 38px;
          display: flex;
          align-items: center;
          justify-content: center;

          .follow-details-alignemt {
            border-right: 1px solid #e4e4e8;
            padding: 0 61px 0 0;
            cursor: pointer;

            @media only screen and (max-width: '999px') {
              border-right: none;
            }

            h6 {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 18px;
              line-height: 27px;
              color: #808191;
              text-align: center;

              @media only screen and (max-width: '999px') {
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                color: var(--secondary-900);
              }
            }

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 18px;
              line-height: 27px;
              color: var(--secondary-900);

              @media only screen and (max-width: '999px') {
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: #808191;
                padding: 2px 0 0 0;
              }
            }
          }

          .following-details-alignemt {
            padding: 0 0 0 61px;
            cursor: pointer;

            h6 {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 18px;
              line-height: 27px;
              color: #808191;
              text-align: center;

              @media only screen and (max-width: '999px') {
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                color: var(--secondary-900);
              }
            }

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 18px;
              line-height: 27px;
              color: var(--secondary-900);

              @media only screen and (max-width: '999px') {
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: #808191;
                padding: 2px 0 0 0;
              }
            }
          }
        }

        .follow-following-alignment-mobile {
          @media only screen and (min-width: '999px') {
            display: none;
          }

          padding: 30px 38px 0 38px;
          display: flex;
          align-items: center;
          justify-content: center;

          .follow-details-alignemt {
            border-right: 1px solid #e4e4e8;
            padding: 0 61px 0 0;
            cursor: pointer;

            @media only screen and (max-width: '999px') {
              border-right: none;
            }

            h6 {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 18px;
              line-height: 27px;
              color: #808191;
              text-align: center;

              @media only screen and (max-width: '999px') {
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                color: var(--secondary-900);
              }
            }

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 18px;
              line-height: 27px;
              color: var(--secondary-900);

              @media only screen and (max-width: '999px') {
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: #808191;
                padding: 2px 0 0 0;
              }
            }
          }

          .following-details-alignemt {
            padding: 0 0 0 61px;
            cursor: pointer;

            h6 {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 18px;
              line-height: 27px;
              color: #808191;
              text-align: center;

              @media only screen and (max-width: '999px') {
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                color: var(--secondary-900);
              }
            }

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 18px;
              line-height: 27px;
              color: var(--secondary-900);

              @media only screen and (max-width: '999px') {
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: #808191;
                padding: 2px 0 0 0;
              }
            }
          }
        }

        .interest-details-alignment {
          display: flex;
          gap: 16px;
          align-items: flex-start;
          padding: 40px 0 0 0;

          @media only screen and (max-width: '999px') {
            display: none;
          }

          .icon-alignment {
            width: 24px;
            min-width: 24px;
            max-width: 24px;
            height: 24px;

            img {
              width: 100%;
              height: 100%;
              display: block;
            }
          }

          .interest-name-tag-alignment {
            h5 {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 20px;
              line-height: 30px;
              color: var(--secondary-900);
            }

            .tag-alignment {
              padding: 16px 0 0 0;
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              gap: 8px;

              .tag-box-alignment {
                background: rgba(172, 177, 192, 0.2);
                border-radius: 20px;
                max-width: 122px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 27px;

                span {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 21px;
                  color: #495057;
                }
              }
            }

            .univercity-details-alignment {
              padding: 16px 0 0 0;

              h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #495057;
              }

              p {
                padding: 2px 0 0 0;
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: #808191;
              }

              span {
                display: block;
                padding: 2px 0 0 0;
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: #495057;
              }
            }
          }
        }

        .mobile-view-about-me-alignment {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 40px 0 0 0;
          display: none;

          @media only screen and (max-width: '999px') {
            display: flex;
          }

          .mobile-view-name-alignment {
            h5 {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: var(--secondary-900);
            }

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #808191;
              padding: 2px 0 0 0;
            }
          }

          .mobile-dropdown-icon-alignment {
            img {
              display: block;
            }
          }
        }
      }

      .social-gridItem-alignment {
        width: 100%;

        @media only screen and (max-width: '999px') {
          margin: 0 0 72px 0;
        }

        .social-product-tab-alignment {
          background: #ffffff;
          margin: 8px 0 0 0;
          position: sticky;
          top: -16px;
          border: 16px solid #eceef4;
          z-index: 99;

          @media only screen and (max-width: '999px') {
            display: none;
          }

          ul {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            a {
              &.nav__link {
                top: 4px;
              }
            }

            .active-link {
              border-bottom: 2px solid #4f46e5;
            }

            li {
              .tab-details-alignment {
                width: 84px;
                height: 84px;
                padding: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &:hover {
                  .tab-img-alignment {
                    .disable-icon {
                      display: none;
                    }

                    .tab-active-icon {
                      display: block;
                    }
                  }

                  .tab-name-alignment {
                    p {
                      color: var(--secondary-900);
                      font-weight: 600;
                    }
                  }
                }

                &.active-tab {
                  .tab-img-alignment {
                    .disable-icon {
                      display: none;
                    }

                    .tab-active-icon {
                      display: block;
                    }
                  }

                  .tab-name-alignment {
                    p {
                      color: var(--secondary-900);
                      font-weight: 600;
                    }
                  }
                }

                .tab-img-alignment {
                  padding: 0 0 3px 0;

                  .tab-active-icon {
                    display: none;
                  }
                }

                .tab-name-alignment {
                  p {
                    font-family: 'Poppins', sans-serif;
                    font-size: 13px;
                    line-height: 20px;
                    font-weight: 500;
                    color: #acb1c0;
                  }
                }
              }
            }
          }
        }

        .mobile-view-social-tab-alignment {
          display: none;

          @media only screen and (max-width: '999px') {
            display: block;
            margin: 8px 0;
            // position: sticky;
            // top: 0;
            // z-index: 999;
          }

          .mobile-view-tab-box-alignment {
            background: #ffffff;
            padding: 8px 16px;
            display: flex;
            align-items: center;

            ul {
              display: flex;
              align-items: center;
              justify-content: space-between;

              li {
                padding: 12px 52px;
                display: flex;
                align-items: center;
                gap: 4px;

                &:hover {
                  background: var(--primary-50);
                  border-radius: 8px;

                  svg {
                    path {
                      fill: #3c4ea3;
                    }
                  }

                  p {
                    color: var(--primary-700);
                    font-weight: 600;
                  }
                }

                &.tab-active {
                  background: var(--primary-50);
                  border-radius: 8px;

                  svg {
                    path {
                      fill: #3c4ea3;
                    }
                  }

                  p {
                    color: var(--primary-700);
                    font-weight: 600;
                  }
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--secondary-600);
                }
              }
            }
          }
        }

        .social-product-details-alignment {
          width: 92%;

          @media only screen and (max-width: '1024px') {
            width: 100%;
            margin: 0;
          }

          @media only screen and (max-width: '999px') {
            width: 100%;
            padding: 0;
          }
        }

        .mobile-view-media-box-alignment {
          @media only screen and (max-width: '999px') {
            padding: 16px;
            background: #ffffff;
          }
        }

        .mobile-view-product-media-details-alignment {
          display: none;

          @media only screen and (max-width: '999px') {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .media-details-alignment {
              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: var(--secondary-500);
              }
            }

            .media-photo-video-dropdown-alignment {
              display: flex;
              align-items: center;
              gap: 12px;
              border: 1.5px solid var(--secondary-100);
              border-radius: 8px;
              padding: 6px 16px;

              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 15px;
                line-height: 20px;
                color: var(--secondary-600);
              }
            }
          }
        }

        .mobile-view-media-no-data-modal-alignment {
          height: calc(100vh - 205px);
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 100px;
          justify-content: center;

          @media only screen and (max-width: '1099px') {
            padding: 0 0 30px 0;
          }

          @media only screen and (max-width: '999px') {
            height: 100%;
            padding: 40px 0;
          }

          .media-no-data-name-alignment {
            span {
              font-family: 'Poppins', sans-serif;
              font-style: italic;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              color: var(--secondary-300);
              display: block;
            }
          }
        }

        .social-product-media-alignemt {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 4px;
          margin: 8px 0 64px 0;

          @media only screen and (max-width: '999px') {
            margin: 16px 0 0 0;
          }

          .social-product-img-alignment {
            height: 230px;
            width: 100%;
            cursor: pointer;
            object-fit: cover;

            @media only screen and (max-width: '999px') {
              height: 111px;
            }

            &:hover {
              img {
                transform: scale(0.98);
                transition: 0.3s ease-in-out;
              }
            }

            img {
              width: 100%;
              height: 100%;
              display: block;
              object-fit: cover;

              @media only screen and (max-width: '999px') {
                border-radius: 8px;
              }
            }

            &:hover {
              video {
                transform: scale(0.98);
                transition: 0.3s ease-in-out;
              }
            }

            video {
              width: 100%;
              height: 100%;
              display: block;
              object-fit: cover;
            }
          }
        }

        .mobile-view-feed-no-data-modal-alignment {
          display: none;

          @media only screen and (max-width: '999px') {
            display: block;
            background: #ffffff;
            border: 1px solid #e4e4e8;
            border-radius: 10px 10px 0px 0px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 94px;
            padding: 56px 0;
          }

          .feed-no-data-name-alignment {
            span {
              font-family: 'Poppins', sans-serif;
              font-style: italic;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              color: var(--secondary-300);
              display: block;
            }
          }
        }
      }
    }

    .social-product-no-data-alignment {
      border-radius: 16px;
      background: var(--primary-50);
      width: 100%;
      height: calc(100vh - 460px);
      border-radius: 18px;

      @media only screen and (max-width: '999px') {
        display: none;
      }
    }
  }
}

.followers-modal-alignment {
  .followers-modal-wrapper {
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(30, 36, 50, 0.4);
    bottom: 0;
    right: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    .followers-modal-box-alignment {
      width: 412px;
      max-width: 412px;
      height: auto;
      max-height: 560px;
      overflow-y: auto;
      background: #ffffff;
      border-radius: 16px;
      padding: 16px;

      .modal-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #f1f1f5;
        padding: 0 14px 22px 14px;

        .heding-alignment {
          display: flex;
          align-items: center;
          gap: 7px;

          h5 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            color: var(--secondary-900);
          }

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: #808191;
          }
        }

        .close-alignment {
          cursor: pointer;

          img {
            width: 24px;
            height: 24px;
            display: block;
          }
        }
      }

      .followers-search-alignment {
        padding: 20px 16px;
        position: relative;
        display: flex;
        align-items: center;

        input {
          background: #ffffff;
          border: 1px solid #f3f3f3;
          border-radius: 10px;
          width: 100%;
          height: 40px;
          padding: 8px 12px 8px 40px;
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;

          &::placeholder {
            color: #acb1c0;
          }
        }

        .serach-icon-alignment {
          position: absolute;
          top: 20px;
          left: 14px;
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            width: 40px;
            height: 40px;
            display: block;
          }
        }
      }

      .followers-details-alignment {
        padding: 0 16px 16px 16px;
        height: auto;
        max-height: 385px;
        overflow-y: auto;

        .followers-flex-alignment {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 0 16px 0;

          &:last-child {
            padding: 0;
          }

          .followers-name-profile-alignment {
            display: flex;
            align-items: center;
            gap: 16px;

            .profile-alignment {
              min-width: 40px;
              width: 40px;
              max-width: 40px;
              height: 40px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                display: block;
                object-fit: cover;
              }
            }

            h4 {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.1px;
              color: var(--secondary-900);
              cursor: pointer;
              max-width: 150px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }

          .folloers-button-alignment {
            width: 133px;
            height: 32px;

            button {
              width: 100%;
              height: 100%;
              background: var(--primary-500);
              border-radius: 8px;
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              color: #f8f8f8;

              &:hover {
                background: #2b41a8;
              }

              &.following {
                background: #f8f8f8;
                color: var(--primary-500);

                &:hover {
                  background: #eef1fc;
                }
              }
            }
          }
        }
      }
    }
  }
}

.social-profile-media-alignemt {
  @media only screen and (max-width: '767px') {
    display: none;
  }

  .social-profile-wrapper {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: rgba(30, 36, 50, 0.6);
    top: 0;
    right: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;

    .social-profile-modal-box-alignment {
      background: #ffffff;
      border: 1px solid #e2e2ea;
      border-radius: 16px;
      // display: flex;
      // gap: 16px;
      max-width: 1276px !important;
      width: 1276px !important;
      height: calc(100vh - 100px);
      position: relative;
      top: 30px;

      &.social-profile-feed-alignment {
        top: 32px;
      }

      .social-profile-slider-flex-alignment {
        display: flex !important;
        gap: 16px;
        height: calc(100vh - 102px);

        .social-profile-first-part-alignment {
          width: 616px;
          max-width: 616px;
          padding: 0 0 0 16px;
          margin: auto;

          .slick-slide {
            padding: 0 10px !important;
          }

          .slick-arrow {
            &.slick-prev {
              left: -70px;
            }

            &.slick-next {
              right: -710px;
            }
          }

          .img-media-alignment {
            // width: 585px;
            // max-width: 585px;
            // height: 398px;
            width: 100%;
            max-width: 100%;
            // height: auto;
            height: calc(100vh - 140px);

            img {
              width: 100%;
              height: 100%;
              display: block;
              object-fit: contain;
            }

            .post-preview-image {
              object-fit: contain;
            }
          }

          .child-img-media-alignment {
            // width: 585px;
            // max-width: 585px;
            // height: 398px;
            width: 100%;
            max-width: 100%;
            // height: auto;
            height: calc(100vh - 140px);
            position: relative;

            img {
              width: 100%;
              height: 100%;
              display: block;
              object-fit: contain;
            }
          }
        }

        .social-profile-second-part-alignment {
          width: 660px;
          max-width: 660px;

          .social-profile-modal-details-alignment {
            background: #ffffff;
            border: 1px solid #e2e2ea;
            border-radius: 16px 16px 0 0;
            padding: 16px 16px 8px 16px;
            border-right: none;
            border-top: none;
            border-bottom: none;

            .social-profile-flex-alignment {
              display: flex;
              justify-content: space-between;
              align-items: flex-start;

              .social-profile-alignment {
                display: flex;
                align-items: center;
                gap: 9px;

                .social-profile-img-alignment {
                  width: 56px;
                  max-width: 56px;
                  min-width: 56px;
                  height: 56px;

                  img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    object-fit: cover;
                  }
                }

                .socialprofile-name-details-alignment {
                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.1px;
                    color: var(--secondary-900);
                  }

                  .child-details-alignment {
                    display: flex;
                    align-items: center;
                    gap: 6px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 400;
                      font-size: 12px;
                      line-height: 18px;
                      color: #808191;
                    }

                    p {
                      width: 4px;
                      height: 4px;
                      border-radius: 50%;
                      background: #808191;
                    }

                    .view-alignment {
                      display: flex;
                      align-items: center;
                      gap: 4px;

                      span {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18px;
                        color: #808191;
                      }
                    }
                  }
                }
              }
            }
          }

          .social-follower-like-other-details-alignment {
            display: grid;
            grid-template-columns: 169px 169px 1fr;
            border-bottom: 1px solid #e2e2ea;
            height: 53px;
            background: #f8f8f8;

            .message-alignment {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;
              background: #f8f8f8;
              border-right: 1px solid #e2e2ea;
              border-left: 1px solid #e2e2ea;

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #495057;
              }
            }

            .share-alignment {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;
              border-right: 1px solid #e2e2ea;

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #495057;
              }
            }

            .like-alignment {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 4px;

              .like-details-flex-alignemnt {
                display: flex;
                align-items: center;

                .users_likes {
                  display: flex;
                  align-items: center;
                  margin-left: -10px;

                  .user_box {
                    width: 24px;
                    display: flex;
                    align-items: center;
                    height: 24px;
                    justify-content: center;
                    background: #fff;
                    object-position: center;
                    border: 1px solid #ffffff;
                    border-radius: 30px;

                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover !important;
                    }
                  }
                }

                .count {
                  margin-left: -10px;
                  background: var(--primary-500);
                  border: 1px solid #ffffff;
                  border-radius: 12px;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 18px;
                  letter-spacing: 0.1px;
                  color: #ffffff;
                  padding: 4px 9px 2px 9px;
                }
              }
            }
          }

          .social-chat-details-section {
            background: #f8f8f8;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border-radius: 0;
            border-left: 1px solid #e4e4e8;
            // border-bottom: 1px solid #e4e4e8;
            // height: auto;

            height: calc(100vh - 343px);

            @media only screen and (max-width: '1204px') {
              display: none;
            }

            .social-chat-details-alignment {
              margin: 16px;
              border-radius: 0 0 0 0;

              &.no-data-alignment {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;

                .close-comment-heigth {
                  justify-content: center;
                }
              }
            }

            .social-send-chat-alignment {
              background: #ffffff;
              border-radius: 0px 0px 16px 16px;
              padding: 16px;
              position: sticky;
              bottom: 0;

              @media only screen and (max-width: '1204px') {
                display: none;
              }

              .send-box-details-flex {
                display: flex;
                align-items: center;
                gap: 20px;

                .send-box-img-alignment {
                  width: 32px;
                  max-width: 32px;
                  min-width: 32px;
                  height: 32px;

                  img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                  }
                }

                .send-box-input-alignment {
                  width: 100%;
                  height: 46px;
                  position: relative;

                  input {
                    width: 100%;
                    height: 100%;
                    background: #fafafb;
                    border: 1px solid #f1f1f5;
                    border-radius: 10px;
                    padding: 11px 90px 16px 16px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #92929d;
                  }

                  .input-icon-alignment {
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    position: absolute;
                    top: 11px;
                    right: 16px;

                    img {
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.slick-arrow {
  background: rgba(255, 255, 255, 0.6);

  img {
    &.hover-img {
      display: none;
    }
  }

  &:hover {
    background: #a855f7 !important;

    img {
      &.hover-img {
        display: block;
      }

      &.defalt-icon {
        display: none;
      }
    }

    &.slick-prev {
      img {
        animation: righttoleft 0.4s ease-in-out;
      }
    }

    &.slick-next {
      img {
        animation: bounceRight 0.4s ease-in-out;
      }
    }
  }
}

@keyframes righttoleft {
  0% {
    -moz-transform: translateX(4px);
    transform: translateX(4px);
  }

  100% {
    -moz-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes bounceRight {
  0% {
    -ms-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -ms-transform: translateX(-4px);
    transform: translateX(-4px);
  }
}

.share-post-design-section {
  background: #f8f8f8;
  border: 1px solid #f8f8f8;
  border-radius: 10px;
  padding: 8px;
  width: 100%;
  display: block;

  // @media only screen and (max-width:"1440px") {
  //   height: calc(100vh - 280px);
  //   overflow-y: auto;
  // }

  // .share-post-design-section-slider-alignment {
  //   width: calc(100% - 245px - 90px);
  // }

  &:hover {
    .slick-arrow {
      display: flex;
    }
  }

  .slick-slide {
    div {
      // height: 354px;
      padding: 0 4px;
      // @media screen and (max-width: 767px) {
      //   height: 140px;
      // }
    }
  }

  .slick-list {
    border-radius: 8px;
  }

  .share-post-design-grid-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }

  .share-product-img-alignment {
    // width: 100%;
    // height: 354px;
    padding: 0 4px;
    width: 100%;
    height: 354px;
    background-color: rgb(237 237 237);
    position: relative;
    border-radius: 8px 8px 0px 0px;
    overflow: hidden;

    @media screen and (max-width: 767px) {
      height: 140px;
    }

    .shared-product-media {
      // position: absolute;
      border-radius: 8px 8px 0px 0px;
      position: absolute;
      width: 100%;
      height: 100%;
      background-size: cover;
      height: -webkit-fill-available;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      mix-blend-mode: multiply;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  .share-post-details-alignment {
    padding: 16px 32px 24px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @media screen and (max-width: 767px) {
      align-items: end;
      padding: 8px;
    }

    .share-post-heading {
      h5 {
        cursor: pointer;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: var(--secondary-900);
      }
    }

    .share-post-price-alignment {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 8px 0 0 0;

      h6 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        color: #000000;
      }

      span {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #808191;
      }
    }

    .share-post-delivery-shipping {
      display: flex;
      align-items: center;
      gap: 6px;
      padding: 8px 0 0 0;

      @media screen and (max-width: 767px) {
        // margin: 2px 0;
        padding: 0;
      }

      .best-seller-alignment {
        h6 {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          background: linear-gradient(263.94deg, #ffc554 7.44%, #744c00 86.5%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
      }

      .dot-alignment {
        p {
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: #acb1c0;
        }
      }

      .discount-menu-align {
        width: auto;
        height: auto;
        background: #ff7d23;
        border-radius: 11px;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          padding: 1px 9px;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          display: flex;
          align-items: center;
          color: #ffffff;

          @media screen and (max-width: 767px) {
            padding: 1px 4px;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: #ffffff;
            white-space: nowrap;
          }
        }
      }

      .delivery-menu-align {
        width: auto;
        height: 21px;
        // background: #eaeaea;
        border-radius: 13.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2px;

        @media screen and (max-width: 767px) {
          padding: 4px 6px;
        }

        padding: 4px 8px;

        img {
          width: 100%;
          height: 100%;
        }

        span {
          font-weight: 500;
          font-style: italic;
          font-size: 12px;
          line-height: 18px;
          display: flex;
          align-items: center;
          text-decoration-line: none;
          color: #5f40af;
        }
      }

      .arrow-menu-align {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 6px;

        img {
          width: 11px;
          height: 11px;
        }

        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          display: flex;
          align-items: center;
          color: #70c217;
        }
      }
    }

    .share-post-other-option-alignment {
      display: flex;
      align-items: center;
      gap: 8px;

      .share-post-box-alignment {
        width: 44px;
        height: 44px;
        background: #ffffff;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          background: #eef1fc;
        }

        img {
          width: 24px;
          height: 24px;
          display: block;
        }
      }
    }
  }

  .slick-arrow {
    display: none;
    transition: 0.15s ease-in-out;
  }

  .slick-prev {
    left: 10px !important;
    z-index: 99 !important;
    width: 40px;
    height: 40px;
    background: rgba(255, 255, 255, 0.6) !important;

    img {
      width: 8px;
    }
  }

  .slick-next {
    right: 15px !important;
    z-index: 99 !important;
    width: 40px;
    height: 40px;
    background: rgba(255, 255, 255, 0.6) !important;

    img {
      width: 8px;
    }
  }
}

.social-chat-profile-bg-alignment {
  margin: 0 0 16px 0;

  .social-chat-profile-alignment {
    display: flex;
    background: #ffffff;
    border: 1px solid #f1f1f5;
    border-radius: 0px 10px 10px 10px;
    padding: 12px 16px;
    width: fit-content;
    align-items: flex-start;
    gap: 16px;

    &:last-child {
      margin: 0;
    }

    .social-profile-chat-img-alignment {
      width: 36px;
      max-width: 36px;
      min-width: 36px;
      height: 36px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        display: block;
        object-fit: cover;
      }
    }

    .social-chat-send-alignment {
      .social-chat-heading-alignment {
        display: flex;
        align-items: center;
        gap: 8px;

        h6 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: #495057;
          cursor: pointer;
          margin: 0;
        }

        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #808191;
        }
      }

      .chat-text-alignment {
        display: flex;
        align-items: center;
        gap: 6px;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #495057;
        }
      }

      .child-social-chat-alignment {
        display: grid;
        grid-template-columns: 36px 1fr;
        gap: 16px;
        padding: 16px 0 0 0;

        .child-profile-img {
          width: 100%;
          height: 36px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        .child-profile-details-alignment {
          .child-social-chat-heading-alignment {
            display: flex;
            align-items: center;
            gap: 8px;

            h6 {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              color: #495057;
            }

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #808191;
            }
          }

          .child-chat-text-alignment {
            display: flex;
            align-items: center;
            gap: 6px;

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #495057;
            }
          }
        }
      }

      a {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: var(--primary-500);
        cursor: pointer;
        padding: 8px 0 0 0;
      }

      .see-replies-text-align {
        padding-top: 4px;
      }
    }

    a {
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: var(--primary-500);
      cursor: pointer;
      padding: 0 0 0 53px;
    }
  }

  .social-comment-below-section {
    display: flex;
    align-items: center;
    gap: 23px;
    justify-content: flex-start;

    &.revece-alignment {
      flex-direction: row-reverse;
      justify-content: flex-end;

      display: flex;
      align-items: center;
      gap: 23px;
    }

    .replies-likes-main {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 8px 0;
      gap: 10px;

      .replies-section-align {
        width: 32px;
        height: 32px;
        background: #ffffff;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          cursor: pointer;
        }
      }
    }
  }
}

.social-chat-profile-alignment-text {
  a {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--primary-500);
  }
}

.mobile-view-comment-section {
  display: none;

  @media only screen and (max-width: '767px') {
    display: block;

    .mobile-view-comment-modal-alignment {
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      height: 100vh;
      z-index: 99;
      background: #eceef4;

      &.small-comment-box {
        height: 56vh;
        bottom: 0;
        top: auto;
      }

      .mobile-view-modal-comment-details-alignment {
        @media only screen and (max-width: '1024px') {
          height: 100%;
        }

        .mobile-view-comment-back-alignment {
          display: flex;
          align-items: center;
          gap: 46px;
          padding: 8px 10px;
          display: none;
          background: #ffffff;

          @media only screen and (max-width: '1024px') {
            display: flex;
            position: sticky;
            top: 56px;
            z-index: 99;
          }

          @media only screen and (width: 390px) and (height: 844px) and (-webkit-device-pixel-ratio: 3) {
            top: 0px;
          }

          .back_details-alignment {
            background: #f8f8f8;
            border-radius: 10px;
            padding: 4px 9px 4px 10px;
            display: flex;
            align-items: center;
            gap: 8px;

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              color: #808191;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 58px;
            }
          }
        }

        .mobile-view-cart-height-alignment {
          @media only screen and (max-width: '1024px') {
            // height: 100%;
            // overflow-y: auto;
            padding: 60px 0 122px 0;
            height: 100%;
          }

          .mobile-heading-alignment {
            h4 {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 18px;
              line-height: 27px;
              text-align: center;
              color: var(--secondary-900);
            }
          }

          .mobile-heading-alignment-none {
            display: none;
          }
        }

        .mobile-view-comment-profile-details-alignment {
          padding: 16px;
          background: #ffffff;
          margin: 66px 0 0 0;

          @media only screen and (max-width: '1024px') {
            margin: 0;
          }

          .mobile-view-comment-profile-alignment {
            display: flex;
            align-items: center;
            gap: 16px;

            .mobile-profile-img-alignment {
              width: 40px;
              height: 40px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
              }
            }

            .mobile-profile-details-alignment {
              h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                color: #000000;
              }

              .mobile-child-details-alignment {
                display: flex;
                align-items: center;
                gap: 8px;

                span {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 18px;
                  color: #acb1c0;
                }

                p {
                  width: 4px;
                  height: 4px;
                  border-radius: 50%;
                  background: #d9d9d9;
                }

                .mobile-comment-view-alignment {
                  display: flex;
                  align-items: center;
                  gap: 4px;

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    color: #acb1c0;
                  }
                }
              }
            }
          }

          .mobile-view-notes-alignment {
            padding: 8px 0 0 0;

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: var(--secondary-900);
            }
          }
        }

        .mobile-comment-body-details-alignment {
          padding: 8px 16px;

          height: calc(100vh - 212px);
          overflow-y: auto;

          @media only screen and (max-width: '1024px') {
            height: 100%;
            // padding: 8px 16px 122px 16px;
            overflow-y: inherit;
            padding: 8px;
          }

          .mobile-comment-body-box-alignment {
            background: #ffffff;
            border: 1px solid #e3e4e8;
            border-radius: 0px 10px 10px 10px;
            padding: 16px;

            .mobile-comment-main-details-alignment {
              display: flex;
              align-items: center;
              gap: 8px;

              &.child-alignment {
                align-items: flex-start;
              }

              .mobile-comment-userImg-alignment {
                width: 32px;
                height: 32px;
                min-width: 32px;
                max-width: 32px;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                }
              }

              .mobile-comment-heading-details-alignment {
                display: flex;
                align-items: center;
                gap: 8px;

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 21px;
                  color: #495057;
                }

                span {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 400;
                  font-size: 10px;
                  line-height: 15px;
                  color: #808191;
                }
              }

              .child-details-child-notes-alignment {
                padding: 2px 0 0 0;

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 18px;
                  color: #495057;
                }

                a {
                  padding: 16px 0 0 0;
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 18px;
                  color: var(--primary-500);
                  cursor: pointer;
                  display: block;
                }
              }
            }

            .mobile-comment-body-notes-alignment {
              padding: 8px 0 0 0;

              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: #495057;
              }
            }

            .mobile-comment-img-uploded-details-alignment {
              display: flex;
              align-items: center;
              gap: 8px;
              padding: 20px 0 0 0;

              .mobile-comment-img-alignment {
                height: 82px;
                max-width: 100%;
                position: relative;

                img {
                  max-width: 100%;
                  height: 100%;
                  border-radius: 5px;
                }

                &.last-img-alignment {
                  &::before {
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    top: 0;
                    right: 0;
                    background: #1e2432;
                    opacity: 0.6;
                    border-radius: 5px;
                  }

                  .name-alignment {
                    position: absolute;
                    height: 100%;
                    top: 32px;
                    right: 0;
                    left: 0;

                    h4 {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 21px;
                      text-align: center;
                      color: #f8f8f8;
                    }
                  }
                }
              }
            }

            .child-mobile-comment-details-alignment {
              padding: 14px 20px;
            }
          }

          .mobile-other-option-alignment {
            padding: 8px 0 16px 0;

            .mobile-other-first-alignment {
              display: flex;
              align-items: center;
              gap: 16px;

              .replies-likes-main {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;

                .replies-section-align {
                  width: 32px;
                  height: 32px;
                  background: #f8f8f8;
                  border-radius: 10px;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  svg {
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }

        .mobile-view-footer-input-alignment {
          position: fixed;
          bottom: 0;
          width: 100% !important;
          display: block;
          align-items: center;
          gap: 8px;
          background: rgba(248, 248, 248, 0.92);
          backdrop-filter: blur(13.5914px);
          // padding: 8px 16px 16px 16px;
          padding: 0px 12px 2px 12px;

          @media only screen and (max-width: '1024px') {
            bottom: 64px;
          }

          .mobile-user-alignment {
            width: 36px;
            height: 36px;
            min-width: 36px;
            height: 36px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }

          .mobile-input-alignment {
            display: flex;
            align-items: center;
            gap: 2px;

            .mobile-input-details-alignment {
              width: 209px;
              height: 45px;
              position: relative;

              input {
                background: #ffffff;
                border: 1px solid #e4e4e8;
                border-radius: 10px;
                width: 100%;
                height: 100%;
                padding: 0 40px 0 10px;
              }

              .icon-alignment {
                position: absolute;
                top: 10px;
                right: 10px;
              }
            }

            .mobile-img-alignment {
              img {
                max-width: 100%;
                cursor: pointer;
              }
            }

            .mobile-send-button-alignment {
              width: 45px;
              min-width: 45px;
              max-width: 45px;
              height: 45px;
              background: var(--primary-500);
              border-radius: 10px;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                max-width: 100%;
                width: 100%;
              }
            }
          }
        }

        .mobile-view-alignment-comment {
          width: 100% !important;
          display: block;
        }
      }
    }
  }
}

.post-comment {
  .react-input-emoji--button svg {
    height: 18px;
    width: 18px;

    &:hover {
      fill: red !important;
    }
  }
}

.mobile-view-heading-section {
  display: none;

  @media only screen and (max-width: '767px') {
    display: flex;
    padding: 20px 16px 6px 16px;
    background: rgba(248, 248, 248, 0.92);
    backdrop-filter: blur(13.5914px);
    align-items: center;
    justify-content: space-between;

    .store-back_details-alignment {
      background: #ffffff;
      border-radius: 10px;
      padding: 4px 9px 4px 10px;
      display: flex;
      align-items: center;
      gap: 8px;

      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #808191;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 58px;
      }
    }

    .store-mobile-header-alignment {
      display: flex;
      align-items: center;
      gap: 4px;

      .mobile-profile-img-alignment {
        width: 24px;
        height: 24px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .mobile-profile-name-alignment {
        h5 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 18px;
          line-height: 120%;
          color: var(--secondary-900);
        }
      }
    }

    .store-more-option-alignment {
      width: 44px;
      height: 32px;
      background: #ffffff;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.stcky-header-alignment {
  position: sticky;
  top: 0;
  z-index: 9999;
  background: #fff;
}

.post-input-align-responsive {
  // @media only screen and (max-width: '767px') {
  //   display: none;
  // }
}

.dropdown {
  position: relative;
  width: 300px;
}

.dropdown__input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.dropdown__list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  border: 1px solid #ccc;
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.dropdown__list-item {
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}

.dropdown__list-item--highlighted {
  background-color: #f0f0f0;
}

.dropdown__tag {
  display: inline-flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  border-radius: 4px;
}

.dropdown__tag-label {
  margin-right: 5px;
}

.dropdown__tag-remove {
  cursor: pointer;
}

.post-add-edit-modal {
  z-index: 999999999;
}

.login-modal-index {
  z-index: 999999999999999999;
}

.post-padding {
  padding: 14px;
}

.post-header-padding {
  @media screen and (max-width: 767px) {
    padding: 14px 14px 0 14px;
  }
}

.post-content-padding {
  @media screen and (max-width: 767px) {
    padding: 0 14px;
  }
}

.post-media-radious {
  border-radius: 12px;

  // height: 526px;
  // width: 628px;
  @media screen and (max-width: 767px) {
    border-radius: 0;
  }
}

.post-loaded-radious {
  border-radius: 12px;

  @media screen and (max-width: 767px) {
    border-radius: 0;
  }
}

// .skeleton-image-align{
//   height: 308px;
//   width: 308px;

//   .skeleton-image-align{
//     height: 100%;
//     width: 100%;
//   }
// }

.post-comment-sticky-alignment {
  position: sticky;
  top: 0;
  bottom: 0;
  background: #fff;
  z-index: 9;

  @media only screen and (max-width: '1024px') {
    background: transparent;
    padding: 0 0 10px 0;
  }
}

.mobile-view-text-alignment {
  color: var(--secondary-500);
  font-family: 'Poppins', sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  padding: 2px 0 0 0;
  text-align: center;

  &.active-text {
    color: var(--primary-500);
  }
}

.mobile-view-profile-img-alignment {
  width: 24px;
  height: 24px;
  margin: 2px auto 1px auto;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}

.custom-mb-8 {
  margin: 0 0 8px 0;
}

.feed-mobileSellerCommentPostAlignment {
  display: none;

  @media only screen and (max-width: '767px') {
    display: block;
    margin: 8px 0 0 0;
  }

  .feed-mobileSellerCommentBox {
    border-top: 1px solid #e4e4e8;
    border-bottom: 1px solid #e4e4e8;
    background: #ffffff;
    padding: 16px;

    @media only screen and (max-width: '1024px') {
      border-top: 1px solid var(--primary-50);
      border-bottom: 1px solid var(--primary-50);
    }

    h6 {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--secondary-900);
    }

    .feed-mobileSellerPostBoxAlignment {
      display: flex;
      align-items: center;
      gap: 8px;
      margin: 16px 0 0 0;

      .feed-mobileSellerPostImgAlignment {
        width: 40px;
        height: 40px;
        min-width: 40px;
        max-width: 40px;

        img {
          width: 100%;
          height: 100%;
          display: block;
          border-radius: 4px;
          object-fit: cover !important;
        }

        &.round-border {
          img {
            border-radius: 50%;
            object-fit: cover !important;
          }
        }
      }

      .feed-mobileSellerInputAlignment {
        background: #fafafb;
        border: 1px solid #e4e4e8;
        border-radius: 10px;
        height: 40px;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 0 8px;

        input {
          width: 100%;
          height: 100%;
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          background: transparent;
          border: none;

          &::placeholder {
            color: #acb1c0;
          }
        }
      }
    }
  }
}

.feed-new-left-side-alignment {
  padding: 22px 0 0 0;
  height: auto;
  max-height: calc(100vh - 110px);
  overflow-y: auto;

  .feed-sidebar-profile-alignment {
    padding: 0 0 38px 0;

    .feed-sidebar-profile-details {
      display: flex;
      align-items: center;
      gap: 8px;

      .feed-sidebar-profile-img {
        width: 56px;
        height: 56px;

        @media only screen and (max-width: '1199px') {
          width: 48px;
          height: 48px;
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .feed-sidebar-profile-name {
        h6 {
          color: var(--secondary-900);
          font-family: 'Poppins', sans-serif;
          font-size: 20px;
          font-weight: 600;
          line-height: 24px;

          @media only screen and (max-width: '1199px') {
            font-size: 18px;
            line-height: 22px;
          }
        }

        .profile-status-alignment {
          display: flex;
          align-items: center;
          gap: 4px;

          p {
            width: 6px;
            min-width: 6px;
            max-width: 6px;
            display: block;
            height: 6px;
            border-radius: 50%;
            background: #4aab0e;
          }

          span {
            color: var(--secondary-900);
            font-family: 'Poppins', sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
          }
        }
      }
    }
  }

  .feed-new-sidebar-option-alignment {
    ul {
      li {
        display: flex;
        align-items: center;
        gap: 16px;
        color: var(--secondary-900);
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        cursor: pointer;
        margin: 0 0 4px 0;
        padding: 10px 16px;
        transition: 0.4s ease-in-out;

        &:hover {
          border-radius: 10px;
          background: var(--primary-50);
          transition: 0.4s ease-in-out;
        }

        &.active-sidebar-option {
          border-radius: 10px;
          background: var(--primary-50);
          transition: 0.4s ease-in-out;
        }

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  .feed-new-invite-your-friend-alignment {
    padding: 38px 0 0 0;

    .invite-your-friend-details {
      .invite-your-friend-heading {
        h4 {
          color: var(--secondary-900);
          font-family: 'Poppins', sans-serif;
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
        }
      }

      .invite-your-friend-profile-alignment {
        padding: 16px 0 0 0;
        display: flex;
        align-items: center;

        .invite-your-friend-details-profile {
          width: 36px;
          height: 36px;
          margin: 0 -18px 0 0;

          &:first-child {
            position: relative;
            z-index: 9999;
          }

          &:nth-child(2) {
            position: relative;
            z-index: 999;
          }

          &:nth-child(3) {
            position: relative;
            z-index: 99;
          }

          &:last-child {
            z-index: 9;
          }

          img {
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 50%;
            border-radius: 36px;
            border: 2px solid #fff;
          }
        }
      }

      .invite-your-friend-notes {
        padding: 8px 0 0 0;

        p {
          color: var(--secondary-900);
          font-family: 'Poppins', sans-serif;
          font-size: 13px;
          font-weight: 400;
          line-height: 19px;
        }
      }

      .invitation-send-button {
        padding: 16px 0 0 0;

        button {
          padding: 0 14px;
          height: 32px;
          color: #fff;
          font-family: 'Poppins', sans-serif;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          cursor: pointer;
          border-radius: 8px;
          background: #8930cc;
        }
      }
    }
  }

  .new-footer-section {
    padding: 48px 0 0 0;

    .new-footer-top-details {
      padding: 0 24px 0 0;

      .new-footer-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px 41px;

        @media only screen and (max-width: '1199px') {
          gap: 16px;
        }

        .new-footer-grid-item {
          p {
            color: var(--secondary-500);
            font-family: 'Inter';
            font-size: 13px;
            font-weight: 400;
            line-height: 15px;
            transition: 0.4s ease-in-out;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
              transition: 0.4s ease-in-out;
            }
          }
        }
      }
    }

    .new-footer-copy-right-section {
      padding: 48px 0 0 0;

      p {
        color: var(--secondary-500);
        font-family: 'Inter';
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
      }
    }
  }
}

.feed-new-suggested-details-alignment {
  .feed-new-suggested-box-alignment {
    border-radius: 15px;
    background: #f3f5f8;
    padding: 14px 16px 16px 16px;

    .feed-new-suggested-box-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h6 {
        color: var(--bookmark-heading-color);
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.1px;

        @media only screen and (max-width: '1199px') {
          font-size: 18px;
          line-height: 22px;
        }
      }

      a {
        cursor: pointer;
        padding: 6px 16px;
        color: var(--primary-500);
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;

        @media only screen and (max-width: '1199px') {
          padding: 6px 12px;
          font-size: 14px;
        }
      }
    }

    .feed-new-suggested-body-details-alignment {
      margin: 16px 0 0 0;
      height: auto;
      max-height: 316px;
      overflow-y: auto;

      .feed-new-suggested-child-box-alignment {
        width: 100%;
        border-radius: 10px;
        background: #fff;
        padding: 8px;
        display: flex;
        align-items: center;
        gap: 12px;
        margin: 0 0 8px 0;

        &:last-child {
          margin: 0;
        }

        .feed-new-suggested-img-alignment {
          width: 84px;
          height: 84px;

          @media only screen and (max-width: '1199px') {
            width: 70px;
            height: 70px;
          }

          .new-suggested-img-alignment-img {
            height: 100%;

            img {
              width: 100%;
              height: 100%;
              border-radius: 6px;
              object-fit: cover;
            }
          }
        }

        .feed-new-suggested-profile-details {
          h6 {
            color: var(--secondary-900);
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            cursor: pointer;
            transition: 0.4s ease-in-out;

            &:hover {
              text-decoration: underline;
              transition: 0.4s ease-in-out;
            }
          }

          .feed-new-suggested-flex-alignment {
            display: flex;
            align-items: center;
            gap: 4px;

            span {
              color: var(--secondary-500);
              font-family: 'Poppins', sans-serif;
              font-size: 11px;
              font-weight: 400;
              line-height: 14px;
            }

            p {
              width: 2px;
              height: 2px;
              border-radius: 50%;
              background: #7a7e88;
            }
          }

          .feed-new-suggested-follow-details {
            padding: 5px 0 0 0;

            a {
              color: var(--primary-500);
              font-family: 'Poppins', sans-serif;
              font-size: 14px;
              font-weight: 500;
              line-height: 19px;
              cursor: pointer;
              padding: 6px 0;
              display: block;
            }
          }

          .feed-new-suggested-unfollow-details {
            padding: 5px 0 0 0;
            display: flex;
            // justify-content: center;
            align-items: center;
            gap: 6px;

            .feed-new-followed-store-alignment {
              padding: 5px 0 0 0;

              a {
                display: flex;
                align-items: center;
                gap: 5px;
                color: var(--secondary-500);
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-weight: 500;
                line-height: 18px;
                padding: 6px 0;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

.feed-new-suggested-clubs-alignment {
  margin: 8px 0 0 0;

  .feed-new-suggested-clubs-box {
    border-radius: 15px;
    background: #f3f5f8;
    padding: 14px 16px;

    .feed-new-suggested-clubs-heading {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h6 {
        color: var(--bookmark-heading-color);
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.1px;

        @media only screen and (max-width: '1199px') {
          font-size: 18px;
          line-height: 22px;
        }
      }

      a {
        cursor: pointer;
        padding: 6px 16px;
        color: var(--primary-500);
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;

        @media only screen and (max-width: '1199px') {
          font-size: 14px;
          padding: 6px 12px;
        }
      }
    }

    .feed-new-suggested-clubs-body-details-alignment {
      margin: 12px 0 0 0;
      height: auto;
      max-height: 232px;
      overflow-y: auto;

      .feed-new-suggested-details-box-alignment {
        border-radius: 10px;
        background: #fff;
        display: grid;
        height: 112px;
        grid-template-columns: 150px 1fr;
        align-items: center;
        margin: 0 0 8px 0;

        @media only screen and (max-width: '1199px') {
          height: 100px;
        }

        &:last-child {
          margin: 0;
        }

        .feed-new-suggested-club-img {
          width: 100%;
          height: 112px;
          position: relative;

          .club-image-section {
            position: absolute;
            border-radius: 16px;
          }

          @media only screen and (max-width: '1199px') {
            height: 100px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
            border-radius: 14px;
          }

          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            border: 2px solid #fff;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 34.34%, #fff 86.45%);
            z-index: 99;
            top: 0;
            left: 0;
          }
        }

        .feed-new-suggested-club-details-alignment {
          .feed-new-suggested-heading {
            cursor: pointer;

            h6 {
              color: var(--secondary-900);
              font-family: 'Poppins', sans-serif;
              font-size: 14px;
              font-weight: 500;
              line-height: 18px;
              transition: 0.4s ease-in-out;

              &:hover {
                transition: 0.4s ease-in-out;
                text-decoration: underline;
              }
            }
          }

          .feed-new-suggested-club-member-details {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 5px 0 0 0;

            .feed-new-suggested-club-member-profile-alignment {
              display: flex;
              align-items: center;

              .club-img-details {
                width: 18px;
                height: 18px;
                margin: 0 -6px 0 0;

                &:first-child {
                  position: relative;
                  z-index: 9999;
                }

                &:nth-child(2) {
                  position: relative;
                  z-index: 999;
                }

                &:nth-child(3) {
                  position: relative;
                  z-index: 99;
                }

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  object-fit: cover;
                  display: block;
                  border: 1px solid #fff;
                }
              }
            }

            .total-club-member-box {
              p {
                border-radius: 12px;
                border: 1px solid #fff;
                background: #1e2432;
                padding: 4px 6px;
                color: #fff;
                font-family: 'Poppins', sans-serif;
                font-size: 11px;
                font-weight: 400;
                line-height: 12px;
                letter-spacing: 0.1px;
                margin: 0;
              }
            }
          }

          .join-club-memeber-button-alignment {
            padding: 12px 0 0 0;

            button {
              color: var(--primary-500);
              text-align: center;
              font-family: 'Poppins', sans-serif;
              font-size: 14px;
              font-weight: 600;
              line-height: normal;
              padding: 0;
              margin: 0;
              background: transparent;
              cursor: pointer;
              border: none;
            }
          }

          .feed-new-suggested-unfollow-details {
            padding: 10px 0 0 0;
            display: flex;
            // justify-content: center;
            align-items: center;
            gap: 6px;

            .feed-new-followed-store-alignment {
              padding: 5px 0 0 0;

              a {
                display: flex;
                align-items: center;
                gap: 5px;
                color: var(--secondary-500);
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-weight: 500;
                line-height: 18px;
                padding: 6px 0;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

.feed-page-alignment {
  height: calc(100vh - 96px);
  overflow-y: auto;
  margin: 30px 0 0 0;

  @media only screen and (max-width: '1299px') {
    padding: 0 16px;
  }

  @media only screen and (max-width: '1024px') {
    // height: 100%;
    padding: 0;
    margin: 0;
  }

  @media only screen and (max-width: '767px') {
    padding: 0 0 15px 0;
    // height: 100%;
    margin: 0;
    padding: 0;
  }

  .feed-page-layout-alignment {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 16px;

    .left-sidebar-alignment {
      max-width: 252px;
      width: 252px;
      position: sticky;
      top: 0;

      @media only screen and (max-width: '1199px') {
        max-width: 220px;
        width: 220px;
      }

      @media only screen and (max-width: '1099px') {
        max-width: 196px;
        width: 196px;
      }
    }

    .center-feed-details {
      max-width: 660px;
      width: 660px;

      @media only screen and (max-width: '1299px') {
        max-width: 630px;
        width: 630px;
      }

      @media only screen and (max-width: '1199px') {
        max-width: 550px;
        width: 550px;
      }

      @media only screen and (max-width: '1099px') {
        max-width: 500px;
        width: 500px;
      }
    }

    .right-sidebar-alignment {
      max-width: 332px;
      width: 332px;
      position: sticky;
      top: 0;

      @media only screen and (max-width: '1199px') {
        max-width: 300px;
        width: 300px;
      }
    }
  }

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    padding: 20px;
  }

  .section {
    border: 1px solid #ccc;
    padding: 20px;
  }

  .loader {
    text-align: center;
    padding: 10px;
  }
}

.post-something-box-alignment {
  border-radius: 16px;
  border: 2px solid #eef1fd;
  background: #fff;

  .post-something-heading {
    padding: 10px 20px;
    border-bottom: 1px solid #f1f1f5;

    p {
      color: #000;
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }
  }

  .post-something-body-alignment {
    padding: 7px 20px 15px 20px;
  }
}

.border-bottom-alignment {
  border-bottom: 1px solid #f1f1f5;

  @media only screen and (max-width: '1024px') {
    border-bottom: 1px solid var(--primary-50);
  }
}

.border-bottom-none {
  border-bottom: none;
}

.mobile-view-height-alignment {
  @media only screen and (max-width: '1024px') {
    // padding: 56px 0 0 0;
    // height: calc(100vh - 120px);
    // max-height: calc(100vh - 120px);
    // min-height: calc(100vh - 120px);
    // height: calc(100vh - 50px - 65px - 64px);
    // overflow-y: auto;

    height: calc(100vh - 54px - 64px);
    overflow-y: auto;
  }

  @media only screen and (max-width: '574px') {
    height: inherit;
    min-height: calc(100vh - 120px);
  }
}

.right-side-overflow-alignment {
  height: auto;
  max-height: calc(100vh - 110px);
  overflow-y: auto;
}

.channel-height-alignment {
  @media only screen and (max-width: '1024px') {
    height: 100%;
  }
}

.no-data-found-alignment {
  @media only screen and (max-width: '574px') {
    height: calc(100vh - 54px - 64px);
    overflow-y: auto;
  }
}

.feed-post-uplod-status-box {
  border: 1px solid var(--grey4);
  background: #fff;
  margin: 4px 0 0 0;
  width: 100%;
  height: 52px;
  padding: 0 16px;
  display: flex;
  align-items: center;

  .posting-status-alignment {
    width: 100%;

    .posting-status-top-details {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h6 {
        color: var(--secondary-900);
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
      }

      span {
        color: var(--secondary-500);
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
      }
    }

    .posting-status-range-alignment {
      width: 100%;
      height: 4px;
      border-radius: 2px;
      background: var(--primary-50);
      margin: 5px 0 0 0;
      position: relative;

      .processing-rage-line {
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 2px;
        background: var(--primary-500);
        width: 169px;
        height: 100%;
        z-index: 99;
      }
    }
  }

  .posting-status-failed-alignment {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h6 {
      color: var(--red-500);
      font-family: 'Poppins', sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
    }

    .posting-right-side-alignment-details {
      display: flex;
      align-items: center;
      gap: 24px;

      span {
        color: var(--secondary-500);
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        cursor: pointer;
      }
    }
  }
}

// .ant-modal {
//   @media only screen and (max-width:"767px") {

//     width: calc(100vw - 54px) !important;
//   }
// }

.post-add-edit-modal {
  &.post-view-modal {
    .ant-modal {
      @media only screen and (max-width: '767px') {
        width: calc(100vw - 54px) !important;
      }
    }
  }
}

.mobile-view-footer-details {
  @media only screen and (max-width: '1024px') {
    display: none;
  }
}

.new-comment-input-emogii-alignment {
  display: none;

  @media only screen and (max-width: '1024px') {
    display: block;
    padding: 8px 0;
    position: sticky;
    bottom: 0;
    left: 0;
    background: #fff;

    &.modal-input-alignemt {
      padding: 8px 16px;
    }

    .new-comment-input-emogii-details-alignment {
      display: flex;
      align-items: center;
      gap: 8px;

      .new-comment-input-box {
        width: 100%;
        height: 44px;
        border-radius: 10px;
        border: 1px solid #f1f1f5;
        background: #fafafb;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        padding: 0 16px;

        input {
          width: 100%;
          height: 100%;
          border: none;
          background: transparent;
          color: #92929d;
          font-size: 13px;
          font-weight: 400;
          line-height: 21px;
        }
      }

      .send-message-box-alignment {
        width: 44px;
        height: 44px;
        max-width: 44px;
        min-width: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background: var(--primary-50);
      }
    }
  }
}

// .comment-details-star-alignment {
//   @media only screen and (max-width:"1024px") {
//     ::global(.star-ratings) {
//       svg {
//         width: 10px;
//         height: 10px;
//       }
//     }
//   }
// }

.react-input-emoji--button svg {
  &:hover {
    fill: #556ee6 !important;
  }
}

.ant-dropdown-link {
  svg {
    &:hover {
      path {
        fill: #556ee6 !important;
      }
    }
  }
}

.mobile-view-height-alignment {
  @media only screen and (max-width: '1024px') {
    height: auto;
    overflow-y: auto;
    padding: 4px 0 0 0;
    background: var(--secondary-50);
  }
}

.store-logo-for-skeleton {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.no-data-social-produtc-box-alignment {
  width: 100%;
  height: calc(100vh - 452px);
  border-radius: 16px;
  background: var(--primary-50);
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: '1024px') {
    margin: 16px;
    width: calc(100% - 32px);
  }

  p {
    color: var(--violet-300);
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 14px;

    @media only screen and (max-width: '757px') {
      max-width: 145px;
      margin: 0 auto;
      text-align: center;
    }
  }
}

// comment header section design start
.more-menu-align {
  display: flex;
  gap: 28px;

  // @media only screen and (max-width: '1024px') {
  //   display: none;
  // }

  .more-menu-align-web {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .more-menu-align-mobile {
    display: none;

    @media (max-width: 768px) {
      display: flex;
    }
  }

  .social-option-alignment {
    display: flex;
    align-items: center;
    gap: 28px;

    .close-icon-contain {
      width: 24px;
      height: 24px;
      min-width: 24px;
      max-width: 24px;

      img {
        cursor: pointer;
      }
    }
  }
}

// comment header section design end

// comment section description design start
.social-profile-height-alignment {
  height: calc(100vh - 235px);
  overflow-y: auto;
  border-bottom: 1px solid #e4e4e8;
  transition: 0.4s ease-in-out;

  @media only screen and (max-width: '1024px') {
    height: auto;
  }

  .social-profile-notes-alignment {
    padding: 8px 24px 16px 24px;
    border-left: 1px solid #e2e2ea;

    p {
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: var(--secondary-900);
    }
  }
}

.img-slider-next {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 999;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  &:hover {
    background: var(--white);
  }
}

.img-slider-prev {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 999;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  &:hover {
    background: var(--white);
  }
}

.imageContainer {
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    border-radius: 8px;
    transform: scale(1);
    transition: 0.4s ease-in-out;
  }

  &:hover {
    img {
      transform: scale(1.2);
      transition: 0.4s ease-in-out;
    }
  }
}

.jewelry-club-slider-alignment {
  @media (max-width: 576px) {
    margin: 24px 0 24px -20px;
    width: calc(100% + 35px);
  }
}

.products-searched-flx-pagination-and-dropdown {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 20px;
  // flex-wrap: wrap;

  .filterPaginationAlignment-main {
    @media (max-width: 576px) {
      display: none;
    }
  }
}

.filterPaginationAlignment-main-mobile {
  display: none;

  @media (max-width: 576px) {
    display: block;
  }
}

.seller-social-feed-chat-details {
  height: 0;
  padding: 0 14px;
  box-sizing: border-box;
  overflow-y: auto;
  transition: 0.5s ease-in-out;
  border-bottom: none;

  &.seller-social-feed-chat-details-open {
    height: 290px;
    transition: 0.5s ease-in-out;
    padding: 16px 14px;
    border-bottom: 1px solid #e4e4e8;
  }

  .seller-social-feed-chat-box-main {
    margin: 0 0 16px 0;
    display: flex;
    justify-content: start;
    flex-direction: column;

    .seller-social-feed-chat-box-comment {
      background-color: rgb(248, 248, 248);
      border: 1px solid rgb(241, 241, 245);
      border-radius: 0 10px 10px 10px;
      padding: 20px;
      display: flex;
      width: fit-content;
      align-items: flex-start;
      gap: 16px;

      .seller-social-feed-chat-image {
        width: 40px;
        height: 40px;
        border-radius: 50%;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .seller-social-feed-chat-name {
        display: flex;
        align-items: center;
        gap: 8px;

        h6 {
          a {
            font-family: Poppins;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: var(--primary-500);
            cursor: pointer;
            padding: 8px 0 0;
          }
        }

        span {
          font-family: Poppins;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #808191;
        }
      }

      .chat-text-alignment {
        display: flex;
        align-items: center;
        gap: 6px;

        p {
          font-family: Poppins;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #495057;
        }
      }
    }

    .social-comment-below-section {
      justify-content: flex-start;
      display: flex;
      align-items: center;
      gap: 23px;

      .replies-likes-main {
        display: flex;
        align-items: center;
        gap: 12px;
        justify-content: flex-start;
        cursor: pointer;

        .replies-section-align {
          width: 32px;
          height: 32px;
          background: #fff;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .social-chat-profile-alignment-text {
    a {
      font-family: Poppins;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: var(--primary-500);
    }
  }
}

.Shipping-price-modal-toggle-switch {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  input[type='checkbox'] {
    display: none;
  }

  .Shipping-price-modal-switch-container {
    width: 44px;
    height: 24px;
    background-color: #e5e7eb;
    border-radius: 9999px;
    transition: background-color 0.3s ease;

    &.dark {
      background-color: #374151;
    }

    &:focus-within {
      outline: none;
      box-shadow: 0 0 0 4px rgba(99, 102, 241, 0.3);
    }
  }

  .Shipping-price-modal-toggle-circle {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #fff;
    transition: transform 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  }

  input[type='checkbox']:checked + .Shipping-price-modal-switch-container .Shipping-price-modal-toggle-circle {
    transform: translateX(20px);
    background-color: #fff;
  }

  .dark input[type='checkbox']:checked + .Shipping-price-modal-switch-container .Shipping-price-modal-toggle-circle {
    background-color: #ffffff;
    border-color: #ffffff;
    transform: translateX(20px);
  }

  .Shipping-price-modal-switch-container {
    background-color: #e5e7eb;
  }

  input[type='checkbox']:checked + .Shipping-price-modal-switch-container {
    background-color: #4f46e5;
  }

  .dark input[type='checkbox']:checked + .Shipping-price-modal-switch-container {
    background-color: #fff;
  }
}

// :global(.slick-arrow) {
//   @media (max-width:576px) {
//     display: none !important;
//   }
// }

// .slick-arrow {
//   @media (max-width:576px) {
//     display: none !important;
//   }
// }

.feed-dropdown-web {
  @media (max-width: 576px) {
    display: none;
  }
}

.feed-dropdown-mobile {
  display: none;

  @media (max-width: 576px) {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
  }

  img,
  svg {
    width: 22px;
    height: 22px;
    min-width: 22px;
    min-height: 22px;
    max-width: 22px;
    max-height: 22px;
  }
}

.mobileviewdetailsalignment {
  .dropdown-option {
    padding: 0 !important;
  }

  ul,
  li,
  span {
    display: block;
  }

  li {
    height: auto !important;
    line-height: inherit !important;
  }

  .dropdown-main {
    display: grid;
    grid-template-columns: 24px 1fr;
    gap: 11px;
    align-items: center;
    cursor: pointer;
    transition: 0.4s ease-in-out;
    padding: 0;

    .dropdown-icon {
      width: 100%;
      height: 24px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .dropdown-content {
      h5 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #495057;
        margin: 0 !important;
      }

      p {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #808191;
      }
    }
  }
}

.Description-html-layout {
  h2 {
    line-height: normal;
  }
}

.reportPostModalheader {
  h1 {
    @media (max-width: 768px) {
      font-family: 'Poppins' sans-serif;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #495057;
    }
  }
}
