.addSizeVariantModalAlignment {
  .modalWrapper {
    position: fixed;
    height: 100vh;
    width: 100%;
    background: rgba(30, 36, 50, 0.4);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;

    @media only screen and (max-width: '767px') {
      display: none;
    }

    .modalBoxAlignment {
      max-width: 654px;
      width: 654px;
      background: #ffffff;
      box-shadow: 0px 5px 50px 5px rgba(192, 191, 191, 0.15);
      border-radius: 10px;
      padding: 32px;

      .modalHeaderAlignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 14px;

        h3 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
          color: var(--secondary-900);
        }

        .headerButtonAlignment {
          display: flex;
          align-items: center;
          gap: 32px;

          .cancleButtonAlignment {
            button {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              color: var(--primary-500);
              background: transparent;
              border: none;
              cursor: pointer;
              padding: 0;
              margin: 0;
            }
          }

          .saveButtonAlignment {
            button {
              background: #bbc5f5;
              border-radius: 8px;
              padding: 6px 18px;
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              color: #f8f8f8;
              cursor: pointer;
              border: none;
            }
          }

          .active {
            button {
              background: var(--primary-500);
            }
          }
        }
      }

      .modalBodyAlignment {
        padding: 30px 0 0 0;
      }

      .formAlignment {
        text-align: left;

        label {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #495057;
        }

        .inputDetailsAlignment {
          padding: 16px 0 0 0;

          .inputAlignment {
            position: relative;
            margin: 0 0 4px 0;

            &:last-child {
              padding: 0;
            }

            .input-relative {
              position: relative;
            }

            &:hover {
              .deleteIconAlignment {
                display: block;
              }
            }

            input {
              background: #eceef4;
              border-radius: 10px;
              width: 100%;
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              padding: 10px 46px 10px 16px;
              border: none;

              // &:last-child {
              //   margin: 0;
              // }

              &::placeholder {
                color: #acb1c0;
              }
            }

            .dropdownAlignment {
              background: #ffffff;
              border: 1px solid #e4e4e8;
              box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.05);
              border-radius: 10px;
              padding: 12px 0;
              position: absolute;
              width: 100%;
              bottom: 54px;
              height: auto;
              max-height: 277px;
              overflow-y: auto;

              ul {
                list-style: none;
                list-style-position: outside;
                padding: 0;
                margin: 0;

                li {
                  padding: 4px 20px;
                  margin: 0;
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);

                  &:hover {
                    cursor: pointer;
                    background: #eceef4;
                    box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.05);
                  }

                  &.active {}
                }
              }
            }

            .deleteIconAlignment {
              position: absolute;
              top: 10px;
              right: 10px;
              display: none;

              img {
                width: 24px;
                height: 24px;
                display: block;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}