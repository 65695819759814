.createClubProfileSection {
    padding: 32px;
    height: calc(100vh - 287px);
    overflow-y: auto;

    @media only screen and (max-width:"1024px") {
        height: 100%;
    }

    @media only screen and (max-width:"767px") {
        padding: 16px 16px 82px 16px;
    }

    .createClubProfileDetails {
        .createClubProfileBox {
            border-radius: 14.375px 14.375px 0px 0px;
            border: 1.438px solid var(--secondary-100);
            padding: 6px 6px 26px 6px;
            border-bottom: none;

            .createClubProfileCoverBox {
                border-radius: 8.625px 8.625px 0px 0px;
                background: var(--light-grey);
                width: 100%;
                height: 108px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 8.625px 8.625px 0px 0px;
                    object-fit: cover;
                    display: block;
                }

                p {
                    color: var(--secondary-500);
                    font-family: 'Poppins', sans-serif;
                    font-size: 11px;
                    font-style: italic;
                    font-weight: 400;
                    line-height: normal;

                    @media only screen and (max-width:"767px") {
                        font-size: 10px;
                    }

                }
            }

            .createClubProfileOtherDetails {
                padding: 0 40px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                @media only screen and (max-width:"767px") {
                    padding: 0 24px;
                }

                .createClubProfileLeftSideAlignment {
                    display: flex;
                    align-items: center;
                    gap: 12px;

                    .createClubProfileImg {
                        width: 74px;
                        height: 52px;
                        border-radius: 3.389px;
                        border: 1.694px solid #FFF;
                        background: linear-gradient(0deg, var(--neutral-light-grey, #F8F8F8) 0%, var(--neutral-light-grey, #F8F8F8) 100%);
                        box-shadow: 0px 0.84718px 3.38872px 0px rgba(0, 0, 0, 0.10);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: -22px 0 0 0;

                        @media only screen and (max-width:"767px") {
                            margin: -10px 0 0 0;
                            width: 45px;
                            height: 32px;
                        }

                        p {
                            color: var(--secondary-500);
                            text-align: center;
                            font-family: 'Poppins', sans-serif;
                            font-size: 11px;
                            font-style: italic;
                            font-weight: 400;
                            line-height: normal;

                            @media only screen and (max-width:"767px") {
                                font-size: 10px;
                            }

                        }

                        img {
                            width: 100%;
                            height: 100%;
                            display: block;
                            object-fit: cover;
                            border-radius: 3.389px;
                        }
                    }

                    .createClubLeftBox {
                        width: 74px;
                        height: 18px;
                        border-radius: 5.75px;
                        background: var(--secondary-50);

                        @media only screen and (max-width:"767px") {
                            width: 46px;
                            height: 10px;
                        }


                    }
                }

                .createClubProfileRightSideAlignment {
                    display: flex;
                    align-items: center;
                    gap: 20px;

                    @media only screen and (max-width:"767px") {
                        gap: 8px;
                    }

                    .createClubSmallBox {
                        width: 30px;
                        height: 18px;
                        background: var(--secondary-50);
                        border-radius: 5.75px;

                        @media only screen and (max-width:"767px") {
                            width: 18px;
                            height: 10px;
                        }

                    }

                    .createClubBigBox {
                        width: 74px;
                        height: 18px;
                        background: var(--secondary-50);
                        border-radius: 5.75px;

                        @media only screen and (max-width:"767px") {
                            width: 46px;
                            height: 10px;
                        }
                    }
                }
            }
        }

        .createClubImgUplodedAlignment {
            padding: 32px 0 0 0;

            @media only screen and (max-width:"767px") {
                padding: 65px 0 0 0;
            }

            .createClubImgUplodedFlexAlignment {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 55px;

                @media only screen and (max-width:"767px") {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 9px;
                }

                .uplodedImgBoxAlignment {
                    border-radius: 10px;
                    background: var(--primary-50);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    width: 160px;
                    height: 56px;
                    cursor: pointer;
                    position: relative;

                    @media only screen and (max-width:"767px") {
                        width: 100%;
                    }



                    img {
                        margin: 0 auto;
                    }

                    p {
                        color: var(--primary-500);
                        font-family: 'Poppins', sans-serif;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }

                    input[type="file"] {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        text-indent: 999px;
                        cursor: pointer;
                    }
                }
            }

            .removeClubImgUplodedFlexAlignment {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 55px;

                @media only screen and (max-width:"767px") {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 9px;
                }

                .removeImgBoxAlignment {
                    border-radius: 10px;
                    background: var(--red-50);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    gap: 4px;
                    width: 167px;
                    height: 56px;
                    cursor: pointer;
                    position: relative;

                    @media only screen and (max-width:"767px") {
                        width: 100%;
                    }


                    img {
                        margin: 0 auto;
                    }

                    p {
                        color: var(--red-500);
                        font-family: 'Poppins', sans-serif;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }

                    input[type="file"] {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        text-indent: 999px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}