.storeModalSection {
  // height: 100%;
  // overflow-y: auto;
  // border-radius: 10px;
  height: calc(100vh - 96px);
  overflow-y: auto;
  border-radius: 16px;

  @media only screen and (max-width: '1024px') {
    height: auto;
    overflow-y: unset;
    padding: 0 0 68px 0;
  }

  .storeModalBoxAlignment {
    border-radius: 10px 10px 0 0;
    background: #fff;
    padding: 32px;

    @media only screen and (max-width: '1024px') {
      display: none;
    }

    .storeModalHeadingAlignment {
      h4 {
        color: var(--bookmark-heading-color);
        font-family: 'Poppins', sans-serif;
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
      }
    }
  }

  .storeModalMobileHeaderSticky {
    display: none;

    @media only screen and (max-width: '1024px') {
      display: block;
      position: sticky;
      top: 0;
      left: 0;
      z-index: 99999;
    }

    .storeModalMobileViewHeaderAlignment {
      padding: 8px 16px 12px 16px;
      text-align: center;
      background-color: #fff;
      margin: 0 0 8px 0;
      position: relative;

      h4 {
        color: var(--secondary-900);
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
      }

      .mobileViewBackArrowAlignment {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 16px;
        z-index: 99;
      }
    }
  }

  .storeModalHeightAlignment {
    @media only screen and (max-width: '1024px') {
      height: 100%;
      overflow-y: auto;
      padding: 0 0 64px 0;
    }
  }
}

.storeNewmodalUi {
  border-radius: 16px;
  border: 2px solid #eef1fd;
  // padding: 20px 18px;
  padding: 12px;
  height: calc(100vh - 95px);
  overflow: auto;
  .mobileShowArrow {
    display: none;

    @media (max-width: 576px) {
      display: block;
    }

    svg {
      cursor: pointer;
    }
  }

  .title {
    padding: 0 0 100px 0;

    @media (max-width: 576px) {
      padding: 0 0 30px 0;
    }

    p {
      color: #fff;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      font-style: normal;
      @media (max-width: 576px) {
        font-size: 18px;
        text-align: center;
      }
    }

    span {
      font-size: 13px;
      font-style: normal;
      max-width: 305px;
      color: #fff;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.408px;
      display: block;
      @media (max-width: 576px) {
        font-size: 12px;
        line-height: 20px;
        max-width: 252px;
        letter-spacing: 0px;
        margin: 0 auto;
        text-align: center;
      }
    }
  }

  @media (max-width: 576px) {
    border: none;
    border-radius: 0px;
    height: calc(100vh - 0px);
    padding: 0;
  }

  .slick-next svg {
    transform: rotate(0deg) !important;
  }
  .slick-prev svg {
    transform: rotate(180deg) !important;
  }
}
.storeNewmodalUiChange {
  border-radius: 8px;
  padding: 34px 31px;
  background: linear-gradient(179deg, rgba(214, 198, 249, 0.8) -3.92%, rgba(70, 95, 229, 0.8) 102.05%);

  @media (max-width: 576px) {
    border-radius: 0px;
    padding: 24px 16px;
  }

  .mobileHide {
    @media (max-width:576px) {
      display: none;
    }
  }

  .mobileShow {
    display: none;
    @media (max-width:576px) {
      display: block;
    }
  }
  
}