.mobile-view-product-category-section {
  display: none;

  @media only screen and (max-width: '767px') {
    display: block;
  }

  .mobile-view-product-category-modal-alignment {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: rgba(30, 36, 50, 0.6);
    top: 0;
    right: 0;
    z-index: 9999;

    .mobile-view-product-category-modal-alignment {
      padding: 114px;

      .mobile-view-product-category-card-alignment {
        background: #fff;
        padding: 2px;
        border: 1px solid #f3f3f3;
        border-radius: 10px;
        width: 164px;
        min-width: 164px;
        margin-bottom: 230px;

        .mobile-view-product-category-card-img-alignment {
          width: 100%;
          height: 221px;

          .share-me-feed-img-alignment {
            width: 100%;
            height: 100%;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 5.34609px;
            }

            video {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 5.34609px;
            }
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 8px 8px 0px 0px;
            object-fit: cover;
          }

          video {
            width: 100%;
            height: 100%;
            border-radius: 8px 8px 0px 0px;
            object-fit: cover;
          }
        }

        .mobile-view-product-category-details-allignment {
          padding: 8px 6px;

          h4 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: var(--secondary-900);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .mobile-price-alignment {
            padding: 4px 0 0 0;
            display: flex;
            align-items: center;
            gap: 5px;

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              color: var(--secondary-900);
            }

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              text-decoration-line: line-through;
              color: #acb1c0;
            }
          }
        }
      }

      .mobile-view-bottom-box-alignment {
        width: 100%;
        position: fixed;
        z-index: 9999999;
        left: 50%;
        bottom: 0;
        transition: 0.3s ease-in-out;
        margin: 0 auto;
        border-radius: 10px 10px 0 0;
        background-color: #fff;
        padding: 20px 0;

        &.bottom-box-hidden {
          transform: translate(-50%, 100%);
          transition: 0.3s ease-in-out;
        }

        &.bottom-box-show {
          transform: translate(-50%, 0%);
          transition: 0.3s ease-in-out;
        }
      }
    }
  }
}

.mobile-view-click-drop-alignment {
  border-bottom: 1px solid #eaecef;
  padding: 8px 0 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    width: 33px;
    height: 3px;
    background: #e4e4e8;
    border-radius: 1.5px;
  }
}

.mobile-view-details-alignment {
  height: 290px;
  padding: 32px;

  &.product-details-mobile-view-option {
    padding: 0;
    height: auto;
  }

  .product-details-mobile-view-main {
    padding: 24px;

    @media only screen and (max-width:"1024px") {
      padding: 32px 24px;
    }
  }

  .product-details-share-modal-heading {
    padding: 16px 16px 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width:"1024px") {
      display: none;
    }

    h4 {
      color: var(--secondary-900);
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }

    svg {
      cursor: pointer;
    }
  }


  .mobile-view-sharing-option-alignment {
    padding: 24px 24px 0 24px;

    @media only screen and (max-width:"1024px") {
      padding: 16px 0 0 24px;
    }

    .mobile-view-sharing-option-flex {

      width: 100%;
      overflow-x: auto;
      display: flex;
      align-items: center;
      gap: 16px;

      .mobile-view-sharing-box {
        width: 64px;
        height: 64px;
        border-radius: 16px;
        min-width: 64px;
        max-width: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
          background: var(--primary-50);

        }
      }
    }
  }


  .mobile-view-details-grid-alignment {
    display: grid;
    grid-template-columns: 24px 1fr;
    gap: 11px;
    align-items: center;
    padding: 0 0 24px 0;
    cursor: pointer;
    transition: 0.4s ease-in-out;

    @media only screen and (max-width:"1024px") {
      padding: 0 0 32px 0;
    }

    &:last-child {
      padding: 0;
    }

    .mobile-view-details-icon-alignment {
      width: 100%;
      height: 24px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .mobile-view-child-details-alignment {
      h5 {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #495057;
      }

      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #808191;
      }

      &.delete-alignment {
        h5 {
          color: var(--red-500);
        }

        p {
          color: var(--red-500);
        }
      }
    }

    &:hover {
      transition: 0.4s ease-in-out;

      .mobile-view-details-icon-alignment {
        svg {
          path {
            stroke: #556EE6;
          }
        }
      }

      .mobile-view-child-details-alignment {
        h5 {
          color: #2844ac;
        }

        p {
          color: #556EE6;
        }
      }
    }
  }
}

// .mobile-view-product-category-modal-alignment {
//   position: fixed;
//   width: 100%;
//   height: 100vh;
//   background: rgba(30, 36, 50, 0.6);
//   top: 0;
//   right: 0;
//   z-index: 9999;

//   .mobile-view-product-category-modal-alignment {
//     padding: 114px;

.mobile-view-product-category-card-alignment {
  background: #fff;
  padding: 2px;
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  width: 164px;
  min-width: 164px;
  margin-bottom: 230px;

  .mobile-view-product-category-card-img-alignment {
    width: 100%;
    height: 221px;

    .share-me-feed-img-alignment {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5.34609px;
      }

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5.34609px;
      }
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 8px 8px 0px 0px;
      object-fit: cover;
    }

    video {
      width: 100%;
      height: 100%;
      border-radius: 8px 8px 0px 0px;
      object-fit: cover;
    }
  }

  .mobile-view-product-category-details-allignment {
    padding: 8px 6px;

    h4 {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: var(--secondary-900);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .mobile-price-alignment {
      padding: 4px 0 0 0;
      display: flex;
      align-items: center;
      gap: 5px;

      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: var(--secondary-900);
      }

      span {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-decoration-line: line-through;
        color: #acb1c0;
      }
    }
  }
}

.mobile-view-bottom-box-alignment {
  width: 100%;
  position: fixed;
  z-index: 9999999;
  left: 50%;
  bottom: 0;
  transition: 0.3s ease-in-out;
  margin: 0 auto;
  border-radius: 10px 10px 0 0;
  background-color: #fff;
  padding: 20px 0;

  &.bottom-box-hidden {
    transform: translate(-50%, 100%);
    transition: 0.3s ease-in-out;
  }

  &.bottom-box-show {
    transform: translate(-50%, 0%);
    transition: 0.3s ease-in-out;
  }
}

//   }
// }