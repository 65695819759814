.mobileViewSeeAllBookMarkDetailsSection {
    background: var(--white);

    .mobileViewHeaderStickyAlignment {
        position: sticky;
        top: 0;
        left: 0;
        background: var(--white);
        z-index: 9999;
        .mobileViewSeeAllBookMarkHeader {
            padding: 8px 16px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            h4 {
                color: var(--secondary-900);
                text-align: center;
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
            }

            .backArrowAlignment {
                position: absolute;
                top: 8px;
                left: 16px;
                cursor: pointer;
            }

            .addNewAlignment {
                position: absolute;
                top: 8px;
                right: 16px;
                cursor: pointer;
                padding: 6px 16px;

                a {
                    color: var(--primary-500);
                    text-align: center;
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 20px;
                }
            }
        }
    }

    .mobileViewAllCollectionBodyDetailsAlignment {
        padding: 32px 35px;

        .mobileViewbookMarkCollectionCardProductGrid {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 80px;

            @media only screen and (max-width: '767px') {
                grid-template-columns: repeat(2, 1fr);
                gap: 32px 60px;

            }



        }

        .bookMarkModalCollectionCardPadding {
            padding: 32px 20px 0 20px;

            &.bookMarkModalCollectionPaddingZero {
                padding: 0;
            }


        }

    }
}