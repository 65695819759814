.bookMarkProductAlignment {
    // padding: 48px 0 0 0;

    @media only screen and (max-width:"1024px") {
        padding: 0 16px;
    }

    .bookMarkModalProductHeading {
        .bookMarkModalProductNameAlignment {
            display: flex;
            align-items: center;
            gap: 10px;

            h6 {
                color: var(--bookmark-heading-color);
                font-family: 'Poppins', sans-serif;
                font-size: 20px;
                font-weight: 600;
                line-height: 30px;
            }


            .bookmarkProductNumber {
                padding: 0 8px;
                border-radius: 10px;
                background: var(--secondary-50);

                span {
                    font-family: 'Poppins', sans-serif;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 20px;
                    color: var(--secondary-500);

                    @media only screen and (max-width:"767px") {
                        display: none;
                    }
                }
            }

            .bookmarkEditAlignment {
                cursor: pointer;

                @media only screen and (max-width:"767px") {
                    display: none;
                }
            }
        }

    }

    .bookMarkProductEmptyCartDetails {
        // height: 450px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 25px;
        border-radius: 16px;
        // background: #F8F8F8;
        // margin: 16px 0 0 0;
        padding: 56px 0 0 0;

        @media only screen and (max-width:"767px") {
            height: 265px;
            margin: 16px 0;
            border-radius: 16px;
            background: var(--primary-50);
            gap: 18px;

            svg {
                width: 90px;
                height: 56px;
            }
        }

        p {
            color: var(--violet-300);
            font-size: 16px;
            font-style: italic;
            font-weight: 400;
            line-height: 21px;

            @media only screen and (max-width:"767px") {
                font-size: 12px;
                line-height: 18px;
                text-align: center;
                max-width: 118px;
            }
        }
    }

    .bookMarkAllProductDetailsAlignment {
        padding: 24px 0 0 0;

        @media only screen and (max-width:"767px") {
            padding: 8px 0 0 0;
        }
    }
}