.SellerSocialActivityEventUpcomingLiveStremSection {
  padding: 0 0px 16px 0;

  @media only screen and (max-width: '1024px') {
    padding: 13px 16px 16px 16px;
  }

  .SellerSocialActivityEventUpcomingLiveStremBox {
    border-radius: 16px;
    background: rgba(85, 110, 230, 0.13);
    padding: 32px;

    @media only screen and (max-width: '1024px') {
      padding: 16px 16px;
      border-radius: 0px;
    }

    .SellerSocialActivityEventUpcomingLiveStremHeadingAlignment {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 0 16px;

      @media only screen and (max-width: '767px') {
        padding: 0 8px;
      }

      h4 {
        color: #000;
        font-size: 20px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        line-height: 30px;

        @media only screen and (max-width: '767px') {
          font-size: 18px;
          line-height: 21px;
        }
      }

      .SellerSocialActivityEventUpcomingFilterAlignment {
        display: flex;
        align-items: center;
        gap: 36px;

        .SellerSocialActivityEventUpcomingCalenderBoxAlignment {
          // border-radius: 10px;
          // background: var(--primary-50);
          // padding: 8px 8px 8px 16px;
          // width: 178px;
          // height: 40px;
          // display: flex;
          // align-items: center;
          // justify-content: space-between;

          // .SellerSocialActivityEventUpcoming-calender-flex {
          //     display: flex;
          //     align-items: center;
          //     gap: 8px;

          //     p {
          //         color: var(--secondary-900);
          //         font-size: 16px;
          //         line-height: 24px;
          //         font-family: 'Poppins', sans-serif;
          //         font-weight: 500;
          //     }
          // }

          display: block;

          @media only screen and (max-width: '767px') {
            display: none;
          }
        }

        .SellerSocialActivityEventUpcomingSeeAllAlignment {
          a {
            display: flex;
            align-items: center;
            gap: 6px;

            color: var(--primary-500);
            text-align: center;
            font-size: 18px;
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            line-height: 28px;

            @media only screen and (max-width: '767px') {
              font-size: 14px;
              line-height: 21px;
            }
          }
        }
      }
    }

    .SellerSocialActivityEventUpcomingLiveStremDetailsAlignment {
      padding: 16px 0 0 0;

      .slick-arrow {
        display: none !important;
      }




    }
  }

  .SellerSocialActivityEventUpcomingNoLiveStreamAlignment {
    width: 100%;
    height: 430px;
    gap: 16px;
    border-radius: 16px;
    background: rgba(85, 110, 230, 0.13);
    padding: 32px;

    @media only screen and (max-width: '1024px') {
      padding: 0;
      background: unset;
    }

    .SellerSocialActivityEventUpcomingHeading {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 0 0 26px 0;

      @media only screen and (max-width: '1024px') {
        padding: 0 0 13px 0;
      }

      h4 {
        color: var(--black);
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;
      }

      .dotAlignment {
        p {
          width: 3px;
          height: 3px;
          background: var(--secondary-500);
          border-radius: 50%;
        }
      }

      span {
        color: var(--secondary-500);
        font-size: 16px;
        font-weight: 500;
        line-height: 12px;
        margin-bottom: 6px;
        display: block;
      }
    }

    .SellerSocialActivityEventUpcomingDataBoxAlignment {
      height: 310px;
      border-radius: 16px;
      background: var(--primary-50);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 16px;

      p {
        color: var(--violet-300);
        font-size: 12px;
        font-style: italic;
        font-weight: 400;
        line-height: 18px;
      }
    }


  }

  :global(.slick-arrow) {
    background: #d5cfcf !important;
    border: 1px solid #d5cfcf !important;
  }

  :global(.slick-arrow:hover) {
    background: #ab67fc !important;
  }
}

.SellerSocialActivityEventUpcomingLiveStremDetailsMainAlignment {
  padding: 0 8px;

  @media only screen and (max-width: '767px') {
    padding: 0 4px;
  }

  .SellerSocialActivityEventUpcomingLiveStremDetailsBoxAlignment {
    width: 100%;
    height: 298px;
    position: relative;

    @media only screen and (max-width: '767px') {
      height: 250px;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: 0px 0px 10px 10px;
      background: linear-gradient(0deg, #1e2432 0%, rgba(30, 36, 50, 0) 100%);
      height: 208px;
      width: 100%;
      z-index: 999;
    }

    .SellerSocialActivityEventUpcomingDateNotificationAlignment {
      position: absolute;
      top: 16px;
      right: 16px;
      left: 16px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .dateBoxAlignment {
        border-radius: 10px;
        background: #fff;
        padding: 4px;

        span {
          color: #495057;
          text-align: center;
          font-size: 13px;
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          line-height: 20px;
          display: block;
        }

        div {
          width: 40px;
          height: 40px;
          border-radius: 8px;
          background: var(--secondary-50);
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            color: var(--secondary-900);
            text-align: center;
            font-size: 24px;
            font-family: 'Poppins', sans-serif;
            line-height: 36px;
            font-weight: 600;
          }
        }
      }

      .SellerSocialActivityEventUpcomingNotificationBox {
        padding: 7px;
        border-radius: 8px;
        background: rgba(30, 36, 50, 0.4);
        cursor: pointer;
      }
    }

    .SellerSocialActivityEventUpcomingBottomDetailsAlignment {
      bottom: 16px;
      right: 16px;
      left: 16px;
      position: absolute;
      z-index: 999;

      h6 {
        max-width: 167px;
        color: #f8f8f8;
        font-size: 20px;
        line-height: 30px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;

        @media only screen and (max-width: '767px') {
          font-size: 16px;
          line-height: 22px;
        }
      }

      .SellerSocialActivityEventUpcomingTimeDateAlignment {
        padding: 8px 0 0 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          color: #e4e4e8;
          font-size: 13px;
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          line-height: 19px;

          @media only screen and (max-width: '767px') {
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }
  }
}