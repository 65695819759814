.art-carf-now-trending-section {
  padding: 72px 0 0 0;

  @media only screen and (max-width:"768px") {
    padding: 18px 0 0 20px;

  }

  .art-carf-now-trending-grid-alignment {
    display: grid;
    grid-template-columns: 448px 1fr;
    gap: 16px;

    @media only screen and (max-width:"768px") {
      display: none;
    }

    @media only screen and (max-width:"1099px") {
      grid-template-columns: 300px 1fr;
    }

    @media only screen and (max-width:"768px") {
      grid-template-columns: 289px 1fr;
    }

    .art-carf-now-trending-gridItem-alignment {
      .art-carf-now-trending-leftside-alignment {
        background: linear-gradient(139.9deg, #556ee6 1.69%, #6b21a8 100%);
        border-radius: 16px;
        height: 496px;
        width: 100%;
        padding: 58px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media only screen and (max-width:"1099px") {
          padding: 30px;
        }

        .art-carf-box-details-alignment {
          h6 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 32px;
            line-height: 48px;
            color: #ffffff;

            @media only screen and (max-width:"768px") {
              font-size: 24px;
              line-height: 36px;
            }
          }

          p {
            padding: 10px 0 0 0;
            max-width: 252px;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
          }

          .art-see-all-alignment {
            padding: 32px 0 0 0;
            display: none;

            @media only screen and (max-width:"768px") {
              display: block;
            }

            a {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              text-decoration-line: underline;
              color: #FFFFFF;
              cursor: pointer;
            }
          }
        }
      }

      .art-carf-now-trending-rightside-alignment {
        .art-carf-now-trending-rightside-grid-alignment {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 16px;
          height: 496px;

          .two-row-img-alignment {
            .child-first-img-alignment {
              width: 100%;
              height: 221px;
              margin: 0 0 16px 0;
              position: relative;
              overflow: hidden;
              border-radius: 16px;
              transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

              @media only screen and (max-width:"768px") {
                max-width: 164px;
                width: 164px;
                min-width: 164px;

              }

              &:hover {
                img {
                  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                  transform: scale(1.02);
                  object-fit: cover;
                }

                .hover-details-alignment {
                  display: block;
                }
              }

              img {
                width: 100%;
                height: 100%;
                display: block;
                border-radius: 16px;
                object-fit: cover;
              }
            }

            .child-second-img-alignment {
              width: 100%;
              height: 259px;
              position: relative;
              overflow: hidden;
              border-radius: 16px;
              transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);


              @media only screen and (max-width:"768px") {
                max-width: 164px;
                width: 164px;
                min-width: 164px;
              }

              &:hover {
                img {
                  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                  transform: scale(1.02);
                  object-fit: cover;
                }

                .hover-details-alignment {
                  display: block;
                }
              }

              img {
                width: 100%;
                height: 100%;
                display: block;
                border-radius: 16px;
                object-fit: cover;
              }
            }

            .child-second-more-details-img-alignment {
              width: 100%;
              height: 259px;
              position: relative;
              overflow: hidden;
              border-radius: 16px;

              @media only screen and (max-width:"768px") {
                max-width: 164px;
                width: 164px;
                min-width: 164px;
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }

              transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);


              &:hover {
                img {
                  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                  transform: scale(1.02);
                  object-fit: cover;
                }

                .seemore-box-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  a {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    justify-content: center;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: #ffffff;
                    cursor: pointer;

                    img {
                      width: auto;
                      object-fit: cover;
                    }
                  }
                }
              }

              .seemore-box-alignment {
                position: absolute;
                background: rgba(30, 36, 50, 0.8);
                border-radius: 16px;
                width: 100%;
                height: 100%;
                top: 0;
                right: 0;
                display: none;
                transition: 0.4s ease-in-out;
              }
            }
          }

          .one-row-img-alignment {
            height: 496px;
            width: 100%;
            position: relative;
            transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

            overflow: hidden;
            border-radius: 16px;

            @media only screen and (max-width:"768px") {
              max-width: 164px;
              width: 164px;
              min-width: 164px;
            }

            &:hover {
              transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);


              img {
                transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                transform: scale(1.02);
                border-radius: 16px;
                object-fit: cover;
              }

              .hover-details-alignment {
                display: block;
                border-radius: 16px;
              }
            }

            img {
              width: 100%;
              height: 100%;
              border-radius: 16px;
              object-fit: cover;
            }
          }

          .hover-details-alignment {
            display: none;
            position: absolute;
            background: linear-gradient(180deg, rgba(30, 36, 50, 0) 0%, #1e2432 100%);
            border-radius: 16px;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;

            .bottom-img-texta-alignment {
              position: absolute;
              bottom: 16px;
              right: 16px;
              left: 16px;
              cursor: pointer;

              h5 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                color: #f8f8f8;
                max-width: 203px;
              }

              .bottom-price-alignment {
                padding: 4px 0 0 0;
                display: flex;
                align-items: center;
                gap: 8px;

                .price-offer-details-alignment {
                  display: flex;
                  align-items: center;
                  gap: 2px;

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 27px;
                    color: #f8f8f8;
                  }

                  del {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    color: #ffffff;
                    opacity: 0.6;
                  }
                }

                .price-offer-alignment {
                  padding: 2px 6px;
                  background: #f1efb0;
                  border-radius: 11px;

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    color: #474400;
                  }
                }
              }

              .bottom-rate-alignment {
                display: flex;
                align-items: center;
                gap: 8px;
                padding: 8px 0 0 0;

                .bottom-rate-star-alignment {
                  display: flex;
                  align-items: center;
                  gap: 6px;

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #f8f8f8;
                  }

                  .rate-star-alignment {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                  }
                }

                .rate-number-alignment {
                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #f8f8f8;
                  }
                }
              }
            }

            .top-img-details-alignment {
              position: absolute;
              top: 16px;
              right: 16px;

              .other-option-alignment-details {
                position: absolute;
                top: 16px;
                right: 16px;
                display: flex;
                align-items: center;
                gap: 8px;

                @media only screen and (max-width: '768px') {
                  display: none;
                }

                .hover-option-box-alignment {
                  background: rgba(30, 36, 50, 0.6);
                  border-radius: 8px;
                  padding: 4px;
                  width: 32px;
                  height: 32px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;

                  img {
                    height: auto;
                    object-fit: cover;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // .mobile-view-art-craft-now-trending-gridItem-alignment {
  .mobile-view-art-craft-now-trending-gridItem-alignment {
    padding: 0 4px;
    display: none;

    @media only screen and (max-width:"768px") {
      display: block;
    }

    .art-carf-now-trending-leftside-alignment {
      background: linear-gradient(139.9deg, #556ee6 1.69%, #6b21a8 100%);
      border-radius: 16px;
      height: 496px;
      width: 289px;
      padding: 58px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media only screen and (max-width:"1099px") {
        padding: 30px;
      }

      .art-carf-box-details-alignment {
        h6 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 32px;
          line-height: 48px;
          color: #ffffff;

          @media only screen and (max-width:"768px") {
            font-size: 24px;
            line-height: 36px;
          }
        }

        p {
          padding: 10px 0 0 0;
          max-width: 252px;
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
        }

        .art-see-all-alignment {
          padding: 32px 0 0 0;
          display: none;

          @media only screen and (max-width:"768px") {
            display: block;
          }

          a {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            text-decoration-line: underline;
            color: #FFFFFF;
            cursor: pointer;
          }
        }
      }
    }

    .two-row-img-alignment {
      .child-first-img-alignment {
        width: 100%;
        height: 221px;
        margin: 0 0 16px 0;
        position: relative;
        overflow: hidden;
        border-radius: 16px;
        transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

        @media only screen and (max-width:"768px") {
          max-width: 164px;
          width: 164px;
          min-width: 164px;

        }

        &:hover {
          img {
            transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
            transform: scale(1.02);
            object-fit: cover;
          }

          .hover-details-alignment {
            display: block;
          }
        }

        img {
          width: 100%;
          height: 100%;
          display: block;
          border-radius: 16px;
          object-fit: cover;
        }
      }

      .child-second-img-alignment {
        width: 100%;
        height: 259px;
        position: relative;
        overflow: hidden;
        border-radius: 16px;
        transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);


        @media only screen and (max-width:"768px") {
          max-width: 164px;
          width: 164px;
          min-width: 164px;
        }

        &:hover {
          img {
            transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
            transform: scale(1.02);
            object-fit: cover;
          }

          .hover-details-alignment {
            display: block;
          }
        }

        img {
          width: 100%;
          height: 100%;
          display: block;
          border-radius: 16px;
          object-fit: cover;
        }
      }

      .child-second-more-details-img-alignment {
        width: 100%;
        height: 259px;
        position: relative;
        overflow: hidden;
        border-radius: 16px;

        @media only screen and (max-width:"768px") {
          max-width: 164px;
          width: 164px;
          min-width: 164px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);


        &:hover {
          img {
            transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
            transform: scale(1.02);
            object-fit: cover;
          }

          .seemore-box-alignment {
            display: flex;
            align-items: center;
            justify-content: center;

            a {
              display: flex;
              align-items: center;
              gap: 8px;
              justify-content: center;
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 18px;
              line-height: 27px;
              color: #ffffff;
              cursor: pointer;

              img {
                width: auto;
                object-fit: cover;
              }
            }
          }
        }

        .seemore-box-alignment {
          position: absolute;
          background: rgba(30, 36, 50, 0.8);
          border-radius: 16px;
          width: 100%;
          height: 100%;
          top: 0;
          right: 0;
          display: none;
          transition: 0.4s ease-in-out;
        }
      }
    }

    .one-row-img-alignment {
      height: 496px;
      width: 100%;
      position: relative;
      transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

      overflow: hidden;
      border-radius: 16px;

      @media only screen and (max-width:"768px") {
        max-width: 164px;
        width: 164px;
        min-width: 164px;
      }

      &:hover {
        transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);


        img {
          transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
          transform: scale(1.02);
          border-radius: 16px;
          object-fit: cover;
        }

        .hover-details-alignment {
          display: block;
          border-radius: 16px;
        }
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        object-fit: cover;
      }
    }

    .hover-details-alignment {
      display: none;
      position: absolute;
      background: linear-gradient(180deg, rgba(30, 36, 50, 0) 0%, #1e2432 100%);
      border-radius: 16px;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;

      .bottom-img-texta-alignment {
        position: absolute;
        bottom: 16px;
        right: 16px;
        left: 16px;
        cursor: pointer;

        h5 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: #f8f8f8;
          max-width: 203px;
        }

        .bottom-price-alignment {
          padding: 4px 0 0 0;
          display: flex;
          align-items: center;
          gap: 8px;

          .price-offer-details-alignment {
            display: flex;
            align-items: center;
            gap: 2px;

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 18px;
              line-height: 27px;
              color: #f8f8f8;
            }

            del {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              color: #ffffff;
              opacity: 0.6;
            }
          }

          .price-offer-alignment {
            padding: 2px 6px;
            background: #f1efb0;
            border-radius: 11px;

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              color: #474400;
            }
          }
        }

        .bottom-rate-alignment {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 8px 0 0 0;

          .bottom-rate-star-alignment {
            display: flex;
            align-items: center;
            gap: 6px;

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #f8f8f8;
            }

            .rate-star-alignment {
              display: flex;
              align-items: center;
              gap: 4px;
            }
          }

          .rate-number-alignment {
            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #f8f8f8;
            }
          }
        }
      }

      .top-img-details-alignment {
        position: absolute;
        top: 16px;
        right: 16px;

        .other-option-alignment-details {
          position: absolute;
          top: 16px;
          right: 16px;
          display: flex;
          align-items: center;
          gap: 8px;

          @media only screen and (max-width: '768px') {
            display: none;
          }

          .hover-option-box-alignment {
            background: rgba(30, 36, 50, 0.6);
            border-radius: 8px;
            padding: 4px;
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            img {
              height: auto;
              object-fit: cover;
            }
          }
        }
      }
    }
  }

  // }
}