.risingStoresModalSection {
  border-radius: 10px;
  background: #fff;
  height: calc(100vh - 98px);
  overflow-y: auto;
  padding: 0 32px 32px 32px;

  @media only screen and (max-width: '767px') {
    padding: 0 0 32px 0;
    height: calc(100vh - 51px);
  }

  .risingStoresModalHeader {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 99;
    background: #fff;
    padding: 32px 0;

    @media only screen and (max-width: '767px') {
      padding: 16px;
    }

    h4 {
      color: var(--bookmark-heading-color);
      font-family: 'Poppins', sans-serif;
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      cursor: context-menu;

      @media only screen and (max-width: '767px') {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }

  .risingStoresModalDetailsAlignment {
    .risingStoresModalGridAlignment {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
      @media only screen and (max-width: '1024px') {
        grid-template-columns: repeat(2, 1fr);
      }
      @media only screen and (max-width: '767px') {
        grid-template-columns: 1fr;
        gap: 8px;
        padding: 0 16px;
      }

      .risingStoresModalGridItemAlignment {
        .shopRisingStoresDetailsBoxAlignment {
          border-radius: 8px;
          background: #f8f8f8;
          padding: 16px;

          .shopRisingStoreProductDetailsAlignment {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .shopRisingStoreProductAllDetails {
              display: flex;
              align-items: center;
              gap: 16px;

              @media only screen and (max-width: '767px') {
                gap: 8px;
              }

              .shopRisingStoreProductAllImg {
                width: 102px;
                height: 102px;

                @media only screen and (max-width: '767px') {
                  width: 84px;
                  height: 84px;
                }

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 8px;
                }
              }

              .shopRisingStoreProductOtherDetails {
                h5 {
                  color: var(--secondary-900);
                  font-family: 'Poppins', sans-serif;
                  font-size: 20px;
                  font-weight: 500;
                  line-height: 30px;

                  @media only screen and (max-width: '767px') {
                    font-size: 16px;
                    line-height: 20px;
                  }
                }

                p {
                  padding: 2px 0 0 0;
                  color: var(--secondary-500);
                  font-family: 'Poppins', sans-serif;
                  font-size: 13px;
                  font-weight: 400;
                  line-height: 20px;

                  @media only screen and (max-width: '767px') {
                    font-size: 12px;
                    line-height: 20px;
                  }
                }

                .followStoreButton {
                  padding: 10px 0 0 0;

                  @media only screen and (max-width: '767px') {
                    padding: 6px 0 0 0;
                  }

                  button {
                    padding: 6px 26px;
                    border-radius: 8px;
                    background: var(--primary-50);
                    color: var(--primary-500);
                    text-align: center;
                    font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 21px;
                    cursor: pointer;

                    @media only screen and (max-width: '767px') {
                      padding: 6px 16px;
                    }
                  }
                }
              }
            }

            .shopRisingLikeShareButton {
              display: flex;
              align-items: center;
              flex-direction: column;
              gap: 16px;

              .shopRisingLikeShareIconTotal {
                cursor: pointer;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                p {
                  color: var(--secondary-500);
                  font-family: 'Poppins', sans-serif;
                  font-size: 13px;
                  font-weight: 500;
                  line-height: 20px;
                  padding: 4px 0 0 0;

                  @media only screen and (max-width: '767px') {
                    display: none;
                  }
                }
              }
            }
          }

          .shopRisingChildImagesAlignment {
            padding: 16px 0 0 0;

            .shopRisingChildGrid {
              display: grid;
              grid-template-columns: repeat(5, 1fr);
              gap: 8px;

              @media only screen and (max-width: '767px') {
                gap: 4px;
              }

              .shopRisingChildImg {
                width: 100%;
                height: 100%;
                position: relative;

                @media only screen and (max-width: '767px') {
                  height: 60px;
                }

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 4px;
                  object-fit: cover;
                }

                &.lastImgAlignment {
                  &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 4px;
                    background: linear-gradient(0deg, rgba(30, 36, 50, 0.6) 0%, rgba(30, 36, 50, 0.6) 100%);
                  }

                  .shopRisingMoreImg {
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 9;
                    left: 0;
                    bottom: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    p {
                      color: #fff;
                      font-family: 'Poppins', sans-serif;
                      font-size: 13px;
                      font-weight: 400;
                      line-height: 20px;
                      text-align: center;

                      span {
                        font-size: 16px;
                        line-height: 24px;
                        display: block;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
