.user-profile-dropdown-menu {

  // z-index: 1 !important;
  .ant-dropdown-menu {
    background: #ffffff;
    border-radius: 10px;
    width: 250px;
    overflow: hidden;
    top: 15px;
    right: 163px;

    @media (max-width: 1440px) and (min-width: 1200px) {
      right: -8px;
    }

    @media (max-width: 1680px) and (min-width: 1200px) {
      right: -15px;
    }

    .your-account {
      padding: 32px 16px 16px 16px;

      .account-profile-alignment {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 0 16px;

        .account-profile-img {
          width: 24px;
          height: 24px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        h6 {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: var(--secondary-900);
          cursor: pointer;
        }
      }

      .child-heading-alignment {
        padding: 32px 16px 0 16px;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: var(--secondary-500);
        }
      }

      .your-account-links {
        padding: 4px 0px;
        border: 0;
        margin: 12px 0 0 0;

        li {
          padding: 0;
          // margin: 0;
          display: block;
          align-items: center;

          &:active {
            background-color: transparent;
          }

          a {
            font-size: 14px;
            line-height: 21px;
            font-weight: 500;
            color: var(--bookmark-heading-color);
            display: flex;
            padding: 4px 16px;
            margin: 0 0 8px 0;

            &:active {
              color: #6b21a8;
            }

            &:focus {
              color: #6b21a8;
            }

            &:hover {
              background: var(--primary-50);
              border-radius: 7.27273px;
            }

            .link-icon {
              width: 24px;
              margin-right: 8px;

              &:hover {
                background: var(--primary-50);
                border-radius: 7.27273px;
              }
            }

            .acoount-summary {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: var(--secondary-900);
            }
          }
        }
      }
    }

    .chat-settings {
      padding: 0 16px 22px 16px;

      h6 {
        font-size: 10px;
        line-height: 15px;
        color: var(--bookmark-heading-color);
        font-weight: 600;
        text-transform: uppercase;
        padding: 4px 15px;
        border-top: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
        margin-bottom: 0;
      }

      .chat-settings-links {
        padding: 16px 0px;
        border: 0;

        li {
          padding: 10px 15px 0 15px;
          padding-left: 70px;
          margin: 0;
          font-size: 14px;
          line-height: 21px;
          font-weight: 500;
          position: relative;
          color: var(--bookmark-heading-color);

          &:hover {
            color: #6b21a8;
          }

          &:active {
            color: #6b21a8;
            background-color: transparent;
          }

          &:focus {
            color: #6b21a8;
          }

          &:before {
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
            background-color: #000;
            left: 40px;
            top: 15px;
            border-radius: 10px;
          }

          &.user-online {
            &:before {
              background-color: #32c352;
            }
          }

          &.user-away {
            &:before {
              background-color: #ffcc4d;
            }
          }

          &.user-busy {
            &:before {
              background-color: #ec3920;
            }
          }

          &.user-invisible {
            &:before {
              background-color: #d8d8d8;
            }
          }
        }
      }

      .status-dropdown-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4px 24px;
        margin: 12px 0 0 0;
        transition: 0.4s ease-in-out;
        cursor: pointer;
        position: relative;

        &:hover {
          background: var(--primary-50);
          border-radius: 7.27273px;
          transition: 0.4s ease-in-out;
        }

        .status-dropdown-status-alignment {
          display: flex;
          align-items: center;
          gap: 16px;

          .online {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #1d8603;
          }

          .away {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #ffd02c;
          }

          .busy {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #e71616;
          }

          .invisibles {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #636773;
          }

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: var(--secondary-900);
          }
        }

        .down-arrow-alignment {}

        .status-option-dropdown {
          background: #ffffff;
          box-shadow: 0px 2px 8px rgba(30, 36, 50, 0.1);
          border-radius: 8px;
          // height: 176px;
          height: 88px;
          overflow-y: auto;
          width: 218px;
          position: absolute;
          // margin-top: 8px;
          top: 32px;
          left: 0;
          right: 0;
          z-index: 999;
          padding: 16px 24px;

          .status-dropdown-option-name {
            display: flex;
            align-items: center;
            gap: 16px;
            margin: 0 0 16px 0;
            transition: 0.2s ease-in-out;

            &:hover {
              transition: 0.2s ease-in-out;

              &.online-status {
                p {
                  background: #1d8603;
                }
              }

              &.away-status {
                p {
                  background: #ffd02c;
                }
              }

              &.busy-status {
                p {
                  background: #e71616;
                }
              }

              &.invisibal-status {
                p {
                  background: #636773;
                }
              }

              span {
                color: var(--secondary-900);
              }
            }

            &.active-option {
              &.online-status {
                p {
                  background: #1d8603;
                }
              }

              &.away-status {
                p {
                  background: #ffd02c;
                }
              }

              &.busy-status {
                p {
                  background: #e71616;
                }
              }

              &.invisibal-status {
                p {
                  background: #636773;
                }
              }

              span {
                color: var(--secondary-900);
              }
            }

            &:last-child {
              margin: 0;
            }

            p {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: linear-gradient(0deg, var(--secondary-100), var(--secondary-100)), #ffd02c;
            }

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: var(--secondary-500);
            }
          }
        }
      }
    }

    .about-bluejestic {
      padding: 0 16px;

      // h6 {
      //   font-size: 10px;
      //   line-height: 15px;
      //   color: var(--bookmark-heading-color);
      //   font-weight: 600;
      //   text-transform: uppercase;
      //   padding: 4px 15px;
      //   border-top: 1px solid #e8e8e8;
      //   border-bottom: 1px solid #e8e8e8;
      //   margin-bottom: 0;
      // }
      .about-bluejestic-child-heading-alignment {
        padding: 0 16px;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: var(--secondary-500);
        }
      }

      .about-bluejestic-links {
        padding: 12px 0 0px 0;
        padding-bottom: 0;
        border: 0;

        li {
          margin: 0;
          padding: 0;
          display: block;

          &:active {
            background-color: transparent;
          }

          a {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: var(--secondary-900);
            padding: 4px 16px;
            display: block;

            &:hover {
              background: var(--primary-50);
              border-radius: 7.27273px;
            }

            .link-icon {
              width: 20px;
              margin-right: 30px;
            }
          }
        }
      }
    }

    .log-out-section {
      padding: 20px 16px 0 16px;

      a {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: var(--secondary-500);
        display: flex;

        &:hover {
          color: #6b21a8;
        }

        &:active {
          color: #6b21a8;
        }

        &:focus {
          color: #6b21a8;
        }
      }

      .log-out-link-icon {
        width: 24px;
        margin-right: 8px;
      }
    }

    &.ant-dropdown {
      top: 52px !important;
    }
  }
}

.create-store {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--primary-500);
}

.exist-store {
  color: var(--secondary-900);
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.status-child-heading-alignment {
  padding: 0 16px 0 16px;

  p {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: var(--secondary-500);
  }
}

.header-for-webview {
  @media screen and (max-width: 1199px) {
    display: none;
  }

  @media screen and (max-width: 992px) {
    display: none;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.header-for-mobileview {
  @media screen and (min-width: 992px) {
    display: none;
  }
}

// .product-page {
//   overflow: hidden !important;
//   max-width: 1440px;
//   margin: 0 auto;

//   @media screen and (max-width: 767px) {
//     min-width: 100%;
//     // margin: 0;
//   }

//   .product-page-cover-info {
//     margin-top: -46px;

//     .ant-tabs-nav {
//       margin: 0;

//       // margin-right: 20px;
//       // margin-bottom: 20px !important;
//       .ant-tabs-nav-wrap {
//         margin-bottom: 10px;
//         display: flex;
//         justify-content: flex-end;
//       }
//     }

//     .ant-tabs-top > .ant-tabs-nav::before,
//     .ant-tabs-top > div > .ant-tabs-nav::before {
//       border: 0;
//     }

//     .ant-tabs-nav-list {
//       border-radius: 0;

//       .ant-tabs-tab {
//         padding: 0;
//         margin: 0;

//         .ant-tabs-tab-btn {
//           margin: 18px 0px 12px 0px;
//           padding: 0 16px 0 16px;
//           font-size: 16px;
//           line-height: 16px;
//           color: #a7a7a7;
//           font-weight: 500 !important;
//           border-left: solid 1px #a7a7a7;
//           // height: 15px;
//         }
//       }

//       .ant-tabs-tab-active {
//         .ant-tabs-tab-btn {
//           color: #000;
//         }
//       }

//       .ant-tabs-ink-bar {
//         height: 2px;
//         background: #5f40af;
//         // border-top-left-radius: 2px;
//         // border-top-right-radius: 2px;
//       }
//     }

//     .ant-tabs-content-holder {
//       .ant-tabs-tabpane-active {
//         width: 100%;
//         height: 100%;
//       }
//     }
//   }

//   @media screen and (min-width: 992px) {
//     .product-page-featured-product-wrapper {
//       position: relative;

//       .product-info {
//         position: absolute;
//         top: 220px;
//         left: 0;
//         right: 0;
//         width: 95%;
//         margin: 0 auto;
//         display: flex;
//         justify-content: center;
//         visibility: hidden;

//         ul {
//           display: flex;
//           justify-content: space-between;
//           background-color: rgba(0, 0, 0, 0.55);
//           border-radius: 10px;
//           list-style: none;

//           li {
//             display: flex;
//             align-items: center;
//             margin: 2px 5px;

//             img {
//               width: 12px;
//               margin-right: 5px;
//             }

//             p {
//               color: #fff;
//               font-size: 10px;
//               line-height: 24px;
//               margin: 0;
//             }
//           }
//         }
//       }

//       &:hover {
//         cursor: pointer;

//         .product-info {
//           visibility: visible;
//         }
//       }
//     }

//     .product-page-all-product-wrapper {
//       .product-page-all-product-image-wrapper {
//         position: relative;

//         .interactive-info {
//           visibility: hidden;
//         }

//         &:hover {
//           cursor: pointer;

//           .interactive-info {
//             visibility: visible;
//           }
//         }
//       }

//       &:hover {
//         box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15) !important;
//       }
//     }

//     .mobile-tab-main {
//       display: none;
//     }
//   }

//   @media screen and (max-width: 767px) {
//     .product-page-cover-info {
//       display: none;
//     }

//     .mobile-tab-main {
//       background: #ffffff;
//       border-radius: 8px;
//       margin-top: 0px;
//       height: 42px;

//       .ant-tabs-nav {
//         margin-bottom: 8px !important;

//         .ant-tabs-nav-wrap {
//           display: flex;
//           justify-content: flex-end;
//         }
//       }

//       .ant-tabs-top > .ant-tabs-nav::before,
//       .ant-tabs-top > div > .ant-tabs-nav::before {
//         border: 0;
//       }

//       .ant-tabs-nav-list {
//         border-radius: 0;

//         .ant-tabs-tab {
//           padding: 0;
//           margin: 0;

//           .ant-tabs-tab-btn {
//             margin: 14px 0px 12px 0px;
//             padding: 0px 14px 0 14px;
//             font-size: 13px;
//             line-height: 16px;
//             color: #808191;
//             font-weight: 500 !important;
//             // border-left: solid 1px #a7a7a7;
//             // height: 15px;
//           }
//         }

//         .ant-tabs-tab-active {
//           .ant-tabs-tab-btn {
//             color: #000;
//           }
//         }

//         .ant-tabs-ink-bar {
//           height: 2px;
//           background: #5f40af;
//           // border-top-left-radius: 2px;
//           // border-top-right-radius: 2px;
//         }
//       }

//       .ant-tabs-content-holder {
//         .ant-tabs-tabpane-active {
//           width: 100%;
//           height: 100%;
//         }
//       }

//       .store-section-main {
//         padding: 12px;
//         height: auto;
//         background: #ffffff;
//         border-radius: 8px;
//         margin-bottom: 8px;

//         .slick-slide {
//           padding: 0 4px;
//         }

//         .store-section-header {
//           display: flex;
//           justify-content: space-between;

//           p {
//             font-weight: 500;
//             font-size: 16px;
//             line-height: 24px;
//             color: #000000;
//             margin-bottom: 8px;

//             span {
//               font-weight: 500;
//               font-size: 12px;
//               line-height: 18px;
//               color: #808191;
//             }
//           }

//           .store-header-alignment {
//             display: flex;
//             gap: 6px;
//             align-items: baseline;

//             img {
//               width: 5px;
//               height: 9px;
//             }

//             span {
//               font-weight: 500;
//               font-size: 14px;
//               line-height: 21px;
//               letter-spacing: 0.32px;
//               color: var(--primary-500);
//             }
//           }
//         }

//         .store-front-section {
//           display: flex;
//           justify-content: center;
//           align-items: center;

//           .store-image-align {
//             width: 100%;
//             height: 100%;
//             display: flex;
//             justify-content: center;

//             img {
//               width: 100%;
//               // height: 76px;
//               // border-radius: 8px;
//               object-fit: cover;
//             }
//           }

//           p {
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             font-weight: 500;
//             font-size: 12px;
//             margin-top: 6px;
//             color: #808191;
//           }
//         }
//       }

//       .generalinfo-section-main {
//         padding: 12px;
//         height: auto;
//         background: #ffffff;
//         border-radius: 8px;
//         margin-bottom: 8px;

//         p {
//           font-weight: 500;
//           font-size: 16px;
//           line-height: 24px;
//           color: #000000;
//           margin-bottom: 14px;
//         }

//         .hobbies-section {
//           .hobiies-section-flex {
//             display: flex;
//             gap: 10px;
//             margin-bottom: 4px;

//             .hobbie-icon {
//               img {
//                 height: 18px;
//                 width: 18px;
//               }
//             }

//             h4 {
//               font-weight: 500;
//               font-size: 14px;
//               line-height: 18px;
//               color: var(--secondary-900);
//             }
//           }

//           p {
//             margin-left: 29px;
//             font-weight: 400;
//             font-size: 12px;
//             line-height: 18px;
//             color: #808191;
//           }
//         }
//       }

//       .media-section-main {
//         padding: 12px;
//         height: auto;
//         background: #ffffff;
//         border-radius: 8px;
//         margin-bottom: 8px;
//         display: grid;
//         grid-template-columns: repeat(3, 1fr);
//         gap: 10px;

//         .media-grid {
//           height: 96px;
//           width: 100%;

//           img {
//             height: 100%;
//             width: 100%;
//             object-fit: cover;
//             border-radius: 8px;
//           }
//         }
//       }
//     }

//     .onboard-detail {
//       width: 100%;
//       height: 102px;
//       left: 74px;
//       top: 466px;
//       background: #5f40af;
//       border-radius: 10px;
//       margin-bottom: 8px;
//       padding: 14px;

//       .complete-deatil {
//         display: flex;
//         justify-content: space-between;
//         align-items: center;

//         .onboarding-box {
//           display: flex;
//           column-gap: 8px;

//           span {
//             width: 35.73px;
//             height: 3px;
//             background: #ff7d23;
//           }
//         }

//         h2 {
//           font-weight: 500;
//           font-size: 12px;
//           line-height: 21px;
//           color: #f8f8f8;
//         }

//         margin-bottom: 20px;
//       }

//       .complete-deatil-question {
//         display: flex;
//         justify-content: space-between;
//         align-items: center;

//         h4 {
//           font-weight: 600;
//           font-size: 14px;
//           line-height: 27px;
//           color: #ffffff;
//         }

//         button {
//           padding: 4px 11px;
//           cursor: pointer;
//           font-weight: 600;
//           font-size: 14px;
//           line-height: 24px;
//           background: #fff;
//           color: var(--primary-500);
//           border: none;
//           border-radius: 10px;
//         }
//       }
//     }
//   }

//   .web-socialuser-main {
//     @media screen and (max-width: 767px) {
//       margin-bottom: 8px;
//     }

//     .profile-align {
//       filter: brightness(0.8);

//       .upload-icon-upload {
//         position: absolute;
//         width: 47px;
//         height: 45px;
//         z-index: 9999;
//         background-color: red;
//         top: 63%;
//         right: 1%;
//         background: #d9d9d9;
//         border-radius: 10px;
//         display: flex;
//         justify-content: center;
//         align-items: center;

//         img {
//           width: 24px;
//           height: 24px;
//         }

//         input {
//           width: 100%;
//           height: 100%;
//           position: absolute;
//           top: 0;
//           left: 0;
//           opacity: 0;
//         }

//         @media screen and (max-width: 992px) {
//           display: none;
//         }

//         @media screen and (max-width: 767px) {
//           display: none;
//         }
//       }

//       height: 283px;
//       display: flex;
//       overflow: hidden;

//       @media screen and (max-width: 767px) {
//         height: 136px;
//       }
//     }

//     .user-profile-image {
//       img {
//         width: 105px;
//         height: 105px;
//         top: 226px;
//         left: 86px;
//         object-fit: cover;
//         position: absolute;
//         border-radius: 50%;
//       }

//       @media screen and (max-width: 992px) {
//         display: none;
//       }

//       @media screen and (max-width: 767px) {
//         display: none;
//       }
//     }

//     .profile-section-main {
//       @media screen and (min-width: 992px) {
//         display: none;
//       }

//       @media screen and (max-width: 767px) {
//         .profile-page-alignment {
//           display: block;
//           top: 66px;
//           position: absolute;
//           display: flex;
//           gap: 134px;
//           align-items: center;
//           padding: 0 14px;

//           .profile-img {
//             display: flex;
//             align-items: center;
//             gap: 14px;

//             img {
//               width: 105px;
//               height: 105px;

//               @media screen and (max-width: 767px) {
//                 width: 54px;
//                 height: 54px;
//               }
//             }
//           }

//           .profile-text-align {
//             h4 {
//               font-weight: 500;
//               font-size: 14px;
//               line-height: 21px;
//               color: #ffffff;
//             }

//             p {
//               font-weight: 400;
//               font-size: 12px;
//               line-height: 18px;
//               color: #ffffff;
//             }

//             span {
//               font-weight: 500;
//               font-size: 10px;
//               line-height: 15px;
//               color: #ffffff;
//             }
//           }

//           .upload-icon {
//             width: 32px;
//             height: 32px;
//             z-index: 9999;
//             background-color: var(--secondary-900);
//             top: 63%;
//             right: 1%;
//             background: transparent(50%);
//             border-radius: 10px;
//             display: flex;
//             justify-content: center;
//             align-items: center;

//             svg {
//               width: 24px;
//               height: 24px;
//             }

//             input {
//               width: 100%;
//               height: 100%;
//               position: absolute;
//               top: 0;
//               left: 0;
//               opacity: 0;
//             }
//           }
//         }
//       }
//     }

//     .profile-info-section {
//       @media screen and (max-width: 767px) {
//         .user-information {
//           padding-bottom: 14px;

//           p {
//             font-weight: 400;
//             font-size: 13px;
//             line-height: 18px;
//             color: var(--secondary-900);

//             span {
//               color: var(--primary-500);
//               font-weight: 500;
//             }
//           }
//         }

//         .event-info {
//           display: flex;
//           gap: 8px;
//           align-items: center;
//           padding-bottom: 14px;

//           img {
//             width: 18px;
//             height: 18px;
//           }

//           p {
//             padding-top: 2px;
//             font-weight: 500;
//             font-size: 12px;
//             line-height: 18px;
//             color: var(--primary-500);
//           }
//         }

//         .follower-following-container {
//           display: flex;
//           gap: 172px;
//           align-items: center;

//           .following-followers-align {
//             display: flex;
//             gap: 30px;
//             align-items: center;

//             .following-alignment {
//               p {
//                 font-weight: 600;
//                 font-size: 12px;
//                 line-height: 18px;
//                 color: var(--secondary-900);
//               }

//               span {
//                 font-weight: 400;
//                 font-size: 10px;
//                 line-height: 15px;
//                 display: flex;
//                 align-items: center;
//                 color: var(--secondary-900);
//               }
//             }
//           }

//           .follow-message-container {
//             display: flex;
//             gap: 10px;
//             align-items: center;

//             .follow-button {
//               background: var(--primary-500);
//               border-radius: 8px;
//               width: 91px;
//               height: 32px;
//               display: flex;
//               justify-content: center;
//               align-items: center;

//               button {
//                 font-style: normal;
//                 font-weight: 600;
//                 font-size: 14px;
//                 line-height: 21px;
//                 color: #f8f8f8;
//               }
//             }

//             .message-alignment {
//               background: var(--primary-500);
//               border-radius: 8px;
//               width: 32px;
//               height: 32px;
//               display: flex;
//               justify-content: center;
//               align-items: center;

//               img {
//                 width: 18px;
//                 height: 18px;
//               }
//             }
//           }
//         }
//       }

//       @media screen and (min-width: 992px) {
//         display: none;
//       }
//     }

//     .profile-page-main {
//       padding-left: 256px;
//       padding-top: 8px;
//       height: 75px;

//       @media screen and (max-width: 767px) {
//         display: none;
//         padding-left: 0px;
//         padding-top: 0px;
//         height: 75px;
//       }
//     }
//   }
// }

.header-section {
  position: sticky;
  top: 0;
  z-index: 999999999;
  // z-index: 999;

  @media only screen and (max-width: '1024px') {
    display: none;
  }

  .header-alignment {
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(85, 110, 230, 0.06);
    padding: 9px 0;

    .header-flex-alignment {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      .header-logo-home-alignment {
        display: flex;
        align-items: center;
        gap: 32px;

        .logo-alignment {
          width: 143px;
          max-width: 143px;

          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }

        // .home-alignment {
        //   display: flex;
        //   height: 24px;
        //   gap: 12px !important;
        //   // align-items: center;
        //   // gap: 4px;
        //   align-items: center;

        //   .icon-alignment {
        //     display: flex;
        //     justify-content: space-between;
        //     align-items: center;

        //     gap: 8px;

        //     img {
        //       cursor: pointer;
        //     }
        //   }
        // }
      }

      .search-alignment {
        display: flex;
        justify-content: center;
        align-items: center;
        // gap: 26px;
        width: 100%;

        .search-input {
          max-width: 509px;
          width: 509px;
          height: 40px;

          @media only screen and (max-width: '1299px') {
            max-width: 100%;
            width: 100%;
          }

          &.without-login {
            max-width: 476px;
            width: 476px;

            @media only screen and (max-width: '1299px') {
              max-width: 100%;
              width: 100%;
            }
          }

          input {
            width: 100%;
            height: 100%;
            background: rgba(172, 177, 192, 0.2);
            border-radius: 11px;
            text-align: center;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;

            &::placeholder {
              color: #808191;
            }
          }
        }
      }

      .header-option-profile-alignment {
        display: flex;
        align-items: center;
        gap: 20px;

        .header-option-alignment {
          display: flex;
          align-items: center;
          gap: 8px;

          .search-home-menu-mail-flex {
            display: flex;
            // height: 48px;
            // gap: 12px !important;
            // align-items: center;
            // background: #eef1fc;
            // gap: 4px;
            align-items: center;
            cursor: pointer;
            // border-radius: 10px;

            transition: 0.02s ease-in-out;

            .home-alignment {
              height: 48px;
              display: flex;
              align-items: center;

              padding: 0 12px;

              &.with-out-login {
                background: transparent;
              }

              &:hover {
                background: #eef1fc !important;
                border-radius: 10px;
              }

              .home-icon-bottom-alignment {
                height: 2px;
                width: 10px;
                background: var(--gradient, linear-gradient(134deg, #556ee6 0%, #6b21a8 100%));
                margin: 5px auto 0 auto;
                transition: 0.02s ease-in-out;
                display: none;
              }

              &.activeShop {
                svg {
                  path {
                    stroke: #556ee6;
                  }
                }

                .home-icon-bottom-alignment {
                  display: block;
                  transition: 0.02s ease-in-out;
                }
              }

              &:hover {
                svg {
                  path {
                    stroke: #556ee6;
                  }
                }

                .home-icon-bottom-alignment {
                  display: block;
                  transition: 0.02s ease-in-out;
                }
              }

              .icon-alignment {
                display: flex;
                justify-content: space-between;
                align-items: center;

                gap: 8px;
                width: 24px;
                min-width: 24px;
                max-width: 24px;
                height: 24px;

                img {
                  cursor: pointer;
                }
              }
            }

            &.activeShop {
              svg {
                path {
                  stroke: #556ee6;
                }
              }

              .home-icon-bottom-alignment {
                display: block;
                transition: 0.02s ease-in-out;
              }
            }
          }


          .novu-notication-allignment {
            position: relative;
            width: 49px;
            height: 46px;
            cursor: pointer;


            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
              background: #eef1fc;
              flex-direction: column;
              gap: 5px;
              border-radius: 10px;

              svg {
                rect {
                  stroke: #556ee6 !important;
                }

                path {
                  stroke: #556ee6 !important;
                }
              }


              .border-alignment {
                background: linear-gradient(92.44deg, #556ee6 0.13%, #6b21a8 100%);
                border-radius: 10px;
                height: 2px;
                width: 10px;
                // bottom: 8px;
                left: 9px;
                // right: 0;
              }

            }
          }

          .option-box-alignment {
            max-width: 49px;
            width: 49px;
            height: 46px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            svg {
              width: 24px;
              height: 24px;
              display: block;
            }

            img {
              width: 24px;
              height: 24px;
              display: block;
            }

            &:hover {
              background: #eef1fc;
              border-radius: 10px;
              flex-direction: column;
              gap: 5px;

              svg {
                rect {
                  stroke: #556ee6 !important;
                }

                path {
                  stroke: #556ee6 !important;
                }
              }

              &.home_icon {
                svg {
                  path {
                    // fill: #556ee6;
                    // stroke: none !important;
                  }
                }
              }

              .border-alignment {
                // position: absolute;
                background: linear-gradient(92.44deg, #556ee6 0.13%, #6b21a8 100%);
                border-radius: 10px;
                height: 2px;
                width: 10px;
                // bottom: 8px;
                // left: 0;
                // right: 0;
              }
            }

            &.active-option {
              background: #eef1fc;
              border-radius: 10px;
              flex-direction: column;
              gap: 5px;
              // svg {
              //   rect {
              //     stroke: #556ee6 !important;
              //   }

              //   path {
              //     stroke: #556ee6 !important;
              //   }
              // }

              svg {
                rect {
                  stroke: #556ee6 !important;
                }

                path {
                  stroke: #556ee6 !important;
                }
              }

              &.home_icon {
                svg {
                  path {
                    // fill: #556ee6;
                    // stroke: none !important;
                  }
                }
              }

              .border-alignment {
                // position: absolute;
                background: linear-gradient(92.44deg, #556ee6 0.13%, #6b21a8 100%);
                border-radius: 10px;
                height: 2px;
                width: 10px;
                // bottom: 8px;
                // left: 0;
                // right: 0;
              }
            }

            .cart-number-box-alignment {
              padding: 2px 4px;
              border-radius: 50%;
              background: red;
              position: absolute;
              // top: 4px;
              // right: 6px;
              // height: 12px;
              // width: 12px;
              top: 8px;
              right: 7px;
              height: 16px;
              width: 16px;
              display: flex;
              justify-content: center;
              align-items: center;

              p {
                font-size: 12px;
                line-height: 14px;
                color: #fff;
              }
            }
          }

          .option-icon-alignment {
            position: relative;

          }

          .chat-notification-icon {
            position: absolute;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: red;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              color: #fff;
              text-align: center;
              font-family: "Poppins", system-ui;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }

          .notification-icon-alignment {
            position: relative;

            .notification-box-section {
              position: absolute;
              top: 64px;
              right: 0;
              transform: translateX(40%);
              max-width: 626px;
              width: 626px;
              // height: auto;
              background: #ffffff;
              border-radius: 15px;

              &.open-notification-modal {
                max-height: calc(100vh - 88px);
                overflow-y: auto;
                transition: all 0.5s ease-in-out;
              }

              &.close-notification-modal {
                max-width: 0px;
                transition: all 0.5s ease-in-out;
              }

              .bottom-blur-effect {
                position: sticky;
                width: 100%;
                height: 64px;
                left: 0;
                bottom: 0;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
                border-radius: 0px 0px 15px 15px;
                z-index: 999;
              }

              .notification-box-alignment {
                background: #ffffff;
                border: 2px solid #ffffff;
                width: 100%;

                .notification-main-details-alignment {
                  padding: 32px 32px 16px 32px;
                  border-bottom: 1px solid #eceef4;
                  position: sticky;
                  top: 0;
                  background: #fff;
                  z-index: 999;

                  .notification-heading-alignment {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .notification-main-heading-alignment {
                      display: flex;
                      align-items: center;
                      gap: 4px;

                      h4 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 36px;
                        letter-spacing: 0.1px;
                        color: var(--secondary-900);
                      }

                      .notification-number-alignment {
                        background: #d21414;
                        border-radius: 10px;
                        padding: 0 8px;

                        span {
                          font-family: 'Poppins', sans-serif;
                          font-weight: 500;
                          font-size: 13px;
                          line-height: 20px;
                          color: #ffffff;
                        }
                      }
                    }

                    .mark-allread-notifiaction-alignment {
                      display: flex;
                      align-items: center;
                      gap: 34px;

                      p {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 20px;
                        color: var(--secondary-600);
                        cursor: pointer;
                      }

                      .setting-icon-alignment {
                        img {
                          cursor: pointer;
                        }
                      }
                    }
                  }

                  .notification-filter-details-alignment {
                    padding: 32px 0 0 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .notification-option-tab-alignment {
                      // display: flex;
                      // align-items: center;
                      // gap: 8px;

                      ul {
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        li {
                          padding: 6px 16px;
                          display: flex;
                          align-items: center;
                          gap: 3px;
                          background: #eceef4;
                          border-radius: 8px;
                          cursor: pointer;

                          &:hover {
                            background: var(--primary-50);
                            border-radius: 8px;

                            span {
                              color: var(--primary-700);
                              font-weight: 600;
                            }
                          }

                          &.setting-tab-active {
                            background: var(--primary-50);
                            border-radius: 8px;

                            span {
                              color: var(--primary-700);
                              font-weight: 600;
                            }
                          }

                          span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 15px;
                            line-height: 20px;
                            color: var(--secondary-600);
                          }
                        }
                      }
                    }

                    .unread-option-alignment {
                      display: flex;
                      align-items: center;
                      gap: 4px;

                      p {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 20px;
                        color: var(--secondary-600);
                      }

                      .unready-checkbox-alignment {
                        input[type='checkbox'] {
                          width: 16px;
                          height: 16px;
                        }
                      }
                    }
                  }
                }

                .notification-body-details-alignment {
                  .notification-time-alignment {
                    padding: 16px 32px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 24px;
                      color: var(--secondary-400);
                    }
                  }

                  .notification-all-body-details-alignment {
                    padding: 16px 32px 0 32px;
                    display: flex;
                    align-items: flex-start;
                    gap: 16px;

                    .notification-img-alignment {
                      width: 56px;
                      min-width: 56px;
                      max-width: 56px;
                      height: 56px;

                      img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 4px;
                      }
                    }

                    .product-all-notification-details-alignment {
                      h6 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--secondary-900);

                        span {
                          font-weight: 700;
                        }
                      }

                      .product-all-notification-img-box-alignment {
                        background: #f8f8f8;
                        border: 4px solid #ffffff;
                        box-shadow: 0px 1px 4px rgba(30, 36, 50, 0.16);
                        border-radius: 10px;
                        padding: 16px;
                        margin: 8px 0 0 0;
                        width: auto;

                        .product-all-notification-img-flex-alignment {
                          display: flex;
                          align-items: center;
                          flex-wrap: wrap;
                          gap: 8px;

                          .product-notification-img-alignment {
                            width: 80px;
                            min-width: 80px;
                            max-width: 80px;
                            height: 80px;
                            cursor: pointer;

                            img {
                              width: 100%;
                              height: 100%;
                              object-fit: cover;
                              border-radius: 4px;
                            }
                          }
                        }
                      }

                      .product-notiification-time-alignment {
                        padding: 12px 0;
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        .product-notification-tag-alignment {
                          display: flex;
                          align-items: center;
                          gap: 4px;

                          span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 20px;
                            letter-spacing: 0.1px;
                            color: var(--secondary-500);
                          }

                          p {
                            width: 4px;
                            height: 4px;
                            border-radius: 50%;
                            background: #7a7e88;
                            margin: 0 0 0 4px;
                          }
                        }

                        .tag-time-alignment {
                          span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 20px;
                            letter-spacing: 0.1px;
                            color: var(--secondary-500);
                          }
                        }
                      }
                    }
                  }

                  .notification-other-post-details-alignment {
                    background: #f8f8f8;
                    padding: 16px 32px;
                    display: flex;
                    align-items: flex-start;
                    gap: 16px;
                    position: relative;

                    .notification-red-box-alignment {
                      position: absolute;
                      top: 6px;
                      left: 8px;

                      p {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: #c21717;
                      }
                    }

                    .notification-other-post-img-alignment {
                      width: 56px;
                      min-width: 56px;
                      max-width: 56px;
                      height: 56px;

                      img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        object-fit: cover;
                      }
                    }

                    .notification-post-other-details-alignment {
                      h6 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--secondary-900);

                        span {
                          font-weight: 700;
                        }
                      }

                      .product-notiification-time-alignment {
                        padding: 12px 0 0 0;
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        .product-notification-tag-alignment {
                          display: flex;
                          align-items: center;
                          gap: 4px;

                          span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 20px;
                            letter-spacing: 0.1px;
                            color: var(--secondary-500);
                          }

                          p {
                            width: 4px;
                            height: 4px;
                            border-radius: 50%;
                            background: #7a7e88;
                            margin: 0 0 0 4px;
                          }
                        }

                        .tag-time-alignment {
                          span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 20px;
                            letter-spacing: 0.1px;
                            color: var(--secondary-500);
                          }
                        }
                      }
                    }
                  }

                  .shipped-product-details-alignment {
                    padding: 12px 32px 20px 32px;
                    display: flex;
                    align-items: flex-start;
                    gap: 16px;

                    &:hover {
                      background: var(--primary-50);
                    }

                    .shipped-icon-box-alignment {
                      width: 56px;
                      min-width: 56px;
                      max-width: 56px;
                      height: 56px;

                      img {
                        width: 100%;
                        max-width: 100%;
                        height: auto;
                      }
                    }

                    .shipped-details-flex-alignment {
                      display: flex;
                      align-items: flex-start;
                      gap: 16px;

                      h6 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--secondary-900);

                        span {
                          font-weight: 700;
                        }
                      }

                      .product-notiification-time-alignment {
                        padding: 12px 0;
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        .product-notification-tag-alignment {
                          display: flex;
                          align-items: center;
                          gap: 4px;

                          span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 20px;
                            letter-spacing: 0.1px;
                            color: var(--secondary-500);
                          }

                          p {
                            width: 4px;
                            height: 4px;
                            border-radius: 50%;
                            background: #7a7e88;
                            margin: 0 0 0 4px;
                          }
                        }

                        .tag-time-alignment {
                          span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 20px;
                            letter-spacing: 0.1px;
                            color: var(--secondary-500);
                          }
                        }

                        .more-icon-alignment {
                          margin: 0 0 0 8px;
                        }
                      }

                      .shipped-img-alignment {
                        width: 80px;
                        height: 80px;
                        min-width: 80px;
                        max-width: 80px;

                        img {
                          width: 100%;
                          height: 100%;
                          border: 4px solid #ffffff;
                          box-shadow: 0px 1px 4px rgba(30, 36, 50, 0.16);
                          border-radius: 8px;
                        }
                      }
                    }
                  }

                  .other-details-profile-alignment {
                    padding: 18px 32px 0 32px;
                    display: flex;
                    align-items: flex-start;
                    gap: 16px;

                    .notification-other-post-img-alignment {
                      width: 56px;
                      min-width: 56px;
                      max-width: 56px;
                      height: 56px;

                      img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        object-fit: cover;
                      }
                    }

                    .notification-post-other-details-alignment {
                      h6 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--secondary-900);

                        span {
                          font-weight: 700;
                        }
                      }

                      .child-comment-box-alignment {
                        margin: 8px 0 0 0;
                        background: #f8f8f8;
                        border: 4px solid #ffffff;
                        box-shadow: 0px 1px 4px rgba(30, 36, 50, 0.16);
                        border-radius: 10px;
                        padding: 12px 16px;

                        .child-notification-comment-profile-alignment {
                          display: flex;
                          align-items: center;
                          gap: 8px;

                          .child-comment-img-alignment {
                            width: 32px;
                            min-width: 32px;
                            max-width: 32px;
                            height: 32px;

                            img {
                              width: 100%;
                              height: 100%;
                              border-radius: 50%;
                            }
                          }

                          .child-comment-details-alignment {
                            h6 {
                              font-family: 'Poppins', sans-serif;
                              font-weight: 600;
                              font-size: 13px;
                              line-height: 20px;
                              color: var(--secondary-900);
                            }

                            span {
                              font-family: 'Poppins', sans-serif;
                              font-weight: 400;
                              font-size: 13px;
                              line-height: 20px;
                              color: var(--secondary-500);
                              display: block;
                            }
                          }
                        }

                        p {
                          font-family: 'Poppins', sans-serif;
                          font-weight: 400;
                          font-size: 13px;
                          line-height: 20px;
                          color: var(--secondary-900);
                          padding: 14px 0 0 0;
                        }
                      }

                      .product-notiification-time-alignment {
                        padding: 12px 0 0 0;
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        .product-notification-tag-alignment {
                          display: flex;
                          align-items: center;
                          gap: 4px;

                          span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 20px;
                            letter-spacing: 0.1px;
                            color: var(--secondary-500);
                          }

                          p {
                            width: 4px;
                            height: 4px;
                            border-radius: 50%;
                            background: #7a7e88;
                            margin: 0 0 0 4px;
                          }
                        }

                        .tag-time-alignment {
                          span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 20px;
                            letter-spacing: 0.1px;
                            color: var(--secondary-500);
                          }
                        }
                      }
                    }
                  }

                  .follow-notification-alignment {
                    padding: 34px 32px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .follow-notification-profile-alignment {
                      display: flex;
                      align-items: center;
                      gap: 16px;

                      .follow-notification-img-alignment {
                        width: 56px;
                        min-width: 56px;
                        max-width: 56px;
                        height: 56px;

                        img {
                          width: 100%;
                          height: 100%;
                          border-radius: 50%;
                          display: block;
                          object-fit: cover;
                        }
                      }

                      .follow-notification-profile-details-alignment {
                        h6 {
                          font-family: 'Poppins', sans-serif;
                          font-weight: 400;
                          font-size: 16px;
                          line-height: 24px;
                          color: var(--secondary-900);

                          span {
                            font-weight: 700;
                          }
                        }
                      }
                    }

                    .follow-back-button-alignment {
                      button {
                        padding: 6px 16px;
                        background: var(--primary-500);
                        border-radius: 8px;
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 20px;
                        color: #ffffff;
                        cursor: pointer;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .product-notiification-time-alignment {
          padding: 12px 0;
          display: flex;
          align-items: center;
          gap: 8px;

          .product-notification-tag-alignment {
            display: flex;
            align-items: center;
            gap: 4px;

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 13px;
              line-height: 20px;
              letter-spacing: 0.1px;
              color: var(--secondary-500);
            }

            p {
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background: #7a7e88;
              margin: 0 0 0 4px;
            }
          }

          .tag-time-alignment {
            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              letter-spacing: 0.1px;
              color: var(--secondary-500);
            }
          }
        }

        .header-profile-alignment {
          display: flex !important;
          align-items: center;
          gap: 4px;
          position: relative;

          .profile-img-alignment {
            // width: 36px;
            // min-width: 36px;
            // max-width: 36px;
            height: 36px;
            cursor: pointer;
            display: flex;
            align-items: center !important;
            gap: 4px;

            .profile-img {
              width: 36px;
              min-width: 36px;
              max-width: 36px;
              height: 36px;

              &.seller-profile-img {
                width: 32px;
                min-width: 32px;
                max-width: 32px;
                height: 32px;

                img {
                  border-radius: 4px;
                }
              }

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                display: block;
                object-fit: cover;
              }
            }

            .dropdown-icon {
              position: relative;

              img {
                cursor: pointer;
              }
            }

            .header-dropdown-img {
              width: 12px;
              height: 6px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }

        .header-profile-login-details-alignment {
          display: flex;
          align-items: center;
          gap: 16px;

          .header-login-button {
            button {
              padding: 6px 16px;
              color: var(--primary-500);
              font-family: 'Poppins', sans-serif;
              font-size: 15px;
              font-weight: 500;
              line-height: 20px;
              cursor: pointer;
              background: transparent;
              border: none;

              &.active-auth-button {
                border-radius: 8px;
                background: var(--primary-500);
                color: #ffffff;
              }

              &:hover {
                background: var(--primary-50);
                transition: 0.4s ease-in-out;
                border-radius: 8px;
              }

              @media only screen and (max-width: '1199px') {
                font-size: 12px;
                line-height: 18px;
                padding: 6px 12px;
              }
            }
          }

          .sign-up-button {
            button {
              padding: 6px 16px;
              font-family: 'Poppins', sans-serif;
              font-size: 15px;
              font-weight: 500;
              line-height: 20px;
              cursor: pointer;
              border: none;
              color: var(--primary-500);
              background: transparent;
              white-space: nowrap;

              &.active-auth-button {
                border-radius: 8px;
                background: var(--primary-500);
                color: #ffffff;
              }

              &:hover {
                background: var(--primary-700);
                transition: 0.4s ease-in-out;
              }

              @media only screen and (max-width: '1199px') {
                font-size: 12px;
                line-height: 18px;
                padding: 6px 12px;
              }
            }
          }
        }
      }
    }
  }
}

.nv-popoverContent {
  z-index: 9999999999 !important;
}

.mobile-view-header-alignment {
  display: none;

  @media only screen and (max-width: '1024px') {
    display: block;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999999;
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(85, 110, 230, 0.06);
    width: 100%;

    .mobile-view-header-option-alignment {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4px;
      padding: 0 16px;

      .mobile-view-logo-alignment {
        width: 44px;
        height: 44px;
        max-width: 44px;
        min-width: 44px;

        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }

      .mobile-view-header-other-option-alignment {
        padding: 10px;
        position: relative;

        .chat-notification-icon {
          position: absolute;
          top: 5px;
          right: 5px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-color: red;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            color: #fff;
            text-align: center;
            font-family: "Poppins", system-ui;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }

      .notification-button {
        @media only screen and (max-width: '767px') {
          display: none;
        }
      }

      .mobile-view-search-alignment {
        border-radius: 8.069px;
        background: #f8f8f8;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 0 8px;

        input {
          width: 100%;
          height: 100%;
          background: transparent;
          border: none;
          font-family: 'Poppins', sans-serif;
          font-size: 15px;
          font-weight: 400;
          line-height: 22px;

          &::placeholder {
            color: var(--secondary-500);
          }
        }
      }
    }
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: none;
}

.user-profile-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  h5 {
    font-weight: 500 !important;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
    margin: 0;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #808191;
    margin: 0;
  }
}

.mantine-Popover-dropdown {
  z-index: 999999 !important;
}