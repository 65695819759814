.suggestedClubsDetailsAllDetailsAlignment {
  margin: 16px 0 0 0;
  @media (max-width: 576px) {
    margin: 0;
  }
  .suggestedClubsDetailsAllContentAlignment {
    // max-height: 590px;
    max-height: calc(100vh - 334px);
    overflow: auto;
    padding: 0 8px;
    position: relative;

    @media only screen and (max-width: '1024px') {
      max-height: 100%;
    }

    @media (max-width: 576px) {
      padding: 0;
    }

    .suggestedClubsDetailsCardGrid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;

      @media only screen and (max-width: '574px') {
        grid-template-columns: 1fr;
        gap: 8px;
      }

      .suggestedClubsDetailsCardGridItem {
        border-radius: 8px 8px 0px 0px;
        position: relative;

        .suggestedClubsDetailsCardImage {
          img {
            width: 100%;
            height: 251px;
            object-fit: cover;
            border-radius: 10px 10px 0 0;
          }
        }

        .suggestedClubsCardDetails {
          position: absolute;
          bottom: 0;
          left: 0;

          width: 100%;

          .suggestedClubsCardDetailsAlignment {
            padding: 9px 9px 18px 9px;

            .suggestedClubsAllContentAlignment {
              display: flex;
              align-items: center;
              width: 100%;
              justify-content: space-between;

              .suggestedClubsRightSideContent {
                button {
                  background: rgba(55, 55, 55, 0.78);
                  border-radius: 10px;
                  padding: 0 11px;
                  height: 20px;
                  font-weight: 500;
                  font-size: 12px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                  color: #fff;
                }
              }

              .suggestedClubsLeftSideContent {
                display: flex;
                align-items: center;
                gap: 4px;

                img {
                  cursor: pointer;
                }

                span {
                  display: block;
                  color: #fff;
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 18px;
                }
              }

              .suggestedClubsusers {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .suggestedClubsUserIcon {
                  display: flex;
                  align-items: center;
                  gap: 4px;

                  .suggestedClubseftPart {
                    display: flex;
                    align-items: center;
                    padding-left: 10px;

                    .clubModaUsersLikes {
                      display: flex;
                      align-items: center;
                      margin-left: -10px;

                      .suggestedClubsUserBox {
                        width: 20px;
                        display: flex;
                        align-items: center;
                        height: 20px;
                        justify-content: center;
                        background: #fff;
                        object-position: center;
                        border: 1px solid #ffffff;
                        border-radius: 30px;
                        margin: 0 0 0 -10px;

                        &:first-child {
                          margin: 0;
                        }

                        img {
                          width: 100%;
                          border-radius: 30px;
                          object-fit: cover !important;
                        }
                      }
                    }

                    .clubModaCount {
                      margin-left: -10px;
                      border-radius: 12px;
                      border: 1px solid #fff;
                      background: #1e2432;
                      border-radius: 12px;
                      font-weight: 500;
                      font-size: 12px;
                      line-height: 18px;
                      letter-spacing: 0.1px;
                      color: #ffffff;
                      padding: 0px 9px;
                    }
                  }
                }

                .suggestedClubsRightSideContent {
                  button {
                    border-radius: 8px;
                    background: var(--primary-500);
                    padding: 0 22px;
                    height: 32px;
                    color: #fff;
                    text-align: center;
                    font-size: 14px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    letter-spacing: 0.1px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    color: #fff;
                  }
                }
              }
            }

            h5 {
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #fff;
              margin: 0 0 18px 0;
            }

            .suggestedClubsLimeCamp {
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }

            h4 {
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #fff;
              margin: 0 0 1px 0;
              cursor: pointer;
            }
          }
        }

        .suggestedClubsblurDiv {
          position: absolute;
          background: linear-gradient(360deg, rgba(30, 36, 50, 0.8) 0%, rgba(30, 36, 50, 0) 100%);
          border-radius: 0px;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 251px;
        }
      }
    }
    .showMoreButtonContain {
      padding: 70px 0 52px 0;
    }

    .noFollowingStoreDetailsAlignment {
      padding: 84px 0;

      .noFollowingImg {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 16px;

        p {
          color: var(--secondary-300);
          text-align: center;
          font-family: 'Poppins', sans-serif;
          font-size: 16px;
          font-style: italic;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
  }
}
