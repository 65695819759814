.addProdutcHeadingAlignment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    background: #FFF;
    left: 0;
    z-index: 99;
    padding: 32px 0;

    h6 {
        color: var(--secondary-900);
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
    }

    a {
        color: #E71616;
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-weight: 500;
        line-height: 22px;
        cursor: pointer;

        &:hover {
            color: #E71616;
        }
    }
}

.addProductBodyAlignment {


    .addProdutcBodyChildDetails {
        margin: 0 0 32px 0;

        &:last-child {
            margin: 0;
        }

        label {
            color: var(--secondary-900);
            font-family: 'Poppins', sans-serif;
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            margin: 0;

            span {
                color: var(--secondary-500);
            }
        }

        .addProdutcAllChildList {
            margin: 16px 0 0 0;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 8px;

            .addChildListBox {
                height: 31px;
                padding: 0 14px;
                border-radius: 16px;
                background: var(--light-grey);
                cursor: pointer;
                display: flex;
                align-items: center;
                transition: all 0.4s ease-in-out;
                gap: 4px;

                .colorBoxAlignment {
                    width: 16px;
                    height: 16px;
                    min-width: 16px;
                    max-width: 16px;
                    border-radius: 4px;
                    background: #F10000;

                    &.blueBg {
                        background: #128AF8;
                    }

                    &.yellowBg {
                        background: #F8C200;
                    }

                    &.purpleBg {
                        background: #6A00F1;
                    }

                    &.darkBlue {
                        background: #00308D;
                    }

                    &.greenBg {
                        background: #5BA300;
                    }
                }

                p {
                    color: var(--secondary-500);
                    font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                }

                &:hover {
                    border-radius: 16px;
                    background: var(--primary-50);
                    transition: all 0.4s ease-in-out;

                    p {
                        color: var(--secondary-900);
                    }
                }

                &.activeDetails {
                    border-radius: 16px;
                    background: var(--primary-50);
                    transition: all 0.4s ease-in-out;

                    p {
                        color: var(--secondary-900);
                    }
                }
            }
        }
    }
}