.diy-room-section {
    margin: 56px 0 0 0;

    @media only screen and (max-width:"768px") {
        margin: 32px 0 0 0;
    }

    .diy-room-box-alignment {
        background: var( --primary-50);
        border-radius: 16px;
        padding: 48px 56px;

        @media only screen and (max-width:"768px") {
            background: none;
            padding: 0;
        }

        .diy-mobile-heading-alignment {
            display: none;

            @media only screen and (max-width:"768px") {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 16px;

                h6 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 30px;
                    color: var(--secondary-900);

                }

                .see-all-alignment {
                    a {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 21px;
                        color: var(--primary-500);
                    }
                }
            }
        }

        .diy-room-heading-alignment {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media only screen and (max-width:"768px") {
                display: none;
            }

            h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 36px;
                line-height: 54px;
                color: var(--secondary-900);
            }

            .diy-room-see-all-alignment {
                a {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 27px;
                    color: var(--primary-500);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 4px;
                    cursor: pointer;

                    @media only screen and (max-width:"768px") {
                        font-size: 14px;
                        line-height: 21px;

                        img {
                            display: none;
                        }
                    }

                    &:hover {
                        gap: 10px;
                        text-decoration: underline;
                    }
                }
            }
        }

        .diy-notes-alignment {
            @media only screen and (max-width:"768px") {
                display: none;
            }

            p {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--secondary-900);

            }
        }

        .diy-room-details-alignment {
            padding: 42px 0 0 0;

            @media only screen and (max-width:"768px") {
                background: #FFF3EA;
                border-radius: 16px;
                padding: 16px;
                margin: 16px 0 0 0;
            }

            .diy-room-details-grid-alignment {
                display: grid;
                grid-template-columns: 1fr 420px;
                gap: 32px;


                @media only screen and (max-width:"1099px") {
                    grid-template-columns: 1fr;
                }

                @media only screen and (max-width:"1099px") {
                    grid-template-columns: 1fr;
                    gap: 16px;
                }

                .diy-room-left-side-alignment {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    @media only screen and (max-width:"1099px") {
                        justify-content: center;
                    }

                    .diy-room-left-side-first-img-alignment {
                        width: 407px;
                        height: 404px;
                        overflow: hidden;
                        border-radius: 8px;

                        @media only screen and (max-width:"768px") {
                            width: 193px;
                            height: 211px;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 8px;
                            object-fit: cover;
                            &:hover {
                                transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

                                transform: scale(1.05);
                                border-radius: 8px;

                                img {
                                    border-radius: 8px;
                                    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                                    object-fit: cover;
                                }
                            }
                        }
                    }

                    .diy-room-second-img-alignment {
                        width: 293px;

                        @media only screen and (max-width:"768px") {
                            width: 142px;
                        }

                        .diy-child-img-alignment {
                            width: 100%;
                            height: 198px;
                            margin: 0 0 8px 0;
                            overflow: hidden;
                            border-radius: 8px;

                            @media only screen and (max-width:"768px") {
                                height: 104px;
                            }

                            &:last-child {
                                margin: 0;
                            }

                            img {
                                width: 100%;
                                height: 100%;
                                border-radius: 8px;
                                object-fit: cover;

                                &:hover {
                                    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                                    transform: scale(1.05);

                                    img {
                                        object-fit: cover;
                                        transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

                                    }

                                }

                            }
                        }
                    }
                }

                .diy-room-right-side-alignment {
                    h6 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 36px;
                        color: var(--secondary-900);

                        @media only screen and (max-width:"1099px") {
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }

                    .diy-room-by-details-alignment {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        padding: 5px 0 0 0;

                        span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 20px;
                            color: #808191;
                        }

                        .author-details-alignment {
                            display: flex;
                            align-items: center;
                            gap: 4px;

                            .author-img-alignment {
                                width: 20px;
                                height: 20px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 20px;
                                    object-fit: cover;
                                }
                            }

                            p {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 500;
                                font-size: 13px;
                                line-height: 20px;
                                color: var(--secondary-900);
                                transition: 0.5s ease-in-out;

                                &:hover {
                                    text-decoration: underline;
                                    cursor: pointer;
                                }
                            }
                        }
                    }

                    .diy-room-all-details-alignment {
                        padding: 32px 0 0 0;

                        @media only screen and (max-width:"1099px") {
                            padding: 16px 0 0 0;
                        }

                        span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 20px;
                            color: #495057;
                        }

                        .diy-room-hignt-bg-alignment {

                            position: relative;

                            &::before {
                                content: '';
                                position: absolute;
                                background: linear-gradient(180deg, rgba(238, 241, 253, 0) 0%, var( --primary-50) 100%);
                                width: 100%;
                                height: 110px;
                                bottom: 0;
                                right: 0;

                                @media only screen and (max-width:"768px") {
                                    display: none;

                                }
                            }

                            .diy-room-hight-alignment {
                                height: auto;
                                max-height: 280px;
                                overflow-y: auto;

                                @media only screen and (max-width:"768px") {
                                    height: 100%;
                                    max-height: 100%;
                                }

                                .diy-room-all-details-flex-alignment {
                                    display: flex;
                                    align-items: flex-start;
                                    justify-content: space-between;
                                    margin: 16px 0 0 0;
                                    cursor: pointer;

                                    &:hover {
                                        transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

                                        transform: translateY(-6px);
                                    }

                                    .diy-room-product-details-alignment {
                                        display: flex;
                                        align-items: center;
                                        gap: 16px;

                                        .diy-room-product-img-alignment {
                                            width: 72px;
                                            height: 72px;

                                            img {
                                                width: 100%;
                                                height: 100%;
                                                border-radius: 8px;
                                                object-fit: cover;
                                            }
                                        }

                                        .diy-room-product-child-details-alignment {
                                            h6 {
                                                font-family: 'Poppins', sans-serif;
                                                font-weight: 400;
                                                font-size: 16px;
                                                line-height: 24px;
                                                color: var(--secondary-900);
                                                white-space: nowrap;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                max-width: 245px;
                                            }

                                            .diy-room-rate-alignment {
                                                display: flex;
                                                align-items: center;
                                                gap: 2px;

                                                span {
                                                    font-family: 'Poppins', sans-serif;
                                                    font-weight: 500;
                                                    font-size: 13px;
                                                    line-height: 20px;
                                                    color: #808191;
                                                }

                                                .diy-room-starIcon-alignment {
                                                    display: flex;
                                                    align-items: center;
                                                    gap: 1.5px;
                                                }

                                            }

                                            .diy-rrom-price-alignment {
                                                padding: 2px 0 0 0;

                                                p {
                                                    font-family: 'Poppins', sans-serif;
                                                    font-weight: 500;
                                                    font-size: 14px;
                                                    line-height: 21px;
                                                    color: var(--secondary-900);
                                                }
                                            }
                                        }
                                    }

                                    .diy-room-other-option-alignment {
                                        display: flex;
                                        align-items: center;
                                        gap: 16px;

                                        @media only screen and (max-width:"768px") {
                                            display: none;
                                        }

                                        img {
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }
    }

}