.CollectionByProductBox {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 5.06033182144165px 50.60331726074219px 0px rgba(144, 143, 143, 0.1);
  width: 100%;
  height: calc(100vh - 96px);

  overflow-y: auto;

  @media only screen and (max-width: '767px') {
    height: auto;
    padding: 0;
  }

  .bookMarkProductMainFlexAlignment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px 32px 0 32px;

    @media only screen and (max-width: '767px') {
      padding: 16px 16px 8px 16px;
    }

    .bookMarkModalProductNameAlignment {
      display: flex;
      align-items: center;
      gap: 8px;

      @media only screen and (max-width: '767px') {
        gap: 4px;
      }

      h6 {
        color: var(--bookmark-heading-color);
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-weight: 600;
        line-height: 30px;

        @media only screen and (max-width: '767px') {
          max-width: 174px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .bookmarkProductNumber {
        padding: 0 8px;
        border-radius: 10px;
        background: var(--secondary-50);

        span {
          font-family: 'Poppins', sans-serif;
          font-size: 13px;
          font-weight: 500;
          line-height: 20px;
          color: var(--secondary-500);

          @media only screen and (max-width: '767px') {
            display: none;
          }
        }
      }

      .bookMarkHeaderOption {
        display: flex;
        align-items: center;
        gap: 8px;

        .editIcon {
          @media only screen and (max-width: '767px') {
            display: none;
          }
        }

        svg {
          cursor: pointer;
        }

        .publicPrivateDropdownAlignment {
          padding: 4px 8px;
          display: flex;
          align-items: center;
          gap: 2px;
          transition: 0.4s ease-in-out;
          position: relative;

          @media only screen and (max-width: '767px') {
            padding: 7px 11px;

            svg {
              width: 16px;
              height: 16px;
            }
          }

          .dropdownIcon {
            width: 16px;
            height: 16px;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
              display: block;
            }

            svg {
              transform: rotate(0deg);
              transition: 0.4s ease-in-out;
            }
          }

          .dropdownAllDetails {
            border-radius: 16px;
            background: var(--white);
            box-shadow: 0px 0px 8px 0px rgba(30, 36, 50, 0.08);
            position: absolute;
            top: 42px;
            left: 0;
            width: 152px;
            height: 98px;
            z-index: 99;

            &.openStatusDropdown {
              max-height: 96px;
              overflow-y: hidden;
              transition: 0.4s ease-in-out;
              cursor: pointer;
              z-index: 99999;
            }

            &.closeStatusDropdown {
              max-height: 0px;
              overflow-y: hidden;
              transition: 0.4s ease-in-out;
            }

            ul {
              li {
                padding: 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .optionNameFlex {
                  display: flex;
                  align-items: center;
                  gap: 8px;

                  p {
                    color: var(--secondary-500);
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 21px;
                  }

                  svg {
                    path {
                      stroke: var(--secondary-500);
                    }
                  }
                }

                .rightSignAlignment {
                  width: 24px;
                  height: 24px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                }

                &:first-child {
                  padding: 16px 16px 8px 16px;
                }

                &:last-child {
                  padding: 8px 16px 16px 16px;
                }

                &.activeOption {
                  background: var(--primary-50);

                  &:first-child {
                    border-radius: 16px 16px 0 0;
                  }

                  &:last-child {
                    border-radius: 0px 0px 16px 16px;
                  }

                  svg {
                    path {
                      stroke: var(--secondary-900);
                    }
                  }

                  p {
                    color: var(--secondary-900);
                  }
                }
              }
            }
          }

          &.activeProfile {
            transition: 0.4s ease-in-out;
            border-radius: 8px;
            background: #eef1fc;

            svg {
              path {
                stroke: #1e2432;
              }
            }

            .dropdownIcon {
              svg {
                transform: rotate(-180deg);
                transition: 0.4s ease-in-out;
              }
            }
          }
        }
      }

      .bookmarkEditAlignment {
        cursor: pointer;

        @media only screen and (max-width: '767px') {
          display: none;
        }
      }
    }
    .doneDetails {
      a {
        color: var(--primary-500);
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
      }
    }

    .bookMarkModalSideOption {
      display: flex;
      align-items: center;
      gap: 8px;

      .optionBox {
        width: 32px;
        height: 32px;
        cursor: pointer;

        &.webViewIcon {
          @media only screen and (max-width: '767px') {
            display: none;
          }
        }

        &.mobileViewIcon {
          display: none;

          @media only screen and (max-width: '767px') {
            display: block;
          }
        }
      }
    }
  }

  .bookMarkProductEmptyCartDetails {
    // height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 16px;
    // background: #F8F8F8;
    // margin: 16px 0 0 0;
    height: calc(100vh - 200px);

    @media only screen and (max-width: '767px') {
      height: calc(100vh - 250px);
      border-radius: 16px;
      background: var(--primary-50);
    }

    .bookMarkMobileViewImg {
      width: 90px;
      height: 58px;
      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: contain;
      }
    }

    p {
      color: var(--violet-300);
      text-align: center;
      font-size: 12px;
      font-style: italic;
      font-weight: 400;
      line-height: 18px;

      @media only screen and (max-width: '767px') {
        font-size: 12px;
        line-height: 21px;
        max-width: 213px;
      }
    }
  }

  .bookMarkAllProductDetailsAlignment {
    padding: 0 24px;

    @media only screen and (max-width: '767px') {
      padding: 8px 0 16px 0;
    }

    .bookMarkSecondCollectionDetailsGridAlignment {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px 16px;
    }
  }

  .bookmarkAllProductNumberAlignment {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 2px 32px 18px 32px;

    @media only screen and (max-width: '767px') {
      padding: 2px 16px 18px 16px;
    }
    span {
      color: var(--secondary-500);
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }

    p {
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background: var(--secondary-300);
    }
  }
}

//   padding: 48px 0 32px 0;

//   @media only screen and (max-width: '767px') {
//     padding: 40px 0 32px 0;
//   }

//   .bookMarkModalCollectionHeader {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;

//     .bookMarkModalCollectionNameAlignment {
//       display: flex;
//       align-items: center;
//       gap: 10px;

//       h6 {
//         color: var(--bookmark-heading-color);
//         font-family: 'Poppins', sans-serif;
//         font-size: 20px;
//         font-style: normal;
//         font-weight: 600;
//         line-height: 30px;
//       }

//       .bookmarkCollectionNumber {
//         padding: 0 8px;
//         border-radius: 10px;
//         background: var(--secondary-50);

//         @media only screen and (max-width: '767px') {
//           display: none;
//         }

//         span {
//           font-family: 'Poppins', sans-serif;
//           font-size: 13px;
//           font-weight: 500;
//           line-height: 20px;
//           color: var(--secondary-500);
//         }
//       }
//     }

//     .bookMarkWebVIewAlignment {
//       @media only screen and (max-width: '767px') {
//         display: none;
//       }

//       a {
//         padding: 6px 16px;
//         color: var(--primary-500);
//         font-family: 'Poppins', sans-serif;
//         font-size: 15px;
//         font-weight: 500;
//         line-height: 20px;
//         cursor: pointer;
//       }
//     }

//     .bookMarkMobileVIewAlignment {
//       display: none;

//       @media only screen and (max-width: '767px') {
//         display: block;
//       }

//       a {
//         padding: 6px 16px;
//         color: var(--primary-500);
//         font-family: 'Poppins', sans-serif;
//         font-size: 15px;
//         font-weight: 500;
//         line-height: 20px;
//         cursor: pointer;
//       }
//     }
//   }

//   .bookMarkModalCollectionDetails {
//     padding: 38px 0 0 0;

//     @media only screen and (max-width: '767px') {
//       padding: 16px 0 0 0;
//     }

//     .bookMarkModalCollectionEmptyDetails {
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       flex-direction: column;
//       gap: 16px;
//       border-radius: 16px;
//       background: #f8f8f8;
//       height: 260px;

//       @media only screen and (max-width: '767px') {
//         height: 145px;
//         gap: 8px;
//       }

//       .bookMarkModalCollectionEmptyImg {
//         svg {
//           @media only screen and (max-width: '767px') {
//             width: 56px;
//           }
//         }
//       }

//       p {
//         color: var(--secondary-300);
//         font-family: 'Poppins', sans-serif;
//         font-size: 16px;
//         font-style: italic;
//         font-weight: 400;
//         line-height: 24px;

//         @media only screen and (max-width: '767px') {
//           font-size: 14px;
//           line-height: 21px;
//         }
//       }
//     }

//     .bookMarkModalCollectionAllDetails {
//       .bookMarkModalCollectionAllDetailsSlider {
//         position: relative;

//         &::before {
//           content: '';
//           background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 98.27%);
//           position: absolute;
//           right: 0;
//           width: 120px;
//           height: 100%;
//           z-index: 9;

//           @media only screen and (max-width: '767px') {
//             display: none;
//           }
//         }

//         .bookMarkCollectionCardProductGrid {
//           display: grid;
//           grid-template-columns: repeat(4, 1fr);
//           gap: 80px;

//           @media only screen and (max-width: '767px') {
//             grid-template-columns: repeat(2, 1fr);
//             gap: 32px;

//             @media only screen and (max-width: '767px') {
//               gap: 32px 60px;
//               padding: 0 32px 0 20px;
//             }
//           }
//         }

//         .bookMarkModalCollectionCardPadding {
//           padding: 32px 40px 0 40px;

//           &.bookMarkModalCollectionPaddingZero {
//             padding: 0;
//           }

//           .bookMarkCollectionCardDetails {
//             position: relative;
//             width: 100%;
//             height: 200px;

//             @media only screen and (max-width: '767px') {
//               height: 126px;
//             }

//             .bookMarkCollectionCardImg {
//               width: 200px;
//               height: 200px;
//               border-radius: 16px;
//               border: 6px solid #fff;
//               background: #fff;
//               box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
//               transform: rotate(-6.983deg);

//               @media only screen and (max-width: '767px') {
//                 border: 3px solid #fff;
//                 border-radius: 10px;
//               }

//               img {
//                 width: 100%;
//                 height: 100%;
//                 display: block;
//                 border-radius: 16px;
//                 object-fit: cover;

//                 @media only screen and (max-width: '767px') {
//                   border-radius: 10px;
//                 }
//               }

//               &:nth-child(2) {
//                 position: absolute;
//                 top: 0;
//                 left: 10px;
//                 transform: rotate(0);

//               }
//               &:nth-child(3) {
//                 position: absolute;
//                 top: 0;
//                 left: 20px;
//                 transform: rotate(5.357deg);
//               }
//             }

//             .bookMarkCollectionCardImg2 {
//               width: 100%;
//               height: 100%;
//               border-radius: 16px;
//               border: 6px solid #fff;
//               background: #fff;
//               box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
//               position: absolute;
//               top: 0;
//               left: 10px;

//               @media only screen and (max-width: '767px') {
//                 border: 3px solid #fff;
//                 border-radius: 10px;
//               }

//               img {
//                 width: 100%;
//                 height: 100%;
//                 display: block;
//                 border-radius: 16px;
//                 object-fit: cover;

//                 @media only screen and (max-width: '767px') {
//                   border-radius: 10px;
//                 }
//               }
//             }

//             .bookMarkCollectionCardImg3 {
//               width: 100%;
//               height: 100%;
//               border-radius: 16px;
//               border: 6px solid #fff;
//               background: #fff;
//               box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
//               position: absolute;
//               top: 0;
//               left: 20px;
//               transform: rotate(5.357deg);

//               @media only screen and (max-width: '767px') {
//                 border: 3px solid #fff;
//                 border-radius: 10px;
//               }

//               img {
//                 width: 100%;
//                 height: 100%;
//                 display: block;
//                 border-radius: 16px;
//                 object-fit: cover;

//                 @media only screen and (max-width: '767px') {
//                   border-radius: 10px;
//                 }
//               }
//             }
//           }

//           .bookMarkCollectionProductNameDetailsAlignment {
//             padding: 18px 0 0 0;

//             @media only screen and (max-width: '767px') {
//               padding: 8px 0 0 0;
//             }

//             h6 {
//               color: var(--secondary-900);
//               text-align: center;
//               font-family: 'Poppins', sans-serif;
//               font-size: 16px;
//               font-weight: 500;
//               line-height: 24px;

//               @media only screen and (max-width: '767px') {
//                 font-size: 14px;
//                 line-height: 21px;
//               }
//             }

//             p {
//               padding: 2px 0 0 0;
//               color: var(--secondary-secondary-500, #7a7e88);
//               text-align: center;
//               font-family: 'Poppins', sans-serif;
//               font-size: 13px;
//               font-weight: 20px;
//               line-height: normal;

//               @media only screen and (max-width: '767px') {
//                 font-size: 12px;
//                 line-height: 14px;
//               }
//             }
//           }
//         }

//         :global(.slick-next) {
//           right: 8px;
//           z-index: 99;
//         }

//         :global(.slick-prev) {
//           left: 8px;
//           z-index: 99;
//         }

//         .mobileViewBookMarkMoreDetailsAlignment {
//           display: none;

//           @media only screen and (max-width: '767px') {
//             display: block;
//           }

//           .mobileViewBookMarkMoreDetailsgrid {
//             display: grid;
//             grid-template-columns: repeat(2, 1fr);
//             gap: 8px;

//             .mobileViewNoImg {
//               width: 50px;
//               height: 46px;

//               svg {
//                 width: 100%;
//                 height: 100%;
//               }
//             }

//             .mobileViewBookMarkUploadedImg {
//               position: relative;
//               width: 50px;
//               height: 46px;

//               .mobileViewbookMarkCollectionCardFirstImg {
//                 width: 100%;
//                 height: 100%;
//                 border-radius: 4px;
//                 border: 1px solid #fff;
//                 background: #fff;
//                 box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
//                 transform: rotate(-6.983deg);

//                 img {
//                   width: 100%;
//                   height: 100%;
//                   display: block;
//                   border-radius: 4px;
//                   object-fit: cover;
//                 }
//               }

//               .mobileViewbookMarkCollectionCardSecondImg {
//                 width: 100%;
//                 height: 100%;
//                 border-radius: 4px;
//                 border: 1px solid #fff;
//                 background: #fff;
//                 box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
//                 position: absolute;
//                 top: 0;
//                 left: 3px;

//                 img {
//                   width: 100%;
//                   height: 100%;
//                   display: block;
//                   border-radius: 4px;
//                   object-fit: cover;
//                 }
//               }

//               .mobileViewbookMarkCollectionCardThirdImg {
//                 width: 100%;
//                 height: 100%;
//                 border-radius: 4px;
//                 border: 1px solid #fff;
//                 background: #fff;
//                 box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
//                 position: absolute;
//                 top: 0;
//                 left: 6px;
//                 transform: rotate(5.357deg);

//                 img {
//                   width: 100%;
//                   height: 100%;
//                   display: block;
//                   border-radius: 4px;
//                   object-fit: cover;
//                 }
//               }
//             }
//           }

//           .bookmarkSeeMoreAlignment {
//             padding: 28px 0 0 0;
//             text-align: center;

//             a {
//               color: var(--primary-500);
//               text-align: center;
//               font-family: 'Poppins', sans-serif;
//               font-size: 14px;
//               font-weight: 500;
//               line-height: 21px;
//             }
//           }
//         }
//       }
//     }

//     :global(.slick-prev) {
//       left: 21px;
//       z-index: 99;
//     }

//     :global(.slick-next) {
//       right: 21px;
//       z-index: 999;
//     }

//     :global(.slick-arrow:hover) {
//       background: #fff !important;
//     }
//   }

//   .mobileViewAddNewCollectionModalAlignment {
//     .mobileViewAddNewCollectionModalWrapper {
//       position: fixed;
//       width: 100%;
//       top: 0;
//       left: 0;
//       height: 100vh;
//       background: rgba(30, 36, 50, 0.6);
//       z-index: 999;
//       padding: 16px;
//       display: flex;
//       align-items: center;
//       justify-content: center;

//       .mobileViewAddNewCollectionBox {
//         border-radius: 16px;
//         background: #fff;
//         width: 100%;
//         padding: 18px 14px 32px 14px;

//         .mobileViewAddNewHeading {
//           display: flex;
//           align-items: center;
//           justify-content: space-between;

//           h6 {
//             color: var(--secondary-900);
//             font-family: 'Poppins', sans-serif;
//             font-size: 24px;
//             font-style: normal;
//             font-weight: 600;
//             line-height: 36px;
//           }

//           .mobileCloseIconAlignment {
//             svg {
//               cursor: pointer;
//             }
//           }
//         }

//         .mobileViewBodyDetailsAlignment {
//           padding: 32px 0 0 0;

//           .mobileViewInputAlignment {
//             label {
//               margin: 0 0 0 8px;
//               color: #495057;
//               font-family: 'Poppins', sans-serif;
//               font-size: 13px;
//               font-weight: 400;
//               line-height: 19px;
//             }

//             .mobileViewInput {
//               width: 100%;
//               height: 40px;
//               border-radius: 10px;
//               border: 1px solid var(--secondary-50);
//               background: var(--secondary-50);
//               margin: 4px 0 0 0;
//               padding: 0 16px;

//               input {
//                 width: 100%;
//                 height: 100%;
//                 background: transparent;
//                 border: none;
//                 color: var(--secondary-900);
//                 font-family: 'Poppins', sans-serif;
//                 font-size: 16px;
//                 font-weight: 400;
//                 line-height: 24px;

//                 &::placeholder {
//                   color: var(--secondary-500);
//                 }
//               }
//             }
//           }
//         }

//         .mobileViewButtonAlignment {
//           padding: 32px 0 0 0;

//           button {
//             border-radius: 10px;
//             color: #fff;
//             text-align: center;
//             font-family: 'Poppins', sans-serif;
//             font-size: 18px;
//             font-weight: 500;
//             line-height: 28px;
//             width: 100%;
//             height: 44px;
//             background: var(--primary-500);

//             &.disableButton {
//               background: var(--primary-100);
//             }
//           }
//         }
//       }
//     }
//   }
// }

.bookMarkProductEmptyCartDetails {
  // height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  // background: #F8F8F8;
  // margin: 16px 0 0 0;
  height: calc(100vh - 200px);

  @media only screen and (max-width: '767px') {
    height: calc(100vh - 250px);
    border-radius: 16px;
    background: var(--primary-50);
  }

  .bookMarkMobileViewImg {
    width: 90px;
    height: 58px;
    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: contain;
    }
  }

  p {
    color: var(--violet-300);
    text-align: center;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 18px;

    @media only screen and (max-width: '767px') {
      font-size: 12px;
      line-height: 21px;
      max-width: 213px;
    }
  }
}


.allBookMarkDetailsBodyAlignment {
  padding: 12px;

  .allBookMarkDetailsGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    @media only screen and (max-width: '1024px') {
      grid-template-columns: repeat(3, 1fr);
    }
    @media only screen and (max-width: '699px') {
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
    }
  }

  .noDataBookMarkCollectionDetails {
    height: 209px;
    border-radius: 16px;
    background: var(--primary-50);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
 
    p {
      color: var(--violet-300);
      text-align: center;
      font-size: 12px;
      font-style: italic;
      font-weight: 400;
      line-height: 18px;
    }
  }
}