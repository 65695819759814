:root {
  --white: rgb(255, 255, 255);
  --black: #000000;
  --light-grey: #f8f8f8;
  --light-grey-100: #f1f1f5;
  --light-grey-2: #fafafb;
  --light-grey-3: #eef1fc;
  --secondary-900: #1e2432;
  --secondary-500: #7a7e88;
  --secondary-600: #636773;
  --secondary-700: #4c515d;
  --secondary-50: #eceef4;
  --secondary-800: #353a48;
  --secondary-100: #d5d8de;
  --secondary-200: #bec1c9;
  --secondary-400: #90949e;
  --grey2: #808191;
  --grey3: #acb1c0;
  --grey4: #e4e4e8;
  --grey5: #495057;
  --grey6: #92929d;
  // --grey6: #A7ABB3;
  --secondary-300: #a7abb3;

  --primary-500: #556ee6;
  --primary-600: #4d64d1;
  --primary-700: #3c4ea3;
  --primary-800: #2f3d7f;
  --primary-900: #242e61;
  --primary-50: #eef1fd;
  --primary-100: #cad2f7;
  --primary-200: #b1bcf4;
  --primary-300: #8d9eee;
  --primary-400: #778beb;
  --primary-1: #eeeff2;

  --perple-1: #5f40af;

  --green-500: #1d8603;
  --green-50: #e8f3e6;
  --green-800: #104a02;
  --green-400: #4a9e35;

  --green-1: #70c217;

  --orange-600: #e87220;
  --orange-50: #fff2e9;
  --orange-400: #ff974f;

  --red-400: #ec0000;
  --red-500: #e71616;
  --red-600: #d21414;
  --red-50: #fde8e8;

  --yellow-50: #fffaea;
  --yellow-900: #6b5712;
  --yellow-800: #474400;
  --yellow-500: #ffd02c;
  --yellow-400: #ffd956;
  --yellow-300: #f1efb0;

  --blue-1: #38bdf5;

  --violet-50: #f0e9f6;
  --violet-200: #bb99d7;
  --violet-300: #9c6ac5;
  --violet-400: #894db9;
  --violet-600: #611e99;
  --violet-800: #3b125c;

  --white: #ffffff;

  --light-blue: #b1c2f3;

  --grey-border: #e3e3e3;

  --neutral-600: #6f6c90;
  --neutral-800: #170f49;

  --bookmark-heading-color: #171725;

  --white-6: rgba(255, 255, 255, 0.6);
  --black-6: rgba(30, 36, 50, 0.6);
  --header-bg: rgba(248, 248, 248, 0.92);

  --white-08: rgba(255, 255, 255, 0.8);

  --black-2: #060606;
  
}