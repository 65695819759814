.closeAddProdutModal {
    max-height: 0px;
    overflow-y: hidden;
    transition: 0.4s ease-in-out;
    // transform: scale(0);

    padding: 0 14px 0 32px;

}

.addProdutcMain {
    position: absolute;
    top: 40px;
    left: 16px;
    width: calc(100% - 32px);
    z-index: 99999999;
    padding: 0 8px 0 0;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    // border: 1px solid #FFF;
    background: #FFF;
    // transform-origin: left;

    transition: 0.4s ease-in-out;



}

.addProductFilterAllDetailsBoxAlignment {


    padding: 0 14px 32px 32px;

    &.openAddProdutModal {
        transform: scale(1);
        transition: 0.4s ease-in-out;
        max-height: 380px;
        // transform-origin: left;

        overflow-y: auto;
    }



    &::-webkit-scrollbar {
        width: 2px !important;
        display: block;
    }

    &::-webkit-scrollbar-track {
        background: transparent !important;
    }

    &::-webkit-scrollbar-thumb {
        display: block;
        background: #D9D9D9 !important;
        border-radius: 18px !important;
    }

}
