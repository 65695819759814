.customerOrderDetailsModalAlignment {
    .customerOrderDetailsModalBg {
        background: var(--white);
        border-radius: 10px;
        padding: 32px;
        height: calc(100vh - 98px);
        overflow-y: auto;

        .customerOrderIdDetails {
            display: flex;
            align-items: center;
            gap: 16px;

            h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: var(--secondary-900);
            }

            p {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--secondary-900);
            }
        }

        .customerOrderDetailsAlignment {
            margin: 26px 0 0 0;
            padding: 32px;
            background: #FFFFFF;
            box-shadow: 0px 5px 50px rgba(144, 143, 143, 0.1);
            border-radius: 8px;

            .customerOrderDetailsFlex {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .customerOrderProductDetails {
                    display: flex;
                    align-items: flex-start;
                    gap: 16px;

                    .customerOrderProductImg {
                        width: 147px;
                        min-width: 147px;
                        max-width: 147px;
                        height: 204px;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 8px;
                            object-fit: cover;
                        }
                    }

                    .customerOrderProductChildDetails {
                        h5 {
                            max-width: 350px;
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 30px;
                            color: var(--secondary-900);

                        }

                        span {
                            display: block;
                            padding: 4px 0 0 0;
                            font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 20px;
                            color: var(--secondary-600);
                        }

                        .orderProductOtherDetails {
                            padding: 16px 0 0 0;

                            .orderProductOtherNamesDetails {
                                display: flex;
                                align-items: center;
                                gap: 32px;

                                p {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 600;
                                    font-size: 13px;
                                    line-height: 20px;
                                    color: var(--secondary-600);
                                    max-width: 36px;
                                    width: 36px;
                                }

                                span {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 400;
                                    font-size: 13px;
                                    line-height: 20px;
                                    color: var(--secondary-600);
                                }
                            }
                        }

                        .viewProductOption {
                            padding: 16px 0 0 0;

                            a {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 20px;
                                color: var(--primary-500);
                                cursor: pointer;
                            }
                        }
                    }
                }

                .customerRightSideDetails {
                    height: 209px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .customerDeliverAlignment {
                        width: fit-content;
                        background: var(--green-50);
                        border-radius: 16px;
                        padding: 3px 10px;

                        span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 20px;
                            color: var(--green-500);
                        }
                    }

                    p {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 30px;
                        color: var(--secondary-900);

                    }
                }
            }
        }

        .customerOrderShippingDetailsAlignment {
            padding: 16px 0 0 0;

            .customerOrderShippingGrid {
                display: grid;
                grid-template-columns: 1fr 373px;
                gap: 16px;

                .customerOrderShippingGridItem {
                    .customerOrderShippingBox {
                        background: var(--white);
                        box-shadow: 0px 5px 50px rgba(144, 143, 143, 0.1);
                        border-radius: 8px;
                        padding: 16px;

                        .shippingHeading {
                            h6 {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 600;
                                font-size: 20px;
                                line-height: 30px;
                                color: var(--secondary-900);
                            }

                        }

                        .shippingAddressAndCarrierDetails {
                            padding: 16px 0 0 0;

                            .shippingAddressAndCarrierGrid {
                                display: grid;
                                grid-template-columns: 1fr 155px;
                                gap: 16px;

                                .shippingAddressAndCarrierGridItem {
                                    background: var(--light-grey);
                                    border-radius: 4px;
                                    padding: 16px;

                                    .shippingAddressDetailsAlignment {
                                        h6 {
                                            font-family: 'Poppins', sans-serif;
                                            font-weight: 600;
                                            font-size: 13px;
                                            line-height: 20px;
                                            color: var(--secondary-900);
                                        }

                                        p {
                                            padding: 16px 0 0 0;
                                            font-family: 'Poppins', sans-serif;
                                            font-weight: 400;
                                            font-size: 13px;
                                            line-height: 20px;
                                            color: var(--secondary-600);
                                            width: 263px;
                                        }

                                        span {
                                            padding: 4px 0 0 0;
                                            font-family: 'Poppins', sans-serif;
                                            font-weight: 400;
                                            font-size: 13px;
                                            line-height: 20px;
                                            color: var(--secondary-600);
                                        }
                                    }

                                    .carrierAlignment {
                                        h6 {
                                            font-family: 'Poppins', sans-serif;
                                            font-weight: 600;
                                            font-size: 13px;
                                            line-height: 20px;
                                            color: var(--secondary-900);
                                        }

                                        .carrierLogo {
                                            padding: 32px 0 0 0;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                        }

                                    }
                                }
                            }

                            .shippingTimeLineAlignment {
                                padding: 16px 0 0 0;

                                .shippingTimeLineBoxAlignment {
                                    background: var(--light-grey);
                                    border-radius: 4px;
                                    padding: 16px;

                                    .shippingTimeLineHeadingAlignment {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;

                                        h6 {
                                            font-family: 'Poppins', sans-serif;
                                            font-weight: 600;
                                            font-size: 13px;
                                            line-height: 20px;
                                            color: var(--secondary-900);
                                        }

                                        .trakingIDAlignment {
                                            display: flex;
                                            align-items: center;
                                            gap: 8px;

                                            p {
                                                font-family: 'Poppins', sans-serif;
                                                font-weight: 400;
                                                font-size: 13px;
                                                line-height: 20px;
                                                color: var(--secondary-900);
                                            }
                                        }
                                    }

                                    .shippingTimeTrackDetailsAlignment {
                                        padding: 16px 0 0 0;
                                        height: 289px;
                                        overflow-y: auto;

                                        .shippingTimeTrackDetailsAlignment {
                                            position: relative;

                                            .shippingTimeTrackGrid {
                                                display: grid;
                                                grid-template-columns: 24px 1fr;
                                                gap: 24px;

                                                .shippingTimeTrackGridItem {
                                                    .shippingTimeTrackBox {
                                                        width: 24px;
                                                        height: 24px;
                                                        border-radius: 50%;
                                                        background: #D9D9D9;
                                                    }

                                                    .trackLineAlignment {
                                                        border-right: 2px dashed #D9D9D9;
                                                        height: 76px;
                                                        position: relative;
                                                        left: -12px;
                                                    }

                                                    .shippingTimeDetailsAlignment {
                                                        .shippingTimeAndDateAlignment {
                                                            display: flex;
                                                            align-items: center;
                                                            gap: 14px;

                                                            p {
                                                                font-family: 'Poppins', sans-serif;
                                                                font-weight: 400;
                                                                font-size: 13px;
                                                                line-height: 20px;
                                                                color: var(--grey2);
                                                            }
                                                        }

                                                        h6 {
                                                            max-width: 367px;
                                                            font-family: 'Poppins', sans-serif;
                                                            font-weight: 500;
                                                            font-size: 16px;
                                                            line-height: 24px;
                                                            color: var(--secondary-900);

                                                        }
                                                    }
                                                }
                                            }

                                            .shippingTrackBottom {
                                                position: sticky;
                                                bottom: 0;
                                                left: 0;
                                                background: linear-gradient(180deg, rgba(248, 248, 248, 0) 0%, #F8F8F8 100%);
                                                border-radius: 4px;
                                                width: 100%;
                                                height: 56px;
                                                z-index: 99;
                                            }
                                        }

                                    }
                                }
                            }
                        }
                    }

                    .rightPartAlignment {
                        .customerBoxAlignment {
                            background: #FFFFFF;
                            box-shadow: 0px 5px 50px rgba(144, 143, 143, 0.1);
                            border-radius: 8px;
                            padding: 18px 16px 16px 16px;
                            margin: 0 0 16px 0;

                            h5 {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 600;
                                font-size: 20px;
                                line-height: 30px;
                                color: var(--secondary-900);
                            }

                            .customerProfileDetailsAlignment {
                                display: flex;
                                align-items: center;
                                gap: 8px;
                                padding: 16px 0 0 0;

                                .customerProfileImg {
                                    width: 32px;
                                    height: 32px;
                                    min-width: 32px;
                                    max-width: 32px;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        border-radius: 50%;
                                        object-fit: cover;
                                    }
                                }

                                h6 {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 400;
                                    font-size: 16px;
                                    line-height: 24px;
                                    color: var(--black);
                                }
                            }
                        }

                        .paymentBoxAlignment {
                            background: #FFFFFF;
                            box-shadow: 0px 5px 50px rgba(144, 143, 143, 0.1);
                            border-radius: 8px;
                            padding: 18px 16px 16px 16px;
                            margin: 0 0 16px 0;

                            h5 {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 600;
                                font-size: 20px;
                                line-height: 30px;
                                color: var(--secondary-900);
                            }

                            .paymentProfileDetailsAlignment {
                                display: flex;
                                align-items: center;
                                gap: 32px;
                                padding: 16px 16px 0 16px;

                                p {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 21px;
                                    color: var(--secondary-900);
                                    padding: 0 0 2px 0;
                                }

                                span {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 21px;
                                    color: var(--grey2);
                                }
                            }
                        }

                        .orderSummaryAlignment {
                            background: #FFFFFF;
                            box-shadow: 0px 5px 50px rgba(144, 143, 143, 0.1);
                            border-radius: 8px;
                            padding: 18px 16px 12px 16px;

                            h5 {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 600;
                                font-size: 20px;
                                line-height: 30px;
                                color: var(--secondary-900);
                            }

                            .orderSummaryInvoiceDetailsAlignment {
                                padding: 12px 0 0 0;

                                .orderSummaryAllChildDetailsAlignment {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    border-bottom: 1px solid #E4E4E8;
                                    padding: 8px 0;

                                    // &:first-child {
                                    //     padding: 0 0 8px 0;
                                    // }

                                    // &:last-child {
                                    //     padding: 8px 0 0 0;
                                    // }

                                    p {
                                        font-family: 'Poppins', sans-serif;
                                        font-weight: 400;
                                        font-size: 13px;
                                        line-height: 20px;
                                        color: var(--grey2);
                                    }

                                    span {
                                        font-family: 'Poppins', sans-serif;
                                        font-weight: 500;
                                        font-size: 13px;
                                        line-height: 20px;
                                        color: var(--secondary-900);
                                    }
                                }

                                .orederSummaryTotalDetailsAlignment {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    padding: 8px 0 0 0;

                                    h6 {
                                        font-family: 'Poppins', sans-serif;
                                        font-weight: 500;
                                        font-size: 20px;
                                        line-height: 30px;
                                        color: var(--grey2);
                                    }

                                    p {
                                        font-family: 'Poppins', sans-serif;
                                        font-weight: 600;
                                        font-size: 20px;
                                        line-height: 30px;
                                        color: var(--secondary-900);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}