.feed-mobileSellerCommentPostAlignment {
  @apply bg-white rounded-lg shadow-sm p-4 mb-4;
}

.feed-mobileSellerCommentBox {
  @apply flex flex-col;

  h6 {
    @apply text-lg font-semibold mb-4;
  }
}

.feed-mobileSellerPostBoxAlignment {
  @apply flex items-start gap-4;
}

.feed-mobileSellerPostImgAlignment {
  @apply w-8 h-8 overflow-hidden rounded-full flex-shrink-0;
  
  img {
    @apply w-full h-full object-cover;
  }
}

.postImgAlignment {
  @apply w-10 h-10 overflow-hidden rounded-full flex-shrink-0;
}

.postImg {
  @apply w-full h-full object-cover;
}

.post-header {
  @apply flex items-center gap-3 px-4 pt-2 pb-3;

  .avatar {
    @apply flex-shrink-0 w-10 h-10 rounded-full overflow-hidden;
    
    img {
      @apply w-full h-full object-cover;
    }
  }

  .audience-selector {
    @apply flex items-center gap-2 px-3 py-1.5 rounded-full text-sm font-medium;
    background-color: #f3f4f6;
    color: #4b5563;
    border: 1px solid #e5e7eb;

    .icon {
      @apply w-4 h-4;
    }

    .chevron {
      @apply w-4 h-4 text-gray-400;
    }
  }
}

.post-input {
  @apply px-4 pb-4;

  textarea {
    @apply w-full resize-none bg-transparent p-4 rounded-xl;
    min-height: 120px;
    background-color: #f8f9fa;
    border: 1px solid #e5e7eb;
    
    &::placeholder {
      @apply text-gray-500;
    }
    
    &:focus {
      outline: none;
      border-color: #d1d5db;
    }
  }
}

.feed-mobileSellerInputAlignment {
  @apply flex-1 flex items-center gap-2 bg-gray-50 rounded-lg px-4 py-3;
  border: 1px solid #e5e7eb;
  
  img {
    @apply w-5 h-5;
  }
  
  input {
    @apply flex-1 bg-transparent border-none outline-none text-sm;
    &::placeholder {
      @apply text-gray-500;
    }
  }
}

.media-preview {
  @apply mt-4;

  .media-grid {
    @apply grid gap-2;
    
    &[data-count="1"] {
      grid-template-columns: 1fr;
      
      .preview-item {
        aspect-ratio: 16/9;
      }
    }
    
    &[data-count="2"] {
      grid-template-columns: repeat(2, 1fr);
      
      .preview-item {
        aspect-ratio: 1;
      }
    }
    
    &[data-count="3"] {
      grid-template-columns: repeat(2, 1fr);
      
      .preview-item {
        aspect-ratio: 1;
        
        &:first-child {
          grid-column: span 2;
          aspect-ratio: 16/9;
        }
      }
    }
    
    &[data-count="4"] {
      grid-template-columns: repeat(2, 1fr);
      
      .preview-item {
        aspect-ratio: 1;
      }
    }

    &[data-count="5"] {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
      
      .preview-item {
        aspect-ratio: 1;
        
        &:first-child {
          grid-column: span 2;
          aspect-ratio: 16/9;
        }
      }
    }
  }

  .preview-item {
    @apply relative rounded-xl overflow-hidden;
    background-color: #f3f4f6;
    
    .media-content {
      @apply w-full h-full object-cover;
    }

    &.more-overlay {
      position: relative;
      
      &::after {
        content: '';
        @apply absolute inset-0 bg-black bg-opacity-50;
      }
      
      .more-count {
        @apply absolute inset-0 flex items-center justify-center text-white font-bold text-lg;
        z-index: 1;
      }
    }
    
    .remove-button {
      @apply absolute top-2 right-2 w-8 h-8 flex items-center justify-center rounded-full bg-black bg-opacity-50 text-white text-xl;
      transition: all 0.2s ease;
      
      &:hover {
        @apply bg-opacity-70;
      }
    }
  }
}

.action-buttons {
  @apply flex flex-col gap-3;
  padding: 0 16px 16px;
  border-top: 1px solid #e5e7eb;
  margin-top: 4px;

  .media-buttons {
    @apply flex justify-around border-t border-gray-200;
    padding: 16px 0;
    
    .media-button {
      @apply flex items-center justify-center gap-2 px-3 py-2 rounded-xl transition-all duration-200;
      min-height: 40px;
      
      img {
        @apply w-5 h-5;
      }

      span {
        @apply text-sm font-medium whitespace-nowrap;
      }
      
      &.photo {
        background-color: #fff4e6;
        color: #fcb424;
        
        &:hover {
          background-color: #ffdbb3;
        }
      }
      
      &.emoji {
        background-color: #f3e6f9;
        color: #b414e4;
        
        &:hover {
          background-color: #e6d3f2;
        }
      }
      
      &.gif {
        background-color: #dbeafe;
        color: #2563eb;
        
        &:hover {
          background-color: #bfdbfe;
        }
      }

      &.ai {
        background-color: #ecfdf5;
        color: #059669;
        
        &:hover {
          background-color: #d1fae5;
        }
      }
    }
  }
  
  .post-button {
    @apply w-full py-3 rounded-xl text-white font-medium text-base transition-colors duration-200;
    background: #556EE6;
    
    &:hover:not(:disabled) {
      background: #4055d3;
    }
    
    &:disabled {
      @apply bg-gray-300 cursor-not-allowed;
    }
  }
}

.upload-button {
  @apply w-full flex flex-col items-center justify-center gap-3 p-6 rounded-xl border-2 border-dashed border-gray-300 bg-gray-50 transition-colors duration-200;
  
  .upload-icon {
    @apply w-12 h-12 flex items-center justify-center rounded-full bg-gray-100;
    
    img {
      @apply w-6 h-6 text-gray-500;
    }
  }
  
  p {
    @apply text-gray-600 font-medium;
  }
  
  &:hover {
    @apply bg-gray-100 border-gray-400;
  }
}

.media-options {
  @apply space-y-4;

  .media-option {
    @apply w-full flex items-center gap-4 p-4 rounded-xl transition-colors duration-200;
    
    .option-icon {
      @apply w-12 h-12 flex items-center justify-center rounded-full;
      img {
        @apply w-6 h-6;
      }
    }

    .option-text {
      @apply text-left;
      h3 {
        @apply text-base font-semibold;
      }
      p {
        @apply text-sm text-gray-500;
      }
    }

    &.photo {
      .option-icon {
        background-color: #fff4e6;
        img {
          @apply text-yellow-500;
        }
      }
      &:hover {
        background-color: #fff8f0;
      }
    }

    &.emoji {
      .option-icon {
        background-color: #f3e6f9;
        img {
          @apply text-purple-500;
        }
      }
      &:hover {
        background-color: #f8f0fc;
      }
    }
  }
}

.bottom-sheet {
  @apply fixed bottom-0 left-0 right-0 bg-white shadow-lg;
  z-index: 10001;
  transform: translateY(100%);
  transition: transform 0.3s ease;
  height: 75vh;
  display: flex;
  flex-direction: column;
  border-radius: 20px 20px 0 0;

  &.open {
    transform: translateY(0);
  }

  .bottom-sheet-header {
    @apply sticky top-0 bg-white px-4 py-3 flex items-center justify-between border-b border-gray-200;
    flex-shrink: 0;
    border-radius: 20px 20px 0 0;
    
    .title {
      @apply text-lg font-semibold;
    }
    
    .close-button {
      @apply px-4 py-2 text-gray-500 font-medium;
    }
  }

  .bottom-sheet-content {
    @apply relative flex-1 overflow-y-auto;
    -webkit-overflow-scrolling: touch;
  }
}

.audience-modal {
  @apply absolute inset-0 bg-white;
  z-index: 1;
  
  .modal-header {
    @apply sticky top-0 flex items-center gap-3 px-4 py-3 bg-white border-b border-gray-200;
    
    .back-button {
      @apply flex items-center justify-center w-8 h-8 rounded-full text-gray-500;
      
      img {
        @apply w-5 h-5;
      }
    }
    
    .title {
      @apply text-lg font-semibold;
    }
  }

  .modal-body {
    @apply h-full overflow-y-auto p-4;

    .audience-option {
      @apply flex items-center gap-3 p-4 rounded-lg mb-2;
      
      &.selected {
        @apply bg-blue-50;
      }

      .icon {
        @apply w-6 h-6 text-gray-600;
      }

      .text {
        @apply flex flex-col flex-1;
        
        .title {
          @apply text-base font-medium text-gray-900;
          line-height: 1.25;
        }
        
        .description {
          @apply text-sm text-gray-500;
          line-height: 1.4;
          margin-top: 2px;
        }
      }

      .check {
        @apply w-5 h-5 text-blue-600;
      }
    }
  }
}

.overlay {
  @apply fixed inset-0 bg-black bg-opacity-50;
  z-index: 10000;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;

  &.open {
    opacity: 1;
    pointer-events: auto;
  }
}

.modal-overlay {
  @apply fixed inset-0 bg-black bg-opacity-50 flex items-end;
  z-index: 10002;

  .modal-content {
    @apply w-full bg-white rounded-t-2xl;
    height: 75vh;
    transform: translateY(100%);
    transition: transform 0.3s ease;

    &.open {
      transform: translateY(0);
    }

    .modal-header {
      @apply flex items-center justify-between px-4 py-3 border-b border-gray-200;

      .back-button {
        @apply w-10 h-10 flex items-center justify-center rounded-full text-gray-500;
      }

      .title {
        @apply text-lg font-semibold;
      }

      .close-button {
        @apply w-10 h-10 flex items-center justify-center rounded-full text-gray-500;
      }
    }

    .modal-body {
      @apply p-4;
      height: calc(75vh - 56px); // Subtract header height
      overflow-y: auto;
    }
  }
}

.emoji-picker {
  @apply w-full h-full;
}

.gif-picker {
  .search-input {
    @apply w-full mb-4 px-4 py-2 rounded-lg border border-gray-200;
  }

  .gif-grid {
    @apply grid grid-cols-2 gap-2;
  }
}

.ai-post {
  .prompt-input {
    @apply w-full mb-4;
    
    textarea {
      @apply w-full px-4 py-2 rounded-lg border border-gray-200;
      min-height: 100px;
    }
  }

  .tone-selector {
    @apply mb-4;
    
    select {
      @apply w-full px-4 py-2 rounded-lg border border-gray-200;
    }
  }

  .generate-button {
    @apply w-full py-2 px-4 rounded-lg bg-blue-600 text-white font-medium;
    
    &:disabled {
      @apply bg-gray-300;
    }
  }
}

.emoji-picker-container {
  @apply absolute bottom-full left-0 mb-2;
  z-index: 1000;
}
