.liveStreamAcommentSection {
    height: calc(100vh - 286px);
    overflow-y: auto;
    padding: 0 16px;

    @media only screen and (max-width:"1024px") {
        height: unset;
        padding: 0;
    }

    .liveStreamHeading {
        padding: 0 8px 24px 8px;
        position: sticky;
        top: 0;
        left: 0;
        background: #fff;
        z-index: 9;

        @media only screen and (max-width:"1024px") {
            padding: 16px 16px 24px 16px;
        }

        h4 {
            color: var(--secondary-900);
            font-size: 24px;
            font-weight: 600;
            line-height: 28px;

            @media only screen and (max-width:"1024px") {
                font-size: 20px;
                line-height: 24px;
            }
        }
    }

    .liveSTreamBodyAlignment {
        padding: 12px 0 0 0;
        height: calc(100% - 52px - 60px);
        overflow-y: auto;

        @media only screen and (max-width:"1024px") {
            height: calc(100vh - 244px);
            padding: 0 16px;
        }

        .liveStreamCommentFlex {
            margin: 0 0 24px 0;

            @media only screen and (max-width:"1024px") {
                margin: 0 0 16px 0;
            }

            @media only screen and (max-width:"1024px") {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 8px;
            }

            .liveStreamProfileDetails {
                display: flex;
                align-items: center;
                gap: 8px;

                &:last-child {
                    margin: 0;
                }

                span {
                    width: 100%;
                    height: 100%;
                    // display: block;
                    border-radius: 50%;
                    background: #e8f3e6;
                    display: flex;
                    justify-self: center;
                    align-items: center;
                }


                .liveStreamProfileImg {
                    width: 32px;
                    height: 32px;

                    img {
                        width: 100%;
                        height: 100%;
                        display: block;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }
            }


            .commentUserNameAlignment {
                display: flex;
                align-items: center;
                gap: 4px;

                h6 {
                    color: var(--secondary-900);
                    font-size: 13px;
                    font-weight: 600;
                    line-height: 18px;
                    white-space: nowrap;
                }

                p {
                    color: var(--secondary-900);
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 18px;
                    white-space: nowrap;
                    background: none;
                    border-radius: 0;

                }
            }



            .mobileViewLikeAlignment {
                display: none;

                @media only screen and (max-width:"1024px") {
                    display: block;
                }

            }
        }


    }

    .liveVommentBottomInputMessage {
        display: grid;
        grid-template-columns: 1fr 44px;
        gap: 8px;
        position: sticky;
        bottom: 0;
        left: 0;
        background: #fff;
        z-index: 9;
        padding: 16px 0 0 0;

        @media only screen and (max-width:"1024px") {
            padding: 16px 16px 50px 16px;
        }

        .liveStreamInput {
            border-radius: 10px;
            background: #FAFAFB;
            padding: 0 10px 0 30px;
            display: flex;
            align-items: center;
            height: 44px;
            gap: 8px;

            @media only screen and (max-width:"1024px") {
                padding: 0 10px 0 16px;
            }

            input {
                width: 100%;
                height: 100%;
                background: transparent;
                border: none;
                font-size: 13px;
                font-weight: 400;
                line-height: 18px;
                color: var(--secondary-900);

                &::placeholder {

                    color: #92929D;
                }
            }

            .liveStreamIcon {
                cursor: pointer;
            }
        }

        .liveSendMessage {
            width: 100%;
            height: 44px;
            border-radius: 10px;
            background: var(--secondary-50);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }
}

.noDataCommentSection {
    width: 100%;
    height: calc(100% - 52px);
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        color: #1E2432;
        text-align: center;
        font-size: 13px;
        font-weight: 400;
        line-height: 15px;
        max-width: 258px;
        margin: 0 auto;
    }
}