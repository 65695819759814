.sellerFollowersSection {
  .sellerFollowersAlignment {
    background: var(--white);
    padding: 22px 30px;
    border-radius: 10px;

    .sellerFollowersHeadingAlignment {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .sellerFollowerFlex {
        display: flex;
        align-items: center;
        gap: 9px;

        h6 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
          color: var(--secondary-900);
        }

        .totalFollowersNumber {
          background: #eceef4;
          border-radius: 10px;
          padding: 0 8px;

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: var(--secondary-500);
          }
        }
      }

      .serachAlignment {
        margin: 22px 0 0 0;

        .searchInputAlignment {
          background: #ffffff;
          border: 1px solid #f3f3f3;
          border-radius: 10px;
          width: 348px;
          height: 40px;
          display: flex;
          align-items: center;
          position: relative;

          .iconAlignment {
            position: absolute;
            top: 9px;
            left: 11px;

            svg {
              cursor: pointer;
            }
          }

          input {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            color: #acb1c0;
            padding: 8px 10px 8px 40px;
            background: transparent;
            border: none;
            height: 100%;
          }
        }
      }
    }

    .sellerAllFollowersDetailsAlignment {
      margin: 32px 0 0 0;
      height: calc(100vh - 235px);
      overflow-y: auto;

      .sellerAllFollowersDetailsGrid {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 32px;

        .sellerAllFollowersDetailsGridItem {
          .sellerAllFollowersDetails {
            .personImgAlignment {
              width: 200px;
              height: 200px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
              }
            }

            .sellerAllFollowersChildDetails {
              padding: 16px 0 0 0;

              h5 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 22px;
                line-height: 33px;
                color: var(--black);
                text-align: center;
                cursor: pointer;
              }

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #495057;
                display: block;
                padding: 4px 0 0 0;
                cursor: context-menu;
              }

              p {
                padding: 4px 0 0 0;
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                text-align: center;
                color: #495057;
                cursor: context-menu;
              }

              .sellerAllFollowersMessageButton {
                text-align: center;
                padding: 16px 0 0 0;

                button {
                  padding: 6px 16px;
                  background: var(--primary-500);
                  border-radius: 8px;
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                  line-height: 20px;
                  color: #ffffff;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}
