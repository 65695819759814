.art-craft-now-trending-store-details-alignment {
  // padding: 16px;

  @media only screen and (max-width: '768px') {
    background: #fff;
    padding: 0;
  }

  .mobile-view-art-craft-back-alignment {
    display: none;

    @media only screen and (max-width: '768px') {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 18px;
      padding: 8px 16px;
      background: rgba(248, 248, 248, 0.92);
      backdrop-filter: blur(13.5914px);
      // position: sticky;
      // top: 0;
      // z-index: 999;

      .art-craft-back_details-alignment {
        background: #ffffff;
        border-radius: 10px;
        padding: 4px 9px 4px 10px;
        display: flex;
        align-items: center;
        gap: 8px;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #808191;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 58px;
        }
      }

      .mobile-heading-alignment {
        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          text-align: center;
          color: var(--secondary-900);
        }
      }
    }
  }

  .art-craft-now-trending-store-banner-alignment {
    width: 100%;
    height: 178px;
    position: relative;
    // position: sticky;
    // z-index: 999;
    // top: 0;

    // @media only screen and (max-width: '768px') {
    //   height: 100px;
    //   top: 47px;
    // }

    img {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 8px;
      object-fit: cover;

      @media only screen and (max-width: '768px') {
        display: none;
      }

      &.mobile-view-banner {
        display: none;

        @media only screen and (max-width: '768px') {
          display: block;
        }
      }
    }

    .art-craft-now-trending-store-banner-details {
      position: absolute;
      top: 35px;
      left: 48px;

      @media only screen and (max-width: '768px') {
        top: 20px;
        left: 16px;
      }

      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 40px;
        line-height: 60px;
        color: var(--secondary-900);

        @media only screen and (max-width: '768px') {
          font-size: 20px;
          line-height: 30px;
        }
      }

      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--secondary-900);
        max-width: 540px;

        @media only screen and (max-width: '768px') {
          font-size: 12px;
          line-height: 18px;
          max-width: 165px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .art-craft-now-trending-details-alignment {
    margin: 21px 0 0 0;

    @media only screen and (max-width: '768px') {
      margin: 18px;
    }

    .art-craft-now-trending-details-grid-alignment {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 16px;

      @media only screen and (max-width: '999px') {
        grid-template-columns: repeat(2, 1fr);
      }

      @media only screen and (max-width: '768px') {
        grid-template-columns: repeat(2, 1fr);
      }

      .art-craft-now-trending-details-gridItem-alignment {
        .art-craft-now-trending-detailsimg-details-alignment {
          height: auto;
          width: 100%;
          position: relative;
          transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

          overflow: hidden;
          border-radius: 16px;
          margin: 0 0 16px 0;

          &:last-child {
            margin: 0;
          }

          &:hover {
            transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

            img {
              transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
              transform: scale(1.02);
              border-radius: 10px;
              object-fit: cover;
            }

            .art-craft-now-trending-hover-details-alignment {
              display: block;
              border-radius: 10px;
            }
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            object-fit: cover;
          }

          .art-craft-now-trending-hover-details-alignment {
            display: none;
            position: absolute;
            background: linear-gradient(180deg, rgba(30, 36, 50, 0) 0%, #1e2432 100%);
            border-radius: 16px;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;

            .bottom-img-texta-alignment {
              position: absolute;
              bottom: 16px;
              right: 16px;
              left: 16px;
              cursor: pointer;

              h5 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                color: #f8f8f8;
                max-width: 203px;
              }

              .bottom-price-alignment {
                padding: 4px 0 0 0;
                display: flex;
                align-items: center;
                gap: 8px;

                .price-offer-details-alignment {
                  display: flex;
                  align-items: center;
                  gap: 2px;

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 27px;
                    color: #f8f8f8;
                  }

                  del {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    color: #ffffff;
                    opacity: 0.6;
                  }
                }

                .price-offer-alignment {
                  padding: 2px 6px;
                  background: #f1efb0;
                  border-radius: 11px;

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    color: #474400;
                  }
                }
              }

              .bottom-rate-alignment {
                display: flex;
                align-items: center;
                gap: 8px;
                padding: 8px 0 0 0;

                .bottom-rate-star-alignment {
                  display: flex;
                  align-items: center;
                  gap: 6px;

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #f8f8f8;
                  }

                  .rate-star-alignment {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                  }
                }

                .rate-number-alignment {
                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: #f8f8f8;
                  }
                }
              }
            }

            .top-img-details-alignment {
              // position: absolute;
              // top: 16px;
              // right: 16px;

              .other-option-alignment-details {
                position: absolute;
                top: 16px;
                right: 16px;
                display: flex;
                align-items: center;
                gap: 8px;

                @media only screen and (max-width: '768px') {
                  display: none;
                }

                .hover-option-box-alignment {
                  background: rgba(30, 36, 50, 0.6);
                  border-radius: 8px;
                  padding: 4px;
                  width: 32px;
                  height: 32px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  img {
                    height: auto;
                    border-radius: 10px;
                    object-fit: cover;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}