.jewelryLiveChannelsModalSection {
    .jewelryLiveChannelsModalAlignment {
        border-radius: 10px;
        background: #FFF;
        height: calc(100vh - 96px);
        padding: 32px;
        overflow-y: auto;

        @media only screen and (max-width:"1099px") {
            height: calc(100vh - 32px);
        }

        .jewelryLiveChannelsDetailsAlignment {
            margin: 0 0 48px 0;

            &:last-child {
                margin: 0;
            }

            .jewelryLiveChannelsDetailsHeading {
                display: flex;
                align-items: center;
                justify-content: space-between;

                h6 {
                    color: var(--bookmark-heading-color);
                    font-family: 'Poppins', sans-serif;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 36px;
                }

                .exploreChannel {
                    padding: 2px 10px;

                    a {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        color: var(--primary-500);
                        text-align: center;
                        font-family: 'Poppins', sans-serif;
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 19px;
                    }
                }
            }

            .jewelryLiveChannelsDetailsBodyDetails {
                padding: 32px 0 0 0;

                .jewelryLiveChannelsDetailsGrid {
                    display: grid;
                    grid-template-columns: repeat(5, 1fr);
                    gap: 16px;

                    @media only screen and (max-width:"1099px") {
                        grid-template-columns: repeat(4, 1fr);
                    }

                    .jewelryLiveChannelsDetailsGridItem {
                        .modalJewelryLiveDetailsImgAlignment {
                            width: 100%;
                            height: 298px;
                            position: relative;

                            @media only screen and (max-width:"767px") {
                                height: 224px;
                            }

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                display: block;
                                border-radius: 10px;
                            }


                            &:before {
                                content: '';
                                border-radius: 0px 0px 10px 10px;
                                background: linear-gradient(0deg, #1E2432 0%, rgba(30, 36, 50, 0.00) 100%);
                                width: 100%;
                                height: 208px;
                                bottom: 0;
                                left: 0;
                                z-index: 99;
                                position: absolute;
                            }

                            .modalJewelryLiveDetailsBlurDetails {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                z-index: 999;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;

                                .jewelryLiveAndViewAlignment {
                                    display: flex;
                                    align-items: center;
                                    gap: 4px;
                                    padding: 16px;

                                    @media only screen and (max-width:"767px") {
                                        flex-direction: column;
                                        align-items: flex-start;
                                    }

                                    .jewelryLiveBoxAlignment {
                                        border-radius: 8px;
                                        background: #E71616;
                                        padding: 2px 8px;
                                        display: flex;
                                        align-items: center;
                                        gap: 4px;

                                        @media only screen and (max-width:"767px") {
                                            padding: 4px;
                                        }

                                        p {
                                            color: #FFF;
                                            font-family: 'Poppins', sans-serif;
                                            font-size: 14px;
                                            font-weight: 500;
                                            line-height: 24px;

                                            @media only screen and (max-width:"767px") {
                                                font-size: 12px;
                                                line-height: 14px;
                                            }
                                        }
                                    }

                                    .jewelryLiveNumberAlignment {
                                        border-radius: 8px;
                                        background: rgba(30, 36, 50, 0.60);
                                        display: flex;
                                        align-items: center;
                                        gap: 4px;
                                        padding: 2px 8px;
                                        height: 24px;

                                        @media only screen and (max-width:"767px") {
                                            padding: 4px;
                                        }

                                        p {
                                            color: #E4E4E8;
                                            font-family: 'Poppins', sans-serif;
                                            font-size: 14px;
                                            font-weight: 500;
                                            line-height: 18px;

                                            @media only screen and (max-width:"767px") {
                                                font-size: 12px;
                                                line-height: 14px;
                                            }
                                        }
                                    }
                                }

                                .jewelryLiveAndViewOtherDetailsALignment {
                                    padding: 16px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;

                                    .jewelryLiveAndViewOtherName {
                                        h6 {
                                            color: #F8F8F8;
                                            font-family: 'Poppins', sans-serif;
                                            font-size: 20px;
                                            font-weight: 600;
                                            line-height: 30px;
                                            max-width: 142px;

                                            @media only screen and (max-width:"767px") {
                                                font-size: 16px;
                                                line-height: 20px;
                                                max-width: 100%;
                                            }
                                        }

                                        .dateAlignment {
                                            display: none;

                                            @media only screen and (max-width:"767px") {
                                                display: block;
                                                padding: 2px 0 0 0;

                                                p {
                                                    color: var(--secondary-50);
                                                    font-family: 'Poppins', sans-serif;
                                                    font-size: 12px;
                                                    font-weight: 400;
                                                    line-height: 14px;
                                                }
                                            }

                                        }

                                        .jewelryBrandNameAlignment {
                                            display: flex;
                                            align-items: center;
                                            gap: 16px;
                                            padding: 16px 0 0 0;

                                            @media only screen and (max-width:"767px") {
                                                padding: 8px 0 0 0;
                                                gap: 8px;
                                            }

                                            .jewelryBrandImg {
                                                width: 20px;
                                                height: 20px;

                                                img {
                                                    width: 100%;
                                                    height: 100%;
                                                    border-radius: 2px;
                                                    object-fit: cover;
                                                    display: block;
                                                }
                                            }

                                            p {
                                                color: #FFF;
                                                font-family: 'Poppins', sans-serif;
                                                font-size: 13px;
                                                font-weight: 500;
                                                line-height: 20px;

                                                @media only screen and (max-width:"767px") {
                                                    font-size: 12px;
                                                    line-height: 14px;
                                                }

                                            }
                                        }
                                    }

                                    .jewelryLiveAndViewHeartShareAlignment {
                                        display: flex;
                                        flex-direction: column;
                                        align-items: center;
                                        gap: 17px;
                                        justify-content: center;

                                        @media only screen and (max-width:"767px") {
                                            display: none;
                                        }

                                        .jewelryLiveAndViewOtherDetailsBoxNames {
                                            .jewelryLiveAndViewOtherDetailsBox {
                                                border-radius: 8px;
                                                background: rgba(30, 36, 50, 0.40);
                                                padding: 4px;
                                                cursor: pointer;
                                            }

                                            p {
                                                color: #F8F8F8;
                                                font-family: 'Poppins', sans-serif;
                                                font-size: 13px;
                                                font-weight: 500;
                                                line-height: 26px;
                                                text-align: center;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}