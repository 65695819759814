.newArrivalModalSection {
    border-radius: 10px;
    background: #FFF;
    height: calc(100vh - 98px);
    overflow-y: auto;

    @media only screen and (max-width:"767px") {
        padding: 0;
        height: calc(100vh - 60px);
    }

    .newArrivalModalHeaderAlignment {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 99;

        @media only screen and (max-width:"767px") {
            display: none;
        }

        .newArrivalMainLineBoxAlignment {
            background: linear-gradient(135deg, #556EE6 0%, #6B21A8 100%);
            height: 54px;
            width: 100%;
            position: relative;
            overflow-x: hidden;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                background: linear-gradient(90deg, #F8F8F8 0%, rgba(248, 248, 248, 0.00) 100%);
                height: 100%;
                width: 528px;
                z-index: 9;
            }

            &:after {
                content: '';
                position: absolute;
                right: 0;
                background: linear-gradient(270deg, #F8F8F8 0%, rgba(248, 248, 248, 0.00) 100%);
                height: 100%;
                width: 528px;
                z-index: 9;
                top: 0;
            }

            .newArrivalMainLineBoxFlexAlignment {
                display: flex;
                align-items: center;
                gap: 16px;
                height: 100%;
                white-space: nowrap;
                animation: 58.9874s linear 0s 1 normal none running move;

                @media screen and (max-width: 540px) {
                    animation: 20.9874s linear 0s 1 normal none running move;
                }

                @keyframes move {
                    to {
                        transform: translateX(-100%);
                    }
                }

                p {
                    color: var(--secondary-50);
                    font-family: 'Poppins', sans-serif;
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 28px;
                    white-space: nowrap;
                }

                svg {
                    min-width: 8px;
                    width: 8px;
                    max-width: 8px;
                    height: 8px;
                }
            }
        }
    }

    .newArrivalModalMobileViewHeading {
        display: none;

        @media only screen and (max-width:"767px") {
            display: block;
            padding: 16px 16px 12px 16px;
            position: sticky;
            top: 0;
            z-index: 999;
            background: #FFF;

            h4 {
                color: var(--secondary-900);
                font-family: 'Poppins', sans-serif;
                font-size: 20px;
                font-weight: 600;
                line-height: 24px;
            }
        }

    }

    .newArrivalModalDetailsAlignment {
        padding: 32px 16px;

        @media only screen and (max-width:"767px") {
            padding: 0;
        }
    }
}