.mobileViewCreateNewPostModalDetailsSection {
  //   height: calc(100vh - 51px);
  //   overflow-y: auto;
  width: 100%;

  .mobileViewCreateNewPostModalDetailsClick {
    padding: 8px 0 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
    background-color: #fff;

    p {
      width: 33px;
      height: 3px;
      background: #e4e4e8;
      border-radius: 1.5px;
    }
  }

  .mobileViewCreatenewPostMainHeadingAlignment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 12px 16px;
    border-bottom: 1px solid #eaecef;
    position: sticky;
    top: 27px;
    background-color: #fff;

    h4 {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #495057;
    }

    .mobileVIewCancleButton {
      button {
        background: transparent;
        border: none;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--primary-500);
        cursor: pointer;
      }
    }
  }

  .mobileViewCreateNewPostMainDetailsAlignment {
    padding: 16px 0;
    overflow-y: auto;

    .mobileViewCreateNewPostAlignment {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      padding: 0 16px;

      .mobileViewCreateNewPostImgAlignment {
        width: 40px;
        height: 40px;
        min-width: 40px;
        max-width: 40px;

        &.roundedImg {
          img {
            border-radius: 50%;
          }
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          display: block;
          object-fit: cover;
        }
      }

      .mobileSellerInputAlignment {
        background: #fafafb;
        border: 1px solid #e4e4e8;
        border-radius: 10px;
        height: 40px;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 0 8px;

        input {
          width: 100%;
          height: 100%;
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          background: transparent;
          border: none;

          &::placeholder {
            color: #acb1c0;
          }

          @media only screen and (max-width: '767px') {
            font-size: 16px;

            &::placeholder {
              font-size: 16px;
            }
          }
        }
      }

      .mobileViewCreatePostDescriptionBox {
        background: #fafafb;
        border: 1px solid #e4e4e8;
        border-radius: 10px;
        padding: 10px 20px 5px 8px;
        width: 100%;

        textarea {
          width: 100%;
          height: 95px;
          background: none;
          border: none;
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: var(--secondary-900);
          resize: none;

          @media only screen and (max-width: '767px') {
            font-size: 16px;

            &::placeholder {
              font-size: 16px;
            }
          }
        }
      }
    }

    .productDetailsfeedDetailsSection {
      padding: 16px;
      overflow-y: auto;

      .productDetailsfeedAllDetailsSection {
        margin: 10px 0 0 0;

        .productDetailsfeedDesignSection {
          background: #f8f8f8;
          border: 1px solid #f8f8f8;
          border-radius: 10px;
          padding: 8px;
          width: 100%;
          display: block;
          position: relative;

          .productDetailsfeedImgAlignment {
            width: 100%;
            height: 236px;
            padding: 0 4px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 5.34609px;
            }

            video {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 5.34609px;
            }
          }

          .productDetailsfeedDetailsAlignment {
            padding: 8px 16px 18px 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .productDetailsfeedHeading {
              h5 {
                cursor: pointer;
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                color: var(--secondary-900);
              }
            }

            .productDetailsfeedPriceAlignment {
              display: flex;
              align-items: center;
              gap: 4px;
              padding: 8px 0 0 0;

              h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 15px;
                line-height: 22px;
                color: #000000;
              }

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: #808191;
              }
            }

            .productDetailsfeedDeliveryShipping {
              display: flex;
              align-items: center;
              gap: 6px;
              padding: 6px 0 0 0;

              .productDetailsBestSellerAlignment {
                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  background: linear-gradient(263.94deg, #ffc554 7.44%, #744c00 86.5%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  background-clip: text;
                  text-fill-color: transparent;
                }
              }

              .productDetailsDotAlignment {
                p {
                  width: 4px;
                  height: 4px;
                  border-radius: 50%;
                  background: #acb1c0;
                }
              }

              .productDetailsDeliveryMenuAlign {
                width: auto;
                height: auto;
                background: #ff7d23;
                border-radius: 11px;
                display: flex;
                justify-content: center;
                align-items: center;

                button {
                  padding: 1px 9px;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 18px;
                  display: flex;
                  align-items: center;
                  color: #ffffff;

                  @media screen and (max-width: 767px) {
                    padding: 1px 4px;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    display: flex;
                    align-items: center;
                    color: #ffffff;
                    white-space: nowrap;
                  }
                }
              }

              .productDetailsDeliveryMenuAlign {
                width: auto;
                height: 21px;
                // background: #eaeaea;
                border-radius: 13.5px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 2px;
                background: transparent;

                @media screen and (max-width: 767px) {
                  padding: 4px 6px;
                }

                padding: 4px 8px;

                img {
                  width: 100%;
                  height: 100%;
                }

                span {
                  font-weight: 500;
                  font-style: italic;
                  font-size: 12px;
                  line-height: 18px;
                  display: flex;
                  align-items: center;
                  text-decoration-line: none;
                  color: #5f40af;
                }
              }

              .productDetailsArrowMenuAlign {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 6px;

                img {
                  width: 11px;
                  height: 11px;
                }

                span {
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 21px;
                  display: flex;
                  align-items: center;
                  color: #70c217;
                }
              }
            }
          }

          .productCloseAlignment {
            width: 32px;
            height: 32px;
            background: rgba(30, 36, 50, 0.6);
            border-radius: 10px;
            position: absolute;
            top: 16px;
            right: 16px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }

  .mobileViewCreateNewPostBottomDetailsAlignment {
    position: fixed;
    // height: calc(100vh - 64%);
    height: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;

    .mobileViewCreateNewPostShareButtonAlignment {
      padding: 16px;
      border-top: 1px solid #f1f1f5;

      button {
        border-radius: 10px;
        width: 100%;
        height: 44px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #f8f8f8;
        cursor: pointer;
        background: var(--primary-500);

        &.disableButton {
          background: var(--primary-100);
        }
      }
    }

    .mobileViewBottomOptionAlignment {
      padding: 0 16px 16px 16px;
      // height: calc(100vh - 370px);
      // overflow-y: auto;
      .mobileViewBottomOptionChildDetailsAlignment {
        display: flex;
        align-items: center;
        gap: 16px;
        margin: 0 0 24px 0;

        &:last-child {
          margin: 0;
        }

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: var(--secondary-500);
        }
      }
    }
  }
}
