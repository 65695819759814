.women-mobile-view-details-fashion-name-search-alignment {
  display: none;

  @media only screen and (max-width: 767px) {
    display: flex;
    padding: 16px 0 0 0;
  }

  gap: 8px;
  background: #ffffff;
  padding: 16px 0 0 0;

  .mobile-women-width-alignment {
    width: calc(100% - 0px);

    .mobile-view-child-heading-alignment {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0 26px 0 0 !important;
      // position: sticky;
      // top: 0;
      // left: 0;
      background: #ffffff;
      z-index: 5;
      position: relative;

      // new Css start
      padding: 0 26px 16px 0;
      // new Css end

      &.open-sidebar-header-change {
        gap: 52px;
        justify-content: flex-start;
      }
      .mobile-view-right-side-alignment {
        display: flex;
        align-items: center;
        gap: 8px;
        .mobile-view-close-arrow-alignment {
          background: #f8f8f8;
          border-radius: 0px 8px 8px 0px;
          padding: 10px 14px;
          margin: 0 8px 0 0;
          width: 32px;
          min-width: 32px;
          max-width: 32px;

          .rotate {
            transform: rotate(180deg);
          }
        }
        .mobile-view-child-name-alignment {
          display: flex;
          align-items: center;
          gap: 4px;
          h5 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: var(--secondary-900);
          }

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #808191;
          }
        }
      }

      .mobile-view-list-alignment {
        display: flex;
        align-items: center;
        gap: 24px;
      }

      .mobile-search-icon-alignment {
        img {
          width: 24px;
          height: 24px;
          display: block;
          cursor: pointer;
        }
      }
    }

    .mobile-child-search-alignment {
      position: relative;
      right: 16px;
      margin: 14px 0 0 0;

      input {
        background: #f8f8f8;
        border-radius: 10px;
        width: 100%;
        height: 44px;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: var(--secondary-900);
        padding: 10px 40px 10px 10px;
      }

      .close-alignment {
        position: absolute;
        top: 10px;
        right: 14px;
        cursor: pointer;
      }
    }

    .mobile-view-sidebar-alignment {
      display: flex;
      align-items: flex-start;

      .mobile-search-sidebar-details-box-alignment {
        display: flex;
        align-items: flex-start;

        // new css start
        position: sticky;
        top: 0;
        z-index: 9999;
        padding: 0 0 0 0;
        // new css end
        .mobile-view-sidebar-box-alignment {
          background: #f8f8f8;
          // border-radius: 8px;
          height: calc(100vh - 48px);
          overflow-y: auto;
          width: 100px;
          max-width: 100px;
          min-width: 100px;

          .mobile-silder-side-main-details {
            padding: 18px 16px;
            position: relative;

            // padding: 18px 16px;
            // position: fixed;
            // width: 100px;
            // top: 134px;
            // left: 0;

            .mobile-slider-all-details-alignment {
              margin: 0 0 16px 0;

              &:last-child {
                margin: 0;
              }

              .mobile-silder-main-box-alignmrnt {
                width: 72px;
                height: 72px;
                border: 1px solid transparent;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                &.active-women-category {
                  border: 1px solid #a855f7;

                  .mobile-slider-details-img {
                    background: #ffffff;

                    img {
                      width: 100%;
                      height: 100%;
                      display: block;
                    }
                  }

                  p {
                    font-weight: 600;
                  }
                }

                .mobile-slider-details-img {
                  width: 62px;
                  height: 62px;
                  border-radius: 8px;
                  position: relative;
                  margin: auto;
                  background: #ffffff;
                  border-radius: 50%;
                  padding: 2px;

                  img {
                    width: 100%;
                    height: 100%;
                    display: block;
                  }
                }
              }

              .category-name-align {
                p {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 18px;
                  text-align: center;
                  color: var(--secondary-900);
                  margin: 8px 0 0 0;
                }
              }
            }

            .find-fashion-border-alignment {
              width: 114px;
              height: 114px;
              border: 1px solid transparent;
              border-radius: 68.4405px;
              padding: 6px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0 0 2px 0;

              &.find-fashion-border-active {
                border: 1px solid #ffecbc;
              }

              .find-fashion-box-alignment {
                background: transparent;
                border-radius: 68.4405px;
                width: 102px;
                height: 102px;
                display: flex;
                align-items: center;
                justify-content: center;

                &.find-fashion-box-active {
                  background-color: rgba(255, 203, 70, 0.6);
                }
              }
            }

            .fashion-img-alignment {
              width: 88px;
              height: 88px;
              position: relative;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
              }

              &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background: rgba(30, 36, 50, 0.6);
                top: 0;
                right: 0;
                border-radius: 50%;
              }

              .fashion-option-name-alignment {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 9;

                h5 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  text-align: center;
                  color: #ffffff;
                }
              }
            }
          }
        }

        .mobile-view-left-arrow-alignment {
          background: #f8f8f8;
          border-radius: 0px 8px 8px 0px;
          padding: 10px 14px;
          margin: 0 8px 0 0;
          width: 32px;
          min-width: 32px;
          max-width: 32px;

          .rotate {
            transform: rotate(180deg);
          }
        }
      }

      .header-width-alignment {
        width: 100%;
        overflow-x: hidden;
      }
    }
  }
}

.mobileSubCategory-back-alignment {
  display: flex;
  align-items: center;
  gap: 22px;
  padding: 8px 10px;
  background: rgba(248, 248, 248, 0.92);
  display: none;

  @media only screen and (max-width: '1024px') {
    display: flex;
    position: sticky;
    top: 0;
    z-index: 5;
  }

  .back_details-alignment {
    background: #ffffff;
    border-radius: 10px;
    padding: 4px 9px 4px 10px;
    display: flex;
    align-items: center;
    gap: 8px;

    p {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #808191;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 58px;
    }
  }

  .mobile-heading-alignment {
    h4 {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: var(--secondary-900);
    }
  }
}

.findYourStyle_mobile-view-list-details-alignment {
  margin: 0;
  width: 100%;
  gap: 16px 15px;
  display: block;

  .fondYourStyle_mobile-view-list-box {
    background: #ffffff;
    border: 1px solid #f3f3f3;
    border-radius: 10px;
    padding: 4px 8px;
    margin: 0 0 4px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: start;
    gap: 8px;

    .fondYourStyle_img-alignment {
      min-width: 166px;
      width: 166px;
      max-width: 166px;
      margin: 4px 0 0 0;
      border-radius: 8px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
      }
    }

    .fondYourStyle_listView-details-alignment {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      position: relative;
      h4 {
        max-width: 188px;
        margin: 36px 0 8px 0;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: var(--secondary-900);
        // white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .listView-details_product-child-details-alignment {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 2px 0 0 0;

        .listView-details_product-proce-discount-alignment {
          display: flex;
          align-items: center;
          gap: 5px;
          flex-wrap: wrap;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: var(--secondary-900);
          }

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            text-decoration-line: line-through;
            color: #acb1c0;
          }

          .listView-details_find-your-style-discount-menu-align {
            width: 61px;
            height: 21px;
            background: #ff7d23;
            border-radius: 11px;
            display: flex;
            justify-content: center;
            align-items: center;

            button {
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              display: flex;
              align-items: center;
              color: #ffffff;
            }
          }
        }
      }

      .listView-details_shopping-alignment {
        display: flex;
        align-items: center;
        gap: 8px;
        margin: 4px 0 0 0;

        .listView-details_findYourStyle-color-alignment {
          display: flex;
          align-items: center;
          gap: 7px;
          background: #ffffff;
          border: 0.5px solid #e4e4e8;
          border-radius: 10.5px;
          padding: 2px 6px;

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: var(--secondary-900);
          }
        }

        .listView-details_find-your-style-delivery-menu-align {
          width: 62px;
          height: 21px;
          background: #eaeaea;
          border-radius: 13.5px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 2px;

          img {
          }

          span {
            font-weight: 500;
            font-style: italic;
            font-size: 12px;
            line-height: 18px;
            display: flex;
            align-items: center;
            text-decoration-line: none;
            color: #5f40af;
          }
        }
      }

      .listView-details_child-details-alignment {
        display: flex;
        align-items: center;
        gap: 7px;

        .product-interest-details-alignment {
          display: flex;
          align-items: center;
          gap: 5px;
          // width: 100%;

          &.openSildebar {
            width: 100%;
          }

          .product-interest-child-details-alignment {
            display: flex;
            align-items: center;
            gap: 5px;

            .product-analytics-section {
              display: flex;
              // justify-content: flex-start;
              align-items: center;
              gap: 5px;

              @media screen and (max-width: 767px) {
                margin: 4px 0;
              }

              .product-graph-menu-align {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 6px;

                img {
                  width: 14px;
                  height: 11.8px;
                }

                span {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 18px;
                  color: #000000;
                }
              }

              .arrow-menu-align {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 6px;

                img {
                  width: 11px;
                  height: 11px;
                }

                span {
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 21px;
                  display: flex;
                  align-items: center;
                  color: #70c217;
                }
              }
            }
          }
        }

        .findYourStyle-product-rating {
          display: flex;
          align-items: center;
          margin: 4px 0;

          .findYourStyle-product-rating_point {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            color: var(--secondary-900);
          }

          .findYourStyle-product-stars {
            display: flex;
            align-items: center;
            margin: 0 2px;

            img {
              width: 13px;
              height: 13px;
              display: block;
            }
          }

          .findYourStyle-product-num {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #acb1c0;
          }
        }
      }

      .listView-details_findYourStyle-list-details-alignment {
        .mobile-men-sub-category-list-card_utils {
          display: flex;
          justify-content: start;
          gap: 24px;
          align-items: center;

          .mobile-men-sub-category-list-box {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px 0;

            &:last-child {
              border-right: none;
            }

            svg {
              margin-right: 5px;
            }
          }
        }

        .listView-detailsfindYourStyle-list-card_utils {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          border: 1px solid #f3f3f3;
          border-radius: 10px;
          max-width: 164px;

          .listView-detailsfindYourStyle-list-box {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px 0;
            border-right: 1px solid #f3f3f3;

            &:last-child {
              border-right: none;
            }

            svg {
              margin-right: 5px;
            }
          }
        }
      }
      .listView-details_moreOption-alignment {
        position: absolute;
        top: 4px;
        right: 10px;
      }
    }
  }
}

.men-mobile-view-details-fashion-name-search-alignment {
  display: none;

  @media only screen and (max-width: 767px) {
    display: flex;
    // height: calc(100vh - 48px - 65px);
    overflow-y: auto;
  }

  gap: 8px;
  background: #1e2432;
  // padding: 16px 0 0 0;
  overflow-x: hidden;

  .mobile-women-width-alignment {
    width: calc(100% - 0px);

    .mobile-view-child-heading-alignment {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      // padding: 0 26px 0 0;
      // position: sticky;
      // top: 0;
      // left: 0;
      background: #1e2432;
      z-index: 5;
      position: relative;

      // new css start
      padding: 16px 26px 16px 0;
      // new css end

      &.open-sidebar-header-change {
        gap: 52px;
        justify-content: flex-start;
      }

      .mobile-view-left-side-flex-alignment {
        display: flex;
        align-items: center;
        gap: 8px;

        .mobile-view-close-arrow-alignment {
          background: #f8f8f8;
          border-radius: 0px 8px 8px 0px;
          padding: 10px 14px;
          margin: 0 8px 0 0;
          width: 32px;
          min-width: 32px;
          max-width: 32px;
          height: 30px;

          @media only screen and (max-width: 767px) {
            background: #151515;
          }
        }
        .mobile-view-child-name-alignment {
          display: flex;
          align-items: center;
          gap: 4px;

          h5 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: var(--secondary-900);

            @media only screen and (max-width: 767px) {
              color: #f8f8f8;
            }
          }

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #808191;
          }
        }
      }

      .mobile-view-list-alignment {
        display: flex;
        align-items: center;
        gap: 24px;
      }

      .mobile-search-icon-alignment {
        img {
          width: 24px;
          height: 24px;
          display: block;
          cursor: pointer;
        }
      }
    }

    .mobile-child-search-alignment {
      position: relative;
      right: 16px;
      margin: 14px 0 0 0;

      input {
        background: #f8f8f8;
        border-radius: 10px;
        width: 100%;
        height: 44px;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: var(--secondary-900);
        padding: 10px 40px 10px 10px;
      }

      .close-alignment {
        position: absolute;
        top: 10px;
        right: 14px;
        cursor: pointer;
      }
    }

    .mobile-view-sidebar-alignment {
      display: flex;
      align-items: flex-start;

      .mobile-search-sidebar-details-box-alignment {
        display: flex;
        align-items: flex-start;
        // new css start
        position: sticky;
        top: 0;
        z-index: 9999;
        background: #1e2432;
        padding: 16px 0 0 0;
        // new css close

        .mobile-view-sidebar-box-alignment {
          background: #f8f8f8;
          // border-radius: 8px;
          height: calc(100vh - 90px);
          overflow-y: auto;
          width: 100px;
          max-width: 100px;
          min-width: 100px;

          .mobile-silder-side-main-details {
            padding: 18px 16px;
            position: relative;

            .mobile-slider-all-details-alignment {
              margin: 0 0 16px 0;

              &:last-child {
                margin: 0;
              }

              .mobile-silder-main-box-alignmrnt {
                width: 72px;
                height: 72px;
                border: 1px solid transparent;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                  border: 1px solid #a855f7;

                  .mobile-slider-details-img {
                    background: #ffffff;

                    img {
                      width: 100%;
                      height: 100%;
                      display: block;
                    }
                  }

                  p {
                    font-weight: 600;
                  }
                }

                .mobile-slider-details-img {
                  width: 62px;
                  height: 62px;
                  border-radius: 8px;
                  position: relative;
                  margin: auto;
                  background: #ffffff;
                  border-radius: 50%;
                  padding: 2px;

                  img {
                    width: 100%;
                    height: 100%;
                    display: block;
                  }
                }
              }

              .category-name-align {
                p {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 18px;
                  text-align: center;
                  color: var(--secondary-900);
                  margin: 8px 0 0 0;
                }
              }
            }

            .find-fashion-border-alignment {
              width: 114px;
              height: 114px;
              border: 1px solid transparent;
              border-radius: 68.4405px;
              padding: 6px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0 0 2px 0;

              &.find-fashion-border-active {
                border: 1px solid #ffecbc;
              }

              .find-fashion-box-alignment {
                background: transparent;
                border-radius: 68.4405px;
                width: 102px;
                height: 102px;
                display: flex;
                align-items: center;
                justify-content: center;

                &.find-fashion-box-active {
                  background-color: rgba(255, 203, 70, 0.6);
                }
              }
            }

            .fashion-img-alignment {
              width: 88px;
              height: 88px;
              position: relative;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
              }

              &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background: rgba(30, 36, 50, 0.6);
                top: 0;
                right: 0;
                border-radius: 50%;
              }

              .fashion-option-name-alignment {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 9;

                h5 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  text-align: center;
                  color: #ffffff;
                }
              }
            }
          }
        }

        .mobile-view-left-arrow-alignment {
          background: #151515;
          border-radius: 0px 8px 8px 0px;
          padding: 10px 14px;
          margin: 0 8px 0 0;
          width: 32px;
          min-width: 32px;
          max-width: 32px;
          height: 30px;
          .rotate {
            transform: rotate(180deg);
          }
        }
      }

      .header-width-alignment {
        width: 100%;
      }
    }
  }
}

.men-mobileSubCategory-back-alignment {
  display: flex;
  align-items: center;
  gap: 22px;
  padding: 8px 10px;
  background: #1e2432;
  display: none;

  @media only screen and (max-width: '767px') {
    display: flex;
    position: sticky;
    top: 0;
    z-index: 99;
  }

  .back_details-alignment {
    background: #575c6b;
    border-radius: 10px;
    padding: 4px 9px 4px 10px;
    display: flex;
    align-items: center;
    gap: 8px;

    p {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #e4e4e8;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 58px;
    }
  }

  .mobile-heading-alignment {
    h4 {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: #f8f8f8;
    }
  }
}
