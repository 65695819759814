.mobile-message-selected-forword-alignment-Overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 146px);
  background: rgba(255, 255, 255, 0.6);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  z-index: 9999999 !important;
  display: none;

  @media only screen and (max-width: '1099px') {
    display: block;
  }
}

.mobile-message-selected-forword-alignment-Overlay.open {
  opacity: 1;
  visibility: visible;

  @media only screen and (max-width: '1099px') {
    display: block;
  }
}

.mobile-message-selected-forword-alignment--modal {
  position: fixed;
  right: 0;
  bottom: 6px;
  width: 100%;
  translate: 0 100%;
  transition: 0.3s;
  overflow-y: auto;
  height: calc(100vh - 248px);
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.25);
  border-radius: 8px 8px 0px 0px;
}

.more-option-modal-forword-body-details-alignment {
  padding: 16px;
  box-sizing: border-box;
  
  @media (max-width:576px) {
    height: calc(100% - 68px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .more-option-modal-forword-body-details-alignment-flx1 {
    @media (max-width:576px) {
      height: 100%;
    }
  }

  h6 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: var(--secondary-900);
  }

  .mobile-view-forword-search-alignment {
    margin: 16px 0 0 0;
    background: #ffffff;
    border: 1px solid #f3f3f3;
    border-radius: 10px;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;

    input {
      width: 100%;
      height: 100%;
      border: none;
      background: transparent;
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      ::placeholder {
        color: #acb1c0;
      }
    }
  }

  .mobile-view-forword-all-profile-details-alignment {
    padding: 24px 0 0 0;
    height: calc(100vh - 470px);
    overflow-y: auto;

    @media (max-width:576px) {
      height: calc(100vh - 35vh);
    }

    .mobile-view-forword-profile-name-alignment {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 6px 16px 6px 16px;
      margin: 0 0 6px 0;

      &:last-child {
        margin: 0;
      }

      &:hover {
        background: var(--primary-50);
        border-radius: 10px;
      }
      &.profile-forword-active {
        background: var(--primary-50);
        border-radius: 10px;
      }
      .mobile-view-profile-alignment {
        display: flex;
        align-items: center;
        gap: 8px;

        .mobile-view-profile-img-alignment {
          width: 32px;
          height: 32px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
            display: block;
          }
        }

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: var(--secondary-900);
        }
      }

      .mobile-view-input-checkBox-alignment {
        width: 20px;
        height: 20px;
        input[type='checkbox'] {
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  .mobile-view-forword-send-button {
    padding: 16px 0;

    @media (max-width:576px) {
      height: 10%;
    }

    button {
      width: 100%;
      height: 44px;
      background: var(--primary-100);
      border-radius: 10px;
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #f8f8f8;

      &.active-forword-button {
        background: var(--primary-500);
      }
    }
  }
}
.mobile-message-selected-forword-alignment--modal.open {
  translate: 0;
  z-index: 9999999;
  transform: translate(0, 6%);

  @media only screen and (max-width: '1099px') {
    display: block;
  }
}
