.sellerFollowrsSection {
    margin: 0 0 16px 0;

    .sellerFollowrsAlignment {
        .sellerFollowrsBox {
            background: var(--white);
            border-radius: 10px;
            padding: 22px 32px;

            @media only screen and (max-width:"1499px") {
                padding: 22px 16px;
            }

            .sellerFollowrsBoxHeding {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 8px;
                border-bottom: 1px solid var(--secondary-100);
                padding: 0 0 12px 0;

                .sellerFollowersFlex {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    h5 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 36px;
                        color: var(--secondary-900);

                    }

                    .followersTotalBoxAlignment {
                        background: var(--secondary-50);
                        border-radius: 10px;
                        padding: 0 8px;

                        span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 20px;
                            color: var(--secondary-500);
                        }
                    }
                }

                .seeAllFollowesDetails {
                    display: flex;
                    align-items: center;
                    gap: 12px;

                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 28px;
                    color: var(--primary-500);

                    @media only screen and (max-width:"1499px") {
                        font-size: 14px;
                    }

                    img {
                        cursor: pointer;
                    }
                }
            }

            .sellerFollowrsSearchAlignment {
                padding: 21px 0 0 0;

                .sellerFollowrsSearchInput {
                    background: var(--white);
                    border: 1px solid var(--secondary-100);
                    border-radius: 10px;
                    width: 100%;
                    height: 40px;
                    display: flex;
                    align-items: center;

                    img {
                        width: 40px;
                        height: 40px;
                        display: block;
                        cursor: pointer;
                    }

                    .sellerFollowersInput {
                        input {
                            width: 100%;
                            height: 100%;
                            border: none;
                            background: transparent;
                            font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;

                            &::placeholder {
                                color: var(--grey3);
                            }
                        }
                    }
                }
            }

            .sellerFollowersDetailsAlignment {
                padding: 16px 0 0 0;
                height: auto;
                max-height: 330px;
                overflow-y: auto;

                .sellerFollowersDetailsFlex {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 0 0 16px 0;

                    &:last-child {
                        margin: 0;
                    }

                    .sellerFollowersDetailsProfileFlex {
                        display: flex;
                        align-items: center;
                        gap: 16px;

                        .sellerFollowersProfileImg {
                            width: 40px;
                            height: 40px;
                            min-width: 40px;
                            max-width: 40px;

                            img {
                                width: 100%;
                                height: 100%;
                                border-radius: 50%;
                                object-fit: cover;
                            }
                        }

                        h6 {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            letter-spacing: 0.1px;
                            color: var(--secondary-900);
                        }
                    }

                    .sellerFollowersMessageBox {
                        width: 32px;
                        height: 32px;
                        background: var(--primary-50);
                        border-radius: 8px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            max-width: 100%;
                            height: auto;
                        }
                    }
                }
            }
        }
    }
}