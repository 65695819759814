.store-page {
  max-width: 100%;
  margin: 0 auto;

  @media only screen and (max-width: 767px) {
    display: none;
  }

  .feature-product-align {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 15px;

    @media only screen and (max-width: '767px') {
      max-width: 100%;
      padding: 0;
    }

    margin: 0 auto;

    .store-page-cover-info {
      // margin-top: -46px;
      width: 100%;

      @media screen and (max-width: 767px) {
        margin-top: 0px;
      }

      .ant-tabs-nav {
        margin: 0;

        // margin-right: 20px;
        // margin-bottom: 20px !important;
        @media screen and (min-width: 992px) {
          width: 1314px;
        }

        .ant-tabs-nav-wrap {
          display: flex;
          justify-content: flex-end;

          &.product-nav {
            display: flex;
            justify-content: flex-start;
          }

          @media screen and (max-width: 767px) {
            border-bottom: 1px solid #e4e4e8;
            background: #ffffff;
            height: 43px;
            justify-content: center;
          }
        }
      }

      .ant-tabs-top>.ant-tabs-nav::before,
      .ant-tabs-top>div>.ant-tabs-nav::before {
        border: 0;
      }

      .ant-tabs-nav-list {
        border-radius: 0;

        .ant-tabs-tab {
          padding: 0;
          margin: 0;

          .ant-tabs-tab-btn {
            margin: 14px 0;
            padding: 0 18px;
            font-size: 16px;
            line-height: 16px;
            color: #808191;
            font-weight: 500 !important;

            @media screen and (min-width: 992px) {
              border-left: solid 1px #a7a7a7;
              // height: 15px;
            }
          }
        }

        .ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: #000;
          }
        }

        .ant-tabs-ink-bar {
          height: 3px;
          background: #5f40af;
          border-top-left-radius: 120px;
          border-top-right-radius: 120px;
        }
      }

      .ant-tabs-content-holder {
        .ant-tabs-tabpane-active {
          width: 100%;
          height: 100%;
        }
      }

      // .store-poster-container {
      //   @media screen and (max-width: 767px) {
      //     background-color: #ffffff;
      //   }

      //   .image-poster-flex {
      //     display: flex;
      //     align-items: center;
      //     height: 413px;

      //     @media screen and (max-width: 767px) {
      //       display: none;
      //     }

      //     .product-poster-section-first {
      //       width: 180px;
      //       height: 100%;

      //       img {
      //         width: 100%;
      //         height: 100%;
      //         border-left: 2px solid #ffffff;
      //         border-bottom: 2px solid #ffffff;
      //         border-top: 2px solid #ffffff;
      //         object-fit: cover;
      //       }
      //     }

      //     .product-poster-section-second {
      //       border-right: 2px solid #ffffff;

      //       .product-image-alignment {
      //         width: 531px;
      //         height: 414px;
      //         position: relative;

      //         .blur-div {
      //           position: absolute;
      //           // background: linear-gradient(90deg, #1e2432 0%, rgba(30, 36, 50, 0) 100%);
      //           width: 100%;
      //           top: 0;
      //           left: 0;
      //           height: 200px;
      //         }

      //         img {
      //           width: 100%;
      //           height: 100%;
      //           border-left: 2px solid #ffffff;
      //           border-bottom: 2px solid #ffffff;
      //           border-top: 2px solid #ffffff;
      //           object-fit: cover;
      //         }
      //       }

      //       .absolute-class {
      //         top: 0;
      //         position: absolute;
      //         width: 531px;
      //         height: 414px;
      //         padding: 24px;

      //         .featured-alignment {
      //           width: 94px;
      //           height: 27px;
      //           background: linear-gradient(94.09deg, #556ee6 0.21%, #6b21a8 100%);
      //           border-radius: 12px;
      //           display: flex;
      //           justify-content: center;
      //           align-items: center;

      //           button {
      //             font-weight: 500;
      //             font-size: 15.4286px;
      //             line-height: 23px;
      //             display: flex;
      //             align-items: center;
      //             color: #ffffff;
      //           }
      //         }

      //         .like-more-alignment {
      //           display: flex;
      //           justify-content: flex-end;
      //           gap: 12px;
      //           align-items: center;
      //           margin-bottom: 198px;

      //           .like-button-alignment {
      //             width: 31px;
      //             height: 31px;
      //             background: #1e2432;
      //             opacity: 0.6;
      //             border-radius: 50%;
      //             display: flex;
      //             justify-content: center;
      //             align-items: center;
      //             cursor: pointer;

      //             svg {
      //               display: flex;
      //               justify-content: center;
      //               align-items: center;
      //               width: 20px;
      //               height: 20px;
      //             }
      //           }

      //           .more-button-alignment {
      //             width: 23px;
      //             height: 23px;
      //             background: #1e2432;
      //             opacity: 0.6;
      //             border-radius: 50%;
      //             display: flex;
      //             justify-content: center;
      //             align-items: center;

      //             img {
      //               display: flex;
      //               justify-content: center;
      //               align-items: center;
      //               width: 14px;
      //               height: 14px;
      //             }
      //           }
      //         }

      //         .feature-product-responsive {
      //           .poster-name-alignment {
      //             h1 {
      //               font-weight: 600;
      //               font-size: 28px;
      //               line-height: 42px;
      //               color: #f8f8f8;
      //               z-index: 99;
      //             }
      //           }

      //           .analytics-container-main {
      //             display: flex;
      //             justify-content: flex-start;
      //             align-items: center;
      //             gap: 12px;

      //             .graph-menu-align {
      //               display: flex;
      //               justify-content: flex-start;
      //               align-items: center;
      //               gap: 6px;

      //               svg {
      //                 width: 14px;
      //                 height: 11.8px;
      //               }

      //               span {
      //                 font-weight: 400;
      //                 font-size: 12px;
      //                 line-height: 18px;
      //                 color: #ffffff;
      //               }
      //             }

      //             .arrow-menu-align {
      //               display: flex;
      //               justify-content: flex-start;
      //               align-items: center;
      //               gap: 6px;

      //               img {
      //                 width: 11px;
      //                 height: 11px;
      //               }

      //               span {
      //                 font-weight: 600;
      //                 font-size: 14px;
      //                 line-height: 21px;
      //                 display: flex;
      //                 align-items: center;
      //                 color: #70c217;
      //               }
      //             }

      //             .dott-icon-align {
      //               width: 4px;
      //               height: 4px;
      //               background: #acb1c0;
      //               border-radius: 50%;
      //             }

      //             .rating-section-main {
      //               display: flex;
      //               justify-content: flex-start;
      //               align-items: center;
      //               gap: 4px;

      //               img {
      //                 margin-bottom: 2px;
      //               }

      //               span {
      //                 font-weight: 600;
      //                 font-size: 16px;
      //                 line-height: 24px;
      //                 color: #ffffff;
      //               }

      //               p {
      //                 font-weight: 400;
      //                 font-size: 14px;
      //                 line-height: 24px;
      //                 color: #ffffff;
      //                 margin-bottom: 0 !important;
      //               }
      //             }
      //           }

      //           .price-analytics-container {
      //             display: flex;
      //             justify-content: flex-start;
      //             align-items: center;
      //             gap: 6px;
      //             margin: 10px 0;

      //             @media screen and (max-width: 767px) {
      //               margin-bottom: 6px;
      //             }

      //             h5 {
      //               font-weight: 600;
      //               font-size: 14px;
      //               line-height: 21px;
      //               display: flex;
      //               align-items: center;
      //               color: #ffffff;
      //             }

      //             span {
      //               font-weight: 500;
      //               font-size: 12px;
      //               line-height: 18px;
      //               display: flex;
      //               align-items: center;
      //               text-decoration-line: line-through;
      //               color: #acb1c0;
      //             }

      //             .discount-menu-align {
      //               width: 61px;
      //               height: 21px;
      //               background: #ff7d23;
      //               border-radius: 11px;
      //               display: flex;
      //               justify-content: center;
      //               align-items: center;

      //               button {
      //                 font-weight: 500;
      //                 font-size: 12px;
      //                 line-height: 18px;
      //                 display: flex;
      //                 align-items: center;
      //                 color: #ffffff;
      //               }
      //             }

      //             .delivery-menu-align {
      //               width: 62px;
      //               height: 21px;
      //               background: #eaeaea;
      //               border-radius: 13.5px;
      //               display: flex;
      //               justify-content: center;
      //               align-items: center;
      //               gap: 2px;

      //               span {
      //                 font-weight: 500;
      //                 font-style: italic;
      //                 font-size: 12px;
      //                 line-height: 18px;
      //                 display: flex;
      //                 align-items: center;
      //                 text-decoration-line: none;
      //                 color: #5f40af;
      //               }
      //             }

      //             @media screen and (max-width: 767px) {
      //               .discount-menu-align {
      //                 display: none;
      //               }

      //               .delivery-menu-align {
      //                 display: none;
      //               }
      //             }
      //           }
      //         }
      //       }
      //     }

      //     .product-poster-section-third {
      //       width: 200px;
      //       height: 100%;

      //       img {
      //         width: 100%;
      //         height: 100%;
      //         border-right: 2px solid #ffffff;
      //         border-bottom: 2px solid #ffffff;
      //         border-top: 2px solid #ffffff;
      //         object-fit: cover;
      //       }
      //     }
      //   }
      // }
    }
  }

  .mobile-view-container {
    position: relative;
    margin: auto;
    width: 100%;

    .relative-div {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 183px;

      @media screen and (max-width: 767px) {
        background: linear-gradient(1.53deg, rgba(2, 2, 2, 0.86) 0.62%, rgba(2, 2, 2, 0) 83.19%);
      }

      left: 0;
    }

    .store-image-contain {
      width: 100%;
      height: 363px;

      @media screen and (max-width: 767px) {
        height: 294px;
      }

      display: flex;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .store-cover-main {
      @media screen and (max-width: 767px) {
        width: 100%;
        height: 100%;

        // padding: 16px;
        .back-header-align {
          position: absolute;
          top: 0;
          width: 100%;
          padding: 14px;

          .header-for-mobileview {
            @media screen and (min-width: 992px) {
              display: none;
            }
          }
        }

        .image-alignment {
          position: absolute;
          bottom: 0;
          width: 100%;

          .image-name-align {
            display: flex;
            gap: 18px;
            align-items: center;
            padding: 14px 28px;
            width: 100%;

            .image-container {
              width: 88px;
              height: 59px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 5px;
              }
            }

            .store-description {
              h2 {
                font-weight: 600;
                font-size: 20px;
                line-height: 28px;
                letter-spacing: 0.228571px;
                color: #ffffff;
              }

              span {
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                letter-spacing: 0.32px;
                color: #ffffff;
              }

              .feedback-alignment {
                span {
                  font-weight: 300;
                  font-size: 10px;
                  line-height: 20px;
                  letter-spacing: -0.3px;
                  color: #ffffff;
                }
              }
            }
          }

          .follower-following-container {
            display: flex;
            justify-content: space-between;
            padding: 14px 28px;
            align-items: center;
            width: 100%;

            .following-followers-align {
              display: flex;
              gap: 44px;
              align-items: center;
              width: 100%;

              .following-alignment {
                p {
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 18px;
                  display: flex;
                  align-items: center;
                  color: #ffffff;
                }

                span {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 18px;
                  display: flex;
                  align-items: center;
                  color: #ffffff;
                }
              }
            }

            .follow-message-container {
              display: flex;
              gap: 10px;
              align-items: center;
              width: 100%;
              justify-content: flex-end;

              .follow-button {
                background: var(--primary-500);
                border-radius: 8px;
                width: 91px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;

                button {
                  font-style: normal;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 21px;
                  color: #f8f8f8;
                }
              }

              .message-alignment {
                background: var(--primary-500);
                border-radius: 8px;
                width: 32px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                  width: 18px;
                  height: 18px;
                }
              }
            }
          }
        }
      }

      @media screen and (min-width: 992px) {
        display: none;
      }
    }

    .store-profile-section {
      max-width: 100%;
      margin: 0 auto;

      .store-section-contain {
        max-width: 1300px;
        margin: 0 auto;
        position: relative;

        @media screen and (max-width: 767px) {
          display: none;
        }
      }
    }
  }
}

.stores-page-mobile {
  .ant-tabs-nav {
    margin-bottom: 20px;

    @media screen and (max-width: 767px) {
      margin-bottom: 0;
    }
  }

  .ant-tabs-top>.ant-tabs-nav::before,
  .ant-tabs-top>div>.ant-tabs-nav::before {
    border: 0;
  }

  .ant-tabs-nav-list {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0;
    padding: 0 22px;

    .ant-tabs-tab {
      padding: 0;
      margin: 0;

      .ant-tabs-tab-btn {
        padding: 8px 28px;

        @media screen and (max-width: 767px) {
          padding: 11px 22px;
        }

        padding-bottom: 16px;
        font-size: 16px;
        line-height: 22px;
        color: rgb(141, 137, 137);
        font-weight: 400 !important;
      }

      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: #000;
          font-weight: 500 !important;
        }

        &:before {
          content: '';
          position: absolute;
          height: 2px;
          background: #5f40af;
          border-radius: 4px 4px 0px 0px;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }
    }

    .ant-tabs-ink-bar {
      display: none;
    }
  }

  .slick-dots {
    width: auto;
    top: -11px;
    right: 40px;
    height: 30px;

    li {
      margin-left: 3px;
      margin-right: 3px;
      width: 8px;

      button {
        width: 8px;
        padding: 0;

        &:before {
          content: '';
          width: 8px;
          height: 8px;
          background: #c4c4c4;
          border-radius: 8px;
          opacity: 1;
        }
      }

      &.slick-active {
        width: 20px;

        button {
          &:before {
            width: 16px;
            background: #5f40af;
          }
        }
      }
    }
  }

  .store-padding {
    padding: 12px 20px;

    .store-text-align {
      h5 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: var(--bookmark-heading-color);

        @media screen and (max-width: 767px) {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: var(--bookmark-heading-color);
        }
      }
    }

    .store-detail-align {
      p {
        @media screen and (max-width: 767px) {
          font-weight: 400;
          font-size: 13px;
          line-height: 24px;
          letter-spacing: 0.1px;
          color: #92929d;
        }
      }
    }
  }

  .user-store-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 16px;
    padding: 0 32px;
    margin-top: 32px;
    margin-bottom: 48px;

    @media screen and (max-width: 767px) {
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 0px;
      padding: 0 0;
      margin-top: 0px;
      margin-bottom: 14px;
    }

    .store-container-align {
      display: flex;
      justify-content: space-between;
      border-radius: 12px;
      box-shadow: 0px 0px 20px rgba(163, 156, 169, 0.15);

      @media screen and (max-width: 767px) {
        box-shadow: none;
        display: block;
      }

      .store-name-logo-alignment {
        display: flex;
        gap: 18px;
        align-items: center;
        padding: 12px 20px;
        border-bottom: 1px solid #f1f1f5;

        img {
          width: 120px;
          height: 90px;
          object-fit: cover;
          padding: 8px;

          @media screen and (max-width: 767px) {
            width: 100px;
            height: 100px;
            object-fit: cover;
            padding: 0;
          }
        }

        .store-detail-page {
          .more-menu-button {
            margin-left: 62px;
            justify-content: flex-end;
            display: flex;
            height: 12px;
            width: 100%;

            @media screen and (min-width: 992px) {
              display: none;
            }

            svg {
              height: 100%;
              width: 18px;
            }
          }

          .mutual-align {
            @media screen and (max-width: 767px) {
              display: none;
            }
          }

          .unfollow-button-align-mobile {
            margin-top: 12px;

            @media screen and (min-width: 992px) {
              display: none;
            }

            width: 150px;
            height: 25px;
            background: #f1f1f5;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;

            button {
              font-weight: 600;
              font-size: 12px;
              line-height: 10px;
              letter-spacing: 0.05em;
              color: #696974;
            }
          }

          h5 {
            font-weight: 600;
            font-size: 15px;
            line-height: 22px;
            color: #000000;
          }
        }
      }

      .unfollow-button-align {
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
    }
  }
}

.store-main-men {
  background: transparent;

  @media (max-width: 576px) {
    background: #1e2432;
  }
}

.store-main-grid {
  display: grid;
  grid-template-columns: 320px 1fr;
  gap: 12px;
  transition: 0.3s ease-in-out;
  overflow: hidden;
  padding: 12px;

  @media only screen and (max-width: '1099px') {
    grid-template-columns: 1fr;
  }

  .filter-sidebar-all-content-alignment {
    padding: 14px;
    height: fit-content;

    @media only screen and (max-width: '1099px') {
      display: none;
    }

    .content-hidden {
      max-height: 0px;
      overflow: hidden;
      transition: 0.3s ease-in-out;
    }

    .content-show {
      max-height: calc(100vh - 0px);
      overflow: hidden;
      transition: 0.3s ease-in-out;
    }

    .colors-box-alignemtn {
      background: #ffffff;
      border: 1px solid #e6e8ec;
      margin: 0 0 13px 0;
      border-radius: 10px;

      .color-body-content {
        .color-body-content-alignment {
          padding: 0 13px 13px 13px;

          .show-more-text {
            span {
              display: block;
              font-weight: 400;
              cursor: pointer;
              font-size: 14px;
              line-height: 21px;
              color: var(--primary-500);
            }
          }

          .button-alignment {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding: 0 0 13px 0;
            gap: 4px;

            button {
              background: #ffffff;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: #495057;
              cursor: pointer;
              border: 1px solid #e4e4e8;
              border-radius: 4px;
              padding: 6px 8px;
            }
          }
        }
      }

      .color-header {
        padding: 13px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: var(--secondary-900);
          margin: 0;
        }

        div {
          svg {
            cursor: pointer;
          }
        }
      }
    }

    .main-filter-categories-box {
      background: #ffffff;
      border: 1px solid #e6e8ec;
      margin: 0 0 13px 0;
      border-radius: 10px;

      .main-filter-details {
        .main-filter-details-alignment {
          padding: 0 11px 11px 11px;

          button:last-child {
            margin: 0;
          }

          button {
            padding: 6px 8px;
            cursor: pointer;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            background: #ffffff;
            display: block;
            margin: 0 0 4px 0;
            border: 1px solid #e4e4e8;
            border-radius: 4px;
            color: #495057;
          }

          .active-class {
            border: 1px solid #556ee6;
            border-radius: 4px;
          }

          span {
            font-weight: 400;
            font-size: 14px;
            padding: 2px 0 0 0;
            line-height: 21px;
            color: var(--primary-500);
            display: block;
            cursor: pointer;
          }
        }
      }

      .main-filter-categories-box-header {
        padding: 13px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: var(--secondary-900);
          margin: 0;
        }

        div {
          svg {
            cursor: pointer;
          }
        }
      }
    }

    .filter-header-content-alignment {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 10px 0;

      p {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
        margin: 0;
      }

      span {
        display: block;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: var(--primary-500);
        cursor: pointer;
      }
    }
  }

  &.store-main-grid-toogle {
    grid-template-columns: repeat(1, 1fr);
    transition: 0.3s ease-in-out;

    @media only screen and (max-width: 767px) {
      padding: 0 4px;
    }
  }
}

.store-filter-box {
  background: #ffffff;
  height: fit-content;
  border-radius: 10px;
  transition: 0.3s ease-in-out;

  &.store-filter-box-hidden {
    display: none;
    transition: 0.3s ease-in-out;
  }
}

.allproduct-container {
  max-width: 100%;
  margin: 0 auto;
  // background: #ffffff;
  min-height: 435px;

  @media only screen and (max-width: '1099px') {
    margin: 0 auto;
    // background: #1e2432;
    min-height: auto;
  }

  @media only screen and (max-width: '576px') {
    margin: 0 auto 64px auto;
  }

  .allproduct-mobile-view-header-alignment {
    display: none;

    @media only screen and (max-width: '1099px') {
      display: block;
    }

    .allproduct-mobile-view-header-flex-alignment {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 16px 0 16px;

      .allproduct-mobile-heading-name-alignment {
        display: flex;
        align-items: center;
        gap: 4px;

        h5 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: var(--secondary-900);
        }

        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #808191;
        }
      }

      .allproduct-search-grid-alignment {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }
  }

  .flex-menu {
    display: flex;
    justify-content: space-between;
    padding: 32px 16px 16px 16px;

    @media screen and (max-width: 1099px) {
      // background-color: #ffffff;
      // background: #1e2432;
      justify-content: space-between;
      padding: 14px 16px;
    }

    // @media screen and (max-width: 767px) {
    //   background-color: #ffffff;
    //   justify-content: space-between;
    //   padding: 0 14px;
    // }

    .dropdown-align {
      p {
        &.sort-text-alignment {
          @media screen and (max-width: 1099px) {
            display: none;
          }
        }
      }

      @media screen and (max-width: 767px) {
        // display: none;
        // padding: 10px;
      }
    }
  }

  .product-details-alignment {
    background: #ffffff;
    // padding: 12px 16px;
    border-radius: 10px;

    @media only screen and (max-width: 799px) {
      // padding: 0 11px;
      border-radius: 0;
      // padding: 0 0 16px 0;
    }
  }

  .product-count {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    @media screen and (max-width: 1099px) {
      display: none;
    }

    .product-count-section {
      display: flex;
      gap: 6px;
      align-items: center;

      h2 {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #495057;
      }

      @media screen and (min-width: 992px) {
        h2 {
          display: none;
        }
      }

      .product-align-count {
        span {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #808191;

          @media screen and (max-width: 767px) {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #808191;
          }
        }
      }
    }

    .image-grid-icon {
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;

      .list-section {
        width: 23px;
        height: 18px;
        cursor: pointer;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    @media screen and (max-width: 767px) {
      .image-grid-icon {
        display: none;
      }
    }
  }

  .product-card-section {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    transition: 0.3s ease-in-out;
    gap: 12px;
    background: #ffffff;

    @media only screen and (max-width: 799px) {
      grid-template-columns: repeat(2, 1fr);
    }

    &.four-col-grid {
      display: grid;
      transition: 0.3s ease-in-out;
      grid-template-columns: repeat(3, 1fr);
      gap: 12px;

      @media screen and (max-width: 1099px) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    @media screen and (max-width: 767px) {
      justify-content: center;
      width: 100%;
      margin: 0 auto;
      gap: 15px;
      padding: 0 0 0 0;
    }
  }

  .list-view-grid-section {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

.search-alignment-for-product {
  background: #f8f8f8;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 738px;
  margin: 0 16px;
  padding: 4px 0;

  @media only screen and (max-width: 767px) {
    display: none;
  }

  .search-icon-alignment {
    img {
      cursor: pointer;
      display: block;
      width: 24px;
      height: 24px;
    }
  }

  .search-input-alignment {
    input {
      background: transparent;
      border: none;
      height: 100%;
      font-family: 'Poppins', sans-serif;
      font-weight: 400;

      .ant-radio-inner,
      .ant-radio-inner:after {
        border-radius: 0 !important;
      }

      .ant-radio-inner:after {
        border: none !important;
      }

      font-size: 20px;
      line-height: 30px;
      color: #acb1c0;
    }
  }
}

.dropdown-modal-details {
  .dropdown-modal-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999999999;
    overflow-y: auto;

    // border-radius: 16px 0 0;
    .dropdown-modal-box-alignment {
      width: 492px;
      position: relative;

      // top: 160px;
      @media only screen and (max-width: '1499px') {
        top: 30px;
      }

      @media only screen and (max-width: '1099px') {
        top: 0px;
      }

      @media only screen and (max-width: '767px') {
        width: 100%;
      }
    }
  }
}

.radio-button-class {

  .ant-radio-inner,
  .ant-radio-inner:after {
    border-radius: 6px;
  }

  .ant-radio-inner:after {
    border: 2px solid #556ee6;
  }
}

button {
  &.filter-button {
    border: 1.5px solid var(--secondary-100);
    border-radius: 8px;
    background: transparent;
    padding: 6px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    cursor: pointer;

    .calnder-flex-alignment {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &.dropdown-gap {
      gap: 60px;

      span {
        @media only screen and (max-width: '1099px') {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          color: var(--secondary-600);
        }
      }
    }

    p {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: var(--secondary-600);

      &.mobile-text-none {
        @media only screen and (max-width: '767px') {
          display: none;
        }
      }
    }

    &:hover {
      background: #f8f8f8;
    }

    @media only screen and (max-width: '1099px') {
      &.men-filter {
        border: 1.5px solid #fff;

        p {
          color: #fff;
        }

        span {
          color: #fff;
        }

        svg {
          path {
            stroke: #fff;
          }
        }
      }
    }
  }
}

.section1,
.section3 {
  min-height: 100vh;
  // background-color: #222;
  // display: flex;
  // justify-content: space-around;
  // align-items: center;
  font-size: 50px;
}

.head {
  display: flex;
  flex-direction: row;
  height: 100vh;
  justify-content: space-around;
  align-items: flex-start;
  padding-top: 60px;
}

.content-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
  height: 400px;
  max-width: 590px;
  width: 590px;
  min-width: 590px;
  padding: 0 0 0 80px !important;
}

.images-box {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.heading {
  opacity: 0;
  position: absolute;
  top: 2em;
  transform: translateY(100%);
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

  .sharing-text {
    max-width: 466px;

    h5 {
      color: #000;
      font-family: 'Poppins', sans-serif;
      font-size: 48px;
      font-weight: 600;
      line-height: 72px;

      @media only screen and (max-width: '767px') {
        font-size: 32px;
        line-height: 48px;
      }
    }

    p {
      color: #000;
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      padding: 18px 0 0 0;
      max-width: 466px;

      @media only screen and (max-width: '767px') {
        max-width: 100%;
        font-size: 14px;
      }
    }
  }

  .chatWithFriendLeftSide {
    // padding: 0 0 0 58px;

    @media only screen and (max-width: '767px') {
      padding: 30px 0;
    }

    .chatWithFriendLottie {
      max-width: 450px;

      @media only screen and (max-width: '767px') {
        max-width: 300px;
      }
    }

    p {
      color: #000;
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      padding: 0 0 0 54px;
      max-width: 440px;

      @media only screen and (max-width: '767px') {
        padding: 10px 0 0 32px;
        font-size: 14px;
        max-width: 280px;
        line-height: 21px;
      }
    }
  }

  .liveShoppingLeftSide {
    // padding: 0 0 0 56px;

    @media only screen and (max-width: '767px') {
      padding: 0;
    }

    h5 {
      color: #000;
      font-family: 'Poppins', sans-serif;
      font-size: 48px;
      font-weight: 600;
      line-height: 72px;

      @media only screen and (max-width: '767px') {
        font-size: 32px;
        line-height: 48px;
      }
    }

    p {
      color: #000;
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      padding: 18px 0 0 0;
      max-width: 400px;

      @media only screen and (max-width: '767px') {
        max-width: 280px;
        font-size: 14px;
        line-height: 21px;
      }
    }
  }

  .productEnthusiastLeftSide {
    // padding: 0 0 0 56px;

    @media only screen and (max-width: '767px') {
      padding: 0;
    }

    h5 {
      color: #000;
      font-family: 'Poppins', sans-serif;
      font-size: 48px;
      font-weight: 600;
      line-height: 72px;

      @media only screen and (max-width: '767px') {
        font-size: 32px;
        line-height: 48px;
      }
    }

    p {
      color: #000;
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      padding: 18px 0 0 0;
      max-width: 400px;

      @media only screen and (max-width: '767px') {
        max-width: 280px;
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
}

.scroll-img {
  // width: 30vw;
  /* // display: none; */
  opacity: 0;
  position: absolute;
  top: 5em;
  width: 100%;
  height: 100%;
  // transition: 1s ease-in-out;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

  .sharing-product-video-alignment {
    border-radius: 24px 0px 0px 24px;
    background: #c1cbf4;
    height: 554px;

    .sharingProductVideoAlignment {
      width: 642px;
      margin: 0 auto;
      padding: 40px 0;
    }
  }

  .chat-video-alignment {
    border-radius: 24px 0px 0px 24px;
    background: #c9b1df;
    height: 608px;

    .chatVideoAlignment {
      width: 607px;
      margin: 0 auto;
      padding: 20px 0;
    }
  }

  .liveShoppingRightSide {
    border-radius: 24px 0px 0px 24px;
    background: #fde3e5;
    height: 608px;
    width: 100%;
    padding: 60px 0;

    @media only screen and (max-width: '767px') {
      height: 216px;
      padding: 0;
    }

    .liveShoppingVideo {
      max-width: 595px;
      margin: 0 auto;

      @media only screen and (max-width: '767px') {
        max-width: 280px;
      }
    }
  }

  .productEnthusiastRightSide {
    border-radius: 24px 0px 0px 24px;
    background: #feefe4;
    height: 608px;
    width: 100%;
    padding: 60px 0;

    @media only screen and (max-width: '767px') {
      height: 207px;
      padding: 0;
    }

    .productEnthusiastVideo {
      max-width: 614px;
      margin: 0 auto;

      @media only screen and (max-width: '767px') {
        max-width: 280px;
      }
    }
  }
}

// .active {
//   opacity: 1;
//   /* // display: block; */
// }

@media only screen and (min-width: 1025px) {

  .section1,
  .section3 {
    font-size: 50px;
  }

  .content-box {
    font-size: 40px;
    padding-left: 5em;
  }
}

@media only screen and (max-width: 1024px) {

  .section1,
  .section3 {
    font-size: 35px;
  }

  .content-box {
    font-size: 30px;
    padding-left: 3em;
    text-align: center;
  }
}

@media only screen and (max-width: 770px) {

  .section1,
  .section3 {
    font-size: 20px;
  }

  .content-box {
    font-size: 25px;
    padding-left: 1em;
  }
}

.store-product-filter-click-drop-alignment-main {
  display: none;
  width: 100%;

  @media (max-width:767px) {
    display: block;
  }

  .store-product-filter-click-drop-alignment {
    border-bottom: 1px solid #eaecef;
    padding: 8px 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 999;
    border-radius: 16px 16px 0 0;

    p {
      width: 33px;
      height: 3px;
      background: #e4e4e8;
      border-radius: 1.5px;
    }
  }
}

.add-product-modal-alignment {
  .add-product-modal-wrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(30, 36, 50, 0.6);
    z-index: 9999999999;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: '767px') {
      // position: static;
      flex-direction: column;
      justify-content: end;
      bottom: 0;
      top: unset;
      // height: auto;
    }

    .add-product-modal-box-alignment {
      width: 375px;
      height: 170px;
      border-radius: 16px;
      background: var(--white);
      padding: 16px 16px 0 16px;

      @media only screen and (max-width: '767px') {
        width: 100%;
        border-radius: 0;
      }

      @media only screen and (max-width: '576px') {
        height: 220px;
      }

      .slick-arrow,
      .slick-next,
      .slick-prev {
        display: flex !important;

        @media (max-width:768px) {
          display: flex !important;
        }

        @media (max-width:576px) {
          display: flex !important;
        }
      }

      .add-product-modal-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 32px 0;

        h4 {
          color: var(--secondary-900);
          font-size: 18px;
          font-weight: 600;
          line-height: 24px;
        }

        .close-icon-alignment {
          width: 24px;
          height: 24px;
          cursor: pointer;

          @media only screen and (max-width: '767px') {
            display: none;
          }
        }
      }

      .add-product-body-details-alignment {
        padding: 32px 0 0 0;

        @media only screen and (max-width: '767px') {
          padding: 32px 16px 0 8px;
        }

        .add-product-silder-alignment {
          position: relative;

          .add-product-box-alignment {
            border-radius: 8px;
            border: 1px dashed var(--primary-500);
            background: var(--primary-50);
            width: 54px;
            height: 54px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
          }

          p {
            padding: 8px 0 0 0;
            color: var(--primary-500);
            text-align: center;
            font-size: 10px;
            font-weight: 400;
            line-height: 14px;
          }

          .added-product-detais-alignment {
            .added-profile-img-alignment {
              width: 56px;
              height: 56px;
              margin: 0 auto;
              position: relative;
              cursor: pointer;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
                border-radius: 8px;
              }

              .seclected-details-alignment {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%);
                border-radius: 8px;
                cursor: pointer;
              }
            }

            p {
              color: var(--secondary-900);
              text-align: center;
              font-size: 10px;
              font-weight: 400;
              line-height: 14px;
              padding: 7px 0 0 0;
            }
          }

          .slick-arrow {
            width: 31px !important;
            height: 31px !important;

            &.slick-prev {
              display: none !important;
            }

            &.slick-next {
              top: 28px !important;
              right: 12px !important;
              z-index: 99 !important;
            }

            &:hover {
              background: unset !important;
            }
          }

          &::before {
            content: '';
            position: absolute;
            width: 70px;
            height: 100%;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 98.27%);
            right: 0;
            top: 0;
            z-index: 9;
          }
        }
      }
    }
  }
}

.shre-all-feed-details-modal-body {
  padding: 8px;
  border-radius: 10px;
  border: 1px solid var(--light-grey);
  background: var(--light-grey);
  padding: 8px 9px;

  &:hover {
    .slick-arrow {
      display: flex;
    }
  }

  .slick-arrow {
    display: none;
    transition: 0.15s ease-in-out;
  }

  .slick-prev {
    left: 10px !important;
    z-index: 99;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.6) !important;

    img {
      width: 8px;
    }
  }

  .slick-next {
    right: 15px !important;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.6) !important;

    img {
      width: 8px;
    }
  }

  .collection-product-grid-view {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    // gap: 4px;
    margin: 0 0 16px 0;
  }

  .share-all-feed-details-box-alignment {
    padding: 0 2px;
    width: 100%;

    .product-card-section {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      transition: 0.3s ease-in-out;
      gap: 12px;
      background: #ffffff;

      @media only screen and (max-width: 799px) {
        grid-template-columns: repeat(2, 1fr);
      }

      &.four-col-grid {
        display: grid;
        transition: 0.3s ease-in-out;
        grid-template-columns: repeat(3, 1fr);
        gap: 12px;

        @media screen and (max-width: 1099px) {
          grid-template-columns: repeat(2, 1fr);
        }
      }

      @media screen and (max-width: 767px) {
        justify-content: center;
        width: 100%;
        margin: 0 auto;
        gap: 15px;
        padding: 0 0 0 0;
      }
    }

    .slick-list {
      .slick-track {
        margin: 0 0 15px 0 !important;
      }
    }

    .collection-product-grid-view {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      // gap: 4px;
      margin: 0 0 16px 0;
    }
  }

  .shared-profile-name-alignment {
    padding: 16px 24px 16px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .shared-profile-name-details {
      display: flex;
      align-items: center;
      gap: 20px;

      .shared-feed-profile-alignment {
        .bookMarkCollectionCardDetails {
          position: relative;
          width: 100%;
          height: 50px;
          cursor: pointer;

          .bookMarkCollectionCardImg {
            width: 50px;
            height: 50px;
            border-radius: 3px;
            border: 1px solid #fff;
            background: #fff;
            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
            transform: rotate(-6.983deg);

            img {
              width: 100%;
              height: 100%;
              display: block;
              border-radius: 3px;
              object-fit: cover;

              @media only screen and (max-width: '767px') {
                border-radius: 10px;
                object-position: center;
              }
            }

            &:nth-child(2) {
              position: absolute;
              top: 0;
              left: 5px;
              transform: rotate(0);
            }

            &:nth-child(3) {
              position: absolute;
              top: 0;
              left: 10px;
              transform: rotate(5.357deg);
              z-index: 9;
            }
          }

          .bookMarkCollectionCardImg2 {
            width: 100%;
            height: 100%;
            border-radius: 3px;
            border: 1px solid #fff;
            background: #fff;
            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
            position: absolute;
            top: 0;
            left: 10px;

            @media only screen and (max-width: '767px') {
              border: 1px solid #fff;
              border-radius: 3px;
            }

            img {
              width: 100%;
              height: 100%;
              display: block;
              border-radius: 3px;
              object-fit: cover;

              @media only screen and (max-width: '767px') {
                border-radius: 3px;
              }
            }
          }

          .bookMarkCollectionCardImg3 {
            width: 100%;
            height: 100%;
            border-radius: 3px;
            border: 1px solid #fff;
            background: #fff;
            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
            position: absolute;
            top: 0;
            left: 20px;
            transform: rotate(4.357deg);

            @media only screen and (max-width: '767px') {
              border: 1px solid #fff;
              border-radius: 10px;
            }

            img {
              width: 100%;
              height: 100%;
              display: block;
              border-radius: 3px;
              object-fit: cover;

              @media only screen and (max-width: '767px') {
                border-radius: 3px;
              }
            }
          }
        }
      }

      .shared-all-profile-name {
        h6 {
          color: var(--secondary-900);
          font-size: 16px;
          font-weight: 600;
          line-height: 21px;
          cursor: pointer;
        }

        .name-child-details-alignment {
          padding: 4px 0 0 0;
          display: flex;
          align-items: center;
          gap: 8px;

          span {
            color: var(--secondary-500);
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
          }

          p {
            width: 2px;
            height: 2px;
            background: var(--secondary-300);
          }
        }
      }
    }

    .shared-like-alignment {
      width: 32px;
      height: 32px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .slick-arrow {
    &.slick-prev {
      left: 16px !important;
      z-index: 999;
    }

    &.slick-next {
      right: 16px !important;
    }

    &:hover {
      background: #fff !important;
    }
  }
}

.mobile-view-sticky-header {
  // position: sticky;
  // top: 80px;
  background: #fff;
  // z-index: 99;
  transition: 0.4s ease-in-out;
  // padding: 14px 0 !important;
  padding: 14px 16px !important;
  display: none !important;

  @media only screen and (max-width: '1024px') {
    display: flex !important;
    gap: 14px;

    &.men-filter-bg {
      background: #1e2432;

      @media (max-width: 576px) {
        padding: 0 0 14px 0 !important;
      }
    }
  }
}

.mobile-view-pagination-alignment {
  display: none;

  @media only screen and (max-width: '1024px') {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &.menCategoryBgAlignment {
      background: #1e2432;
    }
  }

  @media only screen and (max-width: '576px') {
    margin: 0;
  }
}

.removeProductAlignment {
  border-radius: 10px;
  background: var(--primary-50);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 30px;

  .remove-product-details-alignment {
    display: flex;
    flex-direction: column;
    gap: 22px;
    align-items: center;

    h6 {
      color: var(--violet-300);
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
  }

  .undo-button-alignment {
    button {
      padding: 4px 22px;
      color: var(--primary-500);
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 21px;
      cursor: pointer;
      border: none;
    }
  }
}

.all-product-mobile-view-open-alignment {
  @media only screen and (max-width: '1024px') {
    padding: 0 4px;
    // position: sticky;
    // top: 0 !important;
    // z-index: 999;
    // left: 0;
    // background: #1e2432;
  }
}

.man-all-product-mobile-view-padding-alignment {
  @media only screen and (max-width: '1024px') {
    padding: 0 16px !important;
  }
}

.filter-category-mobile-view {
  padding: 14px 0 !important;
}

.mobile-view-product-heading-alignment {
  display: none;

  @media only screen and (max-width: '1024px') {
    display: block;

    .mobile-view-heading-flex-alignment {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.feed-no-data-box-alignment {
  width: 100%;
  border-radius: 16px;
  background: var(--primary-50);
  height: calc(100vh - 560px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  margin: 0 0 16px 0;

  @media only screen and (max-width: '1024px') {
    width: calc(100% - 32px);
    margin: 0 16px 16px 16px;
    height: 173px;
  }

  .mobile-view-no-data-text-alignment {
    span {
      color: var(--violet-300);
      font-size: 12px;
      font-style: italic;
      font-weight: 400;
      line-height: 18px;

      @media only screen and (max-width: '1024px') {
        max-width: 145px;
      }
    }
  }
}