.sellerCustomersSection {
    margin: 0 0 16px 0;

    .sellerCustomersAlignment {
        .sellerCustomersBox {
            background: var(--white);
            border-radius: 10px;
            padding: 22px 32px;

            .sellerCustomersBoxHeding {

                display: flex;
                align-items: center;
                justify-content: space-between;

                .sellerCustomersBoxHedingFlex {

                    display: flex;
                    align-items: center;
                    gap: 8px;

                    h5 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 36px;
                        color: var(--secondary-900);

                    }

                    .customersTotalBoxAlignment {
                        background: var(--secondary-50);
                        border-radius: 10px;
                        padding: 0 8px;

                        span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 20px;
                            color: var(--secondary-500);
                        }
                    }
                }

                .rightArrowAlignment {
                    cursor: pointer;
                }
            }

            .sellerCustomersBodyAlignment {
                padding: 30px 0 0 0;
                height: auto;
                max-height: 330px;
                overflow-y: auto;

                .sellerCustomersDetailsAlignment {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 0 0 16px 0;

                    &:last-child {
                        margin: 0;
                    }

                    .sellerCustomersDetailsProfileFlex {
                        display: flex;
                        align-items: center;
                        gap: 16px;

                        .sellerCustomersProfileImg {
                            width: 36px;
                            height: 36px;
                            min-width: 36px;
                            max-width: 36px;

                            img {
                                width: 100%;
                                height: 100%;
                                border-radius: 50%;
                                object-fit: cover;
                            }
                        }

                        h6 {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            letter-spacing: 0.1px;
                            color: var(--secondary-900);
                        }
                    }

                    .customerMessageDetailsFlex {
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        .customerTotalNumberAlignment {
                            padding: 1px 6px;
                            background: #D21414;
                            border-radius: 50%;

                            span {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 20px;
                                color: #FFFFFF;
                            }
                        }

                        .sellerCustomersMessageBox {
                            width: 32px;
                            height: 32px;
                            background: var(--primary-50);
                            border-radius: 8px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img {
                                max-width: 100%;
                                height: auto;
                            }
                        }
                    }
                }
            }
        }

    }
}