.mobileViewSelectCategoryModalAlignment {
    padding: 16px;

    .mobileViewSelectCategoryModalHeader {
        padding: 0 8px 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h6 {
            color: var(--secondary-900);
            font-family: 'Poppins', sans-serif;
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
        }

        a {
            color: var(--primary-500);
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }
    }

    .mobileViewSelectCategoryModalBodyAlignment {
        padding: 20px 4px;

        .mobileViewSelectCategoryOptionAlignment {
            padding: 0 0 24px 0;

            .mobileViewMainCategoryNameAlignment {
                display: grid;
                grid-template-columns: 24px 1fr;
                gap: 8px;
                padding: 0 0 21px 0;

                .mobileCategoryIcon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                h6 {
                    color: var(--secondary-900);
                    font-family: 'Poppins', sans-serif;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 27px;
                }
            }

            .mobileChildCategoryMain {
                padding: 0 0 24px 36px;

                .mobileViewChildCategoryAlignment {
                    display: grid;
                    grid-template-columns: 24px 1fr;
                    gap: 8px;

                    // &:last-child {
                    //     padding: 0 0 0 36px;
                    // }

                    .mobileCategoryIcon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    p {
                        color: var(--secondary-900);
                        font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                    }
                }

                .mobileViewChildCategoryOptionAlignment {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 16px;
                    padding: 21px 0 0 40px;

                    &.tabShowArea {
                        max-height: 120px;
                        overflow-y: auto;
                        transition: 0.3s ease-in-out;
                    }

                    &.tabHiddenArea {
                        max-height: 0px;
                        overflow: hidden;
                        transition: 0.3s ease-in-out;
                        padding: 0;
                    }

                    .mobileViewChildCategoryOptionName {
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        .mobileInputCheckboxAlignment {
                            width: 18px;
                            height: 18px;

                            input[type="checkbox"] {
                                width: 100%;
                                height: 100%;
                                margin: 0;
                                padding: 0;
                            }
                        }

                        p {
                            color: var(--secondary-900);
                            font-family: 'Poppins', sans-serif;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 21px;
                        }
                    }
                }
            }
        }
    }
}

.mainTabShowArea {
    max-height: 288px;
    overflow-y: auto;
    transition: 0.3s ease-in-out;
}

.mainTabHiddenArea {
    max-height: 0px;
    overflow: hidden;
    transition: 0.3s ease-in-out;
    padding: 0;
}