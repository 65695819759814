.dashboard-content-wrapper {
  .content-date {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: #808191;
    margin-bottom: 34px;
  }

  .chart-card {
    background-color: white;
    border-radius: 10px;
    width: 100%;
    // height: 254px;
    margin-top: 35px;

    .chart-title {
      font-family: Poppins;
      font-style: normal;
      font-weight: 600 !important;
      font-size: 14px;
      line-height: 21px;
      color: #000000;
    }

    .chart-description {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #808191;
    }

    .selectView {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal !important;
      font-size: 12px;
      line-height: 18px;
      color: #495057;
    }
  }

  .Bookmark-wrapper {
    margin-top: 35px;
    height: 280px;
    background-color: #ffffff;
    padding: 10px 20px;
    border-radius: 10px;

    .SimpleGallery {
      padding-top: 10px;
      min-width: 400px;
      max-width: 600px;
      width: 100%;
      height: 100%;

      .item {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .item-image {
          min-width: 56px;
          max-width: 56px;
          height: 56px;
          border: 1px solid #f3f3f3;
          box-sizing: border-box;
          border-radius: 6px;

          img {
            width: 56px;
            height: 56px;
          }
        }

        .item-title {
          padding-left: 13px;
          font-family: Poppins;
          font-style: normal;
          font-weight: 500 !important;
          font-size: 12px;
          line-height: 20px;
          color: #495057;
        }
      }
    }
  }

  .bookmark-title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500 !important;
      font-size: 14px;
      line-height: 20px;
      color: #202224;
    }

    .bookmark-description {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal !important;
      font-size: 12px;
      line-height: 18px;
      color: #808191;
    }
  }

  .postoverview-wrapper {
    height: 410px;
    background-color: #4e69eb;
    margin-top: 35px;
    padding: 10px 20px;
    border-radius: 10px;

    h2 {
      font-family: Poppins;
      font-style: normal;
      font-weight: 500 !important;
      font-size: 14px;
      line-height: 20px;
      color: hsl(0, 0%, 100%);
    }

    .custom-card-header {
      justify-content: space-between;
      display: flex;
    }

    .selectView {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal !important;
      font-size: 12px;
      line-height: 18px;
      color: #495057;
    }

    .graph-items {
      padding-top: 20px;
      padding-right: 5px;

      .postoverview-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 65px;

        .text-part {
          // display: flex;
          justify-content: flex-start;
          align-items: center;

          .text-kind {
            font-family: Poppins;
            font-style: normal;
            font-weight: 500 !important;
            font-size: 12px;
            line-height: 20px;
            color: #ffffff;
          }

          .text-ranking {
            padding-top: 5px;
            display: flex;
            justify-content: flex-start;
            height: 20px;

            .count {
              font-family: Poppins;
              font-style: normal;
              font-weight: 500 !important;
              font-size: 18px;
              line-height: 20px;
              color: #ffffff;
            }

            .rise {
              padding-top: 5px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              font-family: Poppins;
              font-style: normal;
              font-weight: 500 !important;
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}

.card-wrapper-alignment {
  background: #ffffff;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 16px;

  @media only screen and (max-width: '767px') {
    padding: 16px;
  }

  .card-details-main-profile-alignment {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    display: flex;
    align-items: center;
    gap: 8px;

    .card-main-details-alignment {
      // padding: 0 0 0 22px;
      position: relative;

      @media only screen and (max-width: '767px') {
        padding: 0;
      }

      // &.orange-color {
      //   &::before {
      //     background-color: #f19e5f;
      //   }
      // }

      // &.purple-color {
      //   &::before {
      //     background-color: #8982f7;
      //   }
      // }

      // &.blue-color {
      //   &::before {
      //     background-color: #86bcfa;
      //   }
      // }

      // &.pink-color {
      //   &::before {
      //     background-color: #f5c4ef;
      //   }
      // }

      // &::before {
      //   content: '';
      //   position: absolute;
      //   width: 6px;
      //   height: 71px;
      //   border-radius: 10px;
      //   top: 0;
      //   left: 0;
      // }

      span {
        // padding: 0 0 0 3px;
        color: var(--secondary-900);
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;

        @media only screen and (max-width: '767px') {
          font-size: 13px;
          line-height: 20px;
        }
      }

      p {
        span {
          padding: 3px 0 0 0;
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 32px;
          line-height: 48px;
          color: #495057;

          @media only screen and (max-width: '767px') {
            padding: 15px 0 0 4px;
            font-size: 22px;
            line-height: 33px;
          }
        }
      }
    }

    .icon-alignment {
      background: rgba(241, 158, 95, 0.1);
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      img {
        width: 24px;
        height: 24px;
        display: block;
      }
    }
  }

  .card-bottom-details-alignment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 24px 9px 24px;

    @media only screen and (max-width: '767px') {
      padding: 12px 0 0 0;
      gap: 16px;
    }

    span {
      color: var(--secondary-900);
      font-family: 'Poppins', sans-serif;
      font-size: 32px;
      font-weight: 600;
      line-height: 38px;

      @media only screen and (max-width: '767px') {
        font-size: 24px;
        line-height: 28px;
      }
    }

    .total-range-alignment {
      p {
        font-family: 'Poppins', sans-serif;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        color: var(--secondary-500);
      }

      span {
        font-family: 'Poppins', sans-serif;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        color: #1d8603;
      }
    }
  }
}

.order-statistics-section {
  margin: 16px 0 0 0;
  // background: #ffffff;
  border-radius: 20px;
  // padding: 40px 60px;
  width: 100%;

  .order-statistics-flex {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    @media only screen and (max-width: '767px') {
      grid-template-columns: repeat(1, 1fr);
    }

    .order-statistics-details-section {
      border-radius: 10px;
      background: #fff;
      box-shadow: 0px 5px 50px 0px rgba(144, 143, 143, 0.1);
      // padding: 22px 32px;
      height: 444px;

      @media only screen and (max-width: '1024px') {
        padding: 16px;
        height: 100%;
      }

      .order-statistics-header-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 22px 32px 0 32px;

        h6 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 24px;
          line-height: 33px;
          color: var(--secondary-900);

          @media only screen and (max-width: '1024px') {
            font-size: 20px;
            line-height: 28px;
          }

          @media only screen and (max-width: '768px') {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }

      .order-statistics-chart-details-alignment {
        padding: 60px 32px 0 32px;
        display: flex;
        align-items: center;
        gap: 76px;

        @media only screen and (max-width: '1024px') {
          flex-direction: column;
          justify-content: center;
          gap: 32px;
        }

        .chart-size-alignment {
          min-width: 258px;
          width: 258px;
          max-width: 258px;
          height: 258px;

          @media only screen and (max-width: '1024px') {
            min-width: 185px;
            width: 185px;
            max-width: 185px;
            height: 185px;
          }

          img {
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 50%;
          }
        }

        .chart-all-area-name-details-alignment {
          width: 100%;

          .chart-range-alignment {
            padding: 0 0 22px 0;

            @media only screen and (max-width: '1024px') {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0 0 8px 0;
            }

            &:last-child {
              padding: 0;
            }

            .chart-range-top-flex-alignment {
              display: flex;
              align-items: center;
              gap: 8px;

              .chart-color-round {
                p {
                  width: 8px;
                  height: 8px;
                  border-radius: 50%;
                  background: #1d8603;

                  &.second-color {
                    background: #f5c4ef;
                  }

                  &.third-color {
                    background: #e87220;
                  }

                  &.four-color {
                    background: #e71616;
                  }
                }
              }

              p {
                color: var(--secondary-900);
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;

                @media only screen and (max-width: '1024px') {
                  font-size: 14px;
                  line-height: 18px;
                }
              }
            }

            .area-color-number-alignment {
              display: flex;
              align-items: center;
              gap: 6px;

              h6 {
                color: var(--secondary-500);
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;

                @media only screen and (max-width: '1024px') {
                  font-size: 14px;
                  line-height: 18px;
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
    }

    .order-analysis-sections {
      width: 100%;
      border-radius: 10px;
      background: #fff;
      box-shadow: 0px 5px 50px 0px rgba(144, 143, 143, 0.1);
      // padding: 22px 32px;
      height: 444px;

      @media only screen and (max-width: '1024px') {
        height: 100%;
        padding: 16px;
      }

      .order-analysis-header-sections {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 22px 32px 0 32px;

        h5 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 22px;
          line-height: 33px;
          color: var(--secondary-900);

          @media only screen and (max-width: '1024px') {
            font-size: 20px;
            line-height: 26px;
          }
        }

        .order-analysis-dropdown-alignment {
          border: 1px solid #acb1c0;
          border-radius: 10px;
          display: flex;
          align-items: center;
          padding: 5px 20px;
          gap: 10px;

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #acb1c0;
          }

          .drop-down-alignment {
            img {
              cursor: pointer;
            }
          }
        }
      }

      .order-analysis-range-overrange-details-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 42px 32px 0 32px;

        @media only screen and (max-width: '1024px') {
          padding: 36px 0 0 0;
        }

        h6 {
          color: var(--secondary-500);
          font-family: 'Poppins', sans-serif;
          font-size: 32px;
          font-weight: 600;
          line-height: 36px;
        }

        .order-analysis-rage-right-side-alignment {
          display: flex;
          align-items: flex-end;
          gap: 26px;

          @media only screen and (max-width: '1024px') {
            gap: 18px;
          }

          @media only screen and (max-width: '768px') {
            flex-direction: column;
            gap: 0px;
            align-items: flex-start;
          }

          .order-analysis-rage-arrow-alignment {
            display: flex;
            align-items: center;

            @media only screen and (max-width: '768px') {
              gap: 4px;
            }

            svg {
              @media only screen and (max-width: '768px') {
                width: 16px;
                height: 16px;
              }
            }

            h6 {
              color: #1d8603;
              font-family: 'Poppins', sans-serif;
              font-size: 32px;
              font-weight: 400;
              line-height: 36px;

              @media only screen and (max-width: '768px') {
                font-size: 24px;
                line-height: 28px;
              }

              @media only screen and (max-width: '1024px') {
                color: #1d8603;
                font-family: 'Poppins', sans-serif;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
              }
            }
          }

          p {
            color: var(--secondary-500);
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;

            @media only screen and (max-width: '768px') {
              font-size: 12px;
              line-height: 18px;
            }
          }
        }
      }

      .order-analysis-rangeArea-alignment {
        padding: 40px 0 0 0;
        height: 280px;


        @media only screen and (max-width: '768px') {
          padding: 32px 0 0 0;
        }

        h6 {
          color: var(--secondary-500);
          font-family: 'Poppins', sans-serif;
          font-size: 32px;
          font-weight: 600;
          line-height: 36px;
        }
      }
    }
  }
}

.mobile-view-order-statistics-section {
  display: none;

  @media only screen and (max-width: '767px') {
    display: none;
    margin: 16px 0 0 0;
  }

  .mobile-view-statics-bg {
    background: #ffffff;
    padding: 16px;
    border-radius: 10px;

    .mobile-view-statics-header-alignment {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h5 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        color: var(--secondary-900);
      }

      .mobile-order-analysis-dropdown-alignment {
        border: 1px solid #acb1c0;
        border-radius: 10px;
        display: flex;
        align-items: center;
        padding: 5px 20px;
        gap: 10px;

        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #acb1c0;
        }

        .drop-down-alignment {
          img {
            cursor: pointer;
          }
        }
      }
    }

    .mobile-statistics-details-alignment {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .mobile-chart-size-alignment {
        min-width: 165px;
        width: 165px;
        max-width: 165px;
        height: 165px;

        img {
          width: 100%;
          height: 100%;
          display: block;
          border-radius: 50%;
        }
      }

      .mobile-chart-all-area-name-details-alignment {
        padding: 12px 0 0 0;

        .chart-range-alignment {
          padding: 0 0 12px 0;

          &:last-child {
            padding: 0;
          }

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: #808191;
          }

          .area-color-number-alignment {
            display: flex;
            align-items: center;
            gap: 6px;

            .chart-color-round {
              p {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background: #8982f7;

                &.second-color {
                  background: #f5c4ef;
                }

                &.third-color {
                  background: #86bcfa;
                }

                &.four-color {
                  background: #f19e5f;
                }
              }
            }

            h6 {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 15px;
              line-height: 22px;
              color: #495057;
            }
          }
        }
      }
    }

    .mobile-order-analysis-rangeArea-alignment {
      padding: 19px 0 0 0;
      height: 299px;

      img {
        height: 100%;
      }
    }
  }
}

.bootmark-save-post-section {
  margin: 16px 0 0 0;

  @media only screen and (max-width: '767px') {
    margin: 16px 0 0 0;
  }

  .bookmark-save-post-alignment {
    // display: grid;
    // grid-template-columns: repeat(2, 1fr);
    // gap: 34px;

    // @media only screen and (max-width: '767px') {
    //   grid-template-columns: repeat(1, 1fr);
    //   gap: 16px;
    // }
    width: 100%;

    .bookmark-box-alignment {
      background: #ffffff;
      border-radius: 20px;
      padding: 24px 34px;
      width: 100%;

      @media only screen and (max-width: '767px') {
        padding: 16px;
        width: 100%;
        overflow-x: auto;
      }

      .bookmark-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h5 {
          color: var(--secondary-900);

          /* Title 1-Semibold */
          font-family: 'Poppins', sans-serif;
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;

          @media only screen and (max-width: '767px') {
            font-size: 15px;
            line-height: 22px;
            color: var(--secondary-900);
          }
        }

        .see-more-alignment {
          a {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            color: var(--primary-500);
            cursor: pointer;
          }

          @media only screen and (max-width: '767px') {
            display: none;
          }
        }

        .right-arrow-alignment {
          display: none;

          @media only screen and (max-width: '767px') {
            display: block;
          }

          img {
            cursor: pointer;
          }
        }
      }

      .bookmark-tab-alignment {
        margin: 40px 0 0 0;

        @media only screen and (max-width: '767px') {
          margin: 16px 0 0 0;
        }

        ul {
          display: flex;
          align-items: center;
          gap: 40px;
          border-bottom: 1px solid #eaecef;
          padding: 0 0 5px;

          @media only screen and (max-width: '1499px') {
            gap: 20px;
          }

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            color: #808191;
            cursor: pointer;
            position: relative;

            @media only screen and (max-width: '767px') {
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              color: #808191;
            }

            &:hover {
              color: #495057;
              font-weight: 500;

              &::before {
                position: absolute;
                content: '';
                height: 3px;
                width: 100%;
                background: var(--primary-500);
                bottom: -6px;
                right: 0;
              }
            }

            &.bookmark-tab-active {
              color: #495057;

              &::before {
                position: absolute;
                content: '';
                height: 3px;
                width: 100%;
                background: var(--primary-500);
                bottom: -6px;
                right: 0;
              }
            }
          }
        }
      }

      .bookmark-post-details-alignment {
        margin: 24px 0 0 0;
        height: auto;
        max-height: 510px;
        overflow-y: auto;

        @media only screen and (max-width: '767px') {
          margin: 12px 0 0 0;
          overflow-x: auto;
          width: 100%;
        }

        .bookmark-post-details-grid-alignment {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 24px 90px;

          @media only screen and (max-width: '1499px') {
            gap: 24px;
          }

          @media only screen and (max-width: '767px') {
            display: flex;
            align-items: center;
            gap: 32px;
          }

          .bookmark-post-details-gridItem-alignment {
            @media only screen and (max-width: '767px') {
              max-width: 100px;
              width: 100px;
              min-width: 100px;
            }

            .bookmark-post-details {
              img {
                width: 100%;
                height: 134px;
                display: block;
                object-fit: cover;

                @media only screen and (max-width: '767px') {
                  height: 100px;
                }
              }

              .bookmark-post-name-details {
                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 400;
                  font-size: 15px;
                  line-height: 22px;
                  color: #495057;
                  margin: 3px 0 0 0;

                  span {
                    font-weight: 600;
                    color: var(--secondary-900);
                  }

                  @media only screen and (max-width: '767px') {
                    font-size: 10px;
                    line-height: 15px;

                    span {
                      font-size: 13px;
                      line-height: 19.5px;
                    }
                  }
                }

                h5 {
                  margin: 3px 0 0 0;
                  font-family: 'Poppins', sans-serif;
                  font-weight: 700;
                  font-size: 17px;
                  line-height: 26px;
                  color: var(--secondary-900);

                  @media only screen and (max-width: '767px') {
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 22px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .save-box-alignment {
      background: #ffffff;
      border-radius: 20px;
      padding: 24px 32px;

      @media only screen and (max-width: '767px') {
        padding: 16px;
        overflow-x: auto;
        width: 100%;
      }

      .save-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h5 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 22px;
          line-height: 33px;
          color: var(--secondary-900);

          @media only screen and (max-width: '767px') {
            font-size: 15px;
            line-height: 22px;
            color: var(--secondary-900);
          }
        }

        .see-more-alignment {
          a {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            color: var(--primary-500);
            cursor: pointer;

            @media only screen and (max-width: '767px') {
              font-size: 14px;
            }
          }
        }

        .right-arrow-alignment {
          display: none;

          @media only screen and (max-width: '767px') {
            display: none;
          }

          img {
            cursor: pointer;
          }
        }
      }

      .save-post-details-alignment {
        margin: 24px 0 0 0;

        @media only screen and (max-width: '1024px') {
          margin: 24px 0 0 0;
          overflow-x: auto;
          width: 100%;
        }

        .save-post-details-grid-alignment {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          // gap: 32px 27px;
          gap: 16px;

          @media only screen and (max-width: '1499px') {
            grid-template-columns: repeat(2, 1fr);
          }

          // @media only screen and (max-width: '1024px') {
          //   display: flex;
          //   align-items: center;
          //   gap: 20px;
          // }

          @media only screen and (max-width: '1024px') {
            display: flex;
            align-items: center;
            gap: 24px;
          }

          .save-post-details-gridItem-alignment {
            .save-post-details {
              margin-bottom: 14px;

              @media only screen and (max-width: '767px') {
                width: 176px;
                max-width: 120px;
                min-width: 120px;
                height: 132px;
              }

              .save-post-img-alignment {

                // width: 176px;
                // height: 132px;
                img {
                  width: 100%;
                  height: 134px;
                  display: block;
                  border-radius: 6px 6px 0px 0px;
                  object-fit: cover;

                  @media only screen and (max-width: '767px') {
                    height: 90px;
                  }
                }

                video {
                  width: 176px;
                  height: 132px;
                  display: block;
                  border-radius: 6px 6px 0px 0px;
                  object-fit: cover;

                  @media only screen and (max-width: '767px') {
                    height: 90px;
                  }
                }

                iframe {
                  border-radius: 6px 6px 0px 0px;
                }

                // .twitter-contain {
                //   .twitter-tweet .twitter-tweet-rendered {
                //     iframe {
                //       body {
                //         .css-1dbjc4n .r-13awgt0 .r-12vffkv {
                //           .css-1dbjc4n .r-1ets6dv .r-1q9bdsx .r-rs99b7 .r-1loqt21 .r-vakc41 .r-y54riw {
                //             min-width: 100px !important;
                //           }
                //         }
                //       }
                //     }
                //     min-width: 10px !important;
                //   }
                // }
              }

              .save-post-name-details {
                background: #ffffff;
                border-radius: 0px 0px 6px 6px;
                padding: 5px 10px;
                box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

                @media only screen and (max-width: '767px') {
                  padding: 4px;
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: #495057;

                  @media only screen and (max-width: '767px') {
                    font-size: 10px;
                    line-height: 15px;
                    color: #495057;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }

                .save-post-option-details {
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  gap: 12px;
                  margin: 8px 0 0 0;

                  .option-alignment {
                    display: flex;
                    align-items: center;
                    gap: 3px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 400;
                      font-size: 10px;
                      line-height: 15px;
                      color: #808191;
                    }
                  }
                }
              }
            }
          }

          .channelDetailsUpcomingSliderAlignment {
            @media only screen and (max-width: '1024px') {
              width: 250px;
              min-width: 250px;
              max-width: 250px;
            }

            .channelDetailsUpcomingSliderImgAlignment {
              width: 100%;
              height: 352px;
              position: relative;

              @media only screen and (max-width: '767px') {
                height: 352px;
              }

              &:before {
                position: absolute;
                border-radius: 0px 0px 10px 10px;
                border-radius: 10px;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) -11.35%, rgba(0, 0, 0, 0.77) 99.99%);
                content: '';
                bottom: 0;
                left: 0;
                height: 100%;
                width: 100%;
                z-index: 9;

                @media only screen and (max-width: '767px') {
                  height: 100%;
                }
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
                border-radius: 10px;
              }

              video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
                border-radius: 10px;
              }

              .channelDetailsUpcomingSliderAllDetails {
                position: absolute;
                top: 0;
                right: 0;
                padding: 16px;
                width: 100%;
                height: 100%;
                z-index: 99;

                .channelDetailsUpcomingAllDetails {
                  display: flex;
                  align-items: flex-start;
                  justify-content: space-between;
                  flex-direction: column;
                  height: 100%;

                  // @media only screen and (max-width:"767px") {
                  //   flex-direction: row;
                  //   align-items: flex-end;
                  // }

                  .channelDetailsUpcomingAndViewDetailsALignment {
                    border-radius: 10px;
                    padding: 1px 4px 4px 4px;
                    text-align: end;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    img {
                      width: auto;
                      cursor: pointer;
                    }

                    span {
                      color: #495057;
                      text-align: center;
                      font-family: 'Poppins', sans-serif;
                      font-size: 13px;
                      font-weight: 500;
                      line-height: 19px;
                      display: block;
                      text-align: center;
                      padding: 0 0 2px 0;
                    }

                    .channelDetailsDateBOxAlignment {
                      width: 40px;
                      height: 40px;
                      border-radius: 8px;
                      background: var(--secondary-50);
                      display: flex;
                      align-items: center;
                      justify-content: center;

                      p {
                        color: var(--secondary-900);
                        text-align: center;
                        font-family: 'Poppins', sans-serif;
                        font-size: 24px;
                        font-weight: 600;
                        line-height: 36px;
                      }
                    }
                  }

                  .channelDetailsUpcomingBottomDetailsAlignment {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .channelDetailsHeadingNameAlignment {
                      h6 {
                        color: var(--light-grey);
                        font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                        max-width: 165px;
                        padding: 0 0 74px 0;

                        @media only screen and (max-width: '767px') {
                          font-size: 16px;
                          line-height: 24px;
                        }
                      }

                      .channelDetailsTimeDateAlignment {
                        padding: 8px 0 0 0;

                        p {
                          color: #e4e4e8;
                          font-family: 'Poppins', sans-serif;
                          font-size: 13px;
                          font-weight: 500;
                          line-height: 19px;
                          padding: 0 0 4px 0;

                          &:last-child {
                            padding: 0;
                          }
                        }
                      }

                      .channelDetailsLogoAlignment {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        padding: 8px 0 0 0;

                        .channelDetailsLogoImg {
                          width: 20px;
                          height: 20px;

                          img {
                            width: 100%;
                            height: 100%;
                            border-radius: 2px;
                            object-fit: cover;
                          }
                        }

                        p {
                          color: #fff;
                          font-family: 'Poppins', sans-serif;
                          font-size: 13px;
                          font-weight: 500;
                          line-height: 19px;
                        }
                      }
                    }

                    .channelDetailsLikeShareAlignment {
                      display: flex;
                      flex-direction: column;
                      gap: 21px;
                      align-items: center;

                      // @media only screen and (max-width:"767px") {
                      //   display: none;
                      // }

                      .channelDetailsLike {
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                        align-items: center;
                        cursor: pointer;

                        .channelDetailsBox {
                          border-radius: 8px;
                          background: rgba(30, 36, 50, 0.4);
                          padding: 4px;
                          cursor: pointer;
                        }

                        p {
                          color: #f8f8f8;
                          font-family: 'Poppins', sans-serif;
                          font-size: 13px;
                          font-weight: 500;
                          line-height: 19px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.activity-post-overview-section {
  margin: 32px 0 0 0;

  @media only screen and (max-width: '767px') {
    margin: 16px 0 0 0;
  }

  .activity-post-overview-alignment {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 34px;

    @media only screen and (max-width: '1499px') {
      grid-template-columns: repeat(1, 1fr);
    }

    @media only screen and (max-width: '767px') {
      gap: 0;
    }

    .activity-post-box-alignment {
      background: #ffffff;
      border-radius: 20px;
      padding: 40px 60px 0 60px;

      @media only screen and (max-width: '767px') {
        padding: 16px;
      }

      .activity-post-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h5 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 22px;
          line-height: 33px;
          color: var(--secondary-900);

          @media only screen and (max-width: '767px') {
            font-size: 15px;
            line-height: 22px;
            color: var(--secondary-900);
          }
        }

        .see-more-alignment {
          @media only screen and (max-width: '767px') {
            display: none;
          }

          a {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            color: var(--primary-500);
            cursor: pointer;
          }
        }

        .right-arrow-alignment {
          display: none;

          @media only screen and (max-width: '767px') {
            display: block;
          }

          img {
            cursor: pointer;
          }
        }
      }

      .activity-post-tab-alignment {
        margin: 40px 0 0 0;

        @media only screen and (max-width: '767px') {
          margin: 16px 0 0 0;
        }

        ul {
          display: flex;
          align-items: center;
          gap: 40px;
          border-bottom: 1px solid #eaecef;
          padding: 0 0 5px;

          @media only screen and (max-width: '1499px') {
            gap: 31px;
          }

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            color: #808191;
            cursor: pointer;
            position: relative;

            @media only screen and (max-width: '767px') {
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              color: #808191;
            }

            &:hover {
              color: #495057;

              &::before {
                position: absolute;
                content: '';
                height: 3px;
                width: 100%;
                background: var(--primary-500);
                bottom: -6px;
                right: 0;
              }
            }

            &.activity-post-tab-active {
              color: #495057;

              &::before {
                position: absolute;
                content: '';
                height: 3px;
                width: 100%;
                background: var(--primary-500);
                bottom: -6px;
                right: 0;
              }
            }
          }
        }
      }

      .activity-post-details-alignment {
        margin: 16px 0 0 0;
        height: auto;
        max-height: 376px;
        overflow-y: auto;

        .activity-post-details-flex {
          display: flex;
          justify-content: space-between;
          padding: 16px 0;
          border-bottom: 1px solid #eaecef;

          .activity-profile-alignment {
            display: flex;
            gap: 16px;

            .prdofileimg-alignment {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;

              @media only screen and (max-width: '767px') {
                width: 32px;
                min-width: 32px;
                max-width: 32px;
                height: 32px;
              }

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                cursor: pointer;
              }
            }

            .profile-name-alignment {
              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 15px;
                line-height: 22px;
                color: #495057;
              }

              .time-alignment {
                display: flex;
                align-items: center;
                gap: 16px;
                flex-wrap: wrap;

                @media only screen and (max-width: '767px') {
                  gap: 1px;
                  margin: 8px 0 0 0;
                }

                span {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 400;
                  font-size: 13px;
                  line-height: 20px;
                  color: #808191;
                }
              }

              .comment-name-alignment {
                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                  line-height: 22px;
                  color: #808191;
                }
              }
            }
          }

          .activity-img-option-alignment {
            display: flex;
            align-items: center;
            gap: 24px;

            @media only screen and (max-width: '767px') {
              gap: 8px;
            }

            .activity-img-alignment {
              width: 94px;
              height: 94px;
              max-width: 94px;

              @media only screen and (max-width: '767px') {
                width: 72px;
                max-width: 72px;
                min-width: 72px;
                height: 72px;
              }

              img {
                width: 100%;
                height: 100%;
                border: 1px solid #f3f3f3;
                border-radius: 6px;
              }
            }

            .activity-img-option-alignment {
              display: flex;
              flex-direction: column;
              gap: 16px;

              @media only screen and (max-width: '767px') {
                gap: 8px;
              }

              .option-box-alignment {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background: #eceef4;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
      }
    }

    .postoverview-box-alignment {
      background: #ffffff;
      border-radius: 20px;
      padding: 40px 60px 0 60px;

      @media only screen and (max-width: '767px') {
        display: none;
      }

      .postoverview-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h5 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 22px;
          line-height: 33px;
          color: var(--secondary-900);
        }

        .postoverview-dropdown-alignment {
          border: 1px solid #acb1c0;
          border-radius: 10px;
          display: flex;
          align-items: center;
          padding: 5px 20px;
          gap: 10px;

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #acb1c0;
          }

          .drop-down-alignment {
            img {
              cursor: pointer;
            }
          }
        }
      }

      .postoverview-box-details-alignment {
        margin: 40px 0 0 0;
        display: flex;
        align-items: center;
        gap: 2px;

        .postoverview-box-name-alignment {
          padding: 8px;

          &.postoverview-active {
            border-radius: 10px;
            background: rgba(168, 85, 247, 0.3);
          }

          &:hover {
            border-radius: 10px;
            background: rgba(168, 85, 247, 0.3);
          }

          h6 {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            color: #808191;
          }

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 22px;
            line-height: 33px;
            color: #495057;
            margin: 8px 0 0 0;
          }

          .percantage-alignment {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 0 7px 0 0;

            .trending-img-alignment {
              display: flex;
              gap: 2px;

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 15px;
                line-height: 22px;
                color: #70c217;
                margin: 0;
              }
            }
          }
        }
      }

      .postoverview-chat-alignment {
        width: 100%;
        height: 234px;
        margin: 32px 0 0 0;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.order-box-section {
  margin: 16px 0 0 0;

  .order-post-alignment {
    background: #ffffff;
    border-radius: 20px;
    padding: 22px 32px;

    @media only screen and (max-width: '767px') {
      padding: 0;
    }

    h5 {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 22px;
      line-height: 33px;
      color: var(--secondary-900);
    }

    .order-post-tab-alignment {
      margin: 40px 0 0 0;

      @media only screen and (max-width: '767px') {
        margin: 0;
        padding: 16px 16px 0 16px;
      }

      ul {
        display: flex;
        align-items: center;
        gap: 64px;
        border-bottom: 1px solid #eaecef;
        padding: 0 0 8px;

        @media only screen and (max-width: '767px') {
          gap: 22px;
        }

        li {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
          color: #808191;
          cursor: pointer;
          position: relative;

          @media only screen and (max-width: '767px') {
            font-size: 14px;
            line-height: 18px;
          }

          &:hover {
            color: #495057;
            font-weight: 600;

            &::before {
              position: absolute;
              content: '';
              height: 3px;
              width: 100%;
              background: var(--primary-500);
              bottom: -9px;
              right: 0;
              border-radius: 8px;
            }
          }

          &.order-tab-active {
            color: #495057;
            font-weight: 600;

            &::before {
              position: absolute;
              content: '';
              height: 3px;
              width: 100%;
              background: var(--primary-500);
              bottom: -6px;
              right: 0;
              border-radius: 8px;
            }
          }
        }
      }
    }

    .mobile-order-view-detaiks-alignment {
      display: none;

      @media only screen and (max-width: '767px') {
        display: block;
      }

      .mobile-name-with-scroll-alignment {
        width: 100%;
        overflow-x: auto;
        display: flex;
        align-items: center;
        gap: 16px;
        border-bottom: 1px solid rgba(228, 228, 232, 0.7);
        padding: 8px 0;

        .mobile-view-flex-alignment {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px;
          min-width: 100%;

          .mobile-view-product-details {
            display: flex;
            align-items: center;
            gap: 24px;

            .produtimg {
              width: 59px;
              height: 59px;
              max-width: 59px;
              min-width: 59px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 8px;
              }
            }

            .product-details-alignment {
              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 10px;
                line-height: 15px;
                color: #a855f7;
              }

              h5 {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                color: #495057;
                padding: 2px 0 0 0;
              }

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 10px;
                line-height: 15px;
                color: #495057;
                padding: 2px 0 0 0;
              }
            }
          }

          .status-other-alignment {
            display: flex;
            align-items: flex-start;
            gap: 16px;

            .mobile-status-alignment {
              background-color: rgba(112, 194, 23, 0.2);
              border-radius: 5px;
              padding: 4px 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 5px;
              width: 86px;
              margin: auto;

              &.hold-on-status {
                background-color: rgba(255, 148, 73, 0.2);

                p {
                  background: #ff7d23;
                }

                span {
                  color: #ff7d23;
                }
              }

              &.cancle-status {
                background-color: rgba(194, 23, 23, 0.2);

                p {
                  background: #c21717;
                }

                span {
                  color: #c21717;
                }
              }

              p {
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #70c217;
              }

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: #70c217;
              }
            }

            p {
              padding: 10px 0 0 0;
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 15px;
              line-height: 22px;
              text-align: center;
              color: #495057;
            }
          }
        }

        .option-details-box-alignment {
          display: flex;
          align-items: center;

          .option-box {
            width: 91px;
            height: 91px;
            background: #70c217;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 12px;

            &.cancle-box {
              background: #ff7d23;
            }

            &.delete-box {
              background: #c21717;
            }

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 13px;
              line-height: 20px;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}

.mobile-view-post-overview-section {
  display: none;

  @media only screen and (max-width: 767px) {
    display: none;
    margin: 16px 0 0 0;
  }

  .mobile-view-post-overview-alignment {
    padding: 16px;
    background: #ffffff;
    border-radius: 10px;

    .mobile-view-post-heading-alignment {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h5 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        color: var(--secondary-900);
      }

      .chart-view-menu {
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          // margin-top: 7px;
          // height: 50px;
          // background: rgba(236, 238, 244, 0.5);
          // padding-top: 10px;
          border-radius: 10px;
          // border: none;
        }
      }

      .mobile-view-post-dropdown-alignment {
        border: 1px solid #acb1c0;
        border-radius: 10px;
        display: flex;
        align-items: center;
        padding: 2px 8px;
        gap: 3px;

        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          color: #acb1c0;
        }

        .mobile-view-post-drop-down-alignment {
          img {
            cursor: pointer;
          }
        }
      }
    }

    .mobile-view-post-overview-tabs-alignment {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 16px 0 0 0;
      overflow-x: auto;

      .mobile-view-post-tab-box-alignment {
        border: 1px solid #808191;
        border-radius: 10px;
        padding: 4px 8px;
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;

        &.active {
          background: rgba(168, 85, 247, 0.3);
          border: none;

          p {
            color: #495057;
          }

          span {
            color: #495057;
          }
        }

        &:hover {
          background: rgba(168, 85, 247, 0.3);
          border: none;

          p {
            color: #495057;
          }

          span {
            color: #495057;
          }
        }

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: #808191;
          white-space: nowrap;
        }

        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          color: #808191;
          white-space: nowrap;
        }
      }
    }

    .mobile-view-post-overview-chat-alignment {
      margin: 16px 0 0 0;
      height: 202px;
      width: 100%;

      img {
        height: 100%;
      }
    }
  }
}

.mobile-view-bootMark-section {
  display: none;

  @media only screen and (max-width: 767px) {
    display: block;
    margin: 16px 0 0 0;
  }

  .mobile-view-bootMark-alignment {
    padding: 16px;
    background-color: #ffffff;
    border-radius: 10px;

    .mobile-view-bootMark-heading-alignment {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h5 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        color: var(--secondary-900);
      }

      .right-icon-alignment {
        cursor: pointer;
      }
    }

    .mobile-view-tab-alignment {
      padding: 16px 0 0 0;

      ul {
        display: flex;
        align-items: center;
        gap: 20px;
        border-bottom: 1px solid #eaecef;
        padding: 3px 0 5px 0;

        li {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: #808191;
          position: relative;

          &:hover {
            font-weight: 500;
            color: #495057;

            &::before {
              content: '';
              position: absolute;
              height: 3px;
              background: var(--primary-500);
              width: 100%;
              bottom: -5px;
              right: 0;
            }
          }
        }
      }
    }

    .mobile-view-bootmark-item-section {
      display: flex;
      align-items: center;
      gap: 32px;

      .mobile-view-bootmark-item-alignment {
        margin: 12px 0 0 0;

        .mobile-view-bootmark-img-alignment {
          width: 100px;
          height: 100px;
          border: 1px solid #f3f3f3;
          border-radius: 6px;

          img {
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 6px;
          }
        }

        .mobile-product-item-details-alignment {
          p {
            span {
              color: var(--secondary-900);
              font-weight: 800;
            }

            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: #495057;
          }

          .mobile-item-price-alignment {
            h6 {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 15px;
              line-height: 22px;
              color: var(--secondary-900);

              sup {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 10px;
                line-height: 15px;
                color: var(--secondary-900);
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {}

.mobile-view-back-alignment {
  display: none;

  @media only screen and (max-width: 767px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 24px 8px 16px;

    .mobile-child-header-alignment {
      display: flex;
      align-items: center;
      gap: 36px;
    }

    .back-header-alignment {
      display: flex;
      align-items: center;
      gap: 80px;
      height: 48px;
      background: #ffffff;

      .header-text-align {
        padding-left: 42px;

        h6 {
          font-family: 'Poppins', sans-serif;
          font-weight: 700;
          font-size: 17px;
          line-height: 26px;
          display: flex;
          align-items: center;
          text-align: center;
          color: var(--secondary-900);
        }
      }
    }

    .back_details-alignment {
      background: #ffffff;
      border-radius: 10px;
      padding: 4px 9px 4px 10px;
      display: flex;
      align-items: center;
      gap: 8px;

      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #808191;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 58px;
      }
    }

    .mobile-heading-alignment {
      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        color: var(--secondary-900);
      }
    }
  }
}

.overview-mobile-view-back-alignment {
  display: none;

  @media only screen and (max-width: 1024px) {
    display: block;
    padding: 0;
    width: 100%;

    .overview-mobile-header {
      display: flex;
      align-items: center;
      gap: 100px;
      background: #ffffff;
      padding: 8px;

      h6 {
        font-family: 'Poppins', sans-serif;
        font-weight: 700;
        font-size: 17px;
        line-height: 26px;
        color: var(--secondary-900);
      }
    }
  }
}

.book-filter-search-alignment {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 8px 16px;
  display: none;

  @media only screen and (max-width: '767px') {
    display: flex;
  }

  .book-filter-alignment {
    max-width: 44px;
    width: 44px;
    min-width: 44px;
    height: 44px;
    background: #ffffff;
    border-radius: 10px;
    padding: 10px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .book-main-search-section {
    background: #eceef4;
    position: relative;
    width: 100%;

    input {
      width: 100%;
      height: 44px;
      border-radius: 10px;
      text-align: center;
      padding: 0 40px 0 14px;
    }

    .close-alignment {
      position: absolute;
      top: 10px;
      right: 14px;
    }
  }
}

.mobile-tag-details-alignment {
  width: 100%;
  overflow-x: auto;
  display: none;

  @media only screen and (max-width: '767px') {
    display: block;
  }

  .mobile-book-tag-alignment {
    display: flex;
    align-items: center;
    gap: 4px;
    margin: 8px 16px;

    .mobile-book-tag-box {
      background: #ffffff;
      border-radius: 20px;
      padding: 6px 10px;
      display: flex;
      align-items: center;
      gap: 4px;

      span {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: var(--secondary-900);
      }

      .mobile-tag-close-icon {
        width: 16px;
        height: 16px;

        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
  }
}

.mobile-serch-details-number-alignment {
  margin: 8px 16px;
  display: none;

  @media only screen and (max-width: '767px') {
    display: block;
  }

  span {
    display: flex;
    align-items: center;
    gap: 2px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #808191;

    p {
      font-weight: 600;
    }
  }
}

.order-details-modal-alignment {
  .order-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100vh;
    background: #eceef4;
    overflow-y: auto;

    .order-details-modal-box-alignment {
      .order-details-alignment {
        padding: 16px;
        background: #fff;

        .order-id-status-alignment {
          display: flex;
          align-items: center;
          justify-content: space-between;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 17px;
            line-height: 26px;
            color: var(--secondary-900);
          }

          .mobile-status-alignment {
            background-color: rgba(112, 194, 23, 0.2);
            border-radius: 5px;
            padding: 4px 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            width: 86px;

            &.hold-on-status {
              background-color: rgba(255, 148, 73, 0.2);

              p {
                background: #ff7d23;
              }

              span {
                color: #ff7d23;
              }
            }

            &.cancle-status {
              background-color: rgba(194, 23, 23, 0.2);

              p {
                background: #c21717;
              }

              span {
                color: #c21717;
              }
            }

            p {
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background: #70c217;
            }

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              color: #70c217;
            }
          }
        }

        .order-shipping-details-alignment {
          padding: 16px 0 0 0;

          .order-shipping-child-heading-name {
            display: flex;
            align-items: center;
            gap: 24px;
            padding: 0 0 8px 0;

            &:last-child {
              padding: 0;
            }

            .heading-name {
              max-width: 96px;
              width: 96px;

              h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 15px;
                line-height: 22px;
                color: #495057;
              }
            }

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              color: #808191;
            }
          }
        }
      }

      .order-product-details-alignment {
        margin: 16px 0 0 0;

        .order-product-box-alignment {
          padding: 16px;
          background: #fff;
          display: flex;
          align-items: center;
          gap: 24px;

          .order-product-img-alignment {
            width: 96px;
            min-width: 96px;
            max-width: 96px;
            height: 96px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .order-product-name-alignment {
            h6 {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 15px;
              line-height: 22px;
              color: #495057;
            }

            .order-product-child-details-alignement {
              padding: 8px 0 0 0;

              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: #808191;
                padding: 0 0 2px 0;

                &:last-child {
                  padding: 0;
                }
              }
            }
          }
        }
      }

      .order-way-alignment {
        background: #fff;
        padding: 16px;
        margin: 16px 0 0 0;

        .stepper-alignment {
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            height: 6px;
            width: 90%;
            background: #e4e4e8;
            top: 10px;
            right: 18px;
          }

          .order-box-aligment {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: #e4e4e8;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
            z-index: 99;
            position: relative;

            &.order-box-active {
              background: #a855f7;
            }
          }

          p {
            padding: 5px;
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;
            color: #495057;
          }
        }

        .steper-option-alignment {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 34.5px;
          padding: 16px 0 0 0;

          .steper-option-box-alignment {
            background: #f8f8f8;
            border: 1px solid #808191;
            border-radius: 10px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 10px;
              line-height: 15px;
              color: #808191;
            }
          }
        }
      }

      .price-box-alignment {
        background: #fff;
        padding: 16px;
        margin: 16px 0 0 0;

        h5 {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 17px;
          line-height: 26px;
          color: var(--secondary-900);
        }

        .price-details-alignment {
          padding: 16px 0 0 0;

          .product-name-price-alignment {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 0 4px 0;

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 13px;
              line-height: 20px;
              color: #495057;
            }

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              color: #808191;
            }
          }

          .product-total-rate-alignment {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 4px 0 0 0;
            border-top: 1px solid rgba(228, 228, 232, 0.7);

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 15px;
              line-height: 22px;
              color: var(--secondary-900);
            }

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 15px;
              line-height: 22px;
              color: #c21717;
            }
          }
        }
      }

      .payment-box-alignment {
        background: #fff;
        padding: 16px;
        margin: 16px 0 0 0;

        h5 {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 17px;
          line-height: 26px;
          color: var(--secondary-900);
        }

        .payment-box-details-alignment {
          padding: 16px 0 0 0;

          .payment-card-details {
            border-bottom: 1px solid rgba(236, 238, 244, 0.5);

            &:last-child {
              border-bottom: none;
            }

            h6 {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 13px;
              line-height: 20px;
              color: #495057;
            }

            p {
              padding: 4px 0;
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              color: #808191;
            }
          }
        }
      }

      .review-produtc-box-alignment {
        margin: 16px 0 0 0;
        background: #fff;
        padding: 16px;

        h6 {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 17px;
          line-height: 26px;
          color: var(--secondary-900);
        }

        .review-flex-alignment {
          display: flex;
          align-items: center;
          gap: 16px;
          padding: 16px 0 0 0;

          .star-img-alignment {
            width: 32px;
            height: 30px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .review-textarea-alignment {
          padding: 16px 0 0 0;

          textarea {
            border: 1px solid #acb1c0;
            border-radius: 5px;
            height: 144px;
            width: 100%;
            padding: 16px;
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;

            &::placeholder {
              color: #808191;
            }
          }
        }

        .review-button-alignment {
          padding: 16px 0 0 0;

          button {
            background: var(--primary-500);
            border-radius: 10px;
            padding: 8px 0;
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            color: #ffffff;
            cursor: pointer;
            width: 100%;
          }
        }
      }
    }
  }
}

.order-activity-report-section {
  margin: 16px 0 0 0;

  .order-activity-report-box {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 5px 50px 0px rgba(144, 143, 143, 0.1);
    padding: 22px 32px;

    @media only screen and (max-width: '767px') {
      padding: 16px 8px;
    }

    .order-activity-report-heading-alignment {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h4 {
        color: var(--secondary-900);
        font-family: 'Poppins', sans-serif;
        font-size: 24px;
        font-weight: 600;
        line-height: 28px;

        @media only screen and (max-width: '1024px') {
          font-size: 20px;
          list-style: 24px;
        }

        @media only screen and (max-width: '767px') {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }

    .order-activity-report-body-alignment {
      .order-activity-report-grid-alignment {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 21px;

        @media only screen and (max-width: '767px') {
          grid-template-columns: repeat(1, 1fr);
          gap: 8px;
        }

        .order-activity-report-grid-item-alignment {
          padding: 34px 4px 0 4px;

          @media only screen and (max-width: '767px') {
            padding: 0;
          }

          .order-activity-report-child-box {
            border-radius: 10px;
            background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
            box-shadow: 0px 5px 50px 0px rgba(144, 143, 143, 0.1);
            padding: 20px;

            @media only screen and (max-width: '767px') {
              padding: 32px 16px;
              display: flex;
              justify-content: space-between;
            }

            .order-activity-child-box-top-details {
              display: flex;
              align-items: center;
              gap: 10px;

              @media only screen and (max-width: '767px') {
                grid-area: 8px;
              }

              .order-activity-child-box-icon {
                width: 41px;
                height: 41px;

                @media only screen and (max-width: '767px') {
                  width: 32px;
                  height: 32px;
                }

                svg {
                  width: 100%;
                  height: 100%;
                }

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              h6 {
                color: var(--secondary-900);
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;

                @media only screen and (max-width: '767px') {
                  font-size: 14px;
                  line-height: 20px;
                }
              }
            }

            .order-activity-child-box-bottom-details {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 24px 24px 0 24px;

              @media only screen and (max-width: '767px') {
                padding: 0;
                gap: 16px;
              }

              h6 {
                color: var(--secondary-900);
                font-family: 'Poppins', sans-serif;
                font-size: 32px;
                font-weight: 600;
                line-height: 36px;

                @media only screen and (max-width: '767px') {
                  font-size: 24px;
                }
              }

              .order-activity-child-box-bottom-right-side {
                .overview-total-details {
                  display: flex;
                  align-items: center;
                  gap: 4px;

                  span {
                    color: #1d8603;
                    font-family: 'Poppins', sans-serif;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 20px;

                    @media only screen and (max-width: '767px') {
                      font-size: 8px;
                    }
                  }
                }

                p {
                  color: var(--secondary-500);
                  font-family: 'Poppins', sans-serif;
                  font-size: 13px;
                  font-weight: 400;
                  line-height: 20px;

                  @media only screen and (max-width: '767px') {
                    font-size: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.order-post-overview-report-section {
  margin: 16px 0 0 0;

  .order-post-overview-report-box {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 5px 50px 0px rgba(144, 143, 143, 0.1);
    padding: 22px 32px;

    @media only screen and (max-width: '767px') {
      padding: 16px;
    }

    .order-post-overview-report-heading-alignment {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h4 {
        color: var(--secondary-900);
        font-family: 'Poppins', sans-serif;
        font-size: 24px;
        font-weight: 600;
        line-height: 28px;

        @media only screen and (max-width: '1024px') {
          font-size: 20px;
          line-height: 24px;
        }

        @media only screen and (max-width: '768px') {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }

    .order-post-overview-report-body-alignment {
      .order-post-overview-report-img {
        width: 100%;
        height: 253px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}