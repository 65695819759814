.mobile-view-follow-following-modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(30, 36, 50, 0.6);
  top: 0;
  right: 0;
  z-index: 999;
}

.mobile-view-follow-following-click-drop-alignment {
  border-bottom: 1px solid #eaecef;
  padding: 8px 0 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 999;

  p {
    width: 33px;
    height: 3px;
    background: #e4e4e8;
    border-radius: 1.5px;
  }
}

.mobile-view-follow-following-bottom-modal-alignment {
  width: 100%;
  // position: fixed;
  // z-index: 9999999;
  // left: 50%;
  // bottom: 0;
  transition: 0.3s ease-in-out;
  // margin: 0 auto;
  border-radius: 10px 10px 0 0;
  background-color: #fff;

  &.follow-following-modal-box-hidden {
    transform: translate(-50%, 100%);
    transition: 0.3s ease-in-out;
  }

  &.follow-following-modal-box-show {
    transform: translate(-50%, 0%);
    transition: 0.3s ease-in-out;
  }

  .mobile-view-follow-following-body-alignment {
    padding: 0 16px;
    display: none;

    @media only screen and (max-width: '1024px') {
      display: block;
    }

    .mobile-view-follow-following-tab-alignment {
      padding: 18px 0 0 0;
      border-bottom: 2px solid #eceef4;

      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;

        li {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 18px;
          line-height: 20px;
          color: var(--secondary-600);
          padding: 0 45px 16px 45px;
          position: relative;

          &:hover {
            font-weight: 600;
            color: var(--primary-700);

            &::before {
              content: '';
              position: absolute;
              height: 2px;
              width: 100%;
              background: var(--primary-700);
              bottom: 0;
              right: 0;
              border-radius: 8px;
            }
          }

          &.follow-following-tab-active {
            font-weight: 600;
            color: var(--primary-700);

            &::before {
              content: '';
              position: absolute;
              height: 2px;
              width: 100%;
              background: var(--primary-700);
              bottom: 0;
              right: 0;
              border-radius: 8px;
            }
          }
        }
      }
    }

    .mobile-view-follow-following-details-sort-alignment {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 0 0 0;

      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: var(--secondary-500);
      }

      .mobile-sort-dropdown-alignment {
        border: 1.5px solid var(--secondary-100);
        border-radius: 8px;
        padding: 6px 16px;
        display: flex;
        align-items: center;
        gap: 12px;

        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          color: var(--secondary-600);
        }
      }
    }

    .mobile-view-follow-following-search-alignment {
      margin: 16px 0 0 0;
      background: #f8f8f8;
      border-radius: 10px;
      height: 44px;
      width: 100%;
      position: relative;

      .search-alignment {
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        position: absolute;
        width: 44px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      input {
        background-color: transparent;
        height: 100%;
        width: 100%;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        padding: 0 10px 0 42px;

        &::placeholder {
          color: var(--secondary-200);
        }
      }
    }

    .mobile-view-follow-following-details-alignment {
      padding: 16px 0 0 0;

      .mobile-view-follow-following-personal-data-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 16px 0;

        .mobile-profile-details-alignment {
          display: flex;
          align-items: center;
          gap: 16px;

          .mobile-profile-img-alignment {
            width: 40px;
            height: 40px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              object-fit: cover;
            }
          }

          .mobile-profile-name-alignment {
            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: var(--secondary-900);
            }
          }
        }

        .mobile-follow-following-button-alignment {
          height: 32px;
          width: 104px;

          button {
            height: 100%;
            width: 100%;
            padding: 6px 16px;
            background: #eceef4;
            border-radius: 8px;
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            color: var(--secondary-600);

            &.active-follow {
              padding: 6px 16px;
              background: var(--primary-500);
              border-radius: 8px;
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 15px;
              line-height: 20px;
              color: #ffffff;
            }
          }
        }
      }
    }

    .mobile-view-no-following-details-alignment {
      height: 306px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 40px;

      span {
        font-family: 'Poppins', sans-serif;
        font-style: italic;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: var(--secondary-300);
      }
    }

    .no-store-data-following-details-alignment {
      height: calc(100vh - 300px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 100px;

      @media only screen and (max-width: 767px) {
        height: 100%;
        padding: 20px 0;
        gap: 80px;
      }

      .no-follow-name-alignment {
        text-align: center;
        max-width: 278px;

        &.like-alignment {
          max-width: 196px;
        }

        span {
          font-family: 'Poppins', sans-serif;
          font-style: italic;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: var(--secondary-400);
        }
      }
    }
  }

  .mobile-view-no-data-may-following-alignment {
    background: #f8f8f8;
    border-radius: 8px 8px 0px 0px;
    padding: 22px 16px;
    margin-bottom: 16px;

    .mobile-view-no-data-may-following-headign {
      h5 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: var(--secondary-900);
      }
    }

    .mobile-view-no-data-follow-alignment {
      padding: 24px 0;

      .mobile-view-no-data-follow-data-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 16px 0;

        &:last-child {
          padding: 0;
        }

        .mobile-profile-details-alignment {
          display: flex;
          align-items: center;
          gap: 16px;

          .mobile-profile-img-alignment {
            width: 40px;
            height: 40px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              object-fit: cover;
            }
          }

          .mobile-profile-name-alignment {
            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: var(--secondary-900);
            }
          }
        }

        .mobile-no-data-follow-button-alignment {
          button {
            padding: 6px 16px;
            background: var(--primary-500);
            border-radius: 8px;
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            color: #ffffff;
          }
        }
      }
    }
  }
}

.follow-followingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(30, 36, 50, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  z-index: 9999999;
  display: none;

  @media only screen and (max-width: '767px') {
    display: block;
  }
}

.follow-followingOverlay.open {
  opacity: 1;
  visibility: visible;
}

.follow-following-modal {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  // height: 63%;
  // height: 100%;
  // height: calc(100% - 6%);
  // height: 0px;
  background: white;
  translate: 0 100%;
  transition: 0.3s;
  border-radius: 16px 16px 0 0;
  overflow-y: auto;
  display: none;

  @media only screen and (max-width: '767px') {
    display: block;
  }

  &.fullWidth {
    border-radius: 0;
  }

  .ant-tabs-nav {
    margin-bottom: 0px !important;
    background-color: #fff;
  }

  .ant-tabs-top>.ant-tabs-nav::before,
  .ant-tabs-top>div>.ant-tabs-nav::before {
    border: 0;
  }

  .ant-tabs-nav-list {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0;
    // padding: 0 74px;

    .ant-tabs-tab {
      padding: 0 38px;
      margin: 0;

      @media only screen and (max-width: 768px) {
        padding: 0 34px;
      }

      .ant-tabs-tab-btn {
        padding: 16px 23px;
        font-size: 15px;
        line-height: 22px;
        color: #a7a7a7;
        font-weight: 400 !important;
      }

      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          font-weight: 500 !important;
          color: var(--primary-700);
        }

        &:before {
          content: '';
          position: absolute;
          height: 3px;
          background: var(--primary-700);
          border-radius: 26px 26px 0 0px;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }
    }

    .ant-tabs-ink-bar {
      display: none;
    }
  }
}

.follow-following-modal.open {
  translate: 0;
  z-index: 999999999999;
  // transform: translate(0, 42px);
  // transform: translate(0, 60%) !important;
  transform: translate(0, 0) !important;
  // height: calc(100% - 42px);
  // height: 93%;

  &.fullWidth {
    transform: translate(0, 0);
  }

  &.paymentMethod {
    transform: translate(0, 52%);
  }

  .allTopSellingDetailsHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 99;
    background: #ffffff;
    padding: 16px;

    h6 {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: var(--secondary-900);
    }

    .closeIconAlignment {
      cursor: pointer;
    }
  }
}

.follow-following-modal.about {
  translate: 0;
  z-index: 9999999;
  transform: translate(0, 36%);
}

.follow-following-modal.group-more {
  translate: 0;
  z-index: 9999999;
  transform: translate(0, 6%);
}

.follow-following-modal.share-product {
  translate: 0;
  z-index: 9999999;
  transform: translate(0, 60%);
}

.follow-following-modal.filter-modal {
  translate: 0;
  z-index: 9999999;
  transform: translate(0, 20%);
}

.follow-followingOverlay-share {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(30, 36, 50, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  z-index: 9999 !important;
}

.follow-followingOverlay-share.share-on-my-feed {
  // opacity: 1;
  visibility: visible;
}

.follow-following-modal-share {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: white;
  translate: 0 100%;
  transition: 0.3s;
  border-radius: 16px 16px 0 0;
  overflow-y: auto;
}

.follow-following-modal-share.share-on-my-feed {
  translate: 0;
  z-index: 99999;
  transform: translate(0, 24%);
}

.follow-following-modal.share-with-friend {
  translate: 0;
  z-index: 9999999;
  transform: translate(0, 24%);
}

.mobile-view-seller-follow-followingOverlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 50px);
  background: rgba(30, 36, 50, 0.6);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  z-index: 999999 !important;
}

.mobile-view-seller-follow-followingOverlay.open {
  opacity: 1;
  visibility: visible;
}

.mobile-view-seller-follow-following-modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 240px;
  height: calc(100vh - 50px);
  background: white;
  translate: -100% 0;
  transition: 0.3s;
  border-radius: 0px 16px 16px 0px;
  overflow-y: auto;
  z-index: 99999;
}

.mobile-view-seller-follow-following-modal.open {
  translate: 0;
  z-index: 9999999;
}