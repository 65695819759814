.sellerOnboardingMainSection {
    @media only screen and (max-width:"1024px") {
        height: 100vh;
    }

    .sellerOnboardingAlignment {
        padding: 24px 0;

        @media only screen and (max-width:"1024px") {
            height: 100%;
            // display: flex;
            // align-items: center;
            width: 100%;
        }

        .sellerOnboardingBoxAlignment {
            background: var(--white);
            border-radius: 24px;
            padding: 16px;
            max-width: 1576px;
            margin: 0 auto;

            @media only screen and (max-width:"1699px") {
                max-width: 1374px;
            }

            @media only screen and (max-width:"1499px") {
                max-width: 1274px;
            }

            @media only screen and (max-width:"1299px") {
                max-width: 100%;
                margin: 0 40px;
                height: 100%;
                // width: 100%;
            }

            @media only screen and (max-width:"576px") {
                max-width: 100%;
                margin: 0 20px;
            }

            .sellerOnboardingGrid {
                display: grid;
                grid-template-columns: 760px 1fr;

                @media only screen and (max-width:"1499px") {
                    grid-template-columns: 660px 1fr;
                }

                @media only screen and (max-width:"1299px") {
                    grid-template-columns: repeat(2, 1fr);
                }

                @media only screen and (max-width:"1024px") {
                    grid-template-columns: repeat(1, 1fr);
                }
            }

            .sellerOnboardingGridItem {}
        }
    }
}