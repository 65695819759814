.noDataBoxAlignment {
  background: rgba(85, 110, 230, 0.13);
  border: 2px dashed #b1c2f3;
  border-radius: 8px;
  padding: 34px 14px 13px 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 162px;
  width: 100%;
  height: 182px;
  position: relative;

  &.mediaError {
    border: 2px dashed red;
  }

  .addIconsAlignment {
    display: flex;
    align-items: center;
    gap: 2px;

    img {
      width: 24px;
      height: 24px;
      display: block;
    }
  }

  .drapAndDropAlignment {
    margin: 8px 0 0 0;

    p {
      max-width: 104px;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
      color: #495057;

      a {
        color: var(--primary-500);
      }
    }
  }

  .allowedImgTypeAlignment {
    p {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
      color: #808191;
    }
  }

  input {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 9999;
  }
}
