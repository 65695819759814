.memberDetailsAlignment {
  border-radius: 16px;
  border: 2px solid var(--primary-50);
  background: var(--white);
  padding: 24px 16px;

  @media only screen and (max-width: '1099px') {
    padding: 0;
    border-radius: 0;
    border: 0;
  }

  .headingAlignment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 0 16px;
    @media only screen and (max-width: '1024px') {
      padding: 16px 16px 8px 16px;
    }
    .headingLeftSideAlignment {
      display: flex;
      align-items: center;
      gap: 8px;

      svg {
        @media only screen and (max-width: '1024px') {
          display: none;
        }
      }

      .headingDetailsFlex {
        display: flex;
        align-items: center;
        gap: 5px;
        h3 {
          color: var(--secondary-900);
          font-size: 20px;
          font-weight: 600;
          line-height: 24px;
        }
        p {
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: var(--secondary-500);
        }
        span {
          color: var(--secondary-500);
          text-align: right;
          font-size: 13px;
          font-weight: 500;
          line-height: 21px;
        }
      }
    }

    .hedingRightSideAlignment {
      display: flex;
      align-items: center;
      gap: 16px;
      .searchIcon {
        width: 32px;
        height: 32px;
        min-width: 32px;
        max-width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .inviteAlignment {
        align-items: center;
        gap: 4px;
        display: flex;
        .inviteIcon {
          width: 16px;
          height: 16px;
          min-width: 16px;
          max-width: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        p {
          color: var(--primary-500);
          text-align: right;
          font-size: 15px;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }
  }

  .mobileHeaderAlignment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 16
    display: none;

    .mobileHeaderNameAlignment {
      display: flex;
      align-items: center;
      gap: 4px;

      h6 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: var(--secondary-900);
      }

      .totalMemberAlignment {
        padding: 0 8px;
        background: #eceef4;
        border-radius: 10px;

        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          color: var(--secondary-500);
        }
      }
    }

    .mobileDropdwnAlignment {
      // border: 1.25px solid var(--secondary-100);
      // border-radius: 8px;
      height: 32px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      gap: 2px;

      span {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: var(--primary-500);
      }
    }
  }

  .memberAllDetailsAlignment {
    margin: 16px 0 0 0;

    .mobileViewFlexAlignment {
      display: flex;
      align-items: center;
      width: 100%;
      overflow-x: auto;
      gap: 2px;
      padding: 0 16px;
      transition: 0.4s ease-in-out;
      cursor: pointer;
      @media only screen and (max-width: '767px') {
        padding: 0;
      }
      .memberDetailsBoxAlignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 11px 0;
        width: 100%;
        min-width: 100%;
        max-width: 100%;

        &.owner {
          @media only screen and (max-width: '767px') {
            border-bottom: 2px solid #eceef4;
            border-radius: 0;
          }
        }

        @media only screen and (max-width: '1099px') {
          box-shadow: none;
          margin: 0;
          padding: 10px 16px 8px 16px;
          &:first-child {
            padding: 16px;
            border-bottom: 1px solid var(--primary-50);
            margin: 0 0 8px 0;
          }
        }

        &:last-child {
          margin: 0;
        }

        .memberProfileAlignment {
          display: flex;
          align-items: center;
          gap: 16px;

          .profileAlignment {
            width: 40px;
            min-width: 40px;
            max-width: 40px;
            height: 40px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              object-fit: cover;
            }
          }

          .profileNameAlignment {
            h4 {
              color: var(--secondary-900);
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              letter-spacing: 0.1px;
              padding: 0 0 2px 0;

              span {
                color: var(--secondary-900);
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                display: none;

                @media only screen and (max-width: '1024px') {
                  display: inline-block;
                }
              }
            }

            .childDetailsAlignment {
              display: flex;
              align-items: center;
              gap: 8px;

              span {
                color: var(--grey6);
                font-size: 13px;
                font-weight: 400;
                line-height: 19px;
              }

              p {
                width: 2px;
                height: 2px;
                border-radius: 50%;
                background: var(--grey2);
              }
            }
          }
        }

        .followButtonAlignment {
          display: flex;
          align-items: center;
          gap: 24px;

          .adminDetails {
            @media only screen and (max-width: '1024px') {
              display: none;
            }
            span {
              color: var(--secondary-500);
              font-size: 15px;
              font-style: italic;
              font-weight: 400;
              line-height: 22px;
              cursor: pointer;
              &.remove {
                color: var(--red-500);
                display: none;
                transition: 0.4s ease-in-out;
              }
            }
          }
          button {
            font-family: 'Poppins', sans-serif;
            font-size: 15px;
            font-weight: 500;
            line-height: 22px;
            color: var(--primary-500);
            cursor: pointer;
            border-radius: 8px;
            background: transparent;
            border: none;
            &.following {
              color: var(--secondary-500);

              // &:hover {
              //   background: #eef1fc;
              // }
            }
          }
        }
      }

      .memberDeleteBox {
        padding: 8px 16px;
        background: var(--red-500);
        display: none;
        @media only screen and (max-width: '1024px') {
          display: block;
        }
        .memberIcon {
          width: 44px;
          height: 44px;
          min-width: 44px;
          max-width: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &:hover {
        transition: 0.4s ease-in-out;
        border-radius: 10px;
        background: var(--primary-50);

        .memberDetailsBoxAlignment {
          .followButtonAlignment {
            .adminDetails {
              span {
                &.remove {
                  display: block;
                  transition: 0.4s ease-in-out;
                }
              }
            }
          }
        }
      }
    }
  }
}

.removeMemberBoxAlignment {
  height: 68px;
  border-radius: 10px;
  background: var(--red-500);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media only screen and (max-width: '1024px') {
    height: 60px;
    border-radius: 0;
  }
  p {
    color: var(--white);
    font-size: 15px;
    font-style: italic;
    font-weight: 400;
    line-height: 21px;
    @media only screen and (max-width: '1024px') {
      font-style: normal;
    }
  }

  .removeIconAlignment {
    display: none;
    @media only screen and (max-width: '1024px') {
      display: block;
      position: absolute;
      top: 18px;
      right: 18px;
    }
  }
}
