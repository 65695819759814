.mobile-author-all-details-alignment {
    display: none;

    @media only screen and (max-width:"767px") {
        display: block;
    }

    .mobile-author-all-view-modal-alignment {
        width: 100%;
        height: 100vh;
        background: var(--secondary-50);
        position: fixed;
        top: 0;
        right: 0;

        .mobile-author-heading-alignment {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: rgba(248, 248, 248, 0.92);
            backdrop-filter: blur(13.5914px);
            padding: 16px 24px 8px 16px;

            .back-alignment {
                background: #ffffff;
                border-radius: 10px;
                padding: 4px 9px 4px 10px;
                display: flex;
                align-items: center;
                gap: 8px;

                p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #808191;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 58px;
                }
            }

            .mobile-view-page-name-alignment {
                h4 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 27px;
                    text-align: center;
                    color: var(--secondary-900);
                }
            }

            .mobile-author-search-alignment {
                img {
                    width: 24px;
                    height: 24px;
                    display: block;
                }
            }
        }

        .mobile-author-view-book-main-search-section {
            background: var(--secondary-50);
            position: relative;
            margin: 16px 16px 0 16px;

            input {
                width: 100%;
                height: 44px;
                border-radius: 10px;
                padding: 0 40px 0 16px;
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #808191;
            }

            .mobile-author-close-alignment {
                position: absolute;
                top: 10px;
                right: 14px;
            }
        }

        .mobile-author-details-alignment {
            padding: 16px;
            height: auto;
            max-height: calc(100vh - 56px);
            overflow-y: auto;

            .mobile-book-auther-details-box {
                border-radius: 20px;
                background: #FFFFFF;
                padding: 8px;
                display: flex;
                align-items: center;
                gap: 18px;
                margin: 0 0 8px 0;

                &:hover {
                    transform: translateY(-2px);
                }


                &:last-child {
                    margin: 0;
                }

                .mobile-book-author-img-alignment {
                    width: 106px;
                    min-width: 106px;
                    max-width: 106px;
                    height: 106px;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 32px;
                        object-fit: cover;
                    }
                }

                .mobile-auther-details-alignment {
                    h6 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--secondary-900);
                    }

                    .mobile-auther-following-details-alignment {
                        display: flex;
                        align-items: center;
                        gap: 17px;
                        padding: 12px 0 0 0;

                        .mobile-child-anme-alignment {
                            display: flex;
                            align-items: center;
                            gap: 4px;

                            span {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 18px;
                                color: #808191;
                                cursor: context-menu;
                            }
                        }

                    }

                    .mobile-auther-options-alignment {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        padding: 16px 0 0 0;

                        .mobile-follow-page-button-alignment {
                            button {
                                background: var(--primary-500);
                                border-radius: 8px;
                                height: 32px;
                                width: 117px;
                                font-family: 'Poppins', sans-serif;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 21px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #F8F8F8;
                                cursor: pointer;
                            }
                        }

                        .mobile-like-share-alignment {
                            display: flex;
                            align-items: center;
                            gap: 8px;


                            .mobile-box-alignment {
                                background: #F8F8F8;
                                border-radius: 8px;
                                width: 32px;
                                min-width: 32px;
                                max-width: 32px;
                                height: 32px;
                                padding: 4px;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}