.addProductModalDescriptionAlignment {
  margin: 0 0 16px 0;

  .addProductModalDescriptionBox {
    border-radius: 10px;
    border: 1px solid var(--secondary-50);
    background: #FFF;
    padding: 10px 16px 16px 16px;
    height: 100%;

    .descriptionHeadingAlignment {
      display: flex;
      align-items: center;
      padding: 0 0 16px 0;

      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #495057;
        margin-right: 3px;
      }

      .starAlignment {
        font-family: 'Inter';
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #d23232;
      }
    }
  }
}