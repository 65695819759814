.mobile-view-all-most-liked-alignment {
    display: none;

    @media only screen and (max-width:"767px") {
        display: block;
    }

    .mobile-view-modal-alignment {
        width: 100%;
        height: 100vh;
        background: var(--secondary-50);
        position: fixed;
        top: 0;
        right: 0;

        .mobile-view-heading-alignment {
            .all-most-liked-back-alignment {
                display: flex;
                align-items: center;
                gap: 46px;
                padding: 8px 10px;
                background: #940000;
                backdrop-filter: blur(13.5914px);
                display: none;

                @media only screen and (max-width: '767px') {
                    display: flex;
                    position: sticky;
                    top: 0;
                    z-index: 99;
                }

                .back_details-alignment {
                    background: #ffffff;
                    border-radius: 10px;
                    padding: 4px 9px 4px 10px;
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    p {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                        color: #808191;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 58px;
                    }
                }

                .mobile-heading-alignment {
                    h4 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 27px;
                        text-align: center;
                        color: #FFFFFF;
                    }
                }

                .mobile-heading-alignment-none {
                    display: none;
                }
            }
        }

        .mobile-view-see-all-details-alignment {
            padding: 16px 0 0 0;

            .mobile-view-see-all-special-search-alignment {
                display: none;

                @media only screen and (max-width: 767px) {
                    display: flex;
                }

                gap: 8px;
                overflow-x: hidden;

                .mobile-view-child-most-liked-heading-alignment {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    padding: 0 16px 0 0;

                    .mobile-view-child-most-liked-name-alignment {
                        display: flex;
                        align-items: center;
                        gap: 4px;

                        h5 {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 30px;
                            color: var(--secondary-900);
                        }

                        span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 18px;
                            color: #808191;
                        }
                    }

                    .mobile-most-liked-search-icon-alignment {
                        img {
                            width: 24px;
                            height: 24px;
                            display: block;
                            cursor: pointer;
                        }
                    }
                }

                .mobile-view-most-rated-main-search-section {
                    position: relative;
                    right: 16px;
                    margin: 14px 0 0 0;

                    input {
                        background: #f8f8f8;
                        border-radius: 10px;
                        width: 100%;
                        height: 44px;
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--secondary-900);
                        padding: 10px 40px 10px 14px;
                    }

                    .mobile-view-most-rated-close-alignment {
                        position: absolute;
                        top: 10px;
                        right: 14px;
                        cursor: pointer;
                    }
                }

                .mobile-view-most-liked-sidebar-alignment {
                    display: flex;
                    align-items: flex-start;

                    .mobile-search-most-liked-sidebar-details-box-alignment {
                        display: flex;
                        align-items: flex-start;

                        .mobile-view-most-liked-sidebar-box-alignment {
                            background: #ffffff;
                            // border-radius: 8px;
                            height: calc(100vh - 64px);
                            // height: calc(100vh - 104px);
                            overflow-y: auto;

                            .mobile-silder-most-liked-side-main-details {
                                padding: 8px;
                                position: relative;

                                .mobile-most-liked-sidebar-details-alignment {
                                    .mobile-most-liked-box-all-details-alignment {
                                        &.all-box {
                                            background: #EEF1FC;
                                            backdrop-filter: blur(28.6539px);
                                        }

                                        margin: 0 0 8px 0;
                                        width: 90px;
                                        min-width: 90px;
                                        max-width: 90px;
                                        height: 144px;
                                        display: flex;
                                        align-items: center;
                                        flex-direction: column;
                                        justify-content: space-evenly;
                                        background: #F8F8F8;
                                        backdrop-filter: blur(28.6539px);

                                        &:last-child {
                                            margin: 0;
                                        }

                                        p {
                                            font-family: 'Poppins', sans-serif;
                                            font-weight: 600;
                                            font-size: 13px;
                                            line-height: 20px;
                                            text-align: center;
                                            color: var(--secondary-900);
                                        }

                                        .most-liked-category-name-alignment {
                                            p {
                                                font-family: 'Poppins', sans-serif;
                                                font-weight: 600;
                                                font-size: 13px;
                                                line-height: 20px;
                                                background: linear-gradient(105.42deg, #C40000 8.92%, #C1E001 53.37%, #0061BB 95.14%);
                                                -webkit-background-clip: text;
                                                -webkit-text-fill-color: transparent;
                                                background-clip: text;
                                                text-fill-color: transparent;

                                                &.notication-text {
                                                    background: linear-gradient(266.63deg, #173FD0 17.65%, #06ABDF 79.04%);
                                                    -webkit-background-clip: text;
                                                    -webkit-text-fill-color: transparent;
                                                    background-clip: text;
                                                    text-fill-color: transparent;
                                                }

                                                &.fiction-text {
                                                    background: linear-gradient(97.94deg, #3844FD 1.89%, #EF48D8 97.91%);
                                                    -webkit-background-clip: text;
                                                    -webkit-text-fill-color: transparent;
                                                    background-clip: text;
                                                    text-fill-color: transparent;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .mobile-view-most-liked-left-arrow-alignment {
                            background: #ffffff;
                            border-radius: 0px 8px 8px 0px;
                            padding: 10px 14px;
                            margin: 0 8px 0 0;
                            width: 32px;
                            min-width: 32px;
                            max-width: 32px;
                            height: 32px;
                        }
                    }

                    .mobile-view-most-liked-details-alignment {
                        position: relative;
                        left: -18px;
                        padding: 16px 0 0 0;

                        .mobile-most-liked-book-details-alignment {
                            height: calc(100vh - 110px);
                            overflow-y: auto;

                            .mobile-most-liked-book-grid {
                                display: grid;
                                grid-template-columns: repeat(2, 1fr);
                                gap: 32px 12px;

                                .mobile-most-liked-book-gridItem {

                                    .mobile-most-liked-book-alignment {
                                        text-align: center;
                                        margin: 0 0 24px 0;

                                        &:last-child {
                                            margin: 0;
                                        }

                                        .mobile-most-liked-book-img-alignment {
                                            max-width: 167px;
                                            width: 167px;
                                            min-width: 167px;
                                            height: 252px;

                                            img {
                                                width: 100%;
                                                height: 100%;
                                                object-fit: cover;
                                            }
                                        }

                                        .mobile-most-liked-book-name-alignment {


                                            h6 {
                                                font-family: 'Poppins', sans-serif;
                                                font-weight: 600;
                                                font-size: 16px;
                                                line-height: 24px;
                                                color: var(--secondary-900);
                                            }

                                            p {
                                                font-family: 'Poppins', sans-serif;
                                                font-weight: 500;
                                                font-size: 13px;
                                                line-height: 20px;
                                                color: #808191;
                                                text-align: center;
                                            }
                                        }

                                        .mobile-most-liked-book-rate-alignment {
                                            display: flex;
                                            align-items: center;
                                            padding: 8px 0 0 0;
                                            justify-content: center;
                                            gap: 4px;

                                            .hike {
                                                display: flex;
                                                align-items: center;

                                                .inter {
                                                    font-family: 'Poppins', sans-serif;
                                                    font-weight: 600;
                                                    font-size: 13px;
                                                    line-height: 20px;
                                                    color: #70C217;

                                                    img {
                                                        margin-right: 3px;
                                                    }
                                                }

                                                .incris {
                                                    font-family: 'Poppins', sans-serif;
                                                    font-weight: 600;
                                                    font-size: 13px;
                                                    line-height: 20px;
                                                    color: #70C217;
                                                    display: flex;
                                                    align-items: center;

                                                    img {
                                                        margin-right: 3px;
                                                    }
                                                }
                                            }

                                            .rating {
                                                display: flex;
                                                align-items: center;

                                                .rating_point {
                                                    font-family: 'Poppins', sans-serif;
                                                    font-weight: 600;
                                                    font-size: 13px;
                                                    line-height: 20px;
                                                    color: #808191;
                                                }

                                                .stars {
                                                    display: flex;
                                                    align-items: center;
                                                    margin: 0 2px;
                                                }

                                                .num {
                                                    font-family: 'Poppins', sans-serif;
                                                    font-weight: 600;
                                                    font-size: 13px;
                                                    line-height: 20px;
                                                    color: #808191;
                                                }
                                            }
                                        }

                                        .mobile-most-liked-book-price-alignment {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            gap: 5px;
                                            padding: 11px 0 0 0;

                                            h5 {
                                                font-family: 'Poppins', sans-serif;
                                                font-weight: 600;
                                                font-size: 16px;
                                                line-height: 24px;
                                                color: #000000;

                                            }

                                            span {
                                                font-family: 'Poppins', sans-serif;
                                                font-weight: 400;
                                                font-size: 13px;
                                                line-height: 20px;
                                                color: #495057;
                                            }
                                        }

                                        .mobile-most-liked-like-share-alignment {
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            gap: 16px;

                                            .like-alignment {
                                                display: flex;
                                                align-items: center;
                                                gap: 2px;

                                                span {
                                                    font-family: 'Poppins', sans-serif;
                                                    font-weight: 500;
                                                    font-size: 13px;
                                                    line-height: 20px;
                                                    color: #808191;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}