.sellerAccountBalanceSection {
    margin: 0 0 16px 0;


    .sellerAccountMainBoxAlignment {
        border-radius: 10px;
        border: 2px solid #FFF;
        background: #FFF;
        width: 100%;

        @media only screen and (max-width:"767px") {
            border-radius: 16px;
            background: #FFF;
            box-shadow: 0px 2px 8px 0px rgba(30, 36, 50, 0.07);
            padding: 16px;
        }

        .sellerBalanceBoxALignment {
            background-color: hsla(228, 100%, 70%, 1);
            background-image: radial-gradient(at 98% 91%, hsla(266, 100%, 37%, 1) 0px, transparent 50%), radial-gradient(at 100% 34%, hsla(266, 100%, 37%, 1) 0px, transparent 50%), radial-gradient(at 100% 0%, hsla(266, 100%, 37%, 1) 0px, transparent 50%), radial-gradient(at 0% 100%, hsla(24, 100%, 57%, 1) 0px, transparent 50%), radial-gradient(at 0% 0%, hsla(228, 100%, 76%, 1) 0px, transparent 50%), radial-gradient(at 87% 71%, hsla(228, 100%, 40%, 1) 0px, transparent 50%);
            width: 100%;
            border-radius: 10px;

            .balanceBoxTopDetailsAlignment {
                border-bottom: 1px solid #CAD2F7;
                padding: 16px;

                .topFirstDetails {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    h4 {
                        color: #FFF;
                        font-family: 'Poppins', sans-serif;
                        font-size: 24px;
                        font-weight: 600;
                        line-height: 36px;
                    }

                    .historyAlignment {
                        display: flex;
                        align-items: center;
                        gap: 6px;
                        cursor: pointer;

                        h6 {
                            color: #CAD2F7;
                            text-align: right;
                            font-family: 'Poppins', sans-serif;
                            font-size: 13px;
                            font-weight: 500;
                            line-height: 19px;
                        }
                    }
                }

                .upcomingDetailsAlignment {
                    padding: 16px 0 0 0;
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    gap: 16px;

                    @media only screen and (max-width:"767px") {
                        padding: 10px 0 0 0;
                    }

                    h5 {
                        color: #FFF;
                        font-family: 'Poppins', sans-serif;
                        font-size: 13px;
                        font-weight: 600;
                        line-height: 19px;
                    }

                    .moreDetailsAlignment {
                        display: flex;
                        align-items: center;
                        gap: 6px;
                        cursor: pointer;

                        h6 {
                            color: #CAD2F7;
                            text-align: right;
                            font-family: 'Poppins', sans-serif;
                            font-size: 13px;
                            font-weight: 500;
                            line-height: 19px;
                        }
                    }

                }

                .upcomingPriceAlignmeny {
                    padding: 10px 0 0 0;

                    p {
                        color: #FFF;
                        text-align: right;
                        font-family: 'Poppins', sans-serif;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 24px;
                    }
                }
            }

            .balanceBoxBottomDetailsAlignment {
                padding: 16px;

                p {
                    color: #FFF;
                    font-family: 'Poppins', sans-serif;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 19px;
                }

                .availablePriceAlignment {

                    span {
                        color: #FFF;
                        text-align: right;
                        font-family: 'Poppins', sans-serif;
                        font-size: 32px;
                        font-weight: 600;
                        line-height: 36px;
                        display: block;
                    }
                }
            }
        }

        .sellerBalanceBottomDetailsAlignment {
            padding: 8px 16px;

            @media only screen and (max-width:"767px") {
                padding: 8px 0 0 0;
            }

            .sellerSetUpPaymentBox {
                border-radius: 8px;
                background: var( --primary-50);
                padding: 14px 12px 16px 16px;

                p {
                    color: var(--secondary-900);
                    font-family: 'Poppins', sans-serif;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 19px;
                }

                .sellerPaymentButtonAlignment {
                    padding: 16px 4px 0 0;

                    @media only screen and (max-width:"767px") {
                        padding: 16px 0 0 0;
                    }

                    button {
                        width: 100%;
                        border-radius: 8px;
                        background: var(--primary-500);
                        padding: 6px 21px;
                        color: #FFF;
                        text-align: center;
                        font-family: 'Poppins', sans-serif;
                        font-size: 15px;
                        font-weight: 500;
                        line-height: 20px;
                        cursor: pointer;
                    }
                }

                .sellerPaymentBottomDetails {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 16px 0 0 0;

                    .learnMoreAlignment {
                        a {
                            display: flex;
                            align-items: center;
                            gap: 4px;
                            color: var(--primary-500);
                            font-family: 'Poppins', sans-serif;
                            font-size: 13px;
                            font-weight: 400;
                            line-height: 19px;
                            cursor: pointer;
                        }
                    }

                    .sellerPaymentOtherDetails {
                        display: flex;
                        align-items: center;
                        gap: 6px;

                        .otherDetailsAlignment {
                            display: flex;
                            align-items: center;
                            gap: 2px;

                            img {
                                cursor: pointer;
                                display: block;
                            }

                            p {
                                color: #000;
                                font-family: 'Helvetica Neue';
                                font-size: 9.217px;
                                font-weight: 500;
                                line-height: 12px;
                            }
                        }

                    }
                }
            }

            .verificationReviewBoxAlignment {
                border-radius: 8px;
                background: var( --primary-50);
                padding: 12px;

                @media only screen and (max-width:"767px") {
                    padding: 16px;
                }

                .verificationReviewBoxHeadingAlignment {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    padding: 4px 4px 0 4px;

                    @media only screen and (max-width:"767px") {
                        padding: 0;
                    }

                    .verificationHeadingTextAlignment {
                        h4 {
                            color: #000;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 20px;
                        }

                        p {
                            color: var(--secondary-500);
                            font-family: 'Poppins', sans-serif;
                            font-size: 13px;
                            font-weight: 400;
                            line-height: 16px;
                        }
                    }
                }

                .verificationDescriptionText {
                    padding: 8px 0 0 0;

                    p {
                        color: var(--secondary-900);
                        font-family: 'Poppins', sans-serif;
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 18px;
                    }
                }

                .veficationDescriptionBottomDetails {
                    .sellerPaymentBottomDetails {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 16px 0 0 0;

                        .learnMoreAlignment {
                            a {
                                display: flex;
                                align-items: center;
                                gap: 4px;
                                color: var(--primary-500);
                                font-family: 'Poppins', sans-serif;
                                font-size: 13px;
                                font-weight: 400;
                                line-height: 19px;
                                cursor: pointer;
                            }
                        }

                        .sellerPaymentOtherDetails {
                            display: flex;
                            align-items: center;
                            gap: 6px;

                            .otherDetailsAlignment {
                                display: flex;
                                align-items: center;
                                gap: 2px;

                                img {
                                    cursor: pointer;
                                    display: block;
                                }

                                p {
                                    color: #000;
                                    font-family: 'Helvetica Neue';
                                    font-size: 9.217px;
                                    font-weight: 500;
                                    line-height: 12px;
                                }
                            }

                        }
                    }
                }
            }

            .withdrawButtonAlignment {
                padding: 8px 0 0 0;

                button {
                    border-radius: 8px;
                    background: var(--primary-100);
                    width: 100%;
                    height: 32px;
                    color: #FFF;
                    text-align: center;
                    font-family: 'Poppins', sans-serif;
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 20px;
                    cursor: pointer;

                    &.activeButton {
                        background: var(--primary-500);
                    }
                }
            }

            .verifiactionFailedBoxAlignment {
                border-radius: 8px;
                background: #FDE8E8;
                padding: 12px;
                margin: 0 0 8px 0;
                position: relative;

                @media only screen and (max-width:"767px") {
                    padding: 16px;
                }

                .verificationFailedBoxHeadingAlignment {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    padding: 4px 4px 0 4px;

                    @media only screen and (max-width:"767px") {
                        padding: 0;
                    }

                    .verificationFailedHeadingTextAlignment {
                        h4 {
                            color: #E71616;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 20px;
                        }

                        p {
                            color: #E71616;
                            font-family: 'Poppins', sans-serif;
                            font-size: 13px;
                            font-weight: 400;
                            line-height: 16px;
                        }
                    }
                }

                .verificationFlexAlignment {

                    .verificationFailedDescriptionText {
                        padding: 8px 0 0 0;

                        p {
                            color: var(--secondary-900);
                            font-family: 'Poppins', sans-serif;
                            font-size: 13px;
                            font-weight: 400;
                            line-height: 18px;
                            display: inline;
                        }

                        .learnMoreAlignment {
                            display: inline-block;
                            margin: 0 0 0 2px;

                            @media only screen and (max-width:"767px") {
                                display: block;
                            }

                            a {
                                display: flex;
                                align-items: center;
                                gap: 4px;
                                color: var(--primary-500);
                                font-family: 'Poppins', sans-serif;
                                font-size: 13px;
                                font-weight: 400;
                                line-height: 19px;
                                cursor: pointer;

                                @media only screen and (max-width:"767px") {
                                    justify-content: center;
                                }
                            }
                        }

                    }

                }

                .veficationDescriptionBottomDetails {
                    .sellerPaymentBottomDetails {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 16px 0 0 0;

                        .learnMoreAlignment {
                            a {
                                display: flex;
                                align-items: center;
                                gap: 4px;
                                color: var(--primary-500);
                                font-family: 'Poppins', sans-serif;
                                font-size: 13px;
                                font-weight: 400;
                                line-height: 19px;
                                cursor: pointer;
                            }
                        }

                        .sellerPaymentOtherDetails {
                            display: flex;
                            align-items: center;
                            gap: 6px;

                            .otherDetailsAlignment {
                                display: flex;
                                align-items: center;
                                gap: 2px;

                                img {
                                    cursor: pointer;
                                    display: block;
                                }

                                p {
                                    color: #000;
                                    font-family: 'Helvetica Neue';
                                    font-size: 9.217px;
                                    font-weight: 500;
                                    line-height: 12px;
                                }
                            }

                        }
                    }
                }

                .verificationFailedCloseAlignment {
                    position: absolute;
                    top: 16px;
                    right: 16px;

                    svg {
                        cursor: pointer;
                    }
                }
            }

            .verifiactionSuccessBoxAlignment {
                border-radius: 8px;
                background: #E8F3E6;
                padding: 12px;
                margin: 0 0 8px 0;
                position: relative;

                @media only screen and (max-width:"767px") {
                    padding: 16px;
                }

                .verificationSuccessBoxHeadingAlignment {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    padding: 4px 4px 0 4px;

                    @media only screen and (max-width:"767px") {
                        padding: 0;
                    }

                    .verificationSuccessHeadingTextAlignment {
                        h4 {
                            color: #1D8603;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 20px;
                        }

                        p {
                            color: #1D8603;
                            font-family: 'Poppins', sans-serif;
                            font-size: 13px;
                            font-weight: 400;
                            line-height: 16px;
                        }
                    }
                }

                .verificationFlexAlignment {

                    @media only screen and (max-width:"767px") {
                        max-width: 244px;
                    }

                    .verificationSuccessDescriptionText {
                        padding: 8px 0 0 0;

                        p {
                            color: var(--secondary-900);
                            font-family: 'Poppins', sans-serif;
                            font-size: 13px;
                            font-weight: 400;
                            line-height: 18px;
                            display: inline;
                        }

                        .learnMoreAlignment {
                            display: inline-block;
                            margin: 0 0 0 2px;

                            a {
                                display: flex;
                                align-items: center;
                                gap: 4px;
                                color: var(--primary-500);
                                font-family: 'Poppins', sans-serif;
                                font-size: 13px;
                                font-weight: 400;
                                line-height: 19px;
                                cursor: pointer;
                            }
                        }

                    }

                }

                .veficationDescriptionBottomDetails {
                    .sellerPaymentBottomDetails {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 16px 0 0 0;

                        .learnMoreAlignment {
                            a {
                                display: flex;
                                align-items: center;
                                gap: 4px;
                                color: var(--primary-500);
                                font-family: 'Poppins', sans-serif;
                                font-size: 13px;
                                font-weight: 400;
                                line-height: 19px;
                                cursor: pointer;
                            }
                        }

                        .sellerPaymentOtherDetails {
                            display: flex;
                            align-items: center;
                            gap: 6px;

                            .otherDetailsAlignment {
                                display: flex;
                                align-items: center;
                                gap: 2px;

                                img {
                                    cursor: pointer;
                                    display: block;
                                }

                                p {
                                    color: #000;
                                    font-family: 'Helvetica Neue';
                                    font-size: 9.217px;
                                    font-weight: 500;
                                    line-height: 12px;
                                }
                            }

                        }
                    }
                }

                .verificationSuccessCloseAlignment {
                    position: absolute;
                    top: 16px;
                    right: 16px;

                    @media only screen and (max-width:"767px") {
                        right: 55px;
                    }

                    svg {
                        cursor: pointer;
                    }
                }
            }


            .withdrawPandingBox {
                border-radius: 8px;
                border: 1px solid var(--secondary-50);
                padding: 8px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                p {
                    color: var(--secondary-900);
                    font-family: 'Poppins', sans-serif;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 20px;
                }

                svg {
                    cursor: pointer;
                }
            }
        }
    }
}

.thankYouBoxAlignment {
    padding: 96px 55px 64px 55px;
    text-align: center;

    .thankYouRightIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0 53px 0;
    }

    .thankYouDescription {
        h4 {
            color: var(--secondary-900);
            text-align: center;
            font-family: 'Poppins', sans-serif;
            font-size: 24px;
            font-weight: 600;
            line-height: 36px;
        }

        p {
            color: var(--secondary-900);
            text-align: center;
            font-family: 'Poppins', sans-serif;
            font-size: 13px;
            font-weight: 400;
            line-height: 19px;
        }
    }

    .closeButtonAlignment {
        padding: 88px 0 0 0;

        button {
            height: 44px;
            padding: 0 72px;
            border-radius: 10px;
            background: var(--primary-500);
            color: #FFF;
            text-align: center;
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
        }
    }
}