.bookMarkCollectionCardDetails {
  position: relative;
  width: 100%;
  height: 200px;
  cursor: pointer;

  @media only screen and (max-width: '767px') {
    height: 220px;
    width: 100%;
  }

  @media only screen and (max-width: '499px') {
    height: 113px;
    width: 113px;
  }

  .bookMarkCollectionCardImg {
    width: 200px;
    height: 200px;
    border-radius: 16px;
    border: 6px solid #fff;
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    transform: rotate(-6.983deg);
    position: relative;

    .tagDesign {
      position: absolute;
      top: 10px;
      right: 10px;
      button {
        border: none;
        border-radius: 10px;
        padding: 4px 16px;
        cursor: pointer;
        color: #fff;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        background-color: #000;
      }
    }

    @media only screen and (max-width: '767px') {
      border: 3px solid #fff;
      border-radius: 10px;
      width: 100%;
      height: 220px;
    }

    @media only screen and (max-width: '499px') {
      border: 3px solid #fff;
      border-radius: 10px;
      width: 113px;
      height: 113px;
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 16px;
      object-fit: cover;

      @media only screen and (max-width: '767px') {
        border-radius: 10px;
        object-position: center;
      }
    }

    &:nth-child(2) {
      position: absolute;
      top: 0;
      left: 10px;
      transform: rotate(0);
    }

    &:nth-child(3) {
      position: absolute;
      top: 0;
      left: 20px;
      transform: rotate(5.357deg);
      z-index: 8;
    }
  }

  .bookMarkCollectionCardImg2 {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    border: 6px solid #fff;
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    left: 10px;

    @media only screen and (max-width: '767px') {
      border: 3px solid #fff;
      border-radius: 10px;
    }

    @media only screen and (max-width: '499px') {
      width: 113px;
    }
    img {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 16px;
      object-fit: cover;

      @media only screen and (max-width: '767px') {
        border-radius: 10px;
      }
    }
  }

  .bookMarkCollectionCardImg3 {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    border: 6px solid #fff;
    background: #fff;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    left: 20px;
    transform: rotate(5.357deg);

    @media only screen and (max-width: '767px') {
      border: 3px solid #fff;
      border-radius: 10px;
    }
    @media only screen and (max-width: '499px') {
      width: 113px;
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 16px;
      object-fit: cover;

      @media only screen and (max-width: '767px') {
        border-radius: 10px;
      }
    }
  }
}

.bookMarkCollectionProductNameDetailsAlignment {
  padding: 18px 0 0 0;
  cursor: pointer;

  @media only screen and (max-width: '767px') {
    padding: 12px 0 0 0;
  }

  h6 {
    color: var(--secondary-900);
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    max-width: 132px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 auto;

    @media only screen and (max-width: '767px') {
      font-size: 14px;
      line-height: 21px;

    }
  }

  p {
    padding: 2px 0 0 0;
    color: var(--secondary-500);
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    font-weight: 20px;
    line-height: normal;

    @media only screen and (max-width: '767px') {
      font-size: 12px;
      line-height: 14px;
    }
  }

  .bookMarkAllCollectionDetails {
    display: flex;
    align-items: center;
    gap: 4px;

    span {
      color: var(--secondary-500);
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }

    p {
      width: 2px;
      height: 2px;
      border-radius: 50%;
      background: var(--secondary-300);
    }
  }

  &.centerBookMarkDetails {
    h6 {
      margin: 0 auto;
      text-align: center;
    }

    .bookMarkAllCollectionDetails {
      justify-content: center;
    }
  }
}
