@mixin on($display) {
  display: $display;
  visibility: visible;
  touch-action: auto;
  pointer-events: all;
}

@mixin off($display) {
  display: $display;
  visibility: hidden;
  touch-action: none;
  pointer-events: none;
}

@mixin block_btn {
  background: var(--primary-500);
  border-radius: 8px;
  display: block;
  width: 100%;
  border: unset;
  outline: unset;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    transform: translateY(-3px);
    background: #2844ac;
  }
}

.men-trendingProducts-section {
  background: #151515;

  .trending-back-alignment {
    display: flex;
    align-items: center;
    gap: 22px;
    padding: 8px 10px;
    background: #1e2432;
    display: none;

    @media only screen and (max-width: '767px') {
      display: flex;
      position: sticky;
      top: 0;
      z-index: 99;
    }

    .back_details-alignment {
      background: rgba(172, 177, 192, 0.4);
      border-radius: 10px;
      padding: 4px 9px 4px 10px;
      display: flex;
      align-items: center;
      gap: 8px;

      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #e4e4e8;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 58px;
      }
    }

    .mobile-heading-alignment {
      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #f8f8f8;
      }
    }
  }

  .trendingProduct-banner {
    background: #151515;
    // background-image: url("../../../assets/img/MensCategory/trendingProdutc.png");
    // width: 100%;
    // height: 100%;
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: center;
    position: sticky;
    top: 0px;
    z-index: 99;

    @media only screen and (max-width: '1199px') {
      top: 0;
    }

    @media only screen and (max-width: '767px') {
      // background-image: url("../../../assets/img/MensCategory/mobileTrendingProductBg.svg");
      // background-size: cover;
      padding: 0 16px;
      position: sticky;
      top: 48px;
      z-index: 99;
    }

    .men-trending-banner-img-alignment {
      position: absolute;
      top: 0;
      right: 0;

      img {
        width: 100%;
        height: 178px;
        object-fit: cover;
        object-position: center;
        display: block;

        @media only screen and (max-width: '767px') {
          display: none;
        }

        &.mobile-view-banner {
          display: none;

          @media only screen and (max-width: '767px') {
            display: block;
            height: 100px;
            display: block;
          }
        }
      }
    }

    .container {
      max-width: 1300px;
      margin: auto;
      padding: 0 20px;

      @media only screen and (max-width: '767px') {
        padding: 0;
      }

      div {
        img {
          cursor: pointer;
          display: block;
        }
      }

      .trendingProduct-banner-section {
        height: 178px;
        display: flex;
        align-items: center;
        gap: 160.5px;
        position: relative;

        @media only screen and (max-width: '1299px') {
          gap: 80px;
          padding: 0 20px;
        }

        @media only screen and (max-width: '767px') {
          height: 100px;
        }

        .left-arrow-alignment {
          @media only screen and (max-width: '767px') {
            display: none;
          }
        }
      }

      .trending-heading-alignment {
        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 40px;
          line-height: 60px;
          color: #f8f8f8;
          padding: 0 0 4px 0;
          text-transform: uppercase;

          @media only screen and (max-width: '767px') {
            font-size: 20px;
            line-height: 30px;
          }
        }

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 300;
          font-size: 18px;
          line-height: 27px;
          color: #f8f8f8;
          text-transform: capitalize;

          @media only screen and (max-width: '767px') {
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 200px;
          }
        }
      }
    }
  }

  .trendingImg-gallery-section {
    padding: 32px 0;
    background-color: var(--secondary-900);
    height: calc(100vh - 178px);
    height: calc(100vh - 178px - 64px);
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: '1024px') {
      padding: 16px 0;
      height: 100%;
      overflow-y: auto;
    }

    .container {
      max-width: 1300px;
      margin: auto;
      padding: 0 20px;

      .trending_product_block {
        width: 100%;

        .trending_product_block_row {
          margin: 0 -8px;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          align-items: stretch;

          @media only screen and (max-width: 969px) {
            grid-template-columns: repeat(3, 1fr);
          }

          @media only screen and (max-width: 769px) {
            grid-template-columns: repeat(2, 1fr);
          }
          .card_top {
            height: 100%;
          }

          .card_col {
            width: 100%;
            padding: 0 8px;
            height: 100%;
            // display: grid;
            display: flex;
            flex-direction: column;
            max-height: 564px;

            .cardcover {
              width: 100%;
              height: 100%;
              border-radius: 10px;
              position: relative;
              overflow: hidden;
              cursor: pointer;
              margin-bottom: 15px;

              &:hover {
                .card_top img {
                  transform: scale(1.05);
                }

                .card_bottom {
                  @include on(flex);
                }
              }

              .card_top img {
                width: 100%;
                height: 100%;
                transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                transform: scale(1.02);
                object-fit: cover;
              }

              .card_bottom {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, rgba(30, 36, 50, 0) 0%, #1e2432 100%);
                padding: 16px;
                display: flex;
                align-items: flex-end;
                @include off(none);

                .card_bottom_cover {
                  width: 100%;

                  h4 {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 30px;
                    color: #f8f8f8;

                    @media only screen and (max-width: '768px') {
                      font-size: 16px;
                      line-height: 24px;
                    }
                  }

                  .amount {
                    margin: 5px 0 8px 0;

                    @media only screen and (max-width: '768px') {
                      display: none;
                    }

                    span {
                      vertical-align: middle;
                    }

                    .price {
                      font-weight: 600;
                      font-size: 18px;
                      line-height: 27px;
                      color: #f8f8f8;
                    }

                    .orignal_price {
                      text-decoration-line: line-through;
                      font-weight: 500;
                      font-size: 12px;
                      line-height: 18px;
                      color: #ffffff;
                      opacity: 0.6;
                      margin: 0 6px;
                    }

                    .discount {
                      font-weight: 500;
                      font-size: 12px;
                      line-height: 18px;
                      text-align: center;
                      color: #ffffff;
                      background: #ff7d23;
                      border-radius: 11px;
                      padding: 2px 6px;
                    }
                  }

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      display: flex;
                      align-items: center;
                      font-size: 12px;
                      line-height: 18px;
                      color: #f8f8f8;

                      img {
                        margin-right: 4px;
                      }
                    }

                    .incris {
                      margin-left: 15px;
                      display: flex;
                      align-items: center;
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 21px;
                      color: #70c217;

                      img {
                        margin-right: 4px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;
                    margin: 9px 0 18px 0;

                    @media only screen and (max-width: '768px') {
                      display: none;
                    }

                    .rating_point {
                      font-weight: 600;
                      font-size: 16px;
                      line-height: 24px;
                      color: #f8f8f8;
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 8px;
                    }

                    .num {
                      font-size: 16px;
                      line-height: 24px;
                      color: #f8f8f8;
                    }
                  }

                  .buy_btn {
                    @include block_btn();

                    @media only screen and (max-width: '768px') {
                      display: none;
                    }
                  }
                }

                .WomeCardContent {
                  display: flex;
                  flex-direction: column;
                  gap: 8px;
                  align-items: start;
  
                  h1 {
                    color: #F8F8F8;
                    font-family: Poppins;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                  }
  
                  .WomenCardPrice {
                    display: flex;
                    align-items: end;
                    justify-content: start;
                    gap: 11px;
  
                    p {
                      color: #F8F8F8;
                      font-family: Poppins;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 600;
                      line-height: normal;
                    }
  
                    del {
                      color: #999999;
                      font-family: Poppins;
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 500;
                      line-height: normal;
                      text-decoration-line: strikethrough;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
