.categoryBrowsedByTypeSection {
  background: var(--white);
  padding: 16px;
  @media only screen and (max-width: '1024px') {
    height: 100vh;
    padding: 0;
    overflow-y: auto;
  }

  .categoryMobileViewHeaderAlignment {
    display: none;
    @media only screen and (max-width: '1024px') {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 46px;
      padding: 8px 16px;
      position: relative;
      text-align: center;
      position: sticky;
      top: 0;
      left: 0;
      background: var(--white);
      z-index: 999;
      .mobileViewBack {
        width: 37px;
        height: 32px;
        position: absolute;
        left: 16px;
        top: 8px;
        cursor: pointer;
      }

      h4 {
        color: var(--secondary-900);
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }
  .categoryBrowsedByTypeDetails {
    @media only screen and (max-width: '1024px') {
      padding: 0 0 24px 0;
    }
    .categoryBrowsedByTypeSlider {
      @media only screen and (max-width: '1024px') {
        padding: 24px 0 0 16px;
      }
    }

    .categoryHotDetailsAlignment {
      padding: 30px 0 0 0;
      @media only screen and (max-width: '1024px') {
        padding: 25px 0 0 0;
      }
      .hotHeading {
        @media only screen and (max-width: '1024px') {
          display: none;
        }
        h4 {
          color: var(--bookmark-heading-color);
          font-size: 24px;
          font-weight: 600;
          line-height: 36px;
        }
      }

      .specialOfferAlignment {
        padding: 26px 0 48px 0;
        @media only screen and (max-width: '1024px') {
          padding: 0 0 0 16px;
        }

        .childHeading {
          display: flex;
          align-items: center;
          justify-content: space-between;
          h6 {
            color: var(--black);
            font-size: 18px;
            font-weight: 600;
            line-height: 17px;

            @media only screen and (max-width: '1024px') {
              font-size: 20px;
              line-height: 24px;
            }
          }
          .seeMoreAlignment {
            padding: 6px 16px;
            cursor: pointer;
            a {
              color: var(--primary-500);
              font-size: 15px;
              font-weight: 500;
              line-height: 20px;
            }
          }
        }

        .specialOfferAllBodyDetailsAlignment {
          padding: 16px 0 0 0;
          .specialOfferAllGrid {
            display: flex;
            grid-template-columns: 468px 1fr;
            gap: 24px;
            @media only screen and (max-width: '1024px') {
              display: block;
            }
            .specialOfferAllGridItem {
              &:first-child {
                width: 468px;
                max-width: 468px;
                min-width: 468px;
                @media only screen and (max-width: '1024px') {
                  width: calc(100% - 16px);
                  max-width: calc(100% - 16px);
                  min-width: calc(100% - 16px);
                  margin: 0 16px 16px 0;
                }
              }
              &:last-child {
                width: calc(100% - 468px - 32px);
                max-width: calc(100% - 468px - 32px);
                min-width: calc(100% - 468px - 32px);
                @media only screen and (max-width: '1024px') {
                  width: 100%;
                  max-width: 100%;
                  min-width: 100%;
                }
              }
              .specialOfferImg {
                width: 100%;
                height: 208px;
                @media only screen and (max-width: '1024px') {
                  height: 152px;
                }
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  display: block;
                  border-radius: 14.345px;
                }
              }

              .specialOfferSliderAlignment {
                width: 100%;
       
                .specialOfferDetailsAlignment {
                  padding: 0 12px;
                  @media only screen and (max-width: '576px') {
                    padding: 0 8px;
                  }
                }
                ::global(.slick-slide) {
                  padding: 0 4px !important;
                }
                ::global(.slick-arrow) {
                  &.slick-prev {
                    display: none;
                  }
                  &.slick-next {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
