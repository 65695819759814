.mobileJewelryHotDetailsModalSection {
    .mobileJewelryHotDetailsModalAlignment {
        padding: 16px;

        .mobileJewelryHeading {
            padding: 0 0 16px 0;

            h6 {
                color: var(--secondary-900);
                font-family: 'Poppins', sans-serif;
                font-size: 20px;
                font-weight: 600;
                line-height: 24px;
            }
        }

        .mobileJewelryAllDetailsAlignment {
            height: calc(100vh - 130px);
            overflow-y: auto;
            padding: 0 0 16px 0;

            .mobileJewelryAllDetailsGrid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 16px;

                .mobileJewelryAllDetailsGridItem {
                    .mobileJewelryHotDetailsImg {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        cursor: pointer;

                        &:hover {
                            transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);
                            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
                            transform: translateY(-4px);
                            border-radius: 9.669px;
                        }

                        &::before {
                            position: absolute;
                            content: '';
                            width: 100%;
                            height: 100%;
                            border-radius: 9.669px;
                            background: linear-gradient(180deg, rgba(72, 72, 72, 0.00) 0%, #2E2E2E 100%);
                            top: 0;
                            left: 0;
                            z-index: 9;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 9.669px;
                        }

                        .mobileJewelryHotDetails {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            padding: 12px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: flex-start;
                            z-index: 9;

                            .mobileOfferBoxAlignment {
                                background: #F1EFB0;
                                padding: 4px;

                                p {
                                    color: #474400;
                                    text-align: center;
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 500;
                                    font-size: 12px;
                                    line-height: 18px;
                                }

                            }

                            .mobileOfferBoxPriceAlignment {

                                display: flex;
                                align-items: center;
                                gap: 4px;

                                h6 {
                                    color: #FFF;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 18px;
                                    font-weight: 600;
                                    line-height: 27px;
                                }

                                p {
                                    color: var(--secondary-100);
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 400;
                                    text-decoration-line: strikethrough;
                                    font-size: 12px;
                                    line-height: 18px;

                                }
                            }
                        }

                        .mobileViewOptionAlignment {
                            position: absolute;
                            top: 2px;
                            right: 2px;
                            z-index: 99;
                            display: none;

                            @media only screen and (max-width:"767px") {
                                display: block;
                            }

                            svg {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }
    }
}