.clubAboutDetailsAlignment {
  .clubAboutMoreDetailsAlignment {
    margin: 0 0 16px 0;
    display: flex;
    align-items: center;
    gap: 26px;

    @media only screen and (max-width: '1024px') {
      display: none;
    }

    .clubAboutMediaImgAlignment {
      display: flex;
      align-items: center;
      .clubProfileImg {
        width: 40px;
        height: 40px;
        min-width: 40px;
        max-width: 40px;
        margin: 0 -13px 0 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
          border: 2px solid var(--white);
        }
      }
    }

    .clubAboutNotesAlignment {
      max-width: 217px;
      p {
        color: var(--secondary-900);
        font-size: 11px;
        font-weight: 400;
        line-height: 14px;

        span {
          font-weight: 600;
        }
      }
    }
  }

  .aboutGroupAlignment {
    border-radius: 16px;
    background: #f3f5f8;
    padding: 20px 16px;

    @media only screen and (max-width: '1099px') {
      display: none;
    }

    h5 {
      color: var(--secondary-900);
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0.1px;
      padding: 0 0 10px 0;
    }

    p {
      // overflow: hidden;

      color: var(--secondary-900);
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.1px;

      span {
        color: var(--primary-500);
        font-size: 15px;
        font-weight: 500;
        line-height: 28px;
        white-space: nowrap;
        cursor: pointer;
        padding: 0 0 0 8px;

        &.mobileViewSaw {
          display: none;
          @media only screen and (max-width: '1204px') {
            display: inline-block;
          }
        }
        &.webViewSaw {
          display: inline-block;
          @media only screen and (max-width: '1204px') {
            display: none;
          }
        }
      }
    }
  }

  .mobileViewClubDetailsAlignment {
    display: none;

    @media only screen and (max-width: '1024px') {
      display: block;
      background: #ffffff;
      border-radius: 16px 16px 0px 0px;
      padding: 18px 16px;
      position: relative;
      margin: -16px 0 0 0;
      width: 100%;

      .mobileViewClubFlexAlignment {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .mobileViewclubProfileAlignment {
          display: flex;
          align-items: center;
          gap: 16px;

          .mobileViewImgAlignment {
            width: 90px;
            min-width: 90px;
            max-width: 90px;
            height: 65px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 6.20606px;
              object-fit: cover;
            }
          }

          .mobileViewClubChildDetailsAlignment {
            h4 {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 18px;
              line-height: 120%;
              color: var(--black);
            }

            .mobileMemeberDetailsAlignment {
              display: flex;
              align-items: center;
              gap: 4px;
              padding: 4px 0 0 0;

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: var(--secondary-500);
              }
            }
          }
        }

        .mobileViewnotificationAlignment {
          width: 32px;
          min-width: 32px;
          max-width: 32px;
          height: 32px;
          border: 1.25px solid var(--secondary-100);
          border-radius: 7.27273px;
          display: flex;
          align-items: center;
          justify-content: center;
          @media only screen and (max-width: '1024px') {
            display: none;
          }
        }
      }

      .mobileViewbuttonAlignment {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 16px 0 0 0;

        .joinClubButtonAlignment {
          width: 100%;

          button {
            width: 100%;
            background: var(--primary-500);
            border-radius: 8px;
            height: 32px;
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            color: #ffffff;
            cursor: pointer;
          }
        }

        .mobileViewMoreOptionAlignment {
          border: 1.25px solid var(--secondary-100);
          border-radius: 7.27273px;
          width: 32px;
          height: 32px;
          min-width: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .mobileViewjoinClubDetailsAlignment {
        padding: 16px 0 0 0;
        display: none;
        @media only screen and (max-width: '1024px') {
          display: grid;
          grid-template-columns: 1fr 32px;
          gap: 8px;

          .mobileViewJoinClubButton {
            width: 100%;
            button {
              width: 100%;
              height: 32px;
              border-radius: 8px;
              background: var(--primary-500);
              color: var(--white);
              text-align: center;
              font-size: 15px;
              font-weight: 500;
              line-height: 20px;
              cursor: pointer;
              &.joinedButton {
                border-radius: 8px;
                background: var(--secondary-50);
                color: var(--secondary-500);
              }
            }
          }

          .mobileVIewMoreOption {
            width: 100%;
            height: 100%;
            border-radius: 7.273px;
            border: 1.25px solid var(--secondary-100);
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .mobileViewAboutClubDetailsAlignment {
        padding: 16px 0 0 0;

        h5 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: var(--secondary-900);
        }

        p {
          padding: 8px 0 0 0;
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #495057;

          a {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            color: var(--primary-500);
            &.webviewSeeMore {
              @media only screen and (max-width: '1024px') {
                display: none;
              }
            }

            &.mobileviewSeeMore {
              display: none;
              @media only screen and (max-width: '1024px') {
                display: inline-block;
              }
            }
          }
        }
      }

      .mobileViewadminDetailsAlignment {
        padding: 16px 0 0 0;

        h5 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: var(--secondary-900);
        }

        .mobileViewAdminAllDetailsAlignment {
          padding: 8px 0 0 0;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .mobileViewadminProfileDetailsAlignment {
            display: flex;
            align-items: center;
            gap: 8px;

            .mobileViewprofileImgAlignment {
              width: 32px;
              min-width: 32px;
              max-width: 32px;
              height: 32px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
              }
            }

            .mobileAdminProfileNameAlignment {
              h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                color: var(--secondary-900);
              }

              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: #acb1c0;
              }
            }
          }

          .mobileFollowButtonAlignment {
            button {
              padding: 4px 10px;
              background: transparent;
              border-radius: 6px;
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 15px;
              line-height: 22px;
              text-align: center;
              color: var(--primary-500);
              cursor: pointer;

              &.following {
                border: 1px solid #556ee6;
                padding: 4px 10px;
                background: var(--white);
                border-radius: 6px;
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                text-align: center;
                color: var(--primary-500);

                &:hover {
                  background: var(--light-grey-3);
                }
              }
            }
          }
        }
      }

      .mobileViewNotesAlignment {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 16px 0 0 0;

        .mobileViewNotesPersonImgAlignment {
          display: flex;
          align-items: center;

          .imgLayoutAlignment {
            margin: 0 0 0 -10px;

            &:first-child {
              margin: 0;
            }

            .mobileViewimgAlignment {
              width: 20px;
              height: 20px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
                border: 1px solid #ffffff;
              }
            }
          }
        }

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: var(--secondary-500);
        }
      }
    }
  }

  .newestMembersSection {
    border-radius: 16px;
    background: #f3f5f8;
    padding: 16px;
    margin: 8px 0 0 0;

    @media only screen and (max-width: '1099px') {
      display: none;
    }

    .newestMembersHeaderAlignment {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: var(--black);
      }

      a {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.1px;
        color: var(--primary-500);
        cursor: pointer;
      }
    }

    .memberDetailsSilderAlignment {
      margin: 23px 0 0 0;

      .slick-arrow {
        display: none !important;
      }

      .memeberDetailsAlignment {
        padding: 0 16px;

        .memberProfileImgAlignment {
          width: 116px;
          min-width: 116px;
          max-width: 116px;
          height: 116px;

          margin: auto;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        .memeberChildDetailsAlignment {
          padding: 11px 0 0 0;
          h5 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: var(--black);
            padding: 0 0 4px 0;
            text-align: center;
            white-space: nowrap;
            cursor: pointer;
          }

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            color: var(--grey2);
          }

          span {
            color: var(--grey2);
            text-align: center;
            font-size: 13px;
            font-weight: 400;
            line-height: 21px;
            display: block;
          }

          .followButtonAlignment {
            padding: 16px 0 0 0;

            button {
              background: var(--primary-500);
              border-radius: 8px;
              width: 100%;
              padding: 6px 0;
              cursor: pointer;
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              color: #f8f8f8;

              &.following {
                background: #f8f8f8;
                color: var(--primary-500);

                &:hover {
                  background: #eef1fc;
                }
              }
            }
          }
        }
      }
    }

    :global(.slick-prev) {
      left: 21px;
      z-index: 99;
      display: none !important;

      @media (max-width: 576px) {
        display: none !important;
      }
    }

    :global(.slick-next) {
      right: -50px;
      z-index: 999;
      display: none !important;

      @media (max-width: 576px) {
        display: none !important;
      }
    }

    :global(.slick-arrow:hover) {
      background: #fff !important;
    }

    :global(.slick-dots) {
      display: none !important;

      @media (max-width: 1024px) {
        display: none !important;
      }
    }
  }

  .recentActivityAlignment {
    background: #ffffff;
    border-radius: 16px;
    padding: 24px 32px;
    margin: 19px 0 0 0;

    @media only screen and (max-width: '1099px') {
      display: none;
    }

    h6 {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: var(--black);
    }

    .recentDetailsMainSection {
      margin: 24px 0 0 0;
      margin: 24px 0 0 0;
      height: auto;
      max-height: 160px;
      overflow-y: auto;

      .recentBoxAlignment {
        padding: 8px;
        border: 1px solid #f1f1f5;
        border-radius: 10px;
        display: flex;
        align-items: flex-start;
        gap: 8px;
        margin: 0 0 10px 0;

        &:last-child {
          margin: 0;
        }

        .recentProfileImgAlignment {
          width: 24px;
          max-width: 24px;
          min-width: 24px;
          height: 24px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        .recentProfileDetailsAlignment {
          .recentNameCommentAlignment {
            display: flex;
            align-items: center;
            gap: 3px;

            h4 {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              color: #495057;
            }

            .commentAlignment {
              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: #495057;

                span {
                  font-weight: 600;
                }
              }
            }
          }

          .mainCommentAlignment {
            padding: 4px 0 0 0;

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #495057;
            }
          }

          .timeAlignment {
            padding: 8px 0 0 0;

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #808191;
            }
          }
        }
      }
    }
  }
}
