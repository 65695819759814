.learn-artists-section {
    margin: 61px 0 0 0;

    @media only screen and (max-width:"768px") {
        margin: 32px 0 0 0;
    }

    .learn-artists-alignment {
        background: #F3F8FE;
        border-radius: 17.7404px;
        padding: 48px 56px;

        @media only screen and (max-width:"768px") {
            padding: 16px 0 16px 16px;
        }

        .learn-artists-heading-alignment {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            @media only screen and (max-width:"768px") {
                padding: 0 16px 0 0;
            }

            .learn-artists-title-alignment {
                h4 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 36px;
                    line-height: 54px;
                    color: var(--secondary-900);

                    @media only screen and (max-width:"768px") {
                        font-size: 20px;
                        line-height: 30px;
                        max-width: 180px;
                    }

                }

                p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--secondary-900);

                    @media only screen and (max-width:"768px") {
                        display: none;
                    }
                }
            }

            .learn-artists-see-more-alignment {
                a {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    color: var(--primary-500);
                    cursor: pointer;

                    @media only screen and (max-width:"768px") {
                        font-size: 14px;
                        line-height: 21px;

                        img {
                            display: none;
                        }
                    }

                    &:hover {
                        gap: 10px;
                        text-decoration: underline;
                    }
                }
            }
        }

        .learn-artists-details-alignment {
            padding: 48px 0 0 0;

            @media only screen and (max-width:"768px") {
                display: none;
            }

            .learn-artists-grid-alignment {
                display: grid;
                grid-template-columns: 1fr 460px;
                gap: 12px;

                @media only screen and (max-width:"1099px") {
                    grid-template-columns: 1fr 370px;
                }

                .learn-artists-gridItem-alignment {
                    .left-side-learn-artists-img-alignment {
                        height: 387px;
                        width: 100%;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 16px;
                            object-fit: cover;
                        }
                    }

                    .right-side-learn-artists-alignment {
                        height: 387px;
                        overflow-y: auto;

                        @media only screen and (max-width:"768px") {
                            display: none;
                        }

                        .artists-details-box-alignment {
                            display: flex;
                            align-items: center;
                            gap: 16px;
                            padding: 8px;
                            margin: 0 0 8px 0;
                            cursor: pointer;

                            &:hover {
                                transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

                                transform: translateY(-4px);
                            }

                            &.artists-details-active {
                                background: var(--secondary-700);
                                border-radius: 8px;

                                .artist-details-alignment {
                                    h6 {
                                        color: #fff;
                                    }

                                    h4 {
                                        color: #fff;
                                    }

                                    p {
                                        color: #fff;
                                    }
                                }
                            }

                            &:last-child {
                                margin: 0;
                            }

                            .artist-img-alignmet {
                                width: 130px;
                                height: 74px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 4px;
                                    object-fit: cover;
                                }
                            }

                            .artist-details-alignment {
                                h6 {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 600;
                                    font-size: 13px;
                                    line-height: 20px;
                                    color: #606060;
                                }

                                h4 {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 24px;
                                    color: #000000;
                                }

                                p {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 400;
                                    font-size: 13px;
                                    line-height: 20px;
                                    color: #606060;
                                    padding: 2px 0 0 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        .mobileview-learn-artists-details-alignment {
            display: none;

            @media only screen and (max-width:"768px") {
                display: block;
                padding: 32px 0 0 0;
            }

            .mobileview-learn-artists-all-alignment {
                padding: 0 4px;

                .mobileview-learn-artists-img-alignment {
                    width: 100%;
                    height: 192px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        display: block;
                        border-radius: 8px;
                    }
                }

                .mobileview-learn-artists-child-details-alignment {
                    padding: 8px;

                    span {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 13px;
                        line-height: 20px;
                        color: #606060;
                    }

                    h6 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--secondary-900);
                    }

                    p {
                        padding: 2px 0 0 0;
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 20px;
                        color: #606060;
                    }
                }
            }

        }
    }
}