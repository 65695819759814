.expendedMediaCommentModalSection {
  @media only screen and (max-width: '1024px') {
    // height: calc(100% - 28px);
    height: calc(100% - 174px);
  }
  .expendedMediaCommentModalAlignment {
    padding: 16px;
    @media only screen and (max-width: '1024px') {
      height: 100%;
    }
    h4 {
      color: var(--secondary-900);
      font-size: 20px;
      font-weight: 500;
      line-height: 21px;
    }
    .expendedMediaCommentModalAllDetails {
      padding: 16px 0 0 0;
      @media only screen and (max-width: '1024px') {
        height: calc(100% - 80px);
      }
    }

    .expendedMediaCommentBoxAlignment {
      padding: 8px 0;
      border-top: 1px solid var(--secondary-50);
      background: var(--white);
      display: grid;
      grid-template-columns: 1fr 44px;
      gap: 8px;
      @media only screen and (max-width: '1024px') {
        position: sticky;
        bottom: 0;
        left: 0;
        background: var(--white);
        z-index: 999;
      }
      .expendedMediaCommentBoxInput {
        border-radius: 10px;
        border: 1px solid var(--light-grey-100);
        background: var(--light-grey-2);
        height: 44px;
        width: 100%;
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        .expendedMediaInput {
          width: 100%;
          height: 100%;
          input {
            width: 100%;
            height: 100%;
            background: transparent;
            border: none;
          }
        }

        .picIconAlignment {
          width: 18px;
          min-width: 18px;
          max-width: 18px;
          height: 18px;
        }
      }

      .expendedMediaBoxAlignment {
        width: 44px;
        min-width: 44px;
        max-width: 44px;
        height: 44px;
        border-radius: 10px;
        background: var(--primary-50);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
