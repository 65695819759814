.mediaSectionAlignment {
  margin: 0 95px;

  @media only screen and (max-width: '1099px') {
    margin: 8px 0 0 0;
  }

  .mediaStoreGrid {
    display: grid;
    grid-template-columns: 1fr 412px;
    gap: 16px;

    @media only screen and (max-width: '1099px') {
      grid-template-columns: 1fr;
      gap: 0;
    }

    .mediaStoreGridItem {
      .mediaGalleryBoxAlignment {
        background: #fff;

        @media only screen and (max-width: '1099px') {
          padding: 16px;
          border-radius: 0;
        }

        .mobileViewMediaGalleryDetailsAlignment {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 0 16px 0;

          // @media only screen and (max-width:"799px") {
          //   max-width: 342px;
          //   margin: auto;
          // }

          h6 {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: var(--secondary-900);
          }

          .mobileViewPhotoVideoDropdownAlignment {
            height: 32px;
            display: flex;
            align-items: center;
            gap: 12px;
            border: 1.5px solid var(--secondary-100);
            border-radius: 8px;
            padding: 6px 16px;

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 15px;
              line-height: 20px;
              color: var(--secondary-600);
            }
          }
        }

        .galleryGridAlignment {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 4px;
          margin: 8px 0 0 0;

          @media only screen and (max-width: 767px) {
            padding: 0;
          }
        }

        @media only screen and (max-width: 1099px) {
          gap: 3px;
        }

        .galleryImgAlignment {
          // display: flex; /* Use flex to center align vertically */
          // align-items: center; /* Center align vertically */
          // justify-content: center; /* Center align horizontally */
          // height: 100px;
          // margin: 0; /* Set the desired height */
          width: 100%;
          height: 100%;
        }

        .galleryImgAlignment img {
          // max-width: 100%;
          // max-height: 100%;
          // width: auto;
          // height: auto;
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
        }

        .mobileViewMediaNoDataModalAlignment {
          height: calc(100vh - 205px);
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 100px;
          justify-content: center;
          display: none;

          @media only screen and (max-width: '1099px') {
            display: flex;
          }

          .mediaNoDataNameAlignment {
            span {
              font-family: 'Poppins', sans-serif;
              font-style: italic;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              color: var(--secondary-300);
              display: block;
            }
          }
        }
      }

      .followAlignment {
        // background: #ffffff;
        // border-radius: 16px;
        // padding: 22px 32px;
        border-radius: 16px;
        background: #f3f5f8;
        padding: 16px;

        @media only screen and (max-width: '1099px') {
          display: none;
        }

        .headingAlignment {
          display: flex;
          align-items: center;
          // justify-content: space-between;
          gap: 4px;

          h4 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: var(--secondary-900);
          }

          p {
            color: #7a7e88;
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
          }

          span {
            color: #7a7e88;
            font-size: 13px;
            font-weight: 500;
            line-height: 21px;
            display: block;
          }
        }

        .serachAlignment {
          margin: 22px 0 0 0;

          .searchInputAlignment {
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            position: relative;

            .iconAlignment {
              position: absolute;
              top: 11px;
              left: 11px;

              img {
                cursor: pointer;
              }
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              display: flex;
              align-items: center;
              padding: 8px 10px 8px 40px;
              background: transparent;
              border: none;
              height: 100%;
              width: 100%;
              color: #1e2432;

              &::placeholder {
                color: #acb1c0;
              }
            }
          }
        }
      }

      .followPeopleDetailsAlignment {
        padding: 20px 0 0 0;
        height: auto;
        max-height: 400px;
        overflow-y: auto;

        @media only screen and (max-width: '1099px') {
          display: none;
        }

        .followPeopleDetailsFlex {
          display: flex;
          justify-content: space-between;
          padding: 0 0 16px 0;

          &:last-child {
            padding: 0;
          }

          .followProfileAlignment {
            display: flex;
            align-items: center;
            gap: 16px;

            .profileImg {
              width: 40px;
              max-width: 40px;
              min-width: 40px;
              height: 40px;

              img {
                width: 100%;
                height: 100%;
                cursor: pointer;
                display: block;
                border-radius: 50%;
              }
            }

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.1px;
              color: var(--secondary-900);
            }
          }

          .followButtonAlignment {
            button {
              width: 113px;
              height: 32px;
              // background: var(--primary-500);
              border-radius: 8px;
              font-family: 'Poppins', sans-serif;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #556ee6;
              border: none;
              cursor: pointer;
              background: transparent;

              &.following {
                color: #7a7e88;
              }
            }
          }
        }
      }

      .specialOfferBoxAlignment {
        background: #ffffff;
        border-radius: 16px;
        padding: 6px 12px;
        margin: 8px 0 0 0;

        @media only screen and (max-width: '1099px') {
          display: none;
        }

        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #000000;
          padding: 0 0 0 11px;
        }

        .NoOffersbox {
          margin: 20px 0 0 -12px;
          width: calc(100% + 24px);
          height: 213px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 32px;
          border-radius: 16px;
          background-color: #eef1fd;

          p {
            color: #9c6ac5;
            text-align: center;
            font-family: 'Poppins' sans-serif;
            font-size: 12px;
            font-style: italic;
            font-weight: 400;
            line-height: normal;
            max-width: 272px;
          }
        }

        .specialOfferDetailsAlignment {
          padding: 9px 0 0 0;

          .specialOfferBg {
            width: 100%;
            height: 151px;
            position: relative;
            background: #1c1c1e;
            border-radius: 10px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 10px;
            }

            .moreOptionAlignment {
              background: rgba(0, 0, 0, 0.4);
              width: 23px;
              height: 23px;
              border-radius: 50%;
              cursor: pointer;
              position: absolute;
              top: 8px;
              right: 8px;
              cursor: pointer;

              img {
                padding: 5px;
              }
            }

            .cardOfferDetails {
              position: absolute;
              display: flex;
              flex-direction: column;
              top: 0;
              right: 0;
              left: 0;
              align-items: center;
              justify-content: center;
              height: 151px;

              h5 {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 24px;
                line-height: 36px;
                color: #ffffff;
              }

              h6 {
                font-family: 'PoetsenOne';
                font-weight: 400;
                font-size: 24px;
                line-height: 29px;
                color: #ffffff;

                span {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 26px;
                  line-height: 39px;
                  color: #ff7d23;
                  display: block;
                  text-align: center;
                }
              }
            }

            .expiredAddAlignment {
              display: flex;
              align-items: center;
              justify-content: space-between;
              position: absolute;
              bottom: 10px;
              left: 21px;
              right: 10px;

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 10px;
                line-height: 15px;
                color: #ffffff;
                max-width: 87px;
              }

              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
}

.mediaNoDataAlignment {
  border-radius: 16px;
  background: var(--primary-50);
  width: 100%;
  height: calc(100vh - 460px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  .mediaNoDataNameAlignment {
    span {
      color: var(--violet-300);
      font-size: 12px;
      font-style: italic;
      font-weight: 400;
      line-height: 18px;
    }
  }
}
