@import '~antd/dist/antd.css';
@import 'base/typhography';
@import 'bluejesticBootstrap/main';
@import 'file-upload';
@import '../scss/summary/shared/shared.scss';
@import '../scss/summary/overview.scss';

.card-input-field input,
.StripeElement {
  display: block;
  margin: 0 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
  border: 3px solid transparent;
}

.card-input-field input::placeholder {
  color: #aab7c4;
}

.card-input-field input:focus,
.StripeElement--focus {
  border: 3px solid #0572de83;
  border-radius: 5px;
  transition: 0.4s ease-in-out;
}

.mobile-view-padding-zero {
  @media only screen and (max-width: '1024px') {
    padding: 0 !important;
  }
}
