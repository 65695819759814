.bookMarkAddNewModalSection {
  .bookMarkModalWrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(30, 36, 50, 0.4);
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999999999;

    @media (max-width:767px) {
      justify-content: end;
      flex-direction: column;
    }


    .bookMarkModalBox {
      width: 345px;
      border-radius: 16px;
      background: #fff;
      height: 258px;
      padding: 18px 16px 32px 16px;
      
      @media (max-width:767px) {
        width: 100%;
        padding: 0 16px 32px 16px;
        height: auto;
      }

      .storeproductfilterclickdropalignmentmain {
        display: none;
        width: 100%;
        margin-bottom: 18px;

        @media (max-width:767px) {
          display: block;
        }

        .storeproductfilterclickdropalignment {
          border-bottom: 1px solid #eaecef;
          padding: 8px 0 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: sticky;
          top: 0;
          background: #fff;
          z-index: 999;

          p {
            width: 33px;
            height: 3px;
            background: #e4e4e8;
            border-radius: 1.5px;
          }
        }
      }

      .bookMarkHeadingAlignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
          color: var(--secondary-900);
          font-family: 'Poppins', sans-serif;
          font-size: 24px;
          font-weight: 600;
          line-height: 36px;
        }

        .bookMarkCloseIcon {
          svg {
            cursor: pointer;
          }

          @media (max-width:767px) {
            display: none;
          }
        }
      }

      .bookMarkBodyDetailsAlignment {
        padding: 32px 0 0 0;

        .bookMarkBodyInputAlignment {
          label {
            color: #495057;
            font-family: 'Poppins', sans-serif;
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
            display: block;
            padding: 0 0 4px 0;
          }

          .bookMarkBodyInput {
            input {
              width: 100%;
              height: 40px;
              padding: 8px 16px;
              font-family: 'Poppins', sans-serif;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              border-radius: 10px;
              border: 1px solid var(--secondary-50);
              background: var(--secondary-50);

              &::placeholder {
                color: var(--secondary-500);
              }
            }
          }

          .bookMarkButton {
            padding: 32px 0 0 0;

            button {
              width: 100%;
              padding: 8px 0;
              color: #fff;
              text-align: center;
              font-family: 'Poppins', sans-serif;
              font-size: 18px;
              font-weight: 500;
              line-height: 28px;
              border-radius: 10px;
              background: var(--primary-100);
              cursor: pointer;

              &.activeCreate {
                background: var(--primary-500);
              }
            }
          }
        }
      }
    }
  }
}