.customerOrderHistoryAlignment {
  margin: 16px 0 0 0;
  background: var(--white);
  border-radius: 8px;
  padding: 22px 0 0 0;
  box-shadow: 0px 5px 50px rgba(144, 143, 143, 0.1);

  .customerOrderHeading {
    padding: 0 32px;

    h5 {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: var(--secondary-900);
    }
  }

  .customerFilterAlignment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px 32px 0 32px;

    .customerFilterBoxAlignment {
      border: 1.5px solid var(--secondary-100);
      border-radius: 8px;
      padding: 6px 16px;
      display: flex;
      align-items: center;
      gap: 4px;
      cursor: pointer;

      span {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: var(--secondary-600);
      }
    }

    .customerFilterOrderHistoryTabAlignment {
      display: flex;
      align-items: center;
      gap: 8px;

      .customerFilterOrderHistoryTabBox {
        padding: 6px 16px;
        background: var(--light-grey);
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 16px;
        cursor: pointer;

        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          color: var(--secondary-500);
        }

        &:hover {
          background: var(--primary-50);
          border-radius: 8px;

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            color: var(--primary-700);
          }
        }

        &.orderHistoryTabActive {
          background: var(--primary-50);
          border-radius: 8px;

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            color: var(--primary-700);
          }
        }

        .downArrowAIcon {
          width: 18px;
          height: 18px;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
      }
    }
  }

  .customerFilterTableAlignment {
    padding: 24px 0 0 0;

    table {
      width: 100%;

      thead {
        border: 1px solid #eceef4;
        border-right: none;
        border-left: none;

        tr {
          th {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: var(--secondary-900);
            padding: 8px 0;
            text-align: center;
          }
        }
      }

      tbody {
        cursor: pointer;
        tr {
          &:hover {
            background: var(--primary-50);
            border-radius: 10px;
          }

          &:first-child {
            margin: 16px 0 0 0;
          }

          td {
            padding: 16px 0;
            text-align: center;

            &.idAlignment {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              color: var(--secondary-900);
            }

            .productDetailsAlignment {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 18px;

              .productImg {
                width: 80px;
                height: 80px;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 8px;
                  object-fit: cover;
                }
              }

              .productDetailsAllDetails {
                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 400;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--secondary-900);
                  max-width: 188px;
                  text-align: left;
                }

                .viewAllAlignment {
                  text-align: left;
                  padding: 16px 0 0 0;

                  a {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--primary-500);
                    cursor: pointer;
                  }
                }
              }
            }

            .deliveryStatus {
              background: var(--green-50);
              border-radius: 16px;
              padding: 3px 10px;
              width: fit-content;
              margin: auto;

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: #1d8603;
              }
            }
          }
        }
      }
    }
  }

  .customerFilterTableAlignment table thead :nth-child(1) {
    width: 15%;
  }

  .customerFilterTableAlignment table thead :nth-child(2) {
    width: 20%;
  }

  .customerFilterTableAlignment table thead :nth-child(3) {
    width: 40%;
  }

  .customerFilterTableAlignment table thead :nth-child(4) {
    width: 15%;
  }

  .customerFilterTableAlignment table thead :nth-child(5) {
    width: 10%;
  }
}
