.learn-from-artistsdetails-modal {
    padding: 16px;

    @media only screen and (max-width:"768px") {
        display: none;
        padding: 0;
    }

    .learn-from-artistsdetails-grid-alignment {
        display: grid;
        grid-template-columns: 1fr 432px;
        gap: 16px;

        @media only screen and (max-width:"768px") {
            grid-template-columns: 1fr;
        }

        .learn-from-artistsdetails-gridItem {
            .learn-from-artistsdetails-left-side-alignment {
                .learn-from-artistsdetails-left-side-main-img-alignment {
                    width: 100%;
                    height: 465px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 8px;
                    }
                }

                .learn-from-artistsdetails-more-video-section-alignment {
                    padding: 48px 0 0 0;

                    h6 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 30px;
                        color: var(--secondary-900);
                    }

                    .learn-from-artistsdetails-more-video-alignment {
                        padding: 16px 0;

                        .learn-from-artistsdetails-more-video-grid-alignment {
                            display: grid;
                            grid-template-columns: repeat(3, 1fr);
                            gap: 16px;

                            @media only screen and (max-width:"1099px") {
                                grid-template-columns: repeat(2, 1fr);
                            }

                            .learn-from-artistsdetails-more-video-gridIem-alignment {
                                .learn-from-artistsdetails-more-video-all-details-alignment {
                                    .learn-from-artistsdetails-more-video-img-alignment {
                                        width: 100%;
                                        height: 151px;
                                        position: relative;
                                        cursor: pointer;

                                        img {
                                            width: 100%;
                                            height: 100%;
                                            border-radius: 8px;
                                            object-fit: cover;
                                        }
                                    }

                                    .art-craft-learns-artists-all-child-details-alignment {
                                        padding: 8px;

                                        span {
                                            font-family: 'Poppins', sans-serif;
                                            font-weight: 400;
                                            font-size: 13px;
                                            line-height: 20px;
                                            color: #606060;
                                            cursor: pointer;
                                        }

                                        p {
                                            font-family: 'Poppins', sans-serif;
                                            font-weight: 500;
                                            font-size: 16px;
                                            line-height: 24px;
                                            color: var(--secondary-900);
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .learn-from-artistsdetails-second-part-alignment {
                width: 100%;
                border: 1px solid #E2E2EA;
                border-radius: 8px;
                margin: 0 0 18px 0;

                .learn-from-artistsdetails-modal-details-alignment {
                    background: #ffffff;
                    border-radius: 8px 8px 0 0;
                    padding: 16px;
                    border-right: none;
                    border-top: none;
                    border-bottom: none;

                    .learn-from-artistsdetails-flex-alignment {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;

                        .learn-from-artistsdetails-alignment {
                            display: flex;
                            align-items: center;
                            gap: 9px;

                            .learn-from-artistsdetails-img-alignment {
                                width: 56px;
                                max-width: 56px;
                                min-width: 56px;
                                height: 56px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 50%;
                                    object-fit: cover;
                                }
                            }

                            .socialprofile-name-details-alignment {
                                h5 {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 600;
                                    font-size: 16px;
                                    line-height: 24px;
                                    letter-spacing: 0.1px;
                                    color: var(--secondary-900);
                                }

                                .child-details-alignment {
                                    display: flex;
                                    align-items: center;
                                    gap: 6px;

                                    span {
                                        font-family: 'Poppins', sans-serif;
                                        font-weight: 400;
                                        font-size: 12px;
                                        line-height: 18px;
                                        color: #808191;
                                    }

                                    p {
                                        width: 4px;
                                        height: 4px;
                                        border-radius: 50%;
                                        background: #808191;
                                    }

                                    .view-alignment {
                                        display: flex;
                                        align-items: center;
                                        gap: 4px;

                                        span {
                                            font-family: 'Poppins', sans-serif;
                                            font-weight: 500;
                                            font-size: 12px;
                                            line-height: 18px;
                                            color: #808191;
                                        }
                                    }
                                }
                            }
                        }

                        .more-menu-align {
                            display: flex;
                            gap: 28px;

                            .social-option-alignment {
                                display: flex;
                                align-items: center;
                                gap: 28px;

                                .close-icon-contain {
                                    width: 24px;
                                    height: 24px;
                                    min-width: 24px;
                                    max-width: 24px;
                                    img {
                                      cursor: pointer;
                                    }
                                  }
                            }
                        }
                    }

                    .learn-from-artistsdetails-notes-alignment {
                        padding: 8px 0 0 0;

                        p {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 21px;
                            color: var(--secondary-900);
                        }
                    }
                }

                .social-follower-like-other-details-alignment {
                    display: grid;
                    grid-template-columns: 169px 169px 1fr;
                    border-bottom: 1px solid #e2e2ea;
                    height: 53px;
                    background: #f8f8f8;

                    .message-alignment {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 10px;
                        background: #f8f8f8;
                        border-right: 1px solid #e2e2ea;
                        border-left: 1px solid #e2e2ea;

                        span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 21px;
                            color: #495057;
                        }
                    }

                    .share-alignment {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 10px;
                        border-right: 1px solid #e2e2ea;

                        span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 21px;
                            color: #495057;
                        }
                    }

                    .like-alignment {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 4px;

                        .like-details-flex-alignemnt {
                            display: flex;
                            align-items: center;

                            .users_likes {
                                display: flex;
                                align-items: center;
                                margin-left: -10px;

                                .user_box {
                                    width: 24px;
                                    display: flex;
                                    align-items: center;
                                    height: 24px;
                                    justify-content: center;
                                    background: #fff;
                                    object-position: center;
                                    border: 1px solid #ffffff;
                                    border-radius: 30px;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover !important;
                                    }
                                }
                            }

                            .count {
                                margin-left: -10px;
                                background: var(--primary-500);
                                border: 1px solid #ffffff;
                                border-radius: 12px;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 18px;
                                letter-spacing: 0.1px;
                                color: #ffffff;
                                padding: 4px 9px 2px 9px;
                            }
                        }
                    }
                }

                .social-chat-details-section {
                    background: #f8f8f8;
                    // height: calc(100vh - 330px);
                    // overflow-y: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    border-radius: 0;
                    // border-left: 1px solid #e4e4e8;
                    // border-bottom: 1px solid #e4e4e8;

                    .social-chat-details-alignment {
                        margin: 16px;
                        border-radius: 0 0 0 0;
                        height: calc(100vh - 362px);
                        overflow-y: auto;
                    }

                    .social-send-chat-alignment {
                        background: #ffffff;
                        border-radius: 0px 0px 16px 16px;
                        padding: 16px;
                        position: sticky;
                        bottom: 0;

                        .send-box-details-flex {
                            display: flex;
                            align-items: center;
                            gap: 20px;

                            .send-box-img-alignment {
                                width: 32px;
                                max-width: 32px;
                                min-width: 32px;
                                height: 32px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 50%;
                                    object-fit: cover;
                                }
                            }

                            .send-box-input-alignment {
                                width: 100%;
                                height: 46px;
                                position: relative;

                                input {
                                    width: 100%;
                                    height: 100%;
                                    background: #fafafb;
                                    border: 1px solid #f1f1f5;
                                    border-radius: 10px;
                                    padding: 11px 90px 16px 16px;
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 21px;
                                    color: #92929d;
                                }

                                .input-icon-alignment {
                                    display: flex;
                                    align-items: center;
                                    gap: 16px;
                                    position: absolute;
                                    top: 11px;
                                    right: 16px;

                                    img {
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .learn-from-product-recommendation-alignment {
                background: #F8F8F8;
                border-radius: 8px;
                padding: 16px;

                .learn-from-product-recommendation-heading {
                    h5 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 30px;
                        color: var(--secondary-900);

                    }
                }

                .learn-from-product-hignt-bg-alignment {

                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        background: linear-gradient(180deg, rgba(255, 243, 234, 0) 0%, #FFF3EA 100%);
                        width: 100%;
                        height: 110px;
                        bottom: 0;
                        right: 0;
                    }

                    .learn-from-product-hight-alignment {
                        height: auto;
                        max-height: 280px;
                        overflow-y: auto;

                        .learn-from-product-all-details-flex-alignment {
                            display: flex;
                            align-items: flex-start;
                            justify-content: space-between;
                            margin: 16px 0 0 0;
                            cursor: pointer;

                            &:hover {
                                transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

                                transform: translateY(-6px);
                            }

                            .learn-from-product-product-details-alignment {
                                display: flex;
                                align-items: center;
                                gap: 16px;

                                .learn-from-product-product-img-alignment {
                                    width: 72px;
                                    height: 72px;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        border-radius: 8px;
                                        object-fit: cover;
                                    }
                                }

                                .learn-from-product-product-child-details-alignment {
                                    h6 {
                                        font-family: 'Poppins', sans-serif;
                                        font-weight: 400;
                                        font-size: 16px;
                                        line-height: 24px;
                                        color: var(--secondary-900);
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        max-width: 219px;
                                    }

                                    .learn-from-product-rate-alignment {
                                        display: flex;
                                        align-items: center;
                                        gap: 2px;

                                        span {
                                            font-family: 'Poppins', sans-serif;
                                            font-weight: 500;
                                            font-size: 13px;
                                            line-height: 20px;
                                            color: #808191;
                                        }

                                        .learn-from-product-starIcon-alignment {
                                            display: flex;
                                            align-items: center;
                                            gap: 1.5px;
                                        }

                                    }

                                    .diy-rrom-price-alignment {
                                        padding: 2px 0 0 0;

                                        p {
                                            font-family: 'Poppins', sans-serif;
                                            font-weight: 500;
                                            font-size: 14px;
                                            line-height: 21px;
                                            color: var(--secondary-900);
                                        }
                                    }
                                }
                            }

                            .learn-from-product-other-option-alignment {
                                display: flex;
                                align-items: center;
                                gap: 16px;

                                img {
                                    cursor: pointer;
                                    object-fit: cover;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.mobile-from-artistasdetails-modal {
    display: none;

    @media only screen and (max-width:"768px") {
        display: block;
    }

    .mobile-view-art-craft-back-alignment {
        display: none;

        @media only screen and (max-width:"768px") {
            display: flex;
            align-items: center;
            gap: 18px;
            padding: 8px 16px;
            background: rgba(248, 248, 248, 0.92);
            backdrop-filter: blur(13.5914px);
            justify-content: flex-start;
            position: sticky;
            top: 0;
            z-index: 999;

            .art-craft-back_details-alignment {
                background: #ffffff;
                border-radius: 10px;
                padding: 4px 9px 4px 10px;
                display: flex;
                align-items: center;
                gap: 8px;

                p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #808191;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 58px;
                }
            }

            .mobile-heading-alignment {
                h4 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 27px;
                    text-align: center;
                    color: var(--secondary-900);
                }
            }
        }
    }

    .mobile-learn-from-artistasdetails-body {
        .mobile-learn-from-artistsDetails-img-alignment {
            width: 100%;
            height: 210px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
            }
        }

        .mobile-view-video-player-option-alignment {
            padding: 16px 13px 22px 13px;
            background: #FFFFFF;
            border-radius: 0px 0px 10px 10px;

            .mobile-video-player-range-time-alignment {
                display: flex;
                align-items: center;
                gap: 8px;

                span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: var(--secondary-900);
                }
            }

            .mobile-video-player-video-other-option-alignment {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 22px 0 0 0;

                .mobile-video-center-option-details-alignment {
                    display: flex;
                    align-items: center;
                    gap: 32px;
                }
            }
        }
    }
}