.mobile-view-diy-room-modal {
    padding: 0 16px;

    .mobile-view-diy-room-modal-alignment {
        .mobile-view-diy-slider-alignment {
            .mobile-view-diy-main-alignment {
                padding: 0 4px;

                .mobile-view-main-img-alignment {
                    // width: 282px;
                    width: 100%;
                    height: 211px;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 8px;
                        object-fit: cover;
                    }
                }
            }
        }

        .mobile-view-diy-product-details-alignment {
            padding: 16px 0 0 0;

            h4 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                color: var(--secondary-900);
                cursor: pointer;

                @media only screen and (max-width:"768px") {
                    font-size: 16px;
                    line-height: 24px;
                }

            }

            .art-craft-auther-details-alignment {
                display: flex;
                align-items: center;
                gap: 8px;
                padding: 5px 0 0 0;

                span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: #808191;
                }

                .art-craft-author-profile-details-alignment {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    .author-profile-img-alignment {
                        width: 20px;
                        height: 20px;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                            object-fit: cover;
                            display: block;
                        }
                    }

                    h6 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 20px;
                        color: var(--secondary-900);
                        cursor: pointer;
                    }
                }
            }
        }

        .mobile-view-diy-total-items-alignment {
            padding: 16px 0 0 0;

            span {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: #495057;
            }
        }

        .mobile-view-diy-all-child-details-alignment {
            padding: 20px 0;

            .mobile-view-diy-all-child-details-main-flex-alignment {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 12px;
                margin: 0 0 32px 0;

                &:last-child {
                    margin: 0;
                }

                .mobile-view-diy-left-part-alignment {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    .mobile-view-diy-all-child-img-alignment {
                        width: 74px;
                        min-width: 74px;
                        max-width: 74px;
                        height: 74px;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 8px;
                            object-fit: cover;
                        }
                    }

                    .mobile-view-diy-all-product-details-alignment {
                        h6 {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            color: var(--secondary-900);
                            max-width: 196px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .mobile-rate-details-alignment {
                            display: flex;
                            align-items: center;
                            gap: 2px;

                            span {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 21px;
                                color: #808191;
                            }

                            .mobile-rate-star-icon-flex-alignment {
                                display: flex;
                                align-items: center;
                                gap: 2px;
                            }

                        }

                        .mobile-price-alignment {
                            p {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 24px;
                                color: var(--secondary-900);
                            }
                        }
                    }

                }

                .mobile-view-diy-right-part-alignment {
                    display: flex;
                    flex-direction: column;
                    gap: 18px;
                }
            }
        }
    }
}