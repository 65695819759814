.PostheaderMobileoverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  background: #1e243236;
  opacity: 0;
  visibility: hidden;
  display: none;

  &.open {
    display: block;
    opacity: 1;
    visibility: visible;
  }
}

.PostheaderMobilemain {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99999999;
  width: 100%;
  background: white;
  translate: 0 100%;
  transition: 0.3s;
  border-radius: 16px 16px 0 0;
  overflow-y: auto;
  height: auto;
  max-height: 90vh;

  &.open {
    translate: 0;
    transform: translate(0, 0) !important;
  }

  .storeproductfilterclickdropalignment {
    border-bottom: 1px solid #eaecef;
    padding: 8px 0 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 2;

    .reportPostModalheader {
      h1 {
        font-family: 'Poppins' sans-serif;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #495057;
      }
    }

    p {
      width: 33px;
      height: 3px;
      background: #e4e4e8;
      border-radius: 1.5px;
    }
  }

  .mobileviewdetailsalignment {
    height: auto;
    padding: 32px 0;

    .dropdown-option {
      padding: 0 !important;
    }

    .mobileviewdetailsgridalignment,
    .dropdown-main {
      display: grid;
      grid-template-columns: 24px 1fr;
      gap: 11px;
      align-items: center;
      cursor: pointer;
      transition: 0.4s ease-in-out;
      padding: 0 0 32px 0;

      .mobileviewdetailsiconalignment,
      .dropdown-icon {
        width: 100%;
        height: 24px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .mobileviewchilddetailsalignment,
      .dropdown-content {
        h5 {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #495057;
        }

        p {
          font-family: 'Poppins' sans-serif;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #808191;
        }
      }
    }
  }
}

