.createClubModalSection {
    border-radius: 16px 0px 0px 16px;
    background: #FFF;
    width: 100%;
    height: 100%;

    .createClubModalTopDetails {
        padding: 32px;
        border-bottom: 1px solid var(--secondary-50);

        @media only screen and (max-width:"1024px") {
            padding: 16px;
            position: sticky;
            top: 0;
            background: #FFF;
            z-index: 999;
        }

        .createClubModalHeading {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media only screen and (max-width:"767px") {
                display: none;
            }

            h4 {
                color: var(--secondary-900);
                font-family: 'Poppins', sans-serif;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            .createClubButton {
                button {
                    padding: 6px 16px;
                    border-radius: 8px;
                    background: var(--primary-500);
                    color: #FFF;
                    font-family: 'Poppins', sans-serif;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    cursor: pointer;
                }
            }
        }

        .mobileViewCreateClubModalHeading {
            display: none;

            @media only screen and (max-width:"767px") {
                display: flex;
                align-items: center;
                gap: 80px;

                h4 {
                    color: var(--secondary-900);
                    text-align: center;
                    font-family: 'Poppins', sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }

        }


        .createClubModalStepper {
            padding: 32px 0 0 0;

            .createClubModalStepperFlex {
                display: flex;
                align-items: center;
                gap: 30px;

                @media only screen and (max-width:"767px") {
                    gap: 50px;
                }

                .createClubModalStepBox {
                    border-radius: 8px;
                    padding: 10px 16px;
                    cursor: pointer;
                    transition: 0.4s ease-in-out;
                    position: relative;

                    @media only screen and (max-width:"767px") {
                        padding: 10px 12px;
                    }

                    &:last-child {
                        &::before {
                            display: none;
                        }
                    }

                    &::before {
                        position: absolute;
                        content: '';
                        width: 23px;
                        height: 2px;
                        border-radius: 1px;
                        background: var(--primary-700);
                        right: -26px;
                        top: 50%;
                        transform: translateY(-50%);
                        z-index: 99;

                        @media only screen and (max-width:"767px") {
                            width: 40px;
                            right: -45px;
                        }

                    }

                    &.activeStep {
                        background: var(--primary-50);
                        transition: 0.4s ease-in-out;

                        span {
                            color: var(--primary-700);
                        }

                        h5 {
                            color: var(--primary-700);
                        }

                    }

                    &:hover {

                        transition: 0.4s ease-in-out;

                        span {
                            color: var(--secondary-900);
                        }

                        h5 {
                            color: var(--secondary-900);
                        }
                    }

                    span {
                        color: var(--secondary-500);
                        font-family: 'Poppins', sans-serif;
                        font-size: 11px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }

                    h5 {
                        color: var(--secondary-500);
                        font-family: 'Poppins', sans-serif;
                        font-size: 13px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        padding: 8px 0 0 0;

                        @media only screen and (max-width:"767px") {
                            padding: 8px 0 0 0;
                        }

                        span {
                            color: var(--secondary-500);
                            font-family: 'Poppins', sans-serif;
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
    }

    .createMobileViewButton {
        display: none;

        @media only screen and (max-width:"767px") {
            display: block;
            position: fixed;
            bottom: 0;
            padding: 16px;
            border-top: 1px solid #F1F1F5;
            width: 100%;
            background: #FFF;
            z-index: 999;

            button {
                border-radius: 10px;
                background: var(--primary-500);
                width: 100%;
                height: 44px;
                color: var(--light-grey);
                text-align: center;
                font-family: 'Poppins', sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }
    }
}