.idea-for-you-section {

    .container {
        max-width: 1440px;
        margin: auto;
        padding: 0;

        .idea-for-you-alignment {
            padding: 48px 56px;

            @media only screen and (max-width:"767px") {
                padding: 40px 0;
            }

            .idea-for-you-heading-alignment {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 40px;

                @media only screen and (max-width:"767px") {
                    padding: 0 16px;
                }

                h4 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 36px;
                    line-height: 54px;
                    color: var(--secondary-900);

                    @media only screen and (max-width:"767px") {
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 30px;
                    }
                }

                .idea-for-you-see-more-alignment {
                    a {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 27px;
                        color: var(--primary-500);
                        cursor: pointer;

                        @media only screen and (max-width:"767px") {
                            font-size: 14px;
                            line-height: 21px;

                            img {
                                display: none;
                            }
                        }
                        &:hover {
                            gap: 10px;
                            text-decoration: underline;
                        }
                    }
                }
            }

            .idea-for-you-details-alignment {
                padding: 56px 0 0 0;

                @media only screen and (max-width:"767px") {
                    padding: 16px 0 0 16px;
                }

                .idea-for-you-details-box-alignment {
                    padding: 8px;
                    background: #2C302C;
                    border-radius: 16px;

                    @media only screen and (max-width:"767px") {
                        display: none;
                    }

                    .idea-for-you-grid-alignment {
                        display: grid;
                        grid-template-columns: 1fr 376px;
                        gap: 109px;

                        @media only screen and (max-width:"1099px") {
                            grid-template-columns: 1fr;
                            gap: 45px;
                        }

                        @media only screen and (max-width:"899px") {
                            grid-template-columns: 1fr;
                            gap: 45px;
                        }

                        .idea-for-you-gridItem-alignment {
                            .idea-for-you-img-alignment {
                                width: 100%;
                                height: 500px;
                                position: relative;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 8px;
                                }

                                .idea-details-alignment {
                                    position: absolute;
                                    top: 14px;
                                    left: 13px;

                                    .idea-user-name-alignment {
                                        background: rgba(255, 255, 255, 0.6);
                                        border-radius: 23px;
                                        padding: 4px 18px 4px 4px;
                                        display: flex;
                                        align-items: center;
                                        gap: 8px;

                                        .idea-user-img-alignment {
                                            width: 38px;
                                            height: 38px;

                                            img {
                                                width: 100%;
                                                height: 100%;
                                                border-radius: 50%;
                                            }
                                        }

                                        p {
                                            font-family: 'Poppins', sans-serif;
                                            font-weight: 500;
                                            font-size: 16px;
                                            line-height: 24px;
                                            color: #000000;
                                        }
                                    }

                                }

                                .plus-box-alignment {
                                    width: 28px;
                                    height: 28px;
                                    border-radius: 50%;
                                    background: rgba(255, 255, 255, 0.4);
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    cursor: pointer;
                                    transition: 0.1s ease-in-out;
                                    &:hover {
                                        width: 40px;
                                        height: 40px;
                                    }

                                    &.minus {
                                        width: 38px;
                                        height: 38px;

                                        .white-box-alignment {
                                            width: 20px;
                                            height: 20px;
                                        }

                                        &:hover {
                                            width: 40px;
                                            height: 40px;
                                        }
                                    }

                                    .white-box-alignment {
                                        width: 22px;
                                        height: 22px;
                                        border-radius: 50%;
                                        background: #FFFFFF;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;

                                        img {
                                            width: 16px;
                                            height: 16px;
                                            display: block;
                                        }
                                    }
                                }

                                .first-plus-details-alignment {
                                    position: absolute;
                                    top: 38px;
                                    right: 220px;
                                }

                                .second-plus-details-alignment {
                                    position: absolute;
                                    bottom: 47px;
                                    left: 64px;
                                }

                                .third-plus-details-alignment {
                                    position: absolute;
                                    right: 272px;
                                    bottom: 100px;
                                }

                                .four-plus-details-alignment {
                                    position: absolute;
                                    bottom: 193px;
                                    left: 146px;
                                }

                                .product-child-details-bootmark-alignment {
                                    display: flex;
                                    align-items: flex-start;
                                    gap: 8px;
                                    margin: 0 0 5px -20px;

                                    .product-details-alignment {
                                        background: #FFFFFF;
                                        border-radius: 16px;
                                        padding: 16px 22px 19px 16px;

                                        .product-personal-details-alignment {
                                            display: flex;
                                            align-items: center;
                                            gap: 16px;

                                            .product-personal-img-alignment {
                                                width: 67px;
                                                height: 67px;

                                                img {
                                                    width: 100%;
                                                    height: 100%;
                                                    border-radius: 8px;
                                                }
                                            }

                                            .product-child-details-alignment {
                                                h6 {
                                                    font-family: 'Poppins', sans-serif;
                                                    font-weight: 600;
                                                    font-size: 16px;
                                                    line-height: 24px;
                                                    color: var(--secondary-900);
                                                }

                                                .product-review-alignemnt {
                                                    display: flex;
                                                    align-items: center;
                                                    gap: 6px;
                                                    padding: 1px 0 0 0;

                                                    p {
                                                        font-family: 'Poppins', sans-serif;
                                                        font-weight: 500;
                                                        font-size: 13px;
                                                        line-height: 20px;
                                                        color: #808191;
                                                    }

                                                    .review-star-alignment {
                                                        display: flex;
                                                        align-items: center;
                                                        gap: 2px;
                                                    }
                                                }

                                                h5 {
                                                    font-family: 'Poppins', sans-serif;
                                                    font-weight: 600;
                                                    font-size: 16px;
                                                    line-height: 24px;
                                                    color: var(--secondary-900);
                                                    padding: 7px 0 0 0;
                                                }
                                            }

                                        }

                                        .product-persoanl-notes-alignment {
                                            padding: 11px 0 0 0;

                                            p {
                                                max-width: 280px;
                                                font-family: 'Poppins', sans-serif;
                                                font-weight: 400;
                                                font-size: 13px;
                                                line-height: 20px;
                                                color: #495057;
                                            }
                                        }
                                    }

                                    .bootmark-cart-alignment {
                                        margin: 15px 0 0 0;

                                        .option-box-alignment {
                                            width: 32px;
                                            height: 32px;
                                            background: #FFFFFF;
                                            border-radius: 8px;
                                            padding: 5px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            cursor: pointer;
                                            margin: 0 0 8px 0;

                                            &:last-child {
                                                margin: 0;
                                            }
                                        }
                                    }
                                }
                            }

                            .idea-for-you-child-details-alignment {
                                max-width: 278px;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-evenly;
                                height: 100%;

                                @media only screen and (max-width:"1099px") {
                                    max-width: 100%;
                                }

                                @media only screen and (max-width:"899px") {
                                    max-width: 100%;
                                }

                                h5 {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 600;
                                    font-size: 24px;
                                    line-height: 36px;
                                    color: #FFFFFF;

                                    @media only screen and (max-width:"1099px") {
                                        padding: 0 0 20px 0;
                                    }

                                    @media only screen and (max-width:"899px") {
                                        padding: 0 0 20px 0;
                                    }
                                }

                                .see-comment-alignment {
                                    transition: ease-in-out;

                                    a {
                                        display: flex;
                                        align-items: center;
                                        gap: 8px;
                                        font-family: 'Poppins', sans-serif;
                                        font-weight: 500;
                                        font-size: 18px;
                                        line-height: 27px;
                                        color: rgba(255, 255, 255, 0.8);

                                        &:hover {
                                            gap: 10px;
                                            text-decoration: underline;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .mobile-view-idea-for-you-details-alignment {
                    display: none;

                    @media only screen and (max-width:"767px") {
                        display: block;

                        .mobile-view-for-you-slider {
                            padding: 0 4px;

                            .mobile-view-idea-for-you-details-box-alignment {
                                padding: 8px;
                                background: #2C302C;
                                border-radius: 10px;

                                .mobile-view-idea-for-you-img-alignment {
                                    width: 100%;
                                    height: 216px;
                                    position: relative;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                        display: block;
                                    }

                                    .mobile-view-data-tag-alignment {
                                        background: rgba(30, 36, 50, 0.69);
                                        border-radius: 8px;
                                        padding: 4px 10px;
                                        display: flex;
                                        align-items: center;
                                        gap: 8px;
                                        width: fit-content;
                                        position: absolute;
                                        bottom: 8px;
                                        left: 8px;

                                        img {
                                            max-width: 100%;
                                            height: 100%;
                                        }

                                        p {
                                            font-family: 'Poppins', sans-serif;
                                            font-weight: 400;
                                            font-size: 16px;
                                            line-height: 24px;
                                            color: #FFFFFF;
                                        }
                                    }
                                }

                                .mobile-view-idea-for-you-all-details-alignment {
                                    padding: 20px 48px 16px 8px;

                                    p {
                                        font-family: 'Poppins', sans-serif;
                                        font-weight: 600;
                                        font-size: 16px;
                                        line-height: 24px;
                                        color: #FFFFFF;
                                        padding: 0 0 8px 0;
                                    }

                                    .mobile-view-see-comment-alignment {
                                        transition: ease-in-out;

                                        a {
                                            display: flex;
                                            align-items: center;
                                            gap: 16px;
                                            font-family: 'Poppins', sans-serif;
                                            font-weight: 500;
                                            font-size: 14px;
                                            line-height: 21px;
                                            color: rgba(255, 255, 255, 0.6);

                                            &:hover {
                                                gap: 18px;
                                                text-decoration: underline;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                    }

                }

            }

            .idea-for-you-child-img-alignment {
                padding: 16px 32px;

                @media only screen and (max-width:"767px") {
                    display: none;
                }

                .idea-for-you-child-grid-alignment {
                    display: grid;
                    grid-template-columns: repeat(5, 1fr);
                    gap: 16px;

                    .idea-for-you-child-gridItem-alignment {
                        width: 100%;
                        height: 148px;
                        transition: 0.4s ease-in-out;
                        cursor: pointer;

                        &:hover {
                            transform: scale(0.9);
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 8px;
                        }
                    }
                }
            }
        }
    }
}