.discover-section {
  background: #ffffff;
  margin: 0 0 16px 0;

  .container {
    max-width: 1440px;
    margin: auto;
    padding: 0;

    .discover-alignment {
      padding: 35px 32px 0 32px;
      width: 100%;
      overflow-x: hidden;

      @media only screen and (max-width: '767px') {
        padding: 16px;
      }

      .discover-grid-alignment {
        position: relative;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 8px;
        height: 602px;
        overflow-y: hidden;
        border-radius: 16px;

        // transition: 0.4s ease-in-out;
        @media only screen and (max-width: '767px') {
          height: 516px;
        }

        .discover-gridItem-alignment {
          &.bottomToTop {
            position: relative;
            bottom: -250px;
            transform: translateY(-500px);
            animation: MoveDownToUp 25s linear infinite;
          }

          &.topToBottom {
            position: relative;
            bottom: 250px;
            // transform: translateY(-250px);
            animation: MoveUpTODown 25s linear infinite;
          }

          .first-col-discover-product-img-alignment {
            margin: 0 0 8px 0;
            position: relative;

            img {
              width: 211px;
              min-width: 211px;
              max-width: 211px;
              border-radius: 10px;

              @media only screen and (max-width: '767px') {
                width: 140px;
                min-width: 140px;
                max-width: 140px;
              }
            }

            &:first-child {
              img {
                border-radius: 10px 0 10px 0;
              }
            }

            &:nth-child(2) {
              img {
                height: 316px;
                border-radius: 0 10px 0 10px;

                @media only screen and (max-width: '767px') {
                  height: 208px;
                }
              }
            }

            &:last-child {
              img {
                border-radius: 0 10px 0 10px;
              }
            }
          }

          .second-col-discover-product-img-alignment {
            margin: 0 0 8px 0;
            width: 211px;
            min-width: 211px;
            max-width: 211px;

            @media only screen and (max-width: '767px') {
              width: 140px;
              min-width: 140px;
              max-width: 140px;
            }

            img {
              width: 100%;
              height: 100%;
              border-radius: 10px;
            }

            &:first-child {
              img {
                border-radius: 0 0 10px 10px;
              }
            }

            &:last-child {
              img {
                border-radius: 10px 10px 0 0;
              }
            }

            .text-box-alignment {
              height: 316px;
              display: flex;
              align-items: center;
              max-width: 172px;
              margin: auto;

              @media only screen and (max-width: '767px') {
                height: 208px;
              }

              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 28px;
                line-height: 42px;
                color: var(--secondary-900);

                @media only screen and (max-width: '767px') {
                  font-size: 18px;
                  line-height: 27px;
                  max-width: 107px;
                  margin: auto;
                }
              }
            }
          }

          .third-col-discover-product-img-alignment {
            margin: 0 0 8px 0;
            width: 211px;
            min-width: 211px;
            max-width: 211px;

            @media only screen and (max-width: '767px') {
              width: 140px;
              min-width: 140px;
              max-width: 140px;
            }

            img {
              width: 100%;
              height: 100%;
              border-radius: 10px;
            }

            &:first-child {
              img {
                border-radius: 0 0 10px 10px;
              }
            }

            &:last-child {
              img {
                border-radius: 10px 10px 0 0;
              }
            }

            .text-box-alignment {
              height: 316px;
              display: flex;
              align-items: center;
              max-width: 172px;
              margin: auto;

              @media only screen and (max-width: '767px') {
                height: 208px;
              }

              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 28px;
                line-height: 42px;
                color: var(--secondary-900);
              }
            }
          }

          .forth-col-discover-product-img-alignment {
            margin: 0 0 8px 0;
            width: 211px;
            min-width: 211px;
            max-width: 211px;

            @media only screen and (max-width: '767px') {
              width: 140px;
              min-width: 140px;
              max-width: 140px;
            }

            img {
              width: 100%;
              height: 100%;
              border-radius: 10px;
            }

            &:first-child {
              img {
                border-radius: 0 0 10px 10px;
              }
            }

            &:last-child {
              img {
                border-radius: 10px 10px 0 0;
              }
            }
          }
        }

        @media (min-width: 768px) {
          &:hover {
            &::before {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              width: 100%;
              height: 100%;
              background: linear-gradient(270.23deg, #1e2432 0.19%, rgba(30, 36, 50, 0) 99.8%);
              border-radius: 16px;
              z-index: 99;
            }

            .explore-more-section-alignment {
              position: absolute;
              top: 0;
              right: 151px;
              height: 100%;
              display: flex;
              align-items: flex-start;
              flex-direction: column;
              justify-content: center;
              gap: 50px;
              z-index: 999;

              .explore-heading-alignment {
                h4 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 36px;
                  line-height: 54px;
                  color: #ffffff;
                  max-width: 182px;
                }
              }

              .expolre-more-alignment {
                transition: 0.4s ease-in-out;

                a {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                  line-height: 27px;
                  color: rgba(255, 255, 255, 0.8);

                  &:hover {
                    gap: 10px;
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }
      }
    }

    .mobile-view-heading-alignment {
      display: none;

      @media only screen and (max-width: '767px') {
        display: block;
        padding: 0 16px;

        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: var(--secondary-900);
        }
      }
    }
  }
}

// @-webkit-keyframes Up-downScroll {
//     0% {
//         transform: translateY(0);
//     }

//     50% {
//         transform: translateY(20px);
//     }

//     100% {
//         transform: translateY(0);
//     }
// }

@keyframes MoveDownToUp {

  0%,
  100% {
    bottom: 0;
  }

  50% {
    bottom: -500px;
  }
}

@keyframes MoveUpTODown {

  0%,
  100% {
    bottom: 0;
  }

  50% {
    bottom: 200px;
  }
}