.browseByTypeBodyGridBox {
  border-radius: 16px;
  background: var(--primary-50);
  padding: 22px 22px 24px 22px;
  @media only screen and (max-width: '546px') {
    padding: 8px;
    max-width: 108px;
    min-width: 108px;
    width: 108px;
  }
  .childGridAlignment {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    @media only screen and (max-width: '1024px') {
      gap: 4px;
    }
    .imgAlignment {
      width: 100%;
      height: 128px;
      background: var(--white);
      border-radius: 8px;
      cursor: pointer;
      overflow: hidden;
      @media only screen and (max-width: '1024px') {
        height: 44px;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        object-fit: cover;
        transform: scale(1);
        transition: 0.4s ease-in-out;
      }
      &:hover {
        img {
          transform: scale(1.2);
          transition: 0.4s ease-in-out;
        }
      }
    }
  }

  .browseByTypeProfile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0 0 0;
    @media only screen and (max-width: '1024px') {
      display: block;
      padding: 4px 0 0 0;
    }
    .browseByTypeProfileLeft {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      .browseByTypeProfileImg {
        width: 32px;
        height: 32px;
        min-width: 32px;
        max-width: 32px;
        border-radius: 4px;
        background: var(--white);
        overflow: hidden;
        @media only screen and (max-width: '1024px') {
          display: none;
        }
        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          border-radius: 4px;
          //   transform: scale(1);
          //   transition: 0.4s ease-in-out;
        }
      }
      h6 {
        color: var(--secondary-900);
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;

        @media only screen and (max-width: '1024px') {
          font-size: 12px;
          line-height: 18px;
          font-weight: 400;
        }
      }

      //   &:hover {
      //     .browseByTypeProfileImg {
      //       img {
      //         transform: scale(1.2);
      //         transition: 0.4s ease-in-out;
      //       }
      //     }
      //   }
    }

    .browseByTypeProfileRight {
      span {
        color: var(--secondary-500);
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        @media only screen and (max-width: '1024px') {
          white-space: nowrap;
        }
      }
    }
  }
}
