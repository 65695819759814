.mobileViewBookMarkDetailsFlex {
  width: 100%;
  overflow-x: auto;
  display: flex;
  align-items: flex-start;
  gap: 9px;

  .mobileAddNewDetails {
    .mobileAddnewBoxAlignment {
      border-radius: 8px;
      border: 1px dashed var(--primary-500);
      background: var(--primary-50);
      width: 54px;
      height: 54px;
      min-width: 54px;
      max-width: 54px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      padding: 8px 0 0 0;
      color: var(--primary-500);
      text-align: center;
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;
    }
  }

  .BookmarkSlider {
    width: 100%;
    overflow: hidden;

    :global(.slick-disabled) {
      display: none;
    }

    :global(.slick-prev) {
      left: 0 !important;
      top: 30px !important;
      z-index: 99 !important;

      svg {
        transform: rotate(180deg) !important;
      }
    }

    :global(.slick-next) {
      right: 0 !important;
      top: 30px !important;
      z-index: 999 !important;

      svg {
        transform: rotate(0deg) !important;
      }
    }

    :global(.slick-arrow) {
      width: auto !important;
      height: auto !important;
    }

    :global(.slick-arrow:hover) {
      background: #ffffff00 !important;

      svg {
        path {
          stroke: #1e2432 !important;
        }
      }
    }

    .mobileViewAddedImges {
      padding: 0 5px;
      .addedImgAlignment {
        width: 56px;
        height: 56px;
        min-width: 56px;
        max-width: 56px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          border-radius: 8px;
          cursor: pointer;
        }

        .bookedRightSign {
          width: 100%;
          height: 100%;
          border-radius: 8px;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%);
          position: absolute;
          top: 0;
          left: 0;
          z-index: 9;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      p {
        padding: 8px 0 0 0;
        color: var(--secondary-900);
        text-align: center;
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
        max-width: 56px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }

  .BookmarkSlider {
    width: 100%;

    .mobileViewAddedImges {
      .addedImgAlignment {
        width: 56px;
        height: 56px;
        min-width: 56px;
        max-width: 56px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          border-radius: 8px;
          cursor: pointer;
        }

        .bookedRightSign {
          width: 100%;
          height: 100%;
          border-radius: 8px;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%);
          position: absolute;
          top: 0;
          left: 0;
          z-index: 9;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      p {
        padding: 8px 0 0 0;
        color: var(--secondary-900);
        text-align: center;
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
        max-width: 56px;
      }
    }
  }
}
