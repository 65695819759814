.addProductModalAttributesSection {
  margin: 0 0 16px 0;

  .addProductModalAttributesBox {
    border-radius: 10px;
    border: 1px solid var(--secondary-50);
    background: #fff;
    padding: 10px 16px;

    .attributesaddProductHeadingAlignment {
      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: var(--secondary-900);
      }

      .attributesaddProductNotesAlignment {
        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: #808191;
        }
      }
    }

    .noAttributesAlignment {
      padding: 35px 0;
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #808191;
      text-align: center;

      @media only screen and (max-width: '767px') {
        display: none;
      }
    }

    .addAttributesButtonAlignment {
      @media only screen and (max-width: '767px') {
        padding: 16px 0 0 0;
      }

      button {
        // background: #eceef4;
        // border: 1px solid #eceef4;
        // border-radius: 10px;
        padding: 16px 0;
        width: 100%;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        line-height: 21px;
        color: var(--primary-500);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 6px;
        cursor: pointer;

        @media only screen and (max-width: '767px') {
          padding: 6px 0;
          font-size: 15px;
          font-weight: 500;
          line-height: 20px;
        }
      }

      &.webAttributesViewButtonAlignment {
        @media only screen and (max-width: '767px') {
          display: none;
        }
      }

      &.mobileAttributesViewButtonAlignment {
        display: none;

        @media only screen and (max-width: '767px') {
          display: flex;
        }
      }
    }

    .addAttributeFromAlignment {
      padding: 16px 0 0 0;

      @media only screen and (max-width: '1024px') {
        display: none;
      }

      .addAttributeGrid {
        display: grid;
        grid-template-columns: 160px 1fr 32px;
        align-items: center;
        gap: 4px;
        padding: 4px 0 0 0;

        &:first-child {
          padding: 0;
        }

        .addAttributeGridItem {
          .labelAlignment {
            padding: 0 0 0 14px;
            text-align: left;

            label {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              color: #495057;
              padding: 0;
              margin: 0;
            }
          }

          .atrributeFormInputAlignment {
            display: flex;
            align-items: flex-end;
            gap: 8px;

            .inputAlignment {
              position: relative;
              width: 100%;

              input {
                background: var(--light-grey);
                width: 100%;
                height: 40px;
                padding: 0 16px;
                color: var(--secondary-900);
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                &.specError {
                  border: 1px solid red;
                }

                &.firstInut {
                  border-radius: 10px 0px 0px 10px;
                }

                &.secondInut {
                  border-radius: 0px 10px 10px 0px;
                }

                &::placeholder {
                  color: #acb1c0;
                }
              }

              .dropdownAlignment {
                background: #ffffff;
                border: 1px solid #e4e4e8;
                border-radius: 10px;
                height: auto;
                max-height: 184px;
                overflow-y: auto;
                padding: 12px 0;
                position: absolute;
                width: 100%;
                bottom: 44px;

                ul {
                  list-style-type: none;
                  list-style-position: outside;
                  margin: 0;
                  padding: 0;

                  li {
                    text-align: left;
                    padding: 4px 19px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--secondary-900);

                    // &::-webkit-scrollbar {
                    //   width: 0;
                    // }

                    &:hover {
                      background: #eceef4;
                      box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.05);
                      cursor: pointer;
                    }
                  }
                }
              }
            }

            .addButtonAlignment {
              button {
                height: 32px;
                width: 68px;
                max-width: 68px;
                min-width: 68px;
                background: #bbc5f5;
                border-radius: 8px;
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #f8f8f8;
                cursor: pointer;
                border: none;

                &.active {
                  background: var(--primary-500);
                }
              }
            }

            .deleteIconAlignment {
              width: 32px;
              height: 32px;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                display: block;
                cursor: pointer;
              }
            }

            .addButtonAlignment {
              button {
                height: 32px;
                width: 68px;
                max-width: 68px;
                min-width: 68px;
                background: #bbc5f5;
                border-radius: 8px;
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #f8f8f8;
                cursor: pointer;
                border: none;

                &.activeButton {
                  background: var(--primary-500);
                }
              }
            }
          }
        }
      }
    }

    .mobileViewAttributeSpecificationSection {
      display: none;

      @media only screen and (max-width: '767px') {
        display: block;
      }

      .mobileViewAttributeSpecificationDetails {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        padding: 16px 0 0 0;

        .mobileViewArttributeLeftSide {
          h6 {
            color: var(--secondary-900);
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
          }

          p {
            padding: 4px 0 0 0;
            color: var(--secondary-500);
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
          }
        }

        .mobileViewAttributrRightSide {
          display: flex;
          align-items: center;
          gap: 16px;

          a {
            color: var(--primary-500);
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
          }
        }
      }

      .mobileViewAddNew {
        padding: 16px 0 0 0;

        a {
          padding: 6px 0;
          color: var(--primary-500);
          font-size: 15px;
          font-weight: 500;
          line-height: 20px;
        }
      }
    }
  }
}
