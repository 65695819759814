.art-craft-learn-artists-store-details-alignment {
    @media only screen and (max-width:"768px") {
        background: #fff;
    }

    .mobile-view-art-craft-back-alignment {
        display: none;

        @media only screen and (max-width:"768px") {
            display: flex;
            align-items: center;
            gap: 18px;
            padding: 8px 16px;
            background: rgba(248, 248, 248, 0.92);
            backdrop-filter: blur(13.5914px);
            justify-content: flex-start;
            position: sticky;
            top: 0;
            z-index: 999;

            .art-craft-back_details-alignment {
                background: #ffffff;
                border-radius: 10px;
                padding: 4px 9px 4px 10px;
                display: flex;
                align-items: center;
                gap: 8px;

                p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #808191;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 58px;
                }
            }

            .mobile-heading-alignment {
                h4 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 27px;
                    text-align: center;
                    color: var(--secondary-900);
                }
            }
        }
    }

    .art-craft-learn-artists-store-banner-alignment {
        width: 100%;
        height: 178px;
        position: relative;

        @media only screen and (max-width:"768px") {
            height: 100px;
            top: 47px;
        }

        img {
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 8px;
            object-fit: cover;

            @media only screen and (max-width:"768px") {
                display: none;
            }

            &.mobile-view-banner {
                display: none;

                @media only screen and (max-width:"768px") {
                    display: block;
                }
            }
        }

        .art-craft-learn-artists-store-banner-positiona-alignment {
            display: flex;
            align-items: center;
            gap: 82px;
            position: absolute;
            height: 100%;
            top: 0;
            left: 82px;

            @media only screen and (max-width:"768px") {
                left: 32px;
            }

            .art-craft-learn-artists-store-banner-details {

                h4 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 40px;
                    line-height: 60px;
                    color: var(--secondary-900);

                    @media only screen and (max-width:"768px") {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }

                p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--secondary-900);
                    max-width: 540px;

                    @media only screen and (max-width:"768px") {
                        font-size: 12px;
                        line-height: 18px;
                        max-width: 165px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }


    }

    .art-craft-learn-artists-tab-alignment {
        padding: 38px 0 0 0;

        @media only screen and (max-width:"768px") {
            padding: 16px;
        }

        ul {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 72px;

            @media only screen and (max-width:"768px") {
                gap: 0;
                width: 100%;
                overflow-x: auto;
                justify-content: flex-start;
            }

            li {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #495057;
                cursor: pointer;
                white-space: nowrap;

                @media only screen and (max-width:"768px") {
                    padding: 6px 16px;
                }

                &:hover {
                    color: var(--primary-500);
                    font-weight: 500;

                    @media only screen and (max-width:"768px") {
                        background: #F2F4FC;
                        border-radius: 8px;
                    }
                }

                &.art-craft-active {
                    color: var(--primary-500);
                    font-weight: 500;

                    @media only screen and (max-width:"768px") {
                        background: #F2F4FC;
                        border-radius: 8px;
                    }
                }
            }
        }
    }


    .art-craft-learn-artists-modal-details-alignment {
        padding: 32px 0;

        @media only screen and (max-width:"768px") {
            padding: 0 16px;
        }

        .art-craft-learn-artists-modal-grid-alignment {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 24px 16px;

            @media only screen and (max-width:"768px") {
                grid-template-columns: 1fr;
            }

            .art-craft-learn-artists-modal-gridIem-alignment {
                .art-craft-learn-artists-modal-all-details-alignment {
                    .art-craft-learn-artists-modal-img-alignment {
                        width: 100%;
                        height: 175px;
                        position: relative;
                        cursor: pointer;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 8px;
                            object-fit: cover;
                        }

                        .time-label-alignment {
                            position: absolute;
                            bottom: 8px;
                            left: 8px;

                            .time-label-box-alignment {
                                background: rgba(30, 36, 50, 0.6);
                                border-radius: 4px;
                                padding: 1px 8px;
                                cursor: pointer;

                                span {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 500;
                                    font-size: 13px;
                                    line-height: 20px;
                                    color: #FFFFFF;
                                }
                            }
                        }
                    }

                    .art-craft-learns-artists-all-child-details-alignment {
                        padding: 8px 16px;

                        span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 20px;
                            color: #606060;
                            cursor: pointer;
                        }

                        p {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            color: var(--secondary-900);
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}