@mixin block_btn {
  background: var(--primary-500);
  border-radius: 8px;
  display: block;
  width: 100%;
  border: unset;
  outline: unset;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    transform: translateY(-3px);
    background: #2844ac;
  }
}

.dashboard-page-wrapper {
  // width: 1920px;
  // min-width: 1320px;
  width: 100%;

  @media only screen and (max-width: '1024px') {
    padding: 0;
  }

  .dashboard-wrapper {
    width: 100%;
    // display: flex;
    // justify-content: flex-start;
    // gap: 100px;

    .dashboard-right-wrapper {
      width: 100%;
      // padding: 50px 100px 50px 0;

      // display: flex;
      // justify-content: center;

      border-radius: 10px;
      border: 1px solid var(--white);
      background: linear-gradient(100deg, rgba(251, 251, 252, 0.67) 42.3%, rgba(255, 255, 255, 0.09) 98.21%);
      box-shadow: 0px 5px 50px 0px rgba(144, 143, 143, 0.1);
      padding: 60px 35px;

      &.profile-section-main {
        padding: 0;
        border: none;
        background: unset;
        box-shadow: unset;
      }

      @media only screen and (max-width: '1024px') {
        padding: 16px 8px;
        background: transparent;
        box-shadow: none;
        border: none;
        border-radius: 0px;
      }

      &.book-category-page {
        width: 80%;
      }

      @media only screen and (max-width: '767px') {
        padding: 0;

        &.order-wrapper {
          padding: 0;
        }
      }

      .dashboard-content-wrapper {
        width: 100%;

        // max-width: 1200px;
        .grid-alignment {
          gap: 16px;

          @media only screen and (max-width: '767px') {
            gap: 8px;
          }
        }

        .dashboard-overview-heading-alignment {
          padding: 0 0 24px 0;

          @media only screen and (max-width: '1024px') {
            display: none;
          }

          h4 {
            color: var(--secondary-900);
            font-family: 'Poppins', sans-serif;
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            padding: 0 0 5px 0;
          }

          p {
            color: var(--secondary-500);
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
          }
        }

        .content-title {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 600 !important;
          font-size: 22px;
          line-height: 33px;
          color: #000000;
        }

        .all_card {
          // padding: 30px;
          width: 100%;
          background: #ffffff;
          border-radius: 10px;
          margin-bottom: 30px;

          .card_header {
            padding-top: 20px;
            padding-left: 30px;
            padding-right: 30px;

            .header-title {
              font-family: Poppins;
              font-style: normal;
              font-weight: 600 !important;
              font-size: 18px;
              line-height: 27px;
              color: #000000;
            }

            .progressNum {
              font-family: Poppins;
              font-style: normal;
              font-weight: 600 !important;
              font-size: 18px;
              line-height: 39px;
              color: #000000;
            }

            .header-description {
              margin-top: -10px;
              font-family: Poppins;
              font-style: normal;
              font-weight: 500 !important;
              font-size: 14px;
              line-height: 21px;
              color: var(--grey2);
            }
          }

          .card_body {
            padding: 30px;
          }
        }

        .slick-dots {
          width: auto;
          top: -30px;
          right: 30px;
          height: 30px;

          li {
            width: 8px;

            button {
              width: 8px;
              padding: 0;

              &:before {
                content: '';
                width: 8px;
                height: 8px;
                background: #c4c4c4;
                border-radius: 8px;
                opacity: 1;
              }
            }

            &.slick-active {
              width: 20px;

              button {
                &:before {
                  width: 20px;
                  background: #5f40af;
                }
              }
            }
          }
        }

        .ant-table-wrapper {
          .ant-table-thead {
            .ant-table-cell {
              padding: 10px 30px;
              min-width: 80px;
              background-color: var(--secondary-50);
              color: var(--grey5);
              font-weight: 600 !important;
              font-size: 12px;
            }
          }

          .ant-table-tbody {
            .ant-table-cell {
              height: 92px;
              text-align: left;
              padding: 0 30px;
              color: #000000;
              font-weight: 400 !important;
              font-size: 16px;
            }
          }

          .ant-table-pagination {
            display: flex;
            justify-content: center;

            li {
              a {
                background-color: var(--primary-500);
                border-radius: 20px;
                border: 0px;
                color: white;
              }

              button {
                background-color: var(--primary-500);
                border-radius: 20px;
                border: 0px;
                color: white;
              }
            }
          }
        }

        .ant-pagination {
          li {
            border-radius: 20px;

            a {
              background-color: #6366f1;
              border-radius: 20px;
              border: 0px;
              color: white;
            }

            button {
              background-color: #6366f1;
              border-radius: 20px;
              border: 0px;
              color: white;
            }
          }
        }
      }
    }
  }

  .dashboard-wrapper-for-book-page {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 100px;

    .dashboard-right-wrapper {
      width: 100%;

      &.book-category-page {
        width: 80%;

        @media only screen and (max-width: '767px') {
          width: 100%;
        }
      }
    }
  }

  .ant-tabs {
    width: 100%;

    .ant-tabs-nav {
      width: 100%;
      // background: #fff;
      height: unset;
      margin: 0;
      border-radius: 0;

      @media only screen and (max-width: '1024px') {
        height: unset;
      }

      &:before {
        border: 0;
      }
    }

    .ant-tabs-nav-wrap {
      // justify-content: flex-end;
      justify-content: flex-start;
      border-bottom: solid 1px #e6e0e0;
      margin-bottom: 0;
      width: 100%;

      @media only screen and (max-width: '1024px') {
        margin-bottom: 15px;
      }

      .ant-tabs-nav-list {
        width: 100%;
        justify-content: space-between;
        padding: 0 78px;

        @media only screen and (max-width: '1024px') {
          padding: 0;
          width: unset;
          justify-content: flex-start;
        }
      }

      .ant-tabs-tab {
        margin: 0;
        padding: 0;
        padding: 20px 30px;
        font-weight: 500 !important;
        color: #a7a7a7;
        font-size: 15px;
        align-items: flex-end;
        position: relative;

        @media only screen and (max-width: '1024px') {
          padding: 0 11px 6px 11px;
        }

        .ant-tabs-tab-btn {
          color: var(--grey2);
          font-size: 16px;
          font-weight: 500;
          line-height: 21px;

          @media only screen and (max-width: '1024px') {
            font-size: 15px;
          }
        }

        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: #000;
          }
        }

        &:before {
          content: '';
          position: absolute;
          background-color: #cbc9c9;
          width: 0px;
          height: 20px;
          left: 0;
        }
      }

      .ant-tabs-ink-bar {
        height: 3px;
        background-color: var(--perple-1);
        border-radius: 4px 4px 0 0;

        @media only screen and (max-width: '1024px') {
          height: 3px;
          border-radius: 3px;
          background: var(--primary-500);
        }
      }
    }

    .ant-tabs-content-holder {
      width: 100%;

      .ant-tabs-content {
        width: 100%;

        .ant-tabs-tabpane {
          width: 100%;
        }

        .ant-tabs-tabpane-active {
          width: 100%;
          display: block;

          .general-tab-panel-card {
            background-color: white !important;
            padding: 32px 64px;
            margin-bottom: 24px;
            margin-top: -16px;
            border-radius: 20px;

            .profile-tab-panel-input {
              padding: 8px;
              color: var(--grey2);
              background-color: var(--secondary-50);
              border-radius: 8px;
              border: Solid 1px var(--secondary-50);
              height: 40px;
              width: 100%;
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px;
              letter-spacing: 0em;
            }

            .profile-tab-panel-textarea {
              padding: 8px;
              color: var(--grey2);
              background-color: var(--secondary-50);
              border-radius: 8px;
              border: Solid 1px var(--secondary-50);
              height: 130px;
              width: 100%;
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px;
              letter-spacing: 0em;
            }
          }
        }
      }
    }
  }

  .profile-payment-tab {
    .ant-tabs-nav-wrap {
      margin-bottom: -1px;
    }
  }

  .profile-tab {
    .ant-tabs-tabpane {
      background-color: var(--secondary-50);
    }
  }

  .orderdetails-tab {
    .ant-tabs-content {
      margin-top: 20px;
      margin-bottom: 30px;
    }
  }

  .reloadbalance {
    .ant-input-group-wrapper {
      .ant-input {
        height: 39px;
      }
    }
  }

  .ant-checkbox {
    .ant-checkbox-inner {
      border-color: #6366f1;
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #6366f1;
      border-color: #6366f1;
    }
  }
}

@media only screen and (max-width: 768px) {
  .ant-checkbox {
    .ant-checkbox-inner {
      border-color: #6366f1;
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #6366f1;
      border-color: #6366f1;
    }
  }
}

.profile-section {
  .profile-heading {
    padding: 0 0 20px 0;

    @media only screen and (max-width: '1024px') {
      display: none;
    }

    h5 {
      font-family: 'Poppins', sans-serif;
      font-weight: 700;
      font-size: 26px;
      line-height: 39px;
      color: #000000;
    }
  }

  .profile-details-alignment {
    .profile-box-alignment {
      // background: #ffffff;
      border-radius: 20px;
      margin: 0 0 32px 0;

      &:last-child {
        margin: 0;
      }

      .profile-main-details-alignment {
        .profile-bg-alignment {
          width: 100%;
          height: 200px;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            bottom: 0;
            left: 0;
            background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 76.75%);
            z-index: 99;
          }

          @media only screen and (max-width: '767px') {
            height: 186px;

            &::before {
              content: '';
              position: absolute;
              width: 100%;
              height: 120px;
              bottom: 0;
              left: 0;
              background: linear-gradient(0deg, rgba(30, 36, 50, 0.8) 0%, rgba(30, 36, 50, 0) 100%);
              z-index: 99;
            }
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 10px 10px 0px 0px;
            object-fit: cover;

            @media only screen and (max-width: '1024px') {
              border-radius: 0;
            }
          }

          .edit-icon-alignment {
            position: absolute;
            top: 38px;
            right: 65px;
            z-index: 999;
            cursor: pointer;

            @media only screen and (max-width: '1024px') {
              display: none;
              cursor: pointer;
            }

            img {
              width: 20px;
              height: 20px;
              display: block;
              cursor: pointer;
            }

            .pic-input-alignment {
              input {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                opacity: 0;
                cursor: pointer;
              }
            }
          }

          .mobile-view-back-alignment {
            display: none;

            @media only screen and (max-width: '1024px') {
              display: block;

              position: absolute;
              top: 12px;
              left: 16px;
              z-index: 9999;
            }
          }

          .mobile-view-profile-details-alignment {
            display: none;

            @media only screen and (max-width: '1024px') {
              display: block;

              position: absolute;
              bottom: 6px;
              left: 16px;
              z-index: 99999;
            }

            .mobile-view-profile-grid {
              display: grid;
              grid-template-columns: 76px 1fr;
              align-items: flex-end;
              gap: 12px;

              .mobile-view-profile-img {
                width: 100%;
                height: 76px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                  border-radius: 50%;
                  border-radius: 76px;
                  border: 2px solid var(--light-grey);
                }
              }

              .mobile-view-all-profile-details {
                h4 {
                  color: var(--white);
                  font-size: 17px;
                  font-weight: 700;
                  line-height: 21px;
                }

                .mobile-view-follow-following-alignment {
                  display: flex;
                  align-items: center;
                  gap: 32px;
                  padding: 8px 0 0 0;

                  .mobile-view-child-details-alignment {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    span {
                      color: var(--white);
                      font-size: 15px;
                      font-weight: 500;
                      line-height: 21px;
                    }

                    p {
                      color: rgba(236, 238, 244, 0.5);
                      font-size: 15px;
                      font-weight: 400;
                      line-height: 21px;
                    }
                  }
                }
              }
            }
          }

          .profile-img-alignment {
            position: absolute;
            top: 32px;
            left: 32px;
            width: 190px;
            min-width: 190px;
            max-width: 190px;
            height: 190px;
            z-index: 999;

            @media only screen and (max-width: '1024px') {
              display: none;
            }

            img {
              width: 100%;
              height: 100%;
              border: 6px solid #eaecef;
              border-radius: 50%;
              object-fit: cover;
            }

            .changes-img-alignment {
              position: absolute;
              top: 127px;
              right: 0;
              cursor: pointer;
              // position: relative;

              img {
                width: 40px;
                height: 40px;
                border: none;
              }

              .pic-input-alignment {
                input {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  position: absolute;
                  top: 0;
                  right: 0;
                  left: 0;
                  opacity: 0;
                  cursor: pointer;
                }
              }
            }
          }
        }

        .profile-layout-alignment {
          // padding: 9px 80px;
          padding: 38px 0 0 0;
          border-radius: 0 0 10px 10px;
          border: 1px solid var(--white);
          background: linear-gradient(100deg, rgba(251, 251, 252, 0.67) 42.3%, rgba(255, 255, 255, 0.09) 98.21%);
          box-shadow: 0px 5px 50px 0px rgba(144, 143, 143, 0.1);

          @media only screen and (max-width: '1024px') {
            padding: 14px 8px;
          }

          .profile-setting-alignment {
            padding: 0 78px 18px 78px;

            @media only screen and (max-width: '1024px') {
              display: none;
            }

            .profile-flex-alignment {
              display: flex;
              align-items: center;
              justify-content: space-between;

              h6 {
                color: var(--secondary-900);
                font-size: 20px;
                font-weight: 600;
                line-height: 30px;
              }

              p {
                color: var(--secondary-500);
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                padding: 5px 0 0 0;
              }

              .profile-save-button-alignment {
                button {
                  max-width: 94px;
                  width: 94px;
                  height: 30px;
                  border-radius: 5px;
                  background: var(--primary-500);
                  color: var(--white);
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 24px;
                  cursor: pointer;
                }
              }
            }
          }

          .profile-child-details-alignment {
            display: flex;
            align-items: flex-start;
            border-bottom: 1px solid #eaecef;
            padding: 9px 80px 0 80px;
            background: #fff;

            @media only screen and (max-width: '1024px') {
              display: none;
            }

            .person-profile-details-alignment {
              display: flex;
              align-items: flex-start;
              gap: 45px;

              .profile-img-alignment {
                position: relative;
                bottom: 80px;
                width: 190px;
                min-width: 190px;
                max-width: 190px;
                height: 190px;

                img {
                  width: 100%;
                  height: 100%;
                  border: 6px solid #eaecef;
                  border-radius: 50%;
                  object-fit: cover;
                }

                .changes-img-alignment {
                  position: absolute;
                  top: 127px;
                  right: 0;
                  cursor: pointer;
                  // position: relative;

                  img {
                    width: 40px;
                    height: 40px;
                    border: none;
                  }

                  .pic-input-alignment {
                    input {
                      width: 100%;
                      height: 100%;
                      border-radius: 50%;
                      position: absolute;
                      top: 0;
                      right: 0;
                      left: 0;
                      opacity: 0;
                      cursor: pointer;
                    }
                  }
                }
              }

              .profile-name-alignment {
                padding: 0 122px 0 0;

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 22px;
                  line-height: 33px;
                  color: #000000;
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 400;
                  font-size: 15px;
                  line-height: 22px;
                  color: var(--grey2);
                  padding: 0 0 22px 0;
                }

                .social-option-alignment {
                  display: flex;
                  align-items: center;
                  gap: 40px;

                  img {
                    width: 32px;
                    height: 32px;
                    cursor: pointer;
                  }
                }
              }
            }

            .person-profile-range-alignment {
              padding: 15px 0 0 122px;
              display: flex;
              align-items: center;
              gap: 25px;

              .range-char-alignment {
                img {
                  width: 80px;
                  height: 80px;
                  border-radius: 50%;
                }
              }

              .range-name-alignment {
                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 700;
                  font-size: 17px;
                  line-height: 26px;
                  color: var(--primary-500);
                }

                span {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 400;
                  font-size: 15px;
                  line-height: 22px;
                  color: var(--grey5);
                  max-width: 270px;
                  width: 270px;
                }
              }
            }
          }

          .profile-tab-alignment {
            padding: 20px 0;
            border-bottom: 1px solid #eaecef;

            ul {
              display: flex;
              align-items: center;
              gap: 100px;

              li {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 17px;
                line-height: 26px;
                color: var(--grey2);
                cursor: pointer;
                position: relative;

                &:hover {
                  color: #000000;
                  font-weight: 700;

                  &::before {
                    position: absolute;
                    content: '';
                    background: var(--primary-500);
                    border-radius: 5px 5px 0px 0px;
                    width: 100%;
                    height: 5px;
                    bottom: -20px;
                    right: 0;
                  }
                }
              }
            }
          }

          .personal-information-section {
            padding: 38px 85px 50px 85px;

            // background: #ffffff;
            border-bottom: 1px solid var(--grey4);
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;

            @media only screen and (max-width: '1024px') {
              // border-radius: 0 0 20px 20px;
              display: block;
              padding: 0;
              border-bottom: none;
              padding: 0;
              // padding: 175px 0 0 0;
            }

            .personal-information-heading-alignment {
              @media only screen and (max-width: '1024px') {
                padding: 16px 0 0 0;
              }

              h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: var(--grey5);

                @media only screen and (max-width: '1024px') {
                  color: var(--secondary-900);
                }
              }

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: var(--grey3);

                @media only screen and (max-width: '1024px') {
                  font-size: 10px;
                  line-height: 14px;
                }
              }

              .edit-button-alignment {
                padding: 20px 0 0 0;

                @media only screen and (max-width: '1024px') {
                  display: none;
                }

                button {
                  border: 1px solid #556ee6;
                  border-radius: 10px;
                  padding: 5px 20px;
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                  line-height: 22px;
                  color: var(--primary-500);
                  cursor: pointer;
                }
              }
            }

            .personal-information-form-alignment {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              padding: 15px 0 0 0;

              @media only screen and (max-width: '1024px') {
                padding: 16px 0 0 0;
              }

              .personal-form-alignment {
                width: 388px;
                // display: grid;

                // grid-template-columns: repeat(2, 1fr);
                // gap: 30px;
                @media only screen and (max-width: '1024px') {
                  width: 100%;
                }

                .two-grid-alignment {
                  display: grid;

                  grid-template-columns: 1fr;
                  gap: 24px;
                }

                .personal-input-alignment {
                  &.first-grid {
                    padding: 0 0 16px 0;

                    @media only screen and (max-width: '1024px') {
                      padding: 0 0 24px 0;
                    }
                  }

                  label {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 22px;
                    color: var(--grey2);
                    padding: 0 0 3px 0;
                    margin: 0;

                    @media only screen and (max-width: '1024px') {
                      font-size: 13px;
                      line-height: 19px;
                      color: var(--grey3);
                      padding: 0 0 2px 0;
                      margin: 0;
                    }
                  }

                  .form-input-alignment {
                    width: 100%;
                    height: 53px;
                    position: relative;

                    @media only screen and (max-width: '1024px') {
                      height: 32px;
                    }

                    input {
                      width: 100%;
                      height: 100%;
                      border-radius: 10px;
                      background: var(--secondary-50);
                      font-family: 'Poppins', sans-serif;
                      font-weight: 400;
                      font-size: 15px;
                      line-height: 22px;
                      padding: 0 20px;

                      @media only screen and (max-width: '1024px') {
                        border-radius: 5px;
                        background: var(--secondary-50);
                        padding: 0 16px;
                        font-size: 13px !important;
                        line-height: 19px !important;
                      }

                      &::placeholder {
                        color: var(--grey3);
                      }
                    }

                    .down-arrow-alignment {
                      position: absolute;
                      top: 19px;
                      right: 19px;
                      cursor: pointer;
                    }
                  }
                }

                .personal-input-alignment-antd {
                  label {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 22px;
                    color: var(--grey2);
                    padding: 0 0 3px 0;
                    margin: 0;

                    @media only screen and (max-width: '1024px') {
                      font-size: 13px;
                      line-height: 19px;
                      color: var(--grey3);
                      padding: 0 0 2px 0;
                      margin: 0;
                    }
                  }

                  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                    margin-top: 0;
                    height: 53px;
                    background: var(--secondary-50);
                    padding-top: 0;
                    border-radius: 10px;
                    border: none;
                    padding: 0 20px;
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: '1024px') {
                      margin: 0 !important;
                      padding: 0 !important;
                      border-radius: 5px !important;
                      background: var(--secondary-50) !important;
                      padding: 0 16px !important;
                    }
                  }

                  .ant-select-selector {
                    @media only screen and (max-width: '1024px') {
                      border-radius: 5px;

                      font-size: 13px !important;
                      line-height: 19px !important;
                      height: 32px !important;
                      margin: 0 !important;
                    }
                  }
                }
              }
            }

            .save-button-alignment {
              text-align: right;
              padding: 30px 0 0 0;
              display: none;

              @media only screen and (max-width: '1024px') {
                padding: 16px 0 0 0;
                text-align: center;
                display: block;
              }

              button {
                background: var(--primary-500);
                border-radius: 10px;
                padding: 10px 20px;
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 15px;
                line-height: 22px;
                color: #ffffff;
                cursor: pointer;

                @media only screen and (max-width: '1024px') {
                  padding: 5px 16px;
                  font-size: 13px;
                  line-height: 19px;
                  border-radius: 6px;
                }
              }
            }
          }

          .billing-details-wraning-alignment {
            margin: 0 0 32px 0;
            background: #fff;
            padding: 24px 80px 32px 80px;
            border-radius: 0 0 20px 20px;

            @media only screen and (max-width: '1024px') {
              display: none;
            }

            .billing-details-wraning-box {
              background: rgba(255, 148, 73, 0.15);
              border-radius: 10px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 16px 32px;

              .first-part-alignment {
                display: flex;
                align-items: center;
                gap: 16px;

                img {
                  cursor: pointer;
                }

                .warning-details-alignment {
                  h6 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 22px;
                    color: var(--secondary-900);
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey2);

                    a {
                      color: #a855f7;
                      cursor: pointer;
                      text-decoration: underline;
                    }
                  }
                }
              }

              .second-part-alignment {
                img {
                  width: 24px;
                  height: 24px;
                  cursor: pointer;
                }
              }
            }
          }

          .bio-section {
            padding: 32px 85px 115px 85px;
            border-radius: 0 0 20px 20px;

            @media only screen and (max-width: '1024px') {
              padding: 0;
              background: #fff;
            }

            .bio-details-grid {
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;

              @media only screen and (max-width: '1024px') {
                grid-template-columns: 1fr;
                display: grid;
                gap: 0;
              }

              .bio-details-gridItem {
                .heading-alignment {
                  @media only screen and (max-width: '1024px') {
                    padding: 0 0 16px 0;
                  }

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 30px;
                    color: var(--grey5);
                  }

                  p {
                    color: var(--grey3);
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                  }
                }

                .edit-button-alignment {
                  padding: 20px 0 0 0;

                  @media only screen and (max-width: '1024px') {
                    display: none;
                  }

                  button {
                    border: 1px solid #556ee6;
                    border-radius: 10px;
                    padding: 5px 20px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 22px;
                    color: var(--primary-500);
                    cursor: pointer;
                  }
                }

                .bioform-alignment {
                  width: 388px;

                  @media only screen and (max-width: '1024px') {
                    width: 100%;
                  }

                  .two-grid-alignment {
                    display: grid;

                    grid-template-columns: 1fr;
                    gap: 24px;
                  }

                  .bioform-input-alignment {
                    &.first-grid {
                      padding: 16px 0 0 0;
                    }

                    label {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 15px;
                      line-height: 22px;
                      color: var(--grey2);
                      padding: 0 0 3px 0;
                      margin: 0;

                      @media only screen and (max-width: '1024px') {
                        font-size: 13px;
                        line-height: 19px;
                        color: var(--grey3);
                        padding: 0 0 2px 0;
                        margin: 0;
                      }
                    }

                    .form-input-alignment {
                      width: 100%;
                      position: relative;

                      input {
                        width: 100%;
                        height: 53px;
                        background: var(--secondary-50);
                        border-radius: 10px;
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 22px;
                        padding: 0px 20px;

                        @media only screen and (max-width: '1024px') {
                          height: 32px;
                          border-radius: 5px;
                          background: var(--secondary-50);
                          padding: 0 16px;
                          font-size: 13px !important;
                          line-height: 19px !important;
                        }

                        &::placeholder {
                          color: var(--grey3);
                        }
                      }

                      textarea {
                        width: 100%;
                        height: 160px;
                        background: var(--secondary-50);

                        border-radius: 10px;
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 22px;
                        padding: 20px;
                        resize: none;

                        @media only screen and (max-width: '1024px') {
                          height: 80px;
                          padding: 6px 16px;
                          font-size: 13px;
                          line-height: 19px;
                        }

                        &::placeholder {
                          color: var(--grey3);

                          @media only screen and (max-width: '1024px') {
                            color: var(--grey3);
                          }
                        }
                      }

                      .down-arrow-alignment {
                        position: absolute;
                        top: 19px;
                        right: 19px;
                        cursor: pointer;
                      }
                    }
                  }

                  .bioform-input-alignment-antd {
                    label {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 15px;
                      line-height: 22px;
                      color: var(--grey2);
                      padding: 0 0 3px 0;
                      margin: 0;

                      @media only screen and (max-width: '1024px') {
                        font-size: 13px;
                        line-height: 19px;
                        color: var(--grey3);
                        padding: 0 0 2px 0;
                        margin: 0;
                      }
                    }

                    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                      margin-top: 0;
                      height: 53px;
                      background: var(--secondary-50);
                      padding-top: 0;
                      border-radius: 10px;
                      border: none;
                      padding: 0 20px;
                      display: flex;
                      align-items: center;

                      @media only screen and (max-width: '1024px') {
                        margin: 0 !important;
                        padding: 0 !important;
                        border-radius: 5px !important;
                        background: var(--secondary-50) !important;
                        padding: 0 16px !important;
                      }
                    }

                    .ant-select-selector {
                      @media only screen and (max-width: '1024px') {
                        border-radius: 5px;

                        font-size: 13px !important;
                        line-height: 19px !important;
                        height: 32px !important;
                        margin: 0 !important;
                      }
                    }
                  }
                }

                .bioform-button-alignment {
                  padding: 64px 0 0 0;
                  text-align: right;
                  display: none;

                  @media only screen and (max-width: '1024px') {
                    padding: 16px 0 0 0;
                    text-align: center;
                    display: block;
                  }

                  button {
                    background: var(--primary-500);
                    border-radius: 10px;
                    padding: 10px 20px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 22px;
                    color: #ffffff;
                    cursor: pointer;

                    @media only screen and (max-width: '1024px') {
                      padding: 5px 16px;
                      font-size: 13px;
                      line-height: 19px;
                      border-radius: 6px;
                    }
                  }
                }
              }
            }
          }

          .integration-section {
            padding: 26px 80px 65px 80px;
            background: #fff;
            border-radius: 0 0 20px 20px;

            @media only screen and (max-width: '1024px') {
              padding: 0;
            }

            .integration-heading-alignment {
              h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                color: var(--grey5);
                padding: 0 0 8px 0;
              }

              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: var(--grey2);
              }
            }

            .integration-details-alignment {
              padding: 20px 0 0 282px;

              .integration-details-grid {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 24px 64px;

                .integration-details-gridItem {
                  background: #ffffff;
                  border: 1px solid var(--grey4);
                  border-radius: 10px;

                  .integration-details-main-alignment {
                    padding: 27px 29px 32px 19px;
                    width: 100%;

                    .main-details-name-alignment {
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                    }

                    p {
                      padding: 29px 0 0 0;
                      font-family: 'Poppins', sans-serif;
                      font-weight: 400;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }

                  .integration-footer-alignment {
                    border-top: 1px solid var(--grey4);
                    padding: 13px 29px 10px 31px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    p {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 15px;
                      line-height: 22px;
                      color: var(--primary-500);
                    }

                    .right-arrow-alignment {
                      img {
                        cursor: pointer;
                      }
                    }
                  }
                }
              }
            }
          }

          .notification-details-section {
            padding: 26px 0px 80px 0px;

            @media only screen and (max-width: '1024px') {
              padding: 0;
              border-radius: 0 0 20px 20px;
              background: #fff;
            }

            .notification-details-grid {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: 81px;
              align-items: flex-start;
              border-bottom: 1px solid var(--grey4);
              padding: 32px 86px;

              &:last-child {
                border-bottom: none;
              }

              @media only screen and (max-width: '1024px') {
                grid-template-columns: 1fr;
                gap: 16px;
                padding: 16px 0;

                &:first-child {
                  padding: 0 0 16px 0;
                }
              }

              &:last-child {
                border-bottom: none;
              }

              &.toggle-alignment {
                align-items: center;

                @media only screen and (max-width: '1024px') {
                  display: flex;
                  justify-content: space-between;
                }
              }

              .notification-details-gridItem {
                max-width: 396px;

                @media only screen and (max-width: '1024px') {
                  max-width: 100%;
                }

                &.toggle-center {
                  padding: 0 0 0 82px;

                  @media only screen and (max-width: '1024px') {
                    padding: 0;
                  }
                }

                .notification-name-alignment {
                  h6 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 22px;
                    color: var(--grey5);

                    @media only screen and (max-width: '1024px') {
                      font-size: 13px;
                      line-height: 19px;
                      color: var(--secondary-900);
                    }
                  }

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: var(--grey3);
                    max-width: 353px;

                    @media only screen and (max-width: '1024px') {
                      font-size: 10px;
                      line-height: 15px;
                      max-width: 100%;
                    }
                  }
                }

                .notification-option-alignment {
                  @media only screen and (max-width: '1024px') {
                    padding: 0 16px;
                  }

                  .notification-option-name-alignment {
                    padding: 0 0 12px 0;

                    &:last-child {
                      padding: 0;
                    }

                    display: flex;
                    align-items: flex-start;
                    gap: 24px;

                    @media only screen and (max-width: '1024px') {
                      gap: 16px;
                    }

                    input[type='checkbox'] {
                      width: 24px;
                      height: 24px;

                      @media only screen and (max-width: '1024px') {
                        width: 16px;
                        height: 16px;
                      }
                    }

                    .notification-notes-name-alignment {
                      h5 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 19px;
                        color: var(--grey5);
                        padding: 0 0 4px 0;

                        @media only screen and (max-width: '1024px') {
                          color: var(--grey2);
                          padding: 0;
                        }
                      }

                      p {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 19px;
                        color: var(--grey3);

                        @media only screen and (max-width: '1024px') {
                          font-size: 10px;
                          line-height: 14px;
                        }
                      }
                    }
                  }
                }

                // .toggle-details-alignment {

                // }
              }
            }

            .notification-save-button-alignment {
              display: none;

              @media only screen and (max-width: '1024px') {
                padding: 16px 0 0 0;
                text-align: left;
                display: block;
              }

              button {
                background: var(--primary-500);
                border-radius: 10px;
                padding: 10px 20px;
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 15px;
                line-height: 22px;
                color: #ffffff;
                cursor: pointer;

                @media only screen and (max-width: '1024px') {
                  padding: 5px 16px;
                  font-size: 13px;
                  line-height: 19px;
                  border-radius: 6px;
                }
              }
            }
          }
        }
      }
    }

    .payment-box-details-alignment {
      // background: #ffffff;
      // padding: 40px 60px;
      padding: 42px 30px 40px 30px;
      border-bottom: 1px solid #eaecef;

      @media only screen and (max-width: '1024px') {
        padding: 0;
        border-radius: 20px;
        margin: 2px 0 0 0;

        border-bottom: none;

        &.billing-section {
          // margin: 42px 0 0 0;
          padding: 176px 0 0 0;
        }
      }

      .payment-details-heading-alignment {
        padding: 0 0 40px 0;

        @media only screen and (max-width: '1024px') {
          padding: 0 0 16px 0;
        }

        h6 {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: var(--grey5);

          @media only screen and (max-width: '1024px') {
            font-size: 15px;
            line-height: 22px;
            font-weight: 500;
            color: var(--secondary-900);
          }
        }

        p {
          color: var(--grey3);
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
        }
      }

      .payment-method-details-alignment {
        .payment-method-grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 24px 34px;

          @media only screen and (max-width: '1024px') {
            grid-template-columns: 1fr;
          }

          .payment-method-gridItem {
            background: var(--white);
            border: 1px dashed var(--grey3);
            border-radius: 10px;
            padding: 24px 32px;

            @media only screen and (max-width: '1024px') {
              padding: 16px;
            }

            .payment-card-details-alignment {
              display: flex;
              align-items: flex-end;
              justify-content: space-between;

              // margin: 17px 0 0 0;
              // @media only screen and (max-width: '1024px') {
              // }
              .payment-methid-box-geading {
                display: flex;
                align-items: center;
                gap: 16px;
                margin: 0 0 17px 0;

                @media only screen and (max-width: '1024px') {
                  margin: 0 0 16px 0;
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 17px;
                  line-height: 26px;
                  color: var(--grey5);

                  @media only screen and (max-width: '1024px') {
                    font-size: 15px;
                    line-height: 22px;
                  }
                }

                .payment-status {
                  background-color: rgba(112, 194, 23, 0.2);
                  border-radius: 8px;
                  padding: 5px 10px;

                  @media only screen and (max-width: '1024px') {
                    padding: 4px 8px;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--green-1);

                    @media only screen and (max-width: '1024px') {
                      font-size: 10px;
                      line-height: 15px;
                    }
                  }
                }
              }

              .card-details-alignment {
                display: flex;
                align-items: center;
                gap: 24px;

                @media only screen and (max-width: '1024px') {
                  gap: 12px;
                }

                .card-img-alignment {
                  width: 94px;
                  max-width: 94px;
                  height: 43px;
                  background: var(--light-grey);
                  border-radius: 8px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  @media only screen and (max-width: '1024px') {
                    width: 70px;
                    max-width: 70px;
                    min-width: 70px;
                    height: 32px;
                  }

                  img {
                    cursor: pointer;
                  }
                }

                .card-name-alignment {
                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 22px;
                    color: var(--grey5);

                    @media only screen and (max-width: '1024px') {
                      font-size: 13px;
                      line-height: 19px;
                    }
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey3);
                    display: block;

                    @media only screen and (max-width: '1024px') {
                      font-size: 10px;
                      line-height: 15px;
                    }
                  }
                }
              }

              .card-button-alignment {
                display: flex;
                align-items: center;
                gap: 12px;

                @media only screen and (max-width: '1024px') {
                  flex-direction: column;
                }

                button {
                  background: var(--light-grey);
                  border-radius: 5px;
                  width: 72px;
                  height: 30px;
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;

                  &.following {
                    background: var(--light-grey);
                    color: var(--primary-500);

                    @media only screen and (max-width: '1024px') {
                      color: var(--grey2);
                    }

                    &:hover {
                      background: var(--light-grey-3);
                    }
                  }

                  &.remove {
                    background: var(--light-grey);
                    color: #ff1818;

                    @media only screen and (max-width: '1024px') {
                      color: var(--grey2);
                    }

                    &:hover {
                      background: #fbd9d3;
                    }
                  }
                }
              }
            }
          }

          .add-new-card-alignment {
            background: rgba(168, 85, 247, 0.15);
            border: 1px dashed var(--grey3);
            border-radius: 10px;
            padding: 24px 32px;
            display: flex;
            align-items: center;

            @media only screen and (max-width: '1024px') {
              padding: 16px;
              width: 100%;
            }

            .add-new-details-alignment {
              display: flex;
              align-items: center;
              gap: 24px;

              @media only screen and (max-width: '1024px') {
                gap: 16px;
                justify-content: space-between;
                width: 100%;
              }

              .add-card-details-notes-alignment {
                @media only screen and (max-width: '1024px') {
                  order: 1;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 17px;
                  line-height: 26px;
                  color: var(--grey5);

                  @media only screen and (max-width: '1024px') {
                    font-size: 15px;
                    line-height: 22px;
                    padding: 0 0 4px 0;
                  }
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 400;
                  font-size: 15px;
                  line-height: 22px;
                  color: var(--grey2);

                  @media only screen and (max-width: '1024px') {
                    font-size: 13px;
                    line-height: 19px;
                    max-width: 240px;
                  }

                  a {
                    color: #a855f7;
                    cursor: pointer;
                  }
                }
              }

              .add-new-card-box-alignment {
                width: 56px;
                height: 56px;
                min-width: 56px;
                max-width: 56px;

                @media only screen and (max-width: '1024px') {
                  order: 2;
                  width: 40px;
                  height: 40px;
                  min-width: 40px;
                  max-width: 40px;

                  img {
                    width: 100%;
                    height: 100%;
                    display: block;
                    object-fit: contain;
                  }
                }
              }
            }
          }
        }
      }

      .payment-save-button-alignment {
        display: none;

        @media only screen and (max-width: '1024px') {
          padding: 16px 0 0 0;
          text-align: center;
          display: block;
        }

        button {
          background: var(--primary-500);
          border-radius: 10px;
          padding: 10px 20px;
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
          color: #ffffff;
          cursor: pointer;

          @media only screen and (max-width: '1024px') {
            padding: 5px 16px;
            font-size: 13px;
            line-height: 19px;
            border-radius: 6px;
          }
        }
      }
    }

    .your-transaction-section {
      background: #ffffff;
      border-radius: 20px;
      padding: 40px 60px;
      margin: 32px 0 0 0;

      .your-transaction-heading-alignment {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        .heading-name-alignment {
          h6 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 22px;
            line-height: 33px;
            color: var(--grey5);
          }

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: var(--grey3);
          }
        }

        .dropdown-alignment {
          border: 1px solid var(--grey3);
          border-radius: 10px;
          padding: 5px 20px;
          display: flex;
          align-items: center;
          gap: 10px;

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: var(--grey3);
          }

          .dropdown-arrow-alignment {
            img {
              cursor: pointer;
            }
          }
        }
      }

      .your-transaction-search-filter-section {
        display: flex;
        align-items: center;
        gap: 94px;
        margin: 40px 0 0 0;

        .search-alignment {
          height: 32px;
          width: 100%;
          position: relative;

          input {
            background: var(--light-grey);
            border-radius: 5px;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            width: 100%;
            height: 100%;
            padding: 0 0 0 65px;
            border: none;

            &::placeholder {
              color: var(--grey2);
            }
          }

          .search-icon-alignment {
            position: absolute;
            top: 7px;
            left: 32px;

            img {
              cursor: pointer;
            }
          }
        }
      }
    }

    .your-transation-table-alignment {
      margin: 12px 0 0 0;
      height: calc(100vh - 500px);
      overflow-y: auto;

      table {
        width: 100%;

        thead {
          position: sticky;
          top: 0;
          background-color: #fff;

          tr {
            th {
              padding: 12px 0;
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 13px;
              line-height: 20px;
              color: var(--grey5);
              text-align: center;

              &.right-alignment {
                text-align: right;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              color: var(--grey5);
              text-align: center;
              padding: 12px 0;

              &.right-alignment {
                text-align: right;
              }

              .card-details-alignment {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 16px;

                .img-alignment {
                  width: 33px;
                  max-width: 33px;
                  min-width: 33px;
                  height: 21px;
                  background: #ffffff;
                  border: 0.5px solid #dedede;
                  border-radius: 5px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 6px 3px;
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 400;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey5);
                }
              }
            }
          }
        }
      }
    }

    .pagination-alignment {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 44px;

      .number-alignment {
        ul {
          display: flex;
          align-items: center;
          gap: 12px;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 17px;
            line-height: 26px;
            color: var(--grey2);
            padding: 2px 12px;
            cursor: pointer;

            &:hover {
              background: var(--primary-500);
              border-radius: 50%;
              color: #ffffff;
              font-weight: 700;
            }

            &.pagination-active {
              background: var(--primary-500);
              border-radius: 50%;
              color: #ffffff;
              font-weight: 700;
            }
          }
        }
      }

      .next-page-alignment {
        display: flex;
        align-items: center;
        gap: 16px;

        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 17px;
          line-height: 26px;
          color: var(--primary-500);
        }
      }
    }

    .your-transation-table-alignment table thead tr th:nth-child(1) {
      width: 15%;
    }

    .your-transation-table-alignment table thead tr th:nth-child(2) {
      width: 35%;
    }

    .your-transation-table-alignment table thead tr th:nth-child(3) {
      width: 35%;
    }

    .your-transation-table-alignment table thead tr th:nth-child(4) {
      width: 15%;
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 20px;
  margin: 0;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: var(--primary-500);
}

input:focus+.slider {
  box-shadow: 0 0 1px #556ee6;
}

input:checked+.slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.check-box-alignment {
  @media only screen and (max-width: '1024px') {
    width: 16px;
    height: 16px;
  }

  input[type='checkbox']+label {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 15px;
    cursor: pointer;
    position: relative;
  }

  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox']+label:before {
    // content: '✔️';
    border: 1px solid rgba(85, 110, 230, 0.6);
    border-radius: 3px;
    display: flex;
    width: 24px;
    height: 24px;
    vertical-align: bottom;
    color: transparent;
    transition: 0.2s;
    font-size: 19px !important;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: '1024px') {
      width: 16px;
      height: 16px;
    }
  }

  input[type='checkbox']:checked+label:before {
    content: url('../../../../assets/icon/check-icon-down.svg');
    background: var(--primary-500);
    border: 1px solid #556ee6;
    color: #ffffff;
    border-radius: 3px;
  }

  input[type='checkbox']:checked+label:after {
    display: block;
    position: absolute;
    top: 11px;
    left: 16px;
    width: 4px;
    height: 10px;
    border: 1px solid none !important;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.radio-button-alignment {

  [type='radio']:checked,
  [type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type='radio']:checked+label,
  [type='radio']:not(:checked)+label {
    position: relative;
    padding-left: 46px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;

    @media only screen and (max-width: '1024px') {
      display: flex;
      padding-left: 30px;
      margin: 0;
      align-items: center;
      min-height: 16px;
    }
  }

  [type='radio']:checked+label:before,
  [type='radio']:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    border: 1px solid rgba(85, 110, 230, 0.6);
    border-radius: 100%;
    background: #fff;

    @media only screen and (max-width: '1024px') {
      width: 16px;
      height: 16px;
    }
  }

  [type='radio']:checked+label:after,
  [type='radio']:not(:checked)+label:after {
    content: '';
    width: 15px;
    height: 15px;
    background: var(--primary-500);
    position: absolute;
    top: 4.5px;
    left: 4.5px;
    right: 4.5px;
    bottom: 4.5px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;

    @media only screen and (max-width: '1024px') {
      width: 10px;
      height: 10px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  [type='radio']:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  [type='radio']:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.order-dashboard-section {
  .heading-alignment {
    padding: 0 0 16px 0;

    @media only screen and (max-width: '767px') {
      display: none;
    }

    h4 {
      color: var(--secondary-900);
      font-family: 'Poppins', sans-serif;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      padding: 0 0 4px 0;
    }

    p {
      color: var(--secondary-500);
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }
  }

  .order-details-overview-alignment {
    @media only screen and (max-width: '767px') {
      margin: 16px 8px;
    }

    .order-details-grid-alignment {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;

      @media only screen and (max-width: '767px') {
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;
      }

      .order-details-gridItem-alignment {
        .order-overview-box-alignment {
          border-radius: 10;
          background: #fff;
          box-shadow: 0px 5.26536px 52.65365px 0px rgba(144, 143, 143, 0.1);
          border-radius: 10px;
          padding: 16px;

          @media only screen and (max-width: '1024px') {
            padding: 16px;
            height: 100%;
          }

          .order-overview-top-details-alignment {
            display: flex;
            align-items: center;
            gap: 4px;

            .icon-alignment {
              width: 34px;
              height: 34px;

              @media only screen and (max-width: '767px') {
                width: 32px;
                height: 32px;
              }

              img {
                width: 100%;
                height: 100%;
                display: block;
              }

              svg {
                width: 100%;
                height: 100%;
              }
            }

            h6 {
              color: var(--secondary-900);
              font-family: 'Poppins', sans-serif;
              font-size: 16;
              font-weight: 600;
              line-height: 20px;

              @media only screen and (max-width: '767px') {
                font-size: 14px;
                line-height: 20px;
              }
            }
          }

          .order-overview-child-box-bottom-details {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px 24px 0 24px;

            @media only screen and (max-width: '1024px') {
              padding: 12px 0 0 0;
              justify-content: flex-start;
              gap: 16px;
            }

            h6 {
              color: var(--secondary-900);
              font-family: 'Poppins', sans-serif;
              font-size: 32px;
              font-weight: 600;
              line-height: 36px;

              @media only screen and (max-width: '767px') {
                font-size: 24px;
              }
            }

            .order-overview-child-box-bottom-right-side {
              .order-overview-total-details {
                display: flex;
                align-items: center;
                gap: 4px;

                @media only screen and (max-width: '767px') {
                  svg {
                    width: 16px;
                    height: 16px;
                  }
                }

                span {
                  color: #1d8603;
                  font-family: 'Poppins', sans-serif;
                  font-size: 13px;
                  font-weight: 500;
                  line-height: 20px;

                  @media only screen and (max-width: '767px') {
                    font-size: 10px;
                  }
                }
              }

              p {
                color: var(--secondary-500);
                font-family: 'Poppins', sans-serif;
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;

                @media only screen and (max-width: '767px') {
                  font-size: 10px;
                }
              }
            }
          }

          .overview-details-alignment {
            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 15px;
              line-height: 22px;
              color: var(--grey2);
              padding: 0 0 3px 0;
            }

            h6 {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 32px;
              line-height: 48px;
              color: var(--grey5);
            }
          }
        }
      }
    }

    .mobile-view-order-analytics-section {
      display: none;

      @media only screen and (max-width: '767px') {
        display: block;
      }

      .mobile-view-box-alignment {
        background: #fff;
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 18px;

        .anylasic-details-alignment {
          text-align: center;
          width: 90px;

          .mobile-icon-alignment {
            margin: auto;
            width: 24px;
            max-width: 24px;
            height: 24px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          p {
            padding: 2px 0 0 0;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 10px;
            line-height: 15px;
            color: var(--grey2);
          }

          span {
            padding: 2px 0 0 0;
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 17px;
            line-height: 26px;
            color: var(--secondary-900);
          }
        }
      }
    }
  }

  .main-order-details-alignment {
    margin: 32px 0 0 0;

    .order-details-box-alignment {
      @media only screen and (max-width: '767px') {
        border-radius: 0;
        padding: 0;
        border-radius: 10px;
        background: #fff;
      }

      .filter-export-details-alignment {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @media only screen and (max-width: '767px') {
          padding: 8px 16px 0 16px;
        }

        .filter-export-alignment {
          display: flex;
          align-items: center;
          gap: 24px;

          @media only screen and (max-width: '767px') {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 8px;
          }

          .filter-box-alignment {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            border: 0.526px solid var(--grey4);
            background: #fff;
            border-radius: 5px;
            height: 32px;
            padding: 0 8px;
            cursor: pointer;

            p {
              color: var(--primary-500);
              font-family: 'Poppins', sans-serif;
              font-size: 15px;
              font-weight: 400;
              line-height: 24px;
            }
          }
        }
      }

      h6 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 22px;
        line-height: 33px;
        color: var(--secondary-900);

        @media only screen and (max-width: '767px') {
          display: none;
        }
      }

      .order-flex-alignment {
        @media only screen and (max-width: '767px') {
          display: flex;
          flex-direction: column;
        }
      }

      .notes-empty-order-alignment {
        @media only screen and (max-width: '767px') {
          order: 2;
        }
      }

      .mobile-view-emptybox {
        display: none;

        @media only screen and (max-width: '767px') {
          display: block;
        }
      }

      .order-notes-box-alignment {
        background: rgba(168, 85, 247, 0.1);
        border-radius: 10px;
        padding: 16px 32px;
        margin: 40px 0 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 58px;

        @media only screen and (max-width: '767px') {
          display: none;
        }

        .order-notes-main-content-alignment {
          display: flex;
          align-items: center;
          gap: 16px;

          .icon-box-alignment {
            width: 50px;
            height: 50px;

            @media only screen and (max-width: '767px') {
              width: 32px;
              max-width: 32px;
              min-width: 32px;
              height: 32px;
            }

            img {
              width: 100%;
              height: 100%;
            }
          }

          .order-box-details-alignment {
            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 15px;
              line-height: 22px;
              color: var(--secondary-900);
            }

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              color: var(--grey2);
            }
          }
        }
      }

      .mobile-view-order-notes-alignment {
        background: rgba(168, 85, 247, 0.1);
        margin: 0 16px 16px 16px;
        padding: 16px;
        display: none;
        border-radius: 10px;

        @media only screen and (max-width: '767px') {
          display: none;
        }

        .order-notes-heading-alignment {
          display: flex;
          align-items: center;
          gap: 8px;

          .icon-box-alignment {
            width: 32px;
            max-width: 32px;
            min-width: 32px;
            height: 32px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .heading-close-alignment {
            display: flex;
            align-items: flex-start;

            h5 {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 13px;
              line-height: 20px;
              color: var(--secondary-900);
            }
          }
        }

        p {
          padding: 4px 0 0 0;
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 10px;
          line-height: 15px;
          color: var(--grey2);
        }
      }

      .main-order-box-section {
        margin: 16px 0 0 0;

        @media only screen and (max-width: '767px') {
          order: 1;
          margin: 0;
        }

        .order-post-alignment {
          padding: 24px 30px;
          border-radius: 10px;
          background: #fff;

          @media only screen and (max-width: '767px') {
            padding: 0;
          }

          .order-post-tab-alignment {
            ul {
              display: flex;
              align-items: center;
              gap: 64px;
              border-bottom: 1px solid #eaecef;
              padding: 0 0 5px;

              @media only screen and (max-width: '767px') {
                gap: 23px;
                padding: 24px 16px 8px 16px;
                display: none;
              }

              &.mobile-view-tab {
                display: none;

                @media only screen and (max-width: '767px') {
                  display: flex;
                }
              }

              li {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 15px;
                line-height: 22px;
                color: var(--grey2);
                cursor: pointer;
                position: relative;

                @media only screen and (max-width: '767px') {
                  font-size: 14px;
                  line-height: 18px;
                }

                &:hover {
                  color: var(--grey5);
                  font-weight: 600;

                  @media only screen and (max-width: '767px') {
                    color: var(--secondary-900);
                  }

                  &::before {
                    position: absolute;
                    content: '';
                    height: 3px;
                    width: 100%;
                    background: var(--primary-500);
                    bottom: -6px;
                    right: 0;
                    border-radius: 8px;

                    @media only screen and (max-width: '767px') {
                      bottom: -8px;
                    }
                  }
                }

                &.order-tab-active {
                  color: var(--grey5);
                  font-weight: 600;

                  @media only screen and (max-width: '767px') {
                    color: var(--secondary-900);
                  }

                  &::before {
                    position: absolute;
                    content: '';
                    height: 3px;
                    width: 100%;
                    background: var(--primary-500);
                    bottom: -6px;
                    right: 0;
                    border-radius: 8px;

                    @media only screen and (max-width: '767px') {
                      bottom: -8px;
                    }
                  }
                }
              }
            }
          }

          // .order-search-filter-section {
          //   display: flex;
          //   align-items: center;
          //   gap: 94px;
          //   margin: 24px 0 0 0;

          //   @media only screen and (max-width: '767px') {
          //     display: none;
          //   }

          //   .search-alignment {
          //     height: 32px;
          //     width: 100%;
          //     position: relative;

          //     input {
          //       background: var(--light-grey);
          //       border-radius: 5px;
          //       font-family: 'Poppins', sans-serif;
          //       font-weight: 400;
          //       font-size: 13px;
          //       line-height: 20px;
          //       width: 100%;
          //       height: 100%;
          //       padding: 0 0 0 65px;
          //       border: none;

          //       &::placeholder {
          //         color: va(  --grey2);
          //       }
          //     }

          //     .search-icon-alignment {
          //       position: absolute;
          //       top: 7px;
          //       left: 32px;

          //       img {
          //         cursor: pointer;
          //       }
          //     }
          //   }

          //   .filter-export-alignment {
          //     display: flex;
          //     align-items: center;
          //     gap: 26px;

          //     .filter-box-alignment {
          //       display: flex;
          //       align-items: center;
          //       justify-content: center;
          //       gap: 10px;
          //       border: 0.5px solid var(  --grey4);
          //       border-radius: 5px;
          //       height: 32px;
          //       width: 84px;
          //       min-width: 84px;
          //       padding: 0 8px;
          //       cursor: pointer;

          //       p {
          //         font-family: 'Poppins', sans-serif;
          //         font-weight: 400;
          //         font-size: 15px;
          //         line-height: 22px;
          //         color: var(--primary-500);
          //       }
          //     }
          //   }
          // }

          // .order-table-alignment {
          //   margin: 24px 0 0 0;
          //   height: auto;
          //   max-height: 570px;
          //   overflow-y: auto;

          //   @media only screen and (max-width: '767px') {
          //     display: none;
          //   }

          //   table {
          //     width: 100%;

          //     thead {
          //       position: sticky;
          //       top: 0;
          //       z-index: 99;

          //       tr {
          //         background: #fff;

          //         th {
          //           font-family: 'Poppins', sans-serif;
          //           font-weight: 500;
          //           font-size: 13px;
          //           line-height: 20px;
          //           text-align: center;
          //           color: var(  --grey5);
          //           padding: 0 0 12px 0;
          //         }
          //       }
          //     }

          //     tbody {
          //       tr {
          //         td {
          //           padding: 12px 26px;
          //           font-family: 'Poppins', sans-serif;
          //           font-weight: 500;
          //           font-size: 13px;
          //           line-height: 20px;
          //           text-align: center;
          //           color: var(  --grey5);

          //           &.id-alignment {
          //             color: #a855f7;
          //           }

          //           &.address-alignment {
          //             color: va(  --grey2);
          //           }

          //           .flex-alignment {
          //             display: flex;
          //             align-items: center;
          //             justify-content: center;
          //             gap: 24px;

          //             span {
          //               font-family: 'Poppins', sans-serif;
          //               font-weight: 500;
          //               font-size: 13px;
          //               line-height: 20px;
          //               text-align: center;
          //               color: var(  --grey5);
          //             }
          //           }

          //           .status-alignment {
          //             background-color: rgba(112, 194, 23, 0.2);
          //             border-radius: 5px;
          //             padding: 4px 8px;
          //             display: flex;
          //             align-items: center;
          //             justify-content: center;
          //             gap: 5px;
          //             width: 86px;
          //             margin: auto;

          //             &.hold-on-status {
          //               background-color: rgba(255, 148, 73, 0.2);

          //               p {
          //                 background: #ff7d23;
          //               }

          //               span {
          //                 color: #ff7d23;
          //               }
          //             }

          //             &.cancle-status {
          //               background-color: rgba(194, 23, 23, 0.2);

          //               p {
          //                 background: #c21717;
          //               }

          //               span {
          //                 color: #c21717;
          //               }
          //             }

          //             p {
          //               width: 6px;
          //               height: 6px;
          //               border-radius: 50%;
          //               background: #70c217;
          //             }

          //             span {
          //               font-family: 'Poppins', sans-serif;
          //               font-weight: 500;
          //               font-size: 12px;
          //               line-height: 18px;
          //               color: #70c217;
          //             }
          //           }
          //         }
          //       }
          //     }
          //   }
          // }

          // .order-table-alignment table thead tr th:nth-child(1) {
          //   width: 5%;
          // }

          // .order-table-alignment table thead tr th:nth-child(2) {
          //   width: 30%;
          // }

          // .order-table-alignment table thead tr th:nth-child(3) {
          //   width: 15%;
          // }

          // .order-table-alignment table thead tr th:nth-child(4) {
          //   width: 25%;
          // }

          // .order-table-alignment table thead tr th:nth-child(5) {
          //   width: 15%;
          // }

          // .order-table-alignment table thead tr th:nth-child(5) {
          //   width: 10%;
          // }

          .mobile-order-view-detaiks-alignment {
            display: none;

            @media only screen and (max-width: '767px') {
              display: block;
            }

            .mobile-name-with-scroll-alignment {
              width: 100%;
              overflow-x: auto;
              display: flex;
              align-items: center;
              gap: 16px;
              border-bottom: 1px solid rgba(228, 228, 232, 0.7);
              padding: 8px 0;

              .mobile-view-flex-alignment {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 16px;
                min-width: 100%;

                .mobile-view-product-details {
                  display: flex;
                  align-items: center;
                  gap: 24px;

                  .produtimg {
                    width: 59px;
                    height: 59px;
                    max-width: 59px;
                    min-width: 59px;

                    img {
                      width: 100%;
                      height: 100%;
                      border-radius: 8px;
                    }
                  }

                  .product-details-alignment {
                    p {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 400;
                      font-size: 10px;
                      line-height: 15px;
                      color: #a855f7;

                      &.web-view {
                        @media only screen and (max-width: '767px') {
                          display: none;
                        }
                      }

                      &.mobile-view {
                        display: none;

                        @media only screen and (max-width: '767px') {
                          display: block;
                        }
                      }
                    }

                    h5 {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey5);
                      padding: 2px 0 0 0;

                      @media only screen and (max-width: '767px') {
                        max-width: 110px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                      }
                    }

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 400;
                      font-size: 10px;
                      line-height: 15px;
                      color: var(--grey5);
                      padding: 2px 0 0 0;
                    }
                  }
                }

                .status-other-alignment {
                  display: flex;
                  align-items: flex-start;
                  gap: 16px;

                  .mobile-status-alignment {
                    background-color: rgba(112, 194, 23, 0.2);
                    border-radius: 5px;
                    padding: 4px 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;
                    width: 86px;
                    margin: auto;

                    &.hold-on-status {
                      background-color: rgba(255, 148, 73, 0.2);

                      p {
                        background: #ff7d23;
                      }

                      span {
                        color: #ff7d23;
                      }
                    }

                    &.cancle-status {
                      background-color: rgba(194, 23, 23, 0.2);

                      p {
                        background: #c21717;
                      }

                      span {
                        color: #c21717;
                      }
                    }

                    p {
                      width: 6px;
                      height: 6px;
                      border-radius: 50%;
                      background: #70c217;
                    }

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 12px;
                      line-height: 18px;
                      color: #70c217;
                    }
                  }

                  p {
                    padding: 10px 0 0 0;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 22px;
                    text-align: center;
                    color: var(--grey5);
                  }
                }
              }

              .option-details-box-alignment {
                display: flex;
                align-items: center;

                .option-box {
                  width: 91px;
                  height: 91px;
                  background: #70c217;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                  gap: 12px;

                  &.cancle-box {
                    background: #ff7d23;
                  }

                  &.delete-box {
                    background: #c21717;
                  }

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 20px;
                    color: #ffffff;
                  }
                }
              }
            }
          }

          .pagination-alignment {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 44px;
            padding: 50px 0 0 0;

            @media only screen and (max-width: '767px') {
              display: none;
            }

            .number-alignment {
              ul {
                display: flex;
                align-items: center;
                gap: 12px;

                li {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 17px;
                  line-height: 26px;
                  color: var(--grey2);
                  padding: 2px 12px;
                  cursor: pointer;

                  &:hover {
                    background: var(--primary-500);
                    border-radius: 50%;
                    color: #ffffff;
                    font-weight: 700;
                  }

                  &.pagination-active {
                    background: var(--primary-500);
                    border-radius: 50%;
                    color: #ffffff;
                    font-weight: 700;
                  }
                }
              }
            }

            .next-page-alignment {
              display: flex;
              align-items: center;
              gap: 16px;

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 17px;
                line-height: 26px;
                color: var(--primary-500);
              }
            }
          }
        }
      }
    }
  }
}

.gift-card-details-section {
  margin: 64px 0 0 0;
  background: #fff;
  padding: 40px 76px;
  border-radius: 20px;

  @media only screen and (max-width: '767px') {
    display: none;
  }

  // .order-search-filter-section {
  //   display: flex;
  //   align-items: center;
  //   gap: 94px;
  //   // margin: 24px 0 0 0;

  //   @media only screen and (max-width: '767px') {
  //     display: none;
  //   }

  //   .search-alignment {
  //     height: 32px;
  //     width: 100%;
  //     position: relative;

  //     input {
  //       background: var(--light-grey);
  //       border-radius: 5px;
  //       font-family: 'Poppins', sans-serif;
  //       font-weight: 400;
  //       font-size: 13px;
  //       line-height: 20px;
  //       width: 100%;
  //       height: 100%;
  //       padding: 0 0 0 65px;
  //       border: none;

  //       &::placeholder {
  //         color: va(  --grey2);
  //       }
  //     }

  //     .search-icon-alignment {
  //       position: absolute;
  //       top: 7px;
  //       left: 32px;

  //       img {
  //         cursor: pointer;
  //       }
  //     }
  //   }

  //   .filter-export-alignment {
  //     display: flex;
  //     align-items: center;
  //     gap: 26px;

  //     .filter-box-alignment {
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       gap: 10px;
  //       border: 0.5px solid var(  --grey4);
  //       border-radius: 5px;
  //       height: 32px;
  //       width: 84px;
  //       min-width: 84px;
  //       padding: 0 8px;
  //       cursor: pointer;

  //       p {
  //         font-family: 'Poppins', sans-serif;
  //         font-weight: 400;
  //         font-size: 15px;
  //         line-height: 22px;
  //         color: var(--primary-500);
  //       }
  //     }
  //   }
  // }

  // .order-table-alignment {
  //   margin: 24px 0 0 0;
  //   height: auto;
  //   max-height: 570px;
  //   overflow-y: auto;

  //   @media only screen and (max-width: '767px') {
  //     display: none;
  //   }

  //   table {
  //     width: 100%;

  //     thead {
  //       position: sticky;
  //       top: 0;
  //       z-index: 99;

  //       tr {
  //         background: #fff;

  //         th {
  //           font-family: 'Poppins', sans-serif;
  //           font-weight: 500;
  //           font-size: 13px;
  //           line-height: 20px;
  //           text-align: center;
  //           color: var(  --grey5);
  //           padding: 0 0 12px 0;
  //         }
  //       }
  //     }

  //     tbody {
  //       tr {
  //         td {
  //           padding: 12px 26px;
  //           font-family: 'Poppins', sans-serif;
  //           font-weight: 500;
  //           font-size: 13px;
  //           line-height: 20px;
  //           text-align: center;
  //           color: var(  --grey5);

  //           &.id-alignment {
  //             color: #a855f7;
  //           }

  //           &.address-alignment {
  //             color: va(  --grey2);
  //           }

  //           .flex-alignment {
  //             display: flex;
  //             align-items: center;
  //             justify-content: center;
  //             gap: 24px;

  //             span {
  //               font-family: 'Poppins', sans-serif;
  //               font-weight: 500;
  //               font-size: 13px;
  //               line-height: 20px;
  //               text-align: center;
  //               color: var(  --grey5);
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  .pagination-alignment {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 44px;

    .number-alignment {
      ul {
        display: flex;
        align-items: center;
        gap: 12px;

        li {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 17px;
          line-height: 26px;
          color: var(--grey2);
          padding: 2px 12px;
          cursor: pointer;

          &:hover {
            background: var(--primary-500);
            border-radius: 50%;
            color: #ffffff;
            font-weight: 700;
          }

          &.pagination-active {
            background: var(--primary-500);
            border-radius: 50%;
            color: #ffffff;
            font-weight: 700;
          }
        }
      }
    }

    .next-page-alignment {
      display: flex;
      align-items: center;
      gap: 16px;

      span {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 17px;
        line-height: 26px;
        color: var(--primary-500);
      }
    }
  }
}

.book-category-section {

  // width: 100%;
  .book-main-banner-section {
    .book-banner-img-alignment {
      width: 100%;
      height: 674px;
      position: relative;

      @media only screen and (max-width: '767px') {
        height: 239px;
      }

      .banner-heading-alignment {
        max-width: 877px;
        position: absolute;
        top: 100px;
        right: 0;
        left: 0;
        margin: auto;

        @media only screen and (max-width: '767px') {
          max-width: 100%;
          top: 26px;
          padding: 0 23px 0 16px;
        }

        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 40px;
          line-height: 60px;
          text-align: center;
          color: var(--secondary-900);

          @media only screen and (max-width: '767px') {
            font-size: 18px;
            line-height: 27px;
          }
        }

        p {
          padding: 8px 0 0 0;
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: var(--secondary-900);

          @media only screen and (max-width: '767px') {
            font-size: 12px;
            line-height: 18px;
          }
        }
      }

      &::before {
        content: '';
        position: absolute;
        background: linear-gradient(180deg, rgba(248, 249, 251, 0.09) -0.07%, var(--secondary-50) 100.07%);
        width: 100%;
        height: 431px;
        bottom: 0;
        right: 0;

        @media only screen and (max-width: '767px') {
          height: 239px;
        }
      }

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
      }
    }

    .now-trending-box-details-alignment {
      margin: -232px 0 32px 0;
      position: relative;

      @media only screen and (max-width: '767px') {
        margin: -72px 0 32px 0;
      }

      .container {
        @media only screen and (max-width: '767px') {
          max-width: 100%;
        }
      }

      .now-trending-box-aligment {
        background: rgba(255, 255, 255, 0.4);
        border: 1px solid #ffffff;
        box-shadow: 0px 35px 48px rgba(85, 110, 230, 0.1);
        backdrop-filter: blur(32px);
        border-radius: 32px;
        height: 694px;
        padding: 22px;

        @media only screen and (max-width: '767px') {
          height: 625px;
        }

        h1 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 140.172px;
          line-height: 210px;
          background: linear-gradient(180deg, #1e2432 23.31%, rgba(30, 36, 50, 0) 110.81%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          text-align: center;
          opacity: 0.3;

          @media only screen and (max-width: '767px') {
            font-size: 24px;
            line-height: 36px;
          }
        }
      }

      .mobile-view-see-all-trending-alignment {
        padding: 16px 0 0 0;
        text-align: center;

        display: none;

        @media only screen and (max-width: '767px') {
          display: block;
        }

        a {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          color: var(--primary-500);
        }
      }

      .now-trending-book-slider-alignment {
        position: absolute;
        top: 148px;
        width: 100%;
        right: 0;

        @media only screen and (max-width: '767px') {
          top: 72px;
        }

        // .now-trending-book-image-alignment {
        //   width: 215px !important;
        //   height: 330px;
        //   display: flex;
        //   align-items: center;

        //   img {
        //     width: 100%;
        //     height: 100%;
        //   }
        // }

        .now-trending-book-image-alignment {
          // width: 215px !important;
          height: 329px;
          display: flex !important;
          align-items: center;
          padding: 0 20px;
          position: absolute;
          top: 82px;

          @media only screen and (max-width: '767px') {
            display: block !important;
            height: 100%;
          }

          .center-img-alignment {
            // width: 100%;
            height: 100%;
            cursor: pointer;

            &:hover {
              transform: scale(0.95);
              transition: 0.3s ease-in-out;
            }

            img {
              width: 100%;
              height: 100%;
            }
          }

          .now-trending-img-details-alignment {
            background: #ffffff;
            width: 320px;
            height: 100%;
            text-align: center;
            padding: 67px 92px 0 92px;
            display: none;
            transition: 0.3s ease-in-out;

            @media only screen and (max-width: '767px') {
              background: transparent;
              width: 219px;
              padding: 16px;
            }

            h4 {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 32px;
              line-height: 48px;
              color: var(--secondary-900);
              padding: 0 0 4px 0;

              @media only screen and (max-width: '767px') {
                font-size: 20px;
                line-height: 30px;
              }
            }

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              color: var(--grey3);
              padding: 0 0 4px 0;
              display: block;

              @media only screen and (max-width: '767px') {
                font-size: 12px;
                line-height: 18px;
              }
            }

            a {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 13px;
              line-height: 20px;
              color: var(--primary-500);
              cursor: pointer;

              @media only screen and (max-width: '767px') {
                padding: 2px 0 0 0;
                font-size: 12px;
                line-height: 18px;
              }
            }

            .now-trending-book-rate-alignment {
              display: flex;
              align-items: center;
              padding: 16px 0 0 0;
              justify-content: center;
              gap: 4px;

              .now-trending-hike {
                display: flex;
                align-items: center;
                padding: 0;

                .now-trending-inter {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 13px;
                  line-height: 20px;
                  color: #70c217;
                  padding: 0;

                  img {
                    margin-right: 3px;
                  }
                }

                .now-trending-incris {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 13px;
                  line-height: 20px;
                  color: #70c217;
                  display: flex;
                  align-items: center;
                  padding: 0;

                  img {
                    margin-right: 3px;
                  }
                }
              }

              .now-trending-rating {
                display: flex;
                align-items: center;
                padding: 0;

                .now-trending-rating_point {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  padding: 0;
                }

                .now-trending-stars {
                  display: flex;
                  align-items: center;
                  margin: 0 2px;
                  padding: 0;
                }

                .now-trending-num {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  padding: 0;
                }
              }
            }

            .now-trending-book-pricing-alignment {
              padding: 48px 0 0 0;

              @media only screen and (max-width: '767px') {
                display: none;
              }

              h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 18px;
                line-height: 27px;
                color: #000000;
              }

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: var(--grey3);
              }
            }

            .mobile-add-to-button-responsive {
              display: none;

              @media only screen and (max-width: '767px') {
                display: flex;
                justify-content: center;
                text-align: center;
                padding: 16px 0 0 0;

                button {
                  width: 117px;
                  height: 32px;
                  background: var(--primary-500);
                  border-radius: 8px;
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 21px;
                  color: var(--light-grey);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                }
              }
            }

            .buy-now-see-details-alignment {
              padding: 55px 0 0 0;

              @media only screen and (max-width: '767px') {
                display: none;
              }

              .buy-now-button-alignment {
                display: flex;
                align-items: center;
                justify-content: center;

                button {
                  width: 117px;
                  height: 32px;
                  background: var(--primary-500);
                  border-radius: 8px;
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 21px;
                  color: var(--light-grey);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                }
              }

              .see-all-alignment {
                padding: 17px 0 0 0;

                a {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 21px;
                  color: var(--primary-500);
                  cursor: pointer;
                }
              }
            }
          }
        }

        .slick-arrow {
          display: none !important;
        }

        .slick-center {
          .now-trending-book-image-alignment {
            // width: 640px !important;
            height: 490px;
            // width: 340px !important;
            position: relative;
            right: 166px;
            top: 0 !important;

            .center-img-alignment {
              width: 320px !important;
              min-width: 320px !important;
              height: 490px !important;

              &:hover {
                transform: none;
                transition: 0.3s ease-in-out;
              }
            }

            .now-trending-img-details-alignment {
              display: block;
            }
          }
        }
      }
    }
  }

  .special-offer-alignment {
    .special-offer-bg {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      padding: 48px 68px;
      width: 100%;

      @media only screen and (max-width: '767px') {
        background: #f4f5f8;
        border: 1px solid #ffffff;
        backdrop-filter: blur(28.6539px);
        padding: 16px;
      }

      .special-offer-heading-alignment {
        text-align: center;

        @media only screen and (max-width: '767px') {
          display: none;
        }

        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 32px;
          line-height: 48px;
          color: var(--secondary-900);

          @media only screen and (max-width: '767px') {
            font-size: 20px;
            line-height: 30px;
          }
        }

        a {
          padding: 3px 0 0 0;
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: var(--primary-500);
          cursor: pointer;

          @media only screen and (max-width: '767px') {
            font-size: 14px;
            line-height: 21px;
            padding: 0;
          }
        }
      }

      .special-offer-heading-alignment-mobile {
        text-align: center;

        @media only screen and (min-width: '767px') {
          display: none;
          align-items: center;
          justify-content: space-between;
        }

        @media only screen and (max-width: '767px') {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 32px;
          line-height: 48px;
          color: var(--secondary-900);

          @media only screen and (max-width: '767px') {
            font-size: 20px;
            line-height: 30px;
          }
        }

        a {
          padding: 3px 0 0 0;
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: var(--primary-500);
          cursor: pointer;

          @media only screen and (max-width: '767px') {
            font-size: 14px;
            line-height: 21px;
            padding: 0;
          }
        }
      }

      .special-offer-book-details-alignment {
        padding: 38px 0 0 0;

        @media only screen and (max-width: '767px') {
          padding: 18px 0 0 0;
        }

        .special-offer-book-grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 63px 24px;

          @media only screen and (max-width: '1199px') {
            grid-template-columns: repeat(2, 1fr);
          }

          @media only screen and (max-width: '899px') {
            grid-template-columns: repeat(1, 1fr);
          }

          @media only screen and (max-width: '767px') {
            display: none;
          }

          .special-offer-book-gridItem {
            background: #ffffff;
            border-radius: 10px;
            height: 193px;
            padding: 0 0 0 22px;
            transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);

            &:hover {
              transform: translateY(-6px);
              border-radius: 10px;
              box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
              padding: 0 0 0 16px;

              .special-offer-book-details {
                gap: 14px;
                transition: 0.8s ease-in-out;

                .book-cover-img {
                  width: 160px;
                  max-width: 160px;
                  min-width: 160px;
                  height: 240px;
                }
              }
            }

            .special-offer-book-details {
              display: flex;
              align-items: center;
              gap: 16px;
              transition: 0.8s ease-in-out;

              .book-cover-img {
                width: 146px;
                max-width: 146px;
                min-width: 146px;
                height: 224px;
                margin: -20px 0 0 0;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .book-name-details-alignement {
                width: 100%;

                h5 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 24px;
                  line-height: 36px;
                  color: var(--secondary-900);
                  cursor: pointer;
                }

                p {
                  padding: 8px 0 0 0;
                  font-family: 'Poppins', sans-serif;
                  font-weight: 400;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                }

                .price-alignment-flex {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  .price-alignment {
                    h6 {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 24px;
                      line-height: 36px;
                      color: #474400;
                    }

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 20px;
                      line-height: 30px;
                      color: var(--grey2);
                      display: block;
                      margin: -6px 0 0 0;
                      text-align: right;

                      del {
                        color: #ca0000;
                      }
                    }
                  }

                  .offer-label-alignment {
                    background: #f1efb0;
                    padding: 9px 17px 7px 17px;

                    p {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 20px;
                      line-height: 30px;
                      text-align: center;
                      color: #474400;
                      padding: 0;
                    }
                  }
                }
              }
            }
          }
        }

        .mobile-view-special-offer-alignment {
          padding: 16px 0 0 0;
          display: none;

          @media only screen and (max-width: '767px') {
            display: block;
            height: 260px;
          }

          .mobile-slider-alifnment {
            padding: 0 4px;
            height: 100%;

            .mobile-special-offer-book-details {
              display: flex !important;
              align-items: center;
              gap: 14px;
              transition: 0.8s ease-in-out;
              background: #ffffff;
              border-radius: 16px;
              padding: 12px 16px;
              height: 209px;
              gap: 8px;

              .mobile-book-cover-img {
                width: 164px;
                max-width: 164px;
                min-width: 164px;
                height: 251px;
                margin: -20px 0 0 0;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .mobile-book-name-details-alignement {
                width: 100%;

                h5 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                  cursor: pointer;
                  max-width: 131px;
                  white-space: nowrap;
                  overflow: hidden;
                  -webkit-line-clamp: 2;
                  text-overflow: ellipsis;
                }

                p {
                  padding: 16px 0 0 0;
                  font-family: 'Poppins', sans-serif;
                  font-weight: 400;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                }

                .mobile-price-alignment-flex {
                  display: block;

                  .mobile-price-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    h6 {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 16px;
                      line-height: 24px;
                      color: #000000;
                    }

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);

                      del {
                        color: var(--grey5);
                      }
                    }
                  }

                  .mobile-offer-label-alignment {
                    background: #f1efb0;
                    padding: 4px 8px;
                    width: fit-content;
                    margin: 5px 0 0 0;

                    p {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 20px;
                      line-height: 30px;
                      text-align: center;
                      color: #474400;
                      padding: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .book-other-section-alignment {
    margin: 32px auto;
    display: flex;
    gap: 18px;
    width: 70%;

    @media only screen and (max-width: '1499px') {
      width: auto;
      margin: 32px 84px;
    }

    @media only screen and (max-width: '1199px') {
      margin: 30px;
    }

    @media only screen and (max-width: '899px') {
      flex-wrap: wrap;
    }

    @media only screen and (max-width: '767px') {
      margin: 16px 0 0 0;
      flex-direction: column-reverse;
    }

    .book-other-option-first-part-alignment {
      width: 60%;

      @media only screen and (max-width: '899px') {
        width: 100%;
      }

      @media only screen and (max-width: '767px') {
        width: 100%;
      }

      .book-category-filter-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media only screen and (max-width: '1199px') {
          flex-wrap: wrap;
          gap: 20px;
        }

        @media only screen and (max-width: '767px') {
          display: none;
        }

        .filter-seacch-alignment {
          display: flex;
          align-items: center;
          gap: 8px;

          .filter-alignment {
            padding: 12px 8px 12px 17px;
            display: flex;
            align-items: center;
            gap: 4px;
            background: #ffffff;
            border: 0.5px solid var(--grey4);
            border-radius: 10px;

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              color: var(--grey5);
            }
          }

          .seacrh-alignment {
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;
            width: 291px;
            height: 40px;

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }
        }

        .category-alignment {
          display: flex;
          align-items: center;
          gap: 8px;

          label {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: var(--secondary-900);
          }

          .category-dropdown-alignment {
            width: 200px;
            height: 40px;
            background: #ffffff;
            border: 1px solid var(--grey4);
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px;

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              color: var(--grey5);
            }

            img {
              cursor: pointer;
            }
          }
        }
      }

      .new-realese-section-alignment {
        margin: 24px 0 0 0;
        background: rgba(255, 255, 255, 0.6);
        border: 1px solid #ffffff;
        backdrop-filter: blur(32px);
        border-radius: 16px;
        padding: 16px 32px;

        @media only screen and (max-width: '767px') {
          border-radius: 0;
          padding: 0;
          background: #f4f5f8;
          backdrop-filter: blur(28.6539px);
        }

        .new-realese-header-alignment {
          display: flex;
          justify-content: space-between;
          padding: 0 0 24px 0;

          @media only screen and (max-width: '767px') {
            display: none;
          }

          .heading-label-alignment {
            background: linear-gradient(94.09deg, #556ee6 0.21%, #6b21a8 100%);
            box-shadow: 1px 3px 3px rgba(95, 64, 175, 0.08);
            border-radius: 0px 0px 8px 0px;
            padding: 4px 16px;
            margin: -25px 0 0 0;

            @media only screen and (max-width: '767px') {
              margin: -8px 0 0 0;
            }

            &.best-seller {
              background: linear-gradient(263.94deg, #ffc554 7.44%, #744c00 86.5%);
            }

            &.bookOfTheMonth {
              background: #1e2432;
            }

            &.topRated {
              background: #db5a00;
            }

            &.mostLiked {
              background: #940000;
            }

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 24px;
              line-height: 36px;
              color: var(--light-grey);
              text-transform: uppercase;

              @media only screen and (max-width: '767px') {
                font-size: 20px;
                line-height: 36px;
                text-transform: capitalize;
              }
            }
          }

          .see-all-alignment {
            a {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              letter-spacing: 0.1px;
              color: var(--primary-500);
              cursor: pointer;
            }

            @media only screen and (max-width: '767px') {
              padding: 16px 16px 0 0;
            }
          }
        }

        .new-realese-header-alignment-mobile {
          display: flex;
          justify-content: space-between;
          padding: 0 0 24px 0;

          @media only screen and (min-width: '767px') {
            display: none;
          }

          .heading-label-alignment {
            background: linear-gradient(94.09deg, #556ee6 0.21%, #6b21a8 100%);
            box-shadow: 1px 3px 3px rgba(95, 64, 175, 0.08);
            border-radius: 0px 0px 8px 0px;
            padding: 4px 16px;
            margin: -25px 0 0 0;

            @media only screen and (max-width: '767px') {
              margin: -8px 0 0 0;
            }

            &.best-seller {
              background: linear-gradient(263.94deg, #ffc554 7.44%, #744c00 86.5%);
            }

            &.bookOfTheMonth {
              background: #1e2432;
            }

            &.topRated {
              background: #db5a00;
            }

            &.mostLiked {
              background: #940000;
            }

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 24px;
              line-height: 36px;
              color: var(--light-grey);
              text-transform: uppercase;

              @media only screen and (max-width: '767px') {
                font-size: 20px;
                line-height: 36px;
                text-transform: capitalize;
              }
            }
          }

          .see-all-alignment {
            a {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              letter-spacing: 0.1px;
              color: var(--primary-500);
              cursor: pointer;
            }

            @media only screen and (max-width: '767px') {
              padding: 16px 16px 0 0;
            }
          }
        }

        .new-realese-book-alignment {
          padding: 16px;

          @media only screen and (max-width: '767px') {
            padding: 8px 3px;
          }

          &:hover {
            transform: translateY(-5px);
            background: rgba(85, 110, 230, 0.1);
            border-radius: 10px;

            .book-img-alignment {
              box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
            }
          }

          &.book-active-details {
            transform: translateY(-5px);
            background: rgba(85, 110, 230, 0.1);
            border-radius: 10px;

            .book-img-alignment {
              box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
            }
          }

          .book-img-alignment {
            height: 224px;

            img {
              width: 100%;
              height: 100%;
              display: block;
            }
          }

          .release-book-name-alignment {
            text-align: center;
            padding: 16px 0 0 0;
            margin: 0 0 24px 0;

            &:last-child {
              margin: 0;
            }

            h6 {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: var(--secondary-900);
            }

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 13px;
              line-height: 20px;
              color: var(--grey2);
              text-align: center;
            }

            .book-rate-alignment {
              display: flex;
              align-items: center;
              padding: 8px 0 0 0;
              justify-content: center;
              gap: 4px;

              .hike {
                display: flex;
                align-items: center;

                .inter {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 13px;
                  line-height: 20px;
                  color: #70c217;

                  img {
                    margin-right: 3px;
                  }
                }

                .incris {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 13px;
                  line-height: 20px;
                  color: #70c217;
                  display: flex;
                  align-items: center;

                  img {
                    margin-right: 3px;
                  }
                }
              }

              .rating {
                display: flex;
                align-items: center;

                @media only screen and (max-width: 799px) {
                  display: none;
                }

                .rating_point {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                }

                .stars {
                  display: flex;
                  align-items: center;
                  margin: 0 2px;
                }

                .num {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                }
              }
            }

            .book-price-alignment {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 5px;
              padding: 11px 0 0 0;

              h5 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #000000;
              }

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: var(--grey5);
              }
            }

            .like-share-alignment {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 16px;

              .like-alignment {
                display: flex;
                align-items: center;
                gap: 2px;

                span {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                }
              }
            }
          }

          .book-of-the-month-release-book-name-alignment {
            text-align: center;
            padding: 16px 0 0 0;
            margin: 0 0 24px 0;

            display: none;

            @media only screen and (max-width: '767px') {
              display: block;
            }

            &:last-child {
              margin: 0;
            }

            h6 {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: var(--secondary-900);
            }

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 13px;
              line-height: 20px;
              color: var(--grey2);
              text-align: center;
            }

            .book-rate-alignment {
              display: flex;
              align-items: center;
              padding: 8px 0 0 0;
              justify-content: center;
              gap: 4px;

              .hike {
                display: flex;
                align-items: center;

                .inter {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 13px;
                  line-height: 20px;
                  color: #70c217;

                  img {
                    margin-right: 3px;
                  }
                }

                .incris {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 13px;
                  line-height: 20px;
                  color: #70c217;
                  display: flex;
                  align-items: center;

                  img {
                    margin-right: 3px;
                  }
                }
              }

              .rating {
                display: flex;
                align-items: center;

                @media only screen and (max-width: 799px) {
                  display: none;
                }

                .rating_point {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                }

                .stars {
                  display: flex;
                  align-items: center;
                  margin: 0 2px;
                }

                .num {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                }
              }
            }

            .book-price-alignment {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 5px;
              padding: 11px 0 0 0;

              h5 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #000000;
              }

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: var(--grey5);
              }
            }

            .like-share-alignment {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 16px;

              .like-alignment {
                display: flex;
                align-items: center;
                gap: 2px;

                span {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                }
              }
            }
          }
        }

        .slick-arrow {
          z-index: 99;
          background: transparent;
          top: 110px;
        }

        .slick-prev {
          left: -4px;
        }

        .slick-next {
          right: -4px;
        }

        .slick-dots {
          top: -37px !important;
          right: -40% !important;
        }
      }
    }

    .book-other-option-second-part-alignment {
      width: 40%;

      @media only screen and (max-width: '899px') {
        width: 100%;
      }

      @media only screen and (max-width: '767px') {
        width: 100%;
      }

      .author-box-section {
        background: #f4f5f8;
        border: 1px solid #ffffff;
        backdrop-filter: blur(32px);
        border-radius: 16px;

        @media only screen and (max-width: '767px') {
          border-radius: 0;
          padding: 16px;
        }

        .heading-alignment {
          padding: 20px 32px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media only screen and (max-width: '767px') {
            display: none;
            padding: 0 0 16px 0;
          }

          h6 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--secondary-900);

            @media only screen and (max-width: '767px') {
              font-size: 20px;
              line-height: 30px;
            }
          }

          a {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.1px;
            color: var(--primary-500);
            cursor: pointer;

            @media only screen and (max-width: '767px') {
              font-size: 14px;
              line-height: 21px;
            }
          }
        }

        .heading-alignment-mobile {
          padding: 20px 32px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media only screen and (min-width: '767px') {
            display: none;
            // padding: 0 0 16px 0;
          }

          @media only screen and (max-width: '767px') {
            padding: 0 0 16px 0;
          }

          h6 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--secondary-900);

            @media only screen and (max-width: '767px') {
              font-size: 20px;
              line-height: 30px;
            }
          }

          a {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.1px;
            color: var(--primary-500);
            cursor: pointer;

            @media only screen and (max-width: '767px') {
              font-size: 14px;
              line-height: 21px;
            }
          }
        }

        .seacrh-alignment {
          background: #ffffff;
          border: 1px solid #f3f3f3;
          border-radius: 10px;
          display: flex;
          align-items: center;
          height: 40px;
          margin: 0 16px 16px 16px;

          @media only screen and (max-width: '767px') {
            display: none;
          }

          img {
            width: 40px;
            min-width: 40px;
            max-width: 40px;
            height: 40px;
          }

          input {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            width: 100%;
            height: 100%;
            border-radius: 0 10px 10px 0;
            padding: 0 10px 0 0;

            &::placeholder {
              color: var(--grey3);
            }
          }
        }

        .book-auther-details-box-alignment {
          height: auto;
          max-height: 598px;
          overflow-y: auto;
          padding: 0 16px;

          @media only screen and (max-width: '767px') {
            display: none;
          }

          .book-auther-details-box {
            border-radius: 20px;
            background: #ffffff;
            padding: 8px;
            display: flex;
            align-items: center;
            gap: 18px;
            margin: 0 0 8px 0;

            &:hover {
              transform: translateY(-2px);
            }

            &:last-child {
              margin: 0;
            }

            .book-author-img-alignment {
              width: 125px;
              min-width: 125px;
              max-width: 125px;
              height: 126px;

              @media only screen and (max-width: '1199px') {
                width: 120px;
                min-width: 120px;
                max-width: 120px;
                height: 120px;
              }

              img {
                width: 100%;
                height: 100%;
                border-radius: 32px;
              }
            }

            .auther-details-alignment {
              h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                color: var(--secondary-900);
                cursor: pointer;
              }

              .auther-following-details-alignment {
                display: flex;
                align-items: center;
                gap: 17px;

                .child-anme-alignment {
                  display: flex;
                  align-items: center;
                  gap: 4px;

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey2);
                    cursor: context-menu;
                  }
                }
              }

              .auther-options-alignment {
                display: flex;
                align-items: center;
                gap: 8px;
                padding: 16px 0 0 0;

                .follow-page-button-alignment {
                  button {
                    background: var(--primary-500);
                    border-radius: 8px;
                    height: 32px;
                    width: 117px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: var(--light-grey);
                    cursor: pointer;
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  gap: 8px;

                  .box-alignment {
                    background: var(--light-grey);
                    border-radius: 8px;
                    width: 32px;
                    min-width: 32px;
                    max-width: 32px;
                    height: 32px;
                    padding: 4px;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }

        .mobile-view-book-auther-details-alignment {
          display: none;

          @media only screen and (max-width: '767px') {
            display: block;
          }

          .view-book-author-mobile-slider {
            padding: 0 4px;

            .view-book-author-mobile-box-alignment {
              background: #ffffff;
              padding: 32px;

              .view-book-author-img-alignment {
                width: 166px;
                height: 166px;
                margin: auto;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                  border-radius: 32px;
                }
              }

              .mobile-view-details-alignment {
                padding: 16px 0 0 0;

                h5 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 18px;
                  line-height: 27px;
                  color: var(--secondary-900);
                  text-align: center;
                }

                .mobile-auther-following-details-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 17px;
                  padding: 8px 0 0 0;

                  .child-anme-alignment {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .mobile-auther-options-alignment {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  padding: 16px 0 0 0;
                  justify-content: center;

                  .follow-page-button-alignment {
                    button {
                      background: var(--primary-500);
                      border-radius: 8px;
                      height: 32px;
                      width: 117px;
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 21px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      color: var(--light-grey);
                      cursor: pointer;
                    }
                  }

                  .like-share-alignment {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    .box-alignment {
                      background: var(--light-grey);
                      border-radius: 8px;
                      width: 32px;
                      min-width: 32px;
                      max-width: 32px;
                      height: 32px;
                      padding: 4px;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .book-club-section-alignment {
        background: rgba(255, 255, 255, 0.6);
        border: 1px solid #ffffff;
        backdrop-filter: blur(32px);
        border-radius: 16px;
        margin: 16px 0 0 0;

        @media only screen and (max-width: '767px') {
          border-radius: 0;
          padding: 16px;
          background: #f4f5f8;
          border: 0.895434px solid #ffffff;
          backdrop-filter: blur(28.6539px);
        }

        .heading-alignment {
          padding: 20px 32px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media only screen and (max-width: '767px') {
            display: none;
            padding: 0 0 16px 0;
          }

          h6 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--secondary-900);

            @media only screen and (max-width: '767px') {
              font-size: 20px;
              line-height: 30px;
            }
          }

          a {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.1px;
            color: var(--primary-500);
            cursor: pointer;

            @media only screen and (max-width: '767px') {
              font-size: 14px;
              line-height: 21px;
            }
          }
        }

        .heading-alignment-mobile {
          padding: 20px 32px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media only screen and (min-width: '767px') {
            // padding: 0 0 16px 0;
            display: none;
          }

          @media only screen and (max-width: '767px') {
            padding: 0 0 16px 0;
          }

          h6 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--secondary-900);

            @media only screen and (max-width: '767px') {
              font-size: 20px;
              line-height: 30px;
            }
          }

          a {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.1px;
            color: var(--primary-500);
            cursor: pointer;

            @media only screen and (max-width: '767px') {
              font-size: 14px;
              line-height: 21px;
            }
          }
        }

        .book-club-create-section {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0 16px 16px 16px;

          @media only screen and (max-width: '1199px') {
            flex-wrap: wrap;
            gap: 15px;
          }

          @media only screen and (max-width: '767px') {
            display: none;
          }

          .create-input-alignment {
            width: 240px;
            min-width: 240px;
            max-width: 240px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            @media only screen and (max-width: '1199px') {
              width: 100%;
              min-width: 100%;
              max-width: 100%;
            }

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }

          .create-clun-alignment {
            @media only screen and (max-width: '1199px') {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-end;
            }

            button {
              background: var(--primary-500);
              border-radius: 8px;
              height: 32px;
              width: 124px;
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: var(--light-grey);
              cursor: pointer;
            }
          }
        }

        .book-club-created-details-alignment {
          padding: 0 16px 16px 16px;
          height: auto;
          max-height: 475px;
          overflow-y: auto;

          @media only screen and (max-width: '767px') {
            display: none;
          }

          .book-club-create-box-alignment {
            background: #ffffff;
            border-radius: 10px;
            padding: 4px;
            display: flex;
            align-items: center;
            gap: 16px;
            margin: 0 0 8px 0;

            &:last-child {
              margin: 0;
            }

            .book-club-img-alignment {
              max-width: 148px;
              width: 148px;
              min-width: 148px;
              height: 99px;

              img {
                width: 100%;
                height: 100%;
                display: block;
                border-radius: 8px;
              }
            }

            .book-club-create-details-alignment {
              width: 100%;

              h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
                color: var(--secondary-900);
              }

              .auther-member-details-join-alignment {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                gap: 5px;

                .member-all-details {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  flex-wrap: wrap;

                  .member-img-flex-alignment {
                    display: flex;
                    align-items: center;

                    .member-img-alignment {
                      max-width: 24px;
                      min-width: 24px;
                      width: 24px;
                      height: 24px;
                      margin-right: -4px;

                      img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                      }
                    }
                  }

                  .member-total-alignment {
                    p {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 21px;
                      color: var(--grey2);
                    }
                  }
                }

                .join-club-button-alignment {
                  width: 92px;
                  height: 32px;

                  button {
                    background: var(--primary-500);
                    border-radius: 8px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: var(--light-grey);
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }
          }
        }
      }

      .mobile-view-shop-by-category-alignment {
        display: none;

        @media only screen and (max-width: '767px') {
          padding: 32px 0 0 0;
          display: block;

          .mobile-view-heading-alignment {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 16px 16px 16px;

            h5 {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 20px;
              line-height: 30px;
              color: var(--secondary-900);
            }
          }

          .mobile-view-shop-by-category-details-alignment {
            .shop-by-grid-alignment {
              display: grid;
              grid-template-columns: repeat(3, 1fr);

              .shop-by-gridItem-box-alignment {
                background: rgba(255, 255, 255, 0.4);
                border: 0.895434px solid #ffffff;
                backdrop-filter: blur(28.6539px);
                height: 144px;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                gap: 8px;

                .category-name-alignment {
                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 20px;
                    text-align: center;
                    background: linear-gradient(105.42deg, #c40000 8.92%, #c1e001 53.37%, #0061bb 95.14%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;

                    &.notification-text {
                      background: linear-gradient(266.63deg, #173fd0 17.65%, #06abdf 79.04%);
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                      background-clip: text;
                      text-fill-color: transparent;
                    }

                    &.fiction-text {
                      background: linear-gradient(97.94deg, #3844fd 1.89%, #ef48d8 97.91%);
                      -webkit-background-clip: text;
                      -webkit-text-fill-color: transparent;
                      background-clip: text;
                      text-fill-color: transparent;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .mobile-view-top-rated-book-alignment {
      display: none;

      @media only screen and (max-width: '767px') {
        display: block;
      }
    }

    .mobile-view-most-liked-book-alignment {
      display: none;

      @media only screen and (max-width: '767px') {
        display: block;
      }
    }

    .mobile-view-best-of-you-alignment {
      display: none;

      @media only screen and (max-width: '767px') {
        display: block;

        .mobile-best-of-you-heading-alignment {
          padding: 55px 0 16px 0;

          h4 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: var(--secondary-900);
            text-align: center;
          }
        }

        .mobile-view-best-of-you-grid-alignment {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          padding: 0 14px;

          .mobile-view-best-of-you-gridItem-alignment {
            .mobile-view-best-of-you-book-alignment {
              padding: 16px;

              @media only screen and (max-width: '767px') {
                padding: 8px 6px;
              }

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .release-book-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }

              .book-of-the-month-release-book-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                display: none;

                @media only screen and (max-width: '767px') {
                  display: block;
                }

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(30, 36, 50, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;

  @media only screen and (max-width: '767px') {
    display: none !important;
  }
}

.overlay.open {
  opacity: 1;
  visibility: visible;
}

.special-offer-modal {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 70%;
  height: calc(100vh - 64px);
  // height: calc(100vh - 104px);
  // padding: 0 16px;
  // background: var(--secondary-50);
  // border-radius: 16px 0px 0px 0px;
  z-index: 9999;
  translate: 100% 0;
  transition: 0.3s;
  display: flex;
  align-items: flex-start;
  gap: 16px;

  @media only screen and (max-width: '1199px') {
    width: 85%;
  }

  @media only screen and (max-width: '1099px') {
    width: 100%;
    height: 100vh;
  }

  @media only screen and (max-width: '767px') {
    width: 100%;
    top: 0;
    height: 100vh;
  }

  .colse-modal-icon-alignment {
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0 0 0;
    cursor: pointer;
  }

  .sidebar-modal-alignment {
    background: var(--secondary-50);
    border-radius: 16px 0px 0px 0px;
    padding: 16px;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    @media only screen and (max-width: '1099px') {
      height: 100%;
    }

    @media only screen and (max-width: '767px') {
      padding: 0;
      border-radius: 0;
    }

    .special-offer-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;

      .special-offer-banner-alignment {
        width: 100%;
        height: 189px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          border-radius: 8px 8px 0 0;
        }

        &::before {
          content: '';
          position: absolute;
          background: linear-gradient(360deg, #f8f9fb 0%, rgba(248, 249, 251, 0) 219.84%);
          border-radius: 8px 8px 0 0;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
        }

        .special-offer-banner-details-alignment {
          text-align: center;
          position: absolute;
          top: 58px;
          right: 0;
          left: 0;

          h2 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 32px;
            line-height: 48px;
            color: var(--secondary-900);
            padding: 0 0 16px 0;
            text-transform: uppercase;
          }

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey5);
          }
        }
      }

      .speaicl-offer-tab-alignment {
        padding: 27px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 32px;
          justify-content: center;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey2);
            cursor: pointer;

            &.offer-active {
              font-weight: 600;
              color: var(--grey5);
            }

            &:hover {
              font-weight: 600;
              color: var(--grey5);
            }
          }
        }
      }

      .special-offer-book-details-alignment {
        margin: 44px 127px;
        height: calc(100vh - 365px);
        overflow-y: auto;

        @media only screen and (max-width: '899px') {
          margin: 30px;
        }

        .speaicl-offer-book-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 24px;

          @media only screen and (max-width: '899px') {
            grid-template-columns: repeat(3, 1fr);
          }

          @media only screen and (max-width: '1199px') {
            grid-template-columns: repeat(3, 1fr);
          }

          .speaicl-offer-book-gridItem-alignment {
            .speaicl-offer-book-alignment {
              padding: 16px;

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .release-book-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // New Release Section Details

    .new-release-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 32px 56px;

      @media only screen and (max-width: '899px') {
        padding: 32px 40px;
      }

      .new-release-modal-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .new-release-heading-label-alignment {
          background: linear-gradient(94.09deg, #556ee6 0.21%, #6b21a8 100%);
          box-shadow: 1px 3px 3px rgba(95, 64, 175, 0.08);
          border-radius: 0px 0px 8px 0px;
          padding: 4px 16px;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--light-grey);
          }
        }

        .new-release-search-alignment {
          .new-release-input-alignment {
            width: 291px;
            min-width: 291px;
            max-width: 291px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }
        }
      }

      .new-realse-note-alignment {
        padding: 16px 0 0 0;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: var(--grey5);
        }
      }

      .new-realese-tab-alignment {
        padding: 14px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 32px;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey2);
            cursor: pointer;

            &.new-release-active {
              font-weight: 600;
              color: var(--grey5);
            }

            &:hover {
              font-weight: 600;
              color: var(--grey5);
            }
          }
        }
      }

      .new-realese-book-details-alignment {
        margin: 44px 0;
        height: calc(100vh - 320px);
        overflow-y: auto;

        .new-realese-book-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 24px;

          @media only screen and (max-width: '1199px') {
            grid-template-columns: repeat(3, 1fr);
          }

          .new-realese-book-gridItem-alignment {
            .new-realese-book-alignment {
              padding: 16px;

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .release-book-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Best Seller Section Details

    .best-seller-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 32px 56px;

      @media only screen and (max-width: '899px') {
        padding: 32px 40px;
      }

      .best-seller-modal-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .best-seller-heading-label-alignment {
          background: linear-gradient(263.94deg, #ffc554 7.44%, #744c00 86.5%);
          box-shadow: 1px 3px 3px rgba(95, 64, 175, 0.08);
          border-radius: 0px 0px 8px 0px;
          padding: 4px 16px;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--light-grey);
          }
        }

        .best-seller-search-alignment {
          .best-seller-input-alignment {
            width: 291px;
            min-width: 291px;
            max-width: 291px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }
        }
      }

      .best-seller-note-alignment {
        padding: 16px 0 0 0;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: var(--grey5);
        }
      }

      .best-seller-tab-alignment {
        padding: 14px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 32px;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey2);
            cursor: pointer;

            &.best-seller-active {
              font-weight: 600;
              color: var(--grey5);
            }

            &:hover {
              font-weight: 600;
              color: var(--grey5);
            }
          }
        }
      }

      .best-seller-book-details-alignment {
        margin: 44px 0;
        height: calc(100vh - 320px);
        overflow-y: auto;

        .best-seller-book-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 24px;

          @media only screen and (max-width: '1199px') {
            grid-template-columns: repeat(3, 1fr);
          }

          .best-seller-book-gridItem-alignment {
            .best-seller-book-alignment {
              padding: 16px;

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .best-seller-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Best Of The Year

    .best-ofthe-year-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 32px 56px;

      @media only screen and (max-width: '899px') {
        padding: 32px 40px;
      }

      .best-ofthe-year-modal-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .best-ofthe-year-heading-label-alignment {
          background: #1e2432;
          box-shadow: 1px 3px 3px rgba(95, 64, 175, 0.08);
          border-radius: 0px 0px 8px 0px;
          padding: 4px 16px;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--light-grey);
          }
        }

        .best-ofthe-year-search-alignment {
          .best-ofthe-year-input-alignment {
            width: 291px;
            min-width: 291px;
            max-width: 291px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }
        }
      }

      .best-ofthe-year-note-alignment {
        padding: 16px 0 0 0;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: var(--grey5);
        }
      }

      .best-ofthe-year-tab-alignment {
        padding: 14px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 32px;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey2);
            cursor: pointer;

            &.best-ofthe-year-active {
              font-weight: 600;
              color: var(--grey5);
            }

            &:hover {
              font-weight: 600;
              color: var(--grey5);
            }
          }
        }
      }

      .best-ofthe-year-book-details-alignment {
        margin: 44px 0;
        height: calc(100vh - 320px);
        overflow-y: auto;

        .best-ofthe-year-book-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 24px;

          @media only screen and (max-width: '1199px') {
            grid-template-columns: repeat(3, 1fr);
          }

          .best-ofthe-year-book-gridItem-alignment {
            .best-ofthe-year-book-alignment {
              padding: 16px;

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .best-ofthe-year-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Top Rated Book of the Year

    .top-rated-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 32px 56px;

      .top-rated-modal-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .top-rated-heading-label-alignment {
          background: #db5a00;
          box-shadow: 1px 3px 3px rgba(95, 64, 175, 0.08);
          border-radius: 0px 0px 8px 0px;
          padding: 4px 16px;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--light-grey);
          }
        }

        .top-rated-search-alignment {
          .top-rated-input-alignment {
            width: 291px;
            min-width: 291px;
            max-width: 291px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }
        }
      }

      .top-rated-note-alignment {
        padding: 16px 0 0 0;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: var(--grey5);
        }
      }

      .top-rated-tab-alignment {
        padding: 14px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 32px;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey2);
            cursor: pointer;

            &.top-rated-active {
              font-weight: 600;
              color: var(--grey5);
            }

            &:hover {
              font-weight: 600;
              color: var(--grey5);
            }
          }
        }
      }

      .top-rated-book-details-alignment {
        margin: 44px 0;
        height: calc(100vh - 320px);
        overflow-y: auto;

        .top-rated-book-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 24px;

          .top-rated-book-gridItem-alignment {
            .top-rated-book-alignment {
              padding: 16px;

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .top-rated-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Most Liked Book

    .most-liked-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 32px 56px;

      .most-liked-modal-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .most-liked-heading-label-alignment {
          background: #940000;
          box-shadow: 1px 3px 3px rgba(95, 64, 175, 0.08);
          border-radius: 0px 0px 8px 0px;
          padding: 4px 16px;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--light-grey);
          }
        }

        .most-liked-search-alignment {
          .most-liked-input-alignment {
            width: 291px;
            min-width: 291px;
            max-width: 291px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }
        }
      }

      .most-liked-note-alignment {
        padding: 16px 0 0 0;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: var(--grey5);
        }
      }

      .most-liked-tab-alignment {
        padding: 14px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 32px;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey2);
            cursor: pointer;

            &.most-liked-active {
              font-weight: 600;
              color: var(--grey5);
            }

            &:hover {
              font-weight: 600;
              color: var(--grey5);
            }
          }
        }
      }

      .most-liked-book-details-alignment {
        margin: 44px 0;
        height: calc(100vh - 320px);
        overflow-y: auto;

        .most-liked-book-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 24px;

          .most-liked-book-gridItem-alignment {
            .most-liked-book-alignment {
              padding: 16px;

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .most-liked-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // More Author Modal Section

    .more-author-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 22px 32px;

      .more-author-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 40px;

        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 32px;
          line-height: 48px;
          color: var(--secondary-900);
        }

        .more-author-search-input-alignment {
          width: 487px;
          min-width: 487px;
          max-width: 487px;
          height: 40px;
          background: #ffffff;
          border: 1px solid #f3f3f3;
          border-radius: 10px;
          display: flex;
          align-items: center;

          @media only screen and (max-width: '899px') {
            width: 280px;
            min-width: 280px;
            max-width: 280px;
          }

          img {
            width: 40px;
            min-width: 40px;
            max-width: 40px;
            height: 40px;
          }

          input {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            width: 100%;
            height: 100%;
            border-radius: 0 10px 10px 0;
            padding: 0 10px 0 0;

            &::placeholder {
              color: var(--grey3);
            }
          }
        }
      }

      .more-author-details-modal-body-alignment {
        margin: 35px 0 0 0;
        height: calc(100vh - 202px);
        overflow-y: auto;

        .more-author-grid-alignment {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 16px;

          @media only screen and (max-width: '899px') {
            grid-template-columns: repeat(2, 1fr);
          }

          .more-author-gridItem-alignment {
            .more-author-box-alignment {
              background: #ffffff;
              padding: 32px;

              .author-img-alignment {
                max-width: 166px;
                width: 166px;
                height: 166px;
                margin: auto;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 32px;
                }
              }

              .more-auther-details-alignment {
                padding: 16px 0 0 0;
                text-align: center;

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 30px;
                  color: var(--secondary-900);
                }

                .auther-following-details-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 17px;
                  padding: 8px 0 0 0;

                  .child-anme-alignment {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .auther-options-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 8px;
                  padding: 16px 0 0 0;

                  .follow-page-button-alignment {
                    button {
                      background: var(--primary-500);
                      border-radius: 8px;
                      height: 32px;
                      width: 117px;
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 21px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      color: var(--light-grey);
                      cursor: pointer;
                    }
                  }

                  .like-share-alignment {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    .box-alignment {
                      background: var(--light-grey);
                      border-radius: 8px;
                      width: 32px;
                      min-width: 32px;
                      max-width: 32px;
                      height: 32px;
                      padding: 4px;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Book Club Modal Section

    .book-club-modal-details-section {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 32px;

      .book-club-modal-details-alignment {
        .book-club-heading-alignment {
          padding: 0 0 0 15px;

          h5 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--secondary-900);
          }
        }

        .book-club-details-flex-alignment {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 32px 0 0 0;

          @media only screen and (max-width: '899px') {
            gap: 40px;
          }

          .book-search-alignment {
            width: 487px;
            min-width: 487px;
            max-width: 487px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            @media only screen and (max-width: '899px') {
              width: 280px;
              min-width: 280px;
              max-width: 280px;
            }

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }

          .create-club-button-alignment {
            button {
              background: var(--primary-500);
              border-radius: 8px;
              padding: 6px 12px;
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              color: var(--light-grey);
              cursor: pointer;
              border: none;
            }
          }
        }

        .book-club-details-body-alignment {
          margin: 32px 0 0 0;
          height: calc(100vh - 270px);
          overflow-y: auto;

          .book-club-grid-alignment {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 32px;

            @media only screen and (max-width: '1199px') {
              grid-template-columns: repeat(2, 1fr);
            }

            .book-club-details_cover {
              padding: 0;

              .card_fashion {
                width: 100%;
                position: relative;
                border-radius: 10px 10px 0px 0px;
                overflow: hidden;
                transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                height: 251px;
                max-height: 251px;

                &:hover {
                  transform: translateY(-5px);

                  .img_cover img {
                    transform: scale(1.05);
                  }
                }

                .img_cover {
                  object-fit: cover;
                  object-position: center;
                  height: 251px;
                  max-height: 251px;

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                  }
                }

                .card_details {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: flex-end;
                  padding: 15px;
                  background: linear-gradient(360deg, #0e1116 0%, rgba(30, 36, 50, 0) 100%);

                  .card_details_cover {
                    width: 100%;

                    h5 {
                      font-weight: 500;
                      font-size: 20px;
                      line-height: 30px;
                      color: #ffffff;
                    }

                    p {
                      font-weight: 400;
                      font-size: 12px;
                      line-height: 18px;
                      color: #ffffff;
                      margin: 4px 0 17px 0;
                    }

                    .users {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;

                      .left_part {
                        display: flex;
                        align-items: center;

                        .users_likes {
                          display: flex;
                          align-items: center;
                          margin-left: -10px;

                          .user_box {
                            width: 20px;
                            display: flex;
                            align-items: center;
                            height: 20px;
                            justify-content: center;
                            background: #fff;
                            object-position: center;
                            border: 1px solid #ffffff;
                            border-radius: 30px;

                            img {
                              width: 100%;
                              object-fit: cover !important;
                            }
                          }
                        }

                        .count {
                          margin-left: -10px;
                          background: var(--primary-500);
                          border: 1px solid #ffffff;
                          border-radius: 12px;
                          font-weight: 500;
                          font-size: 12px;
                          line-height: 18px;
                          letter-spacing: 0.1px;
                          color: #ffffff;
                          padding: 0px 9px;
                        }
                      }

                      .right_part {
                        max-width: 120px;

                        button {
                          @include block_btn();
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Author Modal Section

    .author-modal-section {
      // background: rgba(255, 255, 255, 0.6);
      // border: 1px solid #FFFFFF;
      // backdrop-filter: blur(32px);
      border-radius: 10px;
      width: 96%;
      height: 100vh;
      overflow-y: auto;

      @media only screen and (max-width: '899px') {
        width: 100%;
      }

      @media only screen and (max-width: '767px') {
        width: 100%;
      }

      .author-modal-all-details-alignment {
        .author-banner-bg-alignment {
          background: rgba(217, 217, 217, 0.4);
          backdrop-filter: blur(32px);
          border-radius: 10px;
          width: 100%;
          height: 122px;
          position: relative;

          @media only screen and (max-width: '767px') {
            margin: 8px 0 0 0;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }

          &::before {
            content: '';
            position: absolute;
            background: rgba(217, 217, 217, 0.4);
            backdrop-filter: blur(32px);
            border-radius: 10px;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            z-index: 99;
          }
        }

        .author-details-section {
          margin: -98px 24px 0 24px;
          background: rgba(255, 255, 255, 0.6);
          border: 1px solid #ffffff;
          backdrop-filter: blur(32px);
          border-radius: 8px;
          padding: 32px;
          // height: 100vh;
          // overflow-y: auto;

          @media only screen and (max-width: '767px') {
            margin: -106px 0 0 0;
            padding: 0;
            background: none;
            backdrop-filter: none;
            border-radius: none;
            border: none;
            position: relative;
            height: auto;
          }

          .author-details-flex-alignment {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            @media only screen and (max-width: '767px') {
              background: rgba(255, 255, 255, 0.6);
              border: 1px solid #ffffff;
              backdrop-filter: blur(32px);
              border-radius: 8px;
              padding: 16px;
              margin: 0 16px;
              display: block;
            }

            .author-all-profile-details-alignment {
              display: flex;
              align-items: center;
              gap: 16px;

              .author-img-alignment {
                width: 105px;
                max-width: 105px;
                min-width: 105px;
                height: 105px;

                @media only screen and (max-width: '767px') {
                  width: 87px;
                  max-width: 87px;
                  min-width: 87px;
                  height: 87px;
                }

                img {
                  width: 100%;
                  height: 100%;
                  border: 2px solid #ffffff;
                  border-radius: 24px;
                  object-fit: cover;
                }
              }

              .author-personal-details-alignment {
                h5 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 24px;
                  line-height: 36px;
                  color: var(--secondary-900);

                  @media only screen and (max-width: '767px') {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                  }
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey5);

                  @media only screen and (max-width: '767px') {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                  }
                }
              }

              .author-child-details-alignment {
                display: flex;
                align-items: center;
                gap: 16px;
                padding: 8px 0 0 0;

                @media only screen and (max-width: '767px') {
                  display: none;
                }

                .book-follow-like-alignment {
                  display: flex;
                  align-items: center;
                  gap: 4px;

                  .icon-alignment {
                    width: 20px;
                    height: 20px;

                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 20px;
                    color: #5f40af;
                  }
                }
              }

              .mobile-follow-details-alignment {
                display: flex;
                align-items: center;
                gap: 8px;
                display: none;

                @media only screen and (max-width: '767px') {
                  display: flex;
                  margin: 8px 0 0 0;
                }

                .mobile-option-alignment {
                  background: var(--light-grey);
                  border-radius: 8px;
                  width: 32px;
                  height: 32px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 4px;
                }

                .mobile-follow-page-button-alignment {
                  button {
                    color: var(--primary-500);
                    border-radius: 8px;
                    width: 117px;
                    height: 32px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: var(--light-grey);
                    border-radius: 8px;
                    cursor: pointer;
                  }
                }
              }
            }

            .mobile-author-child-details-alignment {
              display: flex;
              align-items: center;
              gap: 16px;
              padding: 8px 0 0 0;
              display: none;

              @media only screen and (max-width: '767px') {
                display: flex;
              }

              .mobile-book-follow-like-alignment {
                display: flex;
                align-items: center;
                gap: 4px;

                .mobile-icon-alignment {
                  width: 20px;
                  height: 20px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 18px;
                  color: #5f40af;
                }
              }
            }

            .follow-details-alignment {
              display: flex;
              align-items: center;
              gap: 8px;

              @media only screen and (max-width: '767px') {
                display: none;
              }

              .option-alignment {
                background: var(--light-grey);
                border-radius: 8px;
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 4px;
              }

              .follow-page-button-alignment {
                button {
                  background: var(--primary-500);
                  border-radius: 8px;
                  width: 117px;
                  height: 32px;
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 21px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: var(--light-grey);
                  cursor: pointer;
                }
              }
            }
          }

          .author-review-details-alignment {
            padding: 32px 0 0 0;

            @media only screen and (max-width: '767px') {
              padding: 8px 0 25px 16px;
              margin: 16px 0 0 0;
              background: #f4f5f8;
              border: 1px solid #ffffff;
              // backdrop-filter: blur(28.6539px);
            }

            .review-heading-alignment {
              display: flex;
              align-items: center;
              justify-content: space-between;

              h4 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: var(--secondary-900);
              }

              a {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                color: var(--primary-500);
                cursor: pointer;
              }
            }

            .author-child-review-details-alignment {
              padding: 24px 0 0 32px;
              display: flex;
              align-items: center;
              width: 100%;
              overflow-x: hidden;

              @media only screen and (max-width: '767px') {
                overflow-x: auto;
              }

              .author-child-all-detail-alignment {
                border-right: 1px solid var(--grey4);
                padding: 0 16px;

                &:first-child {
                  padding: 0 16px 0 0;
                }

                &:last-child {
                  padding: 0 0 0 16px;
                }

                .author-note-alignment {
                  padding: 16px 0 0 0;
                  max-width: 281px;
                  width: 281px;

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .profile-img-alignment {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  padding: 22px 0 0 0;

                  .child-author-img-alignment {
                    width: 35px;
                    height: 35px;

                    img {
                      width: 100%;
                      height: 100%;
                      border: 2px solid #ffffff;
                      border-radius: 50%;
                    }
                  }

                  .child-author-name-alignment {
                    p {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--secondary-900);
                    }
                  }
                }
              }
            }
          }

          .david-offer-book-alignment {
            padding: 48px 0 0 0;

            @media only screen and (max-width: '767px') {
              padding: 16px;
              margin: 8px 0 0 0;
              background: #f4f5f8;
              border: 1px solid #ffffff;
            }

            .david-offer-heading-alignment {
              @media only screen and (max-width: '767px') {
                display: flex;
                align-items: center;
                justify-content: space-between;
              }

              h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: var(--secondary-900);

                @media only screen and (max-width: '767px') {
                  font-size: 20px;
                  line-height: 30px;
                  max-width: 160px;
                }
              }

              a {
                display: none;

                @media only screen and (max-width: '767px') {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;
                  color: var(--primary-500);
                  display: flex;
                }
              }
            }

            .david-offer-details-alignment {
              padding: 51px 0 25px 0;
              width: 100%;

              @media only screen and (max-width: '767px') {
                padding: 12px 0 28px 0;
              }

              .david-book-other-space-alignment {
                padding: 0 12px;

                @media only screen and (max-width: '767px') {
                  padding: 0;
                }

                .david-offer-book-gridItem {
                  background: #ffffff;
                  border-radius: 10px;
                  height: 193px;
                  padding: 0 0 0 22px;
                  transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);

                  &:hover {
                    transform: translateY(-6px);
                    border-radius: 10px;
                    box-shadow: 2px 7px 15px rgba(95, 64, 175, 0.4);
                    padding: 0 0 0 16px;

                    .david-offer-book-details {
                      gap: 14px;
                      transition: 0.8s ease-in-out;

                      .book-cover-img {
                        width: 160px;
                        max-width: 160px;
                        min-width: 160px;
                        height: 240px;
                      }
                    }
                  }

                  .david-offer-book-details {
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    transition: 0.8s ease-in-out;

                    .book-cover-img {
                      width: 146px;
                      max-width: 146px;
                      min-width: 146px;
                      height: 224px;
                      margin: -20px 0 0 0;

                      img {
                        width: 100%;
                        height: 100%;
                        display: block;
                      }
                    }

                    .book-name-details-alignement {
                      width: 100%;

                      h5 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 36px;
                        color: var(--secondary-900);
                        cursor: pointer;

                        @media only screen and (max-width: '767px') {
                          font-size: 16px;
                          line-height: 24px;
                        }
                      }

                      p {
                        padding: 8px 0 0 0;
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 20px;
                        color: var(--grey2);

                        @media only screen and (max-width: '767px') {
                          padding: 16px 0 0 0;
                        }
                      }

                      .price-alignment-flex {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        @media only screen and (max-width: '767px') {
                          display: block;
                        }

                        .price-alignment {
                          @media only screen and (max-width: '767px') {
                            display: flex;
                            align-items: center;
                            gap: 4px;
                          }

                          h6 {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 24px;
                            line-height: 36px;
                            color: #474400;

                            @media only screen and (max-width: '767px') {
                              font-weight: 600;
                              font-size: 16px;
                              line-height: 24px;
                              color: #000000;
                            }
                          }

                          span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 30px;
                            color: var(--grey2);
                            display: block;
                            margin: -6px 0 0 0;
                            text-align: right;

                            @media only screen and (max-width: '767px') {
                              font-weight: 400;
                              font-size: 13px;
                              line-height: 20px;
                              color: var(--grey5);
                              margin: 2px 0;
                            }

                            del {
                              color: #ca0000;

                              @media only screen and (max-width: '767px') {
                                color: var(--grey5);
                              }
                            }
                          }
                        }

                        .offer-label-alignment {
                          background: #f1efb0;
                          padding: 9px 17px 7px 17px;

                          @media only screen and (max-width: '767px') {
                            margin: 5px 0 0 0;
                            width: 81px;
                            height: 36px;
                            padding: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          }

                          p {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 30px;
                            text-align: center;
                            color: #474400;
                            padding: 0;

                            @media only screen and (max-width: '767px') {
                              font-weight: 500;
                              font-size: 18px;
                              line-height: 27px;
                              color: #474400;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .david-other-book-details-alignment {
            padding: 43px 0 0 0;

            @media only screen and (max-width: '767px') {
              padding: 32px 16px;
            }

            .mobile-view-heading-alignment {
              display: none;

              @media only screen and (max-width: '767px') {
                display: flex;
                align-items: center;
                gap: 2px;
                padding: 0 0 18px 0;

                h4 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 30px;
                  color: var(--secondary-900);
                }

                span {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;
                  color: var(--grey2);
                }
              }
            }

            .david-other-book-filter-alignment {
              display: flex;
              align-items: center;
              justify-content: space-between;

              @media only screen and (max-width: '899px') {
                flex-wrap: wrap;
                gap: 20px;
              }

              .filter-seacch-alignment {
                display: flex;
                align-items: center;
                gap: 8px;

                @media only screen and (max-width: '767px') {
                  width: 100%;
                }

                .filter-alignment {
                  padding: 12px 8px 12px 17px;
                  display: flex;
                  align-items: center;
                  gap: 4px;
                  background: #ffffff;
                  border: 0.5px solid var(--grey4);
                  border-radius: 10px;

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: var(--grey5);
                  }
                }

                .seacrh-alignment {
                  background: #ffffff;
                  border: 1px solid #f3f3f3;
                  border-radius: 10px;
                  display: flex;
                  align-items: center;
                  width: 437px;
                  height: 40px;

                  @media only screen and (max-width: '767px') {
                    width: 100%;
                  }

                  img {
                    width: 40px;
                    min-width: 40px;
                    max-width: 40px;
                    height: 40px;
                  }

                  input {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    width: 100%;
                    height: 100%;
                    border-radius: 0 10px 10px 0;
                    padding: 0 10px 0 0;

                    &::placeholder {
                      color: var(--grey3);
                    }
                  }
                }
              }

              .category-alignment {
                display: flex;
                align-items: center;
                gap: 8px;

                @media only screen and (max-width: '767px') {
                  display: none;
                }

                label {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 21px;
                  color: var(--secondary-900);
                }

                .category-dropdown-alignment {
                  width: 144px;
                  height: 40px;
                  background: #ffffff;
                  border: 1px solid var(--grey4);
                  border-radius: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 8px;

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: var(--grey5);
                  }

                  img {
                    cursor: pointer;
                  }
                }
              }
            }

            .david-other-all-details-alignment {
              padding: 32px 0 0 0;

              @media only screen and (max-width: '767px') {
                padding: 18px 0 0 0;
              }

              .david-other-all-details-grid-alignment {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 30px;

                @media only screen and (max-width: '1199px') {
                  grid-template-columns: repeat(3, 1fr);
                }

                @media only screen and (max-width: '899px') {
                  grid-template-columns: repeat(2, 1fr);
                }

                @media only screen and (max-width: '767px') {
                  grid-template-columns: repeat(2, 1fr);
                  gap: 32px 0;
                }

                .david-other-all-details-gridItem-alignment {
                  .most-liked-book-alignment {
                    padding: 16px;

                    @media only screen and (max-width: '767px') {
                      padding: 6px;
                    }

                    &:hover {
                      transform: translateY(-5px);
                      background: rgba(85, 110, 230, 0.1);
                      border-radius: 10px;

                      .book-img-alignment {
                        box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                      }
                    }

                    &.book-active-details {
                      transform: translateY(-5px);
                      background: rgba(85, 110, 230, 0.1);
                      border-radius: 10px;

                      .book-img-alignment {
                        box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                      }
                    }

                    .book-img-alignment {
                      height: 224px;

                      img {
                        width: 100%;
                        height: 100%;
                        display: block;
                      }
                    }

                    .most-liked-name-alignment {
                      text-align: center;
                      padding: 16px 0 0 0;
                      margin: 0 0 24px 0;

                      &:last-child {
                        margin: 0;
                      }

                      h6 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--secondary-900);
                      }

                      p {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 20px;
                        color: var(--grey2);
                        text-align: center;
                      }

                      .book-rate-alignment {
                        display: flex;
                        align-items: center;
                        padding: 8px 0 0 0;
                        justify-content: center;
                        gap: 4px;

                        .hike {
                          display: flex;
                          align-items: center;

                          .inter {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 20px;
                            color: #70c217;

                            img {
                              margin-right: 3px;
                            }
                          }

                          .incris {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 20px;
                            color: #70c217;
                            display: flex;
                            align-items: center;

                            img {
                              margin-right: 3px;
                            }
                          }
                        }

                        .rating {
                          display: flex;
                          align-items: center;

                          @media only screen and (max-width: 799px) {
                            display: none;
                          }

                          .rating_point {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 20px;
                            color: var(--grey2);
                          }

                          .stars {
                            display: flex;
                            align-items: center;
                            margin: 0 2px;
                          }

                          .num {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 20px;
                            color: var(--grey2);
                          }
                        }
                      }

                      .book-price-alignment {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 5px;
                        padding: 11px 0 0 0;

                        h5 {
                          font-family: 'Poppins', sans-serif;
                          font-weight: 600;
                          font-size: 16px;
                          line-height: 24px;
                          color: #000000;
                        }

                        span {
                          font-family: 'Poppins', sans-serif;
                          font-weight: 400;
                          font-size: 13px;
                          line-height: 20px;
                          color: var(--grey5);
                        }
                      }

                      .like-share-alignment {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 16px;

                        .like-alignment {
                          display: flex;
                          align-items: center;
                          gap: 2px;

                          span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 20px;
                            color: var(--grey2);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.special-offer-modal.open {
  translate: 0;
}

.mobile-view-book-club-created-details-alignment {
  display: none;

  @media only screen and (max-width: '767px') {
    display: block;
  }

  .book-club-details_cover {
    padding: 0 4px;

    .card_fashion {
      width: 100%;
      position: relative;
      border-radius: 10px 10px 0px 0px;
      overflow: hidden;
      transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
      height: 170px;
      max-height: 170px;

      &:hover {
        transform: translateY(-5px);

        .img_cover img {
          transform: scale(1.05);
        }
      }

      .img_cover {
        object-fit: cover;
        object-position: center;
        height: 251px;
        max-height: 251px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
        }
      }

      .card_details {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        padding: 15px;
        background: linear-gradient(360deg, #0e1116 0%, rgba(30, 36, 50, 0) 100%);

        .card_details_cover {
          width: 100%;

          h5 {
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: #ffffff;
          }

          p {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #ffffff;
            margin: 4px 0 17px 0;
          }

          .users {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left_part {
              display: flex;
              align-items: center;

              .users_likes {
                display: flex;
                align-items: center;
                margin-left: -10px;

                .user_box {
                  width: 20px;
                  display: flex;
                  align-items: center;
                  height: 20px;
                  justify-content: center;
                  background: #fff;
                  object-position: center;
                  border: 1px solid #ffffff;
                  border-radius: 30px;

                  img {
                    width: 100%;
                    object-fit: cover !important;
                  }
                }
              }

              .count {
                margin-left: -10px;
                background: var(--primary-500);
                border: 1px solid #ffffff;
                border-radius: 12px;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.1px;
                color: #ffffff;
                padding: 0px 9px;
              }
            }

            .right_part {
              max-width: 120px;

              button {
                @include block_btn();
              }
            }
          }
        }
      }
    }
  }
}

.special-offer-modal {
  position: fixed;
  bottom: 0;
  right: 0;
  // top: 0;
  // product Adujement Modal Width
  // width: 100%;
  // width: 54%;
  // width: 68%;

  width: 70%;
  // width: 662px;
  // width: 100%; ===== UNCOMMET
  height: calc(100vh - 64px);
  // height: calc(100vh - 104px);
  // padding: 0 16px;
  // background: var(--secondary-50);
  // border-radius: 16px 0px 0px 0px;
  z-index: 9999;
  translate: 100% 0;
  transition: 0.3s;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  border-radius: 16px 0 0 16px;

  @media only screen and (max-width: '1699px') {
    width: 80%;
  }

  @media only screen and (max-width: '1599px') {
    width: 85%;
  }

  @media only screen and (max-width: '1499px') {
    width: 92%;
  }

  @media only screen and (max-width: '1299px') {
    width: 98%;
  }

  // @media only screen and (max-width:"1099px") {
  //   width: 48%;
  // }

  @media only screen and (max-width: '1024px') {
    width: 100%;
    // top: 56px;
    // height: calc(100vh - 120px);
    height: 100%;
    z-index: 999999;
    top: 0px;
  }

  @media only screen and (max-width: '767px') {
    width: 100%;
    // top: 56px;
    // height: calc(100vh - 120px);
    z-index: 999999;

    top: 0px;
  }

  .colse-modal-icon-alignment {
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0 0 0;
    cursor: pointer;
  }

  .sidebar-modal-alignment {
    background-color: #fff;
    // background: var(--white);

    // background: #fff;
    // border-radius: 0 0px 0px 0px; ====UNCOMMET
    // border-radius: 16px 0px 0px 0px;
    border-radius: 16px 0 0 16px !important;
    padding: 16px;
    // padding: 0; ====UNCOMMET

    // product Adujement Modal Width
    // width: 54%;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    border-radius: 16px;

    &.productDetailsModalBg {
      background: #ffffff;
    }

    &.borderRaduisNone {
      border-radius: 0 !important;
    }

    @media only screen and (max-width: '1024px') {
      // height: calc(100vh - 56px - 64px);
      border-radius: 0px !important;
      padding: 0;
    }

    @media only screen and (max-width: '767px') {
      border-radius: 0 !important;
      // height: calc(100vh - 150px);
      // height: 100vh;
      // // display: none;
      // margin: 52px 0 64px 0;
    }

    .special-offer-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;

      .special-offer-banner-alignment {
        width: 100%;
        height: 189px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          border-radius: 8px 8px 0 0;
        }

        &::before {
          content: '';
          position: absolute;
          background: linear-gradient(360deg, #f8f9fb 0%, rgba(248, 249, 251, 0) 219.84%);
          border-radius: 8px 8px 0 0;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
        }

        .special-offer-banner-details-alignment {
          text-align: center;
          position: absolute;
          top: 58px;
          right: 0;
          left: 0;

          h2 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 32px;
            line-height: 48px;
            color: var(--secondary-900);
            padding: 0 0 16px 0;
            text-transform: uppercase;
          }

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey5);
          }
        }
      }

      .speaicl-offer-tab-alignment {
        padding: 27px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 32px;
          justify-content: center;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey2);
            cursor: pointer;

            &.offer-active {
              font-weight: 600;
              color: var(--grey5);
            }

            &:hover {
              font-weight: 600;
              color: var(--grey5);
            }
          }
        }
      }

      .special-offer-book-details-alignment {
        margin: 44px 127px;
        height: calc(100vh - 365px);
        overflow-y: auto;

        @media only screen and (max-width: '899px') {
          margin: 30px;
        }

        .speaicl-offer-book-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 24px;

          @media only screen and (max-width: '899px') {
            grid-template-columns: repeat(3, 1fr);
          }

          @media only screen and (max-width: '1199px') {
            grid-template-columns: repeat(3, 1fr);
          }

          .speaicl-offer-book-gridItem-alignment {
            .speaicl-offer-book-alignment {
              padding: 16px;

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .release-book-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // New Release Section Details

    .new-release-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 32px 56px;

      @media only screen and (max-width: '899px') {
        padding: 32px 40px;
      }

      .new-release-modal-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .new-release-heading-label-alignment {
          background: linear-gradient(94.09deg, #556ee6 0.21%, #6b21a8 100%);
          box-shadow: 1px 3px 3px rgba(95, 64, 175, 0.08);
          border-radius: 0px 0px 8px 0px;
          padding: 4px 16px;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--light-grey);
          }
        }

        .new-release-search-alignment {
          .new-release-input-alignment {
            width: 291px;
            min-width: 291px;
            max-width: 291px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }
        }
      }

      .new-realse-note-alignment {
        padding: 16px 0 0 0;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: var(--grey5);
        }
      }

      .new-realese-tab-alignment {
        padding: 14px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 32px;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey2);
            cursor: pointer;

            &.new-release-active {
              font-weight: 600;
              color: var(--grey5);
            }

            &:hover {
              font-weight: 600;
              color: var(--grey5);
            }
          }
        }
      }

      .new-realese-book-details-alignment {
        margin: 44px 0;
        height: calc(100vh - 320px);
        overflow-y: auto;

        .new-realese-book-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 24px;

          @media only screen and (max-width: '1199px') {
            grid-template-columns: repeat(3, 1fr);
          }

          .new-realese-book-gridItem-alignment {
            .new-realese-book-alignment {
              padding: 16px;

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .release-book-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Best Seller Section Details

    .best-seller-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 32px 56px;

      @media only screen and (max-width: '899px') {
        padding: 32px 40px;
      }

      .best-seller-modal-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .best-seller-heading-label-alignment {
          background: linear-gradient(263.94deg, #ffc554 7.44%, #744c00 86.5%);
          box-shadow: 1px 3px 3px rgba(95, 64, 175, 0.08);
          border-radius: 0px 0px 8px 0px;
          padding: 4px 16px;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--light-grey);
          }
        }

        .best-seller-search-alignment {
          .best-seller-input-alignment {
            width: 291px;
            min-width: 291px;
            max-width: 291px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }
        }
      }

      .best-seller-note-alignment {
        padding: 16px 0 0 0;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: var(--grey5);
        }
      }

      .best-seller-tab-alignment {
        padding: 14px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 32px;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey2);
            cursor: pointer;

            &.best-seller-active {
              font-weight: 600;
              color: var(--grey5);
            }

            &:hover {
              font-weight: 600;
              color: var(--grey5);
            }
          }
        }
      }

      .best-seller-book-details-alignment {
        margin: 44px 0;
        height: calc(100vh - 320px);
        overflow-y: auto;

        .best-seller-book-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 24px;

          @media only screen and (max-width: '1199px') {
            grid-template-columns: repeat(3, 1fr);
          }

          .best-seller-book-gridItem-alignment {
            .best-seller-book-alignment {
              padding: 16px;

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .best-seller-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Best Of The Year

    .best-ofthe-year-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 32px 56px;

      @media only screen and (max-width: '899px') {
        padding: 32px 40px;
      }

      .best-ofthe-year-modal-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .best-ofthe-year-heading-label-alignment {
          background: #1e2432;
          box-shadow: 1px 3px 3px rgba(95, 64, 175, 0.08);
          border-radius: 0px 0px 8px 0px;
          padding: 4px 16px;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--light-grey);
          }
        }

        .best-ofthe-year-search-alignment {
          .best-ofthe-year-input-alignment {
            width: 291px;
            min-width: 291px;
            max-width: 291px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }
        }
      }

      .best-ofthe-year-note-alignment {
        padding: 16px 0 0 0;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: var(--grey5);
        }
      }

      .best-ofthe-year-tab-alignment {
        padding: 14px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 32px;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey2);
            cursor: pointer;

            &.best-ofthe-year-active {
              font-weight: 600;
              color: var(--grey5);
            }

            &:hover {
              font-weight: 600;
              color: var(--grey5);
            }
          }
        }
      }

      .best-ofthe-year-book-details-alignment {
        margin: 44px 0;
        height: calc(100vh - 320px);
        overflow-y: auto;

        .best-ofthe-year-book-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 24px;

          @media only screen and (max-width: '1199px') {
            grid-template-columns: repeat(3, 1fr);
          }

          .best-ofthe-year-book-gridItem-alignment {
            .best-ofthe-year-book-alignment {
              padding: 16px;

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .best-ofthe-year-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Top Rated Book of the Year

    .top-rated-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 32px 56px;

      .top-rated-modal-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .top-rated-heading-label-alignment {
          background: #db5a00;
          box-shadow: 1px 3px 3px rgba(95, 64, 175, 0.08);
          border-radius: 0px 0px 8px 0px;
          padding: 4px 16px;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--light-grey);
          }
        }

        .top-rated-search-alignment {
          .top-rated-input-alignment {
            width: 291px;
            min-width: 291px;
            max-width: 291px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }
        }
      }

      .top-rated-note-alignment {
        padding: 16px 0 0 0;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: var(--grey5);
        }
      }

      .top-rated-tab-alignment {
        padding: 14px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 32px;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey2);
            cursor: pointer;

            &.top-rated-active {
              font-weight: 600;
              color: var(--grey5);
            }

            &:hover {
              font-weight: 600;
              color: var(--grey5);
            }
          }
        }
      }

      .top-rated-book-details-alignment {
        margin: 44px 0;
        height: calc(100vh - 320px);
        overflow-y: auto;

        .top-rated-book-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 24px;

          .top-rated-book-gridItem-alignment {
            .top-rated-book-alignment {
              padding: 16px;

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .top-rated-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Most Liked Book

    .most-liked-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 32px 56px;

      .most-liked-modal-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .most-liked-heading-label-alignment {
          background: #940000;
          box-shadow: 1px 3px 3px rgba(95, 64, 175, 0.08);
          border-radius: 0px 0px 8px 0px;
          padding: 4px 16px;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--light-grey);
          }
        }

        .most-liked-search-alignment {
          .most-liked-input-alignment {
            width: 291px;
            min-width: 291px;
            max-width: 291px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }
        }
      }

      .most-liked-note-alignment {
        padding: 16px 0 0 0;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: var(--grey5);
        }
      }

      .most-liked-tab-alignment {
        padding: 14px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 32px;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey2);
            cursor: pointer;

            &.most-liked-active {
              font-weight: 600;
              color: var(--grey5);
            }

            &:hover {
              font-weight: 600;
              color: var(--grey5);
            }
          }
        }
      }

      .most-liked-book-details-alignment {
        margin: 44px 0;
        height: calc(100vh - 320px);
        overflow-y: auto;

        .most-liked-book-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 24px;

          .most-liked-book-gridItem-alignment {
            .most-liked-book-alignment {
              padding: 16px;

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .most-liked-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // More Author Modal Section

    .more-author-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 22px 32px;

      .more-author-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 40px;

        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 32px;
          line-height: 48px;
          color: var(--secondary-900);
        }

        .more-author-search-input-alignment {
          width: 487px;
          min-width: 487px;
          max-width: 487px;
          height: 40px;
          background: #ffffff;
          border: 1px solid #f3f3f3;
          border-radius: 10px;
          display: flex;
          align-items: center;

          @media only screen and (max-width: '899px') {
            width: 280px;
            min-width: 280px;
            max-width: 280px;
          }

          img {
            width: 40px;
            min-width: 40px;
            max-width: 40px;
            height: 40px;
          }

          input {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            width: 100%;
            height: 100%;
            border-radius: 0 10px 10px 0;
            padding: 0 10px 0 0;

            &::placeholder {
              color: var(--grey3);
            }
          }
        }
      }

      .more-author-details-modal-body-alignment {
        margin: 35px 0 0 0;
        height: calc(100vh - 202px);
        overflow-y: auto;

        .more-author-grid-alignment {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 16px;

          @media only screen and (max-width: '899px') {
            grid-template-columns: repeat(2, 1fr);
          }

          .more-author-gridItem-alignment {
            .more-author-box-alignment {
              background: #ffffff;
              padding: 32px;

              .author-img-alignment {
                max-width: 166px;
                width: 166px;
                height: 166px;
                margin: auto;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 32px;
                }
              }

              .more-auther-details-alignment {
                padding: 16px 0 0 0;
                text-align: center;

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 30px;
                  color: var(--secondary-900);
                }

                .auther-following-details-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 17px;
                  padding: 8px 0 0 0;

                  .child-anme-alignment {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .auther-options-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 8px;
                  padding: 16px 0 0 0;

                  .follow-page-button-alignment {
                    button {
                      background: var(--primary-500);
                      border-radius: 8px;
                      height: 32px;
                      width: 117px;
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 21px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      color: var(--light-grey);
                      cursor: pointer;
                    }
                  }

                  .like-share-alignment {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    .box-alignment {
                      background: var(--light-grey);
                      border-radius: 8px;
                      width: 32px;
                      min-width: 32px;
                      max-width: 32px;
                      height: 32px;
                      padding: 4px;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Book Club Modal Section

    .book-club-modal-details-section {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 32px;

      .book-club-modal-details-alignment {
        .book-club-heading-alignment {
          padding: 0 0 0 15px;

          h5 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--secondary-900);
          }
        }

        .book-club-details-flex-alignment {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 32px 0 0 0;

          @media only screen and (max-width: '899px') {
            gap: 40px;
          }

          .book-search-alignment {
            width: 487px;
            min-width: 487px;
            max-width: 487px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            @media only screen and (max-width: '899px') {
              width: 280px;
              min-width: 280px;
              max-width: 280px;
            }

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }

          .create-club-button-alignment {
            button {
              background: var(--primary-500);
              border-radius: 8px;
              padding: 6px 12px;
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              color: var(--light-grey);
              cursor: pointer;
              border: none;
            }
          }
        }

        .book-club-details-body-alignment {
          margin: 32px 0 0 0;
          height: calc(100vh - 270px);
          overflow-y: auto;

          .book-club-grid-alignment {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 32px;

            @media only screen and (max-width: '1199px') {
              grid-template-columns: repeat(2, 1fr);
            }

            .book-club-details_cover {
              padding: 0;

              .card_fashion {
                width: 100%;
                position: relative;
                border-radius: 10px 10px 0px 0px;
                overflow: hidden;
                transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                height: 251px;
                max-height: 251px;

                &:hover {
                  transform: translateY(-5px);

                  .img_cover img {
                    transform: scale(1.05);
                  }
                }

                .img_cover {
                  object-fit: cover;
                  object-position: center;
                  height: 251px;
                  max-height: 251px;

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                  }
                }

                .card_details {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: flex-end;
                  padding: 15px;
                  background: linear-gradient(360deg, #0e1116 0%, rgba(30, 36, 50, 0) 100%);

                  .card_details_cover {
                    width: 100%;

                    h5 {
                      font-weight: 500;
                      font-size: 20px;
                      line-height: 30px;
                      color: #ffffff;
                    }

                    p {
                      font-weight: 400;
                      font-size: 12px;
                      line-height: 18px;
                      color: #ffffff;
                      margin: 4px 0 17px 0;
                    }

                    .users {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;

                      .left_part {
                        display: flex;
                        align-items: center;

                        .users_likes {
                          display: flex;
                          align-items: center;
                          margin-left: -10px;

                          .user_box {
                            width: 20px;
                            display: flex;
                            align-items: center;
                            height: 20px;
                            justify-content: center;
                            background: #fff;
                            object-position: center;
                            border: 1px solid #ffffff;
                            border-radius: 30px;

                            img {
                              width: 100%;
                              object-fit: cover !important;
                            }
                          }
                        }

                        .count {
                          margin-left: -10px;
                          background: var(--primary-500);
                          border: 1px solid #ffffff;
                          border-radius: 12px;
                          font-weight: 500;
                          font-size: 12px;
                          line-height: 18px;
                          letter-spacing: 0.1px;
                          color: #ffffff;
                          padding: 0px 9px;
                        }
                      }

                      .right_part {
                        max-width: 120px;

                        button {
                          @include block_btn();
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Author Modal Section

    .author-modal-section {
      // background: rgba(255, 255, 255, 0.6);
      // border: 1px solid #FFFFFF;
      // backdrop-filter: blur(32px);
      border-radius: 10px;
      width: 96%;
      height: 100vh;
      overflow-y: auto;

      @media only screen and (max-width: '899px') {
        width: 100%;
      }

      @media only screen and (max-width: '767px') {
        width: 100%;
      }

      .author-modal-all-details-alignment {
        .author-banner-bg-alignment {
          background: rgba(217, 217, 217, 0.4);
          backdrop-filter: blur(32px);
          border-radius: 10px;
          width: 100%;
          height: 122px;
          position: relative;

          @media only screen and (max-width: '767px') {
            margin: 8px 0 0 0;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }

          &::before {
            content: '';
            position: absolute;
            background: rgba(217, 217, 217, 0.4);
            backdrop-filter: blur(32px);
            border-radius: 10px;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            z-index: 99;
          }
        }

        .author-details-section {
          margin: -98px 24px 0 24px;
          background: rgba(255, 255, 255, 0.6);
          border: 1px solid #ffffff;
          backdrop-filter: blur(32px);
          border-radius: 8px;
          padding: 32px;
          // height: 100vh;
          // overflow-y: auto;

          @media only screen and (max-width: '767px') {
            margin: -106px 0 0 0;
            padding: 0;
            background: none;
            backdrop-filter: none;
            border-radius: none;
            border: none;
            position: relative;
            height: auto;
          }

          .author-details-flex-alignment {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            @media only screen and (max-width: '767px') {
              background: rgba(255, 255, 255, 0.6);
              border: 1px solid #ffffff;
              backdrop-filter: blur(32px);
              border-radius: 8px;
              padding: 16px;
              margin: 0 16px;
              display: block;
            }

            .author-all-profile-details-alignment {
              display: flex;
              align-items: center;
              gap: 16px;

              .author-img-alignment {
                width: 105px;
                max-width: 105px;
                min-width: 105px;
                height: 105px;

                @media only screen and (max-width: '767px') {
                  width: 87px;
                  max-width: 87px;
                  min-width: 87px;
                  height: 87px;
                }

                img {
                  width: 100%;
                  height: 100%;
                  border: 2px solid #ffffff;
                  border-radius: 24px;
                  object-fit: cover;
                }
              }

              .author-personal-details-alignment {
                h5 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 24px;
                  line-height: 36px;
                  color: var(--secondary-900);

                  @media only screen and (max-width: '767px') {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                  }
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey5);

                  @media only screen and (max-width: '767px') {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                  }
                }
              }

              .author-child-details-alignment {
                display: flex;
                align-items: center;
                gap: 16px;
                padding: 8px 0 0 0;

                @media only screen and (max-width: '767px') {
                  display: none;
                }

                .book-follow-like-alignment {
                  display: flex;
                  align-items: center;
                  gap: 4px;

                  .icon-alignment {
                    width: 20px;
                    height: 20px;

                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 20px;
                    color: #5f40af;
                  }
                }
              }

              .mobile-follow-details-alignment {
                display: flex;
                align-items: center;
                gap: 8px;
                display: none;

                @media only screen and (max-width: '767px') {
                  display: flex;
                  margin: 8px 0 0 0;
                }

                .mobile-option-alignment {
                  background: var(--light-grey);
                  border-radius: 8px;
                  width: 32px;
                  height: 32px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 4px;
                }

                .mobile-follow-page-button-alignment {
                  button {
                    color: var(--primary-500);
                    border-radius: 8px;
                    width: 117px;
                    height: 32px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: var(--light-grey);
                    border-radius: 8px;
                    cursor: pointer;
                  }
                }
              }
            }

            .mobile-author-child-details-alignment {
              display: flex;
              align-items: center;
              gap: 16px;
              padding: 8px 0 0 0;
              display: none;

              @media only screen and (max-width: '767px') {
                display: flex;
              }

              .mobile-book-follow-like-alignment {
                display: flex;
                align-items: center;
                gap: 4px;

                .mobile-icon-alignment {
                  width: 20px;
                  height: 20px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 18px;
                  color: #5f40af;
                }
              }
            }

            .follow-details-alignment {
              display: flex;
              align-items: center;
              gap: 8px;

              @media only screen and (max-width: '767px') {
                display: none;
              }

              .option-alignment {
                background: var(--light-grey);
                border-radius: 8px;
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 4px;
              }

              .follow-page-button-alignment {
                button {
                  background: var(--primary-500);
                  border-radius: 8px;
                  width: 117px;
                  height: 32px;
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 21px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: var(--light-grey);
                  cursor: pointer;
                }
              }
            }
          }

          .author-review-details-alignment {
            padding: 32px 0 0 0;

            @media only screen and (max-width: '767px') {
              padding: 8px 0 25px 16px;
              margin: 16px 0 0 0;
              background: #f4f5f8;
              border: 1px solid #ffffff;
              // backdrop-filter: blur(28.6539px);
            }

            .review-heading-alignment {
              display: flex;
              align-items: center;
              justify-content: space-between;

              h4 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: var(--secondary-900);
              }

              a {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                color: var(--primary-500);
                cursor: pointer;
              }
            }

            .author-child-review-details-alignment {
              padding: 24px 0 0 32px;
              display: flex;
              align-items: center;
              width: 100%;
              overflow-x: hidden;

              @media only screen and (max-width: '767px') {
                overflow-x: auto;
              }

              .author-child-all-detail-alignment {
                border-right: 1px solid var(--grey4);
                padding: 0 16px;

                &:first-child {
                  padding: 0 16px 0 0;
                }

                &:last-child {
                  padding: 0 0 0 16px;
                }

                .author-note-alignment {
                  padding: 16px 0 0 0;
                  max-width: 281px;
                  width: 281px;

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .profile-img-alignment {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  padding: 22px 0 0 0;

                  .child-author-img-alignment {
                    width: 35px;
                    height: 35px;

                    img {
                      width: 100%;
                      height: 100%;
                      border: 2px solid #ffffff;
                      border-radius: 50%;
                    }
                  }

                  .child-author-name-alignment {
                    p {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--secondary-900);
                    }
                  }
                }
              }
            }
          }

          .david-offer-book-alignment {
            padding: 48px 0 0 0;

            @media only screen and (max-width: '767px') {
              padding: 16px;
              margin: 8px 0 0 0;
              background: #f4f5f8;
              border: 1px solid #ffffff;
            }

            .david-offer-heading-alignment {
              @media only screen and (max-width: '767px') {
                display: flex;
                align-items: center;
                justify-content: space-between;
              }

              h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: var(--secondary-900);

                @media only screen and (max-width: '767px') {
                  font-size: 20px;
                  line-height: 30px;
                  max-width: 160px;
                }
              }

              a {
                display: none;

                @media only screen and (max-width: '767px') {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;
                  color: var(--primary-500);
                  display: flex;
                }
              }
            }

            .david-offer-details-alignment {
              padding: 51px 0 25px 0;
              width: 100%;

              @media only screen and (max-width: '767px') {
                padding: 12px 0 28px 0;
              }

              .david-book-other-space-alignment {
                padding: 0 12px;

                @media only screen and (max-width: '767px') {
                  padding: 0;
                }

                .david-offer-book-gridItem {
                  background: #ffffff;
                  border-radius: 10px;
                  height: 193px;
                  padding: 0 0 0 22px;
                  transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);

                  &:hover {
                    transform: translateY(-6px);
                    border-radius: 10px;
                    box-shadow: 2px 7px 15px rgba(95, 64, 175, 0.4);
                    padding: 0 0 0 16px;

                    .david-offer-book-details {
                      gap: 14px;
                      transition: 0.8s ease-in-out;

                      .book-cover-img {
                        width: 160px;
                        max-width: 160px;
                        min-width: 160px;
                        height: 240px;
                      }
                    }
                  }

                  .david-offer-book-details {
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    transition: 0.8s ease-in-out;

                    .book-cover-img {
                      width: 146px;
                      max-width: 146px;
                      min-width: 146px;
                      height: 224px;
                      margin: -20px 0 0 0;

                      img {
                        width: 100%;
                        height: 100%;
                        display: block;
                      }
                    }

                    .book-name-details-alignement {
                      width: 100%;

                      h5 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 36px;
                        color: var(--secondary-900);
                        cursor: pointer;

                        @media only screen and (max-width: '767px') {
                          font-size: 16px;
                          line-height: 24px;
                        }
                      }

                      p {
                        padding: 8px 0 0 0;
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 20px;
                        color: var(--grey2);

                        @media only screen and (max-width: '767px') {
                          padding: 16px 0 0 0;
                        }
                      }

                      .price-alignment-flex {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        @media only screen and (max-width: '767px') {
                          display: block;
                        }

                        .price-alignment {
                          @media only screen and (max-width: '767px') {
                            display: flex;
                            align-items: center;
                            gap: 4px;
                          }

                          h6 {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 24px;
                            line-height: 36px;
                            color: #474400;

                            @media only screen and (max-width: '767px') {
                              font-weight: 600;
                              font-size: 16px;
                              line-height: 24px;
                              color: #000000;
                            }
                          }

                          span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 30px;
                            color: var(--grey2);
                            display: block;
                            margin: -6px 0 0 0;
                            text-align: right;

                            @media only screen and (max-width: '767px') {
                              font-weight: 400;
                              font-size: 13px;
                              line-height: 20px;
                              color: var(--grey5);
                              margin: 2px 0;
                            }

                            del {
                              color: #ca0000;

                              @media only screen and (max-width: '767px') {
                                color: var(--grey5);
                              }
                            }
                          }
                        }

                        .offer-label-alignment {
                          background: #f1efb0;
                          padding: 9px 17px 7px 17px;

                          @media only screen and (max-width: '767px') {
                            margin: 5px 0 0 0;
                            width: 81px;
                            height: 36px;
                            padding: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          }

                          p {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 30px;
                            text-align: center;
                            color: #474400;
                            padding: 0;

                            @media only screen and (max-width: '767px') {
                              font-weight: 500;
                              font-size: 18px;
                              line-height: 27px;
                              color: #474400;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .david-other-book-details-alignment {
            padding: 43px 0 0 0;

            @media only screen and (max-width: '767px') {
              padding: 32px 16px;
            }

            .mobile-view-heading-alignment {
              display: none;

              @media only screen and (max-width: '767px') {
                display: flex;
                align-items: center;
                gap: 2px;
                padding: 0 0 18px 0;

                h4 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 30px;
                  color: var(--secondary-900);
                }

                span {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;
                  color: var(--grey2);
                }
              }
            }

            .david-other-book-filter-alignment {
              display: flex;
              align-items: center;
              justify-content: space-between;

              @media only screen and (max-width: '899px') {
                flex-wrap: wrap;
                gap: 20px;
              }

              .filter-seacch-alignment {
                display: flex;
                align-items: center;
                gap: 8px;

                @media only screen and (max-width: '767px') {
                  width: 100%;
                }

                .filter-alignment {
                  padding: 12px 8px 12px 17px;
                  display: flex;
                  align-items: center;
                  gap: 4px;
                  background: #ffffff;
                  border: 0.5px solid var(--grey4);
                  border-radius: 10px;

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: var(--grey5);
                  }
                }

                .seacrh-alignment {
                  background: #ffffff;
                  border: 1px solid #f3f3f3;
                  border-radius: 10px;
                  display: flex;
                  align-items: center;
                  width: 437px;
                  height: 40px;

                  @media only screen and (max-width: '767px') {
                    width: 100%;
                  }

                  img {
                    width: 40px;
                    min-width: 40px;
                    max-width: 40px;
                    height: 40px;
                  }

                  input {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    width: 100%;
                    height: 100%;
                    border-radius: 0 10px 10px 0;
                    padding: 0 10px 0 0;

                    &::placeholder {
                      color: var(--grey3);
                    }
                  }
                }
              }

              .category-alignment {
                display: flex;
                align-items: center;
                gap: 8px;

                @media only screen and (max-width: '767px') {
                  display: none;
                }

                label {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 21px;
                  color: var(--secondary-900);
                }

                .category-dropdown-alignment {
                  width: 144px;
                  height: 40px;
                  background: #ffffff;
                  border: 1px solid var(--grey4);
                  border-radius: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 8px;

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: var(--grey5);
                  }

                  img {
                    cursor: pointer;
                  }
                }
              }
            }

            .david-other-all-details-alignment {
              padding: 32px 0 0 0;

              @media only screen and (max-width: '767px') {
                padding: 18px 0 0 0;
              }

              .david-other-all-details-grid-alignment {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 30px;

                @media only screen and (max-width: '1199px') {
                  grid-template-columns: repeat(3, 1fr);
                }

                @media only screen and (max-width: '899px') {
                  grid-template-columns: repeat(2, 1fr);
                }

                @media only screen and (max-width: '767px') {
                  grid-template-columns: repeat(2, 1fr);
                  gap: 32px 0;
                }

                .david-other-all-details-gridItem-alignment {
                  .most-liked-book-alignment {
                    padding: 16px;

                    @media only screen and (max-width: '767px') {
                      padding: 6px;
                    }

                    &:hover {
                      transform: translateY(-5px);
                      background: rgba(85, 110, 230, 0.1);
                      border-radius: 10px;

                      .book-img-alignment {
                        box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                      }
                    }

                    &.book-active-details {
                      transform: translateY(-5px);
                      background: rgba(85, 110, 230, 0.1);
                      border-radius: 10px;

                      .book-img-alignment {
                        box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                      }
                    }

                    .book-img-alignment {
                      height: 224px;

                      img {
                        width: 100%;
                        height: 100%;
                        display: block;
                      }
                    }

                    .most-liked-name-alignment {
                      text-align: center;
                      padding: 16px 0 0 0;
                      margin: 0 0 24px 0;

                      &:last-child {
                        margin: 0;
                      }

                      h6 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--secondary-900);
                      }

                      p {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 20px;
                        color: var(--grey2);
                        text-align: center;
                      }

                      .book-rate-alignment {
                        display: flex;
                        align-items: center;
                        padding: 8px 0 0 0;
                        justify-content: center;
                        gap: 4px;

                        .hike {
                          display: flex;
                          align-items: center;

                          .inter {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 20px;
                            color: #70c217;

                            img {
                              margin-right: 3px;
                            }
                          }

                          .incris {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 20px;
                            color: #70c217;
                            display: flex;
                            align-items: center;

                            img {
                              margin-right: 3px;
                            }
                          }
                        }

                        .rating {
                          display: flex;
                          align-items: center;

                          @media only screen and (max-width: 799px) {
                            display: none;
                          }

                          .rating_point {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 20px;
                            color: var(--grey2);
                          }

                          .stars {
                            display: flex;
                            align-items: center;
                            margin: 0 2px;
                          }

                          .num {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 20px;
                            color: var(--grey2);
                          }
                        }
                      }

                      .book-price-alignment {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 5px;
                        padding: 11px 0 0 0;

                        h5 {
                          font-family: 'Poppins', sans-serif;
                          font-weight: 600;
                          font-size: 16px;
                          line-height: 24px;
                          color: #000000;
                        }

                        span {
                          font-family: 'Poppins', sans-serif;
                          font-weight: 400;
                          font-size: 13px;
                          line-height: 20px;
                          color: var(--grey5);
                        }
                      }

                      .like-share-alignment {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 16px;

                        .like-alignment {
                          display: flex;
                          align-items: center;
                          gap: 2px;

                          span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 20px;
                            color: var(--grey2);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.special-offer-modal.open {
  translate: 0;
}

.product-tumbnail-preview-side-alignment {
  width: 46%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(30, 36, 50, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.overlay.open {
  opacity: 1;
  visibility: visible;
  z-index: 9999;
}

.special-offer-modal-seller {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 70%;
  height: calc(100vh - 64px);
  // height: calc(100vh - 104px);
  z-index: 999999;
  translate: 100% 0;
  transition: 0.3s;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  background: #fff;
  border-radius: 16px 0 0 16px;

  @media only screen and (max-width: '1499px') {
    width: 96%;
  }

  @media only screen and (max-width: '1024px') {
    height: 100%;
    bottom: 0;
    overflow-y: auto;
    width: 100%;
    border-radius: 0;
  }

  @media only screen and (max-width: '767px') {
    width: 100%;
    top: 0;
    height: 100%;
  }

  .sidebar-modal-alignment {
    // background: #FFF;
    border-radius: 16px 0px 0px 16px;
    padding: 16px;
    width: 100%;
    height: 100%;
    overflow: auto;

    @media only screen and (max-width: '1024px') {
      height: auto;
      border-radius: 0;
      padding: 0;
    }

    @media only screen and (max-width: '767px') {
      border-radius: 0;
      padding: 0;
    }
  }
}

.special-offer-modal-seller.open {
  translate: 0;
}

.add-product-modal-contain {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  height: calc(100vh - 64px);
  // height: calc(100vh - 104px);

  z-index: 999999;
  translate: 100% 0;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  // background: #ECEEF4;
  border-radius: 16px 0 0 16px;
  // width: 60.7%;

  // @media only screen and (max-width: '1799px') {
  //   width: 70.7%;
  // }

  @media only screen and (max-width: '1024px') {
    height: 100%;
    bottom: 0;
    overflow-y: auto;
    width: 100%;
    border-radius: 0;
  }

  @media only screen and (max-width: '767px') {
    width: 100%;
    top: 0;
    height: 100%;
  }

  .container-add-product-modal {
    background: #fff;
    border-radius: 16px 0px 0px 16px;
    padding: 16px;
    // width: 100%;
    height: 100%;
    width: 60.7%;

    @media only screen and (max-width: '1799px') {
      width: 70.7%;
    }

    @media only screen and (max-width: '1024px') {
      height: 100%;
      border-radius: 0;
      padding: 0;
      width: 100%;
    }

    @media only screen and (max-width: '767px') {
      border-radius: 0;
      padding: 0;
    }

    .special-offer-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;

      .special-offer-banner-alignment {
        width: 100%;
        height: 189px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          border-radius: 8px 8px 0 0;
        }

        &::before {
          content: '';
          position: absolute;
          background: linear-gradient(360deg, #f8f9fb 0%, rgba(248, 249, 251, 0) 219.84%);
          border-radius: 8px 8px 0 0;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
        }

        .special-offer-banner-details-alignment {
          text-align: center;
          position: absolute;
          top: 58px;
          right: 0;
          left: 0;

          h2 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 32px;
            line-height: 48px;
            color: var(--secondary-900);
            padding: 0 0 16px 0;
            text-transform: uppercase;
          }

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey5);
          }
        }
      }

      .speaicl-offer-tab-alignment {
        padding: 27px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 32px;
          justify-content: center;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey2);
            cursor: pointer;

            &.offer-active {
              font-weight: 600;
              color: var(--grey5);
            }

            &:hover {
              font-weight: 600;
              color: var(--grey5);
            }
          }
        }
      }

      .special-offer-book-details-alignment {
        margin: 44px 127px;
        height: calc(100vh - 365px);
        overflow-y: auto;

        @media only screen and (max-width: '899px') {
          margin: 30px;
        }

        .speaicl-offer-book-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 24px;

          @media only screen and (max-width: '899px') {
            grid-template-columns: repeat(3, 1fr);
          }

          @media only screen and (max-width: '1199px') {
            grid-template-columns: repeat(3, 1fr);
          }

          .speaicl-offer-book-gridItem-alignment {
            .speaicl-offer-book-alignment {
              padding: 16px;

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .release-book-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // New Release Section Details

    .new-release-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 32px 56px;

      @media only screen and (max-width: '899px') {
        padding: 32px 40px;
      }

      .new-release-modal-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .new-release-heading-label-alignment {
          background: linear-gradient(94.09deg, #556ee6 0.21%, #6b21a8 100%);
          box-shadow: 1px 3px 3px rgba(95, 64, 175, 0.08);
          border-radius: 0px 0px 8px 0px;
          padding: 4px 16px;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--light-grey);
          }
        }

        .new-release-search-alignment {
          .new-release-input-alignment {
            width: 291px;
            min-width: 291px;
            max-width: 291px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }
        }
      }

      .new-realse-note-alignment {
        padding: 16px 0 0 0;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: var(--grey5);
        }
      }

      .new-realese-tab-alignment {
        padding: 14px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 32px;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey2);
            cursor: pointer;

            &.new-release-active {
              font-weight: 600;
              color: var(--grey5);
            }

            &:hover {
              font-weight: 600;
              color: var(--grey5);
            }
          }
        }
      }

      .new-realese-book-details-alignment {
        margin: 44px 0;
        height: calc(100vh - 320px);
        overflow-y: auto;

        .new-realese-book-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 24px;

          @media only screen and (max-width: '1199px') {
            grid-template-columns: repeat(3, 1fr);
          }

          .new-realese-book-gridItem-alignment {
            .new-realese-book-alignment {
              padding: 16px;

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .release-book-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Best Seller Section Details

    .best-seller-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 32px 56px;

      @media only screen and (max-width: '899px') {
        padding: 32px 40px;
      }

      .best-seller-modal-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .best-seller-heading-label-alignment {
          background: linear-gradient(263.94deg, #ffc554 7.44%, #744c00 86.5%);
          box-shadow: 1px 3px 3px rgba(95, 64, 175, 0.08);
          border-radius: 0px 0px 8px 0px;
          padding: 4px 16px;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--light-grey);
          }
        }

        .best-seller-search-alignment {
          .best-seller-input-alignment {
            width: 291px;
            min-width: 291px;
            max-width: 291px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }
        }
      }

      .best-seller-note-alignment {
        padding: 16px 0 0 0;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: var(--grey5);
        }
      }

      .best-seller-tab-alignment {
        padding: 14px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 32px;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey2);
            cursor: pointer;

            &.best-seller-active {
              font-weight: 600;
              color: var(--grey5);
            }

            &:hover {
              font-weight: 600;
              color: var(--grey5);
            }
          }
        }
      }

      .best-seller-book-details-alignment {
        margin: 44px 0;
        height: calc(100vh - 320px);
        overflow-y: auto;

        .best-seller-book-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 24px;

          @media only screen and (max-width: '1199px') {
            grid-template-columns: repeat(3, 1fr);
          }

          .best-seller-book-gridItem-alignment {
            .best-seller-book-alignment {
              padding: 16px;

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .best-seller-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Best Of The Year

    .best-ofthe-year-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 32px 56px;

      @media only screen and (max-width: '899px') {
        padding: 32px 40px;
      }

      .best-ofthe-year-modal-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .best-ofthe-year-heading-label-alignment {
          background: #1e2432;
          box-shadow: 1px 3px 3px rgba(95, 64, 175, 0.08);
          border-radius: 0px 0px 8px 0px;
          padding: 4px 16px;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--light-grey);
          }
        }

        .best-ofthe-year-search-alignment {
          .best-ofthe-year-input-alignment {
            width: 291px;
            min-width: 291px;
            max-width: 291px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }
        }
      }

      .best-ofthe-year-note-alignment {
        padding: 16px 0 0 0;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: var(--grey5);
        }
      }

      .best-ofthe-year-tab-alignment {
        padding: 14px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 32px;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey2);
            cursor: pointer;

            &.best-ofthe-year-active {
              font-weight: 600;
              color: var(--grey5);
            }

            &:hover {
              font-weight: 600;
              color: var(--grey5);
            }
          }
        }
      }

      .best-ofthe-year-book-details-alignment {
        margin: 44px 0;
        height: calc(100vh - 320px);
        overflow-y: auto;

        .best-ofthe-year-book-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 24px;

          @media only screen and (max-width: '1199px') {
            grid-template-columns: repeat(3, 1fr);
          }

          .best-ofthe-year-book-gridItem-alignment {
            .best-ofthe-year-book-alignment {
              padding: 16px;

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .best-ofthe-year-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Top Rated Book of the Year

    .top-rated-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 32px 56px;

      .top-rated-modal-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .top-rated-heading-label-alignment {
          background: #db5a00;
          box-shadow: 1px 3px 3px rgba(95, 64, 175, 0.08);
          border-radius: 0px 0px 8px 0px;
          padding: 4px 16px;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--light-grey);
          }
        }

        .top-rated-search-alignment {
          .top-rated-input-alignment {
            width: 291px;
            min-width: 291px;
            max-width: 291px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }
        }
      }

      .top-rated-note-alignment {
        padding: 16px 0 0 0;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: var(--grey5);
        }
      }

      .top-rated-tab-alignment {
        padding: 14px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 32px;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey2);
            cursor: pointer;

            &.top-rated-active {
              font-weight: 600;
              color: var(--grey5);
            }

            &:hover {
              font-weight: 600;
              color: var(--grey5);
            }
          }
        }
      }

      .top-rated-book-details-alignment {
        margin: 44px 0;
        height: calc(100vh - 320px);
        overflow-y: auto;

        .top-rated-book-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 24px;

          .top-rated-book-gridItem-alignment {
            .top-rated-book-alignment {
              padding: 16px;

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .top-rated-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Most Liked Book

    .most-liked-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 32px 56px;

      .most-liked-modal-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .most-liked-heading-label-alignment {
          background: #940000;
          box-shadow: 1px 3px 3px rgba(95, 64, 175, 0.08);
          border-radius: 0px 0px 8px 0px;
          padding: 4px 16px;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--light-grey);
          }
        }

        .most-liked-search-alignment {
          .most-liked-input-alignment {
            width: 291px;
            min-width: 291px;
            max-width: 291px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }
        }
      }

      .most-liked-note-alignment {
        padding: 16px 0 0 0;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: var(--grey5);
        }
      }

      .most-liked-tab-alignment {
        padding: 14px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 32px;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey2);
            cursor: pointer;

            &.most-liked-active {
              font-weight: 600;
              color: var(--grey5);
            }

            &:hover {
              font-weight: 600;
              color: var(--grey5);
            }
          }
        }
      }

      .most-liked-book-details-alignment {
        margin: 44px 0;
        height: calc(100vh - 320px);
        overflow-y: auto;

        .most-liked-book-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 24px;

          .most-liked-book-gridItem-alignment {
            .most-liked-book-alignment {
              padding: 16px;

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .most-liked-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // More Author Modal Section

    .more-author-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 22px 32px;

      .more-author-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 40px;

        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 32px;
          line-height: 48px;
          color: var(--secondary-900);
        }

        .more-author-search-input-alignment {
          width: 487px;
          min-width: 487px;
          max-width: 487px;
          height: 40px;
          background: #ffffff;
          border: 1px solid #f3f3f3;
          border-radius: 10px;
          display: flex;
          align-items: center;

          @media only screen and (max-width: '899px') {
            width: 280px;
            min-width: 280px;
            max-width: 280px;
          }

          img {
            width: 40px;
            min-width: 40px;
            max-width: 40px;
            height: 40px;
          }

          input {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            width: 100%;
            height: 100%;
            border-radius: 0 10px 10px 0;
            padding: 0 10px 0 0;

            &::placeholder {
              color: var(--grey3);
            }
          }
        }
      }

      .more-author-details-modal-body-alignment {
        margin: 35px 0 0 0;
        height: calc(100vh - 202px);
        overflow-y: auto;

        .more-author-grid-alignment {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 16px;

          @media only screen and (max-width: '899px') {
            grid-template-columns: repeat(2, 1fr);
          }

          .more-author-gridItem-alignment {
            .more-author-box-alignment {
              background: #ffffff;
              padding: 32px;

              .author-img-alignment {
                max-width: 166px;
                width: 166px;
                height: 166px;
                margin: auto;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 32px;
                }
              }

              .more-auther-details-alignment {
                padding: 16px 0 0 0;
                text-align: center;

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 30px;
                  color: var(--secondary-900);
                }

                .auther-following-details-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 17px;
                  padding: 8px 0 0 0;

                  .child-anme-alignment {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .auther-options-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 8px;
                  padding: 16px 0 0 0;

                  .follow-page-button-alignment {
                    button {
                      background: var(--primary-500);
                      border-radius: 8px;
                      height: 32px;
                      width: 117px;
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 21px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      color: var(--light-grey);
                      cursor: pointer;
                    }
                  }

                  .like-share-alignment {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    .box-alignment {
                      background: var(--light-grey);
                      border-radius: 8px;
                      width: 32px;
                      min-width: 32px;
                      max-width: 32px;
                      height: 32px;
                      padding: 4px;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Book Club Modal Section

    .book-club-modal-details-section {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 32px;

      .book-club-modal-details-alignment {
        .book-club-heading-alignment {
          padding: 0 0 0 15px;

          h5 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--secondary-900);
          }
        }

        .book-club-details-flex-alignment {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 32px 0 0 0;

          @media only screen and (max-width: '899px') {
            gap: 40px;
          }

          .book-search-alignment {
            width: 487px;
            min-width: 487px;
            max-width: 487px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            @media only screen and (max-width: '899px') {
              width: 280px;
              min-width: 280px;
              max-width: 280px;
            }

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }

          .create-club-button-alignment {
            button {
              background: var(--primary-500);
              border-radius: 8px;
              padding: 6px 12px;
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              color: var(--light-grey);
              cursor: pointer;
              border: none;
            }
          }
        }

        .book-club-details-body-alignment {
          margin: 32px 0 0 0;
          height: calc(100vh - 270px);
          overflow-y: auto;

          .book-club-grid-alignment {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 32px;

            @media only screen and (max-width: '1199px') {
              grid-template-columns: repeat(2, 1fr);
            }

            .book-club-details_cover {
              padding: 0;

              .card_fashion {
                width: 100%;
                position: relative;
                border-radius: 10px 10px 0px 0px;
                overflow: hidden;
                transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                height: 251px;
                max-height: 251px;

                &:hover {
                  transform: translateY(-5px);

                  .img_cover img {
                    transform: scale(1.05);
                  }
                }

                .img_cover {
                  object-fit: cover;
                  object-position: center;
                  height: 251px;
                  max-height: 251px;

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                  }
                }

                .card_details {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: flex-end;
                  padding: 15px;
                  background: linear-gradient(360deg, #0e1116 0%, rgba(30, 36, 50, 0) 100%);

                  .card_details_cover {
                    width: 100%;

                    h5 {
                      font-weight: 500;
                      font-size: 20px;
                      line-height: 30px;
                      color: #ffffff;
                    }

                    p {
                      font-weight: 400;
                      font-size: 12px;
                      line-height: 18px;
                      color: #ffffff;
                      margin: 4px 0 17px 0;
                    }

                    .users {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;

                      .left_part {
                        display: flex;
                        align-items: center;

                        .users_likes {
                          display: flex;
                          align-items: center;
                          margin-left: -10px;

                          .user_box {
                            width: 20px;
                            display: flex;
                            align-items: center;
                            height: 20px;
                            justify-content: center;
                            background: #fff;
                            object-position: center;
                            border: 1px solid #ffffff;
                            border-radius: 30px;

                            img {
                              width: 100%;
                              object-fit: cover !important;
                            }
                          }
                        }

                        .count {
                          margin-left: -10px;
                          background: var(--primary-500);
                          border: 1px solid #ffffff;
                          border-radius: 12px;
                          font-weight: 500;
                          font-size: 12px;
                          line-height: 18px;
                          letter-spacing: 0.1px;
                          color: #ffffff;
                          padding: 0px 9px;
                        }
                      }

                      .right_part {
                        max-width: 120px;

                        button {
                          @include block_btn();
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Author Modal Section

    .author-modal-section {
      // background: rgba(255, 255, 255, 0.6);
      // border: 1px solid #FFFFFF;
      // backdrop-filter: blur(32px);
      border-radius: 10px;
      width: 96%;
      height: 100vh;
      overflow-y: auto;

      @media only screen and (max-width: '899px') {
        width: 100%;
      }

      @media only screen and (max-width: '767px') {
        width: 100%;
      }

      .author-modal-all-details-alignment {
        .author-banner-bg-alignment {
          background: rgba(217, 217, 217, 0.4);
          backdrop-filter: blur(32px);
          border-radius: 10px;
          width: 100%;
          height: 122px;
          position: relative;

          @media only screen and (max-width: '767px') {
            margin: 8px 0 0 0;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }

          &::before {
            content: '';
            position: absolute;
            background: rgba(217, 217, 217, 0.4);
            backdrop-filter: blur(32px);
            border-radius: 10px;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            z-index: 99;
          }
        }

        .author-details-section {
          margin: -98px 24px 0 24px;
          background: rgba(255, 255, 255, 0.6);
          border: 1px solid #ffffff;
          backdrop-filter: blur(32px);
          border-radius: 8px;
          padding: 32px;
          // height: 100vh;
          // overflow-y: auto;

          @media only screen and (max-width: '767px') {
            margin: -106px 0 0 0;
            padding: 0;
            background: none;
            backdrop-filter: none;
            border-radius: none;
            border: none;
            position: relative;
            height: auto;
          }

          .author-details-flex-alignment {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            @media only screen and (max-width: '767px') {
              background: rgba(255, 255, 255, 0.6);
              border: 1px solid #ffffff;
              backdrop-filter: blur(32px);
              border-radius: 8px;
              padding: 16px;
              margin: 0 16px;
              display: block;
            }

            .author-all-profile-details-alignment {
              display: flex;
              align-items: center;
              gap: 16px;

              .author-img-alignment {
                width: 105px;
                max-width: 105px;
                min-width: 105px;
                height: 105px;

                @media only screen and (max-width: '767px') {
                  width: 87px;
                  max-width: 87px;
                  min-width: 87px;
                  height: 87px;
                }

                img {
                  width: 100%;
                  height: 100%;
                  border: 2px solid #ffffff;
                  border-radius: 24px;
                  object-fit: cover;
                }
              }

              .author-personal-details-alignment {
                h5 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 24px;
                  line-height: 36px;
                  color: var(--secondary-900);

                  @media only screen and (max-width: '767px') {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                  }
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey5);

                  @media only screen and (max-width: '767px') {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                  }
                }
              }

              .author-child-details-alignment {
                display: flex;
                align-items: center;
                gap: 16px;
                padding: 8px 0 0 0;

                @media only screen and (max-width: '767px') {
                  display: none;
                }

                .book-follow-like-alignment {
                  display: flex;
                  align-items: center;
                  gap: 4px;

                  .icon-alignment {
                    width: 20px;
                    height: 20px;

                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 20px;
                    color: #5f40af;
                  }
                }
              }

              .mobile-follow-details-alignment {
                display: flex;
                align-items: center;
                gap: 8px;
                display: none;

                @media only screen and (max-width: '767px') {
                  display: flex;
                  margin: 8px 0 0 0;
                }

                .mobile-option-alignment {
                  background: var(--light-grey);
                  border-radius: 8px;
                  width: 32px;
                  height: 32px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 4px;
                }

                .mobile-follow-page-button-alignment {
                  button {
                    color: var(--primary-500);
                    border-radius: 8px;
                    width: 117px;
                    height: 32px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: var(--light-grey);
                    border-radius: 8px;
                    cursor: pointer;
                  }
                }
              }
            }

            .mobile-author-child-details-alignment {
              display: flex;
              align-items: center;
              gap: 16px;
              padding: 8px 0 0 0;
              display: none;

              @media only screen and (max-width: '767px') {
                display: flex;
              }

              .mobile-book-follow-like-alignment {
                display: flex;
                align-items: center;
                gap: 4px;

                .mobile-icon-alignment {
                  width: 20px;
                  height: 20px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 18px;
                  color: #5f40af;
                }
              }
            }

            .follow-details-alignment {
              display: flex;
              align-items: center;
              gap: 8px;

              @media only screen and (max-width: '767px') {
                display: none;
              }

              .option-alignment {
                background: var(--light-grey);
                border-radius: 8px;
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 4px;
              }

              .follow-page-button-alignment {
                button {
                  background: var(--primary-500);
                  border-radius: 8px;
                  width: 117px;
                  height: 32px;
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 21px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: var(--light-grey);
                  cursor: pointer;
                }
              }
            }
          }

          .author-review-details-alignment {
            padding: 32px 0 0 0;

            @media only screen and (max-width: '767px') {
              padding: 8px 0 25px 16px;
              margin: 16px 0 0 0;
              background: #f4f5f8;
              border: 1px solid #ffffff;
              // backdrop-filter: blur(28.6539px);
            }

            .review-heading-alignment {
              display: flex;
              align-items: center;
              justify-content: space-between;

              h4 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: var(--secondary-900);
              }

              a {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                color: var(--primary-500);
                cursor: pointer;
              }
            }

            .author-child-review-details-alignment {
              padding: 24px 0 0 32px;
              display: flex;
              align-items: center;
              width: 100%;
              overflow-x: hidden;

              @media only screen and (max-width: '767px') {
                overflow-x: auto;
              }

              .author-child-all-detail-alignment {
                border-right: 1px solid var(--grey4);
                padding: 0 16px;

                &:first-child {
                  padding: 0 16px 0 0;
                }

                &:last-child {
                  padding: 0 0 0 16px;
                }

                .author-note-alignment {
                  padding: 16px 0 0 0;
                  max-width: 281px;
                  width: 281px;

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .profile-img-alignment {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  padding: 22px 0 0 0;

                  .child-author-img-alignment {
                    width: 35px;
                    height: 35px;

                    img {
                      width: 100%;
                      height: 100%;
                      border: 2px solid #ffffff;
                      border-radius: 50%;
                    }
                  }

                  .child-author-name-alignment {
                    p {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--secondary-900);
                    }
                  }
                }
              }
            }
          }

          .david-offer-book-alignment {
            padding: 48px 0 0 0;

            @media only screen and (max-width: '767px') {
              padding: 16px;
              margin: 8px 0 0 0;
              background: #f4f5f8;
              border: 1px solid #ffffff;
            }

            .david-offer-heading-alignment {
              @media only screen and (max-width: '767px') {
                display: flex;
                align-items: center;
                justify-content: space-between;
              }

              h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: var(--secondary-900);

                @media only screen and (max-width: '767px') {
                  font-size: 20px;
                  line-height: 30px;
                  max-width: 160px;
                }
              }

              a {
                display: none;

                @media only screen and (max-width: '767px') {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;
                  color: var(--primary-500);
                  display: flex;
                }
              }
            }

            .david-offer-details-alignment {
              padding: 51px 0 25px 0;
              width: 100%;

              @media only screen and (max-width: '767px') {
                padding: 12px 0 28px 0;
              }

              .david-book-other-space-alignment {
                padding: 0 12px;

                @media only screen and (max-width: '767px') {
                  padding: 0;
                }

                .david-offer-book-gridItem {
                  background: #ffffff;
                  border-radius: 10px;
                  height: 193px;
                  padding: 0 0 0 22px;
                  transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);

                  &:hover {
                    transform: translateY(-6px);
                    border-radius: 10px;
                    box-shadow: 2px 7px 15px rgba(95, 64, 175, 0.4);
                    padding: 0 0 0 16px;

                    .david-offer-book-details {
                      gap: 14px;
                      transition: 0.8s ease-in-out;

                      .book-cover-img {
                        width: 160px;
                        max-width: 160px;
                        min-width: 160px;
                        height: 240px;
                      }
                    }
                  }

                  .david-offer-book-details {
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    transition: 0.8s ease-in-out;

                    .book-cover-img {
                      width: 146px;
                      max-width: 146px;
                      min-width: 146px;
                      height: 224px;
                      margin: -20px 0 0 0;

                      img {
                        width: 100%;
                        height: 100%;
                        display: block;
                      }
                    }

                    .book-name-details-alignement {
                      width: 100%;

                      h5 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 36px;
                        color: var(--secondary-900);
                        cursor: pointer;

                        @media only screen and (max-width: '767px') {
                          font-size: 16px;
                          line-height: 24px;
                        }
                      }

                      p {
                        padding: 8px 0 0 0;
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 20px;
                        color: var(--grey2);

                        @media only screen and (max-width: '767px') {
                          padding: 16px 0 0 0;
                        }
                      }

                      .price-alignment-flex {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        @media only screen and (max-width: '767px') {
                          display: block;
                        }

                        .price-alignment {
                          @media only screen and (max-width: '767px') {
                            display: flex;
                            align-items: center;
                            gap: 4px;
                          }

                          h6 {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 24px;
                            line-height: 36px;
                            color: #474400;

                            @media only screen and (max-width: '767px') {
                              font-weight: 600;
                              font-size: 16px;
                              line-height: 24px;
                              color: #000000;
                            }
                          }

                          span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 30px;
                            color: var(--grey2);
                            display: block;
                            margin: -6px 0 0 0;
                            text-align: right;

                            @media only screen and (max-width: '767px') {
                              font-weight: 400;
                              font-size: 13px;
                              line-height: 20px;
                              color: var(--grey5);
                              margin: 2px 0;
                            }

                            del {
                              color: #ca0000;

                              @media only screen and (max-width: '767px') {
                                color: var(--grey5);
                              }
                            }
                          }
                        }

                        .offer-label-alignment {
                          background: #f1efb0;
                          padding: 9px 17px 7px 17px;

                          @media only screen and (max-width: '767px') {
                            margin: 5px 0 0 0;
                            width: 81px;
                            height: 36px;
                            padding: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          }

                          p {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 30px;
                            text-align: center;
                            color: #474400;
                            padding: 0;

                            @media only screen and (max-width: '767px') {
                              font-weight: 500;
                              font-size: 18px;
                              line-height: 27px;
                              color: #474400;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .david-other-book-details-alignment {
            padding: 43px 0 0 0;

            @media only screen and (max-width: '767px') {
              padding: 32px 16px;
            }

            .mobile-view-heading-alignment {
              display: none;

              @media only screen and (max-width: '767px') {
                display: flex;
                align-items: center;
                gap: 2px;
                padding: 0 0 18px 0;

                h4 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 30px;
                  color: var(--secondary-900);
                }

                span {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;
                  color: var(--grey2);
                }
              }
            }

            .david-other-book-filter-alignment {
              display: flex;
              align-items: center;
              justify-content: space-between;

              @media only screen and (max-width: '899px') {
                flex-wrap: wrap;
                gap: 20px;
              }

              .filter-seacch-alignment {
                display: flex;
                align-items: center;
                gap: 8px;

                @media only screen and (max-width: '767px') {
                  width: 100%;
                }

                .filter-alignment {
                  padding: 12px 8px 12px 17px;
                  display: flex;
                  align-items: center;
                  gap: 4px;
                  background: #ffffff;
                  border: 0.5px solid var(--grey4);
                  border-radius: 10px;

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: var(--grey5);
                  }
                }

                .seacrh-alignment {
                  background: #ffffff;
                  border: 1px solid #f3f3f3;
                  border-radius: 10px;
                  display: flex;
                  align-items: center;
                  width: 437px;
                  height: 40px;

                  @media only screen and (max-width: '767px') {
                    width: 100%;
                  }

                  img {
                    width: 40px;
                    min-width: 40px;
                    max-width: 40px;
                    height: 40px;
                  }

                  input {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    width: 100%;
                    height: 100%;
                    border-radius: 0 10px 10px 0;
                    padding: 0 10px 0 0;

                    &::placeholder {
                      color: var(--grey3);
                    }
                  }
                }
              }

              .category-alignment {
                display: flex;
                align-items: center;
                gap: 8px;

                @media only screen and (max-width: '767px') {
                  display: none;
                }

                label {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 21px;
                  color: var(--secondary-900);
                }

                .category-dropdown-alignment {
                  width: 144px;
                  height: 40px;
                  background: #ffffff;
                  border: 1px solid var(--grey4);
                  border-radius: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 8px;

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: var(--grey5);
                  }

                  img {
                    cursor: pointer;
                  }
                }
              }
            }

            .david-other-all-details-alignment {
              padding: 32px 0 0 0;

              @media only screen and (max-width: '767px') {
                padding: 18px 0 0 0;
              }

              .david-other-all-details-grid-alignment {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 30px;

                @media only screen and (max-width: '1199px') {
                  grid-template-columns: repeat(3, 1fr);
                }

                @media only screen and (max-width: '899px') {
                  grid-template-columns: repeat(2, 1fr);
                }

                @media only screen and (max-width: '767px') {
                  grid-template-columns: repeat(2, 1fr);
                  gap: 32px 0;
                }

                .david-other-all-details-gridItem-alignment {
                  .most-liked-book-alignment {
                    padding: 16px;

                    @media only screen and (max-width: '767px') {
                      padding: 6px;
                    }

                    &:hover {
                      transform: translateY(-5px);
                      background: rgba(85, 110, 230, 0.1);
                      border-radius: 10px;

                      .book-img-alignment {
                        box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                      }
                    }

                    &.book-active-details {
                      transform: translateY(-5px);
                      background: rgba(85, 110, 230, 0.1);
                      border-radius: 10px;

                      .book-img-alignment {
                        box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                      }
                    }

                    .book-img-alignment {
                      height: 224px;

                      img {
                        width: 100%;
                        height: 100%;
                        display: block;
                      }
                    }

                    .most-liked-name-alignment {
                      text-align: center;
                      padding: 16px 0 0 0;
                      margin: 0 0 24px 0;

                      &:last-child {
                        margin: 0;
                      }

                      h6 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--secondary-900);
                      }

                      p {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 20px;
                        color: var(--grey2);
                        text-align: center;
                      }

                      .book-rate-alignment {
                        display: flex;
                        align-items: center;
                        padding: 8px 0 0 0;
                        justify-content: center;
                        gap: 4px;

                        .hike {
                          display: flex;
                          align-items: center;

                          .inter {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 20px;
                            color: #70c217;

                            img {
                              margin-right: 3px;
                            }
                          }

                          .incris {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 20px;
                            color: #70c217;
                            display: flex;
                            align-items: center;

                            img {
                              margin-right: 3px;
                            }
                          }
                        }

                        .rating {
                          display: flex;
                          align-items: center;

                          @media only screen and (max-width: 799px) {
                            display: none;
                          }

                          .rating_point {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 20px;
                            color: var(--grey2);
                          }

                          .stars {
                            display: flex;
                            align-items: center;
                            margin: 0 2px;
                          }

                          .num {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 20px;
                            color: var(--grey2);
                          }
                        }
                      }

                      .book-price-alignment {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 5px;
                        padding: 11px 0 0 0;

                        h5 {
                          font-family: 'Poppins', sans-serif;
                          font-weight: 600;
                          font-size: 16px;
                          line-height: 24px;
                          color: #000000;
                        }

                        span {
                          font-family: 'Poppins', sans-serif;
                          font-weight: 400;
                          font-size: 13px;
                          line-height: 20px;
                          color: var(--grey5);
                        }
                      }

                      .like-share-alignment {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 16px;

                        .like-alignment {
                          display: flex;
                          align-items: center;
                          gap: 2px;

                          span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 20px;
                            color: var(--grey2);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.add-product-modal-contain.open {
  translate: 0;
}

.small-size-modal-notification {
  position: fixed;
  bottom: 0;
  right: 0;
  // width: 34.5%;
  // width: 38.5%;
  width: 630px;
  height: calc(100vh - 64px);
  // height: calc(100vh - 104px);
  z-index: 9999;
  translate: 100% 0;
  transition: 0.3s;
  display: flex;
  align-items: flex-start;
  gap: 16px;

  // @media only screen and (max-width: '1499px') {
  //   width: 50%;
  // }

  // @media only screen and (max-width:"1099px") {
  //   width: 48%;
  // }

  // @media only screen and (max-width:"1199px") {
  //   width: 85%;
  // }

  @media only screen and (max-width: '767px') {
    width: 100%;
    top: 0;
    height: 100vh;
  }

  .sidebar-modal-alignment {
    background: var(--secondary-50);
    // border-radius: 0 0px 0px 0px; ====UNCOMMET
    border-radius: 16px 0px 0px 0px;
    padding: 16px;
    // padding: 0; ====UNCOMMET

    // product Adujement Modal Width
    // width: 54%;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    @media only screen and (max-width: '1099px') {
      height: 100vh;
    }

    @media only screen and (max-width: '767px') {
      padding: 0;
      border-radius: 0;
    }

    .special-offer-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;

      .special-offer-banner-alignment {
        width: 100%;
        height: 189px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          border-radius: 8px 8px 0 0;
        }

        &::before {
          content: '';
          position: absolute;
          background: linear-gradient(360deg, #f8f9fb 0%, rgba(248, 249, 251, 0) 219.84%);
          border-radius: 8px 8px 0 0;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
        }

        .special-offer-banner-details-alignment {
          text-align: center;
          position: absolute;
          top: 58px;
          right: 0;
          left: 0;

          h2 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 32px;
            line-height: 48px;
            color: var(--secondary-900);
            padding: 0 0 16px 0;
            text-transform: uppercase;
          }

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey5);
          }
        }
      }

      .speaicl-offer-tab-alignment {
        padding: 27px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 32px;
          justify-content: center;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey2);
            cursor: pointer;

            &.offer-active {
              font-weight: 600;
              color: var(--grey5);
            }

            &:hover {
              font-weight: 600;
              color: var(--grey5);
            }
          }
        }
      }

      .special-offer-book-details-alignment {
        margin: 44px 127px;
        height: calc(100vh - 365px);
        overflow-y: auto;

        @media only screen and (max-width: '899px') {
          margin: 30px;
        }

        .speaicl-offer-book-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 24px;

          @media only screen and (max-width: '899px') {
            grid-template-columns: repeat(3, 1fr);
          }

          @media only screen and (max-width: '1199px') {
            grid-template-columns: repeat(3, 1fr);
          }

          .speaicl-offer-book-gridItem-alignment {
            .speaicl-offer-book-alignment {
              padding: 16px;

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .release-book-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // New Release Section Details

    .new-release-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 32px 56px;

      @media only screen and (max-width: '899px') {
        padding: 32px 40px;
      }

      .new-release-modal-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .new-release-heading-label-alignment {
          background: linear-gradient(94.09deg, #556ee6 0.21%, #6b21a8 100%);
          box-shadow: 1px 3px 3px rgba(95, 64, 175, 0.08);
          border-radius: 0px 0px 8px 0px;
          padding: 4px 16px;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--light-grey);
          }
        }

        .new-release-search-alignment {
          .new-release-input-alignment {
            width: 291px;
            min-width: 291px;
            max-width: 291px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }
        }
      }

      .new-realse-note-alignment {
        padding: 16px 0 0 0;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: var(--grey5);
        }
      }

      .new-realese-tab-alignment {
        padding: 14px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 32px;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey2);
            cursor: pointer;

            &.new-release-active {
              font-weight: 600;
              color: var(--grey5);
            }

            &:hover {
              font-weight: 600;
              color: var(--grey5);
            }
          }
        }
      }

      .new-realese-book-details-alignment {
        margin: 44px 0;
        height: calc(100vh - 320px);
        overflow-y: auto;

        .new-realese-book-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 24px;

          @media only screen and (max-width: '1199px') {
            grid-template-columns: repeat(3, 1fr);
          }

          .new-realese-book-gridItem-alignment {
            .new-realese-book-alignment {
              padding: 16px;

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .release-book-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Best Seller Section Details

    .best-seller-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 32px 56px;

      @media only screen and (max-width: '899px') {
        padding: 32px 40px;
      }

      .best-seller-modal-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .best-seller-heading-label-alignment {
          background: linear-gradient(263.94deg, #ffc554 7.44%, #744c00 86.5%);
          box-shadow: 1px 3px 3px rgba(95, 64, 175, 0.08);
          border-radius: 0px 0px 8px 0px;
          padding: 4px 16px;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--light-grey);
          }
        }

        .best-seller-search-alignment {
          .best-seller-input-alignment {
            width: 291px;
            min-width: 291px;
            max-width: 291px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }
        }
      }

      .best-seller-note-alignment {
        padding: 16px 0 0 0;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: var(--grey5);
        }
      }

      .best-seller-tab-alignment {
        padding: 14px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 32px;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey2);
            cursor: pointer;

            &.best-seller-active {
              font-weight: 600;
              color: var(--grey5);
            }

            &:hover {
              font-weight: 600;
              color: var(--grey5);
            }
          }
        }
      }

      .best-seller-book-details-alignment {
        margin: 44px 0;
        height: calc(100vh - 320px);
        overflow-y: auto;

        .best-seller-book-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 24px;

          @media only screen and (max-width: '1199px') {
            grid-template-columns: repeat(3, 1fr);
          }

          .best-seller-book-gridItem-alignment {
            .best-seller-book-alignment {
              padding: 16px;

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .best-seller-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Best Of The Year

    .best-ofthe-year-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 32px 56px;

      @media only screen and (max-width: '899px') {
        padding: 32px 40px;
      }

      .best-ofthe-year-modal-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .best-ofthe-year-heading-label-alignment {
          background: #1e2432;
          box-shadow: 1px 3px 3px rgba(95, 64, 175, 0.08);
          border-radius: 0px 0px 8px 0px;
          padding: 4px 16px;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--light-grey);
          }
        }

        .best-ofthe-year-search-alignment {
          .best-ofthe-year-input-alignment {
            width: 291px;
            min-width: 291px;
            max-width: 291px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }
        }
      }

      .best-ofthe-year-note-alignment {
        padding: 16px 0 0 0;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: var(--grey5);
        }
      }

      .best-ofthe-year-tab-alignment {
        padding: 14px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 32px;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey2);
            cursor: pointer;

            &.best-ofthe-year-active {
              font-weight: 600;
              color: var(--grey5);
            }

            &:hover {
              font-weight: 600;
              color: var(--grey5);
            }
          }
        }
      }

      .best-ofthe-year-book-details-alignment {
        margin: 44px 0;
        height: calc(100vh - 320px);
        overflow-y: auto;

        .best-ofthe-year-book-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 24px;

          @media only screen and (max-width: '1199px') {
            grid-template-columns: repeat(3, 1fr);
          }

          .best-ofthe-year-book-gridItem-alignment {
            .best-ofthe-year-book-alignment {
              padding: 16px;

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .best-ofthe-year-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Top Rated Book of the Year

    .top-rated-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 32px 56px;

      .top-rated-modal-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .top-rated-heading-label-alignment {
          background: #db5a00;
          box-shadow: 1px 3px 3px rgba(95, 64, 175, 0.08);
          border-radius: 0px 0px 8px 0px;
          padding: 4px 16px;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--light-grey);
          }
        }

        .top-rated-search-alignment {
          .top-rated-input-alignment {
            width: 291px;
            min-width: 291px;
            max-width: 291px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }
        }
      }

      .top-rated-note-alignment {
        padding: 16px 0 0 0;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: var(--grey5);
        }
      }

      .top-rated-tab-alignment {
        padding: 14px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 32px;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey2);
            cursor: pointer;

            &.top-rated-active {
              font-weight: 600;
              color: var(--grey5);
            }

            &:hover {
              font-weight: 600;
              color: var(--grey5);
            }
          }
        }
      }

      .top-rated-book-details-alignment {
        margin: 44px 0;
        height: calc(100vh - 320px);
        overflow-y: auto;

        .top-rated-book-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 24px;

          .top-rated-book-gridItem-alignment {
            .top-rated-book-alignment {
              padding: 16px;

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .top-rated-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Most Liked Book

    .most-liked-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 32px 56px;

      .most-liked-modal-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .most-liked-heading-label-alignment {
          background: #940000;
          box-shadow: 1px 3px 3px rgba(95, 64, 175, 0.08);
          border-radius: 0px 0px 8px 0px;
          padding: 4px 16px;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--light-grey);
          }
        }

        .most-liked-search-alignment {
          .most-liked-input-alignment {
            width: 291px;
            min-width: 291px;
            max-width: 291px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }
        }
      }

      .most-liked-note-alignment {
        padding: 16px 0 0 0;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: var(--grey5);
        }
      }

      .most-liked-tab-alignment {
        padding: 14px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 32px;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey2);
            cursor: pointer;

            &.most-liked-active {
              font-weight: 600;
              color: var(--grey5);
            }

            &:hover {
              font-weight: 600;
              color: var(--grey5);
            }
          }
        }
      }

      .most-liked-book-details-alignment {
        margin: 44px 0;
        height: calc(100vh - 320px);
        overflow-y: auto;

        .most-liked-book-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 24px;

          .most-liked-book-gridItem-alignment {
            .most-liked-book-alignment {
              padding: 16px;

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .most-liked-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // More Author Modal Section

    .more-author-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 22px 32px;

      .more-author-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 40px;

        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 32px;
          line-height: 48px;
          color: var(--secondary-900);
        }

        .more-author-search-input-alignment {
          width: 487px;
          min-width: 487px;
          max-width: 487px;
          height: 40px;
          background: #ffffff;
          border: 1px solid #f3f3f3;
          border-radius: 10px;
          display: flex;
          align-items: center;

          @media only screen and (max-width: '899px') {
            width: 280px;
            min-width: 280px;
            max-width: 280px;
          }

          img {
            width: 40px;
            min-width: 40px;
            max-width: 40px;
            height: 40px;
          }

          input {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            width: 100%;
            height: 100%;
            border-radius: 0 10px 10px 0;
            padding: 0 10px 0 0;

            &::placeholder {
              color: var(--grey3);
            }
          }
        }
      }

      .more-author-details-modal-body-alignment {
        margin: 35px 0 0 0;
        height: calc(100vh - 202px);
        overflow-y: auto;

        .more-author-grid-alignment {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 16px;

          @media only screen and (max-width: '899px') {
            grid-template-columns: repeat(2, 1fr);
          }

          .more-author-gridItem-alignment {
            .more-author-box-alignment {
              background: #ffffff;
              padding: 32px;

              .author-img-alignment {
                max-width: 166px;
                width: 166px;
                height: 166px;
                margin: auto;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 32px;
                }
              }

              .more-auther-details-alignment {
                padding: 16px 0 0 0;
                text-align: center;

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 30px;
                  color: var(--secondary-900);
                }

                .auther-following-details-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 17px;
                  padding: 8px 0 0 0;

                  .child-anme-alignment {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .auther-options-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 8px;
                  padding: 16px 0 0 0;

                  .follow-page-button-alignment {
                    button {
                      background: var(--primary-500);
                      border-radius: 8px;
                      height: 32px;
                      width: 117px;
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 21px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      color: var(--light-grey);
                      cursor: pointer;
                    }
                  }

                  .like-share-alignment {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    .box-alignment {
                      background: var(--light-grey);
                      border-radius: 8px;
                      width: 32px;
                      min-width: 32px;
                      max-width: 32px;
                      height: 32px;
                      padding: 4px;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Book Club Modal Section

    .book-club-modal-details-section {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 32px;

      .book-club-modal-details-alignment {
        .book-club-heading-alignment {
          padding: 0 0 0 15px;

          h5 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--secondary-900);
          }
        }

        .book-club-details-flex-alignment {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 32px 0 0 0;

          @media only screen and (max-width: '899px') {
            gap: 40px;
          }

          .book-search-alignment {
            width: 487px;
            min-width: 487px;
            max-width: 487px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            @media only screen and (max-width: '899px') {
              width: 280px;
              min-width: 280px;
              max-width: 280px;
            }

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }

          .create-club-button-alignment {
            button {
              background: var(--primary-500);
              border-radius: 8px;
              padding: 6px 12px;
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              color: var(--light-grey);
              cursor: pointer;
              border: none;
            }
          }
        }

        .book-club-details-body-alignment {
          margin: 32px 0 0 0;
          height: calc(100vh - 270px);
          overflow-y: auto;

          .book-club-grid-alignment {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 32px;

            @media only screen and (max-width: '1199px') {
              grid-template-columns: repeat(2, 1fr);
            }

            .book-club-details_cover {
              padding: 0;

              .card_fashion {
                width: 100%;
                position: relative;
                border-radius: 10px 10px 0px 0px;
                overflow: hidden;
                transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                height: 251px;
                max-height: 251px;

                &:hover {
                  transform: translateY(-5px);

                  .img_cover img {
                    transform: scale(1.05);
                  }
                }

                .img_cover {
                  object-fit: cover;
                  object-position: center;
                  height: 251px;
                  max-height: 251px;

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                  }
                }

                .card_details {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: flex-end;
                  padding: 15px;
                  background: linear-gradient(360deg, #0e1116 0%, rgba(30, 36, 50, 0) 100%);

                  .card_details_cover {
                    width: 100%;

                    h5 {
                      font-weight: 500;
                      font-size: 20px;
                      line-height: 30px;
                      color: #ffffff;
                    }

                    p {
                      font-weight: 400;
                      font-size: 12px;
                      line-height: 18px;
                      color: #ffffff;
                      margin: 4px 0 17px 0;
                    }

                    .users {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;

                      .left_part {
                        display: flex;
                        align-items: center;

                        .users_likes {
                          display: flex;
                          align-items: center;
                          margin-left: -10px;

                          .user_box {
                            width: 20px;
                            display: flex;
                            align-items: center;
                            height: 20px;
                            justify-content: center;
                            background: #fff;
                            object-position: center;
                            border: 1px solid #ffffff;
                            border-radius: 30px;

                            img {
                              width: 100%;
                              object-fit: cover !important;
                            }
                          }
                        }

                        .count {
                          margin-left: -10px;
                          background: var(--primary-500);
                          border: 1px solid #ffffff;
                          border-radius: 12px;
                          font-weight: 500;
                          font-size: 12px;
                          line-height: 18px;
                          letter-spacing: 0.1px;
                          color: #ffffff;
                          padding: 0px 9px;
                        }
                      }

                      .right_part {
                        max-width: 120px;

                        button {
                          @include block_btn();
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Author Modal Section

    .author-modal-section {
      // background: rgba(255, 255, 255, 0.6);
      // border: 1px solid #FFFFFF;
      // backdrop-filter: blur(32px);
      border-radius: 10px;
      width: 96%;
      height: 100vh;
      overflow-y: auto;

      @media only screen and (max-width: '899px') {
        width: 100%;
      }

      @media only screen and (max-width: '767px') {
        width: 100%;
      }

      .author-modal-all-details-alignment {
        .author-banner-bg-alignment {
          background: rgba(217, 217, 217, 0.4);
          backdrop-filter: blur(32px);
          border-radius: 10px;
          width: 100%;
          height: 122px;
          position: relative;

          @media only screen and (max-width: '767px') {
            margin: 8px 0 0 0;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }

          &::before {
            content: '';
            position: absolute;
            background: rgba(217, 217, 217, 0.4);
            backdrop-filter: blur(32px);
            border-radius: 10px;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            z-index: 99;
          }
        }

        .author-details-section {
          margin: -98px 24px 0 24px;
          background: rgba(255, 255, 255, 0.6);
          border: 1px solid #ffffff;
          backdrop-filter: blur(32px);
          border-radius: 8px;
          padding: 32px;
          // height: 100vh;
          // overflow-y: auto;

          @media only screen and (max-width: '767px') {
            margin: -106px 0 0 0;
            padding: 0;
            background: none;
            backdrop-filter: none;
            border-radius: none;
            border: none;
            position: relative;
            height: auto;
          }

          .author-details-flex-alignment {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            @media only screen and (max-width: '767px') {
              background: rgba(255, 255, 255, 0.6);
              border: 1px solid #ffffff;
              backdrop-filter: blur(32px);
              border-radius: 8px;
              padding: 16px;
              margin: 0 16px;
              display: block;
            }

            .author-all-profile-details-alignment {
              display: flex;
              align-items: center;
              gap: 16px;

              .author-img-alignment {
                width: 105px;
                max-width: 105px;
                min-width: 105px;
                height: 105px;

                @media only screen and (max-width: '767px') {
                  width: 87px;
                  max-width: 87px;
                  min-width: 87px;
                  height: 87px;
                }

                img {
                  width: 100%;
                  height: 100%;
                  border: 2px solid #ffffff;
                  border-radius: 24px;
                  object-fit: cover;
                }
              }

              .author-personal-details-alignment {
                h5 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 24px;
                  line-height: 36px;
                  color: var(--secondary-900);

                  @media only screen and (max-width: '767px') {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                  }
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey5);

                  @media only screen and (max-width: '767px') {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                  }
                }
              }

              .author-child-details-alignment {
                display: flex;
                align-items: center;
                gap: 16px;
                padding: 8px 0 0 0;

                @media only screen and (max-width: '767px') {
                  display: none;
                }

                .book-follow-like-alignment {
                  display: flex;
                  align-items: center;
                  gap: 4px;

                  .icon-alignment {
                    width: 20px;
                    height: 20px;

                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 20px;
                    color: #5f40af;
                  }
                }
              }

              .mobile-follow-details-alignment {
                display: flex;
                align-items: center;
                gap: 8px;
                display: none;

                @media only screen and (max-width: '767px') {
                  display: flex;
                  margin: 8px 0 0 0;
                }

                .mobile-option-alignment {
                  background: var(--light-grey);
                  border-radius: 8px;
                  width: 32px;
                  height: 32px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 4px;
                }

                .mobile-follow-page-button-alignment {
                  button {
                    color: var(--primary-500);
                    border-radius: 8px;
                    width: 117px;
                    height: 32px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: var(--light-grey);
                    border-radius: 8px;
                    cursor: pointer;
                  }
                }
              }
            }

            .mobile-author-child-details-alignment {
              display: flex;
              align-items: center;
              gap: 16px;
              padding: 8px 0 0 0;
              display: none;

              @media only screen and (max-width: '767px') {
                display: flex;
              }

              .mobile-book-follow-like-alignment {
                display: flex;
                align-items: center;
                gap: 4px;

                .mobile-icon-alignment {
                  width: 20px;
                  height: 20px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 18px;
                  color: #5f40af;
                }
              }
            }

            .follow-details-alignment {
              display: flex;
              align-items: center;
              gap: 8px;

              @media only screen and (max-width: '767px') {
                display: none;
              }

              .option-alignment {
                background: var(--light-grey);
                border-radius: 8px;
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 4px;
              }

              .follow-page-button-alignment {
                button {
                  background: var(--primary-500);
                  border-radius: 8px;
                  width: 117px;
                  height: 32px;
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 21px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: var(--light-grey);
                  cursor: pointer;
                }
              }
            }
          }

          .author-review-details-alignment {
            padding: 32px 0 0 0;

            @media only screen and (max-width: '767px') {
              padding: 8px 0 25px 16px;
              margin: 16px 0 0 0;
              background: #f4f5f8;
              border: 1px solid #ffffff;
              // backdrop-filter: blur(28.6539px);
            }

            .review-heading-alignment {
              display: flex;
              align-items: center;
              justify-content: space-between;

              h4 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: var(--secondary-900);
              }

              a {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                color: var(--primary-500);
                cursor: pointer;
              }
            }

            .author-child-review-details-alignment {
              padding: 24px 0 0 32px;
              display: flex;
              align-items: center;
              width: 100%;
              overflow-x: hidden;

              @media only screen and (max-width: '767px') {
                overflow-x: auto;
              }

              .author-child-all-detail-alignment {
                border-right: 1px solid var(--grey4);
                padding: 0 16px;

                &:first-child {
                  padding: 0 16px 0 0;
                }

                &:last-child {
                  padding: 0 0 0 16px;
                }

                .author-note-alignment {
                  padding: 16px 0 0 0;
                  max-width: 281px;
                  width: 281px;

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .profile-img-alignment {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  padding: 22px 0 0 0;

                  .child-author-img-alignment {
                    width: 35px;
                    height: 35px;

                    img {
                      width: 100%;
                      height: 100%;
                      border: 2px solid #ffffff;
                      border-radius: 50%;
                    }
                  }

                  .child-author-name-alignment {
                    p {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--secondary-900);
                    }
                  }
                }
              }
            }
          }

          .david-offer-book-alignment {
            padding: 48px 0 0 0;

            @media only screen and (max-width: '767px') {
              padding: 16px;
              margin: 8px 0 0 0;
              background: #f4f5f8;
              border: 1px solid #ffffff;
            }

            .david-offer-heading-alignment {
              @media only screen and (max-width: '767px') {
                display: flex;
                align-items: center;
                justify-content: space-between;
              }

              h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: var(--secondary-900);

                @media only screen and (max-width: '767px') {
                  font-size: 20px;
                  line-height: 30px;
                  max-width: 160px;
                }
              }

              a {
                display: none;

                @media only screen and (max-width: '767px') {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;
                  color: var(--primary-500);
                  display: flex;
                }
              }
            }

            .david-offer-details-alignment {
              padding: 51px 0 25px 0;
              width: 100%;

              @media only screen and (max-width: '767px') {
                padding: 12px 0 28px 0;
              }

              .david-book-other-space-alignment {
                padding: 0 12px;

                @media only screen and (max-width: '767px') {
                  padding: 0;
                }

                .david-offer-book-gridItem {
                  background: #ffffff;
                  border-radius: 10px;
                  height: 193px;
                  padding: 0 0 0 22px;
                  transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);

                  &:hover {
                    transform: translateY(-6px);
                    border-radius: 10px;
                    box-shadow: 2px 7px 15px rgba(95, 64, 175, 0.4);
                    padding: 0 0 0 16px;

                    .david-offer-book-details {
                      gap: 14px;
                      transition: 0.8s ease-in-out;

                      .book-cover-img {
                        width: 160px;
                        max-width: 160px;
                        min-width: 160px;
                        height: 240px;
                      }
                    }
                  }

                  .david-offer-book-details {
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    transition: 0.8s ease-in-out;

                    .book-cover-img {
                      width: 146px;
                      max-width: 146px;
                      min-width: 146px;
                      height: 224px;
                      margin: -20px 0 0 0;

                      img {
                        width: 100%;
                        height: 100%;
                        display: block;
                      }
                    }

                    .book-name-details-alignement {
                      width: 100%;

                      h5 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 36px;
                        color: var(--secondary-900);
                        cursor: pointer;

                        @media only screen and (max-width: '767px') {
                          font-size: 16px;
                          line-height: 24px;
                        }
                      }

                      p {
                        padding: 8px 0 0 0;
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 20px;
                        color: var(--grey2);

                        @media only screen and (max-width: '767px') {
                          padding: 16px 0 0 0;
                        }
                      }

                      .price-alignment-flex {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        @media only screen and (max-width: '767px') {
                          display: block;
                        }

                        .price-alignment {
                          @media only screen and (max-width: '767px') {
                            display: flex;
                            align-items: center;
                            gap: 4px;
                          }

                          h6 {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 24px;
                            line-height: 36px;
                            color: #474400;

                            @media only screen and (max-width: '767px') {
                              font-weight: 600;
                              font-size: 16px;
                              line-height: 24px;
                              color: #000000;
                            }
                          }

                          span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 30px;
                            color: var(--grey2);
                            display: block;
                            margin: -6px 0 0 0;
                            text-align: right;

                            @media only screen and (max-width: '767px') {
                              font-weight: 400;
                              font-size: 13px;
                              line-height: 20px;
                              color: var(--grey5);
                              margin: 2px 0;
                            }

                            del {
                              color: #ca0000;

                              @media only screen and (max-width: '767px') {
                                color: var(--grey5);
                              }
                            }
                          }
                        }

                        .offer-label-alignment {
                          background: #f1efb0;
                          padding: 9px 17px 7px 17px;

                          @media only screen and (max-width: '767px') {
                            margin: 5px 0 0 0;
                            width: 81px;
                            height: 36px;
                            padding: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          }

                          p {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 30px;
                            text-align: center;
                            color: #474400;
                            padding: 0;

                            @media only screen and (max-width: '767px') {
                              font-weight: 500;
                              font-size: 18px;
                              line-height: 27px;
                              color: #474400;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .david-other-book-details-alignment {
            padding: 43px 0 0 0;

            @media only screen and (max-width: '767px') {
              padding: 32px 16px;
            }

            .mobile-view-heading-alignment {
              display: none;

              @media only screen and (max-width: '767px') {
                display: flex;
                align-items: center;
                gap: 2px;
                padding: 0 0 18px 0;

                h4 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 30px;
                  color: var(--secondary-900);
                }

                span {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;
                  color: var(--grey2);
                }
              }
            }

            .david-other-book-filter-alignment {
              display: flex;
              align-items: center;
              justify-content: space-between;

              @media only screen and (max-width: '899px') {
                flex-wrap: wrap;
                gap: 20px;
              }

              .filter-seacch-alignment {
                display: flex;
                align-items: center;
                gap: 8px;

                @media only screen and (max-width: '767px') {
                  width: 100%;
                }

                .filter-alignment {
                  padding: 12px 8px 12px 17px;
                  display: flex;
                  align-items: center;
                  gap: 4px;
                  background: #ffffff;
                  border: 0.5px solid var(--grey4);
                  border-radius: 10px;

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: var(--grey5);
                  }
                }

                .seacrh-alignment {
                  background: #ffffff;
                  border: 1px solid #f3f3f3;
                  border-radius: 10px;
                  display: flex;
                  align-items: center;
                  width: 437px;
                  height: 40px;

                  @media only screen and (max-width: '767px') {
                    width: 100%;
                  }

                  img {
                    width: 40px;
                    min-width: 40px;
                    max-width: 40px;
                    height: 40px;
                  }

                  input {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    width: 100%;
                    height: 100%;
                    border-radius: 0 10px 10px 0;
                    padding: 0 10px 0 0;

                    &::placeholder {
                      color: var(--grey3);
                    }
                  }
                }
              }

              .category-alignment {
                display: flex;
                align-items: center;
                gap: 8px;

                @media only screen and (max-width: '767px') {
                  display: none;
                }

                label {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 21px;
                  color: var(--secondary-900);
                }

                .category-dropdown-alignment {
                  width: 144px;
                  height: 40px;
                  background: #ffffff;
                  border: 1px solid var(--grey4);
                  border-radius: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 8px;

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: var(--grey5);
                  }

                  img {
                    cursor: pointer;
                  }
                }
              }
            }

            .david-other-all-details-alignment {
              padding: 32px 0 0 0;

              @media only screen and (max-width: '767px') {
                padding: 18px 0 0 0;
              }

              .david-other-all-details-grid-alignment {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 30px;

                @media only screen and (max-width: '1199px') {
                  grid-template-columns: repeat(3, 1fr);
                }

                @media only screen and (max-width: '899px') {
                  grid-template-columns: repeat(2, 1fr);
                }

                @media only screen and (max-width: '767px') {
                  grid-template-columns: repeat(2, 1fr);
                  gap: 32px 0;
                }

                .david-other-all-details-gridItem-alignment {
                  .most-liked-book-alignment {
                    padding: 16px;

                    @media only screen and (max-width: '767px') {
                      padding: 6px;
                    }

                    &:hover {
                      transform: translateY(-5px);
                      background: rgba(85, 110, 230, 0.1);
                      border-radius: 10px;

                      .book-img-alignment {
                        box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                      }
                    }

                    &.book-active-details {
                      transform: translateY(-5px);
                      background: rgba(85, 110, 230, 0.1);
                      border-radius: 10px;

                      .book-img-alignment {
                        box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                      }
                    }

                    .book-img-alignment {
                      height: 224px;

                      img {
                        width: 100%;
                        height: 100%;
                        display: block;
                      }
                    }

                    .most-liked-name-alignment {
                      text-align: center;
                      padding: 16px 0 0 0;
                      margin: 0 0 24px 0;

                      &:last-child {
                        margin: 0;
                      }

                      h6 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--secondary-900);
                      }

                      p {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 20px;
                        color: var(--grey2);
                        text-align: center;
                      }

                      .book-rate-alignment {
                        display: flex;
                        align-items: center;
                        padding: 8px 0 0 0;
                        justify-content: center;
                        gap: 4px;

                        .hike {
                          display: flex;
                          align-items: center;

                          .inter {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 20px;
                            color: #70c217;

                            img {
                              margin-right: 3px;
                            }
                          }

                          .incris {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 20px;
                            color: #70c217;
                            display: flex;
                            align-items: center;

                            img {
                              margin-right: 3px;
                            }
                          }
                        }

                        .rating {
                          display: flex;
                          align-items: center;

                          @media only screen and (max-width: 799px) {
                            display: none;
                          }

                          .rating_point {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 20px;
                            color: var(--grey2);
                          }

                          .stars {
                            display: flex;
                            align-items: center;
                            margin: 0 2px;
                          }

                          .num {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 20px;
                            color: var(--grey2);
                          }
                        }
                      }

                      .book-price-alignment {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 5px;
                        padding: 11px 0 0 0;

                        h5 {
                          font-family: 'Poppins', sans-serif;
                          font-weight: 600;
                          font-size: 16px;
                          line-height: 24px;
                          color: #000000;
                        }

                        span {
                          font-family: 'Poppins', sans-serif;
                          font-weight: 400;
                          font-size: 13px;
                          line-height: 20px;
                          color: var(--grey5);
                        }
                      }

                      .like-share-alignment {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 16px;

                        .like-alignment {
                          display: flex;
                          align-items: center;
                          gap: 2px;

                          span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 20px;
                            color: var(--grey2);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.small-size-modal-notification.open {
  translate: 0;
}

.seller-customer-modal {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 45.9%;
  height: calc(100vh - 64px);
  // height: calc(100vh - 104px);
  z-index: 9999;
  translate: 100% 0;
  transition: 0.3s;
  display: flex;
  align-items: flex-start;
  gap: 16px;

  @media only screen and (max-width: '1499px') {
    width: 90%;
  }

  // @media only screen and (max-width:"1099px") {
  //   width: 48%;
  // }

  // @media only screen and (max-width:"1199px") {
  //   width: 85%;
  // }

  @media only screen and (max-width: '767px') {
    width: 100%;
    top: 0;
    height: 100vh;
  }

  .sidebar-modal-alignment {
    background: var(--secondary-50);
    // border-radius: 0 0px 0px 0px; ====UNCOMMET
    border-radius: 16px 0px 0px 0px;
    padding: 16px;
    // padding: 0; ====UNCOMMET

    // product Adujement Modal Width
    // width: 54%;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    @media only screen and (max-width: '1099px') {
      height: 100vh;
    }

    @media only screen and (max-width: '767px') {
      padding: 0;
      border-radius: 0;
    }

    .special-offer-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;

      .special-offer-banner-alignment {
        width: 100%;
        height: 189px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          border-radius: 8px 8px 0 0;
        }

        &::before {
          content: '';
          position: absolute;
          background: linear-gradient(360deg, #f8f9fb 0%, rgba(248, 249, 251, 0) 219.84%);
          border-radius: 8px 8px 0 0;
          top: 0;
          right: 0;
          width: 100%;
          height: 100%;
        }

        .special-offer-banner-details-alignment {
          text-align: center;
          position: absolute;
          top: 58px;
          right: 0;
          left: 0;

          h2 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 32px;
            line-height: 48px;
            color: var(--secondary-900);
            padding: 0 0 16px 0;
            text-transform: uppercase;
          }

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey5);
          }
        }
      }

      .speaicl-offer-tab-alignment {
        padding: 27px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 32px;
          justify-content: center;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey2);
            cursor: pointer;

            &.offer-active {
              font-weight: 600;
              color: var(--grey5);
            }

            &:hover {
              font-weight: 600;
              color: var(--grey5);
            }
          }
        }
      }

      .special-offer-book-details-alignment {
        margin: 44px 127px;
        height: calc(100vh - 365px);
        overflow-y: auto;

        @media only screen and (max-width: '899px') {
          margin: 30px;
        }

        .speaicl-offer-book-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 24px;

          @media only screen and (max-width: '899px') {
            grid-template-columns: repeat(3, 1fr);
          }

          @media only screen and (max-width: '1199px') {
            grid-template-columns: repeat(3, 1fr);
          }

          .speaicl-offer-book-gridItem-alignment {
            .speaicl-offer-book-alignment {
              padding: 16px;

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .release-book-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // New Release Section Details

    .new-release-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 32px 56px;

      @media only screen and (max-width: '899px') {
        padding: 32px 40px;
      }

      .new-release-modal-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .new-release-heading-label-alignment {
          background: linear-gradient(94.09deg, #556ee6 0.21%, #6b21a8 100%);
          box-shadow: 1px 3px 3px rgba(95, 64, 175, 0.08);
          border-radius: 0px 0px 8px 0px;
          padding: 4px 16px;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--light-grey);
          }
        }

        .new-release-search-alignment {
          .new-release-input-alignment {
            width: 291px;
            min-width: 291px;
            max-width: 291px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }
        }
      }

      .new-realse-note-alignment {
        padding: 16px 0 0 0;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: var(--grey5);
        }
      }

      .new-realese-tab-alignment {
        padding: 14px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 32px;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey2);
            cursor: pointer;

            &.new-release-active {
              font-weight: 600;
              color: var(--grey5);
            }

            &:hover {
              font-weight: 600;
              color: var(--grey5);
            }
          }
        }
      }

      .new-realese-book-details-alignment {
        margin: 44px 0;
        height: calc(100vh - 320px);
        overflow-y: auto;

        .new-realese-book-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 24px;

          @media only screen and (max-width: '1199px') {
            grid-template-columns: repeat(3, 1fr);
          }

          .new-realese-book-gridItem-alignment {
            .new-realese-book-alignment {
              padding: 16px;

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .release-book-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Best Seller Section Details

    .best-seller-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 32px 56px;

      @media only screen and (max-width: '899px') {
        padding: 32px 40px;
      }

      .best-seller-modal-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .best-seller-heading-label-alignment {
          background: linear-gradient(263.94deg, #ffc554 7.44%, #744c00 86.5%);
          box-shadow: 1px 3px 3px rgba(95, 64, 175, 0.08);
          border-radius: 0px 0px 8px 0px;
          padding: 4px 16px;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--light-grey);
          }
        }

        .best-seller-search-alignment {
          .best-seller-input-alignment {
            width: 291px;
            min-width: 291px;
            max-width: 291px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }
        }
      }

      .best-seller-note-alignment {
        padding: 16px 0 0 0;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: var(--grey5);
        }
      }

      .best-seller-tab-alignment {
        padding: 14px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 32px;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey2);
            cursor: pointer;

            &.best-seller-active {
              font-weight: 600;
              color: var(--grey5);
            }

            &:hover {
              font-weight: 600;
              color: var(--grey5);
            }
          }
        }
      }

      .best-seller-book-details-alignment {
        margin: 44px 0;
        height: calc(100vh - 320px);
        overflow-y: auto;

        .best-seller-book-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 24px;

          @media only screen and (max-width: '1199px') {
            grid-template-columns: repeat(3, 1fr);
          }

          .best-seller-book-gridItem-alignment {
            .best-seller-book-alignment {
              padding: 16px;

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .best-seller-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Best Of The Year

    .best-ofthe-year-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 32px 56px;

      @media only screen and (max-width: '899px') {
        padding: 32px 40px;
      }

      .best-ofthe-year-modal-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .best-ofthe-year-heading-label-alignment {
          background: #1e2432;
          box-shadow: 1px 3px 3px rgba(95, 64, 175, 0.08);
          border-radius: 0px 0px 8px 0px;
          padding: 4px 16px;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--light-grey);
          }
        }

        .best-ofthe-year-search-alignment {
          .best-ofthe-year-input-alignment {
            width: 291px;
            min-width: 291px;
            max-width: 291px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }
        }
      }

      .best-ofthe-year-note-alignment {
        padding: 16px 0 0 0;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: var(--grey5);
        }
      }

      .best-ofthe-year-tab-alignment {
        padding: 14px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 32px;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey2);
            cursor: pointer;

            &.best-ofthe-year-active {
              font-weight: 600;
              color: var(--grey5);
            }

            &:hover {
              font-weight: 600;
              color: var(--grey5);
            }
          }
        }
      }

      .best-ofthe-year-book-details-alignment {
        margin: 44px 0;
        height: calc(100vh - 320px);
        overflow-y: auto;

        .best-ofthe-year-book-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 24px;

          @media only screen and (max-width: '1199px') {
            grid-template-columns: repeat(3, 1fr);
          }

          .best-ofthe-year-book-gridItem-alignment {
            .best-ofthe-year-book-alignment {
              padding: 16px;

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .best-ofthe-year-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Top Rated Book of the Year

    .top-rated-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 32px 56px;

      .top-rated-modal-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .top-rated-heading-label-alignment {
          background: #db5a00;
          box-shadow: 1px 3px 3px rgba(95, 64, 175, 0.08);
          border-radius: 0px 0px 8px 0px;
          padding: 4px 16px;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--light-grey);
          }
        }

        .top-rated-search-alignment {
          .top-rated-input-alignment {
            width: 291px;
            min-width: 291px;
            max-width: 291px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }
        }
      }

      .top-rated-note-alignment {
        padding: 16px 0 0 0;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: var(--grey5);
        }
      }

      .top-rated-tab-alignment {
        padding: 14px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 32px;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey2);
            cursor: pointer;

            &.top-rated-active {
              font-weight: 600;
              color: var(--grey5);
            }

            &:hover {
              font-weight: 600;
              color: var(--grey5);
            }
          }
        }
      }

      .top-rated-book-details-alignment {
        margin: 44px 0;
        height: calc(100vh - 320px);
        overflow-y: auto;

        .top-rated-book-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 24px;

          .top-rated-book-gridItem-alignment {
            .top-rated-book-alignment {
              padding: 16px;

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .top-rated-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Most Liked Book

    .most-liked-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 32px 56px;

      .most-liked-modal-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .most-liked-heading-label-alignment {
          background: #940000;
          box-shadow: 1px 3px 3px rgba(95, 64, 175, 0.08);
          border-radius: 0px 0px 8px 0px;
          padding: 4px 16px;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--light-grey);
          }
        }

        .most-liked-search-alignment {
          .most-liked-input-alignment {
            width: 291px;
            min-width: 291px;
            max-width: 291px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }
        }
      }

      .most-liked-note-alignment {
        padding: 16px 0 0 0;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: var(--grey5);
        }
      }

      .most-liked-tab-alignment {
        padding: 14px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 32px;

          li {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--grey2);
            cursor: pointer;

            &.most-liked-active {
              font-weight: 600;
              color: var(--grey5);
            }

            &:hover {
              font-weight: 600;
              color: var(--grey5);
            }
          }
        }
      }

      .most-liked-book-details-alignment {
        margin: 44px 0;
        height: calc(100vh - 320px);
        overflow-y: auto;

        .most-liked-book-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 24px;

          .most-liked-book-gridItem-alignment {
            .most-liked-book-alignment {
              padding: 16px;

              &:hover {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              &.book-active-details {
                transform: translateY(-5px);
                background: rgba(85, 110, 230, 0.1);
                border-radius: 10px;

                .book-img-alignment {
                  box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                }
              }

              .book-img-alignment {
                height: 224px;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .most-liked-name-alignment {
                text-align: center;
                padding: 16px 0 0 0;
                margin: 0 0 24px 0;

                &:last-child {
                  margin: 0;
                }

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey2);
                  text-align: center;
                }

                .book-rate-alignment {
                  display: flex;
                  align-items: center;
                  padding: 8px 0 0 0;
                  justify-content: center;
                  gap: 4px;

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;

                      img {
                        margin-right: 3px;
                      }
                    }

                    .incris {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: #70c217;
                      display: flex;
                      align-items: center;

                      img {
                        margin-right: 3px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;

                    @media only screen and (max-width: 799px) {
                      display: none;
                    }

                    .rating_point {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 2px;
                    }

                    .num {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .book-price-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 5px;
                  padding: 11px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #000000;
                  }

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .like-share-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 16px;

                  .like-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // More Author Modal Section

    .more-author-details-alignment {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 22px 32px;

      .more-author-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 40px;

        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 32px;
          line-height: 48px;
          color: var(--secondary-900);
        }

        .more-author-search-input-alignment {
          width: 487px;
          min-width: 487px;
          max-width: 487px;
          height: 40px;
          background: #ffffff;
          border: 1px solid #f3f3f3;
          border-radius: 10px;
          display: flex;
          align-items: center;

          @media only screen and (max-width: '899px') {
            width: 280px;
            min-width: 280px;
            max-width: 280px;
          }

          img {
            width: 40px;
            min-width: 40px;
            max-width: 40px;
            height: 40px;
          }

          input {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            width: 100%;
            height: 100%;
            border-radius: 0 10px 10px 0;
            padding: 0 10px 0 0;

            &::placeholder {
              color: var(--grey3);
            }
          }
        }
      }

      .more-author-details-modal-body-alignment {
        margin: 35px 0 0 0;
        height: calc(100vh - 202px);
        overflow-y: auto;

        .more-author-grid-alignment {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 16px;

          @media only screen and (max-width: '899px') {
            grid-template-columns: repeat(2, 1fr);
          }

          .more-author-gridItem-alignment {
            .more-author-box-alignment {
              background: #ffffff;
              padding: 32px;

              .author-img-alignment {
                max-width: 166px;
                width: 166px;
                height: 166px;
                margin: auto;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 32px;
                }
              }

              .more-auther-details-alignment {
                padding: 16px 0 0 0;
                text-align: center;

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 30px;
                  color: var(--secondary-900);
                }

                .auther-following-details-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 17px;
                  padding: 8px 0 0 0;

                  .child-anme-alignment {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--grey2);
                    }
                  }
                }

                .auther-options-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 8px;
                  padding: 16px 0 0 0;

                  .follow-page-button-alignment {
                    button {
                      background: var(--primary-500);
                      border-radius: 8px;
                      height: 32px;
                      width: 117px;
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 21px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      color: var(--light-grey);
                      cursor: pointer;
                    }
                  }

                  .like-share-alignment {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    .box-alignment {
                      background: var(--light-grey);
                      border-radius: 8px;
                      width: 32px;
                      min-width: 32px;
                      max-width: 32px;
                      height: 32px;
                      padding: 4px;
                      cursor: pointer;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Book Club Modal Section

    .book-club-modal-details-section {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #ffffff;
      backdrop-filter: blur(32px);
      border-radius: 8px;
      width: 100%;
      height: 100%;
      padding: 32px;

      .book-club-modal-details-alignment {
        .book-club-heading-alignment {
          padding: 0 0 0 15px;

          h5 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--secondary-900);
          }
        }

        .book-club-details-flex-alignment {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 32px 0 0 0;

          @media only screen and (max-width: '899px') {
            gap: 40px;
          }

          .book-search-alignment {
            width: 487px;
            min-width: 487px;
            max-width: 487px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            display: flex;
            align-items: center;

            @media only screen and (max-width: '899px') {
              width: 280px;
              min-width: 280px;
              max-width: 280px;
            }

            img {
              width: 40px;
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              width: 100%;
              height: 100%;
              border-radius: 0 10px 10px 0;
              padding: 0 10px 0 0;

              &::placeholder {
                color: var(--grey3);
              }
            }
          }

          .create-club-button-alignment {
            button {
              background: var(--primary-500);
              border-radius: 8px;
              padding: 6px 12px;
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              color: var(--light-grey);
              cursor: pointer;
              border: none;
            }
          }
        }

        .book-club-details-body-alignment {
          margin: 32px 0 0 0;
          height: calc(100vh - 270px);
          overflow-y: auto;

          .book-club-grid-alignment {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 32px;

            @media only screen and (max-width: '1199px') {
              grid-template-columns: repeat(2, 1fr);
            }

            .book-club-details_cover {
              padding: 0;

              .card_fashion {
                width: 100%;
                position: relative;
                border-radius: 10px 10px 0px 0px;
                overflow: hidden;
                transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                height: 251px;
                max-height: 251px;

                &:hover {
                  transform: translateY(-5px);

                  .img_cover img {
                    transform: scale(1.05);
                  }
                }

                .img_cover {
                  object-fit: cover;
                  object-position: center;
                  height: 251px;
                  max-height: 251px;

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                  }
                }

                .card_details {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: flex-end;
                  padding: 15px;
                  background: linear-gradient(360deg, #0e1116 0%, rgba(30, 36, 50, 0) 100%);

                  .card_details_cover {
                    width: 100%;

                    h5 {
                      font-weight: 500;
                      font-size: 20px;
                      line-height: 30px;
                      color: #ffffff;
                    }

                    p {
                      font-weight: 400;
                      font-size: 12px;
                      line-height: 18px;
                      color: #ffffff;
                      margin: 4px 0 17px 0;
                    }

                    .users {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;

                      .left_part {
                        display: flex;
                        align-items: center;

                        .users_likes {
                          display: flex;
                          align-items: center;
                          margin-left: -10px;

                          .user_box {
                            width: 20px;
                            display: flex;
                            align-items: center;
                            height: 20px;
                            justify-content: center;
                            background: #fff;
                            object-position: center;
                            border: 1px solid #ffffff;
                            border-radius: 30px;

                            img {
                              width: 100%;
                              object-fit: cover !important;
                            }
                          }
                        }

                        .count {
                          margin-left: -10px;
                          background: var(--primary-500);
                          border: 1px solid #ffffff;
                          border-radius: 12px;
                          font-weight: 500;
                          font-size: 12px;
                          line-height: 18px;
                          letter-spacing: 0.1px;
                          color: #ffffff;
                          padding: 0px 9px;
                        }
                      }

                      .right_part {
                        max-width: 120px;

                        button {
                          @include block_btn();
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Author Modal Section

    .author-modal-section {
      // background: rgba(255, 255, 255, 0.6);
      // border: 1px solid #FFFFFF;
      // backdrop-filter: blur(32px);
      border-radius: 10px;
      width: 96%;
      height: 100vh;
      overflow-y: auto;

      @media only screen and (max-width: '899px') {
        width: 100%;
      }

      @media only screen and (max-width: '767px') {
        width: 100%;
      }

      .author-modal-all-details-alignment {
        .author-banner-bg-alignment {
          background: rgba(217, 217, 217, 0.4);
          backdrop-filter: blur(32px);
          border-radius: 10px;
          width: 100%;
          height: 122px;
          position: relative;

          @media only screen and (max-width: '767px') {
            margin: 8px 0 0 0;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }

          &::before {
            content: '';
            position: absolute;
            background: rgba(217, 217, 217, 0.4);
            backdrop-filter: blur(32px);
            border-radius: 10px;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            z-index: 99;
          }
        }

        .author-details-section {
          margin: -98px 24px 0 24px;
          background: rgba(255, 255, 255, 0.6);
          border: 1px solid #ffffff;
          backdrop-filter: blur(32px);
          border-radius: 8px;
          padding: 32px;
          // height: 100vh;
          // overflow-y: auto;

          @media only screen and (max-width: '767px') {
            margin: -106px 0 0 0;
            padding: 0;
            background: none;
            backdrop-filter: none;
            border-radius: none;
            border: none;
            position: relative;
            height: auto;
          }

          .author-details-flex-alignment {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            @media only screen and (max-width: '767px') {
              background: rgba(255, 255, 255, 0.6);
              border: 1px solid #ffffff;
              backdrop-filter: blur(32px);
              border-radius: 8px;
              padding: 16px;
              margin: 0 16px;
              display: block;
            }

            .author-all-profile-details-alignment {
              display: flex;
              align-items: center;
              gap: 16px;

              .author-img-alignment {
                width: 105px;
                max-width: 105px;
                min-width: 105px;
                height: 105px;

                @media only screen and (max-width: '767px') {
                  width: 87px;
                  max-width: 87px;
                  min-width: 87px;
                  height: 87px;
                }

                img {
                  width: 100%;
                  height: 100%;
                  border: 2px solid #ffffff;
                  border-radius: 24px;
                  object-fit: cover;
                }
              }

              .author-personal-details-alignment {
                h5 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 24px;
                  line-height: 36px;
                  color: var(--secondary-900);

                  @media only screen and (max-width: '767px') {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                  }
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--grey5);

                  @media only screen and (max-width: '767px') {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                  }
                }
              }

              .author-child-details-alignment {
                display: flex;
                align-items: center;
                gap: 16px;
                padding: 8px 0 0 0;

                @media only screen and (max-width: '767px') {
                  display: none;
                }

                .book-follow-like-alignment {
                  display: flex;
                  align-items: center;
                  gap: 4px;

                  .icon-alignment {
                    width: 20px;
                    height: 20px;

                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 20px;
                    color: #5f40af;
                  }
                }
              }

              .mobile-follow-details-alignment {
                display: flex;
                align-items: center;
                gap: 8px;
                display: none;

                @media only screen and (max-width: '767px') {
                  display: flex;
                  margin: 8px 0 0 0;
                }

                .mobile-option-alignment {
                  background: var(--light-grey);
                  border-radius: 8px;
                  width: 32px;
                  height: 32px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 4px;
                }

                .mobile-follow-page-button-alignment {
                  button {
                    color: var(--primary-500);
                    border-radius: 8px;
                    width: 117px;
                    height: 32px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: var(--light-grey);
                    border-radius: 8px;
                    cursor: pointer;
                  }
                }
              }
            }

            .mobile-author-child-details-alignment {
              display: flex;
              align-items: center;
              gap: 16px;
              padding: 8px 0 0 0;
              display: none;

              @media only screen and (max-width: '767px') {
                display: flex;
              }

              .mobile-book-follow-like-alignment {
                display: flex;
                align-items: center;
                gap: 4px;

                .mobile-icon-alignment {
                  width: 20px;
                  height: 20px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 18px;
                  color: #5f40af;
                }
              }
            }

            .follow-details-alignment {
              display: flex;
              align-items: center;
              gap: 8px;

              @media only screen and (max-width: '767px') {
                display: none;
              }

              .option-alignment {
                background: var(--light-grey);
                border-radius: 8px;
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 4px;
              }

              .follow-page-button-alignment {
                button {
                  background: var(--primary-500);
                  border-radius: 8px;
                  width: 117px;
                  height: 32px;
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 21px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: var(--light-grey);
                  cursor: pointer;
                }
              }
            }
          }

          .author-review-details-alignment {
            padding: 32px 0 0 0;

            @media only screen and (max-width: '767px') {
              padding: 8px 0 25px 16px;
              margin: 16px 0 0 0;
              background: #f4f5f8;
              border: 1px solid #ffffff;
              // backdrop-filter: blur(28.6539px);
            }

            .review-heading-alignment {
              display: flex;
              align-items: center;
              justify-content: space-between;

              h4 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: var(--secondary-900);
              }

              a {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                color: var(--primary-500);
                cursor: pointer;
              }
            }

            .author-child-review-details-alignment {
              padding: 24px 0 0 32px;
              display: flex;
              align-items: center;
              width: 100%;
              overflow-x: hidden;

              @media only screen and (max-width: '767px') {
                overflow-x: auto;
              }

              .author-child-all-detail-alignment {
                border-right: 1px solid var(--grey4);
                padding: 0 16px;

                &:first-child {
                  padding: 0 16px 0 0;
                }

                &:last-child {
                  padding: 0 0 0 16px;
                }

                .author-note-alignment {
                  padding: 16px 0 0 0;
                  max-width: 281px;
                  width: 281px;

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--grey5);
                  }
                }

                .profile-img-alignment {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  padding: 22px 0 0 0;

                  .child-author-img-alignment {
                    width: 35px;
                    height: 35px;

                    img {
                      width: 100%;
                      height: 100%;
                      border: 2px solid #ffffff;
                      border-radius: 50%;
                    }
                  }

                  .child-author-name-alignment {
                    p {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: var(--secondary-900);
                    }
                  }
                }
              }
            }
          }

          .david-offer-book-alignment {
            padding: 48px 0 0 0;

            @media only screen and (max-width: '767px') {
              padding: 16px;
              margin: 8px 0 0 0;
              background: #f4f5f8;
              border: 1px solid #ffffff;
            }

            .david-offer-heading-alignment {
              @media only screen and (max-width: '767px') {
                display: flex;
                align-items: center;
                justify-content: space-between;
              }

              h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: var(--secondary-900);

                @media only screen and (max-width: '767px') {
                  font-size: 20px;
                  line-height: 30px;
                  max-width: 160px;
                }
              }

              a {
                display: none;

                @media only screen and (max-width: '767px') {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;
                  color: var(--primary-500);
                  display: flex;
                }
              }
            }

            .david-offer-details-alignment {
              padding: 51px 0 25px 0;
              width: 100%;

              @media only screen and (max-width: '767px') {
                padding: 12px 0 28px 0;
              }

              .david-book-other-space-alignment {
                padding: 0 12px;

                @media only screen and (max-width: '767px') {
                  padding: 0;
                }

                .david-offer-book-gridItem {
                  background: #ffffff;
                  border-radius: 10px;
                  height: 193px;
                  padding: 0 0 0 22px;
                  transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);

                  &:hover {
                    transform: translateY(-6px);
                    border-radius: 10px;
                    box-shadow: 2px 7px 15px rgba(95, 64, 175, 0.4);
                    padding: 0 0 0 16px;

                    .david-offer-book-details {
                      gap: 14px;
                      transition: 0.8s ease-in-out;

                      .book-cover-img {
                        width: 160px;
                        max-width: 160px;
                        min-width: 160px;
                        height: 240px;
                      }
                    }
                  }

                  .david-offer-book-details {
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    transition: 0.8s ease-in-out;

                    .book-cover-img {
                      width: 146px;
                      max-width: 146px;
                      min-width: 146px;
                      height: 224px;
                      margin: -20px 0 0 0;

                      img {
                        width: 100%;
                        height: 100%;
                        display: block;
                      }
                    }

                    .book-name-details-alignement {
                      width: 100%;

                      h5 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 36px;
                        color: var(--secondary-900);
                        cursor: pointer;

                        @media only screen and (max-width: '767px') {
                          font-size: 16px;
                          line-height: 24px;
                        }
                      }

                      p {
                        padding: 8px 0 0 0;
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 20px;
                        color: var(--grey2);

                        @media only screen and (max-width: '767px') {
                          padding: 16px 0 0 0;
                        }
                      }

                      .price-alignment-flex {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        @media only screen and (max-width: '767px') {
                          display: block;
                        }

                        .price-alignment {
                          @media only screen and (max-width: '767px') {
                            display: flex;
                            align-items: center;
                            gap: 4px;
                          }

                          h6 {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 24px;
                            line-height: 36px;
                            color: #474400;

                            @media only screen and (max-width: '767px') {
                              font-weight: 600;
                              font-size: 16px;
                              line-height: 24px;
                              color: #000000;
                            }
                          }

                          span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 30px;
                            color: var(--grey2);
                            display: block;
                            margin: -6px 0 0 0;
                            text-align: right;

                            @media only screen and (max-width: '767px') {
                              font-weight: 400;
                              font-size: 13px;
                              line-height: 20px;
                              color: var(--grey5);
                              margin: 2px 0;
                            }

                            del {
                              color: #ca0000;

                              @media only screen and (max-width: '767px') {
                                color: var(--grey5);
                              }
                            }
                          }
                        }

                        .offer-label-alignment {
                          background: #f1efb0;
                          padding: 9px 17px 7px 17px;

                          @media only screen and (max-width: '767px') {
                            margin: 5px 0 0 0;
                            width: 81px;
                            height: 36px;
                            padding: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          }

                          p {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 30px;
                            text-align: center;
                            color: #474400;
                            padding: 0;

                            @media only screen and (max-width: '767px') {
                              font-weight: 500;
                              font-size: 18px;
                              line-height: 27px;
                              color: #474400;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .david-other-book-details-alignment {
            padding: 43px 0 0 0;

            @media only screen and (max-width: '767px') {
              padding: 32px 16px;
            }

            .mobile-view-heading-alignment {
              display: none;

              @media only screen and (max-width: '767px') {
                display: flex;
                align-items: center;
                gap: 2px;
                padding: 0 0 18px 0;

                h4 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 30px;
                  color: var(--secondary-900);
                }

                span {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;
                  color: var(--grey2);
                }
              }
            }

            .david-other-book-filter-alignment {
              display: flex;
              align-items: center;
              justify-content: space-between;

              @media only screen and (max-width: '899px') {
                flex-wrap: wrap;
                gap: 20px;
              }

              .filter-seacch-alignment {
                display: flex;
                align-items: center;
                gap: 8px;

                @media only screen and (max-width: '767px') {
                  width: 100%;
                }

                .filter-alignment {
                  padding: 12px 8px 12px 17px;
                  display: flex;
                  align-items: center;
                  gap: 4px;
                  background: #ffffff;
                  border: 0.5px solid var(--grey4);
                  border-radius: 10px;

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: var(--grey5);
                  }
                }

                .seacrh-alignment {
                  background: #ffffff;
                  border: 1px solid #f3f3f3;
                  border-radius: 10px;
                  display: flex;
                  align-items: center;
                  width: 437px;
                  height: 40px;

                  @media only screen and (max-width: '767px') {
                    width: 100%;
                  }

                  img {
                    width: 40px;
                    min-width: 40px;
                    max-width: 40px;
                    height: 40px;
                  }

                  input {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    width: 100%;
                    height: 100%;
                    border-radius: 0 10px 10px 0;
                    padding: 0 10px 0 0;

                    &::placeholder {
                      color: var(--grey3);
                    }
                  }
                }
              }

              .category-alignment {
                display: flex;
                align-items: center;
                gap: 8px;

                @media only screen and (max-width: '767px') {
                  display: none;
                }

                label {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 21px;
                  color: var(--secondary-900);
                }

                .category-dropdown-alignment {
                  width: 144px;
                  height: 40px;
                  background: #ffffff;
                  border: 1px solid var(--grey4);
                  border-radius: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 8px;

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: var(--grey5);
                  }

                  img {
                    cursor: pointer;
                  }
                }
              }
            }

            .david-other-all-details-alignment {
              padding: 32px 0 0 0;

              @media only screen and (max-width: '767px') {
                padding: 18px 0 0 0;
              }

              .david-other-all-details-grid-alignment {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 30px;

                @media only screen and (max-width: '1199px') {
                  grid-template-columns: repeat(3, 1fr);
                }

                @media only screen and (max-width: '899px') {
                  grid-template-columns: repeat(2, 1fr);
                }

                @media only screen and (max-width: '767px') {
                  grid-template-columns: repeat(2, 1fr);
                  gap: 32px 0;
                }

                .david-other-all-details-gridItem-alignment {
                  .most-liked-book-alignment {
                    padding: 16px;

                    @media only screen and (max-width: '767px') {
                      padding: 6px;
                    }

                    &:hover {
                      transform: translateY(-5px);
                      background: rgba(85, 110, 230, 0.1);
                      border-radius: 10px;

                      .book-img-alignment {
                        box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                      }
                    }

                    &.book-active-details {
                      transform: translateY(-5px);
                      background: rgba(85, 110, 230, 0.1);
                      border-radius: 10px;

                      .book-img-alignment {
                        box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                      }
                    }

                    .book-img-alignment {
                      height: 224px;

                      img {
                        width: 100%;
                        height: 100%;
                        display: block;
                      }
                    }

                    .most-liked-name-alignment {
                      text-align: center;
                      padding: 16px 0 0 0;
                      margin: 0 0 24px 0;

                      &:last-child {
                        margin: 0;
                      }

                      h6 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--secondary-900);
                      }

                      p {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 20px;
                        color: var(--grey2);
                        text-align: center;
                      }

                      .book-rate-alignment {
                        display: flex;
                        align-items: center;
                        padding: 8px 0 0 0;
                        justify-content: center;
                        gap: 4px;

                        .hike {
                          display: flex;
                          align-items: center;

                          .inter {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 20px;
                            color: #70c217;

                            img {
                              margin-right: 3px;
                            }
                          }

                          .incris {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 20px;
                            color: #70c217;
                            display: flex;
                            align-items: center;

                            img {
                              margin-right: 3px;
                            }
                          }
                        }

                        .rating {
                          display: flex;
                          align-items: center;

                          @media only screen and (max-width: 799px) {
                            display: none;
                          }

                          .rating_point {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 20px;
                            color: var(--grey2);
                          }

                          .stars {
                            display: flex;
                            align-items: center;
                            margin: 0 2px;
                          }

                          .num {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 20px;
                            color: var(--grey2);
                          }
                        }
                      }

                      .book-price-alignment {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 5px;
                        padding: 11px 0 0 0;

                        h5 {
                          font-family: 'Poppins', sans-serif;
                          font-weight: 600;
                          font-size: 16px;
                          line-height: 24px;
                          color: #000000;
                        }

                        span {
                          font-family: 'Poppins', sans-serif;
                          font-weight: 400;
                          font-size: 13px;
                          line-height: 20px;
                          color: var(--grey5);
                        }
                      }

                      .like-share-alignment {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 16px;

                        .like-alignment {
                          display: flex;
                          align-items: center;
                          gap: 2px;

                          span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 20px;
                            color: var(--grey2);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.seller-customer-modal.open {
  translate: 0;
}

.border-raduis-8 {
  border-radius: 8px;
}

.Menbackbuttton {
  width: 43px;
  height: 43px;
  min-width: 43px;
  max-width: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--primary-50);
  position: fixed;
  top: 80px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.4s ease-in-out;
  transform: translateX(-80px);

  &.menbottomBox {
    top: 110px;
    transition: 0.4s ease-in-out;
  }
}

.jewelry-main-alignment {
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 15px;
}

.jewelry-main-alignment-men {
  max-width: 1334px;
  background: rgb(255, 255, 255);
  margin: 19px auto;
  border: 2px solid rgb(238, 241, 253);
  border-radius: 16px;
  padding: 13px 17px;
  height: 100%;
  overflow-x: hidden;
  position: relative;

  @media (max-width: 576px) {
    padding: 0;
    border: none;
    border-radius: 0;
  }
}

.seller-withdrawal-modal {
  position: fixed;
  bottom: 0;
  right: 0;
  // width: 34.5%;
  // width: 38.5%;
  width: 630px;
  height: calc(100vh - 64px);
  // height: calc(100vh - 104px);
  z-index: 9999;
  translate: 100% 0;
  transition: 0.3s;
  display: flex;
  align-items: flex-start;
  gap: 16px;

  .sidebar-modal-alignment {
    background: var(--secondary-50);
    // border-radius: 0 0px 0px 0px; ====UNCOMMET
    border-radius: 16px 0px 0px 0px;
    padding: 16px;
    // padding: 0; ====UNCOMMET

    // product Adujement Modal Width
    // width: 54%;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
}

.seller-withdrawal-modal.open {
  translate: 0;
}

.summary-layout-alignment {
  display: grid;
  grid-template-columns: 320px 1fr;
  gap: 32px;
  background: var(--secondary-50);

  @media only screen and (max-width: '1024px') {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .dashboard-left-sidebar-clone {
    position: relative;
    height: 100vh;
    background-color: #fff;
    min-width: 320px;
    max-width: 320px;
    padding: 0 0 30px 0;

    @media only screen and (max-width: '1024px') {
      display: none;
    }
  }

  .dashboard-left-sidebar {
    z-index: 10;
    // position: absolute;
    height: calc(100vh - 60px);
    background-color: #fff;
    min-width: 320px;
    max-width: 320px;
    width: 320px;
    // padding: 0 0 30px 0;
    padding: 42px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media only screen and (max-width: '1024px') {
      display: none;
    }

    &.sticky {
      position: fixed !important;
      top: 60px !important;
      // box-shadow: rgba(79, 79, 82, 0.4) 7px 7px 10px 0px;
    }

    .left-sidebar-profile {
      // width: 100%;
      // height: 120px;
      // position: relative;
      // padding-top: 21px;
      // padding-left: 30px;

      .left-sidebar-profile-photo {
        width: 98px;
        height: 98px;

        img {
          border-radius: 50%;
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
        }
      }

      .left-sidebar-user-name {
        // position: absolute;
        // left: 100px;
        // right: 0;
        // bottom: 60px;
        padding: 14px 0 0 0;
        text-align: center;

        h6 {
          color: var(--secondary-900);
          font-family: 'Poppins', sans-serif;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
        }
      }
    }

    .summary-left-nav-menu {
      padding: 45px 0 0 0;

      ul {
        li {
          padding: 11px 47px;
          display: flex;
          align-items: center;
          gap: 16px;
          cursor: pointer;
          border: 1px solid transparent;
          border-right: none;
          border-left: none;
          transition: 0.3s ease-in-out;
          margin: 0 0 10px 0;

          a {
            span {
              color: var(--secondary-500);
              font-family: 'Poppins', sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              display: block;
            }

            .active-img-alignment {
              display: none;
            }
          }
        }

        li:hover {
          border-top: 1px solid #e7e9ee;
          border-bottom: 1px solid #e7e9ee;
          background: #fff;

          a {
            span {
              color: var(--primary-500);
            }

            .deActive-img-alignment {
              display: none;
            }

            .active-img-alignment {
              display: block;
            }

            svg {
              path {
                fill: var(--primary-500);
              }

              line {
                stroke: var(--primary-500);
              }

              rect {
                stroke: var(--primary-500);
              }

              // circle {
              //   stroke: white;
              // }

              // .gift {
              //   fill: white;
              // }
            }
          }
        }

        li.tab-active {
          border-top: 1px solid #e7e9ee;
          border-bottom: 1px solid #e7e9ee;
          background: #fff;

          a {
            span {
              color: var(--primary-500);
            }

            .deActive-img-alignment {
              display: none;
            }

            .active-img-alignment {
              display: block;
            }

            svg {
              path {
                fill: var(--primary-500);
              }

              line {
                stroke: var(--primary-500);
              }

              rect {
                stroke: var(--primary-500);
              }
            }
          }
        }
      }
    }

    .summary-user-profile-setting {
      ul {
        li {
          padding: 11px 47px;
          display: flex;
          align-items: center;
          gap: 16px;
          cursor: pointer;
          border: 1px solid transparent;
          border-right: none;
          border-left: none;
          transition: 0.3s ease-in-out;
          margin: 0 0 10px 0;

          a {
            span {
              color: var(--secondary-500);
              font-family: 'Poppins', sans-serif;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              display: block;
            }

            .active-img-alignment {
              display: none;
            }
          }
        }

        li:hover {
          border-top: 1px solid #e7e9ee;
          border-bottom: 1px solid #e7e9ee;
          background: #fff;

          a {
            span {
              color: var(--primary-500);
            }

            .deActive-img-alignment {
              display: none;
            }

            .active-img-alignment {
              display: block;
            }

            svg {
              path {
                fill: var(--primary-500);
              }

              line {
                stroke: var(--primary-500);
              }

              rect {
                stroke: var(--primary-500);
              }

              // circle {
              //   stroke: white;
              // }

              // .gift {
              //   fill: white;
              // }
            }
          }
        }

        li.tab-active {
          border-top: 1px solid #e7e9ee;
          border-bottom: 1px solid #e7e9ee;
          background: #fff;

          a {
            span {
              color: var(--primary-500);
            }

            .deActive-img-alignment {
              display: none;
            }

            .active-img-alignment {
              display: block;
            }

            svg {
              path {
                fill: var(--primary-500);
              }

              line {
                stroke: var(--primary-500);
              }

              rect {
                stroke: var(--primary-500);
              }
            }
          }
        }
      }
    }
  }
}

.summary-right-side-details-alignment {
  max-width: 1200px;
}

.mobile-order-search-filter-section {
  padding: 24px 16px 0 16px;
  display: none;

  @media only screen and (max-width: '767px') {
    display: flex;
  }

  .mobile-search-alignment {
    height: 40px;
    width: 100%;
    position: relative;

    input {
      background: var(--light-grey);
      border-radius: 5px;
      color: var(--grey2);
      font-family: 'Poppins', sans-serif;
      font-size: 13px !important;
      font-weight: 400;
      line-height: 18px;
      width: 100%;
      height: 100%;
      padding: 0 14px 0 50px;
      border: none;

      &::placeholder {
        color: var(--grey2);
      }
    }

    .search-icon-alignment {
      position: absolute;
      top: 12px;
      left: 16px;
      z-index: 99;

      img {
        cursor: pointer;
      }
    }
  }
}

.ant-checkbox-input {
  display: none !important;
}

.loader-height-alignment {
  height: calc(100vh - 100px);

  @media only screen and (max-width: '1024px') {
    height: calc(100vh - 56px - 64px);
  }
}

.add-modal-cusotom-toggle {
  .add-modal-switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 26px;
    margin: 0;
  }

  .add-modal-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .add-modal-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #eceef4;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .add-modal-slider:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: #7a7e88;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked+.add-modal-slider {
    background-color: #1d8603;
  }

  input:checked+.add-modal-slider:before {
    background-color: white;
  }

  input:focus+.add-modal-slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked+.add-modal-slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
  }

  /* Rounded sliders */
  .add-modal-slider.add-modal-round {
    border-radius: 34px;
  }

  .add-modal-slider.add-modal-round:before {
    border-radius: 50%;
  }
}

.emoji-mart {
  width: 100% !important;
}

.icons-option-alignment {
  transition: 0.4s ease-in-out;

  &:hover {
    transition: 0.4s ease-in-out;

    img {
      &.defult-icon-alignment {
        display: none;
      }

      &.hover-icon-alignment {
        display: block;
      }
    }
  }

  img {
    &.defult-icon-alignment {
      display: block;
    }

    &.hover-icon-alignment {
      display: none;
    }
  }
}

.icon-hover-effect-alignment {
  svg {
    cursor: pointer;

    &:hover {
      path {
        fill: var(--primary-500);
      }
    }
  }
}

.product-details-bookmark-alignment {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(30, 36, 50, 0.4);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999999;

  .product-details-bookmark-box {
    border-radius: 16px;
    background: #fff;
    width: 375px;
    height: 294px;
  }

  .product-details-share-box {
    width: 432px;
    height: auto;
    border-radius: 16px;
    background: #fff;
  }
}

.new-mobile-view-tab-alignment {
  display: none;
}

.mobile-view-details-bg-alignment {
  @media only screen and (max-width: '1024px') {
    background: var(--white);
    padding: 16px 16px 32px 16px;
    border-radius: 10px;
  }
}

.transaction-mobile-view-none {
  @media only screen and (max-width: '1024px') {
    display: none;
  }
}

.profile-tab-details-alignment {
  .top-details-main-alignment {
    display: flex;
    align-items: center;
    gap: 26px;
    padding: 20px 82px;
    border-bottom: 1px solid var(--grey4);

    @media only screen and (max-width: '1024px') {
      display: none;
    }

    .top-details-icon {
      width: 45px;
      height: 45px;
      min-width: 45px;
      max-width: 45px;
    }

    .top-details-side-details-alignment {
      h6 {
        color: var(--grey5);
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
      }

      p {
        padding: 4px 0 0 0;
        color: var(--grey3);
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
      }
    }
  }
}

.billing-main-section {
  padding: 0 0 58px 0;
}

.Custom404 {
  width: 100%;
  height: calc(100vh - 66px);
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    // color: linear-gradient(118.63deg, #74E0F9 -10.04%, #B582FE 67.04%);
    background-image: linear-gradient(118.63deg, #74e0f9 27.96%, #b582fe 61.04%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;

    font-size: 190px;
    text-align: center;
    margin: 0 0 60px 0;
    // @include breakpoint("max-sm") {
    //   font-size: 140px;
    // }

    @media (max-width: 576px) {
      font-size: 40px;
    }
  }

  h1.small {
    font-size: 100px;
  }

  h2 {
    font-size: 24px;
    line-height: 36px;
    color: #050505;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    margin: 0 0 1.25rem 0;
    text-align: center;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    color: #323232;
    text-align: center;
  }

  .button-center-alignment {
    display: flex;
    justify-content: center;
    padding: 30px 0 0 0;

    button,
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 12px;
      font-size: 16px;
      cursor: pointer;
      line-height: 24px;
      font-family: 'Inter', sans-serif;
      font-weight: 600;
      // background: #222222;
      color: #fff;
      background: linear-gradient(118.63deg, #74e0f9 -10.04%, #b582fe 67.04%);
      //       background-color:hsla(266,100%,69%,1);
      // background-image:
      // radial-gradient(at 21% 68%, hsla(254,83%,63%,1) 0px, transparent 50%),
      // radial-gradient(at 75% 23%, hsla(201,100%,49%,0.79) 0px, transparent 50%);
      border-radius: 4px;
      height: 48px;
      min-width: 287px;
    }
  }
}

.post-something-textarea {
  &::-webkit-scrollbar {
    display: block !important;
    // width: 8px;
    visibility: hidden;
  }

  // Scroll bar background transparent css start

  // &::-webkit-scrollbar-thumb {
  //   background: transparent;
  //   // border-radius: 18px;
  // }

  // Scroll bar background transparent css end
}