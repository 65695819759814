.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    z-index: 999;
}

.overlay.open {
    opacity: 1;
    visibility: visible;
}



.modal {
    position: fixed;
    top: 0;
    right: 0;
    width: 240px;
    height: 100%;
    padding: 0 16px;
    background: white;
    translate: 100% 0;
    transition: 0.3s;
}

.modal.open {
    translate: 0;
}