.collectionSharedModal {
    .collectionSharedWrapper {
        position: fixed;
        width: 100%;
        height: 100vh;
        background: rgba(30, 36, 50, 0.6);
        top: 0;
        left: 0;
        z-index: 9999999999;
        display: flex;
        align-items: center;
        justify-content: center;

        .collectionSharedModalBox {
            padding: 24px 22px;
            border-radius: 16px;
            background: var(--white);

            .collectionIcon {
                width: 48px;
                height: 48px;
                margin: 0 auto;

                img {
                    width: 100%;
                    height: 100%;
                    display: block;

                }
            }

            p {
                padding: 8px 0 0 0;
                color: var(--secondary-900);
                text-align: center;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                max-width: 62px;
            }
        }
    }

}