.addProductModalCategoryAlignment {
  margin: 0 0 16px 0;

  .addProductModalCategoryBox {
    border-radius: 10px;
    border: 1px solid var(--secondary-50);
    background: #FFF;
    padding: 10px 16px 16px 16px;

    .addProductModalCategoryHeading {
      display: flex;
      align-items: center;

      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #495057;
        margin-right: 3px;
      }

      .starAlignment {
        font-family: 'Inter';
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #d23232;
      }
    }

    .categoryNotes {
      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #808191;
      }
    }

    .modalChildetailsBoxAlignment {
      margin: 16px 0 0 0;
      background: #ffffff;
      border: 1px solid var(--secondary-50);
      border-radius: 8px;
      padding: 16px;
      height: 250px;
      overflow-x: hidden;
      overflow-y: scroll;

      @media only screen and (max-width: '767px') {
        display: none;
      }

      .gridAlignment {
        display: grid;
        grid-template-columns: 18px 1fr;
        gap: 11px;

        .addMinusBoxAlignment {
          background: #acb1c0;
          border-radius: 3px;
          padding: 8px 3px 8px 3px;
          cursor: pointer;
          height: 18px;

          .minusAlignment {
            border: 1px solid #ffffff;
            width: 12px;
          }
        }

        .categoryFashionNameAlignment {
          .mainDropdownNameAlignment {
            border-bottom: 1px solid var(--secondary-50);
            // padding: 0 0 5px 0;
            display: flex;
            justify-content: space-between;
            padding: 0 0 8px 0;

            .firstNameAlignment {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              color: var(--secondary-900);
            }

            .imageTransition {
              cursor: pointer;

              .iconRotateStable {
                transform: rotate(180deg);
              }

              .transitionIcon {
                transition: 0.3s ease-in-out;
              }
            }
          }

          .childDetailsDropdownNameAlignment {
            padding: 0 0 5px 11px;
            // border-bottom: 1px solid var(--secondary-50);

            &:last-child {
              border-bottom: none;
            }

            .childDetailsNameAlignment {
              padding: 8px 0;
              border-bottom: 1px solid var(--secondary-50);
              display: flex;
              align-items: center;
              justify-content: space-between;

              &:last-child {
                border-bottom: none;
              }

              .firstNameAlignment {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                color: var(--secondary-900);
              }

              .imageTransition {
                cursor: pointer;

                .iconRotateStable {
                  transform: rotate(180deg);
                }

                .transitionIcon {
                  transition: 0.3s ease-in-out;
                }
              }
            }

            .optionDetailsBorder {

            }

            .optionDetailsAlignment {
              padding: 5px 0 0 0;
              // height: auto;


              // display: grid;
              // grid-template-columns: repeat(3, 1fr);
              gap: 8px 32px;
              max-width: 500px;
              // max-width: 340px;
              display: flex;
              flex-wrap: wrap;

            }
          }

          .tabShowArea {
            max-height: 164px;
            overflow-y: auto;
            transition: 0.3s ease-in-out;
            border-bottom: 1px solid var(--secondary-50);

          }

          .tabHiddenArea {
            max-height: 0px;
            overflow: hidden;
            transition: 0.3s ease-in-out;
            border-bottom: none;
          }
        }
      }
    }

    .mobileViewCategoryAlignment {
      margin: 16px 0 0 0;
      position: relative;
      display: none;

      @media only screen and (max-width: '767px') {
        display: block;
      }

      input {
        width: 100%;
        height: 40px;
        border-radius: 10px;
        padding: 0 30px 0 16px;
        color: var(--secondary-500);
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        border-radius: 10px;
        border: 1px solid var(--secondary-50);
        background: var(--light-grey);
      }

      .iconAlignment {
        position: absolute;
        right: 12px;
        top: 12px;
      }
    }
  }
}

.checkBoxAlignment {
  // width: 100%;
  position: relative;
  margin: 0 0 8px 0;
  display: flex;
  align-items: center;
  display: flex;
  gap: 12px;

  .checkboxAlignment {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 18px;
    height: 18px;
    min-width: 18px;
    max-width: 18px;

    input[type="checkbox"] {
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
    }

    input.checkbox {
      width: 15px;
      height: 15px;
      /* margin: 2px; */
    }

    label {
      margin-bottom: 0;
      margin-left: 10px;
    }
  }

  span {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: var(--secondary-500);

    &.activeCheckBox {
      color: var(--secondary-900);
    }
  }
}