.mobileViewAdjustImageModalSection {


    .mobileViewAdjustImageModalHeaderAlignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 24px 16px 16px;

        h6 {
            color: var(--secondary-900);
            font-family: 'Poppins', sans-serif;
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
        }

        a {
            color: var(--primary-500);
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }

    }

    .mobileViewAdjustBodyAlignment {
        height: calc(100vh - 240px);
        overflow-y: auto;

        .mobileViewAdjustResizeAlignment {
            width: 100%;
            height: 375px;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
            }

            .nineSixteen {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .leftBox {
                    width: 85px;
                    height: 100%;
                    opacity: 0.6;
                    background: #1E2432;

                    &.secondLeftBox {
                        width: 64px;
                    }

                    &.thirdLeftBox {
                        width: 36px;
                    }
                }
            }
        }

        .mobileViewAdjustResizeOptionAlignment {
            padding: 32px 16px;

            .mobileViewAdjustResizeOptionName {
                padding: 8px 16px;
                margin: 0 0 16px 0;

                label {
                    color: var(--secondary-900);
                    font-family: 'Poppins', sans-serif;
                    font-size: 13px;
                    font-weight: 600;
                    line-height: 19px;
                    margin: 0;
                    padding: 0;
                }

                .mobileViewAdjustInputAlignment {
                    padding: 10px 0 0 0;

                    input[type="range"] {
                        width: 100%;
                    }
                }
            }
        }

    }

    .mobileViewAdjustButtonAlignment {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;
        padding: 0 16px;

        .mobileViewDeleteButton {
            button {
                width: 100%;
                height: 44px;
                border-radius: 10px;
                background: #FDE8E8;
                color: #E71616;
                text-align: center;
                font-family: 'Poppins', sans-serif;
                font-size: 18px;
                font-weight: 500;
                line-height: 28px;
            }
        }

        .mobileSaveChangesButton {
            button {
                width: 100%;
                height: 44px;
                border-radius: 10px;
                background: var(--primary-500);
                color: #FFF;
                text-align: center;
                font-family: 'Poppins', sans-serif;
                font-size: 18px;
                font-weight: 500;
                line-height: 28px;
            }
        }

    }
}