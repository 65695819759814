.book-appliance-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(30, 36, 50, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.book-appliance-overlay.open {
  opacity: 1;
  visibility: visible;
}


.book-appliance-modal {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 70%;
  height: calc(100vh - 64px);
  // height: calc(100vh - 104px);
  // padding: 0 16px;
  // background: var(--secondary-50);
  // border-radius: 16px 0px 0px 0px;
  translate: 100% 0;
  transition: 0.3s;
  display: flex;
  align-items: flex-start;
  gap: 16px;

  @media only screen and (max-width:"1199px") {
    width: 85%;
  }


  @media only screen and (max-width:"767px") {
    width: 100%;
    top: 0;
    height: 100vh;

  }


  .sidebar-modal-alignment {
    background: #FFFFFF;
    border-radius: 16px 0px 0px 0px;
    padding: 16px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;

    @media only screen and (max-width:"767px") {
      padding: 0;
      border-radius: 0;
    }


  }
}

.book-appliance-modal.open {
  translate: 0;
}