.sellerOnboardingTellSelfSection {
  padding: 100px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: calc(100vh - 146px);


  @media only screen and (max-width:"1499px") {
    padding: 12px 0 0 0;
  }

  @media only screen and (max-width:"1024px") {
    height: calc(100vh - 80px);
  }

  .sellerOnboardingContent {
    width: 100%;

    .sellerOnboardingTellSelfHeading {
      padding: 0 0 116px 0;

      @media only screen and (max-width:"1499px") {
        padding: 0 0 80px 0;
      }

      h6 {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 40px;
        line-height: 60px;
        color: #000000;
        padding: 0 0 16px 0;
        text-align: center;


        @media only screen and (max-width:"1199px") {
          font-size: 24px;
          padding: 0 0 8px 0;
        }
      }

      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #979797;

        @media only screen and (max-width:"1199px") {
          font-size: 14px;
        }
      }
    }

    .sellerOnboardingTellSelfOptionDetails {
      padding: 0 105px;
      width: 100%;

      @media only screen and (max-width:"1299px") {
        padding: 0 40px;
      }

      @media only screen and (max-width:"576px") {
        padding: 0;
      }

      .sellerOnboardingTellSelfOptionGrid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 32px;

        @media only screen and (max-width:"576px") {
          gap: 16px;
        }

        .sellerOnboardingTellSelfOptionGridItem {
          .tellSelfOptionBox {
            background: var(--white);
            border: 1px solid #d2d2d2;
            border-radius: 20px;
            height: 116px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: 0.4s ease-in-out;

            @media only screen and (max-width:"1499px") {
              height: 90px;
              padding: 16px;
            }

            &:hover {
              background: rgba(121, 144, 255, 0.29);
              border: 2px solid #556ee6;
              border-radius: 20px;
              transition: 0.4s ease-in-out;
            }

            &.activeType {
              background: rgba(121, 144, 255, 0.29);
              border: 2px solid #556ee6;
              border-radius: 20px;
              transition: 0.4s ease-in-out;
            }

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 20px;
              line-height: 30px;
              text-align: center;
              color: #303030;

              @media only screen and (max-width:"1499px") {
                font-size: 18px;
                line-height: 28px;
              }

              @media only screen and (max-width:"1024px") {
                font-size: 16px;
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  }

  .sellerOnboardingNextAlignment {
    padding: 0 60px 46px 0;
    width: 100%;
    display: block;
    text-align: right;

    @media only screen and (max-width:"576px") {
      padding: 0 16px 46px 0;

    }

    &.deactiveButtonClass {
      opacity: 0.5;
    }

    a {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: var(--primary-500);
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 6px;
      cursor: pointer;

      @media only screen and (max-width:"576px") {
        font-size: 16px;
      }

      img {
        width: 24px;
        display: block;

        @media only screen and (max-width:"576px") {
          width: 18px;
        }

      }
    }
  }
}