.categoryHotWatchesdProductSection {
    background: var(--white);
    padding: 0 32px;
    border-radius: 10px;
  
    .categoryHotWatchesheading {
      padding: 32px 0 26px 0;
  
      h4 {
        color: var(--bookmark-heading-color);
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
      }
    }
  
    .categorHotWatchesBodyAlignemt {
      height: calc(100vh - 190px);
      overflow-y: auto;
      .categorHotWatchesGridAlignment {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 16px;
      }
    }
  }
  