.peopleFollowersSection {
  border-radius: 16px;
  margin: 13px 0 0 0;
  border: 2px solid var(--Primary-primary-50, #eef1fd);
  padding: 10px 30px;
  background: linear-gradient(180deg, #fff 0.02%, #dceff5 135.04%);
  height: calc(100vh - 304px);
  overflow: auto;

  @media (max-width: 576px) {
    border-radius: 0px;
    height: calc(100vh - 163px);
    border-radius: 0px;
    border: none;
  }

  .hideSearch {
    display: none;

    @media (max-width: 576px) {
      display: block;
    }
  }

  .peopleFollowerSearchAlignment {
    height: 40px;
    display: flex;
    align-items: center;
    background: #f8f8f8;
    border-radius: 10px;
    padding: 0 16px 0 0;

    @media only screen and (max-width: '1024px') {
      width: 100%;
      max-width: 100%;
    }

    input {
      width: 100%;
      height: 100%;
      background: transparent;
      border: none;
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      font-weight: 400;
      background-color: #f8f8f8;
      line-height: 24px;

      &::placeholder {
        color: #acb1c0;
      }
    }
  }

  .textStyle {
    color: #171725;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    @media (max-width: 576px) {
      display: none;
    }
  }

  .peopleFollowersDetails {
    @media only screen and (max-width: '1024px') {
      padding: 16px;
    }

    @media (max-width: 576px) {
      padding: 0;
    }

    .peopleFollowerHeading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      flex-wrap: wrap;

      h5 {
        color: var(--bookmark-heading-color);
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;

        @media only screen and (max-width: '1024px') {
          display: none;
        }
      }

      .peopleFollowerSearchAlignment {
        max-width: 391px;
        width: 391px;
        height: 40px;
        display: flex;
        align-items: center;
        background: #f8f8f8;
        border-radius: 10px;
        padding: 0 16px 0 0;

        @media only screen and (max-width: '1024px') {
          width: 100%;
          max-width: 100%;
        }

        input {
          width: 100%;
          height: 100%;
          background: transparent;
          border: none;
          font-family: 'Poppins', sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;

          &::placeholder {
            color: #acb1c0;
          }
        }
      }
    }

    .peopleFollowersAllDetailsAlignment {
      padding: 45px 0 0 0;

      @media only screen and (max-width: '1024px') {
        padding: 32px 16px;

        &.widthNameAlignment {
          padding: 16px 0 32px 0;
        }
      }

      .findPeopleNameAlignment {
        display: none;

        @media only screen and (max-width: '1024px') {
          display: none;
          padding: 0 0 16px 0;

          h6 {
            color: var(--bookmark-heading-color);
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
          }

          p {
            padding: 2px 0 0 0;
            color: var(--secondary-500);
            font-family: 'Poppins', sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }

      .peopleFollowersGridAlignment {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 32px;

        @media only screen and (max-width: '1024px') {
          padding: 0 16px;
        }

        @media only screen and (max-width: '767px') {
          grid-template-columns: repeat(2, 1fr);
          gap: 16px;
          padding: 0;
        }
      }
      .showMoreButtonContain {
        padding: 70px 0 52px 0;
      }
    }

    .noFollowesDataBoxAlignment {
      height: calc(100vh - 376px);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 42px;
      flex-direction: column;

      p {
        color: var(--secondary-300);
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
