.addProductShippingSection {
  margin: 0 0 16px 0;

  .addProductShippingPricingdBox {
    border-radius: 10px;
    border: 1px solid var(--secondary-50);
    background: #FFF;
    padding: 10px 16px 16px 16px;

    .addProductShippingHeadingAlignment {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: var(--secondary-900);
      }

      .shippingHeadingOtherOptionAlignment {
        display: flex;
        align-items: center;
        gap: 15px;

        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 13px;
          line-height: 20px;
          color: #495057;
        }

        .unitFlexAlignment {
          display: flex;
          align-items: center;
          gap: 6px;

          .unitDetailsChildFlex {
            padding: 4px 8px;
            display: flex;
            align-items: center;
            gap: 4px;
            cursor: pointer;
            position: relative;

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              color: #acb1c0;
            }

            &.unitOptionActive {
              p {
                color: var(--secondary-900);
              }

              svg {
                transform: rotate(180deg);
                transition: 0.4s ease-in-out;

                path {
                  stroke: #1e2432;
                }
              }
            }

            &.deactiveOption {
              svg {
                transform: rotate(0deg);
                transition: 0.4s ease-in-out;
              }
            }

            .unitDetailsDropdownBoxAlignment {
              background: #ffffff;
              border: 1px solid #e3e4e8;
              border-radius: 4px;
              padding: 16px 8px;
              position: absolute;
              z-index: 99999999;
              top: 32px;
              right: 0;
              height: auto;
              max-height: 166px;
              overflow-y: auto;

              .unitDetailsDropdownOption {
                padding: 3px 10px;
                margin: 0 0 8px 0;

                &:last-child {
                  margin: 0;
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 21px;
                  color: var(--secondary-900);
                  cursor: pointer;
                  white-space: nowrap;
                }

                &:hover {
                  background: #eef1fc;
                  border-radius: 2px;
                }

                &.selected {
                  background: #eef1fc;
                  border-radius: 2px;
                }
              }
            }
          }
        }
      }

      .addProductShippingFormAlignment {
        padding: 16px 0 0 0;

        .formInputDetailsAlignment {
          .formInputRelative {
            position: relative;

            .iconAlignment {
              position: absolute;
              top: 12px;
              right: 8px;
              cursor: pointer;
              display: flex;
              align-items: center;

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: #acb1c0;
              }

              img {
                display: block;
              }
            }

            .iconAlignment2 {
              position: absolute;
              top: 12px;
              left: 14px;
              cursor: pointer;
              display: flex;
              align-items: center;

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: #acb1c0;
              }
            }

            &.titleError {
              input {
                border: 1.9px solid red;
              }
            }
          }

          label {
            font-family: 'Poppins', sans-serif;
            margin: 0 0 4px 0;
            display: block;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: #495057;
            text-align: left;

            span {
              color: #d23232;
            }
          }

          input {
            width: 100%;
            border: 1px solid var(--secondary-50);
            background: var(--light-grey);
            border-radius: 10px;
            height: 44px;
            padding: 0 10px;

            &.mainInputpadding {
              padding: 0 60px 0 10px;
            }

            &.childInputPadding {
              padding: 0 25px 0 10px;
            }

            &.secondInputAlignment {
              padding: 0 10px 0 28px;
            }

            &.otherInputAlignment {
              padding: 0 40px 0 28px;
            }
          }
        }

        .threeGridAlignment {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 15px 8px;
          margin: 12px 0 0 0;
        }
      }
    }
  }

  .addProductShippingFormAlignment {
    padding: 16px 0 0 0;

    .formInputDetailsAlignment {
      &.invetoryInput {
        @media only screen and (max-width: '767px') {
          width: 147px;
        }
      }

      .formInputRelative {
        position: relative;

        .iconAlignment {
          position: absolute;
          top: 12px;
          right: 8px;
          cursor: pointer;
          display: flex;
          align-items: center;

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: #acb1c0;
          }

          img {
            display: block;
          }
        }

        .iconAlignment2 {
          position: absolute;
          top: 12px;
          left: 14px;
          cursor: pointer;
          display: flex;
          align-items: center;

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: #acb1c0;
          }
        }

        &.titleError {
          input {
            border: 1.9px solid red;
          }
        }
      }

      label {
        font-family: 'Poppins', sans-serif;
        margin: 0 0 4px 0;
        display: block;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #495057;
        text-align: left;

        span {
          color: #d23232;
        }
      }

      input {
        width: 100%;
        border: 1px solid var(--secondary-50);
        background: var(--light-grey);
        border-radius: 10px;
        height: 44px;
        padding: 0 10px;

        &.mainInputpadding {
          padding: 0 60px 0 10px;
        }

        &.otherInputpadding {
          padding: 0 40px 0 10px;
        }

        &.childInputPadding {
          padding: 0 25px 0 10px;
        }

        &.secondInputAlignment {
          padding: 0 10px 0 28px;
        }
      }
    }

    .threeGridAlignment {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 15px 8px;
      margin: 12px 0 0 0;
    }
  }
}