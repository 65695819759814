.appliance-trending-store-section {
    background: #FFFFFF;
    margin: 0 0 16px 0;

    .container {
        max-width: 1440px;
        margin: auto;
        padding: 0;

        .appliance-trending-store-alignment {
            padding: 32px;

            @media only screen and (max-width:"767px") {
                padding: 16px 0 16px 16px;
            }

            .appliance-trending-box-alignment {
                background: #F8F8F8;
                border-radius: 32px;
                padding: 16px;
                display: flex;
                align-items: center;
                gap: 20px;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(90deg, #FFFCFC 59.52%, #B5B4B9 76.72%, #1E2432 100%);
                    border-radius: 16px;
                    z-index: 1;

                    @media (max-width:768px) {
                        display: none   ;
                    }
                }

                @media only screen and (max-width:"899px") {
                    flex-wrap: wrap;
                }

                @media only screen and (max-width:"767px") {
                    background: none;
                    padding: 40px 0;
                    gap: 32px;
                }

                .left-part-appliance-alignment {
                    width: 47%;
                    position: relative;
                    z-index: 2;

                    &::before {
                        content: '';
                        position: absolute;
                        background: linear-gradient(270deg, #F8F8F8 0%, rgba(248, 248, 248, 0) 100%);
                        width: 22%;
                        height: 100%;
                        top: 0;
                        right: 0;
                        z-index: 99;

                        @media only screen and (max-width:"767px") {
                            display: none;
                        }
                    }

                    @media only screen and (max-width:"767px") {
                        width: 100%;
                        order: 2;
                    }

                    .appliance-img-alignment {
                        height: 662px;
                        width: 100%;
                        position: relative;
                        padding: 0 8px;

                        @media only screen and (max-width:"767px") {
                            height: 296px;
                            padding: 0 4px;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 16px;
                        }

                        .brand-details-alignment {
                            position: absolute;
                            top: 32px;
                            left: 32px;

                            @media only screen and (max-width:"767px") {
                                top: 8px;
                                left: 8px;
                            }

                            .brand-box-details-alignment {
                                background: #FFFFFF;
                                border-radius: 10px;
                                padding: 4px 21px 6px 4px;
                                display: flex;
                                align-items: center;
                                gap: 9px;

                                @media only screen and (max-width:"767px") {
                                    padding: 4px 8px 4px 4px;
                                    gap: 8px;
                                }

                                .brand-logo-box-alignment {

                                    background: #005AA9;
                                    border-radius: 10px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    padding: 20px 1px;

                                    @media only screen and (max-width:"767px") {
                                        padding: 12px 0;

                                        img {
                                            width: 38px;
                                            height: auto;
                                        }
                                    }

                                }

                                .brand-logo-details-alignment {
                                    h5 {
                                        font-family: 'Poppins', sans-serif;
                                        font-weight: 600;
                                        font-size: 16px;
                                        line-height: 24px;
                                        color: #000000;

                                        @media only screen and (max-width:"767px") {
                                            font-size: 13px;
                                            line-height: 20px;
                                        }
                                    }

                                    .brand-details-rate-alignment {
                                        padding: 3px 0 0 0;
                                        display: flex;
                                        align-items: center;
                                        gap: 4px;

                                        @media only screen and (max-width:"767px") {
                                            padding: 2px 0 0 0;
                                        }

                                        span {
                                            font-family: 'Poppins', sans-serif;
                                            font-weight: 600;
                                            font-size: 13px;
                                            line-height: 20px;
                                            color: #808191;


                                            @media only screen and (max-width:"767px") {
                                                font-size: 10px;
                                                line-height: 15px;
                                            }
                                        }

                                        .rate-img-flex-alignment {
                                            display: flex;
                                            align-items: center;
                                            gap: 2px;
                                        }
                                    }
                                }

                            }
                        }
                    }

                    @media only screen and (max-width:"899px") {
                        width: 100%;
                    }
                }

                .right-part-appliance-alignment-mobile {
                    width: 100%;
                    display: none !important;
                    padding: 0 20px 0 0;

                    @media (max-width:768px) {
                        display: flex !important;
                    }
                }

                .right-part-appliance-alignment,.right-part-appliance-alignment-mobile {
                    width: 40%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 140px;
                    position: relative;
                    z-index: 2;

                    @media only screen and (max-width:"899px") {
                        width: 100%;
                        flex-direction: row;
                    }

                    @media only screen and (max-width:"768px") {
                        width: 100%;
                        justify-content: space-between;
                        display: none;
                    }


                    p {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 40px;
                        line-height: 60px;
                        color: var(--secondary-900);
                        max-width: 360px;
                        margin: auto;

                        @media only screen and (max-width:"767px") {
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 30px;
                            max-width: 226px;
                            margin: 0;
                        }

                    }

                    .explore-trending-store-alignment {

                        a {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 18px;
                            line-height: 27px;
                            color: var(--primary-500);
                            transition: 0.5s ease-in-out;

                            &:hover {
                                gap: 10px;
                                text-decoration: underline;
                            }

                            @media only screen and (max-width:"767px") {
                                font-size: 14px;
                                line-height: 21px;

                                img {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .slick-arrow {
        display: none !important;

    }
}