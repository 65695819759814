.mobileViewBookMarkModalSection {
    padding: 32px;
    position: relative;

    @media only screen and (max-width:"1024px") {
        padding: 16px 32px;
    }


    .closeAlignment {
        position: absolute;
        top: 24px;
        right: 24px;

        @media only screen and (max-width:"1024px") {
            display: none;
        }

        svg {
            cursor: pointer;
        }

    }

    .mobileViewBookMarkMainLogo {
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .mobileViewBookMarkMainHeding {
        text-align: center;
        padding: 8px 0 0 0;

        h4 {
            color: var(--secondary-900);
            font-size: 16px;
            font-weight: 500;
            line-height: 21px;
        }
    }

    .mobileViewBookMarkBodyAlignment {
        padding: 32px 0 0 0;
        text-align: center;

        h6 {
            color: var(--secondary-900);
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
        }

        .mobileViewBookMarkBodyDetails {
            padding: 10px 0 0 0;
        }
    }
}