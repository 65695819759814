.followAlignment {
  // background: #ffffff;
  // border-radius: 16px;
  // padding: 22px 32px;
  border-radius: 16px;
  background: #f3f5f8;
  padding: 16px;

  @media only screen and (max-width: '1099px') {
    display: none;
  }
  .followMainFlexAlignment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .headingAlignment {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      gap: 4px;

      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: var(--secondary-900);
      }

      p {
        width: 3px;
        min-width: 3px;
        max-width: 3px;
        height: 3px;
        border-radius: 50%;
        background: var(--secondary-500);
      }

      span {
        color: var(--secondary-500);
        font-size: 13px;
        font-weight: 500;
        line-height: 19px;
        display: block;
      }
    }

    .seeAllAlignment {
      padding: 4px 16px;
      cursor: pointer;
      a {
        color: var(--primary-500);
        text-align: right;
        font-size: 15px;
        font-weight: 500;
        line-height: 22px;
      }
    }
  }

  .serachAlignment {
    margin: 22px 0 0 0;

    .searchInputAlignment {
      background: #ffffff;
      border: 1px solid #f3f3f3;
      border-radius: 10px;
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      position: relative;

      .iconAlignment {
        position: absolute;
        top: 11px;
        left: 11px;

        img {
          cursor: pointer;
        }
      }

      input {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        padding: 8px 10px 8px 40px;
        background: transparent;
        border: none;
        height: 100%;
        width: 100%;
        color: #1e2432;

        &::placeholder {
          color: #acb1c0;
        }
      }
    }
  }

  .followPeopleDetailsAlignment {
    padding: 18px 0 0 0;
    height: auto;
    max-height: 400px;
    overflow-y: auto;

    @media only screen and (max-width: '1099px') {
      display: none;
    }

    .followPeopleDetailsFlex {
      display: flex;
      justify-content: space-between;
      padding: 0 0 16px 0;

      &:last-child {
        padding: 0;
      }

      .followProfileAlignment {
        display: flex;
        align-items: center;
        gap: 8px;

        .profileImg {
          width: 32px;
          max-width: 32px;
          min-width: 32px;
          height: 32px;

          img {
            width: 100%;
            height: 100%;
            cursor: pointer;
            display: block;
            border-radius: 50%;
          }
        }

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.1px;
          color: var(--secondary-900);
        }
      }

      .followButtonAlignment {
        button {
          padding: 4px 16px;
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 15px;
          line-height: 21px;
          color: var(--primary-500);
          border: none;
          cursor: pointer;
          background: transparent;

          &.following {
            color: var(--secondary-500);
          }
        }
      }
    }
  }
}
