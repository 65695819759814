.suggested-groups-all-content-alignment {
  max-height: 590px;
  overflow: auto;

  .card-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 19px;

    .card-grid-items {
      border-radius: 8px 8px 0px 0px;
      position: relative;

      .card-image {
        img {
          width: 100%;
          height: 182px;
          object-fit: cover;
          border-radius: 10px 10px 0 0;
        }
      }

      .card-details {
        position: absolute;
        bottom: 0;
        left: 0;

        width: 100%;

        .card-details-alignment {
          padding: 9px 9px 18px 9px;

          .all-content-alignment {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;

            .right-side-content {
              button {
                background: rgba(55, 55, 55, 0.78);
                border-radius: 10px;
                padding: 0 11px;
                height: 20px;
                font-weight: 500;
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                color: #fff;
              }
            }

            .left-side-content {
              display: flex;
              align-items: center;
              gap: 4px;

              img {
                cursor: pointer;
              }

              span {
                display: block;
                color: #fff;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
              }
            }
            .users {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .left_part {
                display: flex;
                align-items: center;
                padding-left: 10px;
                .users_likes {
                  display: flex;
                  align-items: center;
                  margin-left: -10px;

                  .user_box {
                    width: 20px;
                    display: flex;
                    align-items: center;
                    height: 20px;
                    justify-content: center;
                    background: #fff;
                    object-position: center;
                    border: 1px solid #ffffff;
                    border-radius: 30px;

                    img {
                      width: 100%;
                      border-radius: 30px;
                      object-fit: cover !important;
                    }
                  }
                }

                .count {
                  margin-left: -10px;
                  background: var(--primary-500);
                  border: 1px solid #ffffff;
                  border-radius: 12px;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 18px;
                  letter-spacing: 0.1px;
                  color: #ffffff;
                  padding: 0px 9px;
                }
              }

              .right-side-content {
                button {
                  background: rgba(55, 55, 55, 0.78);
                  border-radius: 10px;
                  padding: 0 11px;
                  height: 22px;
                  font-weight: 500;
                  font-size: 12px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                  color: #fff;
                }
              }
            }
          }

          h5 {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #fff;
            margin: 0 0 18px 0;
          }

          .line-clamp {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          h4 {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #fff;
            margin: 0 0 1px 0;
            cursor: pointer;
          }
        }
      }

      .blur-div {
        position: absolute;
        background: linear-gradient(360deg, rgba(30, 36, 50, 0.8) 0%, rgba(30, 36, 50, 0) 100%);
        border-radius: 0px;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 162px;
      }
    }
  }
}

.group-join-all-card-content-alignment {
  .card-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;

    .card-grid-items {
      position: relative;

      .card-details {
        position: absolute;
        bottom: 0;
        left: 0;

        width: 100%;

        .card-details-alignment {
          padding: 9px 9px 18px 9px;

          .all-content-alignment {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;

            .right-side-content {
              button {
                background: rgba(55, 55, 55, 0.78);
                border-radius: 10px;
                padding: 0 11px;
                height: 22px;
                font-weight: 500;
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                color: #fff;
              }
            }

            .left-side-content {
              img {
                cursor: pointer;
              }

              span {
                display: block;
                color: #fff;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
              }
            }

            .users {
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .left_part {
                display: flex;
                align-items: center;
                padding-left: 10px;
                .users_likes {
                  display: flex;
                  align-items: center;
                  margin-left: -10px;

                  .user_box {
                    width: 20px;
                    display: flex;
                    align-items: center;
                    height: 20px;
                    justify-content: center;
                    background: #fff;
                    object-position: center;
                    border: 1px solid #ffffff;
                    border-radius: 30px;

                    img {
                      width: 100%;
                      border-radius: 30px;
                      object-fit: cover !important;
                    }
                  }
                }

                .count {
                  margin-left: -10px;
                  background: var(--primary-500);
                  border: 1px solid #ffffff;
                  border-radius: 12px;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 18px;
                  letter-spacing: 0.1px;
                  color: #ffffff;
                  padding: 0px 9px;
                }
              }

              .right-side-content {
                button {
                  background: rgba(55, 55, 55, 0.78);
                  border-radius: 10px;
                  padding: 0 11px;
                  height: 22px;
                  font-weight: 500;
                  font-size: 12px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                  color: #fff;
                }
              }
            }
          }

          h5 {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #fff;
            margin: 0 0 18px 0;
          }

          .line-clamp {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }

          h4 {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #fff;
            margin: 0 0 1px 0;
            cursor: pointer;
          }
        }
      }

      .blur-div {
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(360deg, rgba(30, 36, 50, 0.8) 0%, rgba(30, 36, 50, 0) 100%);
        width: 100%;
        height: 115px;
      }

      .card-image {
        img {
          width: 100%;
          object-fit: cover;
          height: 132px;
          border-radius: 8px 8px 0px 0px;
        }
      }
    }
  }
}

.suggested-frieds-all-content-alignment {
  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 16px 22px 40px 22px;
    gap: 50px;

    max-height: 780px;
    overflow: scroll;

    .grid-items {
      h5 {
        font-weight: 600;
        font-size: 20px;
        line-height: 33px;
        color: #000;
        text-align: center;
        margin: 0 0 4px 0;

        @media only screen and (max-width: '768px') {
          font-size: 16px;
          line-height: 24px;
        }
      }

      .line-clamp {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      p {
        color: #808191;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;

        margin: 0 0 4px 0;
      }

      span {
        display: block;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #808191;

        @media only screen and (max-width: '768px') {
          font-size: 12px;
          line-height: 18px;
        }
      }

      .button-center-alignment {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px 0 0 0;

        button {
          border: none;
          cursor: pointer;
          padding: 6px 47px;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          background: var(--primary-500);
          border-radius: 8px;
          color: #fff;
        }

        .button-follow {
          border: none;
          cursor: pointer;
          padding: 6px 44px;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          background: #e4e4e8;
          border-radius: 8px;
          color: var(--primary-500);
        }
      }

      .button-spcebet-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        padding: 16px 0 0 0;

        // flex-wrap: wrap;

        @media only screen and (max-width: '768px') {
          justify-content: center;
          flex-wrap: wrap;
        }

        .button-deactive {
          border: 1px solid #556ee6;
          cursor: pointer;
          padding: 6px 20px;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          background: #fff;
          border-radius: 8px;
          color: var(--primary-500);
        }

        .button-active {
          border: none;
          cursor: pointer;
          padding: 6px 20px;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          background: var(--primary-500);
          border-radius: 8px;
          color: #fff;
        }
      }

      .image-center-alignment {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0 16px 0;

        img {
          width: 200px;
          height: 200px;
          border-radius: 50%;
          object-fit: cover;

          @media only screen and (max-width: '768px') {
            width: 140px;
            height: 140px;
          }
        }
      }
    }
  }
}

.followers-list-all-content-alignment {
  .main-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 25px 0 0 0;

    .main-card:last-child {
      margin: 0;
    }

    .main-card {
      padding: 8px 13px;
      background: #ffffff;
      border: 1px solid #f1f1f5;
      border-radius: 15px;
      transition: 0.3s ease-in-out;
      cursor: pointer;
      margin: 0 0 20px 0;

      &:hover {
        border: 1px solid #f1f1f5;
        box-shadow: 0px 5px 50px rgba(163, 156, 169, 0.15);
        transition: 0.3s ease-in-out;
      }

      .profile-grid {
        display: grid;
        grid-template-columns: 70px 1fr;
        gap: 40px;

        .card-last-content {
          display: flex;
          align-items: center;
          gap: 32px;

          img {
            cursor: pointer;
          }

          span {
            display: block;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #92929d;
          }
        }

        .profile-grid-items {
          .two-text-alignment {
            display: flex;
            align-items: center;
            justify-content: space-between;

            div + div {
              display: flex;
              align-items: center;
              gap: 8px;
            }

            div {
              button {
                padding: 4px 10px;
                border-radius: 10px;
                font-weight: 500;
                font-size: 12px;
                cursor: pointer;
                border: none;
                line-height: 18px;
              }

              button:first-child {
                background: var(--primary-500);
                color: #fff;
              }

              button:last-child {
                background: #f1f1f5;
                color: #696974;
              }
            }

            div {
              h5 {
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.1px;
                color: #92929d;
                margin: 0;
              }
            }
          }

          h4 {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.1px;
            color: var(--secondary-900);
          }

          .profile-image {
            img {
              width: 70px;
              height: 70px;
              object-fit: cover;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}
