.videoPlayerButtonAlignment {
  width: 100%;
  height: 100%;
  position: relative;

  .actionButtonAlignment {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    z-index: 9;

    [dir='rtl'] & {
      left: 20px;
      right: unset;
    }

    img {
      max-width: 28px;
      cursor: pointer;
      width: 28px !important;
      height: auto !important;
      background: none !important;
      border-radius: 0px !important;
    }
  }
}
