.mobileViewSpeacialOfferModal {
  .mobileViewHeading {
    padding: 16px;
    position: sticky;
    top: 28px;
    background: var(--white);
    left: 0;
    z-index: 999;
    h6 {
      color: var(--secondary-900);
      font-size: 20px;
      font-weight: 600;
      line-height: 14px;
    }
  }

  .mobileViewBodyAlignment {
    padding: 16px 38px;

    .mobileViewSpeacialOfferDetailsAlignment {
      .mobileViewSpeacialOfferGrid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
        justify-items: center;
      }
    }
  }
}
