.bookMarkModalCollectionAlignment {
  border-radius: 16px 16px 0px 0px;
  background: linear-gradient(180deg, #F8F2FE 0%, #D9E4FB 26.31%, #DADBFD 48.04%, #ECD3F2 70.87%, #FFF 95.13%);
  padding: 48px 19px;
  margin: 11px 0 0 0;

  @media (max-width:576px) {
    padding: 48px 19px 70px 19px;
  }

  .bookMarkModalCollectionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .bookMarkModalCollectionNameAlignment {
      display: flex;
      align-items: center;
      gap: 10px;

      h6 {
        color: var(--bookmark-heading-color);
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
      }

      .bookmarkCollectionNumber {
        padding: 0 8px;
        border-radius: 10px;
        background: var(--secondary-50);

        @media only screen and (max-width: '767px') {
          display: none;
        }

        span {
          font-family: 'Poppins', sans-serif;
          font-size: 13px;
          font-weight: 500;
          line-height: 20px;
          color: var(--secondary-500);
        }
      }
    }

    .bookMarkWebVIewAlignment {
      @media only screen and (max-width: '767px') {
        display: none;
      }

      a {
        padding: 6px 16px;
        color: var(--primary-500);
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        cursor: pointer;
      }
    }

    .bookMarkMobileVIewAlignment {
      display: none;

      @media only screen and (max-width: '767px') {
        display: block;
      }

      a {
        padding: 6px 16px;
        color: var(--primary-500);
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        cursor: pointer;
      }
    }
  }

  .bookMarkModalCollectionDetails {
    // padding: 38px 0 0 0;

    @media only screen and (max-width: '1024px') {
      padding: 32px 0 0 0;
    }

    .bookMarkModalCollectionEmptyDetails {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 16px;
      border-radius: 16px;
      margin-top: 12px;
      // background: #f8f8f8;
      // height: 260px;
      height: 209px;

      border-radius: 16px;
      background: var(--primary-50);

      @media only screen and (max-width: '767px') {
        height: 145px;
        gap: 8px;
        border-radius: 16px;
        background: var(--primary-50);
        flex-direction: column;
      }

      .bookMarkModalCollectionEmptyImg {
        img {
          @media only screen and (max-width: '767px') {
            width: 56px;
          }
        }
      }

      p {
        color: var(--violet-300);
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;

        @media only screen and (max-width: '767px') {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }

    .bookMarkModalCollectionAllDetails {
      .bookMarkModalCollectionAllDetailsSlider {
        position: relative;

        &::before {
          content: '';
          // background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 98.27%);
          position: absolute;
          right: 0;
          width: 120px;
          height: 100%;
          z-index: 9;

          @media only screen and (max-width: '767px') {
            display: none;
          }
        }

        .bookMarkCollectionCardProductGrid {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 80px;

          @media only screen and (max-width: '1024px') {
            grid-template-columns: repeat(2, 1fr);
            gap: 32px;

            @media only screen and (max-width: '767px') {
              gap: 32px 45px;
              padding: 0 22px;
            }
          }
        }

        .bookMarkModalCollectionCardMain {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 10px;

          @media only screen and (max-width: '1350px') {
            grid-template-columns: repeat(4, 1fr);
          }

          @media only screen and (max-width: '900px') {
            grid-template-columns: repeat(3, 1fr);
          }

          @media only screen and (max-width: '576px') {
            grid-template-columns: repeat(2, 1fr);
          }
        }

        .bookMarkModalCollectionCardPadding {
          padding: 32px 0 0 0;

          &.bookMarkModalCollectionPaddingZero {
            padding: 0;
          }

          .bookMarkCollectionCardDetails {
            position: relative;
            width: 100%;
            height: 200px;
            cursor: pointer;

            @media only screen and (max-width: '767px') {
              height: 220px;
              width: 100%;
            }

            @media only screen and (max-width: '499px') {
              height: 113px;
              width: 100%;
            }

            .bookMarkCollectionCardImg {
              width: 180px;
              height: 180px;
              border-radius: 16px;
              border: 6px solid #fff;
              background: #fff;
              box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
              transform: rotate(-6.983deg);

              @media only screen and (max-width: '767px') {
                border: 3px solid #fff;
                border-radius: 10px;
                width: 100%;
                height: 220px;
              }

              @media only screen and (max-width: '499px') {
                border: 3px solid #fff;
                border-radius: 10px;
                width: 100%;
                height: 113px;
              }

              img {
                width: 100%;
                height: 100%;
                display: block;
                border-radius: 16px;
                object-fit: cover;

                @media only screen and (max-width: '767px') {
                  border-radius: 10px;
                  object-position: center;
                }
              }

              &:nth-child(2) {
                position: absolute;
                top: 0;
                left: 10px;
                transform: rotate(0);
              }

              &:nth-child(3) {
                position: absolute;
                top: 0;
                left: 20px;
                transform: rotate(5.357deg);
                z-index: 8;
              }
            }

            .bookMarkCollectionCardImg2 {
              width: 100%;
              height: 100%;
              border-radius: 16px;
              border: 6px solid #fff;
              background: #fff;
              box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
              position: absolute;
              top: 0;
              left: 10px;

              @media only screen and (max-width: '767px') {
                border: 3px solid #fff;
                border-radius: 10px;
              }

              img {
                width: 100%;
                height: 100%;
                display: block;
                border-radius: 16px;
                object-fit: cover;

                @media only screen and (max-width: '767px') {
                  border-radius: 10px;
                }
              }
            }

            .bookMarkCollectionCardImg3 {
              width: 100%;
              height: 100%;
              border-radius: 16px;
              border: 6px solid #fff;
              background: #fff;
              box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
              position: absolute;
              top: 0;
              left: 20px;
              transform: rotate(5.357deg);

              @media only screen and (max-width: '767px') {
                border: 3px solid #fff;
                border-radius: 10px;
              }

              img {
                width: 100%;
                height: 100%;
                display: block;
                border-radius: 16px;
                object-fit: cover;

                @media only screen and (max-width: '767px') {
                  border-radius: 10px;
                }
              }
            }
          }

          .bookMarkCollectionProductNameDetailsAlignment {
            padding: 18px 0 0 0;
            cursor: pointer;

            @media only screen and (max-width: '767px') {
              padding: 8px 0 0 0;
            }

            h6 {
              color: var(--secondary-900);
              text-align: center;
              font-family: 'Poppins', sans-serif;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;

              @media only screen and (max-width: '767px') {
                font-size: 14px;
                line-height: 21px;
              }
            }

            p {
              padding: 2px 0 0 0;
              color: var(--secondary-secondary-500, #7a7e88);
              text-align: center;
              font-family: 'Poppins', sans-serif;
              font-size: 13px;
              font-weight: 20px;
              line-height: normal;

              @media only screen and (max-width: '767px') {
                font-size: 12px;
                line-height: 14px;
              }
            }
          }
        }

        :global(.slick-next) {
          right: 8px;
          z-index: 99;
        }

        :global(.slick-prev) {
          left: 8px;
          z-index: 99;
        }

        .mobileViewBookMarkMoreDetailsAlignment {
          display: none;

          @media only screen and (max-width: '767px') {
            display: block;
          }

          .mobileViewBookMarkMoreDetailsgrid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 8px 44px;

            @media only screen and (max-width: '576px') {
              gap: 8px 20px;
            }

            @media only screen and (max-width: '499px') {
              gap: 8px;
            }

            .mobileViewNoImg {
              // width: 50px;
              // height: 46px;
              width: 100%;
              height: 102px;

              @media only screen and (max-width: '499px') {
                width: 50px;
                height: 46px;
              }

              svg {
                width: 100%;
                height: 100%;
              }
            }

            .mobileViewBookMarkUploadedImg {
              position: relative;
              width: 100%;
              height: 102px;

              @media only screen and (max-width: '499px') {
                height: 46px;
                width: 50px;
              }

              .mobileViewbookMarkCollectionCardImg {
                width: 100%;
                height: 102px;
                border-radius: 4px;
                border: 1px solid #fff;
                background: #fff;
                box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);

                @media only screen and (max-width: '499px') {
                  height: 46px;
                  width: 50px;
                }

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                  border-radius: 4px;
                  object-fit: cover;
                }

                &:first-child {
                  transform: rotate(-6.983deg);
                }

                &:nth-child(2) {
                  position: absolute;
                  top: 0;
                  left: 3px;
                }

                &:last-child {
                  position: absolute;
                  top: 0;
                  left: 6px;
                  transform: rotate(5.357deg);
                }
              }

              .mobileViewbookMarkCollectionCardSecondImg {
                width: 100%;
                height: 100%;
                border-radius: 4px;
                border: 1px solid #fff;
                background: #fff;
                box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                  border-radius: 4px;
                  object-fit: cover;
                }
              }

              .mobileViewbookMarkCollectionCardThirdImg {
                width: 100%;
                height: 100%;
                border-radius: 4px;
                border: 1px solid #fff;
                background: #fff;
                box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                  border-radius: 4px;
                  object-fit: cover;
                }
              }
            }
          }

          .bookmarkSeeMoreAlignment {
            padding: 28px 0 0 0;
            text-align: center;

            a {
              color: var(--primary-500);
              text-align: center;
              font-family: 'Poppins', sans-serif;
              font-size: 14px;
              font-weight: 500;
              line-height: 21px;
            }
          }
        }
      }
    }

    :global(.slick-prev) {
      left: 21px;
      z-index: 99;
    }

    :global(.slick-next) {
      right: 21px;
      z-index: 999;
    }

    :global(.slick-arrow:hover) {
      background: #fff !important;
    }
  }

  .mobileViewAddNewCollectionModalAlignment {
    .mobileViewAddNewCollectionModalWrapper {
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      height: 100vh;
      background: rgba(30, 36, 50, 0.6);
      z-index: 999;
      padding: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      .mobileViewAddNewCollectionBox {
        border-radius: 16px;
        background: #fff;
        width: 100%;
        padding: 18px 14px 32px 14px;

        .mobileViewAddNewHeading {
          display: flex;
          align-items: center;
          justify-content: space-between;

          h6 {
            color: var(--secondary-900);
            font-family: 'Poppins', sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 36px;
          }

          .mobileCloseIconAlignment {
            svg {
              cursor: pointer;
            }
          }
        }

        .mobileViewBodyDetailsAlignment {
          padding: 32px 0 0 0;

          .mobileViewInputAlignment {
            label {
              margin: 0 0 0 8px;
              color: #495057;
              font-family: 'Poppins', sans-serif;
              font-size: 13px;
              font-weight: 400;
              line-height: 19px;
            }

            .mobileViewInput {
              width: 100%;
              height: 40px;
              border-radius: 10px;
              border: 1px solid var(--secondary-50);
              background: var(--secondary-50);
              margin: 4px 0 0 0;
              padding: 0 16px;

              input {
                width: 100%;
                height: 100%;
                background: transparent;
                border: none;
                color: var(--secondary-900);
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;

                &::placeholder {
                  color: var(--secondary-500);
                }
              }
            }
          }
        }

        .mobileViewButtonAlignment {
          padding: 32px 0 0 0;

          button {
            border-radius: 10px;
            color: #fff;
            text-align: center;
            font-family: 'Poppins', sans-serif;
            font-size: 18px;
            font-weight: 500;
            line-height: 28px;
            width: 100%;
            height: 44px;
            background: var(--primary-500);

            &.disableButton {
              background: var(--primary-100);
            }
          }
        }
      }
    }
  }
}