.mobile-view-more-aboutme-modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(30, 36, 50, 0.6);
  top: 0;
  right: 0;
  z-index: 99;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.overlay.open {
  opacity: 1;
  visibility: visible;
}




.mobile-view-more-modal {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: auto;
  padding: 0 16px;
  background: white;
  translate: 100% 0;
  transition: 0.3s;
}

.mobile-view-more-modal.open {
  translate: 0;
  z-index: 9999999;
  transform: translateY(70%);
}


.mobile-view-more-aboutme-bottom-modal-alignment {
  // width: 100%;
  // position: fixed;
  // z-index: 9999999;
  // left: 50%;
  // bottom: 0;
  transition: 0.3s ease-in-out;
  // margin: 0 auto;
  border-radius: 10px 10px 0 0;
  background-color: #fff;
  // height: 100%;

  // @media screen and (max-width: 574px) {
  //   height: inherit;
  //   overflow-y: auto;
  // }

  &.aboutme-modal-box-hidden {
    transform: translate(-50%, 100%);
    transition: 0.3s ease-in-out;
  }

  &.aboutme-modal-box-show {
    transform: translate(-50%, 0%);
    transition: 0.3s ease-in-out;
  }



  .mobile-view-aboutme-click-drop-alignment {
    border-bottom: 1px solid #EAECEF;
    padding: 8px 0 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      width: 33px;
      height: 3px;
      background: #E4E4E8;
      border-radius: 1.5px;
    }
  }

  .mobile-view-aboutme-details-alignment {
    padding: 16px;
    height: 100%;
    overflow-y: auto;

    .mobile-view-aboutme-interest-details-alignment {
      display: flex;
      gap: 18px;
      align-items: flex-start;
      padding: 40px 0 0 0;

      &:first-child {
        padding: 0;
      }

      .mobile-view-aboutme-icon-alignment {
        width: 24px;
        min-width: 24px;
        max-width: 24px;
        height: 24px;

        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }

      .mobile-view-aboutme-interest-name-tag-alignment {
        h5 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: var(--secondary-900);
        }

        .mobile-view-aboutme-tag-alignment {
          padding: 15px 0 0 0;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 8px;

          .mobile-view-aboutme-tag-box-alignment {
            background: rgba(172, 177, 192, 0.2);
            border-radius: 20px;
            max-width: 122px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 27px;

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: #495057;
            }
          }
        }

        .mobile-view-aboutme-univercity-details-alignment {
          padding: 16px 0 0 0;

          h6 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #495057;
          }

          p {
            padding: 2px 0 0 0;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: #808191;
          }

          span {
            display: block;
            padding: 2px 0 0 0;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: #495057;
          }
        }
      }
    }
  }

}