.customerDetailsModalSection {
  .customerDetailsModalBg {
    background: var(--white);
    border-radius: 10px;
    padding: 32px;
    height: calc(100vh - 95px);
    overflow-y: auto;

    .customerDetailsMainHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .customerdDetailsMainProfileFlex {
        display: flex;
        align-items: center;
        gap: 32px;

        .customerProfileImg {
          width: 116px;
          height: 116px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        .customerProfileNameAlignment {
          h5 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 120%;
            color: var(--secondary-900);
          }

          .customerMapAlignment {
            display: flex;
            align-items: center;
            gap: 2px;
            padding: 4px 0 0 0;

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 120%;
              color: var(--grey2);
            }
          }

          .customerMessageFlex {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 8px 0 0 0;

            .customerMessageButton {
              button {
                padding: 6px 16px;
                background: var(--primary-500);
                border-radius: 8px;
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 15px;
                line-height: 20px;
                color: var(--white);
                cursor: pointer;
              }
            }

            .customerMessageMore {
              width: 32px;
              height: 32px;
              background: var(--primary-50);
              border-radius: 8px;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }

      .customerOverviewDetailsAlignment {
        display: flex;
        align-items: center;
        gap: 80px;

        .overviewDetailsAlignment {
          text-align: center;

          h5 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 32px;
            line-height: 48px;
            color: var(--secondary-900);
          }

          p {
            padding: 4px 0 0 0;
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: var(--secondary-600);
          }
        }
      }
    }

    .customerDetailsBodyAlignment {
      padding: 32px 0 0 0;

      .customerDetailsBodyGrid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;

        .customerDetailsBodyGridItem {
          .customerDetailsInformationBox {
            background: var(--white);
            border-radius: 8px;
            padding: 22px 32px 32px 32px;
            box-shadow: 0px 5px 50px rgba(144, 143, 143, 0.1);

            .customerDetailsInformationHeading {
              display: flex;
              align-items: center;
              justify-content: space-between;

              h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 24px;
                line-height: 36px;
                color: var(--secondary-900);
              }
            }

            .customerDetailsInformationBody {
              padding: 32px 0 0 0;

              .customerDetailsInformationDetails {
                display: flex;
                align-items: flex-start;
                gap: 38px;
                padding: 0 0 32px 0;

                &:last-child {
                  padding: 0;
                }

                p {
                  width: 142px;
                  min-width: 142px;
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-900);
                }

                span {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-600);
                }
              }
            }
          }

          .customerDetailsNotesBoxAlignment {
            background: var(--white);
            border-radius: 8px;
            padding: 22px 32px;
            box-shadow: 0px 5px 50px rgba(144, 143, 143, 0.1);

            .customerDetailsInformationHeading {
              display: flex;
              align-items: center;
              justify-content: space-between;

              h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 24px;
                line-height: 36px;
                color: var(--secondary-900);
              }

              .addNotesButton {
                button {
                  padding: 6px 16px;
                  background: var(--primary-50);
                  border-radius: 8px;
                  display: flex;
                  align-items: center;
                  gap: 2px;
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                  line-height: 20px;
                  color: var(--primary-500);

                  img {
                    width: 16px;
                    height: 16px;
                    display: block;
                  }
                }
              }
            }

            .customerDetailsBoxDetails {
              margin: 16px 0 0 0;
              height: 242px;
              overflow-y: auto;
              position: relative;

              .customerFooterBOx {
                position: sticky;
                height: 48px;
                width: 100%;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
                border-radius: 0px 0px 16px 16px;
                bottom: 0;
                left: 0;
                z-index: 99;
              }

              .customerDetailsNotesBox {
                background: var(--yellow-50);
                border-radius: 10px;
                padding: 16px 16px 8px 16px;
                margin: 0 0 8px 0;
                cursor: pointer;

                &:last-child {
                  margin: 0;
                }

                &.boxActive {
                  border: 1px solid var(--yellow-400);
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 400;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--secondary-900);
                }

                .customerDetailsFooterDetailsAlignment {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 4px 0 0 0;

                  .customerDetailsOptionAlignment {
                    display: flex;
                    align-items: center;
                    gap: 16px;

                    .customerDetailsOption {
                      width: 18px;
                      height: 18px;

                      img {
                        cursor: pointer;
                        width: 100%;
                        height: 100%;
                        display: block;
                      }
                    }
                  }

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--secondary-500);
                    margin: 0;
                    padding: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.customerNotesModal {
  .customerAddNotesModal {
    .customerAddNotesModalWrapper {
      .customerAddNotesBoxAlignment {
        .addNotesHeading {
          padding: 0 0 16px 0;

          h6 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: var(--secondary-900);
          }
        }

        .addNotesBoxAlignment {
          min-height: 204px;
          height: auto;
          width: 100%;
          border: 1px solid var(--grey6);
          border-radius: 10px;

          textarea {
            width: 100%;
            min-height: 204px;
            height: 100%;
            padding: 16px;
            border-radius: 10px;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--secondary-900);
          }
        }

        .addNotesButtonALignment {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 8px;
          padding: 16px 0 0 0;

          .addNotesCancle {
            button {
              padding: 6px 16px;
              background: transparent;
              border: none;
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 15px;
              line-height: 20px;
              color: var(--primary-500);
              cursor: pointer;
            }
          }

          .addNotesSave {
            button {
              padding: 6px 16px;
              background: var(--primary-500);
              border: none;
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 15px;
              line-height: 20px;
              color: var(--white);
              cursor: pointer;
              border-radius: 8px;
            }
          }
        }
      }
    }
  }

  //   }
  // }
}