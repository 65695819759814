.book-appliance-trending-store-details-alignment {
  @media only screen and (max-width: '767px') {
    background: #ffffff;
  }

  .mobile-view-book-appliance-back-alignment {
    display: none;

    @media only screen and (max-width: '767px') {
      display: flex;
      align-items: center;
      gap: 18px;
      padding: 8px 16px;
      background: rgba(248, 248, 248, 0.92);
      backdrop-filter: blur(13.5914px);

      .book-appliance-back_details-alignment {
        background: #ffffff;
        border-radius: 10px;
        padding: 4px 9px 4px 10px;
        display: flex;
        align-items: center;
        gap: 8px;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #808191;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 58px;
        }
      }

      .mobile-heading-alignment {
        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          text-align: center;
          color: var(--secondary-900);
        }
      }
    }
  }

  .book-appliance-trending-store-banner-alignment {
    width: 100%;
    height: 178px;
    position: relative;

    @media only screen and (max-width: '767px') {
      height: 100px;
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 8px;

      @media only screen and (max-width: '767px') {
        display: none;
      }

      &.mobile-view-banner {
        display: none;

        @media only screen and (max-width: '767px') {
          display: block;
        }
      }
    }

    .book-appliance-trending-store-banner-details {
      position: absolute;
      top: 48px;
      left: 48px;

      @media only screen and (max-width: '767px') {
        top: 20px;
        left: 16px;
      }

      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 40px;
        line-height: 60px;
        color: var(--secondary-900);

        @media only screen and (max-width: '767px') {
          font-size: 20px;
          line-height: 30px;
        }
      }

      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--secondary-900);

        @media only screen and (max-width: '767px') {
          font-size: 12px;
          line-height: 18px;
          max-width: 165px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .book-appliance-trending-stote-detalis-alignment {
    margin: 17px 0 0 0;

    .book-appliance-trending-store-grid-alignment {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 16px;

      @media only screen and (max-width: '1299px') {
        grid-template-columns: repeat(3, 1fr);
      }

      @media only screen and (max-width: '899px') {
        grid-template-columns: repeat(2, 1fr);
      }

      @media only screen and (max-width: '767px') {
        grid-template-columns: repeat(2, 1fr);
        padding: 0 16px;
      }

      .book-appliance-trending-store-gridItem-alignment {
        .book-appliance-trending-store-box-alignment {
          background: #ffffff;
          border: 1px solid #eceef4;
          border-radius: 16px;
          padding: 8px;
          // height: 432px;

          .book-appliance-details-img-alignment {
            width: 100%;
            height: 222px;

            @media only screen and (max-width: '767px') {
              height: 147px;
            }

            img {
              width: 100%;
              height: 100%;
              border-radius: 8px;
            }
          }

          .book-appliance-child-details-alignment {
            padding: 24px 0 0 0;
            text-align: center;

            @media only screen and (max-width: '767px') {
              padding: 16px 0 0 0;
            }

            h5 {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 20px;
              line-height: 30px;
              color: #000000;

              @media only screen and (max-width: '767px') {
                font-size: 16px;
                line-height: 24px;
              }
            }

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: #495057;

              @media only screen and (max-width: '767px') {
                font-size: 12px;
                line-height: 18px;
                padding: 2px 0 0 0;
              }
            }

            .book-appliance-product {
              // font-weight: 600;
              // font-size: 14px;
              // line-height: 21px;
              // color: #808191;
              margin: 16px 0 8px 0;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 4px;

              @media only screen and (max-width: '767px') {
                flex-wrap: wrap;
                gap: 2px;
                margin: 9px 0 8px 0;
              }

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #495057;

                @media only screen and (max-width: '767px') {
                  font-size: 12px;
                  line-height: 18px;
                  margin: 0 0 2px 0;
                  padding: 0;
                }
              }

              p {
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background: #495057;

                @media only screen and (max-width: '767px') {
                  display: none;
                }
              }
            }

            .book-appliance-visitor {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-wrap: wrap;
              gap: 2px;

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #495057;

                @media only screen and (max-width: '767px') {
                  font-size: 12px;
                  line-height: 18px;
                  padding: 0;
                }
              }

              .book-appliance-pre {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;
              }
            }

            .follow-button-alignment {
              padding: 16px 8px 8px 8px;

              @media only screen and (max-width: '767px') {
                padding: 14px 0 0 0;
              }

              button {
                width: 100%;
                height: 32px;
                background: var(--primary-500);
                border-radius: 8px;
                font-family: 'Poppins', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #f8f8f8;
                cursor: pointer;
                &.following {
                  background: #f8f8f8;
                  color: var(--primary-500);

                  &:hover {
                    background: #eef1fc;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
