.variantDisplayModalSection {
    .variantDisplayModalWrapper {
        width: 100%;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.52);
        z-index: 9999999999;
        display: flex;
        align-items: center;
        justify-content: center;
        // overflow-y: auto;

        .variantWhiteBoxAlignment {
            width: 952px;
            height: 763px;
            border-radius: 16px;
            background: #FFF;
            padding: 0 16px 24px 16px;
            overflow-y: auto;

            @media only screen and (max-width:"1024px") {
                width: 100%;
                height: 100vh;
                border-radius: 0;
                overflow-y: auto;
                padding: 0 8px 12px 8px;
            }

            @media only screen and (max-width:"767px") {
                width: 100%;
                height: 100vh;
                border-radius: 0;
                overflow-y: auto;
                padding: 0;
            }

            .variantHeadingALignment {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 24px 16px 16px 16px;
                position: sticky;
                top: 0;
                background: #FFF;
                z-index: 999;

                @media only screen and (max-width:"767px") {
                    display: none;
                }

                h4 {
                    color: var(--secondary-900);
                    font-family: 'Poppins', sans-serif;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 28px;
                }

                .saveChangesButton {
                    button {
                        padding: 6px 26px;
                        border-radius: 8px;
                        background: var(--primary-500);
                        color: var(--f-8-f-8-f-8, var(--neutral-light-grey, #F8F8F8));
                        text-align: center;
                        font-family: 'Poppins', sans-serif;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 24px;
                        cursor: pointer;
                    }
                }
            }

            .variantTabAlignment {
                display: flex;
                align-items: center;
                gap: 16px;
                padding: 0 16px;
                flex-wrap: wrap;
                position: sticky;
                top: 76px;
                background: #FFF;
                z-index: 999;

                @media only screen and (max-width:"767px") {
                    display: none;
                }

                .variantTabBox {
                    border-radius: 8px;
                    border: 1px solid #E4E4E8;
                    background: #FFF;
                    min-width: 90px;
                    height: 34px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;
                    cursor: pointer;
                    padding: 0 10px 0 8px;
                    position: relative;

                    p {
                        color: var(--primary-500);
                        font-family: 'Poppins', sans-serif;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 21px;

                        @media only screen and (max-width:"576px") {
                            font-size: 12px;
                            line-height: 18px;
                        }

                    }

                    img {
                        transition: 0.4s ease-in-out;

                        &.oprnDropdownBox {
                            transition: 0.4s ease-in-out;

                            transform: rotate(-180deg);
                        }
                    }

                    .variantAllProductVarintTabDropdownBox {
                        position: absolute;
                        top: 40px;
                        width: 100%;
                        left: 0;
                        border-radius: 10px;
                        z-index: 999;
                        background: #FFF;
                        box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.12);

                        &.colorWidthAlignment {
                            width: fit-content;
                        }

                        .variantAllProdutctoptionAlignment {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 4px;
                            padding: 4px 16px;
                            transition: all 0.4s ease-in-out;

                            .colorBoxAlignment {
                                width: 16px;
                                min-width: 16px;
                                max-width: 16px;
                                height: 16px;
                                border-radius: 4px;
                                background: conic-gradient(from 180deg at 50% 50%, #F00 0deg, #FFF500 115.2117669582367deg, #0075FF 229.73557949066162deg, #F00 360deg);
                            }

                            p {
                                color: var(--secondary-900);
                                font-family: 'Poppins', sans-serif;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 24px;
                                white-space: nowrap;
                            }

                            &:hover {
                                transition: all 0.4s ease-in-out;
                                background: var(--secondary-50);
                            }
                        }

                        &.closeVariantDropdown {
                            max-height: 0px;
                            overflow-y: hidden;
                            transition: all 0.4s ease-in-out;

                        }

                        &.openVariantDropdown {
                            border: 1px solid #FFF;
                            max-height: 176px;
                            padding: 12px 0;
                            transition: all 0.4s ease-in-out;
                            overflow-y: auto;

                        }
                    }
                }
            }

            .filterRelative {
                position: relative;
                // margin: 0 0 24px 0;

                .addProdutcFilterAlignment {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    gap: 106px;
                    margin: 0 16px;

                    @media only screen and (max-width:"1024px") {
                        display: none;
                    }

                    .addProductLeftSideAlignment {
                        display: flex;
                        align-items: flex-start;
                        gap: 8px;

                        .addProductFilterBox {
                            cursor: pointer;
                            padding: 0 16px 0 8px;
                            height: 32px;
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            border-radius: 8px;
                            border: 0.5px solid var(--grey4);
                            background: var(--light-grey);
                            transition: all 0.4s ease-in-out;

                            &:hover {
                                transition: all 0.4s ease-in-out;
                                border: 0.5px solid var(--grey4);
                                background: var(--primary-50);

                                svg {
                                    path {
                                        stroke: var(--primary-700);
                                    }
                                }

                                h6 {
                                    color: var(--primary-700);
                                }
                            }

                            h6 {
                                color: var(--secondary-700);
                                font-family: 'Poppins', sans-serif;
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 21px;
                            }
                        }

                        .allAddFilterDetailsAlignment {

                            .addProductAllFilterListAlignment {
                                display: flex;
                                align-items: center;
                                gap: 3px;
                                margin: 0 0 10px 0;
                                flex-wrap: wrap;

                                &:last-child {
                                    margin: 0;
                                }

                                .sizeBoxFilterAlignment {
                                    display: flex;
                                    align-items: center;
                                    gap: 5px;

                                    &.colorBoxPadding {
                                        gap: 8px;
                                    }

                                    .sizeDetailsBox {
                                        min-width: 94px;
                                        height: 32px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        padding: 0 6px 0 12px;
                                        border-radius: 16px;
                                        background: var(--light-grey);
                                        cursor: pointer;

                                        p {
                                            color: var(--secondary-900);
                                            font-family: 'Poppins', sans-serif;
                                            font-size: 16px;
                                            font-weight: 400;
                                            line-height: 24px;
                                        }
                                    }

                                    .colorBoxAlignment {
                                        min-width: 94px;
                                        height: 32px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        padding: 0 6px 0 12px;
                                        border-radius: 16px;
                                        background: var(--light-grey);
                                        cursor: pointer;

                                        .colorBoxDetails {
                                            display: flex;
                                            align-items: center;
                                            gap: 4px;

                                            .colorShowBox {
                                                width: 16px;
                                                min-width: 16px;
                                                max-width: 16px;
                                                height: 16px;
                                                border-radius: 4px;
                                                background: #F10000;

                                                &.blueColor {
                                                    background: #06F;
                                                }
                                            }

                                            p {
                                                color: var(--secondary-900);
                                                font-family: 'Poppins', sans-serif;
                                                font-size: 16px;
                                                font-weight: 400;
                                                line-height: 24px;
                                            }
                                        }

                                    }
                                }

                                .colorBoxMainAlignment {

                                    display: flex;
                                    align-items: center;
                                    gap: 8px;

                                }
                            }
                        }

                    }

                    .clearFilterAlignment {
                        margin: 0 16px 0 0;

                        a {
                            color: var(--primary-500);
                            font-family: 'Poppins', sans-serif;
                            font-size: 15px;
                            font-weight: 400;
                            line-height: 22px;
                            cursor: pointer;
                            white-space: nowrap;
                        }
                    }

                    .addProdutcMain {
                        padding: 0 8px 0 0;
                        position: absolute;
                        top: 40px;
                        left: 0;
                        width: 100%;
                        z-index: 99999999;
                        box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.12);
                        border-radius: 10px;
                        border: 1px solid #FFF;
                        background: #FFF;

                        .addProductFilterAllDetailsBoxAlignment {
                            height: auto;
                            max-height: 380px;
                            padding: 0 14px 32px 32px;
                            overflow-y: auto;

                            &::-webkit-scrollbar {
                                width: 2px !important;
                                display: block;
                            }

                            &::-webkit-scrollbar-track {
                                background: transparent !important;
                            }

                            &::-webkit-scrollbar-thumb {
                                display: block;
                                background: #D9D9D9 !important;
                                border-radius: 18px !important;
                            }

                            .addProdutcHeadingAlignment {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                position: sticky;
                                top: 0;
                                background: #FFF;
                                left: 0;
                                z-index: 99;
                                padding: 32px 0;

                                h6 {
                                    color: var(--secondary-900);
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 20px;
                                    font-weight: 500;
                                    line-height: 24px;
                                }

                                a {
                                    color: #E71616;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 15px;
                                    font-weight: 500;
                                    line-height: 22px;
                                    cursor: pointer;
                                }
                            }

                            .addProductBodyAlignment {


                                .addProdutcBodyChildDetails {
                                    margin: 0 0 32px 0;

                                    // &:last-child {
                                    //   margin: 0;
                                    // }

                                    label {
                                        color: var(--secondary-900);
                                        font-family: 'Poppins', sans-serif;
                                        font-size: 12px;
                                        font-weight: 500;
                                        line-height: 18px;
                                        margin: 0;
                                    }

                                    .addProdutcAllChildList {
                                        margin: 16px 0 0 0;
                                        display: flex;
                                        align-items: center;
                                        flex-wrap: wrap;
                                        gap: 8px;

                                        .addChildListBox {
                                            height: 31px;
                                            padding: 0 14px;
                                            border-radius: 16px;
                                            background: var(--light-grey);
                                            cursor: pointer;
                                            display: flex;
                                            align-items: center;
                                            transition: all 0.4s ease-in-out;
                                            gap: 4px;

                                            .colorBoxAlignment {
                                                width: 16px;
                                                height: 16px;
                                                min-width: 16px;
                                                max-width: 16px;
                                                border-radius: 4px;
                                                background: #F10000;

                                                &.blueBg {
                                                    background: #128AF8;
                                                }

                                                &.yellowBg {
                                                    background: #F8C200;
                                                }

                                                &.purpleBg {
                                                    background: #6A00F1;
                                                }

                                                &.darkBlue {
                                                    background: #00308D;
                                                }

                                                &.greenBg {
                                                    background: #5BA300;
                                                }
                                            }

                                            p {
                                                color: var(--secondary-500);
                                                font-family: 'Poppins', sans-serif;
                                                font-size: 14px;
                                                font-weight: 400;
                                                line-height: 21px;
                                            }

                                            &:hover {
                                                border-radius: 16px;
                                                background: var(--primary-50);
                                                transition: all 0.4s ease-in-out;

                                                p {
                                                    color: var(--secondary-900);
                                                    font-weight: 500;
                                                }
                                            }

                                            &.activeDetails {
                                                border-radius: 16px;
                                                background: var(--primary-50);
                                                transition: all 0.4s ease-in-out;

                                                p {
                                                    color: var(--secondary-900);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }


            .variantBodyDetailsAlignment {
                padding: 24px 0 0;

                @media only screen and (max-width:"576px") {
                    padding: 16px 0 0 0;
                }

                .vriantBodyGridAlignment {
                    display: grid;
                    grid-template-columns: 298px 1fr;
                    gap: 10px;

                    @media only screen and (max-width:"699px") {
                        grid-template-columns: 250px 1fr;
                    }

                    @media only screen and (max-width:"576px") {
                        grid-template-columns: 1fr;
                    }

                    .variantLeftSideDetailsAlignment {
                        // height: calc(100vh - 348px);
                        height: 100%;
                        overflow-y: auto;

                        @media only screen and (max-width:"1024px") {
                            height: calc(100vh - 134px);
                        }

                        @media only screen and (max-width:"767px") {
                            display: none;
                        }

                        &::-webkit-scrollbar {
                            width: 2px !important;
                            display: block !important;
                        }

                        &::-webkit-scrollbar-track {
                            background: transparent !important;
                            display: block !important;
                        }

                        &::-webkit-scrollbar-thumb {
                            background: var(--secondary-300) !important;
                            border-radius: 1px !important;
                            display: block !important;
                        }

                        .variantAllDetailsAlignment {
                            padding: 0 4px 0 0;

                            .variantDetailsBoxAlignment {
                                padding: 8px;
                                width: 100%;
                                transition: all 0.4s ease-in-out;
                                cursor: pointer;

                                &:hover {
                                    border-radius: 13px;
                                    background: var(--secondary-50);
                                    transition: all 0.4s ease-in-out;
                                }

                                &.variantBoxActive {
                                    border-radius: 13px;
                                    background: var(--secondary-50);
                                    transition: all 0.4s ease-in-out;
                                }

                                .variantBoxDetailsGrid {
                                    display: grid;
                                    grid-template-columns: 56px 1fr;
                                    gap: 16px;
                                    align-items: center;

                                    .variantBoxDetailsGridItem {
                                        .variantImg {
                                            width: 100%;
                                            height: 56px;

                                            img {
                                                width: 100%;
                                                height: 100%;
                                                border-radius: 10px;
                                                object-fit: cover;
                                                border: 1px solid #FFF;
                                            }
                                        }

                                        .varintRightSideAlignment {
                                            h6 {
                                                color: var(--secondary-900);
                                                font-family: 'Poppins', sans-serif;
                                                font-size: 14px;
                                                font-weight: 500;
                                                line-height: 21px;
                                                cursor: pointer;
                                            }

                                            p {
                                                color: #6DAF00;
                                                font-family: 'Poppins', sans-serif;
                                                font-size: 12px;
                                                font-weight: 400;
                                                line-height: 14px;
                                                padding: 2px 0 0 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                    }

                    .variantRightSideDetailsAlignment {
                        border-radius: 16px;
                        border: 1px solid var(--secondary-50);
                        background: #FFF;
                        width: 100%;
                        height: 100%;
                        padding: 16px;

                        @media only screen and (max-width:"767px") {
                            border: none;
                            padding: 0;

                        }

                        .variantRightHeadingDetails {
                            display: flex;
                            align-items: flex-start;
                            justify-content: space-between;
                            flex-wrap: wrap;
                            gap: 16px;

                            @media only screen and (max-width:"767px") {
                                padding: 8px 32px;

                            }

                            .rightTopLeftDetailsAlignment {
                                display: flex;
                                align-items: center;
                                gap: 16px;

                                .variantMainImgAlignment {
                                    width: 56px;
                                    height: 56px;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        display: block;
                                        object-fit: cover;
                                        border-radius: 10px;

                                    }
                                }

                                .variantMainHeadingAlignment {
                                    h4 {
                                        color: var(--secondary-900);
                                        font-family: 'Poppins', sans-serif;
                                        font-size: 18px;
                                        font-weight: 500;
                                        line-height: 24px;
                                        cursor: pointer;

                                        @media only screen and (max-width:"767px") {
                                            font-size: 16px;
                                            line-height: 21px;
                                        }

                                    }

                                    p {
                                        padding: 2px 0 0 0;
                                        color: #6DAF00;
                                        font-family: 'Poppins', sans-serif;
                                        font-size: 12px;
                                        font-weight: 400;
                                        line-height: 14px;
                                    }
                                }
                            }

                            .refreshAlignment {
                                display: flex;
                                align-items: center;
                                gap: 6px;
                                cursor: pointer;

                                @media only screen and (max-width:"767px") {
                                    display: none;
                                }

                                .resetIcon {
                                    width: 20px;
                                    height: 20px;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        display: block;
                                    }
                                }

                                p {
                                    color: var(--secondary-300);
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 12px;
                                    font-weight: 400;
                                    line-height: 14px;
                                }
                            }
                        }

                        .variantInventoryPricingSection {
                            padding: 16px 0 0 0;

                            @media only screen and (max-width:"767px") {
                                padding: 8px 16px;

                            }

                            .variantInventoryPricingbox {
                                border-radius: 10px;
                                border: 1px solid var(--secondary-50);
                                background: #FFF;
                                padding: 12px 16px 16px 16px;

                                @media only screen and (max-width:"767px") {
                                    border-radius: 16px;
                                }

                                .inventoryPriceHeading {
                                    h6 {
                                        color: var(--secondary-900);
                                        font-family: 'Poppins', sans-serif;
                                        font-size: 18px;
                                        font-weight: 600;
                                        line-height: 24px;
                                    }
                                }

                                .inventoryPricingAllDetails {
                                    padding: 16px 0 0 0;

                                    .inventoryPricingAllGrid {
                                        display: grid;
                                        grid-template-columns: 155px 155px 164px;
                                        gap: 16px 8px;

                                        @media only screen and (max-width:"899px") {
                                            grid-template-columns: repeat(2, 1fr);
                                        }

                                        @media only screen and (max-width:"76px") {
                                            grid-template-columns: repeat(2, 1fr);
                                            gap: 8px;
                                        }

                                        &:nth-child(2) {
                                            padding: 16px 0 0 0;
                                        }

                                        .inventoryPricingAllGridItem {
                                            .inventoryInputAlignment {
                                                label {
                                                    color: var(--grey5);
                                                    font-family: 'Poppins', sans-serif;
                                                    font-size: 13px;
                                                    font-weight: 400;
                                                    line-height: 20px;
                                                }

                                                .inventoryInput {
                                                    width: 100%;
                                                    height: 40px;
                                                    border-radius: 10px;
                                                    border: 1px solid var(--secondary-50);
                                                    background: var(--light-grey);
                                                    padding: 0 16px;
                                                    display: flex;
                                                    align-items: center;
                                                    gap: 4px;

                                                    .dollarSign {
                                                        width: 12px;
                                                        height: 14px;
                                                        min-width: 12px;
                                                        max-width: 12px;

                                                        img {
                                                            width: 100%;
                                                            height: 100%;
                                                            display: block;
                                                        }
                                                    }

                                                    input {
                                                        width: 100%;
                                                        height: 100%;
                                                        border: none;
                                                        background: transparent;
                                                        color: var(--secondary-900);
                                                        text-align: right;
                                                        font-family: 'Poppins', sans-serif;
                                                        font-size: 14px;
                                                        font-weight: 400;
                                                        line-height: 21px;

                                                        &.quantityInput {
                                                            text-align: center;
                                                        }

                                                        &.inputTextLeftAlignment {
                                                            text-align: left;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .priceCheckAlignment {
                                    display: flex;
                                    align-items: center;
                                    gap: 8px;
                                    padding: 22px 0 0 0;
                                    cursor: pointer;

                                    input[type="checkbox"] {
                                        width: 18px;
                                        height: 18px;
                                        padding: 0;
                                        margin: 0;
                                        cursor: pointer;
                                    }

                                    p {
                                        color: var(--secondary-900);
                                        font-family: 'Poppins', sans-serif;
                                        font-size: 12px;
                                        font-weight: 400;
                                        line-height: 14px;
                                    }
                                }

                            }
                        }

                        .shippingDetailsSection {
                            padding: 16px 0 0 0;

                            @media only screen and (max-width:"767px") {
                                padding: 0 16px;

                            }

                            .shippingBoxSection {
                                padding: 12px 16px 16px 16px;
                                border-radius: 10px;
                                border: 1px solid var(--secondary-50);
                                background: #FFF;

                                .shippingHeadingFlexAlignment {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;

                                    h6 {
                                        color: var(--secondary-900);
                                        font-family: 'Poppins', sans-serif;
                                        font-size: 18px;
                                        font-weight: 600;
                                        line-height: 24px;
                                    }

                                    .shippingRightSideAlignment {
                                        display: flex;
                                        align-items: center;
                                        gap: 15px;

                                        p {
                                            color: #495057;
                                            font-family: 'Poppins', sans-serif;
                                            font-size: 13px;
                                            font-weight: 600;
                                            line-height: 20px;
                                        }

                                        .shippingOtherDetails {
                                            display: flex;
                                            align-items: center;
                                            gap: 6px;

                                            .shippingOtherDetailsSecond {
                                                display: flex;
                                                align-items: center;
                                                gap: 4px;
                                                cursor: pointer;
                                                position: relative;
                                                width: 45px;
                                                justify-content: center;

                                                p {
                                                    color: var(--secondary-500);
                                                    font-family: 'Poppins', sans-serif;
                                                    font-size: 13px;
                                                    font-weight: 400;
                                                    line-height: 20px;
                                                }



                                                svg {
                                                    transition: 0.4s ease-in-out;

                                                    &.activeArrow {
                                                        transition: 0.4s ease-in-out;

                                                        transform: rotate(-180deg);
                                                    }
                                                }

                                                .shippingUnitDetailsDropdownBoxAlignment {
                                                    background: #ffffff;
                                                    border: 1px solid #e3e4e8;
                                                    border-radius: 4px;
                                                    padding: 8px;
                                                    position: absolute;
                                                    z-index: 99999999;
                                                    top: 32px;
                                                    right: 0;
                                                    height: auto;
                                                    max-height: 166px;
                                                    overflow-y: auto;

                                                    .shippingUnitDetailsDropdownOption {
                                                        padding: 3px 10px;
                                                        margin: 0 0 8px 0;

                                                        &:last-child {
                                                            margin: 0;
                                                        }

                                                        p {
                                                            font-family: 'Poppins', sans-serif;
                                                            font-weight: 400;
                                                            font-size: 14px;
                                                            line-height: 21px;
                                                            color: var(--secondary-900);
                                                            cursor: pointer;
                                                            white-space: nowrap;
                                                        }

                                                        &:hover {
                                                            background: #eef1fc;
                                                            border-radius: 2px;
                                                        }

                                                        &.selected {
                                                            background: #eef1fc;
                                                            border-radius: 2px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .shippingBodyDetailsAlignment {
                                    padding: 16px 0 0 0;

                                    .shippingBodyDetailsGridAlignment {
                                        display: grid;
                                        grid-template-columns: 161px 1fr;
                                        gap: 58px;

                                        @media only screen and (max-width:"899px") {
                                            grid-template-columns: repeat(1, 1fr);
                                            gap: 16px;
                                        }

                                        .shippingInputAlignment {
                                            &.itemWidthAlignment {
                                                width: 147px;
                                            }

                                            label {
                                                color: var(--grey5);
                                                font-family: 'Poppins', sans-serif;
                                                font-size: 13px;
                                                font-weight: 400;
                                                line-height: 20px;
                                                display: block;
                                                padding: 0 0 4px 0;
                                            }

                                            .shippingInput {
                                                display: flex;
                                                align-items: center;
                                                gap: 4px;
                                                border-radius: 10px;
                                                border: 1px solid var(--secondary-50);
                                                background: var(--light-grey);
                                                height: 40px;
                                                padding: 0 10px;

                                                input {
                                                    background: transparent;
                                                    border: none;
                                                    width: 100%;
                                                    height: 100%;
                                                }

                                                span {
                                                    color: var(--grey3);
                                                    font-family: 'Poppins', sans-serif;
                                                    font-size: 13px;
                                                    font-weight: 400;
                                                    line-height: 20px;
                                                }

                                            }
                                        }

                                        .shippingThirdGridAlignment {
                                            display: grid;
                                            grid-template-columns: repeat(3, 1fr);
                                            gap: 8px;


                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }

            .mobileViewVarintHeadingAlignment {
                width: 100%;
                position: sticky;
                top: 0;
                left: 0;
                z-index: 999;
                background: #ffffff;
                display: none;

                @media only screen and (max-width:"767px") {
                    display: block;

                }

                .mobileViewVarintHeading {
                    padding: 8px 16px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .mobileVIewBackICon {
                        width: 37px;
                        height: 32px;
                    }

                    h4 {
                        color: var(--secondary-900);
                        text-align: center;
                        font-family: 'Poppins', sans-serif;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 24px;
                    }


                    .mobileViewResetIcon {
                        width: 20px;
                        height: 20px;
                        min-width: 20px;
                        max-width: 20px;

                        img {
                            width: 100%;
                            height: 100%;
                            display: block;
                        }
                    }
                }
            }

            .mobileViewBottomButton {
                position: sticky;
                bottom: 0;
                left: 0;
                padding: 16px 16px 24px 16px;
                background: #FFF;
                display: none;

                @media only screen and (max-width:"767px") {
                    display: block;

                }

                button {
                    width: 100%;
                    height: 44px;
                    border-radius: 10px;
                    background: var(--primary-500);
                    color: #FFF;
                    text-align: center;
                    font-family: 'Poppins', sans-serif;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 28px;
                    cursor: pointer;
                }
            }
        }
    }
}