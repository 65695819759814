.mobileViewAddVariantModalSection {
    padding: 16px;

    .mobileViewAddVariantModalHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 8px 0 0;

        h6 {
            color: var(--secondary-900);
            font-family: 'Poppins', sans-serif;
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
        }

        a {
            color: var(--primary-500);
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }
    }

    .mobileViewAddVariantModalBodyDetails {
        .mobileViewAddVariantDetailsBox {
            height: calc(100vh - 220px);
            overflow-y: auto;

            .mobileViewAddVariantModalNoVariantSection {
                height: 370px;
                display: flex;
                align-items: center;
                justify-content: center;

                p {
                    color: #808191;
                    font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                    text-align: center;
                }
            }

            .mobileViewAddVariantOptionAlignment {
                .mobileViewAddVariantOptionGrid {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    gap: 8px;

                    .mobileViewAddVariantOptionGridItem {
                        .mobileViewAddVariantOptionBox {
                            border-radius: 7.586px;
                            background: #F8F8F8;
                            height: 109px;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            gap: 16px;

                            .mobileViewIconsAlignment {
                                width: 32px;
                                height: 32px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    display: block;
                                }
                            }

                            p {
                                color: var(--secondary-900);
                                font-family: 'Poppins', sans-serif;
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 18px;
                            }
                        }
                    }

                }
            }

            .recentlyCreateAlignment {
                padding: 16px 0 0 0;

                h6 {
                    color: var(--secondary-900);
                    font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 21px;
                }

                .recentlyCreateResizeBox {
                    margin: 8px 0 0 0;
                    border-radius: 7.586px;
                    background: #F8F8F8;
                    padding: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .createdNameAlignment {
                        h6 {
                            color: var(--secondary-900);
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 24px;
                        }

                        p {
                            padding: 8px 0 0 0;
                            color: #495057;
                            text-align: center;
                            font-family: 'Poppins', sans-serif;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 21px;
                        }
                    }

                    .addMoreButtonAlignment {

                        a {
                            padding: 6px 16px;
                            color: var(--primary-500);
                            font-family: 'Poppins', sans-serif;
                            font-size: 15px;
                            font-weight: 500;
                            line-height: 20px;
                        }
                    }
                }
            }

            .mobileViewAddVariantListAlignment {
                padding: 32px 8px 0 0;
                height: 370px;
                overflow-y: auto;

                .mobileViewAddVariantListName {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 53px;
                    margin: 0 0 16px 0;

                    &:last-child {
                        margin: 0;
                    }

                    .mobileViewNameSize {
                        display: grid;
                        grid-template-columns: 48px 111px;
                        gap: 22px;

                        h6 {
                            color: var(--secondary-900);
                            font-family: 'Poppins', sans-serif;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 21px;
                        }

                        p {
                            color: #808191;
                            font-family: 'Poppins', sans-serif;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 18px;
                        }
                    }

                    .mobileViewEditRemoveAlignment {
                        display: flex;
                        align-items: center;
                        gap: 16px;

                        a {
                            color: var(--primary-500);
                            font-family: 'Poppins', sans-serif;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 21px;
                        }
                    }
                }
            }
        }

        .saveChangesButtonAlignment {
            padding: 16px 0 0 0;

            button {
                border-radius: 10px;
                background: var(--primary-500);
                color: #FFF;
                text-align: center;
                font-family: 'Poppins', sans-serif;
                font-size: 18px;
                font-weight: 500;
                line-height: 28px;
                width: 100%;
                height: 44px;
            }
        }
    }
}