.message-media-alignment-Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(30, 36, 50, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  z-index: 9999999 !important;
  display: none;

  @media only screen and (max-width: '1099px') {
    display: block;
  }
}

.message-media-alignment-Overlay.open {
  opacity: 1;
  visibility: visible;

  @media only screen and (max-width: '1099px') {
    display: block;
  }
}

.message-media-modal-alignment {
  position: fixed;
  right: 0;
  width: 100%;
  height: calc(100vh - 62px);
  background: white;
  translate: 0 100%;
  transition: 0.3s;
  overflow-y: auto;
  bottom: 0;
  border-radius: 16px 16px 0px 0px;
}
.message-media-modal-details-alignment {
  padding: 0 16px 16px 16px;

  .message-media-heading-alignment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 28px;
    background: #fff;
    z-index: 99999;
    padding: 16px 0;

    h6 {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: var(--secondary-900);
    }
  }

  .message-media-details-media-post-details {
    height: calc(100vh - 218px);
    overflow-y: auto;
    .message-media-details-media-grid-alignment {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 3px;
      padding: 0 0 16px 0;

      .message-media-details-media-gridItem {
        .message-media-img-alignment {
          width: 100%;
          height: 100%;
          transition: 0.4s ease-in-out;

          &.remove-img {
            opacity: 0;
            transition: 0.4s ease-in-out;
          }

          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
      }
    }
  }
}

.message-media-modal-alignment.open {
  translate: 0;
  z-index: 9999999;
  transform: translate(0, 6%);

  @media only screen and (max-width: '1099px') {
    display: block;
  }
}

.media-all-details-modal-alignment {
  .media-details-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(30, 36, 50, 0.8);
    z-index: 99999999999999;
    padding: 16px 16px 32px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .media-details-heading-alignment {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .media-details-profile-alignment {
        display: flex;
        align-items: center;
        gap: 8px;

        .media-profile-img-alignment {
          width: 32px;
          height: 32px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 2px solid #ffffff;
          }
        }

        h6 {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
        }
      }

      .media-close-button-alignment {
        padding: 10px 16px;
        background: #1e2432;
        border-radius: 10px;
      }
    }

    .media-main-img-alignment {
      width: 100%;
      height: 255px;

      img {
        width: 100%;
        height: 100%;
        border: 4px solid #ffffff;
        border-radius: 16px;
        object-fit: cover;
        object-position: center;
      }
    }

    .media-other-option-alignment {
      .media-option-flex {
        display: flex;
        align-items: center;
        gap: 16px;
        margin: 0 0 32px 0;

        &:last-child {
          margin: 0;
        }

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
        }
      }
    }
  }
}
