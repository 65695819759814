.book-appliance-shop-by-catefory-section {
  .book-appliance-shop-by-category-banner-alignment {
    background: #edeae6;
    padding: 32px 0 25px 0;
    // position: sticky;
    // top: 0;
    // z-index: 99;

    .container {
      // max-width: 1440px;
      // margin: auto;
      // padding: 0 20px;
      max-width: 1300px;
      margin: 0 auto;
      padding: 0 15px;

      .book-appliance-header-flex-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // padding: 0 84px;

        .back-arrow-alignment {
          img {
            cursor: pointer;
          }
        }

        .book-appliance-shop-category-relative-alignemt {
          // width: 95%;
          width: 90%;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            background: linear-gradient(270deg, #edeae6 31.78%, rgba(237, 234, 230, 0) 100%);
            top: 0;
            right: 0;
            width: 258px;
            height: 100%;
            z-index: 9;
          }

          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 176px;
            height: 100%;
            background: linear-gradient(270deg, #edeae6 0%, rgba(237, 234, 230, 0) 100%);
            z-index: 9;
            transform: rotate(-180deg);
          }

          .book-appliane-shop-category-banner-details {
            padding: 0 7px;

            &.active-class {
              .book-appliane-shop-category-banner-box-alignment {
                border: 2px solid #a855f7;
              }
            }

            &:hover {
              .book-appliane-shop-category-banner-box-alignment {
                border: 2px solid #a855f7;
              }

              .shop-category-banner-name-alignment {
                p {
                  font-weight: 600;
                }
              }
            }

            &.book-appliance-active-card {
              .book-appliane-shop-category-banner-box-alignment {
                border: 2px solid #a855f7;
              }

              .shop-category-banner-name-alignment {
                p {
                  font-weight: 600;
                }
              }
            }

            .book-appliane-shop-category-banner-box-alignment {
              width: 100%;
              height: 176px;
              border: 2px solid transparent;
              border-radius: 16px;
              padding: 8px;
              cursor: pointer;

              .shop-category-banner-img-alignment {
                width: 100%;
                height: 100%;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 10px;
                  object-fit: cover;
                }
              }
            }

            .shop-category-banner-name-alignment {
              padding: 12px 0 0 0;

              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: var(--secondary-900);
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  .book-appliance-shop-by-category-child-banner-alignment {
    background: #f8f8f8;
    position: relative;

    .book-appliance-shop-by-child-banner-img {
      width: 100%;
      height: 178px;
      position: absolute;
      top: 0;
      right: 0;

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-position: center;
        object-fit: cover;
      }
    }

    .book-appliance-shop-by-child-banner-section {
      height: 178px;
      display: flex;
      align-items: center;
      gap: 160.5px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        background: linear-gradient(90deg, #f8f8f8 0%, rgba(248, 248, 248, 0) 100%);
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
      }
    }

    .container {
      // max-width: 1440px;
      // margin: auto;
      // padding: 0 82px;]

      max-width: 1300px;
      margin: 0 auto;
      padding: 0 15px;

      @media only screen and (max-width: '767px') {
        max-width: 100%;
        padding: 0;
      }

      .book-appliance-shop-by-child-heading-alignment {
        // padding: 0 84px;
        position: relative;

        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 300;
          font-size: 40px;
          line-height: 60px;
          color: #000000;
          padding: 0 0 4px 0;
        }

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 300;
          font-size: 16px;
          line-height: 27px;
          color: var(--secondary-900);
          width: 700px;
        }
      }
    }
  }

  .book-appliance-shop-by-nav-alignment {
    padding: 29px 84px;
    background: #ffffff;
    position: sticky;
    top: 34px;
    z-index: 999;

    .container {
      max-width: 1440px;
      margin: auto;
      padding: 0 20px;

      .book-appliance-shop-by-nav-details {
        width: 100%;
        position: relative;

        &::before {
          position: absolute;
          content: '';
          background: linear-gradient(270deg, #ffffff 60.71%, rgba(255, 255, 255, 0) 100%);
          width: 272px;
          height: 100%;
          top: 0;
          right: 0;
          z-index: 99;
        }

        // &::after {
        //     position: absolute;
        //     content: "";
        //     // background: linear-gradient(270deg, #FFFFFF 60.71%, rgba(255, 255, 255, 0) 100%);
        //     // transform: rotate(-180deg);
        //     width: 272px;
        //     height: 100%;
        //     top: 0;
        //     left: 0;
        //     z-index: 99;
        // }

        .nav-menu-all-menu-alignment {
          // display: flex;
          // align-items: center;
          gap: 16px;
          width: 100%;
          padding: 0 16px;

          // overflow: auto;
          .menu-relative-alignment {
            position: relative;
            cursor: pointer;

            .nav-menu-alignment {
              display: flex;
              align-items: center;
              gap: 8px;
              padding: 9px 16px;

              &.nav-active {
                p {
                  font-weight: 600;
                  color: var(--primary-500);
                }
              }

              &:hover {
                background: #eef1fc;
                border-radius: 8px;
              }

              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 20px;
                line-height: 30px;
                color: #495057;
                white-space: nowrap;
              }
            }
          }

          .slick-initialized {
            .slick-slide {
              // width: min-content !important;
            }
          }

          .slick-arrow {
            background: #f8f8f8;

            &.slick-next {
              right: 0;
              z-index: 999;
            }

            &.slick-prev {
              left: -50px;
              z-index: 999;
            }
          }
        }
      }
    }
  }

  .dropdown-box-alignment {
    border: 1px solid #e3e4e8;
    background-color: #ffffff;
    border-radius: 10px;
    // width: 172px;
    // max-width: 172px;
    padding: 26px 16px;
    position: fixed;
    // top: 63vh;
    // left: 335px;
    z-index: 9999;
    width: fit-content;

    .dropdown-list-name {
      margin: 0 0 7px 0;

      &:hover {
        background: #eef1fc;
        border-radius: 4px;

        span {
          font-weight: 600;
          color: var(--primary-500);
        }
      }

      span {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: var(--secondary-900);
        cursor: pointer;
        display: block;
        padding: 3px 10px;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  .book-appliance-all-product-details-alignment {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 15px;

    @media only screen and (max-width: '767px') {
      max-width: 100%;
      padding: 0;
    }
  }

  .book-appliance-product-main-details-alignment {
    background: #ffffff;
  }

  .slick-arrow {
    background-color: rgba(255, 255, 255, 0.6);

    &.slick-next {
      right: 0;
      z-index: 99;
    }

    &.slick-prev {
      left: 0;
      z-index: 99;
    }
  }
}

.special-offer-modal-category {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 70%;
  height: calc(100vh - 64px);
  // height: calc(100vh - 104px);
  // padding: 0 16px;
  // background: var(--secondary-50);
  // border-radius: 16px 0px 0px 0px;
  z-index: 9999;
  translate: 100% 0;
  transition: 0.3s;
  display: flex;
  align-items: flex-start;
  gap: 16px;

  .sidebar-modal-alignment-category {
    background: var(--secondary-50);
    border-radius: 16px 0px 0px 0px;
    padding: 16px;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    @media only screen and (max-width: '1099px') {
      height: 100vh;
    }

    @media only screen and (max-width: '767px') {
      padding: 0;
      border-radius: 0;
    }
  }
}

.special-offer-modal-category.open {
  translate: 0;
}

.overlay-category {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(30, 36, 50, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.overlay-category.open {
  opacity: 1;
  visibility: visible;
}

.book-application-shop-by-category-small-banner-alignment {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(16px);
  width: 100%;

  position: fixed;
  top: 66px;
  z-index: 9999;

  @media (max-width:768px) {
    display: none;
  }
  
  .slick-arrow {
    width: 20px !important;
    height: 20px !important;
    display: flex;
    justify-content: center;
    align-items: center;transform: translateY(-50%);
  
    @media (max-width: 576px) {
      display: none;
    }
  }
  
  .slick-prev {
    left: -10px !important;
    top: 50% !important;
  
    @media (max-width: 1024px) {
      left: 0 !important; 
    }
  
    @media (max-width: 576px) {
      display: none;
    }
  }
  
  .slick-next {
    right: -10px !important;
    top: 50% !important;
  
    @media (max-width: 576px) {
      display: none;
    }
  }
  
  :global(.slick-prev) {
    left: 21px;
    z-index: 99;
  
    @media (max-width: 576px) {
      left: 0 !important; 
      display: none !important; 
    }
  }
  
  :global(.slick-next) {
    right: -50px;
    z-index: 999;
  
    @media (max-width: 576px) {
      display: none !important; 
    }
  }
  
  :global(.slick-arrow:hover) {
    background: #fff !important;
  }
  


  &.hide-nav-alignment {
    // display: none;
    transform: translateY(-100%);
    transition: 0.4s ease-in-out;
  }

  &.open-nav-alignment {
    // display: block;
    transform: translateY(0);
    transition: 0.4s ease-in-out;
  }

  .container {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 15px;

    .book-application-shop-by-category-small-banner-flex {
      position: relative;

      .book-application-shop-by-category-link-alignment {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 34px;
      }

      .book-application-shop-by-category-option {
        height: 34px;
        transition: 0.4s ease-in-out;
        
        p {
          color: var(--secondary-900);
          text-align: center;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          cursor: pointer;
          position: relative;

          &.menu-active {
            transition: 0.4s ease-in-out;
            font-weight: 600;

            &::before {
              content: '';
              position: absolute;
              left: 0;
              bottom: -8px;
              width: 100%;
              height: 2px;
              background: var(--primary-500);
            }
          }

          &:hover {
            transition: 0.4s ease-in-out;

            font-weight: 600;

            &::before {
              content: '';
              position: absolute;
              left: 0;
              bottom: -8px;
              width: 100%;
              height: 2px;
              background: var(--primary-500);
            }
          }
        }
      }

      .small-back-arrow-alignment {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        cursor: pointer;

        @media only screen and (max-width: '1024px') {
          display: none;
        }
      }

    }
  }
}