.addProductModalFreeShippingSection {
    margin: 0 0 16px 0;

    .addProductModalFreeShippingBox {
        border-radius: 10px;
        border: 1px solid var(--secondary-50);
        background: #FFF;
        padding: 10px 16px 16px 16px;

        .addProductModalFreeShippingHeadingAlignment {
            h4 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 18px;
                line-height: 27px;
                color: var(--secondary-900);
            }

            p {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: #808191;

            }
        }


        .freeShippingoptionBgAlignment {
            background: rgba(85, 110, 230, 0.13);
            border-radius: 10px;
            margin: 16px 0 0 0;
            padding: 8px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .freeShippingoptionBoxAlignment {
                background: var(--primary-500);
                border-radius: 5px;
                padding: 5px;
                display: block;

                &.deactive {
                    background: #acb1c0;
                }
            }
        }
    }
}