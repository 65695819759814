.jewelryStoreModalSection {
  height: 100%;

  .jewelryStoreModalAlignment {
    border-radius: 10px;
    background: #fff;
    padding: 16px;
    height: 100%;

    .jewelryStoreModalBannerAlignment {
      display: flex;
      align-items: flex-start;
      gap: 110px;

      h4 {
        // padding: 16px 0 16px 16px;
        margin: 0;
        color: var(--bookmark-heading-color);
        font-family: 'Poppins', sans-serif;
        font-size: 24px;
        font-weight: 600;
        line-height: 30px;
        white-space: nowrap;
      }

      .jewelryStoreBg {
        width: 100%;
        height: 94px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
          border-radius: 8px;
        }

        .jewelryBannerDetailsAlignment {
          position: absolute;
          top: 18px;
          left: 30px;
          width: 100%;
          height: 100%;

          .logoAlignment {
            width: 86px;
            height: auto;

            img {
              width: 100%;
              height: 100%;
              display: block;
            }
          }

          p {
            color: #fff;
            font-family: 'Inter';
            font-size: 13px;
            font-weight: 400;
            line-height: 18px;
            padding: 12px 0 0 0;
          }
        }

        .jewelryArrowAlignment {
          position: absolute;
          top: 24px;
          right: 24px;

          svg {
            cursor: pointer;
          }
        }
      }
    }

    .mobileHeadingAlignment {
      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: var(--secondary-900);
      }
    }

    .bookApplianceBackDetailsAlignment {
      background: #ffffff;
      border-radius: 10px;
      padding: 4px 9px 4px 10px;
      display: flex;
      align-items: center;
      gap: 8px;


      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #808191;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 58px;
      }
    }

    .jewelryStoreModalAllDetailsAlignment {
      padding: 32px 0;

      .jewelryStoreModalAllDetailsGrid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;

        @media (max-width:1024px) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width:576px) {
          grid-template-columns: repeat(1, 1fr);
        }

        .jewelryStoreModalAllDetailsGridItem {
          .jewelryStoresDetailsBoxAlignment {
            border-radius: 8px;
            background: #f8f8f8;
            padding: 16px;
            transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

            &:hover {
              transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
              box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
              transform: translateY(-4px);
              border-radius: 8px;
            }

            .jewelryStoreProductDetailsAlignment {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .jewelryStoreProductAllDetails {
                display: flex;
                align-items: center;
                gap: 16px;

                @media only screen and (max-width: '767px') {
                  gap: 8px;
                }

                .jewelryStoreProductAllImg {
                  width: 102px;
                  height: 102px;

                  @media only screen and (max-width: '767px') {
                    width: 84px;
                    height: 84px;
                  }

                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 8px;
                  }
                }

                .jewelryStoreProductOtherDetails {
                  h5 {
                    color: var(--secondary-900);
                    font-family: 'Poppins', sans-serif;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 30px;

                    @media only screen and (max-width: '767px') {
                      font-size: 16px;
                      line-height: 20px;
                    }
                  }

                  p {
                    padding: 2px 0 0 0;
                    color: var(--secondary-500);
                    font-family: 'Poppins', sans-serif;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 20px;

                    @media only screen and (max-width: '767px') {
                      font-size: 12px;
                      line-height: 20px;
                    }
                  }

                  .followStoreButton {
                    padding: 10px 0 0 0;

                    @media only screen and (max-width: '767px') {
                      padding: 6px 0 0 0;
                    }

                    button {
                      padding: 6px 26px;
                      border-radius: 8px;
                      background: var(--primary-50);
                      color: var(--primary-500);
                      text-align: center;
                      font-family: 'Poppins', sans-serif;
                      font-size: 14px;
                      font-weight: 600;
                      line-height: 21px;
                      cursor: pointer;

                      @media only screen and (max-width: '767px') {
                        padding: 6px 16px;
                      }
                    }
                  }
                }
              }

              .jewelryLikeShareButton {
                display: flex;
                align-items: center;
                flex-direction: column;
                gap: 16px;

                .jewelryLikeShareIconTotal {
                  cursor: pointer;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;

                  p {
                    color: var(--secondary-500);
                    font-family: 'Poppins', sans-serif;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 20px;
                    padding: 4px 0 0 0;

                    @media only screen and (max-width: '767px') {
                      display: none;
                    }
                  }
                }
              }
            }

            .jewelryChildImagesAlignment {
              padding: 16px 0 0 0;

              .jewelryChildGrid {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                gap: 8px;

                @media only screen and (max-width: '767px') {
                  gap: 4px;
                }

                .jewelryChildImg {
                  width: 100%;
                  height: 100%;
                  position: relative;

                  @media only screen and (max-width: '767px') {
                    height: 60px;
                  }

                  img {
                    width: 100%;
                    height: 100%;
                    border-radius: 4px;
                    object-fit: cover;
                  }

                  &.lastImgAlignment {
                    &::before {
                      content: '';
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 100%;
                      border-radius: 4px;
                      background: linear-gradient(0deg, rgba(30, 36, 50, 0.6) 0%, rgba(30, 36, 50, 0.6) 100%);
                    }

                    .jewelryMoreImg {
                      position: absolute;
                      top: 0;
                      right: 0;
                      z-index: 9;
                      left: 0;
                      bottom: 0;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;

                      p {
                        color: #fff;
                        font-family: 'Poppins', sans-serif;
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 20px;
                        text-align: center;

                        span {
                          font-size: 16px;
                          line-height: 24px;
                          display: block;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}