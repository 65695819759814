.art-craft-diy-room-store-details-alignment {
    @media only screen and (max-width:"768px") {
        background: #fff;
    }

    .mobile-view-art-craft-back-alignment {
        display: none;

        @media only screen and (max-width:"768px") {
            display: flex;
            align-items: center;
            gap: 18px;
            padding: 8px 16px;
            background: rgba(248, 248, 248, 0.92);
            backdrop-filter: blur(13.5914px);
            justify-content: flex-start;
            position: sticky;
            top: 0;
            z-index: 999;

            .art-craft-back_details-alignment {
                background: #ffffff;
                border-radius: 10px;
                padding: 4px 9px 4px 10px;
                display: flex;
                align-items: center;
                gap: 8px;

                p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #808191;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 58px;
                }
            }

            .mobile-heading-alignment {
                h4 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 27px;
                    text-align: center;
                    color: var(--secondary-900);
                }
            }
        }
    }

    .art-craft-diy-room-store-banner-alignment {
        width: 100%;
        height: 178px;
        position: sticky;
        top: 0;
        z-index: 9999;

        @media only screen and (max-width:"768px") {
            height: 100px;
            top: 47px;
        }

        img {
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 8px;
            object-fit: cover;

            @media only screen and (max-width:"768px") {
                display: none;
            }

            &.mobile-view-banner {
                display: none;

                @media only screen and (max-width:"768px") {
                    display: block;
                }
            }
        }

        .art-craft-diy-room-store-banner-positiona-alignment {

            // position: absolute;
            height: 100%;
            // top: 0;
            // left: 82px;


            .container {
                height: 100%;

                @media only screen and (max-width:"768px") {
                    max-width: 100%;
                }

                .art-craft-diy-room-store-banner-flex-alignment {
                    display: flex;
                    align-items: center;
                    gap: 82px;
                    height: 100%;
                    position: absolute;
                    top: 0;

                    .left-arrow-alignment {
                        @media only screen and (max-width:"768px") {
                            display: none;
                        }
                    }

                    .art-craft-diy-room-store-banner-details {

                        h4 {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 40px;
                            line-height: 60px;
                            color: var(--secondary-900);

                            @media only screen and (max-width:"768px") {
                                font-size: 20px;
                                line-height: 30px;
                            }
                        }

                        p {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            color: var(--secondary-900);
                            max-width: 540px;

                            @media only screen and (max-width:"768px") {
                                font-size: 12px;
                                line-height: 18px;
                                max-width: 165px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                }
            }
        }


    }

    .art-craft-diy-tab-alignment {
        padding: 38px 0 0 0;
        position: relative;
        z-index: 999;

        @media only screen and (max-width:"768px") {
            padding: 16px;
        }

        ul {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 72px;

            @media only screen and (max-width:"1099px") {
                gap: 32px;
            }

            @media only screen and (max-width:"768px") {
                gap: 0;
                width: 100%;
                overflow-x: auto;
                justify-content: flex-start;
            }

            li {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #495057;
                cursor: pointer;

                @media only screen and (max-width:"768px") {
                    white-space: nowrap;
                    padding: 6px 16px;
                }

                &:hover {
                    color: var(--primary-500);
                    font-weight: 500;

                    @media only screen and (max-width:"768px") {
                        background: #F2F4FC;
                        border-radius: 8px;
                    }
                }

                &.art-craft-active {
                    color: var(--primary-500);
                    font-weight: 500;

                    @media only screen and (max-width:"768px") {
                        background: #F2F4FC;
                        border-radius: 8px;
                    }
                }
            }
        }
    }

    .art-craft-diy-body-details-alignment {
        padding: 60px 110px;

        @media only screen and (max-width:"1099px") {
            padding: 60px;
        }

        @media only screen and (max-width:"768px") {
            padding: 0;
        }

        .container {
            @media only screen and (max-width:"768px") {
                width: 100%;
                max-width: 100%;
                padding: 0;
            }
        }

        .art-craft-diy-body-grid-details-alignment {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 16px;

            @media only screen and (max-width:"1099px") {
                grid-template-columns: repeat(2, 1fr);
            }

            @media only screen and (max-width:"767px") {
                grid-template-columns: 1fr;
            }

            .art-craft-diy-body-gridItem-details-alignment {
                .art-craft-diy-box-details-alignment {
                    background: #FFF3EA;
                    border-radius: 16px;
                    padding: 16px;

                    &.second-color {
                        background: #EEF8FF;
                    }

                    &.third-color {
                        background: #F8F8F8;
                    }

                    .art-carft-diy-images-grid-alignment {
                        display: grid;
                        grid-template-columns: 210px 1fr;
                        gap: 16px;

                        .art-carft-diy-images-gridItem-alignment {
                            .art-craft-diy-first-img-alignment {
                                width: 100%;
                                height: 224px;
                                cursor: pointer;

                                @media only screen and (max-width:"768px") {
                                    height: 211px;
                                }

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    border-radius: 8px;
                                    display: block;
                                }
                            }

                            .art-craft-second-part-alignment {
                                .art-carft-child-img-alignment {
                                    width: 100%;
                                    height: 110px;
                                    margin: 0 0 4px 0;
                                    cursor: pointer;

                                    @media only screen and (max-width:"768px") {
                                        height: 104px;
                                    }

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        border-radius: 8px;
                                        object-fit: cover;
                                        display: block;
                                    }
                                }
                            }
                        }
                    }

                    .art-craft-child-details-alignment {
                        padding: 30px 0 0 0;

                        @media only screen and (max-width:"768px") {
                            padding: 16px 0 0 0;
                        }

                        h4 {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 30px;
                            color: var(--secondary-900);
                            cursor: pointer;

                            @media only screen and (max-width:"768px") {
                                font-size: 16px;
                                line-height: 24px;
                            }

                        }

                        .art-craft-auther-details-alignment {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            padding: 5px 0 0 0;

                            span {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 20px;
                                color: #808191;
                            }

                            .art-craft-author-profile-details-alignment {
                                display: flex;
                                align-items: center;
                                gap: 4px;

                                .author-profile-img-alignment {
                                    width: 20px;
                                    height: 20px;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        border-radius: 50%;
                                        object-fit: cover;
                                        display: block;
                                    }
                                }

                                h6 {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 500;
                                    font-size: 13px;
                                    line-height: 20px;
                                    color: var(--secondary-900);
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

.art-carft-web-view-details-alignment {

    @media only screen and (max-width:"768px") {
        display: none;
    }

}

.art-carft-mobile-view-details-alignment {
    display: none;

    @media only screen and (max-width:"768px") {
        display: block;
    }

}