.Newarrivaltitlemain {
    width: 100%;
    padding: 1px 37px;
    border-radius: 10px 10px 0px 0px;
    // background: linear-gradient(90deg, #171717 0%, #575820 100%);
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width:576px) {
        padding: 1px 18px;
    }

    .Newarrivaltitletextmain {
        text-align: left;
        padding: 58px 0;

        @media (max-width:576px) {
            padding: 5px 0 25px 0;
        }

        h4 {
            color: #FFF;
            font-family: "Poppins";
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 0 0 4px 0;
            word-break: keep-all;

            @media (max-width:700px) {
                font-size: 16px;
            }
        }

        p {
            color: #ECEEF4;
            font-family: "Poppins";
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.1px;

            @media (max-width:700px) {
                font-size: 8px;
            }
        }
    }

    .Newarrivalcartoon {
        margin: 0 105px 0 0;

        img {
            --user-drag: none;
            -webkit-user-drag: none;
            user-select: none;
            -moz-user-select: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            width: 100%;
            height: 100%;

            @media (max-width:700px) {
                width: 75px;
                height: 53.691px;
            }
        }

        @media (max-width:1024px) {
            margin: 0 70px 0 0;
        }

        @media (max-width:576px) {
            margin: 0;
        }
    }
}