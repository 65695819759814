@mixin block_btn {
  background: var(--primary-500);
  border-radius: 8px;
  display: block;
  width: 100%;
  border: unset;
  outline: unset;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    transform: translateY(-3px);
    background: #2844ac;
  }
}

.trendingStore-section {
  height: calc(100vh - 66px);
  overflow-y: auto;
  background: #ffffff;

  @media only screen and (max-width: '1024px') {
    height: 100%;
  }

  .trendingStore-main-banner {
    background: #f9f9f9;
    position: sticky;
    top: 0px;
    z-index: 99;

    @media only screen and (max-width: 1199px) {
      top: 0;
    }

    .trendingStore-back-alignment {
      display: flex;
      align-items: center;
      gap: 22px;
      padding: 8px 10px;
      background: rgba(248, 248, 248, 0.92);
      display: none;

      @media only screen and (max-width: '767px') {
        display: flex;
      }

      .back_details-alignment {
        background: #ffffff;
        border-radius: 10px;
        padding: 4px 9px 4px 10px;
        display: flex;
        align-items: center;
        gap: 8px;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #808191;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 58px;
        }
      }

      .mobile-heading-alignment {
        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          text-align: center;
          color: var(--secondary-900);
        }
      }
    }

    .trendingStore-banner {
      background: #f9f9f9;
      // background-image: url("../../../assets/img/trendingStore.svg");
      // width: 100%;
      // height: 100%;
      // background-repeat: no-repeat;
      // background-size: cover;
      // background-position: center;
      position: sticky;
      top: 60px;
      z-index: 99;

      @media only screen and (max-width: '767px') {
        // background-image: url("../../../assets/img/mobileTrendingStore.svg");
        // background-size: cover;
      }

      .trending-banner-img-alignment {
        position: absolute;
        top: 0;
        right: 0;

        img {
          width: 100%;
          height: 178px;
          object-fit: cover;
          object-position: center;
          display: block;

          @media only screen and (max-width: '767px') {
            display: none;
          }

          &.mobile-view-banner {
            display: none;

            @media only screen and (max-width: '767px') {
              display: block;
              height: 100px;
              display: block;
            }
          }
        }
      }

      .container {
        max-width: 1300px;
        margin: auto;
        padding: 0 20px;

        .trendingStore-small-banner-alignment {
          background: #f9f9f9;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          gap: 160.5px;

          .trendingStore-banner-section {
            height: 78px;
            display: flex;
            align-items: center;
            gap: 160.5px;
            position: relative;

            @media only screen and (max-width: '1099px') {
              gap: 80px;
              padding: 0 20px;
            }
          }

          .trendingStore-heading-alignment {
            h4 {
              font-family: 'Poppins', sans-serif;
              font-weight: 300;
              font-size: 30px;
              line-height: 60px;
              color: #000000;
              padding: 0 0 4px 0;
            }
          }
        }

        div {
          img {
            cursor: pointer;
            display: block;
          }
        }

        .trendingStore-banner-section {
          height: 178px;
          display: flex;
          align-items: center;
          gap: 160.5px;
          position: relative;

          @media only screen and (max-width: '1099px') {
            gap: 80px;
            padding: 0 20px;
          }

          @media only screen and (max-width: '767px') {
            height: 100px;
          }

          .left-arrow-alignment {
            @media only screen and (max-width: '767px') {
              display: none;
            }
          }
        }

        .trendingStore-heading-alignment {
          h4 {
            font-family: 'Poppins', sans-serif;
            font-weight: 300;
            font-size: 40px;
            line-height: 60px;
            color: #000000;
            padding: 0 0 4px 0;

            @media only screen and (max-width: '767px') {
              font-size: 20px;
              line-height: 30px;
            }
          }

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 300;
            font-size: 18px;
            line-height: 27px;
            color: var(--secondary-900);

            @media only screen and (max-width: '767px') {
              font-size: 14px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 200px;
            }
          }
        }
      }
    }
  }

  .trending-store-alignment {
    padding: 20px 0;
    background: #ffffff;


    @media only screen and (max-width: '1024px') {
      padding: 16px 0;
      height: 100%;
      overflow-y: auto;
    }
    .popularStoreDetailsGridView {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;

      @media only screen and (max-width: '767px') {
        grid-template-columns: 1fr;
      }
    }

    .container {
      max-width: 1300px;
      margin: auto;
      padding: 0 20px;

      .right_block_cover {
        width: 100%;

        .trending_store_grid {
          position: relative;
          cursor: pointer;
          margin: 0 -8px;
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 21px;

          @media only screen and (max-width: '1199px') {
            grid-template-columns: repeat(4, 1fr);
          }

          @media only screen and (max-width: '999px ') {
            grid-template-columns: repeat(3, 1fr);
            gap: 16px;
          }

          @media only screen and (max-width: '799px') {
            grid-template-columns: repeat(2, 1fr);
            gap: 16px;
          }

          @media only screen and (max-width: '576px') {
            grid-template-columns: repeat(1, 1fr);
            gap: 16px;
          }

          .Trending_Store_slider_cover {
            padding: 0;

            .card_store {
              width: 100%;
              border: 1px solid #eceef4;
              border-radius: 12px;
              background: #ffffff;
              overflow: hidden;
              transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

              &:hover {
                transform: translateY(-4px);
                border: 1px solid #ab67fc;

                .card_content h5 {
                  color: #ab67fc;
                }
              }

              .cardtop_store {
                overflow: hidden;
                border-radius: 12px;
                width: 100%;
                max-height: 145px;
                height: 145px;
                object-fit: cover;
                object-position: center;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              .card_content {
                padding: 16px;
                text-align: center;

                h5 {
                  font-weight: 500;
                  font-size: 20px;
                  line-height: 30px;

                  color: #000000;
                }

                h6 {
                  font-size: 14px;
                  line-height: 21px;
                  font-weight: 400;
                  color: #808191;
                }

                .product {
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 21px;
                  color: #808191;
                  margin: 16px 0 8px 0;
                }

                .Visitor {
                  font-size: 14px;
                  line-height: 21px;
                  color: #808191;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .pre {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                }

                button {
                  @include block_btn();
                  margin-top: 16px;
                }
              }
            }
          }

          .slick-list {
            padding-top: 5px;
          }
        }
      }
    }
  }
}