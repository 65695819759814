.mobilePicUPModalSection {
    padding: 24px;

    .mobileFirstColurBoxAlignment {
        width: 100%;
        height: 40px;
        border-radius: 6px;
        background: #7400E9;
    }

    .mobileViewSecondColurPicBox {
        margin: 8px 0 0 0;
        width: 100%;
        height: 325px;
        border-radius: 6px;
        background: linear-gradient(270deg, #7400E9 0%, rgba(116, 0, 233, 0.00) 100%);
        position: relative;

        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            bottom: 0;
            left: 0;
            border-radius: 6px;
            background: linear-gradient(16deg, #000 0%, rgba(220, 174, 174, 0.00) 100%);
        }
    }

    .mobileViewThirdColurBoxAlignment {
        padding: 12px 0 0 0;
        display: flex;
        align-items: center;
        gap: 11px;

        .mobileViewColorStrick {
            width: 100%;

            svg {
                width: 100%;
            }
        }

        a {
            padding: 6px 16px;
            color: var(--primary-500);
            text-align: center;
            font-family: 'Poppins', sans-serif;
            font-size: 15px;
            font-weight: 500;
            line-height: 20px;
        }

    }
}