@mixin on($display) {
  display: $display;
  visibility: visible;
  touch-action: auto;
  pointer-events: all;
}

@mixin off($display) {
  display: $display;
  visibility: hidden;
  touch-action: none;
  pointer-events: none;
}

@mixin block_btn {
  background: var(--primary-500);
  border-radius: 8px;
  display: block;
  width: 100%;
  border: unset;
  outline: unset;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    transform: translateY(-3px);
    background: #2844ac;
  }
}

.men-findYourStyles-section {
  background: #151515;

  .findYourStyles-back-alignment {
    display: flex;
    align-items: center;
    gap: 22px;
    padding: 8px 10px;
    background: #1e2432;
    display: none;

    @media only screen and (max-width: '767px') {
      display: flex;
      position: sticky;
      top: 0;
      z-index: 99;
    }

    .back_details-alignment {
      background: rgba(172, 177, 192, 0.4);
      border-radius: 10px;
      padding: 4px 9px 4px 10px;
      display: flex;
      align-items: center;
      gap: 8px;

      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #e4e4e8;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 58px;
      }
    }

    .mobile-heading-alignment {
      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #f8f8f8;
      }
    }
  }

  .findYourStyles-banner {
    background: #151515;
    // background-image: url('../../../assets/img/MensCategory/MenfindyourStyleBg.png');
    // width: 100%;
    // height: 100%;
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: center;
    position: sticky;
    top: 0px;
    z-index: 99;

    @media only screen and (max-width: '1199px') {
      top: 0;
    }

    @media only screen and (max-width: 767px) {
      // background-image: url('../../../assets/img/mobileFindYourStyleBg.svg');
      // background-size: cover;
      padding: 0 16px;
      // display: none;
      position: sticky;
      top: 48px;
      z-index: 99;
    }

    .men-find-your-style-banner-img-alignment {
      position: absolute;
      top: 0;
      right: 0;

      img {
        width: 100%;
        height: 178px;
        object-fit: cover;
        object-position: center;
        display: block;

        @media only screen and (max-width: '767px') {
          display: none;
        }

        &.mobile-view-banner {
          display: none;

          @media only screen and (max-width: '767px') {
            display: block;
            height: 100px;
            display: block;
          }
        }
      }
    }

    .container {
      max-width: 1440px;
      margin: auto;
      padding: 0 20px;

      div {
        img {
          cursor: pointer;
          display: block;
        }
      }

      .findYourStyles-banner-section {
        height: 178px;
        display: flex;
        align-items: center;
        gap: 160.5px;
        position: relative;

        @media only screen and (max-width: '1099px') {
          gap: 80px;
          padding: 0 20px;
        }

        @media only screen and (max-width: 767px) {
          height: 100px;
        }

        .left-arrow-alignment {
          @media only screen and (max-width: 767px) {
            display: none;
          }
        }
      }

      .findYourStyles-heading-alignment {
        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 40px;
          line-height: 60px;
          color: #f8f8f8;
          padding: 0 0 4px 0;
          text-transform: uppercase;

          @media only screen and (max-width: '767px') {
            font-size: 20px;
            line-height: 30px;
          }
        }

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 300;
          font-size: 18px;
          line-height: 27px;
          color: #f8f8f8;
          text-transform: capitalize;

          @media only screen and (max-width: '767px') {
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 200px;
          }
        }
      }
    }
  }

  .findYourStyles-search-section {
    padding: 32px 0;
    background: #1e2432;

    @media only screen and (max-width: 767px) {
      display: none;
    }

    .container {
      max-width: 1300px;
      margin: auto;
      padding: 0 20px;

      .search-alignment {
        background: #f8f8f8;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        padding: 15px 0;
        width: 100%;

        @media only screen and (max-width: 767px) {
          display: none;
        }

        .search-icon-alignment {
          img {
            cursor: pointer;
            display: block;
            width: 42px;
            height: 42px;
            object-fit: cover;
          }
        }

        .search-input-alignment {
          input {
            background: transparent;
            border: none;
            height: 100%;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            color: #acb1c0;
          }
        }
      }
    }
  }

  .findYourStyles-styles-details-alignment {
    background: #1e2432;

    @media only screen and (max-width: 767px) {
      display: none;
    }

    // padding: 0 0 32px 0;
    .child-styles-details {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 0 0 32px 0;

      .details-heading-alignment {
        // background: #f7f7f7;
        border-radius: 10px;
        height: 496px;
        width: 336px;
        min-width: 336px;
        max-width: 336px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 20px;
        position: relative;
        // &.second-product {
        //   background-color: #f7f3ed;
        // }

        // &.third-product {
        //   background-color: #091534;

        .men-find-your-style-bg-img {
          position: absolute;
          top: 0;
          right: 0;

          img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }

          &::before {
            content: '';
            position: absolute;
            background: linear-gradient(180deg, rgba(30, 36, 50, 0.32) 0%, #1e2432 89.52%);
            // border-radius: 10px;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
          }
        }

        .fashion-name-alignment {
          display: flex;
          flex-direction: column;
          align-items: center;

          h5 {
            font-family: 'Poppins', sans-serif;
            font-weight: 300;
            font-size: 32px;
            line-height: 48px;
            color: #f8f8f8;
            padding: 0 0 20px 0;
            text-align: center;
            text-transform: uppercase;
          }

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #f8f8f8;
            max-width: 220px;
          }
        }

        .see-all-product-alignment {
          a {
            display: flex;
            gap: 8px;
            align-items: center;
            justify-content: center;
            font-family: 'Poppins', sans-serif;
            font-weight: 300;
            font-size: 18px;
            line-height: 27px;
            text-align: center;
            color: var(--primary-500);
            cursor: pointer;
          }
        }
      }

      .findYourStyle_product_block {
        width: 100%;

        .findYourStyle_product_block_row {
          margin: 0 -8px;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          align-items: stretch;
          height: 496px;
          overflow-y: hidden;

          @media only screen and (max-width: 1199px) {
            grid-template-columns: repeat(3, 1fr);
          }

          @media only screen and (max-width: 967px) {
            grid-template-columns: repeat(2, 1fr);
          }

          .card_col {
            width: 100%;
            padding: 0 8px;
            height: 100%;
            display: grid;
            height: 317px;

            .men-find-style-cardcover {
              width: 100%;
              border-radius: 10px;
              position: relative;
              overflow: hidden;
              cursor: pointer;
              margin-bottom: 15px;

              // @media only screen and (max-width:799px) {
              //     width: 100%;
              // }

              &:last-child {
                margin-bottom: 0;
              }

              &:hover {
                .card_top img {
                  transform: scale(1.05);
                }

                .card_bottom {
                  @include on(flex);
                }
              }

              .card_top {
                height: 100%;
              }

              .card_top img {
                width: 100%;
                height: 100%;
                transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                object-fit: cover;
              }

              .card_bottom {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, rgba(30, 36, 50, 0) 0%, #1e2432 100%);
                padding: 16px;
                display: flex;
                align-items: flex-end;
                @include off(none);

                .card_bottom_cover {
                  width: 100%;

                  h4 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #f8f8f8;
                  }

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      display: flex;
                      align-items: center;
                      font-size: 12px;
                      line-height: 18px;
                      color: #f8f8f8;

                      img {
                        margin-right: 4px;
                      }
                    }

                    .incris {
                      margin-left: 15px;
                      display: flex;
                      align-items: center;
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 21px;
                      color: #70c217;

                      img {
                        margin-right: 4px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;
                    margin: 9px 0 18px 0;

                    .rating_point {
                      font-weight: 600;
                      font-size: 16px;
                      line-height: 24px;
                      color: #f8f8f8;
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 8px;
                    }

                    .num {
                      font-size: 16px;
                      line-height: 24px;
                      color: #f8f8f8;
                    }
                  }

                  .buy_btn {
                    @include block_btn();
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .men-mobile-view-details-fashion-name-search-alignment {
    display: none;

    gap: 8px;
    background: #ffffff;
    padding: 16px 0 0 0;
    overflow-x: hidden;

    @media only screen and (max-width: 767px) {
      background: #1e2432;
      display: flex;
    }

    @media only screen and (max-width: 576px) {
      padding: 0;
    }

    .mobile-view-child-heading-alignment {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0 26px 0 0;

      // @media only screen and (max-width: 767px) {
      //   padding: 0 60px 0 0;
      // }

      .mobile-view-child-name-alignment {
        display: flex;
        align-items: center;
        gap: 4px;

        h5 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 18px;
          line-height: 30px;
          color: var(--secondary-900);

          @media only screen and (max-width: 767px) {
            color: #f8f8f8;
          }
        }

        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #808191;
        }
      }

      .mobile-view-list-alignment {
        display: flex;
        align-items: center;
        gap: 24px;
      }

      .mobile-search-icon-alignment {
        img {
          width: 24px;
          height: 24px;
          display: block;
          cursor: pointer;
        }
      }
    }

    .mobile-child-search-alignment {
      position: relative;
      right: 16px;
      margin: 14px 0 0 0;

      input {
        background: #f8f8f8;
        border-radius: 5px;
        width: auto;
        height: 34px;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: var(--secondary-900);
        padding: 10px 40px 10px 10px;
      }

      .close-alignment {
        position: absolute;
        top: 10px;
        right: 14px;
        cursor: pointer;
      }
    }

    .mobile-view-sidebar-alignment {
      display: flex;
      align-items: flex-start;

      .mobile-search-sidebar-details-box-alignment {
        display: flex;
        align-items: flex-start;

        .men-find-style-mobile-view-sidebar-box-alignment {
          background: #f8f8f8;
          // border-radius: 8px;
          height: calc(100vh - 162px);
          overflow-y: auto;
          width: 145px;
          max-width: 145px;
          min-width: 145px;

          @media only screen and (max-width: 767px) {
            background: #151515;
            height: calc(100vh - 100px - 48px - 65px - 50px);
          }

          @media only screen and (max-width: 576px) {
            height: calc(100vh - 218px);
          }

          .mobile-silder-side-main-details {
            padding: 29px 16px;
            position: relative;

            .mobile-slider-all-details-alignment {
              margin: 0 0 16px 0;

              &:last-child {
                margin: 0;
              }

              &.active-men-style {
                .mobile-silder-main-img-alignment {
                  border: 1px solid #5f40af;
                }
              }

              .mobile-silder-main-img-alignment {
                width: 110px;
                height: 185px;
                border: 1px solid transparent;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                &:hover {
                  border: 1px solid #5f40af;
                }

                .mobile-slider-details-img {
                  width: 98px;
                  height: 173px;
                  border-radius: 8px;
                  position: relative;
                  margin: auto;

                  img {
                    width: 100%;
                    height: 100%;
                    display: block;
                    object-fit: cover;
                  }

                  &::before {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    background: linear-gradient(180deg, rgba(30, 36, 50, 0) 0%, #1e2432 100%);
                    top: 0;
                    right: 0;
                    z-index: 99;
                  }

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    text-align: center;
                    color: #f8f8f8;
                    margin: 4px 0 0 0;
                    position: absolute;
                    bottom: 12px;
                    right: 0;
                    left: 0;
                    z-index: 999;
                  }
                }
              }
            }

            .find-fashion-border-alignment {
              width: 114px;
              height: 114px;
              border: 1px solid transparent;
              border-radius: 68.4405px;
              padding: 6px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0 0 2px 0;

              &.find-fashion-border-active {
                border: 1px solid #ffecbc;
              }

              .find-fashion-box-alignment {
                background: transparent;
                border-radius: 68.4405px;
                width: 102px;
                height: 102px;
                display: flex;
                align-items: center;
                justify-content: center;

                &.find-fashion-box-active {
                  background-color: rgba(255, 203, 70, 0.6);
                }
              }
            }

            .fashion-img-alignment {
              width: 88px;
              height: 88px;
              position: relative;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
              }

              &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background: rgba(30, 36, 50, 0.6);
                top: 0;
                right: 0;
                border-radius: 50%;
              }

              .fashion-option-name-alignment {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 9;

                h5 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  text-align: center;
                  color: #ffffff;
                }
              }
            }
          }
        }

        .mobile-view-left-arrow-alignment {
          background: #f8f8f8;
          border-radius: 0px 8px 8px 0px;
          padding: 10px 14px;
          margin: 0 8px 0 0;
          width: 32px;
          min-width: 32px;
          max-width: 32px;

          @media only screen and (max-width: 767px) {
            background: #151515;
          }
        }
      }
    }
  }
}

// @media only screen and (max-width: 576px) {
//   // Mobile View Design .search-alignment
//   .men-mobile-contain {
//     position: relative;
//     right: 0px !important;
//     width: calc(100% - 20px);
//   }
// }

@media only screen and (max-width: 1024px) {
  .men-mobile-contain {
    // height: calc(100vh - 172px);
  }
}

.width-alignment {
  @media only screen and (max-width: 576px) {
    height: calc(100vh - 216px);
  }

  .men-mobile-findYourStyle_product_block_main {

    @media only screen and (max-width: 576px) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
  }
}

.men-mobile-findYourStyle_product_block-space-remove {
  padding: 0 !important;

  @media (max-width:576px) {
    height: calc(100vh - 216px - 54px) !important;
    overflow-y: auto !important;
  }
}

.men-mobile-findYourStyle_product_block {
  width: 100%;
  overflow-x: hidden;
  margin: 14px 0 0 0;
  position: relative;
  // right: 20px;
  height: calc(100vh - 202px);
  overflow-y: auto;

  @media only screen and (max-width: 576px) {
    height: 100%;
    margin: 0;
    padding: 10px;
    overflow: hidden;
    background-color: #eef1fd;
  }

  .findYourStyle_Mobile-product_block_row {
    margin: 0;
    // background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    grid-template-columns: repeat(2, 1fr);
    align-items: stretch;
    height: 100%;
    overflow-y: auto;
    gap: 12px 0;
    padding: 0 0 16px 0;

    @media only screen and (max-width: 576px) {
      align-items: center;
      justify-content: center;
    }

    p {
      text-align: center;
    }

    .findYourStyle-product-box-alignment {
      background: #ffffff;
      border: 1px solid #f3f3f3;
      border-radius: 10px;
      padding: 2px;
      margin: 0 0 16px 0;

      .findYourStyle-img-option-alignment {
        position: relative;

        .findYourStyle-more-option-flex-alignment {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          position: absolute;
          top: 8px;
          left: 8px;
          right: 8px;

          .img-number-details {
            background: rgba(30, 36, 50, 0.6);
            padding: 6px;
            border-radius: 8px;

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #ffffff;
            }
          }
        }

        .findYourStyle-product-color-main {
          position: absolute;
          bottom: 8px;
          right: 8px;

          .findYourStyle-color-alignment {
            display: flex;
            align-items: center;
            gap: 7px;
            background: #ffffff;
            border: 0.5px solid #e4e4e8;
            border-radius: 10.5px;
            padding: 2px 6px;

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              color: var(--secondary-900);
            }
          }
        }
      }

      .findYourStyle-product-details-alignment {
        padding: 8px;

        .produtc-heading-alignment {
          h4 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: var(--secondary-900);
          }
        }

        .product-child-details-alignment {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 4px 0 0 0;

          .product-proce-discount-alignment {
            display: flex;
            align-items: center;
            gap: 5px;
            flex-wrap: wrap;

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              color: var(--secondary-900);
            }

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              text-decoration-line: line-through;
              color: #acb1c0;
            }

            .find-your-style-discount-menu-align {
              width: 61px;
              height: 21px;
              background: #ff7d23;
              border-radius: 11px;
              display: flex;
              justify-content: center;
              align-items: center;

              button {
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                display: flex;
                align-items: center;
                color: #ffffff;
              }
            }

            .find-your-style-delivery-menu-align {
              width: 62px;
              height: 21px;
              background: #eaeaea;
              border-radius: 13.5px;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 2px;

              img {}

              span {
                font-weight: 500;
                font-style: italic;
                font-size: 12px;
                line-height: 18px;
                display: flex;
                align-items: center;
                text-decoration-line: none;
                color: #5f40af;
              }
            }
          }
        }

        .product-interest-details-alignment {
          display: flex;
          align-items: center;
          gap: 7px;

          .product-interest-child-details-alignment {
            display: flex;
            align-items: center;
            gap: 5px;

            .product-analytics-section {
              display: flex;
              // justify-content: flex-start;
              align-items: center;
              gap: 12px;

              @media screen and (max-width: 767px) {
                margin: 4px 0;
              }

              .product-graph-menu-align {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 6px;

                img {
                  width: 14px;
                  height: 11.8px;
                }

                span {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 18px;
                  color: #000000;
                }
              }

              .arrow-menu-align {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 6px;

                img {
                  width: 11px;
                  height: 11px;
                }

                span {
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 21px;
                  display: flex;
                  align-items: center;
                  color: #70c217;
                }
              }
            }
          }
        }

        .findYourStyle-product-rating {
          display: flex;
          align-items: center;
          margin: 4px 0;

          .findYourStyle-product-rating_point {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            color: var(--secondary-900);
          }

          .findYourStyle-product-stars {
            display: flex;
            align-items: center;
            margin: 0 2px;

            img {
              width: 13px;
              height: 13px;
              display: block;
            }
          }

          .findYourStyle-product-num {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #acb1c0;
          }
        }
      }

      .findYourStyle-list-details-alignment {
        .findYourStyle-list-card_utils {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          border: 1px solid #f3f3f3;
          border-radius: 0px 0px 10px 0px;

          .findYourStyle-list-box {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px 0;
            border-right: 1px solid #f3f3f3;

            &:last-child {
              border-right: none;
            }

            svg {
              margin-right: 5px;
            }
          }
        }
      }
    }

    .card_col {
      width: 100%;
      padding: 0 8px;
      height: 100%;
      display: grid;
      height: 317px;

      .men-find-style-cardcover {
        // width: 180px;
        width: 164px;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        margin-bottom: 15px;

        // @media only screen and (max-width:399px) {
        //   width: 100%;
        // }

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          .card_top img {
            transform: scale(1.05);
          }

          .card_bottom {
            @include on(flex);
          }
        }

        .card_top {
          height: 100%;
        }

        .card_top img {
          width: 100%;
          height: 100%;
          transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
        }

        .card_bottom {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(180deg, rgba(30, 36, 50, 0) 0%, #1e2432 100%);
          padding: 16px;
          display: flex;
          align-items: flex-end;
          @include off(none);

          .card_bottom_cover {
            width: 100%;

            h4 {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #f8f8f8;
            }

            .hike {
              display: flex;
              align-items: center;

              .inter {
                display: flex;
                align-items: center;
                font-size: 12px;
                line-height: 18px;
                color: #f8f8f8;

                img {
                  margin-right: 4px;
                }
              }

              .incris {
                margin-left: 15px;
                display: flex;
                align-items: center;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                color: #70c217;

                img {
                  margin-right: 4px;
                }
              }
            }
          }
        }
      }
    }
  }

  .findYourStyle_mobile-view-list-details-alignment {
    margin: 0;
    width: 100%;
    gap: 16px 15px;
    display: block;

    .fondYourStyle_mobile-view-list-box {
      background: #ffffff;
      border: 1px solid #f3f3f3;
      border-radius: 10px;
      padding: 4px 8px;
      margin: 0 0 4px 0;
      display: flex;
      align-items: flex-start;
      gap: 8px;

      .fondYourStyle_img-alignment {
        min-width: 74px;
        width: 74px;
        max-width: 74px;
        margin: 4px 0 0 0;
        height: 74px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          object-fit: cover;
        }

        @media (max-width:576px) {
          min-width: 100px;
          width: 100px;
          max-width: 100px;
          height: 100%;
        }
      }

      .fondYourStyle_listView-details-alignment {
        width: 100%;
        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: var(--secondary-900);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 244px;
        }

        .listView-details_product-child-details-alignment {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 2px 0 0 0;

          .listView-details_product-proce-discount-alignment {
            display: flex;
            align-items: center;
            gap: 5px;
            flex-wrap: wrap;

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              color: var(--secondary-900);
            }

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              text-decoration-line: line-through;
              color: #acb1c0;
            }

            .listView-details_find-your-style-discount-menu-align {
              width: 61px;
              height: 21px;
              background: #ff7d23;
              border-radius: 11px;
              display: flex;
              justify-content: center;
              align-items: center;

              button {
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                display: flex;
                align-items: center;
                color: #ffffff;
              }
            }
          }
        }

        .listView-details_shopping-alignment {
          display: flex;
          align-items: center;
          gap: 8px;
          margin: 4px 0 0 0;

          .listView-details_findYourStyle-color-alignment {
            display: flex;
            align-items: center;
            gap: 7px;
            background: #ffffff;
            border: 0.5px solid #e4e4e8;
            border-radius: 10.5px;
            padding: 2px 6px;

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              color: var(--secondary-900);
            }
          }

          .listView-details_find-your-style-delivery-menu-align {
            width: 62px;
            height: 21px;
            background: #eaeaea;
            border-radius: 13.5px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2px;

            img {}

            span {
              font-weight: 500;
              font-style: italic;
              font-size: 12px;
              line-height: 18px;
              display: flex;
              align-items: center;
              text-decoration-line: none;
              color: #5f40af;
            }
          }
        }

        .listView-details_child-details-alignment {
          display: flex;
          align-items: center;
          gap: 7px;

          .product-interest-details-alignment {
            display: flex;
            align-items: center;
            gap: 5px;

            .product-interest-child-details-alignment {
              display: flex;
              align-items: center;
              gap: 5px;

              .product-analytics-section {
                display: flex;
                // justify-content: flex-start;
                align-items: center;
                gap: 5px;

                @media screen and (max-width: 767px) {
                  margin: 4px 0;
                }

                .product-graph-menu-align {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  gap: 6px;

                  img {
                    width: 14px;
                    height: 11.8px;
                  }

                  span {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: #000000;
                  }
                }

                .arrow-menu-align {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  gap: 6px;

                  img {
                    width: 11px;
                    height: 11px;
                  }

                  span {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    display: flex;
                    align-items: center;
                    color: #70c217;
                  }
                }
              }
            }
          }

          .findYourStyle-product-rating {
            display: flex;
            align-items: center;
            margin: 4px 0;

            .findYourStyle-product-rating_point {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 12px;
              line-height: 18px;
              color: var(--secondary-900);
            }

            .findYourStyle-product-stars {
              display: flex;
              align-items: center;
              margin: 0 2px;

              img {
                width: 13px;
                height: 13px;
                display: block;
              }
            }

            .findYourStyle-product-num {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #acb1c0;
            }
          }
        }

        .listView-details_findYourStyle-list-details-alignment {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .mobile-men-sub-category-list-card_utils {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            max-width: 164px;

            @media (max-width:576px) {
              max-width: none;
            }

            .mobile-men-sub-category-list-box {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 5px 0;
              border-right: 1px solid #f3f3f3;

              &:last-child {
                border-right: none;
              }

              svg {
                margin-right: 5px;
              }
            }
          }

          .listView-details_moreOption-alignment {}
        }
      }
    }
  }
}