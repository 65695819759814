.message-sidebar-section {
  background: #ffffff;
  border-radius: 10px;
  // padding: 20px 16px;
  position: relative;
  height: calc(100vh - 96px);
  overflow-y: hidden;

  @media only screen and (max-width:"767px") {
    height: calc(100vh - 64px);
    // height: calc(100vh - 104px);
    background-color: transparent;
    border-radius: 0;
    overflow-y: unset;
  }

  .message-sidebar-header-sticky {
    position: sticky;
    top: 0px;
    z-index: 9999;
    background: #fff;
    padding: 20px 16px 0 16px;
    height: 100%;
    border-radius: 16px;
    border: 2px solid #eef1fd;

    @media only screen and (max-width:"767px") {
      height: calc(100vh - 120px);
      background-color: transparent;
      padding: 0;
    }

    .message-personal-profile-chat-alignment {
      padding: 16px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      //   margin: 0 0 16px 0;
      cursor: pointer;

      &:hover {
        background: var(--primary-50);
        border-radius: 10px;

        @media only screen and (max-width:"767px") {
          background: transparent;
        }
      }

      &.un-read-message {
        .message-personal-profile-alignment {
          .message-personal-profile-person-details-alignment {
            p {
              color: var(--secondary-900);
              font-weight: 600;
            }
          }
        }

        .message-time-alignment {
          .total-message-alignment {
            display: block;
          }
        }
      }

      &:last-child {
        margin: 0;
      }

      .message-personal-profile-alignment {
        display: flex;
        align-items: center;
        gap: 8px;

        .message-personal-profile-img-alignment {
          width: 48px;
          height: 48px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        .message-personal-profile-person-details-alignment {
          h5 {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: var(--secondary-900);
            padding: 0 0 4px 0;
          }

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: var(--secondary-600);
            max-width: 179px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .message-time-alignment {
        .total-message-alignment {
          display: none;
          margin: 0 0 8px 0;

          .total-message-box-alignmant {
            padding: 4px;
            border-radius: 50%;
            background: #d21414;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 13px;
              line-height: 18px;
              color: #ffffff;
            }
          }
        }

        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: #92929d;
        }
      }
    }

    .group-message-tab-alignment {
      // margin: 0 0 16px 0;
      border-bottom: 1px solid #eceef4;

      &:last-child {
        margin: 0;
      }

      .group-message-tab-box-alignment {
        padding: 18px 16px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        cursor: pointer;

        &:hover {
          background: var(--primary-50);
          border-radius: 10px;
        }

        &.un-read-group-message {
          .group-message-profile-details-alignment {
            .group-message-profile-all-details-alignment {
              p {
                color: var(--secondary-900);
                font-weight: 600;
              }
            }
          }

          .group-message-time-alignment {
            .group-total-message-alignment {
              display: flex;
            }
          }
        }

        .group-message-time-alignment {
          .group-total-message-alignment {
            display: none;
            margin: 0 0 8px 0;

            .group-total-message-box-alignmant {
              padding: 0 4px;
              border-radius: 50%;
              background: #d21414;
              display: flex;
              align-items: center;
              justify-content: center;

              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 13px;
                line-height: 18px;
                color: #ffffff;
              }
            }
          }

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: #92929d;
          }
        }

        .group-message-profile-details-alignment {
          display: flex;
          align-items: center;
          gap: 16px;

          .group-message-profile-img-box-alignment-sidebar {
            width: 46px;
            min-width: 46px;
            height: 48px;
            min-height: 48px;
            position: relative;

            .group-message-flex-alignment-0 {
              width: 24px;
              height: 24px;
              min-width: 24px;

              .group-message-profile-img-alignment-0 {
                width: 24px;
                height: 24px;
                min-width: 24px;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  object-fit: cover;
                }
              }
            }

            .group-message-flex-alignment-1 {
              display: flex;
              justify-content: flex-end;
              margin: -12px 0 0 0;

              .group-message-profile-img-alignment-1 {
                width: 16px;
                height: 16px;
                min-width: 16px;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  object-fit: cover;
                }
              }
            }

            .group-message-flex-alignment-2 {
              display: flex;
              justify-content: center;

              .group-message-profile-img-alignment-2 {
                width: 20px;
                height: 20px;
                min-width: 20px;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  object-fit: cover;
                }
              }
            }

            .group-message-first-profile-img-alignment {
              width: 24px;
              height: 24px;
              min-width: 24px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
              }
            }

            .group-message-second-flex-alignment {
              display: flex;
              justify-content: flex-end;
              margin: -12px 0 0 0;

              .group-message-second-profile-img-alignment {
                width: 16px;
                height: 16px;
                min-width: 16px;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  object-fit: cover;
                }
              }
            }

            .group-message-third-flex-alignment {
              display: flex;
              justify-content: center;

              .group-message-third-profile-img-alignment {
                width: 20px;
                height: 20px;
                min-width: 20px;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  object-fit: cover;
                }
              }
            }

            .group-message-first-dot-alignment {
              position: absolute;
              top: 4px;
              right: 13px;

              .first-dot-width-alignment {
                width: 5px;
                height: 5px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }

            .group-message-second-dot-alignment {
              position: absolute;
              left: 1px;
              bottom: 15px;

              .second-dot-width-alignment {
                width: 6px;
                height: 6px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }

            .group-message-third-dot-alignment {
              position: absolute;
              right: 9px;
              bottom: 15px;

              .third-dot-width-alignment {
                width: 2px;
                height: 2px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }

          .group-message-profile-all-details-alignment {
            h5 {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 18px;
              line-height: 27px;
              color: var(--secondary-900);
            }

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              color: var(--secondary-600);
              padding: 4px 0 0 0;
              max-width: 179px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .group-message-images-icon-and-text-alignment {
              display: flex;
              align-items: center;
              justify-content: center;
              // padding: 8px 0 96px 0;
              gap: 4px;

              span {
                display: block;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: var(--secondary-600);
              }

              .group-message-image-group {
                display: flex;
                align-items: center;
                gap: 0;

                img:first-child {
                  position: relative;
                  z-index: 99;
                }

                img:nth-child(2) {
                  position: relative;
                  z-index: 9;
                }

                img {
                  margin-left: -10px;
                  border: 1px solid #ffffff;
                  width: 25px;
                  height: 20px;
                  border-radius: 50%;
                  object-fit: cover;
                }
              }
            }
          }
        }
      }
    }
  }

  .message-sidebar-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width:"767px") {
      padding: 16px 16px 0 16px;
    }

    h6 {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: var(--secondary-900);
    }

    .new-button-alignment {
      position: relative;

      .mobileview-button {
        display: none;

        @media only screen and (max-width:"767px") {
          display: block;
        }
      }

      .webview-button {

        @media only screen and (max-width:"767px") {
          display: none;
        }
      }

      button {
        padding: 6px 16px;
        background: var(--primary-50);
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 2px;

        a {

          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          color: var(--primary-500);

          @media only screen and (max-width:"767px") {
            display: none;
          }
        }

        cursor: pointer;
      }

      .new-button-dropdown-alignment {
        position: absolute;
        top: 40px;
        right: 0;
        padding: 16px;
        background: #ffffff;
        border: 1px solid #e3e4e8;
        border-radius: 10px;
        z-index: 999;

        .new-button-dropdown-option-alignment {
          padding: 3px 10px;
          margin: 0 0 8px 0;
          cursor: pointer;

          &:hover {
            background: #eef1fc;
            border-radius: 4px;
          }

          &.new-button-option-active {
            background: #eef1fc;
            border-radius: 4px;
          }

          &:last-child {
            margin: 0;
          }

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: var(--secondary-900);
            white-space: nowrap;
          }
        }
      }
    }
  }

  .message-search-alignment {
    margin-top: 10px;
    background: #ffffff;
    border: 1px solid #d4d3d3;
    border-radius: 10px;
    display: flex;
    align-items: center;
    margin: 14px 0 0 0;
    height: 40px;
    position: relative;

    @media only screen and (max-width:"767px") {
      margin: 14px 16px 0 16px;
    }

    img {
      cursor: pointer;
    }

    input {
      width: 100%;
      height: 100%;
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding: 0 35px 0 0;

      &::placeholder {
        color: #acb1c0;
      }
    }

    .close-icon-alignment {
      position: absolute;
      top: 8px;
      right: 8px;

      img {
        cursor: pointer;
      }
    }
  }

  .total-chat-number-alignment {
    padding: 16px 16px 8px 16px;

    span {
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #808191;
      cursor: pointer;
    }
  }

  .search-member-details-box-alignment {
    padding: 0 16px;

    .search-member-details-alignment {
      padding: 18px 16px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      gap: 16px;
      margin: 0 0 16px 0;

      .search-memeber-details-flex-alignment {
        display: flex;
        align-items: center;
        gap: 16px;

        .search-member-img-alignment {
          width: 48px;
          height: 48px;
          min-width: 48px;
          max-width: 48px;

          img {
            width: 100%;
            height: 100%;
            // border-radius: 50%;
            object-fit: cover;
          }
        }

        .search-member-name-alignment {
          h5 {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: var(--secondary-900);
            padding: 0 0 4px 0;
          }

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: var(--secondary-600);
            max-width: 180px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      span {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #92929d;
      }
    }
  }

  .no-search-details-alignment {
    height: calc(100vh - 272px);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 94px;

    @media only screen and (max-width:"767px") {
      height: calc(100vh - 330px);
    }

    .no-search-img-alignment {}

    p {
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--secondary-400);
    }
  }

  .message-tab-alignment {
    margin: 8px 0 0 0;

    @media only screen and (max-width:"767px") {
      margin: 0;
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eceef4;

      li {
        padding: 18px 0;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        color: var(--secondary-600);
        cursor: pointer;
        width: 100%;
        text-align: center;
        position: relative;

        &:hover {
          font-weight: 600;
          color: var(--primary-700);

          &::before {
            content: '';
            position: absolute;
            background: var(--primary-700);
            border-radius: 8px;
            height: 2px;
            width: 100%;
            right: 0;
            bottom: 0;
          }
        }

        &.message-tab-active {
          font-weight: 600;
          color: var(--primary-700);

          &::before {
            content: '';
            position: absolute;
            background: var(--primary-700);
            border-radius: 8px;
            height: 2px;
            width: 100%;
            right: 0;
            bottom: 0;
          }
        }
      }
    }
  }

  .message-modal-tab {
    .ant-tabs-nav {
      margin-bottom: 0 !important;

      .ant-tabs-nav-wrap {
        display: flex;
        border-bottom: #dbdada solid 1px;
        justify-content: center;
      }
    }

    .ant-tabs-top>.ant-tabs-nav::before,
    .ant-tabs-top>div>.ant-tabs-nav::before {
      border: 0;
    }

    .ant-tabs-nav-list {
      border-radius: 0;

      .ant-tabs-tab {
        padding: 0;
        margin: 0;

        .ant-tabs-tab-btn {
          margin: 0px 0px 8px 0px;
          // padding: 0 16px 0 16px;
          padding: 14px 56px;
          font-size: 18px;
          line-height: 16px;
          color: #a7a7a7;
          font-weight: 500 !important;
          // border-left: solid 1px #A7A7A7;
          // height: 15px;

          @media only screen and (max-width:"767px") {
            padding: 17px 62px;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            margin: 0;
          }
        }
      }

      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: #000;
        }
      }

      .ant-tabs-ink-bar {
        height: 2px;
        background: #5f40af;
      }
    }

    // .ant-tabs-content-holder{
    //     .ant-tabs-tabpane-active{
    //         width: 100%;
    //         height: 100%;
    //     }
    // }
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #6366f1;
        border-color: #6366f1;
      }
    }
  }

  .message-all-chat-details-alignment {
    margin: 16px 0 0 0;
    overflow-y: scroll;
    height: 350px;

    // padding: 16px;

    .message-personal-profile-chat-alignment {
      padding: 16px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      justify-content: space-between;
      margin: 0 0 0 0;
      cursor: pointer;

      &:hover {
        background: var(--primary-50);
        border-radius: 10px;
      }

      &.un-read-message {
        .message-personal-profile-alignment {
          .message-personal-profile-person-details-alignment {
            p {
              color: var(--secondary-900);
              font-weight: 600;
            }
          }
        }

        .message-time-alignment {
          .total-message-alignment {
            display: block;
          }
        }
      }

      &:last-child {
        margin: 0;
      }

      .message-personal-profile-alignment {
        display: flex;
        align-items: center;
        gap: 8px;

        .message-personal-profile-img-alignment {
          width: 48px;
          height: 48px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        .message-personal-profile-person-details-alignment {
          h5 {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: var(--secondary-900);
            padding: 0 0 4px 0;
          }

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: var(--secondary-600);
            max-width: 179px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .message-time-alignment {
        .total-message-alignment {
          display: none;
          margin: 0 0 8px 0;

          .total-message-box-alignmant {
            padding: 4px;
            border-radius: 50%;
            background: #d21414;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 13px;
              line-height: 18px;
              color: #ffffff;
            }
          }
        }

        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: #92929d;
        }
      }
    }
  }

  .select-account-modal-alignment {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(30, 36, 50, 0.8);
    border-radius: 10px;
    z-index: 9999;
    padding: 16px;

    .select-account-white-box-alignment {
      background: #ffffff;
      border-radius: 6px;
      height: 553px;
      width: 100%;
      overflow-y: auto;

      .select-account-sticky {
        position: sticky;
        top: 0;
        background: #ffffff;

        .select-account-heading-alignment {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px;
          border-bottom: 1px solid #f1f1f5;

          h4 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 20px;
            line-height: 20px;
            color: var(--secondary-900);
          }

          .header-close-alignment {
            width: 26px;
            height: 26px;
            background: #acb1c0;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }

        .select-search-alignment {
          padding: 16px;

          .select-account-search-alignment {
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;

            input {
              background: transparent;
              border: none;
              width: 100%;
              height: 100%;
              padding: 0 12px 0 0;
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;

              &::placeholder {
                color: #acb1c0;
              }
            }
          }
        }
      }

      .select-acount-modal-body-alignment {
        padding: 0 16px;

        .account-details-alignment {
          padding: 6px 16px;
          display: flex;
          align-items: center;
          gap: 8px;
          cursor: pointer;
          margin: 0 0 4px 0;

          &:last-child {
            margin: 0;
          }

          &:hover {
            background: var(--primary-50);
            border-radius: 10px;
          }

          .account-profile-img-alignment {
            width: 32px;
            height: 32px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              object-fit: cover;
            }
          }

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: var(--secondary-900);
          }
        }
      }
    }
  }

  .group-select-account-modal-alignment {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(30, 36, 50, 0.8);
    border-radius: 10px;
    z-index: 9999;
    padding: 16px;

    .group-select-account-white-box-alignment {
      background: #ffffff;
      border-radius: 6px;
      height: 702px;
      width: 100%;
      overflow-y: auto;

      .group-select-account-sticky {
        position: sticky;
        top: 0;
        background: #ffffff;

        .group-select-account-heading-alignment {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px;
          border-bottom: 1px solid #f1f1f5;

          h4 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 20px;
            line-height: 20px;
            color: var(--secondary-900);
          }

          .group-header-close-alignment {
            width: 26px;
            height: 26px;
            background: #acb1c0;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }

        .group-profile-tag-alignment {
          padding: 32px 16px 0 16px;
          display: flex;
          align-items: center;
          gap: 16px;

          .group-message-profile-img-box-alignment-sidebar {
            width: 46px;
            min-width: 46px;
            height: 48px;
            min-height: 48px;
            position: relative;

            .group-message-flex-alignment-0 {
              width: 24px;
              height: 24px;
              min-width: 24px;

              .group-message-profile-img-alignment-0 {
                width: 24px;
                height: 24px;
                min-width: 24px;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  object-fit: cover;
                }
              }
            }

            .group-message-flex-alignment-1 {
              display: flex;
              justify-content: flex-end;
              margin: -12px 0 0 0;

              .group-message-profile-img-alignment-1 {
                width: 16px;
                height: 16px;
                min-width: 16px;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  object-fit: cover;
                }
              }
            }

            .group-message-flex-alignment-2 {
              display: flex;
              justify-content: center;

              .group-message-profile-img-alignment-2 {
                width: 20px;
                height: 20px;
                min-width: 20px;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  object-fit: cover;
                }
              }
            }

            .group-message-first-dot-alignment {
              position: absolute;
              top: 4px;
              right: 13px;

              .first-dot-width-alignment {
                width: 5px;
                height: 5px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }

            .group-message-second-dot-alignment {
              position: absolute;
              left: 1px;
              bottom: 15px;

              .second-dot-width-alignment {
                width: 6px;
                height: 6px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }

            .group-message-third-dot-alignment {
              position: absolute;
              right: 9px;
              bottom: 15px;

              .third-dot-width-alignment {
                width: 2px;
                height: 2px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }

          .group-tag-box-alignment {
            background: #eceef4;
            border-radius: 10px;
            width: 100%;
            height: 44px;
            padding: 0 16px;
            display: flex;
            align-items: center;

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 18px;
              line-height: 27px;
              color: var(--secondary-900);
            }
          }
        }

        .group-details-main-header-alignment {
          padding: 32px 16px 0 16px;

          .group-main-text-alignment {
            display: flex;
            align-items: center;
            gap: 4px;

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 18px;
              line-height: 20px;
              color: var(--secondary-900);
            }

            .text-box-number-alignment {
              padding: 0 8px;
              background: #eceef4;
              border-radius: 10px;

              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 12px;
                line-height: 20px;
                color: var(--secondary-500);
              }
            }
          }

          .group-select-account-search-alignment {
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            margin: 16px 0 0 0;

            input {
              background: transparent;
              border: none;
              width: 100%;
              height: 100%;
              padding: 0 12px 0 0;
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;

              &::placeholder {
                color: #acb1c0;
              }
            }
          }
        }
      }

      .group-details-alignment {
        padding: 24px 16px;
        height: 390px;
        overflow-y: auto;

        .group-details-main-alignment {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 6px 16px;
          margin: 0 0 4px 0;
          transition: 0.4s ease-in-out;
          cursor: pointer;

          &:last-child {
            margin: 0;
          }

          &:hover {
            background: var(--primary-50);
            border-radius: 10px;
            transition: 0.4s ease-in-out;
          }

          &.group-member-select {
            background: var(--primary-50);
            border-radius: 10px;
            transition: 0.4s ease-in-out;
          }

          .group-peronal-details-alignment {
            display: flex;
            align-items: center;
            gap: 8px;

            .group-personal-profile-img {
              width: 32px;
              max-width: 32px;
              min-width: 32px;
              height: 32px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                display: block;
                cursor: pointer;
                object-fit: cover;
              }
            }

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
              color: var(--secondary-900);
            }
          }

          .group-select-input-alignment {
            input[type='checkbox'] {
              width: 20px;
              height: 20px;
              display: block;
            }
          }
        }
      }

      .group-footer-button-alignment {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 24px;
        padding: 16px;

        .group-cancle-button-alignment {
          button {
            background: transparent;
            border: none;
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            color: var(--primary-500);
          }
        }

        .create-button-alignment {
          button {
            padding: 6px 16px;
            background: var(--primary-500);
            border-radius: 8px;
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            color: #ffffff;
            cursor: pointer;
          }
        }
      }
    }
  }

  .your-invited-section-alignment {
    margin-top: 10px;
    border: 1px solid #cdced4;
    padding: 16px 16px 10px 16px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    .your-invited-heading-alignment {
      display: flex;
      align-items: center;
      justify-content: space-between;


      .your-invited-heading-name-flex-alignment {
        display: flex;
        align-items: flex-start;
        gap: 4px;

        h6 {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: var(--secondary-900);
        }

        .invited-number-alignment {
          padding: 2px 8px;
          background: #F40104;
          border-radius: 10px;
          border-radius: 50%;
          margin-left: 4px;

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: #ffffff;
          }
        }
      }

      .your-invited-details-arrow {
        cursor: pointer;

        .bottom-rotet-arrow {
          // img {
          transform: rotate(180deg);
          transition: 0.3s ease-in-out;
          // }
        }

        .bottom-rotet-arrow-none {
          // img {
          transform: rotate(0deg);
          transition: 0.3s ease-in-out;
          // }
        }
      }
    }
  }
}

// width: 100% !important;

// .ant-tabs-nav {
//     margin-bottom: 20px !important;

//     .ant-tabs-nav-wrap {
//         display: flex;
//         border-bottom: #dbdada solid 1px;
//         // width: 100% !important;
//     }
// }

// .ant-tabs-top>.ant-tabs-nav::before,
// .ant-tabs-top>div>.ant-tabs-nav::before {
//     border: 0;
// }

// .ant-tabs-nav-list {
//     border-radius: 0;
//     width: 100% !important;

//     .ant-tabs-tab {
//         padding: 0;
//         margin: 0;
//         width: 100% !important;

//         .ant-tabs-tab-btn {
//             margin: 0px 0px 8px 0px;
//             padding: 18px 0;
//             font-family: 'Poppins', sans-serif;
//             font-weight: 600;
//             font-size: 18px;
//             line-height: 20px;
//             color: #a7a7a7;
//             font-weight: 500 !important;
//             // border-left: solid 1px #A7A7A7;
//             // height: 15px;
//             text-align: center;
//             width: 100% !important;
//             color: var(--secondary-600);
//         }
//     }

//     .ant-tabs-tab-active {
//         .ant-tabs-tab-btn {
//             color: var(--primary-700);
//         }
//     }

//     .ant-tabs-ink-bar {
//         height: 2px;
//         background: #5f40af;
//         width: 50% !important;
//         left: 0 !important;
//     }
// }

// // .ant-tabs-content-holder{
// //     .ant-tabs-tabpane-active{
// //         width: 100%;
// //         height: 100%;
// //     }
// // }
// .ant-checkbox-checked {
//     .ant-checkbox-inner {
//         background-color: #6366f1;
//         border-color: #6366f1;
//     }
// }

.your-invited-user-details-alignment {
  // max-height: 84px;
  // overflow-y: hidden;
  // transition: 0.3s ease-in-out;

  padding: 0 0 16px 0;
}

.your-invited-user-all-details-alignment {
  // max-height: ;
  // transition: 0.3s ease-in-out;
  max-height: 270px;
  overflow-y: auto;
  transition: 0.1s ease-in-out;
  z-index: 9;
}

.your-invited-user-all-details-alignment-hidden {
  max-height: 0px;
  overflow-y: hidden;
  transition: 0.1s ease-in-out;
}

.message-mobile-view-bg {

  @media only screen and (max-width:"767px") {
    background: #FFFFFF;
    border-radius: 0px 0px 16px 16px;
    margin: 0 0 4px 0;
  }

  .message-back-more-option-alignment {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 8px 16px 0 16px;
    display: none;

    @media only screen and (max-width:"767px") {
      display: flex;
    }
  }
}


.mobile-view-chat-details-alignment {
  @media only screen and (max-width:"767px") {
    background: #FFFFFF;
    border-radius: 16px 16px 0 0;
    // height: calc(100vh - 170px - 64px - 56px);
    // height: calc(100vh - 312px);
    // overflow-y: auto;
  }
}