.mobileViewWithdrawDetailsDetaModalSection {
    height: 100%;

    .mobileViewWithdrawDetailsDetaModalHeading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 16px 14px 16px;
        border-bottom: 1px solid #EAECEF;
        position: sticky;
        top: 0;
        background: #FFF;
        z-index: 999;

        h4 {
            color: #495057;
            font-size: 18px;
            line-height: 27px;
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
        }

        a {
            color: var(--primary-500);
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            font-family: 'Poppins', sans-serif;
        }
    }

    .mobileViewWithdrawDetailsDetaModalDetails {
        padding: 16px;
        // height: calc(100vh - 120px);
        // overflow-y: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 94px;

        .mobileVIewWithdrawDetailsDataBox {
            border-radius: 16px;
            background: var(--secondary-50);
            padding: 16px;

            .mainMobileViewBoxDetails {
                display: flex;
                align-items: flex-start;
                gap: 48px;
                padding: 16px 16px 24px 16px;

                .imgAlignment {
                    width: 82px;
                    height: auto;

                    img {
                        width: 100%;
                        height: 100%;
                        display: block;
                    }
                }

                p {
                    color: var(--secondary-500);
                    font-size: 12px;
                    font-family: 'Poppins', sans-serif;
                    line-height: 18px;
                    font-weight: 500;
                    padding: 0 0 0 11px;
                }

                h4 {
                    color: var(--secondary-900);
                    font-size: 32px;
                    font-family: 'Poppins', sans-serif;
                    line-height: 48px;
                    font-weight: 600;
                    padding: 4px 0 0 0;
                }
            }

            .mobilrViewWhiteBoxAlignment {
                border-radius: 10px;
                background: #FFF;
                padding: 16px;

                .mobileViewChildDetailsAlignment {
                    margin: 0 0 16px 0;

                    &:last-child {
                        margin: 0;
                    }

                    span {
                        color: var(--secondary-500);
                        font-size: 12px;
                        line-height: 14px;
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                    }

                    p {
                        padding: 6px 0 0 0;
                        color: var(--secondary-900);
                        font-size: 16px;
                        line-height: 24px;
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                    }
                }
            }

            .mobileViewWithdrawNotesBox {
                margin: 8px 0 0 0;
                border-radius: 10px;
                background: #FFF;
                padding: 16px;

                span {
                    color: var(--secondary-500);
                    font-size: 12px;
                    line-height: 14px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                }

                .mobileViewNotesAlignment {
                    margin: 6px 0 0 0;

                    textarea {
                        width: 100%;
                        height: 95px;
                        border: none;
                        font-size: 16px;
                        line-height: 24px;
                        font-weight: 400;
                        font-family: 'Poppins', sans-serif;
                        background: transparent;
                        resize: none;
                        color: var(--secondary-900);

                        &::placeholder {

                           color: var(--secondary-300);
                        }
                    }
                }
            }
        }

        .mobileVIewWithdrawDetailsDetaButton {
            padding: 58px 0 0 0;

            button {
                border-radius: 10px;
                background: var( --primary-50);
                height: 44px;
                width: 100%;
                color: var(--primary-500);
                text-align: center;
                font-size: 16px;
                line-height: 24px;
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
            }
        }

    }

    .mobileWithDrawButtonAlignment {
        display: grid;
        align-items: center;
        grid-template-columns: repeat(2, 1fr);
        gap: 9px;
        width: 100%;
        // position: fixed;
        // bottom: 12px;
        padding: 16px 20px;
        background: #FFF;

        .backButtonAlignment {
            button {
                width: 100%;
                height: 44px;
                color: var(--primary-500);
                text-align: center;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                cursor: pointer;
            }
        }

        .withdrawButtonAlignment {
            button {
                width: 100%;
                height: 44px;
                color: #FFF;
                text-align: center;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                cursor: pointer;
                border-radius: 10px;
                background: var(--primary-500);
            }
        }
    }
}