.onboarding-all-contnet-alignment {
  @media only screen and (max-width: '767px') {
    padding: 16px;
  }

  .slick-dots {
    top: inherit !important;
    bottom: -82px !important;
    left: 0 !important;
    transform: translateX(0%) !important;
    right: 0 !important;
    justify-content: center !important;

    @media screen and (max-width: 767px) {
      bottom: -30px !important;
    }
  }

  .cus-container {
    max-width: 980px;
    padding: 0;
    margin: 0 auto;

    .header-section-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        display: none;
      }

      @media screen and (max-width: 767px) {
        h3 {
          color: var(--primary-500);
          font-weight: 500;
          cursor: pointer;
          display: block;
        }
      }
    }

    .onboarding-box-alignment {
      border-radius: 32px;
      border: 4px solid var(--neutral-white, #fff);
      background: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(8.5px);
      // height: calc(100vh - 306px);
      height: 700px;

      @media (max-width:1500px) {
        height: calc(100vh - 200px);
      }

      @media only screen and (max-width: '767px') {
        background: transparent;
        border: none;
        border-radius: 0;
        backdrop-filter: none;
        height: 100%;
      }
    }
  }

  .onboarding-logo {
    padding: 56px 0;

    @media screen and (max-width: 767px) {
      padding: 8px 16px;
      display: none;
    }

    img {
      cursor: pointer;
    }
  }

  .mobile-view-onboarding-logo {
    display: none;

    @media screen and (max-width: 767px) {
      display: block;
    }
  }

  .same-class-card {
    // height: calc(100vh - 145px - 147px);
    height: 100%;

    @media screen and (max-width: 767px) {
      height: calc(100vh - -34px - 54px - 147px);
    }
  }

  .people-might-section-all-content-alignment {
    @extend .same-class-card;

    .store-recommendation-section-box-center-alignment {
      display: flex;
      justify-content: center;
      // height: calc(100vh - 449px);
      // overflow: auto;

      // @media screen and (max-width: 767px) {
      //   height: calc(100vh - 280px);
      //   display: block;
      // }

      .store-recommendation-box {
        width: 100%;
        padding: 0 50px 0 108px;
        height: calc(100vh - 422px);
        overflow-y: auto;

        @media screen and (max-width: 767px) {
          width: 100%;
          padding: 0;
          height: 100%;
        }

        .store-recommendation-grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 22px;

          @media screen and (max-width: 767px) {
            grid-template-columns: repeat(2, 1fr);
            gap: 16px;
          }

          .store-recommendation-grid-items {
            background: #ffffff;
            border: 1px solid #eceef4;
            border-radius: 10px;

            .store-image {
              img {
                width: 100%;
                height: 145px;
                border-radius: 10px;
                object-fit: cover;
                object-position: center;

                @media screen and (max-width: 767px) {
                  height: 100px;
                }
              }
            }

            .store-image-style-change {
              img {
                width: 100%;
                height: 65px;
                border-radius: 10px 10px 0px 0px;
                object-fit: cover;

                @media screen and (max-width: 767px) {
                  height: 50px;
                }
              }
            }

            .store-details-footer {
              background: #eceef4;
              border: 1px solid #eceef4;
              border-radius: 0px 0px 10px 10px;
              padding: 12px;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              color: var(--primary-500);
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid #eceef4;
              cursor: pointer;

              @media screen and (max-width: 767px) {
                padding: 5px 12px;
              }
            }

            .store-details-footer-follow {
              background: var(--primary-500);
              border: 1px solid #eceef4;
              border-radius: 0px 0px 10px 10px;
              padding: 12px;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              color: #eceef4;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid #eceef4;
              cursor: pointer;

              @media screen and (max-width: 767px) {
                padding: 5px 12px;
              }
            }

            .store-details-content {
              padding: 11px;

              h5 {
                font-weight: 500;
                font-size: 15px;
                line-height: 27px;
                color: #000;
                text-align: center;
                margin: 0 0 2px 0;

                @media screen and (max-width: 767px) {
                  font-size: 15px;
                  line-height: 24px;
                }
              }

              span {
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #808191;
                display: block;
                text-align: center;

                @media screen and (max-width: 767px) {
                  font-size: 12px;
                  line-height: 18px;
                }
              }

              p {
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                color: #808191;
                text-align: center;
                margin: 0 0 2px 0;

                @media screen and (max-width: 767px) {
                  font-size: 12px;
                  line-height: 18px;
                }
              }
            }
          }
        }

        .items-text {
          padding: 0 0 18px 0;
          // position: sticky;
          // top: 0;
          // left: 0;
          // z-index: 999;

          @media screen and (max-width: 767px) {
            padding: 16px 0 10px 0;
          }

          span {
            font-weight: 500;
            font-size: 16px;
            line-height: 120%;
            color: #808191;

            &.club-child-heading {
              color: var(--secondary-900);
            }

            @media screen and (max-width: 767px) {
              font-weight: 600;
              font-size: 12px;
              line-height: 18px;
            }
          }
        }

        .store-recommendation-profile-body-alignment {
          padding: 0 55px 0 0;

          @media screen and (max-width: 767px) {
            padding: 0;
            height: calc(100vh - 280px);
            overflow-y: auto;
          }

          .store-recommendation-profile-grid-alignment {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 16px;

            @media screen and (max-width: 767px) {
              grid-template-columns: 1fr;
            }

            .store-recommendation-profile-grid-item-alignment {
              border-radius: 16px;
              position: relative;
              overflow: hidden;
              transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
              height: 251px;
              max-height: 251px;

              // @media only screen and (max-width:"999px") {
              //   height: 186px;
              //   max-height: 186px;
              // }

              // @media only screen and (max-width: '599px') {
              //   height: 230px;
              //   max-height: 230px;
              // }

              &:hover {
                transform: translateY(-5px);

                .store-recommendation-cardImage {
                  img {
                    transform: scale(1.05);
                  }
                }
              }

              .store-recommendation-cardImage {
                border-radius: 16px;
                height: 251px;
                max-height: 251px;

                // @media only screen and (max-width:"999px") {
                //   height: 186px;
                //   max-height: 186px;
                // }

                @media only screen and (max-width: '599px') {
                  height: 230px;
                  max-height: 230px;
                  border-radius: 16px 16px 0 0;
                }

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                }
              }

              .store-recommendation-cardDetails {
                position: absolute;
                bottom: 0;
                left: 0;

                width: 100%;

                .store-recommendation-cardDetailsAlignment {
                  padding: 9px 9px 18px 9px;

                  @media only screen and (max-width: '767px') {
                    padding: 9px 16px 18px 16px;
                  }

                  .store-recommendation-allContentAlignment {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    justify-content: space-between;

                    .store-recommendation-rightSideContent {
                      button {
                        background: rgba(55, 55, 55, 0.78);
                        border-radius: 10px;
                        padding: 0 11px;
                        height: 20px;
                        font-weight: 500;
                        font-size: 12px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        color: #fff;
                        transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

                        &.joinButton {
                          border-radius: 8px;
                          background: var(--primary-500);
                        }
                      }
                    }

                    .store-recommendation-leftSideContent {
                      display: flex;
                      align-items: center;
                      gap: 4px;

                      img {
                        cursor: pointer;
                      }

                      span {
                        display: block;
                        color: #fff;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                      }
                    }

                    .store-recommendation-users {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;

                      .store-recommendation-userIcon {
                        display: flex;
                        align-items: center;
                        gap: 4px;

                        .store-recommendation-eftPart {
                          display: flex;
                          align-items: center;
                          padding-left: 10px;

                          .clubModaUsersLikes {
                            display: flex;
                            align-items: center;
                            margin-left: -10px;

                            .store-recommendation-userBox {
                              width: 20px;
                              display: flex;
                              align-items: center;
                              height: 20px;
                              justify-content: center;
                              background: #fff;
                              object-position: center;
                              border: 1px solid #ffffff;
                              border-radius: 30px;
                              margin: 0 0 0 -10px;

                              &:first-child {
                                margin: 0;
                              }

                              img {
                                width: 100%;
                                height: 100%;
                                border-radius: 50%;
                                object-fit: cover !important;
                              }
                            }
                          }

                          .clubModaCount {
                            margin-left: -10px;
                            border-radius: 12px;
                            border: 1px solid #fff;
                            background: #1e2432;
                            border-radius: 12px;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 18px;
                            letter-spacing: 0.1px;
                            color: #ffffff;
                            padding: 0px 9px;
                          }
                        }
                      }

                      .store-recommendation-rightSideContent {
                        button {
                          border-radius: 8px;
                          opacity: 0.6000000238418579;
                          background: #1e2432;
                          padding: 0 32px;
                          height: 32px;
                          color: #fff;
                          text-align: center;
                          font-size: 14px;
                          font-family: 'Poppins', sans-serif;
                          font-weight: 500;
                          letter-spacing: 0.1px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          cursor: pointer;
                          color: #fff;
                          transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

                          &.joinButton {
                            border-radius: 8px;
                            background: var(--primary-500);
                            opacity: 1;

                            &:hover {
                              transform: translateY(-3px);
                              background: #2844ac;
                            }
                          }
                        }
                      }
                    }
                  }

                  h5 {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: #fff;
                    margin: 0 0 18px 0;
                  }

                  .store-recommendation-limeCamp {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                  }

                  h4 {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #fff;
                    margin: 0 0 1px 0;
                    cursor: pointer;
                  }
                }
              }

              .store-recommendation-blurDiv {
                position: absolute;
                background: linear-gradient(360deg, rgba(30, 36, 50, 0.8) 0%, rgba(30, 36, 50, 0) 100%);
                border-radius: 0px;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 251px;

                @media only screen and (max-width: '599px') {
                  height: 230px;
                  border-radius: 16px 16px 0 0;
                }
              }
            }
          }
        }
      }
    }

    .interst-section-box-center-alignment {
      display: flex;
      justify-content: center;
      height: calc(100vh - 449px);
      overflow: auto;

      @media screen and (max-width: 767px) {
        height: calc(100vh - 280px);
      }

      .interst-section-box {
        width: 685px;

        @media screen and (max-width: 767px) {
          width: 100%;
        }

        .items-text {
          padding: 0 0 18px 0;

          @media screen and (max-width: 767px) {
            padding: 0 0 10px 0;
          }

          span {
            font-weight: 500;
            font-size: 16px;
            line-height: 120%;
            color: #808191;

            @media screen and (max-width: 767px) {
              font-size: 12px;
              line-height: 18px;
            }
          }
        }

        .main-profile-grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 16px;

          @media screen and (max-width: 767px) {
            grid-template-columns: repeat(1, 1fr);
            gap: 8px;
          }

          .main-profile-grid-items {
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;

            .role-box-sent {
              background: #eceef4;
              border: 1px solid #f3f3f3;
              border-radius: 0px 0px 10px 10px;
              padding: 11px;

              span {
                display: block;
                color: var(--primary-500);
                text-align: center;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
              }
            }

            .role-box-request {
              background: var(--primary-500);
              border: 1px solid #f3f3f3;
              border-radius: 0px 0px 10px 10px;
              padding: 11px;

              span {
                display: block;
                color: #eceef4;
                text-align: center;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
              }
            }

            .profile-grid {
              display: grid;
              grid-template-columns: 60px 1fr;
              gap: 15px;
              padding: 16px 16px 12px 16px;

              @media screen and (max-width: 767px) {
                padding: 16px;
              }

              .profile-grid-items {
                .profile-image {
                  img {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    object-fit: cover;
                    object-position: center;
                  }
                }

                p {
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 21px;
                  color: #000;

                  @media screen and (max-width: 767px) {
                    font-size: 16px;
                    line-height: 24px;
                  }
                }

                span {
                  display: block;
                  font-weight: 400;
                  font-size: 10px;
                  line-height: 15px;
                  color: #808191;
                  padding: 0 0 2px 0;

                  @media screen and (max-width: 767px) {
                    font-size: 12px;
                    line-height: 16px;
                  }
                }

                h5 {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 18px;
                  color: #808191;
                }
              }
            }
          }
        }
      }
    }

    .section-title-alignment {
      padding: 50px 0 0 0;

      @media screen and (max-width: 767px) {
        padding: 20px 0 0 0;
      }

      h5 {
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        color: #000;
        margin: 0 0 8px 0;

        @media screen and (max-width: 767px) {
          font-size: 24px;
          line-height: 36px;
          margin: 0;
        }
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #808191;
      }
    }
  }

  .interst-section-all-content-alignment {
    @extend .same-class-card;

    .interst-section-box-center-alignment {
      display: flex;
      justify-content: center;
      height: calc(100vh - 449px);
      overflow: auto;

      @media screen and (max-width: 767px) {
        height: calc(100vh - 319px);
      }

      .interst-section-box {
        width: 570px;

        .items-text {
          padding: 0 0 18px 0;

          @media screen and (max-width: 767px) {
            padding: 0 0 13px 0;
          }

          span {
            font-weight: 500;
            font-size: 16px;
            line-height: 120%;
            color: #808191;

            @media screen and (max-width: 767px) {
              font-size: 12px;
              padding: 0 35px;
              line-height: 18px;
            }
          }
        }

        .image-grid-new {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 16px;
          padding: 0;

          @media screen and (max-width: 767px) {
            padding: 0 35px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
          }

          .image-grid-items {
            position: relative;
            cursor: pointer;

            .image-style {
              img {
                width: 130px;
                height: 130px;
                border-radius: 17px;
                object-fit: cover;
              }
            }

            .image-style-active {
              img {
                width: 130px;
                height: 130px;
                border-radius: 17px;
                object-fit: cover;
                border: 4px solid #6b21a8;
                opacity: 0.5;
              }
            }

            .checkmark-icon {
              position: absolute;
              top: 8px;
              right: 8px;

              img {
                cursor: pointer;
              }
            }

            .image-upper-text {
              position: absolute;
              bottom: 20px;
              left: 0;
              width: 100%;

              span {
                display: block;
                font-weight: 500;
                font-size: 16px;
                text-align: center;
                line-height: 24px;
                color: #fff;
              }
            }
          }
        }
      }
    }

    .section-title-alignment {
      padding: 37px 0 40px 0;

      @media screen and (max-width: 767px) {
        padding: 20px 0;
      }

      h5 {
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        color: #000;
        margin: 0 0 8px 0;

        @media screen and (max-width: 767px) {
          font-size: 24px;
          line-height: 36px;
          margin: 0;
        }
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #808191;
      }
    }
  }

  .profile-all-section-content-alignment {
    @extend .same-class-card;

    .avtar-image-center-alignment {
      display: flex;
      justify-content: center;
      margin: 80px 0 0 0;

      @media screen and (max-width: 767px) {
        margin: 70px 0 0 0;
      }

      .profile-image {
        position: relative;

        .image-style {
          background: #fff;
          border-radius: 50%;

          img {
            width: 172px;
            height: 172px;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        .upload-button {
          display: flex;
          justify-content: center;
          margin-top: -25px;

          button {
            border-radius: 101px;
            background: var(--primary-500);
            width: 80px;
            height: 45px;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: relative;

            input {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              opacity: 0;
            }
          }
        }

        .remove-button {
          display: flex;
          justify-content: center;
          margin-top: -30px;

          button {
            background: #e4e4e8;
            border-radius: 101px;
            width: 80px;
            height: 45px;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: relative;
            border: 4px solid #eceef4;

            input {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              opacity: 0;
            }
          }
        }
      }
    }

    .profile-content-text-style {
      padding: 50px 0 0 0;

      @media screen and (max-width: 767px) {
        padding: 72px 0 0 0;
        max-width: 343px;
        margin: 0 auto;
      }

      h4 {
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        color: var(--secondary-900);
        text-align: center;
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        max-width: 484px;
        margin: 0 auto;

        @media screen and (max-width: 767px) {
          font-size: 24px;
          line-height: 36px;
        }
      }

      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #808191;
      }
    }
  }

  .identidy-all-section-contnet-alignment {
    @extend .same-class-card;

    .identidy-section-container {
      width: 320px;
      margin: 0 auto;
    }

    .all-box-center-alignment {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 120px 0 0 0;

      @media screen and (max-width: 767px) {
        padding: 96px 0 0 0;
      }

      .selected-box-center {
        width: 230px;

        @media screen and (max-width: 767px) {
          width: 163px;
        }

        .icon-text-box:last-child {
          div {
            span {
              padding: 0;
            }
          }
        }

        .icon-text-box {
          padding: 11px 12px;
          border-radius: 10px;
          border: 1px solid var(--secondary-50);
          background: #fff;
          border-radius: 10px;
          display: flex;
          align-items: center;
          margin: 0 0 15px 0;

          cursor: pointer;
          transition: 0.3s ease-in-out;

          span {
            color: var(--grey2);
          }

          &:hover {
            border-radius: 10px;
            border: 1px solid var(--primary-500, #556ee6);
            background: var(--primary-50, #eef1fd);

            div {
              span {
                color: var(--primary-500);
              }
            }
          }

          &.actives {
            border-radius: 10px;
            border: 1px solid var(--primary-500);
            background: var(--primary-50);

            span {
              color: var(--primary-500);
            }
          }

          &.passives {
            background: #fff;

            span {
              color: var(--primary-500);
            }
          }

          div {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;

            span {
              font-weight: 500;
              text-align: center;
              font-size: 14px;
              line-height: 21px;
              // color: #808191;
              font-family: 'Poppins', sans-serif;
              display: block;

              @media screen and (max-width: 767px) {
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
              }
            }
          }
        }
      }
    }

    .page-title {
      padding: 50px 0 0 0;

      @media screen and (max-width: 767px) {
        padding: 72px 0 0 0;
      }

      h3 {
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        color: #000;
        text-align: center;

        @media screen and (max-width: 767px) {
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
          color: var(--secondary-900);
          text-align: center;
        }
      }
    }
  }

  .setup-form-all-content-alignment {
    @extend .same-class-card;

    .setup-form-box {
      width: 512px;
      margin: 0 auto;

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    .input-grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 16px;
      margin: 0 auto;
      width: 310px;
      padding: 120px 0 0 0;

      //       height: calc(100vh - 512px);
      // align-items: center;
      @media screen and (max-width: 767px) {
        width: 100%;
        padding: 96px 16px 0 16px;
      }

      .input-grid-items {
        label {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #808191;
          display: block;
          padding: 0 0 7px 0;

          @media screen and (max-width: 767px) {
            font-size: 12px;
            line-height: 18px;
            padding: 0 0 0 6px;
          }
        }

        input {
          width: 100%;
          height: 38px;
          border: 1px solid #eceef4;
          border-radius: 5px;
          padding: 0 14px;
          background: #ffffff;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #808191;
          transition: 0.3s ease-in-out;

          &:focus {
            outline: none;
            border: 1px solid #556ee6;
            box-shadow: 0px 4px 20px rgba(53, 97, 183, 0.14);
            transition: 0.3s ease-in-out;
          }

          @media screen and (max-width: 767px) {
            height: 40px;
            border-radius: 10px;
            font-weight: 400;
          }
        }

        ::placeholder {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #808191;
        }
      }
    }

    .text-style {
      padding: 50px 0 0 0;

      @media screen and (max-width: 767px) {
        padding: 72px 0 0 0;
      }

      h2 {
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        color: #000;
        margin: 0 0 5px 0;

        @media screen and (max-width: 767px) {
          font-size: 24px;
          line-height: 36px;
          margin: 0 auto 0 auto;
          max-width: 284px;
          color: var(--secondary-900);
        }
      }

      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #808191;
      }
    }
  }

  .welcome-page-all-content-alignment {
    @extend .same-class-card;
    display: flex;
    align-items: center;
    height: 100%;

    @media screen and (max-width: 767px) {
      display: block;
      padding: 0;
    }

    .welcome-page-container {
      width: 685px;
      margin: 40px auto 0 auto;

      @media screen and (max-width: 767px) {
        width: 100%;
        padding: 0;
        margin: 52px auto 0 auto;
      }

      .grid {
        // display: grid;
        // grid-template-columns: 302px 1fr;
        // gap: 229px;
        align-items: center;

        @media screen and (max-width: 767px) {
          grid-template-columns: repeat(1, 1fr);
          gap: 21px;
        }

        .grid-items:nth-child(1) {
          @media screen and (max-width: 767px) {
            order: 1;
          }
        }

        .grid-items:nth-child(2) {
          @media screen and (max-width: 767px) {
            order: 2;
          }
        }

        .hand-image {
          display: flex;
          justify-content: center;
          align-items: center;

          @media screen and (max-width: 767px) {
            display: flex;
            justify-content: center;
            display: none;
          }

          img {
            @media screen and (max-width: 767px) {
              max-width: 98px;
            }
          }
        }

        .mobile-hand-image {
          display: none;

          @media screen and (max-width: 767px) {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .grid-items {
          h1 {
            background: linear-gradient(97.66deg, #556ee6 0.37%, #6b21a8 70.13%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            margin: 42px 0 20px 0;
            font-weight: 600;
            font-size: 32px;
            line-height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;

            @media screen and (max-width: 767px) {
              text-align: center;
              max-width: 252px;
              margin: 0 auto;
            }
          }

          p {
            color: var(--secondary-900);
            text-align: center;
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            max-width: 420px;
            margin: 0 auto;

            @media screen and (max-width: 767px) {
              max-width: 308px;
              text-align: center;
              margin: 0 auto;
              padding: 66px 0 0 0;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.back-button-slider-arrow-design {
  position: absolute;
  bottom: 40px;
  left: 125px;
  z-index: 999999;

  @media screen and (max-width: 767px) {
    left: 16px;
    bottom: 40px;
    position: static;
  }

  button {
    padding: 4px 34px;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary-500);
    border-radius: 8px;
    background: var(--primary-50);

    @media screen and (max-width: 767px) {
      padding: 10px 34px;
    }
  }
}

.skip-button-slider-arrow-design {
  position: absolute;
  bottom: 45px;
  right: 281px;
  z-index: 999999;

  @media screen and (max-width: 767px) {
    left: 16px;
  }

  button {
    color: var(--primary-500);
    font-weight: 500;
    cursor: pointer;
  }
}

body .slick-dots {
  @media screen and (max-width: 767px) {
    width: 100% !important;
  }
}

.next-button-slider-arrow-design {
  position: absolute;
  bottom: 40px;
  right: 125px;
  z-index: 999999;

  @media screen and (max-width: 767px) {
    right: 16px;
    bottom: 40px;
    position: static;
    left: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  button {
    padding: 4px 34px;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    border-radius: 8px;
    background: var(--primary-500, #556ee6);
    color: #fff;
    border: none;

    @media screen and (max-width: 767px) {
      right: 16px;
      bottom: 40px;
      width: 100%;
      left: 16px;
      padding: 10px 34px;
    }
  }
}

.button-inactive {
  button {
    background: #6b21a8;
    opacity: 0.5;

    @media screen and (max-width: 767px) {
      width: 215px;
      padding: 10px 34px;
    }
  }
}

.slider-hero-section-banner {
  height: calc(100vh - 0px);
  background-image: url('/assets/img/onboading-bg-img.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  z-index: -1;
  background-position: center center;

  @media only screen and (max-width: '767px') {
    background: #eceef4;
  }
}

.onboard-detail {
  width: 365px;
  height: 109px;
  left: 74px;
  top: 466px;
  background: #5f40af;
  border-radius: 10px;
  margin-bottom: 21px;
  padding: 14px;

  .complete-deatil {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .onboarding-box {
      display: flex;
      column-gap: 8px;

      span {
        width: 35.73px;
        height: 3px;
        background: #ff7d23;
      }
    }

    h2 {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #f8f8f8;
    }

    margin-bottom: 20px;
  }

  .complete-deatil-question {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #ffffff;
    }

    button {
      padding: 4px 11px;
      cursor: pointer;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      background: #fff;
      color: var(--primary-500);
      border: none;
      border-radius: 10px;
    }
  }
}

// .upload-icon {
//   position: absolute;
//   width: 47px;
//   height: 45px;
//   z-index: 9999;
//   background-color: red;
//   top: 63%;
//   right: 1%;
//   background: #d9d9d9;
//   border-radius: 10px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   img {
//     width: 24px;
//     height: 24px;
//   }
//   input {
//     width: 100%;
//     height: 100%;
//     position: absolute;
//     top: 0;
//     left: 0;
//     opacity: 0;
//   }
// }

.onboard-detail-align {
  width: 100%;
  height: 71px;
  margin-bottom: 20px;
  background: #5f40af;
  border-radius: 10px;
  padding: 19px 40px;
  align-items: center;

  .onbording-process {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #ffffff;
    }

    button {
      padding: 4px 11px;
      cursor: pointer;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      background: #fff;
      color: var(--primary-500);
      border: none;
      border-radius: 10px;
    }
  }
}

.processbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height */

    /* #495057 */

    color: #495057;
  }
}

.sticky-section-bar {
  position: sticky;
  top: 80px;
}

.mobile-view-skip-none {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.link-size-video {
  a {
    word-break: break-all;
    // padding: 0px 10px;
  }
}

.store-recommendation-section {
  padding: 40px 50px 0 125px;

  @media screen and (max-width: 767px) {
    padding: 16px 0 0 0;
  }

  p {
    color: var(--secondary-900);
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    padding-bottom: 10px;

    @media screen and (max-width: 767px) {
      font-size: 12px;
      padding: 0 0 16px 0;
    }
  }

  .store-recommendation-body-details-alignment {
    padding: 0 128px 0 56px;
    height: calc(100vh - 587px);
    overflow-y: auto;

    @media screen and (max-width: 767px) {
      padding: 0;
      height: calc(100vh - 280px);
    }

    ::-webkit-scrollbar {
      width: 4px !important;
    }

    ::-webkit-scrollbar-track {
      background: transparent !important;
    }

    ::-webkit-scrollbar-thumb {
      background: red !important;
      border-radius: 18px !important;
    }

    .store-recommendation-grid-alignment {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;

      @media screen and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 8px;
      }

      .store-recommendation-grid-item {
        .store-recommendation-child-box-alignment {
          width: 100%;
          border-radius: 10px;
          background: #fff;
          padding: 8px;
          display: flex;
          align-items: center;
          gap: 12px;
          margin: 0 0 8px 0;

          &:last-child {
            margin: 0;
          }

          .store-recommendation-img-alignment {
            width: 84px;
            height: 84px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 6px;
              object-fit: cover;
            }
          }

          .store-recommendation-profile-details {
            h6 {
              color: var(--secondary-900);
              font-family: 'Poppins', sans-serif;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              cursor: pointer;
            }

            .store-recommendation-flex-alignment {
              display: flex;
              align-items: center;
              gap: 4px;

              span {
                color: var(--secondary-500);
                font-family: 'Poppins', sans-serif;
                font-size: 11px;
                font-weight: 400;
                line-height: 14px;
              }

              p {
                width: 2px;
                height: 2px;
                border-radius: 50%;
                background: #7a7e88;
              }
            }

            .store-recommendation-follow-details {
              padding: 5px 0 0 0;

              a {
                color: var(--primary-500);
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-weight: 500;
                line-height: 19px;
                cursor: pointer;
                padding: 6px 0;
                display: block;
              }
            }

            .store-recommendation-unfollow-details {
              padding: 5px 0 0 0;
              display: flex;
              // justify-content: center;
              align-items: center;
              gap: 6px;

              .feed-new-followed-store-alignment {
                padding: 5px 0 0 0;

                a {
                  display: flex;
                  align-items: center;
                  gap: 5px;
                  color: var(--secondary-500);
                  font-family: 'Poppins', sans-serif;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 18px;
                  padding: 6px 0;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }

      .feed-new-suggested-body-details-alignment {
        margin: 16px 0 0 0;
        height: auto;
        max-height: 316px;
        overflow-y: auto;

        .feed-new-suggested-child-box-alignment {
          width: 100%;
          border-radius: 10px;
          background: #fff;
          padding: 8px;
          display: flex;
          align-items: center;
          gap: 12px;
          margin: 0 0 8px 0;

          &:last-child {
            margin: 0;
          }

          .feed-new-suggested-img-alignment {
            width: 84px;
            height: 84px;
            display: flex;
            justify-content: center;
            align-items: center;

            .new-suggested-img-alignment-img {
              position: absolute;
              border-radius: 8px;
            }
          }

          .feed-new-suggested-profile-details {
            h6 {
              color: var(--secondary-900);
              font-family: 'Poppins', sans-serif;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              cursor: pointer;
              transition: 0.4s ease-in-out;

              &:hover {
                text-decoration: underline;
                transition: 0.4s ease-in-out;
              }
            }

            .feed-new-suggested-flex-alignment {
              display: flex;
              align-items: center;
              gap: 4px;

              span {
                color: var(--secondary-500);
                font-family: 'Poppins', sans-serif;
                font-size: 11px;
                font-weight: 400;
                line-height: 14px;
              }

              p {
                width: 2px;
                height: 2px;
                border-radius: 50%;
                background: #7a7e88;
              }
            }

            .feed-new-suggested-follow-details {
              padding: 5px 0 0 0;

              a {
                color: var(--primary-500);
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-weight: 500;
                line-height: 19px;
                cursor: pointer;
                padding: 6px 0;
                display: block;
              }
            }

            .feed-new-suggested-unfollow-details {
              padding: 5px 0 0 0;
              display: flex;
              // justify-content: center;
              align-items: center;
              gap: 6px;

              .feed-new-followed-store-alignment {
                padding: 5px 0 0 0;

                a {
                  display: flex;
                  align-items: center;
                  gap: 5px;
                  color: var(--secondary-500);
                  font-family: 'Poppins', sans-serif;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 18px;
                  padding: 6px 0;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
}

.onbording-mobile-view-flex-alignment {
  @media only screen and (max-width: '767px') {
    position: fixed;
    width: auto;
    left: 16px;
    right: 16px;
    bottom: 40px;
    z-index: 9999;

    display: flex;
    align-items: center;
    gap: 16px;
  }
}
