.gridContainer {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.imageGrid {
  display: grid;
  gap: 8px;
  width: 100%;
  box-sizing: border-box;
}

.imageGrid1 {
  grid-template-columns: 1fr;
}

.imageGrid2 {
  grid-template-columns: 1fr 1fr;
}

.imageGrid3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.imageGrid4 {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.imageGrid5 {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 8px;
  width: 100%;
  aspect-ratio: 16/9;
  min-height: 400px;
}

.imageGrid5 > div:nth-child(1) {
  grid-column: 1;
  grid-row: 1 / span 2;
  min-height: 400px;
}

.imageGrid5 > div:nth-child(2) {
  grid-column: 2;
  grid-row: 1;
  min-height: 200px;
}

.imageGrid5 > div:nth-child(3) {
  grid-column: 3;
  grid-row: 1;
  min-height: 200px;
}

.imageGrid5 > div:nth-child(4) {
  grid-column: 2;
  grid-row: 2;
  min-height: 200px;
}

.imageGrid5 > div:nth-child(5) {
  grid-column: 3;
  grid-row: 2;
  position: relative;
  min-height: 200px;
}

.imageItem {
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 200px;
  overflow: hidden;
  border-radius: 8px;
}

.imageItem img, 
.imageItem video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.imageWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.imageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
  font-weight: bold;
  border-radius: 8px;
}

.imageOverlay span {
  font-size: 32px;
.imageGrid {
  display: grid !important;
  gap: 8px !important;
  width: 100% !important;
  max-width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box !important;
}

.imageGrid1 {
  grid-template-columns: 1fr !important;
}

.imageGrid2 {
  grid-template-columns: 1fr 1fr !important;
}

.imageGrid3 {
  grid-template-columns: 1fr 1fr 1fr !important;
}

.imageGrid4 {
  grid-template-columns: 1fr 1fr !important;
  grid-template-rows: 1fr 1fr !important;
}

.imageGrid5 {
  display: grid !important;
  gap: 8px !important;
  width: 100% !important;
  max-width: 100% !important;
  grid-template-columns: repeat(12, 1fr) !important;
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box !important;
}

.imageGrid5 > div:first-child {
  grid-column: span 6 !important;
  grid-row: span 2 !important;
  height: 100% !important;
}

.imageGrid5 > div:nth-child(2),
.imageGrid5 > div:nth-child(3) {
  grid-column: span 3 !important;
}

.imageGrid5 > div:nth-child(4) {
  grid-column: span 3 !important;
}

.imageGrid5 > div:nth-child(5) {
  grid-column: span 6 !important;
  height: 100% !important;
}

.imageItem {
  width: 100% !important;
  height: 100% !important;
  position: relative !important;
  min-height: 200px !important;
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box !important;
}

.imageItem img, 
.imageItem video {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  border-radius: 8px !important;
}
}
