.art-craft-shop-by-catrgory-section {
    margin: 58px 0 0 0;
    position: relative;

    @media only screen and (max-width:"768px") {
        margin: 32px 0 0 0;
    }

    .art-craft-shop-by-catrgory-bg-alignment {
        background: #F8F8F8;
        border-radius: 16px;
        padding: 48px 58px;

        @media only screen and (max-width:"768px") {
            padding: 32px 0 0 16px;
        }

        .art-craft-shop-by-category-heading {
            @media only screen and (max-width:"768px") {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 16px 0 0;
            }

            h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 36px;
                line-height: 54px;
                color: var(--secondary-900);

                @media only screen and (max-width:"768px") {
                    font-size: 20px;
                    line-height: 30px;
                }

            }

            .see-all-alignment {
                display: none;

                @media only screen and (max-width:"768px") {
                    display: block;
                }

                a {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: var(--primary-500);
                }
            }
        }

        .art-craft-shop-by-category-details-alignment {
            padding: 48px 0 0 0;
            width: 100%;
            overflow-x: hidden;

            @media only screen and (max-width:"768px") {
                display: none;
            }

            .art-craft-shop-by-category-grid-alignment {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                gap: 40px;
                position: relative;
                z-index: 999;

                @media only screen and (max-width:"968px") {
                    gap: 10px;
                }


                .art-craft-shop-by-category-gridItem-alignment {

                    &:hover {
                        .art-craft-shop-by-category-img-alignment {
                            transform: rotate(-2deg);

                            img {
                                transform: scale(1.07);
                            }

                        }

                        p {
                            font-size: 20px;
                            line-height: 30px;

                            @media only screen and (max-width:"768px") {
                                font-size: 12px;
                                line-height: 18px;
                            }
                        }
                    }

                    .art-craft-shop-by-category-img-alignment {
                        width: 100%;
                        height: 200px;
                        transition: 0.2s ease-in-out;
                        cursor: pointer;
                        padding: 0px 23px;

                        @media only screen and (max-width:"1099px") {
                            height: 150px;
                            padding: 0;
                        }

                        @media only screen and (max-width:"768px") {
                            width: 98px;
                            min-width: 98px;
                            max-width: 98px;
                            height: 98px;
                            padding: 0;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 10px;
                            object-fit: cover;
                        }
                    }


                    p {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 30px;
                        color: #000000;
                        text-align: center;
                        padding: 26px 0 0 0;
                        cursor: pointer;

                        @media only screen and (max-width:"768px") {
                            font-size: 12px;
                            line-height: 18px;
                            padding: 8px 0 0 0;
                        }
                    }
                }
            }
        }

        .mobile-view-art-craft-shop-by-category-details-alignment {
            display: none;

            @media only screen and (max-width:"768px") {
                display: block;
                padding: 32px 0 0 0;
            }

            .art-craft-shop-by-category-gridItem-alignment {
                padding: 0 8px;

                @media only screen and (max-width:"967px") {
                    margin: 20px 0;
                }

                &:hover {
                    .art-craft-shop-by-category-img-alignment {
                        transform: rotate(-2deg);

                        img {
                            transform: scale(1.07);
                        }

                    }

                    p {
                        font-size: 20px;
                        line-height: 30px;

                        @media only screen and (max-width:"768px") {
                            font-size: 12px;
                            line-height: 18px;
                        }
                    }
                }

                .art-craft-shop-by-category-img-alignment {
                    width: 100%;
                    height: 200px;
                    transition: 0.2s ease-in-out;
                    cursor: pointer;
                    padding: 0px 23px;

                    @media only screen and (max-width:"768px") {
                        // width: 98px;
                        // min-width: 98px;
                        // max-width: 98px;
                        height: auto;
                        padding: 0;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 10px;
                        object-fit: cover;
                    }
                }


                p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 30px;
                    color: #000000;
                    text-align: center;
                    padding: 26px 0 0 0;
                    cursor: pointer;

                    @media only screen and (max-width:"768px") {
                        font-size: 12px;
                        line-height: 18px;
                        padding: 8px 0 0 0;
                    }
                }
            }
        }
    }

    &::after {
        content: '';
        position: absolute;
        background-image: url("../../../assets/icon/second-line.png");
        width: 100%;
        background-repeat: no-repeat;
        // background-size: cover;
        // background-position: center;
        height: 100%;
        right: 0;
        left: 0;
        transform: translateY(195px);
        z-index: 0;
        top: 0;

        @media only screen and (max-width:"768px") {
            display: none;
        }

    }

    &::before {
        content: '';
        position: absolute;
        background-image: url("../../../assets/icon/first-line.png");
        width: 100%;
        background-repeat: no-repeat;
        // background-size: cover;
        // background-position: center;
        height: 100%;
        right: 0;
        left: 0;
        transform: translateY(-270px);
        z-index: 0;
        top: 100%;

        @media only screen and (max-width:"1099px") {
            transform: translateY(-240px);
        }

        @media only screen and (max-width:"768px") {
            display: none;
        }
    }
}