.mobile-message-selected-delete-alignment-Overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - 110px);
  background: rgba(255, 255, 255, 0.6);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  z-index: 9999999 !important;
  display: none;

  @media only screen and (max-width: '1099px') {
    display: block;
  }
}

.mobile-message-selected-delete-alignment-Overlay.open {
  opacity: 1;
  visibility: visible;

  @media only screen and (max-width: '1099px') {
    display: block;
  }
}

.mobile-message-selected-delete-alignment--modal {
  position: fixed;
  right: 0;
  bottom: 6px;
  width: 100%;
  translate: 0 100%;
  transition: 0.3s;
  overflow-y: auto;
  height: 274px;
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.25);
  border-radius: 8px 8px 0px 0px;
}

.more-option-modal-body-details-alignment {
  padding: 32px 16px 8px 16px;
  height: auto;

  p {
    max-width: 251px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin: auto;
  }

  .mobile-delete-modal-option-alignment {
    padding: 32px 0 0 0;

    ul {
      li {
        margin: 0 0 8px 0;
        padding: 10px 0;
        background: #f8f8f8;
        border-radius: 6px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: var(--secondary-600);
        cursor: pointer;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;

        &.delete-list {
          background: #fde8e8;
          border-radius: 6px;
          color: #e71616;
        }
      }
    }
  }
}

.mobile-message-selected-delete-alignment--modal.open {
  translate: 0;
  z-index: 9999999;
  transform: translate(0, 6%);

  @media only screen and (max-width: '1099px') {
    display: block;
  }
}