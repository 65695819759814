.addProductTitleAlignment {
  margin: 0 0 16px 0;

  .addProductTitleBoxAlignment {
    border-radius: 10px;
    border: 1px solid var(--secondary-50);
    background: #FFF;
    padding: 10px 16px 16px 16px;

    h4 {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #495057;
      margin-right: 3px;

      .starAlignment {
        font-family: "Inter";
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #d23232;
      }
    }

    .addProductTitleInput {
      width: 100%;
      padding: 16px 0 0 0;

      input {
        width: 100%;
        height: 44px;
        background: #eceef4;
        border: 1px solid #eceef4;
        border-radius: 10px;
        padding: 0 13px 0 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: var(--secondary-900);

        &::placeholder {
          color: #acb1c0;
        }

        @media only screen and (max-width:"767px") {
          height: 40px;
          padding: 0 16px;
          border-radius: 10px;
          border: 1px solid var(--secondary-50);
          background: var(--light-grey);
        }
      }

      &.titleError {
        input {
          border: 1.9px solid red;
        }
      }
    }

    .totalTextAlignment {
      padding: 4px 0 0 0;
      text-align: right;

      span {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #808191;

        @media only screen and (max-width:"767px") {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
}