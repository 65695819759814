.inspirational-influencers-main-alignment {
  background: #fff;

  .container {
    max-width: 1440px;
    margin: auto;
    padding: 0;
  }

  .inspirational-influencers-header-alignment {
    padding: 20px 32px 0 32px;

    .inspirational-influencers-header-flex {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media only screen and (max-width:"767px") {
        padding: 0 16px;
      }

      h5 {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        margin: 0;
      }

      .inspirational-influencers-header-link {
        a {
          display: flex;
          align-items: center;
          gap: 8px;
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: var(--primary-500);
          cursor: pointer;

          @media only screen and (max-width:"767px") {
            font-size: 14px;
            line-height: 21px;

            img {
              display: none;
            }
          }

          &:hover {
            gap: 10px;
            text-decoration: underline;
          }
        }
      }
    }

  }

  .men-category-enthusiats-body-alignment {
    margin: 32px 0 0 0;

    @media only screen and (max-width: 799px) {
      margin: 0;
    }

    .men-category-enthusiats-slider {
      padding: 0 32px;
      margin-bottom: 54px;

      @media only screen and (max-width: 799px) {
        padding: 16px 32px;
      }

      .men-category-enthusiats-grid {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
      }

      .men-category-enthusiats-details {
        padding: 0 8px;

        .image-center-alignment {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 0 16px 0;

          img {
            width: 200px;
            height: 200px;
            border-radius: 50%;
            object-fit: cover;
            display: block;

            @media only screen and (max-width: '767px') {
              width: 140px;
              height: 140px;
            }
          }
        }

        .line-clamp {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 22px;
          line-height: 33px;
          color: #000000;
          text-align: center;
          cursor: pointer;

          @media only screen and (max-width: 799px) {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
          }
        }

        p {
          margin: 0 0 4px 0;
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #495057;

          @media only screen and (max-width: 799px) {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            margin: 0 0 8px 0;
          }
        }

        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          text-align: center;
          display: block;
          color: #495057;

          @media only screen and (max-width: '768px') {
            font-size: 12px;
            line-height: 18px;
          }
        }

        .button-center-alignment {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 16px 0 0 0;

          button {
            border: none;
            cursor: pointer;
            padding: 6px 47px;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            background: var(--primary-500);
            border-radius: 8px;
            color: #fff;
          }

          .button-follow {
            border: none;
            cursor: pointer;
            padding: 6px 47px;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            background: #e4e4e8;
            border-radius: 8px;
            color: var(--primary-500);
          }
        }

        .button-spcebet-alignment {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
          padding: 16px 0 0 0;

          // flex-wrap: wrap;

          @media only screen and (max-width: '768px') {
            justify-content: center;
            flex-wrap: wrap;
          }

          .button-deactive {
            border: 1px solid #556ee6;
            cursor: pointer;
            padding: 6px 20px;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            background: #fff;
            border-radius: 8px;
            color: var(--primary-500);
          }

          .button-active {
            border: none;
            cursor: pointer;
            padding: 6px 20px;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            background: var(--primary-500);
            border-radius: 8px;
            color: #fff;
          }
        }
      }
    }
  }

  .slick-arrow {
    @media (max-width:768px) {
      display: none !important;
    }
  }

  .slick-arrow {
    background: rgba(255, 255, 255, 0.6);

    img {
      &.hover-img {
        display: none;
      }
    }

    &:hover {
      background: #a855f7 !important;

      img {
        &.hover-img {
          display: block;
        }

        &.defalt-icon {
          display: none;
        }
      }

      &.slick-prev {
        img {
          animation: righttoleft 0.4s ease-in-out;
        }
      }

      &.slick-next {
        img {
          animation: bounceRight 0.4s ease-in-out;
        }
      }
    }
  }

  @keyframes righttoleft {
    0% {
      -moz-transform: translateX(4px);
      transform: translateX(4px);
    }

    100% {
      -moz-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @keyframes bounceRight {
    0% {
      -ms-transform: translateX(0);
      transform: translateX(0);
    }

    100% {
      -ms-transform: translateX(-4px);
      transform: translateX(-4px);
    }
  }

}