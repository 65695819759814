.addBrandSection {
    padding: 0 0 16px 0;

    .addBrandBox {
        border-radius: 10px;
        border: 1px solid var(--secondary-50);
        background: #FFF;
        padding: 12px 16px 16px 16px;

        .addBrandHeading {
            padding: 0 2px;

            h4 {
                color: var(--secondary-900);
                font-family: 'Poppins', sans-serif;
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
            }
        }

        .addBrandInputAlignment {
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 16px;
            border: 1px solid var(--secondary-50);
            background: var(--light-grey);
            border-radius: 10px;
            margin: 10px 0 0 0;
            position: relative;
            gap: 10px;

            input {
                width: 100%;
                height: 100%;
                border-radius: 10px;
                color: var(--secondary-900);
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 21px;
                background: transparent;
                border: none;
            }

            p {
                font-size: 14px;
                line-height: 21px;
                font-weight: 500;
                color: var(--primary-500);
                cursor: pointer;
            }

            .addBrandDropdownIcon {
                width: 16px;
                height: 16px;
                min-width: 16px;
                max-width: 16px;
                cursor: pointer;


                svg {
                    transition: 0.4s ease-in-out;

                    &.brandActiveArrow {
                        transition: 0.4s ease-in-out;

                        transform: rotate(-180deg);
                    }
                }
            }

            .brandDropdownBox {
                position: absolute;
                top: 48px;
                left: 0;
                width: 100%;
                border-radius: 10px;
                background: #FFF;
                z-index: 999;

                &.closeDropdownBox {
                    max-height: 0px;
                    transition: all 0.4s ease-in-out;
                    overflow-y: hidden;
                }

                &.openDropdownBox {
                    max-height: 157px;
                    transition: all 0.4s ease-in-out;
                    overflow-y: auto;
                    padding: 10px 0 0 0;
                    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.12);
                    border: 1px solid #FFF;
                }

                .noBrandDataText {
                    padding: 4px 16px;

                    p {
                        color: var(--secondary-300);
                        font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                    }
                }

                .selectedListBrand {
                    // min-height: 96px;
                    overflow-y: auto;
                    max-height: 96px;
                    height: auto;

                    p {
                        padding: 4px 16px;
                        transition: all 0.4s ease-in-out;
                        color: var(--secondary-900);
                        font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        cursor: pointer;

                        &:hover {
                            transition: all 0.4s ease-in-out;

                            background: var(--secondary-50);
                        }
                    }

                }


                .selectedBrand {
                    background: var(--secondary-50);
                }

                .addBrandOption {
                    padding: 12px 16px 12px 16px;
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    position: sticky;
                    bottom: 0;
                    left: 0;
                    background: #FFF;
                    z-index: 999;

                    a {
                        color: var(--primary-500);
                        font-family: 'Poppins', sans-serif;
                        font-size: 15px;
                        font-weight: 500;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}