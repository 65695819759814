.rateAndReviewDetailsSection {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 5px 50px rgba(145, 143, 143, 0.1);

  .rateAndReviewDetailsModalDetailsAlignment {
    border-radius: 10px;
    padding: 0 32px;
    height: calc(100vh - 96px);
    overflow-y: auto;

    .rateAndReviewDetailsModalHeading {
      padding: 22px 0 0 0;
      position: sticky;
      top: 0;
      z-index: 99;
      background: #fff;

      h5 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: var(--secondary-900);
      }
    }

    .rateAndReviewDetailsModalAlignment {
      padding: 21px 0 24px 0;

      .rateAndReviewDetailsModalGrid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;

        .rateAndReviewDetailsModalGridItem {
          .rateAndReviewDetailsModalLeftSide {
            height: 135px;
            background: var(--white);
            box-shadow: 0px 5px 50px rgba(144, 143, 143, 0.1);
            border-radius: 8px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .rateAndReviewDetailsModalLeftSideDetails {
              display: flex;
              align-items: center;
              gap: 8px;

              h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 56px;
                line-height: 84px;
                color: var(--secondary-900);
              }

              .rateAndReviewDetailsModalAverageRateAlignment {
                .rateAndReviewDetailsModalaverageRateStarAlignment {
                  display: flex;
                  align-items: center;
                  gap: 3px;
                  padding: 0 0 2px 0;

                  img {
                    width: 24px;
                    height: 24px;
                    display: block;
                  }
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 400;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--secondary-500);
                }
              }
            }
          }

          .rateAndReviewDetailsModalRightSide {
            .rateAndReviewDetailsModalRightSideGrid {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: 16px;

              .rateAndReviewDetailsModalRightSideGridItem {
                .rateAndReviewDetailsModalRightbox {
                  background: var(--white);
                  box-shadow: 0px 5px 50px rgba(144, 143, 143, 0.1);
                  border-radius: 8px;
                  height: 135px;
                  padding: 16px;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  justify-content: center;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 32px;
                    line-height: 48px;
                    color: var(--secondary-700);
                  }

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--secondary-500);
                  }

                  .rateAndReviewDetailsModalPersantag {
                    display: flex;
                    align-items: center;

                    .rateAndReviewDetailsModalarrowAlignment {
                      width: 24px;
                      height: 24px;

                      img {
                        width: 100%;
                        height: 100%;
                        display: block;
                      }
                    }

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 32px;
                      line-height: 48px;
                      color: var(--green-500);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .rateAndReviewDetailsModalfilterNavAlignment {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: sticky;
      top: 58px;
      background: #fff;
      z-index: 99;
      padding: 21px 0 0 0;

      .rateAndReviewDetailsModalStarFilterAlignment {
        display: flex;
        align-items: center;
        gap: 10px;

        .rateAndReviewDetailsModalStarOptionAlignment {
          padding: 4px 10px;
          display: flex;
          align-items: center;
          gap: 4px;
          cursor: pointer;

          &:hover {
            background: var(--primary-50);
            border-radius: 21.5px;
          }

          &.activeRate {
            background: var(--primary-50);
            border-radius: 21.5px;
          }

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: var(--secondary-500);
          }

          .rateAndReviewDetailsModalStarImg {
            width: 19px;
            height: 19px;
            position: relative;
            top: -2px;

            img {
              width: 100%;
              height: 100%;
              display: block;
            }
          }

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--secondary-500);
          }
        }
      }

      .rateAndReviewDetailsModalAlllMediaDetailsAlignment {
        display: flex;
        align-items: center;
        gap: 12px;

        .rateAndReviewDetailsModalAlllMediaimgFlex {
          display: flex;
          align-items: center;
          gap: 4px;

          .rateAndReviewDetailsModalAlllMediaMultiImg {
            display: flex;
            align-items: center;

            .rateAndReviewDetailsModalAlllMediaimg {
              width: 19px;
              height: 19px;
              margin: 0 0 0 -10px;

              &:first-child {
                margin: 0;
                position: relative;
                z-index: 99;
              }

              &:nth-child(2) {
                position: relative;
                z-index: 9;
              }

              img {
                width: 100%;
                height: 100%;
                border: 1px solid #ffffff;
                border-radius: 2.94961px;
              }
            }
          }

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: var(--secondary-500);
          }
        }

        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #808191;
        }
      }

      .sortDropdownDetailsAlignment {
        display: flex;
        align-items: center;
        gap: 8px;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: var(--secondary-900);
        }
      }
    }

    .rateAndReviewDetailsTable {
      padding: 52px 0 0 0;

      table {
        width: 100%;

        tbody {
          tr {
            border-bottom: 1px solid #eceef4;

            td {
              padding: 16px 0;

              .rateReviewProfileAlignment {
                display: flex;
                align-items: center;
                gap: 6px;

                .rateReviewImg {
                  width: 32px;
                  max-width: 32px;
                  min-width: 32px;
                  height: 32px;

                  img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    object-fit: cover;
                  }
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: var(--secondary-900);
                }
              }

              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: var(--secondary-500);

                &.textEnd {
                  text-align: right;
                }
              }

              .iconFlexAlignment {
                display: flex;
                align-items: center;
                gap: 2px;

                img {
                  width: 24px;
                  height: 24px;
                  display: block;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }

    .rateAndReviewDetailsTable table tbody tr td:nth-child(1) {
      width: 20%;
    }

    .rateAndReviewDetailsTable table tbody tr td:nth-child(2) {
      width: 20%;
    }

    .rateAndReviewDetailsTable table tbody tr td:nth-child(3) {
      width: 20%;
    }

    .rateAndReviewDetailsTable table tbody tr td:nth-child(4) {
      width: 25%;
    }

    .rateAndReviewDetailsTable table tbody tr td:nth-child(5) {
      width: 15%;
    }
  }
}
