.shopTrendingProductBox {
  border-radius: 16px;
  padding: 30px;

  @media only screen and (max-width: '1024px') {
    padding: 12px 12px 18px 12px;
    width: 168px;
    min-width: 168px;
    max-width: 168px;
  }

  @media (max-width: 576px) {
    width: 230px;
    min-width: 230px;
    max-width: 230px;
  }

  .childGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;

    .childImg {
      position: relative;

      &:hover {
        .textAlignment {
          transform: translateY(0%);
        }
      }
    }

    .textAlignment {
      position: absolute;
      bottom: 0;
      display: flex;
      transition: 0.3s ease-in-out;
      align-items: flex-end;
      border-radius: 8px;
      height: 100%;
      background: linear-gradient(180deg, rgba(255, 254, 254, 0.1) 50%, rgba(33, 32, 32, 0.9) 100%);
      left: 0;
      width: 100%;
      transform: translateY(100%);

      .spacer {
        padding: 10px;

        p {
          color: #fff;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        span {
          display: block;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          color: #fff;
        }
      }
    }

    .childImg {
      width: 100%;
      height: 120px;
      display: block;
      cursor: pointer;
      overflow: hidden;
      border-radius: 8px;

      @media only screen and (max-width: '1024px') {
        height: 65px;
      }

      @media (max-width:576px) {
        height: 90px;
      }

      .imageContainer {
        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          border-radius: 8px;
          transform: scale(1);
          transition: 0.4s ease-in-out;
        }

        &:hover {
          img {
            transform: scale(1.2);
            transition: 0.4s ease-in-out;
          }
        }
      }
    }
  }

  .boxChildDetails {
    padding: 25px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: '1024px') {
      padding: 6px 0 0 0;
    }

    // @media (max-width: 576px) {
    //   display: none;
    // }

    .boxProfileName {
      display: flex;
      align-items: center;
      gap: 7px;
      cursor: pointer;

      .profileImg {
        width: 32px;
        height: 32px;
        min-width: 32px;
        max-width: 32px;

        // @media only screen and (max-width: '1024px') {
        //   display: none;
        // }

        img {
          width: 100%;
          height: 100%;
          display: block;
          border-radius: 4px;
          object-fit: cover;
        }
      }

      h6 {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        line-height: 21px;

        @media only screen and (max-width: '1024px') {
          font-size: 12px;
          line-height: 18px;
          font-weight: 400;
        }
      }
    }

    p {
      color: #fff;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;

      @media only screen and (max-width: '1024px') {
        font-size: 10px;
        line-height: 14px;
        font-weight: 400;
      }
    }
  }
}