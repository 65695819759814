::selection {
  background: var(--primary-500);
  color: #fff;
}

:root {
  scroll-behavior: smooth;
}

.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

@mixin Head_3($margin) {
  font-weight: 300;
  font-size: 36px;
  line-height: 54px;
  color: #000000;
  margin-bottom: $margin;

  @media only screen and (max-width: 1199px) {
    font-size: 30px;
  }
}

@mixin off($display) {
  display: $display;
  visibility: hidden;
  touch-action: none;
  pointer-events: none;
}

@mixin on($display) {
  display: $display;
  visibility: visible;
  touch-action: auto;
  pointer-events: all;
}

@mixin block_btn {
  background: var(--primary-500);
  border-radius: 8px;
  display: block;
  width: 100%;
  border: unset;
  outline: unset;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    transform: translateY(-3px);
    background: #2844ac;
  }
}

@mixin Head_Link() {
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  color: var(--primary-500);
  display: flex;
  align-items: center;

  &:hover {
    transform: translateY(-3px);
  }

  span {
    margin-left: 8px;
  }
}

.bradcrum_section {
  width: 100%;
  background-color: #fff;
  padding: 6px;

  .container {
    max-width: 1300px;

    p {
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      color: var(--secondary-900);
      cursor: pointer;
    }
  }
}

.BackTo_top {
  width: 50px;
  height: 50px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 70px;
  background: var(--primary-500);
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1000;
  background: #ffffff;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    background: #ab67fc;
    transform: translateY(-3px);

    svg {
      path {
        stroke: #fff;
      }
    }
  }

  svg {
    transform: rotate(90deg) scale(1.3);
    stroke-width: 2px;
  }
}

.WomansCategory_page {
  .slick-arrow::before {
    display: none;
  }

  .slick-arrow {
    background: #ffffff;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    cursor: pointer;
    transform: translateY(-50%);

    @media only screen and (max-width: 576px) {
      display: none !important;
    }

    svg path {
      stroke-width: 2px;
    }

    &:hover {
      background: #ab67fc;

      svg {
        path {
          stroke: #fff;
          stroke-width: 3px;
        }
      }
    }
  }

  .slick-next {
    right: -50px;

    svg {
      transform: rotate(180deg);
    }
  }

  .slick-prev {
    left: -50px;
  }
}

.WomansCategory_page {
  background: #f9f9f9;
  min-height: 100vh;

  @media only screen and (max-width: '767px') {
    // height: calc(100vh - 65px - 64px - 50px);
    // overflow-y: auto;
    // min-height: auto;
    // padding: 48px 0 0 0;
  }

  .section_1 {
    cursor: pointer;

    .bannner_slider {
      .bannner_slider_cover {
        position: relative;

        .img_cover {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;

          &::before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            background: linear-gradient(94.09deg, #710051 0.21%, rgba(107, 33, 168, 0) 100%);
            width: 100%;
            height: 100%;

            @media only screen and (max-width: 799px) {
              border-radius: 10px;
            }
          }

          img,
          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;

            @media only screen and (max-width: 799px) {
              border-radius: 10px;
            }
          }
        }

        .banner_content {
          position: relative;
          z-index: 3;
          padding: 170px 0 240px 0;

          @media only screen and (max-width: 799px) {
            padding: 27px 21px 0 21px;
            height: 208px;
          }

          .container {
            max-width: 990px;

            .content_cover {
              h1 {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 40px;
                line-height: 60px;
                color: #ffffff;

                @media only screen and (max-width: 799px) {
                  font-size: 24px;
                  line-height: 36px;
                  padding: 0 0 11px 0;
                }
              }

              .subtext {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #ffffff;
                max-width: 365px;
                margin: 5px 0 20px 0;

                @media only screen and (max-width: 799px) {
                  font-size: 12px;
                  line-height: 18px;
                  padding: 0 0 11px 0;
                  max-width: 300px;
                }
              }

              .banner_btn {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                background: #ffffff;
                border-radius: 10px;
                padding: 10px 56px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

                @media only screen and (max-width: 799px) {
                  padding: 6px 16px;
                  font-size: 14px;
                  line-height: 21px;
                }

                &:hover {
                  transform: translateY(-3px);
                }

                p {
                  background: linear-gradient(94.09deg, #556ee6 0.21%, #6b21a8 100%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  background-clip: text;
                  // text-fill-color: transparent;
                }
              }
            }
          }
        }
      }

      .slick-arrow {
        display: none !important;
      }

      .slick-dots {
        z-index: 1000;
        top: unset;
        right: unset;
        bottom: 80px;
        left: 50%;
        transform: translateX(-50%);
        height: 20px;
        display: none !important;

        button::before {
          background: #fff;
        }

        .slick-active {
          button::before {
            background: #ffe500;
          }
        }
      }
    }
  }

  .section_2 {
    position: relative;
    z-index: 10;
    margin-top: -75px;
    width: 100%;

    @media only screen and (max-width: 799px) {
      position: static;
      margin: 8px 0 0 0;
      overflow-x: hidden;
    }

    .section_2_slider_main_web {
      height: 228px;
      display: block;

      @media (max-width:768px) {
        display: none;
      }

      // .section_2_slider_flx {
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   gap: 26px;
      // }

      .section_2_slider {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        overflow-x: auto;
      }

      .slick-track {
        display: flex;
      }

      .section_2_slider_content {
        margin: 0;
        cursor: pointer;
        width: 100%;

        @media (max-width:576px) {
          margin: 0 6px 0 0;
        }

        img {
          width: 472px;
          height: 222px;

          @media (max-width:576px) {
            width: 269px;
            height: 125px;
          }
        }
      }
    }

    .section_2_slider_main_mobile {
      height: 228px;
      display: none;

      @media (max-width:768px) {
        display: block;
        height: auto;
      }

      // .section_2_slider_flx {
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   gap: 26px;
      // }

      .slick-track {
        display: flex;
      }

      .section_2_slider_content {
        margin: 0 26px 0 0;
        cursor: pointer;

        @media (max-width:576px) {
          margin: 0 6px 0 0;
        }

        img {
          width: 472px;
          height: 222px;

          @media (max-width:576px) {
            width: 269px;
            height: 125px;
          }
        }
      }
    }

    .container {
      @media only screen and (max-width: 676px) {
        width: 100%;
        min-width: 100%;
        padding: 0;
      }
    }

    .section_2_row {
      margin: 0 -5px;
      align-items: stretch;

      @media only screen and (max-width: 1299px) {
        margin: 0;
      }

      @media only screen and (max-width: 799px) {
        flex-wrap: nowrap;
        gap: 8px;
      }

      .col_img {
        padding: 0 5px;
        height: 100%;

        @media only screen and (max-width: 799px) {
          padding: 0;
        }

        .img_cover {
          width: 100%;
          border-radius: 10px;
          overflow: hidden;
          height: 100%;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            max-height: 250px;

            @media only screen and (max-width: 799px) {
              object-fit: unset;
            }
          }
        }
      }

      .col_1 {
        width: 35%;

        @media only screen and (max-width: 1199px) {
          width: 269px;
          min-width: 269px;
          max-width: 269px;
          height: 125px;
        }

        @media only screen and (max-width: 999px) {
          width: 250px;
          min-width: 250px;
          max-width: 250px;
          height: 125px;
        }
      }

      .col_2 {
        width: 40%;

        // @media only screen and (max-width : 1199px) {
        //   width: 35%;
        // }
        @media only screen and (max-width: 1199px) {
          width: 269px;
          min-width: 269px;
          max-width: 269px;
          height: 125px;
        }
      }

      .col_3 {
        width: 25%;

        // @media only screen and (max-width : 1199px) {
        //   width: 20%;
        // }
        @media only screen and (max-width: 1199px) {
          width: 269px;
          min-width: 269px;
          max-width: 269px;
          height: 125px;
        }
      }
    }
  }

  .section_search {
    padding: 32px 0;

    @media only screen and (max-width: 799px) {
      display: none;
    }

    .container {
      width: 1310px;

      @media only screen and (max-width: 999px) {
        max-width: 700px;
        min-width: 700px;
        width: 700px;
      }
    }

    .search_input {
      display: block;
      width: 100%;
      text-align: left;
      background: #f1f0f0;
      border-radius: 16px;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: var(--secondary-900);
      padding: 21px 35%;
      border: 1px solid #f8f8f8;
      background-size: 42px;
      background-image: url(../../../assets/img/WomansCategory/search.png);
      background-repeat: no-repeat;
      background-position: 32% center;
      transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

      @media only screen and (max-width: 999px) {
        padding: 21px 20%;
        background-position: 8% center;
      }

      &::placeholder {
        color: #acb1c0;
        opacity: 1;
        transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
      }

      &:focus {
        border: 1px solid #556ee6;
        // background-image: none;
      }

      &:focus::placeholder {
        opacity: 0;
      }
    }
  }

  .section_3 {
    width: 100%;
    background: #eceef4;
    padding: 32px 0;
    margin: 32px 0;

    @media only screen and (max-width: 1199px) {
      padding: 32px 25px;
    }

    @media only screen and (max-width: 999px) {
      display: none;
    }

    .container {
      max-width: 1310px;

      @media only screen and (max-width: 1199px) {
        max-width: 1024px;
      }

      .section_3_row {
        margin: 0 -10px;

        .left_block {
          width: 275px;
          padding: 0 10px;

          // @media only screen and (max-width:1199px) {
          //   width: 230px;
          // }

          .left_cover {
            h3 {
              @include Head_3(24px);
            }

            a {
              @include Head_Link();
            }
          }
        }

        .right_block {
          width: calc(100% - 275px);
          padding: 0 10px;

          @media only screen and (max-width: 1299px) {
            width: calc(100% - 355px);
          }

          @media only screen and (max-width: 1199px) {
            width: calc(100% - 300px);
          }

          .right_block_cover {
            width: 100%;

            .Category_slider {
              position: relative;
              cursor: pointer;

              .slick-arrow {
                transform: translate(0, -85%);
              }
            }
          }
        }
      }
    }
  }

  .catagory_block {
    text-align: center;

    // @media only screen and (max-width:1199px) {
    //   padding: 0 20px;
    // }

    &:hover {
      p {
        color: #ab67fc;
      }

      .box_cover {
        border: 1px solid #a855f7;
      }

      .img_cover {
        transform: translateY(-5px);
      }
    }

    .box_cover {
      width: 105px;
      height: 105px;
      border-radius: 105px;
      padding: 5px;
      border: 1px solid transparent;
      margin: 0 auto;
      transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

      @media only screen and (max-width: 1199px) {
        width: 90px;
        height: 90px;
      }
    }

    .box {
      background: #ffc0cb;
      width: 100%;
      height: 100%;
      border-radius: 100px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      .img_cover {
        transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
      }
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--secondary-900);
      margin-top: 10px;

      @media only screen and (max-width: 1199px) {
        text-align: center;
      }
    }
  }

  // Mobile View Section_3
  .mobile_view_section3 {
    margin: 32px 0 0 0;
    display: none;

    @media only screen and (max-width: 999px) {
      display: block;
    }

    .section_3_heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;

      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #495057;
      }

      .mobile_view_seeMore {
        a {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: var(--primary-500);
          cursor: pointer;
        }
      }
    }

    .mobile_view_section3_details_alignment {
      margin: 8px 0 0 0;
      overflow-x: hidden;

      .mobile_view_section3_details_bg {
        background: #eceef4;
        padding: 12px 0 12px 12px;
        // .mobile_view_grid {
        //   display: grid;
        //   grid-template-columns: repeat(5, 1fr);
        //   gap: 12px;
        //   padding: 12px;
        //   width: 100%;
        //   overflow-x: hidden;

        .mobile_view_gridItem {
          .imgBox-alignment {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 4px;
            margin: auto;
            border: 1px solid transparent;

            @media only screen and (max-width: 767px) {
              width: 70px;
              height: 70px;
            }

            &:hover {
              border: 1px solid #a855f7;
            }
          }

          .product_name-alignment {
            text-align: center;
            padding: 8px 0 0 0;

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: var(--secondary-900);
              margin: 0;
            }
          }
        }

        // }
      }
    }
  }

  .section_4 {
    padding: 64px 0 65px 0;
    overflow-x: hidden;

    @media only screen and (max-width: 999px) {
      padding: 32px 0;
    }

    @media only screen and (max-width: 799px) {
      padding: 32px 0 0 0;
    }

    .section_4_head {
      text-align: center;
      max-width: 320px;
      margin: 0 auto 30px auto;

      @media only screen and (max-width: 799px) {
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        display: none;
      }

      h3 {
        @include Head_3(10px);

        @media only screen and (max-width: 799px) {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          color: #495057;
        }
      }

      a {
        @include Head_Link();
        justify-content: center;

        @media only screen and (max-width: 799px) {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: var(--primary-500);

          .mobile-view-right-angle-alignment {
            display: none;
          }
        }
      }
    }

    .section_4_heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px 8px 16px;
      width: 100%;
      display: none;

      @media only screen and (max-width: 799px) {
        display: flex;
      }

      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #495057;
      }

      .mobile_view_seeMore {
        a {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: var(--primary-500);
          cursor: pointer;
        }
      }
    }

    .trending_block {
      width: 100%;

      @media only screen and (max-width: 799px) {
        width: 100%;
        background: #f8f8f8;
        padding: 16px 0;
      }

      .trending_block_row {
        // margin: 0 -8px;
        flex-wrap: unset;
        align-items: stretch;

        @media only screen and (max-width: 799px) {
          flex-wrap: nowrap;
          overflow-x: hidden;
        }

        .card_col {
          // width: 20%;
          padding: 0 8px;
          height: 100%;

          // @media only screen and (max-width: 799px) {
          //   max-width: 260px;
          //   width: 260px;
          //   min-width: 260px;
          // }

          .cardcover {
            width: 100%;
            border-radius: 10px;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            margin-bottom: 15px;
            // height: 200px;

            .cart_height1 {
              height: 258.81px;

              img {
                height: 100%;
                width: 100%;
                object-fit: cover;
              }
            }

            .cart_height2 {
              height: 221px;

              img {
                height: 100%;
                width: 100%;
                object-fit: cover;
              }
            }

            .cart_height3 {
              height: 496px;

              img {
                height: 100%;
                width: 100%;
                object-fit: cover;
              }
            }

            &:hover {
              .cart_height1 img {
                transform: scale(1.05);
                transition: 0.4s ease-in-out;
              }

              .cart_height2 img {
                transform: scale(1.05);
                transition: 0.4s ease-in-out;
              }

              .cart_height3 img {
                transform: scale(1.05);
                transition: 0.4s ease-in-out;
              }

              .card_bottom {
                @include on(flex);
              }
            }

            .card_top img {
              width: 100%;
              height: 100%;
              transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
              transform: scale(1.02);
            }

            .card_bottom {
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 100%;
              background: linear-gradient(180deg, rgba(30, 36, 50, 0) 0%, #1e2432 100%);
              padding: 16px;
              display: flex;
              align-items: flex-end;
              @include off(none);

              .WomeCardContent {
                display: flex;
                flex-direction: column;
                gap: 8px;
                align-items: start;

                h1 {
                  color: #F8F8F8;
                  font-family: Poppins;
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                }

                .WomenCardPrice {
                  display: flex;
                  align-items: end;
                  justify-content: start;
                  gap: 11px;

                  p {
                    color: #F8F8F8;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                  }

                  del {
                    color: #999999;
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-decoration-line: strikethrough;
                  }
                }
              }

              .card_bottom_cover {
                width: 100%;

                h4 {
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 30px;
                  color: #f8f8f8;
                }

                .amount {
                  margin: 5px 0 8px 0;

                  @media only screen and (max-width: 799px) {
                    display: none;
                  }

                  span {
                    vertical-align: middle;
                  }

                  .price {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 27px;
                    color: #f8f8f8;
                  }

                  .orignal_price {
                    text-decoration-line: line-through;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    color: #ffffff;
                    opacity: 0.6;
                    margin: 0 6px;
                  }

                  .discount {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    text-align: center;
                    color: #ffffff;
                    background: #ff7d23;
                    border-radius: 11px;
                    padding: 2px 6px;
                  }
                }

                .hike {
                  display: flex;
                  align-items: center;

                  .inter {
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    line-height: 18px;
                    color: #f8f8f8;

                    img {
                      margin-right: 4px;
                    }
                  }

                  .incris {
                    margin-left: 15px;
                    display: flex;
                    align-items: center;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    color: #70c217;

                    img {
                      margin-right: 4px;
                    }
                  }
                }

                .rating {
                  display: flex;
                  align-items: center;
                  margin: 9px 0 18px 0;

                  @media only screen and (max-width: 799px) {
                    display: none;
                  }

                  .rating_point {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: #f8f8f8;
                  }

                  .stars {
                    display: flex;
                    align-items: center;
                    margin: 0 8px;
                  }

                  .num {
                    font-size: 16px;
                    line-height: 24px;
                    color: #f8f8f8;
                  }
                }

                .buy_btn {
                  @include block_btn();

                  @media only screen and (max-width: 799px) {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .section_5 {
    padding: 32px 0 130px;
    background: #f8f8f8;

    @media only screen and (max-width: 999px) {
      display: none;
    }

    .container {
      width: 1210px;
    }

    .section_5_head {
      text-align: center;
      margin-bottom: 120px;

      h3 {
        @include Head_3(10px);
      }

      a {
        @include Head_Link();
        justify-content: center;
      }
    }

    .your_styles_block {
      position: relative;

      .round {
        cursor: pointer;
        background: #f8f8f8;
        overflow: hidden;
        border-radius: 100%;
        transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

        &:hover {
          img {
            transform: scale(1.05);
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
        }
      }

      .img_wrapper {
        width: 100%;
        height: 100%;
        position: relative;

        .layer {
          width: 100%;
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #1e24327e;

          span {
            font-weight: 400;
            font-size: 24px;
            line-height: 36px;
            text-align: center;
            color: #ffffff;
          }
        }
      }

      .img_cover_7,
      .img_cover_4 {
        .img_wrapper .layer span {
          font-size: 16px;
        }
      }

      .img_cover_5,
      .img_cover_6,
      .img_cover_8 {
        .img_wrapper .layer span {
          font-size: 14px;
        }
      }

      .img_cover_1 {
        width: 480px;
        margin-left: auto;
        margin-right: 60px;
        box-shadow: 0px 0px 0px 75px #bed4ff;

        &:hover {
          box-shadow: 0px 0px 0px 55px #bed4ff;
        }
      }

      .img_cover_2 {
        width: 200px;
        position: absolute;
        right: 33%;
        top: 5%;
        box-shadow: 0px 0px 0px 35px rgba(187, 59, 248, 0.6);

        &:hover {
          box-shadow: 0px 0px 0px 45px rgba(187, 59, 248, 0.6);
        }
      }

      .img_cover_3 {
        width: 240px;
        position: absolute;
        right: 54%;
        top: -5%;
        box-shadow: 0px 0px 0px 35px rgba(255, 214, 228, 0.6);

        &:hover {
          box-shadow: 0px 0px 0px 45px rgba(255, 214, 228, 0.6);
        }
      }

      .img_cover_4 {
        width: 140px;
        position: absolute;
        right: 47%;
        bottom: 17%;
        box-shadow: 0px 0px 0px 10px rgba(116, 0, 0, 0.6);

        &:hover {
          box-shadow: 0px 0px 0px 15px rgba(116, 0, 0, 0.6);
        }
      }

      .img_cover_5 {
        width: 95px;
        position: absolute;
        right: 75%;
        bottom: 50%;
        box-shadow: 0px 0px 0px 20px rgba(198, 230, 159, 0.6);

        &:hover {
          box-shadow: 0px 0px 0px 25px rgba(198, 230, 159, 0.6);
        }
      }

      .img_cover_6 {
        width: 75px;
        position: absolute;
        left: 6%;
        bottom: 33%;
        box-shadow: 0px 0px 0px 15px rgba(30, 36, 50, 0.6);

        &:hover {
          box-shadow: 0px 0px 0px 20px rgba(30, 36, 50, 0.6);
        }
      }

      .img_cover_7 {
        width: 145px;
        position: absolute;
        left: 19%;
        bottom: 2%;
        box-shadow: 0px 0px 0px 35px rgba(255, 230, 167, 0.6);

        &:hover {
          box-shadow: 0px 0px 0px 45px rgba(255, 230, 167, 0.6);
        }
      }

      .img_cover_8 {
        width: 85px;
        position: absolute;
        left: 37%;
        bottom: -8%;
        box-shadow: 0px 0px 0px 15px rgba(142, 0, 253, 0.6);

        &:hover {
          box-shadow: 0px 0px 0px 20px rgba(142, 0, 253, 0.6);
        }
      }

      .dots {
        position: absolute;
        cursor: pointer;
        overflow: hidden;
        border-radius: 100%;
        opacity: 0.6;
        transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

        &:hover {
          transform: scale(1.05);
        }
      }

      .dots1 {
        background: #cce09b;
        width: 23.44px;
        height: 23.44px;
        top: 24%;
        left: 10%;
      }

      .dots2 {
        background: rgba(30, 36, 50, 0.6);
        width: 13.13px;
        height: 13.13px;
        bottom: 16%;
        left: 8%;
      }

      .dots3 {
        background: #f178b6;
        width: 26.25px;
        height: 26.25px;
        left: 36%;
        top: 67%;
      }

      .dots4 {
        background: #808191;
        width: 23.44px;
        height: 23.44px;
        top: -8%;
        right: 49%;
      }

      .dots5 {
        background: #a855f7;
        width: 47.81px;
        height: 47.81px;
        right: 45%;
        bottom: -10%;
      }
    }

    // .your_styles_block:first-child {
    //   .img_wrapper {
    //     .layer {
    //       background: red;
    //     }
    //   }
    // }
  }

  .mobile_view_section_5 {
    padding: 16px 0;
    display: none;

    @media only screen and (max-width: 999px) {
      display: block;
    }

    .section_5_heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px 8px 16px;
      width: 100%;
      display: none;

      @media only screen and (max-width: 799px) {
        display: flex;
      }

      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #495057;
      }

      .mobile_view_seeMore {
        a {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: var(--primary-500);
          cursor: pointer;
        }
      }
    }

    .mobile_view_find_your_style_section {
      padding: 0 0 0 16px;
      overflow-x: hidden;
      width: 100%;
      overflow: scroll;
      background: #f8f8f8;

      // .mobile_view_find_your_style_grid {
      //   display: grid;
      //   grid-template-columns: repeat(4, 1fr);
      //   gap: 8px;
      .slick-slider {
        .slick-list {
          padding: 0 !important;
        }
      }

      .mobile_view_find_your_style_gridItem {
        padding: 0 6px;

        .mobile_view_find_your_style_box_alignment {
          // width: 102px;
          // height: 102px;
          // border-radius: 50%;
          background: #f8f8f8;
          border-radius: 68.4405px;
          padding: 7px;

          .mobile-view_fashion-img-alignment {
            position: relative;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 50%;
            }

            &::after {
              content: '';
              position: absolute;
              width: 100%;
              height: 100%;
              background: rgba(30, 36, 50, 0.6);
              top: 0;
              right: 0;
              border-radius: 50%;
            }

            .mobile-view_fashion-option-name-alignment {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 9;

              h5 {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #ffffff;
              }
            }
          }
        }
      }

      // }
    }
  }

  .section_6_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px 8px 16px;
    width: 100%;
    display: none;

    @media only screen and (max-width: 799px) {
      display: flex;
    }

    h4 {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #495057;
    }

    .mobile_view_seeMore {
      a {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: var(--primary-500);
        cursor: pointer;
      }
    }
  }

  .section_6 {
    margin: 30px 0;
    background: #f5eeec;
    padding: 25px 0 30px 0;

    .container {
      width: 1310px;

      @media only screen and (max-width: 999px) {
        width: 100%;
        max-width: 100%;
      }

      .section_3_row {
        margin: 0 -10px;

        @media (max-width:576px) {
          margin: 0;
        }

        .left_block {
          width: 275px;
          padding: 0 10px;

          @media only screen and (max-width: 999px) {
            display: none;
          }

          .left_cover {
            h3 {
              @include Head_3(24px);
            }

            a {
              @include Head_Link();
            }
          }
        }

        .right_block {
          width: calc(100% - 275px);
          padding: 0 10px;

          @media only screen and (max-width: 1299px) {
            width: calc(100% - 335px);
          }

          @media only screen and (max-width: 999px) {
            width: 100%;
            max-width: 100%;
          }

          .right_block_cover {
            width: 100%;

            // @media only screen and (max-width: 799px) {
            //   .slick-slide {
            //     width: 163px !important;
            //   }
            // }

            .Trending_Store_slider {
              position: relative;
              cursor: pointer;
              margin: 0 -8px;

              .trending_store_grid {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 16px;

                @media only screen and (max-width: '1199px') {
                  grid-template-columns: repeat(4, 1fr);
                }

                @media only screen and (max-width: '999px ') {
                  grid-template-columns: repeat(3, 1fr);
                  gap: 16px;
                }

                @media only screen and (max-width: '799px') {
                  grid-template-columns: repeat(2, 1fr);
                  gap: 16px;
                }

                @media only screen and (max-width: '576px') {
                  grid-template-columns: repeat(1, 1fr);
                  gap: 16px;
                }
              }

              .Trending_Store_slider_cover {
                padding: 0 8px;

                .card_store {
                  width: 100%;
                  border: 1px solid #eceef4;
                  border-radius: 12px;
                  background: #ffffff;
                  overflow: hidden;
                  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

                  &:hover {
                    transform: translateY(-4px);
                    border: 1px solid #ab67fc;

                    .card_content h5 {
                      color: #ab67fc;
                    }
                  }

                  .cardtop_store {
                    overflow: hidden;
                    border-radius: 12px;
                    width: 100%;
                    max-height: 145px;
                    object-fit: cover;
                    object-position: center;
                    height: 145px;

                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                  }

                  .card_content {
                    padding: 16px;
                    text-align: center;

                    h5 {
                      font-weight: 500;
                      font-size: 20px;
                      line-height: 30px;

                      color: #000000;

                      @media only screen and (max-width: 799px) {
                        font-size: 16px;
                        line-height: 24px;
                        margin: 0 0 2px 0;
                      }
                    }

                    h6 {
                      font-size: 14px;
                      line-height: 21px;
                      font-weight: 400;
                      color: #808191;

                      @media only screen and (max-width: 799px) {
                        font-size: 12px;
                        line-height: 18px;
                        padding: 0 0 9px 0;
                      }
                    }

                    .product {
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 21px;
                      color: #808191;
                      margin: 16px 0 8px 0;

                      @media only screen and (max-width: 799px) {
                        font-size: 12px;
                        line-height: 18px;
                        margin: 0 0 2px 0;
                      }
                    }

                    .Visitor {
                      font-size: 14px;
                      line-height: 21px;
                      color: #808191;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      flex-wrap: wrap;

                      @media only screen and (max-width: 799px) {
                        font-size: 12px;
                        line-height: 18px;
                      }

                      .pre {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      }
                    }

                    button {
                      @include block_btn();
                      margin-top: 16px;

                      @media only screen and (max-width: 799px) {
                        font-size: 14px;
                        line-height: 21px;
                      }
                    }
                  }
                }
              }

              .slick-list {
                padding-top: 5px;
              }
            }
          }
        }
      }
    }
  }

  .section_7_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px 8px 16px;
    width: 100%;
    display: none;

    @media only screen and (max-width: 799px) {
      display: flex;
    }

    h4 {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #495057;
    }

    .mobile_view_seeMore {
      a {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: var(--primary-500);
        cursor: pointer;
      }
    }
  }

  .section_7 {
    margin: 30px 0;
    background: linear-gradient(93.08deg, #fff4f4 1.3%, #a9b2ff 99.66%);
    padding: 45px 0 50px 0;
    overflow: hidden;

    .container {
      width: 1310px;

      @media only screen and (max-width: 799px) {
        width: 100%;
        max-width: 100%;
      }

      .section_3_row {
        margin: 0 -10px;

        .left_block {
          width: 275px;
          padding: 0 10px;

          @media only screen and (max-width: 999px) {
            display: none;
          }

          .left_cover {
            h3 {
              @include Head_3(24px);
            }

            a {
              @include Head_Link();
            }
          }
        }

        .right_block {
          width: calc(100% - 275px);
          padding: 0 10px;

          @media only screen and (max-width: 1299px) {
            width: calc(100% - 335px);
          }

          @media only screen and (max-width: 999px) {
            width: 100%;
            max-width: 100%;
          }

          .right_block_cover {
            width: 100%;

            // @media only screen and (max-width:799px) {
            //   .slick-slide {
            //     width: 254px !important;
            //   }
            // }

            .Fashion_Group_slider {
              position: relative;
              cursor: pointer;
              margin: 0 -8px;

              .fashion-group-slider-grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);

                @media (max-width:700px) {
                  grid-template-columns: repeat(1, 1fr);
                  gap: 10px;
                  padding: 0 10px;
                }
              }

              .Fashion_Group_slider_cover {
                padding: 0 8px;

                .card_fashion {
                  width: 100%;
                  position: relative;
                  border-radius: 10px 10px 0px 0px;
                  overflow: hidden;
                  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                  height: 251px;

                  &:hover {
                    transform: translateY(-5px);

                    .img_cover img {
                      transform: scale(1.05);
                    }
                  }

                  .img_cover {
                    object-fit: cover;
                    object-position: center;
                    max-height: 251px;
                    height: 251px;

                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                      transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                    }
                  }

                  .card_details {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: flex-end;
                    padding: 15px;
                    background: linear-gradient(360deg, #0e1116 0%, rgba(30, 36, 50, 0) 100%);

                    .card_details_cover {
                      width: 100%;

                      h5 {
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 30px;
                        color: #ffffff;
                      }

                      p {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                        color: #ffffff;
                        margin: 4px 0 17px 0;
                      }

                      .users {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .left_part {
                          display: flex;
                          align-items: center;

                          .users_likes {
                            display: flex;
                            align-items: center;
                            margin-left: -10px;

                            .user_box {
                              width: 20px;
                              display: flex;
                              align-items: center;
                              height: 20px;
                              justify-content: center;
                              background: #fff;
                              object-position: center;
                              border: 1px solid #ffffff;
                              border-radius: 30px;

                              img {
                                width: 100%;
                                object-fit: cover !important;
                              }
                            }
                          }

                          .count {
                            margin-left: -10px;
                            background: var(--primary-500);
                            border: 1px solid #ffffff;
                            border-radius: 12px;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 18px;
                            letter-spacing: 0.1px;
                            color: #ffffff;
                            padding: 0px 9px;
                          }
                        }

                        .right_part {
                          max-width: 120px;

                          button {
                            @include block_btn();
                          }
                        }
                      }
                    }
                  }
                }
              }

              .slick-list {
                padding-top: 5px;
              }
            }
          }
        }
      }
    }
  }

  .section_8 {
    padding: 75px 0 50px 0;
    position: relative;
    background-color: #dcddde;
    z-index: 2;

    @media only screen and (max-width: 999px) {
      display: none;
    }

    .background {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;

      img {
        width: 100%;
        height: 100%;
        // transform: scale(1.02);
        object-fit: cover;
        object-position: center;

        @media only screen and (max-width: 1299px) {
          transform: scale(1);
        }
      }
    }

    .container {
      width: 1310px;
      z-index: 1;
      position: relative;

      .subsection_1 {
        .section_5_head {
          text-align: center;
          margin-bottom: 70px;

          h3 {
            @include Head_3(10px);
          }

          p {
            font-weight: 300;
            font-size: 18px;
            line-height: 27px;
            color: var(--secondary-900);
            margin-bottom: 8px;
          }

          a {
            @include Head_Link();
            justify-content: center;
          }
        }

        .Enthusiasts_block {
          width: 100%;
          position: relative;
          height: 600px;

          .dots {
            position: absolute;
            cursor: pointer;
            overflow: hidden;
            border-radius: 100%;
            opacity: 0.6;
            transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

            &:hover {
              transform: scale(1.05);
            }
          }

          .dots1 {
            background: #ffffff;
            width: 32.95px;
            height: 32.95px;
            left: 14%;
            bottom: 46%;
          }

          .dots2 {
            background: #ffe500;
            width: 19.77px;
            height: 19.77px;
            top: 18%;
            left: 20%;
          }

          .dots3 {
            background: #ff7d23;
            width: 51.62px;
            height: 51.62px;
            top: 0;
            left: 36%;
          }

          .dots4 {
            background: #ff7d23;
            width: 19.77px;
            height: 19.77px;
            bottom: 5%;
            left: 47%;
          }

          .dots5 {
            background: #80008b;
            width: 41px;
            height: 41px;
            top: 14%;
            right: 26%;
          }

          .dots6 {
            background: #a855f7;
            width: 26px;
            height: 26px;
            right: 14%;
            top: 37%;
          }

          .round {
            border-radius: 100%;
            position: absolute;
            cursor: pointer;
            transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
            outline: 2px solid transparent;
            border: 3px solid transparent;
            outline-offset: 10px;

            &:hover {
              outline-color: #ff7d23;
              border-color: #ff7d23;

              .parent {
                .round_card {
                  transform: scale(1);
                  visibility: visible;
                  pointer-events: all;
                  touch-action: auto;
                }
              }
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }

          .parent {
            position: relative;

            .round_card {
              transform: scale(0);
              transform-origin: left;
              visibility: hidden;
              touch-action: none;
              pointer-events: none;
              border-radius: 100%;
              overflow: hidden;
              position: absolute;
              cursor: pointer;
              transition: all 0.4s ease-in-out;
              border: unset;
              outline: unset;
              z-index: 2;
              right: -70%;
              top: 0%;
              width: 216px;
              height: 216px;
              background: #ffffff;
              box-shadow: 7px 7px 13px rgba(95, 64, 175, 0.16);
              border-radius: 110px;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              flex-direction: column;

              h5 {
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                color: #000000;
              }

              h6 {
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #495057;
                margin: 4px 0;
              }

              p {
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                color: #495057;
                margin-bottom: 8px;
              }

              .btn_block {
                min-width: 108px;

                button {
                  @include block_btn();
                }
              }
            }
          }

          .round1 {
            width: 75.49px;
            height: 75.49px;
            bottom: 36%;
            right: 20%;
          }

          .round2 {
            width: 103px;
            height: 103px;
            bottom: 18%;
            right: 27%;
          }

          .round3 {
            width: 188px;
            height: 188px;
            left: 27%;
            bottom: 31%;
          }

          .round4 {
            width: 198px;
            height: 198px;
            left: 45%;
            bottom: 15%;
          }

          .round5 {
            width: 243px;
            height: 243px;
            left: 39%;
            top: 3%;

            img {
              transform: scale(1.05);
            }
          }

          .round6 {
            width: 168px;
            height: 168px;
            right: 27%;
            top: 32%;
          }

          .round7 {
            width: 103px;
            height: 103px;
            left: 35%;
            bottom: 8%;
          }

          .round8 {
            width: 133px;
            height: 133px;
            left: 26%;
            top: 9%;
          }

          .round9 {
            width: 98px;
            height: 98px;
            left: 19%;
            top: 29%;
          }

          .round10 {
            width: 123px;
            height: 123px;
            left: 16%;
            top: 60%;
          }
        }
      }
    }
  }

  .mobile-view-section-8 {
    display: none;

    @media only screen and (max-width: 999px) {
      display: block;
      padding: 8px 0;
    }

    .section_8_heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px 8px 16px;
      width: 100%;
      display: none;

      @media only screen and (max-width: 799px) {
        display: flex;
      }

      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #495057;
        max-width: 179px;
      }

      .mobile_view_seeMore {
        a {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: var(--primary-500);
          cursor: pointer;
          white-space: nowrap;
        }
      }
    }

    .section_8_details-alignment {
      padding: 16px;
      overflow-x: hidden;

      .section_8_details-flex-alignment {
        // display: flex;
        // align-items: center;
        // gap: 16px;

        .enthusiasts-details-alignment {
          padding: 0 8px;

          .person-img-alignment {
            // width: 140px;
            // height: 140px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }

          .enthusiasts-child-details-alignment {
            text-align: center;

            h5 {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: var(--secondary-900);
              padding: 16px 0 0 0;
            }

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #495057;
            }

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              color: #495057;
              padding: 8px 0 0 0;
            }

            .enthusiasts-button-alignment {
              margin: 16px 0 0 0;
              @include block_btn();
            }
          }
        }
      }
    }
  }

  .section_9 {
    background: #fff;
    padding: 50px 0;

    @media only screen and (max-width: 1299px) {
      padding: 50px 0;
    }

    @media only screen and (max-width: 799px) {
      padding: 16px 0;
    }

    .container {
      width: 1310px;
      z-index: 1;
      position: relative;

      @media only screen and (max-width: 799px) {
        width: 100%;
        max-width: 100%;
        padding: 0;
      }

      .subsection_2 {

        // @media screen and (max-width: 1024px) {
        //   padding: 0 14px;
        // }
        .section_5_head2 {
          text-align: center;
          margin-bottom: 50px;

          @media screen and (max-width: 767px) {
            margin-bottom: 10px;
          }

          h3 {
            @include Head_3(0px);

            @media only screen and (max-width: 799px) {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 20px;
              line-height: 30px;
              color: var(--secondary-900);
            }
          }
        }

        .related_search {
          width: 100%;

          .related_search_row {
            margin: 0 -10px;
            align-items: flex-start;

            .col_comman {
              width: 25%;
              padding: 0 10px;

              @media only screen and (max-width: 799px) {
                width: 50%;
              }
            }
          }
        }
      }
    }
  }
}

.product-alignment-mobile {
  margin: 16px 0;
}

.womans_card {
  margin-bottom: 20px;
  width: 100%;
  overflow: hidden;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  border-radius: 10px;
  cursor: pointer;

  .cardtop {
    position: relative;

    img {
      width: 100%;
      border-radius: 8px 8px 0px 0px;
    }

    .options {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      padding: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;

      .option_top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .count {
          background: #1e2432;
          opacity: 0.6;
          border-radius: 8px;
          padding: 6px;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          text-align: center;
          color: #ffffff;
        }

        .left_options {
          display: flex;
          align-items: center;

          .like {
            background: #1e2432;
            opacity: 0.6;
            width: 31px;
            height: 31px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            margin-right: 8px;
          }

          .menu_l {
            background: #1e2432;
            opacity: 0.6;
            width: 23px;
            height: 23px;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }

      .pause {
        background: #1e2432;
        opacity: 0.6;
        border-radius: 21px;
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .option_bottom {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        span {
          background: #ffffff;
          border: 0.5px solid #e4e4e8;
          border-radius: 10.5px;
          padding: 4px;
          font-weight: 500;
          font-size: 12px;
          line-height: 1px;
          color: var(--secondary-900);
          display: inline-flex;

          img {
            width: 13px;
            height: 13px;
            margin-right: 5px;
          }
        }
      }
    }
  }

  .card_details_box {
    border-bottom: 1px solid #f3f3f3;
    padding: 15px;

    @media only screen and (max-width: 799px) {
      padding: 8px;
    }

    h6 {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--secondary-900);

      @media only screen and (max-width: 799px) {
        font-size: 14px;
        line-height: 21px;
      }
    }

    .row1 {
      display: flex;
      width: 100%;
      margin: 10px 0 12px 0;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      // span {
      //   margin-right: 10px;
      // }

      .price {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #000000;
      }

      .item2 {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-decoration-line: line-through;
        color: #acb1c0;
      }

      .other-details-flex {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .item3 {
        background: #ff7d23;
        border-radius: 11px;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #ffffff;
        padding: 1px 5px;
        white-space: nowrap;
      }

      .item4 {
        padding: 1px 5px;
        background: #eaeaea;
        border-radius: 13.5px;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #5f40af;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          margin-right: 4px;
        }
      }
    }

    .row2 {
      flex-wrap: wrap;

      .second-other-details-alignment {
        display: flex;
        align-items: center;
      }

      span {
        margin-right: 7px;
        display: flex;
      }

      display: flex;
      align-items: center;
      width: 100%;

      .item1 {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        color: #000000;

        img {
          margin-right: 5px;
        }
      }

      .item2 {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: #70c217;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          margin-right: 7px;
        }
      }

      .reting {
        @media only screen and (max-width: 799px) {
          padding: 4px 0 0 0;
          display: flex;
          align-items: center;
          gap: 4px;
        }

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: var(--secondary-900);
        }

        .countee {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #acb1c0;
          padding: 0 0 0 2px;
        }

        // margin-left: 29px;
        // font-weight: 600;
        // font-size: 16px;
        // line-height: 1px;
        // display: flex;
        // align-items: center;
        // color: #808191;

        @media only screen and (max-width: 799px) {
          margin-left: 0;
        }

        img {
          margin-left: 2px;
          height: 16px;
          width: 16px;
        }
      }
    }
  }

  .card_utils {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .box {
      width: 33.33%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 17px 8px;

      svg {
        margin-right: 5px;
      }
    }
  }
}

// .Category_slider_cover {
//   @media only screen and (max-width:1199px) {
//    ; padding: 0 10px
//   }
// }
.section_1 {
  display: block;
}

.section_2 {
  display: block;
}

.section_search {
  display: block;
}

.section_3 {
  display: block;
}

.section_4 {
  display: block;
}

.section_5 {
  display: block;
}

.section_6 {
  display: block;
}

.section_7 {
  display: block;
}

.section_8 {
  display: block;
}

.section_9 {
  display: block;
}

.mobile-women-category-header-section {
  position: sticky;
  top: 66px;
  width: 100%;
  z-index: 5;

  .mobile-women-category-back-alignment {
    display: flex;
    align-items: center;
    gap: 22px;
    padding: 8px 10px;
    background: rgba(248, 248, 248, 0.92);
    display: none;

    @media only screen and (max-width: '768px') {
      display: flex;
      position: sticky;
      top: 0;
      z-index: 9;
    }

    .back_details-alignment {
      background: #ffffff;
      border-radius: 10px;
      padding: 4px 9px 4px 10px;
      display: flex;
      align-items: center;
      gap: 8px;

      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #808191;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 58px;
      }
    }

    .mobile-heading-alignment {
      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: var(--secondary-900);
      }
    }
  }
}