@mixin block_btn {
    background: var(--primary-500);
    border-radius: 8px;
    display: block;
    width: 100%;
    border: unset;
    outline: unset;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 10px;
    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

    &:hover {
        transform: translateY(-3px);
        background: #2844ac;
    }
}

.art-craft-meet-our-artists-store-details-alignment {
    // padding: 16px;

    @media only screen and (max-width:"768px") {
        background: #fff;
        padding: 0;
    }

    .mobile-view-art-craft-back-alignment {
        display: none;

        @media only screen and (max-width:"768px") {
            display: flex;
            align-items: center;
            gap: 18px;
            padding: 8px 16px;
            background: rgba(248, 248, 248, 0.92);
            backdrop-filter: blur(13.5914px);
            justify-content: flex-start;
            // position: sticky;
            // top: 0;
            // z-index: 999;

            .art-craft-back_details-alignment {
                background: #ffffff;
                border-radius: 10px;
                padding: 4px 9px 4px 10px;
                display: flex;
                align-items: center;
                gap: 8px;

                p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #808191;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 58px;
                }
            }

            .mobile-heading-alignment {
                h4 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 27px;
                    text-align: center;
                    color: var(--secondary-900);
                }
            }
        }
    }


    .art-craft-meet-our-artists-store-banner-alignment {
        width: 100%;
        height: 178px;
        position: relative;
        // position: sticky;
        // top: 0;
        // z-index: 9999;

        @media only screen and (max-width:"768px") {
            height: 100px;
            top: 47px;
        }

        img {
            width: 100%;
            height: 100%;
            display: block;
            // border-radius: 8px;
            object-fit: cover;

            @media only screen and (max-width:"768px") {
                display: none;
            }

            &.mobile-view-banner {
                display: none;

                @media only screen and (max-width:"768px") {
                    display: block;
                }
            }
        }

        .art-craft-meet-our-artists-store-banner-details {
            position: absolute;
            top: 35px;
            left: 48px;

            @media only screen and (max-width:"768px") {
                top: 20px;
                left: 16px;
            }

            h4 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 40px;
                line-height: 60px;
                color: var(--secondary-900);

                @media only screen and (max-width:"768px") {
                    font-size: 20px;
                    line-height: 30px;
                }
            }

            p {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--secondary-900);
                max-width: 540px;

                @media only screen and (max-width:"768px") {
                    font-size: 12px;
                    line-height: 18px;
                    max-width: 165px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .art-craft-meet-our-artists-store-all-details-alignment {
        padding: 64px 80px;

        @media only screen and (max-width:"1099px") {
            padding: 18px 40px;
        }

        @media only screen and (max-width:"768px") {
            padding: 18px 40px;
        }

        .art-craft-meet-our-artists-store-all-details-grid {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 64px 32px;

            @media only screen and (max-width:"1024px") {
                grid-template-columns: repeat(4, 1fr);
                gap: 32px 16px;
            }

            @media only screen and (max-width:"968px") {
                grid-template-columns: repeat(3, 1fr);
                gap: 32px 16px;
            }

            @media only screen and (max-width:"767px") {
                grid-template-columns: repeat(2, 1fr);
                gap: 32px 16px;
            }

            .art-craft-meet-our-artists-store-all-details-gridItem {
                .art-craft-meet-our-artists-details-alignment {

                    .person-img-alignment {

                        height: 200px;
                        width: 100%;

                        @media only screen and (max-width:"768px") {
                            height: 180px;
                        }

                        @media only screen and (max-width:"768px") {
                            height: 140px;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                            object-fit: cover;
                        }
                    }

                    .art-craft-meet-our-artists-child-details-alignment {
                        text-align: center;

                        h5 {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 24px;
                            color: var(--secondary-900);
                            padding: 16px 0 0 0;
                        }

                        span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 18px;
                            color: #495057;
                            display: block;
                            padding: 4px 0 0 0;
                        }

                        p {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 21px;
                            color: #495057;
                            padding: 8px 0 0 0;
                        }

                        .art-craft-meet-our-artists-button-alignment {
                            margin: 16px 0 0 0;
                            @include block_btn();
                        }
                    }
                }
            }
        }
    }
}