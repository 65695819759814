.clubModalDetailsAllContentAlignment {
  max-height: 590px;
  overflow: auto;
  position: relative;
  padding: 0 16px 0 0;

  @media only screen and (max-width: '767px') {
    padding: 0 4px;
  }

  .clubModalDetailsCardGridItem {
    border-radius: 8px 8px 0px 0px;
    position: relative;
    overflow: hidden;
    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
    height: 251px;
    max-height: 251px;
    transition: 0.4s ease-in-out;

    // @media only screen and (max-width:"999px") {
    //   height: 186px;
    //   max-height: 186px;
    // }

    // @media only screen and (max-width: '599px') {
    //   height: 230px;
    //   max-height: 230px;
    // }

    // @media (max-width:576px) {
    //   height: 180px;
    //   max-height: 180px;
    // }

    &:hover {
      transform: translateY(-5px);
      transition: 0.4s ease-in-out;

      .clubModalDetailsCardImage {
        img {
          transform: scale(1.05);
          transition: 0.4s ease-in-out;
        }
      }
    }

    .clubModalDetailsCardImage {
      border-radius: 10px 10px 0 0;
      height: 251px;
      max-height: 251px;

      // @media only screen and (max-width:"999px") {
      //   height: 186px;
      //   max-height: 186px;
      // }

      // @media only screen and (max-width: '599px') {
      //   height: 230px;
      //   max-height: 230px;
      // }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.4s ease-in-out !important;
      }
    }

    .clubModalCardDetails {
      position: absolute;
      bottom: 0;
      left: 0;

      width: 100%;

      .clubModalCardDetailsAlignment {
        padding: 9px 9px 18px 9px;

        @media only screen and (max-width: '767px') {
          padding: 9px 16px 18px 16px;
        }

        @media (max-width:576px) {
          padding: 10px 14px;
        }

        .clubModalAllContentAlignment {
          display: flex;
          align-items: center;
          width: 100%;
          justify-content: space-between;

          .clubModalRightSideContent {
            button {
              background: rgba(55, 55, 55, 0.78);
              border-radius: 10px;
              padding: 0 11px;
              height: 20px;
              font-weight: 500;
              font-size: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              color: #fff;
              transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

              &.joinButton {
                border-radius: 8px;
                background: var(--primary-500);
              }
            }
          }

          .clubModalLeftSideContent {
            display: flex;
            align-items: center;
            gap: 4px;

            img {
              cursor: pointer;
            }

            span {
              display: block;
              color: #fff;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
            }
          }

          .clubModalusers {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .clubModalUserIcon {
              display: flex;
              align-items: center;
              gap: 4px;

              .clubModaLeftPart {
                display: flex;
                align-items: center;
                padding-left: 10px;

                .clubModaUsersLikes {
                  display: flex;
                  align-items: center;
                  margin-left: -10px;

                  .clubModalUserBox {
                    width: 20px;
                    display: flex;
                    align-items: center;
                    height: 20px;
                    justify-content: center;
                    background: #fff;
                    object-position: center;
                    border: 1px solid #ffffff;
                    border-radius: 30px;
                    margin: 0 0 0 -10px;
                    min-width: 20px;
                    max-width: 20px;

                    &:first-child {
                      margin: 0;
                    }

                    .clubLazyImg {
                      width: 100%;
                      min-width: 100%;
                      max-width: 100%;

                      img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        object-fit: cover !important;
                      }
                    }
                  }
                }

                .clubModaCount {
                  margin-left: -10px;
                  border-radius: 12px;
                  border: 1px solid #fff;
                  background: #1e2432;
                  border-radius: 12px;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 18px;
                  letter-spacing: 0.1px;
                  color: #ffffff;
                  padding: 0px 9px;
                }
              }
            }

            .clubModalRightSideContent {
              button {
                border-radius: 8px;
                opacity: 0.6000000238418579;
                background: #1e2432;
                padding: 0 32px;
                height: 32px;
                color: #fff;
                text-align: center;
                font-size: 14px;
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                letter-spacing: 0.1px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                color: #fff;
                transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

                &.joinButton {
                  border-radius: 8px;
                  background: var(--primary-500);
                  opacity: 1;

                  &:hover {
                    transform: translateY(-3px);
                    background: #2844ac;
                  }
                }
              }
            }
          }
        }

        h5 {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #fff;
          margin: 0 0 18px 0;
        }

        .clubModalLimeCamp {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        h4 {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #fff;
          margin: 0 0 1px 0;
          cursor: pointer;

          @media (max-width:576px) {
           font-size: 14px;
           line-height: 20px; 
          }
        }
      }
    }

    .clubModalblurDiv {
      position: absolute;
      background: linear-gradient(360deg, rgba(30, 36, 50, 0.8) 0%, rgba(30, 36, 50, 0) 100%);
      border-radius: 0px;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 251px;

      @media only screen and (max-width: '599px') {
        height: 100%;
      }
    }
  }
}