.addProductModalHeadingAlignment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  background: #fff;
  padding: 16px 0 0 0;

  @media only screen and (max-width:"1024px") {
    display: none;
  }

  .headingAlignment {
    padding: 0 0 0 14px;
    max-width: 439px;


    h2 {
      font-family: 'Poppins', sans-serif;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      color: var(--secondary-900);

      @media only screen and (max-width:"1199px") {
        font-size: 20px;
        line-height: 24px;
      }

      span {
        padding: 5px 8px;
        border-radius: 12px;
        background: var(--green-50);
        color: #1D8603;
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        position: relative;
        top: -2px;
        left: 8px;
      }

    }


    .draftBox {


      p {
        padding: 5px 8px;
        border-radius: 12px;
        background: var(--secondary-50);
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: var(--secondary-700);
      }
    }
  }

  .addProductOtherOptionAlignment {
    display: flex;
    align-items: center;
    gap: 16px;

    .peviewDeleteButtonAlignment {
      display: flex;
      align-items: center;
      gap: 8px;

      a {
        display: block;
        padding: 6px 16px;
        color: var(--primary-500);
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        cursor: pointer;
      }

      button {
        background: #f8f8f8;
        border-radius: 8px;
        width: 32px;
        height: 32px;
        justify-content: center;
        border: none;
        display: flex;
        align-items: center;
        gap: 4px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: var(--primary-500);
        cursor: pointer;

        &:hover {
          background: var(--primary-100);
        }

        &.deleteIcon {
          border-radius: 8px;
          background: #FDE8E8;

          &:hover {
            background: #F8B7B7;
          }
        }

        img {
          width: 18px;
          height: 18px;
          display: block;
        }
      }
    }

    .publishedAndSaveButtonAlignment {
      display: flex;
      align-items: center;
      gap: 8px;

      .publishBoxAlignment {
        border-radius: 22px;
        border: 1px solid var(--secondary-50);
        height: 32px;
        padding: 0 3px 0 12px;
        display: flex;
        align-items: center;
        gap: 14px;

        p {
          color: var(--secondary-900);
          font-family: 'Poppins', sans-serif;
          font-size: 18px;
          font-weight: 500;
          line-height: 24px;
        }
      }

      .saveButton {
        button {
          background: var(--primary-500);
          border-radius: 8px;
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: #f8f8f8;
          width: 120px;
          height: 32px;
          cursor: pointer;
          border: none;
          transition: 0.4s ease-in-out;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background: var(--primary-700);
            transition: 0.4s ease-in-out;

          }

          &.savingButton {
            display: flex;
            align-items: center;
            gap: 12px;

            img {
              width: 18px;
              height: 18px;
              display: block;
            }
          }

          &.savedButton {
            border-radius: 8px;
            background: var(--primary-100);
          }
        }
      }
    }
  }
}