.explore-banner-slider {
  display: flex;
  justify-content: space-between;

  .slick-slide {
    padding: 0 10px;
  }
  .brand-alignment-main {
    box-shadow: 6px 6px 54px rgba(0, 0, 0, 0.05);
    margin-bottom: 14px;
  }
  @media screen and (max-width: 767px) {
    display: block;
  }

  .slick-dots {
    top: 121px !important;
    left: 155px !important;
    height: 34px !important;
    li {
      width: 6px !important;

      button {
        width: 8px;
        padding: 0;

        &:before {
          content: '';
          width: 6px !important;
          height: 6px !important;
          background: #eceef4 !important;
          border-radius: 8px;
          opacity: 1;
        }
      }

      &.slick-active {
        width: 20px;

        button {
          &:before {
            width: 6px !important;
            background: #ff7d23 !important;
          }
        }
      }
    }
  }
}

.feature-alignment {
  width: 177px;
  height: 170px;
  border-radius: 8px;

  img {
    width: 100%;
    height: 108px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin: 0 !important;
    padding-left: 60px;
    object-fit: contain;
  }
  p {
    font-weight: 300;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: -0.075em;
    color: #eaeaea;
    padding-left: 5px;
  }
  span {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    padding-left: 5px;
  }
}

.explore-image-wrapper {
  height: 200px;
  width: 100%;
  background-color: #f7f8fa;
  border-radius: 8px 8px 0 0;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: top;
    padding: 10px;
  }
}

.price-tag-latest {
  display: flex;
  justify-self: center;
  align-items: center;
  gap: 5px;
  p {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    display: flex;
    align-items: center;
    color: #333333;
  }
  span {
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-decoration-line: line-through;
    color: #828282;
    text-decoration: line-through;
  }
}

.latest-prod-alignment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px 0px 16px;
  .text-area-align {
    background: #ffffff;
    border-radius: 4px;
    width: 53px;
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    p {
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #000000;
    }
  }
  svg {
    width: 13.33px;
    height: 11.44px;
  }
}

.feature-store-slider {
  .slick-slide {
    padding: 0 10px;
  }
  .brand-alignment-main {
    box-shadow: 6px 6px 54px rgb(0 0 0 / 5%);
  }
}

.explore-featurestore-wrapper {
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    // object-position: top;
  }
}

.location-name-align {
  // width: 72px;
  // height: 18px;
  // left: 10px;
  // top: 136px;
  // background: rgba(85, 110, 230, 0.1);
  // border-radius: 5px;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  margin: 10px 0px !important;
  p {
    font-weight: 300;
    font-size: 8px;
    line-height: 12px;
    color: #7e7e84;
  }
}

.store-name-align {
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    padding-top: 12px;
    display: flex;
    align-items: center;
    color: #3b3b3d;
  }
  span {
    font-weight: 300;
    font-size: 9px;

    color: #7e7e84;
  }
}

.detail-prod-follow {
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 10px;
  .followers-info {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    gap: 2px;
    img {
      width: 8px;
      height: 8px;
    }
    span {
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      /* identical to box height */

      /* Grey2 */

      color: #808191;
    }
  }

  .product-info {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    gap: 2px;
    img {
      width: 8px;
      height: 8px;
    }
    span {
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      /* identical to box height */

      /* Grey2 */

      color: #808191;
    }
  }
}

.store-button-detail {
  background: var(--primary-500);
  border-radius: 8px;
  width: 100%;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    font-weight: 500;
    font-size: 12px;
    line-height: 10px;
    /* or 83% */

    letter-spacing: 0.05em;

    color: #ffffff;
  }
}

.see-all-button {
  p {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: var(--primary-500);
  }
}

.grid-cols {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  // margin: auto 0;
  column-gap: 13px;
}

.grid-cols-second {
  grid-template-rows: repeat(1, 1fr);
}

.image-around-font {
  h4 {
    padding-left: 14px;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #ffffff;
    bottom: 129px;
    position: absolute;
  }
  p {
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: #ffffff;
    position: absolute;
    bottom: 116px;
    padding-left: 14px;
  }
}

.image-around-font-third {
  position: relative;
  .image-around-font-main {
    position: absolute;
    top: 8px;
    left: 12px;
    h4 {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #ffffff;
    }
    p {
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      color: #ffffff;
    }
  }
  img {
    top: 55px;
    left: 12px;
  }
}

.top-proile-menu {
  margin-top: -7px;
}
