.createClubInvitePeopleSection {
    padding: 32px;
    height: calc(100vh - 287px);
    overflow-y: auto;

    @media only screen and (max-width:"1024px") {
        // height: calc(100vh - 342px);
        height: 100%;
    }

    @media only screen and (max-width:"767px") {
        padding: 16px 16px 82px 16px;
    }

    .createClubInvitePeopleSearchAlignment {
        width: 282px;
        height: 40px;
        background: var(--light-grey);
        display: flex;
        align-items: center;
        border-radius: 8px;
        position: relative;

        @media only screen and (max-width:"767px") {
            width: 100%;
        }

        input {
            width: 100%;
            height: 100%;
            background: transparent;
            border: none;
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            &::placeholder {
                color: var(--secondary-500);

            }
        }

        .searchDetailsBoxAlignment {
            border-radius: 10px;
            background: #FFF;
            box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.10);
            width: 384px;
            height: auto;
            max-height: 256px;
            overflow-y: auto;
            padding: 16px;
            position: absolute;
            top: 48px;
            left: 0;
            z-index: 999;
            padding: 16px;

            @media only screen and (max-width:"767px") {
                width: 100%;
            }

            .searchDetailsFlexAlignment {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 0 16px 0;

                .searchDetailsLeftide {
                    display: flex;
                    align-items: center;
                    gap: 12px;

                    .searchDetailsProfileImg {
                        width: 32px;
                        max-width: 32px;
                        min-width: 32px;
                        height: 32px;

                        img {
                            width: 100%;
                            height: 100%;
                            display: block;
                            object-fit: cover;
                        }
                    }

                    h6 {
                        color: var(--secondary-900);
                        font-family: 'Poppins', sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }

                .addButtonAlignment {
                    button {
                        padding: 6px 16px;
                        border-radius: 8px;
                        background: var(--primary-50);
                        color: var(--primary-500);
                        text-align: center;
                        font-family: 'Poppins', sans-serif;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px;
                        cursor: pointer;
                    }
                }

                .addedButtonAlignment {
                    button {
                        padding: 6px 16px;
                        border-radius: 8px;
                        background: var(--light-grey);
                        color: var(--secondary-500);
                        text-align: center;
                        font-family: 'Poppins', sans-serif;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 20px;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .createClubInviteAllNameAlignment {
        margin: 8px 0 0 0;

        .createClubInviteFlexAlignment {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px 12px 8px 8px;
            transition: 0.4s ease-in-out;

            &:hover {
                border-radius: 10px;
                background: var(--primary-50);
                transition: 0.4s ease-in-out;

                .createClubCloseIcon {
                    display: block;
                    transition: 0.4s ease-in-out;
                }
            }

            .createClubInvitePeopleDetails {
                width: 100%;
                display: flex;
                align-items: center;
                gap: 12px;


                .createClubInvitePeopleImg {
                    width: 32px;
                    max-width: 32px;
                    min-width: 32px;
                    height: 32px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        display: block;
                        border-radius: 50%;
                    }

                }

                h6 {
                    color: #000;
                    font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }


            .createClubCloseIcon {
                display: none;

                @media only screen and (max-width:"767px") {
                    display: block;
                }

                svg {
                    cursor: pointer;

                    @media only screen and (max-width:"767px") {
                        width: 20px;
                        height: 20px;
                    }

                }
            }
        }

    }
}