.shop-by-category-section {
  // display: none;
  .container {
    max-width: 1440px;
    margin: auto;
    padding: 0;

    .shop-by-category-alignment {
      padding: 48px 56px;

      @media only screen and (max-width: '767px') {
        padding: 20px 0 0 0;
      }

      .shop-by-category-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 40px;

        @media only screen and (max-width: '767px') {
          padding: 0 16px;
        }

        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 36px;
          line-height: 54px;
          color: var(--secondary-900);

          @media only screen and (max-width: '767px') {
            font-size: 20px;
            line-height: 30px;
          }
        }

        .shop-by-category-see-more-alignment {
          a {
            display: flex;
            align-items: center;
            gap: 8px;
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: var(--primary-500);
            cursor: pointer;

            @media only screen and (max-width: '767px') {
              font-size: 14px;
              line-height: 21px;

              img {
                display: none;
              }
            }

            &:hover {
              gap: 10px;
              text-decoration: underline;
            }
          }
        }
      }

      .shop-by-category-details-alignment {
        padding: 48px 0 0 0;

        @media only screen and (max-width: '767px') {
          padding: 16px 0 0 0;
        }

        .shop-by-category-grid-alignment {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          gap: 32px;

          @media only screen and (max-width: '1099px') {
            grid-template-columns: repeat(3, 1fr);
          }

          @media only screen and (max-width: '899px') {
            grid-template-columns: repeat(3, 1fr);
          }

          @media only screen and (max-width: '767px') {
            display: none;
          }

          .shop-by-category-gridItem-alignment {
            transition: 0.4s ease-in-out;

            &:hover {
              transform: translateY(-4px);

              p {
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
              }
            }

            .shop-by-category-img-alignment {
              width: 100%;
              height: 240px;

              img {
                width: 100%;
                height: 100%;
                cursor: pointer;
                display: block;
                border-radius: 10px;
              }
            }

            p {
              padding: 16px 0 0 0;
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 18px;
              line-height: 32px;
              text-align: center;
              color: var(--secondary-900);
              cursor: pointer;
            }
          }
        }

        .mobile-shop-by-category-alignment {
          display: none;

          @media only screen and (max-width: '767px') {
            display: block;

            .mobile_view_shop-by-category_details_alignment {
              .mobile_view_shop-by-category-view_gridItem {
                padding: 0 8px 16px 8px;

                .mobile_view_shop-by-category-imgbox-alignment {
                  width: 98px;
                  height: 98px;

                  img {
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                  }
                }

                .mobile_view_shop-by-category-product_name-alignment {
                  padding: 8px 0 0 0;

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    text-align: center;
                    color: var(--secondary-900);
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
