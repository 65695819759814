.art-craft-trending-store-details-section {
    // padding: 16px;

    @media only screen and (max-width:"768px") {
        background: #fff;
        padding: 0;
    }

    .mobile-view-art-craft-back-alignment {
        display: none;

        @media only screen and (max-width:"768px") {
            display: flex;
            align-items: center;
            gap: 18px;
            padding: 8px 16px;
            background: rgba(248, 248, 248, 0.92);
            backdrop-filter: blur(13.5914px);
            justify-content: flex-start;
            // position: sticky;
            // top: 0;
            // z-index: 999;

            .art-craft-back_details-alignment {
                background: #ffffff;
                border-radius: 10px;
                padding: 4px 9px 4px 10px;
                display: flex;
                align-items: center;
                gap: 8px;

                p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #808191;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 58px;
                }
            }

            .mobile-heading-alignment {
                h4 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 27px;
                    text-align: center;
                    color: var(--secondary-900);
                }
            }
        }
    }


    .art-craft-trending-store-banner-alignment {
        width: 100%;
        height: 178px;
        position: relative;
        // position: sticky;
        // top: 0;
        // z-index: 999;

        // @media only screen and (max-width:"768px") {
        //     height: 100px;
        //     top: 47px;
        // }

        img {
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 0;
            object-fit: cover;

            @media only screen and (max-width:"768px") {
                display: none;
            }

            &.mobile-view-banner {
                display: none;

                @media only screen and (max-width:"768px") {
                    display: block;
                }
            }
        }

        .art-craft-trending-store-banner-details {
            position: absolute;
            top: 35px;
            left: 48px;

            @media only screen and (max-width:"768px") {
                top: 20px;
                left: 16px;
            }

            h4 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 40px;
                line-height: 60px;
                color: var(--secondary-900);

                @media only screen and (max-width:"768px") {
                    font-size: 20px;
                    line-height: 30px;
                }
            }

            p {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--secondary-900);
                max-width: 540px;

                @media only screen and (max-width:"768px") {
                    font-size: 12px;
                    line-height: 18px;
                    max-width: 165px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    .art-craft-trending-store-details-alignment {
        padding: 18px 0 0 0;

        @media only screen and (max-width: '768px') {
            padding: 18px;
        }

        .art-craft-trending-store-grid-alignment {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 16px;

            @media only screen and (max-width: '1024px') {
                grid-template-columns: repeat(4, 1fr);
            }

            @media only screen and (max-width: '968px') {
                grid-template-columns: repeat(3, 1fr);
            }

            @media only screen and (max-width: '768px') {
                grid-template-columns: repeat(2, 1fr);
            }

            .art-craft-trending-store-gridItem-alignment {
                .art-craft-details-box-alignment {
                    padding: 8px;
                    background: #FFFFFF;
                    border: 1px solid var(--secondary-50);
                    border-radius: 16px;

                    &:hover {
                        transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                        transform: translateY(-6px);
                    }

                    .art-craft-details-logo-alignment {
                        height: 240px;
                        width: 100%;

                        @media only screen and (max-width: '768px') {
                            height: 160px;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .art-craft-child-details-alignment {
                        padding: 14px 0 0 0;
                        cursor: pointer;

                        h6 {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 30px;
                            text-align: center;
                            color: #000000;
                        }

                        p {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 21px;
                            color: #495057;
                            text-align: center;
                        }

                        .art-craft-product {
                            margin: 16px 0 8px 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 4px;

                            @media only screen and (max-width: '768px') {
                                flex-wrap: wrap;
                                gap: 2px;
                                margin: 9px 0 8px 0;
                            }

                            span {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 21px;
                                color: #495057;

                                @media only screen and (max-width: '768px') {
                                    font-size: 12px;
                                    line-height: 18px;
                                    margin: 0 0 2px 0;
                                    padding: 0;
                                }
                            }

                            p {
                                width: 3px;
                                height: 3px;
                                border-radius: 50%;
                                background: #495057;

                                @media only screen and (max-width: '768px') {
                                    display: none;
                                }
                            }
                        }

                        .art-craft-visitor {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-wrap: wrap;
                            gap: 2px;

                            span {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 21px;
                                color: #495057;

                                @media only screen and (max-width: '768px') {
                                    font-size: 12px;
                                    line-height: 18px;
                                    padding: 0;
                                }
                            }

                            .art-craft-pre {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 4px;
                            }
                        }

                        .follow-button-alignment {
                            padding: 16px 8px 8px 8px;

                            @media only screen and (max-width: '768px') {
                                padding: 14px 0 0 0;
                            }

                            button {
                                width: 100%;
                                height: 32px;
                                background: var(--primary-500);
                                border-radius: 8px;
                                font-family: 'Poppins', sans-serif;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 21px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #f8f8f8;
                                cursor: pointer;

                                &.following {
                                    background: #f8f8f8;
                                    color: var(--primary-500);

                                    &:hover {
                                        background: #eef1fc;
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }
    }
}