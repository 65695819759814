.sellerCustomersChildDetailsModalSection {
    .sellerCustomersChildDetailsModalBg {
        background: var(--white);
        padding: 22px 16px;
        border-radius: 10px;

        .sellerCustomersChildDetailsHeading {
            padding: 0 16px;

            .sellerCustomersChildDetailsTopheading {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .sellerCustomersChildDetailsHeadingName {

                    display: flex;
                    align-items: center;
                    gap: 8px;

                    h6 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 36px;
                        color: var(--secondary-900);
                    }

                    .sellerCustomerTotalNumber {
                        padding: 0 8px;
                        background: var(--secondary-50);
                        border-radius: 10px;

                        span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 20px;
                            color: var(--secondary-500);

                        }
                    }
                }

                .importCsvSection {
                    display: flex;
                    align-items: center;
                    gap: 2px;
                    padding: 6px 16px;
                    cursor: pointer;

                    .importIconAlignment {
                        width: 16px;
                        height: 16px;

                        img {
                            width: 100%;
                            height: 100%;
                            display: block;
                        }
                    }

                    p {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 20px;
                        color: var(--primary-500);
                    }
                }

            }

            .sellerCustomerFilterSectionAlignment {
                display: flex;
                align-items: center;
                gap: 32px;
                padding: 16px 0 0 0;

                .sellerFilterAndSearchAlignment {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    width: 100%;

                    .filterBoxAlignment {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        padding: 6px 16px;
                        border: 1.5px solid var(--secondary-100);
                        border-radius: 8px;
                        cursor: pointer;

                        .filterIconAlignment {
                            width: 16px;
                            height: 16px;

                            img {
                                width: 100%;
                                height: 100%;
                                display: block;
                            }
                        }

                        span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 15px;
                            line-height: 20px;
                            color: var(--secondary-600);
                        }
                    }

                    .searchCustomerBoxAlignment {
                        width: 100%;
                        height: 32px;
                        padding: 6px 8px;
                        background: var(--primary-1);
                        border-radius: 8px;
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        .searchIconAlignment {
                            width: 16px;
                            height: 16px;

                            img {
                                width: 100%;
                                height: 100%;
                                display: block;
                            }
                        }

                        input {
                            width: 100%;
                            height: 100%;
                            background: transparent;
                            border: none;
                            font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 21px;

                            &::placeholder {
                                color: var(--grey3);
                            }
                        }
                    }
                }

                .sellerCustomerFilterSSortDropdown {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    label {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        color: var(--secondary-900);
                        margin: 0;
                    }
                }
            }
        }

        .sellerCustomerDetailsTableAlignment {
            margin: 32px 0 0 0;
            height: calc(100vh - 240px);
            overflow-y: auto;
            background: #fff;


            .sellerCustomerDetailsTable {
                table {
                    width: 100%;
                    border-collapse: separate;
                    border-spacing: 0 8px;

                    thead {
                        position: sticky;
                        top: 0;
                        background: #fff;
                        z-index: 999;

                        tr {
                            th {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 24px;
                                color: var(--secondary-900);
                                padding: 0 16px 8px 16px;

                                &.alignCenter {
                                    text-align: center;
                                }
                            }
                        }
                    }

                    tbody {
                        tr {
                            padding: 0 16px;
                            cursor: pointer;

                            &:hover {
                                background: var( --primary-50);
                                border-radius: 8px;
                            }

                            td {
                                padding: 8px 16px;

                                .sellerCustomerProfile {
                                    display: flex;
                                    align-items: center;
                                    gap: 8px;

                                    .sellerCustomerProfileImg {
                                        width: 32px;
                                        height: 32px;

                                        img {
                                            width: 100%;
                                            height: 100%;
                                            border-radius: 50%;
                                            object-fit: cover;
                                        }
                                    }

                                    p {
                                        font-family: 'Poppins', sans-serif;
                                        font-weight: 400;
                                        font-size: 16px;
                                        line-height: 24px;
                                        color: var(--black);
                                    }
                                }

                                .productItemTotal {
                                    text-align: center;

                                    span {
                                        font-family: 'Poppins', sans-serif;
                                        font-weight: 400;
                                        font-size: 16px;
                                        line-height: 24px;
                                        color: var(--secondary-500);
                                    }
                                }

                                .productTotalSpan {
                                    p {
                                        font-family: 'Poppins', sans-serif;
                                        font-weight: 400;
                                        font-size: 16px;
                                        line-height: 24px;
                                        color: var(--black);
                                        text-align: center;
                                    }
                                }

                                .productActionFlex {
                                    display: flex;
                                    align-items: center;
                                    gap: 30px;
                                    justify-content: center;

                                    img {
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .sellerCustomerDetailsTable table thead tr td:nth-child(1) {
                width: 40%;
            }

            .sellerCustomerDetailsTable table thead tr td:nth-child(1) {
                width: 25%;
            }

            .sellerCustomerDetailsTable table thead tr td:nth-child(1) {
                width: 20%;
            }

            .sellerCustomerDetailsTable table thead tr td:nth-child(1) {
                width: 15%;
            }
        }
    }
}