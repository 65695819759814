.withdrawTransactionDetailModalSection {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 5.06033182144165px 50.60331726074219px 0px rgba(144, 143, 143, 0.10);
    padding: 16px 24px;
    height: calc(100vh - 96px);
    overflow-y: auto;

    .withdrawTransactionDetailModalHeading {
        h4 {
            color: var(--secondary-900);
            font-family: 'Poppins', sans-serif;
            font-size: 24px;
            font-weight: 600;
            line-height: 28px;
        }
    }

    .withdrawTransactionDetailModalBannerDetails {
        padding: 76px 0 0 0;
        display: flex;
        align-items: center;
        gap: 21px;

        .withdrawTransactionDetailModalIcon {
            width: 80px;
            height: 80px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .withdrawDetails {
            h6 {
                color: var(--secondary-900);
                font-family: 'Poppins', sans-serif;
                font-size: 24px;
                font-weight: 600;
                line-height: 28px;
            }

            p {
                color: var(--secondary-500);
                font-family: 'Poppins', sans-serif;
                font-size: 13px;
                font-weight: 400;
                line-height: 19px;
                padding: 4px 0 0 0;
            }

            .pendingStatusAlignment {
                padding: 8px 0 0 0;

                button {
                    border-radius: 16px;
                    background: #FFF2E9;
                    padding: 3px 14px;
                    color: #E87220;
                    font-family: 'Poppins', sans-serif;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 19px;
                }
            }
        }
    }

    .withdrawTransactionDetailsSlipAlignment {
        padding: 148px 0 0 0;

        .withdrawTransactionDetailsSlipChildDetails {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 32px 12px;
            border-bottom: 1px solid var(--secondary-50);

            &:first-child {
                padding: 0 12px 32px 12px;
            }

            &:last-child {
                border-bottom: none;
            }

            p {
                color: var(--secondary-900);
                font-family: 'Poppins', sans-serif;
                font-size: 13px;
                font-weight: 400;
                line-height: 19px;
            }

            h6 {
                color: var(--secondary-900);
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
            }

            span {
                color: var(--secondary-900);
                text-align: right;
                font-family: 'Poppins', sans-serif;
                font-size: 13px;
                font-weight: 400;
                line-height: 19px;
                display: block;
            }

            p {
                color: var(--secondary-500);
                font-family: 'Poppins', sans-serif;
                font-size: 13px;
                font-weight: 500;
                line-height: 19px;
                text-align: right;
            }
        }

    }
}