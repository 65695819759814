.requestSubmittedSection {
    .requestSubmittedAlignment {
        margin: 150px 0;

        .submissionTopAlignment {
            text-align: center;

            .submissionIconAlignment {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 0 32px 0;

            }

            p {
                max-width: 208px;
                margin: 0 auto;
                color: var(--secondary-900);
                text-align: center;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
            }
        }

        .submissionBottomAlignment {
            margin: 132px 0 0 0;
            text-align: center;

            .closeButtonAlignment {
                height: 40px;
                width: 232px;
                margin: 0 auto 48px auto;

                button {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                    background: var(--primary-500);
                    color: #FFF;
                    text-align: center;
                    font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                    cursor: pointer;
                }
            }

            .checkStatus {
                a {
                    padding: 2px 10px;
                    color: var(--primary-500);
                    text-align: center;
                    font-family: 'Poppins', sans-serif;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 20px;
                    cursor: pointer;
                }
            }
        }
    }
}