.AddProductCollectionModal {
    width: 375px;
    padding: 16px 16px 30px 16px;
    position: absolute;
    top: -250px;
    right: 14px;
    border-radius: 16px;
    background: #FFF;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    .AddProductCollectiontitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 32px 0;

        h1 {
            color: #1E2432;
            font-family: "Poppins", system-ui;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        img {
            cursor: pointer;
        }
    }


    .slick-slide {
        div {
            height: auto !important;
            padding: 0 0 !important;

            @media screen and (max-width: 767px) {
                height: auto !important;
            }
        }
    }
}