.mobileJewelryFansModalSection {
    .mobileJewelryFansModalAlignment {
        .mobileJewelryFansModalHeading {
            display: flex;
            align-items: center;
            gap: 70px;
            padding: 11px 16px 10px 16px;

            h6 {
                color: var(--secondary-900);
                text-align: center;
                font-family: 'Poppins', sans-serif;
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
            }
        }

        .mobileJewelryFansModalBodyDetailsAlignment {
            padding: 16px 40px;

            .mobileJewelryFansModalBodyGridAlignment {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 16px;

                .mobileJewelryFansModalBodyGridItem {}
            }
        }
    }
}