// .Invitefriendemailmodelback {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background: rgba(0, 0, 0, 0.57);
//     z-index: 999999;
//     transition: opacity 0.3s ease, transform 0.3s ease;
//     opacity: 1;
//     transform: translateY(0);
//     cursor: pointer;

//     &.hidden {
//         opacity: 0;
//         transform: translateY(100%);
//     }
// }

.Invitefriendemailmodelmain {
  // position: fixed;
  // bottom: 62px;
  // left: 0;
  // z-index: 999999;
  background-color: #fff;
  width: 100%;
  // border-radius: 8px 8px 0 0;
  // transition: opacity 0.3s ease, transform 0.3s ease;
  // opacity: 1;
  // transform: translateY(0);

  // &.hidden {
  //     opacity: 0;
  //     transform: translateY(100%);
  // }

  // .Invitefriendmodeltop {
  //     position: relative;
  //     width: 100%;
  //     height: 27px;

  //     &::before {
  //         content: "";
  //         position: absolute;
  //         top: 50%;
  //         left: 50%;
  //         transform: translate(-50%, -50%);
  //         border-radius: 1.5px;
  //         width: 33px;
  //         height: 3px;
  //         background: #e4e4e8;
  //     }
  // }

  .Invitefrientitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 24px 0;
    padding: 0 16px;

    p {
      color: #495057;
      font-family: 'Poppins', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    span {
      color: #556ee6;
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;
    }
  }

  .Sharepera {
    p {
      color: #556ee6;
      font-family: 'Poppins', sans-serif;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
      margin: 0 0 24px 0;
    }
  }

  .searchdivmain {
    position: relative;
    margin: 0 16px 16px;

    input {
      border-radius: 10px;
      background: #f8f8f8;
      padding: 10px 44px;
      color: #acb1c0;
      text-align: center;
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      width: 100%;
    }

    .searchicon {
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }

  .friendsselcteddivmain {
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin: 0 16px 31px;

    p {
      color: #808191;
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0 0 6px 0;
    }

    span {
      color: #556ee6;
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;
    }
  }

  ::-webkit-scrollbar {
    width: 3px !important;
    display: block !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d9d9d9 !important;
  }

  .friendsemaildivmain {
    // min-height: 365px;
    max-height: 312px;
    height: 100%;
    overflow-y: auto;
    margin: 0 16px;

    .friendsemaildiv {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 13px 16px 6px;
      border-radius: 10px;
      cursor: pointer;
      transition: 0.3s ease-in-out;

      &:hover,
      &.active {
        transition: 0.3s ease-in-out;
        background: rgba(85, 110, 230, 0.13);
      }

      p {
        color: #000;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
      }

      .customradiobox {
        width: 20px;
        height: 20px;
        border: 2px solid #acb1c0;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s ease-in-out;

        &.checked {
          transition: 0.3s ease-in-out;
          border-color: #556ee6;

          .customradioboxcircle {
            transition: 0.3s ease-in-out;
            background-color: #556ee6;
          }
        }

        .customradioboxcircle {
          transition: 0.3s ease-in-out;
          width: 10px;
          height: 10px;
          border-radius: 4px;
          background-color: transparent;
        }
      }
    }
  }

  .sendinvitationbutton {
    display: flex;
    justify-content: end;
    align-items: end;
    padding: 20px 16px;

    button {
      border-radius: 8px;
      background: #556ee6;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      text-align: center;
      font-family: 'Poppins', sans-serif;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 6px 12px;

      &:disabled {
        color: var(--white);
        background: var(--primary-500);
        border-color: var(--primary-500);
        cursor: not-allowed;
        opacity: 0.4;
      }
    }
  }
}
