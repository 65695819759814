.mobileViewProductVariantModal {
    background: #FFF;
    height: 100vh;
    overflow-y: auto;

    .mobileViewProductHeadingAlignment {
        position: sticky;
        top: 0;
        z-index: 999;
        left: 0;
        width: 100%;
        background: #FFF;

        .mobileViewHeading {
            display: flex;
            align-items: center;
            gap: 24px;
            padding: 10px 16px;

            .headerBackArrow {
                width: 37px;
                height: 32px;
            }

            h4 {
                color: var(--secondary-900);
                text-align: center;
                font-family: 'Poppins', sans-serif;
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
            }
        }

        .mobileViewTabAlignment {
            padding: 16px 0 16px 16px;

            ul {
                display: flex;
                align-items: center;
                gap: 8px;

                li {
                    border-radius: 10px;
                    border: 1px solid var(--secondary-50);
                    padding: 8px 12px 8px 16px;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    position: relative;


                    .allProductVarintTabDropdownBox {
                        position: absolute;
                        top: 40px;
                        width: 100%;
                        left: 0;
                        border-radius: 10px;
                        z-index: 999;
                        background: #FFF;
                        box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.12);

                        &.colorWidthAlignment {
                            width: fit-content;
                        }

                        .allProdutctoptionAlignment {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 4px;
                            padding: 4px 16px;
                            transition: all 0.4s ease-in-out;

                            .colorBoxAlignment {
                                width: 16px;
                                min-width: 16px;
                                max-width: 16px;
                                height: 16px;
                                border-radius: 4px;
                                background: conic-gradient(from 180deg at 50% 50%, #F00 0deg, #FFF500 115.2117669582367deg, #0075FF 229.73557949066162deg, #F00 360deg);
                            }

                            p {
                                color: var(--secondary-900);
                                font-family: 'Poppins', sans-serif;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 24px;
                                white-space: nowrap;
                            }

                            &:hover {
                                transition: all 0.4s ease-in-out;
                                background: var(--secondary-50);
                            }
                        }

                        &.closeVariantDropdown {
                            max-height: 0px;
                            overflow-y: hidden;
                            transition: all 0.4s ease-in-out;

                        }

                        &.openVariantDropdown {
                            border: 1px solid #FFF;

                            max-height: 176px;
                            padding: 12px 0;
                            transition: all 0.4s ease-in-out;
                            overflow-y: auto;
                        }
                    }
                }
            }
        }

        .mobileViewFilterAlignment {
            display: none;

            @media only screen and (max-width:"1024px") {
                display: block;
                padding: 20px 16px 16px 16px;

                .mobileViewFilterBox {
                    padding: 8px 16px 8px 8px;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    border-radius: 8px;
                    border: 0.5px solid var(--grey4);
                    background: var(--light-grey);
                    width: fit-content;

                    p {
                        color: var(--secondary-700);
                        font-family: 'Poppins', sans-serif;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 21px;
                    }
                }

                .mobileViewFilterDetailsFlex {
                    margin: 14px 0 0 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 16px;

                    .allFilterNameAlignment {
                        width: 100%;
                        overflow-x: auto;
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        .mobileChildViewFilterBox {
                            border-radius: 16px;
                            background: var(--light-grey);
                            min-width: 94px;
                            width: auto;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            height: 32px;
                            padding: 0 8px 0 16px;

                            .filterName {
                                display: flex;
                                align-items: center;
                                gap: 4px;

                                span {
                                    color: var(--secondary-900);
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 16px;
                                    font-weight: 400;
                                    line-height: 20px;
                                }

                                .mobileColorMoxAlignment {
                                    width: 16px;
                                    height: 16px;
                                    border-radius: 4px;
                                    background: #F10000;
                                }
                            }


                            .mobileCloseIcon {
                                width: 16px;
                                height: 16px;

                            }

                        }
                    }

                    .clearFilterAlignment {
                        a {
                            color: var(--primary-500);
                            font-family: 'Poppins', sans-serif;
                            font-size: 15px;
                            font-weight: 400;
                            line-height: 22px;
                        }
                    }
                }
            }
        }
    }

    .mobileVIewProductBodyAlignment {
        .mobileViewDetailsBox {
            padding: 16px;
            border-bottom: 1px solid var(--secondary-50);
            display: flex;
            align-items: center;
            gap: 16px;

            &:first-child {
                border-top: 1px solid var(--secondary-50);

            }

            .mobileViewProductImg {
                width: 48px;
                height: 48px;
                min-width: 48px;
                max-width: 48px;

                img {
                    width: 100%;
                    height: 100%;
                    display: block;
                    object-fit: cover;
                    border-radius: 8px;
                }


            }

            .noImgBoxAlignment {
                background: rgba(85, 110, 230, 0.13);
                border: 2px dashed #b1c2f3;
                border-radius: 8px;
                width: 48px;
                height: 48px;
                min-width: 48px;
                max-width: 48px;

                position: relative;
                padding: 16px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;

                p {
                    font-family: 'Poppins', sans-serif;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14px;
                    color: var(--secondary-900);
                }

                input[type="file"] {
                    width: 100%;
                    height: 100%;
                    position: absolute !important;
                    top: 0;
                    left: 0;
                    z-index: 99;
                    text-align: 9999px;
                    cursor: pointer;
                    opacity: 0;
                }
            }

            .mobileViewBodyProductDetails {
                width: 100%;
                display: flex;
                align-items: center;
                gap: 10px;

                .mobileViewVarintProductDetails {
                    width: 100%;

                    .mobileDetailsTopFlex {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        h6 {
                            color: var(--secondary-900);
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 24px;
                        }

                        .statusText {
                            p {
                                color: #6DAF00;
                                font-family: 'Poppins', sans-serif;
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 18px;

                                &.unavailabeStatus {
                                    color: var(--secondary-300);
                                }
                            }
                        }
                    }

                    .mobileDetailsBottomDetails {
                        display: flex;
                        align-items: center;
                        gap: 4px;

                        p {
                            color: var(--secondary-500);
                            font-family: 'Poppins', sans-serif;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 18px;
                        }

                        .partborder {
                            border-right: 1px solid var(--secondary-200);
                            height: 12px;
                        }
                    }
                }

                .mobileViewMoreDetailsArrow {
                    width: 24px;
                    height: 24px;
                    min-width: 24px;
                    max-width: 24px;

                }

            }
        }
    }

    .mobileViewBottomButton {
        position: sticky;
        bottom: 0;
        left: 0;
        padding: 16px 16px 24px 16px;
        background: #FFF;

        button {
            width: 100%;
            height: 44px;
            border-radius: 10px;
            background: var(--primary-500);
            color: #FFF;
            text-align: center;
            font-family: 'Poppins', sans-serif;
            font-size: 18px;
            font-weight: 500;
            line-height: 28px;
            cursor: pointer;
        }
    }
}

.noImgBoxAlignment {
    background: rgba(85, 110, 230, 0.13);
    border: 2px dashed #b1c2f3;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    position: relative;
    padding: 16px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: var(--secondary-900);
    }

    input[type="file"] {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
        text-align: 9999px;
        cursor: pointer;
        opacity: 0;
    }
}