.notification-box-section {
    max-width: 100%;
    width: 100%;
    // height: auto;
    background: #FFFFFF;
    border: 2px solid #FFFFFF;
    border-radius: 10px;
    position: relative;

    &.open-notification-modal {
        max-height: calc(100vh - 88px);
        overflow-y: auto;
        transition: all .5s ease-in-out;

        @media only screen and (max-width:"574px") {
            height: auto;
        }
    }

    &.close-notification-modal {
        max-width: 0px;
        transition: all .5s ease-in-out;
    }

    .bottom-blur-effect {
        position: absolute;
        width: 100%;
        height: 124px;
        left: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
        border-radius: 0px 0px 15px 15px;
        z-index: 999;
    }

    .notification-box-alignment {
        background: #FFFFFF;
        border: 2px solid #FFFFFF;
        width: 100%;
        height: calc(100vh - 100px);
        overflow-y: auto;

        @media only screen and (max-width:"576px") {
            height: inherit;
        }

        .notification-main-details-alignment {

            padding: 32px 32px 16px 32px;
            border-bottom: 1px solid var(--secondary-50);
            position: sticky;
            top: 0;
            background: #fff;
            z-index: 999;


            @media only screen and (max-width:"576px") {
                top: 48px;
                padding: 16px;
            }

            .notification-back-icon {
                width: fit-content;
                cursor: pointer;
                border-radius: 12px;
                border: 1px solid #808191;
                padding: 5px 10px;
                margin: 0 0 10px 0;
            }

            .notification-heading-alignment {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .notification-main-heading-alignment {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    h4 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 36px;
                        letter-spacing: 0.1px;
                        color: var(--secondary-900);

                        @media only screen and (max-width:"767px") {
                            font-size: 18px;
                            line-height: 24px;
                        }
                    }

                    .notification-number-alignment {
                        background: #D21414;
                        border-radius: 10px;
                        padding: 0 8px;

                        span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 20px;
                            color: #FFFFFF;
                        }
                    }
                }

                .mark-allread-notifiaction-alignment {
                    display: flex;
                    align-items: center;
                    gap: 34px;

                    @media only screen and (max-width:"767px") {
                        gap: 10px;
                    }

                    p {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 20px;
                        color: var(--secondary-600);
                        cursor: pointer;
                    }

                    .setting-icon-alignment {
                        img {
                            cursor: pointer;
                        }
                    }
                }
            }

            .notification-filter-details-alignment {
                padding: 32px 0 0 0;
                display: flex;
                align-items: center;
                justify-content: space-between;

                @media only screen and (max-width:"576px") {
                    padding: 24px 0 0 0;
                    width: 100%;
                    overflow-x: auto;
                    gap: 20px;
                }

                .notification-option-tab-alignment {
                    // display: flex;
                    // align-items: center;
                    // gap: 8px;

                    ul {
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        li {
                            padding: 6px 16px;
                            display: flex;
                            align-items: center;
                            gap: 3px;
                            background: var(--secondary-50);
                            border-radius: 8px;
                            cursor: pointer;

                            &:hover {
                                background: var(--primary-50);
                                border-radius: 8px;

                                span {
                                    color: var(--primary-700);
                                    font-weight: 600;
                                }
                            }

                            &.setting-tab-active {
                                background: var(--primary-50);
                                border-radius: 8px;

                                span {
                                    color: var(--primary-700);
                                    font-weight: 600;
                                }
                            }

                            span {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 500;
                                font-size: 15px;
                                line-height: 20px;
                                color: var(--secondary-600);
                            }
                        }
                    }
                }

                .unread-option-alignment {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    p {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 20px;
                        color: var(--secondary-600);
                    }

                    .unready-checkbox-alignment {
                        width: 16px;
                        height: 16px;

                        input[type="checkbox"] {
                            width: 100%;
                            height: 100%;
                            border-radius: 4px;
                        }
                    }
                }
            }
        }

        .notification-body-details-alignment {
            .notification-time-alignment {
                padding: 16px 32px;

                span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--secondary-400);
                }
            }

            .notification-all-body-details-alignment {
                padding: 16px 32px 0 32px;
                display: flex;
                align-items: flex-start;
                gap: 16px;

                @media only screen and (max-width:"576px") {
                    padding: 16px 16px 0 16px;
                }

                .notification-img-alignment {
                    width: 56px;
                    min-width: 56px;
                    max-width: 56px;
                    height: 56px;

                    @media only screen and (max-width:"576px") {
                        width: 40px;
                        min-width: 40px;
                        max-width: 40px;
                        height: 40px;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 4px;
                    }
                }

                .product-all-notification-details-alignment {
                    h6 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--secondary-900);

                        span {
                            font-weight: 700;
                        }

                        @media only screen and (max-width:"576px") {
                            font-size: 14px;
                            line-height: 18px;
                        }
                    }

                    .product-all-notification-img-box-alignment {
                        background: #F8F8F8;
                        border: 4px solid #FFFFFF;
                        box-shadow: 0px 1px 4px rgba(30, 36, 50, 0.16);
                        border-radius: 10px;
                        padding: 16px;
                        margin: 8px 0 0 0;
                        width: auto;

                        @media only screen and (max-width:"576px") {
                            padding: 10px;
                            margin: 4px 0 0 0;
                        }

                        .product-all-notification-img-flex-alignment {
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;
                            gap: 8px;

                            .product-notification-img-alignment {
                                width: 80px;
                                min-width: 80px;
                                max-width: 80px;
                                height: 80px;
                                cursor: pointer;

                                @media only screen and (max-width:"576px") {
                                    width: 40px;
                                    min-width: 40px;
                                    max-width: 40px;
                                    height: 40px;
                                }

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    border-radius: 4px;
                                }
                            }
                        }
                    }

                    .product-notiification-time-alignment {
                        padding: 12px 0;
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        .product-notification-tag-alignment {
                            display: flex;
                            align-items: center;
                            gap: 4px;

                            span {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 500;
                                font-size: 13px;
                                line-height: 20px;
                                letter-spacing: 0.1px;
                                color: var(--secondary-500);
                            }

                            p {
                                width: 4px;
                                height: 4px;
                                border-radius: 50%;
                                background: #7A7E88;
                                margin: 0 0 0 4px;
                            }
                        }

                        .tag-time-alignment {
                            span {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 20px;
                                letter-spacing: 0.1px;
                                color: var(--secondary-500);
                            }
                        }
                    }
                }
            }

            .notification-other-post-details-alignment {
                background: #F8F8F8;
                padding: 16px 32px;
                display: flex;
                align-items: flex-start;
                gap: 16px;
                position: relative;

                @media only screen and (max-width:"576px") {
                    padding: 16px;
                }

                .notification-red-box-alignment {
                    position: absolute;
                    top: 6px;
                    left: 8px;

                    p {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: #C21717;
                    }
                }

                .notification-other-post-img-alignment {
                    width: 56px;
                    min-width: 56px;
                    max-width: 56px;
                    height: 56px;

                    @media only screen and (max-width:"576px") {
                        width: 40px;
                        min-width: 40px;
                        max-width: 40px;
                        height: 40px;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }

                .notification-post-other-details-alignment {
                    h6 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--secondary-900);

                        @media only screen and (max-width:"576px") {
                            font-size: 14px;
                            line-height: 18px;
                        }

                        span {
                            font-weight: 700;
                        }
                    }

                    .product-notiification-time-alignment {
                        padding: 12px 0 0 0;
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        .product-notification-tag-alignment {
                            display: flex;
                            align-items: center;
                            gap: 4px;

                            span {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 500;
                                font-size: 13px;
                                line-height: 20px;
                                letter-spacing: 0.1px;
                                color: var(--secondary-500);
                            }

                            p {
                                width: 4px;
                                height: 4px;
                                border-radius: 50%;
                                background: #7A7E88;
                                margin: 0 0 0 4px;
                            }
                        }

                        .tag-time-alignment {
                            span {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 20px;
                                letter-spacing: 0.1px;
                                color: var(--secondary-500);
                            }
                        }
                    }
                }
            }

            .shipped-product-details-alignment {
                padding: 12px 32px 20px 32px;
                display: flex;
                align-items: flex-start;
                gap: 16px;

                @media only screen and (max-width:"576px") {
                    padding: 12px 16px;
                }

                &:hover {
                    background: var(--primary-50);
                }

                .shipped-icon-box-alignment {
                    width: 56px;
                    min-width: 56px;
                    max-width: 56px;
                    height: 56px;

                    @media only screen and (max-width:"576px") {
                        width: 40px;
                        min-width: 40px;
                        max-width: 40px;
                        height: 40px;
                    }

                    img {
                        width: 100%;
                        max-width: 100%;
                        height: auto;
                    }
                }

                .shipped-details-flex-alignment {
                    display: flex;
                    align-items: flex-start;
                    gap: 16px;

                    h6 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--secondary-900);

                        @media only screen and (max-width:"576px") {
                            font-size: 14px;
                            line-height: 18px;
                        }

                        span {
                            font-weight: 700;
                        }
                    }

                    .product-notiification-time-alignment {
                        padding: 12px 0;
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        .product-notification-tag-alignment {
                            display: flex;
                            align-items: center;
                            gap: 4px;

                            span {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 500;
                                font-size: 13px;
                                line-height: 20px;
                                letter-spacing: 0.1px;
                                color: var(--secondary-500);
                            }

                            p {
                                width: 4px;
                                height: 4px;
                                border-radius: 50%;
                                background: #7A7E88;
                                margin: 0 0 0 4px;
                            }
                        }

                        .tag-time-alignment {
                            span {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 20px;
                                letter-spacing: 0.1px;
                                color: var(--secondary-500);
                            }
                        }

                        .more-icon-alignment {
                            margin: 0 0 0 8px;
                        }
                    }

                    .shipped-img-alignment {
                        width: 80px;
                        height: 80px;
                        min-width: 80px;
                        max-width: 80px;

                        @media only screen and (max-width:"576px") {
                            width: 40px;
                            min-width: 40px;
                            max-width: 40px;
                            height: 40px;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            border: 4px solid #FFFFFF;
                            box-shadow: 0px 1px 4px rgba(30, 36, 50, 0.16);
                            border-radius: 8px;
                        }
                    }
                }
            }

            .other-details-profile-alignment {
                padding: 18px 32px 0 32px;
                display: flex;
                align-items: flex-start;
                gap: 16px;

                @media only screen and (max-width:"576px") {
                    padding: 18px 16px 0 6px;
                }

                .notification-other-post-img-alignment {
                    width: 56px;
                    min-width: 56px;
                    max-width: 56px;
                    height: 56px;

                    @media only screen and (max-width:"576px") {
                        width: 40px;
                        min-width: 40px;
                        max-width: 40px;
                        height: 40px;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }

                .notification-post-other-details-alignment {
                    h6 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--secondary-900);

                        @media only screen and (max-width:"576px") {
                            font-size: 14px;
                            line-height: 18px;
                        }

                        span {
                            font-weight: 700;
                        }
                    }

                    .child-comment-box-alignment {
                        margin: 8px 0 0 0;
                        background: #F8F8F8;
                        border: 4px solid #FFFFFF;
                        box-shadow: 0px 1px 4px rgba(30, 36, 50, 0.16);
                        border-radius: 10px;
                        padding: 12px 16px;

                        @media only screen and (max-width:"576px") {
                            margin: 4px 0 0 0;
                            padding: 12px;
                        }

                        .child-notification-comment-profile-alignment {
                            display: flex;
                            align-items: center;
                            gap: 8px;

                            .child-comment-img-alignment {
                                width: 32px;
                                min-width: 32px;
                                max-width: 32px;
                                height: 32px;

                                @media only screen and (max-width:"576px") {
                                    width: 24px;
                                    min-width: 24px;
                                    max-width: 24px;
                                    height: 24px;
                                }

                                img {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 50%;
                                }
                            }

                            .child-comment-details-alignment {
                                h6 {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 600;
                                    font-size: 13px;
                                    line-height: 20px;
                                    color: var(--secondary-900);

                                    @media only screen and (max-width:"576px") {
                                        font-size: 12px;
                                        line-height: 18px;
                                    }
                                }

                                span {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 400;
                                    font-size: 13px;
                                    line-height: 20px;
                                    color: var(--secondary-500);
                                    display: block;

                                    @media only screen and (max-width:"576px") {
                                        font-size: 12px;
                                        line-height: 18px;
                                    }
                                }
                            }
                        }

                        p {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 20px;
                            color: var(--secondary-900);
                            padding: 14px 0 0 0;

                            @media only screen and (max-width:"576px") {
                                font-size: 12px;
                                line-height: 18px;
                                padding: 8px 0 0 0;
                            }
                        }
                    }

                    .product-notiification-time-alignment {
                        padding: 12px 0 0 0;
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        .product-notification-tag-alignment {
                            display: flex;
                            align-items: center;
                            gap: 4px;

                            span {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 500;
                                font-size: 13px;
                                line-height: 20px;
                                letter-spacing: 0.1px;
                                color: var(--secondary-500);
                            }

                            p {
                                width: 4px;
                                height: 4px;
                                border-radius: 50%;
                                background: #7A7E88;
                                margin: 0 0 0 4px;
                            }
                        }

                        .tag-time-alignment {
                            span {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 20px;
                                letter-spacing: 0.1px;
                                color: var(--secondary-500);
                            }
                        }
                    }
                }
            }

            .follow-notification-alignment {
                padding: 34px 32px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                @media only screen and (max-width:"576px") {
                    padding: 16px;
                    margin: 0 0 64px 0;
                }

                .follow-notification-profile-alignment {
                    display: flex;
                    align-items: center;
                    gap: 16px;

                    .follow-notification-img-alignment {
                        width: 56px;
                        min-width: 56px;
                        max-width: 56px;
                        height: 56px;

                        @media only screen and (max-width:"576px") {
                            width: 40px;
                            min-width: 40px;
                            max-width: 40px;
                            height: 40px;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                            display: block;
                            object-fit: cover;
                        }
                    }

                    .follow-notification-profile-details-alignment {

                        h6 {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            color: var(--secondary-900);

                            @media only screen and (max-width:"576px") {
                                font-size: 14px;
                                line-height: 18px;
                            }

                            span {
                                font-weight: 700;
                            }
                        }

                        .product-notiification-time-alignment {
                            padding: 10px 0 0 0;
                            display: flex;
                            align-items: center;
                            gap: 8px;

                            .product-notification-tag-alignment {
                                display: flex;
                                align-items: center;
                                gap: 4px;

                                span {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 500;
                                    font-size: 13px;
                                    line-height: 20px;
                                    letter-spacing: 0.1px;
                                    color: var(--secondary-500);
                                }

                                p {
                                    width: 4px;
                                    height: 4px;
                                    border-radius: 50%;
                                    background: #7A7E88;
                                    margin: 0 0 0 4px;
                                }
                            }

                            .tag-time-alignment {
                                span {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 400;
                                    font-size: 13px;
                                    line-height: 20px;
                                    letter-spacing: 0.1px;
                                    color: var(--secondary-500);
                                }
                            }
                        }
                    }
                }

                .follow-back-button-alignment {
                    button {
                        padding: 6px 16px;
                        background: var(--primary-500);
                        border-radius: 8px;
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 20px;
                        color: #FFFFFF;
                        cursor: pointer;

                        @media only screen and (max-width:"576px") {
                            padding: 6px 12px;
                            font-size: 12px;
                            line-height: 14px;
                        }
                    }
                }
            }
        }
    }

}