.art-craft-related-search-section {
  padding: 48px 0;

  .art-craft-related-search-heading {
    text-align: center;
    padding: 0 0 57px 0;

    @media only screen and (max-width: '768px') {
      padding: 0 0 0 0;
    }

    h4 {
      font-family: 'Poppins', sans-serif;
      font-weight: 300;
      font-size: 36px;
      line-height: 54px;
      color: var(--secondary-900);

      @media only screen and (max-width: '768px') {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}
