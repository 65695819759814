.meet-artists-section {
    padding: 80px 0 0 0;

    @media only screen and (max-width:"1099px") {
        display: none;
    }

    .meet-artists-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 100px;

        .meet-artics-left-alignment {

            .meet-artists-left-details-alignment {
                padding: 48px 0 0 0;

                h6 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 36px;
                    line-height: 54px;
                    color: var(--secondary-900);
                }

                p {
                    max-width: 356px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--secondary-900);
                    padding: 16px 0 0 0;
                }
            }
        }

        .meet-artics-right-alignment {
            max-width: 780px;
            height: 542px;
            background: url("../../../assets/img/artCarft/art-cart-bg.png");
            padding: 58px;

            .meet-artics-right-grid-alignment {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 32px;

                .meet-artics-right-gridItem-alignment {
                    .meet-artics-profile-alignment {
                        width: 200px;
                        height: 200px;
                        position: relative;
                        transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

                        &:hover {

                            transform: scale(1.07);
                            transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

                            img {
                                height: 100%;
                                width: 100%;
                                transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                                object-fit: cover;
                            }
                        }

                        cursor: pointer;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                            object-fit: cover;
                        }

                        &:hover {
                            &::before {
                                content: '';
                                position: absolute;
                                width: 214px;
                                height: 214px;
                                top: -5px;
                                right: -5px;
                                background: url("../../../assets/img/artCarft/child-img-icon.png");
                                z-index: 99;
                                background-repeat: no-repeat;
                                background-size: contain;
                                background-position: center center;
                            }

                            .meet-artics-img-details-box-alignment {
                                display: block;
                            }
                        }

                        .meet-artics-img-details-box-alignment {
                            background: #FFFFFF;
                            border-radius: 17px;
                            padding: 20px 24px;
                            width: 212px;
                            position: absolute;
                            top: -110px;
                            right: -71px;
                            transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
                            display: none;
                            z-index: 999;

                            p {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 21px;
                                color: #000000;
                            }

                            h6 {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 600;
                                font-size: 20px;
                                line-height: 30px;
                                color: var(--primary-500);
                                padding: 8px 0 0 0;
                            }

                            .meet-artics-follow-details-alignment {
                                display: flex;
                                align-items: center;
                                gap: 8px;

                                span {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 400;
                                    font-size: 13px;
                                    line-height: 20px;
                                    color: #505050;
                                }

                                p {
                                    width: 3px;
                                    height: 3px;
                                    border-radius: 50%;
                                    background: #ACB1C0;
                                }
                            }
                        }

                        // .meet-artics-img-hover-animation-alignment {


                        // }

                    }

                    .meet-artics-see-more-profile-alignment {
                        width: 200px;
                        height: 200px;
                        position: relative;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                            object-fit: cover;
                        }


                        transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);


                        &:hover {
                            img {
                                transform: scale(1.05);
                            }

                            .meet-artics-hover-effect-alignment {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }

                        .meet-artics-hover-effect-alignment {
                            position: absolute;
                            background: rgba(30, 36, 50, 0.8);
                            border-radius: 16px;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            right: 0;
                            display: none;
                            border-radius: 50%;
                            transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);


                            a {
                                display: flex;
                                align-items: center;
                                gap: 8px;
                                justify-content: center;
                                font-family: 'Poppins', sans-serif;
                                font-weight: 500;
                                font-size: 18px;
                                line-height: 27px;
                                color: #FFFFFF;
                                cursor: pointer;

                                img {
                                    width: auto;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}