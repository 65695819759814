.send-card-details-alignment {
  padding: 48px 0;

  .birthday-gift-card-section-alignment {
    border-bottom: 1px solid rgba(228, 228, 232, 0.7);

    &:last-child {
      border-bottom: none;
      padding: 48px 0 0 0;
    }

    .birthday-gift-heading-alignment {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h6 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        color: #495057;
      }

      span {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 15px;
        line-height: 22px;
        color: #A855F7;
      }
    }

    .birthday-gift-card-body-alignment {
      padding: 12px 0 24px 0;

      .birthday-gift-card-alignment {
        padding: 0 89px 0 0;

        .birthday-img-alignment {
          img {
            width: 100%;
            height: 141px;
            display: block;
            border-radius: 10px;
          }
        }

        p {
          padding: 12px 0 0 0;
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          text-align: center;
          color: #495057;
        }
      }

      .slick-slider {
        .slick-dots {
          top: -28px !important;
          right: -48% !important;
        }
      }
    }
  }

  .send-cart-amount-modal {
    .send-cart-amount-modal-wrapper {
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      height: 100vh;
      background: rgba(72, 72, 72, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;

      .send-cart-modal-box {
        padding: 32px 40px;
        display: grid;
        grid-template-columns: 473px 1fr;
        gap: 96px;
        background: #FFFFFF;
        border-radius: 20px;

        .send-cart-first-part-alignment {
          h6 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 22px;
            line-height: 33px;
            color: #495057;
          }

          .gift-cart-amount-flex-alignment {
            display: flex;
            align-items: center;
            gap: 12px;
            padding: 8px 0 0 0;

            .gift-cart-amount-box-alignment {
              height: 32px;
              padding: 0 12px;
              background: var(--secondary-50);
              border-radius: 8px;
              display: flex;
              align-items: center;
              cursor: pointer;

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 15px;
                line-height: 22px;
                color: #808191;
              }

              &:hover {
                background: #A855F7;

                span {
                  font-weight: 500;
                  color: #FFFFFF;
                }
              }

              &.gift-cart-active {
                background: #A855F7;

                span {
                  font-weight: 500;
                  color: #FFFFFF;
                }
              }
            }

            .custom-price-alignment {
              display: flex;
              align-items: center;
              height: 32px;

              .custom-label-alignment {
                background: var(--secondary-50);
                border-radius: 8px 0px 0px 8px;
                padding: 0 12px;
                height: 100%;
                display: flex;
                align-items: center;
              }

              input {
                background: #FFFFFF;
                border: 1px solid #E4E4E8;
                border-radius: 0px 8px 8px 0px;
                padding: 0 12px;
                height: 100%;
                width: 67px;
              }
            }
          }

          .gift-card-send-to-alignment {
            padding: 40px 0 0 0;

            label {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 22px;
              line-height: 33px;
              color: #495057;
            }

            .gift-card-send-input-alignment {
              display: flex;
              align-items: center;
              gap: 8px;

              .gift-card-input-alignment {
                background: var(--secondary-50);
                border-radius: 10px;
                width: 100%;
                height: 48px;

                input {
                  width: 100%;
                  height: 100%;
                  background: transparent;
                  padding: 0 16px;
                }

              }

              .gift-select-alignment {
                button {
                  width: 127px;
                  min-width: 127px;
                  height: 48px;
                  background: #FFFFFF;
                  border: 1px solid #556EE6;
                  border-radius: 10px;
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 17px;
                  line-height: 26px;
                  color: var(--primary-500);
                  cursor: pointer;
                }
              }
            }
          }

          .gift-card-message-alignment {
            padding: 40px 0 0 0;

            label {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 22px;
              line-height: 33px;
              color: #495057;
            }

            .gift-card-textarea-alignment {
              padding: 8px 0 0 0;

              textarea {
                height: 136px;
                background: var(--secondary-50);
                border-radius: 10px;
                width: 100%;
              }
            }
          }

          .gift-add-cart-button-alignment {
            padding: 32px 0 0 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            button {
              background: var(--primary-500);
              border-radius: 10px;
              height: 48px;
              display: flex;
              align-items: center;
              padding: 0 20px;
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 15px;
              line-height: 22px;
              color: #FFFFFF;
              text-align: center;
              cursor: pointer;
            }
          }
        }

        .send-second-part-alignment {
          .send-second-card-image-alignment {
            width: 274px;
            height: 173px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 10px;

            }
          }

          .card-name-alignment {
            padding: 16px 0 0 0;
            text-align: center;

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 15px;
              line-height: 22px;
              text-align: center;
              color: #495057;
            }
          }
        }
      }
    }
  }
}


// Mobile view send card details css

.mobile-view-send-card-details-section {

  .mobile-view-amount-heading-alignment {

    h6 {
      padding: 0 16px;
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 15px;
      line-height: 22px;
      color: #495057;
    }


    .amount-details-alignment {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
      padding: 8px 16px 0 16px;

      .mobile-gift-cart-amount-box-alignment {
        height: 32px;
        padding: 0 12px;
        background: var(--secondary-50);
        border-radius: 8px;
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: #808191;
        }

        &:hover {
          background: #A855F7;

          span {
            font-weight: 500;
            color: #FFFFFF;
          }
        }

        &.gift-cart-active {
          background: #A855F7;

          span {
            font-weight: 500;
            color: #FFFFFF;
          }
        }
      }

      .mobile-custom-price-alignment {
        display: flex;
        align-items: center;
        height: 32px;

        .custom-label-alignment {
          background: var(--secondary-50);
          border-radius: 8px 0px 0px 8px;
          padding: 0 12px;
          height: 100%;
          display: flex;
          align-items: center;

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: #808191;
          }
        }

        input {
          background: #FFFFFF;
          border: 1px solid #E4E4E8;
          border-radius: 0px 8px 8px 0px;
          padding: 0 12px;
          height: 100%;
          width: 67px;
        }
      }
    }

    .mobile-view-choose-cart-alignment {
      padding: 24px 0 0 0;

      .choose-cart-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;

        h5 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 15px;
          line-height: 22px;
          color: #495057;
        }

        a {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          color: #A855F7;
          cursor: pointer;
        }
      }

      .mobile-choose-cart-tab-alignment {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 8px 16px;

        .mobile-choose-tab-box-alignment {
          padding: 4px 8px;
          background: var(--secondary-50);
          border-radius: 8px;

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;
            color: #808191;

          }

          &:hover {
            background: #FF9449;

            span {
              color: #FFFFFF;
            }
          }

          &.choose-tab-active {
            background: #FF9449;

            span {
              color: #FFFFFF;
            }
          }
        }
      }

      .card-image-alignment {
        padding: 0 0 0 16px;
        width: 100%;
        overflow-x: auto;

        .card-image-flex {
          display: flex;
          align-items: center;
          gap: 16px;

          .card-image-view {
            width: 143px;
            height: 96px;
            min-width: 143px;
            max-width: 143px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 10px;
            }
          }
        }
      }

      .mobile-option-alignment {
        padding: 26px 16px 16px 16px;
        display: flex;
        align-items: center;

        .mobile-option-tab-alignment {
          width: 115px;
          height: 40px;
          background: var(--secondary-50);
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 1px solid #E4E4E8;

          &:first-child {
            border-radius: 100px 0px 0px 100px;
          }

          &:last-child {
            border-radius: 0px 100px 100px 0px;
            border-right: none;
          }

          &.option-active {
            background: #A855F7;

            span {
              color: #FFFFFF;
              font-weight: 500;
            }
          }

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            color: #808191;
          }
        }
      }

      .mobile-search-section {
        padding: 0 16px;

        .mobile-search-alignment {
          border: 1px solid #E4E4E8;
          border-radius: 10px;
          width: 100%;
          height: 29px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 16px;

          input {
            height: 100%;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: #808191;
          }

          .search-icon-alignment {
            max-width: 12px;
            min-width: 12px;
            height: 12px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .mobile-user-user-details-alignment {
        padding: 12px 27px 26px 16px;

        .mobile-user-main-flex-alignment {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 0 16px 0;

          &:last-child {
            padding: 0;
          }

          .user-profile-name-alignment {
            display: flex;
            align-items: center;
            gap: 10px;

            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
            }

            .user-name-alignment {
              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                color: #000000;
              }
            }
          }

          // .mobile-user-check-alignment {
          input[type="checkbox"] {
            width: 18px;
            height: 18px;
            margin: 0;
          }

          // }
        }

      }

      .message-send-alignment {
        padding: 12px 16px 30px 12px;
        display: flex;
        align-items: center;
        gap: 16px;
        width: 100%;

        .message-input-alignment {
          width: 100%;

          input {
            width: 100%;
            padding: 10px 12px;
            background: var(--secondary-50);
            border-radius: 10px;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: #ACB1C0;
          }
        }

        .message-send-button-alignment {
          button {
            padding: 8px 16px;
            background: var(--primary-500);
            border-radius: 10px;
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            color: #FFFFFF;
          }
        }
      }
    }
  }
}