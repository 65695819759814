.seeAllStoreDetailsAlignment {
  border-radius: 16px;
  overflow: auto;
  @media (max-width: 576px) {
    border-radius: 0px;
  }

  .firstheaderBoxDesign {
    border-radius: 10px 10px 0px 0px;
    padding: 0 64px 0 20px;
    display: grid;
    gap: 50px;
    grid-template-columns: 1fr 411px 240px;
    align-items: center;
    background: linear-gradient(90deg, rgba(105, 102, 219, 0.84) 0%, rgba(109, 59, 191, 0.88) 64.64%, #6534a5 100%);
    height: 175px;

    @media (max-width: 1024px) {
      height: auto;
    }

    @media (max-width: 576px) {
      margin: 0;
      display: block;
      height: 105px;
      padding: 12px 16px;
      position: relative;
      border-radius: 0px;
    }

    .backButtonShow {
      display: none;

      @media (max-width: 576px) {
        display: block;
      }
    }

    .peopleFollowerSearchAlignment {
      height: 40px;
      display: flex;
      align-items: center;
      background: #fff;
      border-radius: 10px;
      padding: 0 16px 0 0;

      @media only screen and (max-width: '1024px') {
        width: 100%;
        max-width: 100%;
      }

      @media (max-width: 576px) {
        display: none;
      }

      input {
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 400;
        background-color: #fff;
        line-height: 24px;

        &::placeholder {
          color: #acb1c0;
        }
      }
    }

    p {
      color: #fff;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 169.231% */
      letter-spacing: -0.408px;

      @media (max-width: 576px) {
        text-align: center;
      }
    }

    .img {
      @media (max-width: 576px) {
        display: none;
      }
      img {
        width: 100%;
        display: block;
      }
    }

    h2 {
      color: #fff;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      @media (max-width: 576px) {
        font-size: 18px;
        text-align: center;
      }
    }
  }

  .hideInput {
    display: none;
    padding: 10px 10px 0 10px;
    @media (max-width: 576px) {
      display: block;
    }
  }

  .seeAllStoreBoxAlignment {
    border-radius: 10px;
    .peopleFollowerSearchAlignment {
      height: 40px;
      display: flex;
      align-items: center;
      background: #f8f8f8;
      border-radius: 10px;
      padding: 0 16px 0 0;

      @media only screen and (max-width: '1024px') {
        width: 100%;
        max-width: 100%;
      }

      input {
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 400;
        background-color: #f8f8f8;
        line-height: 24px;

        &::placeholder {
          color: #acb1c0;
        }
      }
    }
    .seeAllStoreHeadingAlignment {
      h6 {
        color: var(--bookmark-heading-color);
        font-family: 'Poppins', sans-serif;
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
      }
    }

    .allFollowStoreDetailsAlignment {
      padding: 26px 0 0 0;

      .allFollowStoreGridAlignment {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;

        // @media only screen and (max-width:"1699px") {
        //     grid-template-columns: repeat(3, 1fr);
        // }

        // @media only screen and (max-width:"1499px") {
        //     grid-template-columns: repeat(2, 1fr);
        // }

        @media only screen and (max-width: '1099px') {
          grid-template-columns: repeat(3, 1fr);
        }

        @media only screen and (max-width: '899px') {
          grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 576px) {
          grid-template-columns: 1fr;
        }

        .allFollowStoreGridItemAlignment {
          .allFollowingStoreDetailsSliderAlignment {
            .allFollowingStoreCardStore {
              width: 100%;
              border: 1px solid #eceef4;
              border-radius: 12px;
              background: #ffffff;
              overflow: hidden;
              transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

              &:hover {
                transform: translateY(-4px);
                border: 1px solid #ab67fc;

                .allFollowingStoreCardContent h5 {
                  color: #ab67fc;
                }
              }

              .allFollowingStoreCardtopStore {
                overflow: hidden;
                border-radius: 12px;
                width: 100%;
                max-height: 145px;
                object-fit: cover;
                object-position: center;
                height: 145px;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              .allFollowingStoreCardContent {
                padding: 16px;
                text-align: center;

                h5 {
                  font-weight: 500;
                  font-size: 20px;
                  line-height: 30px;

                  color: #000000;

                  @media only screen and (max-width: 799px) {
                    font-size: 16px;
                    line-height: 24px;
                    margin: 0 0 2px 0;
                  }
                }

                h6 {
                  font-size: 14px;
                  line-height: 21px;
                  font-weight: 400;
                  color: #808191;

                  @media only screen and (max-width: 799px) {
                    font-size: 12px;
                    line-height: 18px;
                    padding: 0 0 9px 0;
                  }
                }

                .allFollowingStoreProduct {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 6px;
                  padding: 16px 0 0 0;

                  span {
                    color: #808191;
                    text-align: center;
                    font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 21px;

                    @media only screen and (max-width: 799px) {
                      font-size: 12px;
                      line-height: 18px;
                      margin: 0 0 2px 0;
                    }
                  }
                }

                .allFollowingStoreVisitor {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 2px;
                  padding: 8px 0 0 0;

                  span {
                    font-size: 14px;
                    line-height: 21px;
                    color: #808191;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;

                    @media only screen and (max-width: 799px) {
                      font-size: 12px;
                      line-height: 18px;
                    }

                    .pre {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }

                    .rateAlignment {
                      color: #70c217;
                      margin: 0 0 0 2px;
                      font-size: 14px;
                      font-weight: 600;
                    }
                  }
                }

                button {
                  background: var(--primary-500);
                  border-radius: 8px;
                  display: block;
                  width: 100%;
                  border: unset;
                  outline: unset;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 21px;
                  text-align: center;
                  color: #ffffff;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 6px 10px;
                  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

                  &.allFollowingBtnAlignment {
                    border-radius: 8px;
                    background: #f8f8f8;
                    color: var(--secondary-500);
                  }

                  &:hover {
                    transform: translateY(-3px);
                    background: #2844ac;
                    color: #ffffff;
                  }

                  margin-top: 16px;

                  @media only screen and (max-width: 799px) {
                    font-size: 14px;
                    line-height: 21px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .showMoreButtonContain {
    padding: 70px 0 52px 0;
  }
}

.activeBorder {
  border: 2px solid var(--Primary-primary-50, #eef1fd);
  padding: 12px;
  height: calc(100vh - 95px);
}
