.addProductModalSEOAlignment {
  padding: 0 0 16px 0;

  .addProductModalSEOBox {
    border-radius: 10px;
    border: 1px solid var(--secondary-50);
    background: #FFF;
    padding: 10px 16px 16px 16px;


    .aaddProductModalSEOHeadingAlignment {
      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: var(--secondary-900);

      }
    }


    .seoFormAlignment {
      margin: 16px 0 0 0;

      .formgroupGridAlignment {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 16px;
        padding: 0 0 16px 0;

        @media only screen and (max-width:"767px") {
          grid-template-columns: 1fr;
          gap: 16px;
          padding: 0 0 16px 0;
        }
      }

      .formDetailsAlignment {
        width: 100%;

        @media only screen and (max-width:"767px") {
          padding: 0;
        }

        &:last-child {
          padding: 0;
        }

        label {
          font-family: "Poppins", sans-serif;
          margin: 0 0 4px 0;
          display: block;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: #495057;
          text-align: left;
        }

        input {
          width: 100%;
          border-radius: 10px;
          border: 1px solid var(--secondary-50);
          background: var(--light-grey);
          border-radius: 10px;
          height: 40px;
          padding: 0 16px;
        }

        textarea {
          width: 100%;
          border-radius: 10px;
          border: 1px solid var(--secondary-50);
          background: var(--light-grey);
          border-radius: 10px;
          height: 118px;
          padding: 16px;

        }

        ::-webkit-scrollbar {
          display: block !important;
        }
      }
    }

  }
}