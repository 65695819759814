.mobileJewelryNewArrivalSection {
    .mobileJewelryNewArrivalAlignment {
        padding: 16px;

        .mobileJewelryNewArrivalHeadingAlignment {
            padding: 0 0 16px 0;

            h6 {
                color: var(--secondary-900);
                font-family: 'Poppins', sans-serif;
                font-size: 20px;
                font-weight: 600;
                line-height: 24px;
            }
        }

        .mobileJewelryNewArrivalDetails {
            height: calc(100vh - 130px);

            .mobileJewelryNewArrivalGrid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 16px;

                .mobileJewelryNewArrivalGridItem {

                    .jewelryNewArrivalDetailsImg {
                        width: 100%;
                        height: 100%;
                        position: relative;
                        cursor: pointer;

                        &:hover {
                            transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);
                            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
                            transform: translateY(-4px);
                            border-radius: 8px;


                        }

                        @media only screen and (max-width:"767px") {
                            width: 164px;
                            height: 100%;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 8px;

                            @media only screen and (max-width:"767px") {
                                height: 164PX;
                            }
                        }

                        .jewelryNewArrivalAllDetails {
                            position: absolute;
                            bottom: 16px;
                            left: 16px;
                            z-index: 9;

                            @media only screen and (max-width:"767px") {
                                position: static;
                                padding: 5px 0 0 0;
                            }

                            p {
                                max-width: 163px;
                                color: #F8F8F8;
                                font-family: 'Poppins', sans-serif;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 20px;
                                max-width: 159px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                color: var(--secondary-900);
                            }

                            .jewelryPrice {
                                padding: 4px 0 0 0;

                                span {
                                    color: #F8F8F8;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 16px;
                                    line-height: 24px;

                                    color: var(--secondary-900);
                                    font-weight: 600;
                                }
                            }

                        }

                        .mobileViewOptionAlignment {
                            position: absolute;
                            top: 2px;
                            right: 2px;
                            z-index: 99;
                            display: none;

                            @media only screen and (max-width:"767px") {
                                display: block;
                            }

                            svg {
                                cursor: pointer;
                            }
                        }

                    }
                }
            }
        }
    }
}