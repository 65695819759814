.group-message-tab-section {
  padding: 16px 0;
  height: calc(100vh - 236px);
  overflow-y: auto;

  .group-message-tab-alignment {
    margin: 0 0 0 0;

    &:last-child {
      margin: 0;
    }

    .group-message-tab-box-alignment {
      padding: 18px 16px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      cursor: pointer;

      &:hover {
        background: var(--primary-50);
        border-radius: 10px;
      }

      &.un-read-group-message {
        .group-message-profile-details-alignment {
          .group-message-profile-all-details-alignment {
            p {
              color: var(--secondary-900);
              font-weight: 600;
            }
          }
        }

        .group-message-time-alignment {
          .group-total-message-alignment {
            display: flex;
          }
        }
      }

      .group-message-time-alignment {
        .group-total-message-alignment {
          display: none;
          margin: 0 0 8px 0;

          .group-total-message-box-alignmant {
            padding: 0 4px;
            border-radius: 50%;
            background: #d21414;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 13px;
              line-height: 18px;
              color: #ffffff;
            }
          }
        }

        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: #92929d;
        }
      }

      .group-message-profile-details-alignment {
        display: flex;
        align-items: center;
        gap: 16px;

        .group-message-profile-img-box-alignment-sidebar {
          width: 46px;
          min-width: 46px;
          height: 48px;
          min-height: 48px;
          position: relative;

          .group-message-flex-alignment-0 {
            width: 24px;
            height: 24px;
            min-width: 24px;

            .group-message-profile-img-alignment-0 {
              width: 24px;
              height: 24px;
              min-width: 24px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
              }
            }
          }

          .group-message-flex-alignment-1 {
            display: flex;
            justify-content: flex-end;
            margin: -12px 0 0 0;

            .group-message-profile-img-alignment-1 {
              width: 16px;
              height: 16px;
              min-width: 16px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
              }
            }
          }

          .group-message-flex-alignment-2 {
            display: flex;
            justify-content: center;

            .group-message-profile-img-alignment-2 {
              width: 20px;
              height: 20px;
              min-width: 20px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
              }
            }
          }

          .group-message-first-profile-img-alignment {
            width: 24px;
            height: 24px;
            min-width: 24px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              object-fit: cover;
            }
          }

          .group-message-second-flex-alignment {
            display: flex;
            justify-content: flex-end;
            margin: -12px 0 0 0;

            .group-message-second-profile-img-alignment {
              width: 16px;
              height: 16px;
              min-width: 16px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
              }
            }
          }

          .group-message-third-flex-alignment {
            display: flex;
            justify-content: center;

            .group-message-third-profile-img-alignment {
              width: 20px;
              height: 20px;
              min-width: 20px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
              }
            }
          }

          .group-message-first-dot-alignment {
            position: absolute;
            top: 4px;
            right: 13px;

            .first-dot-width-alignment {
              width: 5px;
              height: 5px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          .group-message-second-dot-alignment {
            position: absolute;
            left: 1px;
            bottom: 15px;

            .second-dot-width-alignment {
              width: 6px;
              height: 6px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          .group-message-third-dot-alignment {
            position: absolute;
            right: 9px;
            bottom: 15px;

            .third-dot-width-alignment {
              width: 2px;
              height: 2px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }

        .group-message-profile-all-details-alignment {
          h5 {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: var(--secondary-900);
          }

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: var(--secondary-600);
            padding: 4px 0 0 0;
            max-width: 179px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }


        }
      }
    }
  }
}