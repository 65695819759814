.cart-page-container {
  // overflow: hidden !important;
  // max-width: 1300px;
  // width: 1300px;
  height: calc(100vh - 194px);
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #e4e4e8;
  border-radius: 10px 10px 0 0;
  border-bottom: 0;
  // margin-top: 28px;
  padding: 0 32px 32px 32px;
  overflow-y: auto;

  @media only screen and (max-width:1024px) {
    background: #ffffff;
    padding: 0 16px 122px 16px;
    height: 100%;
  }

  @media only screen and (max-width:767px) {
    background: transparent;
    padding: 0 0 122px 0;
    height: 100%;
  }
  @media only screen and (max-width:576px) {
    padding: 0;
  }

  .mobile-cart-back-alignment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // display: none;

    // @media only screen and (max-width:767px) {

    // }
    @media only screen and (max-width:1024px) {
      padding: 8px;
      background: #ffffff;
    }

    .back_details-alignment {
      background: #ffffff;
      border-radius: 10px;
      padding: 4px 9px 4px 10px;
      display: flex;
      align-items: center;
      gap: 8px;

      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #808191;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 58px;
      }
    }

    .notification-alignment {
      position: relative;

      // .number-alignment {
      //   width: 22px;
      //   height: 22px;
      //   border-radius: 50%;

      //   span {

      //   }
      // }
    }
  }

  .cart-main-header {
    display: flex;
    gap: 4px;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 999;
    background: #ffffff;
    padding: 32px 0 24px 0;

    @media only screen and (max-width:1024px) {
      padding: 16px;
    }

    // @media only screen and (max-width:767px) {
    //   padding: 16px 0 0 0;
    //   margin-bottom: 16px;
    // }


    .cart-main-text {
      span {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        display: flex;
        align-items: center;
        color: #000000;
      }
    }

    .prouct-main-count {
      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        color: #808191;
      }
    }
  }

  .cart-main-contain {
    // height: 660px;
    // overflow: scroll;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-bottom: 8px;

    @media only screen and (max-width:1024px) {
      // height: calc(100vh - 250px);
      gap: 5px;
    }

    @media only screen and (max-width:768px) {
      // height: calc(100vh - 250px);
      height: calc(100vh - 250px);
      gap: 5px;
    }
    @media only screen and (max-width:576px) {
      height: 100%;
      margin: 0 0 102px 0;
    }

    .main-card {
      display: flex;
      gap: 16px;

      @media only screen and (max-width:767px) {
        background: #ffffff;
        padding: 8px;
        border: 1px solid #E4E4E8;
        border-radius: 10px;

        .ant-checkbox-inner {
          width: 24px !important;
          height: 24px !important;
        }

        .ant-checkbox-checked::after {
          left: 0 !important;
        }
      }


      .cart-grid-align {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        width: 100%;

        @media only screen and (max-width:"767px") {
          width: 100%;
          overflow-x: auto;
        }

        .store-logo-align {
          width: 24px;
          height: 24px;
          display: flex;
          gap: 6px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }

          span {
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: var(--secondary-900);
            white-space: nowrap;
          }
        }

        .cart-product-main {
          width: 100%;
          height: auto;
          background: #ffffff;
          border: 1px solid #e4e4e8;
          border-radius: 10px;
          padding: 16px;

          @media only screen and (max-width:"767px") {
            padding: 0;
            border: none;

          }


          .card-section {
            .product-first-part {
              display: grid;
              grid-template-columns: 30px 1fr;

              .product-section-main {


                .product-detail-alignment {
                  .product-card-inner-detail {
                    display: flex;
                    align-items: center;
                    gap: 16px;

                    @media only screen and (max-width:"767px") {
                      align-items: flex-start;
                    }

                    .product-image-contain {
                      width: 73px;
                      height: 90px;

                      @media only screen and (max-width:"767px") {
                        width: 66px;
                        height: 81px;
                        min-width: 66px;
                      }

                      img {
                        width: 100%;
                        height: 100%;
                        border: 1px solid #e4e4e8;
                        border-radius: 8px;
                        object-fit: cover;
                      }
                    }

                    .product-info-main {
                      width: 100%;
                      display: grid;
                      row-gap: 16px;

                      @media only screen and (max-width:"767px") {
                        row-gap: 8px;
                      }


                      .product-title-align {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .cart-product-detail {
                          h2 {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            display: flex;
                            align-items: center;
                            color: #000000;

                            @media only screen and (max-width:"767px") {
                              font-size: 14px;
                              line-height: 21px;
                            }

                          }
                        }

                        .product-store-detail {
                          display: flex;
                          justify-content: flex-end;
                          align-items: center;
                          height: 30px;

                          .save-delete-section {
                            display: none;
                            // display: flex;
                            gap: 8px;
                            align-items: center;

                            .save-product-button {
                              width: 121px;
                              height: 32px;
                              background: #ffffff;
                              border: 1px solid #e4e4e8;
                              border-radius: 5px;
                              display: flex;
                              justify-content: center;
                              align-items: center;

                              @media only screen and (max-width:767px) {
                                width: 77px;
                                height: 60px;

                              }

                              span {
                                font-weight: 500;
                                font-size: 13px;
                                line-height: 20px;
                                display: flex;
                                align-items: center;
                                color: #acb1c0;

                                @media only screen and (max-width:767px) {
                                  justify-content: center;

                                }
                              }
                            }

                            .trash-align {
                              width: 32px;
                              height: 32px;
                              background: #ffffff;
                              border: 1px solid #e4e4e8;
                              border-radius: 5px;
                              display: flex;
                              justify-content: center;
                              align-items: center;

                              svg {
                                width: 20px;
                                height: 20px;

                                path {
                                  stroke: #acb1c0;
                                }
                              }
                            }
                          }
                        }
                      }

                      .product-detail-section {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        @media only screen and (max-width:"767px") {
                          display: none;
                        }


                        .cart-detail-section-main {
                          .size-color-container {
                            display: grid;
                            row-gap: 8px;

                            .size-contain {
                              display: flex;
                              gap: 16px;
                              align-items: center;

                              span {
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 21px;
                                display: flex;
                                align-items: center;
                                color: #808191;
                              }

                              p {
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 18px;
                                display: flex;
                                align-items: center;
                                text-align: center;
                                color: var(--primary-500);
                                cursor: pointer;
                              }
                            }

                            .color-contain {
                              display: flex;
                              gap: 8px;
                              align-items: center;

                              .color-selecition {
                                display: flex;
                                gap: 6px;
                                align-items: center;

                                span {
                                  font-weight: 500;
                                  font-size: 14px;
                                  line-height: 21px;
                                  display: flex;
                                  align-items: center;
                                  color: #808191;
                                }

                                .color-preview {
                                  span {
                                    font-weight: 500;
                                    font-size: 14px;
                                    line-height: 21px;
                                    display: flex;
                                    align-items: center;
                                    color: var(--secondary-900);
                                  }
                                }
                              }

                              p {
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 18px;
                                display: flex;
                                align-items: center;
                                text-align: center;
                                color: var(--primary-500);
                                cursor: pointer;
                              }
                            }
                          }

                          .store-coupon-code {
                            span {
                              font-weight: 400;
                              font-size: 14px;
                              line-height: 21px;
                              color: #000000;
                            }
                          }

                          .coupon-code-alignment {
                            height: 32px;
                            display: flex;
                            gap: 20px;
                            align-items: center;

                            span {
                              font-weight: 600;
                              font-size: 14px;
                              line-height: 21px;
                              color: #000000;
                            }

                            p {
                              font-weight: 500;
                              font-size: 12px;
                              line-height: 18px;
                              display: flex;
                              align-items: center;
                              text-align: center;
                              color: var(--primary-500);
                              cursor: pointer;
                            }
                          }

                          .coupon-code-input {
                            height: 32px;
                            display: flex;
                            gap: 8px;
                            align-items: center;

                            .input-section {
                              input {
                                padding: 0px 12px;
                                border: 1px solid #e4e4e8;
                                border-radius: 10px;
                                width: 142px;
                                height: 32px;
                              }
                            }

                            .button-box {
                              width: 73px;
                              height: 32px;
                              background: var(--primary-500);
                              border-radius: 8px;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              opacity: 0.5;

                              button {
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 21px;
                                text-align: center;
                                color: #f8f8f8;
                              }
                            }

                            .active-class {
                              opacity: 1;
                            }
                          }
                        }

                        .product-quantity-box {
                          @media only screen and (max-width:"767px") {
                            display: none;
                          }

                          .quantity-counter {
                            .quantity-button-contain {
                              display: flex;
                              align-items: center;
                              gap: 8px;

                              .quantity-button-section {
                                width: 32px;
                                height: 32px;
                                background: #ffffff;
                                border: 1px solid #acb1c0;
                                border-radius: 10px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                cursor: pointer;
                              }

                              .product-quantity-box {
                                height: 32px;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                input {
                                  width: 64px;
                                  height: 32px;
                                  background: #ffffff;
                                  border: 1px solid #acb1c0;
                                  border-radius: 10px;
                                  padding: 10px 10px;
                                  font-weight: 500;
                                  font-size: 14px;
                                  line-height: 20px;
                                  text-align: center;
                                  color: var(--secondary-900);
                                }
                              }
                            }
                          }
                        }

                        .price-align {
                          text-align: center;

                          @media only screen and (max-width:"767px") {
                            display: none;
                          }

                          span {
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 27px;
                            color: #495057;
                          }
                        }
                      }

                      .mobile-view-product-details-alignment {
                        display: none;

                        @media only screen and (max-width:"767px") {
                          display: block;
                        }

                        .mobile-view-chils-details-alignment {
                          display: flex;
                          align-items: center;
                          gap: 7px;

                          .mobile-view-details-alignment {
                            display: flex;
                            align-items: center;
                            gap: 4px;
                            height: auto;
                            padding: 0;

                            span {
                              font-family: 'Poppins', sans-serif;
                              font-weight: 500;
                              font-size: 14px;
                              line-height: 21px;
                              color: #808191;
                            }

                            p {
                              font-family: 'Poppins', sans-serif;
                              font-weight: 500;
                              font-size: 14px;
                              line-height: 21px;
                              color: var(--secondary-900);
                              white-space: nowrap;
                            }
                          }
                        }

                        .mobile-view-change-alignment {
                          padding: 4px 0 0 0;

                          a {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 21px;
                            color: var(--primary-500);
                            cursor: pointer;
                          }
                        }

                      }
                    }
                  }
                }


              }

              .bottom-border {
                border: 1px solid #e4e4e8;
                margin: 14px 0;
              }
            }
          }

          .product-first-part:last-child {
            .bottom-border {
              border: none;
              margin: 0;
            }
          }
        }
      }

      .cart-opacity {
        opacity: 0.5;
      }

      // HOVER_EFFECT===========================================================================================================
      .cart-product-main:hover {
        transition: 0.3 ease-in-out;
        border: 1px solid #556ee6;

        @media only screen and (max-width:767px) {
          border: none;
        }

        .product-first-part {
          .product-section-main {
            .mobile-view-flex-alignment {


              .product-detail-alignment {
                .product-card-inner-detail {
                  .product-info-main {
                    .product-title-align {
                      .product-store-detail {

                        @media only screen and (max-width:767px) {
                          display: none;
                        }

                        .save-delete-section {
                          display: flex;
                          transition: 0.3 ease-in-out;

                          @media only screen and (max-width:767px) {
                            flex-direction: column-reverse;
                          }

                          .save-product-button:hover {
                            border: 1px solid #556ee6;
                            cursor: pointer;

                            span {
                              color: var(--primary-500);
                            }
                          }

                          .trash-align:hover {
                            border: 1px solid #e65555;
                            cursor: pointer;

                            svg {
                              width: 20px;
                              height: 20px;

                              path {
                                stroke: #e65555;
                                stroke-width: 2px;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

            }
          }
        }
      }
    }
  }

  .empty-cart-section {
    width: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    padding: 124px 0;

    @media only screen and (max-width:1024px) {
      padding: 0;
      height: 100%;
    }

    .empty-cart-sub-section {
      .lottie-img {
        width: 235px;
        height: 235px;

        @media only screen and (max-width:768px) {
          width: 150px;
          height: 150px;
        }
      }

      .empty-cart-text {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        span {
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          display: flex;
          align-items: center;
          text-align: right;
          color: #808191;


          @media only screen and (max-width:768px) {
            font-size: 18px;
            line-height: 24px;
          }
        }
      }

      .empty-cart-button-section {
        display: flex;
        justify-content: center;
        align-items: center;

        .empty-cart-button {
          width: 164px;
          height: 32px;
          background: var(--primary-500);
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          margin-top: 64px;

          @media only screen and (max-width:1024px) {
            margin-top: 30px;
          }

          button {
            display: flex;
            gap: 6px;
            align-items: center;

            span {
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              display: flex;
              align-items: center;
              text-align: center;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}


.cart-item-detail {
  overflow: hidden !important;
  // max-width: 1300px !important;
  height: 98px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #e4e4e8;
  border-radius: 0 0 10px 10px;
  padding: 22px 22px 22px 52px;
  margin-bottom: 0;

  @media only screen and (max-width:"1024px") {
    padding: 10px 16px;
    height: auto;
    margin-bottom: 0;
    position: fixed;
    bottom: 64px;
    z-index: 999;
    left: 0;
    width: 100%;
  }
  @media only screen and (max-width:"576px") {
    bottom: 0;
  }

  .product-item-alignment {
    .processed-to-checkout-section {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;

      @media only screen and (max-width : "767px") {
        display: none;
      }

      .selected-item-title {
        span {
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          color: #495057;

          @media only screen and (max-width:"767px") {
            font-size: 12px;
            line-height: 18px;
          }
        }

        .product-count {
          span {
            font-weight: 700;
            font-size: 20px;
            line-height: 30px;
            color: #000000;

            @media only screen and (max-width:"767px") {
              font-size: 12px;
              line-height: 18px;
            }
          }
        }
      }

      .selected-item-contain {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .button-alignment-section {
          width: 309px;
          height: 50px;
          background: var(--primary-500);
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;

          @media only screen and (max-width:"767px") {
            width: 197px;
            height: 44px;

          }

          button {
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #ffffff;

            @media only screen and (max-width:"767px") {
              font-size: 14px;
              line-height: 21px;

            }
          }
        }

        .button-opa {
          opacity: 0.5;

          button {
            cursor: context-menu;
          }
        }
      }
    }

    .mobile-processed-to-checkout-section {
      display: none;

      @media only screen and (max-width:"767px") {
        display: block;

        .mobile-processed-details-flex-alignment {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .mobile-process-child-details-alignment {
            display: flex;
            align-items: center;
            gap: 4px;
            padding: 0 0 2px 0;

            &:last-child {
              padding: 0;
            }

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              color: #495057;

              &.sec {
                font-size: 14px;
                line-height: 21px;
              }
            }

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              color: var(--secondary-900);

              &.sec {
                font-size: 14px;
                line-height: 21px;
                font-weight: 700;
              }
            }
          }

          .button-alignment-section {
            width: 309px;
            height: 50px;
            background: var(--primary-500);
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;

            @media only screen and (max-width:"767px") {
              width: 197px;
              height: 44px;

            }

            button {
              font-weight: 600;
              font-size: 18px;
              line-height: 27px;
              display: flex;
              align-items: center;
              text-align: center;
              color: #ffffff;

              @media only screen and (max-width:"767px") {
                font-size: 14px;
                line-height: 21px;

              }
            }
          }
        }

      }
    }
  }
}

.mobile-view-product-flex-alignment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 0 0;
  display: none;

  @media only screen and (max-width:"767px") {
    display: flex;
  }

  .product-quantity-box {
    .quantity-counter {
      .quantity-button-contain {
        display: flex;
        align-items: center;
        gap: 8px;

        .quantity-button-section {
          width: 32px;
          height: 32px;
          background: #ffffff;
          border: 1px solid #acb1c0;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }

        .product-quantity-box {
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;

          input {
            width: 64px;
            height: 32px;
            background: #ffffff;
            border: 1px solid #acb1c0;
            border-radius: 10px;
            padding: 10px 10px;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: var(--secondary-900);
          }
        }
      }
    }
  }

  .price-align {
    text-align: center;

    span {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #495057;
    }
  }
}

.mobile-view-flex-alignment {

  @media only screen and (max-width:"1024px") {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
  }

}

.product-store-details {
  // display: none;

  // @media only screen and (max-width:767px) {
  //   display: block;
  // }

  .save-delete-section {
    display: flex;
    transition: 0.3 ease-in-out;

    @media only screen and (max-width:767px) {
      flex-direction: column-reverse;
      align-items: center;
      gap: 16px;
    }

    .save-product-button:hover {
      border: 1px solid #556ee6;
      cursor: pointer;

      span {
        color: var(--primary-500);
      }
    }

    .trash-align:hover {
      border: 1px solid #e65555;
      cursor: pointer;

      svg {
        width: 20px;
        height: 20px;

        path {
          stroke: #e65555;
          stroke-width: 2px;
        }
      }
    }

    .save-product-button {
      width: 121px;
      height: 32px;
      background: #ffffff;
      border: 1px solid #e4e4e8;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width:767px) {
        width: 77px;
        height: 60px;

      }

      span {
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #acb1c0;

        @media only screen and (max-width:767px) {
          justify-content: center;
          text-align: center;
          color: var(--primary-500);
        }
      }
    }

    .trash-align {
      width: 32px;
      height: 32px;
      background: #ffffff;
      border: 1px solid #e4e4e8;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width:767px) {
        width: 77px;

      }

      svg {
        width: 20px;
        height: 20px;

        path {
          stroke: #acb1c0;
        }
      }
    }
  }
}