.peopleFollowersGridItemAlignment {
  .peopleFollowersChildDetails {
    .peopleFollowersChildImg {
      width: 200px;
      height: 200px;
      margin: 0 auto;
      border-radius: 50%;
      overflow: hidden;

      @media only screen and (max-width: '999px') {
        width: 140px;
        height: 140px;
      }

      @media only screen and (max-width: '767px') {
        width: 140px;
        height: 140px;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        transition: 0.3s ease-in-out;

        &:hover {
          transition: 0.3s ease-in-out;
          transform: scale(1.2);
        }
      }
    }

    .peopleFollowersChildAllDetails {
      padding: 11px 0 0 0;

      .PeopleFollowersname {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      h6 {
        color: #000;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
        cursor: pointer;
        width: fit-content;

        &:hover {
          transition: 0.4s ease-in-out;
          text-decoration-line: underline;
        }

        @media only screen and (max-width: '999px') {
          font-size: 18px;
          line-height: 24px;
        }

        @media only screen and (max-width: '767px') {
          font-size: 16px;
          line-height: 22px;
        }
      }

      p {
        padding: 4px 0 0 0;
        color: #495057;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        white-space: pre-wrap;
        cursor: context-menu;

        @media only screen and (max-width: '767px') {
          font-size: 12px;
          line-height: 14px;
        }
      }

      span {
        padding: 4px 0 0 0;
        display: block;
        color: #495057;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
        cursor: context-menu;

        @media only screen and (max-width: '767px.') {
          font-size: 12px;
          line-height: 14px;
        }
      }

      .peopleFollowersButtonAlignment {
        text-align: center;
        // padding: 16px 0 0 0;

        // button {
        //   width: 140px;
        //   height: 32px;
        //   border-radius: 8px;
        //   background: var(--primary-500);
        //   cursor: pointer;
        //   color: #f8f8f8;
        //   text-align: center;
        //   font-family: 'Poppins', sans-serif;
        //   font-size: 14px;
        //   font-weight: 600;
        //   line-height: 21px;
        //   border-radius: none;

        //   &.following {
        //     background: #f8f8f8;
        //     color: var(--primary-500);

        //     &:hover {
        //       background: #eef1fc;
        //     }
        //   }
        // }
        button {
          width: 140px;
          height: 32px;
          border-radius: 8px;
          background: var(--primary-500);
          cursor: pointer;
          color: #f8f8f8;
          text-align: center;
          font-family: 'Poppins', sans-serif;
          font-size: 14px;
          font-weight: 600;
          line-height: 21px;
          transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

          &:hover {
            transform: translateY(-3px);
            background: #2844ac;
            color: #ffffff;
          }

          &.following {
            background: #f8f8f8;
            color: var(--primary-500);

            &:hover {
              background: #eef1fc;
            }
          }

          margin-top: 16px;

          @media only screen and (max-width: 1024px) {
            margin-top: 10px;
          }

          @media only screen and (max-width: 799px) {
            font-size: 14px;
            line-height: 21px;
          }
        }
      }
    }
  }
}
