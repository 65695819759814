.bookMarkCollectionDetailsModalAlignment {
    border-radius: 10px;
    background: var(--white);

    height: calc(100vh - 98px);
    overflow-y: auto;

    .bbokMarkCollectionHeading {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 32px 32px 18px 32px;
        position: sticky;
        top: 0;
        background: var(--white);
        z-index: 99;

        @media only screen and (max-width:"767px") {
            padding: 16px 16px 8px 16px;
        }

        .bookMarkLeftDetails {
            h4 {
                color: var(--bookmark-heading-color);
                font-size: 24px;
                font-weight: 600;
                line-height: 36px;

                @media only screen and (max-width:"767px") {
                    font-size: 20px;
                    line-height: 24px;
                }
            }

            .bookMarkProfileCollection {
                display: flex;
                align-items: center;
                gap: 8px;

                @media only screen and (max-width:"767px") {
                    display: none;
                }

                .bookMarkProfile {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    .profileImg {
                        width: 18px;
                        height: 18px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 50%;
                            display: block;
                        }
                    }

                    p {
                        color: var(--secondary-500);
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 19px;
                    }
                }

                .followButton {
                    a {
                        color: var(--primary-500);
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 20px;
                        padding: 6px 16px;
                        display: block;
                        cursor: pointer;

                    }
                }
            }
        }

        .bookMarkRightSideAlignment {
            display: flex;
            align-items: center;
            gap: 8px;

            @media only screen and (max-width:"767px") {
                gap: 16px;
            }

            .bookMarkIcon {
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &.mobileView {
                    display: none;

                    @media only screen and (max-width:"767px") {
                        display: flex;
                    }
                }

                &.webView {
                    display: flex;

                    @media only screen and (max-width:"767px") {
                        display: none;
                    }
                }
            }
        }
    }

    .bookMarkCollectionAllProductAlignment {
        padding: 0 24px;

        @media only screen and (max-width:"767px") {
            padding: 0 16px;
        }

        .bookMarkCollectionTop {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 0 12px;

            @media only screen and (max-width:"767px") {
                padding: 0;
            }

            p {
                width: 2px;
                height: 2px;
                background: var(--secondary-300);
                border-radius: 50%;
            }

            span {
                color: var(--secondary-500);
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
            }
        }

        .bookMarkCollectionAllDetails {
            margin: 16px 0 0 0;
        }


        .bookMarkProductEmptyCartDetails {
            // height: 450px;
            display: flex;
            // flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 25px;
            border-radius: 16px;
            // background: #F8F8F8;
            // margin: 16px 0 0 0;
            height: calc(100vh - 200px);

            @media only screen and (max-width:"767px") {
                height: calc(100vh - 215px);

            }

            p {
                color: var(--violet-300);
                text-align: center;
                font-size: 12px;
                font-style: italic;
                font-weight: 400;
                line-height: 18px;

                @media only screen and (max-width:"767px") {
                    font-size: 12px;
                    line-height: 21px;
                    max-width: 100%;
                }
            }
        }

    }
}