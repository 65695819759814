.message-serach-text-alignment-Overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    z-index: 9999999 !important;
    display: none;

    @media only screen and (max-width:"1099px") {
        display: block;
    }

}

.message-serach-text-alignment-Overlay.open {
    opacity: 1;
    visibility: visible;

    @media only screen and (max-width:"1099px") {
        display: block;
    }
}

.message-serach-text-modal-alignment {
    position: fixed;
    right: 0;
    width: 100%;
    height: auto;
    background: white;
    translate: 0 100%;
    transition: 0.3s;
    overflow-y: auto;
    bottom: 0;

    .message-search-text-modal-box-alignment {


        .message-serch-label-box-alignment {
            width: 100%;
            height: 44px;
            background: #6B21A8;
            padding: 6px 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                font-family: 'Poppins', sans-serif;
                font-style: italic;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: #FFFFFF;

            }

            .message-search-label-right-side-alignment {
                display: flex;
                align-items: center;
                gap: 2px;
            }
        }

        .message-input-search-box-alignment {
            padding: 8px 16px;

            .message-search-input-alignment {
                background: #FFFFFF;
                border: 1px solid var(--secondary-100);
                border-radius: 10px;
                height: 32px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 4px 8px;

                input {
                    width: 100%;
                    height: 100%;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 22px;
                    color: var(--secondary-900);
                }
            }
        }

    }


}

.message-serach-text-modal-alignment.open {
    translate: 0;
    z-index: 9999999;
    transform: translate(0, 6%);

    @media only screen and (max-width:"1099px") {
        display: block;
    }
}