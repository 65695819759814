.Newarrivaltitlemain {
    width: 100%;
    padding: 1px 37px;
    border-radius: 10px 10px 0px 0px;
    background: linear-gradient(90deg, #171717 0%, #575820 100%);
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width:576px) {
        padding: 1px 18px;
    }

    .Newarrivaltitletextmain {
        text-align: left;
        padding: 58px 0;

        @media (max-width:576px) {
            padding: 5px 0 25px 0;
        }

        h4 {
            color: #FFF;
            font-family: "Poppins", sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            margin: 0 0 4px 0;
            word-break: keep-all;

            @media (max-width:700px) {
                font-size: 16px;
            }
        }

        p {
            color: #ECEEF4;
            font-family: "Poppins", sans-serif;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.1px;

            @media (max-width:700px) {
                font-size: 8px;
            }
        }
    }

    .Newarrivalcartoon {
        margin: 0 105px 0 0;

        img {
            width: 100%;
            height: 100%;

            @media (max-width:700px) {
                width: 75px;
                height: 53.691px;
            }
        }

        @media (max-width:1024px) {
            margin: 0 70px 0 0;
        }

        @media (max-width:576px) {
            margin: 0;
        }
    }
}


.allBookMarkDetailsBodyAlignment {
    padding: 12px;

    @media (max-width:576px) {
        height: calc(100vh - 150px);
      }

    .allBookMarkDetailsGrid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 16px;

        @media only screen and (max-width: '1024px') {
            grid-template-columns: repeat(3, 1fr);
        }

        @media only screen and (max-width: '699px') {
            grid-template-columns: repeat(2, 1fr);
            gap: 15px;
        }
    }

    .noDataBookMarkCollectionDetails {
        height: 209px;
        border-radius: 16px;
        background: var(--primary-50);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 16px;

        p {
            color: var(--violet-300);
            text-align: center;
            font-size: 12px;
            font-style: italic;
            font-weight: 400;
            line-height: 18px;
        }
    }
}