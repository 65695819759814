.following-tab {
  .following-tab-contain {
    width: 100%;
    height: 55px;
    background: #ffffff;
    box-shadow: 0px 1px 2px rgba(172, 177, 192, 0.2);
    margin-bottom: 14px;
    .ant-tabs-nav {
      // margin-right: 20px;
      // margin-bottom: 20px !important;
      .ant-tabs-nav-wrap {
        display: flex;
        justify-content: center;
      }
    }
    .ant-tabs-top > .ant-tabs-nav::before,
    .ant-tabs-top > div > .ant-tabs-nav::before {
      border: 0;
    }
    .ant-tabs-nav-list {
      border-radius: 0;
      .ant-tabs-tab {
        padding: 0;
        margin: 0;
        .ant-tabs-tab-btn {
          margin: 22px 0px 17px 0px;
          padding: 0 22px 0 22px;
          font-size: 16px;
          line-height: 16px;
          color: #a7a7a7;
          font-weight: 500 !important;
          // border-left: solid 1px #a7a7a7;
          // height: 15px;
        }
      }
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: var(--primary-500);
        }
      }
      .ant-tabs-ink-bar {
        height: 2px;
        background: #5f40af;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
      }
    }
    .ant-tabs-content-holder {
      .ant-tabs-tabpane-active {
        width: 100%;
        height: 100%;
      }
    }
  }
  .searchbar-alignment {
    width: 100%;
    height: 62px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 14px;
    .icon-searchbar {
      img {
        top: 20px;
        left: 22px;
        position: absolute;
        z-index: 6;
      }
    }
    input {
      width: 378px;
      height: 36px;
      padding: 0px 20px 0px 44px;
      background: #f1f2f6;
      border-radius: 18px;
      position: absolute;

      // color: aqua;
      ::placeholder {
      }
    }
  }
  .follower-container {
    width: 100%;
    height: 70px;
    background: #ffffff;
    padding: 0 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    .main-section-follow {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 14px;
      .profile-name-alignment {
        width: 36px;
        height: 36px;
        img {
          height: 100%;
          width: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .store-follower-main {
        .follower-name-align {
          align-items: center;
          p {
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.32px;
            color: #0a1f44;
            // margin-top: 14px;
          }
        }
        .store-follower-info {
          margin-top: -4px;
          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #acb1c0;
          }
        }
      }
    }

    .follow-button-align {
      width: auto;
      height: 25px;
      background: var(--primary-500);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.32px;
        color: #f8f8f8;
        padding: 8px;
      }
    }
  }
}
