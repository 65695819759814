.addProductTagsSection {
    padding: 0 0 16px 0;
    .addProductTagsBox {
        border-radius: 10px;
        border: 1px solid var(--secondary-50);
        background: #FFF;
        padding: 10px 16px 16px 16px;

        .addProductTagsBoxHeading {
            h4 {
                font-family: "Poppins", sans-serif;
                font-weight: 600;
                font-size: 18px;
                line-height: 27px;
                color: #495057;
                margin-right: 3px;
            }

        }

        .addProductTagDetailsAlignment {
            .tageFormAlignment {
                margin: 16px 0 0 0;
                display: flex;
                align-items: center;
                gap: 8px;

                .enterCodeInput {
                    input {
                        background: #f8f8f8;
                        border: 1px solid #eceef4;
                        border-radius: 6px;
                        padding: 4px 14px;
                        font-family: "Poppins", sans-serif;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 20px;
                        height: 28px;
                        max-width: 257px;
                        width: 2578px;

                        &::placeholder {
                            color: #acb1c0;

                        }
                    }
                }

                .addButtonAlignment {
                    button {
                        background: #f8f8f8;
                        border-radius: 6px;
                        padding: 4px 16px;
                        font-family: "Poppins", sans-serif;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 20px;
                        color: var(--primary-500);
                        border: none;
                        cursor: pointer;

                        @media only screen and (max-width:"767px") {
                            background: var(--primary-50);
                        }
                    }
                }
            }

            .tagDetailsAlignment {
                margin: 8px 0 0 0;
                display: flex;
                align-items: center;
                gap: 8px;
                flex-wrap: wrap;

                @media only screen and (max-width:"767px") {
                    display: none;
                }

                .tagBoxAlignment {
                    min-width: 94px;
                    height: 26px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 8px;
                    // border: 1px solid #eceef4;
                    border-radius: 13px;
                    background: #FFFAEA;
                    padding: 4px 10px;

                    &.purpleColor {
                        background: #F0E9F6;
                    }

                    &.pinkColor {
                        background: #FFF2E9;
                    }

                    span {
                        font-family: "Poppins", sans-serif;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18px;
                        color: #ffffff;
                    }
                    svg{
                        cursor: pointer;
                    }

                    img {
                        display: block;
                        cursor: pointer;
                    }
                }
            }

            .mobileViewTagDetailsAlignment {
                margin: 8px 0 0 0;
                display: flex;
                align-items: center;
                gap: 8px;
                flex-wrap: wrap;
                display: none;

                @media only screen and (max-width:"767px") {
                    display: flex;
                }

                .mobileViewTagBoxAlignment {
                    border-radius: 13px;
                    background: #F8F8F8;
                    min-width: 76px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 8px;
                    padding: 1px 9px;

                    p {
                        color: var(--secondary-900);
                        font-family: 'Poppins', sans-serif;
                        font-size: 15px;
                        font-weight: 500;
                        line-height: 22px;
                    }
                }
            }
        }
    }
}