.feed-wrapper {
  max-width: 1300px;
  margin: 0 auto;
  margin-top: 30px;
}

.react-emoji {
  .react-input-emoji--container {
    margin: 0;
    border: none;
    background-color: transparent;
    width: 100%;
  }
}

.feed-dropdown {
  .ant-dropdown-menu {
    width: 196px !important;
    border-radius: 10px;
    padding: 0;
    // margin-left: -174px;
    // margin-top: 10px;
    margin-left: -172px;
    margin-top: -27px;
  }

  .ant-dropdown-menu-item {
    padding: 0;
    margin-bottom: 0px;

    &:last-child {
      margin: 0;
    }
  }

  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: transparent;

    .dropdown-content {
      h5 {
        color: var(--primary-500);
      }
    }
  }

  .dropdown-option {
    align-items: center;

    &:hover {
      background-color: #e4e4e8;
      border-radius: none !important;
    }

    // background-color: plum;
    .dropdown-main {
      display: flex;
      align-items: center;
      justify-content: space-between !important;
      padding: 0px 17px;

      .dropdown-icon {
        flex: 20%;
        text-align: left;
      }

      .dropdown-content {
        flex: 80%;

        h5 {
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
          color: #495057;
          margin: 0;
        }

        p {
          &:hover {
            font-size: 8px;
            line-height: 12px;
            color: #808191;
            font-weight: 400;
            color: #495057;
          }

          font-size: 8px;
          line-height: 12px;
          color: #808191;
          margin: 0;
        }
      }
    }
  }

  .dropdown-option {
    &:hover {
      h5 {
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        color: var(--secondary-900);
      }

      p {
        font-size: 8px;
        line-height: 12px;
        color: #808191;
        font-weight: 400;
        color: #495057;
      }
    }
  }

  .dropdown-option-last {
    &:hover {
      background-color: #e4e4e8;
      border-radius: 0px 0px 10px 10px;
    }
  }

  .dropdown-option-first {
    &:hover {
      background-color: #e4e4e8;
      border-radius: 10px 10px 0px 0px;
    }
  }
}
