.sellerPaymentMethodSection {
    margin: 0 0 16px 0;

    @media only screen and (max-width:"767px") {
        margin: 0;
    }

    .sellerPaymentMethodBox {
        background: var(--white);
        padding: 22px 32px;
        border: 2px solid var(--white);
        border-radius: 10px;

        @media only screen and (max-width:"767px") {
            padding: 16px;
        }

        .sellerPaymentMethodHeading {
            @media only screen and (max-width:"767px") {
                display: none;
            }
 
            h5 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: var(--secondary-900);
            }
        }

        .sellerPaymentMethodDetails {
            padding: 16px 0 0 0;

            @media only screen and (max-width:"767px") {
                padding: 0;
            }

            .sellerPaymentMethodCardBox {
                width: 100%;
                height: 154px;
                position: relative;

                @media only screen and (max-width:"767px") {
                    height: 218px;
                }

                img {
                    width: 100%;
                    height: 100%;
                }

                .sellerPaymentCardDetailsAlignment {
                    .sellerPaymentLogoAlignment {
                        position: absolute;
                        top: 8px;
                        left: 16px;
                    }

                    .sellerWifeTrasferAlignment {
                        position: absolute;
                        top: 13px;
                        right: 16px;

                        p {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 20px;
                            color: var(--white);
                        }
                    }

                    .sellerCardPersonDetailsAlignment {
                        position: absolute;
                        bottom: 11px;
                        right: 16px;
                        left: 16px;


                        h6 {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 20px;
                            color: var(--white);
                        }

                        p {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            color: var(--white);
                        }
                    }
                }
            }
        }
    }
}