.aboutStoreSection {
  margin: 0 94px;

  @media only screen and (max-width: '1199px') {
    margin: 8px 14px 8px 18px;
  }

  @media only screen and (max-width: '767px') {
    margin: 8px 14px 8px 18px;
  }

  .aboutStoreGrid {
    display: grid;
    grid-template-columns: 1fr 412px;
    gap: 16px;

    @media only screen and (max-width: '1099px') {
      grid-template-columns: 1fr;
      gap: 0;
    }

    @media only screen and (max-width: '767px') {
      padding: 0 0 30px 0;
    }

    .aboutStoreGridItem {
      .noDataWhiteBoxAlignment {
        background: #ffffff;
        border-radius: 18px;
        width: 100%;
        height: 100%;
      }

      .aboutStickyAlignment {
        @media only screen and (max-width: '1099px') {
          display: none;
        }

        position: sticky;
        top: 92px;
      }

      .aboutStoreitmemBox {
        // background: #ffffff;
        // border-radius: 16px;
        border-radius: 16px;
        border: 2px solid #eef1fd;
        background: #fff;
        padding: 20px 28px 16px 31px;

        @media only screen and (max-width: '1099px') {
          padding: 12px 16px;
        }

        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: var(--secondary-900);
          padding: 0 0 16px 0;

          @media only screen and (max-width: '1099px') {
            padding: 0 0 8px 0;
          }
        }

        .noDescripionAlignment {
          padding: 0 0 50px 0;
          span {
            color: var(--secondary-500);
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
          }
        }

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #808191;
          padding: 0 0 8px 0;
        }

        a {
          cursor: pointer;
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 14px;
          color: var(--primary-500);
          text-align: center;
          display: block;

          @media only screen and (max-width: '1099px') {
            font-size: 13px;
          }
        }
      }

      .aboutOtherDetailsAlignment {
        .threeGridAlignment {
          display: grid;
          align-items: center;
          grid-template-columns: 204px 1fr 191px;
          gap: 7px;
          margin: 8px 0 0 0;

          @media only screen and (max-width: '1099px') {
            grid-template-columns: 1fr;
          }

          .threeGridItem {
            .aboutOtherDetailsBox {
              // background: #ffffff;
              // border-radius: 16px;
              border-radius: 16px;
              border: 2px solid #eef1fd;
              background: #fff;
              padding: 10px 16px;
              height: 147px;
              max-height: 147px;

              @media only screen and (max-width: '1099px') {
                padding: 12px 16px;
                height: auto;
              }

              h1 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                color: #000000;
              }

              .noSocialData {
                padding: 33px 0 0 0;
                span {
                  color: var(--secondary-500);
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 21px;
                }
              }

              .bosyDetails {
                text-align: center;
                padding: 31px 0 0 0;

                @media only screen and (max-width: '1099px') {
                  padding: 16px 0 0 0;
                  text-align: left;
                }

                span {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 400;
                  font-size: 18px;
                  line-height: 27px;
                  color: #495057;

                  @media only screen and (max-width: '1099px') {
                    font-size: 14px;
                    line-height: 21px;
                  }
                }
              }

              .locationDetails {
                padding: 16px 0;
                display: flex;
                align-items: flex-start;
                gap: 8px;

                @media only screen and (max-width: '1099px') {
                  padding: 16px 0 0 0;
                }

                .iconAlignment {
                  width: 24px;
                  min-width: 24px;
                  max-width: 24px;
                  height: 24px;

                  img {
                    width: 100%;
                    height: 100%;
                    display: block;
                  }
                }

                .locatopnNameAddressDetails {
                  @media only screen and (max-width: 1399px) {
                    height: 55px;
                    overflow-y: auto;
                  }

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #495057;
                  }
                }
              }

              .socialIconAlignment {
                padding: 29px 0 0 0;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;

                @media only screen and (max-width: '1099px') {
                  padding: 16px 0 0 0;
                  justify-content: flex-start;
                }
              }
            }
          }
        }
      }

      .storeOwnerBoxAlignment {
        margin: 8px 0 0 0;
        // background: #ffffff;
        // border-radius: 16px;
        border-radius: 16px;
        border: 2px solid #eef1fd;
        background: #fff;
        padding: 10px 21px 32px 21px;

        h1 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: #000000;
        }

        .storeOwnerDetailsAlignment {
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media only screen and (max-width: '1099px') {
            padding: 16px 0 0 0;
            display: block;
          }

          .ownerDetails {
            padding: 0 0 0 16px;
            display: flex;
            align-items: center;
            gap: 16px;

            @media only screen and (max-width: '1099px') {
              gap: 30px;
            }

            .ownerImg {
              width: 80px;
              min-width: 80px;
              max-width: 80px;
              height: 80px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                display: block;
              }
            }

            .ownerNameAlignmwnt {
              h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 18px;
                line-height: 27px;
                color: #495057;
              }

              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                color: #808191;
              }
            }
          }

          .followButtonAlignment {
            @media only screen and (max-width: '1099px') {
              display: flex;
              align-items: center;
              gap: 8px;
              padding: 22px 0 0 0;
            }

            button {
              background: var(--primary-500);
              border-radius: 8px;
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #f8f8f8;
              width: 117px;
              height: 32px;
              cursor: pointer;

              @media only screen and (max-width: '1099px') {
                width: 100%;
              }

              &.following {
                background: #f8f8f8;
                color: var(--primary-500);

                &:hover {
                  background: #eef1fc;
                }
              }
            }

            .messageChatButtonAlignment {
              display: none;

              @media only screen and (max-width: '1099px') {
                display: block;
              }

              button {
                width: 32px;
                height: 32px;
                background: var(--primary-50);
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
      }

      .activitiesAlignment {
        margin: 8px 0 0 0;

        padding: 22px 17px;
        border-radius: 16px;
        border: 2px solid #eef1fd;
        background: #fff;

        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
        }

        .chatAreaAlignment {
          margin: 13px 0 0 0;
          background: #ffffff;
          border: 1px solid #e6e8ec;
          border-radius: 10px;
          padding: 21px 23px;

          .dropdownAlignment {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .dropdownBox {
              background: #ffffff;
              border: 0.5px solid #e4e4e8;
              border-radius: 10px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 8px;
              height: 34px;
              width: 174px;
              padding: 0 20px;

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                color: var(--primary-500);
              }
            }
          }
        }

        .noActivitySection {
          width: 100%;
          height: 380px;
          border-radius: 8px;
          background: var(--primary-50);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 16px 0 0 0;

          span {
            color: var(--violet-300);
            font-size: 12px;
            font-style: italic;
            font-weight: 400;
            line-height: 14px;
          }
        }
      }

      .followAlignment {
        // background: #ffffff;
        // border-radius: 16px;
        border-radius: 16px;
        background: #f3f5f8;
        padding: 16px;

        @media only screen and (max-width: '1099px') {
          display: none;
        }

        .headingAlignment {
          display: flex;
          align-items: center;
          // justify-content: space-between;
          gap: 4px;

          h4 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: var(--secondary-900);
          }

          p {
            color: #7a7e88;
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
          }

          span {
            color: #7a7e88;
            font-size: 13px;
            font-weight: 500;
            line-height: 21px;
            display: block;
          }
        }

        .serachAlignment {
          margin: 18px 0 0 0;

          .searchInputAlignment {
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            position: relative;

            .iconAlignment {
              position: absolute;
              top: 11px;
              left: 11px;

              img {
                cursor: pointer;
              }
            }

            input {
              font-family: 'Poppins', sans-serif;
              width: 100%;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              display: flex;
              align-items: center;
              padding: 8px 10px 8px 40px;
              background: transparent;
              border: none;
              height: 100%;

              color: #1e2432;

              &::placeholder {
                color: #acb1c0;
              }
            }
          }
        }
      }

      .followPeopleDetailsAlignment {
        padding: 20px 0 0 0;
        height: auto;
        max-height: 400px;
        overflow-y: auto;

        .followPeopleDetailsFlex {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 0 16px 0;

          &:last-child {
            padding: 0;
          }

          .followProfileAlignment {
            display: flex;
            align-items: center;
            gap: 16px;

            .profileImg {
              width: 40px;
              max-width: 40px;
              min-width: 40px;
              height: 40px;

              img {
                width: 100%;
                height: 100%;
                cursor: pointer;
                display: block;
                border-radius: 50%;
                object-fit: cover;
              }
            }

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.1px;
              color: var(--secondary-900);
            }
          }

          .followButtonAlignment {
            button {
              width: 113px;
              height: 32px;
              // background: var(--primary-500);
              border-radius: 8px;
              font-family: 'Poppins', sans-serif;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #556ee6;
              border: none;
              cursor: pointer;
              background: transparent;

              &.following {
                color: #7a7e88;
              }
            }
          }
        }
      }

      .specialOfferBoxAlignment {
        background: #ffffff;
        border-radius: 16px;
        padding: 6px 12px;
        margin: 8px 0 0 0;

        @media only screen and (max-width: '1099px') {
          display: none;
        }

        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #000000;
          padding: 0 0 0 11px;
        }

        .specialOfferDetailsAlignment {
          padding: 9px 0 0 0;

          .specialOfferBg {
            width: 100%;
            height: 151px;
            position: relative;
            background: #1c1c1e;
            border-radius: 10px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 10px;
            }

            .moreOptionAlignment {
              background: rgba(0, 0, 0, 0.4);
              width: 23px;
              height: 23px;
              border-radius: 50%;
              cursor: pointer;
              position: absolute;
              top: 8px;
              right: 8px;
              cursor: pointer;

              img {
                padding: 5px;
              }
            }

            .cardOfferDetails {
              position: absolute;
              display: flex;
              flex-direction: column;
              right: 0;
              left: 0;
              align-items: center;
              height: 151px;
              justify-content: center;
              top: 0;

              h5 {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 24px;
                line-height: 36px;
                color: #ffffff;
              }

              h6 {
                font-family: 'PoetsenOne';
                font-weight: 400;
                font-size: 24px;
                line-height: 29px;
                color: #ffffff;

                span {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 26px;
                  line-height: 39px;
                  color: #ff7d23;
                  display: block;
                  text-align: center;
                }
              }
            }

            .expiredAddAlignment {
              display: flex;
              align-items: center;
              justify-content: space-between;
              position: absolute;
              bottom: 10px;
              left: 21px;
              right: 10px;

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 10px;
                line-height: 15px;
                color: #ffffff;
                max-width: 87px;
              }

              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #ffffff;
              }
            }
          }
        }
        .NoOffersbox {
          margin: 20px 0 0 -12px;
          width: calc(100% + 24px);
          height: 213px;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 32px;
          border-radius: 16px;
          background-color: #eef1fd;

          p {
            color: #9c6ac5;
            text-align: center;
            font-family: 'Poppins' sans-serif;
            font-size: 12px;
            font-style: italic;
            font-weight: 400;
            line-height: normal;
            max-width: 272px;
          }
        }
      }

      .noDataWhiteBoxAlignment {
        border-radius: 16px;
        background: #eef1fd;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        height: calc(100vh - 530px);

        p {
          color: #9c6ac5;
          font-size: 12px;
          font-style: italic;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}
