.mobileAddSizeVariantSection {
  padding: 16px 24px 16px 16px;

  .mobileAddSizeVariantHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h6 {
      color: var(--secondary-900);
      font-family: 'Poppins', sans-serif;
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
    }

    a {
      color: var(--primary-500);
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .mobileAddSizeOptionValueBodyDetails {
    .mobileAddSizeOptionValueTop {
      height: calc(100vh - 425px);
      overflow-y: auto;

      .mobileAddSizeNoSizeAddedAlignment {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          color: #808191;
          font-family: 'Poppins', sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
        }
      }

      .mobileAddSIzeDetailsAlignment {
        padding: 32px 0 0 0;

        .mobileAddSIzeDetailsSizeAlignment {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0 0 16px 0;

          &:last-child {
            margin: 0;
          }

          p {
            color: #808191;
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
          }

          a {
            color: var(--primary-500);
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
          }
        }
      }
    }

    .mobileAddSizeOptionValueBottom {
      .mobileOptionValueInputDetails {
        label {
          padding: 0 0 0 8px;
          color: #495057;
          font-family: 'Poppins', sans-serif;
          font-size: 13px;
          font-weight: 400;
          line-height: 19px;
        }

        .mobileOptionValueInput {
          width: 100%;
          height: 40px;
          position: relative;

          input {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            border: 1px solid #eceef4;
            background: #eceef4;
            padding: 0 16px;
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;

            &::placeholder {
              color: var(--secondary-500);
            }
          }

          .mobileDropdownAlignment {
            position: absolute;
            bottom: 46px;
            left: 0;
            border-radius: 10px;
            border: 1px solid var(--grey-4, #e4e4e8);
            background: #fff;
            box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.05);
            width: 100%;
            height: auto;
            max-height: 348px;
            overflow-y: auto;
            padding: 16px 0;

            p {
              padding: 4px 20px;
              margin: 0 0 8px 0;
              color: var(--secondary-900);
              font-family: 'Poppins', sans-serif;
              font-size: 16px;
              font-weight: 500;
              line-height: 24px;
              cursor: pointer;

              &:hover {
                background: #eceef4;
                box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.05);
              }

              &:last-child {
                margin: 0;
              }
            }
          }
        }
      }

      .mobileOptionViewButtonAlignment {
        padding: 32px 0 0 0;

        button {
          width: 100%;
          height: 44px;
          border-radius: 10px;
          background: var(--primary-100);
          color: #fff;
          text-align: center;
          font-family: 'Poppins', sans-serif;
          font-size: 18px;
          font-weight: 500;
          line-height: 28px;
          &.activeButton {
            background: var(--primary-500);
          }
        }
      }
    }
  }
}
