.noDataSectionAlignment {

  border-radius: 15.088px;
  background: rgba(85, 110, 230, 0.13);
  padding: 0px 25px 23px 25px;

  @media (max-width: 576px) {
    padding: 0px 24px 24px 24px;
    margin: 0 16px;
  }

  .noDataTitle {
    font-size: 24px;
    font-weight: bold;
    padding-top: 12px;
    padding-bottom: 2.5rem;

    @media (max-width: 576px) {
      padding-bottom: 1.5rem;
    }
  }

  .subboxDesign {
    border-radius: 15.088px;
    background: var(--Primary-primary-50, #eef1fd);
    padding: 60px;

    @media (max-width: 576px) {
      padding: 20px;
    }

    .centerAlignmentIcon {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 0 0 30px 0;

      @media (max-width: 576px) {
        padding: 0 0 20px 0;
      }

      img {
        @media (max-width: 576px) {
          max-width: 38px;
        }
      }
    }

    p {
      color: #9c6ac5;
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
      margin: 0;
      text-align: center;
    }
  }
}