@mixin block_btn {
  background: var(--primary-500);
  border-radius: 8px;
  display: block;
  width: 100%;
  border: unset;
  outline: unset;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    transform: translateY(-3px);
    background: #2844ac;
  }
}

.men-trendingStore-section {
  height: calc(100vh - 64px);
  // height: calc(100vh - 104px);
  overflow-y: auto;
  background: #dcddde;

  @media only screen and (max-width: 1099px) {
    height: 100vh;
  }

  .trendingStore-main-banner {
    background: #151515;
    position: sticky;
    top: 0;
    z-index: 99;

    @media only screen and (max-width: 1199px) {
      top: 0;
    }

    .trendingStore-back-alignment {
      display: flex;
      align-items: center;
      gap: 22px;
      padding: 8px 10px;
      background: #1e2432;
      display: none;

      @media only screen and (max-width: '767px') {
        display: flex;
      }

      .back_details-alignment {
        background: rgba(172, 177, 192, 0.4);
        border-radius: 10px;
        padding: 4px 9px 4px 10px;
        display: flex;
        align-items: center;
        gap: 8px;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #e4e4e8;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 58px;
        }
      }

      .mobile-heading-alignment {
        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          text-align: center;
          color: #f8f8f8;
        }
      }
    }

    .trendingStore-banner {
      background: #151515;
      // background-image: url("../../../assets/img/MensCategory/MentrendingStoreBg.png");
      // width: 100%;
      // height: 100%;
      // background-repeat: no-repeat;
      // background-size: cover;
      // background-position: center;
      position: sticky;
      top: 60px;
      z-index: 99;

      @media only screen and (max-width: '1199px') {
        top: 0;
      }

      @media only screen and (max-width: '767px') {
        padding: 0 16px;
        position: sticky;
        top: 48px;
        z-index: 99;
      }

      .men-trending-store-banner-img-alignment {
        position: absolute;
        top: 0;
        right: 0;

        img {
          width: 100%;
          height: 178px;
          object-fit: cover;
          object-position: center;
          display: block;

          @media only screen and (max-width: '767px') {
            display: none;
          }

          &.mobile-view-banner {
            display: none;

            @media only screen and (max-width: '767px') {
              display: block;
              height: 100px;
              display: block;
            }
          }
        }
      }

      .container {
        max-width: 1300px;
        margin: auto;
        padding: 0 20px;

        @media only screen and (max-width: '767px') {
          padding: 0;
        }

        .trendingStore-small-banner-alignment {
          background: #f9f9f9;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          gap: 160.5px;
          position: relative;

          .trendingStore-banner-section {
            height: 78px;
            display: flex;
            align-items: center;
            gap: 160.5px;

            @media only screen and (max-width: '999px') {
              gap: 80px;
              padding: 0 20px;
            }
          }

          .trendingStore-heading-alignment {
            h4 {
              font-family: 'Poppins', sans-serif;
              font-weight: 300;
              font-size: 30px;
              line-height: 60px;
              color: #000000;
              padding: 0 0 4px 0;
            }
          }
        }

        div {
          img {
            cursor: pointer;
            display: block;
          }
        }

        .trendingStore-banner-section {
          height: 178px;
          display: flex;
          align-items: center;
          gap: 160.5px;
          position: relative;

          @media only screen and (max-width: '1299px') {
            gap: 80px;
            padding: 0 20px;
          }

          @media only screen and (max-width: '767px') {
            height: 100px;
          }

          .left-arrow-alignment {
            @media only screen and (max-width: '767px') {
              display: none;
            }
          }
        }

        .trendingStore-heading-alignment {
          h4 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 40px;
            line-height: 60px;
            color: #f8f8f8;
            padding: 0 0 4px 0;
            text-transform: uppercase;

            @media only screen and (max-width: '767px') {
              font-size: 20px;
              line-height: 30px;
            }
          }

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 300;
            font-size: 18px;
            line-height: 27px;
            color: #f8f8f8;
            text-transform: capitalize;

            @media only screen and (max-width: '767px') {
              font-size: 14px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 200px;
            }
          }
        }
      }
    }
  }

  .trending-store-alignment {
    padding: 20px 0 0 0;
    background: #dcddde;
    // height: calc(100vh - 242px);
    // overflow-y: auto;

    @media only screen and (max-width: '1024px') {
      padding: 16px 0;
      height: 100%;
      overflow-y: auto;
    }

    .popularStoreDetailsGridView {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;

      @media only screen and (max-width: '767px') {
        grid-template-columns: 1fr;
      }
    }

    .container {
      max-width: 1300px;
      margin: auto;
      padding: 0 20px;
      .popularStoreDetailsGridView {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;

        @media only screen and (max-width: '767px') {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}
