.mediapriviewmodal {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.5);
    z-index: 999999999;
    backdrop-filter: brightness(0.5);
    border-radius: 16px;

    .closebuttonalignment {
        display: flex;
        justify-content: end;
        align-items: center;
        padding: 16px 16px 10px;

        .closebuttondesign {
            height: 40px;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            border-radius: 16px;
            cursor: pointer;
        }
    }

    .centeralignmentmodal {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100% - 66px - 66px);
        width: 100%;
        padding: 0 0 66px 0;

        .mediapreviewimg {
            border: 5px solid #fff;
            border-radius: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;

            img {
                max-width: 400px;
                width: 100%;
                height: auto;
            }
        }
    }
}