body {
  .slick-dots {
    width: auto;
    top: -40px;
    left: 65px;
    height: 30px;

    li {
      margin-left: 3px;
      margin-right: 3px;
      width: 8px;

      button {
        width: 8px;
        padding: 0;

        &:before {
          content: '';
          width: 8px;
          height: 8px;
          background: #c4c4c4;
          border-radius: 4px;
          opacity: 1;
        }
      }

      &.slick-active {
        width: /* 20px */ auto;

        button {
          &:before {
            width: 8px;
            background: #EEF1FD;
          }
        }
      }
    }
  }

  .socialUserProfile {
    .slick-dots {
      right: 140px;
    }
  }


}

.product-info {
  .ant-tabs-nav {
    background-color: #fff;
    padding: 15px;
    margin-bottom: 16px;
  }

  .ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
    justify-content: space-around;
  }

  .ant-tabs-tab {
    color: #acb1c0;
    margin: 0;

    &.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #252525;
    }
  }

  .ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar {
    height: 5px;
    background: #6b21a8;
  }
}

.gift-page-modal {
  .ant-modal-content {
    border-radius: 10px;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--primary-500);
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #425fec;
  }

  .ant-checkbox {
    .ant-checkbox-inner {
      border-color: #6366f1;
    }
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #6366f1;
      border-color: #6366f1;
    }
  }
}

.ant-switch-checked {
  background-color: #6366f1;
}

.ant-radio-checked {
  .ant-radio-inner {
    border-color: #6366f1;
  }

  ::after {
    background-color: #6366f1;
  }

  :hover {
    border-color: #6366f1;
  }
}

.group-feed-modal {
  .ant-tabs-nav {
    margin-bottom: 20px !important;

    .ant-tabs-nav-wrap {
      display: flex;
      border-bottom: #dbdada solid 1px;
    }
  }

  .ant-tabs-top>.ant-tabs-nav::before,
  .ant-tabs-top>div>.ant-tabs-nav::before {
    border: 0;
  }

  .ant-tabs-nav-list {
    border-radius: 0;

    .ant-tabs-tab {
      padding: 0;
      margin: 0;

      .ant-tabs-tab-btn {
        margin: 0px 0px 8px 0px;
        padding: 0 16px 0 16px;
        font-size: 12px;
        line-height: 16px;
        color: #a7a7a7;
        font-weight: 500 !important;
        // border-left: solid 1px #A7A7A7;
        // height: 15px;
      }
    }

    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: #000;
      }
    }

    .ant-tabs-ink-bar {
      height: 2px;
      background: #5f40af;
    }
  }

  // .ant-tabs-content-holder{
  //     .ant-tabs-tabpane-active{
  //         width: 100%;
  //         height: 100%;
  //     }
  // }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #6366f1;
      border-color: #6366f1;
    }
  }
}

.ant-menu-submenu-popup {
  position: fixed;
  z-index: 9999999999;
}

::-webkit-scrollbar {
  width: 7px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8c8d92;
  border-radius: 5px;
  // display: none;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #818185;
  // display: none;
}

// In a global CSS file, e.g., styles.css
.ant-notification {
  z-index: 9999999999; // Adjust this value as needed
}

// In a global CSS file, e.g., styles.css
.ant-notification-notice-content {
  // margin-top: 70px !important; // Adjust this value as needed
}

// Post modal styles
.post-modal {
  @media (max-width: 768px) {
    .ant-modal {
      margin: 0;
      padding: 0;
      max-width: 100%;
      width: 100% !important;
      position: fixed;
      bottom: 0;
    }

    .ant-modal-content {
      border-radius: 24px 24px 0 0;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      max-height: 90vh;
      overflow-y: auto;
      background: white;
      padding-bottom: env(safe-area-inset-bottom);
    }

    .ant-modal-wrap {
      position: fixed;
      bottom: 0;
    }

    .post-footer-left {
      gap: 8px;
      
      .flex.items-center {
        gap: 8px !important;
      }

      button {
        padding: 6px 8px;
        font-size: 12px;
      }
    }
  }
}