.peopleModalBoxAlignment {
  border-radius: 8px;
  height: calc(100vh - 96px);
  width: 100%;
  overflow-y: auto;

  @media only screen and (max-width: '1099px') {
    height: 100%;
    padding: 16px;
  }

  @media only screen and (max-width: '1024px') {
    height: 100%;
    padding: 0px;
    background: transparent;
  }

  .newSuggestedStores {
    background-image: url('/assets/img/people-banner.png');
    width: 100%;
    height: 140px;
    background-repeat: no-repeat;
    border-radius: 8px 8px 0px 0px;
    background-size: cover;
    padding: 62px 60px 0 60px;
    margin: 0 0 10px 0;

    @media (max-width: 576px) {
      margin: 0;
      height: 105px;
      padding: 12px 16px;
      position: relative;
      border-radius: 0px;
    }

    .mobileShow {
      display: none;

      @media (max-width: 576px) {
        display: block;
      }
    }

    .storesGrid {
      display: grid;
      grid-template-columns: 1fr 411px;
      gap: 20px;

      @media (max-width: 576px) {
        grid-template-columns: 1fr;
        gap: 0;
      }

      p {
        margin: 0 0 2px 0;
        color: #fff;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        @media (max-width: 576px) {
          text-align: center;
        }
      }

      span {
        display: block;
        color: #e9e9e9;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.1px;
        @media (max-width: 576px) {
          text-align: center;
        }
      }

      .peopleFollowerSearchAlignment {
        height: 40px;
        display: flex;
        align-items: center;
        background: #fff;
        border-radius: 10px;
        padding: 0 16px 0 0;

        @media only screen and (max-width: '1024px') {
          width: 100%;
          max-width: 100%;
        }
        @media (max-width: 576px) {
          display: none;
        }
        input {
          width: 100%;
          height: 100%;
          background: transparent;
          border: none;
          font-family: 'Poppins', sans-serif;
          font-size: 16px;
          font-weight: 400;
          background-color: #fff;
          line-height: 24px;

          &::placeholder {
            color: #acb1c0;
          }
        }
      }
    }
  }

  .peopleModalHeadingAlignment {
    @media only screen and (max-width: '1099px') {
      display: none;
    }

    h4 {
      color: var(--bookmark-heading-color);
      font-family: 'Poppins', sans-serif;
      font-size: 24px;
      font-weight: 600;
      line-height: 38px;
    }
  }

  .peopleMobileViewSticky {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;

    @media only screen and (max-width: '1024px') {
      display: block;
    }

    .peopleModalMobileViewHeaderAlignment {
      padding: 8px 16px 12px 16px;
      text-align: center;
      background-color: #fff;
      margin: 0 0 8px 0;
      position: relative;

      h4 {
        color: var(--secondary-900);
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
      }

      .mobileViewBackArrowAlignment {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 16px;
        z-index: 99;
      }
    }
  }

  .peopleModalTabAlignment {
    :global(.ant-tabs-nav) {
      margin: 0;

      @media only screen and (max-width: '1024px') {
        padding: 0px 15px;
        background: #fff;
      }
    }

    @media only screen and (max-width: '1024px') {
      padding: 0;
      background: #fff;
      height: calc(100% - 52px);
      overflow-y: auto;
    }

    @media (max-width: 576px) {
      height: calc(100% - 105px);
    }

    :global(.ant-tabs-tab-btn) {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: var(--secondary-500);
    }

    :global(.ant-tabs-tab-active) {
      :global(.ant-tabs-tab-btn) {
        color: var(--primary-700);
      }
    }

    :global(.ant-tabs-ink-bar) {
      background: var(--primary-700);
    }

    :global(.ant-tabs-top > .ant-tabs-nav::before) {
      border-bottom: 1px solid var(--secondary-100);
    }

    :global(.ant-tabs-nav-operations) {
      display: none !important;
    }
  }
}
