.message-modal-section {
  .message-modal-alignment {
    .message-modal-grid-alignment {
      display: flex;
      justify-content: start;
      // grid-template-columns: 415px 1fr;
      gap: 8px;

      @media only screen and (max-width: '767px') {
        display: block;
        gap: 0;
      }

      .message-gridItem-alignment-users {
        max-width: 415px;
        width: 100% !important;
      }

      .message-gridItem-alignment-chat-main {
        width: calc(100% - 415px);
        
        @media only screen and (max-width: '767px') {
          width: 100%;
        }
      }

      .message-gridItem-alignment {
        .message-box-alignment {
          background: #ffffff;
          width: 100%;
          height: calc(100vh - 96px);
          display: flex;
          border-radius: 16px;
          border: 2px solid #eef1fd;

          //   position: relative;
          @media only screen and (max-width: '767px') {
            display: none;
          }

          .message-box-child-alignment {
            width: 100%;
            position: relative;

            .invite-people-modal-alignment {
              .invite-people-modal-wrapper-alignment {
                background: rgba(30, 36, 50, 0.8);
                border-radius: 10px 0px 0px 10px;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;

                .invite-white-modal-box-alignment {
                  background: #ffffff;
                  border-radius: 6px;
                  margin: 85px 6px;
                  width: 383px;
                  height: 584px;
                  z-index: 99999;

                  .invite-people-modal-header-alignment {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 16px;
                    border-bottom: 1px solid #f1f1f5;

                    h6 {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 20px;
                      line-height: 20px;
                      color: var(--secondary-900);
                    }

                    .invite-header-close-alignment {
                      width: 26px;
                      max-width: 26px;
                      min-width: 26px;
                      height: 26px;
                      border-radius: 50%;
                      background: #acb1c0;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      cursor: pointer;
                    }
                  }

                  .invite-prople-modal-body-alignment {
                    padding: 16px 16px 0 16px;
                    position: relative;

                    .invite-people-search-alignment {
                      .invite-people-account-search-alignment {
                        background: #ffffff;
                        border: 1px solid #f3f3f3;
                        border-radius: 10px;
                        width: 100%;
                        height: 40px;
                        display: flex;
                        align-items: center;

                        input {
                          background: transparent;
                          border: none;
                          width: 100%;
                          height: 100%;
                          padding: 0 12px 0 0;
                          font-family: 'Poppins', sans-serif;
                          font-weight: 400;
                          font-size: 16px;
                          line-height: 24px;

                          &::placeholder {
                            color: #acb1c0;
                          }
                        }
                      }
                    }

                    .invite-selected-alignment {
                      padding: 16px 0 0 0;

                      span {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        color: #808191;
                      }
                    }

                    .invite-selected-people-details-alignment {
                      padding: 12px 0 0 0;
                      height: 368px;
                      overflow-y: auto;

                      .invite-selected-people-flex-alignment {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 6px 16px;
                        cursor: pointer;
                        transition: 0.2s ease-in-out;
                        margin: 0 0 4px 0;

                        &:last-child {
                          margin: 0;
                        }

                        &:hover {
                          background: var(--primary-50);
                          border-radius: 10px;
                          transition: 0.2s ease-in-out;
                        }

                        &.invited-active {
                          background: var(--primary-50);
                          border-radius: 10px;
                          transition: 0.2s ease-in-out;
                        }

                        .invite-selected-checkbox-alignment {
                          position: relative;

                          // input[type='checkbox'] + label {
                          //   display: block;
                          //   cursor: pointer;
                          //   margin: 0;
                          // }

                          // input[type='checkbox'] {
                          //   display: none;
                          // }

                          // input[type='checkbox'] + label:before {
                          //   border: 2px solid #acb1c0;
                          //   border-radius: 6px;
                          //   display: flex;
                          //   width: 20px;
                          //   height: 20px;
                          //   vertical-align: bottom;
                          //   color: transparent;
                          //   transition: 0.2s;
                          //   font-size: 24px !important;
                          //   justify-content: center;
                          //   align-items: center;
                          // }

                          // input[type='checkbox']:checked + label:before {
                          //   content: '';
                          //   // background-color: #FFFFFF;
                          //   color: #acb1c0;
                          //   border: 2px solid #556ee6;
                          //   border-radius: 6px;
                          // }

                          // input[type='checkbox']:checked + label:after {
                          //   display: block;
                          //   position: absolute;
                          //   top: 0;
                          //   left: 0;
                          //   width: 10px;
                          //   height: 10px;
                          //   border: 1px solid none !important;
                          //   border-width: 0 2px 2px 0;
                          //   // transform: rotate(45deg);
                          //   // border-radius: 50%;
                          //   background: var(--primary-500);
                          // }
                        }

                        span {
                          color: var(--secondary-400);
                          font-size: 13px;
                          font-style: italic;
                          font-weight: 400;
                          line-height: 20px;
                        }

                        // label:after {
                        //   top: 9px;
                        //   left: 9px;
                        //   width: 8px;
                        //   height: 8px;
                        //   border-radius: 50%;
                        //   background: var(--primary-500);
                        // }

                        .invite-selected-profile-details-alignment {
                          display: flex;
                          align-items: center;
                          gap: 8px;

                          .invite-selected-profile-img {
                            width: 32px;
                            height: 32px;

                            img {
                              width: 100%;
                              height: 100%;
                              border-radius: 50%;
                              object-fit: cover;
                            }
                          }

                          p {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 16px;
                            color: var(--secondary-900);
                          }
                        }
                      }
                    }

                    .invite-people-status-alignment {
                      background: #e8f3e6;
                      position: absolute;
                      top: 0;
                      left: 0;
                      width: 100%;
                      height: 32px;

                      .invite-atatus-details-alignment {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 4px;
                        height: 100%;

                        .check-icon-alignment {
                          img {
                            display: block;
                            cursor: pointer;
                          }
                        }

                        span {
                          font-family: 'Poppins', sans-serif;
                          font-weight: 400;
                          font-size: 15px;
                          line-height: 20px;
                          color: #1d8603;
                        }
                      }
                    }
                  }

                  .invite-people-modal-footer-alignment {
                    padding: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    gap: 24px;

                    .cancle-button-alignment {
                      button {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 20px;
                        color: var(--primary-500);
                        cursor: pointer;
                      }
                    }

                    .send-invition-button-alignment {
                      button {
                        background: var(--primary-500);
                        border-radius: 8px;
                        padding: 6px 16px;
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 20px;
                        color: #ffffff;
                        cursor: pointer;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.mobile-view-message-alignment {
  display: none;

  @media only screen and (max-width: '767px') {
    display: block;
  }
}

.message-modal-option-Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(30, 36, 50, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  z-index: 9999 !important;
  display: none;

  @media only screen and (max-width: '1099px') {
    display: block;
  }

  @media only screen and (max-width: '767px') {
    height: calc(100vh - 180px);
  }
}

.message-modal-option-Overlay.open {
  opacity: 1;
  visibility: visible;

  @media only screen and (max-width: '1099px') {
    display: block;
  }
}

.message-modal-option--modal {
  position: fixed;
  right: 0;
  width: 100%;
  height: 200px;
  background: white;
  translate: 0 100%;
  transition: 0.3s;
  border-radius: 16px 16px 0 0;
  overflow-y: auto;
  bottom: 0;

  @media only screen and (max-width: '1099px') {
    display: block;
  }

  @media only screen and (max-width: '767px') {
    bottom: 180px;
  }
}

.message-modal-body-details-alignment {
  padding: 15px;

  ul {
    li {
      padding: 10px;
      width: 100%;
      background: #f8f8f8;
      border-radius: 6px;
      margin: 0 0 8px 0;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--secondary-600);
      cursor: pointer;
      text-align: center;

      &:last-child {
        margin: 0;
      }

      &:hover {
        background: var(--primary-50);
        border-radius: 6px;
      }

      &.active-message-option {
        background: var(--primary-50);
        border-radius: 6px;
      }
    }
  }
}

.message-modal-option--modal.open {
  translate: 0;
  z-index: 9999999;
  transform: translate(0, 6%);

  @media only screen and (max-width: '1099px') {
    display: block;
  }
}
