.clubModalSection {
  height: calc(100vh - 96px);
  overflow-y: auto;
  border-radius: 16px;
  border: 2px solid var(--Primary-primary-50, #eef1fd);
  padding: 13px;

  @media only screen and (max-width: '1099px') {
    height: 100%;
    padding: 0;
    background: transparent;
  }

  @media (max-width: 576px) {
    border-radius: 0px;
    background: linear-gradient(179deg, #d3e4f7 -21.92%, rgba(255, 255, 255, 0.6) 84.82%);
  }

  .mobileViewHeaderSticky {
    display: none;

    @media (max-width: 576px) {
      display: block;
      position: sticky;
      top: 0;
      left: 0;
      z-index: 99;
    }

    .mobileViewClubModalHeader {
      padding: 8px 16px 12px 16px;
      text-align: center;
      background: #d3e4f7;
      margin: 0 0 8px 0;
      position: relative;

      h4 {
        color: var(--secondary-900);
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
      }

      .mobileViewBackArrowAlignment {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 16px;
        z-index: 99;
      }

      .mobileViewCreateClub {
        position: absolute;
        bottom: 8px;
        right: 16px;
        z-index: 99;

        .mobileCreateClubModalButton {
          button {
            padding: 6px 16px;
            border-radius: 8px;
            background: var(--primary-50);
            color: var(--primary-500);
            text-align: center;
            font-size: 14px;
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
          }
        }
      }
    }
  }

  // .clubModalAlignment {

  //   @media only screen and (max-width: '1024px') {
  //     height: 100%;
  //   }

  .clubModalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: '1024px') {
      display: none;
    }

    h5 {
      color: var(--bookmark-heading-color);
      font-size: 24px;
      line-height: 36px;
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
    }

    .createClubModalButton {
      button {
        padding: 6px 12px;
        border-radius: 8px;
        background: var(--primary-50);
        color: var(--primary-500);
        text-align: center;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
      }
    }
  }
}
