.marketplaceFooter {
    background-color: #fff;
    border-top: 1px solid #D8D8D8;
    padding: 20px 0;
    @media (max-width:576px) {
        padding: 11px 0;
    }
    .footerAlignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width:576px) {
        }
        .leftContnet {
            display: flex;
            align-items: center;
            gap: 4px;
            @media (max-width:576px){
                display: grid;
                grid-template-columns: 1fr;
                gap: 16px;
            }

            img {
                @media (max-width:576px) {
                    max-width: 100px;
                }
            }

            span {
                display: block;
                color: #0F172A;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                @media (max-width:576px) {
                  display: none;  
                }
            }
        }

        .rightContnet {
            display: flex;
            align-items: center;
            gap: 24px;
            @media (max-width:576px) {
                gap: 16px;
            }

            a {
                display: block;
                color: #0F172A;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                cursor: pointer;
                @media (max-width:576px) {
                    font-size: 14px;
                }
            }
        }
    }
}