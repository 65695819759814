.mobileJewelryClubModalSection {
    .mobileJewelryClubModalAlignment {
        .mobileJewelryClubModalHeading {
            display: flex;
            align-items: center;
            gap: 70px;
            padding: 11px 16px 10px 16px;

            h6 {
                color: var(--secondary-900);
                text-align: center;
                font-family: 'Poppins', sans-serif;
                font-size: 18px;
                font-weight: 600;
                line-height: 24px;
            }
        }

        .mobileJewelryClubModalBodyAlignment {
            padding: 0 16px;

            .mobileJewelryClubModalgridAlignment {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                gap: 16px;
            }
        }
    }
}