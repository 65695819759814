.featuredProductsBannerImg {
  width: 100%;
  height: 445px;
  border-radius: 16px;
  position: relative;
  transition: 0.4s ease-in-out;
  cursor: pointer;
  @media only screen and (max-width: '1024px') {
    width: 164px;
    height: 238px;
    min-width: 164px;
    max-width: 164px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 16px;
  }

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    background: linear-gradient(180deg, rgba(238, 241, 253, 0) 0%, #eef1fd 80.44%);
    top: 0;
    left: 0;
    border-radius: 16px;
    z-index: 9;
  }

  .featuredProductChildProfile {
    width: 235px;
    height: 235px;
    border-radius: 26px;
    background: var(--white);
    position: absolute;
    bottom: 33px;
    left: 33px;
    right: 33px;
    z-index: 99;
    transition: 0.4s ease-in-out;
    cursor: pointer;
    overflow: hidden;
    @media only screen and (max-width: '1024px') {
      width: 130px;
      height: 130px;
      min-width: 130px;
      max-width: 130px;
      left: 16px;
      right: 16px;
      bottom: 16px;
      border-radius: 8px;
    }
    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      border: 4px solid var(--white);
      border-radius: 26px;
      transform: scale(1);
      transition: 0.4s ease-in-out;
      @media only screen and (max-width: '1024px') {
        border-radius: 8px;
        border: 2px solid var(--white);
      }
    }

    .hoverEffectDetails {
      width: 100%;
      height: 155px;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: 999;
      padding: 4px;
      display: none;
      .hoverEffectBox {
        border-radius: 26px;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(30, 36, 50, 0) 0%, rgba(30, 36, 50, 0.8) 100%);
        position: relative;

        .hoverAllDetails {
          position: absolute;
          left: 0;
          bottom: 0;
          padding: 16px;
          z-index: 9999;
          width: 100%;
          p {
            color: var(--light-grey);
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            max-width: 163px;
          }

          .hoverBottomDetails {
            padding: 12px 0 0 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            .priceAlignment {
              span {
                color: var(--light-grey);
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
              }
            }

            .hoverOptionDetails {
              display: flex;
              align-items: center;
              gap: 12px;
              .optionBox {
                width: 24px;
                height: 24px;
                min-width: 24px;
                max-width: 24px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  &:hover {
    transition: 0.4s ease-in-out;
    .featuredProductChildProfile {
      img {
        transform: scale(1.2);
        transition: 0.4s ease-in-out;
      }
      .hoverEffectDetails {
        display: block;
      }
    }
  }
}
