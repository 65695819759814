.secondFollowFollowingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(30, 36, 50, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  z-index: 9999999;
  display: none;

  @media only screen and (max-width: '767px') {
    display: block;
  }
}

.secondFollowFollowingOverlayOpen {
  opacity: 1;
  visibility: visible;
}

.secondFollowFollowingModal {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  // height: calc(100% - 6%);
  height: 100%;
  background: white;
  translate: 0 100%;
  transition: 0.3s;
  border-radius: 16px 16px 0 0;
  overflow-y: auto;
  display: none;

  @media only screen and (max-width: '767px') {
    display: block;
  }

  &.fullWidth {
    border-radius: 0;
  }

  .ant-tabs-nav {
    margin-bottom: 0px !important;
    background-color: #fff;
  }

  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before {
    border: 0;
  }

  .ant-tabs-nav-list {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0;
    // padding: 0 74px;

    .ant-tabs-tab {
      padding: 0 38px;
      margin: 0;

      .ant-tabs-tab-btn {
        padding: 16px 23px;
        font-size: 15px;
        line-height: 22px;
        color: #a7a7a7;
        font-weight: 400 !important;
      }

      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          font-weight: 500 !important;
          color: var(--primary-700);
        }

        &:before {
          content: '';
          position: absolute;
          height: 3px;
          background: var(--primary-700);
          border-radius: 26px 26px 0 0px;
          bottom: 0;
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }
    }

    .ant-tabs-ink-bar {
      display: none;
    }
  }
}



.secondFollowFollowingModalOpen {
  translate: 0;
  z-index: 999999999999;
  transform: translate(0, 42px);
  // height: calc(100% - 42px);

  &.fullWidth {
    transform: translate(0, 0);
  }

  &.paymentMethod {
    transform: translate(0, 52%);
  }

  .allTopSellingDetailsHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 99;
    background: #ffffff;
    padding: 16px;

    h6 {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: var(--secondary-900);
    }

    .closeIconAlignment {
      cursor: pointer;
    }
  }
}
