.sellerOnboardingBannerAlignment {
    width: 100%;
    height: calc(100vh - 146px);

    @media only screen and (max-width:"1024px") {
        height: calc(100vh - 80px);
    }

    @media only screen and (max-width:"1024px") {
        display: none;
    }

    .sellerOnboardingBannerImg {
        width: 100%;
        height: 100%;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            border-radius: 16px;
        }


        video {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            border-radius: 16px;
        }

        &:before {
            position: absolute;
            content: '';
            background: rgba(19, 19, 19, 0.48);
            border-radius: 16px;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 99;
        }

        .sellerOnboardingBannerDetails {
            position: absolute;
            bottom: 62px;
            left: 84px;
            right: 84px;
            z-index: 999;

            @media only screen and (max-width:"1199px") {
                left: 34px;
                right: 34px;
            }

            h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 26px;
                line-height: 39px;
                text-align: center;
                color: var(--white);
                padding: 0 0 24px 0;

                @media only screen and (max-width:"1199px") {
                    font-size: 18px;
                    line-height: 24px;
                    padding: 0 0 16px 0;
                }
            }

            p {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #D9D9D9;

                @media only screen and (max-width:"1199px") {
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }
    }
}