.share-me-feed-section {
  .share-me-feed-modal {
    .share-me-feed-heder-alignment {
      border-bottom: 1px solid #eaecef;

      .share-me-feed-click-drop-alignment {
        padding: 8px 0 16px 0;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          width: 33px;
          height: 3px;
          background: #e4e4e8;
          border-radius: 1.5px;
        }
      }

      .share-me-feed-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 14px 16px 14px 16px;

        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          color: #495057;
        }

        a {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: var(--primary-500);
        }
      }
    }

    .share-me-feed-details-section {
      padding: 16px;
      // height: calc(100vh - 322px);
      // max-height: calc(100vh - 322px);
      overflow-y: auto;

      .share-me-feed-search-alignment {
        display: flex;
        align-items: center;
        gap: 8px;

        .share-me-profile-img-alignment {
          width: 40px;
          height: 40px;
          min-width: 40px;
          max-width: 40px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        .share-me-feed-search-input-alignment {
          width: 100%;
          height: 40px;
          padding: 8px;
          background: #fafafb;
          border: 1px solid #e4e4e8;
          border-radius: 10px;
          display: flex;
          align-items: center;
          gap: 8px;

          input {
            width: 100%;
            height: 100%;
            background: transparent;
            border: 1px solid none;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;

            &::placeholder {
              color: #acb1c0;
            }
          }
        }
      }

      .share-me-feed-all-details-section {
        margin: 10px 0 0 0;

        .share-me-feed-design-section {
          background: #f8f8f8;
          border: 1px solid #f8f8f8;
          border-radius: 10px;
          padding: 8px;
          width: 100%;
          display: block;

          &:hover {
            .slick-arrow {
              display: flex;
            }
          }

          .share-me-feed-img-alignment {
            width: 100%;
            height: 236px;
            padding: 0 4px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 5.34609px;
            }

            video {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 5.34609px;
            }
          }

          .share-me-feed-details-alignment {
            padding: 8px 16px 18px 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .share-me-feed-heading {
              h5 {
                cursor: pointer;
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                color: var(--secondary-900);
              }
            }

            .share-me-feed-price-alignment {
              display: flex;
              align-items: center;
              gap: 4px;
              padding: 8px 0 0 0;

              h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 15px;
                line-height: 22px;
                color: #000000;
              }

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: #808191;
              }
            }

            .share-me-feed-delivery-shipping {
              display: flex;
              align-items: center;
              gap: 6px;
              padding: 6px 0 0 0;

              .best-seller-alignment {
                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  background: linear-gradient(263.94deg, #ffc554 7.44%, #744c00 86.5%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  background-clip: text;
                  text-fill-color: transparent;
                }
              }

              .dot-alignment {
                p {
                  width: 4px;
                  height: 4px;
                  border-radius: 50%;
                  background: #acb1c0;
                }
              }

              .discount-menu-align {
                width: auto;
                height: auto;
                background: #ff7d23;
                border-radius: 11px;
                display: flex;
                justify-content: center;
                align-items: center;

                button {
                  padding: 1px 9px;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 18px;
                  display: flex;
                  align-items: center;
                  color: #ffffff;

                  @media screen and (max-width: 767px) {
                    padding: 1px 4px;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    display: flex;
                    align-items: center;
                    color: #ffffff;
                    white-space: nowrap;
                  }
                }
              }

              .delivery-menu-align {
                width: auto;
                height: 21px;
                // background: #eaeaea;
                border-radius: 13.5px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 2px;

                @media screen and (max-width: 767px) {
                  padding: 4px 6px;
                }

                padding: 4px 8px;

                img {
                  width: 100%;
                  height: 100%;
                }

                span {
                  font-weight: 500;
                  font-style: italic;
                  font-size: 12px;
                  line-height: 18px;
                  display: flex;
                  align-items: center;
                  text-decoration-line: none;
                  color: #5f40af;
                }
              }

              .arrow-menu-align {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 6px;

                img {
                  width: 11px;
                  height: 11px;
                }

                span {
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 21px;
                  display: flex;
                  align-items: center;
                  color: #70c217;
                }
              }
            }

            .share-me-feed-other-option-alignment {
              display: flex;
              align-items: center;
              gap: 8px;

              .share-me-feed-box-alignment {
                width: 44px;
                height: 44px;
                background: #ffffff;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &:hover {
                  background: #eef1fc;
                }

                img {
                  width: 24px;
                  height: 24px;
                  display: block;
                }
              }
            }
          }

          .slick-arrow {
            display: none;
            transition: 0.15s ease-in-out;
          }

          .slick-prev {
            left: 10px !important;
            z-index: 99;

            &:hover {
              background: rgba(255, 255, 255, 0.6) !important;
            }
          }

          .slick-next {
            right: 15px !important;

            &:hover {
              background: rgba(255, 255, 255, 0.6) !important;
            }
          }
        }
      }
    }

    .share-me-feed-footer-alignment {
      border-top: 1px solid #f1f1f5;
      padding: 16px 16px 50px 16px;

      button {
        background: var(--primary-500);
        border-radius: 10px;
        width: 100%;
        height: 44px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #f8f8f8;
        cursor: pointer;
      }
    }
  }

  .share-me-feed-body-alignment {
    background: var(--light-grey);
    padding: 16px 0 16px 16px;
    margin: 0 0 92px 0;
    height: calc(100vh - 392px);
    overflow-y: auto;

    .share-me-feed-all-details-alignment {
      // display: flex;
      // align-items: center;
      // width: 100%;
      // overflow-x: auto;

      .share-me-feed-child-details-alignment {
        width: 164px;
        min-width: 164px;
        max-width: 164px;

        .share-me-feed-img-alignment {
          width: 100%;
          height: 164px;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
          }

          .share-me-more-option-alignment {
            position: absolute;
            top: 8px;
            right: 8px;
            width: 25px;
            height: 15px;
            border-radius: 5px;
            background: rgba(30, 36, 50, 0.4);
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 13px;
              height: 3px;
              display: block;
            }
          }
        }

        .share-child-product-child-details-alignment {
          padding: 5px 0 0 0;

          h6 {
            color: var(--secondary-900);
            font-size: 13px;
            font-weight: 400;
            line-height: 21px;
            max-width: 159px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .product-price-alignment {
            padding: 4px 0 0 0;

            span {
              color: var(--secondary-900);
              font-size: 16px;
              font-weight: 600;
              line-height: 21px;
            }
          }
        }
      }

      .collection-product-grid-view {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        // gap: 4px;
        margin: 0 0 16px 0;
      }
    }

    .shared-profile-name-alignment {
      padding: 16px 24px 16px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .shared-profile-name-details {
        display: flex;
        align-items: center;
        gap: 20px;

        .shared-feed-profile-alignment {
          .bookMarkCollectionCardDetails {
            position: relative;
            width: 100%;
            height: 50px;
            cursor: pointer;

            .bookMarkCollectionCardImg {
              width: 50px;
              height: 50px;
              border-radius: 3px;
              border: 1px solid #fff;
              background: #fff;
              box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
              transform: rotate(-6.983deg);

              img {
                width: 100%;
                height: 100%;
                display: block;
                border-radius: 3px;
                object-fit: cover;
              }

              &:nth-child(2) {
                position: absolute;
                top: 0;
                left: 5px;
                transform: rotate(0);
              }

              &:nth-child(3) {
                position: absolute;
                top: 0;
                left: 10px;
                transform: rotate(5.357deg);
                z-index: 8;
              }
            }

            .bookMarkCollectionCardImg2 {
              width: 100%;
              height: 100%;
              border-radius: 3px;
              border: 1px solid #fff;
              background: #fff;
              box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
              position: absolute;
              top: 0;
              left: 10px;

              img {
                width: 100%;
                height: 100%;
                display: block;
                border-radius: 3px;
                object-fit: cover;
              }
            }

            .bookMarkCollectionCardImg3 {
              width: 100%;
              height: 100%;
              border-radius: 3px;
              border: 1px solid #fff;
              background: #fff;
              box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
              position: absolute;
              top: 0;
              left: 20px;
              transform: rotate(4.357deg);

              img {
                width: 100%;
                height: 100%;
                display: block;
                border-radius: 3px;
                object-fit: cover;
              }
            }
          }
        }

        .shared-all-profile-name {
          h6 {
            color: var(--secondary-900);
            font-size: 16px;
            font-weight: 600;
            line-height: 21px;
            cursor: pointer;
          }

          .name-child-details-alignment {
            padding: 4px 0 0 0;
            display: flex;
            align-items: center;
            gap: 8px;

            span {
              color: var(--secondary-500);
              font-size: 12px;
              font-weight: 400;
              line-height: 18px;
            }

            p {
              width: 2px;
              height: 2px;
              background: var(--secondary-300);
            }
          }
        }
      }

      .shared-like-alignment {
        width: 32px;
        height: 32px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .share-me-bottom-details-alignment {
    position: fixed;
    left: 0;
    width: 100%;
    background: var(--white);
    padding: 16px 16px 32px 16px;
    bottom: 0;

    button {
      width: 100%;
      height: 44px;
      border-radius: 10px;
      background: var(--primary-500);
      color: var(--light-grey);
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 21px;
    }
  }
}
