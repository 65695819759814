.mobileAddColorVariantSection {
  padding: 16px;

  .mobileAddColorVariantHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px 0 0;

    h6 {
      color: var(--secondary-900);
      font-family: 'Poppins', sans-serif;
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
    }

    a {
      color: var(--primary-500);
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .mobileAddColorVariantBodyDetails {
    .mobileAddColorVariantTopDetailsAlignment {
      height: calc(100vh - 400px);
      overflow-y: auto;

      .mobileAddColorVariantNOContentDetails {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          color: #808191;
          font-family: 'Poppins', sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
        }
      }

      .mobileAddColorVariantColorDetails {
        padding: 32px 8px 0 0;

        .mobileAddColorVariantColorFlex {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 0 0 16px 0;

          &:last-child {
            margin: 0;
          }

          .mobileColorNameAndBoxAlignment {
            display: flex;
            align-items: center;
            gap: 16px;

            .colorBoxAlignment {
              width: 31px;
              height: 31px;
              border-radius: 8px;
              // background: #DC0505;
            }

            p {
              color: #808191;
              font-family: 'Poppins', sans-serif;
              font-size: 14px;
              font-weight: 400;
              line-height: 21px;
            }
          }

          a {
            color: var(--primary-500);
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
          }
        }
      }
    }

    .mobileAddColorVariantBottomDetailsAlignment {
      .mobileAddColorVariantDetailsOptionFlex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 24px;
        padding: 0 8px 0 0;

        .mobileAddColorVariantBoxALignment {
          display: flex;
          align-items: center;
          gap: 8px;
          width: 100%;

          .mobileAddPlusBoxAlignment {
            width: 40px;
            min-width: 40px;
            max-width: 40px;
            height: 40px;
            border-radius: 10px;
            border: 1px solid #acb1c0;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .mobileViewColorNameAddInput {
            width: 100%;
            height: 40px;

            input {
              width: 100%;
              height: 100%;
              font-family: 'Poppins', sans-serif;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              border-radius: 10px;
              border: 1px solid #eceef4;
              background: #eceef4;
              padding: 0 16px;

              &::placeholder {
                color: var(--secondary-500);
              }
            }
          }
        }

        a {
          color: var(--primary-500);
          text-align: center;
          font-family: 'Poppins', sans-serif;
          font-size: 15px;
          font-weight: 500;
          line-height: 20px;
        }
      }

      .mobileAddColorVariantSaveButton {
        padding: 32px 0 0 0;

        button {
          width: 100%;
          height: 44px;
          border-radius: 10px;
          background: var(--primary-100);
          color: #fff;
          text-align: center;
          font-family: 'Poppins', sans-serif;
          font-size: 18px;
          font-weight: 500;
          line-height: 28px;

          &.activeButton {
            background: var(--primary-500);
          }
        }
      }
    }
  }
}
