.art-carft-main-section {
    background: #fff;

    .container {
        @media only screen and (max-width:"768px") {
            width: 100%;
            max-width: 100%;
            margin: 0;
            padding: 0;
        }
    }
}

.mobile-view-art-craft-back-alignment {
    display: none;

    @media only screen and (max-width:"768px") {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: rgba(248, 248, 248, 0.92);
        backdrop-filter: blur(13.5914px);
        padding: 8px 16px;
        position: sticky;
        top: 0;
        z-index: 999;

        .mobile-view-art-flex-alignment {

            display: flex;
            align-items: center;
            gap: 44px;


            .art-craft-back_details-alignment {
                background: #ffffff;
                border-radius: 10px;
                padding: 4px 9px 4px 10px;
                display: flex;
                align-items: center;
                gap: 8px;

                p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #808191;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 58px;
                }
            }

            .mobile-heading-alignment {
                h4 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 27px;
                    text-align: center;
                    color: var(--secondary-900);
                }
            }
        }

        .search-alignment {
            img {
                cursor: pointer;
                object-fit: cover;
            }

        }
    }
}

.mobile-view-tab-section {
    display: none;

    @media only screen and (max-width:"768px") {
        padding: 8px 16px;
        display: flex;
        width: 100%;
        overflow-x: auto;
        background: #fff;


        .mobile-view-tab-alignment {
            ul {
                display: flex;
                align-items: center;
                gap: 16px;

                li {
                    padding: 6px 22px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    color: #C85708;
                    background: #FFDEC7;
                    border-radius: 8px;
                    white-space: nowrap;

                    &.stationry-color {
                        background: #E8EA93;
                        color: #5C5E00;
                    }

                    &.paper-color {
                        background: #C6E7FF;
                        color: #1D6599;
                    }
                }
            }
        }
    }

}