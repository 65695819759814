.sellerSocialPastLiveStreamsSection {
    padding: 16px;

    @media only screen and (max-width: '767px') {
        margin: 8px 0 0 0;
    }

    .Nohavedatabox {
        border-radius: 16px;
        background: #eef1fd;
        width: 100%;
        height: 310px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;
        gap: 16px;

        p {
            color: #9c6ac5;
            font-family: "Poppins" sans-serif;
            font-size: 12px;
            font-style: italic;
            font-weight: 400;
            line-height: normal;
        }
    }

    .sellerSocialPastLiveStreamsBox {
        border-radius: 16px;
        border: 2px solid #eef1fd;
        background: #fff;
        padding: 32px;

        @media only screen and (max-width: '767px') {
            padding: 8px;
        }

        .sellerSocialPastLiveStreamsHeadingAlignment {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 4px;

            h4 {
                color: #000;
                font-size: 20px;
                font-family: 'Poppins', sans-serif;
                line-height: 30px;
                font-weight: 600;

                @media only screen and (max-width: '767px') {
                    font-size: 18px;
                    line-height: 24px;
                }
            }

            a {
                padding: 6px 16px;
                cursor: pointer;
                color: var(--primary-500);
                font-family: 'Poppins', sans-serif;
                font-size: 15px;
                font-weight: 500;
                line-height: 20px;
                display: flex;
                align-items: center;
                gap: 4px;

                @media only screen and (max-width:"767px") {
                    font-size: 14px;
                    line-height: 21px;
                }
            }

            .dotAlignment {
                p {
                    width: 3px;
                    height: 3px;
                    background: var(--secondary-500);
                    border-radius: 50%;
                }
            }

            span {
                color: var(--secondary-500);
                font-size: 13px;
                font-weight: 500;
                line-height: 20px;
            }
        }

        .sellerSocialPastLiveStreamsDetailsAlignment {
            padding: 16px 0 0 0;

            @media only screen and (max-width: '767px') {
                padding: 16px 0 0 0;
            }


        }
    }

    :global(.slick-arrow) {
        background: #d5cfcf !important;
        border: 1px solid #d5cfcf !important;
    }

    :global(.slick-arrow:hover) {
        background: #ab67fc !important;
    }
}

.sellerSocialPastLiveStreamsDetailsGrid {
    padding: 0 8px;
    display: grid;
    gap: 16px;

    // @media only screen and (max-width: '1299px') {
    //     grid-template-columns: repeat(4, 1fr);
    //     gap: 8px;
    // }

    // @media only screen and (max-width: '1099px') {
    //     grid-template-columns: repeat(3, 1fr);
    //     gap: 8px;
    // }

    // @media only screen and (max-width: '767px') {
    //     grid-template-columns: repeat(2, 1fr);
    //     gap: 8px;
    // }

    .sellerSocialPastLiveStreamsDetailsGridItem {
        width: 100%;

        .sellerSocialPastLiveStreamsBoxAlignment {
            width: 100%;
            height: 298px;
            position: relative;

            @media only screen and (max-width: '767px') {
                height: 250px;
            }

            img {
                width: 100%;
                height: 100%;
                border-radius: 10px;
                object-fit: cover;
            }

            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                border-radius: 0px 0px 10px 10px;
                background: linear-gradient(0deg, #1e2432 0%, rgba(30, 36, 50, 0) 100%);
                height: 208px;
                width: 100%;
                z-index: 999;

                @media only screen and (max-width: '767px') {
                    height: 180px;
                }
            }

            .sellerSocialPastLiveStreamsTimeAlignment {
                position: absolute;
                top: 16px;
                left: 16px;

                border-radius: 8px;
                background: rgba(30, 36, 50, 0.6);
                padding: 4px 8px;
                display: flex;
                align-items: center;
                gap: 4px;

                p {
                    color: #e4e4e8;
                    font-size: 14px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    line-height: 21px;
                }
            }

            .sellerSocialPastLiveStreamsBottomAlignment {
                position: absolute;
                bottom: 16px;
                right: 16px;
                left: 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                z-index: 999;

                @media only screen and (max-width: '767px') {
                    align-items: flex-end;
                }

                .sellerSocialPastLiveStreamsAllDetailsAlignment {
                    h6 {
                        color: #f8f8f8;
                        font-size: 20px;
                        font-family: 'Poppins', sans-serif;
                        line-height: 30px;
                        font-weight: 600;

                        @media only screen and (max-width: '767px') {
                            font-size: 16px;
                            line-height: 22px;
                        }
                    }

                    .allPastLiveTimeDateAlignment {
                        padding: 8px 0 0 0;

                        @media only screen and (max-width: '767px') {
                            display: none;
                        }

                        p {
                            padding: 0 0 4px 0;
                            color: #e4e4e8;
                            font-size: 13px;
                            font-family: 'Poppins', sans-serif;
                            line-height: 19px;
                            font-weight: 500;

                            :last-child {
                                padding: 0;
                            }
                        }
                    }
                }

                .sellerSocialPastLiveStreamsLiveShareAlignment {
                    .sellerSocialPastLiveStreamsDetailsBoxAlignment {
                        padding: 0 0 16px 0;

                        &:last-child {
                            padding: 0;
                        }

                        .childBoxAlignment {
                            width: 32px;
                            height: 32px;
                            border-radius: 8px;
                            background: rgba(30, 36, 50, 0.4);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 4px;
                        }

                        p {
                            color: #f8f8f8;
                            font-size: 13px;
                            font-family: 'Poppins', sans-serif;
                            line-height: 20px;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}