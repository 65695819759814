.message-modal-personal-account-option-Overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(30, 36, 50, 0.8);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    z-index: 9999 !important;
    display: none;

    @media only screen and (max-width:"1099px") {
        display: block;
    }

}

.message-modal-personal-account-option-Overlay.open {
    opacity: 1;
    visibility: visible;

    @media only screen and (max-width:"1099px") {
        display: block;
    }
}

.message-modal-personal-account-option--modal {
    position: fixed;
    right: 0;
    width: 100%;
    height: auto;
    background: white;
    translate: 0 100%;
    transition: 0.3s;
    border-radius: 16px 16px 0 0;
    overflow-y: auto;
    bottom: 0;

    @media only screen and (max-width:"1099px") {
        display: block;
    }

}
.message-modal-personal-account-body-details-alignment {
    padding: 16px;


    .mobile-view-select-account-modal-alignment {
        .mobile-view-select-account-sticky {
            position: sticky;
            top: 0;
            background: #ffffff;

            .mobile-view-select-account-heading-alignment {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 0 18px 0;

                h4 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 30px;
                    color: var(--secondary-900);
                }

                .mobile-view-header-close-alignment {
                    width: 24px;
                    height: 24px;
                    cursor: pointer;

                    img {
                        width: 100%;
                        height: 100%;
                        display: block;
                    }
                }
            }

            .mobile-view-select-search-alignment {

                .mobile-view-select-account-search-alignment {
                    background: #ffffff;
                    border: 1px solid #f3f3f3;
                    border-radius: 10px;
                    width: 100%;
                    height: 40px;
                    display: flex;
                    align-items: center;

                    .mobile-view-search-icon {
                        width: 40px;
                        height: 40px;
                        min-width: 40px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    input {
                        background: transparent;
                        border: none;
                        width: 100%;
                        height: 100%;
                        padding: 0 12px 0 0;
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;

                        &::placeholder {
                            color: #acb1c0;
                        }
                    }
                }
            }
        }

        .mobile-view-select-acount-modal-body-alignment {
            padding: 24px 0 0 0;
            height: calc(100vh - 221px);
            overflow-y: auto;

            .mobile-view-account-details-alignment {
                padding: 6px 16px;
                display: flex;
                align-items: center;
                gap: 8px;
                cursor: pointer;
                margin: 0 0 4px 0;

                &:last-child {
                    margin: 0;
                }

                &:hover {
                    background: var(--primary-50);
                    border-radius: 10px;
                }



                .mobile-view-account-profile-img-alignment {
                    width: 32px;
                    height: 32px;
                    max-width: 32px;
                    min-width: 32px;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }

                p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                    color: var(--secondary-900);
                }
            }
        }
    }
}

.message-modal-personal-account-option--modal.open {
    translate: 0;
    z-index: 9999999;
    transform: translate(0, 6%);

    @media only screen and (max-width:"1099px") {
        display: block;
    }
}

.message-mobile-view-select-click-drop-alignment {
    border-bottom: 1px solid #eaecef;
    padding: 8px 0 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        width: 33px;
        height: 3px;
        background: #e4e4e8;
        border-radius: 1.5px;
    }
}