@mixin block_btn {
    background: var(--primary-500);
    border-radius: 8px;
    display: block;
    width: 100%;
    border: unset;
    outline: unset;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 10px;
    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

    &:hover {
        transform: translateY(-3px);
        background: #2844ac;
    }
}

.mobile-view-creative-clubs-section {
    display: none;

    @media only screen and (max-width:"768px") {
        display: block;
    }

    .mobile-view-creative-clubs-alignment {
        padding: 32px 0 0 16px;

        .mobile-view-creative-heading-alignment {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 16px 0 0;

            h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                color: var(--secondary-900);
            }

            .see-all-alignment {
                a {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: var(--primary-500);
                }
            }
        }

        .mobile-view-creative-details-alignment {
            padding: 18px 0 0 0;

            .creative-clubs-right-gridItem-alignment {
                padding: 0 4px;

                .creative-clubs-right_slider_cover {
                    .card_creative-clubs {
                        width: 100%;
                        position: relative;
                        border-radius: 10px 10px 0px 0px;
                        overflow: hidden;
                        transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                        height: 251px;
                        max-height: 251px;

                        @media only screen and (max-width: '768px') {
                            height: 170px;
                            max-height: 170px;
                        }

                        &:hover {
                            transform: translateY(-5px);

                            .creative-clubs-img_cover img {
                                transform: scale(1.05);
                                transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                                object-fit: cover;
                            }
                        }

                        .creative-clubs-img_cover {
                            object-fit: cover;
                            object-position: center;
                            height: 251px;
                            max-height: 251px;

                            @media only screen and (max-width: '768px') {
                                height: 170px;
                                max-height: 170px;
                            }

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                            }
                        }

                        .creative-clubs-card_details {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: flex-end;
                            padding: 15px;
                            background: linear-gradient(360deg, #0e1116 0%, rgba(30, 36, 50, 0) 100%);

                            .creative-clubs-card_details_cover {
                                width: 100%;

                                h5 {
                                    font-weight: 500;
                                    font-size: 20px;
                                    line-height: 30px;
                                    color: #ffffff;
                                }

                                p {
                                    font-weight: 400;
                                    font-size: 12px;
                                    line-height: 18px;
                                    color: #ffffff;
                                    margin: 4px 0 17px 0;
                                }

                                .creative-clubs-users {
                                    width: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;

                                    .creative-clubs-left_part {
                                        display: flex;
                                        align-items: center;

                                        .creative-clubs-users_likes {
                                            display: flex;
                                            align-items: center;
                                            margin-left: -10px;

                                            .creative-clubs-user_box {
                                                width: 20px;
                                                display: flex;
                                                align-items: center;
                                                height: 20px;
                                                justify-content: center;
                                                background: #fff;
                                                object-position: center;
                                                border: 1px solid #ffffff;
                                                border-radius: 30px;

                                                img {
                                                    width: 100%;
                                                    object-fit: cover !important;
                                                }
                                            }
                                        }

                                        .creative-clubs-count {
                                            margin-left: -10px;
                                            background: var(--primary-500);
                                            border: 1px solid #ffffff;
                                            border-radius: 12px;
                                            font-weight: 500;
                                            font-size: 12px;
                                            line-height: 18px;
                                            letter-spacing: 0.1px;
                                            color: #ffffff;
                                            padding: 0px 9px;
                                        }
                                    }

                                    .creative-clubs-right_part {
                                        max-width: 120px;

                                        button {
                                            @include block_btn();
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}