.kYCVerifiedStep2Section {
    padding: 16px 0 0 0;

    .kYCVerifiedStep2Box {
        border-radius: 16px;
        background: var(--secondary-50);
        padding: 16px;

        .kYCVerifiedStep2FlexAlignment {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 34px 60px 50px 60px;

            .kYCVerifiedStep2lrftDetails {
                p {
                    color: var(--secondary-900);
                    font-family: 'Poppins', sans-serif;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 19px;
                }

                h6 {
                    color: var(--secondary-900);
                    font-family: 'Poppins', sans-serif;
                    font-size: 48px;
                    font-weight: 600;
                    line-height: 72px;
                }
            }


        }

        .kYCVerifiedStep2AddressBox {
            border-radius: 10px;
            background: #FFF;
            padding: 34px 32px;
            margin: 0 0 16px 0;

            .kYCVerifiedStep2ChildDetails {
                padding: 0 0 26px 0;

                &:last-child {
                    padding: 0;
                }

                label {
                    color: var(--secondary-500);
                    font-family: 'Poppins', sans-serif;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 20px;
                }

                p {
                    color: var(--secondary-900);
                    font-family: 'Poppins', sans-serif;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 24px;
                }
            }
        }

        .kYCVerifiedWriteNoteBox {
            border-radius: 10px;
            background: #FFF;
            padding: 32px;

            label {
                color: var(--secondary-500);
                font-family: 'Poppins', sans-serif;
                font-size: 13px;
                font-weight: 500;
                line-height: 20px;
            }

            .kYcVerifiedNoteArea {
                background: transparent;
                border: none;
                width: 100%;

                textarea {
                    background: transparent;
                    border: none;
                    width: 100%;
                    font-family: 'Poppins', sans-serif;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 24px;
                    appearance: none;
                    resize: none;

                    &::placeholder {

                       color: var(--secondary-300);
                    }
                }
            }
        }

    }
    .kYCVerifiedStep2ButtonAlignment {
        text-align: center;
        margin: 30px 0 0 0;
        .kYCWithdrawButton {
            margin: 0 0 16px 0;
            button {
                height: 44px;
                width: 192px;
                border-radius: 10px;
                background: var(--primary-500);
                color: #FFF;
                text-align: center;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                cursor: pointer;
            }
        }

        .kYCWithBackButton {
            button {
                height: 44px;
                width: 192px;
                border-radius: 10px;
                background: transparent;
                color: #556EE6  ;
                text-align: center;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                cursor: pointer;
            }
        }
    }
}