.jewelryNewArrivalModalSection {
  .jewelryNewArrivalModalAlignment {
    border-radius: 10px;
    background: #fff;
    height: calc(100vh - 96px);
    padding: 0 32px;
    overflow-y: auto;

    @media only screen and (max-width: '1099px') {
      height: calc(100vh - 32px);
    }

    .jewelryNewArrivalHeading {
      padding: 32px 0;
      background: #fff;
      position: sticky;
      top: 0;
      z-index: 999;

      h4 {
        color: var(--bookmark-heading-color);
        font-family: 'Poppins', sans-serif;
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
      }
    }

    .jewelryModalModalBodyAlignment {
      padding: 0 0 32px 0;

      .jewelryModalModalBodyGridAlignment {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 16px;

        @media only screen and (max-width: '1099px') {
          grid-template-columns: repeat(3, 1fr);
        }

        .jewelryModalModalBodyGridItem {
          overflow: hidden;
          border-radius: 8px;
          .jewelryModalNewArrivalDetailsImg {
            width: 100%;
            height: 100%;
            position: relative;
            cursor: pointer;
            border-radius: 8px;

            img {
              width: 305px;
              height: 305px;
              object-fit: cover;
              border-radius: 8px;
              transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

              @media only screen and (max-width: '767px') {
                height: 164px;
              }
            }

            // &:before {
            //     position: absolute;
            //     content: '';
            //     top: 0;
            //     left: 0;
            //     width: 100%;
            //     height: 100%;
            //     background: linear-gradient(180deg, rgba(72, 72, 72, 0.00) 0%, #2E2E2E 100%);
            //     z-index: 9;
            // }

            .jewelryModalNewArrivalAllDetails {
              position: absolute;
              bottom: 16px;
              left: 16px;
              z-index: 99;
              display: none;
              p {
                max-width: 163px;
                color: #f8f8f8;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
              }

              .jewelryModalPrice {
                padding: 4px 0 0 0;

                span {
                  color: #f8f8f8;
                  font-family: 'Poppins', sans-serif;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 24px;
                }
              }
            }

            .jewelryModalMoreOptionAlignment {
              position: absolute;
              bottom: 16px;
              right: 16px;
              display: flex;
              align-items: center;
              gap: 12px;
              display: none;
              z-index: 99;

              .jewelryModalIconAlignment {
                cursor: pointer;
              }
            }

            &:hover {
              transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
              box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
              border-radius: 8px;

              &:before {
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 99;
                border-radius: 8px;
                background: linear-gradient(180deg, rgba(30, 36, 50, 0) 0%, rgba(30, 36, 50, 0.8) 100%);
              }

              img {
                transform: rotate(-7.05deg) scale(1.2);
                transition: 0.4s ease-in-out;
                transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
              }

              .jewelryModalNewArrivalAllDetails {
                display: block;
              }

              .jewelryModalMoreOptionAlignment {
                display: flex;
              }
            }
          }
        }
      }
    }
  }
}
