.actionButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  border-top: 1px solid #e5e7eb;

  .leftButtons {
    display: flex;
    gap: 8px;
  }

  .rightButtons {
    display: flex;
    gap: 8px;
    margin-left: auto;
  }
}

.actionButton {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  border: none;
  background: none;
  border-radius: 8px;
  cursor: pointer;
  color: #666;
  font-size: 14px;
  transition: all 0.2s;

  &:hover {
    background: #f3f4f6;
  }

  img {
    width: 20px;
    height: 20px;
  }

  &.mediaButton {
    color: #fcb424;
    background-color: #fff4e6;
    
    &:hover {
      background-color: #ffdbb3;
    }
  }
}

.submitButton {
  padding: 8px 24px;
  border: none;
  background: #556EE6;
  color: white;
  border-radius: 16px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s;

  &:hover {
    background: #4055d3; 
  }

  &:disabled {
    background: #d9d9d9;
    cursor: not-allowed;
  }
}

.photoButton {
  background-color: #fff4e6;
  color: #fcb424;
  box-shadow: 0 2px 4px rgba(252, 180, 36, 0.1);
}

.photoButton:hover {
  background-color: #ffdbb3;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(252, 180, 36, 0.15);
}

.emojiButton {
  background-color: #f3e6f9;
  color: #b414e4;
  box-shadow: 0 2px 4px rgba(180, 20, 228, 0.1);
}

.emojiButton:hover {
  background-color: #e6d3f2;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(180, 20, 228, 0.15);
}

.gifButton {
  background-color: #dbeafe;
  color: #2563eb;
  box-shadow: 0 2px 4px rgba(37, 99, 235, 0.1);
}

.gifButton:hover {
  background-color: #bfdbfe;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(37, 99, 235, 0.15);
}

.gifButton.active {
  background-color: #f3f4f6;
  color: #3b82f6;
}

.aiButton {
  background-color: #f0f9ff;
  color: #0ea5e9;
  box-shadow: 0 2px 4px rgba(14, 165, 233, 0.1);
}

.aiButton:hover {
  background-color: #e0f2fe;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(14, 165, 233, 0.15);
}

.textArea {
  min-height: 120px;
  margin: 12px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  width: 100%;
  font-size: 14px;
  line-height: 1.5;
}

.textArea:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
}

.textArea::placeholder {
  font-size: 14px;
  color: #94a3b8;
  opacity: 0.7;
}

.closeIcon {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 32px;
  height: 32px;
  background-color: #f0f0f0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.closeIcon svg {
  color: #333;
  font-size: 16px;
  font-weight: bold;
}

.closeIcon:hover {
  background-color: #e0e0e0;
}

.aiOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.aiHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #e5e7eb;
  background: white;

  span {
    font-size: 16px;
    font-weight: 500;
  }

  .backButton {
    display: flex;
    align-items: center;
    gap: 4px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
    color: #666;
    font-size: 14px;
    transition: color 0.2s;

    &:hover {
      color: #000;
    }
  }

  .closeButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
    color: #666;
    transition: color 0.2s;

    &:hover {
      color: #000;
    }
  }
}

.aiContent {
  flex: 1;
  overflow-y: auto;
  padding: 8px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.aiInputSection {
  h3 {
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
  }

  .aiPromptInput {
    width: 100%;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    padding: 12px;
    font-size: 14px;
    resize: none;

    &:focus {
      border-color: #556EE6;
      box-shadow: 0 0 0 2px rgba(85, 110, 230, 0.1);
    }
  }
}

.aiStyleSection {
  h3 {
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 500;
  }
}

.styleOptions {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.styleOption {
  padding: 8px 16px;
  border: 1px solid #e5e7eb;
  border-radius: 20px;
  background: none;
  cursor: pointer;
  font-size: 14px;
  color: #374151;
  transition: all 0.2s;

  &:hover {
    border-color: #556EE6;
    color: #556EE6;
    background: rgba(85, 110, 230, 0.02);
  }

  &.selected {
    border-color: #556EE6;
    background: #556EE6;
    color: white;
  }
}

.styleHeader {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 8px;
}

.radioButton {
  width: 18px;
  height: 18px;
  border: 2px solid #d1d5db;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;

  .radioInner {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transform: scale(0);
    transition: transform 0.2s;
  }
}

.styleLabel {
  font-weight: 500;
  color: #1f2937;
}

.styleDescription {
  margin: 0;
  font-size: 13px;
  color: #6b7280;
}

.aiGenerateSection {
  display: flex;
  flex-direction: column;
  gap: 16px;

  :global {
    .ant-btn {
      border-radius: 16px;
      height: 40px;
      font-weight: 500;
      
      &.ant-btn-primary {
        background: #556EE6;
        border-color: #556EE6;
        
        &:hover {
          background: #4055d3;
          border-color: #4055d3;
        }
      }
    }
  }
}

.generateButton {
  align-self: flex-end;
  min-width: 120px;
  background: #556EE6;
  border: none;
  border-radius: 16px;
  
  &:hover {
    background: #4055d3;
  }
}

.actionButtons {
  display: flex;
  gap: 12px;
  justify-content: flex-end;

  button {
    min-width: 120px;
  }

  :global {
    .ant-btn {
      border-radius: 16px;
      height: 40px;
      font-weight: 500;

      &.ant-btn-primary {
        background: #556EE6;
        border-color: #556EE6;
        
        &:hover {
          background: #4055d3;
          border-color: #4055d3;
        }
      }
    }
  }
}

.gifOverlay {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 600px;
  background-color: white;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0 0 12px 12px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  margin-top: -16px;
}

.gifHeader {
  display: flex;
  align-items: center;
  margin: 16px;
  border-bottom: 1px solid #e5e7eb;
  background-color: white;
}

.gifSearchInput {
  flex-grow: 1;
}

.gifContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin: 16px;
  background-color: white;
}

.gifGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-bottom: 16px;
}

.gifItem {
  position: relative;
  padding-top: 75%; /* 4:3 aspect ratio */
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.2s;
  background-color: #f3f4f6;
}

.gifItem img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gifItem:hover {
  transform: scale(1.05);
}

.gifAttribution {
  text-align: center;
  margin: 12px 0;
  color: #6b7280;
  font-size: 12px;
  border-top: 1px solid #e5e7eb;
  background-color: white;
  margin-top: auto;
}

.post-modal {
  position: relative;
}

.post-modal :global(.ant-modal-content) {
  padding: 0;
  border-radius: 12px;
  overflow: hidden;
}

.post-modal :global(.ant-modal-body) {
  padding: 0;
}

/* Mobile Bottom Sheet Styles */
@media (max-width: 768px) {
  .mobileModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
  }

  .bottomSheet {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    z-index: 99999;
    max-height: 75vh;
    transform: translateY(0);
    transition: transform 0.3s ease-in-out;
    box-shadow: 0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0 -2px 4px -1px rgba(0, 0, 0, 0.06);
    margin-top: -16px;
  }

  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99998;
  }

  .bottomSheetContent {
    position: relative;
    overflow: auto;
    height: 100%;
    max-height: 75vh;
    background: white;
  }

  .bottomSheetHandle {
    width: 40px;
    height: 4px;
    background-color: #d1d5db;
    border-radius: 2px;
    margin: 8px auto;
  }

  .modalHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px 16px;
    border-bottom: 1px solid #e5e7eb;
    background: white;
  }

  .modalTitle {
    font-size: 18px;
    font-weight: 600;
    color: #1f2937;
  }

  .closeButton {
    padding: 8px 12px;
    color: #6b7280;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    background: none;
    border: none;
  }

  .post-modal-content {
    max-height: calc(70vh);  /* Slightly less than bottom sheet height to account for header */
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .gifContent,
  .aiContent {
    max-height: 65vh;  /* Adjusted for 75vh bottom sheet */
    overflow-y: auto;
  }
  
  .gifOverlay,
  .aiOverlay {
    position: relative;
    height: auto;
    width: 100%;
    background: white;
    border-radius: 0;
    box-shadow: none;
  }

  .textArea {
    min-height: 40px; /* Even smaller on mobile */
    font-size: 14px;
  }
}

.modalContainer {
  position: relative;
  z-index: 1000;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.postModal {
  :global {
    .ant-modal-content {
      border-radius: 12px;
      overflow: hidden;
      padding: 0;
    }

    .ant-modal-header {
      border-bottom: 1px solid #f0f0f0;
      padding: 16px 24px;
      margin: 0;
    }

    .ant-modal-body {
      padding: 0;
    }
  }
}

.mobilePostModal {
  :global {
    .ant-modal-content {
      border-radius: 24px 24px 0 0;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      max-height: 90vh;
      overflow-y: auto;
      background: white;
      padding-bottom: env(safe-area-inset-bottom);
    }
  }
}

.modalContent,
.mobileModalContent {
  display: flex;
  flex-direction: column;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 24px;
  border-bottom: 1px solid #f0f0f0;

  h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalBody {
  margin: 16px 24px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.userInfo {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 16px;

  .avatar {
    border-radius: 50%;
    flex-shrink: 0;
  }
}

.userDetails {
  flex: 1;
}

.nameAndVisibility {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;

  h3 {
    margin: 0;
    font-size: 15px;
    font-weight: 600;
    color: var(--secondary-900);
  }
}

.visibilitySelector {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 4px 12px;
  border: 1px solid #e5e7eb;
  border-radius: 20px;
  background: white;
  cursor: pointer;
  font-size: 13px;
  color: #4b5563;
  transition: all 0.2s ease;

  &:hover {
    background: #f3f4f6;
    border-color: #d1d5db;
  }
}

.visibilityOption {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 8px;
  min-width: 200px;

  .visibilityTitle {
    font-size: 14px;
    font-weight: 500;
    color: #1f2937;
    margin-bottom: 2px;
  }

  .visibilityDescription {
    font-size: 12px;
    color: #6b7280;
  }
}

.avatar {
  width: 40px;
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  border-radius: 50%;
  object-fit: cover !important;
}

.postInput {
  display: flex;
    gap: 8px;
    align-items: flex-start;
    padding: 12px;
    margin: 8px 16px 8px 16px;
    background: #f8f9fa;
    border: 1px solid #e5e7eb;
    border-radius: 8px;

.featherIcon {
    width: 18px;
    height: 18px;
    margin-top: 2px;
  }

  .input {
    border: none;
    resize: none;
    box-shadow: none;
    padding: 0;
    font-size: 14px;
    line-height: 1.5;
    width: 100%;
    background: transparent;

    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      color: #9ca3af;
    }
  }
}

.visibilitySelector {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border: 1px solid #e5e7eb;
  border-radius: 16px;
  background: white;
  cursor: pointer;
  font-size: 14px;
  color: #4b5563;
}

.visibilityOption {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
}

.visibilityTitle {
  font-weight: 500;
  margin-bottom: 2px;
}

.visibilityDescription {
  font-size: 12px;
  color: #6b7280;
}

.postContent {
  flex-grow: 1;
  margin: 16px 0;
}

.postInput {
  border: none;
  resize: none;
  font-size: 16px;
  width: 100%;
  padding: 0;
  margin-bottom: 16px;
  min-height: 100px;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.imagePreview {
  margin-top: 16px;
  border-radius: 8px;
  overflow: hidden;
}

.imagePreviewGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 8px;
  margin: 16px;
  padding: 16px;
  position: relative;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  background: #f8f9fa;
}

.imagePreviewItem {
  position: relative;
  aspect-ratio: 1;
  border-radius: 8px;
  overflow: hidden;
  background: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  &:first-child {
    grid-column: span 2;
    grid-row: span 2;
  }
}

.previewImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.removeImageButton {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }
}

.imageCountOverlay {
  position: absolute;
  bottom: 8px;
  right: 8px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
}

.emojiPicker {
  position: absolute;
  bottom: 100%;
  right: 16px;
  z-index: 1000;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  overflow: hidden;
}

.gifGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
  padding: 16px;
  max-height: 400px;
  overflow-y: auto;

  img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.05);
    }
  }
}

.gifPicker {
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  margin: 16px;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.gifSearch {
  margin-bottom: 16px;
}

.gifGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 8px;
  max-height: 300px;
  overflow-y: auto;
}

.gifItem {
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.modalFooter {
  margin: 16px 24px;
  border-top: 1px solid #f0f0f0;
}

.modalContent,
.mobileModalContent {
  display: flex;
  flex-direction: column;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 24px;
  border-bottom: 1px solid #f0f0f0;

  h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalBody {
  margin: 16px 24px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.userInfo {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 16px;

  .avatar {
    border-radius: 50%;
    flex-shrink: 0;
  }
}

.userDetails {
  flex: 1;
}

.nameAndVisibility {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;

  h3 {
    margin: 0;
    font-size: 15px;
    font-weight: 600;
    color: var(--secondary-900);
  }
}

.visibilitySelector {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 4px 12px;
  border: 1px solid #e5e7eb;
  border-radius: 20px;
  background: white;
  cursor: pointer;
  font-size: 13px;
  color: #4b5563;
  transition: all 0.2s ease;

  &:hover {
    background: #f3f4f6;
    border-color: #d1d5db;
  }
}

.visibilityOption {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 8px;
  min-width: 200px;

  .visibilityTitle {
    font-size: 14px;
    font-weight: 500;
    color: #1f2937;
    margin-bottom: 2px;
  }

  .visibilityDescription {
    font-size: 12px;
    color: #6b7280;
  }
}

.avatar {
  width: 40px;
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  border-radius: 50%;
  object-fit: cover !important;
}

.postInput {
  display: flex;
  gap: 8px;
  align-items: flex-start;
  padding: 12px;
  margin: 8px 16px 8px 16px;
  background: #f8f9fa;
  border: 1px solid #e5e7eb;
  border-radius: 8px;

  .featherIcon {
    width: 18px;
    height: 18px;
    margin-top: 2px;
  }

  .input {
    border: none;
    resize: none;
    box-shadow: none;
    padding: 0;
    font-size: 14px;
    line-height: 1.5;
    width: 100%;
    background: transparent;
    
    &:focus {
      box-shadow: none;
    }

    &::placeholder {
      color: #9ca3af;
    }
  }
}

.visibilitySelector {
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border: 1px solid #e5e7eb;
  border-radius: 16px;
  background: white;
  cursor: pointer;
  font-size: 14px;
  color: #4b5563;
}

.visibilityOption {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
}

.visibilityTitle {
  font-weight: 500;
  margin-bottom: 2px;
}

.visibilityDescription {
  font-size: 12px;
  color: #6b7280;
}

.postContent {
  flex-grow: 1;
  margin: 16px 0;
}

.postInput {
  border: none;
  resize: none;
  font-size: 16px;
  width: 95%;
  padding: 0;
  margin-bottom: 16px;
  min-height: 100px;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.imagePreview {
  margin-top: 16px;
  border-radius: 8px;
  overflow: hidden;
}

.imagePreviewGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 8px;
  margin: 16px;
  padding: 16px;
  position: relative;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  background: #f8f9fa;
}

.imagePreviewItem {
  position: relative;
  aspect-ratio: 1;
  border-radius: 8px;
  overflow: hidden;
  background: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  &:first-child {
    grid-column: span 2;
    grid-row: span 2;
  }
}

.previewImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.removeImageButton {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }
}

.imageCountOverlay {
  position: absolute;
  bottom: 8px;
  right: 8px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
}

.aiModal {
  :global {
    .ant-modal-content {
      border-radius: 12px;
      overflow: hidden;
    }

    .ant-modal-header {
      border-bottom: 1px solid #f0f0f0;
      padding: 16px 24px;
      margin: 0;
    }

    .ant-modal-body {
      padding: 24px;
    }
  }
}

.aiModalContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.aiPromptInput {
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  line-height: 21px;
  border-radius: 8px;
  border-color: #e4e4e8;
  resize: none;

  &:focus {
    box-shadow: none;
    border-color: #3b82f6;
  }

  &::placeholder {
    color: #acb1c0;
  }
}

.aiModalButtons {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.generateButton {
  background-color: #3b82f6;
  border: none;
  border-radius: 16px;
  height: 40px;
  margin: 0 24px;
  font-weight: 500;

  &:hover {
    background-color: #2563eb;
  }
}

.useButton {
  border-radius: 16px;
  height: 40px;
  margin: 0 24px;
  font-weight: 500;
}

.generatedContent {
  background: #f8f8f8;
  border-radius: 8px;
  margin: 16px;

  h4 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
    color: #1e2432;
  }

  p {
    font-size: 14px;
    line-height: 21px;
    color: #4b5563;
    margin: 0;
    white-space: pre-wrap;
  }
}

.emojiPickerWrapper {
  position: absolute;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  overflow: hidden;
}

.gifGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
  padding: 16px;
  max-height: 400px;
  overflow-y: auto;

  img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.05);
    }
  }
}

.emojiPickerModal {
  .emoji-mart {
    width: 100% !important;
    border: none;
  }
}

.gifGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
  padding: 16px;
  max-height: 400px;
  overflow-y: auto;

  img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.2s;

    &:hover {
      transform: scale(1.05);
    }
  }
}

.pickerOverlay {
  @apply absolute inset-0 bg-white flex flex-col;
  z-index: 30;
  
  .pickerHeader {
    @apply flex items-center justify-between px-4 py-3 border-b border-gray-200;
    
    .backButton {
      @apply flex items-center gap-2 text-gray-600;
      font-size: 14px;
    }
    
    span {
      @apply text-lg font-semibold;
    }
    
    .closeButton {
      @apply text-gray-600;
    }
  }
  
  .pickerContent {
    @apply flex-1 flex flex-col overflow-hidden;
    
    .gifSearch {
      @apply p-4 bg-white border-b border-gray-200;
      
      .searchInput {
        @apply w-full;
        :global(.ant-input-search-input) {
          @apply rounded-lg border-gray-300;
          height: 40px;
          &::placeholder {
            @apply text-gray-400;
          }
        }
      }
    }
    
    .gifGrid {
      @apply flex-1 overflow-y-auto;
      
      .loading {
        @apply flex justify-center items-center py-8;
      }
      
      .gifResults {
        @apply grid grid-cols-3 bg-gray-100;
        
        .gifItem {
          @apply cursor-pointer bg-white border-r border-b border-gray-100;
          aspect-ratio: 1;
          
          &:hover {
            @apply opacity-90;
          }
          
          img {
            @apply w-full h-full object-cover;
          }
        }
      }
    }
  }
}

.emojiPickerOverlay {
  @apply absolute inset-0 bg-white flex flex-col;
  z-index: 30;

  .pickerHeader {
    @apply flex items-center justify-between px-4 py-3 border-b border-gray-200;
    
    .backButton {
      @apply flex items-center gap-2 text-gray-600;
      font-size: 14px;
    }
    
    span {
      @apply text-lg font-semibold;
    }
  }

  .pickerContent {
    @apply flex-1 overflow-y-auto p-4;
    
    :global(.emoji-mart) {
      width: 100% !important;
      border: none;
    }
  }
}

.backdropOverlay {
  @apply fixed inset-0 bg-black bg-opacity-50;
  z-index: 998;
}
