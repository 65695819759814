.mobileViewFilterAlignment {
    padding: 0 16px;
    height: calc(100vh - 188px);
    overflow-y: auto;

    .mobileViewFilterDetailsAlignent {
        padding: 0 16px;
    }

    .mobileViewFilterButton {
        padding: 48px 0 16px 0;
        background: #fff;
        position: sticky;
        bottom: 0;
        left: 0;

        button {
            width: 100%;
            height: 44px;
            border-radius: 10px;
            background: var(--primary-500);
            color: #FFF;
            text-align: center;
            font-family: 'Poppins', sans-serif;
            font-size: 18px;
            font-weight: 500;
            line-height: 28px;
        }
    }
}