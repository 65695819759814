.mobileViewSellerSidebarSection {
    padding: 36px 0;

    .mobileViewSellerOptionAlignment {
        ul {
            li {
                padding: 12px 32px;
                display: flex;
                align-items: center;
                gap: 8px;
                color: #808191;
                font-size: 16px;
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                line-height: 24px;
                margin: 0 0 20px 0;

                &:last-child {
                    margin: 0;
                }

                .sellerHoverImg {
                    display: none;
                }

                &:hover {
                    background: var(--white);
                    border: 1px solid #E7E9EE;
                    border-right: none;
                    border-left: none;

                    .sellerHoverImg {
                        display: block;
                    }

                    .sellerDefaltImg {
                        display: none;
                    }

                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    color: var(--primary-500);
                }

                &.mobileVIewActiveeOption {
                    background: var(--white);
                    border: 1px solid #E7E9EE;
                    border-right: none;
                    border-left: none;

                    .sellerHoverImg {
                        display: block;
                    }

                    .sellerDefaltImg {
                        display: none;
                    }

                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    color: var(--primary-500);
                }
            }
        }
    }
}