.mobile-view-all-book-appliance-shop-by-category-alignment {
  display: none;

  @media only screen and (max-width: '767px') {
    display: block;
  }

  .mobile-view-all-book-appliance-modal-alignment {
    width: 100%;
    height: 100vh;
    background: #eceef4;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999;

    .mobile-view-all-book-appliance-heading-alignment {
      .book-appliance-all-shop-by-category-back-alignment {
        display: flex;
        align-items: center;
        gap: 46px;
        padding: 8px 10px;
        background: rgba(248, 248, 248, 0.92);
        backdrop-filter: blur(13.5914px);
        display: none;

        @media only screen and (max-width: '767px') {
          display: flex;
          position: sticky;
          top: 0;
          z-index: 99;
        }

        .book-appliance-back_details-alignment {
          background: #ffffff;
          border-radius: 10px;
          padding: 4px 9px 4px 10px;
          display: flex;
          align-items: center;
          gap: 8px;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #808191;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 58px;
          }
        }

        .book-appliance-mobile-heading-alignment {
          h4 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            text-align: center;
            color: var(--secondary-900);
          }
        }

        .mobile-heading-alignment-none {
          display: none;
        }
      }
    }

    .book-appliance-mobile-view-see-all-book-appliance-details-alignment {
      padding: 16px 0 0 0;
      background: #ffffff;

      .mobile-view-see-all-book-appliance-search-alignment {
        display: none;

        @media only screen and (max-width: 767px) {
          display: flex;
        }

        gap: 8px;
        overflow-x: hidden;

        .book-appliance-mobile-view-child-shop-by-category-heading-alignment {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 0 16px 0 0;
          position: sticky;
          top: 0;
          background: #ffffff;
          z-index: 99;

          .book-appliance-mobile-view-child-shop-by-category-name-alignment {
            display: flex;
            align-items: center;
            gap: 4px;

            h5 {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 20px;
              line-height: 30px;
              color: var(--secondary-900);
            }

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #808191;
            }
          }

          .book-appliance-mobile-shop-by-category-search-icon-alignment {
            display: flex;
            align-items: center;
            gap: 18px;

            img {
              width: 24px;
              height: 24px;
              display: block;
              cursor: pointer;
            }
          }
        }

        .book-appliance-mobile-shop-by-category-main-search-section {
          position: relative;
          left: 0;
          margin: 14px 16px;
          background: #ffffff;
          border: 1px solid #e4e4e8;
          border-radius: 10px;
          width: calc(100% - 32px);

          input {
            background: transparent;
            border-radius: 10px;
            width: 100%;
            height: 40px;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--secondary-900);
            padding: 10px 40px 10px 14px;
          }

          .book-appliance-mobile-shop-by-category-close-alignment {
            position: absolute;
            top: 10px;
            right: 14px;
            cursor: pointer;
          }

          .book-appliance-mobile-drop-down-alignment {
            position: absolute;
            top: 48px;
            right: 0;
            padding: 12px 16px;
            width: 100%;
            height: auto;
            max-height: 500px;
            overflow-y: auto;
            border: 1px solid #e4e4e8;
            border-radius: 10px;
            z-index: 9;
            background: #ffffff;

            ul {
              li {
                .first-data-alignment {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin: 0 0 24px 0;
                  cursor: pointer;

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--secondary-900);
                  }
                }

                .child-details-alignment {
                  ul {
                    li {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 21px;
                      color: var(--secondary-900);
                      margin: 0 0 21px 0;
                      padding: 3px 10px;
                      cursor: pointer;

                      &:hover {
                        background: #eef1fc;
                        border-radius: 4px;
                        font-weight: 600;
                        color: var(--primary-500);
                        width: fit-content;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .mobile-view-book-appliance-shop-by-category-sidebar-alignment {
          display: flex;
          align-items: flex-start;

          .mobile-book-appliance-search-shop-by-category-sidebar-details-box-alignment {
            display: flex;
            align-items: flex-start;

            .book-appliance-mobile-view-shop-by-category-sidebar-box-alignment {
              background: #ffffff;
              // border-radius: 8px;
              height: calc(100vh - 130px);
              overflow-y: auto;

              .book-appliance-mobile-silder-shop-by-category-side-main-details {
                padding: 16px 12px 16px 18px;
                position: relative;
                background: #f8f8f8;

                .book-appliance-mobile-shop-by-category-sidebar-details-alignment {
                  .book-appliance-mobile-shop-by-category-box-all-details-alignment {
                    margin: 0 0 12px 0;
                    padding: 4px;
                    background: #f8f8f8;

                    &.active-class {
                      .book-appliance-details-box-alignment {
                        border: 2px solid #a855f7;
                      }
                    }
                    .book-appliance-details-box-alignment {
                      width: 68px;
                      min-width: 68px;
                      max-width: 68px;
                      height: 68px;
                      border: 1px solid transparent;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border-radius: 10px;

                      // &:hover {
                      //   border: 1px solid #5f40af;
                      //   transition: 0.3s ease-in-out;
                      // }

                      img {
                        width: 60px;
                        min-width: 60px;
                        max-width: 60px;
                        height: 60px;
                        border-radius: 8px;
                        object-fit: cover;
                        display: block;
                      }
                    }

                    .book-appliance-category-name-alignment {
                      p {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18px;
                        text-align: center;
                        color: var(--secondary-900);
                        max-width: 50px;
                        margin: auto;
                        word-break: break-word;
                      }
                    }

                    &:last-child {
                      margin: 0;
                    }
                  }
                }
              }
            }

            .book-appliance-mobile-view-shop-by-category-left-arrow-alignment {
              background: #f8f8f8;
              border-radius: 0px 8px 8px 0px;
              padding: 10px 14px;
              margin: 0 8px 0 0;
              width: 32px;
              min-width: 32px;
              max-width: 32px;
              height: 32px;
            }
          }

          .book-appliance-mobile-view-shop-by-category-details-alignment {
            position: relative;
            padding: 0;
            width: 100%;
            .book-appliance-mobile-shop-by-category-book-details-alignment {
              height: calc(100vh - 230px);
              overflow-y: auto;

              .book-appliance-mobile-shop-by-category-book-grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 32px 12px;

                .book-appliance-mobile-shop-by-category-book-gridItem {
                  .book-appliance-mobile-shop-by-category-book-alignment {
                    text-align: center;
                    margin: 0 0 24px 0;

                    &:last-child {
                      margin: 0;
                    }

                    .book-appliance-mobile-shop-by-category-book-img-alignment {
                      max-width: 167px;
                      width: 167px;
                      min-width: 167px;
                      height: 252px;

                      img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                      }
                    }

                    .book-appliance-mobile-shop-by-category-book-name-alignment {
                      h6 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--secondary-900);
                      }

                      p {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 20px;
                        color: #808191;
                        text-align: center;
                      }
                    }

                    .book-appliance-mobile-shop-by-category-book-rate-alignment {
                      display: flex;
                      align-items: center;
                      padding: 8px 0 0 0;
                      justify-content: center;
                      gap: 4px;

                      .hike {
                        display: flex;
                        align-items: center;

                        .inter {
                          font-family: 'Poppins', sans-serif;
                          font-weight: 600;
                          font-size: 13px;
                          line-height: 20px;
                          color: #70c217;

                          img {
                            margin-right: 3px;
                          }
                        }

                        .incris {
                          font-family: 'Poppins', sans-serif;
                          font-weight: 600;
                          font-size: 13px;
                          line-height: 20px;
                          color: #70c217;
                          display: flex;
                          align-items: center;

                          img {
                            margin-right: 3px;
                          }
                        }
                      }

                      .rating {
                        display: flex;
                        align-items: center;

                        .rating_point {
                          font-family: 'Poppins', sans-serif;
                          font-weight: 600;
                          font-size: 13px;
                          line-height: 20px;
                          color: #808191;
                        }

                        .stars {
                          display: flex;
                          align-items: center;
                          margin: 0 2px;
                        }

                        .num {
                          font-family: 'Poppins', sans-serif;
                          font-weight: 600;
                          font-size: 13px;
                          line-height: 20px;
                          color: #808191;
                        }
                      }
                    }

                    .book-appliance-mobile-shop-by-category-book-price-alignment {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      gap: 5px;
                      padding: 11px 0 0 0;

                      h5 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: #000000;
                      }

                      span {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 20px;
                        color: #495057;
                      }
                    }

                    .book-appliance-mobile-shop-by-category-like-share-alignment {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      gap: 16px;

                      .like-alignment {
                        display: flex;
                        align-items: center;
                        gap: 2px;

                        span {
                          font-family: 'Poppins', sans-serif;
                          font-weight: 500;
                          font-size: 13px;
                          line-height: 20px;
                          color: #808191;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// .right-side-alignment {
//   height: calc(100vh - 130px);
//   overflow-y: auto;
// }

.book-appliance-mobile-view-left-side-alignment {
  display: flex;
  align-items: center;
  gap: 8px;
  .book-appliance-mobile-view-shop-by-category-close-arrow-alignment {
    background: #f8f8f8;
    border-radius: 0px 8px 8px 0px;
    padding: 10px 14px;
    margin: 0 8px 0 0;
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    height: 32px;

    .rotate {
      transform: rotate(180deg);
    }
  }
}
