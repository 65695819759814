.product-container-main {
  background: #ffffff;
  border-radius: 10px;
  // border: 1px solid #f3f3f3;
  transition: 0.3s ease-in-out;
  padding: 3px;
  margin-bottom: 14px;

  // &.margin-view-1 {
  // }

  &.light-shadow {
    &:hover {
      box-shadow: 0px 3.5px 17px rgba(95, 64, 178, 0.1);
    }
  }

  &.margin-view-1 {
    margin: 0 4px;
  }

  opacity: 1;
  position: relative;

  &.hide-product-details {
    opacity: 0;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    margin-bottom: 10px;
  }

  &.list-view-box-shawdow {
    &:hover {
      box-shadow: none;
    }
  }

  .onhover-align {
    opacity: 0;
    transition: 0.3s ease-in-out;
  }

  .image-contain {
    position: relative;
    // height: auto;
    height: 294px;

    @media only screen {
    }

    .image-contain-data {
      img {
        width: 100%;
        // height: 100%;
        border-radius: 8px 8px 0px 0px;
      }

      video {
        width: 100%;
        height: 100%;
        border-radius: 8px 8px 0px 0px;
      }
    }

    .image-contain-dataimage-contain-data {
      position: relative;
      height: 100%;
      width: 100%;
      background-color: rgb(237 237 237);
      position: relative;
      border-radius: 8px 8px 0px 0px;
      overflow: hidden;
      .product-main-image-section {
        position: absolute;
      }

      .poroduct-image-background {
        border-radius: 8px 8px 0px 0px;
        position: absolute;
        width: 100%;
        height: 100%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        mix-blend-mode: multiply;
      }

      img {
        width: 100%;
        // height: 100%;
        border-radius: 8px 8px 0px 0px;
      }

      video {
        width: 100%;
        height: 100%;
        border-radius: 8px 8px 0px 0px;
      }

      .mobile-view-more-option-alignment {
        position: absolute;
        top: 8px;
        right: 8px;
        background: rgba(30, 36, 50, 0.4);
        padding: 6px;
        border-radius: 5px;
        cursor: pointer;
        display: none;

        @media only screen and (max-width: '767px') {
          display: flex;
        }
      }

      .mobile-view-bookmark-icon-box-alignment {
        position: absolute;
        top: 4px;
        right: 4px;
        border-radius: 8px;
        background: rgba(30, 36, 50, 0.6);
        cursor: pointer;
        display: none;
        width: 32px;
        height: 32px;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width: '767px') {
          display: flex;
        }
      }

      .mobile-view-off-label-alignment {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 1px 6px;
        background: #f1efb0;
        border-radius: 0px 4px 0px 0px;
        display: none;

        @media only screen and (max-width: '767px') {
          display: flex;
        }

        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          text-align: center;
          color: #474400;
        }
      }
    }

    .onhover-align {
      .image-index-align {
        top: 0;
        display: flex;
        justify-content: space-between;
        position: absolute;
        width: 100%;
        padding: 12px;

        @media only screen and (max-width: '767px') {
          display: none;
        }

        .number-of-index {
          height: 30px;
          width: 30px;
          background: #1e2432;
          opacity: 0.6;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            color: #ffffff;
          }
        }

        .like-more-alignment {
          display: flex;
          justify-content: flex-end;
          gap: 12px;
          align-items: center;

          .like-button-alignment {
            width: 32px;
            height: 32px;
            background: rgba(30, 36, 50, 0.6);
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 24px;
              height: 24px;
              opacity: 1;

              path {
                fill-opacity: 100%;
              }
            }
          }

          .more-button-alignment {
            background: rgba(30, 36, 50, 0.6);
            border-radius: 8px;
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            cursor: pointer;

            .option-icon-alignment {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                width: 14px;
                height: 14px;
              }

              svg {
                width: 24px;
                height: 24px;
              }
            }

            .more-option-dropdown-alignment {
              position: absolute;
              margin: 0;
              padding: 4px 0;
              text-align: left;
              list-style-type: none;
              background-color: #fff;
              border-radius: 2px;
              outline: none;
              box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
              width: 196px !important;
              border-radius: 10px;
              padding: 0;
              top: -5px;
              right: 0;
              z-index: 99;

              ul {
                li {
                  cursor: pointer;

                  &:hover {
                    &:first-child {
                      .dropdown-option {
                        background-color: #e4e4e8;
                        border-radius: 10px 10px 0px 0px;
                      }
                    }

                    &:last-child {
                      .dropdown-option {
                        background-color: #e4e4e8;
                        border-radius: 0px 0px 10px 10px;
                      }
                    }
                  }

                  .dropdown-option {
                    align-items: center;

                    &:hover {
                      background-color: #e4e4e8;
                      border-radius: none !important;

                      // &:last-child {
                      //   background-color: #e4e4e8;
                      //   border-radius: 0px 0px 10px 10px;
                      // }

                      // &:first-child {
                      //   background-color: #e4e4e8;
                      //   border-radius: 10px 10px 0px 0px;
                      // }
                    }

                    // background-color: plum;
                    .dropdown-main {
                      display: flex;
                      align-items: center;
                      justify-content: space-between !important;
                      padding: 0px 17px;

                      .dropdown-icon {
                        flex: 20%;
                        text-align: left;
                      }

                      .dropdown-content {
                        flex: 80%;

                        h5 {
                          font-weight: 500;
                          font-size: 14px;
                          line-height: 21px;
                          color: #495057;
                          margin: 0;
                        }

                        p {
                          &:hover {
                            font-size: 8px;
                            line-height: 12px;
                            color: #808191;
                            // font-weight: 400;
                            color: #495057;
                          }

                          font-size: 8px;
                          line-height: 12px;
                          color: #808191;
                          margin: 0;
                        }
                      }
                    }
                  }

                  .dropdown-option {
                    &:hover {
                      h5 {
                        // font-weight: 500;
                        font-size: 14px;
                        line-height: 21px;
                        display: flex;
                        align-items: center;
                        color: var(--secondary-900);
                      }

                      p {
                        font-size: 8px;
                        line-height: 12px;
                        color: #808191;
                        font-weight: 400;
                        color: #495057;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .hover-info-align {
        position: absolute;
        top: 40%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 16px;

        @media only screen and (max-width: '767px') {
          display: none;
        }
      }

      .bottom-section {
        bottom: 0 !important;
        position: absolute;
        width: 100%;

        @media only screen and (max-width: '767px') {
          display: none;
        }

        .product-color-main {
          display: flex;
          justify-content: flex-end;
          width: 100%;
          padding: 12px;

          .color-alignment {
            background: #ffffff;
            border: 0.5px solid #e4e4e8;
            border-radius: 10.5px;
            padding: 0px 6px;
            display: flex;
            justify-content: center;
            gap: 4px;
            align-items: center;

            img {
              width: 13px;
              height: 13px;
            }

            span {
              margin-top: 2px;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              display: flex;
              align-items: center;
              color: var(--secondary-900);
            }
          }
        }

        .add-product-collection-alignment {
          padding: 4px 8px 8px 0;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .add-production-box {
            border-radius: 8px;
            background: rgba(30, 36, 50, 0.6);
            padding: 4px;
            display: flex;
            align-items: center;

            .collection-icon {
              width: 24px;
              height: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              img {
                width: auto;
              }

              &.arrow-left-alignment {
                img {
                  transform: rotate(180deg);
                }
              }
            }
          }
        }
      }
    }
  }

  .hover-event-main {
    width: 310px;
    position: absolute;
    top: 0;
    z-index: 2;
    padding: 12px;
  }

  .grid-image-main-handler {
    // background: #1e2432;
    // opacity: 0.6;
    // height: 48px;
    max-height: 0px;
    overflow: hidden;
    transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

    .spacer-image-grid-alignment {
      padding: 6px 0 6px 6px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    .product-image-list {
      // display: grid;
      // grid-template-columns: repeat(8, 1fr);
      display: flex;
      flex-wrap: wrap;
      gap: 4px;

      @media only screen and (max-width: '1099px') {
        grid-template-columns: repeat(5, 1fr);
      }

      .media-border {
        width: 33px;
        height: 33px;

        .product-sub-image {
          position: absolute;
        }

        img {
          width: 100%;
          height: 100%;
          margin-right: 4px;
          border-radius: 4px;
          object-fit: cover;
        }

        video {
          width: 100%;
          height: 100%;
          // padding-right: 4px;
          object-fit: cover;
        }
      }

      .active-border {
        border: 1px solid #6b21a8;
        border-radius: 4px;
      }
    }
  }

  .product-description-section {
    padding: 22px 17px 16px 14px;

    @media screen and (max-width: 767px) {
      padding: 8px;
    }

    h2 {
      font-weight: 400 !important;
      font-size: 16px;
      line-height: 24px;
      color: var(--secondary-900);

      @media screen and (max-width: 767px) {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        max-width: 148px;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
    }

    .price-analytics-section {
      display: flex;
      gap: 6px;
      align-items: center;
      margin: 10px 0;
      width: 100%;

      @media screen and (max-width: 767px) {
        margin: 4px 0 0 0;
        justify-content: space-between;
        display: block;
      }

      .price-data {
        display: flex;
        // justify-content: space-between;
        align-items: center;
        gap: 6px;
        width: 100%;

        h5 {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          display: flex;
          align-items: center;
          color: #000000;
          gap: 5px;

          strong {
            font-weight: 500;
          }
        }

        .price-analytics-section-price-and-buttons {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;

          .price-analytics-section-buttons-main {
            display: flex;
            align-items: center;
            gap: 8px;

            .price-analytics-section-buttons {
              width: 44px;
              height: 44px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              border-radius: 8px;
            }
          }
        }

        span {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          display: flex;
          align-items: center;
          text-decoration-line: line-through;

          /* Grey3 */

          color: #acb1c0;
        }
      }

      // @media screen and (max-width: 767px) {
      //   .discount-menu-align {
      //     display: none;
      //   }
      //   .delivery-menu-align {
      //     display: none;
      //   }
      // }
    }

    .analytics-section-main {
      display: flex;
      // justify-content: flex-start;
      align-items: center;
      gap: 12px;

      @media screen and (max-width: 767px) {
        display: none;
      }

      .analytics-section {
        display: flex;
        // justify-content: flex-start;
        align-items: center;
        gap: 12px;
        flex-wrap: wrap;

        @media screen and (max-width: 767px) {
          margin: 4px 0;
          width: fit-content;
        }

        .delivery-shipping {
          display: flex;
          align-items: center;
          gap: 6px;

          @media screen and (max-width: 767px) {
            margin: 2px 0;
          }

          .best-seller-alignment {
            h6 {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 13px;
              line-height: 20px;
              background: linear-gradient(263.94deg, #ffc554 7.44%, #744c00 86.5%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              // text-fill-color: transparent;
            }
          }

          .dot-alignment {
            p {
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background: #acb1c0;
            }
          }

          .discount-menu-align {
            width: auto;
            height: auto;
            background: #ff7d23;
            border-radius: 11px;
            display: flex;
            justify-content: center;
            align-items: center;

            button {
              padding: 1px 9px;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              display: flex;
              align-items: center;
              color: #ffffff;

              @media screen and (max-width: 767px) {
                padding: 1px 4px;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                display: flex;
                align-items: center;
                color: #ffffff;
                white-space: nowrap;
              }
            }
          }

          .delivery-menu-align {
            width: auto;
            height: 21px;
            // background: #eaeaea;
            border-radius: 13.5px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2px;

            @media screen and (max-width: 767px) {
              padding: 4px 6px;
            }

            padding: 4px 8px;

            img {
              width: 100%;
              height: 100%;
            }

            span {
              font-weight: 500;
              font-style: italic;
              font-size: 12px;
              line-height: 18px;
              display: flex;
              align-items: center;
              text-decoration-line: none;
              color: #5f40af;
            }
          }
        }

        .graph-menu-align {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 6px;

          img {
            width: 14px;
            height: 11.8px;
          }

          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #000000;
          }
        }

        .arrow-menu-align {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 6px;

          img {
            width: 11px;
            height: 11px;
          }

          span {
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            display: flex;
            align-items: center;
            color: #70c217;
          }
        }
      }

      .feedback-section {
        display: flex;
        // justify-content: flex-start;
        align-items: center;
        gap: 12px;

        .dott-icon-align {
          width: 4px;
          height: 4px;
          background: #acb1c0;
          border-radius: 50%;

          @media screen and (max-width: 767px) {
            display: none;
          }
        }

        .rating-section-main {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 4px;

          img {
            margin-bottom: 2px;
          }

          span {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #808191;
          }

          p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #808191;
            margin-bottom: 0 !important;
          }
        }
      }
    }

    .mobile-view-analytics-section-main {
      display: none;

      @media only screen and (max-width: '768px') {
        display: flex;

        .book-rate-alignment {
          display: flex;
          align-items: center;
          padding: 16px 0 0 0;
          gap: 4px;

          @media only screen and (max-width: '767px') {
            padding: 8px 0 0 0;
            justify-content: center;
          }

          .hike {
            display: flex;
            align-items: center;

            .inter {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 13px;
              line-height: 20px;
              color: #70c217;

              img {
                margin-right: 3px;
              }
            }

            .incris {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 13px;
              line-height: 20px;
              color: #70c217;
              display: flex;
              align-items: center;

              img {
                margin-right: 3px;
              }
            }
          }

          .rating {
            display: flex;
            align-items: center;

            .rating_point {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 13px;
              line-height: 20px;
              color: #808191;
            }

            .stars {
              display: flex;
              align-items: center;
              margin: 0 2px;
            }

            .num {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 13px;
              line-height: 20px;
              color: #808191;
            }
          }
        }
      }
    }
  }

  .comment-section-main {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .comment-box-alignment {
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid #f3f3f3;
      border-right: 1px solid #f3f3f3;
      padding: 3px;
      gap: 4px;

      img {
        width: 16px;
        height: 16px;

        @media screen and (max-width: 767px) {
          width: 14px;
          height: 14px;
        }
      }

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 35px;
        letter-spacing: 0.144px;
        color: #808191;

        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }
    }

    .like-box-alignment {
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid #f3f3f3;
      border-right: 1px solid #f3f3f3;
      padding: 3px;
      gap: 4px;

      img {
        width: 18px;
        height: 18px;

        @media screen and (max-width: 767px) {
          width: 14px;
          height: 14px;
        }
      }

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 35px;
        letter-spacing: 0.144px;
        color: #808191;

        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }
    }

    .share-box-alignment {
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid #f3f3f3;
      border-right: 1px solid #f3f3f3;
      padding: 3px;
      gap: 1px;

      img {
        width: 24px;
        height: 24px;

        @media screen and (max-width: 767px) {
          width: 19px;
          height: 19px;
        }
      }

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 35px;
        letter-spacing: 0.144px;
        color: #808191;

        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }
    }

    :last-child {
      border-right: white 1px solid;
    }
  }

  .comment-section-alignment {
    padding: 0 12px 16px 12px;

    .like-share-alignment {
      display: flex;
      align-items: center;
      gap: 16px;

      .like-alignment {
        display: flex;
        align-items: center;
        gap: 6px;

        img {
          cursor: pointer;
        }

        span {
          padding: 4px 0 0 0;
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          color: #808191;
        }
      }

      .share-alignment {
        margin: 21px 0 0 0;

        img {
          cursor: pointer;
        }
      }
    }
  }

  .mobile-view-bookmark-remove-product-alignment {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(30, 36, 50, 0.6);
    z-index: 999;
    display: none;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-height: '767px') {
      display: block;
    }

    .mobile-view-bookmark-remove-icon-alignment {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      svg {
        cursor: pointer;
      }
    }
  }

  .mobile-view-add-product-bookmark-section {
    position: absolute;
    bottom: 4px;
    right: 4px;
    border-radius: 8px;
    background: rgba(30, 36, 50, 0.6);
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .mobile-view-on-summary-section {
    position: absolute;
    bottom: 4px;
    right: 4px;
    border-radius: 8px;
    background: rgba(30, 36, 50, 0.6);
    padding: 1px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    display: none;

    @media only screen and (max-width: '1024px') {
      display: flex;
    }

    p {
      color: var(--white);
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      max-width: 175px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @media only screen and (max-width: '767px') {
        max-width: 75px;
      }
    }
  }
}

.product-container-main:hover {
  box-shadow: 0px 13px 18px rgba(95, 64, 175, 0.1);
  transition: 0.5s;
  background: #fff;

  .image-contain {
    transition: 0.5s;

    .onhover-align {
      opacity: 1;
      transition: 0.5s;
    }
  }

  .grid-image-main-handler {
    max-height: 100vh;
    overflow: hidden;
    transition: 0.3s ease-in-out;
    @media only screen and (max-width: '768px') {
      display: none;
    }
  }

  .mobile-view-on-summary-section {
    display: flex;
  }
}
