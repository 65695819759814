.mobileProductDescriptionModalSection {
    padding: 16px;

    .mobileProductHeadingAlignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 8px 8px 0;

        h6 {
            color: var(--secondary-900);
            font-family: 'Poppins', sans-serif;
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
        }

        a {
            color: var(--primary-500);
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }
    }

    .mobileProductBodyDescriptionAlignment {
        border-radius: 10px;
        border: 1px solid var(--secondary-50);
        background: #FFF;
        height: calc(100vh - 240px);
        padding: 8px 16px;

        textarea {
            color: var(--secondary-900);
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            width: 100%;
            height: 100%;
        }
    }

    .mobileProductButtonAlignment {
        padding: 20px 0 0 0;

        button {
            color: #FFF;
            text-align: center;
            font-family: 'Poppins', sans-serif;
            font-size: 18px;
            font-weight: 500;
            line-height: 28px;
            border-radius: 10px;
            background: var(--primary-500);
            width: 100%;
            height: 44px;

        }
    }
}