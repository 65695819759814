.liveStreamProductSection {
  height: calc(100vh - 286px);
  overflow-y: auto;

  @media only screen and (max-width: '1024px') {
    height: unset;
  }

  .liveStreamHeading {
    padding: 0 24px 24px 24px;
    position: sticky;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 9;

    @media only screen and (max-width: '1024px') {
      padding: 16px;
    }

    h4 {
      color: var(--secondary-900);
      font-size: 24px;
      font-weight: 600;
      line-height: 28px;

      @media only screen and (max-width: '1024px') {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }

  .liveStreamProductBodyAlignment {
    height: calc(100% - 54px - 60px);
    overflow-y: auto;
    position: relative;
    @media only screen and (max-width: '1024px') {
      height: calc(100vh - 202px);
    }

    .produtcDetailsAlignment {
      padding: 10px 16px;
      margin: 0 0 4px 0;
      transition: 0.4s ease-in-out;
      cursor: pointer;
      position: relative;

      &:last-child {
        margin: 0;
      }

      .productDetailsFlexAlignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        width: 100%;
        overflow-x: auto;

        @media only screen and (max-width: '1024px') {
          align-items: center;
        }

        .leftFlexAlignment {
          display: flex;
          align-items: flex-start;
          gap: 8px;

          .productImg {
            width: 72px;
            height: 72px;
            min-width: 72px;
            max-width: 72px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              display: block;
              border-radius: 8px;
            }
          }

          .productAllDetails {
            max-width: 187px;

            @media only screen and (max-width: '1024px') {
              max-width: calc(100vw - 120px);
              width: calc(100vw - 120px);
            }

            h4 {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              color: var(--secondary-900);
              font-size: 14px;
              font-weight: 500;
              line-height: 21px;
              padding: 0 0 2px 0;

              @media only screen and (max-width: '1024px') {
                max-width: 187px;
              }
            }

            span {
              color: var(--secondary-500);
              font-size: 12px;
              font-weight: 400;
              line-height: 18px;
            }
          }
        }

        .productDeleteIcon {
          position: absolute;
          top: 34px;
          right: 24px;
          visibility: hidden;
          transition: 0.2s ease-in-out;

          @media only screen and (max-width: '1024px') {
            visibility: visible;
            position: static;
          }

          svg {
            cursor: pointer;
          }
        }

        .addCartIcon {
          width: 44px;
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }

      &:hover {
        border-radius: 8px;
        background: var(--light-grey);
        transition: 0.4s ease-in-out;

        .productDeleteIcon {
          transition: 0.2s ease-in-out;
          visibility: visible;
        }
      }
    }

    .productDeleteCollectionModalWrapper {
      position: absolute;
      top: 50%;
      left: 20px;
      right: 20px;
      transform: translateY(-50%);
      padding: 10px;
      border-radius: 16px;
      background: var(--white);
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      text-align: center;

      .productDeleteCollecyionHeading {
        width: 100%;
        h6 {
          color: var(--secondary-900);
          text-align: center;
          font-size: 16px;
          font-weight: 600;
          line-height: 21px;

          @media only screen and (max-width: '767px') {
            max-width: 100%;
          }
        }

        .produtcDetailsAlignment {
          padding: 10px 16px;
          margin: 0 0 4px 0;
          transition: 0.4s ease-in-out;
          cursor: pointer;
          position: relative;

          &:last-child {
            margin: 0;
          }

          .productDetailsFlexAlignment {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            width: 100%;
            overflow-x: auto;

            @media only screen and (max-width: '1024px') {
              align-items: center;
            }

            .leftFlexAlignment {
              display: flex;
              align-items: flex-start;
              gap: 8px;

              .productImg {
                width: 72px;
                height: 72px;
                min-width: 72px;
                max-width: 72px;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  display: block;
                  border-radius: 8px;
                }
              }

              .productAllDetails {
                max-width: 187px;

                @media only screen and (max-width: '1024px') {
                  max-width: calc(100vw - 120px);
                  width: calc(100vw - 120px);
                }

                h4 {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  color: var(--secondary-900);
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 21px;
                  padding: 0 0 2px 0;

                  @media only screen and (max-width: '1024px') {
                    max-width: 187px;
                  }
                }

                span {
                  color: var(--secondary-500);
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 18px;
                }
              }
            }

            .productDeleteIcon {
              position: absolute;
              top: 34px;
              right: 24px;
              visibility: hidden;
              transition: 0.2s ease-in-out;

              @media only screen and (max-width: '1024px') {
                visibility: visible;
                position: static;
              }

              svg {
                cursor: pointer;
              }
            }

            .addCartIcon {
              width: 44px;
              height: 44px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              button {
                border: 1px solid;
                padding: 5px;
                color: #8395ea;
                border-radius: 5px;
              }
            }
          }

          &:hover {
            border-radius: 8px;
            background: var(--light-grey);
            transition: 0.4s ease-in-out;

            .productDeleteIcon {
              transition: 0.2s ease-in-out;
              visibility: visible;
            }
          }
        }
      }
    }
  }

  .addProductButtonAlignment {
    padding: 16px 0 0 0;
    background: #fff;
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 9;

    @media only screen and (max-width: '1024px') {
      padding: 16px;
    }

    button {
      border-radius: 10px;
      background: var(--secondary-50);
      width: 100%;
      height: 44px;
      color: var(--primary-500);
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;

      @media only screen and (max-width: '1024px') {
        font-weight: 500;
      }
    }
  }
}
