.mobile-view-group-search-alignment {
  background: #ffffff;
  padding: 16px 0;

  .mobile-view-group-search-input-alignment {
    position: relative;
    padding: 0 16px;

    input {
      height: 44px;
      width: 100%;
      background: #f8f8f8;
      border-radius: 10px;
      padding: 0 16px;
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--secondary-900);

      &::placeholder {
       color: var(--secondary-300);
      }
    }

    .close-icon-alignment {
      position: absolute;
      top: 10px;
      right: 28px;
    }
  }

  .mobile-view-group-result-details-alignment {
    padding: 16px 16px 0 16px;

    p {
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: var(--secondary-500);
    }
  }

  .mobile-view-group-details-alignment {
    padding: 26px 16px 16px 16px;

    .mobile-view-group-details-heading-alignment {
      display: flex;
      align-items: center;
      gap: 4px;

      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: var(--secondary-900);
      }

      .number-box-alignment {
        background: #eceef4;
        border-radius: 10px;
        padding: 0 8px;

        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          color: var(--secondary-500);
        }
      }
    }
  }

  .mobile-view-group-search-member-details-alignment {
    padding: 0 0 0 16px;

    .mobile-view-group-search-member-grid-alignment {
      width: 100%;
      display: flex;
      align-items: center;
      overflow-x: auto;
      gap: 16px;

      .book-appliance-trending-store-gridItem-alignment {
        .book-appliance-trending-store-box-alignment {
          background: #ffffff;
          border-radius: 16px;
          // height: 432px;

          .book-appliance-details-img-alignment {
            width: 140px;
            height: 140px;

            @media only screen and (max-width: '767px') {
              height: 100%;
            }

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
          }

          .book-appliance-child-details-alignment {
            padding: 24px 0 0 0;
            text-align: center;

            @media only screen and (max-width: '767px') {
              padding: 16px 0 0 0;
            }

            h5 {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 20px;
              line-height: 30px;
              color: #000000;

              @media only screen and (max-width: '767px') {
                font-size: 16px;
                line-height: 24px;
              }
            }

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: #495057;

              @media only screen and (max-width: '767px') {
                font-size: 12px;
                line-height: 18px;
                padding: 2px 0 0 0;
              }
            }

            .book-appliance-product {
              // font-weight: 600;
              // font-size: 14px;
              // line-height: 21px;
              // color: #808191;
              margin: 16px 0 8px 0;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 4px;

              @media only screen and (max-width: '767px') {
                flex-wrap: wrap;
                gap: 2px;
                margin: 9px 0 8px 0;
              }

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #495057;

                @media only screen and (max-width: '767px') {
                  font-size: 12px;
                  line-height: 18px;
                  margin: 0 0 2px 0;
                  padding: 0;
                }
              }

              p {
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background: #495057;

                @media only screen and (max-width: '767px') {
                  display: none;
                }
              }
            }

            .book-appliance-visitor {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-wrap: wrap;
              gap: 2px;

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                color: #495057;

                @media only screen and (max-width: '767px') {
                  font-size: 12px;
                  line-height: 18px;
                  padding: 0;
                }
              }

              .book-appliance-pre {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;
              }
            }

            .follow-button-alignment {
              padding: 16px 8px 8px 8px;

              @media only screen and (max-width: '767px') {
                padding: 14px 0 0 0;
              }

              button {
                width: 100%;
                height: 32px;
                background: var(--primary-500);
                border-radius: 8px;
                font-family: 'Poppins', sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #f8f8f8;
                cursor: pointer;

                &.following {
                  background: #f8f8f8;
                  color: var(--primary-500);

                  &:hover {
                    background: #eef1fc;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .mobile-view-no-search-data {
    background: #ffffff;
    height: calc(100vh - 220px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 94px;

    .mobile-view-no-search-data-text-alignment {
      text-align: center;

      span {
        font-family: 'Poppins', sans-serif;
        font-style: italic;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
       color: var(--secondary-300);
      }
    }
  }
}
