.hotWatchesAllDetailsAlignment {
  cursor: pointer;
  width: 138px;
  min-width: 138px;
  max-width: 138px;
  .hotWatchesImg {
    width: 100%;
    height: 142px;
    position: relative;
    cursor: pointer;
    overflow-y: hidden;
    border-radius: 8px;
    border: 2.5px solid #fff;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      border-radius: 8px;
      transform: scale(1);
      transition: 0.4s ease-in-out;
    }

    // .offerLabel {
    //   padding: 2px 8px;
    //   border-radius: 0px 8px;
    //   background: var(--yellow-300);
    //   position: absolute;
    //   bottom: 0;
    //   left: 0;
    //   p {
    //     color: var(--yellow-800);
    //     text-align: center;
    //     font-size: 12px;
    //     font-weight: 500;
    //     line-height: 18px;
    //   }
    // }
  }

  .hotWatchesDetails {
    padding: 8px 0 0 0;
    h6 {
      max-width: 117px;
      color: var(--secondary-900);
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      margin: 0 auto;
      text-align: center;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .priceALignment {
      text-align: center;
      @media only screen and (max-width: '1024px') {
        padding: 4px 0 0 0;
      }

      p {
        color: var(--secondary-900);
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
      }
    }
  }
  &:hover {
    .hotWatchesImg {
      img {
        transform: scale(1.2);
        transition: 0.4s ease-in-out;
      }
    }
  }
}
