@mixin block_btn {
  background: var(--primary-500);
  border-radius: 8px;
  display: block;
  width: 100%;
  border: unset;
  outline: unset;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    transform: translateY(-3px);
    background: #2844ac;
  }
}

.home-appliance-club-section {
  // display: none;
  background-color: #fff;

  .container {
    max-width: 1440px;
    margin: auto;
    padding: 0;

    .home-appliance-club-alignment {
      padding: 0 32px;

      .home-appliance-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
          font-family: "Poppins", sans-serif;
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
          margin: 0;
        }

        .home-appliance-see-more-alignment {
          a {
            display: flex;
            align-items: center;
            gap: 8px;
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: var(--primary-500);
            cursor: pointer;

            @media only screen and (max-width: '767px') {
              font-size: 14px;
              line-height: 21px;

              img {
                display: none;
              }
            }

            &:hover {
              gap: 10px;
              text-decoration: underline;
            }
          }
        }
      }

      .home-appliance-details-aligment {
        margin: 16px 0 0 0;

        .fashion_group_slider_cover {
          padding: 0 16px 0 0;

          .card_fashion {
            width: 100%;
            position: relative;
            border-radius: 10px 10px 0px 0px;
            overflow: hidden;
            transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
            height: 251px;
            max-height: 251px;

            &:hover {
              transform: translateY(-5px);

              .img_cover img {
                transform: scale(1.05);
              }
            }

            .img_cover {
              object-fit: cover;
              object-position: center;
              height: 251px;
              max-height: 251px;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
              }
            }

            .card_details {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: flex-end;
              padding: 15px;
              background: linear-gradient(360deg, #0e1116 0%, rgba(30, 36, 50, 0) 100%);

              .card_details_cover {
                width: 100%;
                cursor: pointer;

                h5 {
                  font-weight: 500;
                  font-size: 20px;
                  line-height: 30px;
                  color: #ffffff;
                }

                p {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 18px;
                  color: #ffffff;
                  margin: 4px 0 17px 0;
                }

                .users {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  .left_part {
                    display: flex;
                    align-items: center;

                    .users_likes {
                      display: flex;
                      align-items: center;
                      margin-left: -10px;

                      .user_box {
                        width: 20px;
                        display: flex;
                        align-items: center;
                        height: 20px;
                        justify-content: center;
                        background: #fff;
                        object-position: center;
                        border: 1px solid #ffffff;
                        border-radius: 30px;

                        img {
                          width: 100%;
                          object-fit: cover !important;
                        }
                      }
                    }

                    .count {
                      margin-left: -10px;
                      background: var(--primary-500);
                      border: 1px solid #ffffff;
                      border-radius: 12px;
                      font-weight: 500;
                      font-size: 12px;
                      line-height: 18px;
                      letter-spacing: 0.1px;
                      color: #ffffff;
                      padding: 0px 9px;
                    }
                  }

                  .right_part {
                    max-width: 120px;

                    button {
                      @include block_btn();
                    }
                  }
                }
              }
            }
          }
        }

        .jewelry-grid-alignment {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 16px;

          @media (max-width:768px) {
            grid-template-columns: repeat(2, 1fr);
          }
          @media (max-width:576px) {
            grid-template-columns: repeat(1, 1fr);
          }
        }
      }
    }
  }

  .slick-arrow {
    background: rgba(255, 255, 255, 0.6);

    img {
      &.hover-img {
        display: none;
      }
    }

    &:hover {
      background: #a855f7 !important;

      img {
        &.hover-img {
          display: block;
        }

        &.defalt-icon {
          display: none;
        }
      }

      &.slick-prev {
        img {
          animation: righttoleft 0.4s ease-in-out;
        }
      }

      &.slick-next {
        img {
          animation: bounceRight 0.4s ease-in-out;
        }
      }
    }
  }

  @keyframes righttoleft {
    0% {
      -moz-transform: translateX(4px);
      transform: translateX(4px);
    }

    100% {
      -moz-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @keyframes bounceRight {
    0% {
      -ms-transform: translateX(0);
      transform: translateX(0);
    }

    100% {
      -ms-transform: translateX(-4px);
      transform: translateX(-4px);
    }
  }
}