@mixin Head_3($margin) {
  font-weight: 300;
  font-size: 36px;
  line-height: 54px;
  color: #000000;
  margin-bottom: $margin;
}

@mixin Head_Link() {
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  color: var(--primary-500);
  display: flex;
  align-items: center;

  &:hover {
    transform: translateY(-3px);
  }

  span {
    margin-left: 8px;
  }
}

@mixin on($display) {
  display: $display;
  visibility: visible;
  touch-action: auto;
  pointer-events: all;
}

@mixin off($display) {
  display: $display;
  visibility: hidden;
  touch-action: none;
  pointer-events: none;
}

@mixin block_btn {
  background: var(--primary-500);
  border-radius: 8px;
  display: block;
  width: 100%;
  border: unset;
  outline: unset;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    transform: translateY(-3px);
    background: #2844ac;
  }
}

.men-category-by-shoes-section {
  width: 100%;
  background: #1e2432;
  padding: 32px 0;

  @media only screen and (max-width: 767px) {
    display: none;
  }

  .container {
    width: 1310px;

    .section_3_row {
      margin: 0 -10px;
      height: 138px;

      @media only screen and (max-width: 1299px) {
        margin: 0;
        padding: 0 60px;
      }

      @media only screen and (max-width: 999px) {
        margin: 0;
        padding: 0 30px;
      }

      .catagory_block {
        text-align: center;

        &:hover {
          .shop-by-product-name-alignment {
            p {
              color: #f8f8f8;
            }
          }

          .box_cover {
            border-color: #ab67fc;
          }

          .img_cover {
            transform: translateY(-5px);
          }
        }

        &:hover {
          .shop-by-product-name-alignment {
            p {
              color: #f8f8f8;
            }
          }

          .shop-by-category-img-aligment {
            border: 1px solid #556ee6;
          }

          .shop-by-category-img-aligment {
            transform: translateY(-5px);
          }
        }

        .box_radius {
          border-color: #a855f7 !important;
        }

        .box_cover {
          width: 105px;
          height: 105px;
          border-radius: 105px;
          padding: 5px;
          border: 1px solid transparent;
          margin: 0 auto;
          transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
        }

        .box {
          background: #ffffff;
          width: 100%;
          height: 100%;
          border-radius: 100px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;

          .img_cover {
            transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
          }
        }

        .active {
          background: #91a1ec;
        }

        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: var(--secondary-900);
          margin-top: 10px;
        }
      }

      .left_block {
        width: 151px;
        padding: 0 10px;

        .left_cover {
          cursor: pointer;

          h3 {
            @include Head_3(24px);
          }

          a {
            @include Head_Link();
          }
        }
      }

      .right_block {
        width: calc(100% - 151px);
        padding: 0 10px;

        .right_block_cover {
          width: 100%;

          .category_slider {
            position: relative;
            cursor: pointer;

            .slick-arrow {
              transform: translate(0, -85%);
            }
          }

          .shop-by-cateogry-details-names {
            // padding: 0 8px;

            &:hover {
              cursor: pointer;

              .cover-alignment {
                border: 1px solid #556ee6;
              }

              .shop-by-product-name-alignment {
                // padding: 0;

                p {
                  color: #f8f8f8;
                  font-weight: 600;
                  font-size: 18px;
                  line-height: 27px;
                }
              }
            }

            .cover-alignment {
              width: 110px;
              height: 110px;
              margin: auto;
              border: 1px solid transparent;
              background: #1e2432;
              padding: 6px;
              border-radius: 10px;

              @media only screen and (max-width: 899px) {
                width: 100%;
                height: 100%;
              }

              // &:hover {
              //     border: 1px solid #556EE6;
              //     border-radius: 10px;
              //     padding: 6px;
              //     cursor: pointer;
              // }

              .shop-by-category-img-aligment {
                // width: 98px;
                height: 98px;
                position: relative;

                @media only screen and (max-width: 899px) {
                  height: 100%;
                }

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 8px;
                }

                &::before {
                  position: absolute;
                  content: '';
                  width: 100%;
                  height: 100%;
                  background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 70.41%);
                  border-radius: 8px;
                  top: 0;
                  right: 0;
                }
              }
            }

            .shop-by-product-name-alignment {
              padding: 2px 0 0 0;

              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #acb1c0;
              }
            }
          }

          .active-class-for-category {
            cursor: pointer;

            .cover-alignment {
              border: 1px solid #556ee6;
            }

            .shop-by-product-name-alignment {
              // padding: 0;

              p {
                color: #f8f8f8;
                font-weight: 600;
                font-size: 18px;
                line-height: 27px;
              }
            }
          }
        }
      }
    }
  }
}

// .WomansCategory_page {
.slick-arrow::before {
  display: none;
}

.slick-arrow {
  z-index: 9 !important;
  width: 40px !important;
  height: 40px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  cursor: pointer !important;
  transform: translateY(-50%) !important;
  background: rgba(255, 255, 255, 0.6) !important;

  @media (max-width:576px) {
    display: none !important;
  }

  img {
    width: 8px;
  }

  svg path {
    stroke-width: 2px;
  }

  &:hover {
    background: var(--primary-500);

    svg {
      path {
        stroke: #fff;
        stroke-width: 3px;
      }
    }
  }
}

.slick-next {
  right: -50px;

  svg {
    transform: rotate(180deg);
  }
}

.slick-prev {
  left: -50px;
}

//   }

.men-category-by-shoes-banner-details-section {
  margin: 0 0 20px 0;
  background: #151515;
  position: relative;

  @media only screen and (max-width: 767px) {
    display: none;
  }

  .category-img-alignment {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;

    img {
      height: 100%;
    }

    // &::after {
    //   content: '';
    //   position: absolute;
    //   background: linear-gradient(90deg, #151515 0%, rgba(21, 21, 21, 0) 100%);
    //   top: 0;
    //   right: 0;
    //   width: 100%;
    //   height: 100%;
    // }
  }

  .container {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 15px;

    .category-by-shoes-banner-section {
      height: 178px;
      display: flex;
      align-items: center;
      gap: 160.5px;
      position: relative;

      @media only screen and (max-width: 1299px) {
        gap: 80px;
        padding: 0 20px;
      }

      .category-by-shoes-heading-alignment {
        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 40px;
          line-height: 60px;
          color: #f8f8f8;
          padding: 0 0 4px 0;
          text-transform: uppercase;
        }

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 300;
          font-size: 18px;
          line-height: 27px;
          color: #f8f8f8;
          text-transform: capitalize;
          // max-width: 1000px;
        }
      }
    }
  }
}

.category-by-shoes-fashion-section {
  padding: 16px 0 155px 0;
  background: #ffffff;

  .container {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 15px;

    .category-by-shoes-fashion-details-alignment {
      background: #f7f7f7;
      overflow-x: hidden;

      .category-by-shoes-fashion-flex-alignment {
        display: flex;
        align-items: center;

        .fashion-store-alignment {
          width: 530px;
          max-width: 530px;
          min-width: 530px;
          height: 564px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          transition: 0.5s ease-in-out;

          .log-alignment {
            padding: 0 0 32px 0;

            img {
              width: 238px;
              height: 144px;
              object-fit: contain;
              display: block;
            }
          }

          .details-alignment-shoes {
            max-width: 220px;
            padding: 0 0 72px 0;

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 300;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              color: var(--secondary-900);
            }
          }

          .fashion-shop-details-alignment {
            text-align: center;
            padding: 0 0 16px 0;

            h2 {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 20px;
              line-height: 30px;
              text-align: center;
              color: #000000;
            }

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              text-align: center;
              color: #808191;
            }
          }

          .product {
            display: flex;
            align-items: center;
            gap: 6px;
            justify-content: center;
            padding: 0 0 8px 0;

            span {
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              color: #808191;
            }

            p {
              width: 3px;
              height: 3px;
              background: #808191;
              border-radius: 50%;
            }
          }

          .Visitor {
            font-size: 14px;
            line-height: 21px;
            color: #808191;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 0 16px 0;

            .pre {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          .view-all-product-alignment {
            a {
              display: flex;
              gap: 8px;
              align-items: center;
              justify-content: center;
              font-family: 'Poppins', sans-serif;
              font-weight: 300;
              font-size: 18px;
              line-height: 27px;
              text-align: center;
              color: var(--primary-500);
              cursor: pointer;
            }
          }

          .product-all-brand-alignment {
            a {
              display: flex;
              gap: 8px;
              align-items: center;
              justify-content: center;
              font-family: 'Poppins', sans-serif;
              font-weight: 300;
              font-size: 18px;
              line-height: 27px;
              text-align: center;
              color: var(--primary-500);
              cursor: pointer;
            }
          }
        }

        .brand-hover-alignment {
          width: 335px;
          min-width: 335px;
          max-width: 335px;
          transition: 0.5s ease-in-out;
        }

        .casual_product_block {
          width: 100%;

          .casual_product_block_row {
            height: 100%;
            display: flex;
            align-items: center;
            gap: 16px;
            flex-wrap: nowrap;
            overflow-x: hidden;

            .card_col {
              // width: 100%;
              // padding: 0 8px;
              // height: 100%;
              // display: grid;
              // height: 496px;
              max-width: 348px;
              width: 348px;

              .cardcover {
                width: 100%;
                border-radius: 10px;
                position: relative;
                overflow: hidden;
                cursor: pointer;
                margin-bottom: 15px;

                &:last-child {
                  margin-bottom: 0;
                }

                &:hover {
                  .card_top img {
                    transform: scale(1.05);
                  }

                  .card_bottom {
                    @include on(flex);
                  }
                }

                .card_top {
                  height: 100%;
                }

                .card_top img {
                  width: 100%;
                  height: 100%;
                  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                }

                .card_bottom {
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  width: 100%;
                  height: 100%;
                  background: linear-gradient(180deg, rgba(30, 36, 50, 0) 0%, #1e2432 100%);
                  padding: 16px;
                  display: flex;
                  align-items: flex-end;
                  @include off(none);

                  .card_bottom_cover {
                    width: 100%;

                    h4 {
                      font-weight: 600;
                      font-size: 20px;
                      line-height: 30px;
                      color: #f8f8f8;
                    }

                    .amount {
                      margin: 5px 0 8px 0;

                      span {
                        vertical-align: middle;
                      }

                      .price {
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 27px;
                        color: #f8f8f8;
                      }

                      .orignal_price {
                        text-decoration-line: line-through;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18px;
                        color: #ffffff;
                        opacity: 0.6;
                        margin: 0 6px;
                      }

                      .discount {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18px;
                        text-align: center;
                        color: #ffffff;
                        background: #ff7d23;
                        border-radius: 11px;
                        padding: 2px 6px;
                      }
                    }

                    .hike {
                      display: flex;
                      align-items: center;

                      .inter {
                        display: flex;
                        align-items: center;
                        font-size: 12px;
                        line-height: 18px;
                        color: #f8f8f8;

                        img {
                          margin-right: 4px;
                        }
                      }

                      .incris {
                        margin-left: 15px;
                        display: flex;
                        align-items: center;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 21px;
                        color: #70c217;

                        img {
                          margin-right: 4px;
                        }
                      }
                    }

                    .rating {
                      display: flex;
                      align-items: center;
                      margin: 9px 0 18px 0;

                      .rating_point {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: #f8f8f8;
                      }

                      .stars {
                        display: flex;
                        align-items: center;
                        margin: 0 8px;
                      }

                      .num {
                        font-size: 16px;
                        line-height: 24px;
                        color: #f8f8f8;
                      }
                    }

                    .buy_btn {
                      @include block_btn();
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.slider-right-arrow-alignment {
  position: absolute;
  bottom: -80px;
  right: 82px;
  z-index: 99;
  cursor: pointer;
}

.slider-left-arrow-alignment {
  position: absolute;
  bottom: -80px;
  right: 142px;
  z-index: 99;
  cursor: pointer;
}

.slick-dots {
  transform: translateX(-50%);
  display: flex !important;
  justify-content: flex-end;
  top: 108% !important;
  right: 0;
  width: 100% !important;
}