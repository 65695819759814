.kYCVerifiedCodeSection {
    .kYCVerifiedCodeHeading {
        padding: 100px 0 0 0;
        text-align: center;

        h4 {
            color: var(--secondary-900);
            text-align: center;
            font-family: 'Poppins', sans-serif;
            font-size: 32px;
            font-weight: 600;
            line-height: 48px;
        }

        p {
            padding: 16px 0 0 0;
            max-width: 273px;
            color: var(--secondary-900);
            text-align: center;
            font-family: 'Poppins', sans-serif;
            font-size: 13px;
            font-weight: 400;
            line-height: 19px;
            margin: 0 auto;
        }
    }

    .kYCVerifiedCodeMainAlignment {
        margin: 150px 0;

        &.successPassword {
            .kYCVerifiedInputFlexAlignment {
                .kYCVerifiedInputAlignment {
                    input {
                        background: #E8F3E6;
                        color: #1D8603;
                    }
                }
            }
        }

        &.inCorrectPassWors {
            margin: 100px 0 150px 0;

            .incorrecrPassWordNotes {
                display: block;
            }

            .kYCVerifiedInputFlexAlignment {
                .kYCVerifiedInputAlignment {
                    input {
                        background: #FDE8E8;
                    }
                }
            }
        }

        .incorrecrPassWordNotes {
            text-align: center;
            padding: 0 0 30px 0;
            display: none;

            p {
                color: #E71616;
                text-align: center;
                font-family: 'Poppins', sans-serif;
                font-size: 13px;
                font-weight: 400;
                line-height: 19px;
            }
        }

        .kYCVerifiedInputFlexAlignment {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;

            .kYCVerifiedInputAlignment {
                width: 40px;
                height: 56px;

                input {
                    width: 100%;
                    height: 100%;
                    color: var(--secondary-900);
                    text-align: center;
                    font-family: 'Poppins', sans-serif;
                    font-size: 24px;
                    font-weight: 600;
                    border-radius: 8px;
                    background: #F8F8F8;
                    line-height: 30px;
                }
            }
        }
    }


    .kYCVerifiedOptionAlignment {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 100px;

        a {
            color: var(--primary-500);
            text-align: center;
            font-family: 'Poppins', sans-serif;
            font-size: 13px;
            font-weight: 500;
            line-height: 20px;
            cursor: pointer;
        }


    }
}