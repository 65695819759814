.shopRisingStoresDetailsSlider {
  padding: 0 8px;

  @media only screen and (max-width: '767px') {
    padding: 0 4px;
  }

  .shopRisingStoresDetailsBoxAlignment {
    border-radius: 8px;
    background: #f8f8f8;
    padding: 16px;
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

    &:hover {
      transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
      transform: translateY(-4px);
      border-radius: 8px;
    }

    .shopRisingStoreProductDetailsAlignment {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .shopRisingStoreProductAllDetails {
        display: flex;
        align-items: center;
        gap: 16px;

        @media only screen and (max-width: '767px') {
          gap: 8px;
        }

        .shopRisingStoreProductAllImg {
          width: 64px;
          height: 64px;

          @media only screen and (max-width: '1199px') {
            width: 56px;
            height: 56px;
          }

          @media only screen and (max-width: '767px') {
            width: 84px;
            height: 84px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
          }
        }

        .shopRisingStoreProductOtherDetails {
          h5 {
            color: var(--secondary-900);
            font-family: 'Poppins', sans-serif;
            font-size: 20px;
            font-weight: 500;
            line-height: 30px;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;

            @media only screen and (max-width: '1199px') {
              font-size: 16px;
              line-height: 20px;
            }

            @media only screen and (max-width: '767px') {
              font-size: 16px;
              line-height: 20px;
            }
          }

          p {
            padding: 2px 0 0 0;
            color: var(--secondary-500);
            font-family: 'Poppins', sans-serif;
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;

            @media only screen and (max-width: '767px') {
              font-size: 12px;
              line-height: 20px;
            }
          }

          .followStoreButton {
            padding: 10px 0 0 0;

            @media only screen and (max-width: '767px') {
              padding: 6px 0 0 0;
            }

            button {
              padding: 6px 26px;
              border-radius: 8px;
              background: var(--primary-50);
              color: var(--primary-500);
              text-align: center;
              font-family: 'Poppins', sans-serif;
              font-size: 14px;
              font-weight: 600;
              line-height: 21px;
              cursor: pointer;

              @media only screen and (max-width: '767px') {
                padding: 6px 16px;
              }

              &.following {
                color: #7a7e88;
              }
            }
          }
        }
      }

      .shopRisingLikeShareButton {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 16px;

        .shopRisingLikeShareIconTotal {
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          p {
            color: var(--secondary-500);
            font-family: 'Poppins', sans-serif;
            font-size: 13px;
            font-weight: 500;
            line-height: 20px;
            padding: 4px 0 0 0;

            @media only screen and (max-width: '767px') {
              display: none;
            }
          }
        }
      }
    }

    .shopRisingChildImagesAlignment {
      padding: 16px 0 0 0;

      .shopRisingChildGrid {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 8px;

        @media only screen and (max-width: '767px') {
          gap: 4px;
        }

        .shopRisingChildImg {
          width: 100%;
          height: 56px;
          position: relative;

          @media only screen and (max-width: '1199px') {
            width: 100%;
            height: 54px;
          }

          @media only screen and (max-width: '767px') {
            width: 100%;
            height: 54px;
          }

          .storeLastProductData {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 4px;
            background: linear-gradient(0deg, rgba(30, 36, 50, 0.6) 0%, rgba(30, 36, 50, 0.6) 100%);
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            object-fit: cover;
          }

          &.lastImgAlignment {
            &::before {
              content: '';
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border-radius: 4px;
              background: linear-gradient(0deg, rgba(30, 36, 50, 0.6) 0%, rgba(30, 36, 50, 0.6) 100%);
            }

            .shopRisingMoreImg {
              position: absolute;
              top: 0;
              right: 0;
              z-index: 9;
              left: 0;
              bottom: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              p {
                color: #fff;
                font-family: 'Poppins', sans-serif;
                font-size: 13px;
                font-weight: 400;
                line-height: 20px;
                text-align: center;

                span {
                  font-size: 16px;
                  line-height: 24px;
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }

  :global(.slick-prev) {
    left: 21px;
    z-index: 99;
  }

  :global(.slick-next) {
    right: 21px;
    z-index: 999;
  }

  :global(.slick-arrow:hover) {
    background: #fff !important;
  }
}
