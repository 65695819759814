.storeFeedSection {
  margin: 0 94px;

  @media only screen and (max-width: '1199px') {
    margin: 0;
  }

  @media only screen and (max-width: '767px') {
    padding: 0 0 0 0;

    &.second-about-section {
      margin: 8px 14px 8px 18px;
    }
  }

  .storeFeedGrid {
    display: grid;
    grid-template-columns: 1fr 412px;
    gap: 16px;

    @media only screen and (max-width: '1099px') {
      grid-template-columns: 1fr;
      gap: 0;
    }

    @media only screen and (max-width: '767px') {
      padding: 16px;
    }

    .storeFeedGridItem {
      .noDataWhiteBoxAlignment {
        background: #ffffff;
        border-radius: 18px;
        width: 100%;
        height: 100%;
      }

      .feedStickyAlignment {
        @media only screen and (max-width: '1099px') {
          display: none;
        }

        position: sticky;
        top: 92px;
      }

      .feedSpecialOfferBoxAlignment {
        background: #ffffff;
        border-radius: 16px;
        padding: 6px 12px;
        margin: 8px 0 0 0;

        @media only screen and (max-width: '1099px') {
          display: none;
        }

        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #000000;
          padding: 0 0 0 11px;
        }

        .feedSpecialOfferDetailsAlignment {
          padding: 9px 0 0 0;

          .feedSpecialOfferBg {
            width: 100%;
            height: 151px;
            position: relative;
            background: #1c1c1e;
            border-radius: 10px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 10px;
            }

            .moreOptionAlignment {
              background: rgba(0, 0, 0, 0.4);
              width: 23px;
              height: 23px;
              border-radius: 50%;
              cursor: pointer;
              position: absolute;
              top: 8px;
              right: 8px;
              cursor: pointer;

              img {
                padding: 5px;
              }
            }

            .cardOfferDetails {
              position: absolute;
              display: flex;
              flex-direction: column;
              right: 0;
              left: 0;
              align-items: center;
              height: 151px;
              justify-content: center;
              top: 0;

              h5 {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 24px;
                line-height: 36px;
                color: #ffffff;
              }

              h6 {
                font-family: 'PoetsenOne';
                font-weight: 400;
                font-size: 24px;
                line-height: 29px;
                color: #ffffff;

                span {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 26px;
                  line-height: 39px;
                  color: #ff7d23;
                  display: block;
                  text-align: center;
                }
              }
            }

            .expiredAddAlignment {
              display: flex;
              align-items: center;
              justify-content: space-between;
              position: absolute;
              bottom: 10px;
              left: 21px;
              right: 10px;

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 10px;
                line-height: 15px;
                color: #ffffff;
                max-width: 87px;
              }

              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #ffffff;
              }
            }
          }
        }
      }

      .noDataWhiteBoxAlignment {
        border-radius: 16px;
        background: #eef1fd;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        height: calc(100vh - 530px);

        @media only screen and (max-width: '1024px') {
          height: calc(100vh - 190px);
        }

        p {
          color: #9c6ac5;
          font-size: 12px;
          font-style: italic;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}
