.new-upcoming-live-strem-section {
    padding: 0 94px;
    .new-upcoming-live-strem-box {
        border-radius: 16px;
        background: rgba(85, 110, 230, 0.13);
        padding: 32px;

        @media only screen and (max-width:"767px") {
            padding: 16px 8px;
            border-radius: 0px;
        }

        .new-upcoming-live-strem-heading-alignment {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 0 0 16px;

            @media only screen and (max-width:"767px") {
                padding: 0 8px;
            }

            h4 {
                color: #000;
                font-size: 20px;
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                line-height: 30px;

                @media only screen and (max-width:"767px") {
                    font-size: 18px;
                    line-height: 21px;
                }
            }


            .new-upcoming-filter-alignment {
                display: flex;
                align-items: center;
                gap: 36px;

                .new-upcoming-calender-box-alignment {
                    // border-radius: 10px;
                    // background: var(--primary-50);
                    // padding: 8px 8px 8px 16px;
                    // width: 178px;
                    // height: 40px;
                    // display: flex;
                    // align-items: center;
                    // justify-content: space-between;

                    // .new-upcoming-calender-flex {
                    //     display: flex;
                    //     align-items: center;
                    //     gap: 8px;

                    //     p {
                    //         color: var(--secondary-900);
                    //         font-size: 16px;
                    //         line-height: 24px;
                    //         font-family: 'Poppins', sans-serif;
                    //         font-weight: 500;
                    //     }
                    // }


                    display: block;

                    @media only screen and (max-width:"767px") {
                        display: none;
                    }

                }

                .new-upcoming-see-all-alignment {
                    a {

                        display: flex;
                        align-items: center;
                        gap: 6px;

                        color: var(--primary-500);
                        text-align: center;
                        font-size: 18px;
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        line-height: 28px;

                        @media only screen and (max-width:"767px") {
                            font-size: 14px;
                            line-height: 21px;
                        }
                    }
                }
            }
        }

        .new-upcoming-live-strem-details-alignment {
            padding: 32px 0 0 0;

            .slick-arrow {
                display: none !important;
            }

            .new-upcoming-live-strem-details-main-alignment {
                padding: 0 8px;

                @media only screen and (max-width:"767px") {
                    padding: 0 4px;
                }

                .new-upcoming-live-strem-details-box-alignment {
                    width: 100%;
                    height: 298px;
                    position: relative;

                    @media only screen and (max-width:"767px") {
                        height: 250px;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 10px;
                        object-fit: cover;
                    }

                    &:before {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        border-radius: 0px 0px 10px 10px;
                        background: linear-gradient(0deg, #1E2432 0%, rgba(30, 36, 50, 0.00) 100%);
                        height: 208px;
                        width: 100%;
                        z-index: 999;
                    }

                    .new-upcoming-date-notification-alignment {
                        position: absolute;
                        top: 16px;
                        right: 16px;
                        left: 16px;
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;

                        .dateBoxAlignment {
                            border-radius: 10px;
                            background: #FFF;
                            padding: 4px;

                            span {
                                color: #495057;
                                text-align: center;
                                font-size: 13px;
                                font-family: 'Poppins', sans-serif;
                                font-weight: 500;
                                line-height: 20px;
                                display: block;
                            }

                            .date-child-box-alignment {
                                width: 40px;
                                height: 40px;
                                border-radius: 8px;
                                background: var(--secondary-50);
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                p {
                                    color: var(--secondary-900);
                                    text-align: center;
                                    font-size: 24px;
                                    font-family: 'Poppins', sans-serif;
                                    line-height: 36px;
                                    font-weight: 600;
                                }
                            }
                        }

                        .new-upcoming-notification-box {
                            padding: 7px;
                            border-radius: 8px;
                            background: rgba(30, 36, 50, 0.40);
                            cursor: pointer;
                        }
                    }

                    .new-upcoming-bottom-details-alignment {
                        bottom: 16px;
                        right: 16px;
                        left: 16px;
                        position: absolute;
                        z-index: 999;

                        h6 {
                            max-width: 167px;
                            color: #F8F8F8;
                            font-size: 20px;
                            line-height: 30px;
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;

                            @media only screen and (max-width:"767px") {
                                font-size: 16px;
                                line-height: 22px;
                            }
                        }

                        .new-upcoming-time-date-alignment {
                            padding: 8px 0 0 0;

                            p {
                                color: #E4E4E8;
                                font-size: 13px;
                                font-family: 'Poppins', sans-serif;
                                font-weight: 500;
                                line-height: 19px;

                                @media only screen and (max-width:"767px") {
                                    font-size: 12px;
                                    line-height: 18px;
                                }
                            }
                        }
                    }
                }
            }

            .new-upcoming-live-stream-details-grid {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
            }

        }
    }

    .new-upcoming-no-live-stream-alignment {
        width: 100%;
        height: 380px;
        border-radius: 16px;
        background: #EEF1FD;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 16px;

        p {
            color: #9C6AC5;
            font-size: 12px;
            font-style: italic;
            font-weight: 400;
            line-height: 18px;
        }
    }
}