@mixin Head_3($margin) {
  font-weight: 300;
  font-size: 36px;
  line-height: 54px;
  color: #000000;
  margin-bottom: $margin;
}

@mixin Head_Link() {
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  color: var(--primary-500);
  display: flex;
  align-items: center;

  &:hover {
    transform: translateY(-3px);
  }

  span {
    margin-left: 8px;
  }
}

@mixin on($display) {
  display: $display;
  visibility: visible;
  touch-action: auto;
  pointer-events: all;
}

@mixin off($display) {
  display: $display;
  visibility: hidden;
  touch-action: none;
  pointer-events: none;
}

@mixin block_btn {
  background: var(--primary-500);
  border-radius: 8px;
  display: block;
  width: 100%;
  border: unset;
  outline: unset;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    transform: translateY(-3px);
    background: #2844ac;
  }
}

.category-by-shoes-section {
  width: 100%;
  background: #eceef4;
  padding: 32px 0;

  @media only screen and (max-width: 767px) {
    display: none;
  }

  .container {
    width: 1310px;

    .section_3_row {
      margin: 0 -10px;

      @media only screen and (max-width: 1099px) {
        padding: 0 20px;
        margin: 0;
      }

      .slick-arrow {
        background-color: hsla(0, 0%, 100%, 0.6);
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99 !important;
      }

      .category-by-shoes-heading-left-arrow-alignment {
        width: 7%;
      }

      .category-by-shoes-heading-right-alignment {
        width: 92%;
      }

      .left_block {
        // width: 275px;
        padding: 0 10px;

        @media only screen and (max-width: 1099px) {
          // width: 150px;
          padding: 0;
        }

        .left_cover {
          cursor: pointer;

          h3 {
            @include Head_3(24px);
          }

          a {
            @include Head_Link();
          }
        }
      }

      .right_block {
        // width: calc(100% - 275px);
        padding: 0 10px;

        @media only screen and (max-width: 1099px) {
          // width: calc(100% - 216px);
        }

        .right_block_cover {
          width: 100%;

          .category_slider {
            position: relative;
            cursor: pointer;

            .catagory_block {
              text-align: center;

              &:hover {
                p {
                  color: #ab67fc;
                }

                .box_cover {
                  border-color: #ab67fc;
                }

                .img_cover {
                  transform: translateY(-5px);
                }
              }

              .box_radius {
                border-color: #a855f7 !important;
              }

              .box_cover {
                width: 105px;
                height: 105px;
                border-radius: 105px;
                padding: 5px;
                border: 1px solid transparent;
                margin: 0 auto;
                transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
              }

              .box {
                background: #ffc0cb;
                width: 100%;
                height: 100%;
                border-radius: 100px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;

                .img_cover {
                  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                }
              }

              .active {
                background: #ffc0cb;
              }

              p {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--secondary-900);
                margin-top: 10px;
              }
            }

            .slick-arrow {
              transform: translate(0, -85%);

              @media only screen and (max-width: 1099px) {
                transform: translate(0, -56%);
              }
            }
          }
        }
      }
    }
  }
}

// .WomansCategory_page {
.slick-arrow::before {
  display: none;
}

.slick-arrow {
  background: #ffffff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  cursor: pointer;
  transform: translateY(-50%);

  svg path {
    stroke-width: 2px;
  }

  &:hover {
    background: #ab67fc;

    svg {
      path {
        stroke: #fff;
        stroke-width: 3px;
      }
    }
  }
}

.slick-next {
  right: -50px;

  svg {
    transform: rotate(180deg);
  }
}

.slick-prev {
  left: -50px;
}

//   }

.category-by-shoes-banner-details-section {
  background: #f9f9f9;
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: 767px) {
    display: block;
  }

  .category-by-shoes-banner {
    background: #f9f9f9;
    // background-image: url('../../../assets/img/shoesByCategoryBg.svg');
    // width: 100%;
    // height: 100%;
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: center;
    position: absolute;
    top: 0;
    right: 0;

    img {
      width: 100%;
      height: 178px;
      object-fit: cover;
      object-position: center;
    }

    div {
      img {
        cursor: pointer;
        display: block;
      }
    }
  }

  .category-by-shoes-banner-section {
    height: 178px;
    display: flex;
    align-items: center;
    gap: 160.5px;
    position: relative;

    @media (max-width: 576px) {
      display: none;
    }
  }

  .container {
    max-width: 1440px;
    margin: auto;
    padding: 0 20px;

    .category-by-shoes-heading-alignment {
      @media only screen and (max-width: 1099px) {
        padding: 0 20px;
      }

      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        font-size: 40px;
        line-height: 60px;
        color: #000000;
        padding: 0 0 4px 0;

        @media (max-width: 576px) {
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }

      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        font-size: 18px;
        line-height: 27px;
        color: var(--secondary-900);

        @media (max-width: 576px) {
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
        }
      }
    }
  }

  // }
}

.category-by-shoes-fashion-section {
  padding: 16px 0 155px 0;
  background: #ffffff;

  @media only screen and (max-width: 767px) {
    display: none;
  }

  .container {
    max-width: 1440px;
    margin: auto;
    padding: 0 20px;

    .category-by-shoes-fashion-details-alignment {
      background: #f7f7f7;
      overflow-x: hidden;

      .category-by-shoes-fashion-flex-alignment {
        display: flex;
        align-items: center;

        .fashion-store-alignment {
          width: 530px;
          max-width: 530px;
          min-width: 530px;
          height: 564px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          transition: 0.5s ease-in-out;

          .log-alignment {
            padding: 0 0 32px 0;

            img {
              width: 238px;
              height: 144px;
              object-fit: contain;
              display: block;
            }
          }

          .details-alignment-shoes {
            max-width: 220px;
            padding: 0 0 72px 0;

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 300;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              color: var(--secondary-900);
            }
          }

          .fashion-shop-details-alignment {
            text-align: center;
            padding: 0 0 16px 0;

            h2 {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 20px;
              line-height: 30px;
              text-align: center;
              color: #000000;
            }

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              text-align: center;
              color: #808191;
            }
          }

          .product {
            display: flex;
            align-items: center;
            gap: 6px;
            justify-content: center;
            padding: 0 0 8px 0;

            span {
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              color: #808191;
            }

            p {
              width: 3px;
              height: 3px;
              background: #808191;
              border-radius: 50%;
            }
          }

          .Visitor {
            font-size: 14px;
            line-height: 21px;
            color: #808191;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 0 16px 0;

            .pre {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          .view-all-product-alignment {
            a {
              display: flex;
              gap: 8px;
              align-items: center;
              justify-content: center;
              font-family: 'Poppins', sans-serif;
              font-weight: 300;
              font-size: 18px;
              line-height: 27px;
              text-align: center;
              color: var(--primary-500);
              cursor: pointer;
            }
          }

          .product-all-brand-alignment {
            a {
              display: flex;
              gap: 8px;
              align-items: center;
              justify-content: center;
              font-family: 'Poppins', sans-serif;
              font-weight: 300;
              font-size: 18px;
              line-height: 27px;
              text-align: center;
              color: var(--primary-500);
              cursor: pointer;
            }
          }
        }

        .brand-hover-alignment {
          width: 335px;
          min-width: 335px;
          max-width: 335px;
          transition: 0.5s ease-in-out;
        }

        .casual_product_block {
          width: 100%;

          .casual_product_block_row {
            height: 100%;
            display: flex;
            align-items: center;
            gap: 16px;
            flex-wrap: nowrap;
            overflow-x: hidden;

            .card_col {
              // width: 100%;
              // padding: 0 8px;
              // height: 100%;
              // display: grid;
              // height: 496px;
              max-width: 348px;
              width: 348px;

              .cardcover {
                width: 100%;
                border-radius: 10px;
                position: relative;
                overflow: hidden;
                cursor: pointer;
                margin-bottom: 15px;

                &:last-child {
                  margin-bottom: 0;
                }

                &:hover {
                  .card_top img {
                    transform: scale(1.05);
                  }

                  .card_bottom {
                    @include on(flex);
                  }
                }

                .card_top {
                  height: 100%;
                }

                .card_top img {
                  width: 100%;
                  height: 100%;
                  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
                }

                .card_bottom {
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  width: 100%;
                  height: 100%;
                  background: linear-gradient(180deg, rgba(30, 36, 50, 0) 0%, #1e2432 100%);
                  padding: 16px;
                  display: flex;
                  align-items: flex-end;
                  @include off(none);

                  .card_bottom_cover {
                    width: 100%;

                    h4 {
                      font-weight: 600;
                      font-size: 20px;
                      line-height: 30px;
                      color: #f8f8f8;
                    }

                    .amount {
                      margin: 5px 0 8px 0;

                      span {
                        vertical-align: middle;
                      }

                      .price {
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 27px;
                        color: #f8f8f8;
                      }

                      .orignal_price {
                        text-decoration-line: line-through;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18px;
                        color: #ffffff;
                        opacity: 0.6;
                        margin: 0 6px;
                      }

                      .discount {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18px;
                        text-align: center;
                        color: #ffffff;
                        background: #ff7d23;
                        border-radius: 11px;
                        padding: 2px 6px;
                      }
                    }

                    .hike {
                      display: flex;
                      align-items: center;

                      .inter {
                        display: flex;
                        align-items: center;
                        font-size: 12px;
                        line-height: 18px;
                        color: #f8f8f8;

                        img {
                          margin-right: 4px;
                        }
                      }

                      .incris {
                        margin-left: 15px;
                        display: flex;
                        align-items: center;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 21px;
                        color: #70c217;

                        img {
                          margin-right: 4px;
                        }
                      }
                    }

                    .rating {
                      display: flex;
                      align-items: center;
                      margin: 9px 0 18px 0;

                      .rating_point {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        color: #f8f8f8;
                      }

                      .stars {
                        display: flex;
                        align-items: center;
                        margin: 0 8px;
                      }

                      .num {
                        font-size: 16px;
                        line-height: 24px;
                        color: #f8f8f8;
                      }
                    }

                    .buy_btn {
                      @include block_btn();
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.slider-right-arrow-alignment {
  position: absolute;
  bottom: -80px;
  right: 82px;
  z-index: 99;
  cursor: pointer;
}

.slider-left-arrow-alignment {
  position: absolute;
  bottom: -80px;
  right: 142px;
  z-index: 99;
  cursor: pointer;
}

.slick-dots {
  transform: translateX(-50%);
  display: flex !important;
  justify-content: flex-end;
  top: 108% !important;
  right: 0;
  width: 100% !important;
}
