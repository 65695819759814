@mixin block_btn {
  background: var(--primary-500);
  border-radius: 8px;
  display: block;
  width: 100%;
  border: unset;
  outline: unset;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    transform: translateY(-3px);
    background: #2844ac;
  }
}

.mobile-book-club-all-details-alignment {
  display: none;

  @media only screen and (max-width: '767px') {
    display: block;
  }

  .mobile-book-club-all-view-modal-alignment {
    width: 100%;
    height: 100vh;
    background: #eceef4;
    position: fixed;
    top: 0;
    right: 0;

    .mobile-book-club-heading-alignment {
      display: flex;
      align-items: center;
      gap: 42px;
      background: rgba(248, 248, 248, 0.92);
      backdrop-filter: blur(13.5914px);
      padding: 16px 24px 8px 16px;

      .back-alignment {
        background: #ffffff;
        border-radius: 10px;
        padding: 4px 9px 4px 10px;
        display: flex;
        align-items: center;
        gap: 8px;

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #808191;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 58px;
        }
      }

      .mobile-child-part-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: -webkit-fill-available;

        .mobile-view-page-name-alignment {
          h4 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            text-align: center;
            color: var(--secondary-900);
          }
        }

        .mobile-author-search-alignment {
          img {
            width: 24px;
            height: 24px;
            display: block;
          }
        }
      }
    }

    .mobile-create-club-main-search-section {
      background: #eceef4;
      position: relative;
      margin: 16px 16px 0 16px;

      input {
        width: 100%;
        height: 44px;
        border-radius: 10px;
        padding: 0 40px 0 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #808191;
      }

      .mobile-create-club-close-alignment {
        position: absolute;
        top: 10px;
        right: 14px;
      }
    }

    .mobile-create-club-button-alignment {
      padding: 17px 16px;
      display: flex;
      justify-content: right;

      button {
        background: var(--primary-500);
        border-radius: 8px;
        height: 32px;
        width: 124px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #f8f8f8;
        cursor: pointer;
      }
    }

    .mobile-book-club-details-section {
      padding: 0 16px;
      height: auto;
      max-height: calc(100vh - 122px);
      overflow-y: auto;

      .mobile-book-club-details-alignment {
        padding: 0 0 8px 0;

        &:last-child {
          margin: 0;
        }

        .mobile-book-club-details_cover {
          padding: 0 4px;

          .mobile-card_fashion {
            width: 100%;
            position: relative;
            border-radius: 10px 10px 0px 0px;
            overflow: hidden;
            transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
            height: 230px;
            max-height: 230px;

            &:hover {
              transform: translateY(-5px);

              .mobile-img_cover img {
                transform: scale(1.05);
              }
            }

            .mobile-img_cover {
              object-fit: cover;
              object-position: center;
              height: 230px;
              max-height: 230px;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
              }
            }

            .mobile-card_details {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: flex-end;
              padding: 15px;
              background: linear-gradient(360deg, #0e1116 0%, rgba(30, 36, 50, 0) 100%);

              .mobile-card_details_cover {
                width: 100%;

                h5 {
                  font-weight: 500;
                  font-size: 20px;
                  line-height: 30px;
                  color: #ffffff;
                }

                p {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 18px;
                  color: #ffffff;
                  margin: 4px 0 17px 0;
                }

                .mobile-users {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  .mobile-left_part {
                    display: flex;
                    align-items: center;

                    .users_likes {
                      display: flex;
                      align-items: center;
                      margin-left: -10px;

                      .user_box {
                        width: 20px;
                        display: flex;
                        align-items: center;
                        height: 20px;
                        justify-content: center;
                        background: #fff;
                        object-position: center;
                        border: 1px solid #ffffff;
                        border-radius: 30px;

                        img {
                          width: 100%;
                          object-fit: cover !important;
                        }
                      }
                    }

                    .count {
                      margin-left: -10px;
                      background: var(--primary-500);
                      border: 1px solid #ffffff;
                      border-radius: 12px;
                      font-weight: 500;
                      font-size: 12px;
                      line-height: 18px;
                      letter-spacing: 0.1px;
                      color: #ffffff;
                      padding: 0px 9px;
                    }
                  }

                  .right_part {
                    max-width: 120px;

                    button {
                      @include block_btn();
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.club-modal-style {
  z-index: 9;
}
