.channelsBannerSection {
    padding: 32px 0;
    background: #fff;

    @media only screen and (max-width:"767px") {
        padding: 0 10px 12px 10px;
    }

    .channelsBannerAlignment {
        .channelsBannerSliderAlignment {
            padding: 0 8px;


            @media only screen and (max-width:"767px") {
                padding: 0 4px;
            }

            @media only screen and (max-width:"480px") {
                padding: 0;
            }

            .channelsBannerImg {
                width: 100%;
                height: 314px;
                position: relative;
                border-radius: 16px;
                border: 1px solid #e6e8ec;
                background: linear-gradient(0deg, #1E2432 0%, rgba(30, 36, 50, 0) 65.81%);

                @media only screen and (max-width:"767px") {
                    height: 212px;
                }


                img,
                video {
                    border-radius: 16px;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;


                    @media only screen and (max-width:"767px") {
                        border-radius: 0;
                    }

                }

                .channelBannerImgDetailsAlignment {
                    position: absolute;
                    padding: 16px;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: column;

                    .channelBannerTopDetailsAlignment {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .liveAndViewDetailsALignment {
                            display: flex;
                            align-items: center;
                            gap: 4px;

                            .liveButtonAlignment {
                                display: flex;
                                align-items: center;
                                gap: 4px;
                                padding: 4px 8px;
                                border-radius: 8px;
                                background: #E71616;

                                p {
                                    color: #FFF;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 14px;
                                    font-weight: 500;
                                    line-height: 21px;
                                }
                            }

                            .liveViewNumberAlignment {
                                display: flex;
                                align-items: center;
                                gap: 4px;
                                border-radius: 8px;
                                background: rgba(30, 36, 50, 0.60);
                                padding: 4px 8px;

                                p {
                                    color: #E4E4E8;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 14px;
                                    font-weight: 500;
                                    line-height: 21px;
                                }
                            }
                        }

                        .liveAndViewVoiceAlignment {
                            width: 24px;
                            height: 24px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background: rgba(30, 36, 50, 0.60);
                            border-radius: 8px;
                            cursor: pointer;
                        }
                    }

                    .channelBottomDetailsAlignment {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .channelBannerHeadingNameAlignment {
                            h6 {
                                color: #F8F8F8;
                                font-family: 'Poppins', sans-serif;
                                font-size: 20px;
                                font-weight: 600;
                                line-height: 30px;

                                @media only screen and (max-width:"767px") {
                                    font-size: 16px;
                                    line-height: 24px;
                                }

                            }

                            .channelBannerLogoAlignment {
                                display: flex;
                                align-items: center;
                                gap: 8px;

                                @media only screen and (max-width:"767px") {
                                    padding: 8px 0 0 0;
                                    gap: 4px;
                                }

                                .channelLogoImg {
                                    width: 20px;
                                    height: 20px;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        border-radius: 2px;
                                        object-fit: cover;
                                    }
                                }

                                p {
                                    color: #FFF;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 13px;
                                    font-weight: 500;
                                    line-height: 19px;

                                    @media only screen and (max-width:"767px") {
                                        font-size: 12px;
                                        line-height: 18px;
                                    }
                                }
                            }
                        }

                        .channelLikeShareAlignment {
                            display: flex;
                            flex-direction: column;
                            gap: 21px;
                            align-items: center;

                            @media only screen and (max-width:"767px") {
                                display: none;
                            }

                            .channelLike {
                                display: flex;
                                flex-direction: column;
                                gap: 4px;
                                align-items: center;
                                cursor: pointer;

                                p {
                                    color: #F8F8F8;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 13px;
                                    font-weight: 500;
                                    line-height: 19px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    :global(.slick-prev) {
        left: 21px;
        z-index: 99;
        background: #d5cfcf !important;
    }

    :global(.slick-next) {
        right: 21px;
        z-index: 999;
        background: #d5cfcf !important;
    }

    :global(.slick-arrow:hover) {
        background: #ab67fc !important;
    }


}