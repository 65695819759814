:global(.slick-arrow) {
  display: none !important;
}

:global(.slick-next) {
  display: none !important;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@import './homePage//homePage';
@import './Header/Header';
@import './login/login.scss';
@import './newFeedPage/newFeedPage.scss';
@import '../cart.scss';
@import '../checkout.scss';

.category-enagement-card-design {
  background: #ffffff;
  border-radius: 10px;
  margin: 0 0 16px 0;
  padding: 13px 13px 26px 13px;

  .show-more-text {
    padding: 28px 0 0 0;

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: var(--primary-500);
      text-align: center;
      cursor: pointer;
    }
  }
}

.mobile-view-footer-buttom {
  // margin: 65px 0 0 0;
  position: fixed;
  bottom: -2px;
  left: 0;
  width: 100%;
  // z-index: 9999990;
  z-index: 999999;
}

.mobile-view-page-wrapper {
  // position: fixed;
  // bottom: 0;
  width: 100%;
  z-index: 999;

  .menu-bg-img-alignment {
    height: 186px;
    position: relative;

    .bg-alignment {
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .menu-profile-name-alignment {
      // position: absolute;
      // bottom: 6px;
      // left: 16px;
      // right: 16px;

      .profile-details-alignment {
        display: flex;
        align-items: flex-end;
        gap: 12px;

        .profile-img-alignment {
          width: 76px;
          max-width: 76px;
          min-width: 76px;
          height: 76px;
          display: flex !important;

          &:hover {
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
          }

          img {
            width: 100%;
            height: 100%;
            border: 2px solid #f8f8f8;
            border-radius: 50%;
            transition: transform 0.3s ease, box-shadow 0.3s ease;
          }

          // &:hover img {
          //   transform: scale(1.05);
          //   /* Slightly enlarge the image */
          //   box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
          //   /* Add a shadow */
          // }
        }

        .profile-name-details-alignment {
          h6 {
            font-family: 'Poppins', sans-serif;
            font-weight: 700;
            font-size: 17px;
            line-height: 26px;
            color: #ffffff;
            padding: 0 0 8px 0;
          }

          .profile-followers-following-alignment {
            display: flex;
            align-items: center;
            gap: 33px;

            .profile-child-details-alignment {
              display: flex;
              align-items: center;
              gap: 4px;

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 15px;
                line-height: 22px;
                color: #ffffff;
              }

              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 15px;
                line-height: 22px;
                color: rgba(236, 238, 244, 0.5);
              }
            }
          }
        }
      }
    }
  }

  .menu-content {
    width: 100%;
    max-height: calc(100vh - 82px);
    overflow-y: scroll;
    overflow-x: hidden;
    // padding: 10px 10px 10px 10px;

    .summary-menu-item {
      height: 52px;
      padding: 10px 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: solid 1px #f5f5f5;

      p {
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.4px;
        text-align: left;
        margin-left: 10px;
        color: var(--secondary-900);
      }
    }
  }

  .bottom-menu {
    // position: absolute;
    // bottom: 0px;
    width: 100%;
    height: 64px;
    background-color: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
    display: flex;
    justify-content: center;
    padding: 0 0 10px 0;

    &::before {
      content: '';
      height: 2px;
      width: 100%;
      top: 0;
      left: 0;
      background: #fff;
      position: absolute;
    }

    .ant-radio-group {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 32px;

      .ant-radio-button-wrapper {
        padding: 0;
        height: 58px;
        border: 0px;
        background-color: transparent;
        display: flex;
        align-items: center;

        span {
          .item-icon {
            margin-top: 8px;
            height: 24px;
            width: 24px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .item-text {
            margin-top: 5px;
            margin-bottom: 4px;
            padding: 0 10px;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: center;
            color: #acb1c0;
          }
        }
      }

      .ant-radio-button-wrapper:focus-within {
        box-shadow: none;
      }

      .ant-radio-button-wrapper::before {
        width: 0px;
      }
    }
  }
}

.dashicons-menu-wrapper {
  height: 48px;
  display: flex;
  align-items: center;
  // width: 66px;

  @media only screen and (max-width: '1024px') {
    width: auto;
  }

  .dashicon-main-icon-alignment {
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0 12px;

    @media only screen and (max-width: '1024px') {
      display: none;
    }
  }

  ul {
    li {
      padding: 0 0 !important;
      width: 24px;
      height: 24px;
      min-width: 24px;
      max-width: 24px;

      .ant-menu-submenu-title {
        display: block;
        align-items: center;
        height: 24px;
        padding: 0 0 !important;

        &:hover {
          color: var(--primary-500);
        }
      }
    }
  }

  .dashicons-all-icon-alignment {
    position: relative;

    img {
      width: 100%;
      height: 100%;
      display: block;
    }

    .home-alignment {
      @media only screen and (max-width: '1024px') {
        display: none;
      }

      &.with-out-login {
        background: transparent;
      }

      .home-icon-bottom-alignment {
        height: 2px;
        width: 10px;
        background: var(--gradient, linear-gradient(134deg, #556ee6 0%, #6b21a8 100%));
        margin: 5px auto 0 auto;
        transition: 0.02s ease-in-out;
        display: none;
      }

      &.activeShop {
        svg {
          rect {
            fill: #556ee6;
          }
        }

        .home-icon-bottom-alignment {
          display: block;
          transition: 0.02s ease-in-out;
        }
      }

      &:hover {
        svg {
          rect {
            fill: #556ee6;
          }
        }

        .home-icon-bottom-alignment {
          display: block;
          transition: 0.02s ease-in-out;
        }
      }

      .icon-alignment {
        display: flex;
        justify-content: space-between;
        align-items: center;

        gap: 8px;
        width: 24px;
        min-width: 24px;
        max-width: 24px;
        height: 24px;

        img {
          cursor: pointer;
        }
      }
    }

    .mobile-home-alignment {
      display: none;

      @media only screen and (max-width: '1024px') {
        display: block;
      }

      &.with-out-login {
        background: transparent;
      }

      .mobile-home-icon-bottom-alignment {
        height: 2px;
        width: 10px;
        background: var(--gradient, linear-gradient(134deg, #556ee6 0%, #6b21a8 100%));
        margin: 5px auto 0 auto;
        transition: 0.02s ease-in-out;
        display: none;
      }

      &.activeShop {
        svg {
          rect {
            fill: #556ee6;
          }
        }

        .mobile-home-icon-bottom-alignment {
          display: block;
          transition: 0.02s ease-in-out;
        }
      }

      &:hover {
        svg {
          rect {
            fill: #556ee6;
          }
        }

        .mobile-home-icon-bottom-alignment {
          display: block;
          transition: 0.02s ease-in-out;
        }
      }

      .icon-alignment {
        display: flex;
        justify-content: space-between;
        align-items: center;

        gap: 8px;
        width: 24px;
        min-width: 24px;
        max-width: 24px;
        height: 24px;

        img {
          cursor: pointer;
        }
      }
    }

    .dashboard-main-wrapper-alignment {
      position: fixed;
      width: 100%;
      height: calc(100vh - 66px);
      background: rgba(30, 36, 50, 0.4);
      // top: 66px;
      top: 104px;
      left: 0;
      z-index: 999;

      @media only screen and (max-width: '1024px') {
        display: none;
      }

      &.open-main-wraaper {
        opacity: 1;
        visibility: visible;
        transition: 0.3s;
      }

      &.close-main-wraaper {
        opacity: 0;
        visibility: hidden;

        transition: 0.3s;
      }
    }

    .dashbord-menu-box-alignment {
      position: absolute;
      max-width: 1276px;
      width: calc(100vw - 68px);
      z-index: 99999;
      top: 48px;
      left: -54px;
      transform: translateX(-16%);
      margin: 0 auto;

      @media only screen and (max-width: '1299px') {
        left: -8px;
        transform: translateX(-15%);
      }

      @media only screen and (max-width: '1249px') {
        left: -8px;
        transform: translateX(-16%);
      }

      @media only screen and (max-width: '1199px') {
        left: 0;
        transform: translateX(-17%);
      }

      @media only screen and (max-width: '1149px') {
        left: 0;
        transform: translateX(-18%);
      }

      @media only screen and (max-width: '1099px') {
        left: 0;
        transform: translateX(-19%);
      }

      @media only screen and (max-width: '1049px') {
        left: 0;
        transform: translateX(-20%);
      }

      @media only screen and (max-width: '1024px') {
        display: none;
      }

      &.open-menu-box {
        max-height: 575px;
        overflow-y: auto;
        transition: 0.3s ease-in-out;
        backdrop-filter: blur(52px);
      }

      &.close-menu-box {
        max-height: 0px;
        overflow-y: auto;
        transition: 0.3s ease-in-out;
        backdrop-filter: blur(52px);
      }

      .dashboard-border-alignment {
        border-radius: 0px 0px 16px 16px;
        border: 4px solid #fff;
        background: rgba(255, 255, 255, 0.9);
        backdrop-filter: blur(52px);

        .dashboard-menu-grid-alignment {
          display: grid;
          grid-template-columns: 268px 1fr;
          height: 100%;

          .dashboard-menu-grid-item {
            height: 100%;

            .dashboard-menu-sidebar-alignment {
              height: 100%;
              width: 100%;
              overflow-y: auto;

              .dashboard-menu-option {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                gap: 16px;
                padding: 0 0 0 16px;
                cursor: pointer;
                border-bottom: 1px solid #fff;

                &:last-child {
                  border-bottom: none;
                }

                p {
                  color: var(--secondary-900);
                  font-family: 'Poppins', sans-serif;
                  font-size: 18px;
                  font-weight: 500;
                  line-height: 24px;
                }

                .dashboard-menu-icon {
                  svg {
                    stroke: #1e2432 !important;
                  }
                }

                &:hover {
                  background: rgba(255, 255, 255, 0.6);
                  backdrop-filter: blur(52px);

                  &:first-child {
                    border-radius: 10px 0 0 0;
                  }
                }

                &.active-dashboard-menu {
                  background: rgba(255, 255, 255, 0.6);

                  // &:first-child {
                }
              }
            }

            .dashboard-right-side-alignement {
              width: 100%;
              height: 100%;
              overflow-y: auto;
              border-radius: 0px 0px 16px 0px;
              background: #fff;
              backdrop-filter: blur(52px);

              .right-side-top-details-alignment {
                padding: 24px 16px;

                .right-heading-alignment {
                  padding: 8px 16px;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  gap: 16px;

                  h6 {
                    color: var(--secondary-900);
                    font-family: 'Poppins', sans-serif;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 24px;
                  }

                  .header-icon {
                    cursor: pointer;
                  }
                }

                .right-side-menu-details-alignment {
                  padding: 21px 0;
                  height: 267px;

                  .right-side-menu-grid {
                    display: grid;
                    grid-template-columns: repeat(7, 1fr);
                    gap: 20px;

                    .right-side-menu-grid-item {
                      .all-menu-list-alignment {
                        a {
                          display: block;
                        }

                        h6 {
                          color: var(--secondary-900);
                          font-family: 'Poppins', sans-serif;
                          font-size: 14px;
                          font-weight: 600;
                          line-height: 21px;
                          padding: 0 16px 16px 16px;
                          white-space: nowrap;
                          overflow-x: hidden;
                          cursor: pointer;
                        }

                        .all-child-menu-list-alignment {
                          p {
                            color: var(--grey5);
                            font-family: 'Poppins', sans-serif;
                            font-size: 12px;
                            font-weight: 500;
                            line-height: 18px;
                            cursor: pointer;
                            padding: 7px 16px 4px 16px;
                            white-space: nowrap;
                            max-width: 120px;
                            overflow-x: hidden;
                            text-overflow: ellipsis;
                            margin: 0 0 5px 0;

                            &:hover {
                              border-radius: 8px;
                              background: var(--primary-50);
                              backdrop-filter: blur(52px);
                            }

                            &.active-category-name {
                              border-radius: 8px;
                              background: var(--primary-50);
                              backdrop-filter: blur(52px);
                            }
                          }

                          a {
                            &.link-alignment {
                              color: var(--primary-500);
                              font-family: 'Poppins', sans-serif;
                              font-size: 12px;
                              line-height: 18px;
                              cursor: pointer;
                              padding: 8px 16px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              .right-side-bottom-details-alignment {
                padding: 24px 32px;
                background: #fff;
                border-radius: 0 0 10px 0;

                .right-side-heading-alignment {
                  h6 {
                    color: var(--grey5);
                    font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 21px;
                  }
                }

                .bottom-imgs-alignment {
                  padding: 16px 0 0 0;

                  .bottom-imgs-grid {
                    display: grid;
                    grid-template-columns: repeat(9, 1fr);
                    gap: 14px;

                    .bottom-imgs-grid-item {
                      width: 100%;
                      height: 116px;

                      img {
                        width: 100%;
                        height: 100%;
                        border-radius: 10px;
                        display: block;
                        object-fit: cover;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .mobile-view-catgory-wrpper-alignment {
      position: fixed;

      top: 68px;
      left: 0;
      width: 100%;
      background: rgba(30, 36, 50, 0.6);
      z-index: 999;
      display: none;

      @media only screen and (max-width: '1024px') {
        display: block;
      }

      &.open-wrapper-alignmenbt {
        opacity: 1;
        transition: 0.3s ease-in-out;
        height: calc(100vh - 68px);
      }

      &.close-wrapper-alignmenbt {
        opacity: 0;
        transition: 0.3s ease-in-out;
      }

      .mobile-view-category-box-alignment {
        background: rgba(255, 255, 255, 0.9);
        backdrop-filter: blur(52px);
        width: 100%;

        &.open-category-box-alignmenbt {
          max-height: calc(100vh - 170px);
          overflow-y: auto;
          transition: 0.4s ease-in-out;
        }

        &.close-category-box-alignmenbt {
          max-height: 0;
          overflow-y: hidden;
          transition: 0.4s ease-in-out;
        }

        .mobile-view-main-category-alignment {
          padding: 16px 32px;
          border-bottom: 1px solid #fff;

          .mobile-view-link-alignment {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h4 {
              color: var(--secondary-900);
              font-size: 18px;
              font-weight: 500;
              line-height: 26px;
              font-family: 'Poppins', sans-serif;
            }

            .deactive-dropdown-icon {
              svg {
                transition: 0.4s ease-in-out;
                transform: rotate(0deg);
              }
            }

            .active-dropdown-icon {
              svg {
                transition: 0.4s ease-in-out;
                transform: rotate(-180deg);
              }
            }
          }

          .mobile-view-child-details-alignment {
            &.open-child-category-detils {
              max-height: 296px;
              overflow-y: auto;
              transition: 0.4s ease-in-out;
            }

            &.close-child-category-detils {
              max-height: 0px;
              overflow-y: hidden;
              transition: 0.4s ease-in-out;
            }

            .mobile-view-child-link-alignment {
              padding: 16px 12px 16px 18px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin: 0 0 4px 0;

              h6 {
                color: var(--secondary-900);
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-weight: 500;
                line-height: 24px;
              }
            }
          }
        }
      }
    }

    .second-mobile-view-modal-details-alignment {
      background: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(52px);
      height: calc(100vh - 145px);
      overflow-y: auto;
      // padding: 0 16px;
      position: fixed;
      top: 80px;
      left: 0;
      z-index: 999;
      width: 100%;

      &.open-second-modal {
        transform: translateX(0%);
        transition: 0.4s ease-in-out;
      }

      &.close-second-modal {
        transform: translateX(100%);
        transition: 0.4s ease-in-out;
      }

      .second-mobile-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 16px 8px 16px;
        position: sticky;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.9);
        backdrop-filter: blur(52px);
        z-index: 9999;

        h6 {
          color: var(--secondary-900);
          font-family: 'Poppins', sans-serif;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
        }

        a {
          color: var(--primary-500);
          text-align: right;
          font-family: 'Poppins', sans-serif;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
        }
      }

      .second-mobile-modal-body-alignment {
        padding: 12px 32px;

        .second-mobile-modal-body-grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 32px 16px;

          .second-mobile-modal-body-grid-item {
            .second-mobile-img {
              width: 80px;
              height: 80px;
              margin: 0 auto;

              img {
                width: 100%;
                height: 100%;
                border-radius: 80px;
                object-fit: cover;
              }
            }

            p {
              color: var(--secondary-900);
              text-align: center;
              font-family: 'Poppins', sans-serif;
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
}

.ant-menu-submenu.ant-menu-submenu-popup.ant-menu.ant-menu-light.ant-menu-submenu-placement-rightTop {
  top: 60px !important;

  .ant-menu.ant-menu-sub.ant-menu-vertical {
    border-radius: 0 0 10px 10px;

    .set-w-css {
      width: 1024px;
    }
  }
}

.ant-menu {
  .ant-menu-submenu.ant-menu-submenu-vertical.sub-menu-wrapperr.ant-menu-submenu-active {
    .ant-menu-submenu-title {
      color: var(--secondary-900);
      position: relative;
      height: 100%;

      .ant-menu-submenu-arrow {
        display: none;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: -2px;
        transform: translateY(-50%) rotate(45deg);
        width: 7px;
        height: 7px;
        background: #7787da;
        display: block;
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 4px;
        height: 30px;
        background: linear-gradient(to bottom, #556ee6, #6b21a8);
        display: block;
      }

      &:hover {
        background: transparent;
        color: var(--secondary-900);
      }
    }
  }
}

.ant-menu-title-content {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: var(--secondary-900);
  margin: 8px 0 0 0;
}

.hover-effect-on-image {
  position: relative;

  .hover-element-on-image {
    position: absolute;
    left: 10px;
    bottom: 10px;
    height: 94%;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: opacity 1s;

    .effect-on-wrapper-on-image {
      width: 26px;
      border-radius: 4px;
      overflow: hidden;
      margin-bottom: 5px;
      transition: width 0.5s;

      .effect-on-image {
        display: flex;
        align-items: center;
        width: max-content;
        height: 26px;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.4);
        font-size: 13px;
        color: white;
        font-weight: 400;
        padding: 0 5px;
        cursor: pointer;
        overflow: hidden;
        transition: background-color 0.5s;
      }
    }

    .effect-on-wrapper-on-image:hover {
      width: 130px;
      transition: width 0.5s;

      .effect-on-image {
        background-color: #ff7d23;
      }
    }

    .effect-on-wrapper-on-image-preview {
      width: 30px;
      border-radius: 4px;
      margin-bottom: 5px;
      transition: width 0.5s;

      &:hover {
        box-shadow: 0 2px 4px rgba(85, 110, 230, 0.8);
      }

      .effect-on-image {
        display: flex;
        align-items: center;
        width: 100%;
        height: 30px;
        border-radius: 4px;
        border: 1px solid;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.4);
        font-weight: 400;
        cursor: pointer;
        object-fit: contain;
        transition: background-color 0.5s;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: top;
          border-radius: 4px;
        }
      }
    }
  }

  .hover-info {
    opacity: 0;
    transition: opacity 1s;
  }
}

.hover-effect-on-image:hover {
  .hover-element-on-image {
    opacity: 1;
  }

  .hover-info {
    opacity: 1;
  }
}

.profile-setup-wrapper {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 3;

  .onboarding-popup {
    height: 50vh;
    width: 50vh;
    background: #fff;
    border-radius: 10px;
  }

  .profile-setup {
    height: 65vh;
    width: 58vw;
    border-radius: 10px;
    background: #fff;
    position: relative;
  }

  .custom-profile-setup {
    height: 80vh;
  }

  .first-section-wrap {
    height: 100%;
    width: 20%;
    border-radius: 10px 50px 50px 10px;
  }

  .second-section-wrap {
    height: 100%;
    width: 50%;
    background: #fff;
    border-radius: 10px 50px 50px 10px;
    z-index: 2;
  }

  .third-section-wrap {
    height: 100%;
    width: 35%;
    right: 0;
    position: absolute;
    border-radius: 0 8px 8px 0;
    background: var(--primary-500);

    img {
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
    }
  }
}

.mobile-product-store-details {
  display: none;

  @media only screen and (max-width: 767px) {
    width: 100%;
    display: block;
  }

  .save-delete-section {
    display: flex;
    transition: 0.3 ease-in-out;

    @media only screen and (max-width: 1024px) {
      flex-direction: row;
      align-items: center;
      gap: 16px;
    }

    .save-product-button:hover {
      border: 1px solid #556ee6;
      cursor: pointer;

      span {
        color: var(--primary-500);
      }
    }

    .trash-align:hover {
      border: 1px solid #e65555;
      cursor: pointer;

      svg {
        width: 20px;
        height: 20px;

        path {
          stroke: #e65555;
          stroke-width: 2px;
        }
      }
    }

    .save-product-button {
      width: 80% !important;
      height: 32px !important;
      background: #ffffff;
      border: 1px solid #e4e4e8;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: 767px) {
        width: 77px;
        height: 60px;
      }

      span {
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #acb1c0;

        @media only screen and (max-width: 767px) {
          justify-content: center;
          text-align: center;
          color: var(--primary-500);
        }
      }
    }

    .trash-align {
      width: 32px !important;
      height: 32px;
      background: #ffffff;
      border: 1px solid #e4e4e8;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: 767px) {
        width: 77px;
      }

      svg {
        width: 20px;
        height: 20px;

        path {
          stroke: #acb1c0;
        }
      }
    }
  }
}

.Women-accecccies-page-layout-alignment {
  padding: 20px 0;
  position: relative;

  @media (max-width: 1024px) {
    padding: 0;
  }

  .Backbuttonmain {
    position: fixed;
    top: 110px;
    // left: 66px;
    transform: translateX(230px);
    width: 43px;
    height: 43px;
    min-width: 43px;
    max-width: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #eef1fd;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.4s ease-in-out;

    @media (max-width: 1024px) {
      display: block;
    }
  }

  .Women-accecccies-page-layout {
    max-width: 1334px;
    background: rgb(255, 255, 255);
    margin: 0 auto;
    border: 2px solid rgb(238, 241, 253);
    border-radius: 16px;
    padding: 13px 17px;
    height: 100%;
    overflow-x: hidden;
    position: relative;

    @media (max-width: 576px) {
      padding: 0;
      border: none;
      border-radius: 0;
    }

    .womencatagoryslideralignment {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 0 0 14px 0;
      height: 142px;

      .category_slider {
        width: calc(100% - 82px - 82px);
        padding: 20px 0 20px 0;

        @media (max-width: 1024px) {
          width: 100%;
        }
      }

      .catagory_block {
        text-align: center;
        cursor: pointer;
        // @media only screen and (max-width:1199px) {
        //   padding: 0 20px;
        // }

        &:hover {
          p {
            color: #ab67fc;
          }

          .box_cover {
            border: 1px solid #a855f7;
          }

          .img_cover {
            transform: translateY(-5px);
          }
        }

        .box_cover {
          width: 105px;
          height: 105px;
          border-radius: 105px;
          padding: 5px;
          border: 1px solid transparent;
          margin: 0 auto;
          transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

          @media only screen and (max-width: 1199px) {
            width: 90px;
            height: 90px;
          }
        }

        .box {
          background: #ffc0cb;
          width: 100%;
          height: 100%;
          border-radius: 100px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;

          .img_cover {
            transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
          }
        }

        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: var(--secondary-900);
          margin-top: 10px;

          @media only screen and (max-width: 1199px) {
            text-align: center;
          }
        }
      }

      // @media (max-width: 1024px) {
      //   display: block;
      //   width: calc(100% + 17px + 17px);
      //   margin: 0 0 0 -17px;
      // }]\

      // .right_block_sc {
      //   width: calc(100% - 84px);
      //   padding: 0 10px;

      //   @media (max-width: 1024px) {
      //     width: 100%;
      //     padding: 0;
      //   }

      //   .right_block_cover_sc {
      //     width: 100%;

      //     .slick-arrow {
      //       transform: translate(0, -85%);

      //       @media only screen and (max-width: 1099px) {
      //         transform: translate(0, -56%);
      //       }
      //     }
      //   }
      // }
    }
  }
}

.men-accecccies-page-layout-alignment {
  padding: 20px 0;
  position: relative;

  .Backbuttonmain {
    position: fixed;
    top: 110px;
    // left: 66px;
    transform: translateX(230px);
    width: 43px;
    height: 43px;
    min-width: 43px;
    max-width: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #eef1fd;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.4s ease-in-out;

    @media (max-width: 1024px) {
      display: block;
    }
  }

  .men-accecccies-page-layout {
    max-width: 1334px;
    background: rgb(255, 255, 255);
    margin: 0 auto;
    border: 2px solid rgb(238, 241, 253);
    border-radius: 16px;
    padding: 13px 17px;
    height: 100%;
    overflow-x: hidden;
    position: relative;

    @media (max-width: 576px) {
      padding: 0;
      border: none;
      border-radius: 0;
    }

    .mencatagoryslideralignment {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 0 0 14px 0;
      height: 160px;
      background-color: #1e2432;
      border-radius: 16px 16px;

      @media (max-width: 576px) {
        height: 100%;
        border-radius: 0;
        display: none;
      }

      .category_slider {
        width: calc(100% - 82px - 82px);
        padding: 20px 0 20px 0;

        @media (max-width: 1024px) {
          width: 100%;
        }
      }

      .shop-by-cateogry-details-names {
        // padding: 0 8px;

        &:hover {
          cursor: pointer;

          .cover-alignment {
            border: 1px solid #556ee6;
          }

          .shop-by-product-name-alignment {
            // padding: 0;

            p {
              color: #f8f8f8;
              font-weight: 600;
              line-height: 27px;
              word-break: keep-all;
            }
          }
        }

        .cover-alignment {
          width: 110px;
          height: 110px;
          margin: auto;
          border: 1px solid transparent;
          background: #1e2432;
          padding: 6px;
          border-radius: 10px;

          @media only screen and (max-width: 899px) {
            width: 100%;
            height: 100%;
          }

          // &:hover {
          //     border: 1px solid #556EE6;
          //     border-radius: 10px;
          //     padding: 6px;
          //     cursor: pointer;
          // }

          .shop-by-category-img-aligment {
            // width: 98px;
            height: 98px;
            position: relative;

            @media only screen and (max-width: 899px) {
              height: 100%;
            }

            img {
              width: 100%;
              height: 100%;
              border-radius: 8px;
            }

            &::before {
              position: absolute;
              content: '';
              width: 100%;
              height: 100%;
              background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 70.41%);
              border-radius: 8px;
              top: 0;
              right: 0;
            }
          }
        }

        .shop-by-product-name-alignment {
          padding: 2px 0 0 0;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #acb1c0;
          }
        }
      }

      .active-class-for-category {
        cursor: pointer;

        .cover-alignment {
          border: 1px solid #556ee6;
        }

        .shop-by-product-name-alignment {
          // padding: 0;

          p {
            color: #f8f8f8;
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
          }
        }
      }
    }
  }
}

.mobile-view-main-padding-alignment {
  @media only screen and (max-width: '1024px') {
    // padding:64px 0;
    padding: 0 0 64px 0;
    // padding:0 0 15px 0;
    // height: 100vh;
  }

  @media only screen and (max-width: '576px') {
    padding: 0;
  }

  // max-width: 1334px;
  // background: rgb(255, 255, 255);
  // margin: 0 auto;
  // border: 2px solid rgb(238, 241, 253);
  // border-radius: 16px;
  // padding: 13px 17px;
  // height: 100%;
  // overflow-x: hidden;
  // position: relative;

  // @media (max-width:576px) {
  //   padding: 0;
  //   border: none;
  //   border-radius: 0;
  // }
}

.men-fashion-add-details-modal-alignment {
  background: #fff;
  height: calc(100vh - 96px);
  overflow-y: auto;
  position: relative;

  @media only screen and (max-width: '1099px') {
    height: 100vh;
  }

  .men-fashion-add-details-height-alignment {
    border-radius: 16px 0px 0px 0px;
    // padding: 16px;
    // height: 100vh;
    width: 100%;

    .men-fashion-banner-alignment {
      width: 100%;
      height: 319px;
      position: relative;

      @media only screen and (max-width: '1099px') {
        height: 178px;
      }

      .group-post-media-radious {
        border-radius: 16px;
      }

      .group-banner-radious {
        height: 319px;
        border-radius: 16px;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 10px 10px 0px 0px !important;
        object-fit: cover;

        @media only screen and (max-width: '1024px') {
          border-radius: 0px !important;
        }
      }

      svg {
        border-radius: 10px 10px 0 0;

        @media only screen and (max-width: '1024px') {
          border-radius: 0;
        }
      }

      // .men-group-profile-img-alignment {
      //   position: absolute;
      //   width: 230px;
      //   height: 165px;
      //   bottom: 16px;
      //   left: 94px;

      //   @media only screen and (max-width: '1099px') {
      //     display: none;
      //   }

      //   .group-profile-skeleton {
      //     border-radius: 16px;
      //     width: 230px;
      //     height: 165px;
      //   }

      //   img {
      //     width: 100%;
      //     height: 100%;
      //     border: 4px solid #ffffff;
      //     box-shadow: inset 0px 5px 40px rgba(117, 117, 118, 0.16);
      //     border-radius: 16px;
      //     object-fit: cover;
      //   }
      // }

      .men-group-option-alignment {
        position: absolute;
        right: 94px;
        bottom: 64px;

        @media only screen and (max-width: '1099px') {
          display: none;
        }

        .men-groupAdmin-box-alignment {
          background: rgba(30, 36, 50, 0.6);
          border-radius: 8px;
          padding: 6px 12px;
          display: flex;
          align-items: center;
          gap: 12px;
          cursor: pointer;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 120%;
            color: #ffffff;
          }

          .img-alignment {
            width: 30px;
            min-width: 30px;
            height: 30px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              border: 2px solid #ffffff;
              object-fit: contain;
            }
          }
        }

        .notigication-leave-alignment {
          margin: 11px 0 0 0;
          display: flex;
          align-items: center;
          gap: 13px;

          .notification-alignment {
            background: rgba(30, 36, 50, 0.6);
            border-radius: 8px;
            padding: 3px 9px;
            cursor: pointer;
          }

          .leave-group-alignment {
            padding: 5px 15px;
            background: rgba(30, 36, 50, 0.6);
            border-radius: 8px;
            cursor: pointer;

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 16px;
              line-height: 120%;
              color: #ffffff;
            }
          }
        }
      }

      .mobile-view-banner-option-alignment {
        position: absolute;
        top: 20px;
        right: 16px;
        left: 16px;
        // bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        display: none;

        @media only screen and (max-width: '767px') {
          display: flex;
        }

        .mobile-view-left-box-alignment {
          background: rgba(30, 36, 50, 0.6);
          border-radius: 10px;
          padding: 0 16px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-width: 100%;
            height: auto;
          }
        }
      }
    }

    .header-sticky {
      @media only screen and (max-width: '1099px') {
        display: none;
      }

      .men-tabDetails-alignment {
        background: var(--white);
        padding: 16px 94px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // position: sticky;
        // top: 0;
        // z-index: 999;
        // border-bottom: 8px solid #eceef4;
        height: 80px;

        .men-tab-left-side-alignment {
          display: flex;
          align-items: center;
          gap: 24px;
          height: 100%;

          .men-group-profile-img-alignment {
            // position: absolute;
            width: 174px;
            height: 125px;
            // bottom: 16px;
            // left: 94px;
            margin: -80px 0 0 0;

            @media only screen and (max-width: '1099px') {
              display: none;
            }

            .group-profile-skeleton {
              border-radius: 16px;
              width: 174px;
              height: 125px;
            }

            img {
              width: 100%;
              height: 100%;
              border: 8px solid #ffffff;
              box-shadow: inset 0px 5px 40px rgba(117, 117, 118, 0.16);
              border-radius: 16px;
              object-fit: cover;
            }
          }

          h4 {
            color: var(--secondary-900);
            font-size: 24px;
            font-weight: 600;
            line-height: 120%;
          }
        }

        .men-group-profile-right-side-alignment {
          display: flex;
          align-items: center;
          gap: 24px;

          .men-group-profile-option {
            display: flex;
            align-items: center;

            .men-group-profile-option-box {
              width: 84px;
              cursor: pointer;

              .men-group-new-tab-icon-alignment {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                margin: 0 auto;
              }

              p {
                color: #636773;
                text-align: center;
                font-size: 12px;
                font-weight: 500;
                line-height: 20px;
              }

              .men-group-hover-bottom-line {
                height: 2px;
                background: transparent;
                width: 100%;
              }

              transition: 0.4s ease-in-out;

              &:hover {
                transition: 0.4s ease-in-out;

                .men-group-hover-bottom-line {
                  display: block;
                  margin: 8px 0 0 0;
                  width: 50px;
                  background: #556ee6;
                  height: 2px;
                  margin: 0 auto;
                  border-radius: 2px;
                }

                p {
                  color: #556ee6;
                }

                .men-group-new-tab-icon-alignment {
                  svg {
                    path {
                      fill: #556ee6;
                    }
                  }
                }
              }

              &.activeTabAlignmet {
                transition: 0.4s ease-in-out;

                .men-group-hover-bottom-line {
                  display: block;
                  margin: 8px 0 0 0;
                  width: 50px;
                  background: #556ee6;
                  height: 2px;
                  margin: 0 auto;
                  border-radius: 2px;
                }

                p {
                  color: #556ee6;
                }

                .men-group-new-tab-icon-alignment {
                  svg {
                    path {
                      fill: #556ee6;
                    }
                  }
                }
              }
            }
          }

          .join-button-alignment {
            width: 133px;
            height: 32px;

            button {
              width: 100%;
              height: 100%;
              color: var(--white);
              font-size: 15px;
              font-weight: 500;
              line-height: 28px;
              cursor: pointer;
              border-radius: 8px;
              background: var(--primary-500);
            }
          }
        }

        h6 {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 24px;
          line-height: 120%;
          color: var(--secondary-900);
          white-space: nowrap;
        }

        .men-tab-alignment {
          width: 100%;
          height: 76px;
          background-color: #ffffff;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin: 0 auto;
          padding: 1.5rem 2.5rem;
          border-radius: 0.65rem;
          overflow: hidden;
          // ul {
          //   display: flex;

          //   li {
          //     .tab-details-alignment {
          //       width: 84px;
          //       height: 76px;
          //       display: flex;
          //       flex-direction: column;
          //       align-items: center;
          //       justify-content: center;
          //       cursor: pointer;

          //       &:hover {
          //         .tab-img-alignment {
          //           .disable-icon {
          //             display: none;
          //           }

          //           .tab-active-icon {
          //             display: block;
          //           }
          //         }

          //         .tab-name-alignment {
          //           p {
          //             color: var(--secondary-900);
          //             font-weight: 600;
          //           }
          //         }
          //       }

          //       &.active-tab {
          //         .tab-img-alignment {
          //           .disable-icon {
          //             display: none;
          //           }

          //           .tab-active-icon {
          //             display: block;
          //           }
          //         }

          //         .tab-name-alignment {
          //           p {
          //             color: var(--secondary-900);
          //             font-weight: 600;
          //           }
          //         }
          //       }

          //       .tab-img-alignment {
          //         padding: 0 0 3px 0;

          //         .tab-active-icon {
          //           display: none;
          //         }
          //       }

          //       .tab-name-alignment {
          //         p {
          //           font-family: 'Poppins', sans-serif;
          //           font-size: 13px;
          //           line-height: 20px;
          //           font-weight: 500;
          //           color: #acb1c0;
          //         }
          //       }
          //     }
          //   }
          // }

          .men-tab-alignment__list {
            position: relative;
            display: flex;
            justify-content: space-between;
            width: 29%;
            z-index: 99;
            height: 100%;
            top: 11px;
          }

          &__list {
            position: relative;
            width: 29%;
            display: flex;
            justify-content: center;
            justify-content: space-between;
            column-gap: 60px;
          }

          &__link {
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          &__name {
            position: absolute;
            transition: 0.4s;
            transform: translateY(28px);
            z-index: 20;
            // opacity: 0;
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #acb1c0;
          }

          .men-tab-indicator {
            position: absolute;
            left: -68px;
            fill: var(--first-color);
            transition: 0.3s;
            top: -27px;
            // bottom: 0;
          }

          .men-tab-alignment__link {
            height: 100%;
            z-index: 99;
            position: relative;
            left: -44px;
            top: -8px;

            @media only screen and (max-width: '1499px') {
              left: -66px;
            }

            &:hover {
              svg {
                path {
                  fill: #000000;
                }
              }

              .men-tab-alignment__name {
                opacity: 1;
                transform: translateY(28px);
                color: var(--secondary-900);
                font-weight: 600;
              }
            }
          }

          .men-tab-alignment__link:hover {
            opacity: 1;
            transform: translateY(-5px);
          }

          .men-tab-active-link .men-tab-alignment__name {
            opacity: 1;
            transform: translateY(28px);
            color: var(--secondary-900);
            font-weight: 600;
          }

          .men-tab-active-link {
            top: -8px;

            @media only screen and (max-width: '1499px') {
              top: -10px;
            }
          }

          .men-tab-alignment__link:nth-child(1).men-tab-active-link ~ .men-tab-indicator {
            transform: translateX(-24px);

            @media only screen and (max-width: '1499px') {
              transform: translateX(-48px);
            }
          }

          .men-tab-alignment__link:nth-child(2).men-tab-active-link ~ .men-tab-indicator {
            transform: translateX(calc(57px * 1));

            @media only screen and (max-width: '1499px') {
              transform: translateX(33px);
            }
          }

          .men-tab-alignment__link:nth-child(3).men-tab-active-link ~ .men-tab-indicator {
            transform: translateX(144px);

            @media only screen and (max-width: '1499px') {
              transform: translateX(118px);
            }
          }

          .men-tab-alignment__link:nth-child(4).men-tab-active-link ~ .men-tab-indicator {
            transform: translateX(calc(75px * 3));

            @media only screen and (max-width: '1499px') {
              transform: translateX(200px);
            }
          }

          .men-tab-alignment__link:nth-child(5).men-tab-active-link ~ .men-tab-indicator {
            transform: translateX(calc(75px * 4));
          }
        }
      }
    }

    .club-mobile-view-heading-section {
      display: none;

      @media only screen and (max-width: '767px') {
        display: flex;
        padding: 20px 16px 6px 16px;
        background: rgba(248, 248, 248, 0.92);
        backdrop-filter: blur(13.5914px);
        align-items: center;
        justify-content: space-between;

        .store-back_details-alignment {
          background: #ffffff;
          border-radius: 10px;
          padding: 4px 9px 4px 10px;
          display: flex;
          align-items: center;
          gap: 8px;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #808191;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 58px;
          }
        }

        .store-mobile-header-alignment {
          display: flex;
          align-items: center;
          gap: 4px;

          .mobile-profile-img-alignment {
            width: 34px;
            height: 24px;

            img {
              width: 100%;
              height: 100%;
              box-shadow: inset 0px 0.724373px 5.79499px rgba(117, 117, 118, 0.16);
              border-radius: 2.29196px;
              object-fit: cover;
            }
          }

          .mobile-profile-name-alignment {
            h5 {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 18px;
              line-height: 120%;
              color: var(--secondary-900);
            }
          }
        }

        .store-more-option-alignment {
          width: 44px;
          height: 32px;
          background: #ffffff;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .mobile-view-club-tab-alignment {
      display: none;

      @media only screen and (max-width: '1099px') {
        display: flex;
        margin: -4px 0 8px 0;
        background: #ffffff;
        padding: 8px 16px;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        overflow-x: auto;

        ul {
          display: flex;
          align-items: center;

          li {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 14px 16px;

            // &:hover {
            //   background: var(--primary-50);
            //   border-radius: 8px;

            //   p {
            //     color: var(--primary-700);
            //   }
            // }

            &.active-club-tab {
              background: var(--primary-50);
              border-radius: 8px;

              p {
                color: var(--primary-700);
              }
            }

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 13px;
              line-height: 20px;
              color: var(--secondary-600);
            }
          }
        }
      }
    }

    .men-fashin-main-details-alignment {
      padding: 16px 94px 0 94px;
      background: var(--white);

      @media only screen and (max-width: '1024px') {
        padding: 0;
        background: transparent;
      }

      .men-fashion-details-flex-alignment {
        display: grid;
        grid-template-columns: 412px 1fr;
        // gap: 16px;
        gap: 15px;

        @media only screen and (max-width: '1099px') {
          display: block;
        }

        // align-items: center;
        .men-fashion-first-part-alignment {
          width: 412px;
          min-width: 412px;
          max-width: 412px;

          @media only screen and (max-width: '1099px') {
            width: 100%;
            min-width: 100%;
            max-width: 100%;
          }

          .men-fashion-sticky-alignment {
            position: sticky;
            // top: 86px;
            top: 60px;

            @media only screen and (max-width: '1099px') {
              position: relative;
              top: 12px;
            }

            .about-group-alignment {
              border-radius: 16px;
              background: #f3f5f8;
              padding: 20px 16px;

              @media only screen and (max-width: '1099px') {
                display: none;
              }

              h5 {
                color: var(--secondary-900);
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.1px;
                padding: 0 0 10px 0;
              }

              p {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;

                color: var(--secondary-900);
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 0.1px;
              }
            }

            .mobile-view-club-details-alignment {
              display: none;

              @media only screen and (max-width: '1099px') {
                display: block;
                background: #ffffff;
                border-radius: 16px 16px 0px 0px;
                padding: 18px 16px;
                position: relative;
                top: -24px;
                width: 100%;

                .mobile-view-club-flex-alignment {
                  display: flex;
                  align-items: flex-start;
                  justify-content: space-between;

                  .mobile-view-club-profile-alignment {
                    display: flex;
                    align-items: center;
                    gap: 16px;

                    .mobile-view-img-alignment {
                      width: 90px;
                      height: 65px;

                      img {
                        width: 100%;
                        height: 100%;
                        border-radius: 6.20606px;
                        object-fit: cover;
                      }
                    }

                    .mobile-view-club-child-details-alignment {
                      h4 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 120%;
                        color: #000000;
                      }

                      .mobile-memeber-details-alignment {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        padding: 4px 0 0 0;

                        span {
                          font-family: 'Poppins', sans-serif;
                          font-weight: 400;
                          font-size: 12px;
                          line-height: 18px;
                          color: var(--secondary-500);
                        }
                      }
                    }
                  }

                  .mobile-view-notification-alignment {
                    width: 32px;
                    min-width: 32px;
                    max-width: 32px;
                    height: 32px;
                    border: 1.25px solid var(--secondary-100);
                    border-radius: 7.27273px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                }

                .mobile-view-button-alignment {
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  padding: 16px 0 0 0;

                  .join-club-button-alignment {
                    width: 100%;

                    button {
                      width: 100%;
                      background: var(--primary-500);
                      border-radius: 8px;
                      height: 32px;
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 15px;
                      line-height: 20px;
                      color: #ffffff;
                      cursor: pointer;
                    }
                  }

                  .mobile-view-more-option-alignment {
                    border: 1.25px solid var(--secondary-100);
                    border-radius: 7.27273px;
                    width: 32px;
                    height: 32px;
                    min-width: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                }

                .mobile-view-about-club-details-alignment {
                  padding: 16px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--secondary-900);
                  }

                  p {
                    padding: 8px 0 0 0;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #495057;

                    a {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 15px;
                      line-height: 20px;
                      color: var(--primary-500);
                    }
                  }
                }

                .mobile-view-admin-details-alignment {
                  padding: 16px 0 0 0;

                  h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--secondary-900);
                  }

                  .mobile-view-admin-all-details-alignment {
                    padding: 8px 0 0 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .mobile-view-admin-profile-details-alignment {
                      display: flex;
                      align-items: center;
                      gap: 8px;

                      .mobile-view-profile-img-alignment {
                        width: 32px;
                        min-width: 32px;
                        max-width: 32px;
                        height: 32px;

                        img {
                          width: 100%;
                          height: 100%;
                          border-radius: 50%;
                          object-fit: cover;
                        }
                      }

                      .mobile-admin-profile-name-alignment {
                        h6 {
                          font-family: 'Poppins', sans-serif;
                          font-weight: 600;
                          font-size: 14px;
                          line-height: 21px;
                          color: var(--secondary-900);
                        }

                        p {
                          font-family: 'Poppins', sans-serif;
                          font-weight: 400;
                          font-size: 12px;
                          line-height: 18px;
                          color: #acb1c0;
                        }
                      }
                    }

                    .mobile-follow-button-alignment {
                      button {
                        padding: 2px 10px;
                        background: var(--primary-500);
                        border-radius: 6px;
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 20px;
                        text-align: center;
                        color: #ffffff;
                        cursor: pointer;

                        &.following {
                          border: 1px solid #556ee6;
                          padding: 2px 10px;
                          background: #ffffff;
                          border-radius: 6px;
                          font-family: 'Poppins', sans-serif;
                          font-weight: 500;
                          font-size: 13px;
                          line-height: 20px;
                          text-align: center;
                          color: var(--primary-500);

                          &:hover {
                            background: #eef1fc;
                          }
                        }
                      }
                    }
                  }
                }

                .mobile-view-notes-alignment {
                  display: flex;
                  align-items: center;
                  gap: 4px;
                  padding: 16px 0 0 0;

                  .mobile-view-notes-person-img-alignment {
                    display: flex;
                    align-items: center;

                    .img-layout-alignment {
                      margin: 0 0 0 -10px;

                      &:first-child {
                        margin: 0;
                      }

                      .mobile-view-img-alignment {
                        width: 20px;
                        height: 20px;

                        img {
                          width: 100%;
                          height: 100%;
                          border-radius: 50%;
                          object-fit: cover;
                          border: 1px solid #ffffff;
                        }
                      }
                    }
                  }

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: var(--secondary-500);
                  }
                }
              }
            }

            .newest-members-section {
              border-radius: 16px;
              background: #f3f5f8;
              padding: 16px;
              margin: 8px 0 0 0;

              @media only screen and (max-width: '1099px') {
                display: none;
              }

              .newest-members-header-alignment {
                display: flex;
                align-items: center;
                justify-content: space-between;

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  letter-spacing: 0.1px;
                  color: #000000;
                }

                a {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;
                  letter-spacing: 0.1px;
                  color: var(--primary-500);
                  cursor: pointer;
                }
              }

              .member-details-silder-alignment {
                margin: 23px 0 0 0;

                .slick-arrow {
                  display: none !important;
                }

                .memeber-details-alignment {
                  padding: 0 16px;

                  .member-profile-img-alignment {
                    width: 100px;
                    height: 100px;
                    margin: auto;

                    img {
                      width: 100%;
                      height: 100%;
                      border-radius: 50%;
                      object-fit: cover;
                    }
                  }

                  .memeber-child-details-alignment {
                    h5 {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 16px;
                      line-height: 24px;
                      color: #000000;
                      padding: 0 0 4px 0;
                      text-align: center;
                      white-space: nowrap;
                      cursor: pointer;
                    }

                    p {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 21px;
                      text-align: center;
                      color: #808191;
                    }

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 21px;
                      text-align: center;
                      color: #808191;
                      display: block;
                    }

                    .follow-button-alignment {
                      padding: 11px 0 0 0;

                      button {
                        background: var(--primary-500);
                        border-radius: 8px;
                        width: 100%;
                        padding: 6px 0;
                        cursor: pointer;
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 21px;
                        color: #f8f8f8;

                        &.following {
                          background: #f8f8f8;
                          color: var(--primary-500);

                          &:hover {
                            background: #eef1fc;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            .recent-activity-alignment {
              background: #ffffff;
              border-radius: 16px;
              padding: 24px 32px;
              margin: 19px 0 0 0;

              @media only screen and (max-width: '1099px') {
                display: none;
              }

              h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.1px;
                color: #000000;
              }

              .recent-details-main-section {
                margin: 24px 0 0 0;
                margin: 24px 0 0 0;
                height: auto;
                max-height: 160px;
                overflow-y: auto;

                .recent-box-alignment {
                  padding: 8px;
                  border: 1px solid #f1f1f5;
                  border-radius: 10px;
                  display: flex;
                  align-items: flex-start;
                  gap: 8px;
                  margin: 0 0 10px 0;

                  &:last-child {
                    margin: 0;
                  }

                  .recent-profile-img-alignment {
                    width: 24px;
                    max-width: 24px;
                    min-width: 24px;
                    height: 24px;

                    img {
                      width: 100%;
                      height: 100%;
                      border-radius: 50%;
                    }
                  }

                  .recent-profile-details-alignment {
                    .recent-name-comment-alignment {
                      display: flex;
                      align-items: center;
                      gap: 3px;

                      h4 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 21px;
                        color: #495057;
                      }

                      .comment-alignment {
                        p {
                          font-family: 'Poppins', sans-serif;
                          font-weight: 400;
                          font-size: 12px;
                          line-height: 18px;
                          color: #495057;

                          span {
                            font-weight: 600;
                          }
                        }
                      }
                    }

                    .main-comment-alignment {
                      padding: 4px 0 0 0;

                      p {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                        color: #495057;
                      }
                    }

                    .time-alignment {
                      padding: 8px 0 0 0;

                      span {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                        color: #808191;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .men-web-view-tab-alignment {
          display: none;

          @media only screen and (max-width: '1024px') {
            display: block;
          }
        }

        .men-second-part-alignment {
          // width: 60%;
          width: 100%;
          max-width: 100%;
          min-width: 100%;
          // margin: 0 0 0 15px;

          @media only screen and (max-width: '1099px') {
            width: 100%;
          }

          .post-somthing-section {
            @media only screen and (max-width: '1099px') {
              display: none;
            }
          }

          .member-details-alignment {
            background: #ffffff;
            border-radius: 16px;
            padding: 16px 32px;

            @media only screen and (max-width: '1099px') {
              padding: 16px;
              border-radius: 0;
            }

            .heading-alignment {
              @media only screen and (max-width: '1099px') {
                display: none;
              }

              h3 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                color: #000000;
              }
            }

            .mobile-header-alignment {
              display: flex;
              align-items: center;
              justify-content: space-between;
              display: none;
              // padding: 16px 0;

              @media only screen and (max-width: '1099px') {
                display: flex;
              }

              .mobile-header-name-alignment {
                display: flex;
                align-items: center;
                gap: 4px;

                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 30px;
                  color: var(--secondary-900);
                }

                .total-member-alignment {
                  padding: 0 8px;
                  background: #eceef4;
                  border-radius: 10px;

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 20px;
                    color: var(--secondary-500);
                  }
                }
              }

              .mobile-dropdwn-alignment {
                border: 1.25px solid var(--secondary-100);
                border-radius: 8px;
                height: 32px;
                padding: 0 16px;
                display: flex;
                align-items: center;
                gap: 2px;

                span {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 15px;
                  line-height: 20px;
                  color: var(--secondary-600);
                }
              }
            }

            .member-all-details-alignment {
              margin: 16px 0 0 0;

              .member-details-box-alignment {
                background: #ffffff;
                border-radius: 10px;
                padding: 11px 16px;
                box-shadow: 6px 6px 54px rgb(0 0 0 / 5%);
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0 0 8px 0;

                &.owner {
                  @media only screen and (max-width: '767px') {
                    border-bottom: 2px solid #eceef4;
                    border-radius: 0;
                  }
                }

                @media only screen and (max-width: '1099px') {
                  box-shadow: none;
                  margin: 0;
                  padding: 8px 0;
                }

                &:last-child {
                  margin: 0;
                }

                .member-profile-alignment {
                  display: flex;
                  align-items: center;
                  gap: 16px;

                  .profile-alignment {
                    width: 40px;
                    min-width: 40px;
                    max-width: 40px;
                    height: 40px;

                    img {
                      width: 100%;
                      height: 100%;
                      border-radius: 50%;
                      object-fit: cover;
                    }
                  }

                  .profile-name-alignment {
                    h4 {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 16px;
                      line-height: 24px;
                      letter-spacing: 0.1px;
                      color: var(--secondary-900);
                      padding: 0 0 2px 0;
                    }

                    .child-details-alignment {
                      display: flex;
                      align-items: center;
                      gap: 8px;

                      span {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 20px;
                        letter-spacing: 0.1px;
                        color: #92929d;
                      }

                      p {
                        width: 2px;
                        height: 2px;
                        border-radius: 50%;
                        background: #808191;
                      }
                    }
                  }
                }

                .follow-button-alignment {
                  button {
                    width: 113px;
                    height: 32px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    color: #f8f8f8;
                    cursor: pointer;
                    background: var(--primary-500);
                    border-radius: 8px;

                    &.following {
                      background: #f8f8f8;
                      color: var(--primary-500);

                      &:hover {
                        background: #eef1fc;
                      }
                    }
                  }
                }
              }
            }
          }

          .media-section {
            background: #ffffff;
            padding: 32px;
            border-radius: 16px;

            @media only screen and (max-width: '1099px') {
              padding: 16px;
            }

            .mobile-view-club-media-details-alignment {
              display: none;

              @media only screen and (max-width: '767px') {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .media-details-alignment {
                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: var(--secondary-500);
                  }
                }

                .media-photo-video-dropdown-alignment {
                  display: flex;
                  align-items: center;
                  gap: 12px;
                  border: 1.5px solid var(--secondary-100);
                  border-radius: 8px;
                  padding: 6px 16px;

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 20px;
                    color: var(--secondary-600);
                  }
                }
              }
            }

            .media-details-grid-alignment {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              gap: 7px;

              @media only screen and (max-width: '1099px') {
                padding: 16px 0 0 0;
              }

              .media-img-alignment {
                img {
                  width: 100%;
                  height: 194px;
                  display: block;

                  @media only screen and (max-width: '1099px') {
                    height: 111px;
                    border-radius: 8px;
                  }
                }
              }
            }
          }

          .mobile-view-no-feed-alignment {
            display: none;

            @media only screen and (max-width: '1099px') {
              background: #ffffff;
              border: 1px solid #e4e4e8;
              border-radius: 10px 10px 0px 0px;
              height: calc(100vh - 136px);
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 94px;

              .mobile-view-no-data-text-alignment {
                text-align: center;

                span {
                  font-family: 'Poppins', sans-serif;
                  font-style: italic;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  text-align: center;
                  color: var(--secondary-300);
                }
              }
            }
          }

          .club-mobile-view-media-no-data-modal-alignment {
            height: calc(100vh - 205px);
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 100px;
            justify-content: center;

            .club-media-no-data-name-alignment {
              span {
                font-family: 'Poppins', sans-serif;
                font-style: italic;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: var(--secondary-300);
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

.club-mobile-view-heading-section {
  display: none;

  @media only screen and (max-width: '767px') {
    display: flex;
    padding: 20px 16px 6px 16px;
    background: rgba(248, 248, 248, 0.92);
    backdrop-filter: blur(13.5914px);
    align-items: center;
    justify-content: space-between;

    .store-back_details-alignment {
      background: #ffffff;
      border-radius: 10px;
      padding: 4px 9px 4px 10px;
      display: flex;
      align-items: center;
      gap: 8px;

      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #808191;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 58px;
      }
    }

    .store-mobile-header-alignment {
      display: flex;
      align-items: center;
      gap: 4px;
      // padding: ;

      .mobile-profile-img-alignment {
        width: 34px;
        height: 24px;

        img {
          width: 100%;
          height: 100%;
          box-shadow: inset 0px 0.724373px 5.79499px rgba(117, 117, 118, 0.16);
          border-radius: 2.29196px;
          object-fit: cover;
        }
      }

      .mobile-profile-name-alignment {
        h5 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 18px;
          line-height: 120%;
          color: var(--secondary-900);
        }
      }
    }

    .store-more-option-alignment {
      width: 44px;
      height: 32px;
      background: #ffffff;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.clubAbout-more-details-alignment {
  margin: 0 0 16px 0;
  display: flex;
  align-items: center;
  gap: 26px;

  @media only screen and (max-width: '1024px') {
    display: none;
  }

  .clubAbout-media-img-alignment {
    display: flex;
    align-items: center;

    .club-profile-img {
      width: 40px;
      height: 40px;
      min-width: 40px;
      max-width: 40px;
      margin: 0 -13px 0 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
        border: 2px solid var(--white);
      }
    }
  }

  .clubAbout-notes-alignment {
    max-width: 217px;

    p {
      color: var(--secondary-900);
      font-size: 11px;
      font-weight: 400;
      line-height: 14px;

      span {
        font-weight: 600;
      }
    }
  }
}

.mobile-view-container {
  @media only screen and (max-width: '767px') {
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 100% !important;
  }
}

.container-mobile-view-padding {
  padding: 0 !important;
}

.menu-section {
  .menu-banner-alignment {
    height: 186px;
    position: relative;

    .bg-alignment {
      height: 100%;
      position: relative;

      &::before {
        position: absolute;
        content: '';
        background: linear-gradient(360deg, rgba(30, 36, 50, 0.8) 0%, rgba(30, 36, 50, 0) 100%);
        border-radius: 0px;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 120px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }

      .uploadimageuseerbg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        border-radius: 50%;
        overflow: hidden;
        z-index: 9;

        input {
          cursor: pointer;
          width: 100%;
          height: 100%;
        }
      }
    }

    .menu-profile-name-alignment {
      position: absolute;
      bottom: 12px;
      left: 12px;
      right: 16px;

      .back-box-alignment {
        width: 32px;
        min-width: 32px;
        max-width: 32px;
        height: 32px;
        background: #ffffff;
        border-radius: 10px;
        margin: 0 0 54px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 10;
      }

      .profile-details-alignment {
        display: flex;
        align-items: flex-end;
        gap: 12px;

        .profile-img-alignment {
          width: 76px;
          max-width: 76px;
          min-width: 76px;
          height: 76px;
          position: relative;
          z-index: 10;

          img {
            width: 100%;
            height: 100%;
            border: 2px solid #f8f8f8;
            border-radius: 50%;
            object-fit: cover;
          }

          .uploadimageuseerprofile {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            border-radius: 50%;
            overflow: hidden;
            z-index: 9;

            input {
              cursor: pointer;
              width: 100%;
              height: 100%;
            }
          }
        }

        .profile-name-details-alignment {
          position: relative;
          z-index: 10;

          h6 {
            font-family: 'Poppins', sans-serif;
            font-weight: 700;
            font-size: 17px;
            line-height: 26px;
            color: #ffffff;
            padding: 0 0 8px 0;
          }

          .profile-followers-following-alignment {
            display: flex;
            align-items: center;
            gap: 33px;

            .profile-child-details-alignment {
              display: flex;
              align-items: center;
              gap: 4px;

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 15px;
                line-height: 22px;
                color: #ffffff;
              }

              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 15px;
                line-height: 22px;
                color: rgba(236, 238, 244, 0.5);
              }
            }
          }
        }
      }
    }
  }

  .menu-details-section {
    background: #ffffff;

    ul {
      margin: 4px 0;

      li {
        border-bottom: 1px solid rgba(228, 228, 232, 0.7);

        .menu-list-details-alignment {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px 16px;
          box-shadow: 0px 5px 50px rgba(163, 156, 169, 0.15);

          &:last-child {
            border-bottom: none;
          }

          .menu-icon-name-alignment {
            display: flex;
            align-items: center;
            gap: 16px;

            .icon-alignment {
              padding: 8px;
              border-radius: 10px;
              background: #eef1fc;

              img {
                width: 30px;
                height: 30px;
                display: block;
              }

              .shop-more-option-icon {
                padding: 4px;

                .home-icon-bottom-alignment {
                  height: 2px;
                  width: 10px;
                  background: var(--gradient, linear-gradient(134deg, #556ee6 0%, #6b21a8 100%));
                  margin: 5px auto 0 auto;
                  transition: 0.02s ease-in-out;
                  display: none;
                }

                &:hover {
                  svg {
                    rect {
                      fill: #556ee6;
                    }
                  }

                  .home-icon-bottom-alignment {
                    display: block;
                    transition: 0.02s ease-in-out;
                  }
                }
              }
            }

            h5 {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #495057;
            }
          }

          // .drop-down-icon-alignment {}

          .icon-rotate-stable {
            transform: rotate(180deg);
          }

          .transition-icon {
            transition: 0.3s ease-in-out;
          }
        }

        .tab-show-area {
          max-height: 270px;
          overflow: hidden;
          transition: 0.3s ease-in-out;
          margin: 16px 0 0 0;
        }

        .tab-hidden-area {
          max-height: 0px;
          overflow: hidden;
          transition: 0.3s ease-in-out;
        }

        .menu-list-option-alignment {
          // padding: 12px 0;
          padding: 0 16px;

          .menu-child-option-alignment {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 16px;

            .menu-child-box-alignment {
              background: #f8f8f8;
              height: 121px;
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 16px;
              align-items: center;
              justify-content: center;

              .menu-child-icon-alignment {
                img {
                  width: 40px;
                  height: 40px;
                }
              }

              .name-alignments {
                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: #495057;
                }
              }
            }
          }
        }
      }

      .first-li-border {
        border: none;

        .transition-icon {
          box-shadow: rgb(20 20 20 / 16%) 0px 8px 12px;
        }
      }
    }
  }

  .menu-setting-alignment {
    margin: 16px 0 0 0;
    background: #ffffff;

    ul {
      // padding: 8px 0;

      li {
        padding: 8px 32px 8px 62px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #495057;
        border-bottom: 1px solid rgba(228, 228, 232, 0.7);
        cursor: pointer;

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          background: var(--primary-50);
          border-radius: 7.27273px;
        }
      }
    }
  }

  .menu-logout-alignment {
    margin: 16px 0 0 0;
    background: #ffffff;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px;

    .icon-alignment {
      min-width: 30px;
      width: 30px;
      max-width: 30px;
      height: 30px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    span {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      color: #495057;
    }
  }
}

.cus-container-md {
  max-width: calc(1285px + 40px);
  padding: 0 20px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 0 20px;
  }

  @media (max-width: 576px) {
    padding: 0 20px;
  }
}

.cus-container-md-1 {
  max-width: calc(1052px + 40px);
  padding: 0 20px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 0 60px;
  }

  @media (max-width: 576px) {
    padding: 0 20px;
  }
}

.cus-container-md-2 {
  max-width: calc(1235px + 40px);
  padding: 0 20px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 0 60px;
  }

  @media (max-width: 576px) {
    padding: 0 20px;
  }
}

@media screen and (max-width: 320px) {
  .nav {
    zoom: 0.8;
  }
}

@media screen and (min-width: 968px) {
  .container {
    margin-left: auto;
    margin-right: auto;
  }

  .nav {
    zoom: 1.1;
  }
}

.main-body {
  max-width: 1334px;
  background: rgb(255, 255, 255);
  margin: 19px auto;
  border: 2px solid rgb(238, 241, 253);
  border-radius: 16px;
  padding: 13px 17px;
  height: 100%;
  position: relative;

  @media (max-width: 576px) {
    padding: 0;
    border: none;
    border-radius: 0;
    // margin: 68px auto 19px auto;
  }

  .Womenbackbuttton {
    width: 43px;
    height: 43px;
    min-width: 43px;
    max-width: 43px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--primary-50);
    position: fixed;
    top: 80px;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.4s ease-in-out;
    transform: translateX(-80px);

    &.WomenbottomBox {
      top: 110px;
      transition: 0.4s ease-in-out;
    }
  }

  // &::before {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   content: "";
  //   background-color: rgb(255, 255, 255);
  //   height: 13px;
  // }

  // &::after {
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   content: "";
  //   background-color: rgb(255, 255, 255);
  //   height: 13px;
  // }
}

.topToDown {
  transform: translateY(-100%);
  transition: 0.3s ease-in-out;
}

.downToTop {
  width: 100%;
  transform: translateY(0%);
  transition: 0.3s ease-in-out;
  position: fixed;
  top: 66px;
  z-index: 9;
  background: linear-gradient(
    90deg,
    rgba(98, 93, 245, 0.1) 0.08%,
    rgba(98, 93, 245, 0.03) 16.53%,
    rgba(98, 93, 245, 0.03) 49.06%,
    rgba(98, 93, 245, 0.03) 83.21%,
    rgba(98, 93, 245, 0.1) 99.99%
  );
  -webkit-backdrop-filter: blur(19.596906662px);
  backdrop-filter: blur(19.596906662px);
  transition: 0.3s ease-in-out;
  padding: 10px 0;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topToDownsc {
  transform: translateY(-100%);
  transition: 0.3s ease-in-out;
}

.downToTopsc {
  width: 100%;
  transform: translateY(0%);
  transition: 0.3s ease-in-out;
  position: fixed;
  top: 46px;
  z-index: 9;
  background: linear-gradient(
    90deg,
    rgba(98, 93, 245, 0.1) 0.08%,
    rgba(98, 93, 245, 0.03) 16.53%,
    rgba(98, 93, 245, 0.03) 49.06%,
    rgba(98, 93, 245, 0.03) 83.21%,
    rgba(98, 93, 245, 0.1) 99.99%
  );
  -webkit-backdrop-filter: blur(19.596906662px);
  backdrop-filter: blur(19.596906662px);
  transition: 0.3s ease-in-out;
  padding: 10px 0;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-grid {
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 20px;

  .mobile-view-sidebar-box-alignment {
    background: #f8f8f8;
    overflow: auto;
    height: calc(100vh - 100px);
    position: sticky;
    top: 100px;

    .mobile-silder-side-main-details {
      padding: 18px 16px;

      .mobile-slider-all-details-alignment {
        margin: 0 0 16px 0;

        &:last-child {
          margin: 0;
        }

        .mobile-silder-main-box-alignmrnt {
          width: 72px;
          height: 72px;
          border: 1px solid transparent;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          &.active-women-category {
            border: 1px solid #a855f7;

            .mobile-slider-details-img {
              background: #ffffff;

              img {
                width: 100%;
                height: 100%;
                display: block;
              }
            }

            p {
              font-weight: 600;
            }
          }

          .mobile-slider-details-img {
            width: 62px;
            height: 62px;
            border-radius: 8px;
            position: relative;
            margin: auto;
            background: #ffffff;
            border-radius: 50%;
            padding: 2px;

            img {
              width: 100%;
              height: 100%;
              display: block;
            }
          }
        }

        .category-name-align {
          p {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            color: var(--secondary-900);
            margin: 8px 0 0 0;
          }
        }
      }

      .find-fashion-border-alignment {
        width: 114px;
        height: 114px;
        border: 1px solid transparent;
        border-radius: 68.4405px;
        padding: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 2px 0;

        &.find-fashion-border-active {
          border: 1px solid #ffecbc;
        }

        .find-fashion-box-alignment {
          background: transparent;
          border-radius: 68.4405px;
          width: 102px;
          height: 102px;
          display: flex;
          align-items: center;
          justify-content: center;

          &.find-fashion-box-active {
            background-color: rgba(255, 203, 70, 0.6);
          }
        }
      }

      .fashion-img-alignment {
        width: 88px;
        height: 88px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background: rgba(30, 36, 50, 0.6);
          top: 0;
          right: 0;
          border-radius: 50%;
        }

        .fashion-option-name-alignment {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 9;

          h5 {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #ffffff;
          }
        }
      }
    }
  }
}

.modal-blur {
  background-color: rgb(0, 0, 0, 0.5);
}

.more-option-modal-main {
  opacity: 0;
  transition: 0.5s ease-in-out;
  height: 0;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

  &.more-option-modal-main-open {
    transition: 0.5s ease-in-out;
    height: max-content;
    opacity: 1;

    // .more-option-modal-div {
    //   transition: .5s ease-in-out;
    //   transform: translateY(0px);
    // }
  }

  .more-option-modal-div {
    // transition: .5s ease-in-out;
    // transform: translateY(-10px);

    &:hover {
      transition: 0.2s ease-in-out;
      background-color: #e4e4e8;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

html {
  scroll-behavior: smooth;
}

// .recommendation-section {
//   border-radius: 16px;
//   background: #fff;
//   border: 2px solid #eef1fd;
//   margin: 10px 0;

//   h1 {
//     font-family: 'Poppins', serif;
//     font-size: 20px;
//     font-weight: 600;
//     line-height: 27px;
//     color: #1e2432;
//     margin: 10px 20px;
//   }

//   .slick-next-arrow,
//   .slick-prev-arrow {
//     position: absolute;
//     top: 50%;
//     z-index: 99;
//     cursor: pointer;
//   }

//   .slick-next-arrow {
//     left: 0;
//     transform: translateY(-50%);
//   }

//   .slick-prev-arrow {
//     right: 0;
//     transform: translateY(-50%) rotate(180deg);
//   }

//   .image-slider-main-alignment {
//     background: #f8f8f8;
//     border: 1px solid #f8f8f8;
//     padding: 8px;
//     border-radius: 10px;
//     .image-slider-main {
//       // display: grid;
//       // grid-template-columns: repeat(2, 1fr);
//       // gap: 7px;
//       // display: flex;
//       // flex-direction: column;
//       // justify-content: center;
//       align-items: center;

//       .slick-next-arrow,
//       .slick-prev-arrow {
//         position: absolute;
//         top: 50%;
//         z-index: 99;
//         cursor: pointer;
//         opacity: 0;
//       }

//       .slick-next-arrow {
//         // left: -40%;
//         left: 0;
//         // transform: translateY(-50%) translateX(15%);
//         transform: translateY(-50%);
//       }

//       .slick-prev-arrow {
//         // right: -40%;
//         right: 0;
//         // transform: translateY(-50%) translateX(10%) rotate(180deg);
//         transform: translateY(-50%) rotate(180deg);
//       }

//       .image-slider-div {
//         min-width: 0px;

//         &:hover {
//           .recommendation-image-hover-div {
//             opacity: 1;
//           }

//           .slick-next-arrow,
//           .slick-prev-arrow {
//             opacity: 1;
//           }
//         }

//         .recommendation-image-div-main {
//           border-radius: 6px;
//           // width: 300px;
//           min-width: 0px;
//           padding: 8px;
//           background-color: #fff;
//           cursor: pointer;
//           position: relative;
//           transition: 0.4s ease-in-out;
//           .recommendation-image-main {
//             height: 320px;
//             border-radius: 8px;
//             position: relative;
//             overflow: hidden;
//             background-color: rgb(237, 237, 237);

//             .recommendation-image {
//               position: absolute;
//               top: 0;
//               left: 0;
//               width: 100%;
//               height: -webkit-fill-available;
//               background-position: center center;
//               background-repeat: no-repeat;
//               background-size: contain;
//               mix-blend-mode: multiply;
//             }
//           }
//           .recommendation-image-hover-div {
//             transition: 0.4s ease-in-outs;
//             position: absolute;
//             top: 0;
//             left: 0;
//             width: 100%;
//             height: 100%;
//             opacity: 0;

//             .recommendation-image-heart-main {
//               display: flex;
//               justify-self: end;
//               align-items: center;
//               padding: 12px;

//               .recommendation-image-heart {
//                 padding: 5px;
//                 display: flex;
//                 justify-content: center;
//                 align-items: center;
//                 width: 32px;
//                 height: 32px;
//                 background: rgba(30, 36, 50, 0.6);
//                 border-radius: 8px;
//                 cursor: pointer;
//                 transition: 0.4s ease-in-out;

//                 svg {
//                   transition: 0.4s ease-in-out;
//                 }

//                 &:hover {
//                   svg {
//                     fill: #ff0000;

//                     path {
//                       stroke: #ff0000;
//                     }
//                   }
//                 }
//               }
//             }
//           }

//           .recommendation-image-product-name {
//             padding: 4px 0;

//             p {
//               font-family: 'Poppins', serif;
//               font-size: 13px;
//               font-weight: 500;
//               line-height: 16px;
//               color: #000;
//             }

//             .recommendation-image-product-price {
//               display: flex;
//               justify-content: space-between;
//               align-items: center;
//               span {
//                 font-family: 'Poppins', serif;
//                 font-size: 11px;
//                 font-weight: 600;
//                 line-height: 30px;
//                 color: #556ee6;
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }
.recommendation-section-main-box {
  h1 {
    font-family: 'Poppins', serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 27px;
    color: #1e2432;
    margin: 10px 20px;
  }

  .recommendation-section {
    border-radius: 10px;
    border: 1px solid var(--light-grey);
    background: var(--light-grey);
    padding: 8px 9px;

    .slick-track {
      display: flex;
      margin: 0;
    }

    &:hover {
      .slick-prev-arrow,
      .slick-next-arrow {
        display: flex !important;
      }
    }

    .product-container-main {
      @media (max-width: 576px) {
        width: auto;
      }
    }

    .slick-prev-arrow,
    .slick-next-arrow {
      display: none !important;
    }

    .like-button-alignment {
      transition: 0.4s ease-in-out;

      svg {
        transition: 0.4s ease-in-out;

        path {
          transition: 0.4s ease-in-out;
          // fill: transparent;
        }
      }

      &.like-button-alignment-active {
        transition: 0.4s ease-in-out;

        svg {
          transition: 0.4s ease-in-out;

          path {
            transition: 0.4s ease-in-out;
            fill: #ff0000;
            stroke: #ff0000;
          }
        }
      }
    }

    .price-analytics-section-buttons-main {
      .price-analytics-section-buttons-bookmark {
        transition: 0.4s ease-in-out;

        svg {
          transition: 0.4s ease-in-out;

          path {
            transition: 0.4s ease-in-out;
            fill: transparent;
          }
        }

        &.price-analytics-section-buttons-bookmark-active {
          transition: 0.4s ease-in-out;

          svg {
            transition: 0.4s ease-in-out;

            path {
              transition: 0.4s ease-in-out;
              fill: #556ee6;
            }
          }
        }
      }
    }

    // .comment-section-alignment {
    //   display: none !important;
    // }
    // .like-button-alignment {
    //   display: none !important;
    // }
    // .price-analytics-section-buttons-main {
    //   display: flex !important;
    // }

    .product-container-main {
      margin-left: 10px;
    }

    .slick-prev-arrow {
      position: absolute;
      top: 50%;
      width: 40px;
      height: 40px;
      transform: translateY(-50%);
      z-index: 1;
      right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      svg {
        rotate: 180deg;
      }
    }

    .slick-next-arrow {
      position: absolute;
      top: 50%;
      width: 40px;
      height: 40px;
      transform: translateY(-50%);
      z-index: 1;
      left: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .recommendation-section-slider-box-main {
      width: calc(100% - 8px);
      background: #ffffff;
      border-radius: 10px;
      transition: 0.3s ease-in-out;
      padding: 3px 3px 10px 3px;
      margin-bottom: 14px;
      opacity: 1;
      margin: 0 4px;
      position: relative;
      transition: 0.4s ease-in-out;

      .slick-track {
        display: block !important;
      }

      &:hover {
        transition: 0.4s ease-in-out;

        .recommendation-section-slider-box-image-div-hover {
          opacity: 1;
        }

        .image-index-align {
          opacity: 1;
        }

        .slick-prev-arrow {
          opacity: 1;
        }

        .slick-next-arrow {
          opacity: 1;
        }
      }

      .slick-prev-arrow {
        transition: 0.4s ease-in-out;
        width: 20px;
        height: 20px;
        right: 10px;
        opacity: 0;
      }

      .slick-next-arrow {
        transition: 0.4s ease-in-out;
        width: 20px;
        height: 20px;
        left: 10px;
        opacity: 0;
      }

      .recommendation-section-slider-box {
        position: relative;

        .recommendation-section-slider-box-image-div {
          width: 100%;
          height: 300px;
          border-radius: 8px;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
          mix-blend-mode: multiply;
          background-color: rgb(237, 237, 237);
        }
      }

      .recommendation-section-slider-box-image-div-hover {
        transition: 0.4s ease-in-out;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 10px;
        opacity: 0;

        .recommendation-section-slider-box-image-div-hover-heart-div {
          display: flex;
          justify-content: end;
          align-items: center;

          .recommendation-section-slider-box-image-div-hover-heart {
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            background-color: rgba(30, 36, 50, 0.6);
            cursor: pointer;
            transition: 0.4s ease-in-out;

            svg {
              transition: 0.4s ease-in-out;
              width: 20px;
              height: 20px;

              path {
                transition: 0.4s ease-in-out;
                fill: transparent;
              }
            }

            &:hover {
              svg {
                path {
                  stroke: #ff0000;
                  fill: #ff0000;
                }
              }
            }
          }
        }
      }

      .image-index-align {
        top: 0;
        display: flex;
        justify-content: space-between;
        position: absolute;
        width: 100%;
        padding: 12px;
        opacity: 0;

        @media only screen and (max-width: '767px') {
          display: none;
        }

        .number-of-index {
          height: 30px;
          width: 30px;
          background: #1e2432;
          opacity: 0.6;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            color: #ffffff;
          }
        }

        .like-more-alignment {
          display: flex;
          justify-content: flex-end;
          gap: 12px;
          align-items: center;

          .like-button-alignment {
            width: 32px;
            height: 32px;
            background: rgba(30, 36, 50, 0.6);
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 24px;
              height: 24px;
              opacity: 1;
            }
          }

          .more-button-alignment {
            background: rgba(30, 36, 50, 0.6);
            border-radius: 8px;
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            cursor: pointer;

            .option-icon-alignment {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                width: 14px;
                height: 14px;
              }

              svg {
                width: 24px;
                height: 24px;
              }
            }

            .more-option-dropdown-alignment {
              position: absolute;
              margin: 0;
              padding: 4px 0;
              text-align: left;
              list-style-type: none;
              background-color: #fff;
              border-radius: 2px;
              outline: none;
              box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
              width: 196px !important;
              border-radius: 10px;
              padding: 0;
              top: -5px;
              right: 0;
              z-index: 99;

              ul {
                li {
                  cursor: pointer;

                  &:hover {
                    &:first-child {
                      .dropdown-option {
                        background-color: #e4e4e8;
                        border-radius: 10px 10px 0px 0px;
                      }
                    }

                    &:last-child {
                      .dropdown-option {
                        background-color: #e4e4e8;
                        border-radius: 0px 0px 10px 10px;
                      }
                    }
                  }

                  .dropdown-option {
                    align-items: center;

                    &:hover {
                      background-color: #e4e4e8;
                      border-radius: none !important;

                      // &:last-child {
                      //   background-color: #e4e4e8;
                      //   border-radius: 0px 0px 10px 10px;
                      // }

                      // &:first-child {
                      //   background-color: #e4e4e8;
                      //   border-radius: 10px 10px 0px 0px;
                      // }
                    }

                    // background-color: plum;
                    .dropdown-main {
                      display: flex;
                      align-items: center;
                      justify-content: space-between !important;
                      padding: 0px 17px;

                      .dropdown-icon {
                        flex: 20%;
                        text-align: left;
                      }

                      .dropdown-content {
                        flex: 80%;

                        h5 {
                          font-size: 14px;
                          line-height: 21px;
                          font-weight: 400;
                          color: #495057;
                          margin: 0;
                        }

                        p {
                          &:hover {
                            font-size: 8px;
                            line-height: 12px;
                            color: #808191;
                            font-weight: 400;
                            color: #495057;
                          }

                          font-size: 8px;
                          line-height: 12px;
                          color: #808191;
                          margin: 0;
                        }
                      }
                    }
                  }

                  .dropdown-option {
                    &:hover {
                      h5 {
                        font-size: 14px;
                        line-height: 21px;
                        display: flex;
                        align-items: center;
                        color: var(--secondary-900);
                      }

                      p {
                        font-size: 8px;
                        line-height: 12px;
                        color: #808191;
                        font-weight: 400;
                        color: #495057;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .recommendation-section-slider-box-details {
        padding: 5px 10px;

        a {
          color: #000000 !important;

          p {
            font-family: 'Poppins' serif;
            font-size: 16px;
            font-weight: 400 !important;
            line-height: 24px;
            color: #000000 !important;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            height: 50px;
          }
        }

        .recommendation-section-slider-box-details-and-icons-alignment {
          display: flex;
          justify-content: space-between;
          align-items: center;

          h5 {
            font-family: 'Poppins' serif;
            display: flex;
            align-items: center;
            gap: 5px;
            font-weight: 600;
            font-size: 18px;
            line-height: 30px;
            color: #556ee6;
            margin: 5px 0 0 0;

            span {
              font-weight: 600;
            }
          }

          .share-and-save-button-main {
            display: flex;
            align-items: center;
            gap: 8px;

            .share-and-save-button {
              width: 44px;
              height: 44px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              border-radius: 8px;
            }
          }
        }
      }
    }
  }
}

.stepper-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .stepper-class {
    width: 20%;
  }

  .ant-steps-label-vertical .ant-steps-item {
    width: 100%;
  }
}

.country-state-city-inputs-main {
  input {
    font-weight: 600;
    color: #8d8d8d;

    &::placeholder {
      font-weight: 600;
      color: #8d8d8d;
    }
  }
}

.slick-arrow {
  z-index: 50 !important;
  /* if it is not working then increase the value this is global value */
}

:global(.slick-arrow) {
  @media (max-width: 576px) {
    display: none !important;
  }
}

.reportPostModalheader {
  h1 {
    @media (max-width: 768px) {
      font-family: 'Poppins' sans-serif;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: #495057;
    }
  }
}
