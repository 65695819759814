.addProductImagesDetailsAlignment {
  margin: 0 0 16px 0;

  .addProductImagesDetailsBox {
    border-radius: 10px;
    border: 1px solid var(--secondary-50);
    background: #FFF;
    padding: 10px 16px 16px 16px;

    .addProductHeading {
      display: flex;
      align-items: center;
      cursor: pointer;

      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: var(--secondary-900);
      }

      span {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #c21717;
      }
    }

    .addProductNotesALignment {
      padding: 3px 0 0 0;

      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #808191;
      }
    }

    .addProductViewFilterAlignment {
      padding: 16px 0 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media only screen and (max-width: '767px') {
        display: none;
      }

      .totalImagesNumber {
        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          color: #495057;
        }
      }

      .filterRightSideAlignment {
        display: flex;
        align-items: center;
        gap: 21px;

        .filterRelativeAlignment {
          position: relative;
          cursor: pointer;

          .imagesSizeAlignment {
            background: #f8f8f8;
            border: 1px solid #acb1c0;
            border-radius: 4px;
            padding: 4px 8px 4px 12px;
            display: flex;
            align-items: center;
            gap: 18px;

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              color: #808191;
            }

            .downArrow {
              cursor: pointer;
            }
          }
        }

        .recorderProductDetailsAlignment {
          display: flex;
          align-items: center;
          gap: 16px;

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: var(--secondary-200);
            cursor: pointer;

            &.blue {
              color: var(--primary-500);
            }
          }

          .recorderIconAlignment {
            display: flex;
            align-items: center;
            gap: 8px;

            img {
              cursor: pointer;
            }
          }
        }
      }

      .recorderProductDetailsAlignment {
        display: flex;
        align-items: center;
        gap: 16px;

        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: var(--secondary-200);
          cursor: pointer;

          &.blue {
            color: var(--primary-500);
          }
        }
      }
    }

    .addProductDetailsAlignment {
      margin: 12px 0 0 0;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      gap: 8px;

      // overflow-x: hidden;
      @media only screen and (max-width: '767px') {
        display: none;
      }

      .productScrollImage {
        width: calc(100% - 162px);

        .overFlowDetailsAlignment {
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          gap: 4px;
          overflow: scroll;
          // width: 100%;
          width: 100%;

          &::-webkit-scrollbar {
            width: 0;
            height: 0;
          }

          .addProductAlignment {
            height: 182px;
            max-width: 108px;
            min-width: 108px;
            width: 108px;
            position: relative;
            z-index: 0;

            &.fiveFourWidth {
              width: 135px;
              min-width: 135px;
              max-width: 135px;
            }

            &.threeTwoWidth {
              width: 162px;
              min-width: 162px;
              max-width: 162px;
            }

            &.sixNineWidth {
              width: 192px;
              min-width: 192px;
              max-width: 192px;
            }

            &.child-img {
              min-width: 116px;
            }

            &:hover {
              .hoverDetailsAlignment {
                display: block;
              }
            }

            img {
              width: 100%;
              height: 100%;
              border-radius: 8px;
              object-fit: contain;
            }

            video {
              width: 100%;
              height: 100%;
              border-radius: 8px;
              object-fit: contain;
            }

            .hoverDetailsAlignment {
              position: absolute;
              top: 0;
              right: 0;
              width: 100%;
              background: rgba(30, 36, 50, 0.4);
              height: 100%;
              border-radius: 8px;
              display: none;

              .optionAlignment {
                display: flex;
                align-items: center;
                grid-gap: 12px;
                gap: 12px;
                justify-content: center;
                height: 100%;

                img {
                  width: 20px;
                  height: 20px;
                  display: block;
                  cursor: pointer;
                }
              }

              .imgOptionAlignment {
                display: flex;
                align-items: center;
                gap: 2px;
                position: absolute;
                justify-content: center;
                width: 100%;
                height: 100%;

                .optionBoxAlignment {
                  background: rgba(30, 36, 50, 0.4);
                  border-radius: 8px;
                  padding: 3px;
                  cursor: pointer;

                  img {
                    width: 20px;
                    height: 20px;
                    display: block;
                  }
                }
              }
            }
          }
        }
      }

      .productSizeBoxAlignment {
        background: #f8f8f8;
        border: 2px dashed #b1c2f3;
        border-radius: 8px;
        padding: 28px;
        width: 100%;

        .productMainFlexAlignment {
          display: flex;
          align-items: flex-start;
          gap: 23px;

          .productChildDetailsAlignment {
            h4 {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              color: #495057;
              padding: 0 0 11px 0;
              text-align: center;
            }

            .sizesDetailsFlexAlignment {
              display: flex;
              align-items: flex-start;
              gap: 4px;

              .squareBox {
                width: 54px;
                height: 54px;
                background: #f8f8f8;
                border: 1px solid #acb1c0;
                border-radius: 6.49995px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &:hover {
                  background: var(--secondary-50);
                  border: 1px solid rgba(85, 110, 230, 0.6);
                  border-radius: 6.49995px;
                }

                &.nineBySixteenBox {
                  width: 54px;
                  height: 96px;
                }

                &.sixteenByNineBox {
                  width: 96px;
                  height: 54px;
                }

                &.twoByThreeBox {
                  width: 54px;
                  height: 81px;
                }

                &.threeByTwoBox {
                  width: 81px;
                  height: 54px;
                }

                &.fourByFive {
                  width: 54px;
                  height: 67px;
                }

                &.fiveByFourBOx {
                  width: 67px;
                  height: 54px;
                }

                span {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: #808191;
                }
              }
            }
          }
        }
      }
    }

    .mobileViewAddProductAlignment {
      margin: 16px 0 0 0;
      position: relative;
      display: none;

      @media only screen and (max-width: '767px') {
        display: block;
      }

      input {
        width: 100%;
        height: 40px;
        border-radius: 10px;
        border: 1px solid var(--secondary-50);
        background: var(--light-grey);
        padding: 0 30px 0 16px;
        color: var(--secondary-500);
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }

      .iconAlignment {
        position: absolute;
        right: 12px;
        top: 12px;
      }
    }

    .uploadedButtonAlignment {
      padding: 20px 0 0 0;
      display: none;

      @media only screen and (max-width: '767px') {
        display: block;
      }

      button {
        width: 100%;
        height: 32px;
        border-radius: 8px;
        background: var(--primary-50);
        color: var(--primary-200);
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        position: relative;

        &.activeButton {
          color: var(--primary-500);

          svg {
            path {
              stroke: #556ee6;
            }
          }
        }

        input[type="file"] {
          position: absolute !important;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
      }
    }

    .productUploadedImgesDetailsAlignment {
      padding: 16px 0 0 0;
      display: none;

      @media only screen and (max-width: '767px') {
        display: block;
      }

      .productUploadedImgesDetailsHeading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media only screen and (max-width: '1024px') {
          display: none;
        }
        h6 {
          color: #495057;
          font-family: 'Poppins', sans-serif;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
        }

        a {
          color: var(--primary-500);
          text-align: center;
          font-family: 'Poppins', sans-serif;
          font-size: 15px;
          font-weight: 500;
          line-height: 20px;
        }
      }

      .productUploadedAllImgesAlignment {
        padding: 16px 0 0 0;
        width: 100%;
        overflow-x: auto;
        display: flex;
        align-items: center;
        gap: 8px;

        .productUploadImg {
          width: 80px;
          height: 80px;
          min-width: 80px;
          max-width: 80px;
          position: relative;

          .productSelectedImg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 4px;
            border: 1px dashed #556ee6;
            background: linear-gradient(0deg, rgba(30, 36, 50, 0.4) 0%, rgba(30, 36, 50, 0.4) 100%);
            display: flex;
            align-items: center;
            justify-content: center;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 4px;
            object-fit: cover;
          }
        }
      }
    }
  }
}