.kYCVerifiedStep1Section {
    .kYCVerifiedStep1Alignment {
        padding: 87px 0 90px 0;

        .kYCVerifiedStep1NameAlignment {
            text-align: center;

            h6 {
                color: var(--secondary-900);
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                padding: 0 0 2px 0;
            }

            span {
                color: var(--secondary-500);
                font-family: 'Poppins', sans-serif;
                font-size: 13px;
                font-weight: 400;
                line-height: 24px;
            }

            .toAlignment {
                padding: 8px 0;

                p {
                    color: var(--secondary-900);
                    font-family: 'Poppins', sans-serif;
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 24px;
                }
            }
        }

        .kYCVerifiedAmountBoxAlignment {
            padding: 112px 0 0 0;
            text-align: center;

            h6 {
                color: var(--secondary-900);
                font-family: 'Poppins', sans-serif;
                font-size: 20px;
                font-weight: 500;
                line-height: 24px;
            }

            .amountBox {
                width: 302px;
                height: 75px;
                border-radius: 16px;
                background: var(--secondary-50);
                margin: 16px auto;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                padding: 18px 74px;

                span {
                    color: var(--secondary-900);
                    font-family: 'Poppins', sans-serif;
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 36px;
                }

                input {
                    border: none;
                    background: transparent;
                    color: var(--secondary-900);
                    font-family: 'Poppins', sans-serif;
                    font-size: 40px;
                    font-weight: 500;
                    line-height: 60px;
                    width: 100%;
                    height: 100%;
                }
            }

            .amountBottomDetails {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 16px;

                h6 {
                    color: var(--secondary-900);
                    font-family: 'Poppins', sans-serif;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 19px;
                }

                span {
                    color: var(--secondary-500);
                    font-family: 'Poppins', sans-serif;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 19px;
                }
            }
        }

        .continueButtonAlignment {
            text-align: center;
            margin: 175px 0 0 0 ;

            button {
                border-radius: 10px;
                background: var(--primary-500);
                height: 44px;
                width: 190px;
                color: #FFF;
                text-align: center;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
            }
        }
    }
}