.smallSizeModal {
  position: fixed;
  bottom: 0;
  right: 0;
  // width: 57%;
  width: 63%;
  height: calc(100vh - 64px);
  // height: calc(100vh - 104px);
  z-index: 999999 !important;
  translate: 100% 0;
  transition: 0.3s;
  display: flex;
  align-items: flex-start;
  gap: 16px;


  @media only screen and (max-width: '1699px') {
    width: 65%;
  }

  @media only screen and (max-width: '1499px') {
    width: 85%;
  }

  // @media only screen and (max-width:"1099px") {
  //   width: 48%;
  // }

  // @media only screen and (max-width:"1199px") {
  //   width: 85%;
  // }

  @media only screen and (max-width: '767px') {
    width: 100%;
    top: 0;
    height: 100vh;
  }

  .sidebarModalAlignment {
    // background: #eceef4;
    border-radius: 16px 0px 0px 0px;
    padding: 16px;
    background-color: #fff;
    width: 100%;
    height: 100%;

    @media only screen and (max-width: '1024px') {
      height: calc(100vh - 56px - 72px);
    }

    @media only screen and (max-width: '767px') {
      padding: 0;
      border-radius: 0;
      margin: 56px 0 0 0;
    }
  }
}

.open {
  translate: 0;
}

.smallOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(30, 36, 50, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  z-index: 999999;
}

.openOverlay {
  opacity: 0.5;
  visibility: visible;
}

// .overlay