.sellerToolsSection {
    .sellerToolsAlignment {
        margin: 4px 0 0 0;

        .sellerToolsBox {
            background: var(--white);
            border: 2px solid var(--white);
            border-radius: 0px 0px 10px 10px;
            padding: 22px 32px;

            @media only screen and (max-width:"1499px") {
                padding: 22px 16px;
            }

            .sellerHeadingAlignment {
                h6 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 36px;
                    color: var(--secondary-900);
                }

                p {
                    padding: 16px 0 0 0;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: var(--secondary-900);
                }
            }

            .sellerToolsDetailsAlignment {
                padding: 52px 0 0 0;

                .sellerToolsOptionDetailsAlignment {
                    display: flex;
                    align-items: center;
                    gap: 7px;
                    margin: 0 0 32px 0;
                    transition: 0.4s ease-in-out;
                    cursor: pointer;

                    &:last-child {
                        margin: 0;
                    }

                    &:hover {
                        .sellerToolsOptionDetailsTools {
                            background: var(--primary-50);

                            svg {
                                fill: var(--primary-500);
                                stroke: var(--primary-500);
                            }
                        }

                        p {
                            color: var(--primary-500);
                        }
                    }

                    &.activeTools {
                        .sellerToolsOptionDetailsTools {
                            background: var(--primary-50);

                            svg {
                                fill: var(--primary-500);
                                stroke: var(--primary-500);
                            }
                        }

                        p {
                            color: var(--primary-500);
                        }
                    }

                    .sellerToolsOptionDetailsTools {
                        width: 30px;
                        min-width: 30px;
                        max-width: 30px;
                        height: 30px;
                        background-color: var(--secondary-50);
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        svg {
                            fill: var(--secondary-500);
                            stroke: var(--secondary-500);
                        }
                    }

                    p {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        color: var(--secondary-500);
                    }
                }
            }
        }
    }
}