.Messagechatboxslidert {
  width: 70%;
  .slick-arrow {
    background: rgba(255, 255, 255, 0.6);

    img {
      &.hover-img {
        display: none;
      }
    }

    &:hover {
      background: #a855f7 !important;

      img {
        &.hover-img {
          display: block;
        }

        &.defalt-icon {
          display: none;
        }
      }

      &.slick-prev {
        img {
          animation: righttoleft 0.4s ease-in-out;
        }
      }

      &.slick-next {
        img {
          animation: bounceRight 0.4s ease-in-out;
        }
      }
    }
  }

  @keyframes righttoleft {
    0% {
      -moz-transform: translateX(4px);
      transform: translateX(4px);
    }

    100% {
      -moz-transform: translateX(0);
      transform: translateX(0);
    }
  }

  @keyframes bounceRight {
    0% {
      -ms-transform: translateX(0);
      transform: translateX(0);
    }

    100% {
      -ms-transform: translateX(-4px);
      transform: translateX(-4px);
    }
  }

  .chatsliderdetails {
    h5 {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  .share-post-design-section {
    background: #f8f8f8;
    border: 1px solid #f8f8f8;
    border-radius: 10px;
    padding: 8px;
    width: 100%;
    display: block;

    // @media only screen and (max-width:"1440px") {
    //   height: calc(100vh - 280px);
    //   overflow-y: auto;
    // }

    // .share-post-design-section-slider-alignment {
    //   width: calc(100% - 245px - 90px);
    // }

    &:hover {
      .slick-arrow {
        display: flex;
      }
    }

    .slick-slide {
      div {
        // height: 354px;
        padding: 0 4px;
        // @media screen and (max-width: 767px) {
        //   height: 140px;
        // }
      }
    }

    .slick-list {
      border-radius: 8px;
    }

    .share-post-design-grid-section {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;
    }

    .share-product-img-alignment {
      // width: 100%;
      // height: 354px;
      padding: 0 4px;
      width: 100%;
      height: 354px;
      background-color: rgb(237 237 237);
      position: relative;
      border-radius: 8px 8px 0px 0px;
      overflow: hidden;

      @media screen and (max-width: 767px) {
        height: 140px;
      }

      .shared-product-media {
        // position: absolute;
        border-radius: 8px 8px 0px 0px;
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: cover;
        height: -webkit-fill-available;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        mix-blend-mode: multiply;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
    }

    .share-post-details-alignment {
      padding: 16px 32px 24px 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      @media screen and (max-width: 767px) {
        align-items: end;
        padding: 8px;
      }

      .share-post-heading {
        h5 {
          cursor: pointer;
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: var(--secondary-900);
        }
      }

      .share-post-price-alignment {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 8px 0 0 0;

        h6 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 15px;
          line-height: 22px;
          color: #000000;
        }

        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #808191;
        }
      }

      .share-post-delivery-shipping {
        display: flex;
        align-items: center;
        gap: 6px;
        padding: 8px 0 0 0;

        @media screen and (max-width: 767px) {
          // margin: 2px 0;
          padding: 0;
        }

        .best-seller-alignment {
          h6 {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            background: linear-gradient(263.94deg, #ffc554 7.44%, #744c00 86.5%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }
        }

        .dot-alignment {
          p {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #acb1c0;
          }
        }

        .discount-menu-align {
          width: auto;
          height: auto;
          background: #ff7d23;
          border-radius: 11px;
          display: flex;
          justify-content: center;
          align-items: center;

          button {
            padding: 1px 9px;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: #ffffff;

            @media screen and (max-width: 767px) {
              padding: 1px 4px;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              display: flex;
              align-items: center;
              color: #ffffff;
              white-space: nowrap;
            }
          }
        }

        .delivery-menu-align {
          width: auto;
          height: 21px;
          // background: #eaeaea;
          border-radius: 13.5px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 2px;

          @media screen and (max-width: 767px) {
            padding: 4px 6px;
          }

          padding: 4px 8px;

          img {
            width: 100%;
            height: 100%;
          }

          span {
            font-weight: 500;
            font-style: italic;
            font-size: 12px;
            line-height: 18px;
            display: flex;
            align-items: center;
            text-decoration-line: none;
            color: #5f40af;
          }
        }

        .arrow-menu-align {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 6px;

          img {
            width: 11px;
            height: 11px;
          }

          span {
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            display: flex;
            align-items: center;
            color: #70c217;
          }
        }
      }

      .share-post-other-option-alignment {
        display: flex;
        align-items: center;
        gap: 8px;

        .share-post-box-alignment {
          width: 44px;
          height: 44px;
          background: #ffffff;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &:hover {
            background: #eef1fc;
          }

          img {
            width: 24px;
            height: 24px;
            display: block;
          }
        }
      }
    }

    .slick-arrow {
      display: none;
      transition: 0.15s ease-in-out;
    }

    .slick-prev {
      left: 10px !important;
      z-index: 99;

      &:hover {
        background: rgba(255, 255, 255, 0.6) !important;
      }
    }

    .slick-next {
      right: 15px !important;

      &:hover {
        background: rgba(255, 255, 255, 0.6) !important;
      }
    }
  }
}
