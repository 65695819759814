.store-poster-container {
  .feature-images-responsive {
    display: none;

    @media screen and (max-width: 767px) {
      display: block;
    }

    .product-poster-second {
      border-right: 2px solid #ffffff;

      .product-image-alignment {
        width: 100%;
        height: 414px;

        @media screen and (max-width: 767px) {
          width: 100%;
          height: auto;
        }

        position: relative;

        .main-image-style-mobile {
          .blur-div {
            position: absolute;
            background: linear-gradient(90deg, #1e2432 0%, rgba(30, 36, 50, 0) 100%);
            width: 100%;
            top: 0;
            left: 0;
            height: 200px;
          }

          img {
            width: 100%;
            height: 200px;
            border-left: 2px solid #ffffff;
            object-fit: cover;
          }
        }
      }
    }
  }
}

.absolute-class {
  width: 100%;
  padding: 24px;
  width: 100%;

  @media screen and (max-width: 767px) {
    width: 100%;
    height: auto;
    padding: 0;
    padding-bottom: 0;
  }

  .featured-alignment {
    top: 16px;
    left: 16px;
    position: absolute;
    width: 94px;
    height: 27px;
    background: linear-gradient(94.09deg, #556ee6 0.21%, #6b21a8 100%);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 767px) {
      width: 73px;
      height: 21px;
    }

    button {
      font-weight: 500;
      font-size: 15.4286px;
      line-height: 23px;
      display: flex;
      align-items: center;
      color: #ffffff;

      @media only screen and (max-width: 767px) {
        font-size: 12px;
        line-height: 18px;
      }
    }

    @media screen and (max-width: 767px) {
      font-size: 12px;
    }
  }

  .feature-product-deatail {
    position: absolute;
    bottom: 40px;

    @media screen and (max-width: 1099px) {
      left: 16px;
      bottom: 26px;
    }

    .poster-name-alignment {
      h1 {
        font-weight: 600;
        font-size: 28px;
        line-height: 42px;
        color: #f8f8f8;
        z-index: 999;

        @media screen and (max-width: 767px) {
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: #f8f8f8;
        }
      }
    }

    .analytics-container-main {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;

      @media only screen and (max-width:"1099px") {
        display: none;
      }

      .graph-menu-align {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 6px;

        svg {
          width: 14px;
          height: 11.8px;
        }

        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #ffffff;
        }
      }

      .arrow-menu-align {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 6px;

        img {
          width: 11px;
          height: 11px;
        }

        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          display: flex;
          align-items: center;
          color: #70c217;
        }
      }

      .dott-icon-align {
        width: 4px;
        height: 4px;
        background: #acb1c0;
        border-radius: 50%;
      }

      .rating-section-main {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;

        img {
          margin-bottom: 2px;
        }

        span {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
        }

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #ffffff;
          margin-bottom: 0 !important;
        }
      }
    }

    .price-analytics-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 6px;
      margin: 6px 0;

      @media screen and (max-width: 767px) {
        margin-bottom: 6px;
      }

      h5 {
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        color: #ffffff;

        @media screen and (max-width: 1099px) {
          font-size: 20px;
          line-height: 30px;
        }
      }

      span {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        display: flex;
        align-items: center;
        text-decoration-line: line-through;
        color: #acb1c0;
      }

      .mobile-view-discount-alignment {
        background: #F1EFB0;
        border-radius: 9px;
        padding: 1px 6px;
        display: none;

        @media only screen and (max-width:"1099px") {
          display: block;
        }

        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          text-align: center;
          color: #474400;
          text-decoration-line: none;
        }
      }

      .discount-menu-align {
        width: 61px;
        height: 21px;
        background: #ff7d23;
        border-radius: 11px;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          display: flex;
          align-items: center;
          color: #ffffff;
        }
      }

      .delivery-menu-align {
        width: 62px;
        height: 21px;
        background: #eaeaea;
        border-radius: 13.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2px;

        img {}

        span {
          font-weight: 500;
          font-style: italic;
          font-size: 12px;
          line-height: 18px;
          display: flex;
          align-items: center;
          text-decoration-line: none;
          color: #5f40af;
        }
      }

      @media screen and (max-width: 767px) {
        .discount-menu-align {
          display: none;
        }

        .delivery-menu-align {
          display: none;
        }
      }
    }

    .mobile-view-analytics-container-main {
      display: none;

      @media only screen and (max-width:"1099px") {
        display: flex;
        align-items: center;
        gap: 3px;

        .arrow-menu-align {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 6px;

          img {
            width: 11px;
            height: 11px;
          }

          span {
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: #70c217;
          }
        }

        .dott-icon-align {
          width: 2px;
          height: 2px;
          background: #F8F8F8;
          border-radius: 50%;
        }

        .rating-section-main {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 4px;

          img {
            margin-bottom: 2px;
          }

          span {
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            color: #ffffff;
          }

          p {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #ffffff;
            margin-bottom: 0 !important;
          }
        }
      }
    }
  }
}

.feature-two-col-grid {
  display: grid;
  grid-template-columns: 1fr max(90px);
  gap: 0;

  .child-image-style {
    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
  }
}