.allBookMarkDetailsBodyAlignment {
  padding: 12px 0 0 0;

  @media only screen and (max-width: '699px') {
    padding: 12px;
  }

  .allBookMarkDetailsGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    @media only screen and (max-width: '1024px') {
      grid-template-columns: repeat(3, 1fr);
    }
    @media only screen and (max-width: '699px') {
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
    }
  }

  .noDataBookMarkCollectionDetails {
    height: 209px;
    border-radius: 16px;
    background: var(--primary-50);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;

    p {
      color: var(--violet-300);
      text-align: center;
      font-size: 12px;
      font-style: italic;
      font-weight: 400;
      line-height: 18px;
    }
  }
}

.scrollableContainer {
  max-height: 80vh; // Adjust the height as needed
  overflow-y: auto;
}
