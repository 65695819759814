.gift-card-box-alignment {
  width: 75%;
  margin: 50px 0;

  @media only screen and (max-width:"767px") {
    display: block;
    width: 100%;
    margin: 0;
  }

  .gift-card-grid-alignment {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 64px;

    @media only screen and (max-width:"767px") {
      display: block;
    }

    .gift-card-gridItem-alignment {
      @media only screen and (max-width:"767px") {
        display: none;
      }

      .gift-card-child-box-alignment {
        background: #ff7d23;
        box-shadow: 0px 5px 50px rgba(163, 156, 169, 0.15);
        border-radius: 10px;
        margin: 0 0 5px 0;
        position: relative;
        height: 184px;
        padding: 30px;

        &.purle-card {
          background: #8982F7;
        }

        &:last-child {
          margin: 0;
        }

        .rounded-img-alignment {
          position: absolute;
          top: 0;
          right: 0;
        }

        .card-price-reload-alignment {
          .card-name-alignment {
            padding: 10px 10px 10px 0;

            span {

              position: relative;
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 22px;
              line-height: 33px;
              color: #FFFFFF;

              &::before {
                content: '';
                position: absolute;
                bottom: -10px;
                width: 100%;
                right: 0;
                height: 1px;
                background-color: rgba(228, 228, 232, 0.7);
              }
            }


          }

          .price-reload-alignment {
            display: flex;
            align-items: center;
            gap: 24px;
            padding: 34px 0 0 0;

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 700;
              font-size: 26px;
              line-height: 39px;
              color: #FFFFFF;

            }


            .code-enter-input {
              input {
                height: 48px;
                width: 285px;
                background: #FFFFFF;
                border-radius: 10px;
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 22px;
                padding: 0 24px;

                &::placeholder {

                  color: #898A8D;
                }


              }
            }

            .reload-button-alignment {


              button {
                background: var(--primary-500);
                border-radius: 10px;
                width: 106px;
                height: 48px;
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 17px;
                line-height: 26px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #FFFFFF;
                cursor: pointer;
              }
            }
          }
        }

        a {
          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            color: #808191;
            white-space: nowrap;
          }

          .active-img-alignment {
            display: none;
          }
        }
      }

      li:hover {
        background-color: #556ee6 !important;

        a {
          span {
            color: white !important;
          }

          .deActive-img-alignment {
            display: none;
          }

          .active-img-alignment {
            display: block;
          }

          svg {
            path {
              stroke: white;
            }

            circle {
              stroke: white;
            }

            .gift {
              fill: white;
            }
          }
        }
      }

      li.tab-active {
        background-color: #556ee6 !important;

        a {
          span {
            color: white !important;
          }

          .deActive-img-alignment {
            display: none;
          }

          .active-img-alignment {
            display: block;
          }

          svg {
            path {
              stroke: white;
            }

            circle {
              stroke: white;
            }

            .gift {
              fill: white;
            }
          }
        }
      }
    }
  }


  .summary-page-header {
    position: absolute;
    width: 100%;
    padding: 15px 0;
    background-image: linear-gradient(to right, #556ee6, #6b21a8);
    z-index: 10;
    top: 0px;

    &.sticky {
      position: fixed !important;
      // box-shadow: 0px 5px 10px rgba(0,0,0,0.05);
      // box-shadow: rgba(79, 79, 82, 0.4) 0px 7px 10px 0px;
    }
  }

  .dashboard-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 100px;

    .dashboard-left-sidebar-clone {
      position: relative;
      height: 100vh;
      background-color: #fff;
      min-width: 280px;
      max-width: 280px;
      padding: 0 0 30px 0;
    }

    .dashboard-left-sidebar {
      z-index: 10;
      // position: absolute;
      height: 100%;
      background-color: #fff;
      min-width: 280px;
      max-width: 280px;
      // padding: 0 0 30px 0;
      padding: 60px 56px;

      &.sticky {
        position: fixed !important;
        top: 70px !important;
        // box-shadow: rgba(79, 79, 82, 0.4) 7px 7px 10px 0px;
      }

      .left-sidebar-profile {
        // width: 100%;
        // height: 120px;
        // position: relative;
        // padding-top: 21px;
        // padding-left: 30px;

        .left-sidebar-profile-photo {
          width: 60px;
          height: 60px;

          img {
            border-radius: 30px;
            width: 100%;
            height: 100%;
          }
        }

        .left-sidebar-user-name {
          // position: absolute;
          // left: 100px;
          // right: 0;
          // bottom: 60px;
          padding: 15px 0 0 0;
          text-align: center;

          h6 {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 22px;
            color: #000000;
          }
        }
      }
    }

    .dashboard-right-wrapper {
      width: 100%;
      padding: 50px 100px 50px 0;
      // display: flex;
      // justify-content: center;

      .dashboard-content-wrapper {
        width: 100%;

        // max-width: 1200px;
        .grid-alignment {
          gap: 35px;
        }

        .content-title {
          font-family: Poppins;
          font-style: normal;
          font-weight: 600 !important;
          font-size: 22px;
          line-height: 33px;
          color: #000000;
        }

        .all_card {
          // padding: 30px;
          width: 100%;
          background: #ffffff;
          border-radius: 10px;
          margin-bottom: 30px;

          .card_header {
            padding-top: 20px;
            padding-left: 30px;
            padding-right: 30px;

            .header-title {
              font-family: Poppins;
              font-style: normal;
              font-weight: 600 !important;
              font-size: 18px;
              line-height: 27px;
              color: #000000;
            }

            .progressNum {
              font-family: Poppins;
              font-style: normal;
              font-weight: 600 !important;
              font-size: 18px;
              line-height: 39px;
              color: #000000;
            }

            .header-description {
              margin-top: -10px;
              font-family: Poppins;
              font-style: normal;
              font-weight: 500 !important;
              font-size: 14px;
              line-height: 21px;
              color: #808191;
            }
          }

          .card_body {
            padding: 30px;
          }
        }

        .slick-dots {
          width: auto;
          top: -30px;
          right: 30px;
          height: 30px;

          li {
            width: 8px;

            button {
              width: 8px;
              padding: 0;

              &:before {
                content: '';
                width: 8px;
                height: 8px;
                background: #c4c4c4;
                border-radius: 8px;
                opacity: 1;
              }
            }

            &.slick-active {
              width: 20px;

              button {
                &:before {
                  width: 20px;
                  background: #5f40af;
                }
              }
            }
          }
        }

        .ant-table-wrapper {
          .ant-table-thead {
            .ant-table-cell {
              padding: 10px 30px;
              min-width: 80px;
              background-color: #eceef4;
              color: #495057;
              font-weight: 600 !important;
              font-size: 12px;
            }
          }

          .ant-table-tbody {
            .ant-table-cell {
              height: 92px;
              text-align: left;
              padding: 0 30px;
              color: #000000;
              font-weight: 400 !important;
              font-size: 16px;
            }
          }

          .ant-table-pagination {
            display: flex;
            justify-content: center;

            li {
              a {
                background-color: var(--primary-500);
                border-radius: 20px;
                border: 0px;
                color: white;
              }

              button {
                background-color: var(--primary-500);
                border-radius: 20px;
                border: 0px;
                color: white;
              }
            }
          }
        }

        .ant-pagination {
          li {
            border-radius: 20px;

            a {
              background-color: #6366f1;
              border-radius: 20px;
              border: 0px;
              color: white;
            }

            button {
              background-color: #6366f1;
              border-radius: 20px;
              border: 0px;
              color: white;
            }
          }
        }

        .gift-card-box-alignment {
          .gift-card-grid-alignment {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 64px;

            .gift-card-gridItem-alignment {
              .gift-card-child-box-alignment {
                background: #ff7d23;
                box-shadow: 0px 5px 50px rgba(163, 156, 169, 0.15);
                border-radius: 10px;
                padding: 30px;
                position: relative;

                .rounded-img-alignment {
                  position: absolute;
                  top: 0;
                  right: 0;
                }

                .card-name-alignment {
                  padding: 10px 10px 10px 0px;
                  border-bottom: 1px solid rgba(228, 228, 232, 0.7);

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 33px;
                    color: #ffffff;
                  }
                }

                .card-price-reload-alignment {
                  padding: 24px 0 0 0;
                  display: flex;
                  align-items: center;
                  gap: 24px;

                  .price-alignment {}
                }
              }
            }
          }
        }
      }


    }
  }

  .ant-tabs {
    width: 100%;

    .ant-tabs-nav {
      width: 100%;
      background: #fff;
      height: 85px;
      margin: 0;
      border-radius: 10px;

      &:before {
        border: 0;
      }
    }

    .ant-tabs-nav-wrap {
      justify-content: flex-start;
      border-bottom: solid 1px #e6e0e0;
      margin-bottom: 20px;

      .ant-tabs-tab {
        margin: 0;
        padding: 0;
        padding: 4px 30px;
        font-weight: 500 !important;
        color: #a7a7a7;
        font-size: 15px;
        align-items: flex-end;
        position: relative;

        .rounded-img-alignment {
          position: absolute;
          top: 0;
          right: 0;
        }

        .card-name-alignment {
          padding: 10px 10px 10px 0px;
          border-bottom: 1px solid rgba(228, 228, 232, 0.7);

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 22px;
            line-height: 33px;
            color: #ffffff;
          }
        }

        .card-price-reload-alignment {
          padding: 24px 0 0 0;
          display: flex;
          align-items: center;
          gap: 24px;

          .price-alignment {}
        }
      }
    }
  }
}

.mobile-view-gift-card-section {
  display: none;

  @media only screen and (max-width:"767px") {
    display: block;
  }

  .mobile-view-gift-card-alignment {

    .mobile-view-card-details-alignment {

      background: linear-gradient(92.44deg, #556EE6 0.13%, #6B21A8 100%);
      box-shadow: 0px 5px 50px rgba(163, 156, 169, 0.15);
      border-radius: 10px;
      padding: 24px 16px 16px 16px;
      margin: 0 16px 16px 16px;

      .mobile-view-card-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 0;
        border-bottom: 1px solid rgba(236, 238, 244, 0.5);

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 17px;
          line-height: 26px;
          color: rgba(228, 228, 232, 0.7);
        }

        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 700;
          font-size: 17px;
          line-height: 26px;
          color: #F8F8F8;

        }
      }

      .card-child-details-alignment {
        display: flex;
        justify-content: center;
        gap: 80px;
        align-items: center;
        padding: 16px 0 0 0;

        .child-img-alignment {
          .icon-alignment {
            img {
              margin: auto;
            }
          }

          p {
            padding: 10px 0 0 0;
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            color: rgba(228, 228, 232, 0.7);

          }
        }
      }
    }
  }

  .mobile-view-gift-card-details-alignment {
    background: #FFFFFF;
    margin: 16px 0 0 0;

    .mobile-view-tab-alignment {}

    .mobile-view-main-alignment {
      padding: 0 0 16px 0;

      .mobile-view-activities-section {
        padding: 16px 0;
        border-bottom: 1px solid rgba(228, 228, 232, 0.7);

        &:last-child {
          border-bottom: none;
        }

        .mobile-view-activities-details-alignment {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 12px 16px;

          .mobile-card-name-price-alignment {
            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 13px;
              line-height: 20px;
              color: #C21717;
              padding: 0 0 4px 0;

              &.plus-alignment {
                color: #70C217;
              }
            }

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              color: #495057;
              max-width: 219px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display: block;
            }
          }

          .mobile-date-id-alignment {
            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              color: #808191;
              padding: 0 0 4px 0;

              &:last-child {
                padding: 0;
              }
            }
          }
        }
      }
    }

  }
}