.channelsDetailsLiveSection {
    padding: 32px 0;

    @media only screen and (max-width:"767px") {
        padding: 16px;
    }

    @media (max-width:576px) {
        padding: 16px;
    }

    .channelsDetailsHeading {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width:576px) {
            padding: 0 16px;
        }

        h4 {
            color: var(--secondary-900);
            font-family: 'Poppins', sans-serif;
            font-size: 24px;
            font-weight: 600;
            line-height: 36px;
            cursor: context-menu;

            @media only screen and (max-width:"767px") {
                font-size: 20px;
                line-height: 30px;
            }
        }

        a {
            padding: 6px 16px;
            cursor: pointer;
            color: var(--primary-500);
            font-family: 'Poppins', sans-serif;
            font-size: 15px;
            font-weight: 500;
            line-height: 20px;
            display: flex;
            align-items: center;
            gap: 4px;

            @media only screen and (max-width:"767px") {
                font-size: 14px;
                line-height: 21px;
            }
        }
    }

    .channelDetailsLiveAllAlignment {
        margin: 5px 0 0 0;

        @media only screen and (max-width:"767px") {
            margin: 16px 0 0 0;
        }



        :global(.slick-prev) {
            left: -15px;
            z-index: 99;
            background: #d5cfcf !important;
        }

        :global(.slick-next) {
            right: -15px;
            z-index: 999;
            background: #d5cfcf !important;
        }

        :global(.slick-arrow:hover) {
            background: #ab67fc !important;
        }

    }
}

.channelDetailsLiveSliderAlignment {
    padding: 0 8px;

    @media only screen and (max-width:"767px") {
        padding: 0 4px;
    }

    .channelDetailsLiveSliderImgAlignment {
        width: 100%;
        height: 298px;
        position: relative;

        @media only screen and (max-width:"767px") {
            height: 224px;
        }

        &:before {
            position: absolute;
            border-radius: 0px 0px 10px 10px;
            background: linear-gradient(0deg, #1E2432 0%, rgba(30, 36, 50, 0.00) 100%);
            content: '';
            bottom: 0;
            left: 0;
            height: 208px;
            width: 100%;
            z-index: 9;

            @media only screen and (max-width:"767px") {
                height: 100%;
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
            border-radius: 10px;
        }

        .channelDetailsLiveSliderAllDetails {
            position: absolute;
            top: 0;
            left: 0;
            padding: 16px;
            width: 100%;
            height: 100%;
            z-index: 99;

            .channelDetailsLiveAllDetails {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                flex-direction: column;
                height: 100%;

                .channelDetailsliveAndViewDetailsALignment {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    @media only screen and (max-width:"767px") {
                        flex-direction: column;
                        gap: 6px;
                        align-items: flex-start;
                    }


                    .channelBannerLiveButtonAlignment {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        padding: 4px 8px;
                        border-radius: 8px;
                        background: #E71616;
                        cursor: pointer;

                        p {
                            color: #FFF;
                            font-family: 'Poppins', sans-serif;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 21px;

                            @media only screen and (max-width:"767px") {
                                font-size: 12px;
                                line-height: 18px;
                            }

                        }
                    }

                    .channelBannerLiveViewNumberAlignment {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        border-radius: 8px;
                        background: rgba(30, 36, 50, 0.60);
                        padding: 4px 8px;
                        cursor: pointer;

                        p {
                            color: #E4E4E8;
                            font-family: 'Poppins', sans-serif;
                            font-size: 14px;
                            font-weight: 500;
                            line-height: 21px;

                            @media only screen and (max-width:"767px") {
                                font-size: 12px;
                                line-height: 18px;
                            }
                        }
                    }
                }

                .channelDetailsBottomDetailsAlignment {
                    width: 100%;
                    display: flex;
                    align-items: to-upper-case($string: "");
                    justify-content: space-between;

                    .channelDetailsHeadingNameAlignment {
                        h6 {
                            color: #F8F8F8;
                            font-family: 'Poppins', sans-serif;
                            font-size: 20px;
                            font-weight: 600;
                            line-height: 30px;
                            max-width: 142px;


                            @media only screen and (max-width:"767px") {
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }

                        .channelDetailsLogoAlignment {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            padding: 16px 0 0 0;

                            @media only screen and (max-width:"767px") {
                                padding: 8px 0 0 0;
                            }

                            .channelDetailsLogoImg {
                                width: 20px;
                                height: 20px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 2px;
                                    object-fit: cover;
                                }
                            }

                            p {
                                color: #FFF;
                                font-family: 'Poppins', sans-serif;
                                font-size: 13px;
                                font-weight: 500;
                                line-height: 19px;

                                @media only screen and (max-width:"767px") {
                                    font-size: 12px;
                                    line-height: 14px;
                                }
                            }
                        }
                    }

                    .channelDetailsLikeShareAlignment {
                        display: flex;
                        flex-direction: column;
                        gap: 21px;
                        align-items: flex-start;
                        justify-content: flex-end;



                        .channelDetailsLike {
                            display: flex;
                            flex-direction: column;
                            gap: 4px;
                            align-items: center;
                            cursor: pointer;

                            .channelDetailsBox {
                                border-radius: 8px;
                                background: rgba(30, 36, 50, 0.40);
                                padding: 4px;
                                cursor: pointer;
                            }

                            p {
                                color: #F8F8F8;
                                font-family: 'Poppins', sans-serif;
                                font-size: 13px;
                                font-weight: 500;
                                line-height: 19px;
                            }
                        }
                    }
                }

            }
        }
    }
}