.mobileSelectImageRatioModalAlignment {
    padding: 16px;

    .mobileSelectImageRatioModalHeading {
        padding: 0 8px 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h6 {
            color: var(--secondary-900);
            font-family: 'Poppins', sans-serif;
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
        }

        a {
            color: var(--primary-500);
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }
    }

    .mobileSelectImageRatioModalBodyAlignment {
        padding: 0 55px;

        .mobileSelectImageRatioModalGrid {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 16px;
            padding: 32px 0 0 0;

            .mobileSelectImageRatioModalGridItem {
                .mobileSelectBoxAlignment {
                    border-radius: 6.5px;
                    border: 1px solid #ACB1C0;
                    background: #F8F8F8;
                    width: 100%;
                    height: 54px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    &.secondBox {
                        height: 96px;
                    }

                    &.thirdBox {
                        height: 82px;
                    }

                    &.forthBox {
                        height: 68px;
                    }

                    p {
                        color: #808191;
                        text-align: center;
                        font-family: 'Poppins', sans-serif;
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 19px;

                    }
                }
            }
        }
    }
}