.bookMarkModalAlignment {
  border-radius: 10px;
  background: #fff;
  width: 100%;
  height: calc(100vh - 96px);
  padding: 0 32px 32px 32px;
  overflow-y: auto;
  position: relative;

  @media only screen and (max-width: '1024px') {
    height: 100%;
  }

  @media only screen and (max-width: '767px') {
    height: 100%;
    padding: 0;
  }

  &.modalOvrflowHiddenClass {
    overflow-y: hidden;
  }

  .bookMarkModalHeading {
    padding-top: 32px;
    h5 {
      color: var(--bookmark-heading-color);
      font-family: 'Poppins', sans-serif;
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
    }

    @media only screen and (max-width: '1024px') {
      display: none;
    }
  }

  .bookMarkMobileViewHeaderAlignment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: none;

    @media only screen and (max-width: '1024px') {
      display: flex;
      padding: 8px 16px;
    }

    h5 {
      color: var(--secondary-900);
      font-family: 'Poppins', sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
    }

    .bookMarkOptionAlignment {
      svg {
        cursor: pointer;
      }
    }
  }

  .bookMarkTabAlignment {
    padding: 32px 0 8px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--secondary-100);

    @media only screen and (max-width: '1024px') {
      padding: 16px 18px 8px 16px;
    }

    ul {
      display: flex;
      align-items: center;
      gap: 32px;
      margin: 0;
      // padding: 0 0 18px 0;
      // border-bottom: 1px solid var(--secondary-100);

      li {
        color: var(--secondary-500);
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        transition: 0.4s ease-in-out;
        position: relative;
        cursor: pointer;

        &:hover {
          transition: 0.4s ease-in-out;
          color: var(--primary-700);

          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 3px;
            border-radius: 4px 4px 0px 0px;
            background: var(--primary-700);
            left: 0;
            bottom: -18px;
            transition: 0.4s ease-in-out;
            z-index: 99;

            @media only screen and (max-width: '1024px') {
              bottom: -8px;
            }
          }
        }

        &.activeTabAlignment {
          transition: 0.4s ease-in-out;
          color: var(--primary-700);

          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 3px;
            border-radius: 4px 4px 0px 0px;
            background: var(--primary-700);
            left: 0;
            bottom: -18px;
            transition: 0.4s ease-in-out;
            z-index: 99;

            @media only screen and (max-width: '1024px') {
              bottom: -8px;
            }
          }
        }
      }
    }

    .searchAlignment {
      width: 391px;
      height: 40px;
      background: var(--light-grey);

      border-radius: 8px;
      position: relative;

      @media only screen and (max-width: '1024px') {
        display: none;
      }

      input {
        width: 100%;
        height: 100%;

        color: var(--secondary-90);
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        border: none;
        background: transparent;
        padding: 0 16px 0 40px;
      }

      ::placeholder {
        color: var(--grey3);
      }

      .searchIconAlignment {
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        height: 40px;
        cursor: pointer;
      }
    }
  }

  .mobileViewSeachAlignment {
    padding: 16px;
    display: none;

    .mobileViewSearchBox {
      background: var(--light-grey);
      width: 100%;
      height: 40px;
      border-radius: 8px;
      position: relative;

      input {
        width: 100%;
        height: 100%;
        color: var(--secondary-900);
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        padding: 0 16px 0 40px;
        background: transparent;
        border: none;

        &::placeholder {
          color: var(--grey3);
        }
      }

      .mobileViewIcon {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
        width: 40px;
        height: 40px;
      }
    }

    @media only screen and (max-width: '1024px') {
      display: block;
    }
  }

  .mobileVIewBookMarkDetailsAlignment {
    @media only screen and (max-width: '1024px') {
      height: calc(100vh - 48px - 46px);
      overflow-y: auto;
    }
  }
}
