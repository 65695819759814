.bookMarkProductCardAlignment {
  width: 100%;
  height: 307px;
  border-radius: 8px;
  position: relative;
  transition: 0.4s ease-in-out;
  cursor: pointer;
  overflow: hidden;
  @media only screen and (max-width: '1024px') {
    height: auto;
  }
  .modileViewImg {
    .imagecontainer {
      height: 296px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        border-radius: 8px;
        transform: scale(1);
        transition: 0.4s ease-in-out;
      }
    }
    @media only screen and (max-width: '1024px') {
      height: 164px;
      position: relative;
      .mobileViewBookMarkAlignment {
        display: none;
        @media only screen and (max-width: '1024px') {
          display: block;
          position: absolute;
          bottom: 8px;
          right: 8px;
          width: 32px;
          height: 32px;
          min-width: 32px;
          max-width: 32px;
          border-radius: 8px;
          background: rgba(30, 36, 50, 0.6);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .bookmarkBottomDetailsAlignment {
    position: absolute;
    bottom: 16px;
    left: 16px;
    right: 20px;
    width: calc(100% - 36px);
    z-index: 99;
    opacity: 0;
    @media only screen and (max-width: '1024px') {
      opacity: 1;
      position: static;
      padding: 5px 0 0 0;
      width: 100%;
    }
    .bookMarkProductName {
      padding: 0 0 4px 0;

      h6 {
        max-width: 163px;
        color: var(--light-grey);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;

        @media only screen and (max-width: '1024px') {
          color: var(--secondary-900);
          max-width: 159px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 13px;
          line-height: 19px;
          font-weight: 400;
        }
      }
    }
    .priceAlignmentFlex {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        color: var(--light-grey);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        @media only screen and (max-width: '1024px') {
          color: var(--secondary-900);
          font-weight: 600;
        }
      }

      .rightSideAlignment {
        display: flex;
        align-items: center;
        gap: 12px;

        .rightSideOption {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          min-width: 24px;
          max-width: 24px;
          cursor: pointer;
          transition: .4s ease-in-out;
        
          &:hover {
            svg {
              path {
                fill: #fff;
              }
            }
          }

          svg {
            transition: .4s ease-in-out;
          }
        }
      }
    }
  }

  .bookMarkTopDetailsAlignment {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 99;
    opacity: 0;
    @media only screen and (max-width: '1024px') {
      opacity: 1;
      top: 8px;
      right: 8px;
    }

    .bookMarkBoxAlignment {
      border-radius: 8px;
      background: var(--black-6);
      padding: 4px;
      display: flex;
      align-items: center;
      transition: 0.4s ease-in-out;
      position: relative;

      @media only screen and (max-width: '1024px') {
        padding: 4px 8px;
      }

      .bookMarkOptionBox {
        width: 24px;
        height: 24px;
        min-width: 24px;
        max-width: 24px;
        cursor: pointer;
        @media only screen and (max-width: '1024px') {
          width: 16px;
          height: 16px;
          min-width: 16px;
          max-width: 16px;

          svg {
            width: 100%;
            height: 100%;
          }
        }
      }

      .hoverDetails {
        // background: url('../../../../../public/assets/img/tooltips-img.svg');
        // position: absolute;
        // width: 209px;
        // height: 52px;
        // top: 42px;
        // left: 0;
        // transform: translateX(-50%);
        // padding: 10px 0 0 0;
        // display: none;
        // background-repeat: no-repeat;
        // p {
        //   color: var(--white);
        //   text-align: center;
        //   font-size: 14px;
        //   font-weight: 500;
        //   line-height: 21px;
        // }

        // &::before,
        // ::after {
        //   --scale: 0;
        //   position: absolute;
        //   font-size: 1rem;
        //   transition: transform 100ms;
        //   transition-timing-function: linear;
        //   --tooltip-color: orange;
        //   --arrow-size: 0.5rem;
        //   --scale: 0;

        //   z-index: 1;
        //   font-size: 1rem;
        //   transform: translate(var(--translate-x), var(--translate-y)) scale(var(--scale));
        // }
        display: none;

        button {
          width: 150px;
          height: 50px;
          background-color: royalblue;
          color: white;
          border: none;
          outline: none;
          font-weight: 600;
          border-radius: 20px;
          cursor: pointer;
        }
      }

      &:hover {
        transition: 0.4s ease-in-out;
        .hoverDetails {
          display: block;
          &::before,
          &::after {
            --scale: 1;
            transition-timing-function: cubic-bezier(0.25, 0.1, 0.45, 1.93);
          }
        }
      }
    }

    .bookMarkNotesALignment {
      padding: 2px 8px;
      border-radius: 12px;
      background: var(--black-6);
      cursor: pointer;
      @media only screen and (max-width: '699px') {
        padding: 1px 6px;
      }
      p {
        color: var(--white);
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        @media only screen and (max-width: '699px') {
          max-width: 73px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 12px;
          line-height: 18px;
        }
      }
    }

    .bookMarkMobileViewMoreOptionAlignment {
      position: absolute;
      top: 0;
      right: 0;
      display: none;
      @media only screen and (max-width: '1024px') {
        display: block;
      }
    }
  }

  &:hover {
    transition: 0.4s ease-in-out;
    .modileViewImg {
      .imagecontainer {
        img {
          transform: rotate(-7.05deg) scale(1.2);
          transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
          @media only screen and (max-width: '1024px') {
            transform: rotate(0deg) scale(1);
          }
        }
      }
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 159px;
      border-radius: 0px 0px 8px 8px;
      background: linear-gradient(180deg, rgba(30, 36, 50, 0) 0%, rgba(30, 36, 50, 0.8) 100%);
      z-index: 9;
      @media only screen and (max-width: '1024px') {
        display: none;
      }
    }
    .bookmarkBottomDetailsAlignment {
      opacity: 1;
    }
    .bookMarkTopDetailsAlignment {
      opacity: 1;
    }
  }
}
