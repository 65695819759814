.addProductModalVariantSection {
  margin: 0 0 16px 0;

  .addProductModalVariantBox {

    background: #FFF;
    // padding: 10px 16px;

    border: 1px solid var(--secondary-50);
    border-radius: 10px;

    .addProductTopBoxAlignment {}

    .addProductModalHeadingFlex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 16px;
      // flex-wrap: wrap;
      // flex-direction: column-reverse;
      // gap: 10px ;

      .addProductHeadingAlignment {
        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          color: var(--secondary-900);
        }

        .addProductNotesAlignment {
          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: #495057;
          }
        }
      }

      .addProductButtonAlignment {
        display: flex;
        align-items: center;
        gap: 16px;

        @media only screen and (max-width:"767px") {
          display: none;

        }

        a {
          cursor: pointer;
          color: var(--primary-500);
          text-align: center;
          font-family: 'Poppins', sans-serif;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;
        }

        .saveChangesButtonAlignment {
          button {
            padding: 6px 26px;
            border-radius: 8px;
            background: var(--primary-500);
            color: var(--light-grey);
            text-align: center;
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            border: none;
            cursor: pointer;
            white-space: nowrap;

            @media only screen and (max-width:"1499px") {
              padding: 6px 18px;
            }
          }
        }
      }
    }


    .variantInfoMain {
      margin-top: 10px;
      margin-bottom: 0;

      .sizeColorContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: all 0.4s ease-in-out;
        cursor: pointer;
        padding: 6px 16px;

        .nameVariantSection {
          display: flex;
          gap: 18px;
          align-items: center;

          .sizeTitle {
            cursor: context-menu;

            @media only screen and (max-width: '767px') {
              width: 48px;
              max-width: 48px;
            }

            span {
              font-weight: 500;
              font-size: 13px;
              line-height: 20px;
              display: flex;
              align-items: center;
              color: var(--secondary-900);
            }
          }

          .sizeInput {
            cursor: context-menu;

            @media only screen and (max-width: '767px') {
              width: 130px;
              max-width: 130px;
            }

            span {
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              display: flex;
              align-items: center;
              color: #808191;
            }
          }
        }

        .removeEditSection {
          gap: 16px;
          align-items: center;
          display: none;

          @media only screen and (max-width: '767px') {
            display: flex;
          }

          .removeVariant {
            cursor: pointer;

            span {
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: var(--primary-500);
            }
          }

          .editVariant {
            cursor: pointer;

            @media only screen and (max-width: '767px') {
              display: none;
            }

            span {
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: var(--primary-500);
            }
          }

          .mobieViewEditVariant {
            cursor: pointer;
            display: none;

            @media only screen and (max-width: '767px') {
              display: block;
            }

            span {
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: var(--primary-500);
            }
          }
        }

        &:hover {
          transition: all 0.4s ease-in-out;
          background: var(--light-grey);

          .removeEditSection {
            display: flex;
          }
        }
      }
    }

    .noVariantAlignment {
      padding: 35px 0;
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #808191;
      text-align: center;

      @media only screen and (max-width: '767px') {
        display: none;
      }
    }

    .addVariatButtonAlignment {
      padding: 18px 16px;

      @media only screen and (max-width: '767px') {
        padding: 16px 0 0 0;
      }

      button {
        border-radius: 10px;
        padding: 0;
        width: 100%;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        line-height: 21px;
        color: var(--primary-500);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 6px;
        cursor: pointer;
        padding: 6px 16px;

        &.webViewButtonAlignment {
          @media only screen and (max-width: '767px') {
            display: none;
          }
        }

        &.mobileViewButtonAlignment {
          display: none;

          @media only screen and (max-width: '767px') {
            display: flex;
          }
        }
      }
    }
  }
}

.allProductDetailsAlignment {
  // margin: 16px 0 0 0;

  .allProductDetailsBoxAlignment {
    // border-radius: 10px;
    // border: 1px solid var(--secondary-50);

    .allProductVariantDetailsHeading {
      padding: 16px 16px 25px 16px;

      h4 {
        color: var(--secondary-900);
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
      }
    }

    .allProdutcTabAlignment {
      padding: 0 16px;
      display: flex;
      align-items: center;
      gap: 8px;

      @media only screen and (max-width: '767px') {
        display: none;
      }

      .allProdutcVariantTabBox {
        border-radius: 8px;
        border: 1px solid #E4E4E8;
        background: #FFF;
        min-width: 90px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        cursor: pointer;
        padding: 0 10px 0 8px;
        position: relative;

        p {
          color: var(--primary-500);
          font-family: 'Poppins', sans-serif;
          font-size: 14px;
          font-weight: 500;
          line-height: 21px;

          @media only screen and (max-width:"576px") {
            font-size: 12px;
            line-height: 18px;
          }

        }

        img {
          transition: 0.4s ease-in-out;

          &.productActiveArrow {
            transition: 0.4s ease-in-out;

            transform: rotate(-180deg);
          }
        }

        .allProductVarintTabDropdownBox {
          position: absolute;
          top: 40px;
          width: 100%;
          left: 0;
          border-radius: 10px;
          z-index: 99999;
          background: #FFF;
          box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.12);

          &.colorWidthAlignment {
            width: fit-content;
          }

          .allProdutctoptionAlignment {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 4px;
            padding: 4px 16px;
            transition: all 0.4s ease-in-out;

            .colorBoxAlignment {
              width: 16px;
              min-width: 16px;
              max-width: 16px;
              height: 16px;
              border-radius: 4px;
              background: conic-gradient(from 180deg at 50% 50%, #F00 0deg, #FFF500 115.2117669582367deg, #0075FF 229.73557949066162deg, #F00 360deg);
            }

            p {
              color: var(--secondary-900);
              font-family: 'Poppins', sans-serif;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              white-space: nowrap;
            }

            &:hover {
              transition: all 0.4s ease-in-out;
              background: var(--secondary-50);
            }
          }

          &.closeVariantDropdown {
            max-height: 0px;
            overflow-y: hidden;
            transition: all 0.4s ease-in-out;

          }

          &.openVariantDropdown {
            border: 1px solid #FFF;

            max-height: 176px;
            padding: 12px 0;
            transition: all 0.4s ease-in-out;
            overflow-y: auto;
          }
        }
      }
    }

    .addProductTableAlignment {
      padding: 14px 0 0 0;

      @media only screen and (max-width: '767px') {
        display: none;
      }

      .addProductTable {
        min-width: 100%;
        overflow-x: auto;


        table {
          width: 100%;

          thead {
            tr {
              border-bottom: 1px solid var(--secondary-50);

              th {
                padding: 8px 16px;
                color: var(--secondary-900);
                font-family: 'Poppins', sans-serif;
                font-size: 13px;
                font-weight: 600;
                line-height: 20px;
                text-align: center;
              }
            }
          }

          tbody {
            tr {
              td {
                padding: 8px 0;

                .variantCheckBoxAlignment {
                  padding: 8px 16px;
                }

                &.responisvePadding {

                  @media only screen and (max-width:"1499px") {
                    padding: 8px 4px 8px 0;
                  }
                }

                .variantImagesAlignment {
                  width: 68px;
                  height: 68px;
                  margin: 0 auto;

                  @media only screen and (max-width:"1499px") {
                    width: 50px;
                    height: 50px;
                  }

                  .noImgBoxAlignment {
                    background: rgba(85, 110, 230, 0.13);
                    border: 2px dashed #b1c2f3;
                    border-radius: 8px;
                    width: 100%;
                    height: 100%;
                    position: relative;
                    padding: 16px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    p {
                      font-family: 'Poppins', sans-serif;
                      font-size: 12px;
                      font-weight: 400;
                      line-height: 14px;
                      color: var(--secondary-900);
                    }

                    input[type="file"] {
                      width: 100%;
                      height: 100%;
                      position: absolute;
                      top: 0;
                      left: 0;
                      z-index: 99;
                      text-align: 9999px;
                      cursor: pointer;
                      opacity: 0;
                    }
                  }

                  img {
                    width: 100%;
                    height: 100%;
                    display: block;
                    border-radius: 10px;
                    object-fit: cover;
                  }
                }

                .variantDetailsAlignment {
                  padding: 0 8px;
                  h6 {
                    color: var(--secondary-900);
                    font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 21px;

                    @media only screen and (max-width:"1499px") {
                      font-size: 12px;
                      line-height: 18px;
                    }

                  }

                  p {
                    color: #6DAF00;
                    font-family: 'Poppins', sans-serif;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14px;
                    padding: 2px 0 0 0;

                    &.unAvailbleText {
                      color: var(--secondary-300);
                    }
                  }
                }

                .variantProceInputAlignment {
                  width: 76px;
                  height: 32px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 0 8px;
                  border: 1px solid var(--grey4);
                  border-radius: 8px;

                  // @media only screen and (max-width:"1499px") {
                  //   width: 100%;

                  // }

                  span {
                    color: var(--grey3);
                    font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 21px;


                    @media only screen and (max-width:"1499px") {
                      font-size: 12px;
                    }

                  }

                  input {
                    width: 100%;
                    height: 100%;
                    background: #FFF;
                    color: var(--grey2);
                    border: none;
                    text-align: right;
                    font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                  }
                }

                .variantQtyInputAlignment {
                  width: 78px;
                  height: 32px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 0 8px;
                  border: 1px solid var(--grey4);
                  border-radius: 8px;

                  // @media only screen and (max-width:"1499px") {
                  //   width: 100%;

                  // }

                  input {
                    width: 100%;
                    height: 100%;
                    background: #FFF;
                    color: var(--grey2);
                    border: none;
                    text-align: center;
                    font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                  }
                }

                .varianSkuInputAlignment {
                  width: 125px;
                  height: 32px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 0 8px;
                  border: 1px solid var(--grey4);
                  border-radius: 8px;

                  @media only screen and (max-width:"1499px") {
                    width: 100%;

                  }

                  input {
                    width: 100%;
                    height: 100%;
                    background: #FFF;
                    color: var(--grey2);
                    border: none;
                    text-align: center;
                    font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                  }
                }

                .actionDetailsAlignment {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: 18px;
                  padding: 0 16px;

                  @media only screen and (max-width:"1499px") {
                    gap: 8px;
                  }

                  button {
                    background: transparent;
                    border: none;
                    color: var(--primary-500);
                    font-family: 'Poppins', sans-serif;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14px;
                    padding: 0;
                    margin: 0;
                    cursor: pointer;
                    white-space: nowrap;
                  }

                  .resetIconAlignment {
                    width: 20px;
                    height: 20px;
                    cursor: pointer;

                    img {
                      width: 100%;
                      height: 100%;
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .addProductTable table thead tr th:nth-child(1) {
        width: 4%;
      }

      .addProductTable table thead tr th:nth-child(2) {
        width: 16%;
      }

      .addProductTable table thead tr th:nth-child(3) {
        width: 20%;
      }

      .addProductTable table thead tr th:nth-child(4) {
        width: 12%;
      }

      .addProductTable table thead tr th:nth-child(5) {
        width: 12%;
      }

      .addProductTable table thead tr th:nth-child(6) {
        width: 12%;
      }

      .addProductTable table thead tr th:nth-child(7) {
        width: 24%;
      }
    }

    .filterRelative {
      position: relative;
      margin: 0 0 24px 0;

      .addProdutcFilterAlignment {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 106px;
        margin: 0 16px;

        .addProductLeftSideAlignment {
          display: flex;
          align-items: flex-start;
          gap: 8px;

          .addProductFilterBox {
            cursor: pointer;
            padding: 0 16px 0 8px;
            height: 32px;
            display: flex;
            align-items: center;
            gap: 8px;
            border-radius: 8px;
            border: 0.5px solid var(--grey4);
            background: var(--light-grey);
            transition: all 0.4s ease-in-out;

            &:hover {
              transition: all 0.4s ease-in-out;
              border: 0.5px solid var(--grey4);
              background: var(--primary-50);

              svg {
                path {
                  stroke: var(--primary-700);
                }
              }

              h6 {
                color: var(--primary-700);
              }
            }

            h6 {
              color: var(--secondary-700);
              font-family: 'Poppins', sans-serif;
              font-size: 14px;
              font-weight: 500;
              line-height: 21px;
            }
          }

          .allAddFilterDetailsAlignment {

            .addProductAllFilterListAlignment {
              display: flex;
              align-items: center;
              gap: 3px;
              margin: 0 0 10px 0;
              flex-wrap: wrap;

              &:last-child {
                margin: 0;
              }

              .sizeBoxFilterAlignment {
                display: flex;
                align-items: center;
                gap: 5px;

                .sizeDetailsBox {
                  min-width: 94px;
                  height: 32px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 0 6px 0 12px;
                  border-radius: 16px;
                  background: var(--light-grey);
                  cursor: pointer;

                  p {
                    color: var(--secondary-900);
                    font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                  }
                }

                .colorBoxAlignment {
                  min-width: 94px;
                  height: 32px;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  padding: 0 6px 0 12px;
                  border-radius: 16px;
                  background: var(--light-grey);
                  cursor: pointer;

                  .colorBoxDetails {
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    .colorShowBox {
                      width: 16px;
                      min-width: 16px;
                      max-width: 16px;
                      height: 16px;
                      border-radius: 4px;
                      background: #F10000;

                      &.blueColor {
                        background: #06F;
                      }
                    }

                    p {
                      color: var(--secondary-900);
                      font-family: 'Poppins', sans-serif;
                      font-size: 16px;
                      font-weight: 400;
                      line-height: 24px;
                    }
                  }

                }
              }

              .colorBoxMainAlignment {

                display: flex;
                align-items: center;
                gap: 8px;


              }
            }
          }

        }

        .clearFilterAlignment {
          margin: 0 16px 0 0;

          a {
            color: var(--primary-500);
            font-family: 'Poppins', sans-serif;
            font-size: 15px;
            font-weight: 400;
            line-height: 22px;
            cursor: pointer;
            white-space: nowrap;
          }
        }

        .addProdutcMain {
          padding: 0 8px 0 0;
          position: absolute;
          top: 40px;
          left: 0;
          width: 100%;
          z-index: 99999999;
          box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.12);
          border-radius: 10px;
          border: 1px solid #FFF;
          background: #FFF;

          .addProductFilterAllDetailsBoxAlignment {
            height: auto;
            max-height: 380px;
            padding: 0 14px 32px 32px;
            overflow-y: auto;

            &::-webkit-scrollbar {
              width: 2px !important;
              display: block;
            }

            &::-webkit-scrollbar-track {
              background: transparent !important;
            }

            &::-webkit-scrollbar-thumb {
              display: block;
              background: #D9D9D9 !important;
              border-radius: 18px !important;
            }

            .addProdutcHeadingAlignment {
              display: flex;
              align-items: center;
              justify-content: space-between;
              position: sticky;
              top: 0;
              background: #FFF;
              left: 0;
              z-index: 99;
              padding: 32px 0;

              h6 {
                color: var(--secondary-900);
                font-family: 'Poppins', sans-serif;
                font-size: 20px;
                font-weight: 500;
                line-height: 24px;
              }

              a {
                color: #E71616;
                font-family: 'Poppins', sans-serif;
                font-size: 15px;
                font-weight: 500;
                line-height: 22px;
                cursor: pointer;
              }
            }

            .addProductBodyAlignment {


              .addProdutcBodyChildDetails {
                margin: 0 0 32px 0;

                // &:last-child {
                //   margin: 0;
                // }

                label {
                  color: var(--secondary-900);
                  font-family: 'Poppins', sans-serif;
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 18px;
                  margin: 0;
                }

                .addProdutcAllChildList {
                  margin: 16px 0 0 0;
                  display: flex;
                  align-items: center;
                  flex-wrap: wrap;
                  gap: 8px;

                  .addChildListBox {
                    height: 31px;
                    padding: 0 14px;
                    border-radius: 16px;
                    background: var(--light-grey);
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    transition: all 0.4s ease-in-out;
                    gap: 4px;

                    .colorBoxAlignment {
                      width: 16px;
                      height: 16px;
                      min-width: 16px;
                      max-width: 16px;
                      border-radius: 4px;
                      background: #F10000;

                      &.blueBg {
                        background: #128AF8;
                      }

                      &.yellowBg {
                        background: #F8C200;
                      }

                      &.purpleBg {
                        background: #6A00F1;
                      }

                      &.darkBlue {
                        background: #00308D;
                      }

                      &.greenBg {
                        background: #5BA300;
                      }
                    }

                    p {
                      color: var(--secondary-500);
                      font-family: 'Poppins', sans-serif;
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 21px;
                    }

                    &:hover {
                      border-radius: 16px;
                      background: var(--primary-50);
                      transition: all 0.4s ease-in-out;

                      p {
                        color: var(--secondary-900);
                        font-weight: 500;
                      }
                    }

                    &.activeDetails {
                      border-radius: 16px;
                      background: var(--primary-50);
                      transition: all 0.4s ease-in-out;

                      p {
                        color: var(--secondary-900);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }


    .mobileViewMangeProductAlignment {
      display: none;

      @media only screen and (max-width: '767px') {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px 16px 16px 16px;

        h4 {
          color: var(--secondary-900);
          font-family: 'Poppins', sans-serif;
          font-size: 16px;
          font-weight: 500;
          line-height: 21px;
        }

        .moreManageMobileViewAlignment {
          width: 20px;
          height: 20px;
          min-width: 20px;
          max-width: 20px;
        }
      }
    }
  }
}