@mixin block_btn {
  background: var(--primary-500);
  border-radius: 8px;
  display: block;
  width: 100%;
  border: unset;
  outline: unset;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    transform: translateY(-3px);
    background: #2844ac;
  }
}

.men-fashionGroups-section {
  background: #151515;
  height: calc(100vh - 64px);
  // height: calc(100vh - 104px);
  overflow-y: auto;

  @media only screen and (max-width: '1099px') {
    height: 100vh;
  }

  .men-fashionGroups-back-alignment {
    display: flex;
    align-items: center;
    gap: 22px;
    padding: 8px 10px;
    background: #1e2432;
    display: none;

    @media only screen and (max-width: '767px') {
      display: flex;
      position: sticky;
      top: 0;
      z-index: 99;
    }

    .back_details-alignment {
      background: rgba(172, 177, 192, 0.4);
      border-radius: 10px;
      padding: 4px 9px 4px 10px;
      display: flex;
      align-items: center;
      gap: 8px;

      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #e4e4e8;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 58px;
      }
    }

    .mobile-heading-alignment {
      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #f8f8f8;
      }
    }
  }

  .fashionGroups-banner {
    background: #151515;
    // background-image: url("../../../assets/img/MensCategory/FashionGroupBg.svg");
    // width: 100%;
    // height: 100%;
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: center;
    position: sticky;
    top: 0;
    z-index: 99;

    @media only screen and (max-width: '1199px') {
      top: 0;
    }

    @media only screen and (max-width: '767px') {
      // background-image: url("../../../assets/img/MensCategory/mobileFashionGroupBg.svg");
      // background-size: cover;
      padding: 0 16px;
      position: sticky;
      top: 48px;
      z-index: 99;
    }

    .men-fashionGroups-banner-img-alignment {
      position: absolute;
      top: 0;
      right: 0;

      img {
        width: 100%;
        height: 178px;
        object-fit: cover;
        object-position: center;
        display: block;

        @media only screen and (max-width: '767px') {
          display: none;
        }

        &.mobile-view-banner {
          display: none;

          @media only screen and (max-width: '767px') {
            display: block;
            height: 100px;
            display: block;
          }
        }
      }
    }

    .container {
      max-width: 1440px;
      margin: auto;
      padding: 0 20px;

      @media only screen and (max-width: '767px') {
        padding: 0;
      }

      div {
        img {
          cursor: pointer;
          display: block;
        }
      }

      .fashionGroups-banner-section {
        height: 178px;
        display: flex;
        align-items: center;
        gap: 160.5px;
        position: relative;

        @media only screen and (max-width: '1299px') {
          gap: 80px;
          padding: 0 20px;
        }

        @media only screen and (max-width: '767px') {
          height: 100px;
        }

        .left-arrow-alignment {
          @media only screen and (max-width: '767px') {
            display: none;
          }
        }
      }

      .fashionGroups-heading-alignment {
        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 40px;
          line-height: 60px;
          color: #f8f8f8;
          padding: 0 0 4px 0;
          text-transform: uppercase;

          @media only screen and (max-width: '767px') {
            font-size: 20px;
            line-height: 30px;
          }
        }

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 300;
          font-size: 18px;
          line-height: 27px;
          color: #f8f8f8;
          text-transform: capitalize;

          @media only screen and (max-width: '767px') {
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 200px;
          }
        }
      }
    }
  }

  .fashionGroups-details-alignment {
    padding: 28px 0;
    background-color: var(--secondary-900);
    height: calc(100vh - 178px);
    overflow-y: auto;

    @media only screen and (max-width: '1024px') {
      padding: 16px;
      height: 100%;
      overflow-y: auto
    }
    @media only screen and (max-width: '576px') {
      padding: 16px 16px 90px 16px;
    }

    .container {
      max-width: 1300px;
      margin: auto;
      padding: 0 20px;

      @media only screen and (max-width: '799px') {
        padding: 0;
      }

      .fashion_group_details_grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;


        @media only screen and (max-width: '1024px') {
          grid-template-columns: repeat(2, 1fr);
        }


        @media only screen and (max-width: '799px') {
          grid-template-columns: repeat(2, 1fr);
        }


        @media only screen and (max-width: '599px') {
          grid-template-columns: repeat(1, 1fr);
        }

        .fashion_group_slider_cover {
          padding: 0;

          .card_fashion {
            width: 100%;
            position: relative;
            border-radius: 10px 10px 0px 0px;
            overflow: hidden;
            transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
            height: 251px;
            max-height: 251px;

            &:hover {
              transform: translateY(-5px);

              .img_cover img {
                transform: scale(1.05);
              }
            }

            .img_cover {
              object-fit: cover;
              object-position: center;
              height: 251px;
              max-height: 251px;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
              }
            }

            .card_details {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: flex-end;
              padding: 15px;
              background: linear-gradient(360deg, #0e1116 0%, rgba(30, 36, 50, 0) 100%);

              .card_details_cover {
                width: 100%;

                h5 {
                  font-weight: 500;
                  font-size: 20px;
                  line-height: 30px;
                  color: #ffffff;
                }

                p {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 18px;
                  color: #ffffff;
                  margin: 4px 0 17px 0;
                }

                .users {
                  width: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  .left_part {
                    display: flex;
                    align-items: center;

                    .users_likes {
                      display: flex;
                      align-items: center;
                      margin-left: -10px;

                      .user_box {
                        width: 20px;
                        display: flex;
                        align-items: center;
                        height: 20px;
                        justify-content: center;
                        background: #fff;
                        object-position: center;
                        border: 1px solid #ffffff;
                        border-radius: 30px;

                        img {
                          width: 100%;
                          object-fit: cover !important;
                        }
                      }
                    }

                    .count {
                      margin-left: -10px;
                      background: var(--primary-500);
                      border: 1px solid #ffffff;
                      border-radius: 12px;
                      font-weight: 500;
                      font-size: 12px;
                      line-height: 18px;
                      letter-spacing: 0.1px;
                      color: #ffffff;
                      padding: 0px 9px;
                    }
                  }

                  .right_part {
                    max-width: 120px;

                    button {
                      @include block_btn();
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}