.liveStreamModalSection {
    height: 100%;

    .liveStreamModalGrid {
        display: grid;
        grid-template-columns: 1fr 450px;
        gap: 16px;
        height: 100%;

        @media only screen and (max-width:"1024px") {
            display: block;

        }

        .liveStreamModalGridItem {
            .liveStreamModalLeftBox {
                border-radius: 10px;
                background: #FFF;
                box-shadow: 0px 5.06px 50.603px 0px rgba(144, 143, 143, 0.10);
                width: 100%;
                height: 100%;
                padding: 12px 16px;

                @media only screen and (max-width:"1024px") {
                    padding: 0;
                }

                .liveStreamHeading {
                    @media only screen and (max-width:"1024px") {
                        display: none;
                    }

                    h4 {
                        color: var(--secondary-900);
                        text-align: center;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                    }
                }

                .mobileViewLiveStremHeaidng {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 8px 16px 0 16px;
                    display: none;

                    @media only screen and (max-width:"1024px") {
                        display: flex;
                    }

                    h4 {
                        color: var(--secondary-900);
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 21px;
                    }


                    .headerOptionAlignment {
                        display: flex;
                        align-items: center;
                        gap: 4px;

                        .optionBox {
                            width: 44px;
                            height: 32px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }

                .liveStreamSecondHeading {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 24px 16px;
                    position: relative;
                    z-index: 9;

                    @media only screen and (max-width:"1024px") {
                        padding: 8px 16px;
                    }

                    .liveStreamProfile {
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        @media only screen and (max-width:"1024px") {
                            gap: 4px;
                        }

                        .profileImg {
                            width: 40px;
                            height: 40px;

                            @media only screen and (max-width:"1024px") {
                                width: 20px;
                                height: 20px;
                            }

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius: 4px;
                            }
                        }

                        .profileNamesAlignment {
                            h6 {
                                color: var(--secondary-900);
                                font-size: 14px;
                                font-weight: 600;
                                line-height: 22px;

                                @media only screen and (max-width:"1024px") {
                                    font-size: 12px;
                                    line-height: 18px;
                                }
                            }

                            .profileRate {

                                span {
                                    color: var(--secondary-500);
                                    font-size: 12px;
                                    font-weight: 600;
                                    line-height: 18px;
                                }

                                .profileStar {
                                    display: flex;
                                    align-items: center;

                                    svg {
                                        cursor: pointer;
                                    }
                                }
                            }
                        }

                        .profileNamesAlignment {
                            .profileRate {
                                display: flex;
                                align-items: center;
                                gap: 6px;

                                @media only screen and (max-width:"1024px") {
                                    display: none;
                                }

                            }
                        }
                    }

                    .profileLiveViewAllDetails {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        position: absolute;
                        top: 30px;
                        left: 50%;
                        transform: translateX(-50%);

                        @media only screen and (max-width:"1024px") {
                            position: static;
                            transform: translateX(0%);
                        }

                        .liveBox {
                            padding: 3px 6px;
                            border-radius: 8px;
                            background: #E71616;
                            display: flex;
                            align-items: center;
                            gap: 4px;
                            cursor: pointer;

                            @media only screen and (max-width:"1024px") {
                                padding: 3px 6px 3px 4px;
                            }

                            span {
                                color: #FFF;
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 18px;
                            }
                        }

                        .liveTime {
                            padding: 3px 10px 3px 8px;
                            border-radius: 8px;
                            background: var(--light-grey);
                            cursor: pointer;

                            span {
                                color: var(--secondary-500);
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 18px;
                            }
                        }

                        .liveView {
                            padding: 3px 10px 3px 8px;
                            border-radius: 8px;
                            background: var(--light-grey);
                            display: flex;
                            align-items: center;
                            gap: 4px;
                            position: relative;
                            cursor: pointer;


                            span {
                                color: var(--secondary-500);
                                font-size: 12px;
                                font-weight: 500;
                                line-height: 18px;
                            }

                            .liveViewProfileDetails {
                                width: 390px;
                                border-radius: 16px;
                                background: #FFF;
                                box-shadow: 0px 5.6px 17.6px rgba(30, 36, 50, 0.16);
                                position: absolute;
                                top: 36px;
                                left: 0;
                                z-index: 9999999;
                                overflow-y: auto;

                                &.openLiveVieeProfileBox {
                                    max-height: 560px;
                                    overflow-y: auto;
                                    transition: 0.4s ease-in-out;
                                }

                                &.closeLiveVieeProfileBox {
                                    max-height: 0px;
                                    overflow-y: hidden;
                                    transition: 0.4s ease-in-out;
                                }

                                @media only screen and (max-width:"1024px") {
                                    width: 300px;
                                    left: unset;
                                    right: 0;
                                }

                                .liveViewProfileAll {
                                    border: 2px solid #FFF;
                                    padding: 0 24px;

                                    @media only screen and (max-width:"1024px") {
                                        padding: 0 16px;
                                    }

                                    .liveProfileHeading {
                                        padding: 32px 0;
                                        background: #FFF;
                                        position: sticky;
                                        top: 2px;
                                        left: 0;

                                        @media only screen and (max-width:"1024px") {
                                            padding: 20px 0;
                                        }

                                        h6 {
                                            color: var(--secondary-900);
                                            font-size: 24px;
                                            font-weight: 600;
                                            line-height: 28px;

                                            @media only screen and (max-width:"1024px") {
                                                font-size: 20px;
                                                line-height: 24px;
                                            }
                                        }
                                    }

                                    .liveProfileAllBodyDetails {
                                        .liveProfileNameImg {

                                            display: flex;
                                            align-items: center;
                                            gap: 16px;
                                            margin: 0 0 16px 0;

                                            @media only screen and (max-width:"1024px") {
                                                gap: 8px;
                                                margin: 0 0 8px 0;
                                            }

                                            .liveProfileImg {
                                                width: 40px;
                                                height: 40px;

                                                @media only screen and (max-width:"1024px") {
                                                    width: 32px;
                                                    height: 32px;
                                                }

                                                img {
                                                    width: 100%;
                                                    height: 100%;
                                                    display: block;
                                                    border-radius: 50%;
                                                    object-fit: cover;
                                                }
                                            }

                                            p {
                                                color: var(--secondary-900);
                                                font-size: 16px;
                                                font-weight: 500;
                                                line-height: 24px;
                                                margin: 0;
                                                padding: 0;
                                                @media only screen and (max-width:"1024px") {
                                                    font-size: 14px;
                                                    line-height: 21px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }


                            &:hover {
                                border-radius: 8px;
                                background: var(--primary-50);

                                span {
                                    color: var(--primary-500);
                                }

                                svg {
                                    path {
                                        stroke: var(--primary-500);
                                    }
                                }
                            }

                            &.activeView {
                                border-radius: 8px;
                                background: var(--primary-50);

                                span {
                                    color: var(--primary-500);
                                }

                                svg {
                                    path {
                                        stroke: var(--primary-500);
                                    }
                                }
                            }
                        }
                    }

                    .profileLiveMoreOption {
                        cursor: pointer;

                        @media only screen and (max-width:"1024px") {
                            display: none;
                        }

                        svg {}
                    }
                }

                .liveProfileImgView {
                    width: 100%;
                    height: calc(100vh - 374px);
                    position: relative;
                    z-index: 1;

                    @media only screen and (max-width:"1024px") {
                        height: calc(100vh - 160px);
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        display: block;
                        border-radius: 8px;

                        @media only screen and (max-width:"1024px") {
                            border-radius: 0;
                        }
                    }

                    .mobileViewOptionDetailsAlignment {
                        display: flex;
                        align-items: center;
                        gap: 20px;
                        justify-content: space-between;
                        position: absolute;
                        bottom: 24px;
                        left: 24px;
                        right: 24px;
                        display: none;
                        width: calc(100% - 48px);
                        z-index: 99;

                        @media only screen and (max-width:"1024px") {
                            display: flex;
                        }

                        .centerDetailsBoxAlignment {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                        }

                        .mobileViewOptionBOx {
                            width: 44px;
                            height: 44px;
                            border-radius: 10px;
                            background: rgba(30, 36, 50, 0.60);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }

                    .otherDetailsDataAlignment {
                        background: rgba(30, 36, 50, 0.60);
                        backdrop-filter: blur(32.33518981933594px);
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 9;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        display: none;

                        @media only screen and (max-width:"1024px") {
                            display: flex;
                        }

                        .timeDateDetailsAlignment {
                            text-align: center;

                            .calrnderIcon {
                                margin: 0 0 40px 0;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }

                            .delayedBoxAlignment {
                                margin: 0 auto 48px auto;
                                border-radius: 8px;
                                background: rgba(233, 0, 0, 0.48);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 8px;
                                max-width: 112px;
                                padding: 5px 5px;

                                span {
                                    color: #FFF;
                                    text-align: center;
                                    font-size: 15px;
                                    font-weight: 500;
                                    line-height: 21px;
                                }

                            }


                            .otherDetailsBottomAlignment {
                                p {
                                    color: #FFF;
                                    text-align: center;
                                    font-size: 12px;
                                    font-weight: 400;
                                    line-height: 18px;
                                }

                                h6 {
                                    color: #FFF;
                                    text-align: center;
                                    font-size: 16px;
                                    font-weight: 600;
                                    line-height: 24px;
                                    padding: 8px 0 0 0;
                                }
                            }
                        }

                        .mobileViewerDisplayAllNameAlignment {

                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            gap: 48px;

                            .viewerTopDetailsALignment {

                                .viewerIconAlignment {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    margin: 0 0 40px 0;
                                }

                                p {
                                    color: #FFF;
                                    text-align: center;
                                    font-size: 12px;
                                    font-weight: 400;
                                    line-height: 18px;
                                }

                                h6 {
                                    color: #FFF;
                                    text-align: center;
                                    font-size: 16px;
                                    font-weight: 600;
                                    line-height: 24px;
                                    padding: 8px 0 0 0;
                                }
                            }

                            .viewerBottomAlignment {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                gap: 32px;

                                p {
                                    color: #FFF;
                                    text-align: center;
                                    font-size: 12px;
                                    font-weight: 400;
                                    line-height: 18px;
                                    max-width: 285px;
                                    margin: 0 auto;

                                }
                            }

                        }

                        .mobileApologizeDisplayAlignment {
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                            gap: 32px;

                            .apologizeIconALignment {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }

                            p {
                                color: #FFF;
                                text-align: center;
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 18px;
                                max-width: 235px;
                                margin: 0 auto;
                            }
                        }
                    }

                    .viewerDisplayAlignment {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        border-radius: 8px;
                        background: rgba(30, 36, 50, 0.60);
                        backdrop-filter: blur(32.33518981933594px);
                        top: 0;
                        left: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .viewerDisplayAllNameAlignment {

                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            gap: 48px;

                            .viewerTopDetailsALignment {

                                .viewerIconAlignment {
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    margin: 0 0 24px 0;
                                }

                                p {
                                    color: #FFF;
                                    text-align: center;
                                    font-size: 16px;
                                    font-weight: 400;
                                    line-height: 24px;
                                }

                                h6 {
                                    color: #FFF;
                                    text-align: center;
                                    font-size: 24px;
                                    font-weight: 600;
                                    line-height: 28px;
                                    padding: 16px 0 0 0;
                                }
                            }

                            .viewerBottomAlignment {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                gap: 32px;

                                p {
                                    color: #FFF;
                                    text-align: center;
                                    font-size: 16px;
                                    font-weight: 400;
                                    line-height: 24px;
                                }
                            }

                        }

                        .apologizeDisplayAlignment {
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                            gap: 32px;

                            .apologizeIconALignment {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }

                            p {
                                color: #FFF;
                                text-align: center;
                                font-size: 16px;
                                font-weight: 400;
                                line-height: 24px;
                            }
                        }

                        .timeDateDetailsAlignment {
                            text-align: center;

                            .calrnderIcon {
                                margin: 0 0 40px 0;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }

                            .delayedBoxAlignment {
                                margin: 0 auto 48px auto;
                                border-radius: 8px;
                                background: rgba(233, 0, 0, 0.48);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 8px;
                                max-width: 112px;
                                padding: 5px 5px;

                                span {
                                    color: #FFF;
                                    text-align: center;
                                    font-size: 15px;
                                    font-weight: 500;
                                    line-height: 21px;
                                }

                            }


                            .otherDetailsBottomAlignment {
                                p {
                                    color: #FFF;
                                    text-align: center;
                                    font-size: 12px;
                                    font-weight: 400;
                                    line-height: 18px;
                                }

                                h6 {
                                    color: #FFF;
                                    text-align: center;
                                    font-size: 16px;
                                    font-weight: 600;
                                    line-height: 24px;
                                    padding: 8px 0 0 0;
                                }
                            }
                        }
                    }

                }

                .noPreviewBoxAlignment {
                    width: 100%;
                    height: calc(100vh - 374px);
                    border-radius: 8px;
                    background: var(--secondary-900);
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @media only screen and (max-width:"1024px") {
                        height: calc(100vh - 160px);
                        border-radius: 0;
                    }

                    .livePreviewOptionDetailsAlignment {
                        position: absolute;
                        top: 24px;
                        left: 24px;

                        .optionNotifiactionDetailsBox {
                            border-radius: 22px;
                            background: rgba(236, 238, 244, 0.20);
                            padding: 10px 16px;
                            display: flex;
                            align-items: center;
                            gap: 16px;
                            margin: 0 0 8px 0;
                            width: fit-content;

                            @media only screen and (max-width:"1024px") {
                                gap: 4px;

                                svg {
                                    width: 16px;
                                    height: 16px;
                                }
                            }

                            &:last-child {
                                margin: 0;
                            }

                            p {
                                color: #FFF;
                                text-align: center;
                                font-family: 'Poppins', sans-serif;
                                font-size: 16px;
                                font-weight: 500;
                                line-height: 24px;

                                @media only screen and (max-width:"1024px") {
                                    font-size: 12px;
                                    line-height: 18px;
                                    font-weight: 400;
                                }
                            }

                        }
                    }

                    .noProviewProfileLogo {
                        width: 184px;
                        height: 184px;

                        img {
                            width: 100%;
                            height: 100%;
                            display: block;
                            object-fit: cover;

                        }
                    }
                }

                .liveProfileBottomALignment {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 48px 16px;

                    @media only screen and (max-width:"1024px") {
                        display: none;
                    }

                    .liveBottomOption {
                        width: 44px;
                        height: 44px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                    }

                    .livePerfomaceOption {
                        display: flex;
                        align-items: center;
                        gap: 16px;


                        .livePerfomaceOptionBox {
                            width: 44px;
                            height: 44px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            border-radius: 10px;
                            background: #E8F3E6;

                            &.secondBoxAlignment {
                                background: transparent;
                            }
                        }


                        .endLiveButtuon {
                            padding: 8px 16px;
                            border-radius: 10px;
                            background: #FFEFEF;
                            display: flex;
                            align-items: center;
                            gap: 4px;
                            cursor: pointer;

                            p {
                                color: #E71616;
                                font-size: 18px;
                                font-weight: 500;
                                line-height: 28px;
                            }
                        }
                    }
                }
            }

            .mobileLiveStreamBottomDetails {
                align-items: center;
                justify-content: space-between;
                padding: 16px 24px;
                background: #FFF;
                display: none;

                @media only screen and (max-width:"1024px") {
                    display: flex;

                }

                .mobileLeftSideAlignment {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    .mobileViewIconBox {
                        width: 44px;
                        height: 44px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                .mobileEditBox {
                    padding: 8px 16px;
                    border-radius: 10px;
                    background: var(--red-50);
                    display: flex;
                    align-items: center;
                    gap: 4px;

                    p {
                        color: var(--red-500);
                        font-size: 18px;
                        font-weight: 500;
                        line-height: 28px;
                    }
                }

                .mobileMoreOptionAlignment {}

                .mobileStartEndAlignment {
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    .startButtonAlignment {
                        button {
                            padding: 8px 20px;
                            border-radius: 10px;
                            background: #1D8603;
                            color: #FFF;
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 28px;
                            cursor: pointer;
                            border: none;
                        }
                    }

                    .endButtonAlignment {
                        button {
                            padding: 8px 20px;
                            border-radius: 10px;
                            background: #E71616;
                            color: #FFF;
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 28px;
                            cursor: pointer;
                            border: none;
                        }
                    }
                }
            }

            .liveStreamRightSide {
                border-radius: 10px;
                background: #FFF;
                box-shadow: 0px 5.06px 50.603px 0px rgba(144, 143, 143, 0.10);
                height: 100%;
                width: 100%;
                padding: 32px 8px 15px 8px;

                @media only screen and (max-width:"1024px") {
                    display: none;

                }

                .liveStreamRightBottomDetails {
                    padding: 48px 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 60px;

                    .liveStreamOptionBox {
                        width: 44px;
                        height: 44px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 10px;
                        cursor: pointer;
                        transition: 0.4s ease-in-out;

                        svg {
                            transition: 0.4s ease-in-out;
                        }

                        &:hover {
                            background: var(--primary-50);
                            transition: 0.4s ease-in-out;

                            svg {
                                transition: 0.4s ease-in-out;

                                path {
                                    stroke: var(--primary-500);
                                }
                            }

                        }

                        &.activeOption {
                            background: var(--primary-50);
                            transition: 0.4s ease-in-out;

                            svg {
                                transition: 0.4s ease-in-out;

                                path {
                                    stroke: var(--primary-500);
                                }
                            }

                        }
                    }
                }
            }
        }
    }
}

.bottomButtonAlignment {
    display: flex;
    align-items: center;
    gap: 16px;

    button {
        padding: 8px 20px;
        background: #1D8603;
        border-radius: 10px;
        font-size: 18px;
        line-height: 28px;
        font-weight: 500;
        color: #FFFFFF;
        border: none;
        cursor: pointer;


        &:last-child {
            background: #E71616;
        }
    }
}