.jewelryHotDetailsModalSection {
    .jewelryHotDetailsModalAlignment {
        border-radius: 10px;
        background: #FFF;
        height: calc(100vh - 96px);
        padding: 0 16px;
        overflow-y: auto;

        @media only screen and (max-width:"1099px") {
            height: calc(100vh - 32px);
        }

        .jewelryHotDetailsHeading {
            padding: 32px 16px;
            background: #FFF;
            position: sticky;
            top: 0;
            z-index: 99;

            h4 {
                color: var(--bookmark-heading-color);
                font-family: 'Poppins', sans-serif;
                font-size: 24px;
                font-weight: 600;
                line-height: 36px;
            }
        }

        .jewelryHotAllModalDetails {
            padding: 0 0 32px 0;

            .jewelryHotAllModalgrid {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                gap: 16px;

                @media only screen and (max-width:"1099px") {
                    grid-template-columns: repeat(4, 1fr);
                }

                .jewelryHotAllModalgridItem {
                    .jewelryHotDetailsImg {
                        width: 100%;
                        height: 242px;
                        position: relative;
                        cursor: pointer;

                        &:hover {
                            transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);
                            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
                            transform: translateY(-4px);
                            border-radius: 9.669px;

                            .hoverDetailsAlignment {
                                display: flex;
                            }
                        }

                        &::before {
                            position: absolute;
                            content: '';
                            width: 100%;
                            height: 100%;
                            border-radius: 9.669px;
                            background: linear-gradient(180deg, rgba(72, 72, 72, 0.00) 0%, #2E2E2E 100%);
                            top: 0;
                            left: 0;
                            z-index: 9;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 9.669px;
                        }

                        .jewelryHotDetails {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            left: 0;
                            padding: 12px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: flex-start;
                            z-index: 9;

                            .offerBoxAlignment {
                                background: #F1EFB0;
                                padding: 0 8px;

                                p {
                                    color: #474400;
                                    text-align: center;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 15.712px;
                                    font-weight: 500;
                                    line-height: 23px;
                                }

                            }

                            .offerBoxPriceAlignment {
                                h6 {
                                    color: #FFF;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 18px;
                                    font-weight: 600;
                                    line-height: 27px;
                                }

                                p {
                                    color: var(--secondary-100);
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 13px;
                                    font-weight: 400;
                                    line-height: 19px;
                                    text-decoration-line: strikethrough;
                                }
                            }
                        }

                        .hoverDetailsAlignment {
                            display: flex;
                            align-items: center;
                            gap: 12px;
                            position: absolute;
                            bottom: 20px;
                            right: 20px;
                            z-index: 99;
                            display: none;
                            transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);

                            .hoverInputAlignment {
                                svg {
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}