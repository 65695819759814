.financePaymentBox {
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 5.06033182144165px 50.60331726074219px 0px rgba(144, 143, 143, 0.10);
    width: 100%;
    height: calc(100vh - 96px);
    padding: 16px;
    overflow-y: auto;

    .financePaymentHeading {
        padding: 0 8px;

        h4 {
            color: var(--secondary-900);
            font-family: 'Poppins', sans-serif;
            font-size: 24px;
            font-weight: 600;
            line-height: 36px;
        }
    }
}