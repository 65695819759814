.trendingBlocBox {
  padding: 22px 49px;
  border-radius: 16px;
  background: #1f1e1e;
  margin: 24px 0 24px 0;
  
  @media (max-width: 1024px) {
    min-height: auto !important;
  }
  @media (max-width: 700px) {
    border-radius: 0;
  }

  @media (max-width: 576px) {
    padding: 12px 0 0 0;
    margin: 24px 0 24px 0;
    border-radius: 0px;
  }

  .flexBox {

    .flexBoxItems:nth-child(1) {
      @media (max-width:576px) {
        padding: 0 17px;
      }
    }

    .flexBoxItems:last-child {
      // width: calc(100% - 221px - 77px);
      // padding-right: 40px;

      @media (max-width: 576px) {
        // width: calc(100% - 146px - 20px);
        // padding-right: 0;
      }
    }

    .text {
      display: flex;
      justify-content: space-between;

      p {
        color: #eef1fd;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0;

        @media (max-width:576px) {
          line-height: 23px;
          font-size: 20px;
          margin: 0 0 10px 0;
        }
      }

      button {
        padding: 5px 24px;
        background-color: #fff;
        border: none;
        cursor: pointer;
        color: #353a48;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        border-radius: 12px;
        line-height: 20px;
        height: fit-content;

        @media (max-width: 576px) {
          padding: 3px 16px;
          line-height: 13px;
          font-size: 12px;
          border-radius: 6px;
        }
      }

      span {
        display: block;
        color: #eceef4;
        font-size: 13px;
        font-style: normal;
        // padding: 0 0 20px 0;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.1px;

        @media (max-width: 576px) {
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.068px;
          max-width: 200px;
        }
      }
    }
  }

  
  :global(.slick-prev) {
    left: -39px !important;
    z-index: 99;
  }
  
  :global(.slick-next) {
    right: -45px !important;
    z-index: 99;
  }
}