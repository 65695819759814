.storeMobileViewModalAlignment {

    .storeMobileViewModalHeading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 24px 12px 16px;
        border-bottom: 1px solid #EAECEF;
        position: sticky;
        top: 28px;
        background: #fff;
        z-index: 99;

        h4 {
            color: var(--grey5);
            font-family: 'Poppins', sans-serif;
            font-size: 18px;
            font-weight: 600;
            line-height: 27px;
        }

        .storeMobileCloseAlignment {
            a {
                color: var(--primary-500);
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
            }
        }
    }

    .storeMobileViewBodyAlignment {
        padding: 12px 16px;
        height: calc(100vh - 120px);
        overflow-y: auto;

        .storeMobileGridAlignment {
            display: grid;
            grid-template-columns: 1fr;
            gap: 8px;

            .storeMobileGridItemAlignment {}
        }
    }
}