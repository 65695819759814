// .categoryDropdownContainer {
// }

.addProductModalCategoryAlignment {
  margin: 0 0 16px 0;

  .addProductModalCategoryBox {
    border-radius: 10px;
    border: 1px solid var(--secondary-50);
    background: #fff;
    padding: 10px 16px 16px 16px;

    .addProductStatusInputAlignment {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      border: 1px solid var(--secondary-50);
      background: var(--light-grey);
      border-radius: 10px;
      margin: 10px 0 0 0;
      position: relative;
      cursor: pointer;

      input {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        color: var(--secondary-900);
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        background: transparent;
        border: none;
        cursor: pointer;
      }

      .addProductStatusDropdownIcon {
        width: 16px;
        height: 16px;
        min-width: 16px;
        max-width: 16px;
        cursor: pointer;

        svg {
          transition: 0.4s ease-in-out;

          &.productStatusActiveArrow {
            transition: 0.4s ease-in-out;

            transform: rotate(-180deg);
          }
        }
      }

      .productStatusDropdownBox {
        position: absolute;
        top: 48px;
        left: 0;
        width: 100%;
        border-radius: 10px;
        background: #fff;
        z-index: 999;
        cursor: pointer;
        box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.12);

        &.closeDropdownBox {
          max-height: 0px;
          transition: all 0.4s ease-in-out;
          overflow-y: hidden;
        }

        &.openDropdownBox {
          max-height: 88px;
          transition: all 0.4s ease-in-out;
          overflow-y: auto;
          padding: 10px 0 16px 0;
          border: 1px solid #fff;
        }

        .noBrandDataText {
          padding: 4px 16px;

          p {
            color: var(--secondary-300);
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
          }
        }

        .selectedListProductStatus {
          min-height: 100%;
          overflow-y: auto;

          p {
            padding: 4px 16px;
            transition: all 0.4s ease-in-out;
            color: var(--secondary-900);
            font-family: 'Poppins', sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            cursor: pointer;

            &:hover {
              transition: all 0.4s ease-in-out;

              background: var(--secondary-50);
            }
          }
        }
      }
    }

    .addProductModalCategoryHeading {
      display: flex;
      align-items: center;

      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #495057;
        margin-right: 3px;
      }

      .starAlignment {
        font-family: 'Inter';
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #d23232;
      }
    }

    .categoryNotes {
      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: #808191;
      }
    }
  }
}
