.whatHotModalSection {
    border-radius: 10px;
    background: #FFF;
    height: calc(100vh - 98px);
    overflow-y: auto;

    @media only screen and (max-width:"767px") {
        height: calc(100vh - 51px);
        padding: 0 0 32px 0;
    }

    .whatHotModalHeaderLineAlignment {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 99;

        @media only screen and (max-width:"767px") {
            display: none;
        }

        .whatHotModalNewLineAlignment {
            width: 100%;
            height: 54px;
            overflow-x: hidden;
            position: relative;
            background: #FFF1CD;

            .whatHotModalNewLIneText {
                display: flex;
                align-items: center;
                gap: 55px;
                height: 100%;
                white-space: nowrap;
                animation: 58.9874s linear 0s 1 normal none running move;

                @media screen and (max-width: 540px) {
                    animation: 20.9874s linear 0s 1 normal none running move;
                }

                @keyframes move {
                    to {
                        transform: translateX(-100%);
                    }
                }
            }


            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 528px;
                background: linear-gradient(90deg, #FFF1CD 0%, rgba(255, 241, 205, 0.00) 100%);
                z-index: 9;
            }


            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: 528px;
                background: linear-gradient(270deg, #FFF1CD 0%, rgba(255, 241, 205, 0.00) 100%);
                z-index: 9;
            }

            p {
                color: var(--secondary-900);
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                white-space: nowrap;
            }
        }
    }

    .whatHotModalMobileViewHeading {
        display: none;

        @media only screen and (max-width:"767px") {
            display: block;
            padding: 16px;
            position: sticky;
            top: 0;
            z-index: 999;
            background: #FFF;

            h4 {
                color: var(--secondary-900);
                font-family: 'Poppins', sans-serif;
                font-size: 20px;
                font-weight: 600;
                line-height: 24px;
            }
        }

    }

    .whatHotModalDetailsAlignment {
        padding: 32px 28px;

        @media only screen and (max-width:"767px") {
            padding: 0 16px;
        }

        .whatHotModalNewDetailsMainAlignment {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 5px;

            @media only screen and (max-width:"1099px") {
                grid-template-columns: repeat(3, 1fr);
            }

            @media only screen and (max-width:"767px") {
                grid-template-columns: repeat(2, 1fr);
            }

            .whatHotModalNewDetailsImgCardBox {
                border-radius: 10px;
                background: #FFF;
                padding: 8px;
                width: 100%;
                height: 344px;

                @media only screen and (max-width:"767px") {
                    height: 195px;
                }

                .whatHotModalNewDetailsCardChidImg {
                    display: grid;
                    grid-template-columns: 2fr 1fr;
                    gap: 4px;

                    .whatHotModalNewDetailsCardChidImgLeft {
                        width: 100%;
                        height: 328px;


                        @media only screen and (max-width:"767px") {
                            height: 185px;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            display: block;
                            object-fit: cover;
                            border-radius: 2px;
                        }
                    }

                    .whatHotModalNewDetailsCardChidImgRight {
                        height: 100%;
                        width: 100%;

                        .rightSideChildImg {
                            width: 100%;
                            height: 162px;
                            margin: 0 0 4px 0;

                            @media only screen and (max-width:"767px") {
                                height: 91px;
                            }

                            &:last-child {
                                margin: 0;
                            }

                            img {
                                width: 100%;
                                height: 100%;
                                border-radius: 2px;
                                display: block;
                                object-fit: cover;
                            }
                        }
                    }
                }
            }
        }
    }
}