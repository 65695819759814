.newSuggestedStores {
  background-image: url('/assets/img/store-vectore.png');
  width: 100%;
  height: 140px;
  background-repeat: no-repeat;
  border-radius: 8px 8px 0px 0px;
  background-size: cover;
  padding: 62px 60px 0 60px;
  margin: 0 0 38px 0;

  @media (max-width: 576px) {
    background-image: none;
    padding: 13px 16px;
    margin: 0;
    height: auto;
  }

  .storesGrid {
    display: grid;
    grid-template-columns: 1fr 411px;
    gap: 20px;

    @media (max-width: 576px) {
      grid-template-columns: 1fr;
      gap: 10px;
    }

    p {
      margin: 0 0 2px 0;
      color: #171725;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      @media (max-width: 576px) {
        font-size: 16px;
      }
    }

    span {
      display: block;
      color: #4c515d;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.1px;
      @media (max-width: 576px) {
        font-size: 12px;
      }
    }

    .text {
      @media (max-width: 576px) {
        order: 2;
      }
    }

    .peopleFollowerSearchAlignment {
      height: 40px;
      display: flex;
      align-items: center;
      background: #fff;
      border-radius: 10px;
      padding: 0 16px 0 0;

      @media only screen and (max-width: '1024px') {
        width: 100%;
        max-width: 100%;
      }

      input {
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 400;
        background-color: #fff;
        line-height: 24px;

        &::placeholder {
          color: #acb1c0;
        }
      }
    }
  }
}

.sliderWidthDesign {
  max-width: 1201px;
  .noFollowesDataBoxAlignment {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 42px;
    flex-direction: column;

    p {
      color: var(--secondary-300);
      text-align: center;
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      font-style: italic;
      font-weight: 400;
      line-height: 24px;
    }
  }
}
