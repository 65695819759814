.book-details-modal-section {
    .book-details-modal-bg-alignment {
        background: #1E2432;
        border-radius: 10px;
        width: 100%;
        height: 233px;
        padding: 74px 57px;

        @media only screen and (max-width:"767px") {
            display: none;
        }

        .book-banner-option-alignment {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 12px;

            img {
                cursor: pointer;
            }
        }
    }

    .book-details-profile-alignment {
        background: rgba(255, 255, 255, 0.6);
        border: 1px solid #FFFFFF;
        backdrop-filter: blur(32px);
        border-radius: 10px;
        margin: -82px 24px 0 24px;

        @media only screen and (max-width:"767px") {
            margin: 9px 16px;
        }


        .book-main-details-section {
            padding: 20px 32px 32px 32px;
            display: flex;
            align-items: flex-start;
            gap: 29px;

            @media only screen and (max-width:"899px") {
                flex-wrap: wrap;
            }


            @media only screen and (max-width:"767px") {
                padding: 32px;
                flex-wrap: wrap;
                gap: 16px;
            }

            .book-img-alignment {
                max-width: 336px;
                width: 336px;
                min-width: 336px;
                height: 510px;
                margin-top: -104px;


                @media only screen and (max-width:"1199px") {
                    max-width: 300px;
                    width: 300px;
                    min-width: 300px;
                    height: 460px;
                }

                @media only screen and (max-width:"767px") {
                    margin: 0 auto;
                    width: 201px;
                    max-width: 201px;
                    min-width: 201px;
                    height: 305px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }


            .book-main-all-details-alignment {
                h4 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 48px;
                    line-height: 72px;
                    color: #000000;

                    @media only screen and (max-width:"767px") {
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 30px;
                        text-align: center;
                    }
                }

                p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #808191;

                    span {
                        color: var(--primary-500);
                    }

                    @media only screen and (max-width:"767px") {
                        text-align: center;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 20px;
                        padding: 3px 0 0 0;

                        span {
                            color: #808191;
                        }
                    }
                }

                .book-all-details-number-alignment {
                    display: flex;
                    align-items: center;
                    gap: 17px;
                    padding: 16px 0 0 0;

                    @media only screen and (max-width:"767px") {
                        gap: 8px;
                        justify-content: center;
                    }

                    .book-child-details-alignment {
                        display: flex;
                        align-items: center;
                        gap: 4px;

                        @media only screen and (max-width:"767px") {
                            gap: 2px;
                        }

                        p {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 21px;
                            color: #5F40AF;

                            @media only screen and (max-width:"767px") {
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 18px;
                            }
                        }
                    }

                }

                .book-rate-alignment {
                    display: flex;
                    align-items: center;
                    padding: 16px 0 0 0;
                    gap: 4px;

                    @media only screen and (max-width:"767px") {
                        padding: 8px 0 0 0;
                        justify-content: center;
                    }

                    .hike {
                        display: flex;
                        align-items: center;

                        .inter {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 20px;
                            color: #70C217;

                            img {
                                margin-right: 3px;
                            }
                        }

                        .incris {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 20px;
                            color: #70C217;
                            display: flex;
                            align-items: center;

                            img {
                                margin-right: 3px;
                            }
                        }
                    }

                    .rating {
                        display: flex;
                        align-items: center;

                        .rating_point {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 20px;
                            color: #808191;
                        }

                        .stars {
                            display: flex;
                            align-items: center;
                            margin: 0 2px;
                        }

                        .num {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 20px;
                            color: #808191;
                        }
                    }
                }

                .book-all-notes-alignment {
                    padding: 16px 0 0 0;

                    // @media only screen and (max-width:"767px") {
                    //     display: none;
                    // }

                    p {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 20px;
                        color: #495057;
                    }
                }

                .stock-details-alignment {
                    display: flex;
                    align-items: center;
                    gap: 21px;
                    padding: 31px 0 0 0;

                    @media only screen and (max-width:"767px") {
                        padding: 16px 0 0 0;
                        justify-content: center;
                    }

                    span {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 13px;
                        line-height: 20px;
                        color: var(--secondary-900);
                    }

                    p {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 13px;
                        line-height: 20px;
                        color: #495057;

                    }
                }

                .book-price-alignment {
                    display: flex;
                    align-items: center;
                    padding: 55px 0 0 0;

                    @media only screen and (max-width:"767px") {
                        padding: 16px 0 0 0;
                        justify-content: center;
                    }

                    h6 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 30px;
                        color: #70C217;
                        padding: 0 3px 0 0;

                        @media only screen and (max-width:"767px") {
                            color: var(--secondary-900);
                        }
                    }

                    span {
                        del {

                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 13px;
                            line-height: 20px;
                            color: #495057;
                            padding: 0 5px 0 0;
                        }
                    }

                    p {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 20px;
                        color: #495057;

                    }
                }
            }
        }
    }


    .mobile-view-about-details-alignment {
        display: none;

        @media only screen and (max-width:"767px") {
            display: block;
            margin: 8px 16px 0 16px;
            background: #FFFFFF;
            border-radius: 10px;
            padding: 12px 16px;

            h4 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                color: var(--secondary-900);
            }

            .mobile-view-about-details-section-alignment {
                padding: 8px 0 0 0;

                p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #495057;
                }
            }

            .read-more-alignment {
                padding: 8px 0 0 0;
                text-align: center;

                a {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--primary-500);
                }

            }
        }
    }

    .book-other-details-section {
        margin: 16px 32px 0 32px;

        @media only screen and (max-width:"767px") {
            margin: 8px 16px 0 16px;
        }

        .book-other-details-grid-alignment {
            display: grid;
            grid-template-columns: 1fr 520px;
            gap: 16px;

            @media only screen and (max-width:"1199px") {
                grid-template-columns: 1fr;
            }

            @media only screen and (max-width:"767px") {
                grid-template-columns: 1fr;
            }

            .book-other-details-left-side-alignment {
                .book-details-section {
                    background: #FFFFFF;
                    border-radius: 10px;
                    padding: 20px 30px;

                    @media only screen and (max-width:"767px") {
                        padding: 12px 16px;
                    }

                    h4 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 600;
                        font-size: 24px;
                        line-height: 36px;
                        color: #000000;

                        @media only screen and (max-width:"767px") {
                            font-size: 20px;
                            line-height: 30px;
                        }
                    }

                    .book-all-details-alignment {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 112px;
                        padding: 16px 0 0 0;

                        @media only screen and (max-width:"899") {
                            grid-template-columns: 1fr;
                            gap: 8px;
                        }

                        @media only screen and (max-width:"767px") {
                            display: block;
                        }

                        .book-personal-details-alignment {
                            display: flex;
                            align-items: center;
                            gap: 19px;
                            padding: 0 0 8px 0;


                            &:last-child {
                                padding: 0;
                            }

                            @media only screen and (max-width:"767px") {
                                padding: 8px 0;
                                border-bottom: 1px solid #E4E4E8;

                                &:last-child {
                                    padding: 8px 0;
                                }

                                &.no-border {
                                    border-bottom: none;
                                }
                            }


                            label {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 600;
                                font-size: 13px;
                                line-height: 20px;
                                color: var(--secondary-900);
                                width: 120px;
                                margin: 0;

                                @media only screen and (max-width:"767px") {
                                    font-size: 14px;
                                    line-height: 21px;
                                }
                            }

                            span {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 20px;
                                color: #495057;

                                @media only screen and (max-width:"767px") {
                                    font-size: 14px;
                                    line-height: 21px;
                                }
                            }
                        }
                    }
                }

                .book-all-author-all-details-alignment {
                    margin: 16px 0 0 0;
                    background: #FFFFFF;
                    border-radius: 10px;
                    padding: 20px 30px;

                    @media only screen and (max-width:"767px") {
                        margin: 8px 0 0 0;
                        padding: 12px 16px;
                    }

                    .book-author-heading-alignment {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        h5 {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 24px;
                            line-height: 36px;
                            color: #000000;

                            @media only screen and (max-width:"767px") {
                                font-size: 20px;
                                line-height: 30px;
                            }
                        }

                        .auther-options-alignment {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 8px;
                            padding: 16px 0 0 0;

                            @media only screen and (max-width:"767px") {
                                display: none;
                            }

                            .follow-page-button-alignment {
                                button {
                                    background: var(--primary-500);
                                    border-radius: 8px;
                                    height: 32px;
                                    width: 117px;
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 600;
                                    font-size: 14px;
                                    line-height: 21px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    color: #F8F8F8;
                                    cursor: pointer;
                                }
                            }

                            .like-share-alignment {
                                display: flex;
                                align-items: center;
                                gap: 8px;


                                .box-alignment {
                                    background: #F8F8F8;
                                    border-radius: 8px;
                                    width: 32px;
                                    min-width: 32px;
                                    max-width: 32px;
                                    height: 32px;
                                    padding: 4px;
                                    cursor: pointer;
                                }
                            }
                        }
                    }

                    .book-more-author-box-alignment {
                        display: flex;
                        align-items: center;
                        gap: 16px;
                        padding: 16px 0 0 0;

                        @media only screen and (max-width:"767px") {
                            padding: 12px 0 0 0;
                        }

                        .author-img-alignment {
                            max-width: 96px;
                            width: 96px;
                            height: 96px;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius: 32px;
                            }

                            @media only screen and (max-width:"767px") {
                                max-width: 93px;
                                width: 93px;
                                height: 93px;
                            }
                        }

                        .more-auther-details-alignment {

                            h6 {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 600;
                                font-size: 18px;
                                line-height: 27px;
                                color: #495057;

                                @media only screen and (max-width:"767px") {
                                    font-size: 16px;
                                    line-height: 24px;
                                }


                            }

                            p {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 500;
                                font-size: 13px;
                                line-height: 20px;
                                color: #808191;

                                @media only screen and (max-width:"767px") {
                                    font-size: 12px;
                                    line-height: 18px;
                                    padding: 2px 0 0 0;
                                }

                            }

                            .mobile-auther-options-alignment {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 8px;
                                padding: 16px 0 0 0;
                                display: none;

                                @media only screen and (max-width:"767px") {
                                    display: flex;
                                }


                                .mobile-follow-page-button-alignment {
                                    button {
                                        background: var(--primary-500);
                                        border-radius: 8px;
                                        height: 32px;
                                        width: 117px;
                                        font-family: 'Poppins', sans-serif;
                                        font-weight: 600;
                                        font-size: 14px;
                                        line-height: 21px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        color: #F8F8F8;
                                        cursor: pointer;
                                    }
                                }

                                .mobile-like-share-alignment {
                                    display: flex;
                                    align-items: center;
                                    gap: 8px;


                                    .mobile-box-alignment {
                                        background: #F8F8F8;
                                        border-radius: 8px;
                                        width: 32px;
                                        min-width: 32px;
                                        max-width: 32px;
                                        height: 32px;
                                        padding: 4px;
                                        cursor: pointer;
                                    }
                                }
                            }

                            .auther-following-details-alignment {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 17px;
                                padding: 11px 0 0 0;

                                @media only screen and (max-width:"767px") {
                                    display: none;
                                }

                                .child-anme-alignment {
                                    display: flex;
                                    align-items: center;
                                    gap: 4px;

                                    span {
                                        font-family: 'Poppins', sans-serif;
                                        font-weight: 600;
                                        font-size: 13px;
                                        line-height: 20px;
                                        color: #808191;
                                    }
                                }

                            }


                        }
                    }

                    .mobile-auther-following-details-alignment {
                        display: flex;
                        align-items: center;
                        gap: 18px;
                        padding: 16px 0 0 0;
                        display: none;

                        @media only screen and (max-width:"767px") {
                            display: flex;
                            width: 100%;
                        }

                        .mobile-child-anme-alignment {
                            display: flex;
                            align-items: center;
                            gap: 4px;

                            span {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 500;
                                font-size: 13px;
                                line-height: 20px;
                                color: #808191;
                            }
                        }

                    }
                }

                .book-comment-box-alignment {
                    margin: 16px 0 0 0;
                    background: #FFFFFF;
                    border: 1px solid #FFFFFF;
                    backdrop-filter: blur(32px);
                    border-radius: 16px;
                    width: 100%;
                    height: 714px;
                }
            }

            .book-other-details-right-side-alignment {

                @media only screen and (max-width:"767px") {
                    display: none;
                }

                .more-book-alignment {
                    background: rgba(255, 255, 255, 0.6);
                    border: 1px solid #FFFFFF;
                    backdrop-filter: blur(32px);
                    border-radius: 10px;
                    padding: 20px 30px;

                    .more-book-heding-alignment {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        h5 {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 24px;
                            line-height: 36px;
                            color: var(--secondary-900);
                        }

                        a {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 21px;
                            letter-spacing: 0.1px;
                            color: var(--primary-500);
                            cursor: pointer;
                        }
                    }

                    .more-book-details-body-alignment {
                        padding: 15px 0 0 0;
                        height: auto;
                        max-height: 445px;
                        overflow-y: auto;

                        .more-book-box-alignment {
                            background: #FFFFFF;
                            border-radius: 10px;
                            padding: 8px;
                            display: flex;
                            align-items: center;
                            gap: 18px;
                            margin: 0 0 8px 0;

                            &:last-child {
                                margin: 0;
                            }



                            .book-img-alignment {
                                width: 87px;
                                height: 127px;
                                max-width: 87px;
                                min-width: 87px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    display: block;
                                    border-radius: 4px;
                                    object-fit: cover;
                                }
                            }


                            .more-book-name-alignment {
                                h5 {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 600;
                                    font-size: 20px;
                                    line-height: 30px;
                                    color: #495057;
                                }

                                p {
                                    padding: 16px 0 0 0;
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 600;
                                    font-size: 14px;
                                    line-height: 21px;
                                    color: #808191;
                                }
                            }
                        }
                    }
                }

                .book-club-section-alignment {
                    background: rgba(255, 255, 255, 0.6);
                    border: 1px solid #FFFFFF;
                    backdrop-filter: blur(32px);
                    border-radius: 16px;
                    margin: 16px 0 0 0;

                    .heading-alignment {
                        padding: 20px 32px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        h6 {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 24px;
                            line-height: 36px;
                            color: var(--secondary-900);
                        }

                        a {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 21px;
                            letter-spacing: 0.1px;
                            color: var(--primary-500);
                            cursor: pointer;
                        }
                    }
                    .heading-alignment-mobile {
                        padding: 20px 32px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        @media only screen and (min-width:"767px") {
                            display: none;
                            // padding: 0 0 16px 0;
                          }
                

                        h6 {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 24px;
                            line-height: 36px;
                            color: var(--secondary-900);
                        }

                        a {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 21px;
                            letter-spacing: 0.1px;
                            color: var(--primary-500);
                            cursor: pointer;
                        }
                    }

                    .book-club-create-section {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin: 0 16px 16px 16px;

                        .create-input-alignment {
                            width: 240px;
                            min-width: 240px;
                            max-width: 240px;
                            height: 40px;
                            background: #FFFFFF;
                            border: 1px solid #F3F3F3;
                            border-radius: 10px;
                            display: flex;
                            align-items: center;

                            img {
                                width: 40px;
                                min-width: 40px;
                                max-width: 40px;
                                height: 40px;
                            }

                            input {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                                width: 100%;
                                height: 100%;
                                border-radius: 0 10px 10px 0;
                                padding: 0 10px 0 0;

                                &::placeholder {
                                    color: #ACB1C0;
                                }

                            }
                        }

                        .create-clun-alignment {
                            button {
                                background: var(--primary-500);
                                border-radius: 8px;
                                height: 32px;
                                width: 124px;
                                font-family: 'Poppins', sans-serif;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 21px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: #F8F8F8;
                                cursor: pointer;
                            }
                        }
                    }

                    .book-club-created-details-alignment {
                        padding: 0 16px 16px 16px;
                        height: auto;
                        max-height: 475px;
                        overflow-y: auto;

                        .book-club-create-box-alignment {
                            background: #FFFFFF;
                            border-radius: 10px;
                            padding: 4px;
                            display: flex;
                            align-items: center;
                            gap: 16px;
                            margin: 0 0 8px 0;

                            &:last-child {
                                margin: 0;
                            }

                            .book-club-img-alignment {
                                max-width: 148px;
                                width: 148px;
                                min-width: 148px;
                                height: 99px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    display: block;
                                    border-radius: 8px;
                                }
                            }

                            .book-club-create-details-alignment {
                                width: 100%;

                                h6 {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 500;
                                    font-size: 20px;
                                    line-height: 30px;
                                    color: var(--secondary-900);
                                }

                                .auther-member-details-join-alignment {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;

                                    .member-all-details {
                                        display: flex;
                                        align-items: center;
                                        gap: 8px;

                                        .member-img-flex-alignment {
                                            display: flex;
                                            align-items: center;

                                            .member-img-alignment {
                                                max-width: 24px;
                                                min-width: 24px;
                                                width: 24px;
                                                height: 24px;
                                                margin-right: -4px;



                                                img {
                                                    width: 100%;
                                                    height: 100%;
                                                    border-radius: 50%;
                                                }
                                            }
                                        }

                                        .member-total-alignment {
                                            p {
                                                font-family: 'Poppins', sans-serif;
                                                font-weight: 500;
                                                font-size: 14px;
                                                line-height: 21px;
                                                color: #808191;
                                            }
                                        }
                                    }

                                    .join-club-button-alignment {
                                        width: 92px;
                                        height: 32px;

                                        button {
                                            background: var(--primary-500);
                                            border-radius: 8px;
                                            font-family: 'Poppins', sans-serif;
                                            font-weight: 600;
                                            font-size: 14px;
                                            line-height: 21px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            color: #F8F8F8;
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }

    .mobile-view-book-other-details-alignment {
        display: none;

        @media only screen and (max-width:"767px") {
            display: block;
            margin: 16px 0 0 0;
            background: #f0f1f6;
            border: 1px solid #FFFFFF;
            box-shadow: 0px 31.3402px 42.9809px rgba(85, 110, 230, 0.1);
            padding: 16px 0;

            .mobile-more-details-heading-alignment {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 16px;

                h4 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 30px;
                    color: var(--secondary-900);
                }

                a {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: var(--primary-500);
                    cursor: pointer;
                }
            }

            .mobile-view-more-details-alignment {
                padding: 32px 0 32px 16px;

                .mobile-more-details-book-alignment {

                    @media only screen and (max-width:"767px") {
                        padding: 8px 3px;
                    }

                    &:hover {
                        transform: translateY(-5px);
                        background: rgba(85, 110, 230, 0.1);
                        border-radius: 10px;

                        .book-img-alignment {
                            box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                        }
                    }

                    &.book-active-details {
                        transform: translateY(-5px);
                        background: rgba(85, 110, 230, 0.1);
                        border-radius: 10px;

                        .book-img-alignment {
                            box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                        }
                    }


                    .book-img-alignment {
                        height: 224px;

                        img {
                            width: 100%;
                            height: 100%;
                            display: block;
                        }
                    }

                    .mobile-more-details-name-alignment {
                        text-align: center;
                        padding: 16px 0 0 0;
                        margin: 0 0 24px 0;

                        &:last-child {
                            margin: 0;
                        }

                        h6 {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 24px;
                            color: var(--secondary-900);
                        }

                        p {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 20px;
                            color: #808191;
                            text-align: center;
                        }

                        .book-rate-alignment {
                            display: flex;
                            align-items: center;
                            padding: 8px 0 0 0;
                            justify-content: center;
                            gap: 4px;

                            .hike {
                                display: flex;
                                align-items: center;

                                .inter {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 600;
                                    font-size: 13px;
                                    line-height: 20px;
                                    color: #70C217;

                                    img {
                                        margin-right: 3px;
                                    }
                                }

                                .incris {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 600;
                                    font-size: 13px;
                                    line-height: 20px;
                                    color: #70C217;
                                    display: flex;
                                    align-items: center;

                                    img {
                                        margin-right: 3px;
                                    }
                                }
                            }

                            .rating {
                                display: flex;
                                align-items: center;

                                @media only screen and (max-width: 799px) {
                                    display: none;
                                }

                                .rating_point {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 600;
                                    font-size: 13px;
                                    line-height: 20px;
                                    color: #808191;
                                }

                                .stars {
                                    display: flex;
                                    align-items: center;
                                    margin: 0 2px;
                                }

                                .num {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 600;
                                    font-size: 13px;
                                    line-height: 20px;
                                    color: #808191;
                                }
                            }
                        }

                        .book-price-alignment {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 5px;
                            padding: 11px 0 0 0;

                            h5 {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 24px;
                                color: #000000;

                            }

                            span {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 20px;
                                color: #495057;
                            }
                        }

                        .like-share-alignment {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 16px;

                            .like-alignment {
                                display: flex;
                                align-items: center;
                                gap: 2px;

                                span {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 500;
                                    font-size: 13px;
                                    line-height: 20px;
                                    color: #808191;
                                }
                            }
                        }
                    }

                    .book-of-the-month-release-book-name-alignment {
                        text-align: center;
                        padding: 16px 0 0 0;
                        margin: 0 0 24px 0;

                        display: none;

                        @media only screen and (max-width:"767px") {
                            display: block;
                        }

                        &:last-child {
                            margin: 0;
                        }

                        h6 {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 24px;
                            color: var(--secondary-900);
                        }

                        p {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 20px;
                            color: #808191;
                            text-align: center;
                        }

                        .book-rate-alignment {
                            display: flex;
                            align-items: center;
                            padding: 8px 0 0 0;
                            justify-content: center;
                            gap: 4px;

                            .hike {
                                display: flex;
                                align-items: center;

                                .inter {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 600;
                                    font-size: 13px;
                                    line-height: 20px;
                                    color: #70C217;

                                    img {
                                        margin-right: 3px;
                                    }
                                }

                                .incris {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 600;
                                    font-size: 13px;
                                    line-height: 20px;
                                    color: #70C217;
                                    display: flex;
                                    align-items: center;

                                    img {
                                        margin-right: 3px;
                                    }
                                }
                            }

                            .rating {
                                display: flex;
                                align-items: center;

                                @media only screen and (max-width: 799px) {
                                    display: none;
                                }

                                .rating_point {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 600;
                                    font-size: 13px;
                                    line-height: 20px;
                                    color: #808191;
                                }

                                .stars {
                                    display: flex;
                                    align-items: center;
                                    margin: 0 2px;
                                }

                                .num {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 600;
                                    font-size: 13px;
                                    line-height: 20px;
                                    color: #808191;
                                }
                            }
                        }

                        .book-price-alignment {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 5px;
                            padding: 11px 0 0 0;

                            h5 {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 24px;
                                color: #000000;

                            }

                            span {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 20px;
                                color: #495057;
                            }
                        }

                        .like-share-alignment {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 16px;

                            .like-alignment {
                                display: flex;
                                align-items: center;
                                gap: 2px;

                                span {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 500;
                                    font-size: 13px;
                                    line-height: 20px;
                                    color: #808191;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .mobile-book-club-section {
        display: none;

        @media only screen and (max-width:"767px") {
            display: block;
            background: rgba(255, 255, 255, 0.4);
            border: 1px solid #FFFFFF;
            // backdrop-filter: blur(28.6539px);
            padding: 16px;
            margin: 16px 0 0 0;

            .mobile-book-club-headign-alignment {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 0 16px 0;

                h4 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 30px;
                    color: var(--secondary-900);
                }

                a {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: var(--primary-500);
                    cursor: pointer;
                }
            }
        }
    }

    .book-other-book-details-alignment {
        margin: 16px 32px 0 32px;
        background: rgba(255, 255, 255, 0.6);
        border: 1px solid #FFFFFF;
        backdrop-filter: blur(32px);
        border-radius: 16px;
        padding: 20px 30px;

        @media only screen and (max-width:"767px") {
            margin: 16px 4px 0 4px;
            border-radius: 0;
            padding: 16px;
        }

        .explore-book-heading-alignment {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h5 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 24px;
                line-height: 36px;
                color: var(--secondary-900);

                @media only screen and (max-width:"767px") {
                    font-size: 20px;
                    line-height: 30px;
                }
            }

            a {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: var(--primary-500);
                cursor: pointer;
            }
        }

        .book-other-book-grid-alignment {
            @media only screen and (max-width:"767px") {
                padding: 30px 0;
            }

            .book-other-book-gridItem-alignment {
                .book-other-book-alignment {
                    padding: 16px;

                    @media only screen and (max-width:"767px") {
                        padding: 3px;
                    }

                    &:hover {
                        transform: translateY(-5px);
                        background: rgba(85, 110, 230, 0.1);
                        border-radius: 10px;

                        .book-img-alignment {
                            box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                        }
                    }

                    &.book-active-details {
                        transform: translateY(-5px);
                        background: rgba(85, 110, 230, 0.1);
                        border-radius: 10px;

                        .book-img-alignment {
                            box-shadow: 2px 13px 28px rgba(95, 64, 175, 0.4);
                        }
                    }


                    .book-img-alignment {
                        height: 224px;

                        img {
                            width: 100%;
                            height: 100%;
                            display: block;
                        }
                    }

                    .book-other-name-alignment {
                        text-align: center;
                        padding: 16px 0 0 0;
                        margin: 0 0 24px 0;

                        &:last-child {
                            margin: 0;
                        }

                        h6 {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 24px;
                            color: var(--secondary-900);
                        }

                        p {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 20px;
                            color: #808191;
                            text-align: center;
                        }

                        .book-rate-alignment {
                            display: flex;
                            align-items: center;
                            padding: 8px 0 0 0;
                            justify-content: center;
                            gap: 4px;

                            .hike {
                                display: flex;
                                align-items: center;

                                .inter {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 600;
                                    font-size: 13px;
                                    line-height: 20px;
                                    color: #70C217;

                                    img {
                                        margin-right: 3px;
                                    }
                                }

                                .incris {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 600;
                                    font-size: 13px;
                                    line-height: 20px;
                                    color: #70C217;
                                    display: flex;
                                    align-items: center;

                                    img {
                                        margin-right: 3px;
                                    }
                                }
                            }

                            .rating {
                                display: flex;
                                align-items: center;

                                @media only screen and (max-width: 799px) {
                                    display: none;
                                }

                                .rating_point {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 600;
                                    font-size: 13px;
                                    line-height: 20px;
                                    color: #808191;
                                }

                                .stars {
                                    display: flex;
                                    align-items: center;
                                    margin: 0 2px;
                                }

                                .num {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 600;
                                    font-size: 13px;
                                    line-height: 20px;
                                    color: #808191;
                                }
                            }
                        }

                        .book-price-alignment {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 5px;
                            padding: 11px 0 0 0;

                            h5 {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 600;
                                font-size: 16px;
                                line-height: 24px;
                                color: #000000;

                            }

                            span {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: 20px;
                                color: #495057;
                            }
                        }

                        .like-share-alignment {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 16px;

                            .like-alignment {
                                display: flex;
                                align-items: center;
                                gap: 2px;

                                span {
                                    font-family: 'Poppins', sans-serif;
                                    font-weight: 500;
                                    font-size: 13px;
                                    line-height: 20px;
                                    color: #808191;
                                }
                            }
                        }
                    }
                }
            }

        }
    }

    .rate-review-details-alignment {
        margin: 16px 32px 0 32px;
        background: rgba(255, 255, 255, 0.6);
        border: 1px solid #FFFFFF;
        backdrop-filter: blur(32px);
        border-radius: 16px;
        height: 704px;

        @media only screen and (max-width:"767px") {
            margin: 16px 0 0 0;
            border-radius: 0;

        }
    }

    .slick-dots {
        top: -20px !important;
        right: -42% !important;
    }

    .slick-arrow {
        display: none !important;
    }
}