// .diy-room-details-modal-alignment {
// .diy-room-details-modal-wrapper {
//   position: fixed;
//   width: 100%;
//   height: 100vh;
//   background: rgba(30, 36, 48, 0.8);
//   top: 0;
//   right: 0;
//   z-index: 99;
//   display: flex;
//   align-items: center;
//   justify-content: center;

.diy-room-details-modal-box-alignment {
  //   width: 1276px;
  background: #fff3ea;
  border-radius: 16px;
  padding: 58px;

  .diy-room-details-grid-alignment {
    display: grid;
    grid-template-columns: 1fr 420px;
    gap: 32px;

    .diy-room-left-side-alignment {
      display: flex;
      align-items: center;
      gap: 8px;

      .diy-room-left-side-first-img-alignment {
        width: 407px;
        height: 404px;
        overflow: hidden;
        border-radius: 8px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
          object-fit: cover;

          &:hover {
            transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

            transform: scale(1.05);
            border-radius: 8px;

            img {
              border-radius: 8px;
              transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
              object-fit: cover;
            }
          }
        }
      }

      .diy-room-second-img-alignment {
        width: 293px;

        .diy-child-img-alignment {
          width: 100%;
          height: 198px;
          margin: 0 0 8px 0;
          overflow: hidden;
          border-radius: 8px;

          &:last-child {
            margin: 0;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            object-fit: cover;

            &:hover {
              transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
              transform: scale(1.05);

              img {
                object-fit: cover;
                transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
              }
            }
          }
        }
      }
    }

    .diy-room-right-side-alignment {
      h6 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        color: var(--secondary-900);
      }

      .diy-room-by-details-alignment {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 5px 0 0 0;

        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: #808191;
        }

        .author-details-alignment {
          display: flex;
          align-items: center;
          gap: 4px;

          .author-img-alignment {
            width: 20px;
            height: 20px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 20px;
              object-fit: cover;
            }
          }

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: var(--secondary-900);
            transition: 0.5s ease-in-out;

            &:hover {
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }

      .diy-room-all-details-alignment {
        padding: 32px 0 0 0;

        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: #495057;
        }

        .diy-room-hignt-bg-alignment {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            background: linear-gradient(180deg, rgba(255, 243, 234, 0) 0%, #fff3ea 100%);
            width: 100%;
            height: 110px;
            bottom: 0;
            right: 0;
          }

          .diy-room-hight-alignment {
            height: auto;
            max-height: 280px;
            overflow-y: auto;

            .diy-room-all-details-flex-alignment {
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              margin: 16px 0 0 0;
              cursor: pointer;

              &:hover {
                transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

                transform: translateY(-6px);
              }

              .diy-room-product-details-alignment {
                display: flex;
                align-items: center;
                gap: 16px;

                .diy-room-product-img-alignment {
                  width: 72px;
                  height: 72px;

                  img {
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    object-fit: cover;
                  }
                }

                .diy-room-product-child-details-alignment {
                  h6 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: var(--secondary-900);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 245px;
                  }

                  .diy-room-rate-alignment {
                    display: flex;
                    align-items: center;
                    gap: 2px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 20px;
                      color: #808191;
                    }

                    .diy-room-starIcon-alignment {
                      display: flex;
                      align-items: center;
                      gap: 1.5px;
                    }
                  }

                  .diy-rrom-price-alignment {
                    padding: 2px 0 0 0;

                    p {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 21px;
                      color: var(--secondary-900);
                    }
                  }
                }
              }

              .diy-room-other-option-alignment {
                display: flex;
                align-items: center;
                gap: 16px;

                img {
                  cursor: pointer;
                  object-fit: cover;
                }
              }
            }
          }
        }
      }
    }
  }
}

// }
// }

.diyRoomDetailsWeb-view-modal-alignment {

  @media only screen and (max-width:"768px") {
    display: none;
  }
}