.mediaSection {
  background: var(--primary-50);
  padding: 32px;
  border-radius: 16px;
  height: 100%;
  position: relative;
  // height: calc(100vh - 430px);

  @media only screen and (max-width: '1099px') {
    padding: 16px;
    height: auto;
    background: transparent;
  }

  .mobileViewClubMediaDetailsAlignment {
    display: none;

    @media only screen and (max-width: '767px') {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .mediaDetailsAlignment {
        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: var(--secondary-500);
        }
      }

      .mediaPhotoVideoDropdownAlignment {
        display: flex;
        align-items: center;
        gap: 12px;
        border: 1.5px solid var(--secondary-100);
        border-radius: 8px;
        padding: 6px 16px;
        @media only screen and (max-width: '1024px') {
          border: 0;
          padding: 0;
          gap: 8px;
        }
        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          color: var(--secondary-600);
        }
      }
    }
  }

  .mediaDetailsGridAlignment {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 7px;

    @media only screen and (max-width: '1099px') {
      padding: 22px 0 0 0;
    }

    .Nomediabx {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      p {
        color: #9c6ac5;
        font-family: 'Poppins' sans-serif;
        font-size: 12px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        margin: 12px 0 0 0;
      }
    }

    .mediaImgAlignment {
      img {
        width: 100%;
        height: 194px;
        display: block;

        @media only screen and (max-width: '1024px') {
          height: 111px;
          border-radius: 8px;
        }
      }
    }
  }

  .clubMobileViewMediaNoDataModalAlignment {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    justify-content: center;

    @media only screen and (max-width: '1024px') {
      border-radius: 16px;
      background: var(--primary-50);
      margin: 22px 0 0 0;
      height: calc(100vh - 200px);
    }

    .clubMediaNoDataNameAlignment {
      span {
        color: var(--violet-300);
        font-size: 12px;
        font-style: italic;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }
}
