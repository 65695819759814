.story-user-name {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  color: var(--secondary-900);
}

.story-user-name-first {
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  color: var(--secondary-900);
  bottom: 1px;
  left: 0px;
}
