.message-modal-personal-selected-account-option-Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(30, 36, 50, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  z-index: 9999 !important;
  display: none;

  @media only screen and (max-width: '1099px') {
    display: block;
  }
}

.message-modal-personal-selected-account-option-Overlay.open {
  opacity: 1;
  visibility: visible;

  @media only screen and (max-width: '1099px') {
    display: block;
  }
}

.message-modal-personal-selected-account-option--modal {
  position: fixed;
  right: 0;
  width: 100%;
  height: 100vh;
  background: white;
  translate: 0 100%;
  transition: 0.3s;
  border-radius: 16px 16px 0 0;
  overflow-y: auto;
  top: 0;

  @media only screen and (max-width: '1099px') {
    display: block;
  }

  .message-modal-personal-selected-account-body-details-alignment {
    padding: 16px;

    .mobile-view-personal-selected-account-sticky {
      position: sticky;
      top: 0;
      background: #ffffff;

      .mobile-view-heading-details-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .mobile-view-user-details-alignment {
          display: flex;
          align-items: center;
          gap: 8px;

          .mobile-view-selected-profile-img-alignment {
            width: 32px;
            max-width: 32px;
            min-width: 32px;
            height: 32px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              object-fit: cover;
              display: block;
            }
          }

          h6 {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: var(--secondary-900);
          }

          .mobile-view-mute-icon-alignment {
            width: 16px;
            height: 16px;
            margin: 0 0 0 3px;

            img {
              width: 100%;
              height: 100%;
              display: block;
            }
          }
        }

        .mobile-view-other-option-alignment {
          display: flex;
          align-items: center;
          gap: 4px;

          .more-option-alignment {
            &:hover {
              background: #f8f8f8;
              border-radius: 10px;
            }
          }
        }
      }
    }

    .mobile-view-persoanal-user-details-alignment {
      height: calc(100vh - 200px);
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .mobile-view-personal-selected-main-profile-alignment {
        width: 56px;
        height: 56px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        color: var(--secondary-900);
        padding: 16px 0 0 0;
      }

      .mobile-view-location-alignment {
        padding: 2px 0 0 0;
        display: flex;
        align-items: center;
        gap: 2px;

        .mobile-view-map-icon-alignment {}

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 12px;
          line-height: 120%;
          color: #acb1c0;
          padding: 0;
          margin: 0;
        }
      }

      .mobile-view-button-alignment {
        padding: 16px 0 0 0;

        button {
          padding: 6px 16px;
          background: var(--primary-500);
          border-radius: 8px;
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }

    .send-message-position-alignment {
      position: sticky;
      bottom: 8px;
      background: #ffffff;
    }
  }
}

.message-modal-personal-selected-account-option--modal.open {
  translate: 0;
  z-index: 9999999;
  transform: translate(0, 0%);

  @media only screen and (max-width: '1099px') {
    display: block;
  }
}

.more-options-modal-alignment-Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  z-index: 9999999 !important;
  display: none;

  @media only screen and (max-width: '1099px') {
    display: block;
  }
}

.more-options-modal-alignment-Overlay.open {
  opacity: 1;
  visibility: visible;

  @media only screen and (max-width: '1099px') {
    display: block;
  }
}

.more-options-modal-alignment {
  position: fixed;
  right: 0;
  width: 100%;
  height: calc(100vh - 470px);
  background: white;
  translate: 0 100%;
  transition: 0.3s;
  border-radius: 16px 16px 0 0;
  overflow-y: auto;
  bottom: 0;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.25);
}

.more-option-modal-body-details-alignment {
  padding: 20px 16px;
  height: auto;

  ul {
    li {
      display: flex;
      align-items: center;
      gap: 17px;
      padding: 10px 16px;
      margin: 0 0 12px 0;
      transition: 0.4s ease-in-out;


      &:last-child {
        margin: 0;
      }

      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: var(--secondary-600);
        margin: 0;
        max-width: 100%;
        text-align: left;

        &.delete-text {
          color: #e71616;
        }
      }

      .active-img {
        display: none;
      }

      &:hover {
        background: var(--primary-50);
        border-radius: 6px;
        transition: 0.4s ease-in-out;

        p {
          color: var(--secondary-900);
        }

        svg {
          path {
            stroke: #1e2432;
          }
        }

        .active-img {
          display: block;
        }

        .defalt-img {
          display: none;
        }
      }

      &.active-more-option {
        background: var(--primary-50);
        border-radius: 6px;
        transition: 0.4s ease-in-out;

        p {
          color: var(--secondary-900);
        }

        svg {
          path {
            stroke: #1e2432;
          }
        }

        .active-img {
          display: block;
        }

        .defalt-img {
          display: none;
        }
      }
    }
  }
}

.more-options-modal-alignment.open {
  translate: 0;
  z-index: 9999999;
  transform: translate(0, 6%);

  @media only screen and (max-width: '1099px') {
    display: block;
  }
}

.more-options-modal-select-click-drop-alignment {
  border-bottom: 1px solid #eaecef;
  padding: 8px 0 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    width: 33px;
    height: 3px;
    background: #e4e4e8;
    border-radius: 1.5px;
  }
}

.message-mobile-view-modal {
  display: none;

  @media only screen and (max-width: '767px') {
    display: block;
  }
}