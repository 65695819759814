.mobileViewNewArrivalProductDetailsAlignment {
  padding: 0 16px;

  .mobileViewHeading {
    padding: 16px 0;
    position: sticky;
    top: 28px;
    background: var(--white);
    left: 0;
    z-index: 999;
    h6 {
      color: var(--secondary-900);
      font-size: 20px;
      font-weight: 600;
      line-height: 14px;
    }
  }

  .mobileViewArrivalBodyAlignment {
    padding: 0 0 16px 0;
    .mobileViewArrivalGrid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
        justify-items: center;
    }
  }
}
