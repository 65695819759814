.transition-main-container {
  // height: calc(100vh - 96px);
  // overflow-y: auto;
  border-radius: 8px;
  max-width: 1300px;

  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 1024px) {
    border-radius: 0px;
  }

  @media screen and (max-width: 767px) {
    height: calc(100vh - 180px);
  }

  @media screen and (max-width: 574px) {
    height: 100%;
    // padding: 0 0 64px 0;
  }
}

.product-detail-wrapper {
  overflow: hidden !important;
  // max-width: 1300px;
  margin: 0 auto;
  // padding: 16px 0;
  border-radius: 8px;

  @media screen and (max-width: 1024px) {
    border-radius: 0px;
    height: 100vh;

    overflow-y: auto !important;
    // margin: 0;
  }

  @media screen and (max-width: 767px) {
    min-width: 100%;
    // margin: 0;
  }

  //   height: 500px;

  .product-detail-secondary {
    // display: flex;
    // justify-content: center;
    gap: 14px;
    margin-bottom: 10px;
    border-radius: 8px;
    display: grid;
    grid-template-columns: 1fr 299px;

    @media screen and (max-width: 767px) {
      display: block;
      width: 100%;
      margin-bottom: 0;
      margin-top: 0;
    }

    .product-deatil-container {
      @media screen and (max-width: 767px) {
        height: 100%;
        background-color: #fff;
        border-radius: 10px;
        overflow: auto;
        overflow-x: hidden;
      }

      .product-main-template {
        // width: 982px;
        width: 100%;
        height: auto;

        @media only screen and (max-width: '1499px') {
          // width: 948px;
          width: 100%;
        }

        @media screen and (min-width: 992px) {
          // height: 100%;
          height: 714px;
        }

        // height: 100%;
        //   height: 708px;
        background-color: #ffffff;
        border-radius: 10px;
        display: flex;
        gap: 30px;
        //   justify-content: space-between;
        padding: 32px;

        @media screen and (max-width: 767px) {
          display: block;
          width: 100%;
          padding: 16px;
          border-radius: 10px 10px 0px 0px;
        }

        .product-main-image {
          @media screen and (max-width: 767px) {
            display: none;
          }

          .image-main {
            width: 359px;
            height: 517px;

            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              border-radius: 10px;
            }

            video {
              width: 359px;
              height: 517px;
              object-fit: cover;
              border-radius: 10px;
            }
          }

          .beta-image-section {
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            gap: 10px 8px;

            @media screen and (max-width: 767px) {
              display: flex;
              gap: 14px;
              width: 356px;
              overflow: scroll;
              scroll-behavior: smooth;
            }

            margin-top: 10px;

            .image-select-section {
              cursor: pointer;
              // width: 38px;
              // height: 45px;

              .image-preview-contain {
                width: 44px;
                height: 44px;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 5px;
                  object-fit: cover;
                }

                .video-container-align {
                  position: relative;

                  video {
                    width: 40px;
                    height: 40px;
                    border-radius: 5px;
                    object-fit: cover;
                  }

                  display: flex;
                  justify-content: center;
                  align-items: center;

                  img {
                    position: absolute;
                    width: 20px;
                    height: 20px;
                  }
                }
              }

              .active-image {
                border: 1px solid #6b21a8;
                border-radius: 5px;
              }
            }
          }
        }

        .product-detail-main {
          width: 100%;

          .product-title-row {
            display: flex;
            justify-content: space-between;

            @media screen and (max-width: 767px) {
              display: block;
            }

            .product-title {
              width: 384px;

              h1 {
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                color: var(--secondary-900);
              }
            }

            .likes-info-main {
              display: flex;
              justify-content: space-between;
              gap: 12px;
              cursor: pointer;

              @media screen and (max-width: 767px) {
                display: none;
              }

              span {
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.1px;
                color: #44444f;
                white-space: nowrap;
              }
            }
          }

          .price-tag-main {
            display: flex;
            gap: 10px;
            margin-top: 10px;

            p {
              font-weight: 600;
              font-size: 24px;
              line-height: 36px;
              color: var(--secondary-900);
            }

            span {
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              display: flex;
              align-items: center;
              text-decoration-line: line-through;
              color: #808191;
            }

            .off-price-section {
              background: #ff7d23;
              width: 86px;
              height: 27px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 12px;

              p {
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                display: flex;
                align-items: center;
                color: #ffffff;
              }
            }
          }

          .feedback-section-main {
            display: flex;
            gap: 10px;
            margin-top: 10px;

            p {
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #808191;
            }

            span {
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #808191;
            }
          }

          .gift-card-main {
            display: flex;
            gap: 10px;
            margin-top: 10px;

            .gift-card-section {
              display: flex;
              gap: 6px;
              background: linear-gradient(92.44deg, #556ee6 0.13%, #6b21a8 100%);
              border-radius: 13.5px;
              align-items: center;
              padding: 3px 10px;
              cursor: pointer;

              span {
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #f8f8f8;
              }
            }

            .free-shipping-section {
              background: #eaeaea;
              border-radius: 13.5px;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 2px;
              padding: 0px 10px;
              cursor: pointer;

              .shipping-truck {
                height: 30px;
                width: 30px;

                img {
                  height: 100%;
                  width: 100%;
                }
              }

              span {
                font-weight: 500;
                font-style: italic;
                font-size: 16px;
                line-height: 21px;
                display: flex;
                align-items: center;
                text-decoration-line: none;
                color: #5f40af;
              }
            }

            .cashback-section {
              background: rgba(199, 247, 138, 0.6);
              border-radius: 13.5px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;

              span {
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #5f40af;
                padding: 3px 10px;
              }
            }
          }

          .product-description-main {
            margin-top: 22px;
            display: grid;
            row-gap: 20px;

            .product-size-section-main {
              display: grid;
              grid-template-columns: 120px 1fr;

              @media screen and (max-width: 767px) {
                grid-template-columns: 92px 1fr;
              }

              // align-items: center;
              span {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: var(--secondary-900);
              }

              .product-size-section {
                display: grid;
                grid-template-columns: repeat(8, 44px);
                border-radius: 5px;

                @media screen and (max-width: 767px) {
                  grid-template-columns: repeat(5, 47px);
                  row-gap: 7px;
                }

                .size-box-alignment {
                  width: 40px;
                  height: 40px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border: 1px solid #acb1c0;
                  border-radius: 5px;
                  cursor: pointer;

                  span {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    text-align: center;
                    color: var(--secondary-900);
                  }
                }

                .active-size-class {
                  background: #495057;
                  border: 1px solid #e4e4e8;
                  border-radius: 5px;

                  span {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    text-align: center;
                    color: #f8f8f8;
                  }
                }
              }
            }

            .product-color-section-main {
              display: grid;
              grid-template-columns: 120px 1fr;

              @media screen and (max-width: 767px) {
                grid-template-columns: 92px 1fr;
              }

              span {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: var(--secondary-900);
              }

              .product-color-section {
                display: grid;
                grid-template-columns: repeat(7, 50px);
                gap: 6px;
                border-radius: 5px;

                @media screen and (max-width: 767px) {
                  grid-template-columns: repeat(4, 50px);
                }

                .color-box-alignment {
                  width: 52px;
                  height: auto;
                  // height: 80px;
                  padding: 6px;
                  border: 1px solid #eceef4;
                  border-radius: 5px;
                  background: #f8f8f8;
                  cursor: pointer;
                  word-break: break-all;

                  img {
                    width: 40px;
                    height: 40px;
                    border-radius: 5px;
                    object-fit: cover;
                  }

                  p {
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 15px;
                    text-align: center;
                    color: var(--secondary-900);
                    margin-top: 2px;
                  }
                }

                .active-color {
                  border: 1px solid #6b21a8;
                }
              }
            }

            .product-quantity-section-main {
              display: grid;
              grid-template-columns: 120px 1fr;

              @media screen and (max-width: 767px) {
                grid-template-columns: 92px 1fr;
              }

              align-items: center;

              span {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: var(--secondary-900);
              }

              .product-quantity-section {
                display: flex;
                align-items: center;
                gap: 16px;

                .quantity-button-contain {
                  display: flex;
                  align-items: center;
                  gap: 10px;

                  .quantity-button-section {
                    width: 42px;
                    height: 42px;
                    background: #ffffff;
                    border: 1px solid #acb1c0;
                    border-radius: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                  }

                  .product-quantity-box {
                    width: 64px;
                    height: 42px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    input {
                      width: 64px;
                      height: 42px;
                      background: #ffffff;
                      border: 1px solid #acb1c0;
                      border-radius: 10px;
                      padding: 10px 10px;
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 20px;
                      text-align: center;
                      color: var(--secondary-900);
                    }
                  }
                }

                .product-stock {
                  span {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: #808191;
                  }
                }
              }
            }

            .product-specs-container {
              display: grid;
              grid-template-columns: 120px 1fr;

              @media screen and (max-width: 767px) {
                grid-template-columns: 92px 1fr;
              }

              span {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: var(--secondary-900);
              }

              .product-specs-section {
                .specs-container-info {
                  width: 100%;
                  display: grid;
                  grid-template-columns: repeat(2, 50%);

                  @media screen and (max-width: 767px) {
                    grid-template-columns: repeat(2, 134px);
                  }

                  span {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: var(--secondary-900);
                  }
                }

                .see-more-option {
                  span {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    color: var(--primary-500);
                    cursor: pointer;
                  }
                }
              }
            }

            .product-store-main {
              display: grid;
              grid-template-columns: 120px 1fr;

              @media screen and (max-width: 767px) {
                grid-template-columns: 92px 1fr;
              }

              span {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: var(--secondary-900);
              }

              .store-information-section {
                display: grid;
                grid-template-columns: repeat(2, 50%);
                align-items: center;

                @media screen and (max-width: 767px) {
                  display: block;
                }

                .image-title-alignment {
                  display: flex;
                  gap: 10px;
                  align-items: center;

                  .store-image-contain {
                    img {
                      width: 72px;
                      height: 51px;
                      border-radius: 5px;
                      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
                      object-fit: cover;
                    }
                  }

                  .button-container {
                    span {
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 21px;
                      color: #808191;
                    }

                    p {
                      font-weight: 400;
                      font-size: 10px;
                      line-height: 15px;
                      color: #808191;
                    }
                  }
                }

                .follow-button-container {
                  display: flex;
                  gap: 16px;

                  @media screen and (max-width: 767px) {
                    margin-top: 12px;
                  }

                  .follow-button-start {
                    width: 139px;
                    height: 32px;
                    background: var(--primary-500);
                    border-radius: 8px;
                    display: flex;
                    justify-content: center;
                    align-content: center;

                    button {
                      font-weight: 600;
                      font-size: 14px;
                      display: flex;
                      align-items: center;
                      text-align: center;
                      color: #f8f8f8;
                    }
                  }

                  .message-icon-section {
                    width: 32px;
                    height: 32px;
                    background: var(--primary-500);
                    border-radius: 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    img {
                      width: 20px;
                      height: 20px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .modal-show-area {
      @media screen and (max-width: 767px) {
        transition: 0.3s ease-in-out;
        transform: translateY(-65%);
      }
    }

    .modal-close-area {
      @media screen and (max-width: 767px) {
        transition: 0.3s ease-in-out;
        transform: translateY(0%);
      }
    }

    .checkout-container {
      width: 100%;
      height: 714px;
      background-color: #ffffff;
      border-radius: 10px;
      padding: 32px;

      .checkout-main-container {
        h1 {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: var(--secondary-900);
          text-align: center;
        }
      }

      .main-product-image {
        width: 100%;
        height: 268px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 24px;

        img {
          width: 193px;
          height: 268px;
          object-fit: cover;
          border-radius: 10px;
        }
      }

      .final-cart-item-main {
        margin-top: 36px;

        .product-info-align {
          display: flex;
          justify-content: space-between;

          span {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #808191;
          }

          p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: right;
            color: var(--secondary-900);
          }
        }

        .devider-specs {
          width: 100%;
          border: 1px solid #acb1c0;
          margin: 14px 0;
        }

        .total-info-text {
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #495057;
          }

          p {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: right;
            color: var(--secondary-900);
          }
        }

        .checkout-button-section {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 57px;

          .shop-icon-alignment {
            width: 45px;
            height: 45px;
            background: #f8f8f8;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 26px;
              height: 26px;
              border-radius: 10px;
            }
          }

          .checkout-bg-contain {
            width: 182px;
            height: 45px;
            background: var(--primary-500);
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;

            button {
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              color: #ffffff;
            }
          }
        }
      }

      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    .mobile-modal-align {
      @media screen and (min-width: 992px) {
        display: none;
      }

      .product-image-mobile {
        display: flex;
        justify-content: center;

        @media screen and (max-width: 767px) {
          padding-top: 64px;
        }

        .product-main-image-mobile {
          .image-main {
            width: 359px;
            // height: 517px;
            height: 542px;

            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              border-radius: 10px;
            }

            video {
              width: 359px;
              height: 517px;
              object-fit: cover;
              border-radius: 10px;
            }
          }

          .beta-image-section {
            display: grid;
            grid-template-columns: repeat(7, 1fr);

            @media screen and (max-width: 767px) {
              display: flex;
              gap: 14px;
              width: 356px;
              overflow: scroll;
              scroll-behavior: smooth;
            }

            margin-top: 10px;
            margin-bottom: 10px;

            .image-select-section {
              cursor: pointer;
              width: 38px;
              height: 45px;

              .image-preview-contain {
                width: 40px;
                height: 40px;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 5px;
                  object-fit: cover;
                }

                .video-container-align {
                  position: relative;

                  video {
                    width: 40px;
                    height: 40px;
                    border-radius: 5px;
                    object-fit: cover;
                  }

                  display: flex;
                  justify-content: center;
                  align-items: center;

                  img {
                    position: absolute;
                    width: 20px;
                    height: 20px;
                  }
                }
              }

              .active-image {
                border: 1px solid #6b21a8;
                border-radius: 5px;
              }
            }
          }
        }
      }

      .header-for-mobileview-align {
        .back-header-alignment {
          @media screen and (max-width: 767px) {
            width: 100%;
            position: fixed;
            background-color: #eaecef;
            z-index: 1;
          }
        }

        .like-count-prod {
          span {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #44444f;
          }
        }
      }
    }
  }

  .new-product-details-secondary-section {
    display: grid;
    grid-template-columns: 660px 1fr;
    // padding: 16px 0 0 0;
    background: #fff;
    gap: 8px;
    border-radius: 0px;
    position: relative;

    .back-btn-left-alignment {
      width: 43px;
      height: 43px;
      min-width: 43px;
      max-width: 43px;
      display: flex;
      align-items: center;
      top: 0;
      justify-content: center;
      border: 2px solid var(--primary-50);
      position: fixed;
      top: 110px;
      border-radius: 50%;
      cursor: pointer;
      transition: 0.4s ease-in-out;
      transform: translateX(-66px);
    }

    .back-btn-left-alignment-mobile {
      position: absolute;
      top: 15px;
      left: 15px;
      background-color: rgb(30, 36, 50);
      border-radius: 10px;
      width: 44px;
      height: 32px;
      display: none;
      justify-content: center;
      align-items: center;
      z-index: 9;

      @media (max-width:576px) {
        display: flex;
      }
    }

    @media only screen and (max-width: '1024px') {
      display: block;
      width: 100%;
    }

    .new-product-left-side-alignment {
      padding: 0 0 0 16px;
      display: flex;
      justify-content: center;

      @media only screen and (max-width: '1024px') {
        display: block;
        padding: 0;
      }

      .new-product-details-slider-box {
        width: 520px;
        border-radius: 16px;
        position: relative;
        height: 700px;

        @media only screen and (max-width: '1024px') {
          height: auto;
          width: 100%;
        }

        .new-product-webView {
          height: 100%;

          @media only screen and (max-width: '1024px') {
            display: none;
          }

          .slick-dots {
            .slick-active {
              width: 48px !important;
              height: 48px !important;
              border: 2px solid pink;
              border-radius: 10px !important;
            }
          }
        }

        .new-product-mobileView {
          display: none;

          @media only screen and (max-width: '1024px') {
            display: block;
          }
        }

        @media only screen and (max-width: '1024px') {
          width: 100%;
          border-radius: 0;
        }

        .slick-next {
          @media (max-width:576px) {
            display: none !important;
          }
        }

        .slick-prev {
          @media (max-width:576px) {
            display: none !important;
          }
        }

        .new-produtc-img-alignment {
          width: 100%;
          height: 690px;
          background-color: rgb(237 237 237);
          position: relative;
          border-radius: 8px 8px 0px 0px;
          overflow: hidden;

          .poroduct-image-background {
            border-radius: 8px 8px 0px 0px;
            position: absolute;
            width: 100%;
            height: 690px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            mix-blend-mode: multiply;
          }

          img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
          }

          @media only screen and (max-width: '1024px') {
            height: auto;
          }

          .new-produtc-img-alignment-image {
            width: 100%;
            height: 100%;

            img {
              width: 100%;
              height: 100%;
              display: block;
              object-fit: cover;
            }
          }
        }

        .slick-slider .slick-list {
          border-radius: 16px;
          height: 696px !important;

          @media only screen and (max-width: '1024px') {
            border-radius: 0 !important;
            height: auto !important;
          }
        }

        .slick-prev {
          left: auto;
          right: 32px;
          z-index: 999 !important;
          bottom: 90px;
          transform: translateY(0);
          top: auto;
          z-index: 9999;
          background: transparent;

          &:hover {
            background: var(--primary-50) !important;

            svg {
              path {
                stroke: #556ee6;
                stroke-width: 2px;
              }
            }
          }
        }

        .slick-next {
          left: auto;
          right: 32px;
          z-index: 999 !important;
          bottom: 40px;
          transform: translateY(0);
          top: auto;
          z-index: 9999;
          transform: rotate(180deg);
          background: transparent;

          &:hover {
            background: var(--primary-50) !important;

            svg {
              path {
                stroke: #556ee6;
                stroke-width: 2px;
              }
            }
          }
        }

        .slick-dots {
          gap: 8px;

          li {
            width: 4px;
            height: 4px;
            margin: 0;

            button {
              width: 4px;
              height: 4px;

              &::before {
                width: 4px;
                height: 4px;
                background: #d5d8de;
              }
            }

            &.slick-active {
              width: 12px;
              border-radius: 10px 10px 16px 16px;

              button {
                width: 12px;

                &::before {
                  width: 12px;
                  background: var(--secondary-500);
                  border-radius: 10px 10px 16px 16px;
                }
              }
            }
          }
        }

        .new-product-details-slider-all-details {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          display: none;
          z-index: 9999;
          background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
          padding: 0 16px;
          height: 60px;

          &.new-product-bg-transperent {
            background: transparent;
          }

          @media only screen and (max-width: '1024px') {
            display: flex;
          }

          .new-product-slider-right-side-alignment {
            display: flex;
            align-items: center;
            gap: 8px;

            .slider-banner-cartbox-alignment {
              width: 44px;
              height: 32px;
              background: rgba(30, 36, 50, 0.6);
              border-radius: 8px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;

              .cart-number-alignment {
                position: absolute;
                top: 3px;
                right: 3px;

                .cart-number-box {
                  width: 16px;
                  height: 16px;
                  background: #d10000;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 50%;

                  p {
                    color: #fff;
                    text-align: center;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                  }
                }
              }
            }
          }
        }
      }

      .new-product-side-details-alignment {
        padding: 30px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        width: 108px;

        @media only screen and (max-width: '1024px') {
          align-items: flex-end;
          width: 100%;
          padding: 8px 16px;
          margin: 40px 0 0 0;
        }

        .top-img-details-alignment {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 54px;

          .number-img {
            @media only screen and (max-width: '1024px') {
              display: none;
            }

            h4 {
              color: var(--secondary-500);
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;

              span {
                color: var(--secondary-900);
                font-size: 32px;
                font-weight: 500;
                line-height: 38px;
                padding: 0 4px 0 0;
              }
            }
          }

          .slider-imges-small-view {
            display: flex;
            flex-direction: column;
            gap: 8px;
            justify-content: center;

            @media only screen and (max-width: '1024px') {
              display: none;
            }

            .slider-small-img {
              width: 44px;
              height: 44px;
              cursor: pointer;

              &.activeImg {
                width: 56px;
                height: 56px;
              }

              img {
                width: 100%;
                height: 100%;
                border-radius: 8px;
              }
            }
          }
        }

        .product-bottom-details-alignment {
          display: flex;
          flex-direction: column;
          gap: 28px;

          @media only screen and (max-width: '1024px') {
            flex-direction: row;
            padding: 10px 0;
          }

          .bottom-option-alignment {
            cursor: pointer;

            @media only screen and (max-width: '1024px') {
              &:first-child {
                order: 4;
              }

              &:nth-child(2) {
                order: 1;
              }

              &:nth-child(3) {
                order: 2;
              }

              &:last-child {
                order: 3;
              }
            }

            .webViewBookMark {
              @media only screen and (max-width: '1024px') {
                display: none;
              }
            }

            .mobileViewBookmark {
              display: none;

              @media only screen and (max-width: '1024px') {
                display: block;
              }
            }

            &:hover {
              svg {
                path {
                  stroke: #FF5733;
                }
              }
            }
          }
        }
      }

      .slick-dots {
        top: 0 !important;
        transform: translateY(30%);
        right: -80px;
        left: 106%;
        /* left: 0; */
        flex-direction: column;
        height: 340px;
        width: fit-content !important;
        align-items: center !important;
        justify-content: flex-start !important;
        overflow-y: auto;

        @media only screen and (max-width: '1024px') {
          width: auto !important;
          flex-direction: row;
          height: auto;
          right: unset;
          bottom: -32px !important;
          top: unset !important;
          transform: translateY(0) !important;
          left: 16px !important;
        }

        li {
          width: 44px !important;
          height: 44px !important;
          transition: 0.4s ease-in-out;

          @media only screen and (max-width: '1024px') {
            width: 4px !important;
            height: 4px !important;

            &.slick-active {
              width: 12px !important;
              height: 4px !important;
              border-radius: 10px 10px 16px 16px !important;
            }
          }

          @media only screen and (max-width: '1024px') {
            width: 4px !important;
            height: 4px !important;

            &:hover {
              width: 12px !important;
              height: 4px !important;
              border-radius: 10px 10px 16px 16px !important;
            }
          }

          a {
            width: 100%;
            height: 100%;

            img {
              width: 100%;
              height: 100%;
              border-radius: 8px;
            }
          }
        }
      }
    }

    .new-product-right-side-alignment {
      padding: 16px 32px 0 16px;
      border: 2px solid #eef1fd;
      border-radius: 16px;
      height: 700px;
      overflow-y: auto;

      @media only screen and (max-width: '1024px') {
        height: unset;
        padding: 24px 16px;
      }

      .new-product-top-details-alignment {
        height: calc(100% - 156px);
        overflow-y: auto;

        .new-product-details-heading-alignment {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 40px;

          h6 {
            color: var(--secondary-secondary-900);
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;

            @media only screen and (max-width: '1024px') {
              font-size: 14px;
              line-height: 21px;
              max-width: 290px;
            }
          }

          .more-option-icon {
            cursor: pointer;
            position: relative;
            transition: 0.4s ease-in-out;

            .more-option-dropdown-alignment {
              position: absolute;
              margin: 0;
              padding: 4px 0;
              text-align: left;
              list-style-type: none;
              background-color: #fff;
              border-radius: 2px;
              outline: none;
              box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
              width: 196px !important;
              border-radius: 10px;
              padding: 0;
              top: -5px;
              right: 0;
              z-index: 99;

              ul {
                li {
                  cursor: pointer;

                  .hide-web-class {
                    display: none;

                    @media only screen and (max-width: '767px') {
                      display: block;
                      margin: 8px 0 0 0;
                    }
                  }

                  .dropdown-option {
                    align-items: center;

                    &:hover {
                      background-color: #e4e4e8;
                      border-radius: none !important;
                    }

                    // background-color: plum;
                    .dropdown-main {
                      display: flex;
                      align-items: center;
                      justify-content: space-between !important;
                      padding: 0px 17px;

                      .dropdown-icon {
                        flex: 20%;
                        text-align: left;
                      }

                      .dropdown-content {
                        flex: 80%;

                        h5 {
                          font-size: 14px;
                          line-height: 21px;
                          color: #495057;
                          margin: 0;
                        }

                        p {
                          &:hover {
                            font-size: 8px;
                            line-height: 12px;
                            color: #808191;
                            font-weight: 400;
                            color: #495057;
                          }

                          font-size: 8px;
                          line-height: 12px;
                          color: #808191;
                          margin: 0;
                        }
                      }
                    }
                  }

                  .dropdown-option {
                    &:hover {
                      h5 {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 21px;
                        display: flex;
                        align-items: center;
                        color: var(--secondary-900);
                      }

                      p {
                        font-size: 8px;
                        line-height: 12px;
                        color: #808191;
                        font-weight: 400;
                        color: #495057;
                      }
                    }
                  }

                  .dropdown-option-last {
                    &:hover {
                      background-color: #e4e4e8;
                      border-radius: 0px 0px 10px 10px;
                    }
                  }

                  .dropdown-option-first {
                    &:hover {
                      background-color: #e4e4e8;
                      border-radius: 10px 10px 0px 0px;
                    }
                  }
                }
              }
            }

            @media only screen and (max-width: '1024px') {
              display: none;
            }

            &:hover {
              transition: 0.4s ease-in-out;

              svg {
                path {
                  fill: var(--primary-500);
                }
              }
            }
          }
        }

        .new-product-bootm-details-alignment {
          padding: 16px 0 0 0;

          // height: calc(100% - 180px);
          // overflow-y: auto;
          @media only screen and (max-width: '1024px') {
            padding: 10px 0 0 0;
          }

          .other-details-flex-alignment {
            display: flex;
            align-items: center;
            gap: 8px;

            .other-price-alignment {
              display: flex;
              align-items: baseline;
              gap: 2px;

              h6 {
                color: var(--secondary-900);
                font-size: 24px;
                font-weight: 600;
                line-height: 36px;

                @media only screen and (max-width: '1024px') {
                  font-size: 20px;
                  line-height: 24px;
                }
              }

              span {
                color: var(--grey2);
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                text-decoration-line: strikethrough;

                @media only screen and (max-width: '1024px') {
                  font-size: 12px;
                  line-height: 18px;
                }
              }
            }

            .off-box-alignment {
              height: 27px;
              padding: 0 14px;
              border-radius: 13.5px;
              background: #ff7d23;
              display: flex;
              align-items: center;

              @media only screen and (max-width: '1024px') {
                height: 24px;
                padding: 0 8px;
                border-radius: 12px;
                background: #f1efb0;
              }

              p {
                color: #fff;
                font-size: 14px;
                font-weight: 500;
                line-height: 21px;

                @media only screen and (max-width: '1024px') {
                  font-size: 13px;
                  line-height: 19px;
                  color: #474400;
                }
              }
            }
          }

          .rating-flex-alignment {
            display: flex;
            justify-content: flex-start;
            gap: 10px;
            padding-top: 10px;
            align-items: center;

            // @media only screen and (max-width:"1024px") {
            //   padding: 8px 0 0 0;
            // }

            h6,
            span {
              color: #808191;
              font-size: 16px;
              font-weight: 600;
            }

            .rating-alignment {
              display: flex;
            }

            svg {
              width: 19px;
              height: 19px;
              flex-shrink: 0;
            }
          }

          .follow-store-alignemnt {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 10px;

            @media only screen and (max-width: '1024px') {
              padding: 16px 0 0 0;
            }

            @media only screen and (max-width: '576px') {
              align-items: start;
            }

            .soldby-text-alignment {
              display: flex;
              align-items: center;
              gap: 4px;

              @media only screen and (max-width: '576px') {
                flex-direction: column;
                align-items: start;
              }

              h6 {
                color: #1e2432;
                font-size: 16px;
                font-weight: 400;
                line-height: 21px;
                cursor: pointer;

                span {
                  color: #556ee6;
                  font-size: 16px;
                  font-weight: 600;

                  &:hover {
                    color: #2844ac;
                  }
                }
              }

              .soldby-text {
                display: flex;
                align-items: center;
                gap: 2px;

                span {
                  color: var(--secondary-500);
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 21px;
                }
              }
            }

            a {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 7px;

              p {
                color: var(--primary-500);
                font-size: 15px;
                font-weight: 500;
                line-height: 21px;
                cursor: pointer;

                &:hover {
                  color: #2844ac;
                }
              }
            }
          }

          .product-all-details-section {
            padding: 40px 0 0 0;

            @media only screen and (max-width: '1024px') {
              padding: 48px 0 0 0;
            }

            .product-details-grid-alignment {
              display: grid;
              grid-template-columns: 96px 1fr;
              gap: 21px;
              align-items: center;
              margin: 0 0 24px 0;

              @media only screen and (max-width: '1024px') {
                grid-template-columns: 84px 1fr;
                gap: 6px;
                padding: 16px 0;
                margin: 0;

                &:last-child {
                  border-bottom: none;
                }
              }

              &:last-child {
                margin: 0;
              }

              h6 {
                color: var(--secondary-600);
                font-size: 16px;
                font-weight: 500;
                line-height: 21px;

                @media only screen and (max-width: '1024px') {
                  font-size: 14px;
                  line-height: 21px;
                  color: var(--secondary-900);
                }
              }

              .grid-left-side-alignment {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 8px;

                @media only screen and (max-width: '1024px') {
                  flex-wrap: nowrap;
                  width: 100%;
                  overflow-x: auto;
                  position: relative;

                  // &::before {
                  //   content: '';
                  //   position: absolute;
                  //   top: 0;
                  //   left: 0;
                  //   background: linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 93.4%);
                  //   height: 100%;
                  //   width: 32px;
                  //   z-index: 99;
                  // }

                  &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 93.4%);
                    height: 100%;
                    width: 37px;
                    z-index: 99;
                  }
                }

                .size-box-alignment {
                  padding: 9px 13px;
                  border-radius: 8px;
                  border: 1px solid var(--secondary-50);
                  display: flex;
                  align-items: center;
                  gap: 4px;
                  cursor: pointer;
                  transition: 0.4s ease-in-out;
                  white-space: nowrap;

                  &.colorBoxPadding {
                    padding: 5px 8px;
                  }

                  .colorBoxAlignment {
                    width: 18px;
                    height: 18px;
                    border-radius: 4px;
                    background: #f17400;
                  }

                  span {
                    color: var(--secondary-900);
                    text-align: center;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                  }

                  &:hover {
                    border-radius: 8px;
                    border: 1px solid var(--primary-50);
                    background: var(--primary-50);
                    transition: 0.4s ease-in-out;

                    span {
                      color: var(--secondary-900);
                    }
                  }

                  &.activeSizeBox {
                    border-radius: 8px;
                    border: 1px solid var(--primary-50);
                    background: var(--primary-50);
                    transition: 0.4s ease-in-out;

                    span {
                      color: var(--secondary-900);
                    }
                  }
                }
              }
            }

            .product-mobile-view-show-all-button {
              display: none;

              @media only screen and (max-width: '1024px') {
                display: block;
                text-align: center;
                padding: 9px 0 0 0;

                a {
                  color: var(--primary-500);
                  font-size: 15px;
                  font-weight: 500;
                  line-height: 24px;
                }
              }
            }
          }
        }
      }

      .new-product-sticky-alignment {
        position: sticky;
        bottom: 0;
        background: #fff;
        z-index: 999;
        left: 0;

        .quantity-details-alignment {
          padding: 16px 0 0 0;

          display: flex;
          align-items: center;
          gap: 44px;

          @media only screen and (max-width: '1024px') {
            padding: 41px 0 0 0;
            gap: 16px;
          }

          h6 {
            color: var(--secondary-600);
            font-size: 16px;
            font-weight: 500;
            line-height: 21px;

            @media only screen and (max-width: '1024px') {
              color: var(--secondary-900);
              font-weight: 600;
            }
          }

          .quantity-other-details-alignment {
            display: flex;
            align-items: center;
            gap: 16px;

            .quantity-input-flex-alignment {
              display: flex;
              align-items: center;
              gap: 8px;

              span {
                color: var(--grey2);
                font-size: 12px;
                font-weight: 400;
                line-height: 14px;
              }

              .incremnt-icon-img {
                padding: 6px 11px;
                cursor: pointer;

                @media only screen and (max-width: '1024px') {
                  height: 42px;
                  display: flex;
                  align-items: center;
                }
              }

              .quantity-input-aignment {
                border-radius: 10px;
                border: 1px solid var(--secondary-50);
                background: var(light-grey);
                width: 64px;
                height: 32px;

                @media only screen and (max-width: '1024px') {
                  height: 42px;
                  background: var(--light-grey);
                }

                input {
                  width: 100%;
                  height: 100%;
                  background: transparent;
                  border: none;
                  color: var(--secondary-900);
                  text-align: center;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 20px;
                }
              }
            }
          }

          span {
            color: var(--grey2);
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
          }
        }

        .quantity-other-all-details-alignment {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 40px;
          padding: 32px 0;

          @media only screen and (max-width: '1024px') {
            display: none;
          }

          .quantity-other-all-details-left {
            display: flex;
            align-items: center;
            gap: 4px;
            flex-wrap: wrap;

            .quantity-other-all-box {
              display: flex;
              align-items: center;
              gap: 4px;
              padding: 4px 8px;
              border-radius: 13px;
              background: var(--light-grey);
              cursor: pointer;

              span {
                color: var(--secondary-500);
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
              }

              .quantity-all-color-box-alignment {
                width: 12px;
                height: 12px;
                border-radius: 3px;
                background: #003e87;
              }
            }
          }

          .quantity-other-all-right-side-alignment {
            display: flex;
            align-items: center;
            gap: 8px;

            .checkout-cart-button {
              width: 44px;
              height: 44px;
              border-radius: 10px;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: 0.4s ease-in-out;

              &.cart-button-disabled {
                cursor: not-allowed !important;

                &:hover {
                  transform: translateY(-3px);
                }
              }

              &:hover {
                transition: 0.4s ease-in-out;
                background: var(--primary-50);
              }
            }

            .checkout-button-alignment {
              button {
                padding: 0 25px;
                height: 44px;
                border-radius: 10px;
                background: var(--primary-500);
                color: #fff;
                text-align: center;
                font-size: 18px;
                font-weight: 500;
                line-height: 24px;
                display: flex;
                align-items: center;

                &.button-disabled {
                  background: #bec1c9;
                  cursor: not-allowed !important;

                  &:hover {
                    transform: translateY(-3px);
                    background: #bec1c9;
                  }
                }

                &:hover {
                  transform: translateY(-3px);
                  background: #2844ac;
                }
              }
            }
          }
        }
      }
    }
  }

  .product-specifications-section {
    margin: 8px 0 0 0;
    background: #fff;

    .product-specifications-alignment {
      padding: 32px 16px;

      @media only screen and (max-width: '1024px') {
        padding: 8px 16px 16px 16px;
      }

      .product-specifications-heading {
        h4 {
          color: var(--secondary-900);
          font-size: 20px;
          font-weight: 600;
          line-height: 24px;

          @media only screen and (max-width: '1024px') {
            font-size: 16px;
            line-height: 21px;
          }
        }
      }

      .product-specifications-detailstable-alignment {
        margin: 24px 0 0 0;
        border-radius: 8px;
        border: 1px solid var(--secondary-50);

        @media only screen and (max-width: '1024px') {
          margin: 16px 0 0 0;
        }

        table {
          width: 100%;

          tbody {
            tr {
              border-bottom: 1px solid var(--secondary-50);

              &:last-child {
                border-bottom: none;
              }

              th {
                padding: 12px 21px;
                color: var(--secondary-900);
                font-size: 16px;
                font-weight: 600;
                line-height: 24px;
                border-right: 1px solid var(--secondary-50);

                @media only screen and (max-width: '1024px') {
                  font-size: 14px;
                  line-height: 21px;
                }
              }

              td {
                padding: 12px 32px;
                color: var(--secondar-900);
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;

                @media only screen and (max-width: '1024px') {
                  font-size: 14px;
                  line-height: 21px;
                }
              }
            }
          }
        }
      }
    }
  }

  .product-detail-info-tabs {
    // max-width: 1300px;
    border-radius: 16px;
    border: 2px solid #eef1fd;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 !important;
    margin: 24px 0 32px 0 !important;

    .ant-tabs-nav {
      margin-bottom: 0 !important;

      .ant-tabs-nav-wrap {
        padding: 28px 28px 0 28px;
        display: flex !important;
        justify-content: center !important;
        border-bottom: 2px solid #eef1fd;
      }
    }

    .ant-tabs-top>.ant-tabs-nav::before,
    .ant-tabs-top>div>.ant-tabs-nav::before {
      border: 0;
    }

    .ant-tabs-nav-list {
      border-bottom: none;
      border-radius: 0;
      gap: 54px;

      padding: 0 0 14px 0;

      .ant-tabs-tab {
        padding: 0;
        margin: 0;

        .ant-tabs-tab-btn {
          color: rgb(141, 137, 137);
          font-weight: 500 !important;
          border-left: none !important;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.32px;
          color: var(--secondary-500);
        }

        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: var(--secondary-900);
            font-weight: 600 !important;
            border-left: none;
          }

          &:before {
            content: '';
            position: absolute;
            height: 3px;
            background: #5f40af;
            border-radius: 4px 4px 0px 0px;
            bottom: -15px;
            left: 0;
            right: 0;
            margin: 0 auto;
          }
        }
      }

      .ant-tabs-ink-bar {
        display: none;
      }
    }

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .tab-section-mobile-main {
    @media screen and (min-width: 992px) {
      display: none;
    }

    width: 100%;
    background-color: #ffffff;

    @media screen and (max-width: 1024px) {
      padding: 0 16px;
    }

    .tab-container-flex-alignment {
      display: flex;
      align-items: center;
      gap: 20px;
      position: sticky;
      top: -2px;
      z-index: 999;
      background-color: #ffffff;

      @media screen and (max-width: 1024px) {
        top: 60px;
      }

      &::before {
        content: '';
        // background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 93.4%);
        width: 64px;
        height: 22px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 999;
        transform: translateY(50%);
      }

      &::after {
        content: '';
        // background: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0) 93.4%);
        width: 64px;
        height: 22px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
        transform: translateY(50%);
      }

      ul {
        width: 100%;
        display: flex;
        align-items: center;
        overflow-x: auto;
        padding: 15px 0;
        border-bottom: 2px solid #eceef4;

        li {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: var(--secondary-600);
          white-space: nowrap;
          padding: 0 16px 0 16px;
          position: relative;

          &:hover {
            color: var(--primary-700);
            font-weight: 600;

            &:before {
              content: '';
              position: absolute;
              bottom: -15px;
              right: 0;
              width: 100%;
              height: 2px;
              background: var(--primary-700);
              border-radius: 8px;
            }
          }

          &.product-details-active {
            color: var(--primary-700);
            font-weight: 600;

            &:before {
              content: '';
              position: absolute;
              bottom: -15px;
              right: 0;
              width: 100%;
              height: 2px;
              background: var(--primary-700);
              border-radius: 8px;
            }
          }
        }
      }
    }

    .tab-container-main {
      padding: 16px 16px 0px 16px;

      @media screen and (max-width: 1024px) {
        padding: 0;
      }

      .customer-traction-text {
        display: flex;

        @media screen and (max-width: 772px) {
          padding-top: 18px;
        }

        justify-content: space-between;
        align-items: center;

        &:last-child {
          padding-bottom: 20px;
        }

        span {
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          color: var(--secondary-900);
        }

        .image-count-align {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 8px;

          img {
            transform: rotate(360deg);
          }

          span {
            padding-top: 4px;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            text-align: right;
            letter-spacing: 0.32px;
            color: #808191;
          }

          .all-start-align {
            padding-top: 4px;

            span {
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #808191;
            }
          }
        }
      }

      .customer-traction-description-text {
        display: flex;

        @media screen and (max-width: 772px) {
          padding-top: 18px;
        }

        justify-content: space-between;
        align-items: center;

        &:last-child {
          padding-bottom: 20px;
        }

        span {
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          color: var(--secondary-900);
        }

        .image-count-align {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 8px;

          img {
            transform: rotate(360deg);
          }

          span {
            padding-top: 4px;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            text-align: right;
            letter-spacing: 0.32px;
            color: #808191;
          }

          .all-start-align {
            padding-top: 4px;

            span {
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #808191;
            }
          }
        }

        .rotate {
          transform: rotate(90deg);
        }

        .transition-icon {
          transition: 0.3s ease-in-out;
        }
      }

      .tab-hidden-area {
        max-height: 0px;
        overflow: hidden;
        transition: 0.3s ease-in-out;
      }

      .tab-show-area {
        height: 100%;
        overflow: hidden;
        transition: 0.3s ease-in-out;
      }

      .description {
        height: calc(100vh - 212px);
        overflow-y: auto;

        .description-main-container {
          .description-heading {
            padding: 16px 0 0 0;

            h6 {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 20px;
              line-height: 30px;
              color: var(--secondary-900);

              @media (max-width:576px) {
                line-height: 45px;
              }
            }
          }

          h2 {
            @media (max-width:576px) {
              line-height: 45px;
            }
          }

          .image-container {
            height: 100%;
            width: 100%;
            padding-top: 18px;
            display: grid;
            row-gap: 8px;

            img {
              height: 100%;
              width: 100%;
            }
          }

          .image-description-main {
            h3 {
              padding-top: 6px;
              font-weight: 600;
              font-size: 20px;
              line-height: 30px;
              color: var(--secondary-900);
            }

            p {
              padding-top: 6px;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: var(--secondary-900);
            }
          }
        }
      }

      .customer-traction-text:first-child {
        padding-top: 18px;
      }

      .customer-traction-text:last-child {
        padding-bottom: 18px;
      }
    }
  }

  .checkout-button-section-mobile {
    @media screen and (min-width: 992px) {
      .checkout-contain-main {
        display: none !important;
      }
    }

    @media only screen and (max-width: 1024px) {
      position: sticky;
      bottom: 0;
      left: 0;
    }

    // @media screen and (max-width:"767px") {
    //   position: static;
    // }

    position: fixed;
    z-index: 999999;
    bottom: -1px;
    width: 100%;

    @media only screen and (max-width: '1024px') {
      background: #fff;
      padding: 8px 16px 16px 16px;
      box-shadow: 13px 28px 58.5926px rgba(0, 0, 0, 0.19);
    }

    .mobile-view-selected-product-all-details-alignment {
      display: none;

      @media only screen and (max-width: '1024px') {
        display: block;
      }

      .mobile-view-all-details-flex-alignment {
        display: flex;
        align-items: center;
        gap: 4px;

        .mobile-view-select-box {
          padding: 4px 8px;
          border-radius: 13px;
          background: var(--light-grey);
          display: flex;
          align-items: center;
          gap: 4px;

          .mobile-color-box {
            width: 12px;
            height: 12px;
            border-radius: 3px;
            background: #003e87;
          }

          p {
            color: var(--secondary-500);
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
          }
        }
      }
    }

    .checkout-contain-main {
      width: 100%;
      max-width: 394px;
      height: 98px;
      background: #ffffff;
      box-shadow: 13px 28px 58.5926px rgba(0, 0, 0, 0.19);
      display: flex;
      gap: 8px;
      align-items: center;
      padding: 16px;

      @media only screen and (max-width: '1024px') {
        padding: 8px 0 0 0;
        height: auto;
        background: transparent;
        box-shadow: unset;
      }

      @media screen and (max-width: '767px') {
        max-width: 100%;
      }

      .shop-icon-alignment {
        width: 52px;
        height: 45px;
        background: #f8f8f8;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media only screen and (max-width: '1024px') {
          width: 44px;
          height: 44px;
          min-width: 44px;
          max-width: 44px;
          border-radius: 10px;
          background: var(--primary-50);
        }

        img {
          width: 26px;
          height: 26px;
          border-radius: 10px;
        }
      }

      .checkout-bg-contain {
        // width: 100%;
        // height: 45px;
        // background: var(--primary-500);
        // border-radius: 10px;
        // display: flex;
        // justify-content: center;
        // align-items: center;

        @media only screen and (max-width: '1024px') {
          height: 44px;
        }

        height: 100%;
        width: 100%;

        button {
          width: 100%;
          height: 45px;
          background: var(--primary-500);
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #ffffff;

          &.button-disabled {
            background: #bec1c9;
            cursor: not-allowed !important;

            &:hover {
              transform: translateY(-3px);
              background: #bec1c9;
            }
          }

          @media only screen and (max-width: '1024px') {
            font-size: 18px;
            line-height: 28px;
          }

          s {
            text-decoration: unset;
          }
        }
      }
    }
  }
}

.product-store-modal-body {
  background: #fff;
  max-height: calc(100vh - 98px);
  overflow-y: auto;
  border-radius: 0;

  @media only screen and (max-width: '1024px') {
    max-height: 100%;
    border-radius: 0px;
    padding: 0 0 56px 0;
  }

  .product-stote-bg-img {
    height: 294px;
    position: relative;
    border-radius: 16px 0px 0px 0px;

    @media only screen and (max-width: '1024px') {
      height: 166px;
    }

    &:before {
      position: absolute;
      content: '';
      background: linear-gradient(180deg, rgba(30, 36, 50, 0) 0%, rgba(30, 36, 50, 0.6) 100%);
      width: 100%;
      height: 134px;
      bottom: 0;
      left: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    svg {
      border-radius: 10px 10px 0 0;
    }

    .product-share-like-alignment {
      display: flex;
      align-items: center;
      gap: 8px;
      // margin: 0 0 25px 0;
      position: absolute;
      right: 94px;
      bottom: 33px;

      @media only screen and (max-width: '1024px') {
        display: none;
      }

      .product-icon-box {
        width: 44px;
        height: 44px;
        border-radius: 10px;
        background: rgba(30, 36, 50, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 5px;

        img {
          width: 24px;
          height: 24px;
          display: block;
        }
      }
    }
  }

  .mobile-product-store-details-alignment {
    display: none;

    @media only screen and (max-width: '1024px') {
      display: block;
      background: #ffffff;
      border-radius: 16px 16px 0px 0px;
      padding: 16px;
      margin-top: -14px;
      position: relative;
      z-index: 999;

      .mobile-produtc-store-main-details-alignment {
        display: flex;
        align-items: center;
        gap: 16px;

        .mobile-view-store-img-aligment {
          width: 90px;
          height: 90px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            object-fit: contain;
          }
        }

        .mobile-view-store-all-details-alignemnt {
          h5 {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 18px;
            line-height: 120%;
            color: #000000;
          }

          .rate-star-alignment {
            display: flex;
            align-items: center;
            gap: 16px;
            padding: 4px 0 0 0;

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 12px;
              line-height: 18px;
              color: #808191;
            }

            .rate-details-alignment {
              display: flex;
              align-items: center;
              gap: 4px;

              .star-rate-alignment {
                display: flex;
                align-items: center;
                gap: 2px;
              }

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: #808191;
              }
            }
          }

          .location-alignment {
            display: flex;
            align-items: center;
            gap: 6px;
            padding: 6px 0 0 0;

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: var(--secondary-500);
            }
          }
        }
      }

      .mobile-follow-button-alignment {
        display: flex;
        align-items: center;
        gap: 16px;
        padding: 14px 0 0 0;

        .mobile-store-follow-button-alignment {
          @media only screen and (max-width: '574px') {
            width: 100%;
          }

          button {
            background: var(--primary-500);
            border-radius: 8px;
            width: 100%;
            height: 32px;
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
            cursor: pointer;

            @media only screen and (max-width: '1024px') {
              padding: 0 80px;
            }

            @media only screen and (max-width: '574px') {
              padding: 0;
            }
          }
        }

        .mobile-store-other-option-alignment {
          display: flex;
          align-items: center;
          gap: 20px;

          .mobile-stoe-icon-alignment {
            width: 24px;
            height: 24px;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

      .mobile-follow-following-details-alignment {
        padding: 30px 45px 8px 45px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media only screen and (max-width: '1024px') {
          max-width: 500px;
        }

        @media only screen and (max-width: '767px') {
          max-width: 100%;
        }

        .mobile-follow-following-child-details-alignment {
          text-align: center;

          h6 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: var(--secondary-900);
            padding: 0 0 2px 0;
          }

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #808191;
          }
        }
      }
    }
  }

  .store-product-tab-follow-alignment {
    margin: 8px 0 0 0;
    background: #ffffff;
    // height: 76px;
    padding: 0 95px;
    position: sticky;
    top: 0;
    border-bottom: 8px solid #eceef4;
    z-index: 9999;

    @media only screen and (max-width: '1024px') {
      display: none;
    }

    .store-product-flex-alignment {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .store-product-tab-alignment {
        width: 100%;
        height: 76px;
        background-color: #ffffff;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0 auto;
        padding: 1.5rem 2.5rem;
        border-radius: 0.65rem;
        overflow: hidden;

        .store-product-tab-alignment__list {
          position: relative;
          display: flex;
          justify-content: space-between;
          width: 29%;
          z-index: 99;
          height: 100%;
          top: 11px;
        }

        &__list {
          position: relative;
          width: 29%;
          display: flex;
          justify-content: center;
          justify-content: space-between;
          column-gap: 60px;
        }

        &__link {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        &__name {
          position: absolute;
          transition: 0.4s;
          transform: translateY(28px);
          z-index: 20;
          // opacity: 0;
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #acb1c0;
        }

        .store-product-tab-indicator {
          position: absolute;
          left: -48px;
          fill: var(--first-color);
          transition: 0.3s;
          top: -27px;
          // bottom: 0;
        }

        .store-product-tab-alignment__link {
          height: 100%;
          z-index: 99;

          // &:nth-child(1) {
          position: relative;
          right: -21px;
          top: -8px;
          // }

          &:hover {
            &.calender-icon {
              svg {
                path {
                  stroke: #1e2432;
                  fill: none;
                }
              }
            }

            svg {
              path {
                fill: #000000;
              }

              circle {
                stroke: #000000;
              }
            }

            .store-product-tab-alignment__name {
              opacity: 1;
              transform: translateY(28px);
              color: var(--secondary-900);
              font-weight: 600;
            }
          }
        }

        .store-product-tab-alignment__link:hover {
          opacity: 1;
          transform: translateY(-5px);
        }

        .store-product-tab-active-link .store-product-tab-alignment__name {
          opacity: 1;
          transform: translateY(28px);
          color: var(--secondary-900);
          font-weight: 600;
        }

        .store-product-tab-active-link {
          top: -8px;
        }

        .store-product-tab-alignment__link:nth-child(1).store-product-tab-active-link~.store-product-tab-indicator {
          transform: translateX(calc(20px * 1));
        }

        .store-product-tab-alignment__link:nth-child(2).store-product-tab-active-link~.store-product-tab-indicator {
          transform: translateX(calc(100px * 1));
        }

        .store-product-tab-alignment__link:nth-child(3).store-product-tab-active-link~.store-product-tab-indicator {
          transform: translateX(calc(184px * 1));
        }

        .store-product-tab-alignment__link:nth-child(4).store-product-tab-active-link~.store-product-tab-indicator {
          transform: translateX(calc(268px * 1));
        }

        .store-product-tab-alignment__link:nth-child(5).store-product-tab-active-link~.store-product-tab-indicator {
          transform: translateX(calc(350px * 1));
        }
      }

      .share-follow-alignment {
        display: flex;
        align-items: center;
        gap: 8px;

        .message-box-alignment {
          background: #f8f8f8;
          border-radius: 10px;
          padding: 5.5px;

          img {
            width: 33px;
            height: 33px;
            display: block;
            cursor: pointer;
          }

          &:hover {
            background: #eef1fc;
          }
        }

        .follow-button-alignment {
          width: 200px;
          height: 44px;

          button {
            background: var(--primary-500);
            border-radius: 10px;
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #f8f8f8;
            cursor: pointer;
            width: 100%;
            height: 100%;

            &.active {
              background: #2b41a8;
            }

            &.following {
              background: #f8f8f8;
              color: var(--primary-500);

              &:hover {
                background: #eef1fc;
              }
            }
          }
        }
      }
    }
  }

  .mobile-view-store-product-header-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    display: none;
    background: rgba(248, 248, 248, 0.92);
    backdrop-filter: blur(13.5914px);

    &.Close {
      transform: translateY(-100%);
      transition: 0.3s ease-in-out;
      // position: absolute;
      overflow: hidden;
    }

    &.Open {
      transform: translateY(0%);
      transition: 0.3s ease-in-out;
      position: sticky;
    }

    @media only screen and (max-width: '1099px') {
      display: flex;
    }

    .store-modal-back-button-alignment {
      background: #ffffff;
      border-radius: 10px;
      width: 37px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .store-modal-more-option-alignment {
      background: #ffffff;
      border-radius: 10px;
      width: 44px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .store-product-details-alignment {
      display: flex;
      align-items: center;
      gap: 8px;

      .store-product-logo-alignment {
        width: 32px;
        height: 32px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .store-product-logo-name-alignment {
        h6 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          color: var(--secondary-900);
        }
      }
    }
  }

  .mobile-view-store-product-tab-alignment {
    display: none;

    @media only screen and (max-width: '1024px') {
      display: flex;
      margin: 0px 0 8px 0;
      background: #ffffff;
      padding: 8px 16px;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      overflow-x: auto;

      ul {
        display: flex;
        align-items: center;

        li {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 14px 16px;

          &:hover {
            background: var(--primary-50);
            border-radius: 8px;

            svg {
              g {
                path {
                  fill: #3c4ea3;
                }
              }

              path {
                fill: #3c4ea3;
              }
            }

            p {
              color: var(--primary-700);
            }
          }

          &.active-club-tab {
            background: var(--primary-50);
            border-radius: 8px;

            p {
              color: var(--primary-700);
            }
          }

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 13px;
            line-height: 20px;
            color: var(--secondary-600);
          }
        }
      }
    }

    span {
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      color: var(--secondary-600);
    }
  }
}

.product-button-section {
  width: 82px;
  height: 39px;

  @media screen and (max-width: 767px) {
    height: 32px;
    width: 50px;
  }

  background: var(--primary-500);
  opacity: 0.5;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;

  button {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
  }
}

.active-button {
  width: 82px;
  height: 32px;
  background: var(--primary-500);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 6px;

  button {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
  }
}

.left-full-arrow-alignment {
  position: absolute;
  top: 0;
  left: 0;
  width: 56px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  height: calc(100% - 8px);
  left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
}

.right-full-arrow-alignment {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  right: 5px;
  width: 56px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  height: calc(100% - 8px);
  border-radius: 5px;
}

.comment-main-container {
  padding: 0 18px 18px 18px;
  margin: 0px 341px;

  @media screen and (max-width: 767px) {
    margin: 0;
  }

  .comment-section-title {
    display: flex;
    gap: 4px;
    align-items: center;
    padding-top: 12px;

    @media screen and (max-width: 767px) {
      padding-top: 0;
      padding-bottom: 8px;
    }

    h3 {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: var(--secondary-900);
    }

    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 1px;
      color: #808191;
    }
  }

  .product-comment-section-main {
    // height: 530px;
    height: 100%;

    @media screen and (max-width: 767px) {
      height: calc(100vh - 176px);
    }

    overflow: scroll;

    .commented-person-section {
      .commnet-section-grid {
        background: #f8f8f8;
        border-radius: 0px 10px 10px 10px;
        padding: 16px;
        display: grid;
        grid-template-columns: 50px 1fr;
        margin-top: 14px;

        .commnet-text-section {
          .commenter-name {
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: #495057;

            span {
              font-weight: 400;
              font-size: 10px;
              line-height: 15px;
              color: #808191;
            }
          }

          .comment-description {
            margin-top: 8px;

            p {
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: #495057;
            }
          }

          .show-more-section {
            margin-top: 6px;
            cursor: pointer;

            span {
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              color: var(--primary-500);
            }
          }

          .reply-comment-alignment {
            .commnet-section-grid-second {
              background: #f8f8f8;
              border-radius: 0px 10px 10px 10px;
              padding-top: 14px;
              display: grid;
              grid-template-columns: 50px 1fr;

              .commnet-text-section {
                .commenter-name {
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 21px;
                  color: #495057;

                  span {
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 15px;
                    color: #808191;
                  }
                }

                .comment-description {
                  margin-top: 8px;

                  p {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #495057;
                  }
                }

                .show-more-section {
                  margin-top: 6px;
                  cursor: pointer;

                  span {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    color: var(--primary-500);
                  }
                }
              }
            }
          }
        }
      }

      .comment-count-section {
        display: flex;
        gap: 10px;
        align-items: center;

        .replies-likes-main {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 8px 0;
          gap: 10px;

          .replies-section-align {
            width: 32px;
            height: 32px;
            background: #f8f8f8;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }

          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #808191;
            cursor: pointer;
          }
        }
      }
    }
  }
}

//Slider-design
.gallery-container-main {
  .slick-dots {
    width: auto;
    top: -36px;
    right: 0;
    height: 30px;
  }

  .image-preview-section-main {
    display: flex;
    justify-content: space-between;
    // height: 586px;

    .image-preview-section {
      height: 100%;
      width: 100%;
      display: flex;
      gap: 10px;

      .image-preview-align {
        width: 474px;
        height: 482px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
          object-fit: cover;
        }
      }

      .image-comment-section-main {
        padding-top: 16px;

        .image-section-flex {
          display: flex;
          gap: 8px;
          align-items: center;

          .image-alignment {
            width: 32px;
            height: 32px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 50%;
            }
          }

          span {
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            color: #495057;
          }

          p {
            font-weight: 400;
            font-size: 10px;
            line-height: 15px;
            color: #808191;
          }
        }

        .comment-description-align {
          margin-top: 8px;

          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #495057;
          }
        }

        .goto-comment-section {
          padding: 4px 0 0 0;

          span {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: var(--primary-500);
          }
        }
      }
    }

    .slider-section {
      height: 100%;
      width: 100%;

      .main-image-container-align-pre {
        position: relative;

        .image-contaoner-main-pre {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 236px;
          border-left: 4.5px solid white;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 5px;
            background: linear-gradient(180deg, rgba(30, 36, 50, 0.6) 0%, rgba(30, 36, 50, 0) 100%);
          }
        }
      }
    }

    .section-devide {
      border: 1px solid #acb1c0;
      margin: 22px 4px;
    }
  }

  .image-count-section {
    display: flex;
    gap: 8px;
    align-items: flex-end;
    padding: 16px 0;

    span {
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #808191;
    }

    p {
      cursor: pointer;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: var(--primary-500);
    }
  }

  .main-image-container {
    position: relative;
    padding: 0 4px 0 0;

    .image-contaoner-main {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 236px;
      border-left: 2px solid white;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
        background: linear-gradient(180deg, rgba(30, 36, 50, 0.6) 0%, rgba(30, 36, 50, 0) 100%);
      }
    }

    .image-info-main {
      .image-rate-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;

        .star-count-section {
          position: absolute;
          top: 16px;
          left: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 4px;

          span {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #ffffff;
            padding-top: 3px;
          }

          svg {
            height: 14px;
            width: 14px;
          }
        }

        .image-count-alignment {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 4px;
          top: 16px;
          right: 16px;

          span {
            padding-top: 3px;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            text-align: center;
            color: #ffffff;
          }

          img {
            height: 14px;
            width: 14px;
          }
        }
      }
    }
  }
}

.shipping-section-align {
  max-width: 630px;
  margin: 0 auto;
  padding-top: 12px;

  @media screen and (max-width: 767px) {
    margin: 0px;
    // overflow: scroll;
    // height: calc(100vh - 68px);
    padding-top: 0;
  }
}

.shipping-section-align-body {
  padding: 16px 0 0 0;
  position: relative;

  @media screen and (max-width: 1024px) {
    height: calc(100vh - 300px);
    overflow-y: auto;

    &::before {
      position: absolute;
      content: '';
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) -4.55%, #fff 100%);
      width: 100%;
      height: 110px;
      bottom: 0;
      left: 0;
      z-index: 999;
    }
  }

  h5 {
    color: var(--secondary-900);
    text-align: justify;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .shipping-all-details-notes {
    padding: 18px 0 0 0;

    @media only screen and (max-width: '1024px') {
      padding: 16px 0 0 0;
    }

    p {
      color: var(--secondary-900);
      text-align: justify;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
  }
}

.review-container-main {
  padding: 12px 20px;

  // @media screen and (max-width: 767px) {
  //   overflow: scroll;
  //   height: calc(100vh - 142px);
  //   padding-top: 0;
  // }
  @media screen and (max-width: 1024px) {
    padding: 12px;
    position: relative;
    height: calc(100vh - 266px);
    overflow-y: auto;
  }

  .review-section-container {
    display: grid;
    grid-template-columns: 264px 1fr;
    gap: 75px;
    align-items: flex-start;

    @media screen and (max-width: 767px) {
      display: block;
    }

    .review-title-align {
      // width: 30%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      display: none;

      @media screen and (max-width: 1024px) {
        width: 100%;
        display: block;
      }

      .rate-review-align {
        padding: 0 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (min-width: 992px) {
          display: none;
        }

        @media only screen and (max-width: '1024px') {
          padding: 0;
        }

        .rate-section-align {
          span {
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: var(--secondary-900);
          }
        }
      }
    }

    .review-detail-section {
      // width: 70%;
      // height: 526px;
      background: #ffffff;
      // border: 1px solid #e4e4e8;
      border-radius: 8px;
      padding: 16px;
      height: 100%;

      @media screen and (max-width: 1024px) {
        width: 100%;
        border: none;
        padding: 16px 0;
      }

      .rate-main-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 16px;

        .ratting-menu-section {
          display: flex;
          gap: 12px;
          align-items: center;

          @media screen and (max-width: 1024px) {
            flex-wrap: wrap;
            gap: 8px;
          }

          .star-container {
            display: flex;
            gap: 4px;
            align-items: center;
            background: #ffffff;
            border: 0.4178px solid #e4e4e8;
            border-radius: 21.5px;
            padding: 1px 10px;
            cursor: pointer;

            @media screen and (max-width: 1024px) {
              height: 32px;
            }

            .all-start-align {
              padding-top: 4px;

              @media screen and (max-width: 1024px) {
                padding: 0;
              }

              span {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: var(--primary-500);

                @media screen and (max-width: 1024px) {
                  color: var(--secondary-500);
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 21px;
                }
              }
            }

            .start-icon-align {
              @media screen and (max-width: 1024px) {
                width: 20px;
                height: 20px;

                svg {
                  width: 20px;
                  height: 20px;
                }
              }
            }

            .feedback-total-count {
              padding-top: 4px;

              @media screen and (max-width: 1024px) {
                padding: 0;
              }

              p {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #808191;

                @media screen and (max-width: 1024px) {
                  color: var(--grey2);
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 21px;
                }
              }
            }
          }

          .active-class {
            border-radius: 16px;
            border: 1px solid var(--primary-50);
            background: var(--primary-50);

            .all-start-align {
              span {
                color: var(--secondary-900);
              }
            }

            .feedback-total-count {
              p {
                color: var(--secondary-900);
              }
            }
          }
        }

        .image-section-align {
          width: 151px;
          height: 32px;
          background: #ffffff;
          border: 0.4178px solid #e4e4e8;
          border-radius: 21.5px;
          padding: 2px 5px;
          display: flex;
          justify-content: right;
          gap: 3px;
          align-items: center;
          cursor: pointer;

          @media screen and (max-width: 1024px) {
            display: none;
          }

          .image-contanier-align {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 19.17px;
              height: 19.17px;
              margin-left: -12px;
              box-shadow: 0 0 6px rgba(0, 0, 0, 0.2) l;
              border: 1.5px solid #ffffff;
              border-radius: 2.94961px;
            }
          }

          .image-font-align {
            span {
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: var(--primary-500);
            }
          }

          .image-count-align {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #808191;
          }
        }

        .back-sectiona-main {
          display: flex;
          gap: 42px;
          align-items: center;

          .back-button-align {
            width: 68px;
            height: 32px;
            background: #f8f8f8;
            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            span {
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              color: #808191;
            }
          }

          .image-main-title-align {
            cursor: context-menu;

            span {
              font-weight: 500;
              font-size: 20px;
              line-height: 30px;
              color: var(--secondary-900);
            }
          }
        }
      }

      .no-reviews-message-div {
        height: 100%;

        .no-reviews-message {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .rate-main-header-for-image {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;

    .ratting-menu-section {
      display: flex;
      gap: 12px;
      align-items: center;

      .star-container {
        display: flex;
        gap: 4px;
        align-items: center;
        background: #ffffff;
        border: 0.4178px solid #e4e4e8;
        border-radius: 21.5px;
        padding: 1px 10px;
        cursor: pointer;

        .all-start-align {
          padding-top: 4px;

          span {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: var(--primary-500);
          }
        }

        .feedback-total-count {
          padding-top: 4px;

          p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #808191;
          }
        }
      }

      .active-class {
        border: 1px solid #556ee6;
      }
    }

    .rate-main-header-star-alignment {
      display: flex;
      align-items: center;
      gap: 9px;

      .new-review-dropdown-alignment {
        padding: 6px 16px;
        border-radius: 8px;
        border: 1.5px solid #d5d8de;
        display: flex;
        align-items: center;
        gap: 32px;
        cursor: pointer;

        p {
          color: var(--secondary-600);
          font-size: 15px;
          font-weight: 500;
          line-height: 20px;
        }
      }
    }

    .back-sectiona-main {
      display: flex;
      gap: 30px;
      align-items: center;

      .back-button-align {
        width: 68px;
        height: 32px;
        background: #f8f8f8;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #808191;
        }
      }

      .image-main-title-align {
        cursor: context-menu;

        span {
          color: var(--secondary-900);
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }
  }
}

.review-count-box {
  @media screen and (max-width: 1024px) {
    padding: 16px 0 0 0;
    display: block;
  }

  @media screen and (max-width: 1024px) {
    .modal-all-review-notes-alignment {
      padding: 16px 16px 8px 16px;
      background: var(--light-grey);

      p {
        color: var(--secondary-900);
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }

  .ratting-count-align {
    display: flex;
    justify-content: flex-start;
    gap: 2px;
    align-items: baseline;

    @media only screen and (max-width: '1024px') {
      &.ratting-all-modal-bg {
        padding: 0 16px;
        background: var(--light-grey);
      }
    }

    @media screen and (max-width: 767px) {
      justify-content: flex-start;
    }

    .rating-main-count {
      span {
        font-weight: 600;
        font-size: 66.3035px;
        line-height: 99px;
        color: var(--secondary-900);
      }

      @media screen and (max-width: 1024px) {
        span {
          color: var(--secondary-900);
          font-size: 24px;
          font-weight: 600;
          line-height: 28px;
        }
      }
    }

    .total-rating-count {
      p {
        font-weight: 400;
        font-size: 23.2958px;
        line-height: 35px;
        color: var(--grey2);
      }

      @media screen and (max-width: 1024px) {
        p {
          color: var(--grey2);
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
        }
      }
    }
  }

  .mobile-view-star-rating-alignment {
    @media only screen and (max-width: '1024px') {
      padding: 2px 0 0 0;

      &.ratting-all-star-modal-bg {
        padding: 0 16px 16px 16px;
        background: var(--light-grey);
      }
    }
  }

  .rate-review-all-details-alignment {
    padding: 50px 0 0 0;

    @media only screen and (max-width: '1024px') {
      padding: 20px 0 0 0;

      &.second-rate-review-all-details-modal {
        padding: 16px;
      }
    }

    .rate-review-all-child-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 9px;
      padding: 0 0 10px 0;

      .rate-review-left-side-alignment {
        display: flex;
        align-items: center;
        gap: 14px;

        .rate-star-number-flex {
          display: flex;
          align-items: center;
          gap: 1px;

          p {
            color: var(--secondary-900);
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
          }
        }

        .rate-average-area-alignment {
          width: 190px;
          height: 6px;
          background: #eef1fd;
          border-radius: 8px;
          position: relative;

          .rate-average-area-alignment-fill {
            position: absolute;
            content: '';
            height: 100%;
            border-radius: 8px;
            top: 0;
            left: 0;
            background: #556ee6;
          }
        }
      }

      .rate-review-right-side-alignment {
        p {
          color: var(--secondary-900);
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
        }
      }
    }
  }

  .mobile-view-rate-and-review-all-img {
    align-items: center;
    gap: 8px;
    padding: 14px 6px 8px 0;
    display: none;

    @media only screen and (max-width: '1024px') {
      display: flex;

      .mobile-view-review-img {
        width: 60px;
        height: 60px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          display: block;
          border-radius: 8px;
          object-fit: cover;
        }

        &.last-show-img {
          &::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            border-radius: 8px;
            background: linear-gradient(0deg, rgba(30, 36, 50, 0.6) 0%, rgba(30, 36, 50, 0.6) 100%);
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
        }

        .mobile-view-total-number {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 99;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            color: #fff;
            text-align: center;
            font-size: 15px;
            font-weight: 500;
            line-height: 21px;
          }
        }
      }

      &.second-all-media-alignment {
        padding: 8px 16px;
      }
    }
  }
}

.comment-box-main-section {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 60px;
    background: linear-gradient(360deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
    z-index: 999;
    bottom: 0;
    left: 0;
  }

  @media only screen and (max-width: '1024px') {
    &.all-comment-box-details {
      &::before {
        display: none;
      }
    }
  }
}

.comment-alignment {
  // height: 444px;
  // overflow: scroll;

  // @media screen and (max-width: 1024px) {
  //   height: auto;
  //   padding: 16px 0 0 0;
  // }
  // height: calc(100vh - 245px);
  // overflow-y: auto;
  // padding: 16px 0 0 0;

  @media only screen and (max-width: '1024px') {
    height: 100px;
    overflow-y: hidden;

    &.all-comment-details {
      height: auto;
      overflow-y: auto;
    }
  }

  .comment-container-main {
    @media only screen and (max-width: '1024px') {
      padding: 0 0 24px 0;
    }

    .comment-section {
      // margin-bottom: 20px;
      // background: #f8f8f8;
      // border: 1px solid #e4e4e8;
      // border-radius: 8px;
      // padding: 10px 16px;
      display: grid;
      row-gap: 10px;
      padding: 16px 0;

      @media screen and (max-width: 1024px) {
        border: none;
        background: transparent;
        padding: 0;
      }
    }

    .comment-description {
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: var(--secondary-900);
        max-width: 760px;
      }
    }

    .mobile-view-comment-reviewimg-alignment {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;
      overflow-x: auto;
      display: none;

      @media only screen and (max-width: '1024px') {
        display: flex;
      }

      .mobile-view-review-img {
        width: 94px;
        height: 82px;
        min-width: 94px;
        max-width: 94px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 5px;
        }
      }
    }

    .commenter-profile-section {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .image-name-align {
        display: flex;
        gap: 10px;
        align-items: center;

        .user-profile-image {
          width: 24px;
          height: 24px;

          img {
            height: 100%;
            width: 100%;
            border-radius: 50%;
          }
        }

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #808191;
        }
      }

      .date-alignment {
        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #808191;
        }
      }
    }

    &:last-child {
      .comment-section {
        margin-bottom: 0px;
      }
    }
  }
}

.new-comment-box-alignmet {
  padding: 16px 0 0 0;

  .new-comment-rate-icon-flex {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 0 0 9px 0;
  }

  .new-comment-description-alignment {
    padding: 0 0 9px 0;

    p {
      color: var(--secondary-900);
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
  }

  .new-comment-profile-img-flex-alignment {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    overflow-x: auto;

    .new-comment-img {
      width: 94px;
      height: 82px;
      min-width: 94px;
      max-width: 94px;

      img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 5px;
      }
    }
  }

  .new-comment-profile-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 0 0 0;

    .new-comment-profile-flex {
      display: flex;
      align-items: center;
      gap: 10px;

      .new-profile-img {
        width: 24px;
        height: 24px;

        img {
          width: 100%;
          height: 100%;
          display: block;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      p {
        color: var(--grey2);
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
      }
    }

    .new-comment-date-alignment {
      span {
        color: var(--grey2);
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
      }
    }
  }
}

.review-mobileView-all-show {
  display: none;

  @media screen and (max-width: 1024px) {
    display: block;
    text-align: center;
    padding: 8px 0 16px 0;

    a {
      color: var(--primary-500);
      font-size: 15px;
      font-weight: 500;
      line-height: 22px;
    }
  }
}

.image-section-align {
  // width: 151px;
  // height: 32px;
  // background: #ffffff;
  // border: 0.4178px solid #e4e4e8;
  // border-radius: 21.5px;
  // padding: 2px 5px;
  display: flex;
  justify-content: right;
  gap: 3px;
  align-items: center;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    border: 0;
  }

  .image-contanier-align {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 19.17px;
      height: 19.17px;
      margin-left: -12px;
      border: 1px solid #ffffff;
      border-radius: 3px;
    }
  }

  .image-font-align {
    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--primary-500);

      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  }

  .image-count-align {
    color: var(--primary-500);
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;

    @media screen and (max-width: 1024px) {
      font-size: 15px;
      line-height: 22px;
      color: var(--primary-500);
    }
  }
}

.modal-container-main {
  width: 100%;
  // height: 100vh;
  background-color: #ffffff;
  // position: fixed;
  // top: 0;

  .back-menu-align {
    .back-menu-main {
      width: 68px;
      height: 32px;
      background: #f8f8f8;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #808191;
      }
    }
  }

  .gallery-main-container {
    // padding: 18px 0;
    // height: calc(100vh - -2px);
    // overflow: auto;
    padding: 18px 0 0 0;
    // height: calc(100vh - 200px);
    // overflow: auto;

    @media only screen and (max-width: '1024px') {
      padding: 0;
    }

    .mobile-view-all-media-seaction {
      display: none;

      @media only screen and (max-width: '1024px') {
        display: block;
        text-align: center;
        padding: 0 0 16px 0;

        a {
          color: var(--primary-500);
          font-size: 15px;
          font-weight: 500;
          line-height: 21px;
        }
      }
    }

    .back-button-align {
      .back-menu-alignss {
        .back-menu-main {
          width: 68px;
          height: 32px;
          background: #f8f8f8;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          span {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #808191;
          }
        }
      }

      .gellery-image-preview {
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 10px;
        }
      }

      .image-section-flex {
        display: flex;
        gap: 8px;
        align-items: center;
        padding-top: 12px;

        .image-alignment {
          width: 32px;
          height: 32px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: #495057;
        }

        p {
          font-weight: 400;
          font-size: 10px;
          line-height: 15px;
          color: #808191;
        }
      }

      .comment-description-align {
        margin-top: 8px;

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #495057;
        }
      }

      .goto-comment-section {
        padding-top: 8px;
        padding-bottom: 16px;

        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: var(--primary-500);
        }
      }

      .image-card-flex-prev {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 0 auto;
        gap: 12px;
        overflow: auto;

        @media screen and (max-width: 767px) {
          justify-content: center;
          width: 100%;
          margin: 0 auto;
          gap: 12px;
        }

        .image-align-mobile {
          margin-bottom: 12px;

          img {
            object-fit: cover;
            border-radius: 5px;
          }
        }
      }
    }

    .back-menu-alignment {
      .image-card-flex-align {
        display: flex;
        gap: 8px;
        align-items: center;
        padding-top: 12px;

        .image-alignment {
          width: 32px;
          height: 32px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: #495057;
        }

        p {
          font-weight: 400;
          font-size: 10px;
          line-height: 15px;
          color: #808191;
        }
      }
    }
  }

  .gallery-main-image-section {
    @media only screen and (max-width: '1024px') {
      height: calc(100vh - 258px);
      overflow-y: auto;
      position: relative;

      &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) -4.55%, #fff 100%);
        z-index: 999;
        bottom: 0;
        left: 0;
      }
    }

    .gallery-header-mobile {
      .gallery-title {
        display: flex;
        gap: 4px;
        align-items: center;
        padding-bottom: 10px;
        padding-top: 0;

        h3 {
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: var(--secondary-900);
        }

        span {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #808191;
          padding-top: 4px;
        }
      }
    }
  }

  .image-card-flex {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    gap: 12px;
    overflow: auto;
    position: relative;

    @media screen and (max-width: 1024px) {
      justify-content: center;
      width: 100%;
      margin: 0 auto;
      gap: 15px;
      height: calc(100% - 42px);
    }

    .image-align-mobile {
      margin-bottom: 12px;

      @media screen and (max-width: 1024px) {
        margin-bottom: 16px;
      }

      img {
        object-fit: cover;
        border-radius: 5px;
      }
    }
  }

  .image-container-main {
    width: 100%;
    // height: 100vh;
    padding: 0px 19px 19px 19px;

    @media screen and (max-width: 767px) {
      overflow: scroll;
      height: 100vh;
      padding-top: 0;
    }

    .image-header-mobile {
      .image-title {
        display: flex;
        gap: 4px;
        align-items: center;
        padding-bottom: 16px;

        h3 {
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: var(--secondary-900);
        }

        span {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #808191;
          padding-top: 4px;
        }
      }
    }

    .ratting-menu-section {
      display: flex;
      gap: 12px;
      align-items: center;
      padding-bottom: 16px;

      @media screen and (max-width: 767px) {
        width: 100%;
        overflow: scroll;
      }

      .star-container {
        display: flex;
        gap: 4px;
        align-items: center;
        background: #ffffff;
        border: 0.4178px solid #e4e4e8;
        border-radius: 21.5px;
        padding: 1px 10px;
        cursor: pointer;

        .all-start-align {
          padding-top: 4px;

          span {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: var(--primary-500);
          }
        }

        .feedback-total-count {
          padding-top: 4px;

          p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #808191;
          }
        }
      }

      .active-class {
        border: 1px solid #556ee6;
      }
    }

    .image-card-flex-grid-alignment {
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 0 auto;
      gap: 12px;
      overflow: auto;

      @media screen and (max-width: 767px) {
        justify-content: center;
        width: 100%;
        height: calc(100vh - 178px);
        margin: 0 auto;
        gap: 12px;
      }

      .image-main-section {
        position: relative;

        .image-align-mobile {
          margin-bottom: 12px;

          img {
            object-fit: cover;
            border-radius: 5px;
          }
        }

        .image-info-main-section {
          .image-rate-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 10px;

            .star-count-section {
              position: absolute;
              top: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 4px;

              span {
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #ffffff;
                padding-top: 3px;
              }

              svg {
                height: 14px;
                width: 14px;
              }
            }

            .image-count-alignment {
              position: absolute;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 4px;
              top: 0;
              right: 10px;

              span {
                padding-top: 3px;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                text-align: center;
                color: #ffffff;
              }

              img {
                height: 14px;
                width: 14px;
              }
            }
          }
        }
      }
    }
  }

  .image-main-contain {
    width: 100%;

    // height: 100vh;s
    @media screen and (max-width: 767px) {
      overflow: scroll;
      height: 100vh;
      padding-top: 0;
    }

    .image-container-prev-main {
      padding: 0px 19px;

      .image-section-postion {
        position: relative;

        .image-class {
          img {
            border-radius: 10.4573px;
            height: 100%;
            width: 100%;
          }
        }

        .image-count-hover-section {
          width: 30px;
          height: 30px;
          padding: 6px;
          background: #1e2432;
          opacity: 0.6;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          right: 0;
          margin: 8px;

          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            color: #ffffff;
          }
        }
      }
    }

    .comment-description {
      position: fixed;
      bottom: 0;
      padding: 19px;

      .feedback-info-text {
        margin-top: 8px;

        p {
          font-weight: 400;
          font-size: 13px;
          line-height: 21px;
          color: var(--secondary-900);
        }
      }

      .commenter-profile-sectionsss {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 8px;

        .image-name-align {
          display: flex;
          gap: 10px;
          align-items: center;

          .user-profile-image {
            width: 24px;
            height: 24px;

            img {
              height: 100%;
              width: 100%;
              border-radius: 50%;
            }
          }

          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #808191;
          }
        }

        .date-alignment {
          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #808191;
          }
        }
      }
    }
  }
}

.customer-traction-main {
  padding: 18px 18px 18px 18px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  @media screen and (max-width: 767px) {
    display: block;
  }

  .customer-heading-alignment {
    display: none;

    @media screen and (max-width: 767px) {
      display: block;
    }

    h6 {
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      color: var(--secondary-900);
    }
  }

  .graph-container-main {
    width: 100%;
    height: 382px;
    background: #ffffff;
    border: 1px solid #e6e8ec;
    border-radius: 10px;
    padding: 18px;

    @media screen and (max-width: 767px) {
      border: none;
      border-radius: 0px;
      padding: 16px 0 0 0;
    }

    .graph-title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .graph-title-align {
        h3 {
          font-weight: 500;
          font-size: 20px;
          line-height: 30px;
          color: var(--secondary-900);

          @media screen and (max-width: 767px) {
            font-size: 16px;
            line-height: 24px;
            color: #495057;
          }
        }
      }

      .dropdown-section-main {
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          border: 1px solid #e4e4e8;
          border-radius: 10px;
        }

        .ant-select {

          // .ant-select-selector {
          //   .ant-select-selection-item {
          //     .demo-option-label-item {
          //       color: red;
          //     }
          //   }
          // }
          .ant-select-arrow {
            span {
              svg {
                fill: #556ee6;
                stroke: #556ee6;
                stroke-width: 40px;
              }
            }
          }
        }

        .dropdown-option-label-item {
          font-weight: 600;
          font-size: 14px;
          color: var(--primary-500);
        }
      }
    }

    .graph-second-container {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .graph-align {
        @media screen and (min-width: 992px) {
          h3 {
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: var(--secondary-900);
          }

          .interest-section-mobile {
            display: none;
          }
        }

        @media screen and (max-width: 767px) {
          .interest-section-mobile {
            display: flex;
            gap: 8px;
            align-items: center;

            .interest-text {
              span {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #495057;
              }
            }

            .interest-up-contain {
              display: flex;
              gap: 4px;
              align-items: center;

              span {
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                display: flex;
                align-items: center;
                color: #70c217;
              }
            }
          }

          h3 {
            display: none;
          }
        }
      }

      .graph-page-size-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 32px;

        .page-size-align {
          cursor: pointer;

          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #808191;
          }
        }

        .prev-next-section {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 14px;
          cursor: pointer;

          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #495057;
          }
        }
      }
    }

    .devider {
      border: 1px solid #e4e4e8;
      width: 100%;
      margin: 12px 0;
    }

    .progressbar-main {
      height: 46px;
      border-bottom: 1px solid #e4e4e8;
      margin-bottom: 12px;

      .progress-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #495057;
        }

        p {
          font-weight: 400;
          font-size: 11px;
          line-height: 16px;
          color: #495057;
        }
      }
    }
  }
}

.product-main-contain-mobile {
  .main-text-alignment {
    span {
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: var(--secondary-900);
    }
  }

  .product-card-section {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    gap: 12px;

    @media screen and (max-width: 767px) {
      justify-content: center;
      width: 100%;
      margin: 0 auto;
      gap: 12px;
    }
  }

  .store-product-card-align {
    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.32px;
      color: var(--primary-500);
    }
  }
}

.list-view-prodcut-details-design {
  cursor: pointer;
  margin: 0 0 20px 0;

  &:hover {
    box-shadow: 0px 13px 18px rgba(95, 64, 175, 0.1);
    border-radius: 10px;
    transition: 0.3s ease-in-out;

    .onhover-align {
      opacity: 1;
      transition: 0.3s ease-in-out;
    }

    .image-main-handler {
      display: flex !important;
    }
  }

  .list-sub-grid {
    display: grid;
    grid-template-columns: 294px 1fr;
    gap: 11px;

    .list-sub-grid-items {
      position: relative;

      .all-details-content-alignment {
        padding: 16px 18px 18px 0;

        .last-child-text-style {
          padding: 18px 0 0 0;

          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #808191;
          }
        }
      }

      .video-style-alignment {
        position: relative;

        .image-style {
          img {
            width: 100%;
            height: 296px;
            object-fit: cover;
            border-radius: 8px;
          }

          video {
            width: 100%;
            height: 296px;
            object-fit: cover;
            border-radius: 8px;
          }
        }
      }

      &:hover {
        transition: 0.3s ease-in-out;

        .onhover-align {
          opacity: 1;
          transition: 0.3s ease-in-out;
        }
      }

      .image-contain-data {
        position: relative;

        img {
          width: 100%;
          // height: 100%;
          border-radius: 8px 8px 0px 0px;
        }

        video {
          width: 100%;
          height: 100%;
          border-radius: 8px 8px 0px 0px;
        }
      }

      .onhover-align {
        .image-index-align {
          // top: 0;
          display: flex;
          justify-content: space-between;
          // position: absolute;
          width: 100%;
          padding: 12px;

          .number-of-index {
            height: 30px;
            width: 30px;
            background: #1e2432;
            opacity: 0.6;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              text-align: center;
              color: #ffffff;
            }
          }

          .like-more-alignment {
            display: flex;
            justify-content: flex-end;
            gap: 12px;
            align-items: center;

            .like-button-alignment {
              width: 31px;
              height: 31px;
              background: #1e2432;
              opacity: 0.6;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20px;
                height: 20px;
              }
            }

            .more-button-alignment {
              width: 23px;
              height: 23px;
              background: #1e2432;
              opacity: 0.6;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 14px;
                height: 14px;
              }
            }
          }
        }

        .bottom-section {
          bottom: 0 !important;
          position: absolute;
          width: 100%;

          .product-color-main {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            padding: 12px;

            .color-alignment {
              background: #ffffff;
              border: 0.5px solid #e4e4e8;
              border-radius: 10.5px;
              padding: 0px 6px;
              display: flex;
              justify-content: center;
              gap: 4px;
              align-items: center;

              img {
                width: 13px;
                height: 13px;
              }

              span {
                margin-top: 2px;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                display: flex;
                align-items: center;
                color: var(--secondary-900);
              }
            }
          }
        }
      }
    }

    .image-main-handler {
      align-items: center;
      padding: 6px 0 6px 6px;
      display: none;

      .product-image-list {
        display: grid;
        grid-template-columns: repeat(8, 1fr);
        gap: 4px;

        @media only screen and (max-width: '1099px') {
          grid-template-columns: repeat(5, 1fr);
        }

        .media-border {
          width: 33px;
          height: 33px;

          .product-sub-image {
            position: absolute;
          }

          img {
            width: 100%;
            height: 100%;
            margin-right: 4px;
            border-radius: 4px;
            object-fit: cover;
          }

          video {
            width: 100%;
            height: 100%;
            // padding-right: 4px;
            margin-right: 4px;
            border-radius: 4px;
            object-fit: cover;
          }
        }

        .active-border {
          border: 1px solid #6b21a8;
          border-radius: 4px;
        }
      }
    }
  }

  .onhover-align {
    opacity: 0;
    transition: 0.3s ease-in-out;
  }

  .product-description-section {
    // padding: 14px;

    // @media screen and (max-width: 767px) {
    //   padding: 8px;
    // }

    h2 {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--secondary-900);

      @media screen and (max-width: 767px) {
        font-size: 13px;
      }
    }

    .price-analytics-section {
      display: flex;
      gap: 6px;
      align-items: center;
      margin: 8px 0;

      @media screen and (max-width: 767px) {
        margin: 0;
        justify-content: space-between;
        display: block;
      }

      .price-data {
        display: flex;
        // justify-content: space-between;
        align-items: center;
        gap: 4px;

        h5 {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          display: flex;
          align-items: center;
          color: #000000;
        }

        span {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          display: flex;
          align-items: center;
          text-decoration-line: line-through;

          /* Grey3 */

          color: #acb1c0;
        }
      }

      .delivery-shipping {
        display: flex;
        align-items: center;
        gap: 6px;

        @media screen and (max-width: 767px) {
          margin: 2px 0;
        }

        .discount-menu-align {
          width: auto;
          height: auto;
          background: #ff7d23;
          border-radius: 11px;
          display: flex;
          justify-content: center;
          align-items: center;

          button {
            padding: 1px 9px;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: #ffffff;

            @media screen and (max-width: 767px) {
              padding: 1px 4px;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              display: flex;
              align-items: center;
              color: #ffffff;
            }
          }
        }

        .delivery-menu-align {
          width: auto;
          height: 21px;
          background: #eaeaea;
          border-radius: 13.5px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 2px;

          @media screen and (max-width: 767px) {
            padding: 4px 6px;
          }

          padding: 4px 8px;

          img {
            width: 100%;
            height: 100%;
          }

          span {
            font-weight: 500;
            font-style: italic;
            font-size: 12px;
            line-height: 18px;
            display: flex;
            align-items: center;
            text-decoration-line: none;
            color: #5f40af;
          }
        }
      }

      // @media screen and (max-width: 767px) {
      //   .discount-menu-align {
      //     display: none;
      //   }
      //   .delivery-menu-align {
      //     display: none;
      //   }
      // }
    }

    .all-product-best-seller-and-details-section {
      display: flex;
      align-items: center;
      gap: 8px;

      .best-seller-tag-alignment {
        h6 {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          background: linear-gradient(263.94deg, #ffc554 7.44%, #744c00 86.5%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }
      }

      .dots-alignment {
        p {
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: #acb1c0;
        }
      }

      .delivery-menu-align {
        // width: 62px;
        // height: 21px;
        // background: #eaeaea;
        border-radius: 13.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2px;

        span {
          font-weight: 500;
          font-style: italic;
          font-size: 12px;
          line-height: 18px;
          display: flex;
          align-items: center;
          text-decoration-line: none;
          color: #5f40af;
        }
      }

      .arrow-menu-align {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 6px;

        img {
          width: 11px;
          height: 11px;
        }

        span {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          display: flex;
          align-items: center;
          color: #70c217;
        }
      }
    }

    .analytics-section-main {
      display: flex;
      // justify-content: flex-start;
      align-items: center;
      gap: 12px;

      @media screen and (max-width: 767px) {
        display: block;
      }

      .analytics-section {
        display: flex;
        // justify-content: flex-start;
        align-items: center;
        gap: 12px;

        @media screen and (max-width: 767px) {
          margin: 4px 0;
        }

        .graph-menu-align {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 6px;

          img {
            width: 14px;
            height: 11.8px;
          }

          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #000000;
          }
        }

        .arrow-menu-align {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 6px;

          img {
            width: 11px;
            height: 11px;
          }

          span {
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            display: flex;
            align-items: center;
            color: #70c217;
          }
        }
      }

      .feedback-section {
        display: flex;
        // justify-content: flex-start;
        align-items: center;
        gap: 12px;

        .dott-icon-align {
          width: 4px;
          height: 4px;
          background: #acb1c0;
          border-radius: 50%;

          @media screen and (max-width: 767px) {
            display: none;
          }
        }

        .rating-section-main {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 4px;

          img {
            margin-bottom: 2px;
          }

          span {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #808191;
          }

          p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #808191;
            margin-bottom: 0 !important;
          }
        }
      }
    }

    .list-view-comment-section-alignment {
      padding: 9px 0 0 0;

      .like-share-alignment {
        display: flex;
        align-items: center;
        gap: 16px;

        .like-alignment {
          display: flex;
          align-items: center;
          gap: 6px;

          img {
            cursor: pointer;
          }

          span {
            padding: 4px 0 0 0;
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            color: #808191;
          }
        }

        .share-alignment {
          margin: 21px 0 0 0;

          img {
            cursor: pointer;
          }
        }
      }
    }

    .list-view-notes-alignment {
      padding: 16px 0 0 0;

      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #808191;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-width: 297px;
      }
    }
  }

  .comment-section-main {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    .comment-box-alignment {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #f3f3f3;
      border-radius: 0px 0px 0px 10px;
      padding: 3px;
      gap: 4px;

      img {
        width: 16px;
        height: 16px;

        @media screen and (max-width: 767px) {
          width: 14px;
          height: 14px;
        }
      }

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 35px;
        letter-spacing: 0.144px;
        color: #808191;

        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }
    }

    .like-box-alignment {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #f3f3f3;
      border-radius: 0px 0px 0px 0;
      padding: 3px;
      gap: 4px;

      img {
        width: 18px;
        height: 18px;

        @media screen and (max-width: 767px) {
          width: 14px;
          height: 14px;
        }
      }

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 35px;
        letter-spacing: 0.144px;
        color: #808191;

        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }
    }

    .share-box-alignment {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #f3f3f3;
      border-radius: 0px 0px 10px 0px;
      padding: 3px;
      gap: 1px;

      img {
        width: 24px;
        height: 24px;

        @media screen and (max-width: 767px) {
          width: 19px;
          height: 19px;
        }
      }

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 35px;
        letter-spacing: 0.144px;
        color: #808191;

        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }
    }
  }
}

.invite-friend-modal {
  .invite-modal {
    height: 336px;
    overflow: scroll;
  }

  .ant-modal-body {
    padding: 17px;
  }
}

.form-details-alignment {
  width: 100%;

  label {
    font-family: 'Poppins', sans-serif;
    margin: 0 0 4px 0;
    display: block;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #495057;
    text-align: left;
  }

  input {
    width: 100%;
    background: #eceef4;
    border: 1px solid #eceef4;
    border-radius: 10px;
    height: 32px;
    padding: 0 10px;
  }

  textarea {
    width: 100%;
    background: #eceef4;
    border: 1px solid #eceef4;
    border-radius: 10px;
    height: 118px;
    padding: 10px;
  }
}

.send-button-align {
  background: var(--primary-500);
  border-radius: 8px;
  width: 106px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  button {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #f8f8f8;
  }

  &.close {
    opacity: 0.5;
  }
}

.productDetails-modal-alignment {
  .product-modal-wrapper {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 60px;
    right: 0;
    z-index: 999;
    background: rgba(30, 36, 50, 0.3);
    display: flex;
    justify-content: flex-end;

    .productDetails-modal-details-alignment {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      width: 72%;

      .close-button-alignment {
        background: #ffffff;
        border-radius: 10px;
        padding: 4px;
        width: 32px;
        min-width: 32px;
        max-width: 32px;
        height: 32px;
        margin: 32px 0 0 0;
        cursor: pointer;

        img {
          display: block;
        }
      }

      .productDetails-add-details-modal-alignment {
        background: #eceef4;
        border-radius: 16px 0px 0px 0px;
        padding: 16px;
        height: 100%;
        width: 96%;

        .productDetails-height-alignment {
          height: 100vh;
          max-height: calc(100vh - 90px);
          overflow-y: auto;
        }
      }
    }
  }
}

.store-product-modal-alignment {
  .store-product-modal-wrapper {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 60px;
    right: 0;
    z-index: 999999;
    background: rgba(30, 36, 50, 0.3);
    display: flex;
    justify-content: flex-end;

    .store-productDetails-modal-details-alignment {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      width: 70%;

      .close-button-alignment {
        background: #ffffff;
        border-radius: 10px;
        padding: 4px;
        width: 32px;
        min-width: 32px;
        height: 32px;
        margin: 32px 0 0 0;
        cursor: pointer;

        img {
          display: block;
        }
      }

      .store-productDetails-add-details-modal-alignment {
        background: #eceef4;
        border-radius: 16px 0px 0px 0px;
        padding: 16px;
        height: 100vh;
        // max-height: calc(100vh - 90px);
        // overflow-y: auto;
        width: 100%;
      }
    }
  }
}

.about-store-section {
  margin: 0 94px;

  @media only screen and (max-width: '1199px') {
    margin: 0;

    &.second-about-section {
      margin: 8px 14px 8px 18px;
    }
  }

  @media only screen and (max-width: '767px') {
    padding: 0 0 0 0;

    &.second-about-section {
      margin: 8px 14px 8px 18px;
    }
  }

  .about-store-grid {
    display: grid;
    grid-template-columns: 1fr 412px;
    gap: 16px;

    @media only screen and (max-width: '1099px') {
      grid-template-columns: 1fr;
      gap: 0;
    }

    @media only screen and (max-width: '767px') {
      padding: 0 0 30px 0;
    }

    .about-store-gridItem {
      .no-data-white-box-alignment {
        background: #ffffff;
        border-radius: 18px;
        width: 100%;
        height: 100%;
      }

      .about-sticky-alignment {
        @media only screen and (max-width: '1099px') {
          display: none;
        }

        position: sticky;
        top: 92px;
      }

      .about-store-itme-box {
        // background: #ffffff;
        // border-radius: 16px;
        border-radius: 16px;
        border: 2px solid #eef1fd;
        background: #fff;
        padding: 20px 28px 16px 31px;

        @media only screen and (max-width: '1099px') {
          padding: 12px 16px;
        }

        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: var(--secondary-900);
          padding: 0 0 16px 0;

          @media only screen and (max-width: '1099px') {
            padding: 0 0 8px 0;
          }
        }

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #808191;
          padding: 0 0 8px 0;
        }

        a {
          cursor: pointer;
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 14px;
          color: var(--primary-500);
          text-align: center;
          display: block;

          @media only screen and (max-width: '1099px') {
            font-size: 13px;
          }
        }
      }

      .about-other-details-alignment {
        .three-grid-alignment {
          display: grid;
          align-items: center;
          grid-template-columns: 204px 1fr 191px;
          gap: 7px;
          margin: 8px 0 0 0;

          @media only screen and (max-width: '1099px') {
            grid-template-columns: 1fr;
          }

          .three-gridItem {
            .about-other-details-box {
              // background: #ffffff;
              // border-radius: 16px;
              border-radius: 16px;
              border: 2px solid #eef1fd;
              background: #fff;
              padding: 10px 16px;
              height: 147px;
              max-height: 147px;

              @media only screen and (max-width: '1099px') {
                padding: 12px 16px;
                height: auto;
              }

              h1 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                color: #000000;
              }

              .bosy-details {
                text-align: center;
                padding: 31px 0 0 0;

                @media only screen and (max-width: '1099px') {
                  padding: 16px 0 0 0;
                  text-align: left;
                }

                span {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 400;
                  font-size: 18px;
                  line-height: 27px;
                  color: #495057;

                  @media only screen and (max-width: '1099px') {
                    font-size: 14px;
                    line-height: 21px;
                  }
                }
              }

              .location-details {
                padding: 16px 0;
                display: flex;
                align-items: flex-start;
                gap: 8px;

                @media only screen and (max-width: '1099px') {
                  padding: 16px 0 0 0;
                }

                .icon-alignment {
                  width: 24px;
                  min-width: 24px;
                  max-width: 24px;
                  height: 24px;

                  img {
                    width: 100%;
                    height: 100%;
                    display: block;
                  }
                }

                .locatopn-name-address-details {
                  @media only screen and (max-width: 1399px) {
                    height: 55px;
                    overflow-y: auto;
                  }

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 21px;
                    color: #495057;
                  }
                }
              }

              .social-icon-alignment {
                padding: 29px 0 0 0;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;

                @media only screen and (max-width: '1099px') {
                  padding: 16px 0 0 0;
                  justify-content: flex-start;
                }
              }
            }
          }
        }
      }

      .store-owner-box-alignment {
        margin: 8px 0 0 0;
        // background: #ffffff;
        // border-radius: 16px;
        border-radius: 16px;
        border: 2px solid #eef1fd;
        background: #fff;
        padding: 10px 21px 32px 21px;

        h1 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: #000000;
        }

        .store-owner-details-alignment {
          display: flex;
          align-items: center;
          justify-content: space-between;

          @media only screen and (max-width: '1099px') {
            padding: 16px 0 0 0;
            display: block;
          }

          .owner-details {
            padding: 0 0 0 16px;
            display: flex;
            align-items: center;
            gap: 16px;

            @media only screen and (max-width: '1099px') {
              gap: 30px;
            }

            .owner-img {
              width: 80px;
              min-width: 80px;
              max-width: 80px;
              height: 80px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                display: block;
              }
            }

            .owner-name-alignmwnt {
              h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 18px;
                line-height: 27px;
                color: #495057;
              }

              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                color: #808191;
              }
            }
          }

          .follow-button-alignment {
            @media only screen and (max-width: '1099px') {
              display: flex;
              align-items: center;
              gap: 8px;
              padding: 22px 0 0 0;
            }

            button {
              background: var(--primary-500);
              border-radius: 8px;
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #f8f8f8;
              width: 117px;
              height: 32px;
              cursor: pointer;

              @media only screen and (max-width: '1099px') {
                width: 100%;
              }

              &.following {
                background: #f8f8f8;
                color: var(--primary-500);

                &:hover {
                  background: #eef1fc;
                }
              }
            }

            .message-chat-button-alignment {
              display: none;

              @media only screen and (max-width: '1099px') {
                display: block;
              }

              button {
                width: 32px;
                height: 32px;
                background: var(--primary-50);
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }
      }

      .activities-alignment {
        margin: 8px 0 0 0;

        padding: 22px 17px;
        border-radius: 16px;
        border: 2px solid #eef1fd;
        background: #fff;

        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: #000000;
        }

        .chat-area-alignment {
          margin: 13px 0 0 0;
          background: #ffffff;
          border: 1px solid #e6e8ec;
          border-radius: 10px;
          padding: 21px 23px;

          .dropdown-alignment {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .dropdown-box {
              background: #ffffff;
              border: 0.5px solid #e4e4e8;
              border-radius: 10px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              gap: 8px;
              height: 34px;
              width: 174px;
              padding: 0 20px;

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                color: var(--primary-500);
              }
            }
          }
        }
      }

      .follow-alignment {
        // background: #ffffff;
        // border-radius: 16px;
        border-radius: 16px;
        background: #f3f5f8;
        padding: 16px;

        @media only screen and (max-width: '1099px') {
          display: none;
        }

        .heading-alignment {
          display: flex;
          align-items: center;
          // justify-content: space-between;
          gap: 4px;

          h4 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: var(--secondary-900);
          }

          p {
            color: #7a7e88;
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
          }

          span {
            color: #7a7e88;
            font-size: 13px;
            font-weight: 500;
            line-height: 21px;
            display: block;
          }
        }

        .serach-alignment {
          margin: 18px 0 0 0;

          .search-input-alignment {
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            position: relative;

            .icon-alignment {
              position: absolute;
              top: 11px;
              left: 11px;

              img {
                cursor: pointer;
              }
            }

            input {
              font-family: 'Poppins', sans-serif;
              width: 100%;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              display: flex;
              align-items: center;
              padding: 8px 10px 8px 40px;
              background: transparent;
              border: none;
              height: 100%;

              color: #1e2432;

              &::placeholder {
                color: #acb1c0;
              }
            }
          }
        }
      }

      .follow-people-details-alignment {
        padding: 20px 0 0 0;
        height: auto;
        max-height: 400px;
        overflow-y: auto;

        .follow-people-details-flex {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 0 16px 0;

          &:last-child {
            padding: 0;
          }

          .follow-profile-alignment {
            display: flex;
            align-items: center;
            gap: 16px;

            .profile-img {
              width: 40px;
              max-width: 40px;
              min-width: 40px;
              height: 40px;

              img {
                width: 100%;
                height: 100%;
                cursor: pointer;
                display: block;
                border-radius: 50%;
                object-fit: cover;
              }
            }

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.1px;
              color: var(--secondary-900);
            }
          }

          .follow-button-alignment {
            button {
              width: 113px;
              height: 32px;
              // background: var(--primary-500);
              border-radius: 8px;
              font-family: 'Poppins', sans-serif;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #556ee6;
              border: none;
              cursor: pointer;
              background: transparent;

              &.following {
                color: #7a7e88;
              }
            }
          }
        }
      }

      .special-offer-box-alignment {
        background: #ffffff;
        border-radius: 16px;
        padding: 6px 12px;
        margin: 8px 0 0 0;

        @media only screen and (max-width: '1099px') {
          display: none;
        }

        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #000000;
          padding: 0 0 0 11px;
        }

        .special-offer-details-alignment {
          padding: 9px 0 0 0;

          .special-offer-bg {
            width: 100%;
            height: 151px;
            position: relative;
            background: #1c1c1e;
            border-radius: 10px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 10px;
            }

            .more-option-alignment {
              background: rgba(0, 0, 0, 0.4);
              width: 23px;
              height: 23px;
              border-radius: 50%;
              cursor: pointer;
              position: absolute;
              top: 8px;
              right: 8px;
              cursor: pointer;

              img {
                padding: 5px;
              }
            }

            .card-offer-details {
              position: absolute;
              display: flex;
              flex-direction: column;
              right: 0;
              left: 0;
              align-items: center;
              height: 151px;
              justify-content: center;
              top: 0;

              h5 {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 24px;
                line-height: 36px;
                color: #ffffff;
              }

              h6 {
                font-family: 'PoetsenOne';
                font-weight: 400;
                font-size: 24px;
                line-height: 29px;
                color: #ffffff;

                span {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 26px;
                  line-height: 39px;
                  color: #ff7d23;
                  display: block;
                  text-align: center;
                }
              }
            }

            .expired-add-alignment {
              display: flex;
              align-items: center;
              justify-content: space-between;
              position: absolute;
              bottom: 10px;
              left: 21px;
              right: 10px;

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 10px;
                line-height: 15px;
                color: #ffffff;
                max-width: 87px;
              }

              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #ffffff;
              }
            }
          }
        }
      }

      .no-data-white-box-alignment {
        border-radius: 16px;
        background: #eef1fd;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        height: calc(100vh - 530px);

        p {
          color: #9c6ac5;
          font-size: 12px;
          font-style: italic;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}

.about-store-no-data-alignment {
  height: calc(100vh - 120px);
  background: #ffffff;
  border: 1px solid #e4e4e8;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 94px;

  .about-story-no-data-text-alignment {
    text-align: center;
    max-width: 192px;

    span {
      font-family: 'Poppins', sans-serif;
      font-style: italic;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--secondary-400);
    }
  }
}

.media-section-alignment {
  margin: 0 110px;

  @media only screen and (max-width: '1099px') {
    margin: 8px 0 0 0;
  }

  .media-store-grid {
    display: grid;
    grid-template-columns: 1fr 412px;
    gap: 16px;

    @media only screen and (max-width: '1099px') {
      grid-template-columns: 1fr;
      gap: 0;
    }

    .media-store-gridItem {
      .media-gallery-box-alignment {
        border-radius: 16px;
        border: 2px solid #eef1fd;
        background: #fff;
        padding: 32px;

        @media only screen and (max-width: '1099px') {
          padding: 16px;
          border-radius: 0;
        }

        .mobile-view-media-gallery-details-alignment {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 0 16px 0;

          // @media only screen and (max-width:"799px") {
          //   max-width: 342px;
          //   margin: auto;
          // }

          h6 {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: var(--secondary-900);
          }

          .mobile-view-photo-video-dropdown-alignment {
            height: 32px;
            display: flex;
            align-items: center;
            gap: 12px;
            border: 1.5px solid var(--secondary-100);
            border-radius: 8px;
            padding: 6px 16px;

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 15px;
              line-height: 20px;
              color: var(--secondary-600);
            }
          }
        }

        .gallery-grid-alignment {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 4px;
          margin: 8px 0 0 0;

          @media only screen and (max-width: 767px) {
            padding: 0 0 64px 0;
          }
        }

        @media only screen and (max-width: 1099px) {
          gap: 3px;
        }

        .gallery-img-alignment {
          // display: flex; /* Use flex to center align vertically */
          // align-items: center; /* Center align vertically */
          // justify-content: center; /* Center align horizontally */
          // height: 100px;
          // margin: 0; /* Set the desired height */
          width: 100%;
          height: 100%;
        }

        .gallery-img-alignment img {
          // max-width: 100%;
          // max-height: 100%;
          // width: auto;
          // height: auto;
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
        }

        .mobile-view-media-no-data-modal-alignment {
          height: calc(100vh - 205px);
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 100px;
          justify-content: center;
          display: none;

          @media only screen and (max-width: '1099px') {
            display: flex;
          }

          .media-no-data-name-alignment {
            span {
              font-family: 'Poppins', sans-serif;
              font-style: italic;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              text-align: center;
              color: var(--secondary-300);
              display: block;
            }
          }
        }
      }

      .follow-alignment {
        // background: #ffffff;
        // border-radius: 16px;
        // padding: 22px 32px;
        border-radius: 16px;
        background: #f3f5f8;
        padding: 16px;

        @media only screen and (max-width: '1099px') {
          display: none;
        }

        .heading-alignment {
          display: flex;
          align-items: center;
          // justify-content: space-between;
          gap: 4px;

          h4 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: var(--secondary-900);
          }

          p {
            color: #7a7e88;
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
          }

          span {
            color: #7a7e88;
            font-size: 13px;
            font-weight: 500;
            line-height: 21px;
            display: block;
          }
        }

        .serach-alignment {
          margin: 22px 0 0 0;

          .search-input-alignment {
            background: #ffffff;
            border: 1px solid #f3f3f3;
            border-radius: 10px;
            width: 100%;
            height: 40px;
            display: flex;
            align-items: center;
            position: relative;

            .icon-alignment {
              position: absolute;
              top: 11px;
              left: 11px;

              img {
                cursor: pointer;
              }
            }

            input {
              font-family: 'Poppins', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              display: flex;
              align-items: center;
              padding: 8px 10px 8px 40px;
              background: transparent;
              border: none;
              height: 100%;
              width: 100%;
              color: #1e2432;

              &::placeholder {
                color: #acb1c0;
              }
            }
          }
        }
      }

      .follow-people-details-alignment {
        padding: 20px 0 0 0;
        height: auto;
        max-height: 400px;
        overflow-y: auto;

        @media only screen and (max-width: '1099px') {
          display: none;
        }

        .follow-people-details-flex {
          display: flex;
          justify-content: space-between;
          padding: 0 0 16px 0;

          &:last-child {
            padding: 0;
          }

          .follow-profile-alignment {
            display: flex;
            align-items: center;
            gap: 16px;

            .profile-img {
              width: 40px;
              max-width: 40px;
              min-width: 40px;
              height: 40px;

              img {
                width: 100%;
                height: 100%;
                cursor: pointer;
                display: block;
                border-radius: 50%;
              }
            }

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.1px;
              color: var(--secondary-900);
            }
          }

          .follow-button-alignment {
            button {
              width: 113px;
              height: 32px;
              // background: var(--primary-500);
              border-radius: 8px;
              font-family: 'Poppins', sans-serif;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #556ee6;
              border: none;
              cursor: pointer;
              background: transparent;

              &.following {
                color: #7a7e88;
              }
            }
          }
        }
      }

      .special-offer-box-alignment {
        background: #ffffff;
        border-radius: 16px;
        padding: 6px 12px;
        margin: 8px 0 0 0;

        @media only screen and (max-width: '1099px') {
          display: none;
        }

        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #000000;
          padding: 0 0 0 11px;
        }

        .special-offer-details-alignment {
          padding: 9px 0 0 0;

          .special-offer-bg {
            width: 100%;
            height: 151px;
            position: relative;
            background: #1c1c1e;
            border-radius: 10px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 10px;
            }

            .more-option-alignment {
              background: rgba(0, 0, 0, 0.4);
              width: 23px;
              height: 23px;
              border-radius: 50%;
              cursor: pointer;
              position: absolute;
              top: 8px;
              right: 8px;
              cursor: pointer;

              img {
                padding: 5px;
              }
            }

            .card-offer-details {
              position: absolute;
              display: flex;
              flex-direction: column;
              top: 0;
              right: 0;
              left: 0;
              align-items: center;
              justify-content: center;
              height: 151px;

              h5 {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 24px;
                line-height: 36px;
                color: #ffffff;
              }

              h6 {
                font-family: 'PoetsenOne';
                font-weight: 400;
                font-size: 24px;
                line-height: 29px;
                color: #ffffff;

                span {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 26px;
                  line-height: 39px;
                  color: #ff7d23;
                  display: block;
                  text-align: center;
                }
              }
            }

            .expired-add-alignment {
              display: flex;
              align-items: center;
              justify-content: space-between;
              position: absolute;
              bottom: 10px;
              left: 21px;
              right: 10px;

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 10px;
                line-height: 15px;
                color: #ffffff;
                max-width: 87px;
              }

              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
}

.event-section-alignment {
  margin: 0 6px 0 0;

  @media only screen and (max-width: '1099px') {
    margin: 16px;
  }

  @media only screen and (max-width: '767px') {
    margin: 0;
  }

  .event-live-chat-alignment {
    .event-live-chat-grid-alignment {
      // display: grid;
      // grid-template-columns: 361px 1fr;
      gap: 8px;
      display: flex;

      @media only screen and (max-width: '1099px') {
        display: block;
      }

      // .event-live-chat-gridItem {
      .live-box-alignment {
        background: #ffffff;
        border: 1px solid #ffffff;
        backdrop-filter: blur(32px);
        border-radius: 16px;
        padding: 20px 32px;
        max-width: 28%;
        width: 28%;
        min-width: 28%;

        @media only screen and (max-width: '1099px') {
          max-width: 100%;
          width: 100%;
          min-width: 100%;
          padding: 12px 16px;
        }

        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          background: linear-gradient(92.44deg, #556ee6 0.13%, #6b21a8 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;

          @media only screen and (max-width: '1099px') {
            color: var(--secondary-900);
            background: none;
            -webkit-background-clip: unset;
            -webkit-text-fill-color: var(--secondary-900);
          }
        }

        .live-details-box-alignment {
          padding: 32px 0 12px 0;

          @media only screen and (max-width: '1099px') {
            padding: 16px 0 0 0;
          }

          .event-post-img-alignment {
            height: 528px;
            width: 100%;
            position: relative;

            // @media only screen and (max-width: '1099px') {
            //   display: none;
            // }

            img {
              width: 100%;
              height: 100%;
              border-radius: 10px;
            }

            &::before {
              content: '';
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              right: 0;
              background: linear-gradient(0deg, #1e2432 0%, rgba(30, 36, 50, 0) 100%);
              border-radius: 10px;
            }

            .view-status-alignment {
              display: flex;
              align-items: center;
              gap: 4px;
              position: absolute;
              top: 16px;
              right: 16px;

              .view-alignment {
                background: rgba(30, 36, 50, 0.6);
                border-radius: 8px;
                display: flex;
                align-items: center;
                gap: 3px;
                padding: 0 8px;
                height: 24px;

                img {
                  padding: 4px 0;
                }

                p {
                  font-family: 'Poppins', sans-serif;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;
                  color: #e4e4e8;
                }
              }

              .live-icon-alignment {
                background: #ffffff;
                border-radius: 8px;
                padding: 0 8px;
                display: flex;
                align-items: center;
                gap: 4px;
                height: 24px;

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: #c21717;
                }
              }
            }

            .post-details-alignment {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              position: absolute;
              bottom: 32px;
              left: 15px;
              right: 16px;

              .post-details-name-alignment {
                h6 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 600;
                  font-size: 20px;
                  line-height: 30px;
                  color: #f8f8f8;
                }

                .post-date-alignment {
                  background: rgba(0, 0, 0, 0.4);
                  backdrop-filter: blur(32px);
                  border-radius: 16px;
                  margin: 14px 0 0 0;
                  padding: 2px 10px;

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 20px;
                    color: #acb1c0;
                  }
                }
              }

              .like-share-alignment {
                .like-alignment {
                  img {
                    cursor: pointer;
                  }

                  p {
                    padding: 4px 0 0 0;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 20px;
                    color: #f8f8f8;
                  }
                }

                .share-alignment {
                  margin: 21px 0 0 0;

                  img {
                    cursor: pointer;
                  }
                }
              }
            }
          }

          .event-post-no-data-alignment {
            display: none;

            @media only screen and (max-width: '1099px') {
              display: flex;
              height: 528px;
              width: 100%;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              gap: 110px;

              .event-post-name-alignment {
                max-width: 154px;
                text-align: center;

                span {
                  font-family: 'Poppins', sans-serif;
                  font-style: italic;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  color: var(--secondary-400);
                }
              }
            }
          }
        }
      }

      .ongoing-event-box-alignment {
        background: #ffffff;
        border: 1px solid #ffffff;
        backdrop-filter: blur(32px);
        border-radius: 16px;
        padding: 20px 32px;
        // max-width: 72%;
        // width: 72%;
        // min-width: 72%;
        width: 930px;

        @media only screen and (max-width: '1099px') {
          width: 100%;
          margin: 8px 0 0 0;
          padding: 12px 16px;
        }

        .card-heading-alignment {
          display: flex;
          align-items: center;
          gap: 4px;

          h4 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: #000000;
          }

          .mobile-view-number-alignment {
            background: #f0f0f0;
            border-radius: 10px;
            padding: 0 8px;
            border-radius: 50%;

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 12px;
              line-height: 20px;
              color: #656565;
            }
          }
        }

        .ongoing-event-post-card-alignment {
          padding: 32px 0 12px 0;

          @media only screen and (max-width: '1099px') {
            padding: 16px 0 0 0;
          }

          .slick-arrow {
            &.slick-next {
              display: none !important;
            }

            &.slick-prev {
              display: none !important;
            }
          }

          .ongoing-event-post-img-alignment {
            padding: 0 8px;

            .ongoing-post-img {
              height: 528px;
              width: 100%;
              position: relative;

              img {
                width: 100%;
                height: 100%;
                border-radius: 10px;
                object-fit: cover;
              }

              &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                right: 0;
                background: linear-gradient(0deg, #1e2432 0%, rgba(30, 36, 50, 0) 100%);
                border-radius: 10px;
              }

              .ongoing-event-view-status-alignment {
                display: flex;
                align-items: center;
                gap: 4px;
                position: absolute;
                top: 16px;
                right: 16px;

                .ongoing-event-status-alignment {
                  background: #ffffff;
                  border-radius: 8px;
                  padding: 0 8px;
                  display: flex;
                  align-items: center;
                  gap: 4px;
                  height: 24px;

                  p {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--secondary-900);
                  }
                }
              }

              .ongoing-event-post-details-alignment {
                display: flex;
                // justify-content: space-between;
                align-items: flex-end;
                position: absolute;
                bottom: 32px;
                left: 15px;
                right: 16px;

                .ongoing-event-post-details-name-alignment {
                  h6 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 30px;
                    color: #f8f8f8;
                    padding: 0 0 8px 0;
                  }

                  .ongoing-post-details-notes-alignment {
                    padding: 0 0 16px 0;

                    p {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 400;
                      font-size: 13px;
                      line-height: 20px;
                      color: #f8f8f8;
                    }
                  }

                  .ongoing-time-date-alignment {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    padding: 0 0 8px 0;

                    &:last-child {
                      padding: 0;
                    }

                    &.last-details-notes {
                      padding: 16px 0 0 0;
                    }

                    .time-icon-alignment {
                      img {
                        width: 16px;
                        height: 16px;
                        display: block;
                      }
                    }

                    .ongoing-details-alignment {
                      display: flex;
                      align-items: center;
                      gap: 7px;

                      span {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 20px;
                        color: #e4e4e8;
                      }

                      p {
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                        background: #acb1c0;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .ongoing-no-data-alignment {
          display: none;

          @media only screen and (max-width: '1099px') {
            display: flex;
            height: 528px;
            width: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 110px;

            .ongoing-name-alignment {
              max-width: 154px;
              text-align: center;

              span {
                font-family: 'Poppins', sans-serif;
                font-style: italic;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--secondary-400);
              }
            }
          }
        }
      }

      // }
    }
  }

  .upcoming-event-alignment {
    margin: 8px 0;
    background: #ffffff;
    border: 1px solid #ffffff;
    backdrop-filter: blur(32px);
    padding: 20px 32px;
    border-radius: 16px;
    width: 1297px;

    @media only screen and (max-width: '1099px') {
      width: 100%;
      padding: 16px;
    }

    .slick-arrow {
      &.slick-next {
        display: none !important;
      }

      &.slick-prev {
        display: none !important;
      }
    }

    .recordrd-heading-alignment {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .recordrd-card-heading-alignment {
        display: flex;
        align-items: center;
        gap: 4px;

        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: #000000;
        }

        .recordrd-mobile-view-number-alignment {
          background: #f0f0f0;
          border-radius: 10px;
          padding: 0 8px;
          border-radius: 50%;

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            color: #656565;
          }
        }
      }

      .recorded-option-alignment {
        display: flex;
        align-items: center;
        gap: 64px;

        @media only screen and (max-width: '1099px') {
          display: none;
        }

        .child-tab-alignment {
          ul {
            display: flex;
            align-items: center;
            gap: 32px;

            li {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #808191;

              &:hover {
                color: var(--secondary-900);
              }

              &.child-tab-active {
                color: var(--secondary-900);
              }
            }
          }
        }

        .calender-dropdown-alignment {
          background: #f8f8f8;
          border-radius: 10px;
          width: 178px;
          height: 40px;
          background: #f8f8f8;
          border-radius: 10px;
          padding: 0 17px 0 8px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .calender-name-alignment {
            display: flex;
            align-items: center;
            gap: 8px;

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #495057;
            }
          }

          .img-alignment {
            img {
              cursor: pointer;
            }
          }
        }
      }
    }

    .upcoming-event-post-card-alignment {
      padding: 32px 0 12px 0;

      @media only screen and (max-width: '1099px') {
        padding: 16px 0 0 0;
      }

      .upcoming-event-post-img-alignment {
        padding: 0 8px;

        .upcoming-post-img {
          height: 528px;
          width: 100%;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }

          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            background: linear-gradient(0deg, #1e2432 0%, rgba(30, 36, 50, 0) 100%);
            border-radius: 10px;
          }

          .upcoming-event-view-status-alignment {
            display: flex;
            align-items: center;
            gap: 4px;
            position: absolute;
            top: 16px;
            right: 16px;

            .upcoming-event-status-alignment {
              background: #ffffff;
              border-radius: 8px;
              padding: 4px;

              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                color: #495057;
                text-align: center;
              }

              .upcoming-date-alignment {
                background: #eceef4;
                border-radius: 8px;
                padding: 3px 12px;

                span {
                  font-family: 'Poppins', sans-serif;
                  font-style: normal;
                  font-weight: 600;
                  font-size: 24px;
                  line-height: 36px;
                  text-align: center;
                  color: var(--secondary-900);
                }
              }
            }
          }

          .upcoming-event-post-details-alignment {
            display: flex;
            // justify-content: space-between;
            align-items: flex-end;
            position: absolute;
            bottom: 32px;
            left: 15px;
            right: 16px;

            .upcoming-event-post-details-name-alignment {
              h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                color: #f8f8f8;
                padding: 0 0 8px 0;
              }

              .upcoming-post-details-notes-alignment {
                padding: 0 0 16px 0;

                p {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 400;
                  font-size: 13px;
                  line-height: 20px;
                  color: #f8f8f8;
                }
              }

              .upcoming-time-date-alignment {
                display: flex;
                align-items: center;
                gap: 8px;
                padding: 0 0 8px 0;

                &:last-child {
                  padding: 0;
                }

                &.last-details-notes {
                  padding: 16px 0 0 0;
                }

                .upcoming-time-icon-alignment {
                  img {
                    width: 16px;
                    height: 16px;
                    display: block;
                  }
                }

                .upcoming-details-alignment {
                  display: flex;
                  align-items: center;
                  gap: 7px;

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 20px;
                    color: #e4e4e8;
                  }

                  p {
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background: #acb1c0;
                  }
                }
              }

              .upcoming-instered-share-section {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 16px 0 0 0;

                .instred-drop-alignment {
                  display: flex;
                  align-items: center;
                  gap: 4px;

                  .interested-dropdown-name {
                    background: #f8f8f8;
                    border-radius: 8px;
                    padding: 6px 18px 5px 24px;

                    span {
                      font-family: 'Poppins', sans-serif;
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 21px;
                      display: block;
                      text-align: center;
                      color: #495057;
                    }
                  }

                  .dropbox-alignment {
                    background: #f8f8f8;
                    border-radius: 8px;
                    padding: 5px 4px 3px 4px;
                    cursor: pointer;
                  }
                }

                .upcoming-option-alignment {
                  display: flex;
                  align-items: center;
                  gap: 8px;

                  .uopcoming-option-box-alignment {
                    background: #f8f8f8;
                    border-radius: 8px;
                    padding: 4px;
                    cursor: pointer;

                    img {
                      display: block;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .ongoing-no-data-alignment {
      display: none;

      @media only screen and (max-width: '1099px') {
        display: flex;
        height: 528px;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 110px;

        .ongoing-name-alignment {
          max-width: 154px;
          text-align: center;

          span {
            font-family: 'Poppins', sans-serif;
            font-style: italic;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--secondary-400);
          }
        }
      }
    }
  }

  .recorded-event-alignment {
    margin: 8px 0;
    background: #ffffff;
    border: 1px solid #ffffff;
    backdrop-filter: blur(32px);
    padding: 20px 32px;
    border-radius: 16px;
    width: 1297px;

    @media only screen and (max-width: '1099px') {
      width: 100%;
      padding: 16px;
    }

    .slick-arrow {
      &.slick-next {
        display: none !important;
      }

      &.slick-prev {
        display: none !important;
      }
    }

    .recorded-event-card-heading-alignment {
      display: flex;
      align-items: center;
      gap: 4px;

      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        color: #000000;
      }

      .recorded-event-mobile-view-number-alignment {
        background: #f0f0f0;
        border-radius: 10px;
        padding: 0 8px;
        border-radius: 50%;

        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          color: #656565;
        }
      }
    }

    .recorder-event-post-section {
      padding: 32px 0 12px 0;

      @media only screen and (max-width: '1099px') {
        padding: 16px 0 0 0;
      }

      .recorder-details-box-alignment {
        padding: 0 8px;

        .recorder-post-img-alignment {
          height: 528px;
          width: 100%;
          position: relative;

          img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }

          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            background: linear-gradient(0deg, #1e2432 0%, rgba(30, 36, 50, 0) 100%);
            border-radius: 10px;
          }

          .recorder-view-status-alignment {
            display: flex;
            align-items: center;
            gap: 4px;
            position: absolute;
            top: 16px;
            right: 16px;

            .recorder-time-alignment {
              background: rgba(30, 36, 50, 0.6);
              padding: 2px 8px;
              display: flex;
              align-items: center;
              gap: 4px;

              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: #e4e4e8;
                white-space: nowrap;
              }

              img {
                width: 100%;
                height: 100%;
                cursor: pointer;
              }
            }
          }

          .recorder-event-details-alignment {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            position: absolute;
            bottom: 32px;
            left: 15px;
            right: 16px;

            .recorder-event-details-name-alignment {
              h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;
                color: #f8f8f8;
              }

              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: #f8f8f8;
                padding: 8px 0 0 0;
              }

              .recorder-time-date-alignment {
                display: flex;
                align-items: center;
                gap: 8px;
                padding: 16px 0 0 0;

                .recorder-time-icon-alignment {
                  img {
                    width: 16px;
                    height: 16px;
                    display: block;
                  }
                }

                .recorder-details-alignment {
                  display: flex;
                  align-items: center;
                  gap: 7px;

                  span {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 20px;
                    color: #e4e4e8;
                  }

                  p {
                    width: 2px;
                    height: 2px;
                    border-radius: 50%;
                    background: #acb1c0;
                    padding: 0;
                  }
                }
              }
            }

            .recorder-like-share-alignment {
              .recorder-like-alignment {
                img {
                  cursor: pointer;
                }

                p {
                  padding: 4px 0 0 0;
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 13px;
                  line-height: 20px;
                  color: #f8f8f8;
                }
              }

              .recorder-share-alignment {
                margin: 21px 0 0 0;

                img {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }

      .recorder-no-data-alignment {
        display: none;

        @media only screen and (max-width: '1099px') {
          display: flex;
          height: 528px;
          width: 100%;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 110px;

          .recorder-name-alignment {
            max-width: 154px;
            text-align: center;

            span {
              font-family: 'Poppins', sans-serif;
              font-style: italic;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: var(--secondary-400);
            }
          }
        }
      }
    }
  }
}

.option-alignment {
  position: relative;

  .more-option-dropdown-alignment {
    position: absolute;
    margin: 0;
    padding: 4px 0;
    text-align: left;
    list-style-type: none;
    background-color: #fff;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    width: 196px !important;
    border-radius: 10px;
    padding: 0;
    top: -5px;
    right: 0;
    z-index: 99;

    ul {
      li {
        cursor: pointer;

        .hide-web-class {
          display: none;

          @media only screen and (max-width: '767px') {
            display: block;
            margin: 8px 0 0 0;
          }
        }

        .dropdown-option {
          align-items: center;

          &:hover {
            background-color: #e4e4e8;
            border-radius: none !important;
          }

          // background-color: plum;
          .dropdown-main {
            display: flex;
            align-items: center;
            justify-content: space-between !important;
            padding: 0px 17px;

            .dropdown-icon {
              flex: 20%;
              text-align: left;
            }

            .dropdown-content {
              flex: 80%;

              h5 {
                font-size: 14px;
                line-height: 21px;
                color: #495057;
                margin: 0;
              }

              p {
                &:hover {
                  font-size: 8px;
                  line-height: 12px;
                  color: #808191;
                  font-weight: 400;
                  color: #495057;
                }

                font-size: 8px;
                line-height: 12px;
                color: #808191;
                margin: 0;
              }
            }
          }
        }

        .dropdown-option {
          &:hover {
            h5 {
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              display: flex;
              align-items: center;
              color: var(--secondary-900);
            }

            p {
              font-size: 8px;
              line-height: 12px;
              color: #808191;
              font-weight: 400;
              color: #495057;
            }
          }
        }

        .dropdown-option-last {
          &:hover {
            background-color: #e4e4e8;
            border-radius: 0px 0px 10px 10px;
          }
        }

        .dropdown-option-first {
          &:hover {
            background-color: #e4e4e8;
            border-radius: 10px 10px 0px 0px;
          }
        }
      }
    }
  }
}

.store-mobile-view-banner-option-alignment {
  position: absolute;
  top: 25px;
  right: 16px;
  left: 16px;
  // bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: none;

  @media only screen and (max-width: '767px') {
    display: flex;
  }

  .store-mobile-view-left-box-alignment {
    background: rgba(30, 36, 50, 0.6);
    border-radius: 10px;
    padding: 0 16px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.filter-menu-for-web {
  @media only screen and (max-width: '767px') {
    display: none;
  }
}

.filter-menu-for-mobile {
  @media only screen and (min-width: '767px') {
    display: none;
  }
}

.product-details-all-contain-alignment {
  padding: 0 0 110px 0;

  @media only screen and (max-width: '1024px') {
    padding: 16px 2px 0 2px;
    // height: calc(100vh - 212px);
    // overflow-y: auto;
  }
}

.shiiping-heading-alignment {
  // display: none;

  // @media screen and (max-width: 767px) {
  //   display: block;
  //   padding: 16px;
  // }

  h6 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    color: var(--secondary-900);
  }
}

.hide-web-class {
  display: none;

  @media only screen and (max-width: '1024px') {
    display: block;
    margin: 8px 0 0 0;
  }
}

.mobile-view-slider-view-img-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999999;
  display: none;

  &.open-wrapper-alignment {
    height: 100vh;
    overflow-y: auto;
    transition: 0.4s ease-in-out;
    background: rgba(30, 36, 50, 0.8);
  }

  &.close-wrapper-alignment {
    max-height: 0px;
    overflow-y: hidden;
    transition: 0.4s ease-in-out;
  }

  @media only screen and (max-width: '1024px') {
    display: block;
  }

  .mobile-view-slider-img-box {
    margin: 95px 0 0 0;

    .mobile-view-slider-img {
      width: 100%;
      height: 375px;

      &.mobile-view-second-view {
        height: 486px;
      }

      &.mobile-view-third-view {
        height: 563px;
      }

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
      }
    }

    .slick-slider .slick-list {
      -webkit-transform: none !important;
      -moz-transform: none !important;
      -ms-transform: none !important;
      -o-transform: none !important;
      transform: none !important;
    }

    .slick-slide {
      height: 200px;
    }

    .slick-dots {
      gap: 8px;
      transform: translateX(0px);
      right: 0;
      left: 0;
      justify-content: center;
      display: none !important;

      li {
        // width: 4px;
        // height: 4px;
        width: auto;
        height: auto;
        margin: 0;

        button {
          // width: 4px;
          // height: 4px;
          width: auto;
          height: auto;

          &::before {
            // width: 4px;
            // height: 4px;
            width: auto;
            height: auto;
            background: #d5d8de;
          }
        }

        &.slick-active {
          // width: 12px;
          width: auto;
          border-radius: 10px 10px 16px 16px;

          button {
            // width: 12px;
            width: auto;

            &::before {
              // width: 12px;
              width: auto;
              background: var(--secondary-500);
              border-radius: 10px 10px 16px 16px;
            }
          }
        }
      }
    }
  }
}

.new-review-details-alignment {
  .new-review-details-top-alignment {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .new-review-left-alignment {
      display: flex;
      align-items: center;
      gap: 9px;

      @media only screen and (max-width: '1024px') {
        gap: 8px;
      }

      .new-review-dropdown-alignment {
        padding: 6px 16px;
        border-radius: 8px;
        border: 1.5px solid #d5d8de;
        display: flex;
        align-items: center;
        gap: 32px;
        cursor: pointer;

        @media only screen and (max-width: '1024px') {
          padding: 6px 8px;
          gap: 8px;
        }

        p {
          color: var(--secondary-600);
          font-size: 15px;
          font-weight: 500;
          line-height: 20px;
        }
      }
    }
  }
}

.no-review-box-alignment {
  width: 100%;
  height: 585px;
  border-radius: 16px;
  border: 1px solid var(--secondary-50);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;

  @media only screen and (max-width: '1024px') {
    height: 242px;
    margin: 56px 0 0 0;
  }

  h6 {
    color: #9c6ac5;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;

    @media only screen and (max-width: '1024px') {
      font-size: 12px;
      font-style: italic;
      line-height: 18px;
    }
  }
}

.no-gallery-media-section {
  border-radius: 16px;
  border: 1px solid var(--secondary-50);
  width: 100%;
  height: 482px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;

  @media only screen and (max-width: '1024px') {
    height: 290px;
  }

  p {
    color: #9c6ac5;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 14px;
  }
}

.new-comment-box-section {
  max-width: 640px;
  margin: 0 auto;
  padding: 16px 0 0 0;

  @media only screen and (max-width: '1024px') {
    padding: 0;
    max-width: 100%;
    height: calc(100vh - 236px);
    overflow-y: auto;
  }

  .new-comment-box-main-alignment {
    border-radius: 16px;
    border: 1px solid var(--secondary-50);
    padding: 16px 32px;

    @media only screen and (max-width: '1024px') {
      padding: 0;
      border: 0;
    }

    .new-comment-box-heading-alignment {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .new-comment-main-heading {
        display: flex;
        align-items: center;
        gap: 4px;

        @media only screen and (max-width: '1024px') {
          align-items: baseline;
        }

        h4 {
          color: var(--secondary-900);
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;

          @media only screen and (max-width: '1024px') {
            font-weight: 600;
          }
        }

        span {
          color: var(--grey2);
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;

          @media only screen and (max-width: '1024px') {
            line-height: 16px;
          }
        }
      }

      a {
        color: var(--primary-500);
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;
        cursor: pointer;

        @media only screen and (max-width: '1024px') {
          display: none;
        }
      }
    }

    .new-comment-all-body-details-alignment {
      padding: 16px 0 0 0;
      height: calc(100vh - 384px);
      overflow-y: auto;

      @media only screen and (max-width: '1024px') {
        height: auto;
        padding: 8px 0 0 0;
      }

      .new-comment-mobile-view-all-comment-alignment {
        display: none;

        @media only screen and (max-width: '1024px') {
          display: block;
          text-align: center;
          padding: 9px 0 0 0;

          a {
            color: var(--primary-500);
            font-size: 15px;
            font-weight: 500;
            line-height: 21px;
          }
        }
      }

      .new-comment-no-data-alignment {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 16px;

        @media only screen and (max-width: '1024px') {
          border-radius: 16px;
          border: 1px solid var(--secondary-50);
          height: 238px;
        }

        p {
          color: #9c6ac5;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          text-align: center;

          @media only screen and (max-width: '1024px') {
            font-style: italic;
          }
        }
      }
    }
  }

  .new-all-comment-details-section {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 60px;
      background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
      z-index: 999;
      top: 0;
      left: 0;
    }
  }

  .new-comment-details-alignment {
    padding: 0 0 16px 0;

    .new-comment-profile-details {
      display: flex;
      align-items: center;
      gap: 8px;

      .new-comment-profile-img {
        width: 32px;
        height: 32px;

        img {
          width: 100%;
          height: 100%;
          display: block;
          border-radius: 50%;
        }
      }

      h6 {
        color: var(--secondary-900);
        font-size: 14px;
        font-weight: 600;
        line-height: 21px;
      }

      span {
        color: var(--secondary-500);
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
      }
    }

    .new-comment-all-details-alignment {
      padding: 8px 0 0 40px;

      p {
        color: var(--secondary-900);
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
      }

      .new-comment-share-like-alignment {
        display: flex;
        align-items: center;
        gap: 16px;

        .new-comment-child-option {
          display: flex;
          align-items: center;
          gap: 10px;
          cursor: pointer;

          span {
            color: var(--secondary-500);
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
          }
        }
      }
    }
  }

  .new-comment-box-input-alignment {
    padding: 0 20px;

    @media only screen and (max-width: '1024px') {
      padding: 0;
    }
  }
}

.customer-traction-new-main {
  padding: 16px 32px 32px 32px;

  @media only screen and (max-width: '1024px') {
    padding: 0;
  }

  .customer-tracation-grid-alignment {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    @media only screen and (max-width: '1024px') {
      grid-template-columns: 1fr;
      gap: 16px;
    }

    .customer-tracation-box-new-alignment {
      border-radius: 10px;
      border: 1px solid #e6e8ec;
      background: #fff;
      width: 100%;
      height: 382px;
      padding: 16px 24px;

      @media only screen and (max-width: '1024px') {
        padding: 0;
        width: 100%;
        border: 0;
        height: auto;
      }

      .customer-new-heading-alignment {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
          color: var(--secondary-900);
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;

          @media only screen and (max-width: '1024px') {
            display: none;
          }

          &.mobile-view-text {
            display: none;

            @media only screen and (max-width: '1024px') {
              font-weight: 600;
              display: block;
            }
          }
        }

        .customer-new-dropdown-alignment {
          display: flex;
          align-items: center;
          gap: 6px;
          cursor: pointer;

          .drodwn-name-alignment {
            min-width: 115px;

            p {
              color: var(--secondary-500);
              font-size: 14px;
              font-weight: 500;
              line-height: 21px;
            }
          }

          .dropdown-bottom-arrow {
            cursor: pointer;
          }
        }

        .customer-new-tracation-right-details {
          display: flex;
          align-items: center;
          gap: 24px;

          span {
            color: var(--grey2);
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
          }

          .customer-next-prev-pagination-alignment {
            display: flex;
            align-items: center;
            gap: 24px;

            .child-name-flex-alignment {
              display: flex;
              align-items: center;
              gap: 4px;
              cursor: pointer;
            }
          }
        }
      }

      .customer-new-dropdown-tab-alignment {
        padding: 16px 0 0 0;

        ul {
          display: flex;
          align-items: center;
          gap: 8px;
          list-style-type: none;

          li {
            padding: 5px 14px;
            border-radius: 8px;
            border: 1px solid var(--secondary-50);
            color: var(--secondary-500);
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            cursor: pointer;
            transition: 0.4s ease-in-out;

            &:hover {
              border-radius: 8px;
              background: var(--primary-50);
              border: 1px solid transparent;
              transition: 0.4s ease-in-out;
              color: var(--secondary-900);
            }
          }
        }
      }

      .new-customer-tracation-chart-alignment {
        height: 100%;
        padding: 21px 0 0 0;

        .devider {
          border: 1px solid #e4e4e8;
          width: 100%;
          margin: 12px 0;
        }

        .progressbar-main {
          height: 46px;
          border-bottom: 1px solid #e4e4e8;
          margin-bottom: 12px;

          .progress-title {
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: #495057;
            }

            p {
              font-weight: 400;
              font-size: 11px;
              line-height: 16px;
              color: #495057;
            }
          }
        }
      }

      .new-customer-no-data-chart-alignment {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 16px;
        height: 272px;
        width: 100%;

        @media only screen and (max-width: '1024px') {
          height: 232px;
          border-radius: 16px;
          border: 1px solid var(--secondary-50);
          margin: 8px 0 0 0;
        }

        &.secongBox-chart {
          height: 322px;

          @media only screen and (max-width: '1024px') {
            height: 208px;
          }
        }

        p {
          color: #9c6ac5;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
        }
      }
    }
  }
}

.comment-see-all-comment-alignment {
  text-align: center;

  @media only screen and (max-width: '1024px') {
    display: none;
  }

  a {
    color: var(--primary-500);
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    cursor: pointer;

    &:hover {
      color: var(--primary-500);
    }
  }
}

.star-ratings {
  @media screen and (max-width: 1024px) {
    svg {
      width: 10px;
      height: 10px;
    }
  }
}

.new-product-comment-second-header-alignment {
  display: none;

  @media screen and (max-width: 1024px) {
    display: block;
    position: relative;
    text-align: center;
    background: #fff;
    padding: 0 16px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    h6 {
      color: var(--secondary-900);
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }

    .mobile-view-back-arrow-alignment {
      position: absolute;
      top: 8px;
      left: 16px;
    }
  }
}

.mobile-view-all-shipping-seaction {
  display: none;

  @media screen and (max-width: 1024px) {
    display: block;
    padding: 8px 0 16px 0;
    text-align: center;

    a {
      color: var(--primary-500);
      font-size: 15px;
      font-weight: 500;
      line-height: 22px;
    }
  }
}

.all-child-details-modal-alignment {
  .all-child-details-modal-wrapper {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 9999999;
    overflow-y: auto;

    &.open-modal-wrapper {
      transform: translateX(0%);
      transition: 0.4s ease-in-out;
    }

    &.close-modal-wrapper {
      transform: translateX(100%);
      transition: 0.4s ease-in-out;
    }

    .all-child-header-sticky {
      position: sticky;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 99;

      .all-child-modal-header-alignment {
        display: none;

        @media screen and (max-width: 1024px) {
          display: block;
          position: relative;
          text-align: center;
          background: #fff;
          padding: 0 16px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;

          h6 {
            color: var(--secondary-900);
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
          }

          .mobile-view-back-arrow-alignment {
            position: absolute;
            top: 8px;
            left: 16px;
          }
        }
      }

      .product-mobile-view-second-header-alignment {
        padding: 8px 16px;
        background: #fff;
        display: none;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 1024px) {
          display: flex;
        }

        .mobile-view-heading-alignment {
          h6 {
            color: var(--secondary-900);
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
          }
        }
      }
    }

    .all-child-modal-all-details-alignment {
      padding: 12px 16px;
    }

    .all-child-comment-modal-all-details-alignment {
      padding: 8px 16px;
    }
  }
}

@media only screen and (max-width: '1024px') {
  .review-modal-dropdown-alignment {
    padding: 16px;
  }
}

.search-main-box-alignment {
  display: flex;
  align-items: center;
  margin: 0 30px 0 0;
  transition: 0.4s ease-in-out;

  &:hover {
    background: #eef1fc;
    border-radius: 10px;
    transition: 0.4s ease-in-out;
  }

  &.searct-main-active-box {
    background: #eef1fc;
    border-radius: 10px;
    transition: 0.4s ease-in-out;
  }
}

.no-data-following-alignment {
  width: 100%;
  height: 228px;
  border-radius: 16px;
  background: #eef1fd;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-direction: column;

  p {
    color: #9c6ac5;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
  }
}

.product-priview-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: rgb(0, 0, 0, 0.5);
  z-index: 999999999;
  backdrop-filter: brightness(0.5);

  .close-button-design {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    position: absolute;
    right: 30px;
    top: 30px;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
    z-index: 999;
  }

  .center-alignment-modal {
    padding-left: 10%;

    .produtc-img-alignment {
      width: 100%;
      height: calc(100vh - 150px) !important;
      line-height: 690px;

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: contain;
      }

      @media only screen and (max-width: '1024px') {
        height: auto;
      }
    }

    .new-produtc-img-alignment-preview {
      width: 500px;
      height: calc(100vh - 150px) !important;
      background-color: rgb(237 237 237);
      position: relative;
      // border-radius: 8px;
      overflow: hidden;

      .poroduct-image-background-preview {
        // border-radius: 8px;
        position: absolute;
        width: 500px;
        height: calc(100vh - 150px) !important;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        mix-blend-mode: multiply;
      }
    }
  }

  .slick-list {
    height: calc(100vh - 150px) !important;
    max-width: 760px;

    @media (max-width: 1500px) {
      max-width: 600px;
    }
  }

  .slick-dots {
    transform: translateY(-50%);
    display: grid !important;
    justify-content: flex-end;
    grid-template-columns: repeat(2, 1fr);
    width: auto;
    top: 50% !important;
    right: -55%;
    width: auto !important;
    height: auto;
    transform: translateY(-50%);

    @media (max-width: 1500px) {
      right: -45%;
    }
  }

  .slick-dots li img {
    border-radius: 8px;
  }

  .slick-dots li {
    background: #ffffff;
    align-items: center;
    display: flex;
    margin-left: 3px;
    margin-right: 3px;
    width: 59px !important;
    height: 85px !important;
    border: 2px solid #fff;
    border-radius: 8px;
    margin-bottom: 6px !important;
  }
}

.new-produtc-img-alignment-dots {
  width: 44px;
  height: 44px;
  background-color: rgb(237 237 237);
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  .poroduct-image-background {
    border-radius: 8px;
    position: absolute;
    width: 44px;
    height: 44px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    mix-blend-mode: multiply;
  }
}