.imagesSizeDropdownAlignment {
  background: #ffffff;
  border: 1px solid #e3e4e8;
  border-radius: 4px;
  padding: 16px 8px;
  position: absolute;
  z-index: 99999;
  top: 32px;
  left: 0;
  width: 100%;

  .imagesSizeDropdownOption {
    padding: 0 0 8px 0;

    .imagesSizeHeadingAlignment {
      padding: 0 10px 8px 10px;

      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: var(--secondary-300);
      }
    }

    .imagesSizeNameAlignment {
      padding: 3px 8px;
      cursor: pointer;
      margin: 0 0 8px 0;
      text-align: center;

      &:last-child {
        margin: 0;
      }



      span {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: var(--secondary-900);
      }

      &:hover {
        background: #eef1fc;
        border-radius: 4px;

        span {
          font-weight: 600;
          color: var(--primary-500);
        }
      }
    }
  }
}