.message-chat-box-section {
  width: 100%;

  @media only screen and (max-width: '767px') {
    // height: 100%;
  }

  .message-cha-box-alignment {
    @media only screen and (max-width: '767px') {
      // height: 100%;
    }

    .message-heading-alignment {
      border-bottom: 1px solid var(--secondary-100);
      border-radius: 10px 10px 0px 0px;
      padding: 16px 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: static;
      top: 28px;
      left: 0;
      background: #fff;
      z-index: 9999999999;
      width: 100%;

      @media only screen and (max-width: '767px') {
        // padding: 16px;
        padding: 10px 16px;
        position: sticky;
      }

      .message-heading-profile-flex-alignment {
        display: flex;
        align-items: center;
        gap: 22px;

        .typing-display-alignment {
          left: 10px;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: #6f6f78;
          text-transform: capitalize;
          margin-top: 5px;
        }

        @media only screen and (max-width: '767px') {
          gap: 8px;
        }

        .message-heading-profile-img-alignment {
          width: 56px;
          height: 56px;

          @media only screen and (max-width: '767px') {
            width: 32px;
            height: 32px;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: var(--secondary-900);

          @media only screen and (max-width: '767px') {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }

      .group-message-heading-profile-flex-alignment {
        display: flex;
        align-items: center;
        gap: 22px;

        @media only screen and (max-width: '767px') {
          gap: 12px;
        }

        .group-message-heading-img-box-alignment {
          width: 46px;
          min-width: 46px;
          height: 48px;
          min-height: 48px;
          position: relative;

          @media only screen and (max-width: '767px') {
            width: 42px;
            min-width: 42px;
            height: 42px;
            min-height: 42px;
          }

          .group-message-flex-alignment-0 {
            width: 24px;
            height: 24px;
            min-width: 24px;

            @media only screen and (max-width: '767px') {
              width: 20px;
              height: 20px;
              min-width: 20px;
              position: relative;
              bottom: -4px;
              right: -1px;
            }

            .group-message-profile-img-alignment-0 {
              width: 24px;
              height: 24px;
              min-width: 24px;

              @media only screen and (max-width: '767px') {
                width: 18px;
                height: 18px;
                min-width: 18px;
                margin: 0 auto;
              }

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
              }
            }
          }

          .group-message-flex-alignment-1 {
            display: flex;
            justify-content: flex-end;
            margin: -12px 0 0 0;

            @media only screen and (max-width: '767px') {
              margin: -9px 0 0 0;
            }

            .group-message-profile-img-alignment-1 {
              width: 16px;
              height: 16px;
              min-width: 16px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
              }
            }
          }

          .group-message-flex-alignment-2 {
            display: flex;
            justify-content: center;

            .group-message-profile-img-alignment-2 {
              width: 20px;
              height: 20px;
              min-width: 20px;

              @media only screen and (max-width: '767px') {
                width: 16px;
                height: 16px;
                min-width: 16px;
              }

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
              }
            }
          }

          .group-message-first-dot-alignment {
            position: absolute;
            top: 4px;
            right: 13px;

            .first-dot-width-alignment {
              width: 5px;
              height: 5px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          .group-message-second-dot-alignment {
            position: absolute;
            left: 1px;
            bottom: 15px;

            @media only screen and (max-width: '767px') {
              left: 2px;
              bottom: 10px;
            }

            .second-dot-width-alignment {
              width: 6px;
              height: 6px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          .group-message-third-dot-alignment {
            position: absolute;
            right: 9px;
            bottom: 15px;

            @media only screen and (max-width: '767px') {
              right: 6px;
              bottom: 8px;
            }

            .third-dot-width-alignment {
              width: 2px;
              height: 2px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }

        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: var(--secondary-900);

          @media only screen and (max-width: '767px') {
            font-size: 16px;
            line-height: 24px;
          }
        }
      }

      .message-option-details-alignment {
        display: flex;
        align-items: center;
        gap: 20px;

        @media only screen and (max-width: '767px') {
          gap: 12px;
        }

        .message-option-icon-alignment {
          position: relative;

          .message-option-icon-alignment-active {
            padding: 8px;
            background: #EEF1FD;
            border-radius: 8px;
          }

          &.mobile-view-none-icon {
            @media only screen and (max-width: '767px') {
              display: none;
            }
          }

          &.mobile-view-show-icon {
            display: none;

            @media only screen and (max-width: '767px') {
              display: block;
            }
          }

          img {
            cursor: pointer;
          }

          .message-option-dropdown-alignment {
            background: #ffffff;
            border-radius: 10px;
            padding: 11px 0;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
            max-width: 250px;
            width: 250px;
            position: absolute;
            right: 0;
            top: 36px;
            z-index: 999999;



            @media only screen and (max-width: '767px') {
              display: none;
            }

            .message-box-option-name-alignment {
              display: flex;
              align-items: center;
              gap: 18px;
              padding: 18px 16px;
              cursor: pointer;

              &.delete-message {
                span {
                  color: #e71616;
                }
              }

              &:hover {
                background: #eceef4;
              }

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: var(--secondary-600);
              }
            }
          }
        }

        .open-serach-input-alignment {
          width: 230px;
          height: 32px;
          position: relative;

          input {
            border-radius: 10px;
            padding: 0 34px 0 8px;
            background: #ffffff;
            border: 1px solid var(--secondary-100);
            border-radius: 10px;
            width: 100%;
            height: 100%;
          }

          .close-input-alignment {
            position: absolute;
            cursor: pointer;
            top: 4px;
            right: 8px;
          }
        }
      }

      .group-message-edit-header-profile-alignment {
        display: flex;
        align-items: center;
        gap: 20px;

        .group-message-edit-profile-alignment {
          .group-message-edit-profile-box-alignment {
            width: 46px;
            min-width: 46px;
            height: 48px;
            min-height: 48px;
            position: relative;

            .group-message-flex-alignment-0 {
              width: 24px;
              height: 24px;
              min-width: 24px;

              .group-message-profile-img-alignment-0 {
                width: 24px;
                height: 24px;
                min-width: 24px;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  object-fit: cover;
                }
              }
            }

            .group-message-flex-alignment-1 {
              display: flex;
              justify-content: flex-end;
              margin: -12px 0 0 0;

              .group-message-profile-img-alignment-1 {
                width: 16px;
                height: 16px;
                min-width: 16px;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  object-fit: cover;
                }
              }
            }

            .group-message-flex-alignment-2 {
              display: flex;
              justify-content: center;

              .group-message-profile-img-alignment-2 {
                width: 20px;
                height: 20px;
                min-width: 20px;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                  object-fit: cover;
                }
              }
            }

            .group-message-first-dot-alignment {
              position: absolute;
              top: 4px;
              right: 13px;

              @media only screen and (max-width: '767px') {
                top: 3px;
                right: 15px;
              }

              .first-dot-width-alignment {
                width: 5px;
                height: 5px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }

            .group-message-second-dot-alignment {
              position: absolute;
              left: 1px;
              bottom: 15px;

              .second-dot-width-alignment {
                width: 6px;
                height: 6px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }

            .group-message-third-dot-alignment {
              position: absolute;
              right: 9px;
              bottom: 15px;

              .third-dot-width-alignment {
                width: 2px;
                height: 2px;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }

        .group-name-edit-input-box-alignment {
          width: 304px;
          height: 44px;

          input {
            width: 100%;
            height: 100%;
            background: #eceef4;
            border-radius: 10px;
            padding: 0 12px;
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            color: var(--secondary-900);
          }
        }
      }

      .group-message-edit-option-alignment {
        display: flex;
        align-items: center;
        gap: 24px;

        .cancle-button-alignment {
          button {
            border: none;
            background: transparent;
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            color: var(--primary-500);
            cursor: pointer;
          }
        }

        .save-button-alignment {
          button {
            padding: 6px 16px;
            background: var(--primary-500);
            border-radius: 8px;
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            color: #ffffff;
            cursor: pointer;
          }
        }
      }

      .media-modal-alignment {
        width: 100%;
        background: #ffffff;
        // padding: 8px;
        padding: 0;
        position: absolute;
        top: 89px;
        z-index: 999;
        left: 0;

        .top-menu-bg-alignment {
          // background: linear-gradient(180deg, rgba(30, 36, 50, 0) 0%, rgba(30, 36, 50, 0.6) 100%);
          transform: rotate(180deg);
          width: 100%;
          height: 30px;
          top: 0;
          right: 0;
          position: absolute;
        }

        .bottom-menu-bg-alignment {
          // background: linear-gradient(180deg, rgba(30, 36, 50, 0) 0%, rgba(30, 36, 50, 0.6) 100%);
          width: 100%;
          height: 30px;
          bottom: 0;
          right: 0;
          position: absolute;
        }

        .media-modal-details-alignment {
          height: 644px;
          overflow-y: auto;

          .no-media-found-alignment {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 644px;

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 20px;
              line-height: 24px;
              color: var(--secondary-600);
            }
          }

          .media-modal-img-grid-alignment {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 8px;



            .media-modal-img-gridItem-alignment {
              border: 1px solid #D9D9D9;
              box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);

              .media-modal-img {
                width: 100%;
                height: 224px;
                overflow: hidden;
                position: relative;
                transition: .4s ease-in-out;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  transition: .4s ease-in-out;
                  z-index: 1;
                }

                &::before {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  content: "";
                  opacity: 0;
                  z-index: 9;
                  background-color: #00000050;
                  transition: .4s ease-in-out;
                }

                &:hover {
                  img {
                    transform: scale(1.1);
                  }

                  &::before {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }

      .media-modal-show-area {
        max-height: 652px;
        overflow-y: auto;
        transition: 0.3s ease-in-out;
        z-index: 999999;
      }

      .media-modal-hidden-area {
        max-height: 0px;
        overflow: hidden;
        transition: 0.3s ease-in-out;
      }
    }

    .message-box-body-alignment {
      padding: 16px 56px;

      height: calc(100vh - 262px);
      overflow-y: auto;

      @media only screen and (max-width: '1024px') {
        height: calc(100vh - 198px);
      }

      &.message-overflow-hidden {
        overflow-y: hidden;
      }

      &.group-message-body-alignment {
        padding: 16px;
      }

      .right-side-main-alignment {
        // margin: 0 0 16px 0;
        margin: 8px 0 8px 0;
        position: relative;
        z-index: 99999 !important;

        &.border-effect {
          margin: 0 20px;
          padding: 10px;
          border-radius: 8px;
          border: 1px solid #d4d5d8;
          transform: scale(1.05);
          transition: 0.2s;
          box-shadow: 0px 8px 10px rgba(47, 61, 127, 0.3);
        }

        .message-box-right-side-message-alignment {
          display: flex;
          align-items: flex-end;
          gap: 8px;
          justify-content: flex-end;
          margin: 0 0 16px 0;

          .web-view-time-alignment {
            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              color: #92929d;

              @media only screen and (max-width: '767px') {
                display: none;
              }
            }
          }

          .right-message-box-alignment {
            background: #1e2432;
            border-radius: 16px 16px 0px 16px;
            padding: 16px;
            max-width: 352px;
            position: relative;
            cursor: pointer;
            z-index: 9999999;

            .reacted-details {
              background: #ffffff;
              border-radius: 12px;
              padding: 4px;
              box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
              position: absolute;
              // right: 32px;
              // right: 50%;
              left: 50%;
              transform: translateX(-50%);
              bottom: -12px;

              .reated-details-flex-alignment {
                display: flex;
                align-items: center;
                gap: 2px;

                .reacted-icon-alignment {
                  cursor: pointer;
                  width: 16px;
                  height: 16px;

                  img {
                    width: 100%;
                    height: 100%;
                    display: block;
                  }
                }
              }
            }

            @media only screen and (max-width: '767px') {
              padding: 8px 16px;
            }

            &:hover {
              box-shadow: 0px 4px 5px rgba(47, 61, 127, 0.24);
              border-radius: 16px 16px 0px 16px;
              transition: 0.3s ease-in-out;
            }

            .right-message-hover-details-alignment {
              display: flex;
              display: flex;
              align-items: center;
              gap: 2px;
              position: absolute;
              left: -172px;
              top: 0;

              @media only screen and (max-width: '767px') {
                display: none;
              }

              .message-icon-alignment {
                cursor: pointer;
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;

                .reaction-box-alignment {
                  border-radius: 38px;
                  background: #fff;
                  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
                  width: 388px;
                  padding: 16px;
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  position: absolute;
                  top: -75px;
                  right: -80px;
                  z-index: 999999;
                  transition: 0.4s ease-in-out;

                  .child-reaction-box {
                    width: 44px;
                    height: 44px;
                    border-radius: 50%;
                    border-radius: 22px;
                    background: #fff;
                    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                      width: 24px;
                      height: 24px;
                      display: block;
                    }
                  }
                }

                &:hover {
                  &:last-child {
                    border-radius: 8px;
                    background: #eef1fd;

                    svg {
                      path {
                        stroke: #556ee6;
                      }
                    }
                  }

                  // .reaction-box-alignment {
                  //   transition: 0.4s ease-in-out;
                  //   display: flex;
                  // }
                }
              }
            }

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 15px;
              line-height: 20px;
              color: var(--secondary-200);
            }

            .mobile-view-right-side-message-time-alignment {
              display: none;

              @media only screen and (max-width: '767px') {
                display: block;
                text-align: right;
              }

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: #92929d;
              }
            }

            .right-reacted-details {
              background: #ffffff;
              border-radius: 12px;
              padding: 4px;
              box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
              position: absolute;
              right: 32px;
              bottom: -12px;

              .right-reated-details-flex-alignment {
                display: flex;
                align-items: center;
                gap: 2px;

                .right-reacted-icon-alignment {
                  cursor: pointer;
                  width: 16px;
                  height: 16px;

                  img {
                    width: 100%;
                    height: 100%;
                    display: block;
                  }
                }
              }
            }

            // .right-message-hover-details-alignment {
            //   display: flex;
            //   align-items: center;
            //   gap: 2px;
            //   display: none;
            // }
          }

          .replied-arrow-alignment {
            display: none;
          }

          .right-side-replied-arrow-alignment {
            // display: none;
          }
        }

        .right-side-replied-details-alignment {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: #92929d;
            // display: block;
            padding: 0 0 4px 4px;
          }

          .right-side-replied-message-box-alignment {
            background: var(--primary-50);
            border-radius: 16px;
            border-radius: 16px;
            padding: 16px;
            max-width: 358px;
            margin: 0 0 4px 0;
            // display: block;

            p {
              max-width: 326px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-family: 'Poppins', sans-serif;
              font-style: italic;
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              color: var(--secondary-600);
              padding: 0;
            }
          }
        }

        &.right-side-replied-details-section {
          .right-side-replied-details-alignment {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              color: #92929d;
              // display: block;
              padding: 0 0 4px 4px;
            }

            .right-side-replied-message-box-alignment {
              background: var(--primary-50);
              border-radius: 16px;
              border-radius: 16px;
              padding: 16px;
              max-width: 358px;
              margin: 0 0 4px 0;
              // display: block;

              p {
                max-width: 326px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-family: 'Poppins', sans-serif;
                font-style: italic;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: var(--secondary-600);
                padding: 0;
              }
            }
          }

          .message-box-right-side-message-alignment {
            .replied-arrow-alignment {
              display: block;
            }

            .right-side-replied-arrow-alignment {
              // display: block;
            }
          }
        }

        // .right-side-replied-details-alignment {
        //   display: none;
        //   margin: 8px 0 0 0;
        // }

        // .right-side-replied-message-box-alignment {
        //   display: none;
        // }
      }

      .left-side-main-alignment {
        // margin: 0 0 8px 0;

        &.replied-details-section {
          .replied-details-alignment {
            display: block;

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 13px;
              line-height: 20px;
              color: #92929d;
              display: block;
              padding: 0 0 4px 4px;
            }

            .replied-message-box-alignment {
              background: #1e2432;
              border-radius: 16px;
              padding: 16px;
              max-width: 358px;
              margin: 0 0 4px 0;
              display: block;

              p {
                max-width: 326px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-family: 'Poppins', sans-serif;
                font-style: italic;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: #ffffff;
                padding: 0;
              }
            }
          }

          .message-box-left-side-message-alignment {
            .replied-arrow-alignment {
              display: block;
            }
          }
        }

        .message-box-left-side-message-alignment {
          display: flex;
          align-items: start;
          gap: 8px;
          justify-content: flex-start;
          // margin: 0 0 8px 0;
          transition: 0.3s ease-in-out;
          flex-direction: column;

          // &:last-child {
          //   margin: 0;
          // }

          .replied-arrow-alignment {
            display: none;
          }

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: #92929d;

            @media only screen and (max-width: '767px') {
              display: none;
            }
          }

          .left-side-user-details-flex-alignemnt {
            display: flex;
            align-items: flex-start;
            gap: 8px;
            width: calc(100% - 67px);

            // margin: 0 0 8px 0;
            .left-side-user-img-alignment {
              width: 32px;
              height: 32px;
              max-width: 32px;
              min-width: 32px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                display: block;
                object-fit: cover;
              }
            }

            .left-side-flex-alignment {
              display: flex;
              align-items: flex-end;
              gap: 8px;
            }

            .left-message-box-alignment {
              background: var(--primary-50);
              border-radius: 0px 16px 16px 16px;
              padding: 16px;
              max-width: 326px;
              position: relative;
              cursor: pointer;
              transition: 0.3s ease-in-out;

              &.without-border {
                border-radius: 16px;
              }

              &.group-message {
                padding: 10px 16px 16px 16px;
              }

              &:hover {
                box-shadow: 0px 4px 5px rgba(47, 61, 127, 0.24);
                border-radius: 0 16px 16px 16px;
                transition: 0.3s ease-in-out;

                &.without-border {
                  border-radius: 16px;
                }
              }

              .message-hover-details-alignment {
                display: flex;
                align-items: center;
                gap: 2px;
                position: absolute;
                right: -104px;
                top: -14px;
                height: 50px;

                .message-icon-alignment {
                  cursor: pointer;
                  width: 32px;
                  height: 32px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .reaction-box-alignment {
                    border-radius: 38px;
                    background: #fff;
                    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
                    width: 388px;
                    padding: 16px;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    position: absolute;
                    top: -70px;
                    left: -80px;
                    z-index: 999999;
                    transition: 0.4s ease-in-out;

                    .child-reaction-box {
                      width: 44px;
                      height: 44px;
                      border-radius: 50%;
                      border-radius: 22px;
                      background: #fff;
                      box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
                      cursor: pointer;
                      display: flex;
                      align-items: center;
                      justify-content: center;

                      img {
                        width: 24px;
                        height: 24px;
                        display: block;
                      }
                    }
                  }

                  &:hover {
                    &:last-child {
                      border-radius: 8px;
                      background: #eef1fd;

                      svg {
                        path {
                          stroke: #556ee6;
                        }
                      }
                    }

                    // .reaction-box-alignment {
                    //   transition: 0.4s ease-in-out;
                    //   display: flex;
                    // }
                  }
                }
              }

              .reacted-details {
                background: #ffffff;
                border-radius: 12px;
                padding: 4px;
                box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
                position: absolute;
                // right: 32px;
                // right: 50%;
                left: 50%;
                transform: translateX(-50%);
                bottom: -12px;

                .reated-details-flex-alignment {
                  display: flex;
                  align-items: center;
                  gap: 2px;

                  .reacted-icon-alignment {
                    cursor: pointer;
                    width: 16px;
                    height: 16px;

                    img {
                      width: 100%;
                      height: 100%;
                      display: block;
                    }
                  }
                }
              }

              // .message-hover-details-alignment {
              //   display: flex;
              //   align-items: center;
              //   gap: 2px;
              //   display: none;
              // }

              h6 {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                font-size: 13px;
                line-height: 20px;
                color: var(--primary-800);
                padding: 0 0 3px 0;
              }

              p {
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                color: #182431;
              }
            }
          }
        }
      }

      .left-media-alignment {
        // margin: 30px 0;
        width: 334px;
        height: 222px;
        padding: 8px 0 0 0;

        img {
          width: 100%;
          height: 100%;
          border: 8px solid #eef1fd;
          border-radius: 16px;
          display: block;
        }
      }

      .right-img-flex {
        display: flex;
        justify-content: flex-end;

        .right-side-media-alignment {
          // margin: 30px 0;
          width: 334px;
          height: 222px;

          img {
            width: 100%;
            height: 100%;
            // border: 8px solid #1e2432;
            border-radius: 16px;
            display: block;
            object-fit: cover;
          }
        }
      }

      .repied-details-all-alignment {
        margin: 0 0 8px 0;
        display: flex;
        // align-items: flex-end;
        flex-direction: column;
        align-items: flex-start;
        // gap: 8px;
        width: 100%;

        &.border-effect {
          margin: 0 20px;
          padding: 10px;
          border-radius: 8px;
          border: 1px solid #d4d5d8;
          transform: scale(1.05);
          transition: 0.2s;
          box-shadow: 0px 8px 10px rgba(47, 61, 127, 0.3);
        }

        &.img-with-message {
          display: block;

          .left-side-user-details-flex-alignemnt {
            margin: 0 0 8px 0;
          }
        }
      }

      .replied-message-first-flex {
        display: flex;
        align-items: center;
        gap: 4px;
        margin: 0 0 0 12px;
        width: 100%;

        .replied-message-child-flex-alignment {
          display: flex;
          align-items: flex-end;
          gap: 8px;
          flex-wrap: wrap;

          .left-side-user-details-flex-alignemnt {
            width: inherit !important;
          }
        }
      }

      .replied-details-alignment {
        display: none;
      }

      .replied-message-box-alignment {
        display: none;
      }

      .relod-alignment {
        padding: 4px 0 0 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: #92929d;
          cursor: pointer;
        }
      }

      .no-chat-detaild-box-alignment {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .no-chat-profile-img {
          width: 116px;
          height: 116px;
          margin: auto;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        .group-message-profile-img-box-alignment-sidebar {
          width: 114px;
          min-width: 46px;
          height: 118px;
          min-height: 48px;
          position: relative;
          margin: auto;

          .group-message-flex-alignment-0 {
            .group-message-profile-img-alignment-0 {
              width: 58.96px;
              height: 58.96px;
              min-width: 24px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
              }
            }
          }

          .group-message-flex-alignment-1 {
            display: flex;
            justify-content: flex-end;
            margin: -26px 0 0 0;

            .group-message-profile-img-alignment-1 {
              width: 39.3px;
              height: 39.3px;
              min-width: 16px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
              }
            }
          }

          .group-message-flex-alignment-2 {
            display: flex;
            justify-content: center;

            .group-message-profile-img-alignment-2 {
              width: 49.13px;
              height: 49.13px;
              min-width: 20px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
              }
            }
          }

          .group-message-first-dot-alignment {
            position: absolute;
            top: 9px;
            right: 31px;

            .first-dot-width-alignment {
              width: 12.28px;
              height: 12.28px;
              background: var(--secondary-400);
              border-radius: 50%;
              // img {
              //   width: 100%;
              //   height: 100%;
              // }
            }
          }

          .group-message-second-dot-alignment {
            position: absolute;
            left: 2px;
            bottom: 32px;

            .second-dot-width-alignment {
              width: 14.74px;
              height: 14.74px;
              background: var(--secondary-800);
              border-radius: 50%;
            }
          }

          .group-message-third-dot-alignment {
            position: absolute;
            right: 9px;
            bottom: 29px;

            .third-dot-width-alignment {
              width: 4.91px;
              height: 4.91px;
              background: #636773;
              border-radius: 50%;
              // img {
              //   width: 100%;
              //   height: 100%;
              // }
            }
          }
        }

        h5 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 24px;
          line-height: 120%;
          color: var(--secondary-900);
          padding: 28px 0 0 0;
          text-align: center;
        }

        .location-details-alignment {
          padding: 4px 0 0 0;
          display: flex;
          align-items: center;
          gap: 4px;
          justify-content: center;

          img {
            width: 100px;
            height: 100px;
          }

          p {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 120%;
            color: #acb1c0;
          }
        }

        .visit-profile-button-alignment {
          text-align: center;
          padding: 14px 0 0 0;

          button {
            padding: 6px 16px;
            background: var(--primary-500);
            border-radius: 8px;
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            color: #ffffff;
            cursor: pointer;
            border: none;
          }
        }
      }
    }

    // .message-box-footer-alignment {
    //   border-top: 1px solid var(--secondary-100);
    //   border-radius: 0px 0px 10px 10px;
    //   padding: 16px 56px;
    //   display: flex;
    //   align-items: center;
    //   gap: 8px;

    //   .message-box-input-alignment {
    //     width: 100%;
    //     height: 44px;
    //     background: #fafafb;
    //     border: 1px solid #f1f1f5;
    //     border-radius: 10px;
    //     display: flex;
    //     align-items: center;

    //     input {
    //       width: 100%;
    //       height: 100%;
    //       padding: 0 16px;
    //       font-family: 'Poppins', sans-serif;
    //       font-weight: 400;
    //       font-size: 13px;
    //       line-height: 20px;
    //       color: #92929d;
    //       border-radius: 10px;
    //       background: transparent;
    //       border: none;
    //     }

    //     .message-input-other-option-alignment {
    //       display: flex;
    //       align-items: center;
    //       gap: 2px;

    //       .message-input-icon-alignment {
    //         width: 32px;
    //         height: 32px;

    //         img {
    //           max-width: 100%;
    //           height: 100%;
    //           cursor: pointer;
    //         }
    //       }
    //     }
    //   }

    //   .message-send-button-alignment {
    //     width: 44px;
    //     height: 44px;
    //     background: var(--primary-500);
    //     border-radius: 10px;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     cursor: pointer;
    //   }
    // }
  }
}

.typing-display-alignment {
  color: #666;
  font-size: 15px;
  font-style: italic;
  height: 24px;
}

.msgMarginSameUser {
  // margin-top: 0.2em;
  // margin-bottom: 0.2em;
}

.msgMarginDiffUser {
  margin: 16px 0 0 0;
}

.messageWrapper {
  .sentMsg {
    padding: 0.4em 1em;
    background-color: red;
    color: #fff;
    border-radius: 10;
    margin-left: auto;
    max-width: 88%;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;

    .msgtext {
      word-wrap: anywhere;
      margin-right: 0.5em;
    }

    .msgTime {
      font-size: 0.6em;
      margin-top: 0.3em;
    }
  }

  .receivedMsg {
    padding: 0.4em 1em;
    background-color: green;
    border-radius: 10;
    margin-right: auto;
    max-width: 88%;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;

    .msgtext {
      word-wrap: anywhere;
      margin-right: 0.5em;
    }

    .msgTime {
      font-size: 0.6em;
      margin-top: 0.3em;
    }
  }
}

.dateInfoWrapper {
  margin: 2em 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .infoText {
    margin: auto auto 1em auto;
    background-color: #f4f5f8;
    color: #182431;
    padding: 0.4em 0.8em;
    border-radius: 8px;
    // border: 1px solid #1e2432;
  }
}

.centerMessageClass {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .infoText {
    margin: auto auto 1em auto;
    background-color: #f4f5f8;
    color: #182431;
    padding: 0.4em 0.8em;
    border-radius: 8px;
    // border: 1px solid #1e2432;
  }
}

.images-icon-and-text-alignment {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0 24px 0;
  gap: 4px;

  span {
    display: block;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: var(--secondary-600);
  }

  .image-group {
    display: flex;
    align-items: center;
    gap: 0;

    img:first-child {
      position: relative;
      z-index: 99;
    }

    img:nth-child(2) {
      position: relative;
      z-index: 9;
    }

    img {
      margin-left: -10px;
      border: 1px solid #ffffff;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}

.invite-button-style {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    border: none;
    cursor: pointer;
    padding: 6px 16px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    background: var(--primary-500);
    border-radius: 8px;
    text-align: center;
    color: #ffffff;
  }
}

.decline-text-alignment {
  padding: 38px 0 0 0;

  span {
    display: block;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: var(--primary-500);
    cursor: pointer;
  }
}

.no-invites-text-heading {
  padding: 0 0 70px 0;

  p {
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    color: var(--secondary-900);

    span {
      color: var(--primary-500);

      font-weight: 600;
    }
  }
}



.uploaded-image-alignment {
  background: #EEF1FD;
  width: 100%;
  height: 77px;
  margin-top: -70px;
  z-index: 999999;
  position: absolute;

  .uploaded-image-box {
    padding: 8px 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;


    .uploaded-image-name {
      font-size: 13px;
      line-height: 21px;
      color: #1E2432;
      margin-left: 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 200px;
    }

    .uploaded-image-close-icon {
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }

      &:active {
        opacity: 0.5;
      }
    }

    img {
      border-radius: 8px;
      width: 88px;
      height: 60px;
    }
  }
}

.message-box-footer-alignment {
  border-top: 1px solid var(--secondary-100);
  border-radius: 0px 0px 10px 10px;
  padding: 16px 56px;
  display: flex;
  align-items: center;
  gap: 8px;

  @media only screen and (max-width: '767px') {
    padding: 16px;
    border-top: none;
    position: sticky;
    bottom: 0;
    z-index: 999999;
  }

  .message-box-input-alignment {
    width: 100%;
    height: 44px;
    background: #fafafb;
    border: 1px solid #f1f1f5;
    border-radius: 10px;
    display: flex;
    align-items: center;

    input {
      width: 100%;
      height: 100%;
      padding: 0 16px;
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: #3c3c40;
      border-radius: 10px;
      background: transparent;
      border: none;
    }

    .message-input-other-option-alignment {
      display: flex;
      align-items: center;
      gap: 2px;

      .message-input-icon-alignment {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;

        .emoji-picker {
          z-index: 999999;
        }

        input[type='file'] {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          cursor: pointer;
        }

        img {
          max-width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }
    }
  }

  .message-send-button-alignment {
    width: 44px;
    max-width: 44px;
    min-width: 44px;
    height: 44px;
    background-color: #5C5CCF;
    border-radius: 10px;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media only screen and (max-width: '767px') {
      background: var(--primary-50);
      border-radius: 10px;

      svg {
        path {
          stroke: #556ee6;
        }
      }
    }
  }
}

.selectedEffect {
  position: relative;
  z-index: 99999999 !important;
  transform: scale(1.05);
  transition: 1s;
  margin: 0 8px 0 0;
}

.closeEffect {
  transform: scale(1);
  transition: 1s;
  margin: 0;
}



.right-message-box-alignment {
  position: relative;

  .reacted-details-sc {
    background: #ffffff;
    border-radius: 12px;
    padding: 4px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    position: absolute;
    right: 32px;
    bottom: -12px;

    .reated-details-flex-alignment {
      display: flex;
      align-items: center;
      gap: 2px;

      .reacted-icon-alignment {
        cursor: pointer;
        width: 16px;
        height: 16px;

        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
  }
}