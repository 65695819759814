.mobileViewStockReportAlignment {
    display: none;
    background: white;

    @media only screen and (max-width:"767px") {
        display: block;
    }
}

.mobileStockReportModalOptionOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(30, 36, 50, 0.8);
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    z-index: 9999 !important;
    display: none;

    @media only screen and (max-width:"1099px") {
        display: block;
    }
}

.mobileStockReportModalOptionOverlay.open {
    opacity: 1;
    visibility: visible;

    @media only screen and (max-width:"1099px") {
        display: block;
    }
}


.mobileStockReportModalDetailsAlignment {
    .mobileViewHeadingAlignment {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 16px;
        border-bottom: 2px solid var(--secondary-50);

        .mobileBackIconAlignment {}

        h4 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            color: var(--secondary-900);
            width: 100%;
            text-align: center;
        }
    }

    .mobileViewSearchAlignment {
        padding: 16px 16px 0 16px;

        .mobileViewSearchBoxAlignment {
            background: #F8F8F8;
            border-radius: 10px;
            height: 46px;
            width: 100%;
            display: flex;
            align-items: center;
            gap: 16px;

            .mobileViewSearchIcon {}

            input {
                border: none;
                background: transparent;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                line-height: 24px;

                &::placeholder {

                    color: var(--secondary-500);
                }
            }
        }
    }

    .mobileOptionDetailsAlignment {
        padding: 16px 0 16px 16px;

        ul {
            display: flex;
            align-items: center;
            gap: 16px;
            width: 100%;
            overflow-x: auto;

            li {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 15px;
                line-height: 20px;
                color: var(--secondary-500);
                padding: 6px 16px;
                background: var(--secondary-50);
                border-radius: 8px;
                white-space: nowrap;
            }
        }
    }

    .mobileViewDetailsMainAlignment {
        padding: 0 16px;
        height: calc(100vh - 178px);
        overflow-y: auto;

        .mobileDetailsAlignment {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin: 0 0 16px 0;



            .mobileViewStockReportProductAlignment {
                display: flex;
                align-items: center;
                gap: 8px;

                .mobileViewStockImgAlignment {
                    width: 80px;
                    height: 80px;

                    img {
                        width: 100%;
                        height: 100%;
                        display: block;
                        border-radius: 8px;
                        object-fit: cover;
                    }
                }

                .mobileViewStockDetailsAlignment {
                    h6 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 20px;
                        color: var(--secondary-900);
                        max-width: 186px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    p {
                        padding: 2px 0 0 0;
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 20px;
                        color: var(--secondary-500);
                    }

                    .mobileViewStockAllDetailsNumberFlex {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        padding: 4px 0 0 0;

                        .mobileViewStockAllDetailsStockAlignmentBox {
                            background: #E8F3E6;
                            border-radius: 16px;
                            padding: 3px 10px;

                            &.lowStock {
                                background: #FFF2E9;

                                span {
                                    color: #E87220;
                                }
                            }

                            &.outOfStock {
                                background: #FDE8E8;

                                span {
                                    color: #E71616;
                                }
                            }

                            span {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 18px;
                                color: #1D8603;
                                display: block;
                            }
                        }

                        p {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 18px;
                            color: var(--secondary-500);
                        }
                    }
                }
            }

            .mobileViewStockReportPriceAlignment {
                h5 {
                    font-family: 'Poppins', sans-serif;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: 20px;
                    color: var(--secondary-900);
                }
            }
        }
    }
}


.mobileStockReportModalOptionModal.open {
    translate: 0;
    z-index: 9999999;
    transform: translate(0, 0);

    @media only screen and (max-width:"1099px") {
        display: block;
    }
}