.login-wrapper {
  // position: relative;
  // background-image: url('https://images.unsplash.com/photo-1534452203293-494d7ddbf7e0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1052&q=80');
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center center;
  // height:100%;
  // padding: 120px 0px;
  // &:after {
  //   background: #793FE1;  /* fallback for old browsers */
  //   background: -webkit-linear-gradient(to left, #793FE1, #556EE6);  /* Chrome 10-25, Safari 5.1-6 */
  //   background: linear-gradient(to left, #793FE1, #556EE6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  //   position: absolute;
  //   content: '';
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   z-index: 0;
  //   opacity: 0.9;
  // }
  padding: 0;
  margin: 0;

  .login-inner-wrapper {
    position: relative;
    z-index: 1;
    // background-color: #F0F3FF;
    // max-width: 1276px;
    margin: 0 auto;
    border-radius: 12px;
    overflow: hidden;
    height: 724px;
    padding: 0;
    @media only screen and (max-width: '1440px') {
      height: calc(100vh - 20px);
      overflow-y: auto;
    }
    @media only screen and (max-width: '1199px') {
      height: 100vh;
      overflow-y: auto;
    }

    @media only screen and (max-width: '767px') {
      height: 100vh;
      overflow-y: auto;
      border-radius: 0;
    }

    .new-login-alignment {
      // display: grid;
      display: flex;
      grid-template-columns: 1fr 415px;
      height: 100%;

      @media only screen and (max-width: '767px') {
        grid-template-columns: 1fr;
      }

      .new-login-mobile-view-back {
        display: none;

        @media only screen and (max-width: '767px') {
          display: block;

          button {
            padding: 6px 9px 6px 16px;
            display: flex;
            align-items: center;
            gap: 8px;
            color: #808191;
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            cursor: pointer;
          }
        }
      }

      .new-login-mobile-View_logo {
        display: none;

        @media only screen and (max-width: '767px') {
          display: block;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 32px 0 0 0;
          width: 133px;
          height: auto;
          margin: 0 auto;
        }
      }

      .new-login-left-side-alignment {
        border-radius: 8px 0px 0px 8px;
        border: 2px solid #fff;
        background: var(--secondary-50);
        width: calc(100% - 415px);

        height: 100%;

        // @media only screen and (max-width:"1299px") {
        //   max-width: 712px;
        // }

        // @media only screen and (max-width:"1099px") {
        //   max-width: 576px;
        // }

        @media only screen and (max-width: '1099px') {
          display: none;
        }
      }

      .new-login-right-side-alignment {
        width: 415px;
        max-width: 415px;
        min-width: 415px;
        height: 100%;
        border-radius: 0px 8px 8px 0px;
        border: 2px solid #fff;
        background: #fff;
        padding: 56px 52px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media only screen and (max-width: '1099px') {
          margin: 0 auto;
        }

        @media only screen and (max-width: '767px') {
          padding: 8px 16px;
          border-radius: 0;
        }

        @media only screen and (max-width: '499px') {
          width: 100%;
          max-width: 100%;
          min-width: 100%;
        }

        .new-login-form-alignment {
          .new-login-form-heading {
            h4 {
              color: #000;
              font-family: 'Poppins', sans-serif;
              font-size: 32px;
              font-weight: 600;
              line-height: 48px;
              text-align: center;

              @media only screen and (max-width: '767px') {
                display: none;
              }
            }
          }

          .new-login-form-input-alignment {
            padding: 50px 0 0 0;

            @media only screen and (max-width: '767px') {
              padding: 42px 16px 0 16px;
            }

            .new-login-form-input {
              margin: 0 0 8px 0;
              width: 100%;
              height: 40px;
              position: relative;

              &.input-error-input {
                input {
                  border-radius: 10px;
                  background: var(--red-50);

                  &:focus {
                    border: 1px solid var(--red-500);
                  }
                }

                .new-login-icon {
                  svg {
                    g {
                      path {
                        fill: var(--red-500);
                      }
                    }

                    path {
                      fill: var(--red-500);
                      stroke: var(--red-500);
                    }
                  }
                }
              }

              input {
                padding: 10px;
                width: 100%;
                height: 100%;
                border-radius: 10px;
                background: var(--secondary-50);
                border: 1px solid var(--secondary-50);
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                padding: 0 10px 0 38px;
                color: var(--primary-900);
                transition: 0.4s ease-in-out;

                &.password-show-input {
                  padding: 0 38px;
                }

                &::placeholder {
                  color: var(--secondary-300);
                }

                &:focus {
                  border: 1px solid var(--primary-500);
                  transition: 0.4s ease-in-out;
                }
              }

              &:last-child {
                margin: 0;
              }

              .new-login-icon {
                position: absolute;
                top: 10px;
                left: 10px;
                z-index: 999;
              }

              .password-show-icon {
                position: absolute;
                top: 10px;
                right: 10px;
                z-index: 999;

                svg {
                  cursor: pointer;
                }
              }
            }

            .remeber-login-alignment {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 10px 0 0 0;

              @media only screen and (max-width: '767px') {
                padding: 8px 0 0 0;
              }

              .remeber-input-alignment {
                display: flex;
                align-items: center;
                gap: 8px;

                input[type='checkbox'] {
                  width: 18px;
                  height: 18px;
                }

                label {
                  color: #3c3c3c;
                  font-family: 'Poppins', sans-serif;
                  font-size: 13px;
                  font-weight: 400;
                  line-height: 18px;
                  padding: 0;
                  margin: 0;
                }
              }

              .forgot-password-alignment {
                a {
                  padding: 0 10px;
                  color: var(--primary-500);
                  text-align: center;
                  font-family: 'Poppins', sans-serif;
                  font-size: 13px;
                  font-weight: 500;
                  line-height: 19px;
                  cursor: pointer;

                  @media only screen and (max-width: '767px') {
                    padding: 2px 10px;
                  }
                }
              }
            }

            .login-button-alignment {
              padding: 18px 0 0 0;

              button {
                border-radius: 10px;
                background: var(--primary-500);
                padding: 6px 16px;
                color: #fff;
                font-family: 'Poppins', sans-serif;
                font-size: 18px;
                font-weight: 500;
                line-height: 20px;
                cursor: pointer;
                width: 100%;
                height: 44px;
              }
            }
          }

          .login-with-section {
            padding: 90px 0 0 0;
            text-align: center;

            &.incorrect-login-message {
              padding: 32px 0 0 0;

              .login-error-message-section {
                display: block;
              }
            }

            .login-error-message-section {
              padding: 0 0 39px 0;
              display: none;

              p {
                color: var(--red-500);
                font-family: 'Poppins', sans-serif;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }

            h6 {
              color: #3c3c3c;
              font-family: 'Poppins', sans-serif;
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
            }

            .login-with-option-section {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 32px;
              padding: 24px 0 0 0;

              .with-option-icon {
                svg {
                  cursor: pointer;
                }
              }
            }
          }

          .login-dont-account-section {
            padding: 120px 0 0 0;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;

            @media only screen and (max-width: '1199px') {
              padding: 60px 0 0 0;
            }

            @media only screen and (max-width: '767px') {
              padding: 70px 0 48px 0;
            }

            p {
              color: #3c3c3c;
              font-family: 'Poppins', sans-serif;
              font-size: 15px;
              font-weight: 400;
              line-height: 22px;
            }

            a {
              color: var(--primary-500);
              font-family: 'Poppins', sans-serif;
              font-size: 15px;
              font-weight: 500;
              line-height: 22px;
              cursor: pointer;
            }
          }
        }

        .new-register-form-alignment {
          .new-register-mobile-view-back {
            display: none;

            @media only screen and (max-width: '767px') {
              display: block;

              button {
                padding: 6px 9px 6px 16px;
                display: flex;
                align-items: center;
                gap: 8px;
                color: #808191;
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                cursor: pointer;
              }
            }
          }

          .new-register-logo-alignment {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 35px 0 15px 0 !important ;
            width: 173px !important;
            height: auto !important;
            margin: 0 auto !important;

            @media only screen and (max-width: '767px') {
              padding: 32px 0 15px 0 !important;
              width: 133px !important;
              height: auto !important;
              margin: 0 auto !important;
            }
          }

          .new-register-form-details-alignment {
            padding: 30px 0 0 0;

            @media only screen and (max-width: '767px') {
              padding: 14px 16px 0 16px;
            }

            .new-register-form-input {
              margin: 0 0 8px 0;
              width: 100%;
              height: 40px;
              position: relative;

              &.input-error-input {
                input {
                  border-radius: 10px;
                  background: var(--red-50);

                  &:focus {
                    border: 1px solid var(--red-500);
                  }
                }

                .new-register-icon {
                  svg {
                    g {
                      path {
                        fill: var(--red-500);
                      }
                    }

                    path {
                      fill: var(--red-500);
                      stroke: var(--red-500);
                    }
                  }
                }

                .wrong-fill-tooltip-box-alignment {
                  display: flex;
                }
              }

              input {
                padding: 10px;
                width: 100%;
                height: 100%;
                border-radius: 10px;
                background: var(--secondary-50);
                border: 1px solid var(--secondary-50);
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                padding: 0 16px;
                color: var(--primary-900);
                transition: 0.4s ease-in-out;

                &.password-show-input {
                  padding: 0 38px;
                }

                &::placeholder {
                  color: var(--secondary-300);
                }

                &:focus {
                  border: 1px solid var(--primary-500);
                  transition: 0.4s ease-in-out;
                }
              }

              &:last-child {
                margin: 0;
              }

              .new-register-icon {
                position: absolute;
                top: 10px;
                left: 10px;
              }

              .password-show-icon {
                position: absolute;
                top: 10px;
                right: 10px;

                svg {
                  cursor: pointer;
                }
              }

              .register-new-create-password-icon {
                position: absolute;
                top: 10px;
                right: 10px;
                display: flex;
                align-items: center;
                gap: 16px;

                .right-sign-icon {
                  svg {
                    display: none;
                  }
                }

                svg {
                  cursor: pointer;
                }
              }

              &.register-show-input-password-icon {
                input {
                  padding: 0 38px 0 16px;
                }
              }

              &.register-confirm-condtion {
                input {
                  padding: 10px 66px 10px 16px;
                }

                .register-new-create-password-icon {
                  .register-right-sign-icon {
                    svg {
                      display: block;
                    }
                  }
                }
              }

              .wrong-fill-tooltip-box-alignment {
                position: absolute;
                width: 100%;
                top: 44px;
                left: 0;
                height: 44px;
                border-radius: 10px;
                background: #fff;
                box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
                z-index: 9;
                display: flex;
                align-items: center;
                gap: 8px;
                padding: 0 12px;
                display: none;

                p {
                  color: var(--red-500);
                  font-family: 'Poppins', sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }

              .new-register-password-box-alignment {
                border-radius: 10px;
                background: #fff;
                box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
                width: 100%;
                height: 200px;
                position: absolute;
                top: 44px;
                left: 0;
                z-index: 99;
                padding: 16px;

                .new-register-password-box-heading {
                  h6 {
                    color: var(--secondary-900);
                    font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                  }
                }

                .new-register-password-box-lines-alignment {
                  display: grid;
                  grid-template-columns: repeat(3, 1fr);
                  gap: 4px;
                  width: 100%;
                  padding: 16px 0 0 0;

                  .new-register-box-lines {
                    width: 100%;
                    height: 3px;
                    background: var(--secondary-50);

                    &.new-register-box-lines-active {
                      background: #ffd02c;
                    }
                  }
                }

                .new-register-password-box-conditions {
                  padding: 16px 0 0 0;

                  .new-register-password-box-all-conditins-details {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    margin: 0 0 16px 0;

                    &:last-child {
                      margin: 0;
                    }

                    p {
                      color: var(--secondary-900);
                      font-family: 'Poppins', sans-serif;
                      font-size: 12px;
                      font-weight: 400;
                      line-height: 14px;
                    }
                  }
                }
              }
            }

            .new-register-form-terms-and-condition-alignment {
              padding: 10px 0 0 0;
              display: flex;
              align-items: flex-start;
              gap: 8px;

              .new-register-checkbox-alignment {
                width: 18px;
                height: 18px;

                input[type='checkbox'] {
                  width: 100%;
                  height: 100%;
                  margin: 0;
                  padding: 0;
                }
              }

              p {
                color: var(--secondary-900);
                font-family: 'Poppins', sans-serif;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                a {
                  color: var(--primary-500);
                  cursor: pointer;
                }
              }
            }

            .new-register-create-button {
              padding: 16px 0 0 0;

              button {
                width: 100%;
                height: 44px;
                color: #fff;
                font-family: 'Poppins', sans-serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                border-radius: 10px;
                background: var(--primary-500);
                cursor: pointer;
                border: none;

                &.disable-button {
                  background: var(--primary-100);
                }
              }
            }

            .new-register-login-with-section {
              padding: 24px 0 0 0;
              text-align: center;

              h6 {
                color: #3c3c3c;
                font-family: 'Poppins', sans-serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }

              .all-otions-login-alignmrent {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 32px;
                padding: 24px 0 0 0;

                .register-with-option-icon {
                  svg {
                    cursor: pointer;
                  }
                }
              }
            }

            .new-register-login-option-alignment {
              padding: 24px 0 0 0;

              p {
                color: #3c3c3c;
                font-family: 'Poppins', sans-serif;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                display: inline-block;
              }

              a {
                color: var(--primary-500);
                font-family: 'Poppins', sans-serif;
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                display: inline-block;
                padding: 0 0 0 4px;
              }
            }
          }
        }

        .new-register-sueccess-details-alignment {
          padding: 52px 0 0 0;

          .new-register-sueccess-right-sign-alignment {
            display: flex;
            align-items: center;
            justify-content: center;

            @media only screen and (max-width: '767px') {
              padding: 104px 0 0 0;
            }
          }

          .new-register-sueccess-congratulation {
            padding: 24px 0 0 0;

            h4 {
              color: #000;
              text-align: center;
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              line-height: 30px;
              text-align: center;
            }
          }

          .new-register-sueccess-ualoded-heading-alignment {
            // h4 {
            //   color: #000;
            //   text-align: center;
            //   font-family: 'Poppins', sans-serif;
            //   font-size: 20px;
            //   font-weight: 500;
            //   line-height: 24px;
            //   padding: 44px 0 0 0;
            // }

            p {
              padding: 38px 0 0 0;
              color: #000;
              text-align: center;
              font-family: 'Poppins', sans-serif;
              font-size: 13px;
              font-weight: 400;
              line-height: 19px;

              @media only screen and (max-width: '767px') {
                padding: 32px 0 0 0;
                max-width: 198px;
                margin: 0 auto;
              }

              a {
                color: #556ee6;
              }
            }
          }

          .new-register-sueccess-back-to-login-button-alignment {
            padding: 76px 0 0 0;
            text-align: center;

            @media only screen and (max-width: '767px') {
              position: fixed;
              bottom: 32px;
              right: 32px;
              left: 32px;
            }

            button {
              padding: 6px 16px;
              height: 44px;
              color: #fff;
              font-family: 'Poppins', sans-serif;
              font-size: 18px;
              font-weight: 500;
              line-height: 20px;
              width: 195px;
              cursor: pointer;
              border-radius: 10px;
              background: var(--primary-500);

              @media only screen and (max-width: '767px') {
                width: 100%;
              }
            }
          }
        }

        .ant-modal {
          // @media only screen and (max-width:"1499px") {
          top: 50% !important;
          padding: 0 !important;
          transform: translateY(-50%) !important;
          // }

          @media only screen and (max-width: '767px') {
            top: 0 !important;
            padding: 0 !important;
            margin: 0;
            max-width: 100%;
          }
        }
      }

      .new-forgot-password-right-side-alignment {
        padding: 161px 52px 141px 52px;
        margin: 0 auto;
        width: 415px;
        max-width: 415px;
        min-width: 415px;

        @media only screen and (max-width: '999px') {
          margin: 0 auto;
        }

        @media only screen and (max-width: '767px') {
          padding: 8px 16px;
          border-radius: 0;
        }

        @media only screen and (max-width: '499px') {
          width: 100%;
          max-width: 100%;
          border-radius: 0;
          min-width: 100%;
        }

        .new-forgot-password-details-alignment {
          @media only screen and (max-width: '767px') {
            padding: 46px 32px;
          }

          .new-forgot-password-heading {
            h4 {
              color: var(--black);
              text-align: center;
              font-family: 'Poppins', sans-serif;
              font-size: 20px;
              font-weight: 500;
              line-height: 30px;
            }

            p {
              color: #000;
              text-align: center;
              font-family: 'Poppins', sans-serif;
              font-size: 13px;
              font-weight: 400;
              line-height: 19px;
              padding: 32px 0 0 0;
            }
          }

          .new-forgot-input-alignment {
            margin: 80px 0 0 0;
            width: 100%;
            height: 40px;

            input {
              width: 100%;
              height: 100%;
              border-radius: 10px;
              background: var(--secondary-50);
              padding: 0 16px;
              font-family: 'Poppins', sans-serif;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              color: var(--secondary-900);
              border: 1px solid var(--secondary-50);
              transition: 0.4s ease-in-out;

              &::placeholder {
                color: var(--secondary-300);
              }

              &:focus {
                border-radius: 10px;
                border: 1px solid var(--primary-500);
                background: var(--secondary-50);
                transition: 0.4s ease-in-out;
              }
            }
          }

          .danger-input {
            input {
              width: 100%;
              height: 100%;
              border-radius: 10px;
              background: var(--red-50);
              padding: 0 16px;
              font-family: 'Poppins', sans-serif;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              color: var(--secondary-900);
              border: 1px solid var(--secondary-50);
              transition: 0.4s ease-in-out;

              &::placeholder {
                color: var(--secondary-300);
              }

              &:focus {
                border-radius: 10px;
                background: var(--red-50);
                transition: 0.4s ease-in-out;
              }
            }
          }

          .new-forgot-button-alignment {
            margin: 80px 0 0 0;

            @media only screen and (max-width: '767px') {
              position: fixed;
              width: 100%;
              bottom: 32px;
              left: 0;
              right: 0;
              padding: 0 32px;
            }

            .new-forgot-submit-button {
              width: 100%;
              height: 44px;
              border-radius: 10px;
              background: var(--primary-500);

              button {
                width: 100%;
                height: 100%;
                color: #fff;
                font-family: 'Poppins', sans-serif;
                font-size: 18px;
                font-weight: 500;
                line-height: 20px;
                cursor: pointer;
              }
            }

            .new-forgot-cancle-button {
              margin: 32px 0 0 0;
              width: 100%;
              height: 44px;

              button {
                width: 100%;
                height: 100%;
                color: var(--primary-500);
                font-family: 'Poppins', sans-serif;
                font-size: 18px;
                font-weight: 500;
                line-height: 20px;
                cursor: pointer;
              }
            }
          }
        }

        .new-verify-code-alignment {
          @media only screen and (max-width: '767px') {
            padding: 46px 32px;
          }

          .new-verify-code-heding-alignment {
            text-align: center;

            h4 {
              color: var(--secondary-900);
              text-align: center;
              font-family: 'Poppins', sans-serif;
              font-size: 20px;
              font-weight: 500;
              line-height: 24px;
            }

            p {
              padding: 32px 0 0 0;
              color: var(--secondary-900);
              text-align: center;
              font-family: 'Poppins', sans-serif;
              font-size: 13px;
              font-weight: 400;
              line-height: 18px;
            }

            span {
              display: block;
              padding: 8px 0 0 0;
              color: var(--secondary-900);
              text-align: center;
              font-family: 'Poppins', sans-serif;
              font-size: 13px;
              font-weight: 500;
              line-height: 18px;

              @media only screen and (max-width: '767px') {
                padding: 0;
                font-weight: 600;
              }
            }
          }

          .new-verify-code-input-alignment {
            padding: 56px 0 0 0;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;

            @media only screen and (max-width: '767px') {
              padding: 48px 0 0 0;
            }

            .new-verify-code-input {
              width: 114px;
              height: 56px;
              border-radius: 8px;
              background: var(--light-grey);

              &.success-input {
                background: var(--green-50);
                border-radius: 8px;

                input {
                  color: #1d8603;
                }
              }

              &.danger-input {
                background: var(--red-50);
                border-radius: 8px;

                input {
                  color: var(--red-500);
                }
              }

              input {
                width: 100%;
                height: 100%;
                background: transparent;
                border: none;
                text-align: center;
                color: var(--secondary-900);
                text-align: center;
                font-family: 'Poppins', sans-serif;
                font-size: 24px;
                font-weight: 600;
                line-height: 28px;
                border-radius: 8px;
                border: 1px solid transparent;
                transition: 0.4s ease-in-out;
              }
            }
          }

          .new-verify-code-option-alignment {
            padding: 90px 0 0 0;

            &.incorrect-code-alignment {
              padding: 16px 0 0 0;

              .incorrect-notes-alignment {
                display: block;
                padding: 0 0 54px 0;
              }
            }

            .incorrect-notes-alignment {
              display: none;

              p {
                color: var(--red-500);
                text-align: center;
                font-family: 'Poppins', sans-serif;
                font-size: 13px;
                font-weight: 400;
                line-height: 19px;
              }
            }

            .new-verify-mobile-view {
              @media only screen and (max-width: '767px') {
                position: fixed;
                width: 100%;
                bottom: 32px;
                left: 0;
                right: 0;
              }

              .resend-code-alignment {
                text-align: center;

                a {
                  padding: 2px 10px;
                  color: var(--primary-500);
                  text-align: center;
                  font-family: 'Poppins', sans-serif;
                  font-size: 13px;
                  font-weight: 500;
                  line-height: 19px;
                  cursor: pointer;
                }
              }

              .back-button-alignment {
                width: 100%;
                height: 44px;
                margin: 42px 0 0 0;

                button {
                  width: 100%;
                  height: 100%;
                  color: var(--primary-500);
                  font-family: 'Poppins', sans-serif;
                  font-size: 18px;
                  font-weight: 500;
                  line-height: 20px;
                  cursor: pointer;
                }
              }
            }
          }
        }

        .new-create-password-alignment {
          @media only screen and (max-width: '767px') {
            padding: 46px 32px;
          }

          .new-create-heding-alignment {
            h4 {
              color: #000;
              text-align: center;
              font-family: 'Poppins', sans-serif;
              font-size: 20px;
              font-weight: 500;
              line-height: 30px;
            }
          }

          .new-create-body-alignment {
            padding: 78px 0 00;

            .new-create-input-alignment {
              margin: 0 0 8px 0;
              width: 100%;
              height: 40px;
              position: relative;

              &.confirm-condtion {
                input {
                  padding: 10px 66px 10px 16px;
                }

                .new-create-password-icon {
                  .right-sign-icon {
                    svg {
                      display: block;
                    }
                  }
                }
              }

              &.wrong-password-input {
                input {
                  border-radius: 10px;
                  background: var(--red-50);

                  &:focus {
                    border-radius: 10px;
                    border: 1px solid var(--red-50);
                    background: var(--red-50);
                    transition: 0.4s ease-in-out;
                  }
                }
              }

              &:last-child {
                margin: 0;
              }

              input {
                width: 100%;
                height: 100%;
                border-radius: 10px;
                background: var(--secondary-50);
                padding: 10px 38px 10px 16px;
                font-family: 'Poppins', sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                color: var(--secondary-900);
                transition: 0.4s ease-in-out;
                border: 1px solid var(--secondary-50);

                &::placeholder {
                  color: var(--secondary-300);
                }

                &:focus {
                  border-radius: 10px;
                  border: 1px solid var(--primary-500);
                  background: var(--secondary-50);
                  transition: 0.4s ease-in-out;
                }
              }

              .new-create-password-icon {
                position: absolute;
                top: 10px;
                right: 10px;
                display: flex;
                align-items: center;
                gap: 16px;

                .right-sign-icon {
                  svg {
                    display: none;
                  }
                }

                svg {
                  cursor: pointer;
                }
              }

              .password-box-alignment {
                border-radius: 10px;
                background: #fff;
                box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.16);
                width: 100%;
                height: 200px;
                position: absolute;
                top: 44px;
                left: 0;
                z-index: 99;
                padding: 16px;

                .password-box-heading {
                  h6 {
                    color: var(--secondary-900);
                    font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                  }
                }

                .password-box-lines-alignment {
                  display: grid;
                  grid-template-columns: repeat(3, 1fr);
                  gap: 4px;
                  width: 100%;
                  padding: 16px 0 0 0;

                  .box-lines {
                    width: 100%;
                    height: 3px;
                    background: var(--secondary-50);

                    &.box-lines-active {
                      background: #ffd02c;
                    }
                  }
                }

                .password-box-conditions {
                  padding: 16px 0 0 0;

                  .password-box-all-conditins-details {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    margin: 0 0 16px 0;

                    &:last-child {
                      margin: 0;
                    }

                    p {
                      color: var(--secondary-900);
                      font-family: 'Poppins', sans-serif;
                      font-size: 12px;
                      font-weight: 400;
                      line-height: 14px;
                    }
                  }
                }
              }
            }
          }

          .new-create-paaword-button-alignment {
            padding: 58px 0 0 0;

            @media only screen and (max-width: '767px') {
              position: fixed;
              bottom: 32px;
              right: 32px;
              left: 32px;
            }

            button {
              width: 100%;
              border-radius: 10px;
              background: var(--primary-500);
              height: 44px;
              color: #fff;
              font-family: 'Poppins', sans-serif;
              font-size: 18px;
              font-weight: 500;
              line-height: 20px;
              cursor: pointer;

              &.disable-button {
                background: var(--primary-100);
              }
            }
          }
        }

        .password-uploaded-details-alignment {
          .password-right-sign-alignment {
            display: flex;
            align-items: center;
            justify-content: center;

            @media only screen and (max-width: '767px') {
              padding: 104px 0 0 0;
            }
          }

          .password-congratulation {
            h4 {
              background: var(--primary-bluejestic-gradient, linear-gradient(93deg, #556ee6 0%, #6b21a8 100%));
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              text-align: center;
              font-family: 'Poppins', sans-serif;
              font-size: 32px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
          }

          .password-ualoded-heading-alignment {
            h4 {
              color: #000;
              text-align: center;
              font-family: 'Poppins', sans-serif;
              font-size: 20px;
              font-weight: 500;
              line-height: 24px;
              padding: 32px 0 0 0;
            }

            p {
              padding: 22px 0 0 0;
              color: #000;
              text-align: center;
              font-family: 'Poppins', sans-serif;
              font-size: 13px;
              font-weight: 400;
              line-height: 19px;

              @media only screen and (max-width: '767px') {
                padding: 32px 0 0 0;
                max-width: 198px;
                margin: 0 auto;
              }
            }
          }

          .back-to-login-button-alignment {
            padding: 132px 0 0 0;
            text-align: center;

            @media only screen and (max-width: '767px') {
              position: fixed;
              bottom: 32px;
              right: 32px;
              left: 32px;
            }

            button {
              padding: 6px 16px;
              height: 44px;
              color: #fff;
              font-family: 'Poppins', sans-serif;
              font-size: 18px;
              font-weight: 500;
              line-height: 20px;
              cursor: pointer;
              border-radius: 10px;
              background: var(--primary-500);

              @media only screen and (max-width: '767px') {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .login-form-wrapper {
      // padding: 60px 40px;
      background-color: #fff;

      .logo-wrapper {
        margin-bottom: 50px;
      }

      .page-title-wrapper {
        h4 {
          font-weight: 700 !important;
          font-size: 28px;
          line-height: 42px;
          color: var(--secondary-900);
        }

        p {
          font-weight: 500 !important;
          font-size: 20px;
          line-height: 30px;
          color: #828282;
          margin-bottom: 30px;
        }
      }

      .social-login-wrapper {
        margin-bottom: 20px;

        ul {
          display: flex;
          align-items: center;
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            margin-right: 15px;

            a {
              width: 114px;
              height: 44px;
              border-radius: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              text-decoration: none;
              color: #fff;
              font-weight: 600 !important;
              font-size: 12px;
              line-height: 18px;

              &.twitter {
                background-color: #4fa8d7;
              }

              &.google {
                background-color: #cc5542;
              }

              &.facebook {
                background-color: #3e579b;
              }
            }
          }
        }
      }

      .muted-text {
        text-align: center;
        margin-bottom: 20px;

        p {
          font-size: 14px;
          line-height: 21px;
          color: #4f4f4f;
        }

        .or {
          font-weight: 500 !important;
          font-size: 16px;
          line-height: 24px;
          color: #898a8d;
        }
      }

      .form-wrapper {
        .form-group {
          margin-top: 22px;
          position: relative;
          margin-bottom: 5px;

          img {
            position: absolute;
            top: 10px;
            left: 10px;
            z-index: 1;
          }

          input {
            background: var(--secondary-50);
            border: 1px solid #e7e3e3;
            border-radius: 10px;
            height: 44px;
            color: #495057;
            padding-left: 40px;
            width: 100%;
            border: 1px solid var(--secondary-50);
            transition: 0.4s ease-in-out;

            &:focus {
              outline: 0;
              box-shadow: none;
              transition: 0.4s ease-in-out;
            }
          }

          ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #495057;
            opacity: 1;
            /* Firefox */
          }

          :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #495057;
          }

          ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #495057;
          }
        }

        .remember {
          display: flex;
          justify-content: space-between;
          align-items: center;

          span,
          a {
            font-size: 13px;
            line-height: 19px;
            color: #4f4f4f;
            text-decoration: none;

            b {
              font-weight: 700 !important;
            }
          }
        }

        .btn {
          display: block;
          margin-top: 50px;
          width: 100%;
          height: 44px;
          background: #5f40af;
          border-radius: 15px;

          a {
            color: #fff;
            font-weight: 700 !important;
            text-decoration: none;
            font-size: 16px;
            line-height: 24px;
          }

          &:hover {
            border-color: var(--primary-500);
            background-color: #556ee6;
          }

          &:focus {
            border-color: var(--primary-500);
            background-color: #556ee6;
          }
        }
      }

      .already {
        margin-top: 60px;
        text-align: center;
        color: #000000;

        a {
          color: #ff7d23;
          text-decoration: underline;
        }
      }
    }

    .login-slider-wrapper {
      border-radius: 8px 0px 0px 8px;
      // border: 2px solid #FFF;
      background: #e9ebf2;
      height: 100%;
      padding: 54px 0 0 0;

      .item {
        text-align: center;

        img {
          margin: 0 auto;
          margin-bottom: 30px;
          height: 450px;
        }

        .video-details-alignment {
          max-width: 100%;
          height: 400px;
          margin: 0 auto;

          video {
            width: 100%;
            height: 100%;
            object-fit: contain !important;
          }
        }

        .slide-details {
          padding: 50px 0 0 0;

          h4 {
            color: #000;
            text-align: center;
            font-family: 'Poppins', sans-serif;
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
          }

          p {
            color: var(--secondary-500);
            text-align: center;
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            padding: 16px 0 0 0;
            max-width: 494px;
            margin: 0 auto;
          }
        }
      }

      .slick-dots {
        right: -50px;
        top: 106% !important;

        li {
          button {
            width: 8px;
            height: 8px;
            background-color: #fff;
            border-radius: 8px;
            margin: 0;
          }

          button:before {
            font-size: 0;
            background-color: #fff;
          }

          &.slick-active {
            width: 8px;

            button {
              background-color: var(--primary-500);
              width: 8px;

              &::before {
                width: 8px;
                background-color: var(--primary-500);
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1091px) {
  .login-wrapper {
    padding: 0;

    .login-inner-wrapper {
      border-radius: 0;
    }
  }
}

@media screen and (max-width: 479px) {
  .login-wrapper {
    .login-inner-wrapper {
      .login-form-wrapper {
        padding: 40px 20px;

        .social-login-wrapper {
          ul {
            flex-wrap: wrap;

            li {
              margin-bottom: 10px;
              margin-right: 10px;
            }
          }
        }
      }

      .login-slider-wrapper {
        .item {
          .slide-details {
            h4 {
              font-size: 18px;
              line-height: 28px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1091px) {
  .login-wrapper {
    padding: 0;

    .login-inner-wrapper {
      border-radius: 0;
    }
  }
}

@media screen and (max-width: 479px) {
  .login-wrapper {
    .login-inner-wrapper {
      .login-form-wrapper {
        padding: 0px 0px;

        .social-login-wrapper {
          ul {
            flex-wrap: wrap;

            li {
              margin-bottom: 10px;
              margin-right: 5px;
            }
          }
        }
      }

      .login-slider-wrapper {
        .item {
          .slide-details {
            h4 {
              font-size: 18px;
              line-height: 28px;
            }
          }
        }
      }
    }
  }
}

.login-modal {
  // @media only screen and (max-width:"1499px") {
  top: 50% !important;
  padding: 0 !important;
  transform: translateY(-50%) !important;
  transition: 0.3s;

  // }
  @media only screen and (max-width: '1299px') {
  }

  max-width: calc(100vw - 132px) !important;

  // @media only screen and (max-width:"1024px") {
  //   top: 40px !important;
  //   transform: translateY(0) !important;
  // }

  @media only screen and (max-width: '767px') {
    top: 0 !important;
    padding: 0 !important;
    margin: 0;
    max-width: 100% !important;
    transform: translateY(0) !important;
  }

  .ant-modal {
    // @media only screen and (max-width:"1499px") {
    top: 50% !important;
    padding: 0 !important;
    transform: translateY(-50%) !important;

    // }
    @media only screen and (max-width: '1099px') {
      max-width: 576px;
    }

    @media only screen and (max-width: '767px') {
      top: 0 !important;
      padding: 0 !important;
      margin: 0;
      max-width: 100%;
    }
  }

  .ant-modal-content {
    @media only screen and (max-width: '767px') {
      border-radius: 0px !important;
    }

    .ant-modal-body {
      padding: 0 !important;
    }
  }
}

// .ant-modal {

//   // @media only screen and (max-width:"1499px")
//   padding: 0 !important;

//   @media only screen and (max-width:"767px") {
//     top: 0 !important;
//     padding: 0 !important;
//     margin: 0;
//     max-width: 100%;
//   }
// }

.mobile-modal-logo {
  display: none;

  @media only screen and (max-width: '767px') {
    display: block;
    width: 78px;
    height: auto;
    margin: 0 auto;
    padding: 48px 0 0 0;
  }
}

@keyframes fade-animation {
  0% {
    transform: translate(-3%, 0);
    // transition: .3s ease-in-out;
  }

  // 25% {
  //   transform: translate(0%, 0);
  //   // transition: .3s ease-in-out;
  // }

  // 50% {
  //   transform: translate(-3%, 0);
  //   // transition: .3s ease-in-out;
  // }

  100% {
    transform: translate(0%, 0%);
    // transition: .3s ease-in-out;
  }
}

.animation-button-class-name {
  animation: fade-animation 0.3s;
}
