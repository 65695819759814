.book-related-search-section {
  padding: 48px 0;
  background: #fff;
  @media only screen and (max-width: '767px') {
    padding: 0;
  }
  .container {
    max-width: 1440px;
    margin: auto;
    padding: 0;

    .book-related-search-heading {
      text-align: center;
      padding: 0 0 6px 0;

      @media only screen and (max-width: '767px') {
        padding: 0 0 0 0;
      }

      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        font-size: 36px;
        line-height: 54px;
        color: var(--secondary-900);

        @media only screen and (max-width: '767px') {
          font-size: 20px;
          line-height: 30px;
        }
      }
    }
  }
}

.book-related-padding-alignment {
  padding: 0 30px;

  @media only screen and (max-width: '767px') {
    padding: 16px 0;
  }
}
