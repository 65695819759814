// .Invitefriendmodelback {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background: rgba(0, 0, 0, 0.57);
//     z-index: 999999;
//     transition: opacity 0.3s ease, transform 0.3s ease;
//     opacity: 1;
//     transform: translateY(0);
//     cursor: pointer;
    
//     &.hidden {
//         opacity: 0;
//         transform: translateY(100%);
//     }
// }

.Invitefriendmodelmain {
    // position: fixed;
    // bottom: 62px;
    // left: 0;
    // z-index: 999999;
    background-color: #fff;
    width: 100%;
    border-radius: 8px 8px 0 0;
    // transition: opacity 0.3s ease, transform 0.3s ease;
    // opacity: 1;
    // transform: translateY(0);

    // &.hidden {
    //     opacity: 0;
    //     transform: translateY(100%);
    // }

    // .Invitefriendmodeltop {
    //     position: relative;
    //     width: 100%;
    //     height: 27px;

    //     &::before {
    //         content: "";
    //         position: absolute;
    //         top: 50%;
    //         left: 50%;
    //         transform: translate(-50%, -50%);
    //         border-radius: 1.5px;
    //         width: 33px;
    //         height: 3px;
    //         background: #e4e4e8;
    //     }
    // }

    .Invitefrientitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 24px 0;
        padding: 0 16px;

        p {
            color: #495057;
            font-family: "Poppins", sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        span {
            color: #556ee6;
            font-family: "Poppins", sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            cursor: pointer;
        }
    }

    .Sharepera {
        p {
            color: #060606;
            font-family: "Poppins", sans-serif;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-align: center;
            margin: 0 0 24px 0;
        }
    }

    .profilelinkdivmain {
        padding: 0 16px 24px 21px;

        .profilelinkdiv {
            position: relative;

            input {
                border-radius: 10px;
                border: 1px solid #eceef4;
                padding: 12px 11px;
                color: #7a7e88;
                font-family: "Poppins", sans-serif;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                width: 100%;
                box-sizing: border-box;
            }

            .copyicon {
                position: absolute;
                top: 12px;
                right: 12px;
                cursor: pointer;
            }
        }
    }

    .shareplatformdivmain {
        padding: 0 16px;
        margin: 0 0 27px 0;
        p {
            color: #060606;
            font-family: "Poppins", sans-serif;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 0 0 13px 0;
        }

        .shareplatformflxmain {
            display: flex;
            align-items: center;
            justify-content: start;
            flex-wrap: wrap;
            gap: 16px;

            .shareplatformbox {
                border-radius: 10px;
                background: #eef1fd;
                width: 42px;
                height: 42px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
        }
    }
    
    .inviteemailmain {
        margin: 0 0 46px 0;
        padding: 0 21px 0 16px;
        
        p {
            color: #060606;
            font-family: "Poppins", sans-serif;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 0 0 16px 0;
        }
        
        .inviteemailflxmain {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 7px;
            
            input {
                border-radius: 10px;
                border: 1px solid #eef1fd;
                padding: 10px 16px;
                width: 100%;
                color: #7a7e88;
                font-family: "Poppins", sans-serif;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                width: 100%;
            }
            
            .Plusicondiv {
                border-radius: 10px;
                background: #eef1fd;
                width: 42px;
                height: 42px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
        }
    }

    .sendbuttonmain {
        display: flex;
        justify-content: end;
        align-items: center;
        padding: 0 21px;
        margin: 0 0 29px 0;

        button {
            padding: 6px;
            width: 93px;
            height: 32px;
            border-radius: 8px;
            background: #556ee6;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            text-align: center;
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
}
