.storemainBox {
  border-radius: 8px;
  padding: 34px 31px;
  background: linear-gradient(179deg, rgba(214, 198, 249, 0.8) -3.92%, rgba(70, 95, 229, 0.8) 102.05%);
  margin-bottom: 16px;
  @media (max-width: 576px) {
    border-radius: 0px;
    padding: 24px 16px;
  }

  .mobileHide {
    @media (max-width: 576px) {
      display: none;
    }
  }

  .mobileShow {
    display: none;
    @media (max-width: 576px) {
      display: block;
    }
  }

  .flexBox {
    display: flex;
    gap: 35px;

    @media (max-width: 576px) {
      flex-wrap: wrap;
      gap: 10px;
    }

    .flexBoxItems:nth-child(1) {
      width: 221px;

      @media (max-width: 576px) {
        width: 100%;
      }
    }

    // .slick-next svg {
    //   transform: rotate(0deg) !important;
    // }

    .flexBoxItems:nth-child(2) {
      width: calc(100% - 221px - 90px);
      @media (max-width: 576px) {
        width: 100%;
      }
    }

    .content {
      @media (max-width: 576px) {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
      }
      p {
        color: #1e2432;
        font-size: 18px;
        margin: 0 0 2px 0;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        @media (max-width: 576px) {
          font-size: 16px;
        }
      }

      span {
        display: block;
        color: #353a48;
        font-size: 13px;
        padding: 0 0 20px 0;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.1px;
        @media (max-width: 576px) {
          font-size: 12px;
        }
      }

      button {
        color: #fff;
        padding: 6px 24px;
        border-radius: 6px;
        background: #1e2432;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        border: none;
        cursor: pointer;

        @media (max-width: 576px) {
          background-color: transparent;
          color: #556ee6;
        }
      }
    }
  }

  .mobileShowArrow {
    display: none;

    @media (max-width: 576px) {
      display: block;
    }

    svg {
      cursor: pointer;
    }
  }

  .title {
    padding: 0 0 100px 0;

    @media (max-width: 576px) {
      padding: 0 0 30px 0;
    }

    p {
      color: #000000;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      font-style: normal;
      @media (max-width: 576px) {
        font-size: 18px;
        text-align: center;
      }
    }

    span {
      font-size: 13px;
      font-style: normal;
      max-width: 305px;
      color: #000000;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.408px;
      display: block;
      @media (max-width: 576px) {
        font-size: 12px;
        line-height: 20px;
        max-width: 252px;
        letter-spacing: 0px;
        margin: 0 auto;
        text-align: center;
      }
    }
  }
}
