@mixin on($display) {
  display: $display;
  visibility: visible;
  touch-action: auto;
  pointer-events: all;
}

@mixin off($display) {
  display: $display;
  visibility: hidden;
  touch-action: none;
  pointer-events: none;
}

@mixin block_btn {
  background: var(--primary-500);
  border-radius: 8px;
  display: block;
  width: 100%;
  border: unset;
  outline: unset;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    transform: translateY(-3px);
    background: #2844ac;
  }
}

.findYourStyles-section {
  background: #f9f9f9;

  .findYourStyles-back-alignment {
    display: flex;
    align-items: center;
    gap: 22px;
    padding: 8px 10px;
    background: rgba(248, 248, 248, 0.92);
    display: none;

    @media only screen and (max-width: '767px') {
      display: flex;
      position: sticky;
      top: 0;
      z-index: 99;
    }

    .back_details-alignment {
      background: #ffffff;
      border-radius: 10px;
      padding: 4px 9px 4px 10px;
      display: flex;
      align-items: center;
      gap: 8px;

      p {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #808191;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 58px;
      }
    }

    .mobile-heading-alignment {
      h4 {
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: var(--secondary-900);
      }
    }
  }

  .findYourStyles-banner {
    background: #f9f9f9;
    // background-image: url("../../../assets/img/findStyleBg.svg");
    // width: 100%;
    // height: 100%;
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: center;
    position: sticky;
    top: 0px;
    z-index: 99;

    @media only screen and (max-width: '1199px') {
      top: 0;
    }

    @media only screen and (max-width: 767px) {
      // background-image: url("../../../assets/img/mobileFindYourStyleBg.svg");
      position: sticky;
      top: 48px;
      z-index: 99;
    }

    .findYourStyles-banner-img-alignment {
      position: absolute;
      top: 0;
      right: 0;

      img {
        width: 100%;
        height: 178px;
        object-fit: cover;
        object-position: center;
        display: block;

        @media only screen and (max-width: '767px') {
          display: none;
        }

        &.mobile-view-banner {
          display: none;

          @media only screen and (max-width: '767px') {
            display: block;
            height: 100px;
            display: block;
          }
        }
      }
    }

    .container {
      max-width: 1440px;
      margin: auto;
      padding: 0 20px;

      div {
        img {
          cursor: pointer;
          display: block;
        }
      }

      .findYourStyles-banner-section {
        height: 178px;
        display: flex;
        align-items: center;
        gap: 160.5px;
        position: relative;

        @media only screen and (max-width: '1099px') {
          gap: 80px;
          padding: 0 20px;
        }

        @media only screen and (max-width: '767px') {
          height: 100px;
        }

        .left-arrow-alignment {
          @media only screen and (max-width: '767px') {
            display: none;
          }
        }
      }

      .findYourStyles-heading-alignment {
        h4 {
          font-family: 'Poppins', sans-serif;
          font-weight: 300;
          font-size: 40px;
          line-height: 60px;
          color: #000000;
          padding: 0 0 4px 0;

          @media only screen and (max-width: '767px') {
            font-size: 20px;
            line-height: 30px;
          }
        }

        p {
          font-family: 'Poppins', sans-serif;
          font-weight: 300;
          font-size: 18px;
          line-height: 27px;
          color: var(--secondary-900);

          @media only screen and (max-width: '767px') {
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 200px;
          }
        }
      }
    }
  }

  .findYourStyles-search-section {
    padding: 32px 0;
    background: #ffffff;

    @media only screen and (max-width: 767px) {
      display: none;
    }

    .container {
      max-width: 1300px;
      margin: auto;
      padding: 0 20px;

      .search-alignment {
        background: #f8f8f8;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        padding: 15px 0;
        width: 100%;

        @media only screen and (max-width: '767px') {
          display: none;
        }

        .search-icon-alignment {
          img {
            cursor: pointer;
            display: block;
            width: 42px;
            height: 42px;
          }
        }

        .search-input-alignment {
          input {
            background: transparent;
            border: none;
            height: 100%;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            color: #acb1c0;
          }
        }
      }
    }
  }

  .findYourStyles-styles-details-alignment {
    background-color: #ffffff;
    margin: 30px 0 0 0;

    @media only screen and (max-width: 767px) {
      display: none;
    }

    // padding: 0 0 32px 0;
    .child-styles-details {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 0 0 32px 0;

      .details-heading-alignment {
        // background: #F7F7F7;
        border-radius: 10px;
        height: 496px;
        width: 336px;
        min-width: 336px;
        max-width: 336px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 20px;
        position: relative;
        // &.second-product {
        //     background-color: #F7F3ED;
        // }

        // &.third-product {
        //     background-color: #091534;

        //     .fashion-name-alignment {
        //         h5 {
        //             color: #ffffff;
        //         }

        //         p {
        //             color: #ffffff;
        //         }
        //     }
        // }

        .find-your-style-bg-img {
          position: absolute;
          top: 0;
          right: 0;

          img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }

          &::before {
            content: '';
            position: absolute;
            background: linear-gradient(180deg, rgba(30, 36, 50, 0.32) 0%, #1e2432 89.52%);
            border-radius: 10px;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
          }
        }

        .fashion-name-alignment {
          display: flex;
          flex-direction: column;
          align-items: center;

          h5 {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 32px;
            line-height: 48px;
            text-align: center;
            color: #f8f8f8;
          }

          p {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #f8f8f8;
            max-width: 270px;
            margin: 16px 0 0 0;
            position: relative;
          }
        }

        .see-all-product-alignment {
          margin: 56px 0 12px 0;

          a {
            display: flex;
            gap: 8px;
            align-items: center;
            justify-content: center;
            font-family: 'Poppins', sans-serif;
            font-weight: 300;
            font-size: 18px;
            line-height: 27px;
            text-align: center;
            color: var(--primary-500);
            cursor: pointer;
          }
        }
      }

      .findYourStyle_product_block_empty {
        width: 100%;
        height: 100%;
        border-radius: 14px;
        background: rgba(85, 110, 230, 0.13);
        min-height: 374px;
        display: flex;
        justify-content: center;
        align-items: end;
        padding: 27px 24px;
        margin: 0 0 0 50px;

        .findYourStyle_product_block_empty_details_box {
          width: 100%;
          border-radius: 14px;
          background: #EEF1FD;
          padding: 40px 0 26px 0;
          display: flex;
          align-items: center;
          gap: 39px;
          flex-direction: column;

          p {
            color: #9C6AC5;
            font-family: Poppins;
            font-size: 10.641px;
            font-style: italic;
            font-weight: 400;
            line-height: normal;
          }
        }
      }

      .findYourStyle_product_block {
        width: 100%;

        .findYourStyle_product_block_row {
          margin: 0 -8px;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          align-items: stretch;
          height: 496px;
          overflow-y: hidden;

          @media only screen and (max-width: 1199px) {
            grid-template-columns: repeat(3, 1fr);
          }

          @media only screen and (max-width: 967px) {
            grid-template-columns: repeat(2, 1fr);
          }

          .card_col {
            width: 100%;
            padding: 0 8px;
            height: 100%;
            display: grid;
            height: 496px;

            .cardcover {
              width: 100%;
              border-radius: 10px;
              position: relative;
              overflow: hidden;
              cursor: pointer;
              margin-bottom: 15px;

              &:last-child {
                margin-bottom: 0;
              }

              &:hover {
                .card_top img {
                  transform: scale(1.05);
                }

                .card_bottom {
                  @include on(flex);
                }
              }

              .card_top {
                height: 100%;
              }

              .card_top img {
                width: 100%;
                height: 100%;
                transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
              }

              .card_bottom {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(180deg, rgba(30, 36, 50, 0) 0%, #1e2432 100%);
                padding: 16px;
                display: flex;
                align-items: flex-end;
                @include off(none);

                .card_bottom_cover {
                  width: 100%;

                  h4 {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 30px;
                    color: #f8f8f8;
                  }

                  .amount {
                    margin: 5px 0 8px 0;

                    span {
                      vertical-align: middle;
                    }

                    .price {
                      font-weight: 600;
                      font-size: 18px;
                      line-height: 27px;
                      color: #f8f8f8;
                    }

                    .orignal_price {
                      text-decoration-line: line-through;
                      font-weight: 500;
                      font-size: 12px;
                      line-height: 18px;
                      color: #ffffff;
                      opacity: 0.6;
                      margin: 0 6px;
                    }

                    .discount {
                      font-weight: 500;
                      font-size: 12px;
                      line-height: 18px;
                      text-align: center;
                      color: #ffffff;
                      background: #ff7d23;
                      border-radius: 11px;
                      padding: 2px 6px;
                    }
                  }

                  .hike {
                    display: flex;
                    align-items: center;

                    .inter {
                      display: flex;
                      align-items: center;
                      font-size: 12px;
                      line-height: 18px;
                      color: #f8f8f8;

                      img {
                        margin-right: 4px;
                      }
                    }

                    .incris {
                      margin-left: 15px;
                      display: flex;
                      align-items: center;
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 21px;
                      color: #70c217;

                      img {
                        margin-right: 4px;
                      }
                    }
                  }

                  .rating {
                    display: flex;
                    align-items: center;
                    margin: 9px 0 18px 0;

                    .rating_point {
                      font-weight: 600;
                      font-size: 16px;
                      line-height: 24px;
                      color: #f8f8f8;
                    }

                    .stars {
                      display: flex;
                      align-items: center;
                      margin: 0 8px;
                    }

                    .num {
                      font-size: 16px;
                      line-height: 24px;
                      color: #f8f8f8;
                    }
                  }

                  .buy_btn {
                    @include block_btn();
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .women-mobile-view-details-fashion-name-search-alignment {
    display: none;

    @media only screen and (max-width: 767px) {
      display: flex;
      padding: 0 0 30px 0;
    }

    gap: 8px;
    background: #ffffff;
    padding: 16px 0 0 0;
    overflow-x: hidden;

    .mobile-view-child-heading-alignment {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      // padding: 0 54px 0 0;
      padding: 0 38px 0 0;

      @media (max-width:576px) {
        padding: 16px 20px;
      }

      .mobile-view-child-name-alignment {
        display: flex;
        align-items: center;
        gap: 4px;

        h5 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 20px;
          line-height: 30px;
          color: var(--secondary-900);
        }

        span {
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #808191;
        }
      }

      .mobile-search-icon-alignment {
        img {
          width: 24px;
          height: 24px;
          display: block;
          cursor: pointer;
        }
      }
    }

    .mobile-child-search-alignment {
      
      position: relative;
      right: 16px;
      margin: 14px 0 0 0;

      input {
        background: #f8f8f8;
        border-radius: 10px;
        width: 100%;
        height: 44px;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: var(--secondary-900);
        padding: 10px 40px 10px 10px;
      }

      .close-alignment {
        position: absolute;
        top: 10px;
        right: 14px;
        cursor: pointer;
      }
    }

    .mobile-view-sidebar-alignment {
      display: flex;
      align-items: flex-start;

      .mobile-search-sidebar-details-box-alignment {
        display: flex;
        align-items: flex-start;

        .mobile-view-sidebar-box-alignment {
          background: #f8f8f8;
          // border-radius: 8px;
          height: calc(100vh - 164px);
          overflow-y: auto;
          width: 146px;
          max-width: 146px;
          min-width: 146px;
          @media only screen and (max-width:"1024px") {
            height: calc(100vh - 215px);
          }
          .mobile-silder-side-main-details {
            padding: 29px 10px;
            position: relative;

            .find-fashion-border-alignment {
              width: 114px;
              height: 114px;
              border: 1px solid transparent;
              border-radius: 68.4405px;
              padding: 6px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0 0 2px 0;

              &.find-fashion-border-active {
                border: 1px solid #ffecbc;
              }

              .find-fashion-box-alignment {
                background: transparent;
                border-radius: 68.4405px;
                width: 102px;
                height: 102px;
                display: flex;
                align-items: center;
                justify-content: center;

                &.find-fashion-box-active {
                  background-color: rgba(255, 203, 70, 0.6);
                }
              }
            }

            .fashion-img-alignment {
              width: 88px;
              height: 88px;
              position: relative;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 50%;
              }

              &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background: rgba(30, 36, 50, 0.6);
                top: 0;
                right: 0;
                border-radius: 50%;
              }

              .fashion-option-name-alignment {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 9;

                h5 {
                  font-family: 'Poppins', sans-serif;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  text-align: center;
                  color: #ffffff;
                }
              }
            }
          }
        }

        .mobile-view-left-arrow-alignment {
          background: #f8f8f8;
          border-radius: 0px 8px 8px 0px;
          padding: 10px 14px;
          margin: 0 8px 0 0;
          width: 32px;
          min-width: 32px;
          max-width: 32px;
        }
      }
    }
  }
}

// Mobile View Design .search-alignment

.women-mobile-findYourStyle_product_block_empty_state {
  height: calc(100vh - 270px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 14px;

  p {
    color: #ACB1C0;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.women-mobile-findYourStyle_product_block {
  // width: 100%;
  // overflow-x: auto;
  // margin: 0;
  // position: relative;
  // height: calc(100vh - 108px);
  // overflow-y: auto;

  // &.women-find-style-details {
  //   height: calc(100vh - 286px);
  // }

  // &.mobile-contain {
  //   height: 100%;
  //   overflow-y: auto;
  // }

  // transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);

  // @media (max-width: 576px) {
  //   padding: 0 20px;
  // }
  .findYourStyle_product_block_row {
    margin: 0 -8px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: stretch;
    height: 100%;
    overflow-y: auto;
    gap: 12px 0;
    padding: 0 0 16px 0;

    .card_col {
      width: 100%;
      padding: 0 8px;
      height: 100%;
      display: grid;
      height: 317px;

      .find-your-style-cardcover {
        // width: 180px;
        width: 164px;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        margin-bottom: 15px;

        // @media only screen and (max-width:799px) {
        //     width: 100%;
        // }

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          .card_top img {
            transform: scale(1.05);
          }

          .card_bottom {
            @include on(flex);
          }
        }

        .card_top {
          height: 100%;
        }

        .card_top img {
          width: 100%;
          height: 100%;
          transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
          object-fit: cover;
        }

        .card_bottom {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(180deg, rgba(30, 36, 50, 0) 0%, #1e2432 100%);
          padding: 16px;
          display: flex;
          align-items: flex-end;
          @include off(none);

          .card_bottom_cover {
            width: 100%;

            h4 {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #f8f8f8;
            }

            .hike {
              display: flex;
              align-items: center;

              .inter {
                display: flex;
                align-items: center;
                font-size: 12px;
                line-height: 18px;
                color: #f8f8f8;

                img {
                  margin-right: 4px;
                }
              }

              .incris {
                margin-left: 15px;
                display: flex;
                align-items: center;
                font-weight: 600;
                font-size: 14px;
                line-height: 21px;
                color: #70c217;

                img {
                  margin-right: 4px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.width-alignment {
  width: 100%;
}