.suggestedClubsDetailsSection {
  margin: 16px 0 0 0;

  @media only screen and (max-width: '1024px') {
    margin: 8px 0 0 0;
  }

  .suggestedClubsDetailsBoxAlignment {
    border-radius: 8px;
    background: #fff;
    // padding: 25px 32px;

    @media only screen and (max-width: '1024px') {
      padding: 16px 16px 70px 16px;
    }

    @media (max-width: 576px) {
      background-color: transparent;
      padding: 0 16px 30px 16px;
    }

    .suggestedClubSearchAlignment {
      width: 391px;
      height: 40px;
      background: #f8f8f8;
      display: flex;
      align-items: center;
      border-radius: 8px;
      padding: 0 8px 0 0;

      @media only screen and (max-width: '1024px') {
        width: 100%;
      }

      @media only screen and (max-width: '767px') {
        width: 100%;
      }

      .suggestedSearchIcon {
        width: 40px;
        min-width: 40px;
        max-width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      input {
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;

        &::placeholder {
          color: #acb1c0;
        }
      }
    }

    .suggestedClubsAllDetailsAlignment {
      .suggestedClubsAllHeading {
        h6 {
          color: var(--bookmark-heading-color);
          font-size: 18px;
          font-family: 'Poppins', sans-serif;
          font-weight: 500;
          line-height: 27px;
        }

        p {
          padding: 2px 0 0 0;
          color: var(--secondary-500);
          font-size: 13px;
          font-family: 'Poppins', sans-serif;
          line-height: 24px;
          letter-spacing: 0.1px;
        }
      }

      .suggestedClubsDetailsAllDetailsAlignment {
        margin: 16px 0 0 0;

        .suggestedClubsDetailsAllContentAlignment {
          max-height: 590px;
          overflow: auto;
          padding: 0 8px;
          position: relative;

          .suggestedClubsDetailsCardGrid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 16px;

            @media only screen and (max-width: '574px') {
              grid-template-columns: 1fr;
              gap: 8px;
            }

            .suggestedClubsDetailsCardGridItem {
              border-radius: 8px 8px 0px 0px;
              position: relative;

              .suggestedClubsDetailsCardImage {
                img {
                  width: 100%;
                  height: 251px;
                  object-fit: cover;
                  border-radius: 10px 10px 0 0;
                }
              }

              .suggestedClubsCardDetails {
                position: absolute;
                bottom: 0;
                left: 0;

                width: 100%;

                .suggestedClubsCardDetailsAlignment {
                  padding: 9px 9px 18px 9px;

                  .suggestedClubsAllContentAlignment {
                    display: flex;
                    align-items: center;
                    width: 100%;
                    justify-content: space-between;

                    .suggestedClubsRightSideContent {
                      button {
                        background: rgba(55, 55, 55, 0.78);
                        border-radius: 10px;
                        padding: 0 11px;
                        height: 20px;
                        font-weight: 500;
                        font-size: 12px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        color: #fff;
                      }
                    }

                    .suggestedClubsLeftSideContent {
                      display: flex;
                      align-items: center;
                      gap: 4px;

                      img {
                        cursor: pointer;
                      }

                      span {
                        display: block;
                        color: #fff;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                      }
                    }

                    .suggestedClubsusers {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: space-between;

                      .suggestedClubsUserIcon {
                        display: flex;
                        align-items: center;
                        gap: 4px;

                        .suggestedClubseftPart {
                          display: flex;
                          align-items: center;
                          padding-left: 10px;

                          .clubModaUsersLikes {
                            display: flex;
                            align-items: center;
                            margin-left: -10px;

                            .suggestedClubsUserBox {
                              width: 20px;
                              display: flex;
                              align-items: center;
                              height: 20px;
                              justify-content: center;
                              background: #fff;
                              object-position: center;
                              border: 1px solid #ffffff;
                              border-radius: 30px;
                              margin: 0 0 0 -10px;

                              &:first-child {
                                margin: 0;
                              }

                              img {
                                width: 100%;
                                border-radius: 30px;
                                object-fit: cover !important;
                              }
                            }
                          }

                          .clubModaCount {
                            margin-left: -10px;
                            border-radius: 12px;
                            border: 1px solid #fff;
                            background: #1e2432;
                            border-radius: 12px;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 18px;
                            letter-spacing: 0.1px;
                            color: #ffffff;
                            padding: 0px 9px;
                          }
                        }
                      }

                      .suggestedClubsRightSideContent {
                        button {
                          border-radius: 8px;
                          background: var(--primary-500);
                          padding: 0 22px;
                          height: 32px;
                          color: #fff;
                          text-align: center;
                          font-size: 14px;
                          font-family: 'Poppins', sans-serif;
                          font-weight: 500;
                          letter-spacing: 0.1px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          cursor: pointer;
                          color: #fff;
                        }
                      }
                    }
                  }

                  h5 {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: #fff;
                    margin: 0 0 18px 0;
                  }

                  .suggestedClubsLimeCamp {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                  }

                  h4 {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #fff;
                    margin: 0 0 1px 0;
                    cursor: pointer;
                  }
                }
              }

              .suggestedClubsblurDiv {
                position: absolute;
                background: linear-gradient(360deg, rgba(30, 36, 50, 0.8) 0%, rgba(30, 36, 50, 0) 100%);
                border-radius: 0px;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 251px;
              }
            }
          }
          .showMoreButtonContain {
            padding: 70px 0 52px 0;
          }
        }
      }
    }
  }
}

.newSuggestedStores {
  background-image: url('/assets/img/store-vectore.png');
  width: 100%;
  height: 140px;
  background-repeat: no-repeat;
  border-radius: 8px 8px 0px 0px;
  background-size: cover;
  padding: 62px 60px 0 60px;
  margin: 0 0 30px 0;

  @media (max-width: 576px) {
    display: none;
  }

  .storesGrid {
    display: grid;
    grid-template-columns: 1fr 411px;
    gap: 20px;

    p {
      margin: 0 0 2px 0;
      color: #171725;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    span {
      display: block;
      color: #4c515d;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.1px;
    }

    .peopleFollowerSearchAlignment {
      height: 40px;
      display: flex;
      align-items: center;
      background: #fff;
      border-radius: 10px;
      padding: 0 16px 0 0;

      @media only screen and (max-width: '1024px') {
        width: 100%;
        max-width: 100%;
      }

      input {
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 400;
        background-color: #fff;
        line-height: 24px;

        &::placeholder {
          color: #acb1c0;
        }
      }
    }
  }
}

.mobileViewHeaderSticky {
  display: none;

  @media (max-width: 576px) {
    display: block;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 99;
  }

  .mobileViewClubModalHeader {
    padding: 8px 16px 12px 16px;
    text-align: center;
    background: #d3e4f7;
    margin: 0 0 8px 0;
    position: relative;

    h4 {
      color: var(--secondary-900);
      text-align: center;
      font-family: 'Poppins', sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }

    .mobileViewBackArrowAlignment {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 16px;
      z-index: 99;
    }

    .mobileViewCreateClub {
      position: absolute;
      bottom: 8px;
      right: 16px;
      z-index: 99;

      .mobileCreateClubModalButton {
        button {
          padding: 6px 16px;
          border-radius: 8px;
          background: var(--primary-50);
          color: var(--primary-500);
          text-align: center;
          font-size: 14px;
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
        }
      }
    }
  }
}

.mobileviewShowContnetDesign {
  display: none;

  @media (max-width: 576px) {
    display: block;
  }

  p {
    color: #171725;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 16px 0 2px 0;
  }

  span {
    padding: 0 0 20px 0;
    display: block;
    color: #7a7e88;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 200% */
  }

  .peopleFollowerSearchAlignment {
    height: 40px;
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 10px;
    padding: 0 16px 0 0;

    @media only screen and (max-width: '1024px') {
      width: 100%;
      max-width: 100%;
    }

    input {
      width: 100%;
      height: 100%;
      background: transparent;
      border: none;
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      font-weight: 400;
      background-color: #fff;
      line-height: 24px;

      &::placeholder {
        color: #acb1c0;
      }
    }
  }
}
