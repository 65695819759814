.deleteCollectionModalSection {
    .deleteCollectionModalWrapper {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background: rgba(30, 36, 50, 0.6);
        z-index: 9999999999;
        display: flex;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width:"767px") {
            padding: 0 32px;
        }

        .deleteCollectionModalBox {
            padding: 19px 32px 32px 32px;
            border-radius: 16px;
            background: var(--white);

            @media only screen and (max-width:"767px") {
                padding: 32px;
                width: 100%;
            }

            .deleteCollecyionHeading {
                h6 {
                    color: var(--secondary-900);
                    text-align: center;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 24px;
                    max-width: 262px;

                    @media only screen and (max-width:"767px") {
                        max-width: 100%;
                    }

                }
            }

            .deleteButtonAlignment {
                padding: 32px 0 0 0;

                @media only screen and (max-width:"767px") {
                    padding: 24px 0 0 0;
                }

                button {
                    width: 100%;
                    height: 44px;
                    color: var(--white);
                    text-align: center;
                    font-size: 18px;
                    font-weight: 500;
                    line-height: 28px;
                    border-radius: 10px;
                    background: var(--red-500);
                    margin: 0 0 8px 0;

                    &:last-child {
                        background: var(--secondary-50);
                        color: var(--secondary-500);
                        margin: 0;
                    }
                }
            }
        }
    }
}