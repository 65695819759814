.mobileViewWithdrawRequestModalSection {
    height: calc(100vh - 54px);

    .mobileViewWithdrawRequestModalHeading {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 16px 14px 16px;
        border-bottom: 1px solid #EAECEF;

        h4 {
            color: #495057;
            font-size: 18px;
            line-height: 27px;
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
        }

        a {
            color: var(--primary-500);
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            font-family: 'Poppins', sans-serif;
        }
    }

    .mobileViewWithdrawRequestModalAlignment {

        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 100%;
        padding: 16px;
        height: calc(100vh - 112px);
        .mobileViewWithdrawRequestModalFirstPart {
            .mobileViewWithdrawRequestIcon {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            p {
                padding: 28px 0 0 0;
                color: var(--secondary-900);
                text-align: center;
                font-size: 16px;
                font-family: 'Poppins', sans-serif;
                line-height: 24px;
                font-weight: 500;
                max-width: 209px;
                margin: 0 auto;
            }
        }

        .mobileViewWithdrawRequestModalBottomPart {
            display: flex;
            flex-direction: column;
            gap: 44px;
            align-items: center;

            .requestOkButton {
                button {
                    max-width: 201px;
                    width: 201px;
                    height: 44px;
                    border-radius: 10px;
                    background: var(--primary-500);
                    color: #FFF;
                    text-align: center;
                    font-size: 16px;
                    font-family: 'Poppins', sans-serif;
                    font-weight: 600;
                    line-height: 24px;
                }
            }

            .checkStatusButtonAlignment {
                button {
                    background-color: transparent;
                    border: none;
                    padding: 0;
                    margin: 0;
                    color: var(--primary-500);
                    text-align: center;
                    font-size: 13px;
                    font-family: 'Poppins', sans-serif;
                    line-height: 18px;
                    font-weight: 500;
                }
            }
        }
    }

}