.categorySpecialOfferdProductSection {
  background: var(--white);
  padding: 0 32px;
  border-radius: 10px;

  .categorySpecialOfferheading {
    padding: 32px 0 16px 0;

    h4 {
      color: var(--bookmark-heading-color);
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
    }
  }

  .categorySpecialOfferFilter {
    ul {
      display: flex;
      align-items: center;
      gap: 32px;
      padding: 8px 0;

      li {
        color: var(--secondary-900);
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        cursor: pointer;
        position: relative;
        transition: 0.4s ease-in-out;
        &:hover {
          font-weight: 600;
          transition: 0.4s ease-in-out;

          &::before {
            content: '';
            position: absolute;
            bottom: -8px;
            left: 0;
            width: 100%;
            height: 2px;
            border-radius: 1px;
            background: var(--primary-500);
          }
        }

        &.activeFIlter {
          font-weight: 600;
          transition: 0.4s ease-in-out;

          &::before {
            content: '';
            position: absolute;
            bottom: -8px;
            left: 0;
            width: 100%;
            height: 2px;
            border-radius: 1px;
            background: var(--primary-500);
          }
        }
      }
    }
  }

  .categorySpecialOfferBodyAlignment {
    margin: 24px 0 0 0;
    height: calc(100vh - 238px);
    overflow-y: auto    ;

    .categoryGridAlignment {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 16px;

      .categoryGridItem {
      }
    }
  }
}
