.tumbnailPreviewModalSection {
    // position: absolute;
    // top: 110px;
    // left: 165px;
    // z-index: 9999;
    width: 39.3%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    @media only screen and (max-width: '1799px') {
        width: 29.3%;
    }

    @media only screen and (max-width: '1024px') {
        display: none;
    }

    .tumbnailPreviewModalBox {
        background: var(--secondary-50);
        border-radius: 14px;
        padding: 25px 32px 32px 32px;
        width: 364px;

        @media only screen and (max-width: '1699px') {
            width: 300px;
            padding: 20px 12px 12px 12px;
        }

        .tumbnailPreviewModalHeadingAlignment {
            h4 {
                font-family: 'Inter';
                font-weight: 600;
                font-size: 24px;
                line-height: 29px;
                color: #000000;
                text-align: center;

                @media only screen and (max-width: '1699px') {
                    font-size: 20px;
                    line-height: 26px;
                }

            }
        }

        .tumbnailPreviewModalDetails {
            padding: 16px 0 0 0;

            .tumbnailPreviewModalDetailsBox {
                background: #FFFFFF;
                border: 1px solid #F3F3F3;
                border-radius: 10px;
                padding: 3px;

                .tumbnailPreviewModalPreviewImg {
                    width: 100%;
                    height: 441px;
                    position: relative;
                    cursor: pointer;

                    @media only screen and (max-width: '1699px') {
                        height: 345px;
                    }

                    img {
                        width: 100%;
                        height: 441px;
                        object-fit: cover;
                        border-radius: 8px 8px 0px 0px;

                        @media only screen and (max-width: '1699px') {
                            height: 345px;
                        }

                    }

                    .noTumbnailPreviewBoxAlignment {
                        width: 100%;
                        height: 100%;
                        background: #F8F8F8;
                        border-radius: 8px 8px 0px 0px;


                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        gap: 12px;

                        .noTumbnailImg {
                            width: 28px;
                            height: 28px;

                            img {
                                width: 100%;
                                height: 100%;
                                display: block;
                                border-radius: 0;
                            }
                        }

                        p {
                            font-family: 'Poppins', sans-serif;
                            font-style: italic;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 20px;
                            text-align: center;
                            color: #808191;
                        }
                    }

                    .colorBoxAlignment {
                        position: absolute;
                        bottom: 10px;
                        right: 10px;
                        background: #FFFFFF;
                        border: 0.5px solid #E4E4E8;
                        border-radius: 10.5px;
                        display: flex;
                        align-items: center;
                        gap: 6px;
                        padding: 2px 6px;
                        cursor: pointer;

                        .colorBoxImgAlignment {
                            width: 13px;
                            height: 13px;

                            img {
                                width: 100%;
                                height: 100%;
                                border-radius: 50%;
                            }
                        }

                        span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 18px;
                            color: var(--secondary-900);
                        }
                    }

                    .popularProductHoverInfoAlign {
                        position: absolute;
                        top: 40%;
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        padding: 16px;
                        display: none;
                        transition: all 0.4s ease-in-out;

                        svg {
                            cursor: pointer;
                        }
                    }
                }

                .tumbnailPreviewModalDetailsAlignment {
                    padding: 16px 14px;

                    h6 {
                        font-family: 'Poppins', sans-serif;
                        font-weight: 400;
                        font-size: 15px;
                        line-height: 22px;
                        color: var(--secondary-900);
                        max-width: 244px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        cursor: pointer;
                    }

                    .tumbnailPreviewPrice {
                        padding: 9px 0 0 0;
                        display: flex;
                        align-items: center;
                        gap: 4px;

                        p {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 21px;
                            color: #000000;
                        }

                        span {
                            font-family: 'Poppins', sans-serif;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 18px;
                            color: #808191;
                        }
                    }

                    .tumbnailPreviewtshareDeliveryShipping {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        padding: 8px 0 0 0;

                        @media screen and (max-width: 767px) {
                            // margin: 2px 0;
                            padding: 0;
                        }

                        .tumbnailPreviewBestSellerAlignment {
                            h6 {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 500;
                                font-size: 13px;
                                line-height: 20px;
                                background: linear-gradient(263.94deg, #ffc554 7.44%, #744c00 86.5%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                background-clip: text;
                                text-fill-color: transparent;
                            }
                        }

                        .tumbnailPreviewActivityDotAlignment {
                            p {
                                width: 4px;
                                height: 4px;
                                border-radius: 50%;
                                background: var(--grey3);
                            }
                        }


                        .tumbnailPreviewDiscountMenuAlign {
                            width: auto;
                            height: 21px;
                            border-radius: 13.5px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            gap: 2px;

                            @media screen and (max-width: 767px) {
                                padding: 4px 6px;
                            }

                            padding: 4px 8px;

                            img {
                                width: 100%;
                                height: 100%;
                            }

                            span {
                                font-weight: 500;
                                font-style: italic;
                                font-size: 12px;
                                line-height: 18px;
                                display: flex;
                                align-items: center;
                                text-decoration-line: none;
                                color: var(--perple-1);
                            }
                        }

                        .tumbnailPreviewarrowmenualign {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 6px;

                            img {
                                width: 11px;
                                height: 11px;
                            }

                            span {
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 21px;
                                display: flex;
                                align-items: center;
                                color: var(--green-1);
                            }
                        }
                    }

                    .tumbnailPreviewOtherOptionAlignment {
                        padding: 16px 0 0 0;
                        display: flex;
                        align-items: center;
                        gap: 16px;

                        .tumbnailPreviewOtherOptionDetails {
                            display: flex;
                            align-items: center;
                            gap: 2px;
                            cursor: pointer;

                            .tumbnailPreviewIcon {
                                width: 20px;
                                height: 20px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    display: block;
                                }
                            }

                            span {
                                font-family: 'Poppins', sans-serif;
                                font-weight: 500;
                                font-size: 13px;
                                line-height: 20px;
                                color: var(--secondary-500);
                            }
                        }
                    }
                }

                &::global(.slick-prev) {
                    left: -10px !important;

                }
            }
        }

        &:hover {
            transition: all 0.4s ease-in-out;

            .tumbnailPreviewModalDetails {
                .tumbnailPreviewModalPreviewImg {
                    .popularProductHoverInfoAlign {
                        display: flex;

                    }

                    .slickNextThumbline {
                        display: flex;
                        transition: 0.4s ease-in-out;
                    }

                    .slickPrevThumbline {
                        display: flex;
                        transition: 0.4s ease-in-out;

                    }
                }
            }
        }
    }


}


// .myCustomCarousel .slick-arrow {
//     color: #070606 !important;
//     border: none;
//     outline: none;
//     background: #242424 !important;
// }




// ::global(.slick-prev) {
//     left: 3% !important;
//     z-index: 1;
// }

// .slick-next {
//     right: 3% !important;
//     z-index: 1;
// }



.slickPrevThumbline {
    position: absolute;
    top: 50%;
    left: 5px;
    z-index: 999999;
    background: rgba(255, 255, 255, 0.6);
    width: 40px;
    height: 40px;
    // display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    cursor: pointer;
    transform: translateY(-50%);

    display: none;
    transition: 0.4s ease-in-out;

    svg path {
        stroke-width: 2px;
    }

    &:hover {
        background: var(--primary-500);

        svg {
            path {
                stroke: #fff;
                stroke-width: 3px;
            }
        }
    }
}

.slickNextThumbline {
    position: absolute;
    top: 50%;
    right: 5px;
    z-index: 999999;
    background: rgba(255, 255, 255, 0.6);
    width: 40px;
    height: 40px;
    // display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    cursor: pointer;
    transform: translateY(-50%);

    display: none;
    transition: 0.4s ease-in-out;

    svg path {
        stroke-width: 2px;
    }

    svg {
        transform: rotate(180deg);
    }

    &:hover {
        background: var(--primary-500);

        svg {
            path {
                stroke: #fff;
                stroke-width: 3px;
            }
        }
    }

}