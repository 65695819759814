.mobile-view-clubModal-details-alignment {
  padding: 32px;

  .mobile-view-clubModal-details-nav-alignment {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 0 0 32px 0;

    &:last-child {
      padding: 0;
    }

    .mobile-view-clubModal-icon-alignment {
    }

    .mobile-view-clubModal-option-name {
      h6 {
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #495057;
        &.logout {
          color: #e71616;
        }
      }

      span {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #808191;
      }
    }
  }
}
