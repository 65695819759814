.sellerLeftSidebarSection {
  background: rgba(255, 255, 255, 0.8);
  height: calc(100vh - 67px);
  width: 100%;
  border: 2px solid var(--white);
  backdrop-filter: blur(32px);
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  position: sticky;
  top: 67px; 
  z-index: 10;


  // @media only screen and (max-width: '1299px') {
  //   position: fixed;
  //   width: 280px;
  //   z-index: 999999;
  //   top: 0;
  //   left: ;
  // }
  @media only screen and (max-width: '1024px') {
    height: 100%;
    background: #ffffff;
    position: static;
  }

  &.activeSidebarDetails {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

    @media only screen and (max-width: '1024px') {
      display: none;
    }

    .sellerLeftSidebarAlignment {
      .sellerMainSidebarHeadingAlignment {
        .sellerMainProfileDetailsAlignment {
          @media only screen and (max-width: '1299px') {
            display: block;
          }
        }
      }

      .sellerMainSidebarDetailsAlignment {
        ul {
          li {
            .sellerOptionAlignment {
              padding: 11px 47px;

              span {
                display: block;
              }

              &.activeOption {
                span {
                  display: block;
                }
              }
            }
          }
        }
      }
    }

    .sellerSettingAlignment {
      ul {
        li {
          .sellerOptionAlignment {
            padding: 11px 47px;

            span {
              display: block;
            }

            &.activeOption {
              span {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  .sellerLeftSidebarAlignment {
    @media only screen and (max-width: '1024px') {
      border-bottom: 2px solid #eceef4;
    }

    .sellerMainSidebarHeadingAlignment {
      padding: 50px 0 32px 0;
      text-align: center;

      @media only screen and (max-width: '1299px') {
        position: relative;
      }

      @media only screen and (max-width: '1024px') {
        display: none;
      }

      .sellerToggleIcon {
        position: absolute;
        top: 12px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 999;
        display: none;

        @media only screen and (max-width: '1299px') {
          display: block;
        }

        img {
          cursor: pointer;
        }

        i {
          font-size: 20px;
          cursor: pointer;
        }
      }

      .sellerMainProfileImgAlignment {
        width: 89px;
        height: 89px;
        margin: auto;
        cursor: pointer;

        @media only screen and (max-width: '1299px') {
          width: 60px;
          height: 60px;
        }

        @media only screen and (max-width: '1024px') {
          width: 72px;
          height: 72px;
        }

        img {
          width: 100%;
          height: 100%;
          border-radius: 8px;
          object-fit: cover;
        }
      }

      .sellerMainProfileDetailsAlignment {
        padding: 16px 0 0 0;

        @media only screen and (max-width: '1299px') {
          display: none;
        }

        h6 {
          font-family: 'Poppins', sans-serif;
          font-weight: 600;
          font-size: 20px;
          line-height: 120%;
          color: var(--secondary-900);
        }

        .sellerProfiileRateFlexAlignment {
          padding: 8px 0 0 0;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;

          @media only screen and (max-width: '1024px') {
            padding: 2px 0 0 0;
          }

          .sellerOverRateAlignment {
            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              font-size: 13px;
              line-height: 20px;
              color: var(--secondary-500);
              padding: 0;
            }
          }

          .sellerRateStarFlexAlignment {
            display: flex;
            align-items: center;

            .starIconAlignment {
              img {
                max-width: 100%;
                cursor: pointer;
              }
            }
          }

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: var(--secondary-500);
          }
        }

        .mobileViewLocationDetailsAlignment {
          display: none;

          @media only screen and (max-width: '1024px') {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            padding: 5px 0 0 0;

            .mobileViewMapIcon {
              width: 12px;
              height: 12px;

              img {
                width: 100%;
                height: 100%;
                display: block;
              }
            }

            p {
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: var(--secondary-500);
            }
          }
        }
      }
    }

    .sellerMainSidebarDetailsAlignment {
      @media only screen and (max-width: '1024px') {
        display: none;
      }

      ul {
        li {
          padding: 0 0 10px 0;
          transition: 0.3s ease-in-out;

          .sellerOptionAlignment {
            padding: 11px 47px;
            display: flex;
            align-items: center;
            gap: 16px;
            cursor: pointer;
            border: 1px solid transparent;
            border-right: none;
            border-left: none;

            @media only screen and (max-width: '1299px') {
              padding: 11px 30px;
            }

            &:hover {
              background: var(--white);
              border: 1px solid #e7e9ee;
              border-right: none;
              border-left: none;

              .sellerHoverImg {
                display: block;
              }

              .sellerDefaltImg {
                display: none;
              }

              // svg {
              //     path {
              //         stroke: var(--primary-500);
              //         fill: var(--primary-500);
              //     }

              //     g {
              //         path {

              //             fill: var(--primary-500);
              //         }
              //     }

              //     stroke: var(--primary-500);
              // }

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                color: var(--primary-500);


              }
            }

            &.activeOption {
              background: var(--white);
              border: 1px solid #e7e9ee;
              border-right: none;
              border-left: none;

              .sellerHoverImg {
                display: block;
              }

              .sellerDefaltImg {
                display: none;
              }

              span {
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                color: var(--primary-500);

                @media only screen and (max-width: '1299px') {
                  display: none;
                }
              }
            }

            .sellerHoverImg {
              display: none;
            }

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: var(--secondary-500);

              @media only screen and (max-width: '1299px') {
                display: none;
              }
            }
          }
        }
      }
    }

    .mobileViewScrollHeaderAlignment {
      display: none;

      @media only screen and (max-width: '1024px') {
        display: flex;
        align-items: center;
        gap: 48px;
        height: 50px;
        padding: 0 16px;
        border-bottom: 2px solid #e7e9ee;

        .mobileViewBackIcon {}

        .mobileViewScrollProfileDetailsAlignment {
          display: flex;
          align-items: center;
          gap: 8px;

          .mobileViewScrollProfileDetailsImg {
            width: 24px;
            height: 24px;

            img {
              width: 100%;
              height: 100%;
              border-radius: 2px;
              object-fit: cover;
            }
          }

          h6 {
            font-family: 'Poppins', sans-serif;
            font-weight: 600;
            font-size: 18px;
            line-height: 27px;
            color: var(--secondary-900);
          }
        }
      }
    }
  }

  .sellerSettingAlignment {

    // padding: 32px 47px;
    @media only screen and (max-width:"1024px") {
      display: none;
    }

    ul {
      li {
        padding: 0 0 10px 0;
        transition: 0.3s ease-in-out;

        .sellerOptionAlignment {
          padding: 11px 47px;
          display: flex;
          align-items: center;
          gap: 16px;
          cursor: pointer;
          border: 1px solid transparent;
          border-right: none;
          border-left: none;

          @media only screen and (max-width: '1299px') {
            padding: 11px 30px;
          }

          &:hover {
            background: var(--white);
            border: 1px solid #e7e9ee;
            border-right: none;
            border-left: none;

            .sellerHoverImg {
              display: block;
            }

            .sellerDefaltImg {
              display: none;
            }

            // svg {
            //     path {
            //         stroke: var(--primary-500);
            //         fill: var(--primary-500);
            //     }

            //     g {
            //         path {

            //             fill: var(--primary-500);
            //         }
            //     }

            //     stroke: var(--primary-500);
            // }

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              color: var(--primary-500);
            }
          }

          &.activeOption {
            background: var(--white);
            border: 1px solid #e7e9ee;
            border-right: none;
            border-left: none;

            .sellerHoverImg {
              display: block;
            }

            .sellerDefaltImg {
              display: none;
            }

            span {
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              color: var(--primary-500);
            }
          }

          .sellerHoverImg {
            display: none;
          }

          span {
            font-family: 'Poppins', sans-serif;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: var(--secondary-500);

            @media only screen and (max-width: '1299px') {
              display: none;
            }
          }
        }
      }
    }
  }


}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(30, 36, 50, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.overlay.open {
  opacity: 1;
  visibility: visible;
  z-index: 9999;
}

.mobileViewDashboardModal {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 64%;
  height: calc(100vh - 64px);
  // height: calc(100vh - 104px);
  z-index: 9999;
  translate: 100% 0;
  transition: 0.3s;
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.mobileViewDashboardModal.open {
  translate: 0;
}